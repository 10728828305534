import React from 'react';
import { useIntl } from 'react-intl';
import { Panel } from 'rsuite';
import { PictoValueInterface } from '..';
import PanelHeader from '../../../Custom/PanelHeader';
import { PictoPreview } from './fontPreview';

type Props = {
    pictos: PictoValueInterface[];
    selectedPictoIndex: number;
    setClickedPictoIndex: (index: number) => void;
    pictoType: number;
    pictoToCopy: number;
};

export const LeftSideBar = (props: Props) => {
    const intl = useIntl();

    return (
        <Panel
            bordered
            style={{ maxHeight: '97vh', overflow: 'scroll' }}
            bodyFill
            shaded
            className="panel-small panel-sticky"
            header={<PanelHeader title={intl.formatMessage({ id: 'editPicto.pictoSelector' })} buttons={[]} />}>
            {props.pictos.map((picto, index) => {
                return (
                    <div
                        data-cy={`picto-${index}`}
                        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.4)' }}
                        key={index}
                        onClick={() => props.setClickedPictoIndex(index)}>
                        <PictoPreview
                            pictoType={props.pictoType}
                            picto={picto}
                            isSelected={index === props.selectedPictoIndex}
                            isCopied={props.pictoToCopy === index}
                        />
                    </div>
                );
            })}
        </Panel>
    );
};
