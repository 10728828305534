import { faParking, faPlusCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Panel, Tag } from 'rsuite';
import { parkingActions } from '../../redux/actions';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import ParkingDrawer from './Drawer';
import CreateParkingModal from './Modal/CreateParkingModal';
import DeleteParkingModal from './Modal/DeleteParkingModal';
import { default as imageCell } from './Table/ImageCell';

const mapDispatchToProps = dispatch => ({
    getAllParkings: () => dispatch(parkingActions.getAll()),
    openCreateParkingModal: () => dispatch(parkingActions.openCreateModal()),
    openViewDrawer: id => dispatch(parkingActions.openViewDrawer(id)),
    // openDeleteUserModal: (id) => dispatch(usersModalActions.openDeleteModal(id)),
    // openEditUserModal: (id) => dispatch(usersModalActions.openEditModal(id)),
});

type Props = {
    getAllParkings: Function;
    parkings: Record<string, any>;
    openCreateParkingModal: Function;
    openViewDrawer: Function;
} & WrappedComponentProps;

class ParkingsList extends React.Component<Props> {
    columns: TableColumn<RowRecord>[];

    constructor(props) {
        super(props);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'parking.logo',
                }),
                grow: 1,
                center: true,
                cell: row => imageCell(row.name, row.image, row.imageType),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'parking.name',
                }),
                grow: 1,
                center: true,
                cell: row => <div data-cy="parking-name">{row.name}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'parking.levels',
                }),
                grow: 1,
                center: true,
                cell: row => (
                    <Tag data-cy="parking-nbLevels" color="blue">
                        <span className="margin-right-5">{row.levelsLength}</span>
                        {this.props.intl.formatMessage({ id: 'parking.level.length' })}
                    </Tag>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'parking.description',
                }),
                grow: 1,
                center: true,
                cell: row => <div data-cy="parking-description">{row.description}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'parking.creation',
                }),
                grow: 1,
                center: true,
                cell: row => <div data-cy="parking-createInfo">{row.createInformations}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'parking.lastUpdate',
                }),
                grow: 1,
                center: true,
                cell: row => <div data-cy="parking-updateInfo">{row.updateInformations}</div>,
            },
        ];
    }
    componentDidMount() {
        this.props.getAllParkings();
    }

    render() {
        return (
            <Fragment>
                <SecuredFragment authorizedRoles={[rolesConstants.parking.CREATE]}>
                    <CreateParkingModal />
                </SecuredFragment>
                <SecuredFragment authorizedRoles={[rolesConstants.parking.DELETE]}>
                    <DeleteParkingModal />
                </SecuredFragment>
                <SecuredFragment authorizedRoles={[rolesConstants.parking.VIEW]}>
                    <ParkingDrawer />
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.parking.VIEW_LIST]}>
                    <Panel
                        className="panel-big dashboard-card-header"
                        shaded
                        bordered
                        header={
                            <PanelHeader
                                icon={faParking}
                                title={this.props.intl.formatMessage({ id: 'parkings.title' })}
                                tagValue={this.props.parkings.parkingList.length}
                                buttons={[
                                    <SecuredFragment
                                        key="user-reload"
                                        authorizedRoles={[rolesConstants.parking.VIEW_LIST]}>
                                        <Button
                                            appearance="primary"
                                            placement="left"
                                            size="sm"
                                            onClick={() => this.props.getAllParkings()}
                                            data-cy="parking-reloadParking-button">
                                            <FontAwesomeIcon icon={faSyncAlt} className="margin-right-10" />
                                            <FormattedMessage id="parkings.reloadList" />
                                        </Button>
                                    </SecuredFragment>,
                                    <SecuredFragment
                                        key="user-create"
                                        authorizedRoles={[rolesConstants.parking.CREATE]}>
                                        <Button
                                            color="red"
                                            placement="left"
                                            size="sm"
                                            onClick={() => this.props.openCreateParkingModal()}
                                            data-cy="parking-addParking-button">
                                            <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                            <FormattedMessage id="parkings.addParking" />
                                        </Button>
                                    </SecuredFragment>,
                                ]}
                            />
                        }
                        bodyFill>
                        <SecuredFragment authorizedRoles={[rolesConstants.level.VIEW_LIST]}>
                            <ElementTable
                                columns={this.columns}
                                data={this.props.parkings.parkingList}
                                progressPending={this.props.parkings.isLoading}
                                onRowClicked={row => this.props.openViewDrawer(row.id)}
                                small={false}
                            />
                        </SecuredFragment>
                    </Panel>
                </SecuredFragment>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { parkings } = state;

    return { parkings };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ParkingsList));
