import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { Button, ButtonGroup } from 'rsuite';
import { LPDisplay } from '../../../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import DeleteLPVirtualDisplayStepModal from '../Modal/DeleteLPVirtualDisplayStepModal';
import EditLPVirtualDisplayStepModal from '../Modal/EditLPVirtualDisplayStepModal';

type Props = {
    step: VirtualDisplayStep;
    virtualDisplay: VirtualDisplayPreviewInformation;
    reloadDisplay: Function;
    display: LPDisplay;
    updateInformation: Function;
};

type State = {
    editLPVirtualDisplayStepModal: boolean;
    deleteLPVirtualDisplayStepModal: boolean;
};

export default class LPVirtualDisplayStepEditAndDelete extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editLPVirtualDisplayStepModal: false,
            deleteLPVirtualDisplayStepModal: false,
        };
    }

    openEditLPVirtualDisplayStepModal = (e: React.SyntheticEvent) => {
        e.stopPropagation();

        this.setState({
            editLPVirtualDisplayStepModal: true,
        });
    };

    closeEditLPVirtualDisplayStepModal = (shouldReload: boolean = false) => {
        this.setState({
            editLPVirtualDisplayStepModal: false,
        });

        if (shouldReload) {
            this.props.reloadDisplay();
        }
    };

    openDeleteLPVirtualDisplayStepModal = (e: React.SyntheticEvent) => {
        e.stopPropagation();

        this.setState({
            deleteLPVirtualDisplayStepModal: true,
        });
    };

    closeDeleteLPVirtualDisplayStepModal = (shouldReload: boolean = false) => {
        this.setState({
            deleteLPVirtualDisplayStepModal: false,
        });

        if (shouldReload) {
            this.props.reloadDisplay();
        }
    };

    render = () => (
        <Fragment>
            <DeleteLPVirtualDisplayStepModal
                show={this.state.deleteLPVirtualDisplayStepModal}
                onHide={this.closeDeleteLPVirtualDisplayStepModal}
                step={this.props.step}
                virtualDisplay={this.props.virtualDisplay}
                updateInformation={this.props.updateInformation}
            />

            <EditLPVirtualDisplayStepModal
                show={this.state.editLPVirtualDisplayStepModal}
                onHide={this.closeEditLPVirtualDisplayStepModal}
                step={this.props.step}
                virtualDisplay={this.props.virtualDisplay}
                display={this.props.display}
                updateInformation={this.props.updateInformation}
            />

            <ButtonGroup>
                <Button
                    color="blue"
                    data-cy="lpDisplay-virtualDisplay-edit"
                    onClick={this.openEditLPVirtualDisplayStepModal}>
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button
                    color="red"
                    data-cy="lpDisplay-virtualDisplay-delete"
                    onClick={this.openDeleteLPVirtualDisplayStepModal}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </ButtonGroup>
        </Fragment>
    );
}
