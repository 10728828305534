import React from 'react';
import { Handle, NodeComponentProps, Position } from 'react-flow-renderer';
import { useIntl } from 'react-intl';
import { FlexboxGrid, Tag } from 'rsuite';
import VirtualDisplayPreview from '../../../../../../../IpCanElementsPage/LPMatrixDisplayV2Config/Nodes/VirtualDisplayNode/VirtualDisplayPreview';
import { LPMatrixDisplayV2VirtualDisplayForceType } from '../../LPMatrixDisplayFactory';
import { LPMatrixDisplayChartVirtualDisplayNodeType } from '../LPMatrixDisplayChartVirtualDisplayNode';

type Props = NodeComponentProps<LPMatrixDisplayChartVirtualDisplayNodeType>;

export const LPMatrixDisplayChartVirtualDisplayNodeComponent = (props: Props) => {
    const intl = useIntl();

    const renderTag = () => {
        if (props.data.virtualDisplayForceData?.mode === LPMatrixDisplayV2VirtualDisplayForceType.ADD_FORCE_STEP) {
            return (
                <Tag color="orange">
                    {intl.formatMessage({ id: 'lpMatrixDisplayV2.virtualDisplay.force.addForceStep' })}
                </Tag>
            );
        }
        if (props.data.virtualDisplayForceData?.mode === LPMatrixDisplayV2VirtualDisplayForceType.OVERRIDE) {
            return (
                <Tag color="orange">
                    {intl.formatMessage({ id: 'lpMatrixDisplayV2.virtualDisplay.force.override' })}
                </Tag>
            );
        }
        return null;
    };

    return (
        <div className={`matrix-display-node ${props.data.isForce && 'virtual-display-forced'}`}>
            <Handle
                id="target-virtual-display-topology"
                type="target"
                position={Position.Left}
                style={{ background: '#555' }}
                isConnectable={false}
            />

            <Handle
                id="source-virtual-display-step"
                type="source"
                position={Position.Right}
                style={{ background: '#555' }}
                isConnectable={false}
            />

            <h5 className="text-center margin-bottom-10">
                {props.data.label} {renderTag()}
            </h5>
            <FlexboxGrid align="middle" justify="center">
                <FlexboxGrid.Item>
                    <VirtualDisplayPreview
                        color={props.data.color}
                        virtualDisplay={props.data.virtualDisplay}
                        display={props.data.display}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};
