import {
    faArrowsAltH,
    faCamera,
    faCheck,
    faColumns,
    faEthernet,
    faNetworkWired,
    faParking,
    faPlus,
    faServer,
    faStepBackward,
    faStepForward,
    faTag,
    faTimesCircle,
    faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    InputGroup,
    Message,
    Modal,
    SelectPicker,
} from 'rsuite';
import Camera from '../../../../handlers/Camera/Camera';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';

type Props = {
    cancelCreation: () => void;
    validCreation: () => void;
    values: Record<string, any>;
    show: boolean;
    loading: boolean;
    cm: number;
    onChange: Function;
    placeTypes: Array<Record<string, any>>;
    controllers: Array<Record<string, any>>;
    error: boolean;
    errorMessage: string;
    cameras: Camera[];
};

export const CreatePlacesModal = (props: Props) => {
    const sensorTypeSelector = [
        {
            label: 'TCM Sensor',
            value: 'tcm',
        },
        {
            label: 'LP Sensor',
            value: 'lp',
        },
        {
            label: 'Camera Sensor',
            value: 'place-camera',
        },
    ];

    const busSelector = [
        {
            label: '1',
            value: 0,
        },
        {
            label: '2',
            value: 1,
        },
    ];

    const placeTypeSelector = props.placeTypes.map(placeType => {
        return {
            label: placeType.label,
            value: placeType.id,
            image: placeType.placeType.image || null,
            imageType: placeType.placeType.imageType,
        };
    });

    const controllerSelector = props.controllers.map(controller => {
        return {
            label: `${controller.label} (${controller.macStr})`,
            value: controller.id,
        };
    });

    const placesCamera = () => {
        const camera = props.cameras.find(camera => camera.getId() === props.values.id_camera);

        if (camera) {
            return camera.getPlacesCamera().map(place => {
                return {
                    label: place.getLabel(),
                    value: place.getIdPlace(),
                };
            });
        } else {
            return [];
        }
    };

    return (
        <SecuredFragment authorizedRoles={[rolesConstants.mapTCMSensors.CREATE]}>
            <Modal backdrop={'static'} show={props.show} onHide={() => props.cancelCreation()} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="map.places.createPlaces" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.error && (
                        <Message
                            className="margin-bottom-15"
                            type="error"
                            description={<FormattedMessage id="map.places.creationError" />}
                        />
                    )}
                    <Form fluid onChange={values => props.onChange(values)} formDefaultValue={props.values}>
                        {/* Number of places and distance */}
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-5">
                                <h5 className="text-purple">
                                    <FormattedMessage id="map.places.numberAndDistance" />
                                </h5>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid className="padding-top-10">
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <ControlLabel>
                                    <FormattedMessage id="map.places.nbOfPlaces" />
                                </ControlLabel>
                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faParking} />
                                    </InputGroup.Addon>
                                    <FormControl data-cy="map-nbOfPlaces" name="nbOfPlaces" type="number" />
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <ControlLabel>
                                    <FormattedMessage id="map.places.nbPlaceBetweenColumn" />
                                </ControlLabel>
                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faArrowsAltH} />
                                    </InputGroup.Addon>
                                    <FormControl
                                        data-cy="map-nbPlaceBetweenColumn"
                                        name="nbPlaceBetweenColumn"
                                        type="number"
                                    />
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <ControlLabel>
                                    <FormattedMessage id="map.places.columnWidth" />
                                </ControlLabel>
                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faColumns} />
                                    </InputGroup.Addon>
                                    <FormControl data-cy="map-columnWidth" name="columnWidth" type="number" />
                                </InputGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        {/* Types */}
                        <FlexboxGrid className="margin-top-20" style={{ backgroundColor: 'aliceblue' }}>
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-5">
                                <h5 className="text-purple">
                                    <FormattedMessage id="map.places.types" />
                                </h5>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid
                            className="padding-top-10 padding-bottom-10"
                            style={{ backgroundColor: 'aliceblue' }}>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel>
                                    <FormattedMessage id="map.places.sensorType" />
                                </ControlLabel>

                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faWifi} style={{ transform: 'rotate(180deg)' }} />
                                    </InputGroup.Addon>
                                    <FormControl
                                        data-cy="map-sensorType"
                                        accepter={SelectPicker}
                                        cleanable={false}
                                        data={sensorTypeSelector}
                                        name="sensorType"
                                        renderMenuItem={(label, item) => {
                                            return <div data-cy={`createPlace-type-value-${item.value}`}>{label}</div>;
                                        }}
                                    />
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel>
                                    <FormattedMessage id="map.places.placeType" />
                                </ControlLabel>
                                <FormControl
                                    data-cy="map-placeType"
                                    accepter={SelectPicker}
                                    cleanable={false}
                                    data={placeTypeSelector}
                                    name="placeType"
                                    renderMenuItem={(_, item) => {
                                        return (
                                            <FlexboxGrid align="middle">
                                                <FlexboxGrid.Item>
                                                    <img
                                                        alt={item.label}
                                                        style={{ height: 20 }}
                                                        src={`data:image/${item.imageType};base64, ${item.image}`}
                                                    />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item data-cy={`createPlace-placeType-value-${item.value}`}>
                                                    {item.label}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        );
                                    }}
                                    renderValue={(_, item) => {
                                        return (
                                            <FlexboxGrid align="middle">
                                                <FlexboxGrid.Item>
                                                    <img
                                                        alt={item.label}
                                                        style={{ height: 20 }}
                                                        src={`data:image/${item.imageType};base64, ${item.image}`}
                                                    />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item data-cy={`createPlace-placeType-value-${item.value}`}>
                                                    {item.label}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        {props.values.sensorType === 'place-camera' ? (
                            <>
                                {/* Camera */}
                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-5">
                                        <h5 className="text-purple">
                                            <FormattedMessage id="map.places.placeCamera" />
                                        </h5>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                <FlexboxGrid className="padding-top-10">
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <ControlLabel>
                                            <FormattedMessage id="map.places.camera" />
                                        </ControlLabel>
                                        <InputGroup style={{ marginTop: 0 }}>
                                            <InputGroup.Addon>
                                                <FontAwesomeIcon icon={faCamera} />
                                            </InputGroup.Addon>
                                            <FormControl
                                                data-cy="map-place-camera"
                                                accepter={SelectPicker}
                                                cleanable={false}
                                                data={props.cameras.map(camera => {
                                                    return {
                                                        label: camera.getName(),
                                                        value: camera.getId(),
                                                        mac: camera.getMacStr(),
                                                    };
                                                })}
                                                name="id_camera"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`createPlace-placeCamera-${item.value}`}>
                                                            {label} ({item.mac})
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </InputGroup>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <ControlLabel>
                                            <FormattedMessage id="map.places.startPlaceId" />
                                        </ControlLabel>
                                        <InputGroup style={{ marginTop: 0 }}>
                                            <InputGroup.Addon>
                                                <FontAwesomeIcon icon={faEthernet} rotate={180} />
                                            </InputGroup.Addon>
                                            <FormControl
                                                data-cy="map-idPlace"
                                                accepter={SelectPicker}
                                                cleanable={false}
                                                data={placesCamera()}
                                                name="idPlace"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`createPlace-ipPlace-${item.value}`}>{label}</div>
                                                    );
                                                }}
                                            />
                                        </InputGroup>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </>
                        ) : (
                            <>
                                {/* ID on bus */}
                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-5">
                                        <h5 className="text-purple">
                                            <FormattedMessage id="map.places.controller" />
                                        </h5>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                <FlexboxGrid className="padding-top-10">
                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                        <ControlLabel>
                                            <FormattedMessage id="map.places.controller" />
                                        </ControlLabel>
                                        <InputGroup style={{ marginTop: 0 }}>
                                            <InputGroup.Addon>
                                                <FontAwesomeIcon icon={faServer} rotate={180} />
                                            </InputGroup.Addon>
                                            <FormControl
                                                data-cy="map-controller"
                                                accepter={SelectPicker}
                                                cleanable={false}
                                                data={controllerSelector}
                                                name="controller"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`createPlace-controller-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </InputGroup>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={4}>
                                        <ControlLabel>
                                            <FormattedMessage id="map.places.bus" />
                                        </ControlLabel>
                                        <InputGroup style={{ marginTop: 0 }}>
                                            <InputGroup.Addon>
                                                <FontAwesomeIcon icon={faEthernet} rotate={180} />
                                            </InputGroup.Addon>
                                            <FormControl
                                                data-cy="map-bus"
                                                accepter={SelectPicker}
                                                cleanable={false}
                                                data={busSelector}
                                                name="bus"
                                                renderMenuItem={(label, item) => {
                                                    return <div data-cy={`createPlace-bus-${item.value}`}>{label}</div>;
                                                }}
                                            />
                                        </InputGroup>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={6}>
                                        <ControlLabel>
                                            <FormattedMessage id="map.places.id" />
                                        </ControlLabel>
                                        <InputGroup style={{ marginTop: 0 }}>
                                            <InputGroup.Addon>
                                                <FontAwesomeIcon icon={faNetworkWired} rotate={180} />
                                            </InputGroup.Addon>
                                            <FormControl data-cy="map-id" type="number" name="id" />
                                        </InputGroup>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={2} />
                                    <FlexboxGrid.Item componentClass={Col} xs={4}>
                                        <ControlLabel>
                                            <FormattedMessage id="map.places.increment" />
                                        </ControlLabel>
                                        <InputGroup style={{ marginTop: 0 }}>
                                            <InputGroup.Addon>
                                                <FontAwesomeIcon icon={faPlus} rotate={180} />
                                            </InputGroup.Addon>
                                            <FormControl type="number" name="increment" />
                                        </InputGroup>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </>
                        )}

                        {/* Naming */}
                        <FlexboxGrid className="margin-top-20" style={{ backgroundColor: 'aliceblue' }}>
                            <FlexboxGrid.Item componentClass={Col} xs={24}>
                                <h5 className="text-purple">
                                    <FormattedMessage id="map.places.naming" />
                                </h5>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid
                            className="padding-top-10 padding-bottom-10"
                            style={{ backgroundColor: 'aliceblue' }}>
                            <FlexboxGrid.Item componentClass={Col} xs={6}>
                                <ControlLabel>
                                    <FormattedMessage id="map.places.prefix" />
                                </ControlLabel>
                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faStepBackward} />
                                    </InputGroup.Addon>
                                    <FormControl data-cy="map-prefix" name="prefix" />
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={6}>
                                <ControlLabel>
                                    <FormattedMessage id="map.places.prefixNumber" />
                                </ControlLabel>
                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faTag} />
                                    </InputGroup.Addon>
                                    <FormControl data-cy="map-prefixNumber" name="prefixNumber" type="number" />
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={6}>
                                <ControlLabel>
                                    <FormattedMessage id="map.places.suffix" />
                                </ControlLabel>
                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faStepForward} />
                                    </InputGroup.Addon>
                                    <FormControl data-cy="map-suffix" name="suffix" />
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={2} />
                            <FlexboxGrid.Item componentClass={Col} xs={4}>
                                <ControlLabel>
                                    <FormattedMessage id="map.places.prefixIncrement" />
                                </ControlLabel>
                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </InputGroup.Addon>
                                    <FormControl type="number" name="prefixIncrement" />
                                </InputGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={() => props.cancelCreation()} data-cy="map-placeType-cancel" color="red">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={() => props.validCreation()}
                            data-cy="map-placeType-valid"
                            loading={props.loading}
                            color="green">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </SecuredFragment>
    );
};
