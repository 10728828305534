import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Whisper } from 'rsuite';

export default function placeCameraUpdateGeojson() {
    return (
        <div data-cy="history-placeCameraUpdateGeojson">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.placeCameraUpdateGeojson" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'green' }} size="lg" />
            </Whisper>
        </div>
    );
}
