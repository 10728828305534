import { LatLngExpression } from 'leaflet';
import TCMSensorCalibration from '../ipCanDevices/Sensor/TCMSensorCalibration';
import { TCMSensorEventForceSensor } from '../ipCanDevices/Sensor/TCMSensorEventForceSensor';
import TCMSensor from '../ipCanDevices/TCMSensor';
import { Scene } from '../scene/Scene';
import { MapSensorBase } from './MapSensorBase';

export class MapTCMSensor extends MapSensorBase {
    private _sensor: TCMSensor;

    constructor(data, sensorPlaceType) {
        super(data, sensorPlaceType);

        this._sensor = new TCMSensor(data.tcmSensor, data.tcmSensor.ipcanmodule.id, null, null);
    }

    public get sensor() {
        return this._sensor;
    }

    public setSensor(sensor_) {
        this._sensor.webSocketUpdate(sensor_);
    }

    public updateSensor(sensor_, sensorPlaceType_) {
        this.setLabel(sensor_.label);
        this.setUpdatedAt(sensor_.updatedAt);
        this.setUpdatedBy(sensor_.updatedBy);
        this.setGeoJSON(sensor_.geoJSON);
        this.setSensorPlaceType(sensorPlaceType_);
        this._sensor.updateSensor(sensor_.tcmSensor);
    }

    public updateSensorCalibration(sensor_) {
        this._sensor.updateSensorCalibration(sensor_.calibration);
    }

    public getMapSensor(): TCMSensorMapInformation_I {
        return {
            detectionState: this._sensor.detectionState,
            detectionStateTime: this._sensor.detectionStateTimeFormatted,
            online: this._sensor.online,
            label: this.label,
            ipCanId: this._sensor.ipCanId,
            bus: this._sensor.bus,
            deviceId: this._sensor.deviceId,
            lastOnlineStateDate: this._sensor.lastOnlineStateDateFormatted,
            eventForce: this._sensor.eventForce,
            geoJSON: this.geoJSON,
            isForce: this._sensor.isForce,
            id: this._sensor.id,
            forceType: this._sensor.forceType,
            sensorPlaceType: this._sensor.sensorPlaceType.id,
            calibration: this._sensor.calibration,
            type: 'tcm',
            mapSensorId: this.id,
            scenes: this.getScenes(),
            endForceTime: this._sensor.endForceTime,
            startForceTime: this._sensor.startForceTime,
            tcmSensor: this._sensor,
        };
    }
}

export interface TCMSensorMapInformation_I {
    detectionState: number;
    detectionStateTime: string;
    online: boolean;
    label: string;
    ipCanId: number;
    bus: number;
    deviceId: number;
    lastOnlineStateDate: string;
    eventForce: TCMSensorEventForceSensor;
    geoJSON: LatLngExpression;
    isForce: boolean;
    id: number;
    forceType: number;
    sensorPlaceType: number;
    calibration: TCMSensorCalibration;
    type: string;
    mapSensorId: number;
    scenes: [Scene, Scene, Scene, Scene];
    endForceTime: Date;
    startForceTime: Date;
    tcmSensor: TCMSensor;
}
