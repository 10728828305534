import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function UpdateKeyTextConfig(option) {
    return (
        <div>
            <div data-cy="lpMatrixDisplayV2-historic-lang">
                <FormattedMessage id="lpMatrixDisplayV2.historic.openForMore" />
            </div>
        </div>
    );
}
