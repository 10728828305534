import { faHistory } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Panel } from 'rsuite';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';
import HistoryTable from '../../../../ReactDataTableComponent/HistoryTable';
import ActivityTypeCell from './ActivityTypeCell';

type Props = {
    levelId: number;
} & WrappedComponentProps;

type State = {
    loading: boolean;
    data: Array<any>;
    // defaultFetch: number;
};

const uiTimeout = 500;
let refreshUi;

class ActivityTable extends React.Component<Props, State> {
    columns: TableColumn<RowRecord>[];
    sensorHistoric: Array<any>;
    constructor(props) {
        super(props);

        this.sensorHistoric = [];

        this.state = {
            loading: true,
            data: [],
            // defaultFetch: 25,
        };

        this.sensorEvent = this.sensorEvent.bind(this);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'map.sensorHistoric.labelPlace',
                }),
                width: '185px',
                cell: row => row.labelPlace,
                sortable: true,
                selector: 'labelPlace',
                center: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'map.sensorHistoric.label',
                }),
                width: '185px',
                cell: row => row.label,
                sortable: true,
                selector: 'label',
                center: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'map.sensorHistoric.date',
                }),
                width: '185px',
                // sortable: true,
                cell: row => row.date,
                sortable: true,
                selector: 'date',
                center: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'map.sensorHistoric.state',
                }),
                width: '185px',
                cell: row => ActivityTypeCell(row),
                sortable: true,
                selector: 'state',
                center: true,
            },
        ];
    }

    componentDidMount() {
        // Websockets
        webSocketService.joinRoom('engine');
        webSocketService.onEvent('engine:sensorEvent', this.sensorEvent);
        // Fetch data from API
        this.fetchData();
    }

    fetchData = () => {
        this.setState({
            loading: true,
        });

        axiosService
            .getAxios()
            .get('/engine/sensorsHistoric', { headers: authHeader() })
            .then(sensorHistoricResponse => sensorHistoricResponse.data)
            .then(sensorHistoricData => sensorHistoricData.map(data => this.sensorHistoricHandler(data)))
            .then(sensorHistoric => {
                this.sensorHistoric = sensorHistoric.reverse();
                // this.sensorHistoric = this.sensorHistoric.slice(0, this.state.defaultFetch);

                this.setState({
                    loading: false,
                    data: this.sensorHistoric,
                });
            });
    };

    componentWillUnmount() {
        webSocketService.offEvent('engine:sensorEvent', this.sensorEvent);
    }

    sensorEvent(data) {
        const newActivity = this.sensorHistoricHandler(data);
        let sensorHistoric = [...this.sensorHistoric];

        sensorHistoric.unshift(newActivity);

        // Assign the array to the object
        this.sensorHistoric = sensorHistoric;

        // Refresh UI every `uiTimeout` ms
        if (!refreshUi) {
            refreshUi = setTimeout(() => {
                this.setState(
                    {
                        data: sensorHistoric,
                    },
                    () => (refreshUi = null)
                );
            }, uiTimeout);
        }
    }

    sensorHistoricHandler(data) {
        return {
            id: `${data.labelPlace}-${data.label}-${data.date}-${data.state}`,
            bus: data.bus,
            ipCanModuleId: data.ipCanModuleId,
            state: data.state,
            value: data.value,
            date: moment(data.date).format('DD/MM/YYYY HH:mm:ss'),
            labelPlace: data.labelPlace,
            label: data.label,
            levelId: data.levelId,
            levelString: data.levelString,
            key: `${data.labelPlace}-${data.label}-${data.date}-${data.state}`,
        };
    }

    render() {
        return (
            <Panel
                className="panel-small"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        icon={faHistory}
                        title={this.props.intl.formatMessage({ id: 'map.activity.history' })}
                        buttons={[]}
                    />
                }>
                <HistoryTable
                    dense
                    columns={this.columns}
                    data={this.state.data}
                    progressPending={this.state.loading}
                />
            </Panel>
        );
    }
}

export default injectIntl(ActivityTable);
