import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { FlexboxGrid, List, Panel, PanelGroup } from 'rsuite';
import { Profile } from '../../../../../../handlers/profile/Profile';

type Props = {
    profile: Profile;
};

export const ViewProfileRolesPart = (props: Props) => {
    const intl = useIntl();

    const currentRoles = props.profile.getRoles().reduce((previous, current) => {
        previous[current.getSection()] = previous[current.getSection()] || [];
        previous[current.getSection()].push(current);
        return previous;
    }, Object.create(null));

    return (
        <PanelGroup style={{ borderRadius: 0 }}>
            {Object.keys(currentRoles).map(role => {
                return (
                    <Panel
                        className="rs-panel-collapsible-no-carret panel-roles panel-small no-radius margin-bottom-0"
                        bodyFill
                        header={
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item>{role}</FlexboxGrid.Item>
                            </FlexboxGrid>
                        }>
                        <List>
                            {currentRoles[role].map(currentRole => (
                                <List.Item
                                    key={`${role}-${currentRole.name}`}
                                    className="margin-left-5 margin-right-5 list-profile-role">
                                    <FlexboxGrid align="middle" justify="space-between">
                                        <FlexboxGrid.Item>{currentRole.name}</FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            <FontAwesomeIcon icon={faCheck} color="green" size="lg" />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            ))}
                        </List>
                    </Panel>
                );
            })}
        </PanelGroup>
    );
};
