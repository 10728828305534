import { ArrowHeadType, EdgeProps, getBezierPath, getMarkerEnd, NodeComponentProps } from 'react-flow-renderer';
import { LPMatrixDisplayChartTopologyVirtualDisplayEdgeType } from '../LPMatrixDisplayChartTopologyVirtualDisplayEdge';

type Props = NodeComponentProps<LPMatrixDisplayChartTopologyVirtualDisplayEdgeType> & EdgeProps;

export const LPMatrixDisplayChartVirtualDisplayStepEdgeComponent = (props: Props) => {
    const edgePath = getBezierPath({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        sourcePosition: props.sourcePosition,
        targetX: props.targetX,
        targetY: props.targetY,
        targetPosition: props.targetPosition,
    });

    const markerEnd = getMarkerEnd(ArrowHeadType.Arrow, props.markerEndId);

    return (
        <path
            id={props.id}
            style={{ stroke: props.data.color, strokeWidth: 3 }}
            className="react-flow__edge-path"
            d={edgePath}
            markerEnd={markerEnd}
        />
    );
};
