import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Drawer } from 'rsuite';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import { TCMSensorMapInformation_I } from '../../../../../handlers/map/MapTCMSensor';
import { SensorPlaceType } from '../../../../../handlers/sensorPlaceType/SensorPlaceType';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import { DeviceStateInformation } from '../../../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../../../IpCanElementsPage/Cards/DeviceStateInformation/deviceStateInformationGenerator';
import ColorCard from '../../../../IpCanElementsPage/Drawer/Sensor/ColorCard';
import ConfigurationCard from '../../../../IpCanElementsPage/Drawer/Sensor/ConfigurationCard';
import TimeCard from '../../../../IpCanElementsPage/Drawer/Sensor/TimeCard';
import CalibrationPart from './CalibrationPart';
import EditPlacePart from './EditPlacePart';

type Props = {
    show: boolean;
    onHide: Function;
    sensor: TCMSensorMapInformation_I;
    controllers: IpCan[];
    placeTypes: SensorPlaceType[];
    service: any;
} & WrappedComponentProps;

type State = {
    fetchingDatabase: boolean;
    fetchDatabaseError: boolean;
    databaseData: Record<string, any> | undefined;
    isCheckingDatabase: boolean;
};

class TCMSensorPlaceDrawer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fetchingDatabase: false,
            fetchDatabaseError: false,
            databaseData: undefined,
            isCheckingDatabase: false,
        };
    }

    hideDrawer = () => {
        this.props.onHide();
    };

    setCheckingDatabase = () => {
        this.setState({
            fetchingDatabase: true,
        });

        axiosService
            .getAxios()
            .get(`/devices/tcm-sensor/config/${this.props.sensor.id}`, { headers: authHeader() })
            .then(tcmSensorConfigResponse => {
                this.setState({
                    databaseData: tcmSensorConfigResponse.data,
                    isCheckingDatabase: true,
                });
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    fetchDatabaseError: true,
                });
            })
            .finally(() => {
                this.setState({
                    fetchingDatabase: false,
                });
            });
    };

    render() {
        const currentIpCan = this.props.controllers.find(controller => controller.id === this.props.sensor.ipCanId);

        return (
            <Drawer backdrop={false} show={this.props.show} onHide={this.hideDrawer}>
                <Drawer.Header>
                    <Drawer.Title>
                        {currentIpCan?.label} - {this.props.sensor.bus + 1} - {this.props.sensor.deviceId}
                    </Drawer.Title>
                </Drawer.Header>

                <Drawer.Body>
                    <EditPlacePart
                        sensor={this.props.sensor}
                        controllers={this.props.controllers}
                        placeTypes={this.props.placeTypes}
                        currentIpCan={currentIpCan}
                        service={this.props.service}
                    />

                    <DeviceStateInformation
                        device={this.props.sensor.tcmSensor}
                        editMaintenanceStateUrl={MaintenanceStateInformationEditURL.TCM_SENSOR}
                    />

                    <ConfigurationCard
                        config={this.props.sensor.tcmSensor.config}
                        databaseData={this.state.databaseData}
                        isCheckingDatabase={this.state.isCheckingDatabase}
                    />

                    <ColorCard
                        isCheckingDatabase={this.state.isCheckingDatabase}
                        sensor={this.props.sensor.tcmSensor}
                        databaseData={this.state.databaseData}
                    />

                    <TimeCard
                        isCheckingDatabase={this.state.isCheckingDatabase}
                        sensor={this.props.sensor.tcmSensor}
                        databaseData={this.state.databaseData}
                    />

                    <CalibrationPart sensor={this.props.sensor} service={this.props.service} />
                </Drawer.Body>
            </Drawer>
        );
    }
}

export default injectIntl(TCMSensorPlaceDrawer);
