export const colorsValues = {
    0: {
        value: 0,
        color: 'transparent',
        secondColor: 'transparent',
    },
    1: {
        value: 1,
        color: '#43A047',
        secondColor: '#43A047',
    },
    2: {
        value: 2,
        color: '#E53935',
        secondColor: '#E53935',
    },
    3: {
        value: 3,
        color: '#000',
        secondColor: '#000',
    },
    4: {
        value: 4,
        color: '#43A047',
        secondColor: 'transparent',
    },
    5: {
        value: 5,
        color: '#E53935',
        secondColor: 'transparent',
    },
    6: {
        value: 6,
        color: '#FB8C00',
        secondColor: 'transparent',
    },
    7: {
        value: 7,
        color: '#E53935',
        secondColor: '#43A047',
    },
};

export const colorsPicker = [
    {
        label: 'const.colors.noColor',
        value: 0,
    },
    {
        label: 'const.colors.green',
        value: 1,
    },
    {
        label: 'const.colors.red',
        value: 2,
    },
    {
        label: 'const.colors.black',
        value: 3,
    },
    {
        label: 'const.colors.greenBlink',
        value: 4,
    },
    {
        label: 'const.colors.redBlink',
        value: 5,
    },
    {
        label: 'const.colors.redGreenBlink',
        value: 7,
    },
];

export const tabColor = [
    '#000000',
    '#000055',
    '#0000AA',
    '#0000FF',
    '#005500',
    '#005555',
    '#0055AA',
    '#0055FF',
    '#00AA00',
    '#00AA55',
    '#00AAAA',
    '#00AAFF',
    '#00FF00',
    '#00FF55',
    '#00FFAA',
    '#00FFFF',
    '#550000',
    '#550055',
    '#5500AA',
    '#5500FF',
    '#555500',
    '#555555',
    '#5555AA',
    '#5555FF',
    '#55AA00',
    '#55AA55',
    '#55AAAA',
    '#55AAFF',
    '#55FF00',
    '#55FF55',
    '#55FFAA',
    '#55FFFF',
    '#AA0000',
    '#AA0055',
    '#AA00AA',
    '#AA00FF',
    '#AA5500',
    '#AA5555',
    '#AA55AA',
    '#AA55FF',
    '#AAAA00',
    '#AAAA55',
    '#AAAAAA',
    '#AAAAFF',
    '#AAFF00',
    '#AAFF55',
    '#AAFFAA',
    '#AAFFFF',
    '#FF0000',
    '#FF0055',
    '#FF00AA',
    '#FF00FF',
    '#FF5500',
    '#FF5555',
    '#FF55AA',
    '#FF55FF',
    '#FFAA00',
    '#FFAA55',
    '#FFAAAA',
    '#FFAAFF',
    '#FFFF00',
    '#FFFF55',
    '#FFFFAA',
    '#FFFFFF',
    '#000000',
    '#040404',
    '#080808',
    '#0C0C0C',
    '#101010',
    '#141414',
    '#181818',
    '#1C1C1C',
    '#202020',
    '#242424',
    '#282828',
    '#2C2C2C',
    '#303030',
    '#343434',
    '#383838',
    '#3C3C3C',
    '#404040',
    '#444444',
    '#484848',
    '#4C4C4C',
    '#505050',
    '#555555',
    '#595959',
    '#5D5D5D',
    '#616161',
    '#656565',
    '#696969',
    '#6D6D6D',
    '#717171',
    '#757575',
    '#797979',
    '#7D7D7D',
    '#818181',
    '#858585',
    '#898989',
    '#8D8D8D',
    '#919191',
    '#959595',
    '#999999',
    '#9D9D9D',
    '#A1A1A1',
    '#A5A5A5',
    '#AAAAAA',
    '#AEAEAE',
    '#B2B2B2',
    '#B6B6B6',
    '#BABABA',
    '#BEBEBE',
    '#C2C2C2',
    '#C6C6C6',
    '#CACACA',
    '#CECECE',
    '#D2D2D2',
    '#D6D6D6',
    '#DADADA',
    '#DEDEDE',
    '#E2E2E2',
    '#E6E6E6',
    '#EAEAEA',
    '#EEEEEE',
    '#F2F2F2',
    '#F6F6F6',
    '#FAFAFA',
    '#FFFFFF',
];

export const LPVirtualDisplayColors = [
    '#AD9CE6',
    '#3ACA29',
    '#4b97e8',
    '#336600',
    '#9161ce',
    '#e9b344',
    '#bade0a',
    '#39c8f9',
    '#9fb008',
    '#7d1a33',
    '#10e498',
    '#394290',
    '#a088ce',
    '#dc81ec',
    '#1a693d',
    '#0539f1',
];
