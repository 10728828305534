import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal } from 'rsuite';
import { parkingActions } from '../../../redux/actions';

const mapDispatchToProps = dispatch => ({
    closeCreateParkingModal: () => dispatch(parkingActions.closeCreateModal()),
    createParking: (name, description) => dispatch(parkingActions.create(name, description)),
});

type Props = {
    createParking: Function;
    isLoading: boolean;
    closeCreateParkingModal: Function;
    create_modal_openned: boolean;
} & WrappedComponentProps;

type State = {
    loading: boolean;
    formValue: Record<string, any>;
};
class CreateParkingModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            formValue: {
                name: '',
                description: '',
            },
        };

        this.valid = this.valid.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    valid() {
        let { name, description } = this.state.formValue;

        if (description === '') description = ' ';

        this.setState({
            loading: true,
        });
        this.props.createParking(name, description);
        this.setState({
            formValue: {
                name: '',
                description: '',
            },
        });
    }

    hideModal() {
        if (!this.props.isLoading) {
            this.setState({
                formValue: {
                    name: '',
                    description: '',
                },
            });

            this.props.closeCreateParkingModal();
        }
    }

    render() {
        return (
            <Modal backdrop={'static'} show={this.props.create_modal_openned} onHide={this.hideModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="parking.create.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="parking-addParking-modal">
                    <Form fluid formValue={this.state.formValue} onChange={formValue => this.setState({ formValue })}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="parking.create.name" />
                            </ControlLabel>
                            <FormControl name="name" data-cy="parking-add-name" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="parking.create.description" />
                            </ControlLabel>
                            <FormControl name="description" data-cy="parking-add-description" />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={this.hideModal}
                            data-cy="parking-addParking-modal-cancel"
                            color="red"
                            loading={this.props.isLoading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.valid}
                            data-cy="parking-addParking-modal-create"
                            appearance="primary"
                            loading={this.props.isLoading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const { create_modal_openned, isLoading } = state.parkings;

    return { create_modal_openned, isLoading };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateParkingModal));
