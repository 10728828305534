import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KonvaEventObject } from 'konva/lib/Node';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layer, Rect, Stage } from 'react-konva';
import { Alert, Button, ButtonGroup, FlexboxGrid, Modal } from 'rsuite';
import { DisplayPreviewInformation } from '../../../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../../../handlers/lpDisplays/VirtualDisplay';
import { authHeader } from '../../../../../../../redux/helpers';
import { axiosService } from '../../../../../../../redux/services';
import { findLastIndex } from '../../../../../../../utils/findLastIndex';

const PIXEL_X = 16;
const PIXEL_Y = 16;

type Props = {
    virtualDisplay: VirtualDisplayPreviewInformation;
    display: DisplayPreviewInformation;
    show: boolean;
    onHide: Function;
    updateInformation: Function;
};

interface CoordInterface {
    selected: boolean;
}

const EditVirtualDisplayShapeModal = ({ show, onHide, virtualDisplay, display, updateInformation }: Props) => {
    const [coordArray, setCoordArray] = React.useState<CoordInterface[][]>([]);
    const [isEditing, setIsEditing] = React.useState<boolean>(false);

    const intl = useIntl();

    useEffect(() => {
        let squareArray: CoordInterface[][] = [];
        for (let i = 0; i < display.nbMatrixY; i++) {
            let displays: CoordInterface[] = [];
            for (let j = 0; j < display.nbMatrixX * 2; j++) {
                displays.push({
                    selected: false,
                });
            }
            squareArray[i] = displays;
        }
        const indexStartX = virtualDisplay.formattedStartX;
        const indexStartY = virtualDisplay.formattedStartY;
        const sizeX = virtualDisplay.formattedSizeX;
        const sizeY = virtualDisplay.formattedSizeY;
        for (let i = indexStartY; i < indexStartY + sizeY; i++) {
            for (let j = indexStartX; j < indexStartX + sizeX; j++) {
                if (i <= display.nbMatrixY && j <= display.nbMatrixX * 2 && squareArray[i] && squareArray[i][j]) {
                    squareArray[i][j].selected = true;
                }
            }
        }

        setCoordArray(squareArray);
    }, [virtualDisplay]);

    const handleClick = (event: KonvaEventObject<MouseEvent>) => {
        const indexY = parseInt(event.target.attrs.id);
        const indexX = parseInt(event.target.attrs.name);

        const squareArray = [...coordArray];

        squareArray[indexY][indexX].selected = !squareArray[indexY][indexX].selected;

        setCoordArray(squareArray);
    };

    const handleEditVirtualDisplay = () => {
        if (isRectangle()) {
            setIsEditing(true);

            let firstIsKnown = false;
            let coordFirstSquare = { x: 0, y: 0 };
            let sizeX = 0;
            let sizeY = 0;

            for (let i = 0; i < coordArray.length; i++) {
                if (!firstIsKnown) {
                    for (let j = 0; j < coordArray[i].length; j++) {
                        if (coordArray[i][j].selected && !firstIsKnown) {
                            coordFirstSquare = { x: j * PIXEL_X, y: i * PIXEL_Y };
                            firstIsKnown = true;
                        }
                    }
                }

                if (coordArray[i].findIndex(line => line.selected) !== -1) {
                    sizeY++;
                }
            }

            for (let display in coordArray[coordFirstSquare.y / PIXEL_Y]) {
                if (coordArray[coordFirstSquare.y / PIXEL_Y][display].selected) {
                    sizeX++;
                }
            }

            axiosService
                .getAxios()
                .put(
                    '/devices/lp-matrixdisplays-v2/virtualDisplay/updateConfig',
                    {
                        backgroundColor: virtualDisplay.backgroundColorRGB,
                        startX: coordFirstSquare.x,
                        startY: coordFirstSquare.y,
                        sizeX: sizeX * PIXEL_X,
                        sizeY: sizeY * PIXEL_Y,
                        font: virtualDisplay.font,
                        language: virtualDisplay.lang,
                        arrowSpeed: virtualDisplay.arrowSpeed,
                        textSpeed: virtualDisplay.textSpeed,
                        state: virtualDisplay.state,
                        id: virtualDisplay.id,
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(updateVirtualDisplayResponse => {
                    // display.updateVirtualDisplay(updateVirtualDisplayResponse.data);

                    updateInformation(updateVirtualDisplayResponse.data);

                    setIsEditing(false);

                    Alert.success(intl.formatMessage({ id: 'ipCanDevices.lpDisplays.virtualDisplays.update.success' }));

                    onHide(true);
                })
                .catch(err => {
                    console.error(err);

                    setIsEditing(false);

                    Alert.error(
                        intl.formatMessage({ id: 'ipCanDevices.lpDisplays.virtualDisplays.update.error' }, { err })
                    );
                });
        } else {
            Alert.error(intl.formatMessage({ id: 'ipCanDevices.lpDisplays.virtualDisplays.notARectangleError' }));
        }
    };

    const isRectangle = (): boolean => {
        const coordArrayFlat = coordArray.flat();
        const nbOfElementsActive = coordArrayFlat.filter(display => display.selected).length;
        /**
         * Case no active semi-display
         */
        if (nbOfElementsActive === 0) return false;
        /**
         * Case only one semi-display is selected
         **/
        if (nbOfElementsActive === 1) return true;
        /**
         * Case all semi-diplays are selected
         */
        if (nbOfElementsActive === coordArrayFlat.length) return true;
        let linesWithActiveSemiDisplay: number[] = [];
        /**
         * Check if elements in lines are packed
         */
        for (let i = 0; i < coordArray.length; i++) {
            // Loop over lines
            let currentLine = coordArray[i];
            const firstLineEntry = currentLine.findIndex(column => column.selected);
            if (firstLineEntry !== -1) {
                linesWithActiveSemiDisplay.push(i);
                const lastLineEntry = findLastIndex(currentLine, column => column.selected);
                for (let k = firstLineEntry + 1; k < lastLineEntry; k++) {
                    // Loop over found index to check if every case is active
                    if (!currentLine[k].selected) return false;
                }
            }
        }
        /**
         * Case only one line
         */
        if (linesWithActiveSemiDisplay.length === 1) return true;
        let arrayToCompare: number[] = [];
        /**
         * Generate the comparision base case with the very first line
         */
        for (let i = 0; i < coordArray[linesWithActiveSemiDisplay[0]].length; i++) {
            const semiVirtualDisplay = coordArray[linesWithActiveSemiDisplay[0]][i];
            if (semiVirtualDisplay.selected) {
                arrayToCompare.push(i);
            }
        }
        /**
         * Check any other lines to correspond with base
         */
        for (let i = 1; i < linesWithActiveSemiDisplay.length; i++) {
            let currentArrayCompared: number[] = [];
            let currentLineNumber = linesWithActiveSemiDisplay[i];
            if (currentLineNumber - linesWithActiveSemiDisplay[i - 1] !== 1) {
                return false;
            }
            for (let j = 0; j < coordArray[currentLineNumber].length; j++) {
                if (coordArray[currentLineNumber][j].selected) {
                    currentArrayCompared.push(j);
                }
            }
            /**
             * Lines in array are not all the same
             */
            if (JSON.stringify(arrayToCompare) !== JSON.stringify(currentArrayCompared)) {
                return false;
            }
        }
        /**
         * Lines in array are all the same as base
         */
        return true;
    };

    const MATRIX_WIDTH = 100;
    const MATRIX_HEIGHT = 100;
    const SEPARATOR_WIDTH = 6;
    const SEMI_SEPARATOR_WIDTH = SEPARATOR_WIDTH / 3;

    const stageWidth =
        (display.nbMatrixX + 1) * SEPARATOR_WIDTH +
        display.nbMatrixX * SEMI_SEPARATOR_WIDTH +
        display.nbMatrixX * 2 * MATRIX_WIDTH;

    const stageHeight = display.nbMatrixY * MATRIX_HEIGHT + (display.nbMatrixY + 1) * SEPARATOR_WIDTH;

    return (
        <Modal full show={show} onHide={() => onHide()} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="configuration.virtualDisplay.editShape" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexboxGrid align="middle" justify="center">
                    <div data-cy="configuration-virtualDisplay-editShape">
                        <FlexboxGrid.Item>
                            <Stage width={stageWidth} height={stageHeight}>
                                <Layer>
                                    <Rect width={stageWidth} height={stageHeight} x={0} y={0} fill="#000" />
                                </Layer>
                                <Layer>
                                    {coordArray.map((displays, indexY) => {
                                        let previousX = SEPARATOR_WIDTH;
                                        return displays.map((disp, indexX) => {
                                            let x;
                                            if (indexX > 0) {
                                                x = previousX + MATRIX_WIDTH;

                                                if (indexX % 2 !== 0) {
                                                    x += SEMI_SEPARATOR_WIDTH;
                                                } else {
                                                    x += SEPARATOR_WIDTH;
                                                }

                                                previousX = x;
                                            } else {
                                                x = previousX;
                                            }

                                            let y = indexY * MATRIX_HEIGHT + (indexY + 1) * SEPARATOR_WIDTH;

                                            return (
                                                <Rect
                                                    key={`${indexX}_${indexY}`}
                                                    width={MATRIX_WIDTH}
                                                    height={MATRIX_HEIGHT}
                                                    fill={disp.selected ? 'green' : 'grey'}
                                                    x={x}
                                                    y={y}
                                                    id={indexY.toString()}
                                                    name={indexX.toString()}
                                                    onClick={handleClick}
                                                />
                                            );
                                        });
                                    })}
                                </Layer>
                            </Stage>
                        </FlexboxGrid.Item>
                    </div>
                </FlexboxGrid>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={() => onHide()} color="red" disabled={isEditing}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={handleEditVirtualDisplay}
                        appearance="primary"
                        disabled={!isRectangle()}
                        loading={isEditing}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

export default EditVirtualDisplayShapeModal;
