import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Col, FlexboxGrid, Panel } from 'rsuite';

type Props = {
    sensors: Array<Record<string, any>>;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    loading: boolean;
};
class SensorsOnlineState extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    render() {
        let donutSize = '100%';
        let donutColSize = 12;

        const state = {
            total: 0,
            online: { total: 0, percent: 0 },
            offline: { total: 0, percent: 0 },
        };

        const dataDoughnut = {
            title: '',
            datasets: [
                {
                    data: [0, 0, 0],
                    backgroundColor: ['#f44336', '#008000'],
                },
            ],
            labels: [
                this.props.intl.formatMessage({ id: 'ipCanElements.sensors.state.online' }),
                this.props.intl.formatMessage({ id: 'ipCanElements.sensors.state.offline' }),
            ],
        };

        for (let s in this.props.sensors) {
            let sensor = this.props.sensors[s];
            //---
            if (!sensor.online) state.offline.total++;
            else state.online.total++;
            //---
            state.total++;
        }

        //---
        state.online.percent = parseFloat(((state.online.total / state.total) * 100).toFixed(1));
        state.offline.percent = parseFloat(((state.offline.total / state.total) * 100).toFixed(1));
        //---
        dataDoughnut.datasets[0].data[0] = state.offline.total;
        dataDoughnut.datasets[0].data[1] = state.online.total;

        return (
            <Loader show={this.props.isLoading}>
                <Panel
                    data-cy="dashboard-sensors-cardOnline"
                    className={'dashboard-card-header hoverable'}
                    header={<FormattedMessage id="sensors.cardOnline.title" />}
                    shaded>
                    <FlexboxGrid align="middle" justify="space-between" style={{ height: donutSize }}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={12}
                            xs={donutColSize}
                            style={{ height: donutSize }}>
                            <Doughnut
                                data={dataDoughnut}
                                // height={'100%'}
                                // width={donutSize}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    aspectRatio: 1,
                                    legend: {
                                        display: false,
                                    },
                                }}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} colspan={24} xs={24 - donutColSize}>
                            <div
                                data-cy="dashboard-sensors-card-online"
                                className="parking-legend-title margin-top-15"
                                style={{ color: '#008000' }}>
                                <FormattedMessage id="sensors.cardOnline.online" />
                            </div>
                            <div data-cy="dashboard-sensors-card-online-value" className="parking-legend-value">
                                {state.online.total} <FormattedMessage id="sensors.cardOnline.sensors" />
                                <span className="margin-left-5 small free">({state.online.percent}%)</span>
                            </div>

                            <div
                                data-cy="dashboard-sensors-card-offline"
                                className="parking-legend-title"
                                style={{ color: '#f44336' }}>
                                <FormattedMessage id="sensors.cardOnline.offline" />
                            </div>
                            <div data-cy="dashboard-sensors-card-offline-value" className="parking-legend-value">
                                {state.offline.total} <FormattedMessage id="sensors.cardOnline.sensors" />
                                <span className="margin-left-5 small occupied">({state.offline.percent}%)</span>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Panel>
            </Loader>
        );
    }
}

export default injectIntl(SensorsOnlineState);
