import { faFilter, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid, Tooltip, Whisper } from 'rsuite';

export default function updateUsAndFilterConfig() {
    return (
        <div data-cy="historic-icon-updateUsAndFilterConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.updateUsAndFilterConfig" />
                    </Tooltip>
                }>
                <FlexboxGrid>
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon icon={faWifi} rotation={180} size="lg" style={{ color: '#2184da' }} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon icon={faFilter} size="lg" style={{ color: '#2184da' }} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Whisper>
        </div>
    );
}
