import { LatLngExpression } from 'leaflet';
import { Alert } from 'rsuite';
import { assign, Machine } from 'xstate';
import { MapLPSensor } from '../../../handlers/map/MapLPSensor';
import { MapTCMSensor } from '../../../handlers/map/MapTCMSensor';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

interface PlaceMachineStateSchema {
    states: {
        entrypoint: {};
        createPlaceForm: {};
        createPlaces: {};
        editPlaces: {};
        deletePlaces: {};
        reload: {};
        editPlacesIdForm: {};
        editPlacesId: {};
    };
}

type PlaceMachineEvent =
    | { type: 'CREATE_PLACE' }
    | { type: 'EDIT_PLACE' }
    | { type: 'START_EDIT_PLACE_MODE' }
    | { type: 'CANCEL_EDIT_PLACE_MODE' }
    | { type: 'DELETE_PLACE' }
    | { type: 'EDIT_PLACES_FORM' }
    | { type: 'EDIT_CREATE_PLACE_FORM' }
    | { type: 'VALID_CREATE_PLACE' }
    | { type: 'CANCEL_CREATE_PLACE' }
    | { type: 'RELOADED' }
    | { type: 'EDIT_PLACE_IDS_FORM' }
    | { type: 'VALID_PLACE_IDS_FORM' }
    | { type: 'CANCEL_PLACE_IDS_FORM' };

interface PlaceMachineContext {
    createPlacesModalOpen: boolean;
    levelId: number | undefined;
    createPlaceFormValue: CreatePlaceFormValue;
    editPlaceFormValue: EditPlaceFormValue;
    editPlacesIdsFormValue: EditPlacesIdsFormValue;
    updatingPlacesId: boolean;
    updatePlacesIdError: boolean;
    geojson: Record<string, any>;
    editMode: boolean;
    creationError: boolean;
    creationErrorText: string | undefined;
    placesIdToEdit: PlaceToEdit[];
    editPlacesIdModalOpen: boolean;
    creatingPlace: boolean;
    intl: Record<string, any>;
    loading: boolean;
}

type PlaceToEdit = {
    sensor: MapLPSensor | MapTCMSensor;
    type: string;
};

type CreatePlaceFormValue = {
    nbOfPlaces: number;
    nbPlaceBetweenColumn: number;
    columnWidth: number;
    sensorType: string;
    placeType: string | null;
    controller: number | null;
    id_camera: number | null;
    idPlace: number | null;
    bus: number;
    id: number;
    increment: number;
    prefix: string | null;
    prefixNumber: string | null;
    prefixIncrement: 1;
    suffix: string | null;
    loading: boolean;
};

type EditPlaceFormValue = {
    idToMove: number;
    nameToMove: number;
};

type EditPlacesIdsFormValue = {
    offsetId: number;
    offsetLabel?: number;
};

export const PlaceMachine = Machine<PlaceMachineContext, PlaceMachineStateSchema, PlaceMachineEvent>(
    {
        id: 'places',
        initial: 'entrypoint',
        context: {
            createPlacesModalOpen: false,
            levelId: undefined,
            createPlaceFormValue: {
                nbOfPlaces: 1,
                nbPlaceBetweenColumn: 3,
                columnWidth: 45,
                sensorType: 'tcm',
                placeType: null,
                controller: null,
                id_camera: null,
                idPlace: null,
                bus: 0,
                id: 1,
                increment: 1,
                prefix: null,
                prefixNumber: null,
                prefixIncrement: 1,
                suffix: null,
                loading: false,
            },
            editPlaceFormValue: {
                idToMove: 1,
                nameToMove: 1,
            },
            editPlacesIdsFormValue: {
                offsetId: 1,
            },
            updatingPlacesId: false,
            updatePlacesIdError: false,
            geojson: {},
            editMode: false,
            creationError: false,
            creationErrorText: undefined,
            placesIdToEdit: [],
            editPlacesIdModalOpen: false,
            creatingPlace: false,
            intl: {},
            loading: false,
        },
        states: {
            entrypoint: {
                on: {
                    CREATE_PLACE: {
                        target: 'createPlaceForm',
                        actions: 'setCreatePlace',
                    },
                    EDIT_PLACE: {
                        target: 'editPlaces',
                    },
                    START_EDIT_PLACE_MODE: {
                        actions: 'setEditMode',
                    },
                    CANCEL_EDIT_PLACE_MODE: {
                        actions: 'unsetEditMode',
                    },
                    DELETE_PLACE: {
                        target: 'deletePlaces',
                    },
                    EDIT_PLACES_FORM: {
                        target: 'editPlacesIdForm',
                        actions: 'setEditPlacesForm',
                    },
                },
            },
            createPlaceForm: {
                on: {
                    EDIT_CREATE_PLACE_FORM: {
                        target: 'createPlaceForm',
                        actions: 'editCreatePlaceForm',
                    },
                    VALID_CREATE_PLACE: {
                        target: 'createPlaces',
                        actions: 'validCreatePlace',
                    },
                    CANCEL_CREATE_PLACE: {
                        target: 'entrypoint',
                        actions: 'createPlaceForm',
                    },
                },
            },
            createPlaces: {
                invoke: {
                    id: 'create-map-sensor',
                    src: (context: PlaceMachineContext, event: Record<string, any>) => () => {
                        const { levelId, createPlaceFormValue, geojson } = context;

                        let nbOfPoteaux = 0;
                        // Calculate size of poteau
                        const poteauSize = createPlaceFormValue.columnWidth * (event.calibration / event.calibrationCm);

                        // Calculate number of poteaux on the line
                        if (
                            createPlaceFormValue.nbPlaceBetweenColumn > 0 &&
                            createPlaceFormValue.nbOfPlaces > createPlaceFormValue.nbPlaceBetweenColumn
                        ) {
                            nbOfPoteaux = Math.floor(
                                createPlaceFormValue.nbOfPlaces / createPlaceFormValue.nbPlaceBetweenColumn
                            );
                        }

                        // get max coordinates (size) with lat and lng
                        let lineCoordinateMax = {
                            lat: geojson.lat1 - geojson.lat0,
                            lng: geojson.lng1 - geojson.lng0,
                        };

                        // Calculate size of the mine (pythagore)
                        let hypothenuse = Math.sqrt(
                            Math.pow(lineCoordinateMax.lat, 2) + Math.pow(lineCoordinateMax.lng, 2)
                        );

                        // Calculate number of walls on the line
                        let nbWalls = hypothenuse / poteauSize;

                        // Set poteaux movments
                        let latPoteau = lineCoordinateMax.lat / nbWalls;
                        let lngPoteau = lineCoordinateMax.lng / nbWalls;

                        let totalPlacesSize = {
                            lat: lineCoordinateMax.lat - latPoteau * nbOfPoteaux,
                            lng: lineCoordinateMax.lng - lngPoteau * nbOfPoteaux,
                        };

                        // Set place movments
                        const quantumPlaceLat = totalPlacesSize.lat / createPlaceFormValue.nbOfPlaces;
                        const quantumPlaceLng = totalPlacesSize.lng / createPlaceFormValue.nbOfPlaces;

                        let currentPoint = [geojson.lat0 + quantumPlaceLat / 2, geojson.lng0 + quantumPlaceLng / 2];

                        let points: Array<Record<string, any>> = [];

                        if (createPlaceFormValue.sensorType === 'tcm') {
                            for (let i = 1; i <= createPlaceFormValue.nbOfPlaces; i++) {
                                points.push({
                                    label: generateLabel(
                                        createPlaceFormValue.prefix,
                                        createPlaceFormValue.prefixNumber,
                                        createPlaceFormValue.prefixIncrement * (i - 1),
                                        createPlaceFormValue.suffix
                                    ),
                                    geoJSON: currentPoint,
                                    levelId,
                                    tcmSensor: {
                                        id_ipcanmodule: createPlaceFormValue.controller,
                                        id_sensorPlaceType: createPlaceFormValue.placeType,
                                        bus: createPlaceFormValue.bus,
                                        deviceId: createPlaceFormValue.id + createPlaceFormValue.increment * (i - 1),
                                    },
                                });

                                if (
                                    i % createPlaceFormValue.nbPlaceBetweenColumn === 0 &&
                                    createPlaceFormValue.nbOfPlaces > createPlaceFormValue.nbPlaceBetweenColumn
                                ) {
                                    currentPoint = [
                                        currentPoint[0] + quantumPlaceLat + latPoteau,
                                        currentPoint[1] + quantumPlaceLng + lngPoteau,
                                    ];
                                } else {
                                    currentPoint = [
                                        currentPoint[0] + quantumPlaceLat,
                                        currentPoint[1] + quantumPlaceLng,
                                    ];
                                }
                            }

                            return axiosService.getAxios().post('/map-tcm-sensors', points, { headers: authHeader() });
                        } else if (createPlaceFormValue.sensorType === 'lp') {
                            for (let i = 1; i <= createPlaceFormValue.nbOfPlaces; i++) {
                                points.push({
                                    label: generateLabel(
                                        createPlaceFormValue.prefix,
                                        createPlaceFormValue.prefixNumber,
                                        createPlaceFormValue.prefixIncrement * (i - 1),
                                        createPlaceFormValue.suffix
                                    ),
                                    geoJSON: currentPoint,
                                    levelId,
                                    lpSensor: {
                                        id_ipcanmodule: createPlaceFormValue.controller,
                                        id_sensorPlaceType: createPlaceFormValue.placeType,
                                        bus: createPlaceFormValue.bus,
                                        deviceId: createPlaceFormValue.id + createPlaceFormValue.increment * (i - 1),
                                    },
                                });

                                if (
                                    i % createPlaceFormValue.nbPlaceBetweenColumn === 0 &&
                                    createPlaceFormValue.nbOfPlaces > createPlaceFormValue.nbPlaceBetweenColumn
                                ) {
                                    currentPoint = [
                                        currentPoint[0] + quantumPlaceLat + latPoteau,
                                        currentPoint[1] + quantumPlaceLng + lngPoteau,
                                    ];
                                } else {
                                    currentPoint = [
                                        currentPoint[0] + quantumPlaceLat,
                                        currentPoint[1] + quantumPlaceLng,
                                    ];
                                }
                            }

                            return axiosService.getAxios().post('/map-lp-sensors', points, { headers: authHeader() });
                        } else if (createPlaceFormValue.sensorType === 'place-camera' && createPlaceFormValue.idPlace) {
                            for (let i = 1; i <= createPlaceFormValue.nbOfPlaces; i++) {
                                points.push({
                                    label: generateLabel(
                                        createPlaceFormValue.prefix,
                                        createPlaceFormValue.prefixNumber,
                                        createPlaceFormValue.prefixIncrement * (i - 1),
                                        createPlaceFormValue.suffix
                                    ),
                                    geoJSON: currentPoint,
                                    levelId,
                                    id_camera: createPlaceFormValue.id_camera,
                                    id_sensorPlaceType: createPlaceFormValue.placeType,
                                    idPlace: createPlaceFormValue.idPlace + (i - 1),
                                });

                                if (
                                    i % createPlaceFormValue.nbPlaceBetweenColumn === 0 &&
                                    createPlaceFormValue.nbOfPlaces > createPlaceFormValue.nbPlaceBetweenColumn
                                ) {
                                    currentPoint = [
                                        currentPoint[0] + quantumPlaceLat + latPoteau,
                                        currentPoint[1] + quantumPlaceLng + lngPoteau,
                                    ];
                                } else {
                                    currentPoint = [
                                        currentPoint[0] + quantumPlaceLat,
                                        currentPoint[1] + quantumPlaceLng,
                                    ];
                                }
                            }

                            return axiosService.getAxios().post('/map-place-camera', points, { headers: authHeader() });
                        }
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'createPlaceFormValid',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.places.create.success' })),
                        ],
                    },
                    onError: {
                        target: 'createPlaceForm',
                        actions: 'createPlaceFormError',
                    },
                },
            },
            editPlaces: {
                entry: 'setLoading',
                invoke: {
                    id: 'edit-map-place',
                    src: (_, event: Record<string, any>) => () => {
                        let tcmSensors: Array<{ id: number; type: string; geoJSON: LatLngExpression }> = [];
                        let lpSensors: Array<{ id: number; type: string; geoJSON: LatLngExpression }> = [];
                        let placeCameras: Array<{ id: number; type: string; geoJSON: LatLngExpression }> = [];

                        for (let s in event.sensors) {
                            let currentSensor = event.sensors[s];

                            if (currentSensor.type === 'tcm') {
                                tcmSensors.push(currentSensor);
                            }

                            if (currentSensor.type === 'lp') {
                                lpSensors.push(currentSensor);
                            }

                            if (currentSensor.type === 'place-camera') {
                                placeCameras.push(currentSensor);
                            }
                        }

                        const LPdata = {
                            mapLpSensorTab: lpSensors.map(sensor => {
                                return { geoJSON: sensor.geoJSON, id: sensor.id };
                            }),
                        };

                        const TCMdata = {
                            mapTcmSensorTab: tcmSensors.map(sensor => {
                                return { geoJSON: sensor.geoJSON, id: sensor.id };
                            }),
                        };

                        const PlaceCameraData = {
                            mapPlaceCameraTab: placeCameras.map(sensor => {
                                return { geoJSON: sensor.geoJSON, id: sensor.id };
                            }),
                        };

                        return axiosService
                            .getAxios()
                            .put('/map-tcm-sensors', TCMdata, { headers: authHeader() })
                            .then(() =>
                                axiosService
                                    .getAxios()
                                    .put('/map-lp-sensors', LPdata, { headers: authHeader() })
                                    .then(() => {
                                        axiosService
                                            .getAxios()
                                            .put('/map-place-camera', PlaceCameraData, { headers: authHeader() });
                                    })
                            );
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.places.edit.success' })),
                        ],
                    },
                    onError: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.error(context.intl.formatMessage({ id: 'map.places.edit.error' })),
                        ],
                    },
                },
            },
            deletePlaces: {
                entry: 'setLoading',
                invoke: {
                    id: 'delete-map-place',
                    src: (_, event: any) => () => {
                        let tcmSensors: Array<number> = [];
                        let lpSensors: Array<number> = [];
                        let placesCamera: Array<number> = [];

                        for (let s in event.sensors) {
                            let currentSensor = event.sensors[s];

                            if (currentSensor.type === 'tcm') {
                                tcmSensors.push(currentSensor.id);
                            }

                            if (currentSensor.type === 'lp') {
                                lpSensors.push(currentSensor.id);
                            }

                            if (currentSensor.type === 'place-camera') {
                                placesCamera.push(currentSensor.id);
                            }
                        }

                        const LPdata = {
                            headers: authHeader(),
                            data: { tabId: lpSensors },
                        };

                        const TCMdata = {
                            headers: authHeader(),
                            data: { tabId: tcmSensors },
                        };

                        const placeCameraData = {
                            headers: authHeader(),
                            data: { tabId: placesCamera },
                        };

                        return axiosService
                            .getAxios()
                            .delete('/map-tcm-sensors', TCMdata)
                            .then(() =>
                                axiosService
                                    .getAxios()
                                    .delete('/map-lp-sensors', LPdata)
                                    .then(() => {
                                        axiosService.getAxios().delete('/map-place-camera', placeCameraData);
                                    })
                            );
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.places.delete.success' })),
                        ],
                    },
                    onError: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.error(context.intl.formatMessage({ id: 'map.places.delete.error' })),
                        ],
                    },
                },
            },
            reload: {
                on: {
                    RELOADED: 'entrypoint',
                },
            },
            editPlacesIdForm: {
                on: {
                    EDIT_PLACE_IDS_FORM: {
                        target: 'editPlacesIdForm',
                        actions: 'updatePlaceIdsForm',
                    },
                    VALID_PLACE_IDS_FORM: {
                        target: 'editPlacesId',
                        actions: 'validPlaceIdsForm',
                    },
                    CANCEL_PLACE_IDS_FORM: {
                        target: 'entrypoint',
                        actions: 'cancelPlaceIdsForm',
                    },
                },
            },
            editPlacesId: {
                invoke: {
                    id: 'edit-map-places-id',
                    src: context => () => {
                        let placesIdToEdit = context.placesIdToEdit.sort(comparePlacesId);

                        if (context.editPlacesIdsFormValue.offsetId > 0) {
                            placesIdToEdit = placesIdToEdit.reverse();
                        } else {
                            const calc =
                                placesIdToEdit[0].sensor.sensor.deviceId + context.editPlacesIdsFormValue.offsetId;
                            if (calc < 1) {
                                return Promise.reject();
                            }
                        }

                        let counter = 0;

                        updatePlacesId(
                            placesIdToEdit,
                            context.editPlacesIdsFormValue.offsetId,
                            context.editPlacesIdsFormValue.offsetLabel,
                            context.levelId,
                            0,
                            () => {
                                counter++;
                            }
                        );

                        const editId = new Promise(resolve => {
                            let checkInterval = setInterval(() => {
                                if (counter >= placesIdToEdit.length) {
                                    clearInterval(checkInterval);
                                    resolve(null);
                                }
                            }, 500);
                        });

                        return editId;
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'updatePlacesIdSuccess',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.places.editIds.success' })),
                        ],
                    },
                    onError: {
                        target: 'editPlacesIdForm',
                        actions: 'updatePlacesIdError',
                    },
                },
            },
        },
    },
    {
        actions: {
            setCreatePlace: assign<PlaceMachineContext, PlaceMachineEvent>({
                geojson: (_, event: Record<string, any>) => event.geojson,
                createPlacesModalOpen: true,
            }),
            setEditPlacesMode: assign<PlaceMachineContext, PlaceMachineEvent>({
                editMode: true,
            }),
            unsetEditPlacesMode: assign<PlaceMachineContext, PlaceMachineEvent>({
                editMode: false,
            }),
            setEditPlacesForm: assign<PlaceMachineContext, PlaceMachineEvent>({
                editPlacesIdModalOpen: true,
                placesIdToEdit: (_, event: Record<string, any>) => event.sensors,
            }),
            editCreatePlaceForm: assign<PlaceMachineContext, PlaceMachineEvent>({
                createPlaceFormValue: (_, event: Record<string, any>) => event.value,
            }),
            validCreatePlace: assign<PlaceMachineContext, PlaceMachineEvent>({
                creatingPlace: true,
                creationError: false,
                creationErrorText: undefined,
            }),
            createPlaceForm: assign<PlaceMachineContext, PlaceMachineEvent>({
                createPlacesModalOpen: false,
                geojson: [],
            }),
            createPlaceFormValid: assign<PlaceMachineContext, PlaceMachineEvent>({
                creatingPlace: false,
                createPlacesModalOpen: false,
                createPlaceFormValue: context => setFormValue(context),
            }),
            createPlaceFormError: assign<PlaceMachineContext, PlaceMachineEvent>({
                creatingPlace: false,
                creationError: true,
                creationErrorText: (_, event: Record<string, any>) => event.data.response.data.err,
            }),
            setLoading: assign<PlaceMachineContext, PlaceMachineEvent>({
                loading: true,
            }),
            unsetLoading: assign<PlaceMachineContext, PlaceMachineEvent>({
                loading: false,
            }),
            updatePlaceIdsForm: assign<PlaceMachineContext, PlaceMachineEvent>({
                editPlacesIdsFormValue: (_, event: Record<string, any>) => event.value,
            }),
            validPlaceIdsForm: assign<PlaceMachineContext, PlaceMachineEvent>({
                updatingPlacesId: true,
                updatePlacesIdError: false,
            }),
            cancelPlaceIdsForm: assign<PlaceMachineContext, PlaceMachineEvent>({
                editPlacesIdModalOpen: false,
                geojson: [],
                editPlacesIdsFormValue: context => {
                    return {
                        ...context.editPlacesIdsFormValue,
                        offsetId: 1,
                    };
                },
                updatePlacesIdError: false,
            }),
            updatePlacesIdSuccess: assign<PlaceMachineContext, PlaceMachineEvent>({
                updatingPlacesId: false,
                editPlacesIdModalOpen: false,
                geojson: [],
                editPlacesIdsFormValue: context => {
                    return {
                        ...context.editPlacesIdsFormValue,
                        offsetId: 1,
                    };
                },
                updatePlacesIdError: false,
            }),
            updatePlacesIdError: assign<PlaceMachineContext, PlaceMachineEvent>({
                updatingPlacesId: false,
                updatePlacesIdError: true,
            }),
        },
    }
);

function generateLabel(prefix, prefixNumber, increment, suffix) {
    // Init label
    let label = '';

    const formattedPrefixNumber = parseInt(prefixNumber);

    if (prefix) {
        label += prefix;
    }

    if (!isNaN(formattedPrefixNumber)) {
        label = label + (prefixNumber + increment);
    }

    if (suffix) {
        label += suffix;
    }

    return label;
}

function setFormValue(context) {
    let id = parseInt(context.createPlaceFormValue.id);
    let nbOfPlaces = parseInt(context.createPlaceFormValue.nbOfPlaces);
    let idIncr = parseInt(context.createPlaceFormValue.increment);
    let prefixNumber = parseInt(context.createPlaceFormValue.prefixNumber);
    let prefixIncrement = parseInt(context.createPlaceFormValue.prefixIncrement);
    return {
        ...context.createPlaceFormValue,
        id: id + idIncr * nbOfPlaces,
        prefixNumber: prefixNumber + prefixIncrement * nbOfPlaces,
    };
}

type UpdatePlaceType_TYPE = {
    id: number;
    label: string;
    tcmSensor?: {
        id_ipcanmodule: number;
        id_sensorPlaceType: number;
        bus: number;
        deviceId: number;
    };
    lpSensor?: {
        id_ipcanmodule: number;
        id_sensorPlaceType: number;
        bus: number;
        deviceId: number;
    };
};

function updatePlacesId(sensors, offsetId, offsetLabel, levelId, iterator, cb) {
    const currentSensor = sensors[iterator].sensor;
    const nb_offsetId = parseInt(offsetId);
    const nb_offsetLabel = parseInt(offsetLabel);

    const findNumberRegex = /[0-9]+/g;
    const foundLabel = currentSensor.label.match(findNumberRegex);

    let label = currentSensor.label;

    if (foundLabel && foundLabel.length > 0 && !isNaN(nb_offsetLabel)) {
        label = label.replace(foundLabel[0], parseInt(foundLabel[0]) + nb_offsetLabel);
    }

    const type = sensors[iterator].type;

    if (type === 'tcm') {
        let data: UpdatePlaceType_TYPE = {
            id: currentSensor.id,
            label: label,
            tcmSensor: {
                id_ipcanmodule: currentSensor.sensor.ipCanId,
                id_sensorPlaceType: currentSensor.sensor.sensorPlaceType.id,
                bus: currentSensor.sensor.bus,
                deviceId: nb_offsetId + parseInt(currentSensor.sensor.deviceId),
            },
        };

        axiosService
            .getAxios()
            .put('/map-tcm-sensors/updateSensor', data, { headers: authHeader() })
            .then(() => {
                if (iterator < sensors.length - 1) {
                    updatePlacesId(sensors, offsetId, offsetLabel, levelId, iterator + 1, cb);
                }
                cb();
            });
    }

    if (type === 'lp') {
        let data: UpdatePlaceType_TYPE = {
            id: currentSensor.id,
            label: label,
            lpSensor: {
                id_ipcanmodule: currentSensor.sensor.ipCanId,
                id_sensorPlaceType: currentSensor.sensor.sensorPlaceType.id,
                bus: currentSensor.sensor.bus,
                deviceId: nb_offsetId + parseInt(currentSensor.sensor.deviceId),
            },
        };

        axiosService
            .getAxios()
            .put('/map-lp-sensors/updateSensor', data, { headers: authHeader() })
            .then(() => {
                if (iterator < sensors.length - 1) {
                    updatePlacesId(sensors, offsetId, offsetLabel, levelId, iterator + 1, cb);
                }
                cb();
            });
    }
}

function comparePlacesId(a, b) {
    if (a.sensor.id < b.sensor.id) {
        return -1;
    } else if (a.sensor.id > b.sensor.id) {
        return 1;
    } else {
        return 0;
    }
}
