import { VirtualDisplayStepTrigger } from '../../../../../handlers/lpDisplays/VirtualDisplayStepTrigger';
import FlowEdge from './FlowEdge';

export class FlowEdgeVirtualDisplayStepTrigger extends FlowEdge {
    private _trigger: VirtualDisplayStepTrigger;
    private _type: 'min' | 'max';

    constructor(
        source: string,
        sourceHandle: string,
        target: string,
        targetHandle: string,
        trigger: VirtualDisplayStepTrigger,
        type: 'min' | 'max',
        color: string
    ) {
        super(source, sourceHandle, target, targetHandle, color);

        this._trigger = trigger;
        this._type = type;
    }

    public toRender = () => {
        return {
            id: `${this._source}-${this._sourceHandle}-${this._target}-${this._targetHandle}`,
            source: this._source,
            sourceHandle: this._sourceHandle,
            target: this._target,
            targetHandle: this._targetHandle,
            type: 'edgeVirtualDisplayStepTrigger',
            data: {
                trigger: this._trigger,
                type: this._type,
            },
            style: {
                stroke: this._color,
                strokeWidth: 3,
            },
        };
    };
}
