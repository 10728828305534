import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Message, Modal } from 'rsuite';
import { VehicleCounterExtension } from '../../../../../handlers/vehicleCounter/VehicleCounterExtension';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    extension: VehicleCounterExtension;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isDeleting: boolean;
    hasError: boolean;
    error: any;
};

class DeleteExtensionModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isDeleting: false,
            hasError: false,
            error: null,
        };
    }

    hideModal = (shouldReload: boolean = false) => {
        if (!this.state.isDeleting) {
            this.props.onHide(shouldReload);
        }
    };

    handleDeleteExtension = () => {
        this.setState({
            isDeleting: true,
            hasError: false,
            error: null,
        });

        axiosService
            .getAxios()
            .delete('/devices/vehicle-counters/extension', {
                headers: authHeader(),
                data: { id: this.props.extension.getId() },
            })
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'passCount.extensions.delete.success' }));
                this.setState(
                    {
                        isDeleting: false,
                    },
                    () => {
                        this.hideModal(true);
                    }
                );
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                    isDeleting: false,
                });
            });
    };

    render() {
        return (
            <Modal backdrop={'static'} show={this.props.show} onHide={() => this.hideModal()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="passCount.extensions.delete.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.hasError && (
                        <Message
                            type="error"
                            description={this.props.intl.formatMessage(
                                { id: 'passCount.extensions.delete.error' },
                                { error: this.state.error }
                            )}
                        />
                    )}
                    <p>
                        <FormattedMessage
                            id="passCount.extensions.delete.message"
                            values={{ name: this.props.extension.getName() }}
                        />
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            data-cy="map-passCounter-deleteExtension-cancel"
                            onClick={() => this.hideModal()}
                            color="red"
                            disabled={this.state.isDeleting}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            data-cy="map-passCounter-deleteExtension-valid"
                            onClick={this.handleDeleteExtension}
                            appearance="primary"
                            loading={this.state.isDeleting}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(DeleteExtensionModal);
