import { faPlay, faFileExport, faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, DatePicker, FlexboxGrid, SelectPicker } from 'rsuite';

const nbElements = [
    {
        label: 25,
        value: 25,
    },
    {
        label: 50,
        value: 50,
    },
    {
        label: 100,
        value: 100,
    },
    {
        label: 250,
        value: 250,
    },
    {
        label: 500,
        value: 500,
    },
];

type Props = {
    setBefore: Function;
    setAfter: Function;
    setUser: Function;
    users: Array<Record<string, any>>;
    setNbElements: Function;
    valid: Function;
};

type State = {
    before: null | Date;
    after: null | Date;
    playDisabled: boolean;
};

export default class HistorySortPart extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            before: null,
            after: null,
            playDisabled: false,
        };

        this.setBefore = this.setBefore.bind(this);
        this.setAfter = this.setAfter.bind(this);
        this.enableValid = this.enableValid.bind(this);
    }

    setBefore(value) {
        this.setState(
            {
                before: value,
            },
            () => {
                this.enableValid();
            }
        );

        this.props.setBefore(value);
    }

    setAfter(value) {
        this.setState(
            {
                after: value,
            },
            () => {
                this.enableValid();
            }
        );

        this.props.setAfter(value);
    }

    enableValid() {
        if ((this.state.after && !this.state.before) || (!this.state.after && this.state.before)) {
            this.setState({
                playDisabled: true,
            });
        } else if (
            moment(this.state.after).isAfter(this.state.before) ||
            moment(this.state.before).isBefore(this.state.after)
        ) {
            this.setState({
                playDisabled: true,
            });
        } else {
            this.setState({
                playDisabled: false,
            });
        }
    }

    render() {
        return (
            <FlexboxGrid align="bottom" justify="center" className="margin-bottom-10">
                <FlexboxGrid.Item componentClass={Col} md={6}>
                    <p>
                        <FormattedMessage id="history.sort.user" />
                    </p>
                    <SelectPicker
                        data={this.props.users}
                        block
                        onChange={user => this.props.setUser(user)}
                        renderMenuItem={label => {
                            if (label === 'MQTT') {
                                return (
                                    <div>
                                        <FontAwesomeIcon icon={faRobot} />{' '}
                                        <FormattedMessage id="history.sort.user.automaton" />
                                    </div>
                                );
                            } else {
                                return label;
                            }
                        }}
                        renderValue={label => {
                            if (label === 'MQTT') {
                                return (
                                    <div>
                                        <FontAwesomeIcon icon={faRobot} />{' '}
                                        <FormattedMessage id="history.sort.user.automaton" />
                                    </div>
                                );
                            } else {
                                return label;
                            }
                        }}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} md={6}>
                    <p>
                        <FormattedMessage id="history.sort.after" />
                    </p>
                    <DatePicker format="DD/MM/YYYY HH:mm:ss" block onChange={value => this.setAfter(value)} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} md={6}>
                    <p>
                        <FormattedMessage id="history.sort.before" />
                    </p>
                    <DatePicker format="DD/MM/YYYY HH:mm:ss" block onChange={value => this.setBefore(value)} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <p>
                        <FormattedMessage id="history.sort.nbElements" />
                    </p>
                    <SelectPicker
                        data={nbElements}
                        searchable={false}
                        cleanable={false}
                        block
                        onChange={value => this.props.setNbElements(value)}
                        defaultValue={100}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <Button color="green" onClick={() => this.props.valid()} disabled={this.state.playDisabled}>
                        <FontAwesomeIcon icon={faPlay} />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        );
    }
}
