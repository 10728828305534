import { faCheck, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Col, ControlLabel, FlexboxGrid, Form, FormControl, Panel } from 'rsuite';
import { parkingActions } from '../../../redux/actions';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import PanelHeader from '../../Custom/PanelHeader';

const mapDispatchToProps = dispatch => ({
    updateInformations: parking => {
        if (parking.description === '') parking = ' ';
        dispatch(parkingActions.updateInformations(parking.id, parking.name, parking.description));
    },
    updateImage: (id, image) => {
        dispatch(parkingActions.updateParkingImage(id, image));
    },
});

type Props = {
    parking: Record<string, any>;
    updateInformations: Function;
    updateImage: Function;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    formValue: Record<string, any>;
    editMode: boolean;
};
class ParkingInformations extends React.Component<Props, State> {
    fileInput: any;
    constructor(props) {
        super(props);

        this.state = {
            formValue: { ...this.props.parking },
            editMode: false,
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.unsetEditMode = this.unsetEditMode.bind(this);
        this.cancelEditMode = this.cancelEditMode.bind(this);
        this.setFormValue = this.setFormValue.bind(this);
        this.validEdition = this.validEdition.bind(this);
        this.validImage = this.validImage.bind(this);
        this.fileInput = React.createRef();
    }

    setEditMode() {
        this.setState({ editMode: true });
    }

    unsetEditMode() {
        this.setState({ editMode: false });
    }

    cancelEditMode() {
        this.setState({ editMode: false, formValue: this.props.parking });
        this.unsetEditMode();
    }

    validEdition() {
        this.unsetEditMode();
        this.props.updateInformations(this.state.formValue);
    }

    validImage() {
        this.unsetEditMode();
        this.props.updateImage(this.props.parking.id, this.fileInput.current.files[0]);
    }

    setFormValue(formValue) {
        this.setState({ formValue: formValue });
    }

    render() {
        return (
            <Loader show={this.props.isLoading} message="loading">
                <SecuredFragment authorizedRoles={[rolesConstants.parking.UPDATE]}>
                    <Panel
                        shaded
                        bordered
                        className="panel-small"
                        header={
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'parking.information' })}
                                buttons={[
                                    <Button
                                        key="parkingInformations.edit"
                                        data-cy="parkingInformations-editButton"
                                        onClick={this.setEditMode}
                                        appearance="primary"
                                        size="sm">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>,
                                ]}
                            />
                        }>
                        <Form
                            formValue={this.state.formValue}
                            onChange={formValue => this.setFormValue(formValue)}
                            fluid>
                            <FlexboxGrid align="middle" justify="end">
                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    <ControlLabel className="form-control-label">
                                        <FormattedMessage id="parking.edit.name" />
                                    </ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={16}>
                                    <FormControl
                                        data-cy="parking-edit-name"
                                        // size="sm"
                                        name="name"
                                        plaintext={!this.state.editMode}
                                    />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    <ControlLabel className="form-control-label">
                                        <FormattedMessage id="parking.edit.description" />
                                    </ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={16}>
                                    <FormControl
                                        data-cy="parking-edit-description"
                                        // size="sm"
                                        name="description"
                                        plaintext={!this.state.editMode}
                                    />
                                </FlexboxGrid.Item>

                                {this.state.editMode && (
                                    <FlexboxGrid.Item style={{ marginTop: 5 }}>
                                        <ButtonGroup>
                                            <Button
                                                data-cy="parkingInformations-edit-cancel"
                                                color="red"
                                                onClick={this.cancelEditMode}>
                                                <FontAwesomeIcon icon={faTimesCircle} />
                                            </Button>
                                            <Button
                                                data-cy="parkingInformations-edit-valid"
                                                color="green"
                                                onClick={this.validEdition}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                        </ButtonGroup>
                                    </FlexboxGrid.Item>
                                )}
                            </FlexboxGrid>
                            {this.state.editMode && (
                                <Fragment>
                                    <hr />
                                    <SecuredFragment authorizedRoles={[rolesConstants.parking.UPLOAD_PICTURE]}>
                                        <FlexboxGrid justify="end" align="middle">
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <ControlLabel className="form-control-label">
                                                    <FormattedMessage id="parking.edit.image" />
                                                </ControlLabel>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="parking-add-image" componentClass={Col} xs={16}>
                                                <input type="file" accept="image/*" ref={this.fileInput} />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item>
                                                <Button
                                                    style={{ marginTop: 8 }}
                                                    data-cy="parkingInformations-edit-image-valid"
                                                    color="green"
                                                    onClick={this.validImage}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </Button>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </SecuredFragment>
                                </Fragment>
                            )}
                        </Form>
                    </Panel>
                </SecuredFragment>
            </Loader>
        );
    }
}

function mapStateToProps(state) {
    let { isLoading } = state.parkings;

    return { isLoading };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ParkingInformations));
