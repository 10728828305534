import { faCheck, faCircle, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CompactPicker } from 'react-color';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    CheckPicker,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    List,
    Panel,
    SelectPicker,
    Tag
} from 'rsuite';
import { LPVirtualDisplayLanguages } from '../../../../../handlers/lpDisplays/Enums/LPVirtualDisplayEnums';
import { VirtualDisplay } from '../../../../../handlers/lpDisplays/VirtualDisplay';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import { hexToRgb } from '../../../../../utils/colorUtils';
import PanelHeader from '../../../../Custom/PanelHeader';
import { LPVirtualDisplayFont, LPVirtualDisplayLangs, LPVirtualDisplaySpeed } from './Form/LPVirtualDisplayFormValues';
import { LPVirtualDisplayStepColor } from './Form/LPVirtualDisplayStepFormValues';

type Props = {
    display: VirtualDisplay;
    updateValue: Function;
} & WrappedComponentProps;

type State = {
    isEditMode: boolean;
    formValue: FormValue;
    isEditing: boolean;
};

type FormValue = {
    color: string;
    font: number;
    languages: number[];
    arrowSpeed: number;
    textSpeed: number;
};

class LPVirtualDisplayInformations extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            formValue: {
                color: this.props.display.getBackgroundColor(),
                font: this.props.display.getFont(),
                languages: this.props.display.getDisplayLanguage(),
                arrowSpeed: this.props.display.getArrowSpeed(),
                textSpeed: this.props.display.getTextSpeed(),
            },
            isEditing: false,
        };
    }

    setEditMode = () => {
        this.setState({
            isEditMode: true,
        });
    };

    handleFormChange = formValue => {
        this.setState({
            formValue: {
                ...this.state.formValue,
                font: parseInt(formValue.font),
                languages: formValue.languages,
                arrowSpeed: formValue.arrowSpeed,
                textSpeed: formValue.textSpeed,
            },
        });
    };

    handleColorChange = color => {
        this.setState({
            formValue: {
                ...this.state.formValue,
                color: color.hex,
            },
        });
    };

    handleTextSpeedChange = value => {
        this.setState({
            formValue: {
                ...this.state.formValue,
                textSpeed: parseInt(value),
            },
        });
    };

    handleArrowSpeedChange = value => {
        this.setState({
            formValue: {
                ...this.state.formValue,
                arrowSpeed: parseInt(value),
            },
        });
    };

    validEdition = () => {
        this.setState({
            isEditing: true,
        });

        const { color, font, languages, arrowSpeed, textSpeed } = this.state.formValue;

        const data = {
            backgroundColor: hexToRgb(color),
            font: font,
            language: languages.reduce((acc, value) => acc + value),
            arrowSpeed,
            textSpeed,
            startX: this.props.display.getStartX(),
            startY: this.props.display.getStartY(),
            sizeX: this.props.display.getSizeX(),
            sizeY: this.props.display.getSizeY(),
            state: 1,
            id: this.props.display.getId(),
        };

        axiosService
            .getAxios()
            .put('/devices/lp-matrixdisplays-v2/virtualDisplay/updateConfig', data, { headers: authHeader() })
            .then(updateVirtualDisplayReponse => {
                this.props.display.updateVirtualDisplay(updateVirtualDisplayReponse.data);

                this.setState({
                    isEditMode: false,
                    isEditing: false,
                });

                Alert.success(
                    this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.update.success' })
                );

                this.props.updateValue();
            })
            .catch(err => {
                this.setState({
                    isEditing: false,
                });

                Alert.error(
                    this.props.intl.formatMessage(
                        { id: 'ipCanDevices.lpDisplay.baseConfiguration.update.error' },
                        { err }
                    )
                );
            });
    };

    cancelEdition = () => {
        this.setState({
            formValue: {
                color: this.props.display.getBackgroundColor(),
                font: this.props.display.getFont(),
                languages: this.props.display.getDisplayLanguage(),
                arrowSpeed: this.props.display.getArrowSpeed(),
                textSpeed: this.props.display.getTextSpeed(),
            },
            isEditMode: false,
        });
    };

    renderForm = () => {
        return (
            <Form formValue={this.state.formValue} onChange={this.handleFormChange} fluid>
                <List hover>
                    {/* COLOR */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.color" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-edit-color" componentClass={Col} xs={8} className="padding-top-5 padding-bottom-5">
                                <CompactPicker
                                    colors={LPVirtualDisplayStepColor()}
                                    color={this.state.formValue.color}
                                    onChangeComplete={this.handleColorChange}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* FONT */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.font" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <FormControl
                                    data-cy="lpDisplay-baseConfiguration-edit-font"
                                    className="form-margin"
                                    placement="auto"
                                    name="font"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplayFont()}
                                    cleanable={false}
                                    searchable={false}
                                    disabled={this.state.isEditing}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div data-cy={`lpDisplay-baseConfiguration-edit-font-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* LANGUAGES */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.languages" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <FormControl
                                    data-cy="lpDisplay-baseConfiguration-edit-language"
                                    className="form-margin"
                                    name="languages"
                                    accepter={CheckPicker}
                                    cleanable={false}
                                    data={LPVirtualDisplayLangs()}
                                    searchable={false}
                                    disabled={this.state.isEditing}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div data-cy={`lpDisplay-baseConfiguration-edit-lang-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* TEXT SPEED */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.textSpeed" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <FormControl
                                    data-cy="lpDisplay-baseConfiguration-edit-textSpeed"
                                    className="form-margin"
                                    name="textSpeed"
                                    accepter={SelectPicker}
                                    disabled={this.state.isEditing}
                                    data={LPVirtualDisplaySpeed()}
                                    searchable={false}
                                    cleanable={false}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div data-cy={`lpDisplay-baseConfiguration-edit-textSpeed-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* ARROW SPEED */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.arrowSpeed" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <FormControl
                                    data-cy="lpDisplay-baseConfiguration-edit-arrowSpeed"
                                    className="form-margin"
                                    name="arrowSpeed"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplaySpeed()}
                                    disabled={this.state.isEditing}
                                    searchable={false}
                                    cleanable={false}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div data-cy={`lpDisplay-baseConfiguration-edit-arrowSpeed-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* BUTTONS */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="end">
                            <FlexboxGrid.Item>
                                <ButtonGroup>
                                    <Button
                                        data-cy="lpDisplay-baseConfiguration-edit-valid"
                                        color="green"
                                        onClick={this.validEdition}
                                        loading={this.state.isEditing}
                                        disabled={this.state.formValue.languages.length === 0}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                    <Button data-cy="lpDisplay-baseConfiguration-edit-cancel" color="red" onClick={this.cancelEdition} disabled={this.state.isEditing}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </ButtonGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                </List>
            </Form>
        );
    };

    renderView = () => {
        return (
            <List hover>
                {/* Creation */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.creation" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-creation">
                            {this.props.display.getCreatedAt()} - {this.props.display.getCreatedBy()}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* update */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.update" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-update">
                            {this.props.display.getUpdatedAt()} - {this.props.display.getUpdatedBy()}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* COLOR */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.color" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <FontAwesomeIcon
                                data-cy="lpDisplay-baseConfiguration-color"
                                className="margin-right-5"
                                icon={faCircle}
                                color={this.props.display.getBackgroundColor()}
                            />
                            {this.props.display.getBackgroundColor()}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* FONT */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.font" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-font">{`MLE_BIG_${this.props.display.getFont()}`}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* LANGUAGES */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.languages" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-language">
                            {this.props.display.getDisplayLanguage().map(lang => (
                                <Tag color="blue">{LPVirtualDisplayLanguages(lang)}</Tag>
                            ))}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* TEXT SPEED */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.textSpeed" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-textSpeed">{this.props.display.getTextSpeed()}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* ARROW SPEED */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.arrowSpeed" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-arrowSpeed">{this.props.display.getArrowSpeed()}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        );
    };

    render = () => {
        return (
            <Panel
                data-cy="lpDisplay-baseConfiguration-card" 
                className="panel-small"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({
                            id: 'ipCanDevices.lpDiplay.virtualDisplay.configuration',
                        })}
                        buttons={[
                            <Button
                                data-cy="lpDisplay-baseConfiguration-editMode"
                                appearance="primary"
                                size="sm"
                                color="blue"
                                disabled={this.state.isEditMode}
                                onClick={this.setEditMode}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>,
                        ]}
                    />
                }>
                {this.state.isEditMode ? this.renderForm() : this.renderView()}
            </Panel>
        );
    };
}

export default injectIntl(LPVirtualDisplayInformations);
