import React, { Component, Fragment } from 'react';
import { Col, FlexboxGrid } from 'rsuite';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import { ConfigurationActions } from './Actions';
import DatabaseCard from './DatabaseCard';
import DateTimeCard from './DateTimeCard';
import NetworkCard from './NetworkCard';
import { VersionTable } from './VersionsCards/VersionTable';

export default class GeneralConfiguration extends Component {
    render() {
        return (
            <Fragment>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={24}>
                        <VersionTable />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <SecuredFragment authorizedRoles={[rolesConstants.configuration.GET_DATABASE_INFORMATION]}>
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                            <DatabaseCard database="configuration" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                            <DatabaseCard database="logs" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                            <DatabaseCard database="statistics" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                            <DatabaseCard database="historic" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </SecuredFragment>

                <FlexboxGrid justify="start">
                    <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                        <ConfigurationActions />
                    </FlexboxGrid.Item>
                    <SecuredFragment authorizedRoles={[rolesConstants.configuration.GET_NETWORK]}>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                            <NetworkCard />
                        </FlexboxGrid.Item>
                    </SecuredFragment>
                    <SecuredFragment authorizedRoles={[]}>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
                            <DateTimeCard />
                        </FlexboxGrid.Item>
                    </SecuredFragment>
                </FlexboxGrid>
            </Fragment>
        );
    }
}
