import { faArrowDown, faArrowUp, faExchangeAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import L from 'leaflet';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Marker } from 'react-leaflet';
import { VehicleCounterObserver } from '../../../../handlers/ipcan/observers/VehicleCounterObserver';
import { MapVehicleCounter } from '../../../../handlers/map/MapVehicleCounter';
import PassCountPopup from './PassCountPopup';

type Props = {
    passCount: MapVehicleCounter;
    editMode: boolean;
    editMap: boolean;
    reloadPassCount: Function;
} & WrappedComponentProps;

type State = {
    clickOpen: boolean;
    shouldReload: boolean;
};

class PassCountIcon extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            clickOpen: false,
            shouldReload: false,
        };
    }

    componentDidMount = () => {
        VehicleCounterObserver.subscribe(() => this.setShouldReload());
    };

    componentWillUnmount = () => {
        VehicleCounterObserver.unsubscribe(() => this.setShouldReload());
    };

    setShouldReload = () => {
        this.setState({
            shouldReload: !this.state.shouldReload,
        });
    };

    onMouseOver = e => {
        if (!this.state.clickOpen) {
            e.target.openPopup();
        }
    };

    onMouseOut = e => {
        if (!this.state.clickOpen) {
            e.target.closePopup();
        }
    };

    openPopup = e => {
        if (!this.state.clickOpen) {
            e.target.openPopup();
        } else {
            e.target.closePopup();
        }

        this.setState({
            clickOpen: !this.state.clickOpen,
        });
    };

    createIcon = () => {
        const valuesToDisplay = this.props.passCount.getDisplayValue();
        const values = this.props.passCount.getVehicleCounter();

        return (
            <div className="counter">
                {valuesToDisplay.includes('IN') && (
                    <span style={{ color: 'green' }}>
                        IN : {this.props.editMap ? <FontAwesomeIcon icon={faTimes} /> : values.getValueIn().getValue()}
                        {values.getValueIn().getShowUpArrow() && (
                            <FontAwesomeIcon className="margin-left-10" icon={faArrowUp} color="green" />
                        )}
                        {values.getValueIn().getShowDownArrow() && (
                            <FontAwesomeIcon className="margin-left-10" icon={faArrowDown} color="green" />
                        )}
                    </span>
                )}
                {valuesToDisplay.includes('OUT') && (
                    <span style={{ color: 'red' }}>
                        OUT :{' '}
                        {this.props.editMap ? <FontAwesomeIcon icon={faTimes} /> : values.getValueOut().getValue()}
                        {values.getValueOut().getShowUpArrow() && (
                            <FontAwesomeIcon className="margin-left-10" icon={faArrowUp} color="red" />
                        )}
                        {values.getValueOut().getShowDownArrow() && (
                            <FontAwesomeIcon className="margin-left-10" icon={faArrowDown} color="red" />
                        )}
                    </span>
                )}
                {valuesToDisplay.includes('OCCUPED') && (
                    <span>
                        FREE : {this.props.editMap ? <FontAwesomeIcon icon={faTimes} /> : values.getValue().getValue()}
                    </span>
                )}
                {valuesToDisplay.length === 0 && (
                    <span>
                        <FontAwesomeIcon icon={faExchangeAlt} size="lg" />
                    </span>
                )}
            </div>
        );
    };

    render() {
        return (
            <Marker
                onclick={this.openPopup}
                onmouseover={this.onMouseOver}
                onmouseout={this.onMouseOut}
                position={{
                    lat: this.props.passCount.getGeoJSON()[0],
                    lng: this.props.passCount.getGeoJSON()[1],
                }}
                name={this.props.passCount.getId()}
                icon={L.divIcon({
                    className: 'pass-counter',
                    html: ReactDOMServer.renderToStaticMarkup(this.createIcon()),
                })}>
                <PassCountPopup
                    editMap={this.props.editMap}
                    editMode={this.props.editMode}
                    passCount={this.props.passCount}
                    hover={!this.state.clickOpen}
                    reloadPassCount={this.props.reloadPassCount}
                />
            </Marker>
        );
    }
}

export default injectIntl(PassCountIcon);
