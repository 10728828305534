import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid, List, Modal, Panel, PanelGroup } from 'rsuite';

const ListItem = ({ bus, index }) => (
    <List.Item className={`panel-list-error ${index % 2 !== 0 ? 'strip' : ''}`}>
        <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item colspan={18}>
                <FontAwesomeIcon icon={faPlug} style={{ color: bus.element.online ? 'green' : 'red' }} />{' '}
                <FormattedMessage id="displayError.modal.address" /> {bus.element.deviceId}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6}>{bus.msg}</FlexboxGrid.Item>
        </FlexboxGrid>
    </List.Item>
);

type Props = {
    hideErrors: Function;
    show: boolean;
    warnings: Array<Record<string, any>>;
};

type State = {};
export default class DisplayErrorTable extends React.Component<Props, State> {
    render() {
        return (
            <Modal
                backdrop="static"
                onHide={() => this.props.hideErrors()}
                show={this.props.show}
                size="lg"
                overflow={true}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="displayError.modal.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PanelGroup
                        accordion
                        bordered
                        style={{
                            display: this.props.warnings.length === 0 || !this.props.show ? 'none' : 'block',
                        }}
                        className="no-radius">
                        {this.props.warnings.map(warning => {
                            return (
                                <Panel
                                    key={`${warning.ip}-${warning.macStr}`}
                                    header={
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item colspan={12}>
                                                {warning.label} - {warning.ip} ({warning.macStr})
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} className="small-text-white">
                                                {warning.elements.bus0.length +
                                                    warning.elements.bus1.length +
                                                    warning.elements.bus2.length}{' '}
                                                élément(s)
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    }
                                    className="panel-error-title margin-top-0 margin-bottom-0 no-radius no-border"
                                    bodyFill>
                                    {warning.elements.bus0.length > 0 && (
                                        <Panel
                                            header={
                                                <FlexboxGrid justify="space-between" align="middle">
                                                    <FlexboxGrid.Item colspan={4}>Bus 0</FlexboxGrid.Item>
                                                    <FlexboxGrid.Item colspan={6} className="small-text">
                                                        {warning.elements.bus0.length} élément(s)
                                                    </FlexboxGrid.Item>
                                                </FlexboxGrid>
                                            }
                                            className="panel-error-bus margin-top-0 margin-bottom-0 panel-errors no-radius"
                                            collapsible
                                            bodyFill>
                                            <List hover>
                                                {warning.elements.bus0.map((bus, index) => {
                                                    return <ListItem key={`${bus.id}`} bus={bus} index={index} />;
                                                })}
                                            </List>
                                        </Panel>
                                    )}

                                    {warning.elements.bus1.length > 0 && (
                                        <Panel
                                            header={
                                                <FlexboxGrid justify="space-between" align="middle">
                                                    <FlexboxGrid.Item colspan={4}>Bus 1</FlexboxGrid.Item>
                                                    <FlexboxGrid.Item colspan={6} className="small-text">
                                                        {warning.elements.bus1.length} élément(s)
                                                    </FlexboxGrid.Item>
                                                </FlexboxGrid>
                                            }
                                            className="panel-error-bus margin-top-0 margin-bottom-0 panel-errors no-radius"
                                            bodyFill
                                            collapsible>
                                            <List hover>
                                                {warning.elements.bus1.map((bus, index) => {
                                                    return <ListItem key={`${bus.id}`} bus={bus} index={index} />;
                                                })}
                                            </List>
                                        </Panel>
                                    )}

                                    {warning.elements.bus2.length > 0 && (
                                        <Panel
                                            header={
                                                <FlexboxGrid justify="space-between" align="middle">
                                                    <FlexboxGrid.Item colspan={4}>Bus 2</FlexboxGrid.Item>
                                                    <FlexboxGrid.Item colspan={6} className="small-text">
                                                        {warning.elements.bus2.length} élément(s)
                                                    </FlexboxGrid.Item>
                                                </FlexboxGrid>
                                            }
                                            className="panel-error-bus margin-top-0 margin-bottom-0 panel-errors no-radius"
                                            bodyFill
                                            collapsible>
                                            <List hover>
                                                {warning.elements.bus2.map((bus, index) => {
                                                    return <ListItem key={`${bus.id}`} bus={bus} index={index} />;
                                                })}
                                            </List>
                                        </Panel>
                                    )}
                                </Panel>
                            );
                        })}
                    </PanelGroup>
                </Modal.Body>
            </Modal>
        );
    }
}
