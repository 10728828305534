export default class ConfigDisplayTCM {
    arrow: number;
    lang: number;
    text: number;
    type: number;
    requireUIUpdate: boolean;
    constructor(arrow_, lang_, text_, type_) {
        this.arrow = arrow_;
        this.lang = lang_;
        this.text = text_;
        this.type = type_;
        this.requireUIUpdate = false;
    }

    setConfigDisplayTCM(arrow, lang, text, type) {
        this.setArrow(arrow);
        this.setLang(lang);
        this.setText(text);
        this.setType(type);

        return this.requireUIUpdate;
    }

    setArrow(arrow) {
        if (!isNaN(arrow) && arrow !== this.arrow) {
            this.requireUIUpdate = true;
            this.arrow = arrow;
        }
    }

    setLang(lang) {
        if (!isNaN(lang) && lang !== this.lang) {
            this.requireUIUpdate = true;
            this.lang = lang;
        }
    }

    setText(text) {
        if (!isNaN(text) && text !== this.text) {
            this.requireUIUpdate = true;
            this.text = text;
        }
    }

    setType(type) {
        if (!isNaN(type) && type !== this.type) {
            this.requireUIUpdate = true;
            this.type = type;
        }
    }
}
