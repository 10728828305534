import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';
import { PlaceCameraState } from '../../../../handlers/Camera/PlaceCamera';

type Props = {
    state: PlaceCameraState;
    statePrediction: string;
    detectionStateDate: string;
};

export const DetectionStatePlace = (props: Props) => {
    return (
        <div className="text-center">
            {props.state === PlaceCameraState.FREE && (
                <Tag color="green">
                    <FormattedMessage id="cameras.cameraPlace.state.free" />
                </Tag>
            )}
            {props.state === PlaceCameraState.OCCUPIED && (
                <Tag color="red">
                    <FormattedMessage id="cameras.cameraPlace.state.occupied" />
                </Tag>
            )}
            {props.state === PlaceCameraState.OVERSTAY && (
                <Tag color="orange">
                    <FormattedMessage id="cameras.cameraPlace.state.overstay" />
                </Tag>
            )}
            {props.state === PlaceCameraState.UNKNOWN && (
                <Tag color="yellow">
                    <FormattedMessage id="cameras.cameraPlace.state.unknown" />
                </Tag>
            )}
            <small className="margin-left-5">({props.statePrediction}%)</small>
            <br />
            <small className="text-center">{props.detectionStateDate}</small>
        </div>
    );
};
