import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { Col, FlexboxGrid, List } from 'rsuite';
import Camera from '../../../../handlers/Camera/Camera';
import PlaceCamera from '../../../../handlers/Camera/PlaceCamera';
import { MaintenanceState } from '../../../../handlers/MaintenanceInformation';
import { DeviceBase } from '../../../../handlers/ipCanDevices/DeviceBase';

type Props = {
    device: DeviceBase | Camera | PlaceCamera;
    isMinimized?: boolean;
};

export const ViewDeviceStateInformation = (props: Props) => {
    const intl = useIntl();

    return (
        <List hover size={props.isMinimized ? 'sm' : 'md'}>
            {/* MAINTENANCE STATE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                        {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState' })}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        {props.device.getMaintenanceState() === MaintenanceState.EVERYTHING_OK && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="green" size="lg" />
                        )}
                        {props.device.getMaintenanceState() === MaintenanceState.LOW && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="#FFDA00" size="lg" />
                        )}
                        {props.device.getMaintenanceState() === MaintenanceState.MEDIUM && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="orange" size="lg" />
                        )}
                        {props.device.getMaintenanceState() === MaintenanceState.HIGH && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="#FF5349" size="lg" />
                        )}
                        {props.device.getMaintenanceState() === MaintenanceState.VERY_HIGH && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="red" size="lg" />
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* INFORMATION NOTEPAD */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={24} className="bold">
                        {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.notepad' })}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <p style={{ whiteSpace: 'break-spaces' }}>{props.device.getNotepad()}</p>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
};
