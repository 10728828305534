import { ipCanConstants } from '../constants';

type State = {
    isLoading: boolean;
    ipCanList: Array<Record<string, any>>;
    error: undefined | string;
    fetchedIpcan: Record<string, any>;
    createModalOpen: boolean;
    viewDrawerOpen: boolean;
    deleteModalOpen: boolean;
    drawerLoading: boolean;
};

const initialState = {
    isLoading: false,
    ipCanList: [],
    error: undefined,
    fetchedIpcan: {},
    createModalOpen: false,
    viewDrawerOpen: false,
    deleteModalOpen: false,
    drawerLoading: false,
};

export function ipcan(state: State = initialState, action) {
    switch (action.type) {
        case ipCanConstants.GET_ALL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ipCanConstants.GET_ALL_SUCCESS:
            return {
                isLoading: false,
                ipCanList: action.ipCan.map(ipcan => {
                    return { ...ipcan, loading: false };
                }),
            };
        case ipCanConstants.GET_ALL_FAILURE:
            return {
                isLoading: false,
                ipCanList: [],
                error: action.error,
            };
        case ipCanConstants.CREATE_REQUEST:
            return {
                ...state,
                isLoading: false,
            };
        case ipCanConstants.CREATE_SUCCESS:
            return {
                ...state,
                ipCanList: state.ipCanList.concat(action.ipCan),
            };
        case ipCanConstants.CREATE_FAILURE:
            return {
                ...state,
                ipCanList: state.ipCanList.filter(ipcan => ipcan.id !== action.id),
                error: action.error,
            };

        case ipCanConstants.UPDATE_REQUEST:
            return {
                ...state,
                ipCanList: state.ipCanList.map(ipcan => (ipcan.id === action.id ? { ...ipcan, loading: true } : ipcan)),
            };
        case ipCanConstants.UPDATE_SUCCESS:
            return {
                ...state,
                ipCanList: state.ipCanList.map(ipcan => {
                    if (ipcan.id === action.ipCan.id) {
                        return { ...ipcan, ...action.ipCan, loading: false };
                    } else {
                        return ipcan;
                    }
                }),
                fetchedIpcan: { ...state.fetchedIpcan, ...action.ipCan },
                // TODO
            };
        case ipCanConstants.UPDATE_FAILURE:
            return {
                ...state,
                ipCanList: state.ipCanList.map(ipcan => {
                    if (ipcan.id === action.id) {
                        return { ...ipcan, loading: false };
                    } else {
                        return ipcan;
                    }
                }),
            };

        case ipCanConstants.DELETE_REQUEST:
            // add 'deleting:true' property to ipcan being deleted
            return {
                ...state,
                ipCanList: state.ipCanList.map(ipcan => (ipcan.id === action.id ? { ...ipcan, loading: true } : ipcan)),
            };
        case ipCanConstants.DELETE_SUCCESS:
            // remove deleted ipcan from state
            return {
                ...state,
                ipCanList: state.ipCanList.filter(ipcan => ipcan.id !== action.ipCan.id),
            };
        case ipCanConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to ipcan
            return {
                ...state,
                ipCanList: state.ipCanList.map(ipcan => {
                    if (ipcan.id === action.id) {
                        // make copy of ipcan without 'deleting:true' property
                        const { ...ipcanCopy } = ipcan;
                        // return copy of ipcan with 'deleteError:[error]' property
                        return { ...ipcanCopy, deleteError: action.error };
                    }

                    return ipcan;
                }),
            };
            // case ipCanConstants.GETONE_REQUEST:
            //     return {
            //         ...state,
            //         fetchedIpcan: {},
            //         drawerLoading: true,
            //     };
            // case ipCanConstants.GETONE_SUCCESS:
            //     return {
            //         ...state,
            //         fetchedIpcan: action.ipCan,
            //         drawerLoading: false,
            //     };
            // case ipCanConstants.GETONE_FAILURE:
            //     return {
            //         ...state,
            //         error: action.error,
            //         drawerLoading: false,
            //     };

        case ipCanConstants.OPEN_CREATE_MODAL:
            return {
                ...state,
                createModalOpen: true,
            };

        case ipCanConstants.CLOSE_CREATE_MODAL:
            return {
                ...state,
                createModalOpen: false,
            };
        case ipCanConstants.OPEN_VIEW_DRAWER_REQUEST:
            return {
                ...state,
                fetchedIpcan: {},
            };
        case ipCanConstants.OPEN_VIEW_DRAWER_SUCCESS:
            return {
                ...state,
                fetchedIpcan: action.ipcan,
                viewDrawerOpen: true,
            };
        case ipCanConstants.CLOSE_VIEW_DRAWER:
            return {
                ...state,
                fetchedIpcan: {},
                viewDrawerOpen: false,
            };

        case ipCanConstants.OPEN_DELETE_MODAL_REQUEST:
            return {
                ...state,
                fetchedIpcan: action.ipcan,
            };
        case ipCanConstants.OPEN_DELETE_MODAL_SUCCESS:
            return {
                ...state,
                deleteModalOpen: true,
                fetchedIpcan: action.ipcan,
            };
        case ipCanConstants.CLOSE_DELETE_MODAL:
            return {
                ...state,
                fetchedIpcan: {},
                deleteModalOpen: false,
            };
        case ipCanConstants.WEBSOCKET_UPDATE_IPCAN:
            if (state.ipCanList.filter(ipCan => ipCan.mac === action.ipCan.mac).length === 0) {
                return {
                    ...state,
                    ipCanList: state.ipCanList.concat({
                        ...action.ipCan,
                        loading: false,
                    }),
                };
            } else {
                if (state.fetchedIpcan && state.fetchedIpcan.mac === action.ipCan.mac) {
                    return {
                        ...state,
                        ipCanList: state.ipCanList.map(ipCan => {
                            if (ipCan.mac === action.ipCan.mac) {
                                return {
                                    ...ipCan,
                                    ...action.ipCan,
                                    nbDisplays: { ...ipCan.nbDisplays, ...action.ipCan.nbDisplays },
                                    nbSensors: { ...ipCan.nbSensors, ...action.ipCan.nbSensors },
                                };
                            } else {
                                return ipCan;
                            }
                        }),
                        fetchedIpcan: {
                            ...state.fetchedIpcan,
                            ...action.ipCan,
                            nbDisplays: { ...state.fetchedIpcan.nbDisplays, ...action.ipCan.nbDisplays },
                            nbSensors: { ...state.fetchedIpcan.nbSensors, ...action.ipCan.nbSensors },
                        },
                    };
                } else {
                    return {
                        ...state,
                        ipCanList: state.ipCanList.map(ipCan => {
                            if (ipCan.mac === action.ipCan.mac) {
                                return {
                                    ...ipCan,
                                    ...action.ipCan,
                                    nbDisplays: { ...ipCan.nbDisplays, ...action.ipCan.nbDisplays },
                                    nbSensors: { ...ipCan.nbSensors, ...action.ipCan.nbSensors },
                                };
                            } else {
                                return ipCan;
                            }
                        }),
                    };
                }
            }
        case ipCanConstants.WEBSOCKET_KEEP_ALIVE_IP_CAN:
            if (state.fetchedIpcan && state.fetchedIpcan.mac === action.data.macAddress) {
                return {
                    ...state,
                    ipCanList: state.ipCanList.map(ipCan => {
                        if (ipCan.mac === action.data.macAddress) {
                            return {
                                ...ipCan,
                                nbReboot: action.data.nbReboot,
                                syncState: action.data.syncState,
                                msgFifoLength: action.data.msgFifoLength,
                                nbDisplays: {
                                    ...ipCan.nbDisplays,
                                    onModule: action.data.displays.total,
                                    online: action.data.displays.online,
                                },
                                nbSensors: {
                                    ...ipCan.nbSensors,
                                    onModule: action.data.sensors.total,
                                    online: action.data.sensors.online,
                                },
                                monitoring: {
                                    externalVoltage: action.data.externalVoltage,
                                    mcuTemperature: action.data.mcuTemperature,
                                    bus: action.data.bus,
                                    boardTemperature: action.data.boardTemperature,
                                    totalPower: (
                                        action.data.bus[0].p +
                                        action.data.bus[1].p +
                                        action.data.bus[2].p
                                    ).toFixed(2),
                                    gpio: action.data.gpio,
                                    displays: action.data.displays,
                                },
                            };
                        } else {
                            return ipCan;
                        }
                    }),
                    fetchedIpcan: {
                        ...state.fetchedIpcan,
                        nbReboot: action.data.nbReboot,
                        syncState: action.data.syncState,
                        msgFifoLength: action.data.msgFifoLength,
                        nbDisplays: {
                            ...state.fetchedIpcan.nbDisplays,
                            onModule: action.data.displays.total,
                            online: action.data.displays.online,
                        },
                        nbSensors: {
                            ...state.fetchedIpcan.nbSensors,
                            onModule: action.data.sensors.total,
                            online: action.data.sensors.online,
                        },
                        monitoring: {
                            externalVoltage: action.data.externalVoltage,
                            mcuTemperature: action.data.mcuTemperature,
                            bus: action.data.bus,
                            boardTemperature: action.data.boardTemperature,
                            totalPower: (action.data.bus[0].p + action.data.bus[1].p + action.data.bus[2].p).toFixed(2),
                            gpio: action.data.gpio,
                            displays: action.data.displays,
                        },
                        busConfiguration: state.fetchedIpcan.busConfiguration.map((configuration, index) => {
                            let newBusConfiguration = {
                                ...configuration,
                                online: action.data['devicesOnBus' + index].devices.online,
                                onModule: action.data['devicesOnBus' + index].devices.total,
                                errors: action.data['devicesOnBus' + index].errors,
                            };
                            return newBusConfiguration;
                        }),
                    },
                };
            } else {
                return {
                    ...state,
                    ipCanList: state.ipCanList.map(ipCan => {
                        if (ipCan.mac === action.data.macAddress) {
                            return {
                                ...ipCan,
                                nbReboot: action.data.nbReboot,
                                syncState: action.data.syncState,
                                msgFifoLength: action.data.msgFifoLength,
                                nbDisplays: {
                                    ...ipCan.nbDisplays,
                                    onModule: action.data.displays.total,
                                    online: action.data.displays.online,
                                },
                                nbSensors: {
                                    ...ipCan.nbSensors,
                                    onModule: action.data.sensors.total,
                                    online: action.data.sensors.online,
                                },
                                monitoring: {
                                    externalVoltage: action.data.externalVoltage,
                                    mcuTemperature: action.data.mcuTemperature,
                                    bus: action.data.bus,
                                    boardTemperature: action.data.boardTemperature,
                                    totalPower: (
                                        action.data.bus[0].p +
                                        action.data.bus[1].p +
                                        action.data.bus[2].p
                                    ).toFixed(2),
                                    gpio: action.data.gpio,
                                    displays: action.data.displays,
                                },
                            };
                        } else {
                            return ipCan;
                        }
                    }),
                };
            }
        case ipCanConstants.GET_DB_VALUE: {
            //---
            let fetchedIpcan = state.fetchedIpcan;
            if (fetchedIpcan && fetchedIpcan.id === action.ipCan.id) {
                fetchedIpcan = {
                    ...fetchedIpcan,
                    nbDisplays: { ...fetchedIpcan.nbDisplays, onDB: action.ipCan.nbDisplays.onDB },
                    nbSensors: { ...fetchedIpcan.nbSensors, onDB: action.ipCan.nbSensors.onDB },
                };
                //---
                for (const i in fetchedIpcan.busConfiguration) {
                    const conf = fetchedIpcan.busConfiguration[i];
                    fetchedIpcan.busConfiguration[i] = {
                        ...conf,
                        onDB: action.ipCan.busConfiguration[i].onDB,
                    };
                }
            }
            //---
            const tempState = {
                ...state,
                ipCanList: state.ipCanList.map(ipCan => {
                    if (ipCan.id === action.ipCan.id) {
                        return {
                            ...ipCan,
                            nbDisplays: { ...ipCan.nbDisplays, onDB: action.ipCan.nbDisplays.onDB },
                            nbSensors: { ...ipCan.nbSensors, onDB: action.ipCan.nbSensors.onDB },
                            onDBLoading: false,
                        };
                    } else {
                        return ipCan;
                    }
                }),
            };
            tempState.fetchedIpcan = fetchedIpcan;
            return tempState;
        }
        default:
            return state;
    }
}
