import { DeviceBase } from './DeviceBase';
import ConfigDisplayTCM from './Display/ConfigDisplayTCM';
import ConfigDisplayTypeTCM from './Display/ConfigDisplayTypeTCM';

export default class TCMDisplay extends DeviceBase {
    _configuration: ConfigDisplayTCM;
    _version: string;
    _modelName: string;
    _manufacturer: string;
    _intensity: number;
    _eventForceText: string;
    _configType: ConfigDisplayTypeTCM;
    _counters: Array<number>;
    _substractionCounters: Array<number>;
    _displayValue: TCMDisplayValueType_I;
    _eventForceType: string;
    _forceType: TCMDisplayForceType_I;
    _putData: Function;
    _updateElements: Function;

    constructor(tcmDisplay_, ipCanId_, putData_, updateElements_) {
        super(
            tcmDisplay_.id,
            tcmDisplay_.bus,
            tcmDisplay_.deviceId,
            tcmDisplay_.online,
            tcmDisplay_.createdAt,
            tcmDisplay_.updatedAt,
            tcmDisplay_.createdBy,
            tcmDisplay_.updatedBy,
            tcmDisplay_.versionSoft,
            tcmDisplay_.startForceTime,
            tcmDisplay_.endForceTime,
            tcmDisplay_.isForce,
            tcmDisplay_.lastOnlineStateDate,
            tcmDisplay_.maintenanceState,
            tcmDisplay_.notepad,
            ipCanId_
        );
        this._configuration = new ConfigDisplayTCM(
            tcmDisplay_.arrow,
            tcmDisplay_.lang,
            tcmDisplay_.text,
            tcmDisplay_.type
        );
        this._version = tcmDisplay_.version;
        this._modelName = tcmDisplay_.modelName;
        this._manufacturer = tcmDisplay_.manufacturer;
        this._intensity = tcmDisplay_.intensity;
        this._eventForceText = tcmDisplay_.eventForceText;
        this._configType = new ConfigDisplayTypeTCM(tcmDisplay_.configType);
        this._counters = tcmDisplay_.counters;
        this._substractionCounters = tcmDisplay_.substractionCounters;
        this._displayValue = {
            electrical: {
                free: -1,
                occupied: -1,
                overstayOccupied: -1,
                overstayFree: -1,
                forced: -1,
                total: -1,
            },
            generic: {
                free: -1,
                occupied: -1,
                overstayOccupied: -1,
                overstayFree: -1,
                forced: -1,
                total: -1,
            },
            pmr: {
                free: -1,
                occupied: -1,
                overstayOccupied: -1,
                overstayFree: -1,
                forced: -1,
                total: -1,
            },
            family: {
                free: -1,
                occupied: -1,
                overstayOccupied: -1,
                overstayFree: -1,
                forced: -1,
                total: -1,
            },
        };
        this._putData = putData_;
        this._updateElements = updateElements_;
        this._eventForceType = tcmDisplay_.eventForceType;
        this._forceType = { forceType: 0, generic: 0, pmr: 0, family: 0, electrical: 0 };
        // this.substractionCounters = substractionCounters.map(counter => new Counter(counter));
    }

    //      ____ _____ _____ _____ _____ ____  ____
    //     / ___| ____|_   _|_   _| ____|  _ \/ ___|
    //    | |  _|  _|   | |   | | |  _| | |_) \___ \
    //    | |_| | |___  | |   | | | |___|  _ < ___) |
    //     \____|_____| |_|   |_| |_____|_| \_\____/

    public get version() {
        return this._version;
    }

    public get modelName() {
        return this._modelName;
    }

    public get manufacturer() {
        return this._manufacturer;
    }

    public get configuration() {
        return this._configuration;
    }

    public get displayValue() {
        return this._displayValue;
    }

    public get forceType() {
        return this._forceType;
    }

    public get endForceTime() {
        return this._endForceTime;
    }

    getDisplayInformation() {
        return {
            deviceId: this.deviceId,
            online: this.online,
            version: this.version,
            modelName: this.modelName,
            manufacturer: this.manufacturer,
            creationInformation: `${this.createdBy} - ${this.createdAt}`,
            updateInformation: `${this.updatedBy} - ${this.updatedAt}`,
        };
    }

    public get configTCMDisplay() {
        return this.configuration;
    }

    public get configType() {
        return this._configType;
    }

    public get counters() {
        return {
            counters: this._counters,
            substractionCounters: this._substractionCounters,
        };
    }

    //  ____  _____ _____ _____ _____ ____  ____
    // / ___|| ____|_   _|_   _| ____|  _ \/ ___|
    // \___ \|  _|   | |   | | |  _| | |_) \___ \
    //  ___) | |___  | |   | | | |___|  _ < ___) |
    // |____/|_____| |_|   |_| |_____|_| \_\____/

    /**
     *
     * @param {object} updateForceDisplay
     */
    updateForceDisplay({ endForceTime, eventForceText }) {
        this.setEndForceTime(endForceTime);
        this.setEventForceText(eventForceText);
    }

    /**
     *
     * @param {ConfigDisplayTCM} configDisplayTCM
     */
    setConfigDisplay(configDisplayTCM): boolean {
        return this.configuration.setConfigDisplayTCM(
            configDisplayTCM.arrow,
            configDisplayTCM.lang,
            configDisplayTCM.text,
            configDisplayTCM.type
        );
    }

    /**
     *
     * @param {ConfigDisplayTypeTCM} configDisplayTypeTCM
     */
    setConfigDisplayType(configDisplayTypeTCM): boolean {
        return this._configType.setConfigDisplayTypeTCM(configDisplayTypeTCM);
    }

    /**
     *
     * @param {number} arrow
     * @param {number} lang
     * @param {number} text
     * @param {number} type
     */
    setConfiguration(arrow, lang, text, type) {
        return this.configuration.setConfigDisplayTCM(arrow, lang, text, type);
    }

    /**
     *
     * @param {number} intensity
     */
    setIntensity(intensity): boolean {
        if (!isNaN(intensity) && intensity !== this._intensity) {
            this._intensity = intensity;
            return true;
        }
        return false;
    }

    /**
     *
     * @param {number} eventForceText
     */
    setEventForceText(eventForceText): boolean {
        if (!isNaN(eventForceText) && eventForceText !== this._eventForceText) {
            this._eventForceText = eventForceText;
            return true;
        }
        return false;
    }

    public setIsForce(isForce_: boolean): boolean {
        if (this._isForce !== isForce_) {
            this._isForce = isForce_;
            return true;
        }
        return false;
    }

    public setEventForceType(eventForceType_: string): boolean {
        if (this._eventForceType !== eventForceType_) {
            this._eventForceType = eventForceType_;
            return true;
        }
        return false;
    }

    public setOnline(online_: boolean): boolean {
        if (this._online !== online_) {
            this._online = online_;

            return true;
        }
        return false;
    }

    public setForceType(forceType_: TCMDisplayForceType_I): boolean {
        if (this._forceType !== forceType_) {
            this._forceType = forceType_;
            return true;
        }

        return false;
    }

    public setDisplayValue(displayValue_: TCMDisplayValueType_I): boolean {
        if (this._displayValue !== displayValue_) {
            this._displayValue = displayValue_;
            return true;
        }
        return false;
    }

    //      _    ____ ___
    //     / \  |  _ \_ _|
    //    / _ \ | |_) | |
    //   / ___ \|  __/| |
    //  /_/   \_\_|  |___|

    /**
     * @param {ConfigDisplayTypeTCM} configDisplayTypeTCM
     */
    updateConfigDisplayType(configDisplayTypeTCM) {
        const data = {
            id: this.id,
            configType: configDisplayTypeTCM,
        };

        return this._putData('/devices/tcm-display/updateConfigType', data).then(updateConfigTypeResponse => {
            const newConfigDisplayTypeTCM = new ConfigDisplayTypeTCM(updateConfigTypeResponse.configType);

            this.setConfigDisplayType(newConfigDisplayTypeTCM);
        });
    }

    /**
     *
     * @param {ConfigDisplayTCM} configDisplayTCM
     */
    updateConfigDisplayTCM(configDisplayTCM) {
        const data = {
            id: this.id,
            arrow: configDisplayTCM.arrow,
            lang: configDisplayTCM.lang,
            text: configDisplayTCM.text,
            type: configDisplayTCM.type,
        };

        return this._putData('/devices/tcm-display/updateConfig', data).then(updateConfigResponse => {
            const { arrow, lang, type, text } = updateConfigResponse;
            const newConfigDisplayTCM = new ConfigDisplayTCM(arrow, lang, text, type);

            this.setConfigDisplay(newConfigDisplayTCM);
        });
    }

    /**
     *
     * @param {number[]} counters
     * @returns
     */
    updateCounters(counters) {
        const data = {
            id: this.id,
            tabCounterId: counters,
        };

        return this._putData('/devices/tcm-display/updateCounters', data).then(updateCountersResponse => {
            this._counters = updateCountersResponse.counters;
        });
    }

    /**
     *
     * @param {number[]} counters
     * @returns
     */
    updateSubstractionCounters(counters) {
        const data = {
            id: this.id,
            tabCounterId: counters,
        };

        return this._putData('/devices/tcm-display/updateSubstractionCounters', data).then(
            updateSustractionCountersResponse => {
                this._substractionCounters = updateSustractionCountersResponse.substractionCounters;
            }
        );
    }

    // __        _______ ____ ____   ___   ____ _  _______ _____ ____
    // \ \      / / ____| __ ) ___| / _ \ / ___| |/ / ____|_   _/ ___|
    //  \ \ /\ / /|  _| |  _ \___ \| | | | |   | ' /|  _|   | | \___ \
    //   \ V  V / | |___| |_) |__) | |_| | |___| . \| |___  | |  ___) |
    //    \_/\_/  |_____|____/____/ \___/ \____|_|\_\_____| |_| |____/

    webSocketUpdate(data: TCMDisplayWebsocket_I) {
        let nbElementsUpdated = 0;

        if (this.setConfigDisplayType(data.configType)) nbElementsUpdated++;
        if (this.setConfiguration(data.arrow, data.lang, data.text, data.type)) nbElementsUpdated++;
        if (this.setEventForceText(data.eventForceText)) nbElementsUpdated++;
        if (this.setIsForce(data.isForce)) nbElementsUpdated++;
        if (this.setEventForceType(data.eventForceType)) nbElementsUpdated++;
        if (this.setForceType(data.forceType)) nbElementsUpdated++;
        if (this.setLastOnlineStateDate(data.lastOnlineStateDate)) nbElementsUpdated++;
        if (this.setOnline(data.online)) nbElementsUpdated++;
        if (this.setIntensity(data.intensity)) nbElementsUpdated++;
        if (this.setEndForceTime(data.endForceTime)) nbElementsUpdated++;
        if (this.setDisplayValue(data.displayValue)) nbElementsUpdated++;

        if (nbElementsUpdated > 0) this._updateElements();
    }
}

interface TCMDisplayWebsocket_I {
    arrow: number;
    bus: number;
    configType: ConfigDisplayTypeTCM;
    deviceId: number;
    displayValue: TCMDisplayValueType_I;
    endForceTime: Date;
    eventForceText: number;
    eventForceType: string;
    forceType: TCMDisplayForceType_I;
    id: number;
    intensity: number;
    ipcanmoduleId: number;
    isForce: boolean;
    lang: number;
    lastOnlineStateDate: Date;
    manufacturer: number;
    modelName: number;
    online: boolean;
    text: number;
    type: number;
    version: number;
}

export interface TCMDisplayValueType_I {
    electrical: TCMDisplayValue_I;
    family: TCMDisplayValue_I;
    generic: TCMDisplayValue_I;
    pmr: TCMDisplayValue_I;
}

export interface TCMDisplayValue_I {
    free: number;
    occupied: number;
    overstayOccupied: number;
    overstayFree: number;
    forced: number;
    total: number;
}

export interface TCMDisplayForceType_I {
    forceType: number;
    generic: number;
    pmr: number;
    family: number;
    electrical: number;
}
