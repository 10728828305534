import { faCheck, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Alert, Button, Col, FlexboxGrid, Input, List, Rate } from 'rsuite';
import { MaintenanceState } from '../../../../../../handlers/MaintenanceInformation';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';

type Props = {
    device: any;
    setIsEditMode: (isEditMode: boolean) => void;
    service: any;
};

export const MapTCMEditDeviceStateInformation = (props: Props) => {
    const intl = useIntl();

    const [maintenanceState, setMaintenanceState] = React.useState<MaintenanceState>(
        props.device.tcmDisplay.maintenanceState
    );
    const [notepad, setNotePad] = React.useState<string>(props.device.tcmDisplay.notepad);
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

    const editMaintenanceState = () => {
        setIsUpdating(true);

        axiosService
            .getAxios()
            .put(
                '/devices/tcm-display/updateMaintenanceState',
                {
                    id: props.device.tcmDisplay.id,
                    maintenanceState: maintenanceState,
                    notepad: notepad,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateMaintenanceStateResponse => {
                props.service.send('DISPLAY_UPDATE', { display: updateMaintenanceStateResponse.data });

                Alert.success(
                    intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState.edit.success' })
                );

                setIsUpdating(false);

                props.setIsEditMode(false);
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    intl.formatMessage({
                        id: 'ipCanDevices.maintenanceInformation.maintenanceState.edit.error',
                    })
                );

                setIsUpdating(false);
            });
    };

    const cancelEdit = () => {
        setMaintenanceState(props.device.tcmDisplay.maintenanceState);
        setNotePad(props.device.tcmDisplay.notepad);

        props.setIsEditMode(false);
    };

    return (
        <List hover>
            {/* MAINTENANCE STATE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                        {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState' })}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        <Rate
                            renderCharacter={renderCharacter}
                            readOnly={false}
                            cleanable={false}
                            defaultValue={maintenanceState}
                            onChange={value => setMaintenanceState(value)}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* INFORMATION NOTEPAD */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={24} className="bold">
                        {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.notepad' })}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-top-10">
                        <Input
                            onChange={value => setNotePad(value)}
                            componentClass="textarea"
                            maxLength={400}
                            rows={3}
                            defaultValue={notepad}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* BUTTONS */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <Button block color="red" disabled={isUpdating} onClick={() => cancelEdit()}>
                            <FontAwesomeIcon icon={faTimes} />
                            {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.cancel' })}
                        </Button>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <Button block color="green" loading={isUpdating} onClick={() => editMaintenanceState()}>
                            <FontAwesomeIcon icon={faCheck} />
                            {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.valid' })}
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
};

const renderCharacter = (value: number, index: number) => {
    if (value < index + 1) return <FontAwesomeIcon icon={faExclamationCircle} />;
    if (value === MaintenanceState.EVERYTHING_OK) return <FontAwesomeIcon icon={faExclamationCircle} color="green" />;
    if (value === MaintenanceState.LOW) return <FontAwesomeIcon icon={faExclamationCircle} color="#FFDA00" />;
    if (value === MaintenanceState.MEDIUM) return <FontAwesomeIcon icon={faExclamationCircle} color="orange" />;
    if (value === MaintenanceState.HIGH) return <FontAwesomeIcon icon={faExclamationCircle} color="#FF5349" />;
    if (value === MaintenanceState.VERY_HIGH) return <FontAwesomeIcon icon={faExclamationCircle} color="red" />;
};
