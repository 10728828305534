import { faCheck, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal, SelectPicker } from 'rsuite';
import { sensorPlaceTypeActions } from '../../../../redux/actions';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

const mapDispatchToProps = dispatch => ({
    createPlaceType: (label, description, placeTypeId) =>
        dispatch(sensorPlaceTypeActions.create(label, description, placeTypeId)),
    closeCreateModal: () => dispatch(sensorPlaceTypeActions.closeCreateModal()),
});

type Props = {
    closeCreateModal: Function;
    createPlaceType: Function;
    create_modal_openned: boolean;
} & WrappedComponentProps;

type State = {
    formValues: Record<string, any>;
    placeTypes: Array<Record<string, any>>;
    loading: boolean;
    formComplete: boolean;
};

class CreateSensorPlaceTypeModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            formValues: {
                label: null,
                description: null,
                placeTypeId: null,
            },
            placeTypes: [],
            loading: false,
            formComplete: false,
        };

        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createPlaceType = this.createPlaceType.bind(this);
        this.handleFetchPlaceType = this.handleFetchPlaceType.bind(this);
    }

    closeModal() {
        this.setState({
            formValues: {
                label: null,
                description: null,
                placeTypeId: null,
            },
            formComplete: false,
            placeTypes: [],
            loading: false,
        });

        this.props.closeCreateModal();
    }

    handleChange(formValues) {
        const { label, placeTypeId } = formValues;
        let formComplete = false;

        if (label && placeTypeId) {
            formComplete = true;
        }

        this.setState({
            formValues: formValues,
            formComplete,
        });
    }

    handleFetchPlaceType() {
        if (this.state.placeTypes.length === 0) {
            axiosService
                .getAxios()
                .get('/place-type', { headers: authHeader() })
                .then(response =>
                    response.data.map(placeType => {
                        return {
                            label: placeType.label,
                            value: placeType.id,
                        };
                    })
                )
                .then(placeTypes => {
                    this.setState({
                        placeTypes,
                    });
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    createPlaceType() {
        this.setState({
            loading: true,
        });

        this.props.createPlaceType(
            this.state.formValues.label,
            this.state.formValues.description,
            this.state.formValues.placeTypeId
        );

        this.closeModal();
    }

    render() {
        return (
            <Modal backdrop="static" show={this.props.create_modal_openned} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="sensorPlaceType.create.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="sensorPlaceType-create-modal">
                    <Form fluid formValue={this.state.formValues} onChange={this.handleChange}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="sensorPlaceType.create.label" />
                            </ControlLabel>
                            <FormControl name="label" data-cy="sensorPlaceType-create-modal-label" />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="sensorPlaceType.create.description" />
                            </ControlLabel>
                            <FormControl name="description" data-cy="sensorPlaceType-create-modal-description" />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="sensorPlaceType.create.type" />
                            </ControlLabel>
                            <FormControl
                                data-cy="sensorPlaceType-create-modal-type"
                                block
                                name="placeTypeId"
                                accepter={SelectPicker}
                                data={this.state.placeTypes}
                                onOpen={this.handleFetchPlaceType}
                                renderMenu={menu => {
                                    if (this.state.placeTypes.length === 0) {
                                        return (
                                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                            </p>
                                        );
                                    }
                                    return menu;
                                }}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={this.closeModal}
                            data-cy="sensorPlaceType-create-modal-cancel"
                            color="red"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.createPlaceType}
                            data-cy="sensorPlaceType-create-modal-valid"
                            appearance="primary"
                            loading={this.state.loading}
                            disabled={!this.state.formComplete}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    let { create_modal_openned } = state.sensorPlaceTypes;

    return { create_modal_openned };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateSensorPlaceTypeModal));
