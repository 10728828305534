import React from 'react';
import { FormattedMessage } from 'react-intl';

export function filterCell(confirm, filter, median) {
    return (
        <div data-tag="allowRowEvents">
            <span className="bold" data-tag="allowRowEvents">
                <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.filter.confirm" />
                <span data-cy="lpSensor-filterFree-confirm" data-tag="allowRowEvents" className="text-normal">
                    {confirm}
                </span>
            </span>
            <br />
            <span data-tag="allowRowEvents" className="bold">
                <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.filter.filter" />
                <span data-cy="lpSensor-filterFree-filter" data-tag="allowRowEvents" className="text-normal">
                    {filter}
                </span>
            </span>
            <br />
            <span data-tag="allowRowEvents" className="bold">
                <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.filter.median" />
                <span data-cy="lpSensor-filterFree-median" data-tag="allowRowEvents" className="text-normal">
                    {median}
                </span>
            </span>
        </div>
    );
}
