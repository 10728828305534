import { IntlShape } from 'react-intl';

export const taskTypes = (intl: IntlShape) => [
    {
        label: intl.formatMessage({
            id: 'tasks.forceTCMSensorColor',
        }),
        value: TaskTypeEnum.TCM_SENSOR_FORCE_COLOR,
    },
    {
        label: intl.formatMessage({
            id: 'tasks.disableForceTCMSensorColor',
        }),
        value: TaskTypeEnum.TCM_SENSOR_DISABLE_FORCE,
    },
    {
        label: intl.formatMessage({
            id: 'tasks.forceTCMDisplay',
        }),
        value: TaskTypeEnum.TCM_DISPLAY_FORCE,
    },
    {
        label: intl.formatMessage({
            id: 'tasks.disableForceTCMDisplay',
        }),
        value: TaskTypeEnum.TCM_DISPLAY_DISABLE_FORCE,
    },
    {
        label: intl.formatMessage({
            id: 'tasks.forceLPSensorColor',
        }),
        value: TaskTypeEnum.LP_SENSOR_FORCE_COLOR,
    },
    {
        label: intl.formatMessage({
            id: 'tasks.disableForceLPSensorColor',
        }),
        value: TaskTypeEnum.LP_SENSOR_DISABLE_FORCE,
    },
    {
        label: intl.formatMessage({
            id: 'tasks.forceLPMatrixDisplaysV2Black',
        }),
        value: TaskTypeEnum.LP_MATRIX_DISPLAY_V2_BLACK_FORCE,
    },
    {
        label: intl.formatMessage({
            id: 'tasks.enableForceLPMatrixDisplaysV2',
        }),
        value: TaskTypeEnum.LP_MATRIX_DISPLAY_V2_ENABLE_FORCE,
    },
    {
        label: intl.formatMessage({
            id: 'tasks.disableForceLPMatrixDisplayV2',
        }),
        value: TaskTypeEnum.LP_MATRIX_DISPLAY_V2_DISABLE_FORCE,
    },
    {
        label: intl.formatMessage({
            id: 'tasks.setGPIO',
        }),
        value: TaskTypeEnum.FORCE_IPCAN_GPIO,
    },
];

export const translatedTaskTypes = (intl: IntlShape, type: string): string => {
    switch (type) {
        case TaskTypeEnum.TCM_SENSOR_FORCE_COLOR:
            return intl.formatMessage({
                id: 'tasks.forceTCMSensorColor',
            });
        case TaskTypeEnum.TCM_SENSOR_DISABLE_FORCE:
            return intl.formatMessage({
                id: 'tasks.disableForceTCMSensorColor',
            });
        case TaskTypeEnum.TCM_DISPLAY_FORCE:
            return intl.formatMessage({
                id: 'tasks.forceTCMDisplay',
            });
        case TaskTypeEnum.TCM_DISPLAY_DISABLE_FORCE:
            return intl.formatMessage({
                id: 'tasks.disableForceTCMDisplay',
            });
        case TaskTypeEnum.LP_SENSOR_FORCE_COLOR:
            return intl.formatMessage({
                id: 'tasks.forceLPSensorColor',
            });
        case TaskTypeEnum.LP_SENSOR_DISABLE_FORCE:
            return intl.formatMessage({
                id: 'tasks.disableForceLPSensorColor',
            });
        case TaskTypeEnum.LP_MATRIX_DISPLAY_V2_ENABLE_FORCE:
            return intl.formatMessage({
                id: 'tasks.enableForceLPMatrixDisplaysV2',
            });
        case TaskTypeEnum.LP_MATRIX_DISPLAY_V2_BLACK_FORCE:
            return intl.formatMessage({
                id: 'tasks.forceLPMatrixDisplaysV2Black',
            });
        case TaskTypeEnum.LP_MATRIX_DISPLAY_V2_DISABLE_FORCE:
            return intl.formatMessage({
                id: 'tasks.disableForceLPMatrixDisplay',
            });
        case TaskTypeEnum.FORCE_IPCAN_GPIO:
            return intl.formatMessage({
                id: 'tasks.setGPIO',
            });
        default:
            return '';
    }
};

export enum TaskTypeEnum {
    TCM_SENSOR_FORCE_COLOR = 'tcm-sensor_force_color',
    TCM_SENSOR_DISABLE_FORCE = 'tcm-sensor_disable_force',
    TCM_DISPLAY_FORCE = 'tcm-display_force',
    TCM_DISPLAY_DISABLE_FORCE = 'tcm-display_disable_force',
    LP_SENSOR_FORCE_COLOR = 'lp-sensor_force_color',
    LP_SENSOR_DISABLE_FORCE = 'lp-sensor_disable_force',
    LP_MATRIX_DISPLAY_V2_ENABLE_FORCE = 'lp-matrixDisplayV2_enable_force',
    LP_MATRIX_DISPLAY_V2_BLACK_FORCE = 'lp-matrixDisplayV2_black_force',
    LP_MATRIX_DISPLAY_V2_DISABLE_FORCE = 'lp-matrixDisplayV2_disable_force',
    FORCE_IPCAN_GPIO = 'force_gpio',
}

export enum TCMSensorForceColorEnum {
    GREEN = 'green',
    RED = 'red',
    BLACK = 'black',
    GREEN_BLINK = 'green_blink',
    RED_BLINK = 'red_blink',
    RED_GREEN_BLINK = 'red_green_blink',
}

export const TCMSensorForceColorFrom = (intl: IntlShape) => [
    {
        label: intl.formatMessage({
            id: 'sensor.color.green',
        }),
        value: TCMSensorForceColorEnum.GREEN,
    },
    {
        label: intl.formatMessage({
            id: 'sensor.color.red',
        }),
        value: TCMSensorForceColorEnum.RED,
    },
    {
        label: intl.formatMessage({
            id: 'sensor.color.black',
        }),
        value: TCMSensorForceColorEnum.BLACK,
    },
    {
        label: intl.formatMessage({
            id: 'sensor.color.greenBlink',
        }),
        value: TCMSensorForceColorEnum.GREEN_BLINK,
    },
    {
        label: intl.formatMessage({
            id: 'sensor.color.redBlink',
        }),
        value: TCMSensorForceColorEnum.RED_BLINK,
    },
    {
        label: intl.formatMessage({
            id: 'sensor.color.redGreenBlink',
        }),
        value: TCMSensorForceColorEnum.RED_GREEN_BLINK,
    },
];

export enum TCMDisplayForceEnum {
    FULL = 0,
    CLOSE = 65535,
    FREE = 10000,
}

export const TCMDisplayForceForm = (intl: IntlShape) => [
    {
        label: intl.formatMessage({
            id: 'tcmDisplay.forceMode.full',
        }),
        value: TCMDisplayForceEnum.FULL,
    },
    {
        label: intl.formatMessage({
            id: 'tcmDisplay.forceMode.close',
        }),
        value: TCMDisplayForceEnum.CLOSE,
    },
    {
        label: intl.formatMessage({
            id: 'tcmDisplay.forceMode.free',
        }),
        value: TCMDisplayForceEnum.FREE,
    },
];

export const translatedTaskTCMDisplayForce = (intl: IntlShape, type: TCMDisplayForceEnum): string => {
    switch (type) {
        case TCMDisplayForceEnum.FULL:
            return intl.formatMessage({
                id: 'tcmDisplay.forceMode.full',
            });
        case TCMDisplayForceEnum.CLOSE:
            return intl.formatMessage({
                id: 'tcmDisplay.forceMode.close',
            });
        case TCMDisplayForceEnum.FREE:
            return intl.formatMessage({
                id: 'tcmDisplay.forceMode.free',
            });
        default:
            return '';
    }
};
