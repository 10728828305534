export const ipCanDevicesConstants = {
    GET_BUS_DEVICES_REQUEST: 'IPCAN_DEVICES_GET_BUS_REQUEST',
    GET_BUS_DEVICES_SUCCESS: 'IPCAN_DEVICES_GET_BUS_SUCCESS',
    GET_BUS_DEVICES_FAILURE: 'IPCAN_DEVICES_GET_BUS_FAILURE',

    UPDATE_DISPLAY_CONFIGURATION_REQUEST: 'IPCAN_DEVICES_UPDATE_DISPLAY_CONFIGURATION_REQUEST',
    UPDATE_DISPLAY_CONFIGURATION_SUCCESS: 'IPCAN_DEVICES_UPDATE_DISPLAY_CONFIGURATION_SUCCESS',
    UPDATE_DISPLAY_CONFIGURATION_FAILURE: 'IPCAN_DEVICES_UPDATE_DISPLAY_CONFIGURATION_FAILURE',

    DELETE_DISPLAY_REQUEST: 'IPCAN_DEVICES_DELETE_DISPLAY_REQUEST',
    DELETE_DISPLAY_SUCCESS: 'IPCAN_DEVICES_DELETE_DISPLAY_SUCCESS',
    DELETE_DISPLAY_FAILURE: 'IPCAN_DEVICES_DELETE_DISPLAY_FAILURE',

    UPDATE_SENSOR_REQUEST: 'IPCAN_DEVICES_UPDATE_SENSOR_REQUEST',
    UPDATE_SENSOR_SUCCESS: 'IPCAN_DEVICES_UPDATE_SENSOR_SUCCESS',
    UPDATE_SENSOR_FAILURE: 'IPCAN_DEVICES_UPDATE_SENSOR_FAILURE',

    DELETE_SENSOR_REQUEST: 'IPCAN_DEVICES_DELETE_SENSOR_REQUEST',
    DELETE_SENSOR_SUCCESS: 'IPCAN_DEVICES_DELETE_SENSOR_SUCCESS',
    DELETE_SENSOR_FAILURE: 'IPCAN_DEVICES_DELETE_SENSOR_FAILURE',

    WEBSOCKET_UPDATE_SENSOR: 'IPCAN_DEVICES_WEBSOCKET_UPDATE_SENSOR',
    WEBSOCKET_UPDATE_DISPLAY: 'IPCAN_DEVICES_WEBSOCKET_UPDATE_DISPLAY',

    UPDATE_SENSOR_FORCE: 'IPCAN_DEVICES_UPDATE_SENSOR_FORCE',
    UPDATE_DISPLAY_FORCE: 'IPCAN_DEVICES_UPDATE_DISPLAY_FORCE',
};
