import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LPSensorUsSensValues } from '../../../handlers/ipCanDevices/Sensor/LPSensorUsConfig';

export default function usSensCell(sens_detection, sens_release) {
    return (
        <div className="text-center" data-tag="allowRowEvents">
            <span className="bold" data-tag="allowRowEvents">
                <FormattedMessage id="ipCanDevices.LPSensors.usSens.detection" data-tag="allowRowEvents" />
                <span data-cy="lpSensor-sDetection" className="text-normal" data-tag="allowRowEvents">
                    {LPSensorUsSensValues(sens_detection)}
                </span>
            </span>
            <br />
            <span className="bold" data-tag="allowRowEvents">
                <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.usSens.release" />
                <span data-cy="lpSensor-sRelease" className="text-normal" data-tag="allowRowEvents">
                    {LPSensorUsSensValues(sens_release)}
                </span>
            </span>
        </div>
    );
}
