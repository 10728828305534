import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { CameraErrorFlag } from '../../../handlers/Camera/Camera';

type Props = {
    errorFlag: CameraErrorFlag;
};

export const ErrorFlag = (props: Props) => {
    return (
        <div data-tag="allowRowEvents" className="text-center">
            {props.errorFlag === CameraErrorFlag.OK && <FontAwesomeIcon size="lg" icon={faCheckCircle} color="green" />}
            {props.errorFlag === CameraErrorFlag.BAD_CONFIG && (
                <>
                    <FontAwesomeIcon size="lg" icon={faExclamationTriangle} color="red" />
                    <FormattedMessage id="camera.errorFlag.badConfig" />
                </>
            )}
            {props.errorFlag === CameraErrorFlag.BAD_LOGIN && (
                <>
                    <FontAwesomeIcon size="lg" icon={faExclamationTriangle} color="orange" />
                    <FormattedMessage id="camera.errorFlag.badlogin" />
                </>
            )}
        </div>
    );
};
