import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function onlineState(online) {
    switch (online) {
        case 0:
            return (
                <div data-cy="controllers-disconnected" data-tag="allowRowEvents">
                    <FontAwesomeIcon data-tag="allowRowEvents" icon={faPlug} style={{ color: 'red' }} />
                </div>
            );
        case 1:
            return (
                <div data-cy="controllers-loading" data-tag="allowRowEvents">
                    <FontAwesomeIcon data-tag="allowRowEvents" icon={faPlug} style={{ color: 'orange' }} />
                </div>
            );
        case 2:
            return (
                <div data-cy="controllers-connected" data-tag="allowRowEvents">
                    <FontAwesomeIcon data-tag="allowRowEvents" icon={faPlug} style={{ color: 'green' }} />
                </div>
            );
        default:
            return (
                <div data-cy="controllers-default" data-tag="allowRowEvents">
                    <FontAwesomeIcon data-tag="allowRowEvents" icon={faPlug} style={{ color: 'red' }} />
                </div>
            );
    }
}
