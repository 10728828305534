import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Layer, Rect, Stage } from 'react-konva';
import { PictoRGBValueInterface } from '..';

type Props = {
    pictos: PictoRGBValueInterface[];
    selectedPictoIndex: number;
    handleClick: (line: number, column: number) => void;
};

const PREVIEW_SIZE = 850;

export const PictoEditor = (props: Props) => {
    const picto = props.pictos[props.selectedPictoIndex];

    let squareSize: number = picto ? PREVIEW_SIZE / picto.value.length : 0;

    if (picto && picto.value.length > 0 && picto.value.length < picto.value[0].length) {
        squareSize = PREVIEW_SIZE / picto.value[0].length;
    }

    const handleClick = event => {
        const column = parseInt(event.target.attrs.name);
        const line = parseInt(event.target.attrs.id);

        props.handleClick(line, column);
    };

    if (picto) {
        return (
            <>
                <h3 className="text-center margin-bottom-15" style={{ color: 'black' }}>
                    <FormattedMessage id="editPicto.picto" /> - {picto.name}{' '}
                </h3>
                {picto.value.length > 0 && squareSize && (
                    <div data-cy="pictoRGB-canvas">
                        <Stage width={squareSize * picto.value[0].length} height={PREVIEW_SIZE}>
                            {squareSize !== Infinity && squareSize !== 0 && (
                                <Layer>
                                    {picto.value.map((line, lineIndex) => {
                                        return line.map((pixel, pixelIndex) => (
                                            <Rect
                                                x={pixelIndex * squareSize}
                                                y={lineIndex * squareSize}
                                                strokeWidth={1}
                                                stroke="grey"
                                                width={squareSize}
                                                height={squareSize}
                                                fill={`rgb(${pixel.r}, ${pixel.g}, ${pixel.b})`}
                                                id={lineIndex.toString()}
                                                name={pixelIndex.toString()}
                                                onClick={handleClick}
                                            />
                                        ));
                                    })}
                                </Layer>
                            )}
                        </Stage>
                    </div>
                )}
            </>
        );
    } else {
        return <></>;
    }
};
