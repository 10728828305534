import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Col, DatePicker, FlexboxGrid, List, Loader, Panel, SelectPicker } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import SecuredFragment from '../../Auth/SecuredFragment';
import PanelHeader from '../../Custom/PanelHeader';

type Props = WrappedComponentProps;

type State = {
    date: Date | undefined;
    timezone: number;
    dateForm: Date | undefined;
    isLoading: boolean;
    isEditMode: boolean;
    timezoneValue: number;
    summerTime: boolean;
};

let timeInterval;

class DateTimeCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            date: undefined,
            timezone: 0,
            dateForm: undefined,
            isLoading: true,
            isEditMode: false,
            timezoneValue: 0,
            summerTime: false,
        };
    }

    componentDidMount = () => {
        this.startInterval();
        axiosService
            .getAxios()
            .get('/configuration/system/date', {
                headers: authHeader(),
            })
            .then(dateResponse => {
                const timezone = dateResponse.data.timezone / 60;
                this.setState({
                    date: moment(dateResponse.data.date).utc().add(timezone, 'hour').toDate(),
                    timezone,
                });
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage(
                        {
                            id: 'configuration.date.fetchDateError',
                        },
                        {
                            error: err,
                        }
                    )
                );
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    componentWillUnmount = () => {
        this.stopInterval();
    };

    stopInterval = () => {
        if (timeInterval) {
            clearInterval(timeInterval);
        }
    };

    startInterval = () => {
        timeInterval = setInterval(() => {
            this.setState({
                date: moment(this.state.date).add(1, 'second').toDate(),
            });
        }, 1000);
    };

    openEditDateTimeModal = () => {
        this.setState({
            isEditMode: true,
            dateForm: new Date(),
        });
    };

    handleChange = value => {
        this.setState({
            dateForm: value,
        });
    };

    handleTimezoneChange = value => {
        this.setState({
            timezoneValue: value,
        });
    };

    validEdition = () => {
        this.setState({
            isLoading: true,
        });

        const date = moment(this.state.dateForm).format('YYYY-MM-DD HH:mm:ss');

        axiosService
            .getAxios()
            .put(
                '/configuration/system/date',
                {
                    date,
                    timezone:
                        this.state.timezoneValue < 0
                            ? `GMT${this.state.timezoneValue}`
                            : `GMT+${this.state.timezoneValue}`,
                    isSummerTime: false,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateDateResponse => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'configuration.date.updateDateSuccess',
                    })
                );

                const timezone = updateDateResponse.data.timezone / 60;
                this.setState({
                    date: moment(updateDateResponse.data.date).subtract(timezone, 'hour').toDate(),
                    timezone,
                });

                axiosService.getAxios().put('/configuration/rebootAutomate', {}, { headers: authHeader() });
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage(
                        {
                            id: 'configuration.date.updateDateError',
                        },
                        {
                            error: err,
                        }
                    )
                );
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                    isEditMode: false,
                });
            });
    };

    cancelEdition = () => {
        this.setState({
            isEditMode: false,
        });
    };

    generateTimezoneList = (): Array<{ label: string; value: number }> => {
        let timezoneList: Array<{ label: string; value: number }> = [];

        for (let i: number = -11; i <= 12; i++) {
            const label = i < 0 ? `GMT${i}` : `GMT+${i}`;
            timezoneList.push({
                label,
                value: -i,
            });
        }

        return timezoneList;
    };

    render() {
        return (
            <Fragment>
                <Panel
                    bodyFill
                    shaded
                    className="panel-small"
                    header={
                        <SecuredFragment authorizedRoles={[]}>
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'configuration.datetime' })}
                                buttons={[
                                    <Button
                                        key="datetime-edit"
                                        color="blue"
                                        onClick={this.openEditDateTimeModal}
                                        disabled={this.state.isEditMode || this.state.isLoading}
                                        size="xs">
                                        <FontAwesomeIcon icon={faEdit} className="magin-right-10" />
                                        <FormattedMessage id="configuration.datetime.edit" />
                                    </Button>,
                                ]}
                            />
                        </SecuredFragment>
                    }>
                    <List>
                        <SecuredFragment authorizedRoles={[]}>
                            <List.Item key="configuration.update" index={0} className="panel-list">
                                <FlexboxGrid align="middle" justify="space-between">
                                    <FlexboxGrid.Item className="bold" componentClass={Col} xs={12}>
                                        <FormattedMessage id="configuration.action.newDate" />
                                    </FlexboxGrid.Item>
                                    {this.state.isEditMode ? (
                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <DatePicker
                                                format="DD/MM/YYYY HH:mm:ss"
                                                value={this.state.dateForm}
                                                ranges={[
                                                    {
                                                        label: this.props.intl.formatMessage({
                                                            id: 'now',
                                                        }),
                                                        value: new Date(),
                                                    },
                                                ]}
                                                onChange={this.handleChange}
                                                cleanable={false}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <FlexboxGrid.Item>
                                            {this.state.isLoading ? (
                                                <Loader />
                                            ) : (
                                                moment(this.state.date).utc().format('DD/MM/YYYY HH:mm:ss')
                                            )}
                                        </FlexboxGrid.Item>
                                    )}
                                </FlexboxGrid>
                            </List.Item>
                            <List.Item className="panel-list">
                                <FlexboxGrid align="middle" justify="space-between">
                                    <FlexboxGrid.Item className="bold" componentClass={Col} xs={12}>
                                        <FormattedMessage id="configuration.action.GMTTimezone" />
                                    </FlexboxGrid.Item>
                                    {this.state.isEditMode ? (
                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <SelectPicker
                                                data={this.generateTimezoneList()}
                                                onChange={this.handleTimezoneChange}
                                                cleanable={false}
                                                searchable={false}
                                                placement={'auto'}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <FlexboxGrid.Item>
                                            {this.state.timezone > 0
                                                ? `GMT+${this.state.timezone}`
                                                : `GMT-${-1 * this.state.timezone}`}
                                        </FlexboxGrid.Item>
                                    )}
                                </FlexboxGrid>
                            </List.Item>

                            {this.state.isEditMode && (
                                <List.Item className="panel-list">
                                    <FlexboxGrid align="middle" justify="end">
                                        <ButtonGroup>
                                            <Button
                                                data-cy="task-edit"
                                                color="green"
                                                size="sm"
                                                onClick={this.validEdition}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                            <Button
                                                data-cy="task-delete"
                                                color="red"
                                                size="sm"
                                                onClick={this.cancelEdition}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                        </ButtonGroup>
                                    </FlexboxGrid>
                                </List.Item>
                            )}
                        </SecuredFragment>
                    </List>
                </Panel>
            </Fragment>
        );
    }
}

export default injectIntl(DateTimeCard);
