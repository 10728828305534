import { faCheck, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, InputNumber, InputPicker, Modal, Table } from 'rsuite';
import Counter from '../../../../handlers/Counter/Counter';
import { EventCounterCondition, EventCounterConditionTriggerType } from '../../../../handlers/EventAction/EventAction';

type Props = {
    counters: Counter[];
    isEditMode: boolean;
    currentCounters: EventCounterCondition[];
    onCountersChange: (counters: EventCounterCondition[]) => void;
    show: boolean;
    onHide: () => void;
};

export const EditEventCounterModal = (props: Props) => {
    const intl = useIntl();

    const triggerCondition = useMemo(() => {
        return [
            {
                label: EventCounterConditionTriggerType.LOWER,
                value: EventCounterConditionTriggerType.LOWER,
            },
            {
                label: EventCounterConditionTriggerType.EQUALITY,
                value: EventCounterConditionTriggerType.EQUALITY,
            },
            {
                label: EventCounterConditionTriggerType.SUPERIOR,
                value: EventCounterConditionTriggerType.SUPERIOR,
            },
        ];
    }, []);

    const handleAddCounter = () => {
        props.onCountersChange([
            ...props.currentCounters,
            {
                counterId: props.counters[0].id,
                triggerType: EventCounterConditionTriggerType.LOWER,
                triggerValue: 0,
            },
        ]);
    };

    const handleCounterChange = (counterId: number, index: number) => {
        const newCounters = [...props.currentCounters];
        newCounters[index].counterId = counterId;
        props.onCountersChange(newCounters);
    };

    const handleConditionChange = (condition: EventCounterConditionTriggerType, index: number) => {
        const newCounters = [...props.currentCounters];
        newCounters[index].triggerType = condition;
        props.onCountersChange(newCounters);
    };

    const handleValueChange = (value: number, index: number) => {
        const newCounters = [...props.currentCounters];
        newCounters[index].triggerValue = value;
        props.onCountersChange(newCounters);
    };

    const handleDeleteCounter = (index: number) => {
        const newCounters = [...props.currentCounters];
        newCounters.splice(index, 1);
        props.onCountersChange(newCounters);
    };

    const counters = useMemo(() => {
        return props.counters.map(counter => {
            return {
                label: counter.label,
                value: counter.id,
            };
        });
    }, [props.counters]);

    return (
        <Modal backdrop="static" show={props.show} onHide={props.onHide} data-cy="event-counter-edit-modal">
            <Modal.Header>
                <Modal.Title>{intl.formatMessage({ id: 'event.counters.title' })}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Table
                    bordered
                    height={300}
                    data={props.currentCounters}
                    renderEmpty={() => (
                        <div className="text-center" style={{ marginTop: 32 }}>
                            <p style={{ marginBottom: 8 }}>{intl.formatMessage({ id: 'event.counter.noCounter' })}</p>
                            {props.isEditMode && (
                                <Button onClick={handleAddCounter} appearance="primary" color="blue">
                                    <FontAwesomeIcon icon={faPlus} /> {intl.formatMessage({ id: 'event.counter.add' })}
                                </Button>
                            )}
                        </div>
                    )}>
                    <Table.Column flexGrow={2}>
                        <Table.HeaderCell>{intl.formatMessage({ id: 'event.counter.name' })}</Table.HeaderCell>
                        <EditCounterCell
                            isEditMode={props.isEditMode}
                            counters={counters}
                            dataKey="counterId"
                            onChange={handleCounterChange}
                            rowData
                        />
                    </Table.Column>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>{intl.formatMessage({ id: 'event.counter.condition' })}</Table.HeaderCell>
                        <EditCounterConditionCell
                            isEditMode={props.isEditMode}
                            dataKey="triggerType"
                            onChange={handleConditionChange}
                            rowData
                            triggers={triggerCondition}
                        />
                    </Table.Column>
                    <Table.Column flexGrow={1}>
                        <Table.HeaderCell>{intl.formatMessage({ id: 'event.counter.value' })}</Table.HeaderCell>
                        <EditValueCell
                            isEditMode={props.isEditMode}
                            dataKey="triggerValue"
                            onChange={handleValueChange}
                            rowData
                        />
                    </Table.Column>
                    {props.isEditMode && (
                        <Table.Column width={50}>
                            <Table.HeaderCell>{intl.formatMessage({ id: 'event.counter.delete' })}</Table.HeaderCell>
                            <DeleteButtonCell dataKey="delete" onClick={handleDeleteCounter} rowData />
                        </Table.Column>
                    )}
                </Table>

                {props.isEditMode && (
                    <div className="text-center" style={{ marginTop: 8 }}>
                        <Button onClick={handleAddCounter} appearance="ghost">
                            <FontAwesomeIcon icon={faPlus} /> {intl.formatMessage({ id: 'event.counter.add' })}
                        </Button>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={props.onHide} appearance="primary" color="green" data-cy="event-counter-edit-valid">
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const EditValueCell = ({ rowData, dataKey, onChange, isEditMode, ...props }) => {
    if (!isEditMode) {
        return <Table.Cell {...props}>{rowData[dataKey]}</Table.Cell>;
    }
    return (
        <Table.Cell {...props} className="form-table">
            <InputNumber
                value={rowData[dataKey]}
                min={0}
                onChange={value => onChange(value, props.rowIndex)}
                data-cy={`counter-value-${props.rowIndex}`}
            />
        </Table.Cell>
    );
};

export const EditCounterCell = ({ rowData, dataKey, onChange, counters, isEditMode, ...props }) => {
    if (!isEditMode) {
        const counter = counters.find(counter => counter.value === rowData[dataKey]);
        return <Table.Cell {...props}>{counter ? counter.label : ''}</Table.Cell>;
    }

    return (
        <Table.Cell {...props} className="form-table">
            <InputPicker
                cleanable={false}
                data={counters}
                value={rowData[dataKey]}
                onChange={value => onChange(value, props.rowIndex)}
                data-cy={`counter-counterId-${props.rowIndex}`}
            />
        </Table.Cell>
    );
};

export const EditCounterConditionCell = ({ rowData, dataKey, onChange, triggers, isEditMode, ...props }) => {
    if (!isEditMode) {
        const trigger = triggers.find(trigger => trigger.value === rowData[dataKey]);
        return <Table.Cell {...props}>{trigger ? trigger.label : ''}</Table.Cell>;
    }

    return (
        <Table.Cell {...props} className="form-table">
            <InputPicker
                cleanable={false}
                data={triggers}
                value={rowData[dataKey]}
                data-cy={`counter-condition-${props.rowIndex}`}
                onChange={value => onChange(value, props.rowIndex)}
            />
        </Table.Cell>
    );
};

export const DeleteButtonCell = ({ rowData, dataKey, onClick, ...props }) => {
    return (
        <Table.Cell {...props} className="form-table">
            <Button
                onClick={() => onClick(props.rowIndex)}
                appearance="primary"
                color="red"
                data-cy={`counter-delete-${props.rowIndex}`}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        </Table.Cell>
    );
};
