import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    List,
    Loader,
    Message,
    Panel,
    Tag,
    TagGroup,
    TagPicker,
} from 'rsuite';
import Counter from '../../../../../handlers/Counter/Counter';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';

type Props = {
    display: any;
    service: any;
} & WrappedComponentProps;

type State = {
    isEditCountersMode: boolean;
    isUpdatingCounters: boolean;
    hasError: boolean;
    isEditSubstractCountersMode: boolean;
    isUpdatingSubstractionCounters: boolean;
    countersFormValue: CountersFormValue;
    substractionCountersFormValue: SubstractionCountersFormValue;
    isFetchingCounter: boolean;
    counters: Counter[];
};

type CountersFormValue = {
    counters: number[];
};

type SubstractionCountersFormValue = {
    counters: number[];
};

class CounterConfiguration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditCountersMode: false,
            isUpdatingCounters: false,
            hasError: false,
            isEditSubstractCountersMode: false,
            isUpdatingSubstractionCounters: false,
            countersFormValue: {
                counters: this.props.display.tcmDisplay.counters.map(counter => counter.id),
            },
            substractionCountersFormValue: {
                counters: this.props.display.tcmDisplay.substractionCounters.map(counter => counter.id),
            },
            isFetchingCounter: true,
            counters: [],
        };
    }

    componentDidMount = () => {
        axiosService
            .getAxios()
            .get('/counters', { headers: authHeader() })
            .then(countersResponse => {
                this.setState({
                    counters: countersResponse.data.map(counter => new Counter(counter)),
                    isFetchingCounter: false,
                });
            });
    };

    generateSelectPickerValue = (): { label: string; value: number }[] => {
        return this.state.counters.map(counter => {
            return {
                label: counter.label,
                value: counter.id,
            };
        });
    };

    setEditCountersMode = () => {
        this.setState({
            isEditCountersMode: true,
        });
    };

    cancelEditCountersMode = () => {
        this.setState({
            isEditCountersMode: false,
            countersFormValue: {
                counters: this.props.display.tcmDisplay.counters.map(counter => counter.id),
            },
        });
    };

    validEditCountersMode = () => {
        this.setState({
            isUpdatingCounters: true,
            hasError: false,
        });

        axiosService
            .getAxios()
            .put(
                '/devices/tcm-display/updateCounters',
                {
                    id: this.props.display.tcmDisplay.id,
                    tabCounterId: this.state.countersFormValue.counters,
                },
                { headers: authHeader() }
            )
            .then(countersResponse => {
                this.setState({
                    isUpdatingCounters: false,
                    isEditCountersMode: false,
                });

                this.props.service.send('DISPLAY_UPDATE', { display: countersResponse.data });

                Alert.success(this.props.intl.formatMessage({ id: 'map.tcmDisplay.edit.counters.success' }));
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                    isUpdatingCounters: false,
                });
            });
    };

    setEditSubstractCountersMode = () => {
        this.setState({
            isEditSubstractCountersMode: true,
        });
    };

    cancelEditSubstractCountersMode = () => {
        this.setState({
            isEditSubstractCountersMode: true,
            substractionCountersFormValue: {
                counters: this.props.display.tcmDisplay.substractionCounters.map(counter => counter.id),
            },
        });
    };

    validEditSubstractionCountersMode = () => {
        this.setState({
            isUpdatingSubstractionCounters: true,
            hasError: false,
        });

        axiosService
            .getAxios()
            .put(
                '/devices/tcm-display/updateSubstractionCounters',
                {
                    id: this.props.display.tcmDisplay.id,
                    tabCounterId: this.state.substractionCountersFormValue.counters,
                },
                { headers: authHeader() }
            )
            .then(countersResponse => {
                this.setState({
                    isUpdatingSubstractionCounters: false,
                    isEditSubstractCountersMode: false,
                });

                this.props.service.send('DISPLAY_UPDATE', { display: countersResponse.data });

                Alert.success(this.props.intl.formatMessage({ id: 'map.tcmDisplay.edit.subCounters.success' }));
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                    isUpdatingSubstractionCounters: false,
                });
            });
    };

    generateCountersEditPart = () => (
        <Fragment>
            {this.state.isFetchingCounter ? (
                <Loader />
            ) : (
                <Form fluid onChange={this.handleCountersChange} formValue={this.state.countersFormValue}>
                    <FormGroup>
                        <FormControl
                            data-cy="tcmDisplay-edit-addCounter"
                            accepter={TagPicker}
                            data={this.generateSelectPickerValue()}
                            name="counters"
                            placement="auto"
                            block
                            cleanable={false}
                            searchable={true}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`configuration-addCounter-value-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FormGroup>
                </Form>
            )}
        </Fragment>
    );

    generateCountersViewPart = () => (
        <TagGroup>
            {this.props.display.tcmDisplay.counters.map(counter => (
                <Tag data-cy="tcmDisplay-addCounter" color="blue">
                    {counter.label}
                </Tag>
            ))}
        </TagGroup>
    );

    handleCountersChange = formValues => {
        this.setState({
            countersFormValue: formValues,
        });
    };

    generateSubstractCountersEditPart = () => (
        <Fragment>
            {this.state.isFetchingCounter ? (
                <Loader />
            ) : (
                <Form
                    fluid
                    onChange={this.handleSubstractionCountersChange}
                    formValue={this.state.substractionCountersFormValue}>
                    <FormGroup>
                        <FormControl
                            data-cy="tcmDisplay-edit-substractCounter"
                            accepter={TagPicker}
                            data={this.generateSelectPickerValue()}
                            name="counters"
                            placement="auto"
                            block
                            cleanable={false}
                            searchable={true}
                            renderMenuItem={(label, item) => {
                                return (
                                    <div data-cy={`configuration-substractCounter-value-${item.value}`}>{label}</div>
                                );
                            }}
                        />
                    </FormGroup>
                </Form>
            )}
        </Fragment>
    );

    generateSubstractCountersViewPart = () => (
        <TagGroup>
            {this.props.display.tcmDisplay.substractionCounters.map(counter => (
                <Tag data-cy="tcmDisplay-substractCounter" color="blue">
                    {counter.label}
                </Tag>
            ))}
        </TagGroup>
    );

    handleSubstractionCountersChange = formValues => {
        this.setState({
            substractionCountersFormValue: formValues,
        });
    };

    render = () => (
        <Panel
            className="panel-small"
            bordered
            bodyFill
            shaded
            header={
                <PanelHeader
                    title={this.props.intl.formatMessage({
                        id: 'map.tcmDisplay.edit.counters',
                    })}
                />
            }>
            {this.state.hasError && (
                <Message
                    type="error"
                    description={this.props.intl.formatMessage({ id: 'map.tcmDisplay.edit.information.error' })}
                />
            )}
            <List>
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={8} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.addCounters" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            {this.state.isEditCountersMode
                                ? this.generateCountersEditPart()
                                : this.generateCountersViewPart()}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {this.state.isEditCountersMode ? (
                                <ButtonGroup>
                                    <Button
                                        data-cy="tcmDisplay-edit-addCounter-cancel"
                                        size="sm"
                                        color="red"
                                        onClick={this.cancelEditCountersMode}
                                        disabled={this.state.isUpdatingCounters}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                    <Button
                                        data-cy="tcmDisplay-edit-addCounter-valid"
                                        size="sm"
                                        color="green"
                                        onClick={this.validEditCountersMode}
                                        loading={this.state.isUpdatingCounters}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </ButtonGroup>
                            ) : (
                                <Button
                                    data-cy="tcmDisplay-edit-addCounters"
                                    size="sm"
                                    color="blue"
                                    onClick={this.setEditCountersMode}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={8} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.substractCounters" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            {this.state.isEditSubstractCountersMode
                                ? this.generateSubstractCountersEditPart()
                                : this.generateSubstractCountersViewPart()}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {this.state.isEditSubstractCountersMode ? (
                                <ButtonGroup>
                                    <Button
                                        data-cy="tcmDisplay-edit-substractCounter-cancel"
                                        size="sm"
                                        color="red"
                                        onClick={this.cancelEditSubstractCountersMode}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                    <Button
                                        data-cy="tcmDisplay-edit-substractCounter-valid"
                                        size="sm"
                                        color="green"
                                        onClick={this.validEditSubstractionCountersMode}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </ButtonGroup>
                            ) : (
                                <Button
                                    data-cy="tcmDisplay-edit-substractCounters"
                                    size="sm"
                                    color="blue"
                                    onClick={this.setEditSubstractCountersMode}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Panel>
    );
}

export default injectIntl(CounterConfiguration);
