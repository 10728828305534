import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, ButtonGroup, Modal } from 'rsuite';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    show: boolean;
    close: Function;
    level: Record<string, any>;
    deleteLevel: Function;
};

type State = {
    loading: boolean;
};
export default class DeleteLevelModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.deleteLevel = this.deleteLevel.bind(this);
    }

    deleteLevel() {
        this.setState({
            loading: true,
        });

        const data = {
            headers: authHeader(),
            data: { id: this.props.level.id },
        };

        axiosService
            .getAxios()
            .delete('/levels', data)
            .then(() => {
                this.props.close(true);

                this.props.deleteLevel(this.props.level.id);
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <Modal backdrop={'static'} show={this.props.show} onHide={() => this.props.close()}>
                <Modal.Header>
                    <Modal.Title>Supprimer un niveau</Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="level-deleteModal">
                    Voulez-vous supprimer le niveau <b>{this.props.level.name}</b> ?
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.props.close(false)}
                            data-cy="level-deleteModal-cancel"
                            color="red"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.deleteLevel}
                            data-cy="level-deleteModal-delete"
                            appearance="primary"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}
