import { faArrowLeft, faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    ControlLabel,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Schema,
    TagPicker,
    Toggle,
} from 'rsuite';
import { EventConditionType } from '../../../../handlers/EventAction/EventAction';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { EventActionFormDataType } from '../CreateEventModal';

const { StringType, DateType, BooleanType, ArrayType } = Schema.Types;

type Props = {
    formData: EventActionFormDataType;
    setFormData: (data: EventActionFormDataType) => void;
    handleCancel: () => void;
    handleGoBack: () => void;
};

export const StepTwoCalendar = (props: Props) => {
    const intl = useIntl();

    const formRef = useRef<any>(null);

    const [isLoading, setIsLoading] = useState(false);

    const handleFormChange = (value: any) => {
        props.setFormData({ ...props.formData, ...value });
    };

    const model = useMemo(() => {
        return Schema.Model({
            name: StringType().isRequired(intl.formatMessage({ id: 'form.requiredField' })),
            desc: StringType().isRequired(intl.formatMessage({ id: 'form.requiredField' })),
            isInfinite: BooleanType(),
            startTime: DateType().isRequired(intl.formatMessage({ id: 'form.requiredField' })),
            daily: ArrayType()
                .minLength(1, intl.formatMessage({ id: 'form.requiredField' }))
                .maxLength(7, intl.formatMessage({ id: 'form.requiredField' })),
            startDate: DateType()
                .addRule((value, data) => {
                    if (data.isInfinite && !value) {
                        return false;
                    }

                    return true;
                }, intl.formatMessage({ id: 'form.requiredField' }))
                .addRule((value, data) => {
                    if (!data.isInfinite && data.endDate && value && moment(value).isAfter(data.endDate)) {
                        return false;
                    }

                    return true;
                }, intl.formatMessage({ id: 'form.dateShouldNotBeAfter' })),
            endDate: DateType()
                .addRule((value, data) => {
                    if (data.isInfinite && !value) {
                        return false;
                    }

                    return true;
                }, intl.formatMessage({ id: 'form.requiredField' }))
                .addRule((value, data) => {
                    if (!data.isInfinite && data.startDate && value && moment(value).isBefore(data.startDate)) {
                        return false;
                    }

                    return true;
                }, intl.formatMessage({ id: 'form.dateShouldNotBeBefore' })),
        });
    }, []);

    const daysTag = useMemo(() => {
        return [
            {
                label: intl.formatMessage({ id: 'day.monday' }),
                value: 'monday',
            },
            {
                label: intl.formatMessage({ id: 'day.tuesday' }),
                value: 'tuesday',
            },
            {
                label: intl.formatMessage({ id: 'day.wednesday' }),
                value: 'wednesday',
            },
            {
                label: intl.formatMessage({ id: 'day.thursday' }),
                value: 'thursday',
            },
            {
                label: intl.formatMessage({ id: 'day.friday' }),
                value: 'friday',
            },
            {
                label: intl.formatMessage({ id: 'day.saturday' }),
                value: 'saturday',
            },
            {
                label: intl.formatMessage({ id: 'day.sunday' }),
                value: 'sunday',
            },
        ];
    }, []);

    const handleSubmit = () => {
        if (formRef.current?.check()) {
            setIsLoading(true);

            const formattedStartTime = moment(props.formData.startTime);
            const dailyValues = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            };

            props.formData.daily.forEach((day: string) => {
                dailyValues[day] = true;
            });

            axiosService
                .getAxios()
                .post(
                    '/event-actions/event',
                    {
                        name: props.formData.name,
                        desc: props.formData.desc,
                        isInfinite: props.formData.isInfinite,
                        daily: dailyValues,
                        startDate: props.formData.startDate,
                        endDate: props.formData.endDate,
                        eventCondition: {
                            eventConditionType: EventConditionType.CALENDAR,
                            startTime: formattedStartTime.get('hours') * 60 + formattedStartTime.get('minutes'),
                            autoResetTrigger: 'auto',
                            autoResetTriggerTime: 0,
                        },
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    setIsLoading(false);
                    props.handleCancel();
                })
                .catch(err => {
                    setIsLoading(false);

                    Alert.error(intl.formatMessage({ id: 'event.createTask.error' }));

                    console.error(err);
                });

            return;
        }

        Alert.error(intl.formatMessage({ id: 'form.error' }));
    };

    return (
        <Form fluid formValue={props.formData} onChange={handleFormChange} ref={formRef} model={model}>
            <FormGroup>
                <ControlLabel>
                    <FormattedMessage id="event.createEvent.name" />
                </ControlLabel>
                <FormControl data-cy="event-add-name" name="name" />
            </FormGroup>
            <FormGroup>
                <ControlLabel>
                    <FormattedMessage id="event.createEvent.desc" />
                </ControlLabel>
                <FormControl data-cy="event-add-desc" name="desc" />
            </FormGroup>
            <FormGroup>
                <ControlLabel>
                    <FormattedMessage id="event.createEvent.startTime" />
                </ControlLabel>
                <FormControl
                    data-cy="event-add-startTime"
                    name="startTime"
                    accepter={DatePicker}
                    // onSelect={value => this.handleDateChange(value)}
                    cleanable={false}
                    format="HH:mm"
                    ranges={[]}
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>
                    <FormattedMessage id="event.createEvent.daily" />
                </ControlLabel>
                <FormControl
                    data-cy="event-add-daily"
                    name="daily"
                    accepter={TagPicker}
                    data={daysTag}
                    renderMenuItem={(label, item) => {
                        return <div data-cy={`event-createEvent-value-${item.value}`}>{label}</div>;
                    }}
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>
                    <FormattedMessage id="event.createEvent.isInfinite" />
                </ControlLabel>
                <FormControl
                    data-cy="event-add-isInfinite"
                    name="isInfinite"
                    accepter={Toggle}
                    cleanable={false}
                    defaultChecked={props.formData.isInfinite}
                />
            </FormGroup>

            {!props.formData.isInfinite && (
                <>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="event.createEvent.startDate" />
                        </ControlLabel>
                        <FormControl
                            data-cy="event-add-startDate"
                            name="startDate"
                            accepter={DatePicker}
                            cleanable={false}
                            format="DD/MM/YYYY"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="event.createEvent.endDate" />
                        </ControlLabel>
                        <FormControl
                            data-cy="event-add-endDate"
                            name="endDate"
                            accepter={DatePicker}
                            cleanable={false}
                            format="DD/MM/YYYY"
                        />
                    </FormGroup>
                </>
            )}

            <FlexboxGrid style={{ marginTop: 12 }} justify="space-between">
                <FlexboxGrid.Item>
                    <Button
                        onClick={props.handleCancel}
                        appearance="ghost"
                        color="red"
                        data-cy="event-createModal-cancel">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <ButtonGroup>
                        <Button onClick={props.handleGoBack} data-cy="event-createModal-back" appearance="ghost">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            loading={isLoading}
                            type="submit"
                            data-cy="event-createModal-valid"
                            appearance="primary">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Form>
    );
};
