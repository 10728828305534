import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TableCellProps } from 'rsuite';

type Props = {
    isChecked: boolean;
} & TableCellProps;

export default class CheckCell extends React.Component<Props> {
    render() {
        if (this.props.isChecked) {
            return <FontAwesomeIcon icon={faCheck} color="green" size="2x" />;
        } else {
            return <FontAwesomeIcon icon={faTimes} color="red" size="2x" />;
        }
    }
}
