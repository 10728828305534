import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Col, FlexboxGrid, Loader, Tag } from 'rsuite';
import { IpCan } from '../../../../../../handlers/ipcan/IpCan';
import TCMSensor from '../../../../../../handlers/ipCanDevices/TCMSensor';
import { VehicleCounterExtension } from '../../../../../../handlers/vehicleCounter/VehicleCounterExtension';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';
import DeleteExtensionModal from '../DeleteExtensionModal';
import EditExtensionModal from '../EditExtensionModal';

type Props = {
    ipcans: IpCan[];
    extension: VehicleCounterExtension;
    fetchExtensions: Function;
};

type State = {
    isFetchingSensor: boolean;
    sensor1?: TCMSensor;
    sensor2?: TCMSensor;
    hasError: boolean;
    showDeleteModal: boolean;
    isDeleted: boolean;
    showEditModal: boolean;
};

export class TCMSensor2List extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isFetchingSensor: true,
            hasError: false,
            sensor1: undefined,
            sensor2: undefined,
            showDeleteModal: false,
            isDeleted: false,
            showEditModal: false,
        };
    }

    hideDeleteModal = (isDeleted: boolean = false) => {
        this.setState({
            showDeleteModal: false,
            isDeleted,
        });

        if (isDeleted) {
            this.props.fetchExtensions();
        }
    };

    showDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    hideEditModal = (isEdited: boolean = false) => {
        this.setState({
            showEditModal: false,
        });

        if (isEdited) {
            this.props.fetchExtensions();
        }
    };

    showEditModal = () => {
        this.setState({
            showEditModal: true,
        });
    };

    componentDidMount() {
        this.fetchSensors();
    }

    componentDidUpdate = (prevProps: Props) => {
        if (JSON.stringify(prevProps.extension) !== JSON.stringify(this.props.extension)) {
            this.fetchSensors();
        }
    };

    fetchSensors = () => {
        this.setState({
            isFetchingSensor: true,
        });

        const fetchSensor1 = axiosService
            .getAxios()
            .get(`/devices/tcm-sensor/${this.props.extension.getTCMSensor1()?.id}`, { headers: authHeader() });
        const fetchSensor2 = axiosService
            .getAxios()
            .get(`/devices/tcm-sensor/${this.props.extension.getTCMSensor2()?.id}`, { headers: authHeader() });

        Promise.all([fetchSensor1, fetchSensor2])
            .then(values => {
                this.setState({
                    sensor1: new TCMSensor(values[0].data, values[0].data.ipcanmodule.id, null, null),
                    sensor2: new TCMSensor(values[1].data, values[1].data.ipcanmodule.id, null, null),
                });
            })
            .catch(() => {
                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isFetchingSensor: false,
                });
            });
    };

    render() {
        if (this.state.isFetchingSensor) {
            return (
                <FlexboxGrid align="middle" justify="center">
                    <FlexboxGrid.Item>
                        <Loader />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            );
        } else {
            const ipcanTCMSensor1 = this.props.ipcans.find(ipcan => ipcan.id === this.state.sensor1?.ipCanId);
            const ipcanTCMSensor2 = this.props.ipcans.find(ipcan => ipcan.id === this.state.sensor2?.ipCanId);

            return (
                <Fragment>
                    <DeleteExtensionModal
                        show={this.state.showDeleteModal}
                        onHide={this.hideDeleteModal}
                        extension={this.props.extension}
                    />

                    {this.state.showEditModal && (
                        <EditExtensionModal
                            show={this.state.showEditModal}
                            onHide={this.hideEditModal}
                            extension={this.props.extension}
                        />
                    )}

                    <FlexboxGrid justify={'center'} align="middle">
                        <FlexboxGrid.Item
                            componentClass={Col}
                            xs={20}
                            style={{ textOverflow: 'clip', overflow: 'auto', whiteSpace: 'normal' }}>
                            <strong>{this.props.extension.getName()}</strong>
                            <Tag data-cy="map-passCount-showType" color="blue" className="margin-left-5">
                                {this.props.extension.getType()}
                            </Tag>
                            <Tag data-cy="map-passCount-timeOutSensor" color="green" className="margin-left-5">
                                <FormattedMessage
                                    id="map.passCounter.extension.timeoutSensor"
                                    values={{ time: this.props.extension.getTimeoutSensor() }}
                                />
                            </Tag>
                            <p className="margin-top-5">
                                <Tag data-cy="map-passCount-showName1" color="violet">{`${ipcanTCMSensor1?.label} - ${
                                    this.state.sensor1!.bus + 1
                                } - ${this.state.sensor1?.id}`}</Tag>
                                <Tag data-cy="map-passCount-showName2" color="violet">{`${ipcanTCMSensor2?.label} - ${
                                    this.state.sensor2!.bus + 1
                                } - ${this.state.sensor2?.id}`}</Tag>
                            </p>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                            <ButtonGroup size="sm">
                                <Button
                                    data-cy="map-passCount-editTCMSensor1"
                                    onClick={this.showEditModal}
                                    appearance="primary"
                                    loading={false}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <Button
                                    data-cy="map-passCount-deleteTCMSensor2"
                                    onClick={this.showDeleteModal}
                                    color="red"
                                    loading={false}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Fragment>
            );
        }
    }
}
