import * as gju from 'geojson-utils';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Button, Col, FlexboxGrid, Modal } from 'rsuite';
import { MapCameraSensor } from '../../../handlers/map/MapCameraSensor';
import { MapLPSensor } from '../../../handlers/map/MapLPSensor';
import { MapTCMSensor } from '../../../handlers/map/MapTCMSensor';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import MapForceSensorsModal from './MapForceSensorsModal';

type Props = {
    tcmSensors: MapTCMSensor[];
    lpSensors: MapLPSensor[];
    placeCameraSensors: MapCameraSensor[];
    reload: Function;
    show?: boolean;
    onHide?: Function;
    allMapClicked?: Function;
    allMapLoading?: boolean;
    selectOnMapClicked?: Function;
};

type State = {
    showSelectForceModal: boolean;
    allMapLoading: boolean;
    lpSensorsToUpdate: Array<number>;
    tcmSensorsToUpdate: Array<number>;
    placeCameraSensorsToUpdate: Array<number>;
    forceModalOpen: boolean;
    event: Record<string, any> | undefined;
};
export default class ForceSensors extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            showSelectForceModal: false,
            allMapLoading: false,
            lpSensorsToUpdate: [],
            tcmSensorsToUpdate: [],
            placeCameraSensorsToUpdate: [],
            forceModalOpen: false,
            event: undefined,
        };

        this.handleDrawStart = this.handleDrawStart.bind(this);
        this.closeSelectForceModal = this.closeSelectForceModal.bind(this);
        this.allMapClicked = this.allMapClicked.bind(this);
        this.closeForceModal = this.closeForceModal.bind(this);
        this.selectOnMapClicked = this.selectOnMapClicked.bind(this);
        this.handleDrawEnd = this.handleDrawEnd.bind(this);
    }

    handleDrawStart(event) {
        this.setState({
            showSelectForceModal: true,
            event: event,
        });
    }

    handleDrawEnd(e) {
        e.layer.remove();

        let coordPoints: Array<Array<number>> = [];

        for (let p in e.layer.editing.latlngs[0][0]) {
            coordPoints.push([
                parseFloat(e.layer.editing.latlngs[0][0][p].lat),
                parseFloat(e.layer.editing.latlngs[0][0][p].lng),
            ]);
        }

        let tcmSensorsIds: Array<number> = [];
        let lpSensorsIds: Array<number> = [];
        let placeCameraSensorsIds: Array<number> = [];

        for (let s in this.props.placeCameraSensors) {
            let sensor = this.props.placeCameraSensors[s];

            if (
                gju.pointInPolygon(
                    { type: 'Point', coordinates: sensor.geoJSON },
                    { type: 'Polygon', coordinates: [coordPoints] }
                )
            ) {
                placeCameraSensorsIds.push(sensor.getPlaceCamera().getId());
            }
        }

        for (let s in this.props.tcmSensors) {
            let sensor = this.props.tcmSensors[s];

            if (
                gju.pointInPolygon(
                    { type: 'Point', coordinates: sensor.geoJSON },
                    { type: 'Polygon', coordinates: [coordPoints] }
                )
            ) {
                tcmSensorsIds.push(sensor.sensor.id);
            }
        }

        for (let s in this.props.lpSensors) {
            let sensor = this.props.lpSensors[s];

            if (
                gju.pointInPolygon(
                    { type: 'Point', coordinates: sensor.geoJSON },
                    { type: 'Polygon', coordinates: [coordPoints] }
                )
            ) {
                lpSensorsIds.push(sensor.sensor.id);
            }
        }

        this.setState(
            {
                tcmSensorsToUpdate: tcmSensorsIds,
                lpSensorsToUpdate: lpSensorsIds,
                placeCameraSensorsToUpdate: placeCameraSensorsIds,
            },
            () => {
                this.setState({
                    showSelectForceModal: false,
                    forceModalOpen: true,
                });
            }
        );
    }

    closeSelectForceModal() {
        this.setState({
            showSelectForceModal: false,
        });
    }

    allMapClicked() {
        let tcmSensors = this.props.tcmSensors.map(sensor => sensor.sensor.id);
        let lpSensors = this.props.lpSensors.map(sensor => sensor.sensor.id);
        let placeCameraSensors = this.props.placeCameraSensors.map(sensor => sensor.getPlaceCamera().getId());

        this.setState(
            {
                tcmSensorsToUpdate: tcmSensors,
                lpSensorsToUpdate: lpSensors,
                placeCameraSensorsToUpdate: placeCameraSensors,
            },
            () => {
                this.setState({
                    showSelectForceModal: false,
                    forceModalOpen: true,
                });
            }
        );
    }

    selectOnMapClicked() {
        this.setState({
            showSelectForceModal: false,
        });
    }

    closeForceModal() {
        this.setState(
            {
                forceModalOpen: false,
                tcmSensorsToUpdate: [],
                lpSensorsToUpdate: [],
                placeCameraSensorsToUpdate: [],
                allMapLoading: false,
            },
            () => {
                this.props.reload('sensors');
            }
        );
    }

    render() {
        return (
            <FeatureGroup>
                <EditControl
                    position="topright"
                    onDrawStart={this.handleDrawStart}
                    onCreated={this.handleDrawEnd}
                    draw={{
                        rectangle: false,
                        circle: false,
                        marker: false,
                        circlemarker: false,
                        polygon: true,
                        polyline: false,
                    }}
                    edit={{
                        remove: false,
                        edit: false,
                    }}
                />

                <SelectForceModal
                    allMapClicked={this.allMapClicked}
                    allMapLoading={this.state.allMapLoading}
                    selectOnMapClicked={this.selectOnMapClicked}
                    show={this.state.showSelectForceModal}
                    onHide={this.closeSelectForceModal}
                />

                <MapForceSensorsModal
                    lpSensors={this.state.lpSensorsToUpdate}
                    tcmSensors={this.state.tcmSensorsToUpdate}
                    placeCameraSensors={this.state.placeCameraSensorsToUpdate}
                    show={this.state.forceModalOpen}
                    onHide={this.closeForceModal}
                />
            </FeatureGroup>
        );
    }
}

type SelectForceModalProps = {
    show: boolean;
    onHide: Function;
    allMapClicked: Function;
    allMapLoading: boolean;
    selectOnMapClicked: Function;
};

type SelectForceModalState = {};

class SelectForceModal extends React.Component<SelectForceModalProps, SelectForceModalState> {
    render() {
        return (
            <SecuredFragment
                authorizedRoles={[rolesConstants.TCMSensor.FORCE_SENSOR, rolesConstants.LPSensor.FORCE_SENSOR]}>
                <Modal backdrop="static" show={this.props.show} onHide={() => this.props.onHide()}>
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage id="map.level.forceSensors" />
                        </Modal.Title>
                        <Modal.Body>
                            <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <Button
                                        appearance="default"
                                        block
                                        data-cy="forced-allSensors"
                                        onClick={() => this.props.allMapClicked()}
                                        loading={this.props.allMapLoading}>
                                        <FormattedMessage id="map.level.forceSensor.allLevel" />
                                    </Button>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <Button
                                        appearance="default"
                                        data-cy="forced-sensor"
                                        block
                                        onClick={() => this.props.selectOnMapClicked()}>
                                        <FormattedMessage id="map.level.forceSensor.selectSensors" />
                                    </Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Modal.Body>
                    </Modal.Header>
                </Modal>
            </SecuredFragment>
        );
    }
}
