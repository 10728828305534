import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Col, FlexboxGrid, List } from 'rsuite';
import { MaintenanceState } from '../../../../../../handlers/MaintenanceInformation';

type Props = {
    device: any;
};

export const MapTCMViewDeviceStateInformation = (props: Props) => {
    const intl = useIntl();

    return (
        <List hover>
            {/* MAINTENANCE STATE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                        {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState' })}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        {props.device.tcmDisplay.maintenanceState === MaintenanceState.EVERYTHING_OK && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="green" size="lg" />
                        )}
                        {props.device.tcmDisplay.maintenanceState === MaintenanceState.LOW && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="#FFDA00" size="lg" />
                        )}
                        {props.device.tcmDisplay.maintenanceState === MaintenanceState.MEDIUM && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="orange" size="lg" />
                        )}
                        {props.device.tcmDisplay.maintenanceState === MaintenanceState.HIGH && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="#FF5349" size="lg" />
                        )}
                        {props.device.tcmDisplay.maintenanceState === MaintenanceState.VERY_HIGH && (
                            <FontAwesomeIcon icon={faExclamationCircle} color="red" size="lg" />
                        )}
                        <span className="margin-left-5">({props.device.tcmDisplay.maintenanceState})</span>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* INFORMATION NOTEPAD */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                        {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.notepad' })}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        <p>{props.device.tcmDisplay.notepad}</p>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
};
