import { IntlShape } from 'react-intl';

type FormValuesInputString = {
    label: string;
    value: string;
};

type FormValuesInputNumber = {
    label: string;
    value: number;
};

export const LPVirtualDisplayColor = (): FormValuesInputString[] => {
    return [
        {
            label: '#FF0000',
            value: '#FF0000',
        },
        {
            label: '#00FF00',
            value: '#00FF00',
        },
        {
            label: '#0000FF',
            value: '#0000FF',
        },
        {
            label: '#000000',
            value: '#000000',
        },
        {
            label: '#FFFFFF',
            value: '#FFFFFF',
        },
    ];
};

export const LPVirtualDisplayState = (intl: IntlShape): FormValuesInputNumber[] => {
    return [
        // {
        //     label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.state.disabled' }),
        //     value: 0,
        // },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.state.enabledWaitModBus' }),
            value: 1,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.state.wait' }),
            value: 2,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.state.forcedEnabled' }),
            value: 3,
        },
    ];
};

export const LPVirtualDisplayFont = (): FormValuesInputNumber[] => {
    return [
        {
            label: 'MLE_BIG_1',
            value: 1,
        },
        {
            label: 'MLE_BIG_2',
            value: 2,
        },
        {
            label: 'MLE_BIG_3',
            value: 3,
        },
        {
            label: 'MLE_BIG_4',
            value: 4,
        },
        {
            label: 'MLE_BIG_5',
            value: 5,
        },
    ];
};

export const LPVirtualDisplayLangs = (): FormValuesInputNumber[] => {
    return [
        {
            label: 'FR',
            value: 1,
        },
        {
            label: 'LANG 1',
            value: 2,
        },
        {
            label: 'LANG 2',
            value: 4,
        },
        {
            label: 'LANG 3',
            value: 8,
        },
    ];
};

export const LPVirtualDisplaySpeed = (): FormValuesInputNumber[] => {
    return [
        {
            label: '1',
            value: 1,
        },
        {
            label: '2',
            value: 2,
        },
        {
            label: '3',
            value: 3,
        },
        {
            label: '4',
            value: 4,
        },
        {
            label: '5',
            value: 5,
        },
        {
            label: '6',
            value: 6,
        },
        {
            label: '7',
            value: 7,
        },
        {
            label: '8',
            value: 8,
        },
        {
            label: '9',
            value: 9,
        },
        {
            label: '10',
            value: 10,
        },
    ];
};
