import React from 'react';
import { FormattedMessage } from 'react-intl';
import { secondsToHours } from '../../../../handlers/helper';
import {
    LPSensorUsHeightValues,
    LPSensorUsSensValues,
} from '../../../../handlers/ipCanDevices/Sensor/LPSensorUsConfig';

export default function updateUsConfig(option) {
    const { us1, us2, overstay, keepAlive } = option;
    const overstayHour = secondsToHours(overstay);

    return (
        <div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.c1" /> :
                </b>{' '}
                {LPSensorUsHeightValues(us1.height)}
            </div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.c2" /> :
                </b>{' '}
                {LPSensorUsHeightValues(us2.height)}
            </div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.sDetection" /> :
                </b>{' '}
                {LPSensorUsSensValues(us1.sens)}
            </div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.sRelease" /> :
                </b>{' '}
                {LPSensorUsSensValues(us2.sens)}
            </div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.overstay" /> :
                </b>{' '}
                {`${overstayHour.stringified}`}
            </div>
        </div>
    );
}
