import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert } from 'rsuite';
import { IpCan } from '../handlers/ipcan/IpCan';
import { authHeader } from '../redux/helpers';
import { axiosService } from '../redux/services';

export function useFetchIpCan(ipcanId: number) {
    const intl = useIntl();

    const [ipCan, setIpCan] = useState<IpCan | null>(null);

    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/ipcanmodules/${ipcanId}`, {
                headers: authHeader(),
            })
            .then(response => {
                setIpCan(response.data);
            })
            .catch(error => {
                Alert.error(intl.formatMessage({ id: 'error.fetchIpCanError' }));
                console.error(error);
            });
    }, [ipcanId]);

    return ipCan;
}
