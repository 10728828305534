import { faCopy, faFont, faImage, faTh, faThLarge, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { Button, ButtonGroup, Col, Drawer, FlexboxGrid, Popover, Whisper } from 'rsuite';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import { MapLPMatrixDisplayV2 } from '../../../../../handlers/map/MapLPMatrixDisplayV2';
import SecuredFragment from '../../../../Auth/SecuredFragment';
import { DeviceStateInformation } from '../../../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../../../IpCanElementsPage/Cards/DeviceStateInformation/deviceStateInformationGenerator';
import LPDisplayBaseConfigurationCard from '../../../../IpCanElementsPage/Drawer/LPDisplay/Cards/LPDisplayBaseConfigurationCard';
import LPDisplaySystemTextCard from '../../../../IpCanElementsPage/Drawer/LPDisplay/Cards/LPDisplaySystemTextCard';
import LPDisplayUserTextCard from '../../../../IpCanElementsPage/Drawer/LPDisplay/Cards/LPDisplayUserTextCard';
import { CopyLPDisplayConfig } from '../../../../IpCanElementsPage/LPDisplays/Modal/CopyLPDisplayConfig';
import EditLPDisplayCountersModal from '../../../../IpCanElementsPage/LPDisplays/Modal/EditLPDisplayCountersModal';
import LPDisplayAdvancedCommandsModal from '../../../../IpCanElementsPage/LPDisplays/Modal/LPDisplayAdvancedCommandsModal';
import LPMatrixDisplayV2Config from '../../../../IpCanElementsPage/LPMatrixDisplayV2Config';
import LPDisplayMapInformationCard from './LPDisplayMapInformationCard';

type Props = {
    show: boolean;
    onHide: () => void;
    display: MapLPMatrixDisplayV2;
    controllers: IpCan[];
    service: any;
};

export const LPDisplayDrawer = (props: Props) => {
    const [isAdvancedCommandsModalOpen, setIsAdvancedCommandsModalOpen] = useState(false);
    const [showEditLPDisplayTopologyModal, setShowEditLPDisplayTopologyModal] = useState(false);
    const [showCopyConfigModal, setShowCopyConfigModal] = useState<boolean>(false);
    const [isEditLPDisplayCountersModalOpen, setIsEditLPDisplayCountersModalOpen] = useState(false);

    const currentDisplay = props.display.getLpMatrixDisplayV2();

    const currentIpCan = props.controllers.find(controller => controller.id === currentDisplay.getIpCanId());

    const reloadDisplay = () => {
        props.service.send('UPDATE_LP_MATRIX_DISPLAY', {
            display: props.display,
        });
    };

    const handleCloseCopyConfigModal = () => {
        setShowCopyConfigModal(false);

        props.service.send('RELOAD_DISPLAYS');
    };

    const history = useHistory();

    const generateSpeaker = () => {
        return (
            <Popover style={{ width: '40vh' }}>
                <ButtonGroup justified>
                    <Button
                        onClick={() =>
                            history.push(
                                `/ipCan/${
                                    currentIpCan?.id
                                }/bus/${currentDisplay?.getBus()}/matrix-display/${currentDisplay?.getId()}/font/16`
                            )
                        }
                        data-cy="lpDisplay-button-updateFont"
                        appearance="primary"
                        color="blue">
                        <FontAwesomeIcon icon={faFont} className="margin-right-5" />
                        <FormattedMessage id="ipCanDevices.lpDisplay.updateFont" />
                    </Button>
                    <Button
                        onClick={() =>
                            history.push(
                                `/ipCan/${
                                    currentIpCan?.id
                                }/bus/${currentDisplay?.getBus()}/matrix-display/${currentDisplay?.getId()}/picto/1`
                            )
                        }
                        data-cy="lpDisplay-button-updatePicto"
                        appearance="primary"
                        color="cyan">
                        <FontAwesomeIcon icon={faImage} className="margin-right-5" />
                        <FormattedMessage id="ipCanDevices.lpDisplay.updatePicto" />
                    </Button>
                </ButtonGroup>
            </Popover>
        );
    };

    return (
        <Fragment>
            <SecuredFragment authorizedRoles={[]}>
                <LPMatrixDisplayV2Config
                    display={props.display.getLpMatrixDisplayV2()}
                    show={showEditLPDisplayTopologyModal}
                    onHide={() => setShowEditLPDisplayTopologyModal(false)}
                    reloadDisplay={reloadDisplay}
                />
            </SecuredFragment>

            {isEditLPDisplayCountersModalOpen && (
                <SecuredFragment authorizedRoles={[]}>
                    <EditLPDisplayCountersModal
                        display={props.display.getLpMatrixDisplayV2()}
                        show={isEditLPDisplayCountersModalOpen}
                        onHide={() => setIsEditLPDisplayCountersModalOpen(false)}
                        reloadDisplay={reloadDisplay}
                    />
                </SecuredFragment>
            )}

            <CopyLPDisplayConfig
                displayToCopy={props.display.getLpMatrixDisplayV2()}
                show={showCopyConfigModal}
                onHide={handleCloseCopyConfigModal}
                ipCanId={props.display.getLpMatrixDisplayV2().getIpCanId()}
            />

            <Drawer backdrop={false} show={props.show} onHide={() => props.onHide()}>
                <Drawer.Header>
                    <Drawer.Title>
                        {props.display.label} ({currentIpCan?.label} - {currentDisplay.bus + 1} -{' '}
                        {currentDisplay.deviceId})
                    </Drawer.Title>
                </Drawer.Header>

                <Drawer.Body className="drawer-body-without-avatar">
                    <LPDisplayMapInformationCard
                        service={props.service}
                        currentIpCan={currentIpCan}
                        display={props.display}
                        controllers={props.controllers}
                    />

                    <DeviceStateInformation
                        device={currentDisplay}
                        editMaintenanceStateUrl={MaintenanceStateInformationEditURL.LP_MATRIX_DISPLAY_V2}
                    />

                    <LPDisplayBaseConfigurationCard
                        display={currentDisplay}
                        isLoading={false}
                        reloadDisplay={reloadDisplay}
                    />

                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <Button
                                block
                                data-cy="lpDisplays-counterConfiguration"
                                color="orange"
                                className="margin-bottom-10"
                                onClick={() => setIsEditLPDisplayCountersModalOpen(true)}>
                                <FormattedMessage id="ipCanDevices.lpDisplays.counterConfiguration" />
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <Button
                                data-cy="lpDisplay-advancedCommands"
                                block
                                color="orange"
                                onClick={() => setIsAdvancedCommandsModalOpen(true)}>
                                <FontAwesomeIcon icon={faTools} className="margin-right-5" />
                                <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands" />
                            </Button>

                            <LPDisplayAdvancedCommandsModal
                                show={isAdvancedCommandsModalOpen}
                                onHide={() => setIsAdvancedCommandsModalOpen(false)}
                                lpDisplay={props.display.getLpMatrixDisplayV2()}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <LPDisplaySystemTextCard
                        display={currentDisplay}
                        isLoading={false}
                        isCollapsible
                        reloadDisplay={reloadDisplay}
                    />

                    <LPDisplayUserTextCard
                        display={currentDisplay}
                        isLoading={false}
                        isCollapsible
                        reloadDisplay={reloadDisplay}
                    />
                </Drawer.Body>

                <Drawer.Footer>
                    <FlexboxGrid className="margin-bottom-10">
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <Button
                                data-cy="lpDisplay-button-copyConfig"
                                block
                                appearance="primary"
                                color="yellow"
                                onClick={() => setShowCopyConfigModal(true)}>
                                <FontAwesomeIcon icon={faCopy} className="margin-right-5" />
                                <FormattedMessage id="ipCanDevices.lpDisplay.copyConfig" />
                            </Button>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <Whisper trigger="click" placement="top" speaker={generateSpeaker()}>
                                <Button block color="violet">
                                    <FontAwesomeIcon icon={faTh} className="margin-right-5" />
                                    <FormattedMessage id="ipCanDevices.lpDisplay.updateFontOrPicto" />
                                </Button>
                            </Whisper>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <Button
                                block
                                data-cy="lpDisplay-button-updateLayout"
                                appearance="primary"
                                color="blue"
                                onClick={() => setShowEditLPDisplayTopologyModal(true)}>
                                <FontAwesomeIcon icon={faThLarge} className="margin-right-5" />
                                <FormattedMessage id="ipCanDevices.lpDisplay.updateLayout" />
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Drawer.Footer>
            </Drawer>
        </Fragment>
    );
};
