import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Col, FlexboxGrid, Input, Message, Progress } from 'rsuite';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';

type FileUploaderProps = {
    setChangelog: Function;
    nextStep: Function;
} & WrappedComponentProps;

type FileUploaderState = {
    loading: boolean;
    value: string | Blob;
    percent: number;
    started: boolean;
    hasError: boolean;
};

class FileUploader extends React.Component<FileUploaderProps, FileUploaderState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            value: '',
            percent: 0,
            started: false,
            hasError: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
    }

    handleChange(_, e) {
        this.setState({
            value: e.target.files[0],
        });
    }

    onUploadProgress(event) {
        this.setState({
            percent: parseInt(((event.loaded / event.total) * 100).toFixed(0)),
            started: true,
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            loading: true,
        });

        const parameters = {
            'content-type': 'multipart/form-data',
            headers: authHeader(),
        };

        const data = new FormData();
        data.append('file', this.state.value);

        axiosService
            .getAxios()
            .post('/configuration/system/uploadUpdate', data, {
                ...parameters,
                onUploadProgress: event => this.onUploadProgress(event),
            })
            .then(response => {
                this.props.setChangelog(
                    response.data.changelog.api,
                    response.data.changelog.engine,
                    response.data.changelog.dashboard
                );

                this.setState({
                    loading: false,
                });

                this.props.nextStep();
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                    loading: false,
                });
            });
    }

    render() {
        return (
            <FlexboxGrid justify="center">
                {this.state.hasError && (
                    <Message type="error" description={this.props.intl.formatMessage({ id: 'update.manual.error' })} />
                )}
                <FlexboxGrid.Item className="margin-top-10" componentClass={Col} xs={24}>
                    <FormattedMessage id="update.manual.description" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="margin-top-10" componentClass={Col} xs={24}>
                    {this.state.started ? (
                        <Progress.Line
                            percent={this.state.percent}
                            status={this.state.percent === 100 ? (this.state.hasError ? 'fail' : 'success') : 'active'}
                        />
                    ) : (
                        <Input
                            onChange={this.handleChange}
                            className="margin-top-10"
                            type="file"
                            accept="application/x-zip-compressed"
                        />
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="margin-top-10">
                    <Button
                        onClick={this.handleSubmit}
                        disabled={!this.state.value}
                        loading={this.state.loading}
                        color="orange"
                        className="margin-top-10">
                        <FontAwesomeIcon icon={faUpload} className="margin-right-10" />
                        <FormattedMessage id="update.manual.upload" />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        );
    }
}

export default injectIntl(FileUploader);
