import History from './History';

export default class LPSensorHistoric extends History {
    _idLpSensor: number;
    _lpSensorName: string;

    constructor(historyObject) {
        super(historyObject);
        const { idLpSensor, lpSensorName } = historyObject;

        this._idLpSensor = idLpSensor;
        this._lpSensorName = lpSensorName;
    }

    public get idLpSensor() {
        return this._idLpSensor;
    }

    public get lpSensorName() {
        return this._lpSensorName;
    }

    getOption(): any {
        return this.option;
    }

    getHistory(): History_I {
        return {
            id: this.getId(),
            user: this.getUser(),
            createdAt: this.getCreatedAt(),
            createdBy: this.getCreatedBy(),
            desc: this.getDesc(),
            option: this.getOption(),
            idLpSensor: this.idLpSensor,
            lpSensorName: this.lpSensorName,
            disabled: this.shouldBeDisabled(),
        };
    }

    private shouldBeDisabled(): boolean {
        let result = true;

        if (this.getDesc() === 'update us and filter config') result = false;
        if (this.getDesc() === 'read config') result = false;
        if (this.getDesc() === 'read scene config') result = false;
        if (this.getDesc() === 'send scenes') result = false;
        if (this.getDesc() === 'update place type') result = false;

        return result;
    }
}

interface History_I {
    id: number;
    user: string;
    createdAt: string;
    createdBy: string;
    desc: string;
    option: Record<string, any>;
    idLpSensor: number;
    lpSensorName: string;
    disabled: boolean;
}
