export const ipCanConstants = {
    GET_ALL_REQUEST: 'IPCAN_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'IPCAN_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'IPCAN_GET_ALL_FAILURE',

    GET_ONE_REQUEST: 'IPCAN_GET_ONE_REQUEST',
    GET_ONE_SUCCESS: 'IPCAN_GET_ONE_SUCCESS',
    GET_ONE_ERROR: 'IPCAN_GET_ONE_ERROR',

    DELETE_REQUEST: 'IPCAN_DELETE_REQUEST',
    DELETE_SUCCESS: 'IPCAN_DELETE_SUCCESS',
    DELETE_FAILURE: 'IPCAN_DELETE_FAILURE',

    CREATE_REQUEST: 'IPCAN_CREATE_REQUEST',
    CREATE_SUCCESS: 'IPCAN_CREATE_SUCCESS',
    CREATE_FAILURE: 'IPCAN_CREATE_FAILURE',

    UPDATE_REQUEST: 'IPCAN_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'IPCAN_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'IPCAN_UPDATE_FAILURE',

    OPEN_CREATE_MODAL: 'IPCAN_OPEN_CREATE_MODAL',
    CLOSE_CREATE_MODAL: 'IPCAN_CLOSE_CREATE_MODAL',

    OPEN_VIEW_DRAWER_REQUEST: 'IPCAN_OPEN_VIEW_DRAWER_REQUEST',
    OPEN_VIEW_DRAWER_SUCCESS: 'IPCAN_OPEN_VIEW_DRAWER_SUCCESS',
    OPEN_VIEW_DRAWER_FAILURE: 'IPCAN_OPEN_VIEW_DRAWER_FAILURE',

    CLOSE_VIEW_DRAWER: 'IPCAN_CLOSE_VIEW_DRAWER',

    OPEN_DELETE_MODAL_REQUEST: 'IPCAN_OPEN_DELETE_MODAL_REQUEST',
    OPEN_DELETE_MODAL_SUCCESS: 'IPCAN_OPEN_DELETE_MODAL_SUCCESS',
    OPEN_DELETE_MODAL_FAILURE: 'IPCAN_OPEN_DELETE_MODAL_FAILURE',

    CLOSE_DELETE_MODAL: 'IPCAN_CLOSE_DELETE_MODAL',

    WEBSOCKET_UPDATE_IPCAN: 'IPCAN_WEBSOCKET_UPDATE_IPCAN',
    WEBSOCKET_KEEP_ALIVE_IP_CAN: 'IPCAN_WEBSOCKET_KEEP_ALIVE_IP_CAN',

    GET_DB_VALUE: 'IPCAN_GET_DB_VALUE',
    GET_DB_VALUE_REQUEST: 'IPCAN_GET_DB_VALUE_REQUEST',
};
