import { faBolt, faCog, faSave, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { ContextProps, withLeaflet } from 'react-leaflet';
import Control from 'react-leaflet-control';
import { Alert, Button, ButtonGroup, Modal, Tooltip, Whisper } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import EditActions from './EditActions';

type Props = {
    editMode: boolean;
    context: Record<string, any>;
    current: Record<string, any>;
    levelId: number;
    handleButtonClick: Function;
    reload: Function;
    needReload: boolean;
    logicCountingSync: boolean;
    calibration: number;
    updateLogicCountingSync: Function;
} & WrappedComponentProps &
    ContextProps;

type State = {
    savingZoom: boolean;
    reloadingCounter: boolean;
    showLoadingModal: boolean;
    engineSync: boolean;
};

class TopLeftControls extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            savingZoom: false,
            reloadingCounter: false,
            showLoadingModal: false,
            engineSync: true,
        };

        this.handleSaveZoom = this.handleSaveZoom.bind(this);
        this.handleReloadCounters = this.handleReloadCounters.bind(this);
        this.handleUpdateAllCounterZone = this.handleUpdateAllCounterZone.bind(this);
        this.handleEngineSync = this.handleEngineSync.bind(this);
    }

    componentDidMount() {
        webSocketService.joinRoom('engine');
        webSocketService.onEvent('engine:sync', this.handleEngineSync);
    }

    componentWillUnmount() {
        webSocketService.offEvent('engine:sync', this.handleEngineSync);
    }

    handleEngineSync(e) {
        this.props.updateLogicCountingSync(e);
    }

    handleSaveZoom() {
        let leafletPosition = this.props.leaflet?.map?.getCenter();
        let zoom = this.props.leaflet?.map?.getZoom();
        let position = [leafletPosition?.lat, leafletPosition?.lng];

        this.setState({
            savingZoom: true,
        });

        let data = {
            id: this.props.levelId,
            zoom,
            position,
        };

        axiosService
            .getAxios()
            .put('/levels/updateZoom', data, { headers: authHeader() })
            .then((updateZoomResponse) => {
                this.setState({
                    savingZoom: false,
                });

                this.props.leaflet?.map?.setZoom(updateZoomResponse.data.defaultZoom);
                this.props.leaflet?.map?.setView(updateZoomResponse.data.positionZoom);


                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'map.zoom.save.success',
                    }),
                    5000
                );
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    savingZoom: false,
                });

                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'map.zoom.save.error',
                    }),
                    5000
                );
            });
    }

    handleUpdateAllCounterZone() {
        this.setState({
            showLoadingModal: true,
        });

        axiosService
            .getAxios()
            .put('/map-zones/updateAllCountersZone', {}, { headers: authHeader() })
            .then(() => {
                this.setState({
                    showLoadingModal: false,
                });

                this.props.reload('zones');

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'map.updateAllCountersZone.reload.success',
                    }),
                    5000
                );
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    showLoadingModal: false,
                });

                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'map.updateAllCountersZone.reload.error',
                    }),
                    5000
                );
            });
    }

    handleReloadCounters() {
        this.setState({
            reloadingCounter: true,
        });

        axiosService
            .getAxios()
            .put('/engine/reloadConfig', {}, { headers: authHeader() })
            .then(() => {
                this.setState({
                    reloadingCounter: false,
                });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'map.counter.reload.success',
                    }),
                    5000
                );
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    reloadingCounter: false,
                });

                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'map.counter.reload.error',
                    }),
                    5000
                );
            });
    }

    render() {
        if (this.props.editMode) {
            return (
                <Fragment>
                    {/* Actions sur la map */}
                    <Control position="topleft">
                        <EditActions
                            activeButton={this.props.current}
                            handleButtonClick={this.props.handleButtonClick}
                            levelId={this.props.context.level.id}
                            needCalibration={this.props.calibration === 0}
                        />
                    </Control>
                    {/* Actions globales */}
                    <Control position="topleft">
                        <ButtonGroup vertical>
                            <SecuredFragment authorizedRoles={[rolesConstants.map.EDIT]}>
                                <Whisper
                                    trigger="hover"
                                    placement={'autoHorizontal'}
                                    speaker={
                                        <Tooltip>
                                            <FormattedMessage id="map.actions.saveZoom" />
                                        </Tooltip>
                                    }>
                                    <Button
                                        data-cy="saveZoom"
                                        className="button-map"
                                        appearance="primary"
                                        color="green"
                                        size="md"
                                        loading={this.state.savingZoom}
                                        onClick={this.handleSaveZoom}>
                                        <FontAwesomeIcon icon={faSave} />
                                    </Button>
                                </Whisper>
                            </SecuredFragment>
                            <Fragment>
                                <SecuredFragment authorizedRoles={[rolesConstants.mapZones.UPDATE_ALL_COUNTER_ZONE]}>
                                    <Whisper
                                        trigger="hover"
                                        placement={'autoHorizontal'}
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="map.actions.updateAllCountersZone" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            data-cy="update-Zone"
                                            className={`button-map ${this.props.needReload && 'button-blink-yellow'}`}
                                            appearance="primary"
                                            color="yellow"
                                            size="md"
                                            onClick={this.handleUpdateAllCounterZone}>
                                            <FontAwesomeIcon icon={faSync} />
                                        </Button>
                                    </Whisper>
                                </SecuredFragment>
                                <SecuredFragment authorizedRoles={[rolesConstants.engine.RELOAD_CONFIG]}>
                                    <Whisper
                                        trigger="hover"
                                        placement={'autoHorizontal'}
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="map.actions.reloadCounters" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            data-cy="reload-Counters"
                                            className={`button-map ${
                                                !this.props.logicCountingSync && 'button-blink-orange'
                                            }`}
                                            appearance="primary"
                                            color="orange"
                                            size="md"
                                            disabled={this.props.needReload}
                                            loading={this.state.reloadingCounter}
                                            onClick={this.handleReloadCounters}>
                                            <FontAwesomeIcon icon={faBolt} />
                                        </Button>
                                    </Whisper>
                                </SecuredFragment>
                            </Fragment>
                        </ButtonGroup>
                    </Control>

                    <UpdateAllCountersZoneModal show={this.state.showLoadingModal} />
                </Fragment>
            );
        } else {
            return (
                <Control position="topleft">
                    <ButtonGroup vertical>
                        <SecuredFragment authorizedRoles={[rolesConstants.map.EDIT]}>
                            <Whisper
                                trigger="hover"
                                placement={'autoHorizontal'}
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="map.actions.saveZoom" />
                                    </Tooltip>
                                }>
                                <Button
                                    data-cy="saveZoom"
                                    className="button-map"
                                    appearance="primary"
                                    color="green"
                                    size="md"
                                    loading={this.state.savingZoom}
                                    onClick={this.handleSaveZoom}>
                                    <FontAwesomeIcon icon={faSave} />
                                </Button>
                            </Whisper>
                        </SecuredFragment>
                    </ButtonGroup>
                </Control>
            );
        }
    }
}

type UpdateAllCountersZoneModalProps = {
    show: boolean;
};
class UpdateAllCountersZoneModal extends React.Component<UpdateAllCountersZoneModalProps> {
    render() {
        return (
            <Modal show={this.props.show}>
                <Modal.Body className="text-center">
                    <FontAwesomeIcon icon={faCog} size="5x" spin />
                </Modal.Body>
            </Modal>
        );
    }
}

export default injectIntl(withLeaflet(TopLeftControls));
