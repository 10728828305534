import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    List,
    Message,
    Panel,
    Tag,
    TagPicker,
} from 'rsuite';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';

type Props = {
    display: any;
    service: any;
    placeTypes: any;
} & WrappedComponentProps;

type State = {
    isEditMode: boolean;
    isUpdating: boolean;
    hasError: boolean;
    formValue: FormValue;
};

type FormValue = {
    generic: number[];
    pmr: number[];
    family: number[];
    electrical: number[];
};

class TypeConfiguration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            isUpdating: false,
            hasError: false,
            formValue: {
                generic: this.props.display.tcmDisplay.configType.generic,
                pmr: this.props.display.tcmDisplay.configType.pmr,
                family: this.props.display.tcmDisplay.configType.family,
                electrical: this.props.display.tcmDisplay.configType.electrical,
            },
        };
    }

    handleChange = formValue => {
        this.setState({
            formValue,
        });
    };

    generatePlaceTypeSelector = (): { label: string; value: number }[] => {
        return this.props.placeTypes.map(placeType => {
            return {
                label: placeType.label,
                value: placeType.id,
            };
        });
    };

    cancelEditMode = () => {
        this.setState({
            formValue: {
                generic: this.props.display.tcmDisplay.configType.generic,
                pmr: this.props.display.tcmDisplay.configType.pmr,
                family: this.props.display.tcmDisplay.configType.family,
                electrical: this.props.display.tcmDisplay.configType.electrical,
            },
            isEditMode: false,
        });
    };

    validEdition = () => {
        this.setState({
            isUpdating: true,
            hasError: false,
        });

        const { generic, pmr, electrical, family } = this.state.formValue;

        axiosService
            .getAxios()
            .put(
                '/devices/tcm-display/updateConfigType',
                {
                    id: this.props.display.tcmDisplay.id,
                    configType: {
                        generic,
                        pmr,
                        electrical,
                        family,
                    },
                },
                {
                    headers: authHeader(),
                }
            )
            .then(tcmDisplayEditResponse => {
                this.setState({
                    isUpdating: false,
                    isEditMode: false,
                });

                this.props.service.send('DISPLAY_UPDATE', { display: tcmDisplayEditResponse.data });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'map.tcmDisplay.edit.type.success',
                    })
                );
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    isUpdating: false,
                    hasError: true,
                });
            });
    };

    generateEditPart = () => (
        <Form onChange={this.handleChange} formValue={this.state.formValue} fluid>
            <List hover>
                {/* GENERIC */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                            <FormattedMessage id="ipCanDevices.display.configType.generic" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={16}>
                            <FormControl
                                data-cy="ipCanDevices-configType-edit-generic"
                                placement="auto"
                                block
                                accepter={TagPicker}
                                name="generic"
                                data={this.generatePlaceTypeSelector()}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`configType-generic-value-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* PMR */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                            <FormattedMessage id="ipCanDevices.display.configType.pmr" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={16}>
                            <FormControl
                                data-cy="ipCanDevices-configType-edit-pmr"
                                placement="auto"
                                block
                                accepter={TagPicker}
                                name="pmr"
                                data={this.generatePlaceTypeSelector()}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`configType-pmr-value-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                            <FormattedMessage id="ipCanDevices.display.configType.family" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={16}>
                            <FormControl
                                data-cy="ipCanDevices-configType-edit-family"
                                placement="auto"
                                block
                                accepter={TagPicker}
                                name="family"
                                data={this.generatePlaceTypeSelector()}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`configType-family-value-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                            <FormattedMessage id="ipCanDevices.display.configType.electrical" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={16}>
                            <FormControl
                                data-cy="ipCanDevices-configType-edit-electrical"
                                placement="auto"
                                block
                                accepter={TagPicker}
                                name="electrical"
                                data={this.generatePlaceTypeSelector()}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`configType-electrical-value-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="ipCanDevices-configType-edit-cancel"
                                    color="red"
                                    onClick={this.cancelEditMode}
                                    disabled={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    data-cy="ipCanDevices-configType-edit-valid"
                                    color="green"
                                    onClick={this.validEdition}
                                    loading={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );

    generateViewPart = () => {
        let genericTags: any = [];
        let pmrTags: any = [];
        let familyTags: any = [];
        let electricalTags: any = [];

        if (this.props.display.tcmDisplay.configType.generic) {
            genericTags = this.props.display.tcmDisplay.configType.generic.map(type => {
                return this.props.placeTypes.find(placeType => placeType.id === type);
            });
        }

        if (this.props.display.tcmDisplay.configType.pmr) {
            pmrTags = this.props.display.tcmDisplay.configType.pmr.map(type => {
                return this.props.placeTypes.find(placeType => placeType.id === type);
            });
        }

        if (this.props.display.tcmDisplay.configType.family) {
            familyTags = this.props.display.tcmDisplay.configType.family.map(type => {
                return this.props.placeTypes.find(placeType => placeType.id === type);
            });
        }

        if (this.props.display.tcmDisplay.configType.electrical) {
            electricalTags = this.props.display.tcmDisplay.configType.electrical.map(type => {
                return this.props.placeTypes.find(placeType => placeType.id === type);
            });
        }

        return (
            <List hover>
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="ipCanDevices.display.configType.generic" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {genericTags.map(g => (
                                <Tag data-cy="ipCanDevices-configType-generic" color="blue">
                                    {g.label}
                                </Tag>
                            ))}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="ipCanDevices.display.configType.pmr" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {pmrTags.map(g => (
                                <Tag data-cy="ipCanDevices-configType-pmr" color="blue">
                                    {g.label}
                                </Tag>
                            ))}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="ipCanDevices.display.configType.family" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {familyTags.map(g => (
                                <Tag data-cy="ipCanDevices-configType-family" color="blue">
                                    {g.label}
                                </Tag>
                            ))}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="ipCanDevices.display.configType.electrical" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {electricalTags.map(g => (
                                <Tag data-cy="ipCanDevices-configType-electrical" color="blue">
                                    {g.label}
                                </Tag>
                            ))}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        );
    };

    setEditMode = () => {
        this.setState({
            isEditMode: true,
        });
    };

    render = () => (
        <Panel
            className="panel-small"
            bordered
            bodyFill
            shaded
            header={
                <PanelHeader
                    title={this.props.intl.formatMessage({
                        id: 'map.tcmDisplay.edit.configurationTypes',
                    })}
                    buttons={[
                        <Button
                            data-cy="ipCanDevices-configType"
                            color="blue"
                            size="sm"
                            onClick={this.setEditMode}
                            disabled={this.state.isEditMode}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {this.state.hasError && (
                <Message
                    type="error"
                    description={this.props.intl.formatMessage({ id: 'map.tcmDisplay.edit.type.error' })}
                />
            )}
            {this.state.isEditMode ? this.generateEditPart() : this.generateViewPart()}
        </Panel>
    );
}

export default injectIntl(TypeConfiguration);
