const INITIAL_POSITION_Y = 50;
const INITIAL_POSITION_X = 300;
const GAP_Y = 100;
const GAP_X = 300;

export const virtualDisplayYPosition = (): VirtualDisplayYPositionInterface => {
    const FOUR_LINES_MATRIX_HEIGHT = 189;
    const ONE_LINE_MATRIX_HEIGHT = 87;
    const LINE_HEIGHT = (FOUR_LINES_MATRIX_HEIGHT - ONE_LINE_MATRIX_HEIGHT) / 3;

    return {
        oneLineMatrixHeight: ONE_LINE_MATRIX_HEIGHT,
        fourLinesMatrixHeight: FOUR_LINES_MATRIX_HEIGHT,
        initialPositionY: INITIAL_POSITION_Y,
        initialPositionX: INITIAL_POSITION_X,
        gapY: GAP_Y,
        lineHeight: LINE_HEIGHT,
        baseTopologyHeight: ONE_LINE_MATRIX_HEIGHT - LINE_HEIGHT,
    };
};

export const virtualDisplayXPosition = (): VirtualDisplayXPositionInterface => {
    const TWO_COLUMNS_MATRIX_WIDTH = 98.5;
    const FOUR_COLUMNS_MATRIX_WIDTH = 148;
    const COLUMN_WIDTH = (FOUR_COLUMNS_MATRIX_WIDTH - TWO_COLUMNS_MATRIX_WIDTH) / 2;

    return {
        twoColumnsMatrixWidth: TWO_COLUMNS_MATRIX_WIDTH,
        fourColumnsMatrixWidth: FOUR_COLUMNS_MATRIX_WIDTH,
        initialPositionX: INITIAL_POSITION_X,
        gapX: GAP_X,
        columnWidth: COLUMN_WIDTH,
        baseTopologyWidth: TWO_COLUMNS_MATRIX_WIDTH - COLUMN_WIDTH,
    };
};

export interface VirtualDisplayYPositionInterface {
    oneLineMatrixHeight: number;
    fourLinesMatrixHeight: number;
    initialPositionY: number;
    initialPositionX: number;
    gapY: number;
    lineHeight: number;
    baseTopologyHeight: number;
}

export interface VirtualDisplayXPositionInterface {
    twoColumnsMatrixWidth: number;
    fourColumnsMatrixWidth: number;
    initialPositionX: number;
    gapX: number;
    columnWidth: number;
    baseTopologyWidth: number;
}
