class ActionObserverClass {
    private _listeners: Array<any>;

    constructor() {
        this._listeners = [];
    }

    subscribe(fn: CallableFunction): void {
        this._listeners.push(fn);
    }

    unsubscribe(fn: CallableFunction): void {
        this._listeners = this._listeners.filter(listener => listener !== fn);
    }

    emit(event: ActionObserverEvent, data?: any): void {
        this._listeners.forEach(listener => listener.call(null, event, data));
    }
}

export enum ActionObserverEvent {
    CREATE_VIRTUAL_DISPLAY = 'create_virtual_display',
    UPDATE_VIRTUAL_DISPLAY = 'update_virtual_display',
    REMOVE_VIRTUAL_DISPLAY_FROM_TOPOLOGY = 'remove_virtual_display_from_topology',
    DELETE_VIRTUAL_DISPLAY = 'delete_virtual_display',
    ADD_TOPOLOGY_TRIGGER = 'add_topology_trigger',
    UPDATE_TOPOLOGY_TRIGGER = 'update_topology_trigger',
    DELETE_TOPOLOGY_TRIGGER = 'delete_topology_trigger',
    ADD_VIRTUAL_DISPLAY_TO_TOPOLOGY = 'add_virtual_display_to_topology',
    UPDATE_VIRTUAL_DISPLAY_STEPS = 'update_virtual_display_steps',
    UPDATE_VIRTUAL_DISPLAY_ORDER = 'update_virtual_display_order',
    UPDATE_VIRTUAL_DISPLAY_STEPS_ORDER = 'update_virtual_display_steps_order',
}

const ActionObserver = new ActionObserverClass();
export { ActionObserver };
