import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Panel, Toggle } from 'rsuite';
import { FontValueInterface } from '..';
import PanelHeader from '../../Custom/PanelHeader';
import { FontPreview } from './fontPreview';

type Props = {
    fonts: FontValueInterface[];
    selectedFontIndex: number;
    setClickedFontIndex: (index: number) => void;
    fontSize: number;
    fontToCopy: number;
};

export const LeftSideBar = (props: Props) => {
    const [showEmptyFont, setShowEmptyFont] = React.useState(false);

    const intl = useIntl();

    return (
        <Panel
            bordered
            style={{ maxHeight: '97vh', overflow: 'scroll' }}
            bodyFill
            shaded
            className="panel-small panel-sticky"
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'editFont.fontSelector' })}
                    buttons={[
                        <Toggle
                            size="md"
                            onChange={checked => setShowEmptyFont(checked)}
                            className="margin-right-5"
                            checkedChildren={<FontAwesomeIcon icon={faEye} />}
                            unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} color="black" />}
                        />,
                    ]}
                />
            }>
            {/* <FlexboxGrid
                className="padding-top-10 padding-bottom-10"
                style={{ backgroundColor: '#212121', color: 'white' }}
                align="middle"
                justify="center">
                <FlexboxGrid.Item>
                    <Toggle size="md" onChange={checked => setShowEmptyFont(checked)} className="margin-right-5" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <FormattedMessage id="editFont.showAll" />
                </FlexboxGrid.Item>
            </FlexboxGrid> */}

            {props.fonts.map((font, index) => {
                if ((!showEmptyFont && font.value.length !== 0) || showEmptyFont) {
                    return (
                        <div
                            style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.4)' }}
                            key={index}
                            data-cy={`font-${index}`}
                            onClick={() => props.setClickedFontIndex(index)}>
                            <FontPreview
                                fontSize={props.fontSize}
                                font={font}
                                isSelected={index === props.selectedFontIndex}
                                index={index}
                                isCopied={props.fontToCopy === index}
                            />
                        </div>
                    );
                }
            })}
        </Panel>
    );
};
