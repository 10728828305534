import { ipCanDevicesConstants } from '../constants';

type State = {
    isLoading: boolean;
    sensors: Array<Record<string, any>>;
    displays: Array<Record<string, any>>;
    ipCan: undefined | Record<string, any>;
    error: string | undefined;
};

const initialState = {
    isLoading: false,
    sensors: [],
    displays: [],
    ipCan: undefined,
    error: undefined,
};

export function ipcanDevices(state: State = initialState, action: Record<string, any>) {
    switch (action.type) {
        case ipCanDevicesConstants.GET_BUS_DEVICES_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case ipCanDevicesConstants.GET_BUS_DEVICES_SUCCESS:
            return {
                isLoading: false,
                sensors: action.sensors,
                displays: action.displays,
                ipCan: action.ipCan,
                error: undefined,
            };
        case ipCanDevicesConstants.GET_BUS_DEVICES_FAILURE:
            return {
                isLoading: false,
                sensors: [],
                displays: [],
                ipCan: undefined,
                error: action.error,
            };
        case ipCanDevicesConstants.UPDATE_DISPLAY_CONFIGURATION_REQUEST:
            return { ...state };
        case ipCanDevicesConstants.UPDATE_DISPLAY_CONFIGURATION_SUCCESS:
            return {
                ...state,
                displays: state.displays.map(display => {
                    if (display.id === action.display.id) {
                        return action.display;
                    } else {
                        return display;
                    }
                }),
            };
        case ipCanDevicesConstants.UPDATE_DISPLAY_CONFIGURATION_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case ipCanDevicesConstants.DELETE_DISPLAY_REQUEST:
            return { ...state };
        case ipCanDevicesConstants.DELETE_DISPLAY_SUCCESS:
            // TODO: Change this maybe
            var displaysId: Array<number> = [];
            for (let d in action.display) {
                displaysId.push(action.display[d].id);
            }

            return {
                ...state,
                displays: state.displays.filter(display => {
                    if (!displaysId.includes(display.id)) {
                        return display;
                    } else {
                        return null;
                    }
                }),
            };
        case ipCanDevicesConstants.DELETE_DISPLAY_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case ipCanDevicesConstants.UPDATE_SENSOR_REQUEST:
            return {
                ...state,
            };
        case ipCanDevicesConstants.UPDATE_SENSOR_SUCCESS:
            return {
                ...state,
                sensors: state.sensors.map(sensor => {
                    if (sensor.id === action.sensor.id) {
                        return { ...sensor, ...action.sensor };
                    } else {
                        return sensor;
                    }
                }),
            };
        case ipCanDevicesConstants.UPDATE_SENSOR_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case ipCanDevicesConstants.DELETE_SENSOR_REQUEST:
            return {
                ...state,
            };
        case ipCanDevicesConstants.DELETE_SENSOR_SUCCESS:
            // TODO: Change this maybe
            var sensorsId: Array<number> = [];
            for (let d in action.sensor) {
                sensorsId.push(action.sensor[d].id);
            }

            return {
                ...state,
                sensors: state.sensors.filter(sensor => {
                    if (!sensorsId.includes(sensor.id)) {
                        return sensor;
                    } else {
                        return null;
                    }
                }),
            };
        case ipCanDevicesConstants.DELETE_SENSOR_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case ipCanDevicesConstants.WEBSOCKET_UPDATE_DISPLAY: {
            let displayIndex = state.displays.findIndex(
                display =>
                    display.bus === action.bus &&
                    display.deviceId === action.data.deviceId &&
                    state.ipCan?.id === action.data.ipCanId
            );

            if (displayIndex === -1 && action.data.bus === action.bus && state.ipCan?.id === action.data.ipCanId) {
                return {
                    ...state,
                    displays: state.displays.concat({
                        ...action.data,
                    }),
                };
            } else if (action.data.bus === action.bus && state.ipCan?.id === action.data.ipCanId) {
                let displays = state.displays;
                displays[displayIndex] = { ...displays[displayIndex], ...action.data };

                return {
                    ...state,
                    displays,
                };
            } else {
                return state;
            }
        }
        case ipCanDevicesConstants.WEBSOCKET_UPDATE_SENSOR: {
            let sensorIndex = state.sensors.findIndex(
                sensor =>
                    sensor.bus === action.bus &&
                    sensor.deviceId === action.data.deviceId &&
                    state.ipCan?.id === action.data.ipCanId
            );

            if (sensorIndex === -1 && action.data.bus === action.bus && state.ipCan?.id === action.data.ipCanId) {
                return {
                    ...state,
                    sensors: state.sensors.concat({
                        ...action.data,
                    }),
                };
            } else if (action.data.bus === action.bus && state.ipCan?.id === action.data.ipCanId) {
                let sensors = state.sensors;
                sensors[sensorIndex] = { ...sensors[sensorIndex], ...action.data };

                return {
                    ...state,
                    sensors,
                };
            } else {
                return state;
            }
        }
        case ipCanDevicesConstants.UPDATE_DISPLAY_FORCE: {
            let newArray = state.displays;
            let dataIndex: Array<number> = [];

            for (let d in action.data) {
                dataIndex.push(newArray.findIndex(array => array.id === action.data[d].id));
            }

            for (let i = 0; i < dataIndex.length; i++) {
                newArray[i] = { ...newArray[i], ...action.data[i] };
            }

            return {
                ...state,
                displays: newArray,
            };
        }
        case ipCanDevicesConstants.UPDATE_SENSOR_FORCE: {
            let newArray = state.sensors;
            let dataIndex: Array<number> = [];

            for (let d in action.data) {
                dataIndex.push(newArray.findIndex(array => array.id === action.data[d].id));
            }

            for (let i = 0; i < dataIndex.length; i++) {
                newArray[i] = { ...newArray[i], ...action.data[i] };
            }

            return {
                ...state,
                sensors: newArray,
            };
        }
        default:
            return state;
    }
}
