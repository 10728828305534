import React from 'react';
import noImage from '../../../style/assets/eye-slash-solid.svg';

export default function imageCell(name, image, imageType) {
    if (!image) {
        return (
            <div
                data-cy="placeType-image-vide"
                style={{
                    height: 40,
                    background: '#f5f5f5',
                    marginTop: 2,
                    overflow: 'hidden',
                    display: 'inline-block',
                }}>
                <img
                    src={noImage}
                    style={{
                        width: 44,
                        backgroundColor: 'white',
                        lineHeight: 1,
                    }}
                    alt="empty"
                />
            </div>
        );
    } else {
        return (
            <div
                data-cy="placeType-image"
                style={{
                    height: 40,
                    background: '#f5f5f5',
                    marginTop: 2,
                    overflow: 'hidden',
                    display: 'inline-block',
                }}>
                <img alt={`${name}-logo`} src={`data:image/${imageType};base64, ${image}`} width="44" />
            </div>
        );
    }
}
