import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';

export default function DefineOneSensorId(options) {
    return (
        <div>
            <FormattedMessage id="history.defineOneSensorId.bus" /> : <Tag color="violet">{options.bus + 1}</Tag>
            <br />
            <span>
                <FormattedMessage id="history.defineOneSensorId.oldId" /> : <Tag color="red">{options.oldId}</Tag>
            </span>
            <br />
            {options.withButton ? (
                <Tag color="green">
                    <FormattedMessage id="history.defineOneSensorId.withButton" />
                </Tag>
            ) : (
                <span>
                    <FormattedMessage id="history.defineOneSensorId.newId" /> : <Tag color="green">{options.newId}</Tag>
                </span>
            )}
        </div>
    );
}
