import { ArrowHeadType, EdgeProps, getBezierPath, getMarkerEnd, NodeComponentProps } from 'react-flow-renderer';
import { useIntl } from 'react-intl';
import { LPMatrixDisplayChartTopologyTriggerEdgeType } from '../LPMatrixDisplayChartTopologyTriggerEdge';

type Props = NodeComponentProps<LPMatrixDisplayChartTopologyTriggerEdgeType> & EdgeProps;

export const LPMatrixDisplayChartTopologyTriggerEdgeComponent = (props: Props) => {
    const intl = useIntl();

    const edgePath = getBezierPath({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        sourcePosition: props.sourcePosition,
        targetX: props.targetX,
        targetY: props.targetY,
        targetPosition: props.targetPosition,
    });

    const foreignObjectSize = 100;

    const markerEnd = getMarkerEnd(ArrowHeadType.Arrow, props.markerEndId);

    const generateMode = () => {
        if (!props.data) return '';

        switch (props.data.trigger.trigger) {
            case 1: {
                return `${intl.formatMessage(
                    { id: 'ipCanDevices.lpDisplays.topology.sourceTime' },
                    {
                        value: props.data.trigger.value,
                    }
                )}`;
            }
            case 2: {
                return `${intl.formatMessage(
                    {
                        id: 'ipCanDevices.lpDisplays.topology.sourceCounterMin',
                    },
                    {
                        source: props.data.trigger.source,
                        value: props.data.trigger.value,
                    }
                )}`;
            }
            case 3: {
                return `${intl.formatMessage(
                    {
                        id: 'ipCanDevices.lpDisplays.topology.sourceCounterMax',
                    },
                    {
                        source: props.data.trigger.source,
                        value: props.data.trigger.value,
                    }
                )}`;
            }
            case 4: {
                return `${intl.formatMessage(
                    {
                        id: 'ipCanDevices.lpDisplays.topology.sourceGpio',
                    },
                    {
                        value: props.data.trigger.value + 1,
                    }
                )}`;
            }
            case 5: {
                return `${intl.formatMessage(
                    {
                        id: 'ipCanDevices.lpDisplays.topology.sourceInvertGPIO',
                    },
                    {
                        value: props.data.trigger.value + 1,
                    }
                )}`;
            }
            default:
                return null;
        }
    };

    return (
        <>
            <path
                id={props.id}
                style={{ stroke: props.data.color, strokeWidth: 3 }}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize / 5}
                x={props.sourceX - foreignObjectSize / 2}
                y={props.sourceY + 2}
                requiredExtensions="http://www.w3.org/1999/xhtml">
                <div
                    style={{
                        backgroundColor: 'white',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '5px',
                    }}>
                    {generateMode()}
                </div>
            </foreignObject>
        </>
    );
};
