import { faCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, Col, FlexboxGrid, Form, FormControl, List, Panel, SelectPicker } from 'rsuite';
import { colorsPicker, colorsValues } from '../../../../handlers/ipCanDevices/const/colors';
import TCMSensor from '../../../../handlers/ipCanDevices/TCMSensor';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';

type Props = {
    sensor: TCMSensor;
    isCheckingDatabase: boolean;
    databaseData?: Record<string, any> | undefined;
} & WrappedComponentProps;

type State = {
    primaryColor: boolean;
    forced: string;
    free: string;
    occupied: string;
    overstay: string;
    overstayFree: string;
    forced_check: string | undefined;
    free_check: string | undefined;
    occupied_check: string | undefined;
    overstay_check: string | undefined;
    overstayFree_check: string | undefined;
    editMode: boolean;
    formValue: Record<string, any>;
    isUpdating: boolean;
};
class ColorCard extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        const color = this.props.sensor.color.fetchColors;

        this.state = {
            primaryColor: true,
            forced: color.forced.color,
            free: color.free.color,
            occupied: color.occupied.color,
            overstay: color.overstay.color,
            overstayFree: color.overstayFree.color,
            forced_check: undefined,
            free_check: undefined,
            occupied_check: undefined,
            overstay_check: undefined,
            overstayFree_check: undefined,
            editMode: false,
            isUpdating: false,
            formValue: {
                forced: color.forced.value,
                free: color.free.value,
                occupied: color.occupied.value,
                overstay: color.overstay.value,
                overstayFree: color.overstayFree.value,
            },
        };

        this.changeColor = this.changeColor.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.cancel = this.cancel.bind(this);
        this.valid = this.valid.bind(this);
    }

    componentDidMount() {
        setInterval(this.changeColor, 1000);
    }

    changeColor() {
        const color = this.props.sensor.color.fetchColors;
        if (this.state.primaryColor) {
            this.setState({
                primaryColor: false,
                forced: color.forced.secondColor,
                free: color.free.secondColor,
                occupied: color.occupied.secondColor,
                overstay: color.overstay.secondColor,
                overstayFree: color.overstayFree.secondColor,
            });
        } else {
            this.setState({
                primaryColor: true,
                forced: color.forced.color,
                free: color.free.color,
                occupied: color.occupied.color,
                overstay: color.overstay.color,
                overstayFree: color.overstayFree.color,
            });
        }

        if (this.props.isCheckingDatabase) {
            const forced = colorsValues[this.props.databaseData?.color.forced];
            const free = colorsValues[this.props.databaseData?.color.free];
            const occupied = colorsValues[this.props.databaseData?.color.occupied];
            const overstay = colorsValues[this.props.databaseData?.color.overstay];
            const overstayFree = colorsValues[this.props.databaseData?.color.overstayFree];

            if (this.state.primaryColor) {
                this.setState({
                    forced_check: forced.color,
                    free_check: free.color,
                    occupied_check: occupied.color,
                    overstay_check: overstay.color,
                    overstayFree_check: overstayFree.color,
                });
            } else if (this.props.isCheckingDatabase && !this.state.primaryColor) {
                this.setState({
                    forced_check: forced.secondColor,
                    free_check: free.secondColor,
                    occupied_check: occupied.secondColor,
                    overstay_check: overstay.secondColor,
                    overstayFree_check: overstayFree.secondColor,
                });
            }
        }
    }

    toggleEditMode() {
        this.setState({
            editMode: !this.state.editMode,
        });
    }

    cancel() {
        const color = this.props.sensor.color.fetchColors;
        this.setState({
            editMode: false,
            formValue: {
                forced: color.forced.value,
                free: color.free.value,
                occupied: color.occupied.value,
                overstay: color.overstay.value,
                overstayFree: color.overstayFree.value,
            },
        });
    }

    valid() {
        const { forced, free, occupied, overstay, overstayFree } = this.state.formValue;

        this.setState({
            isUpdating: true
        });

        this.props.sensor.color.setColors(this.props.sensor.id, free, occupied, overstayFree, overstay, forced)
            .then(() => {
                this.setState({
                    editMode: false
                });
                Alert.success(this.props.intl.formatMessage({ id: 'tcmSensor.color.update.success' }))
            })
            .catch((err) => {
                Alert.error(this.props.intl.formatMessage({ id: 'tcmSensor.color.update.error' }, { error: err }))
            }).finally(() => {
                this.setState({
                    isUpdating: false,
                })
            });

        // this.props.updateColors(color);
    }

    render() {
        let forcedClass;
        let freeClass;
        let occupiedClass;
        let overstayClass;
        let overstayFreeClass;

        const color = this.props.sensor.color.fetchColors;

        if (this.props.isCheckingDatabase) {
            forcedClass =
                color.forced.value === this.props.databaseData?.color.forced ? 'list-green' : 'list-red';
            freeClass = color.free.value === this.props.databaseData?.color.free ? 'list-green' : 'list-red';
            occupiedClass =
                color.occupied.value === this.props.databaseData?.color.occupied ? 'list-green' : 'list-red';
            overstayClass =
                color.overstay.value === this.props.databaseData?.color.overstay ? 'list-green' : 'list-red';
            overstayFreeClass =
                color.overstayFree.value === this.props.databaseData?.color.overstayFree
                    ? 'list-green'
                    : 'list-red';
        }

        return (
            <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.UPDATE_COLOR_CONFIG]}>
                <Panel
                    className={'panel-small'}
                    shaded
                    data-cy="ipcan-elements-sensor-drawer-color"
                    bordered
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'ipCanDevices.sensor.color' })}
                            buttons={
                                this.props.isCheckingDatabase
                                    ? []
                                    : [
                                            <Button
                                                data-cy="sensor-color-edit"
                                                key="color-edit"
                                                color="blue"
                                                size="sm"
                                                onClick={this.toggleEditMode}
                                                disabled={this.state.editMode}>
                                                <FontAwesomeIcon icon={faEdit} />
                                                <FormattedMessage id="ipCanDevices.sensor.color.edit" />
                                            </Button>,
                                        ]
                            }
                        />
                    }
                    bodyFill>
                    <Form onChange={formValue => this.setState({ formValue })} formValue={this.state.formValue}>
                        <List hover>
                            <List.Item className={`panel-list ${forcedClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-forced" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.color.forced" />
                                    </FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                accepter={SelectPicker}
                                                cleanable={false}
                                                data-cy="sensor-color-edit-forced"
                                                data={colorsPicker.map(colorPicker => {
                                                    return {
                                                        ...colorPicker,
                                                        label: this.props.intl.formatMessage({
                                                            id: colorPicker.label,
                                                        }),
                                                    };
                                                })}
                                                style={{ width: 224 }}
                                                size="sm"
                                                name="forced"
                                                disabled={this.state.isUpdating}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div
                                                            data-cy={`color-forced-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <Fragment>
                                            {/* DATABASE CHECKING */}
                                            {this.props.isCheckingDatabase && (
                                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        style={{
                                                            color: this.state.forced_check,
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                            )}

                                            <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-forced-value">
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    style={{
                                                        color: this.state.forced,
                                                    }}
                                                />
                                            </FlexboxGrid.Item>
                                        </Fragment>
                                    )}
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${freeClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-free" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.color.free" />
                                    </FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                accepter={SelectPicker}
                                                cleanable={false}
                                                data-cy="sensor-color-edit-free"
                                                data={colorsPicker.map(colorPicker => {
                                                    return {
                                                        ...colorPicker,
                                                        label: this.props.intl.formatMessage({
                                                            id: colorPicker.label,
                                                        }),
                                                    };
                                                })}
                                                style={{ width: 224 }}
                                                size="sm"
                                                name="free"
                                                disabled={this.state.isUpdating}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div
                                                            data-cy={`color-free-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <Fragment>
                                            {/* DATABASE CHECKING */}
                                            {this.props.isCheckingDatabase && (
                                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        style={{
                                                            color: this.state.free_check,
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                            )}
                                            <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-free-value">
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    style={{
                                                        color: this.state.free,
                                                    }}
                                                />
                                            </FlexboxGrid.Item>
                                        </Fragment>
                                    )}
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${occupiedClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-occupied" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.color.occupied" />
                                    </FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                accepter={SelectPicker}
                                                cleanable={false}
                                                data-cy="sensor-color-edit-occupied"
                                                data={colorsPicker.map(colorPicker => {
                                                    return {
                                                        ...colorPicker,
                                                        label: this.props.intl.formatMessage({
                                                            id: colorPicker.label,
                                                        }),
                                                    };
                                                })}
                                                style={{ width: 224 }}
                                                size="sm"
                                                name="occupied"
                                                disabled={this.state.isUpdating}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div
                                                            data-cy={`color-occupied-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <Fragment>
                                            {/* DATABASE CHECKING */}
                                            {this.props.isCheckingDatabase && (
                                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        style={{
                                                            color: this.state.occupied_check,
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                            )}
                                            <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-occupied-value">
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    style={{
                                                        color: this.state.occupied,
                                                    }}
                                                />
                                            </FlexboxGrid.Item>
                                        </Fragment>
                                    )}
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${overstayClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-overstay" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.color.overstay" />
                                    </FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                accepter={SelectPicker}
                                                cleanable={false}
                                                data-cy="sensor-color-edit-overstay"
                                                data={colorsPicker.map(colorPicker => {
                                                    return {
                                                        ...colorPicker,
                                                        label: this.props.intl.formatMessage({
                                                            id: colorPicker.label,
                                                        }),
                                                    };
                                                })}
                                                style={{ width: 224 }}
                                                size="sm"
                                                name="overstay"
                                                disabled={this.state.isUpdating}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div
                                                            data-cy={`color-overstay-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <Fragment>
                                            {/* DATABASE CHECKING */}
                                            {this.props.isCheckingDatabase && (
                                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        style={{
                                                            color: this.state.overstay_check,
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                            )}

                                            <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-overstay-value">
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    style={{
                                                        color: this.state.overstay,
                                                    }}
                                                />
                                            </FlexboxGrid.Item>
                                        </Fragment>
                                    )}
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${overstayFreeClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-overstayFree" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.color.overstayFree" />
                                    </FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                accepter={SelectPicker}
                                                cleanable={false}
                                                data-cy="sensor-color-edit-overstayFree"
                                                data={colorsPicker.map(colorPicker => {
                                                    return {
                                                        ...colorPicker,
                                                        label: this.props.intl.formatMessage({
                                                            id: colorPicker.label,
                                                        }),
                                                    };
                                                })}
                                                style={{ width: 224 }}
                                                size="sm"
                                                name="overstayFree"
                                                disabled={this.state.isUpdating}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div
                                                            data-cy={`color-overstayFree-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <Fragment>
                                            {/* DATABASE CHECKING */}
                                            {this.props.isCheckingDatabase && (
                                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        style={{
                                                            color: this.state.overstayFree_check,
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                            )}

                                            <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-color-overstayFree-value">
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    style={{
                                                        color: this.state.overstayFree,
                                                    }}
                                                />
                                            </FlexboxGrid.Item>
                                        </Fragment>
                                    )}
                                </FlexboxGrid>
                            </List.Item>

                            {this.state.editMode && (
                                <List.Item className={'panel-list'}>
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item>
                                            <Fragment>
                                                <Button
                                                    data-cy="ipCanDevices-sensor-edit-valid"
                                                    color="green"
                                                    onClick={this.valid}
                                                    loading={this.state.isUpdating}>
                                                    <FormattedMessage id="ipCanDevices.sensor.color.edit.valid" />
                                                </Button>
                                                <Button
                                                    data-cy="ipCanDevices-sensor-edit-cancel"
                                                    appearance="link"
                                                    onClick={this.cancel}
                                                    disabled={this.state.isUpdating}>
                                                    <FormattedMessage id="ipCanDevices.sensor.color.edit.cancel" />
                                                </Button>
                                            </Fragment>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            )}
                        </List>
                    </Form>
                </Panel>
            </SecuredFragment>
        );
    }
}

export default injectIntl(ColorCard);