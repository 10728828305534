import { faCopy, faDesktop, faPlug, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Panel, Tag } from 'rsuite';
import { LPDisplay } from '../../handlers/lpDisplays/LPDisplay';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import LPDisplayDrawer from './Drawer/LPDisplay/LPDisplayDrawer';
import { CopyLPDisplayConfig } from './LPDisplays/Modal/CopyLPDisplayConfig';
import DeleteLPDisplayModal from './LPDisplays/Modal/DeleteLPDisplayModal';
import VirtualDisplaysComponent from './LPDisplays/VirtualDisplay/VirtualDisplaysComponent';
import lpDisplayForcedCell from './Table/lpDisplayForcedCell';
import { maintenanceStateInformation } from './Table/maintenanceStateInformation';

type Props = {
    isLoading: boolean;
    displays: Array<LPDisplay>;
    reloadDevices: Function;
    ipCanId: number;
    ipCanLabel?: string;
} & WrappedComponentProps;

type State = {
    displayDrawerOpen: boolean;
    selectedDisplay?: number;
    showDeleteLPDisplayModal: boolean;
    displaysChecked: number[];
    toggledClearRows: boolean;
    showEditLPDisplayModal: boolean;
    showCopyLPDisplayModal: boolean;
    lpDisplayToCopy?: LPDisplay;
};

class LPDisplaysTable extends React.Component<Props, State> {
    columns: TableColumn<LPDisplay>[];

    constructor(props: Props) {
        super(props);

        this.state = {
            displayDrawerOpen: false,
            selectedDisplay: -1,
            showDeleteLPDisplayModal: false,
            displaysChecked: [],
            toggledClearRows: false,
            showEditLPDisplayModal: false,
            showCopyLPDisplayModal: false,
        };

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.online',
                }),
                cell: row => (
                    <div data-cy="lpDisplay-status" style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faPlug} color={row.getOnline() ? 'green' : 'red'} />
                        <p>{row.getLastOnlineStateDate()}</p>
                    </div>
                ),
                sortable: true,
                selector: row => row.online,
                center: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.maintenanceState',
                }),
                selector: row => row.getMaintenanceState(),
                center: true,
                sortable: true,
                grow: 2,
                cell: row => (
                    <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_MAINTENACE_STATE]}>
                        {maintenanceStateInformation(row.getMaintenanceState(), row.getNotepad())}
                    </SecuredFragment>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.address',
                }),
                cell: row => <div data-cy="lpDisplay-address">{row.getDeviceId()}</div>,
                sortable: true,
                selector: row => row.deviceId,
                center: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.softwareVersion',
                }),
                cell: row => (
                    <div>
                        <Tag color="blue" data-cy="lpDisplays-softwareVersion">
                            {row.getInfoConfig().getSoftwareVersion()}
                        </Tag>
                        <Tag color="violet" data-cy="lpDisplays-modelName">
                            {row.getInfoConfig().getModelName()}
                        </Tag>
                    </div>
                ),
                sortable: true,
                selector: row => (
                    <div data-cy="lpDisplay-softwareVersion">{row.getInfoConfig().getSoftwareVersion()}</div>
                ),
                center: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.size',
                }),
                cell: row => (
                    <span data-cy="lpDisplay-size">{`${row
                        .getBaseConfig()
                        .getNbPixelY()}px x ${row.getBaseConfig().getNbPixelX()}px`}</span>
                ),
                center: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.nbVirtualDisplays',
                }),
                cell: row => (
                    <Tag data-cy="lpDisplay-nbVirtualDisplays" color="blue">
                        {row.getVirtualDisplays().length}
                    </Tag>
                ),
                center: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.isForced',
                }),
                center: true,
                grow: 1,

                cell: row => lpDisplayForcedCell(row.isForce, row.endForceTimeFormatted),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.copyConfig',
                }),
                cell: row => (
                    <Button color="blue" onClick={() => this.openCopyModal(row)}>
                        <FontAwesomeIcon icon={faCopy} />
                    </Button>
                ),
                center: true,
            },
        ];
    }

    openCopyModal = (data: LPDisplay) => {
        this.setState({
            showCopyLPDisplayModal: true,
            lpDisplayToCopy: data,
        });
    };

    closeCopyModal = (shouldReload: boolean) => {
        this.setState({
            showCopyLPDisplayModal: false,
        });

        if (shouldReload) {
            this.props.reloadDevices();
        }
    };

    openDrawer = (row: LPDisplay) => {
        this.setState({
            displayDrawerOpen: true,
            selectedDisplay: row.id,
        });
    };

    closeDrawer = (shouldReloadDevices: boolean = false) => {
        this.setState({
            displayDrawerOpen: false,
            selectedDisplay: undefined,
        });

        if (shouldReloadDevices) {
            this.props.reloadDevices();
        }
    };

    showDeleteLPDisplayModal = (event: React.SyntheticEvent<Element, Event>) => {
        event.stopPropagation();

        this.setState({
            showDeleteLPDisplayModal: true,
        });
    };

    hideDeleteLPDisplayModal = (shouldReloadDevices: boolean = false) => {
        this.setState({
            showDeleteLPDisplayModal: false,
            toggledClearRows: true,
            displaysChecked: [],
        });

        if (shouldReloadDevices) {
            this.props.reloadDevices();
        }
    };

    selectedRowsChange = ({ selectedRows }) => {
        this.setState({
            displaysChecked: selectedRows.map(row => row.id),
        });
    };

    shouldDisableDeleteButton = (): boolean => {
        if (this.state.displaysChecked.length === 0) return true;

        const selectedDisplays = this.props.displays.filter(display => this.state.displaysChecked.includes(display.id));

        return selectedDisplays.some(display => display.getOnline());
    };

    render = () => {
        const data = [...this.props.displays];
        const selectedDisplay = this.props.displays.find(display => display.getId() === this.state.selectedDisplay);

        return (
            <Fragment>
                <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.DELETE]}>
                    <DeleteLPDisplayModal
                        show={this.state.showDeleteLPDisplayModal}
                        onHide={this.hideDeleteLPDisplayModal}
                        lpDisplaysIds={this.state.displaysChecked}
                    />
                </SecuredFragment>

                {typeof selectedDisplay !== 'undefined' && (
                    <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.VIEW]}>
                        <LPDisplayDrawer
                            show={this.state.displayDrawerOpen}
                            onHide={this.closeDrawer}
                            reloadDisplays={this.props.reloadDevices}
                            display={selectedDisplay}
                            ipCanLabel={this.props.ipCanLabel}
                            ipCanId={this.props.ipCanId}
                            isLoading={this.props.isLoading}
                        />
                    </SecuredFragment>
                )}

                {this.state.lpDisplayToCopy && (
                    <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.COPY_CONFIG]}>
                        <CopyLPDisplayConfig
                            displayToCopy={this.state.lpDisplayToCopy}
                            show={this.state.showCopyLPDisplayModal}
                            onHide={shouldReload => this.closeCopyModal(shouldReload)}
                            ipCanId={this.props.ipCanId}
                        />
                    </SecuredFragment>
                )}

                <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.VIEW_LIST]}>
                    <Panel
                        className={'panel-big element-table dashboard-card-header rs-panel-collapsible-no-carret'}
                        shaded
                        bordered
                        header={
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplays' })}
                                tagValue={this.props.displays.length}
                                icon={faDesktop}
                                buttons={[
                                    <Button
                                        key="moduleIPCan-sensors-table-multiDelete"
                                        color="red"
                                        data-cy="moduleIPCan-sensors-table-multiDelete"
                                        disabled={this.shouldDisableDeleteButton()}
                                        onClick={this.showDeleteLPDisplayModal}>
                                        <FontAwesomeIcon icon={faTrash} className="margin-right-10" />
                                        <FormattedMessage id="ipCanDevices.lpDisplay.deleteMode" />
                                    </Button>,
                                ]}
                            />
                        }
                        collapsible
                        defaultExpanded={this.props.displays.length > 0}
                        bodyFill
                        data-cy="moduleIPCan-sensors-table">
                        <ElementTable
                            columns={this.columns}
                            data={data}
                            striped
                            onRowClicked={this.openDrawer}
                            highlightOnHover
                            selectableRows
                            onSelectedRowsChange={this.selectedRowsChange}
                            clearSelectedRows={this.state.toggledClearRows}
                            expandableRows
                            expandableRowsComponent={VirtualDisplaysComponent}
                            expandableRowDisabled={row => row.getVirtualDisplays().length === 0}
                            expandableRowsComponentProps={{
                                reloadDevices: this.props.reloadDevices,
                                isLoading: this.props.isLoading,
                            }}
                        />
                    </Panel>
                </SecuredFragment>
            </Fragment>
        );
    };
}

export default injectIntl(LPDisplaysTable);
