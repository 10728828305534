import moment from 'moment';

export function tcmDisplaysHandler(tcmDisplay) {
    return {
        ...tcmDisplay,
        arrow: tcmDisplay.arrow,
        bus: tcmDisplay.bus,
        configType: tcmDisplay.configType,
        counters: tcmDisplay.counters,
        substractionCounters: tcmDisplay.substractionCounters,
        createdAt: tcmDisplay.createdAt,
        createdBy: tcmDisplay.createdBy,
        creation: `${tcmDisplay.createdBy} - ${moment(tcmDisplay.createdAt).format('DD/MM/YYYY HH:mm:ss')}`,
        deviceId: tcmDisplay.deviceId,
        displayValue: tcmDisplay.displayValue
            ? tcmDisplay.displayValue
            : { generic: -1, pmr: -1, electrical: -1, family: -1 },
        id: tcmDisplay.id,
        lang: tcmDisplay.lang,
        manufacturer: tcmDisplay.manufacturer,
        modelName: tcmDisplay.modelName,
        online: tcmDisplay.online,
        text: tcmDisplay.text,
        type: tcmDisplay.type,
        updatedAt: tcmDisplay.updatedAt,
        updatedBy: tcmDisplay.updatedBy,
        update: `${tcmDisplay.updatedBy} - ${moment(tcmDisplay.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`,
        version: tcmDisplay.version,
        isForce: tcmDisplay.isForce,
        forceType: tcmDisplay.forceType,
        endForceTime: tcmDisplay.endForceTime,
        lastOnlineStateDate: moment(tcmDisplay.lastOnlineStateDate).isValid()
            ? moment(tcmDisplay.lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss')
            : null,
        maintenanceState: tcmDisplay.maintenanceState,
        notepad: tcmDisplay.notepad,
    };
}
