type Props = {
    color: string;
};

export const ElectricalIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} x="32" y="192" width="96" height="128" />
            <rect fill={color} x="128" y="160" width="32" height="64" />
            <rect fill={color} x="352" y="96" width="32" height="320" />
            <g>
                <rect fill={color} x="256" y="64" width="32" height="64" />
                <rect fill={color} x="256" y="384" width="32" height="64" />
                <rect fill={color} x="192" y="96" width="32" height="64" />
                <rect fill={color} x="192" y="352" width="32" height="64" />
                <rect fill={color} x="96" y="192" width="64" height="32" />
                <rect fill={color} x="96" y="288" width="64" height="32" />
                <rect fill={color} x="160" y="352" width="64" height="32" />
                <rect fill={color} x="160" y="128" width="64" height="32" />
                <rect fill={color} x="320" y="96" width="64" height="32" />
                <rect fill={color} x="320" y="384" width="64" height="32" />
            </g>
            <rect fill={color} x="192" y="96" width="96" height="32" />
            <rect fill={color} x="256" y="64" width="96" height="32" />
            <rect fill={color} x="352" y="128" width="128" height="32" />
            <rect fill={color} x="352" y="352" width="128" height="32" />
            <rect fill={color} x="192" y="384" width="96" height="32" />
            <rect fill={color} x="128" y="288" width="32" height="64" />
            <rect fill={color} x="480" y="320" width="32" height="32" />
            <rect fill={color} x="480" y="160" width="32" height="32" />
            <rect fill={color} x="320" y="384" width="32" height="64" />
            <rect fill={color} x="256" y="416" width="96" height="32" />
            <rect fill={color} x="320" y="64" width="32" height="64" />
            <rect fill={color} x="352" y="192" width="128" height="32" />
            <rect fill={color} x="352" y="288" width="128" height="32" />
        </g>
    </svg>
);
