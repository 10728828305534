import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal, SelectPicker } from 'rsuite';
import { ForceCounterModalInformation } from '../../ForceLPMatrixDisplayModal';
import LPMatrixDisplayFactory, { LPMatrixDisplayV2CounterForceType } from '../LPMatrixDisplayFactory';

type Props = {
    modalInformation: ForceCounterModalInformation;
    onHide: () => void;
    factory: LPMatrixDisplayFactory;
};

enum ForceType {
    NO_FORCE = 0,
    FORCE_VALUE = 1,
    FORCE_RELATIVE = 2,
    FORCE_FULL = 3,
}

type FormValue = {
    forceType: ForceType;
    forceValue: number;
};

export const ForceCounterModal = (props: Props) => {
    const intl: IntlShape = useIntl();

    const initFormValue = (): FormValue => {
        if (props.modalInformation.forceMode) {
            if (props.modalInformation.forceMode.mode === 1 && props.modalInformation.forceMode.value === 0) {
                return {
                    forceType: ForceType.FORCE_FULL,
                    forceValue: 0,
                };
            }

            if (props.modalInformation.forceMode.mode === 1) {
                return {
                    forceType: ForceType.FORCE_VALUE,
                    forceValue: props.modalInformation.forceMode.value,
                };
            }

            if (props.modalInformation.forceMode.mode === 2) {
                return {
                    forceType: ForceType.FORCE_RELATIVE,
                    forceValue: props.modalInformation.forceMode.value,
                };
            }
        }

        return {
            forceType: ForceType.NO_FORCE,
            forceValue: 0,
        };
    };

    const [formValue, setFormValue] = React.useState<FormValue>({
        forceType: ForceType.NO_FORCE,
        forceValue: 0,
    });

    useEffect(() => {
        setFormValue(initFormValue());
    }, [props.modalInformation]);

    const hideModal = () => {
        props.onHide();

        setFormValue(initFormValue());
    };

    const validEdition = () => {
        let forceType: LPMatrixDisplayV2CounterForceType = LPMatrixDisplayV2CounterForceType.NO_FORCE;
        let forceValue: number = 0;

        if (formValue.forceType === ForceType.NO_FORCE) {
            props.factory.removeCounterForced(props.modalInformation.step!.getMode() - 101);
        } else {
            if (formValue.forceType === ForceType.FORCE_VALUE) {
                forceType = LPMatrixDisplayV2CounterForceType.FORCE_VALUE;
                forceValue = formValue.forceValue;
            }

            if (formValue.forceType === ForceType.FORCE_RELATIVE) {
                forceType = LPMatrixDisplayV2CounterForceType.FORCE_RELATIVE;
                forceValue = formValue.forceValue;
            }

            if (formValue.forceType === ForceType.FORCE_FULL) {
                forceType = LPMatrixDisplayV2CounterForceType.FORCE_VALUE;
                forceValue = 0;
            }

            props.factory.addCounterForced(props.modalInformation.step!.getMode() - 101, forceType, forceValue);
        }
        props.onHide();
    };

    const shouldDisplayValue =
        formValue.forceType === ForceType.FORCE_RELATIVE || formValue.forceType === ForceType.FORCE_VALUE;

    const handleFormChange = formValueChanged => {
        setFormValue({
            forceType: formValueChanged.forceType,
            forceValue: parseInt(formValueChanged.forceValue),
        });
    };

    return (
        <Modal show={props.modalInformation.isOpen} onHide={() => hideModal()}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="map.lpMatrixDisplayV2.force.counter.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form fluid onChange={formValueChanged => handleFormChange(formValueChanged)} formValue={formValue}>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="map.lpMatrixDisplayV2.force.counter.type" />
                        </ControlLabel>
                        <FormControl
                            name="forceType"
                            accepter={SelectPicker}
                            data={ForceCounterType(intl)}
                            block
                            searchable={false}
                            cleanable={false}
                        />
                    </FormGroup>
                    {shouldDisplayValue && (
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.lpMatrixDisplayV2.force.counter.value" />
                            </ControlLabel>
                            <FormControl name="forceValue" type="number" min={formValue.forceType === ForceType.FORCE_VALUE ? 0 : undefined} />
                        </FormGroup>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={() => hideModal()} data-cy="lpDisplayV2-forceCounterModal-cancel" color="red">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={() => validEdition()}
                        data-cy="lpDisplayV2-forceCounterModal-valid"
                        appearance="primary">
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

const ForceCounterType = (intl: IntlShape): { label: string; value: number }[] => [
    {
        label: intl.formatMessage({ id: 'map.lpMatrixDisplayV2.force.counter.type.noForce' }),
        value: ForceType.NO_FORCE,
    },
    {
        label: intl.formatMessage({ id: 'map.lpMatrixDisplayV2.force.counter.type.forceValue' }),
        value: ForceType.FORCE_VALUE,
    },
    {
        label: intl.formatMessage({ id: 'map.lpMatrixDisplayV2.force.counter.type.forceRelative' }),
        value: ForceType.FORCE_RELATIVE,
    },
    {
        label: intl.formatMessage({ id: 'map.lpMatrixDisplayV2.force.counter.type.forceFull' }),
        value: ForceType.FORCE_FULL,
    },
];
