import { faCar, faCogs, faLightbulb, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Alert, Button, Col, Grid, Modal, Row } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { notificationActions } from '../../../redux/actions';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import SetOneLPIdModal from './SetOneLPIdModal';
import StopForceLPModal from './StopForceLPModal';

const mapDispatchToProps = dispatch => ({
    createNotification: (type, messageId) => dispatch(notificationActions.createNotification(type, messageId)),
});

type Props = {
    ipCan: IpCan;
    bus: number;
    createNotification: Function;
    onHide: Function;
    show: boolean;
} & WrappedComponentProps;

type State = {
    loading: boolean;
    openStopForceModal: boolean;
    showSetOneIdModal: boolean;
    ipcan?: IpCan;
    isFetching: boolean;
    hasError: boolean;
};

type SendCmd = {
    id: number;
    bus: number;
    cmd: string;
    red?: number;
    green?: number;
    blue?: number;
};

class LPSensorsActionsModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            openStopForceModal: false,
            showSetOneIdModal: false,
            isFetching: true,
            hasError: false,
        };

        this.openStopForceModal = this.openStopForceModal.bind(this);
        this.closeStopForceModal = this.closeStopForceModal.bind(this);
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get(`/ipcanmodules/${this.props.ipCan?.id}`, { headers: authHeader() })
            .then(ipcanmoduleResponse => {
                const ipcan = new IpCan(ipcanmoduleResponse.data);

                this.setState({
                    ipcan: ipcan,
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isFetching: false,
                });
            });
    }

    handleClick(cmd: string, color?: string) {
        this.setState({
            loading: true,
        });

        let data: SendCmd = {
            id: this.props.ipCan.id,
            bus: this.props.bus,
            cmd: cmd,
        };

        if (color) {
            const colorRGB = this.hexToRgb(color);

            data = { ...data, red: colorRGB?.r, green: colorRGB?.g, blue: colorRGB?.b };
        }

        axiosService
            .getAxios()
            .put('/ipcanmodules/cmdLP', data, { headers: authHeader() })
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: `ipcan.actions.${cmd}.success` }));

                this.setState({
                    loading: false,
                });
            })
            .catch(err => {
                console.error(err);

                Alert.error(this.props.intl.formatMessage({ id: `ipcan.actions.${cmd}.error` }, { error: err }));

                this.setState({
                    loading: false,
                });
            });
    }

    openStopForceModal() {
        this.setState({
            openStopForceModal: true,
        });
    }

    closeStopForceModal() {
        this.setState({
            openStopForceModal: false,
        });
        this.props.onHide();
    }

    hexToRgb(hex: string) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        if (result) {
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            };
        }

        return null;
    }

    hideSetOneIdModal = () => {
        this.setState({
            showSetOneIdModal: false,
        });
    }

    showSetOneIdModal = () => {
        this.setState({
            showSetOneIdModal: true,
        });
    }

    render() {
        return (
            <Fragment>
                <StopForceLPModal
                    show={this.state.openStopForceModal}
                    onHide={this.closeStopForceModal}
                    createNotification={this.props.createNotification}
                    ipcanId={this.props.ipCan.getId()}
                    bus={this.props.bus}
                />

                {!this.state.isFetching && this.state.ipcan && (
                    <SetOneLPIdModal
                        ipCan={this.state.ipcan}
                        bus={this.props.bus}
                        show={this.state.showSetOneIdModal}
                        onHide={this.hideSetOneIdModal}
                    />
                )}

                <Modal show={this.props.show} onHide={this.openStopForceModal} overflow={false}>
                    <Modal.Header>
                        <Modal.Title data-cy="ipCan-bus-title">
                            <FormattedMessage id="ipCan.bus.actions.title" />
                            <span className="margin-left-5">
                                {' '}
                                - {this.props.ipCan.label} - {this.props.bus + 1}
                            </span>
                        </Modal.Title>
                        <Modal.Body style={{ height: '100%' }}>
                            {/* Forçage */}
                            <Grid fluid>
                                <Row>
                                    <SecuredFragment authorizedRoles={[rolesConstants.controller.COMMAND_TCM]}>
                                        <Col xs={12}>
                                            <Button
                                                data-cy="ipCan-force-black"
                                                className="button-icon-black"
                                                loading={this.state.loading}
                                                onClick={() => this.handleClick('forceAllSensorToColor', '#000000')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceBlack" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-green"
                                                color="green"
                                                loading={this.state.loading}
                                                onClick={() => this.handleClick('forceAllSensorToColor', '#00FF00')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceGreen" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-red"
                                                color="red"
                                                loading={this.state.loading}
                                                onClick={() => this.handleClick('forceAllSensorToColor', '#FF0000')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceRed" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-blue"
                                                loading={this.state.loading}
                                                onClick={() => this.handleClick('forceAllSensorToColor', '#0000FF')}
                                                color="blue"
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceBlue" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-orange"
                                                loading={this.state.loading}
                                                color="orange"
                                                onClick={() => this.handleClick('forceAllSensorToColor', '#FF8800')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceOrange" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-disableforced"
                                                color="violet"
                                                loading={this.state.loading}
                                                onClick={() => this.handleClick('disableforcedAllSensor')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.disableForce" />
                                            </Button>
                                        </Col>
                                    </SecuredFragment>
                                    {/* Other actions */}
                                    <Col xs={12}>
                                        <SecuredFragment authorizedRoles={[rolesConstants.controller.SET_ONE_ID]}>
                                            <Button
                                                data-cy="ipCan-bus-setOneId"
                                                loading={this.state.loading}
                                                onClick={this.showSetOneIdModal}
                                                color="orange"
                                                block>
                                                <FontAwesomeIcon icon={faCogs} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.setOneId" />
                                            </Button>
                                        </SecuredFragment>

                                        <SecuredFragment authorizedRoles={[rolesConstants.controller.COMMAND_TCM]}>
                                            <Button
                                                data-cy="ipCan-bus-sendBackConfiguration"
                                                loading={this.state.loading}
                                                onClick={() => this.handleClick('sendAllDevicesConfig')}
                                                color="orange"
                                                block>
                                                <FontAwesomeIcon icon={faCogs} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.sendBackConfiguration" />
                                            </Button>
                                        </SecuredFragment>

                                        <SecuredFragment authorizedRoles={[rolesConstants.controller.SET_MANY_ID]}>
                                            <Button
                                                data-cy="ipCan-rebootAll"
                                                loading={this.state.loading}
                                                onClick={() => this.handleClick('rebootAll')}
                                                color="orange"
                                                block>
                                                <FontAwesomeIcon icon={faWrench} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.rebootAll" />
                                            </Button>
                                        </SecuredFragment>

                                        <SecuredFragment authorizedRoles={[rolesConstants.controller.DELETE_BUS]}>
                                            <Button
                                                data-cy="ipCan-sendAllDevicesScenes"
                                                loading={this.state.loading}
                                                onClick={() => this.handleClick('sendAllDevicesScene')}
                                                block
                                                color="orange">
                                                <FontAwesomeIcon icon={faCar} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.sendScenes" />
                                            </Button>
                                        </SecuredFragment>
                                    </Col>
                                </Row>
                            </Grid>
                        </Modal.Body>
                    </Modal.Header>
                </Modal>
            </Fragment>
        );
    }
}

export default connect(null, mapDispatchToProps)(injectIntl(LPSensorsActionsModal));
