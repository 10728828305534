import { faCheck, faCheckDouble, faCircle, faEdit, faPlug, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    Input,
    List,
    Message,
    Panel,
    SelectPicker,
    Tag,
} from 'rsuite';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import { LPSensorMapInformation_I } from '../../../../../handlers/map/MapLPSensor';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';
import LPSensorTypeComparissionModal from '../../../../IpCanElementsPage/LPSensors/LPSensorTypeComparissionModal';

type Props = {
    sensor: LPSensorMapInformation_I;
    controllers: IpCan[];
    placeTypes: any[];
    service: any;
    currentIpCan?: IpCan;
    showRealTimeGraph?: boolean
} & WrappedComponentProps;

type State = {
    isEditMode: boolean;
    hasError: boolean;
    isUpdating: boolean;
    formValue: FormValue;
    isCompareSceneConfigOpen: boolean;
    currentSensorPlaceType: any;
};

type FormValue = {
    label: string;
    ipcan_module: number;
    placeType?: number;
    bus: number;
    device_id: number;
};

type FormSelector = {
    label: string;
    value: number;
};

type FormSelectorWithImage = {
    label: string;
    value: number;
    image: any;
    imageType?: string;
};

class LPSensorInformationPart extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            isUpdating: false,
            hasError: false,
            formValue: {
                label: this.props.sensor.label,
                ipcan_module: this.props.sensor.lpSensor.ipCanId,
                placeType: this.props.sensor.lpSensor.sensorPlaceType?.id,
                bus: this.props.sensor.lpSensor.bus,
                device_id: this.props.sensor.lpSensor.deviceId,
            },
            isCompareSceneConfigOpen: false,
            currentSensorPlaceType: {},
        };
    }

    componentDidMount = () => {
        const currentSensorPlaceType = this.props.placeTypes.find(
            placeType => placeType.id === this.props.sensor.lpSensor.sensorPlaceType?.id
        );

        if (currentSensorPlaceType) {
            this.setState({
                currentSensorPlaceType,
            });
        }
    };

    setEditMode = () => {
        this.setState({
            isEditMode: true,
        });
    };

    generateForceType = () => {
        if (this.props.sensor.lpSensor.forceType.forceType === 1) {
            return (
                <Tag color="green" data-tag="allowRowEvents">
                    <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.sensorForce.free" />
                </Tag>
            );
        }

        if (this.props.sensor.lpSensor.forceType.forceType === 2) {
            return (
                <Tag color="red" data-tag="allowRowEvents">
                    <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.sensorForce.occupied" />
                </Tag>
            );
        }

        if (this.props.sensor.lpSensor.forceType.forceType === 3) {
            return (
                <Tag color="orange" data-tag="allowRowEvents">
                    <FormattedMessage
                        data-tag="allowRowEvents"
                        id="ipCanDevices.LPSensors.sensorForce.overstay_occupied"
                    />
                </Tag>
            );
        }

        if (this.props.sensor.lpSensor.forceType.forceType === 4) {
            return (
                <Tag color="violet" data-tag="allowRowEvents">
                    <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.sensorForce.reserved" />
                </Tag>
            );
        }

        if (this.props.sensor.lpSensor.forceType.forceType === 10) {
            return (
                <Tag
                    style={{
                        backgroundColor: `rgb(${this.props.sensor.lpSensor.forceType.color.red}, ${this.props.sensor.lpSensor.forceType.color.green}, ${this.props.sensor.lpSensor.forceType.color.blue})`,
                        color: '#FFF',
                    }}
                    data-tag="allowRowEvents">
                    <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.sensorForce.color" />
                </Tag>
            );
        }
    };

    handleFormChange = formValue => {
        this.setState({
            formValue: {
                label: formValue.label,
                device_id: parseInt(formValue.device_id),
                bus: formValue.bus,
                ipcan_module: formValue.ipcan_module,
                placeType: formValue.placeType,
            },
        });
    };

    generateControllersForm = (): FormSelector[] => {
        let controllers: Array<FormSelector> = [];

        this.props.controllers.forEach(controller =>
            controllers.push({
                label: `${controller.label} (${controller.macStr})`,
                value: controller.id,
            })
        );

        return controllers;
    };

    generateSensorPlaceTypeForm = (): FormSelectorWithImage[] => {
        let sensorPlaceTypes: FormSelectorWithImage[] = [];

        this.props.placeTypes.forEach(placeType => {
            sensorPlaceTypes.push({
                label: placeType.label,
                value: placeType.id,
                image: placeType.placeType?.image,
                imageType: placeType.placeType?.imageType,
            });
        });

        return sensorPlaceTypes;
    };

    generateBusForm = (): FormSelector[] => {
        return [
            {
                label: 'Bus 1',
                value: 0,
            },
            {
                label: 'Bus 2',
                value: 1,
            },
        ];
    };

    cancelEditMode = () => {
        this.setState({
            isEditMode: false,
            formValue: {
                label: this.props.sensor.label,
                ipcan_module: this.props.sensor.lpSensor.ipCanId,
                placeType: this.props.sensor.lpSensor.sensorPlaceType?.id,
                bus: this.props.sensor.lpSensor.bus,
                device_id: this.props.sensor.lpSensor.deviceId,
            },
        });
    };

    validEdition = () => {
        this.setState({
            isUpdating: true,
            hasError: false,
        });

        const { placeType, bus, device_id, ipcan_module } = this.state.formValue;

        let label = this.state.formValue.label;

        if (label === '') {
            label = ' ';
        }

        axiosService
            .getAxios()
            .put(
                '/map-lp-sensors/updateSensor',
                {
                    id: this.props.sensor.mapSensorId,
                    label: label,
                    lpSensor: {
                        id_ipcanmodule: ipcan_module,
                        id_sensorPlaceType: placeType,
                        bus,
                        deviceId: device_id,
                    },
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateSensorResponse => {
                this.setState({
                    isUpdating: false,
                    isEditMode: false,
                });

                this.props.service.send('LPSENSOR_EDIT_CONFIGURATION', { sensor: updateSensorResponse.data });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'map.tcmSensor.edit.information.label.success',
                    })
                );
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    isUpdating: false,
                    hasError: true,
                });
            });
    };

    generateEditPart = () => (
        <Form fluid formValue={this.state.formValue} onChange={this.handleFormChange}>
            <List>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.lpSensor.edit.information.label" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl data-cy="lpSensor-edit-label" name="label" accepter={Input} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* SENSOR PLACE TYPE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.lpSensor.edit.information.sensorPlaceType" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="lpSensor-edit-sensorPlaceType"
                                name="placeType"
                                accepter={SelectPicker}
                                data={this.generateSensorPlaceTypeForm()}
                                cleanable={false}
                                renderMenuItem={(label, item: any) => {
                                    return (
                                        <FlexboxGrid align="middle">
                                            <FlexboxGrid.Item>
                                                <img
                                                    alt={item.label}
                                                    style={{ height: 20 }}
                                                    src={`data:image/${item.imageType};base64, ${item.image}`}
                                                />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy={`editPlace-placeType-value-${item.value}`}>
                                                {label}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    );
                                }}
                                renderValue={(_, item: any) => {
                                    return (
                                        <FlexboxGrid align="middle">
                                            <FlexboxGrid.Item>
                                                <img
                                                    alt={item.label}
                                                    style={{ height: 20 }}
                                                    src={`data:image/${item.imageType};base64, ${item.image}`}
                                                />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="placeType">{item.label}</FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    );
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IPCAN */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.lpSensor.edit.information.ipcanModule" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="lpSensor-edit-ipCan"
                                name="ipcan_module"
                                cleanable={false}
                                accepter={SelectPicker}
                                data={this.generateControllersForm()}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.lpSensor.edit.information.bus" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="lpSensor-edit-bus"
                                name="bus"
                                accepter={SelectPicker}
                                data={this.generateBusForm()}
                                cleanable={false}
                                searchable={false}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* DEVICE ID */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.deviceId" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="lpSensor-edit-deviceId"
                                name="device_id"
                                accepter={Input}
                                type="number"
                                min={1}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="lpSensor-edit-cancel"
                                    color="red"
                                    onClick={this.cancelEditMode}
                                    disabled={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    data-cy="lpSensor-edit-valid"
                                    color="green"
                                    onClick={this.validEdition}
                                    loading={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );

    generateViewPart = () => {
        const sensorPlaceType = this.props.placeTypes.find(spt => spt.id === this.props.sensor.sensorPlaceType);
        let color = 'black';
        switch (this.props.sensor.detectionState) {
            case 1:
                color = 'green';
                break;
            case 2:
                color = 'red';
                break;
            case 3:
                color = 'orange';
                break;
            case 4:
                color = 'purple';
                break;
            default:
                color = 'black';
        }

        return (
            <List size='sm'>
                {/* LABEL */}
                {!this.props.showRealTimeGraph && (
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.lpSensor.edit.information.label" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="lpSensor-information-label">
                                {this.props.sensor.label}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}
                {/* SENSOR PLACE TYPE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.lpSensor.edit.information.sensorPlaceType" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpSensor-information-sensorPlaceType">
                            <img
                                alt={sensorPlaceType?.label}
                                style={{ height: 20 }}
                                className="margin-right-5"
                                src={`data:image/${sensorPlaceType?.placeType?.imageType};base64, ${sensorPlaceType?.placeType?.image}`}
                            />
                            {sensorPlaceType?.label}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* ONLINE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.lpSensor.edit.information.online" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpSensor-information-online">
                            <FontAwesomeIcon
                                size="lg"
                                icon={faPlug}
                                color={this.props.sensor.online ? 'green' : 'red'}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* DETECTION STATE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.lpSensor.edit.information.detectionState" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpSensor-information-detectionState">
                            <FontAwesomeIcon icon={faCircle} color={color} /> ({this.props.sensor.detectionStateTime})
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IP CAN MODULE */}
                {!this.props.showRealTimeGraph && (
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.lpSensor.edit.information.ipcanModule" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="lpSensor-information-ipCanModule">{`${this.props.currentIpCan?.label} (${this.props.currentIpCan?.macStr})`}</FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}
                {/* BUS */}
                {!this.props.showRealTimeGraph && (
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.lpSensor.edit.information.bus" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="lpSensor-information-bus">
                                {this.props.sensor.bus + 1}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}
                {/* DEVICE ID */}
                {!this.props.showRealTimeGraph && (
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.lpSensor.edit.information.deviceId" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="lpSensor-information-deviceId">
                                {this.props.sensor.deviceId}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}
                {/* MODEL NAME & VERSION */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.lpSensor.edit.information.modelNameAndVersion" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpSensor-information-modelNameAndVersion">{`${this.props.sensor.lpSensor.informations.modelName} - ${this.props.sensor.lpSensor.informations.softwareVersion}`}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IS FORCE */}
                {!this.props.showRealTimeGraph && (
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.lpSensor.edit.information.isForce" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="lpSensor-information-isForce">
                                <FontAwesomeIcon
                                    size="lg"
                                    icon={this.props.sensor.isForce ? faCheck : faTimes}
                                    color={this.props.sensor.isForce ? 'green' : 'red'}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}
                {this.props.sensor.isForce && (
                    <Fragment>
                        {/* FORCE TYPE */}
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                    <FormattedMessage id="map.lpSensor.edit.information.forceType" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item data-cy="lpSensor-information-forceType">
                                    {this.generateForceType()}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {/* START FORCE TIME */}
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                    <FormattedMessage id="map.lpSensor.edit.information.startForceDate" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item data-cy="lpSensor-information-startForceDate">
                                    {moment(this.props.sensor.lpSensor.startForceTime).format('DD/MM/YYYY HH:mm')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {/* END FORCE TIME */}
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                    <FormattedMessage id="map.lpSensor.edit.information.endForceDate" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item data-cy="lpSensor-information-endForceDate">
                                    {moment(this.props.sensor.lpSensor.endForceTime).format('DD/MM/YYYY HH:mm')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                    </Fragment>
                )}
            </List>
        );
    };

    toggleCompareSceneConfig = () => {
        this.setState({
            isCompareSceneConfigOpen: !this.state.isCompareSceneConfigOpen,
        });
    };

    render = () => (
        <>
            {this.state.isCompareSceneConfigOpen && (
                <LPSensorTypeComparissionModal
                    show={this.state.isCompareSceneConfigOpen}
                    onHide={this.toggleCompareSceneConfig}
                    sensorId={this.props.sensor.lpSensor.id}
                    currentSensorPlaceType={this.state.currentSensorPlaceType}
                />
            )}
            <Panel
                className="panel-small"
                bordered
                bodyFill
                shaded
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({ id: 'map.lpSensor.edit.information' })}
                        buttons={this.props.showRealTimeGraph ? [] : [
                            <Button
                                data-cy="ipCanDevices-lpSensor-compareMode-informations"
                                color="orange"
                                onClick={this.toggleCompareSceneConfig}
                                size="sm"
                                disabled={!this.props.sensor.lpSensor.online}>
                                <FontAwesomeIcon icon={faCheckDouble} />
                            </Button>,
                            <Button
                                data-cy="lpSensor-edit-information"
                                color="blue"
                                size="sm"
                                onClick={this.setEditMode}
                                disabled={this.state.isEditMode}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>,
                        ]}
                    />
                }>
                {this.state.hasError && (
                    <Message
                        type="error"
                        description={this.props.intl.formatMessage({ id: 'map.lpSensor.edit.information.error' })}
                    />
                )}
                {this.state.isEditMode && !this.props.showRealTimeGraph ? this.generateEditPart() : this.generateViewPart()}
            </Panel>
        </>
    );
}

export default injectIntl(LPSensorInformationPart);
