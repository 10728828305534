let token: string | null = null;
let user: string | null = null;

export function authHeader() {
    if (!token) {
        token = localStorage.getItem('token');
    }

    if (!user) {
        user = JSON.parse(localStorage.getItem('user') || '{}');
    }

    if (user && token) {
        return { Authorization: 'Bearer ' + token };
    } else {
        return {};
    }
}

export function setAuthHeader(_token: string | null, _user: string | null) {
    if (_token) {
        localStorage.setItem('token', _token);
    } else {
        localStorage.removeItem('token');
    }

    if (_user) {
        localStorage.setItem('user', _user);
    } else {
        localStorage.removeItem('user');
    }

    token = null;
    user = null;
}
