export class HyperParametersConfig {
    private _historySize?: number;
    private _priorCoeffKx?: number;
    private _priorCoeffKy?: number;
    private _priorCoeffOx?: number;
    private _priorCoeffOy?: number;

    constructor(config: HyperParametersConfigInterface) {
        this._historySize = config.history_size;
        this._priorCoeffKx = config.prior_coeff_kx;
        this._priorCoeffKy = config.prior_coeff_ky;
        this._priorCoeffOx = config.prior_coeff_ox;
        this._priorCoeffOy = config.prior_coeff_oy;
    }

    public getHistorySize(): number | undefined {
        return this._historySize;
    }

    public getPriorCoeffKx(): number | undefined {
        return this._priorCoeffKx;
    }

    public getPriorCoeffKy(): number | undefined {
        return this._priorCoeffKy;
    }

    public getPriorCoeffOx(): number | undefined {
        return this._priorCoeffOx;
    }

    public getPriorCoeffOy(): number | undefined {
        return this._priorCoeffOy;
    }
}

export interface HyperParametersConfigInterface {
    history_size?: number;
    prior_coeff_kx?: number;
    prior_coeff_ky?: number;
    prior_coeff_ox?: number;
    prior_coeff_oy?: number;
}
