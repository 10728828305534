import React, { Fragment } from 'react';
import { connect } from 'react-redux';

type Props = {
    auth: Record<string, any>;
    authorizedRoles: Array<Record<string, any>>;
    children: Record<string, any>;
};

type State = {
    displayable: boolean;
    authorizedRoles: Array<Record<string, any>>;
};

class SecuredFragment extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            displayable:
                this.props.auth.user.roles.find(data => {
                    return data.name === 'Admin';
                }) || false,
            authorizedRoles: this.props.authorizedRoles || [],
        };
    }

    componentDidMount() {
        if (!this.state.displayable && this.state.authorizedRoles.length > 0) {
            const currentRoles = this.props.auth.user.roles;

            for (let currentIndex in currentRoles) {
                let cr = currentRoles[currentIndex];
                for (let authorizedIndex in this.props.authorizedRoles) {
                    let ar = this.props.authorizedRoles[authorizedIndex];
                    if (ar && cr.name === ar.name && cr.section === ar.section) {
                        this.setState({
                            displayable: true,
                        });
                    }
                }
            }
        }
    }

    render() {
        if (this.state.displayable) {
            return <Fragment>{this.props.children}</Fragment>;
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    const { auth } = state;

    return { auth };
}

export default connect(mapStateToProps)(SecuredFragment);
