import handleError from '../../handlers/error.handler';
import { sensorPlaceTypeHandler } from '../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { updateSensorPlaceTypeHandler } from '../../handlers/sensorPlaceType/updateSensorPlaceType.handler';
import { authHeader } from '../helpers';
import { axiosService } from './axios.services';

export const sensorPlaceTypeService = {
    getAll,
    getById,
    create,
    update,
    remove: remove,
};

function getAll() {
    return axiosService
        .getAxios()
        .get('/sensor-place-type', { headers: authHeader() })
        .then(response => response.data.map(sensorPlaceType => sensorPlaceTypeHandler(sensorPlaceType)))
        .then(sensorPlaceTypes => {
            return sensorPlaceTypes;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function getById(id) {
    return axiosService
        .getAxios()
        .get(`/sensor-place-type/${id}`, { headers: authHeader() })
        .then(response => {
            return sensorPlaceTypeHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function create(label, description, placeTypeId) {
    const parameters = { headers: authHeader() };
    const data = {
        label,
        description: description ? description : ' ',
        placeTypeId,
    };
    return axiosService
        .getAxios()
        .post('/sensor-place-type', data, parameters)
        .then(response => {
            let sensorPlaceType = response.data;
            return axiosService
                .getAxios()
                .get(`/place-type/${placeTypeId}`, parameters)
                .then(response => {
                    sensorPlaceType.placeType = response.data;
                    return sensorPlaceTypeHandler(sensorPlaceType);
                })
                .catch(err => {
                    handleError(err);
                    return Promise.reject(err);
                });
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function update(id, label, description, placeTypeId) {
    const parameters = { headers: authHeader() };
    const data = { id, label, description, placeTypeId };

    return axiosService
        .getAxios()
        .put('/sensor-place-type', data, parameters)
        .then(response => {
            let sensorPlaceType = response.data;
            return axiosService
                .getAxios()
                .get(`/place-type/${placeTypeId}`, parameters)
                .then(response => {
                    sensorPlaceType.placeType = response.data;
                    return updateSensorPlaceTypeHandler(sensorPlaceType);
                })
                .catch(err => {
                    handleError(err);
                    return Promise.reject(err);
                });
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function remove(id) {
    const data = {
        headers: authHeader(),
        data: { id: id },
    };
    return axiosService
        .getAxios()
        .delete('/sensor-place-type', data)
        .then(response => {
            return response.data.id;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}
