import { faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, Col, FlexboxGrid, Form, FormControl, InputNumber, List, Panel } from 'rsuite';
import TCMSensor from '../../../../handlers/ipCanDevices/TCMSensor';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';

type Props = {
    sensor: TCMSensor;
    isCheckingDatabase: boolean;
    databaseData: Record<string, any> | undefined;
} & WrappedComponentProps;

type State = {
    editMode: boolean;
    formValue: Record<string, any>;
    isUpdating: boolean;
};
class TimeCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const time = props.sensor.time.fetchTime;

        this.state = {
            editMode: false,
            formValue: {
                maxFree: time.maxFree,
                maxOccupied: time.maxOccupied,
                provOccupied: time.provOccupied,
            },
            isUpdating: false
        };

        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.cancel = this.cancel.bind(this);
        this.valid = this.valid.bind(this);
    }

    cancel() {
        const time = this.props.sensor.time.fetchTime;

        this.setState({
            formValue: {
                maxFree: time.maxFree,
                maxOccupied: time.maxOccupied,
                provOccupied: time.provOccupied,
            },
            editMode: false,
        });
    }

    valid() {
        const maxFree = parseInt(this.state.formValue.maxFree);
        const maxOccupied = parseInt(this.state.formValue.maxOccupied);
        const provOccupied = parseInt(this.state.formValue.provOccupied);

        this.setState({
            isUpdating: true,
        });

        this.props.sensor.time.setTime(this.props.sensor.id, maxFree, maxOccupied, provOccupied)
            .then(() => {
                this.setState({
                    editMode: false
                });
                Alert.success(this.props.intl.formatMessage({ id: 'tcmSensor.time.update.success' }))
            })
            .catch((err) => {
                Alert.error(this.props.intl.formatMessage({ id: 'tcmSensor.color.update.error' }, { error: err }))
            }).finally(() => {
                this.setState({
                    isUpdating: false,
                })
            });
    }

    toggleEditMode() {
        this.setState({
            editMode: !this.state.editMode,
        });
    }

    render() {
        let maxFreeClass;
        let maxOccupiedClass;
        let provOccupiedClass;

        const time = this.props.sensor.time;

        if (this.props.isCheckingDatabase) {
            maxFreeClass =
                this.props.databaseData?.time.maxFree === time.maxFree ? 'list-green' : 'list-red';
            maxOccupiedClass =
                this.props.databaseData?.time.maxOccupied === time.maxOccupied ? 'list-green' : 'list-red';
            provOccupiedClass =
                this.props.databaseData?.time.provOccupied === time.provOccupied ? 'list-green' : 'list-red';
        }

        return (
            <Fragment>
                <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.UPDATE_TIME_CONFIG]}>
                    <Panel
                        className={'panel-small'}
                        shaded
                        data-cy="ipcan-elements-sensor-drawer-time"
                        bordered
                        header={
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'ipCanDevices.sensor.time' })}
                                buttons={
                                    this.props.isCheckingDatabase
                                        ? []
                                        : [
                                                <Button
                                                    data-cy="sensor-time-edit"
                                                    key="time-edit"
                                                    color="blue"
                                                    size="sm"
                                                    onClick={this.toggleEditMode}
                                                    disabled={this.state.editMode}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                    <FormattedMessage id="ipCanDevices.sensor.time.edit" />
                                                </Button>,
                                            ]
                                }
                            />
                        }
                        bodyFill>
                        <Form onChange={formValue => this.setState({ formValue })} formValue={this.state.formValue}>
                            <List hover data-cy="ipcan-elements-sensor-drawer-time">
                                <List.Item className={`panel-list ${maxFreeClass}`}>
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-time-free" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                            <FormattedMessage id="ipCanDevices.sensor.time.maxFree" />
                                        </FlexboxGrid.Item>
                                        {this.state.editMode ? (
                                            <FlexboxGrid.Item>
                                                <FormControl
                                                    data-cy="ipCanDevices-sensor-time-maxFree"
                                                    accepter={InputNumber}
                                                    style={{ width: 224 }}
                                                    size="sm"
                                                    name="maxFree"
                                                    disabled={this.state.isUpdating}
                                                />
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                <Fragment>
                                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                        {/* CHECKING DATABASE */}
                                                        {this.props.isCheckingDatabase && (
                                                            <Fragment>
                                                                {this.props.databaseData?.time.maxFree === 0 ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faTimesCircle}
                                                                        color="red"
                                                                    />
                                                                ) : (
                                                                    `${this.props.databaseData?.time.maxFree} ms`
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </FlexboxGrid.Item>

                                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-time-free-value">
                                                        {time.maxFree === 0 ? (
                                                            <FontAwesomeIcon icon={faTimesCircle} color="red" />
                                                        ) : (
                                                            `${time.maxFree} ms`
                                                        )}
                                                    </FlexboxGrid.Item>
                                                </Fragment>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>

                                <List.Item className={`panel-list ${maxOccupiedClass}`}>
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-time-occupied" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                            <FormattedMessage id="ipCanDevices.sensor.time.maxOccupied" />
                                        </FlexboxGrid.Item>
                                        {this.state.editMode ? (
                                            <FlexboxGrid.Item>
                                                <FormControl
                                                    data-cy="ipCanDevices-sensor-time-maxOccupied"
                                                    accepter={InputNumber}
                                                    style={{ width: 224 }}
                                                    size="sm"
                                                    name="maxOccupied"
                                                    disabled={this.state.isUpdating}
                                                />
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                <Fragment>
                                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                        {/* CHECKING DATABASE */}
                                                        {this.props.isCheckingDatabase && (
                                                            <Fragment>
                                                                {this.props.databaseData?.time.maxOccupied === 0 ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faTimesCircle}
                                                                        color="red"
                                                                    />
                                                                ) : (
                                                                    `${this.props.databaseData?.time.maxOccupied} ms`
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </FlexboxGrid.Item>

                                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-time-occupied-value">
                                                        {time.maxOccupied === 0 ? (
                                                            <FontAwesomeIcon icon={faTimesCircle} color="red" />
                                                        ) : (
                                                            `${time.maxOccupied} ms`
                                                        )}
                                                    </FlexboxGrid.Item>
                                                </Fragment>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>

                                <List.Item className={`panel-list ${provOccupiedClass}`}>
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-time-occupied-provOccupied" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                            <FormattedMessage id="ipCanDevices.sensor.time.provOccupied" />
                                        </FlexboxGrid.Item>
                                        {this.state.editMode ? (
                                            <FlexboxGrid.Item>
                                                <FormControl
                                                    data-cy="ipCanDevices-sensor-time-provOccupied"
                                                    accepter={InputNumber}
                                                    style={{ width: 224 }}
                                                    size="sm"
                                                    name="provOccupied"
                                                    disabled={this.state.isUpdating}
                                                />
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                <Fragment>
                                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                        {/* CHECKING DATABASE */}
                                                        {this.props.isCheckingDatabase && (
                                                            <Fragment>
                                                                {this.props.databaseData?.time.provOccupied ===
                                                                    0 ? (
                                                                            <FontAwesomeIcon
                                                                                icon={faTimesCircle}
                                                                                color="red"
                                                                            />
                                                                        ) : (
                                                                            `${this.props.databaseData?.time.provOccupied} ms`
                                                                        )}
                                                            </Fragment>
                                                        )}
                                                    </FlexboxGrid.Item>

                                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-time-occupied-provOccupied-value">
                                                        {time.provOccupied === 0 ? (
                                                            <FontAwesomeIcon icon={faTimesCircle} color="red" />
                                                        ) : (
                                                            `${time.provOccupied} ms`
                                                        )}
                                                    </FlexboxGrid.Item>
                                                </Fragment>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>

                                {this.state.editMode && (
                                    <List.Item className={'panel-list'}>
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                                <Fragment>
                                                    <Button
                                                        data-cy="ipCanDevices-display-edit-valid"
                                                        color="green"
                                                        onClick={this.valid}
                                                        loading={this.state.isUpdating}>
                                                        <FormattedMessage id="ipCanDevices.sensor.time.edit.valid" />
                                                    </Button>
                                                    <Button
                                                        data-cy="ipCanDevices-display-edit-cancel"
                                                        appearance="link"
                                                        onClick={this.cancel}
                                                        disabled={this.state.isUpdating}>
                                                        <FormattedMessage id="ipCanDevices.sensor.time.edit.cancel" />
                                                    </Button>
                                                </Fragment>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                )}
                            </List>
                        </Form>
                    </Panel>
                </SecuredFragment>
            </Fragment>
        );
    }
}

export default injectIntl(TimeCard);
