import { FormattedMessage } from 'react-intl';
import Task from '../../../../../handlers/Event/Task';
import { LPDisplayPart } from './Parts/LPDisplayPart';

type Props = {
    task: Task;
};

export const LPDisplayUnforce = (props: Props) => {
    return (
        <>
            <FormattedMessage id="tasks.disableForceLPMatrixDisplayV2" />

            <LPDisplayPart lpDisplay={props.task.getLpMatrixDisplaysV2()} />
        </>
    );
};
