import { appConstants } from '../constants';

export const appActions = {
    changeLanguage,
};

function changeLanguage(language) {
    return dispatch => {
        dispatch(request());

        function request() {
            return {
                type: appConstants.CHANGE_LANGUAGE_REQUEST,
                language,
            };
        }
    };
}
