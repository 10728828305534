import moment from 'moment';
import { updateParkingHandler } from './parking/updateParkingHandler';

export function levelHandler(level) {
    return {
        calibrationCm: level.calibrationCm,
        calibrationPlace: level.calibrationPlace,
        createdAt: moment(level.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: level.createdBy,
        updatedBy: level.updatedBy,
        updatedAt: moment(level.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        defaultZoom: level.defaultZoom,
        description: level.description,
        id: level.id,
        image: generateImage(level.image),
        imageType: checkType(level.imageType),
        parking: level.parking ? updateParkingHandler(level.parking) : undefined,
        name: level.name,
        order: level.order,
        positionZoom: level.positionZoom,
        isUpToDate: level.isUpToDate,
    };
}

function generateImage(image) {
    if (image) {
        let buffer = Buffer.from(image.data, 'base64');
        return buffer.toString('base64');
    } else {
        return null;
    }
}

function checkType(type) {
    if (type === 'svg') {
        return 'svg+xml';
    } else {
        return type;
    }
}
