import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Modal, Uploader } from 'rsuite';
import { FileType } from 'rsuite/lib/Uploader';
import { PixelArtPictoImport } from '../../../../handlers/pixelArt/pixelArtWS';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    handlePictoUpload: (receivedPicto: PixelArtPictoImport) => void;
};

export const ImportPictoModal = (props: Props) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [file, setFile] = React.useState<FileType[]>([]);

    const onChangeHandler = fileList => {
        setFile(fileList);
    };

    const onClickValid = async () => {
        setIsLoading(true);

        const data = await file[0].blobFile?.text();

        if (data) {
            props.handlePictoUpload(JSON.parse(data));
        }

        setIsLoading(false);

        handleCloseModal();
    };

    const handleCloseModal = () => {
        setFile([]);

        props.onHide();
    };

    return (
        <Modal show={props.isOpen} onHide={() => handleCloseModal()}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="editPicto.importPicto.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Uploader
                    multiple={false}
                    autoUpload={false}
                    onChange={onChangeHandler}
                    disabled={file.length >= 1}
                    accept="application/json"
                />
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={() => handleCloseModal()}
                        data-cy="import-picto-cancel"
                        color="red"
                        disabled={isLoading}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={() => onClickValid()}
                        type="submit"
                        data-cy="import-picto-valid"
                        appearance="primary"
                        loading={isLoading}
                        disabled={file.length !== 1}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
