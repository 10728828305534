import forceElement from './Desc/forceElement';
import offline from './Desc/offline';
import online from './Desc/online';
import readRealDisplayConfig from './Desc/readRealDisplayConfig';
import updateConfig from './Desc/updateConfig';
import updateCounters from './Desc/updateCounters';
import updateCountersAssign from './Desc/updateCountersAssign';
import updateMaintenanceState from './Desc/updateMaintenanceState';
import updateSubstractCounters from './Desc/updateSubstractCounters';

// wait for online, total and db as data
// wait for controler online or not

export default function TCMDisplayDescCell(props) {
    switch (props) {
        case 'online':
            return online();
        case 'offline':
            return offline();
        case 'update config':
            return updateConfig();
        case 'update counters':
            return updateCounters();
        case 'force display':
            return forceElement();
        case 'update counters assign':
            return updateCountersAssign();
        case 'update substract counters':
            return updateSubstractCounters();
        case 'read real sensor config':
            return readRealDisplayConfig();
        case 'update maintenance state':
            return updateMaintenanceState();
        default:
            return props;
    }
}
