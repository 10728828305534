import { faSquareFull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function ColorCell(scenes: any[], state: number) {
    const firstScene = scenes.filter(scene => scene.state === state)[0];

    if (firstScene) return <FontAwesomeIcon icon={faSquareFull} color={firstScene.color} size="2x" />;
    return null;
}
