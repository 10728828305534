import { faArrowUp, faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Popup } from 'react-leaflet';
import { Button, FlexboxGrid, List, Loader, Tag } from 'rsuite';
import { DrawerInformationInterface } from '../..';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import ForceDisplayModal from '../../../IpCanElementsPage/Drawer/Display/Modal/ForceDisplayModal';
import TCMDisplayHistoryModal from '../../../IpCanElementsPage/Drawer/Display/Modal/TCMDisplayHistoryModal';
import { MapTCMDeviceStateInformationLogo } from './Drawer/DeviceStateInformation/MapTCMDeviceStateInformationLogo';
import TCMDisplayDrawer from './Drawer/TCMDisplayDrawer';

// type States = {
//     forceModalOpen: boolean;
//     editModalOpen: boolean;
//     countersModalOpen: boolean;
//     editConfigTypeModalOpen: boolean;
//     configModalOpen: boolean;
// };

type Props = {
    controllers: IpCan[];
    display: Record<string, any>;
    hover: boolean;
    reloadDisplays: Function;
    editMode: boolean;
    types: Array<Record<string, any>>;
    currentType: number;
    arrowRotation: number;
    placeTypes: Array<Record<string, any>>;
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
    service: any;
} & WrappedComponentProps;

type State = {
    forceModalOpen: boolean;
    editModalOpen: boolean;
    countersModalOpen: boolean;
    editConfigTypeModalOpen: boolean;
    configModalOpen: boolean;
    historyModalOpen: boolean;
};

class TCMDisplayPopup extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            forceModalOpen: false,
            editModalOpen: false,
            countersModalOpen: false,
            editConfigTypeModalOpen: false,
            configModalOpen: false,
            historyModalOpen: false,
        };

        this.openForceModal = this.openForceModal.bind(this);
        this.closeForceModal = this.closeForceModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.openConfigModal = this.openConfigModal.bind(this);
        this.closeConfigModal = this.closeConfigModal.bind(this);
        this.openEditConfigTypeModal = this.openEditConfigTypeModal.bind(this);
        this.closeEditConfigTypeModal = this.closeEditConfigTypeModal.bind(this);
        this.openCountersModal = this.openCountersModal.bind(this);
        this.closeCountersModal = this.closeCountersModal.bind(this);
    }

    openForceModal() {
        this.setState({
            forceModalOpen: true,
        });
    }

    closeForceModal() {
        this.setState({
            forceModalOpen: false,
        });

        this.props.reloadDisplays('displays');
    }

    openEditModal() {
        this.setState({
            editModalOpen: true,
        });
    }

    closeEditModal() {
        this.setState({
            editModalOpen: false,
        });

        this.props.reloadDisplays('displays');
    }

    openConfigModal() {
        this.setState({
            configModalOpen: true,
        });
    }

    closeConfigModal() {
        this.setState({
            configModalOpen: false,
        });

        this.props.reloadDisplays('displays');
    }

    openEditConfigTypeModal() {
        this.setState({
            editConfigTypeModalOpen: true,
        });
    }

    closeEditConfigTypeModal() {
        this.setState({
            editConfigTypeModalOpen: false,
        });

        this.props.reloadDisplays('displays');
    }

    openCountersModal() {
        this.setState({
            countersModalOpen: true,
        });
    }

    closeCountersModal() {
        this.setState({
            countersModalOpen: false,
        });

        this.props.reloadDisplays('displays');
    }

    openHistoryModal = () => {
        this.setState({
            historyModalOpen: true,
        });
    };

    closeHistoryModal = () => {
        this.setState({
            historyModalOpen: false,
        });
    };

    render() {
        const arrowStyle = {
            transform: `rotate(${this.props.arrowRotation}deg)`,
        };

        const displayArrowLeft =
            this.props.display.tcmDisplay.arrow !== 0 &&
            (this.props.arrowRotation >= 180 || this.props.arrowRotation === 0);

        const displayArrowRight =
            this.props.display.tcmDisplay.arrow !== 0 &&
            this.props.arrowRotation < 180 &&
            this.props.arrowRotation !== 0;

        const displayValue = this.props.display.tcmDisplay.displayValue;

        const shouldDisplayCountersPart =
            this.props.display.tcmDisplay.substractionCounters.length > 0 ||
            this.props.display.tcmDisplay.counters.length > 0;

        const shouldShowDrawer =
            this.props.editMode &&
            this.props.drawerInformation.isOpen &&
            this.props.drawerInformation.type === 'tcm-display' &&
            this.props.drawerInformation.id === this.props.display.id;

        const currentDisplay = this.props.display.tcmDisplay;

        return (
            <Fragment>
                {shouldShowDrawer && (
                    <TCMDisplayDrawer
                        show={this.props.drawerInformation.isOpen}
                        onHide={() => this.props.closeDrawer()}
                        display={this.props.display}
                        controllers={this.props.controllers}
                        service={this.props.service}
                        placeTypes={this.props.placeTypes}
                    />
                )}
                <Popup autoPan={false} autoClose={false}>
                    <h5>
                        <SecuredFragment authorizedRoles={[]}>
                            <FontAwesomeIcon
                                className="margin-right-10"
                                icon={faPlug}
                                color={currentDisplay.online ? 'green' : 'red'}
                            />
                        </SecuredFragment>
                        <span className="margin-right-5">{this.props.display.label}</span>
                        <SecuredFragment authorizedRoles={[]}>
                            <MapTCMDeviceStateInformationLogo maintenanceState={currentDisplay.maintenanceState} />
                        </SecuredFragment>
                    </h5>
                    <p>
                        {currentDisplay.ipcanmodule.label} -- {parseInt(currentDisplay.bus) + 1} --{' '}
                        {currentDisplay.deviceId}
                    </p>

                    <SecuredFragment authorizedRoles={[]}>
                        <p>
                            <FormattedMessage id="map.tcmDisplay.lastOnlineStateDate" /> :{' '}
                            {moment(currentDisplay.lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss')}
                        </p>
                    </SecuredFragment>

                    <hr />
                    {currentDisplay.eventForceType === 'tcm-display_force' && (
                        <p>
                            <h5 className="margin-bottom-10">
                                <FormattedMessage id="map.displays.popup.eventForce" />
                            </h5>{' '}
                            :{' '}
                            <h6>
                                {currentDisplay.eventForceText === 0 && <FormattedMessage id="display.force.full" />}

                                {currentDisplay.eventForceText === 65535 && (
                                    <FormattedMessage id="display.force.close" />
                                )}

                                {currentDisplay.eventForceText === 10000 && (
                                    <FormattedMessage id="display.force.free" />
                                )}
                            </h6>
                        </p>
                    )}

                    <h6 className="margin-bottom-10">
                        <FormattedMessage id="map.displays.popup.actualDisplay" />
                    </h6>

                    <FlexboxGrid align="middle" justify="center">
                        <FlexboxGrid.Item>
                            {this.props.types.map((type, index) => {
                                if (currentDisplay.displayValue) {
                                    if (currentDisplay.isForce) {
                                        return (
                                            <div className="tcm-display-popup" key={`display-popup-${index}`}>
                                                <FlexboxGrid align="middle" justify="space-between">
                                                    <FlexboxGrid.Item className="icon">
                                                        <FlexboxGrid>
                                                            {displayArrowLeft && (
                                                                <FlexboxGrid.Item className="margin-right-5">
                                                                    <FontAwesomeIcon
                                                                        icon={faArrowUp}
                                                                        style={arrowStyle}
                                                                    />
                                                                </FlexboxGrid.Item>
                                                            )}
                                                            <FlexboxGrid.Item className="icon">
                                                                {this.props.types[index].image && (
                                                                    <Fragment>
                                                                        <img
                                                                            style={{
                                                                                backgroundColor: '#ffb600',
                                                                                width: 16,
                                                                                height: 16,
                                                                            }}
                                                                            src={this.props.types[index].image}
                                                                            alt=""
                                                                            className="margin-right-5"
                                                                        />
                                                                    </Fragment>
                                                                )}
                                                            </FlexboxGrid.Item>
                                                        </FlexboxGrid>
                                                    </FlexboxGrid.Item>
                                                    <FlexboxGrid.Item>
                                                        {currentDisplay.forceType.forceType < 3 && (
                                                            <Fragment>
                                                                <span>
                                                                    {displayValue[type.name].free >= 0
                                                                        ? displayValue[type.name].free
                                                                        : 0}
                                                                </span>
                                                                <span className="text-light-red">
                                                                    {' '}
                                                                    - {displayValue[type.name].occupied}
                                                                </span>
                                                            </Fragment>
                                                        )}
                                                        {currentDisplay.forceType.forceType === 3 && (
                                                            <FormattedMessage id="display.force.full" />
                                                        )}
                                                        {currentDisplay.forceType.forceType === 4 && (
                                                            <FormattedMessage id="display.force.close" />
                                                        )}
                                                        {currentDisplay.forceType.forceType === 5 && (
                                                            <FormattedMessage id="display.force.free" />
                                                        )}
                                                    </FlexboxGrid.Item>
                                                    {displayArrowRight && (
                                                        <FlexboxGrid.Item>
                                                            <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                                                        </FlexboxGrid.Item>
                                                    )}
                                                </FlexboxGrid>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="tcm-display-popup" key={`display-popup-${index}`}>
                                                <FlexboxGrid align="middle" justify="space-between">
                                                    <FlexboxGrid>
                                                        {displayArrowLeft && (
                                                            <FlexboxGrid.Item className="margin-right-5">
                                                                <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                                                            </FlexboxGrid.Item>
                                                        )}
                                                        <FlexboxGrid.Item className="icon">
                                                            {this.props.types[index].image && (
                                                                <Fragment>
                                                                    <img
                                                                        style={{
                                                                            backgroundColor: '#ffb600',
                                                                            width: 16,
                                                                            height: 16,
                                                                        }}
                                                                        src={this.props.types[index].image}
                                                                        alt=""
                                                                        className="margin-right-5"
                                                                    />
                                                                </Fragment>
                                                            )}
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid>
                                                    <FlexboxGrid.Item>
                                                        <span>
                                                            {currentDisplay.displayValue[type.name].free >= 0
                                                                ? currentDisplay.displayValue[type.name].free
                                                                : 0}
                                                        </span>
                                                        <span className="text-light-red">
                                                            {' '}
                                                            - {currentDisplay.displayValue[type.name].occupied}
                                                        </span>
                                                    </FlexboxGrid.Item>
                                                    {displayArrowRight && (
                                                        <FlexboxGrid.Item>
                                                            <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                                                        </FlexboxGrid.Item>
                                                    )}
                                                </FlexboxGrid>
                                            </div>
                                        );
                                    }
                                } else {
                                    return <Loader key={`display-popup-${index}`} />;
                                }
                            })}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    {currentDisplay.isForce && (
                        <Fragment>
                            <hr />
                            <h6>
                                <FormattedMessage id="map.displays.popup.forceModes" />
                            </h6>

                            <p>
                                <FormattedMessage id="map.displays.popup.startForceDate" />
                                <span className="margin-left-5">
                                    {moment(currentDisplay.startForceTime).format('DD/MM/YYYY HH:mm:ss')}
                                </span>
                                <br />
                                <FormattedMessage id="map.displays.popup.endForceDate" />
                                <span className="margin-left-5">
                                    {moment(currentDisplay.endForceTime).format('DD/MM/YYYY HH:mm:ss')}
                                </span>
                            </p>

                            {currentDisplay.forceType.forceType < 3 && (
                                <p className="underline">
                                    Mode de forçage :{' '}
                                    {currentDisplay.forceType.forceType === 1 ? (
                                        <FormattedMessage id="display.force.value" />
                                    ) : (
                                        <FormattedMessage id="display.force.relative" />
                                    )}
                                </p>
                            )}

                            <List bordered size="sm">
                                {this.props.types.map((type, index) => {
                                    return (
                                        <List.Item key={`type-${type.name}`} index={index}>
                                            <FlexboxGrid align="middle" justify="space-between">
                                                <FlexboxGrid.Item className="bold">
                                                    <FormattedMessage
                                                        id={`ipCanDevices.display.configType.${type.name}`}
                                                    />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item>
                                                    {currentDisplay.forceType.forceType < 3 &&
                                                        currentDisplay.forceType[type.name]}

                                                    {currentDisplay.forceType.forceType === 3 && (
                                                        <FormattedMessage id="display.force.full" />
                                                    )}
                                                    {currentDisplay.forceType.forceType === 4 && (
                                                        <FormattedMessage id="display.force.close" />
                                                    )}
                                                    {currentDisplay.forceType.forceType === 5 && (
                                                        <FormattedMessage id="display.force.free" />
                                                    )}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        </Fragment>
                    )}

                    {shouldDisplayCountersPart && (
                        <Fragment>
                            <hr />
                            <h6>
                                <FormattedMessage id="map.displays.popup.counters" />
                            </h6>

                            <List bordered size="sm" style={{ width: '250px' }}>
                                <List.Item
                                    style={{
                                        backgroundColor: 'rgba(0, 255, 0, 0.2)',
                                        paddingTop: '0',
                                        paddingBottom: '0',
                                    }}>
                                    <FlexboxGrid align="middle" justify="center">
                                        {currentDisplay.counters.map(counter => (
                                            <FlexboxGrid.Item key={`counter-${this.props.display.id}-${counter.id}`}>
                                                <Tag color="green" className="margin-bottom-5 margin-top-5">
                                                    {counter.label}
                                                </Tag>
                                            </FlexboxGrid.Item>
                                        ))}
                                    </FlexboxGrid>
                                </List.Item>
                                <List.Item
                                    style={{
                                        backgroundColor: 'rgba(255, 0, 0, 0.2)',
                                        paddingTop: '0',
                                        paddingBottom: '0',
                                    }}>
                                    <FlexboxGrid align="middle" justify="center">
                                        {currentDisplay.substractionCounters.map(counter => (
                                            <FlexboxGrid.Item
                                                key={`sub-counter-${this.props.display.id}-${counter.id}`}>
                                                <Tag color="red" className="margin-bottom-5 margin-top-5">
                                                    {counter.label}
                                                </Tag>
                                            </FlexboxGrid.Item>
                                        ))}
                                    </FlexboxGrid>
                                </List.Item>
                            </List>
                        </Fragment>
                    )}

                    {!this.props.hover && (
                        <Fragment>
                            <hr />

                            <SecuredFragment authorizedRoles={[]}>
                                <Button appearance="default" color="green" block onClick={this.openHistoryModal}>
                                    <FormattedMessage id="map.displays.popup.showHistory" />
                                </Button>

                                <TCMDisplayHistoryModal
                                    show={this.state.historyModalOpen}
                                    onHide={this.closeHistoryModal}
                                    display={currentDisplay}
                                />
                            </SecuredFragment>

                            <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.FORCE_DISPLAY]}>
                                <Button appearance="default" color="blue" block onClick={this.openForceModal}>
                                    <FormattedMessage id="map.displays.popup.forceDisplay" />
                                </Button>
                            </SecuredFragment>

                            {this.props.editMode && (
                                <SecuredFragment authorizedRoles={[rolesConstants.mapTCMDisplays.UPDATE_DISPLAY]}>
                                    <Button
                                        style={{ marginTop: 20 }}
                                        appearance="default"
                                        block
                                        color="orange"
                                        onClick={() => this.props.openDrawer('tcm-display', this.props.display.id)}
                                        className="margin-botttom-15">
                                        <FormattedMessage id="map.displays.popup.editDisplay" />
                                    </Button>
                                </SecuredFragment>
                            )}

                            {/* {this.props.editMode && (
                            <Fragment>
                                <SecuredFragment authorizedRoles={[rolesConstants.mapTCMDisplays.UPDATE_DISPLAY]}>
                                    <Button
                                        style={{ marginTop: 20 }}
                                        appearance="default"
                                        block
                                        color="orange"
                                        onClick={this.openEditModal}
                                        className="margin-botttom-15">
                                        <FormattedMessage id="map.displays.popup.editDisplay" />
                                    </Button>
                                </SecuredFragment>

                                <SecuredFragment authorizedRoles={[rolesConstants.mapTCMDisplays.UPDATE_DISPLAY]}>
                                    <Button
                                        appearance="default"
                                        block
                                        color="orange"
                                        onClick={this.openConfigModal}
                                        className="margin-botttom-15">
                                        <FormattedMessage id="map.displays.popup.configDisplay" />
                                    </Button>
                                </SecuredFragment>

                                <SecuredFragment authorizedRoles={[rolesConstants.mapTCMDisplays.UPDATE_DISPLAY]}>
                                    <Button
                                        appearance="default"
                                        block
                                        color="orange"
                                        onClick={this.openEditConfigTypeModal}
                                        className="margin-botttom-15">
                                        <FormattedMessage id="map.displays.popup.editConfigType" />
                                    </Button>
                                </SecuredFragment>

                                <SecuredFragment authorizedRoles={[rolesConstants.mapTCMDisplays.UPDATE_DISPLAY]}>
                                    <Button
                                        appearance="default"
                                        block
                                        color="orange"
                                        onClick={this.openCountersModal}
                                        className="margin-botttom-15">
                                        <FormattedMessage id="map.displays.popup.counters" />
                                    </Button>
                                </SecuredFragment>
                            </Fragment>
                        )} */}

                            {/* HERE ARE ALL THE MODALS */}
                            <ForceDisplayModal
                                show={this.state.forceModalOpen}
                                onHide={this.closeForceModal}
                                id={[currentDisplay.id]}
                                display={currentDisplay}
                            />

                            {/* {this.props.editMode && (
                            <Fragment>
                                <EditTCMDisplayModal
                                    display={this.props.display}
                                    show={this.state.editModalOpen}
                                    onHide={this.closeEditModal}
                                    controllers={this.props.controllers}
                                />

                                <TCMDisplayConfigModal
                                    display={this.props.display}
                                    show={this.state.configModalOpen}
                                    onHide={this.closeConfigModal}
                                />

                                <EditConfigTypeModal
                                    display={this.props.display}
                                    show={this.state.editConfigTypeModalOpen}
                                    onHide={this.closeEditConfigTypeModal}
                                    placeTypes={this.props.placeTypes}
                                />

                                <TCMDisplayCountersModal
                                    display={this.props.display}
                                    show={this.state.countersModalOpen}
                                    onHide={this.closeCountersModal}
                                />
                            </Fragment>
                        )} */}
                        </Fragment>
                    )}
                </Popup>
            </Fragment>
        );
    }
}

export default injectIntl(TCMDisplayPopup);
