import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Checkbox,
    CheckPicker,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Modal,
    Tag,
} from 'rsuite';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { LPDisplay, LPDisplayData } from '../../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    show: boolean;
    onHide: (shouldReload: boolean) => void;
    displayToCopy: LPDisplay;
    ipCanId: number;
};

type FormValue = {
    configurationToCopy: {
        baseConfig: boolean;
        countersConfig: boolean;
        topologysConfig: boolean;
        textConfig: boolean;
        virtualDisplaysConfig: boolean;
    };
    lpDisplaysId: number[];
};

type LPDisplayFormValue = {
    label: string;
    value: number;
    ipcanModule: string;
};

export const CopyLPDisplayConfig = (props: Props) => {
    const intl = useIntl();

    const [formValue, setFormValue] = React.useState<FormValue>({
        configurationToCopy: {
            baseConfig: true,
            countersConfig: true,
            topologysConfig: true,
            textConfig: true,
            virtualDisplaysConfig: true,
        },
        lpDisplaysId: [],
    });

    const [lpDisplays, setLPDisplays] = React.useState<LPDisplay[]>([]);
    const [ipcanModules, setIpCanModules] = React.useState<IpCan[]>([]);
    const [lpDisplayFormValue, setLPDisplayFormValue] = React.useState<LPDisplayFormValue[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/devices/lp-matrixdisplays-v2', {
                headers: authHeader(),
            })
            .then(response => {
                const lpDisplays: LPDisplay[] = response.data.map((lpDisplay: LPDisplayData) => {
                    if (lpDisplay.ipcanmodule) return new LPDisplay(lpDisplay, lpDisplay.ipcanmodule.id, null);
                });

                setLPDisplays(lpDisplays.filter(lpDisplay => lpDisplay.getId() !== props.displayToCopy.id));
            });
    }, [props.displayToCopy.getId()]);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/ipcanmodules', {
                headers: authHeader(),
            })
            .then(ipcanmoduleResponse => {
                const ipcanModules = ipcanmoduleResponse.data.map(ipcanModule => {
                    return new IpCan(ipcanModule);
                });

                setIpCanModules(ipcanModules);
            });
    }, []);

    useEffect(() => {
        if (ipcanModules.length > 0) {
            const lpDisplayForm = lpDisplays.map(lpDisplay => {
                const ipcan = ipcanModules.find(ipcan => ipcan.id === lpDisplay.getIpCanId());

                return {
                    label: `${ipcan?.label} - ${lpDisplay.getBus() + 1} - ${lpDisplay.getDeviceId()}`,
                    value: lpDisplay.getId(),
                    ipcanModule: `${ipcan?.label} (${ipcan?.macStr})`,
                };
            });

            setLPDisplayFormValue(lpDisplayForm);
        }
    }, [lpDisplays, ipcanModules]);

    const handleCheckChange = (value, check) => {
        setFormValue({
            ...formValue,
            configurationToCopy: {
                ...formValue.configurationToCopy,
                [value]: check,
            },
        });
    };

    const validEdition = () => {
        setIsLoading(true);

        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/copyConfig',
                {
                    id: props.displayToCopy.getId(),
                    tabId: formValue.lpDisplaysId,
                    baseConfig: formValue.configurationToCopy.baseConfig,
                    countersConfig: formValue.configurationToCopy.countersConfig,
                    topologysConfig: formValue.configurationToCopy.topologysConfig,
                    textConfig: formValue.configurationToCopy.textConfig,
                    virtualDisplaysConfig: formValue.configurationToCopy.virtualDisplaysConfig,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'lpMatrixDisplay.copy.displayToCopyOn' }));

                props.onHide(true);

                setFormValue({
                    configurationToCopy: {
                        baseConfig: true,
                        countersConfig: true,
                        topologysConfig: true,
                        textConfig: true,
                        virtualDisplaysConfig: true,
                    },
                    lpDisplaysId: [],
                });
            })
            .catch(err => {
                console.error(err);

                Alert.success(intl.formatMessage({ id: 'lpMatrixDisplay.copy.displayToCopyOn' }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const cancelEdition = () => {
        if (!isLoading) {
            setFormValue({
                configurationToCopy: {
                    baseConfig: true,
                    countersConfig: true,
                    topologysConfig: true,
                    textConfig: true,
                    virtualDisplaysConfig: true,
                },
                lpDisplaysId: [],
            });

            props.onHide(false);
        }
    };

    const currentIpCanModule = ipcanModules.find(ipcan => ipcan.id === props.ipCanId);

    return (
        <Modal show={props.show} onHide={() => props.onHide(false)} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="lpMatrixDisplay.copy.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="margin-top-20">
                <FlexboxGrid justify="center" className="margin-bottom-20">
                    <FlexboxGrid.Item>
                        <Tag color="blue" className="padding-left-10 padding-right-10">
                            {currentIpCanModule?.label} - ({currentIpCanModule?.macStr}) - {props.displayToCopy.bus + 1}{' '}
                            - {props.displayToCopy.deviceId}
                        </Tag>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <Form fluid formValue={formValue} onChange={value => setFormValue(value as FormValue)}>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="lpMatrixDisplay.copy.displayToCopyOn" />
                        </ControlLabel>
                        <div data-cy="lpMatrixDisplay-copy-displayToCopyOn">
                            <FormControl
                                name="lpDisplaysId"
                                accepter={CheckPicker}
                                data={lpDisplayFormValue}
                                groupBy="ipcanModule"
                                searchable={false}
                            />
                        </div>
                    </FormGroup>

                    <FlexboxGrid
                        style={{ backgroundColor: '#CFCFCF' }}
                        align="middle"
                        justify="center"
                        className="margin-top-10">
                        <FlexboxGrid.Item>
                            <div data-cy="lpMatrixDisplay-copy-baseConfig">
                                <Checkbox
                                    value="baseConfig"
                                    checked={formValue.configurationToCopy.baseConfig}
                                    onChange={(value, check) => handleCheckChange(value, check)}>
                                    <FormattedMessage id="lpMatrixDisplay.copy.baseConfig" />
                                </Checkbox>
                            </div>
                            <div data-cy="lpMatrixDisplay-copy-countersConfig">
                                <Checkbox
                                    value="countersConfig"
                                    checked={formValue.configurationToCopy.countersConfig}
                                    onChange={(value, check) => handleCheckChange(value, check)}>
                                    <FormattedMessage id="lpMatrixDisplay.copy.countersConfig" />
                                </Checkbox>
                            </div>
                            <div data-cy="lpMatrixDisplay-copy-topologysConfig">
                                <Checkbox
                                    value="topologysConfig"
                                    checked={formValue.configurationToCopy.topologysConfig}
                                    onChange={(value, check) => handleCheckChange(value, check)}>
                                    <FormattedMessage id="lpMatrixDisplay.copy.topologysConfig" />
                                </Checkbox>
                            </div>
                            <div data-cy="lpMatrixDisplay-copy-textConfig">
                                <Checkbox
                                    value="textConfig"
                                    checked={formValue.configurationToCopy.textConfig}
                                    onChange={(value, check) => handleCheckChange(value, check)}>
                                    <FormattedMessage id="lpMatrixDisplay.copy.textConfig" />
                                </Checkbox>
                            </div>
                            <div data-cy="lpMatrixDisplay-copy-virtualDisplaysConfig">
                                <Checkbox
                                    value="virtualDisplaysConfig"
                                    checked={formValue.configurationToCopy.virtualDisplaysConfig}
                                    onChange={(value, check) => handleCheckChange(value, check)}>
                                    <FormattedMessage id="lpMatrixDisplay.copy.virtualDisplaysConfig" />
                                </Checkbox>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={() => cancelEdition()}
                        color="red"
                        size="sm"
                        disabled={isLoading}
                        data-cy="lpMatrixDisplay-copy-cancel">
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Button
                        onClick={() => validEdition()}
                        color="blue"
                        size="sm"
                        loading={isLoading}
                        data-cy="lpMatrixDisplay-copy-validate">
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
