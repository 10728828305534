import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Col, FlexboxGrid, Tag, TagGroup } from 'rsuite';
import { LPDisplay } from '../../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { LPMatrixDisplayWidget } from '../../../Widgets/LPMatrixDisplayWidget';
import LPVirtualDisplayStepEditAndDelete from './Parts/LPVirtualDisplayStepEditAndDelete';

type Props = {
    step: VirtualDisplayStep;
    backgroundColor: string;
    virtualDisplay: VirtualDisplayPreviewInformation;
    reloadDisplay: Function;
    display: LPDisplay;
    updateInformation: Function;
};

export const LPVirtualDisplayStepArrow = ({
    step,
    backgroundColor,
    virtualDisplay,
    reloadDisplay,
    display,
    updateInformation,
}: Props) => (
    <FlexboxGrid align="middle" justify="space-between">
        <FlexboxGrid.Item data-cy="lpDisplay-virtualDisplay-step-order" componentClass={Col} xs={2}>
            <Avatar circle size="sm">
                {step.getStepOrder() + 1}
            </Avatar>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} xs={6}>
            <Tag data-cy="lpDisplay-virtualDisplay-step-arrow" color="blue">
                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.arrow" />
            </Tag>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} xs={13}>
            <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} xs={24}>
                    <LPMatrixDisplayWidget backgroundColor={backgroundColor} step={step} display={display} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <TagGroup>
                {step.getState() === 0 && (
                    <Tag color="red">
                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.mode.disabled" />
                    </Tag>
                )}
                {step.getState() === 1 && (
                    <Tag color="green">
                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.mode.enabled" />
                    </Tag>
                )}
                {step.getState() === 2 && (
                    <Tag color="orange">
                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.mode.waiting" />
                    </Tag>
                )}

                <Tag data-cy="lpDisplay-virtualDisplay-step-size" color="violet">{`${step.getArrowSize()}px`}</Tag>

                <Tag data-cy="lpDisplay-virtualDisplay-step-time" color="cyan">
                    <span className="margin-right-5">{step.getTime()}</span>
                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.seconds" />
                </Tag>

                {step.getTransition() === 1 && (
                    <Tag data-cy="lpDisplay-virtualDisplay-step-transitionDown" color="orange">
                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.transition.DownToDown" />
                    </Tag>
                )}
                {step.getTransition() === 2 && (
                    <Tag data-cy="lpDisplay-virtualDisplay-step-transitionUp" color="orange">
                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.transition.UpToDown" />
                    </Tag>
                )}

                <Tag data-cy="lpDisplay-virtualDisplay-step-position" color="yellow">
                    <FormattedMessage
                        id={`ipCanDevices.lpDisplay.virtualDisplay.position.${step.getArrowPosition()}`}
                    />
                </Tag>
            </TagGroup>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} xs={3}>
            <LPVirtualDisplayStepEditAndDelete
                step={step}
                virtualDisplay={virtualDisplay}
                reloadDisplay={reloadDisplay}
                display={display}
                updateInformation={updateInformation}
            />
        </FlexboxGrid.Item>
    </FlexboxGrid>
);
