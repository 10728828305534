import { LPDisplay } from '../lpDisplays/LPDisplay';
import { MapDeviceBase } from './MapDeviceBase';

export class MapLPMatrixDisplayV2 extends MapDeviceBase {
    private _mapArrowRotation: number;
    private _isShow: boolean;
    private _lpMatrixDisplayV2: LPDisplay;
    private _isMirror: boolean;

    constructor(data) {
        super(data);

        this._mapArrowRotation = data.mapArrowRotation;
        this._isShow = data.isShow;
        this._isMirror = data.isMirror;

        if (data.lpMatrixDisplayV2.ipcanmodule) {
            this._lpMatrixDisplayV2 = new LPDisplay(
                data.lpMatrixDisplayV2,
                data.lpMatrixDisplayV2.ipcanmodule.id,
                null
            );
        } else {
            this._lpMatrixDisplayV2 = new LPDisplay(data.lpMatrixDisplayV2, 0, null);
        }
    }

    public getMapArrowRotation(): number {
        return this._mapArrowRotation;
    }

    public getIsShow() {
        return this._isShow;
    }

    public getIsMirror() {
        return this._isMirror;
    }

    public getLpMatrixDisplayV2() {
        return this._lpMatrixDisplayV2;
    }

    public updateDisplay(display) {
        this._lpMatrixDisplayV2.updateConfig(display.lpMatrixDisplayV2);
        if (display.label) this.setLabel(display.label);
        if (typeof display.isShow !== 'undefined') this._isShow = display.isShow;
        if (typeof display.isMirror !== 'undefined') this._isMirror = display.isMirror;
        if (typeof display.mapArrowRotation !== 'undefined') this._mapArrowRotation = display.mapArrowRotation;
    }

    public websocketUpdateDisplay(lpDisplay) {
        this._lpMatrixDisplayV2.setWebsocketInformation(lpDisplay);
    }
}
