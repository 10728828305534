import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal, Toggle } from 'rsuite';
import IPCanTcm from '../../../handlers/ipcan/IpCanTCM';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    show: boolean;
    bus: number;
    ipCan: IPCanTcm | undefined;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    formValue: Record<string, any>;
    isLoading: boolean;
};

class SetOneIdModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            formValue: {
                withButton: false,
                oldId: 1,
                newId: 2,
            },
            isLoading: false,
        };
    }

    handleFormChange = formValue => {
        this.setState({
            formValue: {
                ...this.state.formValue,
                ...formValue,
            },
        });
    };

    handleSetOneId = () => {
        this.setState({ isLoading: true });

        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/cmdTCMSetOneId',
                {
                    id: this.props.ipCan?.getId(),
                    bus: this.props.bus,
                    oldId: parseInt(this.state.formValue.oldId),
                    newId: parseInt(this.state.formValue.newId),
                    withButton: this.state.formValue.withButton,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.setState({ isLoading: false });

                this.props.onHide();

                this.setState({ formValue: { withButton: false, oldId: 1, newId: 1 } });

                Alert.success(this.props.intl.formatMessage({ id: 'ipcan.actions.setOneId.success' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(this.props.intl.formatMessage({ id: 'ipcan.actions.setOneId.error' }));

                this.setState({
                    isLoading: false,
                });
            });
    };

    closeModal = () => {
        if (!this.state.isLoading) {
            this.props.onHide();
        }
    };

    render() {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCan.setOneId.title" /> | {this.props.ipCan?.getLabel()} -{' '}
                        {this.props.bus + 1}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid onChange={this.handleFormChange} formValue={this.state.formValue}>
                        <FormGroup>
                            <ControlLabel>{this.props.intl.formatMessage({ id: 'ipCan.setOneId.oldId' })}</ControlLabel>
                            <FormControl name="oldId" disabled={this.state.formValue.withButton} type="number" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{this.props.intl.formatMessage({ id: 'ipCan.setOneId.newId' })}</ControlLabel>
                            <FormControl name="newId" type="number" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                {this.props.intl.formatMessage({ id: 'ipCan.setOneId.withButton' })}
                            </ControlLabel>
                            <FormControl name="withButton" accepter={Toggle} />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={this.closeModal} color="red">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button onClick={this.handleSetOneId} appearance="primary" loading={this.state.isLoading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(SetOneIdModal);
