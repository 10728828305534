import forceElement from './Desc/forceElement';
import offline from './Desc/offline';
import online from './Desc/online';
import readRealSensorConfig from './Desc/readRealSensorConfig';
import updateCalibration from './Desc/updateCalibration';
import updateColor from './Desc/updateColor';
import updateMaintenanceState from './Desc/updateMaintenanceState';
import updateTime from './Desc/updateTime';

// wait for online, total and db as data
// wait for controler online or not

export default function TCMSensorDescCell(props) {
    switch (props) {
        case 'online':
            return online();
        case 'offline':
            return offline();
        case 'update color':
            return updateColor();
        case 'update time':
            return updateTime();
        case 'update calibration':
            return updateCalibration();
        case 'force sensor':
            return forceElement();
        case 'read real sensor config':
            return readRealSensorConfig();
        case 'update maintenance state':
            return updateMaintenanceState();
        default:
            return props;
    }
}
