import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    Modal,
    SelectPicker,
    Toggle
} from 'rsuite';
import { PlaceIPCamera } from '../../../handlers/Camera/ApiCamera';
import PlaceCamera from '../../../handlers/Camera/PlaceCamera';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { generatePlaceCameraForceTypes } from '../Types/PlaceCameraTypes';

type Props = {
    onClose: () => void;
    show: boolean;
    places: PlaceCamera[];
};

type FormValue = {
    isForce: boolean;
    type: number;
    endForceDate: Date;
};

export const ForceCameraPlaceModal = (props: Props) => {
    const intl = useIntl();

    useEffect(() => {
        if (props.show) {
            if (props.places.length === 1 && props.places[0].getIsForce()) {
                setFormValue({
                    isForce: true,
                    type: props.places[0].getForceType(),
                    endForceDate: moment(props.places[0].getEndForceTime(), 'DD/MM/YYYY HH:mm:ss').toDate(),
                });
            } else {
                setFormValue({
                    isForce: false,
                    type: 0,
                    endForceDate: moment().add(1, 'year').toDate(),
                });
            }
        }
    }, [props.show]);

    const [formValue, setFormValue] = useState<FormValue>({
        isForce: false,
        type: 0,
        endForceDate: moment().add(1, 'year').toDate(),
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleValid = () => {
        setIsLoading(true);

        axiosService
            .getAxios()
            .put<PlaceIPCamera[]>(
                '/places-camera/forcePlaceCamera',
                {
                    tabId: props.places.map(place => place.getId()),
                    isForce: formValue.isForce,
                    forceType: formValue.type,
                    endForceTime: moment(formValue.endForceDate).toISOString(),
                    startForceTime: moment().toISOString(),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(forceResponse => {
                forceResponse.data.forEach((place: PlaceIPCamera) => {
                    const placeCamera = props.places.find(
                        (placeCamera: PlaceCamera) => placeCamera.getId() === place.id
                    );
                    if (placeCamera) {
                        placeCamera.updatePlace(place);
                    }
                });

                props.onClose();
                Alert.success(intl.formatMessage({ id: 'camera.places.forcePlace.success' }));
            })
            .catch(err => {
                console.error(err);
                Alert.error(intl.formatMessage({ id: 'camera.places.forcePlace.error' }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCancel = () => {
        setFormValue({
            isForce: false,
            type: 0,
            endForceDate: moment().add(1, 'year').toDate(),
        });

        props.onClose();
    };

    return (
        <Modal backdrop="static" show={props.show} onHide={props.onClose}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="camera.places.forcePlace.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form fluid formValue={formValue} onChange={formValue => setFormValue(formValue as FormValue)}>
                    <FlexboxGrid justify="center" className="margin-bottom-15">
                        <FlexboxGrid.Item>
                            <FormControl
                                name="isForce"
                                accepter={Toggle}
                                size="lg"
                                checkedChildren={intl.formatMessage({
                                    id: 'sensor.force.forced',
                                })}
                                unCheckedChildren={intl.formatMessage({
                                    id: 'sensor.force.unforced',
                                })}
                                defaultChecked={formValue.isForce}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    {formValue.isForce && (
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    name="type"
                                    accepter={SelectPicker}
                                    searchable={false}
                                    cleanable={false}
                                    data={generatePlaceCameraForceTypes(intl)}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    name="endForceDate"
                                    accepter={DatePicker}
                                    format="DD/MM/YYYY HH:mm:ss"
                                    ranges={[
                                        {
                                            label: intl.formatMessage({ id: 'calendar.now' }),
                                            value: new Date(),
                                        },
                                    ]}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    )}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <FlexboxGrid justify="end">
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button onClick={handleCancel} color="red">
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button onClick={handleValid} loading={isLoading} color="green">
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    );
};
