import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, Modal } from 'rsuite';
import Camera from '../../../../handlers/Camera/Camera';
import { Configuration } from '../../../../handlers/Configuration/Configuration';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { EditHyperParamCameraModal } from './EditHyperParamCameraModal';
import { ViewHyperParamCameraModal } from './ViewHyperParamCameraModal';

type Props = {
    show: boolean;
    onClose: () => void;
    camera: Camera;
};

export const HyperParamCameraModal = (props: Props) => {
    const intl = useIntl();

    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const [configurations, setConfigurations] = useState<Configuration[]>([]);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/configuration', { headers: authHeader() })
            .then(configurationResponse => {
                setConfigurations(
                    configurationResponse.data
                        .map(configuration => new Configuration(configuration))
                        .filter(
                            configuration =>
                                configuration.getSection() === 'CameraOccupancySensor' ||
                                configuration.getSection() === 'CameraServiceMl'
                        )
                );
            })
            .catch(err => {
                Alert.error(intl.formatMessage({ id: 'placeCamera.hyperParam.fetchConfigurationError' }));
                console.error(err);
            });
    }, []);

    return (
        <Modal show={props.show} onHide={() => props.onClose()} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="placeCamera.editHyperParam.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {isEditMode ? (
                    <EditHyperParamCameraModal
                        camera={props.camera}
                        cancelEdit={() => setIsEditMode(false)}
                        configurations={configurations}
                    />
                ) : (
                    <ViewHyperParamCameraModal camera={props.camera} configurations={configurations} />
                )}
            </Modal.Body>
            {!isEditMode && (
                <Modal.Footer>
                    <Button color="blue" onClick={() => setIsEditMode(true)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};
