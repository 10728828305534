import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Col, FlexboxGrid, Modal, Progress } from 'rsuite';
import { authActions } from '../../redux/actions';

type Props = {
    logout: Function;
    show: boolean;
};
type State = {
    percent: number;
    reloading: boolean;
    show: boolean;
    seconds: number;
};

const mapDispatchToProps = (dispatch: Function) => ({
    logout: () => dispatch(authActions.logout(false)),
});

class RestartingModal extends React.Component<Props, State> {
    timer: ReturnType<typeof setTimeout> | undefined;

    constructor(props: Props) {
        super(props);

        this.state = {
            percent: 0,
            reloading: false,
            show: true,
            seconds: 180,
        };

        this.connect = this.connect.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.state.seconds > 0) {
                this.setState({
                    percent: this.state.percent + 1,
                    seconds: this.state.seconds - 1,
                });
            } else {
                this.timer && clearInterval(this.timer);
                this.props.logout();
            }
        }, 1000);
    }

    componentWillUnmount = () => {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    connect() {
        this.setState({
            show: false,
            reloading: true,
        });

        this.props.logout();
    }

    render() {
        return (
            <Modal backdrop="static" show={this.props.show && this.state.show}>
                <Modal.Body>
                    <FlexboxGrid align="middle" justify="center">
                        <FlexboxGrid.Item>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'orange' }} size="3x" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid align="middle" justify="center">
                        <FlexboxGrid.Item>
                            <FormattedMessage id="reboot.message" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid align="middle" justify="center">
                        <FlexboxGrid.Item componentClass={Col} xs={20}>
                            <Progress.Line percent={(this.state.percent / 180) * 100} showInfo={false} />{' '}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                            {this.state.seconds}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>
            </Modal>
        );
    }
}

export default connect(null, mapDispatchToProps)(RestartingModal);
