import { useIntl } from 'react-intl';
import { FlexboxGrid, Panel } from 'rsuite';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { TopologyInterface } from '../../../../handlers/lpDisplays/LPDisplayTopology';
import PanelHeader from '../../../Custom/PanelHeader';
import PreviewTopologyPart from '../Nodes/TopologyNode/PreviewTopologyPart';

type Props = {
    display: LPDisplay;
    topology: TopologyInterface;
};

export const TopologyPreviewDrawer = (props: Props) => {
    const intl = useIntl();

    return (
        <Panel
            shaded
            className="panel-small"
            header={<PanelHeader title={intl.formatMessage({ id: 'configuration.topology.preview' })} buttons={[]} />}>
            <FlexboxGrid align="middle" justify="center">
                <FlexboxGrid.Item data-cy="topology-preview">
                    <PreviewTopologyPart display={props.display} topology={props.topology} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    );
};
