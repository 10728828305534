import handleError from '../../handlers/error.handler';
import PlaceType from '../../handlers/PlaceType';
import { authHeader } from '../helpers';
import { axiosService } from './axios.services';

export const placeTypeService = {
    getAll,
    getById,
    create,
    update,
    updateImage,
    remove: remove,
};

function getAll() {
    return axiosService
        .getAxios()
        .get('/place-type', { headers: authHeader() })
        .then(response =>
            response.data.map(type => {
                let placeType = new PlaceType(type);

                return placeType.getPlaceType();
            })
        )
        .then(placeTypes => {
            return placeTypes;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function getById(id) {
    return axiosService
        .getAxios()
        .get(`/place-type/${id}`, { headers: authHeader() })
        .then(response => {
            let placeType = new PlaceType(response.data);

            return placeType.getPlaceType();
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function create(label) {
    const parameters = { headers: authHeader() };
    const data = {
        label,
    };
    return axiosService
        .getAxios()
        .post('/place-type', data, parameters)
        .then(response => {
            let placeType = new PlaceType(response.data);

            return placeType.getPlaceType();
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function update(id, label) {
    const parameters = { headers: authHeader() };
    const data = { id, label };

    return axiosService
        .getAxios()
        .put('/place-type', data, parameters)
        .then(response => {
            let placeType = new PlaceType(response.data);

            return placeType.getPlaceType();
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateImage(id, image) {
    const parameters = { headers: authHeader() };
    let data = new FormData();
    data.append('file', image);

    return axiosService
        .getAxios()
        .post(`/place-type/picture/${id}`, data, parameters)
        .then(response => {
            let placeType = new PlaceType(response.data);

            return placeType.getPlaceType();
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function remove(id) {
    const data = {
        headers: authHeader(),
        data: { id: id },
    };
    return axiosService
        .getAxios()
        .delete('/place-type', data)
        .then(response => {
            let placeType = new PlaceType(response.data);

            return placeType.getPlaceType();
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}
