import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'rsuite';

type Props = {
    show: boolean;
};

export function DisconnectedModal({ show }: Props) {
    return (
        <Modal backdrop="static" show={show} size="xs">
            <Modal.Body>
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{
                        color: '#ffb300',
                        fontSize: 24,
                        marginRight: 10,
                    }}
                />
                <FormattedMessage id="message.disconnected" />
            </Modal.Body>
        </Modal>
    );
}
