import {
    faCopy,
    faFont,
    faHistory,
    faImage,
    faLock,
    faTh,
    faThLarge,
    faTools,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Drawer, FlexboxGrid, Popover, Whisper } from 'rsuite';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import ForceLPMatrixDisplayModal from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/ForceLPMatrixDisplayModal';
import { DeviceStateInformation } from '../../Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../Cards/DeviceStateInformation/deviceStateInformationGenerator';
import { CopyLPDisplayConfig } from '../../LPDisplays/Modal/CopyLPDisplayConfig';
import EditLPDisplayCountersModal from '../../LPDisplays/Modal/EditLPDisplayCountersModal';
import LPDisplayAdvancedCommandsModal from '../../LPDisplays/Modal/LPDisplayAdvancedCommandsModal';
import LPDisplayHistoryModal from '../../LPDisplays/Modal/LPDisplayHistoryModal';
import LPMatrixDisplayV2Config from '../../LPMatrixDisplayV2Config';
import DeleteLPDisplayModal from './../../LPDisplays/Modal/DeleteLPDisplayModal';
import LPDisplayBaseConfigurationCard from './Cards/LPDisplayBaseConfigurationCard';
import LPDisplaySystemTextCard from './Cards/LPDisplaySystemTextCard';
import LPDisplayUserTextCard from './Cards/LPDisplayUserTextCard';

type Props = {
    show: boolean;
    onHide: Function;
    display: LPDisplay;
    reloadDisplays: Function;
    ipCanLabel?: string;
    isLoading: boolean;
    ipCanId: number;
} & RouteComponentProps;

type State = {
    showDeleteLPDisplayModal: boolean;
    editVirtualDisplaysModalOpen: boolean;
    editVirtualDisplayFontModalOpen: boolean;
    isEditLPDisplayCountersModalOpen: boolean;
    isEditTopologyModalOpen: boolean;
    isAdvancedCommandsModalOpen: boolean;
    showEditLPDisplayTopologyModal: boolean;
    showForceLPDisplayModal: boolean;
    showLPDisplayHistoryModal: boolean;
    showCopyConfigModal: boolean;
};

class LPDisplayDrawer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showDeleteLPDisplayModal: false,
            editVirtualDisplaysModalOpen: false,
            editVirtualDisplayFontModalOpen: false,
            isEditLPDisplayCountersModalOpen: false,
            isEditTopologyModalOpen: false,
            isAdvancedCommandsModalOpen: false,
            showEditLPDisplayTopologyModal: false,
            showForceLPDisplayModal: false,
            showLPDisplayHistoryModal: false,
            showCopyConfigModal: false,
        };
    }

    handleOpenCopyConfigModal = () => {
        this.setState({
            showCopyConfigModal: true,
        });
    };

    handleCloseCopyConfigModal = () => {
        this.setState({
            showCopyConfigModal: false,
        });
    };

    showDeleteLPDisplayModal = (event: React.SyntheticEvent<Element, Event>) => {
        event.stopPropagation();

        this.setState({
            showDeleteLPDisplayModal: true,
        });
    };

    hideDeleteLPDisplayModal = (shouldReloadDevices: boolean = false) => {
        this.setState({
            showDeleteLPDisplayModal: false,
        });

        if (shouldReloadDevices) {
            this.props.reloadDisplays();
        }
    };

    toggleEditLPDisplayTopologyModal = (shouldReloadDevices: boolean = false) => {
        this.setState({
            showEditLPDisplayTopologyModal: !this.state.showEditLPDisplayTopologyModal,
        });

        if (shouldReloadDevices) {
            this.props.reloadDisplays();
        }
    };

    openEditVirtualDisplayFontModal = () => {
        this.setState({
            editVirtualDisplayFontModalOpen: true,
        });
    };

    closeEditVirtualDisplayFontModal = (shouldReload: boolean = false) => {
        this.setState({
            editVirtualDisplayFontModalOpen: false,
        });

        if (shouldReload) {
            this.props.reloadDisplays();
        }
    };

    toggleOpenEditLPDisplayCountersModal = (shouldReload: boolean = false) => {
        this.setState({
            isEditLPDisplayCountersModalOpen: !this.state.isEditLPDisplayCountersModalOpen,
        });

        if (shouldReload) {
            this.props.reloadDisplays();
        }
    };

    openAdvancedCommandsModal = () => {
        this.setState({
            isAdvancedCommandsModalOpen: true,
        });
    };

    closeAdvancedCommandsModal = () => {
        this.setState({
            isAdvancedCommandsModalOpen: false,
        });
    };

    openForceLPSensorModal = () => {
        this.setState({
            showForceLPDisplayModal: true,
        });
    };

    closeForceLPSensorModal = () => {
        this.setState({
            showForceLPDisplayModal: false,
        });
    };

    openLPDisplayHistoricModal = () => {
        this.setState({
            showLPDisplayHistoryModal: true,
        });
    };

    closeLPDisplayHistoricModal = () => {
        this.setState({
            showLPDisplayHistoryModal: false,
        });
    };

    generateSpeaker = () => {
        return (
            <Popover style={{ width: '40vh' }}>
                <ButtonGroup justified>
                    <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.READ_FONT]}>
                        <Button
                            onClick={() =>
                                this.props.history.push(
                                    `/ipCan/${this.props.ipCanId}/bus/${this.props.display.bus}/matrix-display/${this.props.display.id}/font/16`
                                )
                            }
                            data-cy="lpDisplay-button-updateFont"
                            appearance="primary"
                            color="blue">
                            <FontAwesomeIcon icon={faFont} className="margin-right-5" />
                            <FormattedMessage id="ipCanDevices.lpDisplay.updateFont" />
                        </Button>
                    </SecuredFragment>

                    <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.READ_PICTO]}>
                        <Button
                            onClick={() =>
                                this.props.history.push(
                                    `/ipCan/${this.props.ipCanId}/bus/${this.props.display.bus}/matrix-display/${this.props.display.id}/picto/1`
                                )
                            }
                            data-cy="lpDisplay-button-updatePicto"
                            appearance="primary"
                            color="cyan">
                            <FontAwesomeIcon icon={faImage} className="margin-right-5" />
                            <FormattedMessage id="ipCanDevices.lpDisplay.updatePicto" />
                        </Button>
                    </SecuredFragment>
                </ButtonGroup>
            </Popover>
        );
    };

    render = () => (
        <Fragment>
            <DeleteLPDisplayModal
                show={this.state.showDeleteLPDisplayModal}
                onHide={this.hideDeleteLPDisplayModal}
                lpDisplaysIds={[this.props.display.id]}
            />

            {this.state.isEditLPDisplayCountersModalOpen && (
                <EditLPDisplayCountersModal
                    display={this.props.display}
                    show={this.state.isEditLPDisplayCountersModalOpen}
                    onHide={this.toggleOpenEditLPDisplayCountersModal}
                />
            )}

            <LPMatrixDisplayV2Config
                display={this.props.display}
                show={this.state.showEditLPDisplayTopologyModal}
                onHide={this.toggleEditLPDisplayTopologyModal}
            />

            <Drawer show={this.props.show} onHide={() => this.props.onHide()} backdrop={true}>
                <Drawer.Header>
                    <Drawer.Title>
                        {this.props.ipCanLabel} - {this.props.display.getBus() + 1} - {this.props.display.getDeviceId()}
                    </Drawer.Title>
                </Drawer.Header>

                <Drawer.Body className="drawer-body-without-avatar">
                    <LPDisplayBaseConfigurationCard display={this.props.display} isLoading={this.props.isLoading} />
                    <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_MAINTENACE_STATE]}>
                        <DeviceStateInformation
                            device={this.props.display}
                            editMaintenanceStateUrl={MaintenanceStateInformationEditURL.LP_MATRIX_DISPLAY_V2}
                        />
                    </SecuredFragment>

                    <FlexboxGrid className="margin-bottom-10">
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <SecuredFragment
                                authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_COUNTERS_CONFIG]}>
                                <Button
                                    block
                                    data-cy="lpDisplays-counterConfiguration"
                                    color="orange"
                                    loading={this.props.isLoading}
                                    className="margin-bottom-10"
                                    onClick={() => this.toggleOpenEditLPDisplayCountersModal(false)}>
                                    <FormattedMessage id="ipCanDevices.lpDisplays.counterConfiguration" />
                                </Button>
                            </SecuredFragment>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.CMD_DEVICE]}>
                                <Button
                                    data-cy="lpDisplay-advancedCommands"
                                    block
                                    color="orange"
                                    onClick={this.openAdvancedCommandsModal}>
                                    <FontAwesomeIcon icon={faTools} className="margin-right-5" />
                                    <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands" />
                                </Button>

                                <LPDisplayAdvancedCommandsModal
                                    show={this.state.isAdvancedCommandsModalOpen}
                                    onHide={this.closeAdvancedCommandsModal}
                                    lpDisplay={this.props.display}
                                />
                            </SecuredFragment>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_FORCE_TYPE]}>
                                <Button
                                    data-cy="lpDisplay-advancedCommands"
                                    block
                                    color="blue"
                                    onClick={this.openForceLPSensorModal}>
                                    <FontAwesomeIcon icon={faLock} className="margin-right-5" />
                                    <FormattedMessage id="ipCanDevices.lpDisplays.forcedDisplay" />
                                </Button>

                                {this.props.show && (
                                    <ForceLPMatrixDisplayModal
                                        isOpen={this.state.showForceLPDisplayModal}
                                        onHide={this.closeForceLPSensorModal}
                                        display={this.props.display}
                                    />
                                )}
                            </SecuredFragment>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <SecuredFragment authorizedRoles={[rolesConstants.historics.VIEW_LP_MATRIX_DISPLAY_V2]}>
                                <Button
                                    data-cy="lpDisplay-historic"
                                    block
                                    color="blue"
                                    onClick={this.openLPDisplayHistoricModal}>
                                    <FontAwesomeIcon icon={faHistory} className="margin-right-5" />
                                    <FormattedMessage id="ipCanDevices.lpDisplays.historic" />
                                </Button>

                                {this.props.show && (
                                    <LPDisplayHistoryModal
                                        show={this.state.showLPDisplayHistoryModal}
                                        onHide={this.closeLPDisplayHistoricModal}
                                        display={this.props.display}
                                    />
                                )}
                            </SecuredFragment>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={24} style={{ marginTop: 12 }}>
                            <CopyLPDisplayConfig
                                displayToCopy={this.props.display}
                                show={this.state.showCopyConfigModal}
                                onHide={this.handleCloseCopyConfigModal}
                                ipCanId={this.props.display.getIpCanId()}
                            />

                            <Button
                                data-cy="lpDisplay-button-copyConfig"
                                block
                                appearance="primary"
                                color="yellow"
                                onClick={this.handleOpenCopyConfigModal}>
                                <FontAwesomeIcon icon={faCopy} className="margin-right-5" />
                                <FormattedMessage id="ipCanDevices.lpDisplay.copyConfig" />
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <LPDisplaySystemTextCard display={this.props.display} isLoading={this.props.isLoading} />

                    <LPDisplayUserTextCard display={this.props.display} isLoading={this.props.isLoading} />
                </Drawer.Body>

                <Drawer.Footer>
                    <FlexboxGrid className="margin-bottom-10">
                        <SecuredFragment
                            authorizedRoles={[
                                rolesConstants.lpMatrixDisplayV2.READ_PICTO,
                                rolesConstants.lpMatrixDisplayV2.READ_FONT,
                            ]}>
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <Whisper trigger="click" placement="leftEnd" speaker={this.generateSpeaker()}>
                                    <Button
                                        block
                                        color="violet"
                                        loading={this.props.isLoading}
                                        disabled={this.props.isLoading}>
                                        <FontAwesomeIcon icon={faTh} className="margin-right-5" />
                                        <FormattedMessage id="ipCanDevices.lpDisplay.updateFontOrPicto" />
                                    </Button>
                                </Whisper>
                            </FlexboxGrid.Item>
                        </SecuredFragment>

                        <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_TOPOLOGYS_CONFIG]}>
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <Button
                                    block
                                    data-cy="lpDisplay-button-updateLayout"
                                    appearance="primary"
                                    color="blue"
                                    onClick={() => this.toggleEditLPDisplayTopologyModal(false)}
                                    loading={this.props.isLoading}
                                    disabled={this.props.isLoading}>
                                    <FontAwesomeIcon icon={faThLarge} className="margin-right-5" />
                                    <FormattedMessage id="ipCanDevices.lpDisplay.updateLayout" />
                                </Button>
                            </FlexboxGrid.Item>
                        </SecuredFragment>

                        <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.DELETE]}>
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <Button
                                    data-cy="lpDisplay-button-delete"
                                    appearance="primary"
                                    color="red"
                                    disabled={this.props.display.getOnline()}
                                    block
                                    onClick={this.showDeleteLPDisplayModal}>
                                    <FontAwesomeIcon icon={faTrash} className="margin-right-5" />
                                    <FormattedMessage id="ipCanDevices.lpDisplays.delete" />
                                </Button>
                            </FlexboxGrid.Item>
                        </SecuredFragment>
                    </FlexboxGrid>
                </Drawer.Footer>
            </Drawer>
        </Fragment>
    );
}

export default withRouter(LPDisplayDrawer);
