import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { match, useHistory, useRouteMatch } from 'react-router';
import { Alert, FlexboxGrid, Loader, Message } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { LPDisplay } from '../../../handlers/lpDisplays/LPDisplay';
import { PictoPixel, PixelArtPictoRGBImport, PixelArtPictoRGBWS } from '../../../handlers/pixelArt/pixelArtPictoRGBWS';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';
import { clone } from '../../../utils/clone';
import { PictoLoader } from '../PictoMonochrome/Loader';
import { LeftSideBar } from './LeftSideBar';
import { PictoEditor } from './PictoEditor';
import { RightSideBar } from './RightSideBar';

const PICTO_TIMEOUT = 10000;

export const PictoRGB = () => {
    let pictoTimeout: ReturnType<typeof setTimeout>;
    let changeWidthTimeout: ReturnType<typeof setTimeout>;
    let changeHeightTimeout: ReturnType<typeof setTimeout>;

    const routeParams: match<RouteParams> | null = useRouteMatch(
        '/ipCan/:ipCanId/bus/:busNumber/matrix-display/:displayId/picto/:type'
    );
    const intl = useIntl();

    const history = useHistory();

    if (!routeParams)
        return <Message full type="error" description={intl.formatMessage({ id: 'pixelArt.pictoEditor.error' })} />;

    // PARSE ROUTE PARAMS TO NUMBER
    const busNumber = parseInt(routeParams.params.busNumber, 10);
    const displayId = parseInt(routeParams.params.displayId, 10);
    const type = parseInt(routeParams.params.type, 10);
    const ipCanId = parseInt(routeParams.params.ipCanId, 10);

    // LP MATRIX DISPLAY V2
    const [lpMatrixDisplay, setLPMatrixDisplay] = React.useState<LPDisplay | undefined>(undefined);
    // picto ARRAY
    const [picto, setPicto] = React.useState<PictoPixel[][][]>([]);
    // MatrixDisplay
    const [ipCanModule, setIpCanModule] = React.useState<IpCan | undefined>(undefined);
    // SELECTED picto INDEX
    const [selectedPictoIndex, setSelectedPictoIndex] = React.useState<number>(0);
    // LOADING STATE
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    // ERROR STATE
    const [hasError, setHasError] = React.useState<boolean>(false);
    // PICTO TO COPY
    const [pictoToCopy, setPictoToCopy] = React.useState<number>(-1);
    // CURRENT SELECTED COLOR
    const [currentSelectedColor, setCurrentSelectedColor] = React.useState<RGBColorType>({
        r: 0,
        g: 0,
        b: 0,
    });

    const [isTooLarge, setIsTooLarge] = React.useState<boolean>(false);

    const [isEyeDropperMode, setIsEyeDropperMode] = React.useState<boolean>(false);

    // Websocket connection
    useEffect(() => {
        webSocketService.joinRoom('lp-matrixDisplayV2');

        return function cleanup() {
            webSocketService.offEvent('lp-matrixDisplayV2:readPicto', handleWebsocket);
        };
    }, []);

    // // REQUEST MATRIX DISPLAY DATA
    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/devices/lp-matrixdisplays-v2/${displayId}`, {
                headers: authHeader(),
            })
            .then(matrixDisplayResponse => {
                setLPMatrixDisplay(new LPDisplay(matrixDisplayResponse.data, ipCanId, null));
            })
            .catch(err => {
                console.error(err);
            });
    }, [displayId]);

    // REQUEST IP CAN DATA
    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/ipcanmodules/${ipCanId}`, { headers: authHeader() })
            .then(ipCanResponse => {
                setIpCanModule(new IpCan(ipCanResponse.data));
            })
            .catch(err => {
                console.error(err);
            });
    }, [ipCanId]);

    // REQUEST WEBSOCKET DATA
    useEffect(() => {
        readPictoFromAPI();
    }, [type]);

    // Websocket handling
    const handleWebsocket = (data: PixelArtPictoRGBWS) => {
        if (data.id === displayId) {
            if (pictoTimeout) {
                clearTimeout(pictoTimeout);
            }

            setPicto(data.pictos.picto);

            if (data.pictos.pictoWasComplete) {
                setIsLoading(false);

                webSocketService.offEvent('lp-matrixDisplayV2:readPicto', handleWebsocket);
            } else {
                pictoTimeout = setTimeout(() => {
                    setIsLoading(false);
                    setHasError(true);
                }, PICTO_TIMEOUT);
            }
        }
    };

    const readPictoFromAPI = () => {
        setPicto([]);
        setIsLoading(true);
        setHasError(false);

        webSocketService.onEvent('lp-matrixDisplayV2:readPicto', handleWebsocket);

        setTimeout(() => {
            axiosService.getAxios().put(
                '/devices/lp-matrixdisplays-v2/readPicto',
                {
                    id: displayId,
                    pictoType: type,
                },
                {
                    headers: authHeader(),
                }
            );

            pictoTimeout = setTimeout(() => {
                setIsLoading(false);
                setHasError(true);
            }, PICTO_TIMEOUT);
        }, 1000);
    };

    const generatePictos = () => {
        const finalValue: PictoRGBValueInterface[] = [];

        picto.map((p, pIndex) => {
            const columns: PictoPixel[][] = [];
            p.map(value => {
                columns.push(value);
            });
            finalValue.push({
                name: (pIndex + 1).toString(),
                value: columns,
            });
        });

        return finalValue;
    };

    const handlePictoWidthChange = width => {
        if (changeWidthTimeout) {
            clearTimeout(changeWidthTimeout);
        }

        changeWidthTimeout = setTimeout(() => {
            let finalWidht = width;

            if (finalWidht > 100) finalWidht = 100;
            if (finalWidht < 0) finalWidht = 0;

            const pictos = [...picto];
            const currentPicto = pictos[selectedPictoIndex];

            const keys = Object.keys(currentPicto);

            keys.forEach(key => {
                const currentPictoValue = currentPicto[key];

                const diff = currentPictoValue.length - finalWidht;
                // > 0 means we need to remove columns
                if (diff > 0) {
                    currentPictoValue.splice(finalWidht, diff);
                }
                // < 0 means we need to add columns
                if (diff < 0) {
                    currentPictoValue.push(...Array(Math.abs(diff)).fill('0'));
                }
            });

            calcIsTooLarge();

            setPicto(pictos);
        }, 500);
    };

    const handlePictoHeightChange = height => {
        if (changeHeightTimeout) {
            clearTimeout(changeHeightTimeout);
        }

        changeHeightTimeout = setTimeout(() => {
            let finalHeight = height;

            if (finalHeight > 100) finalHeight = 100;
            if (finalHeight < 0) finalHeight = 0;

            const pictos = [...picto];
            const currentPicto = pictos[selectedPictoIndex];

            const diff = currentPicto.length - finalHeight;

            if (diff > 0) {
                currentPicto.splice(finalHeight, diff);
            }

            if (diff < 0) {
                for (let i = 0; i > diff; i--) {
                    const blackArray: PictoPixel[] = [];

                    if (currentPicto.length > 0) {
                        for (let j = 0; j < currentPicto[0].length; j++) {
                            blackArray.push({ r: 0, g: 0, b: 0 });
                        }
                    }

                    currentPicto.push(blackArray);
                }
                // currentPicto.push(
                //     ...Array(Math.abs(diff)).fill([...Array(currentPicto[0].length).fill({ r: 0, g: 0, b: 0 })])
                // );
            }

            calcIsTooLarge();

            setPicto(pictos);
        }, 500);
    };

    const calcIsTooLarge = async () => {
        let totalSize = 0;
        const MAX_SIZE = 32768;

        picto.forEach(p => {
            if (p.length > 0) {
                totalSize += p.length * p[0].length * 2 + 2;
            } else {
                totalSize += 2;
            }
        });

        setIsTooLarge(totalSize > MAX_SIZE);
    };

    const handleFullBlack = () => {
        const pictos = [...picto];
        const currentPicto = pictos[selectedPictoIndex];

        currentPicto.forEach(p => p.fill({ r: 0, g: 0, b: 0 }));

        setPicto(pictos);
    };

    const handleClick = (line: number, column: number) => {
        if (isEyeDropperMode) {
            setCurrentSelectedColor(picto[selectedPictoIndex][line][column]);
        } else {
            const pictos = [...picto];
            const currentPicto = pictos[selectedPictoIndex];

            currentPicto[line][column] = currentSelectedColor;

            setPicto(pictos);
        }
    };

    const handleSavePicto = () => {
        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/writePicto',
                {
                    tabId: [displayId],
                    pictoValue: {
                        picto: picto,
                        pictoType: type,
                    },
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'pixelArt.pictoEditor.startSave' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'pixelArt.pictoEditor.saveError' }));
            });
    };

    const handleSaveToManyPicto = (displays: number[]) => {
        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/writePicto',
                {
                    pictoValue: {
                        picto: picto,
                        pictoType: type,
                    },
                    tabId: displays,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'pixelArt.pictoEditor.startSave' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'pixelArt.pictoEditor.saveError' }));
            });
    };

    const handlePictoUpload = (receivedPicto: PixelArtPictoRGBImport) => {
        webSocketService.offEvent('lp-matrixDisplayV2:readPicto', handleWebsocket);

        if (receivedPicto.pictoType === type && receivedPicto.picto) {
            setPicto(receivedPicto.picto);
            Alert.success(intl.formatMessage({ id: 'pixelArt.pictoEditor.uploadSuccess' }), 5000);
        } else {
            Alert.error(intl.formatMessage({ id: 'pixelArt.pictoEditor.uploadError' }), 5000);
        }
    };

    const handleSizeChange = (size: number) => {
        history.push(`/ipCan/${ipCanId}/bus/${busNumber}/matrix-display/${displayId}/picto/${size}`);
    };

    const handleClickCopy = () => {
        setPictoToCopy(selectedPictoIndex);
    };

    const handleClickPaste = () => {
        const pictos = [...picto];

        pictos[selectedPictoIndex] = clone(picto[pictoToCopy]);

        setPicto(pictos);
    };

    const handleImageUpload = (receivedPicto: PictoPixel[][]) => {
        const pictos = [...picto];

        pictos[selectedPictoIndex] = receivedPicto;

        setPicto(pictos);
    };

    const handleDeletePicto = (index: number) => {
        const pictos = [...picto];

        if (selectedPictoIndex === index) {
            setSelectedPictoIndex(0);
        }

        pictos.splice(index, 1);

        setPicto(pictos);
    };

    const handleAddPicto = () => {
        const pictos = [...picto];

        pictos.push([[]]);

        setPicto(pictos);
    };

    return (
        <>
            {hasError && (
                <Message
                    className="margin-bottom-10"
                    type="error"
                    description={intl.formatMessage({ id: 'pixelArt.pictoEditor.error' })}
                    closable
                />
            )}

            {isLoading && <Message description={<Loader center content={<PictoLoader pictoNb={picto.length} />} />} />}

            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={4} className="padding-left-0 padding-right-0">
                    {picto.length > 0 && (
                        <LeftSideBar
                            setClickedPictoIndex={setSelectedPictoIndex}
                            pictos={generatePictos()}
                            selectedPictoIndex={selectedPictoIndex}
                            pictoToCopy={pictoToCopy}
                            handleClickDelete={handleDeletePicto}
                            handleClickAdd={handleAddPicto}
                        />
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    {picto.length > 0 && (
                        <PictoEditor
                            pictos={generatePictos()}
                            selectedPictoIndex={selectedPictoIndex}
                            handleClick={handleClick}
                        />
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={4} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    <RightSideBar
                        pictos={generatePictos()}
                        selectedPictoIndex={selectedPictoIndex}
                        isLoading={isLoading}
                        pictosFromApi={picto}
                        handlePictoWidthChange={handlePictoWidthChange}
                        handlePictoHeightChange={handlePictoHeightChange}
                        handlePictoUpload={handlePictoUpload}
                        readPictoFromAPI={readPictoFromAPI}
                        handleSizeChange={handleSizeChange}
                        handleSavePicto={handleSavePicto}
                        handleImageUpload={handleImageUpload}
                        handleSaveToManyPicto={handleSaveToManyPicto}
                        handleClickCopy={handleClickCopy}
                        handleClickPaste={handleClickPaste}
                        handleFullBlack={handleFullBlack}
                        isPasteButtonEnabled={pictoToCopy >= 0}
                        currentSelectedColor={currentSelectedColor}
                        setCurrentSelectedColor={setCurrentSelectedColor}
                        isEyeDropperMode={isEyeDropperMode}
                        setIsEyeDropperMode={setIsEyeDropperMode}
                        isTooLarge={isTooLarge}
                        display={lpMatrixDisplay}
                        ipcan={ipCanModule}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};

type RouteParams = {
    ipCanId: string;
    busNumber: string;
    displayId: string;
    type: string;
};

export interface PictoRGBValueInterface {
    name: string;
    value: PictoPixel[][];
}

export type RGBColorType = {
    r: number;
    g: number;
    b: number;
};
