import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import L from 'leaflet';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Alert, Button, Col, FlexboxGrid, Input, Modal } from 'rsuite';
import { interpret } from 'xstate';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import { CalibrationMachine } from '../Hooks/CalibrationMachine';

type Props = {
    levelId: number;
    reloadLevel: Function;
    validUpdate?: Function;
    cancelUpdate?: Function;
    show?: boolean;
    loading?: boolean;
    onCmChange?: Function;
    cm?: number;
    calibrationError?: boolean;
} & WrappedComponentProps;

type State = {
    current: Record<string, any>;
};
class CalibrationMenu extends React.Component<Props, State> {
    service: any;
    constructor(props) {
        super(props);

        this.state = {
            current: CalibrationMachine.initialState,
            // calibrationValue: 0,
        };

        this.service = interpret(
            CalibrationMachine.withContext({ ...CalibrationMachine.context, levelId: this.props.levelId })
        ).onTransition(current => this.setState({ current }));

        this.cancelUpdate = this.cancelUpdate.bind(this);
        this.validUpdate = this.validUpdate.bind(this);
        this.onCmChange = this.onCmChange.bind(this);
        this.generateError = this.generateError.bind(this);
        this.generateSuccess = this.generateSuccess.bind(this);
    }

    componentDidMount() {
        this.service.start();
    }

    componentWillUnmount() {
        this.service.stop();
    }

    cancelUpdate() {
        this.service.send('CANCEL_CALIBRATION');
    }

    validUpdate() {
        this.service.send('UPDATE_CALIBRATION');
    }

    lineCreated(event) {
        let precision = 1;
        let lat0 = event.layer._latlngs[0].lat * precision;
        let lat1 = event.layer._latlngs[1].lat * precision;
        let lng0 = event.layer._latlngs[0].lng * precision;
        let lng1 = event.layer._latlngs[1].lng * precision;

        let long = Math.floor(Math.sqrt(Math.pow(lat0 - lat1, 2) + Math.pow(lng0 - lng1, 2)));

        event.layer.remove();

        this.service.send('VALID_CALIBRATION', { calibration: long });

        return false;
    }

    onCmChange(value) {
        this.service.send('EDIT_CALIBRATION', { cm: value });
    }

    generateError() {
        Alert.error(this.props.intl.formatMessage({ id: 'map.calibration.error' }));
        this.service.send('CALIBRATION_ERROR');
    }

    generateSuccess() {
        Alert.success('OK');
        this.service.send('CALIBRATION_SUCCESS');
        this.props.reloadLevel();
    }

    render() {
        const { current } = this.state;

        current.matches('error') && this.generateError();
        current.matches('updated') && this.generateSuccess();

        return (
            <FeatureGroup>
                <EditControl
                    position="topright"
                    onCreated={event => this.lineCreated(event)}
                    draw={{
                        rectangle: false,
                        circle: false,
                        marker: false,
                        circlemarker: false,
                        polygon: false,
                        polyline: {
                            shapeOptions: {
                                color: 'red',
                                weight: 4,
                            },
                            icon: new L.DivIcon({
                                iconSize: new L.Point(10, 10),
                            }),
                            showLength: false,
                            guidelineDistance: 10,
                        },
                    }}
                    edit={{
                        remove: false,
                        edit: false,
                    }}
                />

                <CalibrationModal
                    cancelUpdate={this.cancelUpdate}
                    validUpdate={this.validUpdate}
                    calibrationValue={current.context.calibration}
                    show={current.context.calibrationModalOpen}
                    loading={current.context.loading}
                    cm={current.context.cm}
                    onCmChange={this.onCmChange}
                    calibrationError={current.context.calibrationError}
                />
            </FeatureGroup>
        );
    }
}

export default injectIntl(CalibrationMenu);

type CalibrationModalProps = {
    cancelUpdate: Function;
    validUpdate: Function;
    calibrationValue: number;
    show: boolean;
    loading: boolean;
    cm: number;
    onCmChange: Function;
    calibrationError: boolean;
};

class CalibrationModal extends React.Component<CalibrationModalProps> {
    render() {
        return (
            <Fragment>
                <SecuredFragment authorizedRoles={[rolesConstants.map.EDIT]}>
                    <Modal backdrop={'static'} show={this.props.show} onHide={() => this.props.cancelUpdate()}>
                        <Modal.Header>
                            <Modal.Title>
                                <FormattedMessage id="map.calibration.updateCalibration" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FlexboxGrid align="middle" justify="center">
                                <FlexboxGrid.Item componentClass={Col} xs={22}>
                                    <Input
                                        type="number"
                                        data-cy="calibration-number"
                                        value={this.props.cm.toString()}
                                        onChange={value => this.props.onCmChange(value)}
                                    />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={2}>
                                    {this.props.calibrationError ? (
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} size="lg" />
                                    ) : (
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} size="lg" />
                                    )}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                disabled={this.props.calibrationError}
                                loading={this.props.loading}
                                data-cy="calibration-valid"
                                onClick={() => this.props.validUpdate()}
                                appearance="primary">
                                <FormattedMessage id="map.calibration.updateCalibration.valid" />
                            </Button>
                            <Button
                                onClick={() => this.props.cancelUpdate()}
                                data-cy="calibration-cancel"
                                appearance="subtle">
                                <FormattedMessage id="map.calibration.updateCalibration.cancel" />
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </SecuredFragment>
            </Fragment>
        );
    }
}
