import React, { Fragment } from 'react';
import { RowRecord } from 'react-data-table-component';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid } from 'rsuite';
import Arrows from '../../../IpCanElementsPage/Elements/Arrow';
import Languages from '../../../IpCanElementsPage/Elements/Language';
import Text from '../../../IpCanElementsPage/Elements/Text';
import Types from '../../../IpCanElementsPage/Elements/Types';

type Props =  RowRecord;
export default class ReadRealDisplayExpand extends React.Component<Props> {
    render() {
        return (
            <Fragment>
                <FlexboxGrid style={{ margin: 15 }} justify="space-around">
                    {/* Config */}
                    <FlexboxGrid.Item>
                        <h5 data-cy="expandDisplay-config">
                            <FormattedMessage id="sensor.display.readRealSensor.config" />
                        </h5>

                        <div data-cy="expandDisplay-config-arrow">
                            <FormattedMessage id="sensor.display.readRealSensor.config.arrow" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">
                                <Arrows value={this.props.data.option.arrow} history />
                            </span>
                        </div>

                        <div data-cy="expandDisplay-config-languages">
                            <FormattedMessage id="sensor.display.readRealSensor.config.languages" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">
                                <Languages noTag value={this.props.data.option.lang} />
                            </span>
                        </div>

                        <div data-cy="expandDisplay-config-text">
                            <FormattedMessage id="sensor.display.readRealSensor.config.text" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">
                                <Text value={this.props.data.option.text} />
                            </span>
                        </div>

                        <div data-cy="expandDisplay-config-type" >
                            <FormattedMessage id="sensor.display.readRealSensor.config.type" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">
                                <Types noTag value={this.props.data.option.type} />
                            </span>
                        </div>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        <h5 data-cy="expandDisplay-informations">
                            <FormattedMessage id="sensor.display.readRealSensor.informations" />
                        </h5>

                        <div data-cy="expandDisplay-informations-version">
                            <FormattedMessage id="sensor.display.readRealSensor.informations.version" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.version}</span>
                        </div>

                        <div data-cy="expandDisplay-informations-modelName">
                            <FormattedMessage id="sensor.display.readRealSensor.informations.modelName" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.modelName}</span>
                        </div>

                        <div data-cy="expandDisplay-informations-manufacturer">
                            <FormattedMessage id="sensor.display.readRealSensor.informations.manufacturer" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.manufacturer}</span>
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Fragment>
        );
    }
}
