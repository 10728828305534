import moment from 'moment';

export function ipCanCreateHandler(ipCan) {
    return {
        autoSetScene: ipCan.autoSetScene,
        busConfiguration: [],
        config: ipCan.config,
        createdAt: moment(ipCan.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: ipCan.createdBy,
        creationInformations: `${ipCan.createdBy} - ${moment(ipCan.createdAt).format('DD/MM/YYYY HH:mm:ss')}`,
        gpioState: ipCan.gpioState,
        id: ipCan.id,
        ip: ipCan.ip,
        label: ipCan.label,
        mac: ipCan.mac,
        macStr: ipCan.macStr,
        nbDevicesOnBus: ipCan.nbDevicesOnBus ? ipCan.nbDevicesOnBus : 0,
        nbDisplays: ipCan.nbDisplays ? ipCan.nbDisplays : { onDB: 0, onModule: -1, online: -1 },
        nbReboot: ipCan.nbReboot ? ipCan.nbReboot : -1,
        nbSensors: ipCan.nbSensors ? ipCan.nbSensors : { onDB: 0, onModule: -1, online: -1 },
        online: ipCan.online,
        serialNumber: ipCan.serialNumber,
        serialNumberStr: ipCan.serialNumberStr,
        startAt: '...',
        updatedAt: moment(ipCan.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        updateInformations: `${ipCan.updatedBy} - ${moment(ipCan.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`,
        version: ipCan.version,
        lastOnlineStateDate: moment(ipCan.lastOnlineStateDate).isValid()
            ? moment(ipCan.lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss')
            : null,
    };
}
