import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';
import { IpCan } from './IpCan';

export default class IPCanTcm extends IpCan {
    /**
     * Enumerate elements in the given bus
     * @param {number} bus
     * @param {boolean} startStop
     * @param {number} startId
     * @param {number} stopId
     */
    sendEnumCommand(bus, startStop, startId, stopId) {
        axiosService.getAxios().put(
            'ipcanmodules/cmdEnumBus',
            {
                data: {
                    id: this.id,
                    bus: bus,
                    startStop,
                    startId,
                    stopId,
                },
            },
            {
                headers: authHeader(),
            }
        );
    }

    public getBusInformations() {
        let busInfo = this.busInfo;

        if (busInfo.length === 3) {
            busInfo.pop();
        }

        return busInfo;
    }

    getBusConfig() {
        const busConfig = this.config.map(conf => conf.getBusConfig());

        if (busConfig.length === 3) {
            busConfig.pop();
        }

        return busConfig;
    }

    setWebsocketInformations(data) {
        const {
            boardTemperature,
            bus,
            devicesOnBus0,
            devicesOnBus1,
            displays,
            externalVoltage,
            gpio,
            macAddress,
            mcuTemperature,
            nbReboot,
            sensors,
            syncState,
            msgFifoLength,
            onlineState,
        } = data;

        if (macAddress === this.mac) {
            this.setNbDisplays(displays);
            this.setNbSensors(sensors);
            this.setNbReboot(nbReboot);
            this.setDevicesOnBus(0, devicesOnBus0);
            this.setDevicesOnBus(1, devicesOnBus1);
            this.setSyncState(syncState);
            this.setFifoLength(msgFifoLength);
            this.setOnlineState(onlineState);

            this.physicalInformation.setPhysicalInformation(
                boardTemperature,
                mcuTemperature,
                externalVoltage,
                bus.slice(0, 2),
                gpio
            );
        }
    }
}
