import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Redirect } from 'react-router-dom';
import { FlexboxGrid, Panel } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = WrappedComponentProps;

type State = {
    loading: boolean;
    allCounters: number;
    parkingCounters: number;
    levelCounters: number;
    zoneCounters: number;
    vehicleCounters: number;
    redirect: boolean;
    data: Record<string, any>;
};
class CounterCard extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            allCounters: 0,
            parkingCounters: 0,
            levelCounters: 0,
            zoneCounters: 0,
            vehicleCounters: 0,
            redirect: false,
            data: {},
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get('/counters/states', { headers: authHeader() })
            .then(response => {
                this.setState({
                    loading: false,
                    allCounters: response.data.allCounters,
                    parkingCounters: response.data.parkingCounters,
                    levelCounters: response.data.levelCounters,
                    zoneCounters: response.data.zoneCounters,
                    vehicleCounters: response.data.vehicleCounters,
                    data: {
                        title: 'Compteurs',
                        datasets: [
                            {
                                data: [
                                    response.data.parkingCounters || 0,
                                    response.data.levelCounters || 0,
                                    response.data.zoneCounters || 0,
                                    response.data.vehicleCounters || 0,
                                ],
                                backgroundColor: ['#4c409e', '#1e77c5', '#129283', '#EA5455'],
                            },
                        ],
                        labels: [
                            this.props.intl.formatMessage({ id: 'dashboard.counter.parking' }),
                            this.props.intl.formatMessage({ id: 'dashboard.counter.level' }),
                            this.props.intl.formatMessage({ id: 'dashboard.counter.area' }),
                            this.props.intl.formatMessage({ id: 'dashboard.counter.passCount' }),
                        ],
                    },
                });
            });
    }

    handleClick() {
        this.setState({
            redirect: true,
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/counters" />;
        } else {
            return (
                <Loader show={this.state.loading}>
                    <Panel
                        data-cy="dashboard-counters-card"
                        className={'dashboard-card-header hoverable'}
                        header={this.props.intl.formatMessage({ id: 'dashboard.panel.counter' })}
                        shaded
                        onClick={this.handleClick}>
                        <FlexboxGrid justify="space-around" align="middle">
                            <FlexboxGrid.Item colspan={8}>
                                <div className="parking-legend-title">
                                    <FormattedMessage id="dashboard.counter.total" />
                                </div>
                                <div className="parking-legend-value">
                                    <span className="margin-right-5">{this.state.allCounters}</span>
                                    <FormattedMessage id="dashboard.counter.value" />
                                </div>

                                <div className="parking-legend-title margin-top-15" style={{ color: '#4c409e' }}>
                                    <FormattedMessage id="dashboard.counter.parking" />
                                </div>
                                <div className="parking-legend-value">
                                    <span className="margin-right-5">{this.state.parkingCounters}</span>
                                    <FormattedMessage id="dashboard.counter.value" />
                                </div>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item colspan={8}>
                                <Doughnut
                                    data={this.state.data}
                                    height={270}
                                    // width={donutSize}
                                    options={{
                                        rotation: 0.78 * Math.PI,
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        aspectRatio: 1,
                                        legend: {
                                            display: false,
                                        },
                                    }}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item colspan={8} className="text-right">
                                <div className="parking-legend-title" style={{ color: '#1e77c5' }}>
                                    <FormattedMessage id="dashboard.counter.level" />
                                </div>
                                <div className="parking-legend-value">
                                    <span className="margin-right-5" data-cy="dashboard-counter-level">
                                        {this.state.levelCounters}
                                    </span>
                                    <FormattedMessage id="dashboard.counter.value" />
                                </div>

                                <div className="parking-legend-title margin-top-15" style={{ color: '#129283' }}>
                                    <FormattedMessage id="dashboard.counter.zone" />
                                </div>
                                <div className="parking-legend-value">
                                    <span className="margin-right-5" data-cy="dashboard-counter-zone">
                                        {this.state.zoneCounters}
                                    </span>
                                    <FormattedMessage id="dashboard.counter.value" />
                                </div>

                                <div className="parking-legend-title margin-top-15" style={{ color: '#EA5455' }}>
                                    <FormattedMessage id="dashboard.counter.vehicleCounter" />
                                </div>
                                <div className="parking-legend-value">
                                    <span className="margin-right-5" data-cy="dashboard-counter-vehicleCounter">
                                        {this.state.vehicleCounters}
                                    </span>
                                    <FormattedMessage id="dashboard.counter.value" />
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                </Loader>
            );
        }
    }
}

export default injectIntl(CounterCard);
