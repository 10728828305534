export interface PixelArtFontWS {
    id: number;
    fontValue: {
        font: PixelArtFontWSValue[];
        fontSize: number;
        fontWasComplete: boolean;
    };
}

export interface PixelArtFontWSValue {
    [key: string]: string[];
}

export interface PixelArtFontImport {
    font: PixelArtFontWSValue[];
    fontSize: number;
}

export interface WriteFontProgress {
    id: number;
    bus: number;
    deviceId: number;
    ipcanmoduleId: number;
    writeProgress: {
        fontSize: number;
        size?: number;
        totalSize?: number;
        err?: string;
    };
}

export interface PixelArtPictoWS {
    id: number;
    pictos: {
        picto: string[][];
        pictoSize: number;
        pictoWasComplete: boolean;
    };
}

export interface PixelArtPictoImport {
    picto: string[][];
    pictoType: number;
}

export interface WritePictoProgress {
    id: number;
    bus: number;
    deviceId: number;
    ipcanmoduleId: number;
    writeProgress: {
        pictoType: number;
        size?: number;
        totalSize?: number;
        err?: string;
    };
}

export const getColumn = (fontSize: number, value: bigint): boolean[] => {
    if (value === BigInt(0)) return new Array(fontSize).fill(false);

    const maxValue = Math.pow(2, fontSize);

    if (value === BigInt(maxValue)) return new Array(fontSize).fill(true);

    let column: boolean[] = [];

    // Translate value to binary
    let binaryValue = value.toString(2).split('').reverse().join('');

    // Use binary value to create an array of boolean values
    for (let i = 0; i < fontSize; i++) {
        column.push(binaryValue[i] === '1');
    }

    return column;
};

export enum LP_MATRIXDISPLAYV2_PICTO_TYPE {
    PICTO_SIZE_16 = 1,
    PICTO_SIZE_32 = 2,
    PICTO_SIZE_48 = 3,
    PICTO_RGB = 10,
}
