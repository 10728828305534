import {
    faCheck,
    faCheckDouble,
    faCircle,
    faEdit,
    faExclamationTriangle,
    faPlug,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, Col, FlexboxGrid, InputGroup, List, Loader, Panel, SelectPicker, Tooltip, Whisper } from 'rsuite';
import { LPSensor } from '../../../../handlers/ipCanDevices/LPSensor';
import { sensorPlaceTypeHandler } from '../../../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import noImage from '../../../../style/assets/eye-slash-solid.svg';
import PanelHeader from '../../../Custom/PanelHeader';
import LPSensorTypeComparissionModal from '../../LPSensors/LPSensorTypeComparissionModal';

type Props = {
    sensor: LPSensor;
    showRealTimeGraph: boolean;
} & WrappedComponentProps;

type State = {
    isSensorLoading: boolean;
    hasError: boolean;
    sensorPlaceType: { label: string; image: any; imageType: string };
    error: string;
    placeTypeList: any[];
    isPlaceTypeEditMode: boolean;
    formValue: {
        sensorPlaceType: number;
    };
    sensorPlaceTypeSelector: sptSelector[];
    currentSensorPlaceType: any;
    isUpdating: boolean;
    hasUpdateError: boolean;
    updateError: string;
    isCompareSceneConfigOpen: boolean;
};

type sptSelector = {
    label: string;
    value: number;
};

class LPSensorInformationsCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isSensorLoading: true,
            hasError: false,
            sensorPlaceType: {
                label: '',
                image: {},
                imageType: '',
            },
            error: '',
            placeTypeList: [],
            isPlaceTypeEditMode: false,
            formValue: {
                sensorPlaceType: 1,
            },
            sensorPlaceTypeSelector: [],
            currentSensorPlaceType: {},
            isUpdating: false,
            hasUpdateError: false,
            updateError: '',
            isCompareSceneConfigOpen: false,
        };
    }

    componentDidMount = () => {
        axiosService
            .getAxios()
            .get(`/devices/lp-sensor/${this.props.sensor.id}`, {
                headers: authHeader(),
            })
            .then(sensorResponse => {
                const responseData = sensorResponse.data;

                axiosService
                    .getAxios()
                    .get('/sensor-place-type', {
                        headers: authHeader(),
                    })
                    .then(sensorPlaceTypeResponse => {
                        let sensorPlaceTypeSelector: sptSelector[] = [];
                        const sensorPlaceTypeResponseData: any[] = sensorPlaceTypeResponse.data.map(sensorPlaceType => {
                            const spt = sensorPlaceTypeHandler(sensorPlaceType);

                            sensorPlaceTypeSelector.push({
                                label: spt.label,
                                value: spt.id,
                            });

                            return spt;
                        });

                        const currentSensorPlaceType = sensorPlaceTypeResponseData.find(
                            spt => spt.id === responseData.sensorPlaceType.id
                        );

                        this.setState({
                            isSensorLoading: false,
                            formValue: {
                                sensorPlaceType: currentSensorPlaceType.id,
                            },
                            sensorPlaceType: {
                                label: currentSensorPlaceType.label,
                                image: currentSensorPlaceType.placeType.image,
                                imageType: currentSensorPlaceType.placeType.imageType,
                            },
                            placeTypeList: sensorPlaceTypeResponseData,
                            sensorPlaceTypeSelector,
                            currentSensorPlaceType,
                        });
                    });
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                    isSensorLoading: false,
                });
            });
    };

    setEditMode = () => {
        this.setState({
            isPlaceTypeEditMode: true,
        });
    };

    onChange = formValue => {
        this.setState({
            formValue,
        });
    };

    validEdit = () => {
        this.setState({
            isUpdating: true,
            hasUpdateError: false,
            updateError: '',
        });

        axiosService
            .getAxios()
            .put(
                '/devices/lp-sensor/updateSensorPlaceType',
                {
                    id: this.props.sensor.id,
                    id_sensorPlaceType: this.state.formValue.sensorPlaceType,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                const sensorPlaceType = this.state.placeTypeList.find(
                    pt => pt.id === this.state.formValue.sensorPlaceType
                );
                this.setState({
                    currentSensorPlaceType: sensorPlaceType,
                    sensorPlaceType: {
                        label: sensorPlaceType.label,
                        image: sensorPlaceType.placeType.image,
                        imageType: sensorPlaceType.placeType.imageType,
                    },
                    isPlaceTypeEditMode: false,
                });
            })
            .catch(err => {
                this.setState({
                    hasUpdateError: true,
                    updateError: err,
                });
            })
            .finally(() => {
                this.setState({
                    isUpdating: false,
                });
            });
    };

    cancelEdit = () => {
        this.setState({
            isPlaceTypeEditMode: false,
            formValue: {
                sensorPlaceType: this.state.currentSensorPlaceType,
            },
        });
    };

    toggleCompareSceneConfig = () => {
        this.setState({
            isCompareSceneConfigOpen: !this.state.isCompareSceneConfigOpen,
        });
    };

    render = () => {
        const showPlaceTypeView =
            !this.state.isSensorLoading && !this.state.hasError && !this.state.isPlaceTypeEditMode;

        return (
            <Fragment>
                {this.state.isCompareSceneConfigOpen && (
                    <LPSensorTypeComparissionModal
                        show={this.state.isCompareSceneConfigOpen}
                        onHide={this.toggleCompareSceneConfig}
                        sensorId={this.props.sensor.id}
                        currentSensorPlaceType={this.state.currentSensorPlaceType}
                    />
                )}
                <Panel
                    className="panel-small"
                    shaded
                    bordered
                    bodyFill
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({
                                id: 'ipCanDevices.LPSensor.informations',
                            })}
                            buttons={[
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <FormattedMessage id="lpSensor.compareMode.informations" />
                                        </Tooltip>
                                    }>
                                    <Button
                                        data-cy="ipCanDevices-lpSensor-compareMode-informations"
                                        color="orange"
                                        onClick={this.toggleCompareSceneConfig}
                                        size="sm"
                                        disabled={this.state.isSensorLoading || !this.props.sensor.online}>
                                        <FontAwesomeIcon icon={faCheckDouble} />
                                    </Button>
                                </Whisper>,
                            ]}
                        />
                    }>
                    <List hover size={this.props.showRealTimeGraph ? 'sm' : 'md'} >
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                                    <FormattedMessage id="ipCanDevices.LPSensor.informations.online" />
                                </FlexboxGrid.Item>
                                <div data-cy="ipcan-lpSensor-information-online">
                                    <FlexboxGrid.Item>
                                        <FontAwesomeIcon
                                            icon={faPlug}
                                            style={{
                                                color: this.props.sensor.online ? 'green' : 'red',
                                            }}
                                        />
                                    </FlexboxGrid.Item>
                                </div>
                            </FlexboxGrid>
                        </List.Item>
                        {/* --- */}
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item
                                    style={{ fontWeight: 'bold' }}
                                    componentClass={Col}
                                    xs={8}
                                    data-cy="ipCanDevices-LPSensor-informations-placeType">
                                    <FormattedMessage id="ipCanDevices.LPSensor.informations.placeType" />
                                </FlexboxGrid.Item>
                                {!this.state.isPlaceTypeEditMode ? (
                                    <div data-cy="ipcan-lpSensor-information-placeType">
                                        <FlexboxGrid.Item>
                                            {this.state.isSensorLoading && <Loader />}

                                            {this.state.hasError && (
                                                <Whisper
                                                    placement="auto"
                                                    trigger="hover"
                                                    speaker={
                                                        <Tooltip>
                                                            <FormattedMessage
                                                                id="ipCanDevices.LPSensor.informations.fetchPlaceTypeError"
                                                                values={{ error: this.state.error }}
                                                            />
                                                        </Tooltip>
                                                    }>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} color="orange" />
                                                </Whisper>
                                            )}

                                            {showPlaceTypeView && (
                                                <div>
                                                    <span className="margin-right-5">
                                                        {this.state.currentSensorPlaceType.label}
                                                    </span>
                                                    {this.state.sensorPlaceType.image ? (
                                                        <img
                                                            data-cy="lpSensor-img-placeType"
                                                            alt={`${this.state.sensorPlaceType.label}-logo`}
                                                            src={`data:image/${this.state.sensorPlaceType.imageType};base64, ${this.state.sensorPlaceType.image}`}
                                                            width="30"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={noImage}
                                                            style={{
                                                                width: 30,
                                                                backgroundColor: 'white',
                                                                lineHeight: 1,
                                                            }}
                                                            alt="empty"
                                                        />
                                                    )}
                                                    <span>
                                                        <Button
                                                            data-cy="ipcan-element-lpSensor-updateTypeSensor"
                                                            appearance="link"
                                                            size="sm"
                                                            onClick={this.setEditMode}>
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Button>
                                                    </span>
                                                </div>
                                            )}
                                        </FlexboxGrid.Item>
                                    </div>
                                ) : (
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        {this.state.isPlaceTypeEditMode && (
                                            <InputGroup size="sm" className="form-margin">
                                                <SelectPicker
                                                    data-cy="ipcan-element-lpSensor-selectSensor"
                                                    name="sensorPlaceType"
                                                    data={this.state.sensorPlaceTypeSelector}
                                                    defaultValue={this.state.formValue.sensorPlaceType}
                                                    placement="auto"
                                                    cleanable={false}
                                                    size="sm"
                                                    onChange={value =>
                                                        this.setState({ formValue: { sensorPlaceType: value } })
                                                    }
                                                    renderMenuItem={(label, item) => {
                                                        return (
                                                            <div data-cy={`lpSensor-selectSensor-value-${item.value}`}>
                                                                {label}
                                                            </div>
                                                        );
                                                    }}
                                                />
                                                <InputGroup.Button
                                                    data-cy="ipcan-element-lpSensor-updateTypeSensor-valid"
                                                    color="green"
                                                    onClick={this.validEdit}
                                                    size="sm"
                                                    loading={this.state.isUpdating}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </InputGroup.Button>
                                                <InputGroup.Button
                                                    data-cy="ipcan-element-lpSensor-updateTypeSensor-cancel"
                                                    color="red"
                                                    onClick={this.cancelEdit}
                                                    size="sm"
                                                    disabled={this.state.isUpdating}>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </InputGroup.Button>
                                            </InputGroup>
                                        )}
                                    </FlexboxGrid.Item>
                                )}
                            </FlexboxGrid>
                        </List.Item>
                        {/* --- */}
                        {!this.props.showRealTimeGraph && (
                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                                        <FormattedMessage id="ipCanDevices.LPSensor.informations.deviceId" />
                                    </FlexboxGrid.Item>
                                    <div data-cy="ipcan-lpSensor-information-deviceId">
                                        <FlexboxGrid.Item>{this.props.sensor.deviceId}</FlexboxGrid.Item>
                                    </div>
                                </FlexboxGrid>
                            </List.Item>
                        )}
                        {/* --- */}
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                                    <FormattedMessage id="ipCanDevices.LPSensor.informations.modelNameAndVersion" />
                                </FlexboxGrid.Item>
                                <div data-cy="ipcan-lpSensor-information-model-version">
                                    <FlexboxGrid.Item>
                                        {this.props.sensor.informations.modelName} -{' '}
                                        {this.props.sensor.informations.softwareVersion}
                                    </FlexboxGrid.Item>
                                </div>
                            </FlexboxGrid>
                        </List.Item>
                        {/* --- */}
                        {!this.props.showRealTimeGraph && (
                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                                        <FormattedMessage id="ipCanDevices.LPSensor.informations.uniqueId" />
                                    </FlexboxGrid.Item>
                                    <div data-cy="ipcan-lpSensor-information-uniqueId">
                                        <FlexboxGrid.Item>
                                            {this.props.sensor.informations.uniqueId.join('.')}
                                        </FlexboxGrid.Item>
                                    </div>
                                </FlexboxGrid>
                            </List.Item>
                        )}
                        {/* --- */}
                        {!this.props.showRealTimeGraph && (
                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                                        <FormattedMessage id="ipCanDevices.LPSensor.informations.voltage" />
                                    </FlexboxGrid.Item>
                                    <div data-cy="ipcan-lpSensor-information-voltage">
                                        <FlexboxGrid.Item>
                                            {this.props.sensor.informations.voltage / 10} V
                                        </FlexboxGrid.Item>
                                    </div>
                                </FlexboxGrid>
                            </List.Item>
                        )}
                        {/* --- */}
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item
                                    style={{ fontWeight: 'bold' }}
                                    componentClass={Col}
                                    xs={8}
                                    data-cy="ipCanDevices-LPSensor-informations-detectionState">
                                    <FormattedMessage id="ipCanDevices.LPSensor.informations.detectionState" />
                                </FlexboxGrid.Item>
                                <div data-cy="ipcan-lpSensor-information-detectionState">
                                    <FlexboxGrid.Item>
                                        {this.props.sensor.detectionState === 1 && (
                                            <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }} />
                                        )}
                                        {this.props.sensor.detectionState === 2 && (
                                            <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} />
                                        )}
                                        {this.props.sensor.detectionState === 3 && (
                                            <FontAwesomeIcon icon={faCircle} style={{ color: 'orange' }} />
                                        )}
                                        {this.props.sensor.detectionState === 4 && (
                                            <FontAwesomeIcon icon={faCircle} style={{ color: 'purple' }} />
                                        )}
                                        {this.props.sensor.detectionState === 0 ||
                                            (this.props.sensor.detectionState >= 5 && (
                                                <FontAwesomeIcon icon={faCircle} style={{ color: 'black' }} />
                                            ))}{' '}
                                        - {this.props.sensor.detectionStateTimeFormatted}
                                    </FlexboxGrid.Item>
                                </div>
                            </FlexboxGrid>
                        </List.Item>
                        {/* --- */}
                        {!this.props.showRealTimeGraph && (
                            <Fragment>
                                <List.Item className="panel-list">
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={8}
                                            data-cy="ipCanDevices-LPSensor-informations-creation">
                                            <FormattedMessage id="ipCanDevices.LPSensor.informations.creation" />
                                        </FlexboxGrid.Item>
                                        <div data-cy="ipcan-lpSensor-information-creation">
                                            <FlexboxGrid.Item>{this.props.sensor.creation}</FlexboxGrid.Item>
                                        </div>
                                    </FlexboxGrid>
                                </List.Item>
                                {/* --- */}
                                <List.Item className="panel-list">
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={8}
                                            data-cy="ipCanDevices-LPSensor-informations-update">
                                            <FormattedMessage id="ipCanDevices.LPSensor.informations.update" />
                                        </FlexboxGrid.Item>
                                        <div data-cy="ipcan-lpSensor-information-update">
                                            <FlexboxGrid.Item>{this.props.sensor.update}</FlexboxGrid.Item>
                                        </div>
                                    </FlexboxGrid>
                                </List.Item>
                            </Fragment>
                        )}
                    </List>
                </Panel>
            </Fragment>
        );
    };
}

export default injectIntl(LPSensorInformationsCard);
