import { FormattedMessage } from 'react-intl';

export const placeCameraUpdateConfigDesc = option => {
    return (
        <div className="padding-top-5 padding-bottom-5">
            <div>
                <span className="bold">
                    <FormattedMessage id="history.placeCameraUpdateConfigDesc.label" />
                </span>{' '}
                - <span>{option.label}</span>
            </div>
            <div>
                <span className="bold">
                    <FormattedMessage id="history.placeCameraUpdateConfigDesc.sensorPlaceType" />
                </span>{' '}
                - <span>{option.sensorPlaceType}</span>
            </div>
            <div>
                <span className="bold">
                    <FormattedMessage id="history.placeCameraUpdateConfigDesc.overstayTime" />
                </span>{' '}
                - <span>{option.overstayTime}</span>
            </div>
        </div>
    );
};
