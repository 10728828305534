import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function UpdateVirtualDisplayConfig(option) {
    const {
        id,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        posId,
        state,
        startX,
        startY,
        sizeX,
        sizeY,
        font,
        language,
        arrowSpeed,
        textSpeed,
        steps,
        backgroundColor,
        matrixDisplay,
    } = option;

    return (
        <div>
            <div data-cy="lpMatrixDisplayV2-historic-updatedAt">
                <FormattedMessage id="lpMatrixDisplayV2.history.updatedAt" />
                <span className="margin-left-5 margin-right-5">-</span>
                {updatedAt}
            </div>
            <div data-cy="lpMatrixDisplayV2-historic-startX-Y">
                <FormattedMessage id="lpMatrixDisplayV2.history.startX-Y" />
                <span className="margin-left-5 margin-right-5">-</span>
                {startX} - {startY}
            </div>
            <div data-cy="lpMatrixDisplayV2-historic-sizeX-Y">
                <FormattedMessage id="lpMatrixDisplayV2.history.sizeX-Y" />
                <span className="margin-left-5 margin-right-5">-</span>
                {sizeX} - {sizeY}
            </div>
            <div data-cy="lpMatrixDisplayV2-historic-language">
                <FormattedMessage id="lpMatrixDisplayV2.history.language" />
                <span className="margin-left-5 margin-right-5">-</span>
                {language}
            </div>
            <div data-cy="lpMatrixDisplayV2-historic-arrowSpeed">
                <FormattedMessage id="lpMatrixDisplayV2.history.arrowSpeed" />
                <span className="margin-left-5 margin-right-5">-</span>
                {arrowSpeed}
            </div>
            <div data-cy="lpMatrixDisplayV2-historic-backgroundRGB">
                <FormattedMessage id="lpMatrixDisplayV2.history.backgroundRGB" />
                <span className="margin-left-5 margin-right-5">-</span>
                r={backgroundColor.red} g={backgroundColor.green} b={backgroundColor.blue}
            </div>
            <div data-cy="lpMatrixDisplayV2-historic-matrixDisplayID">
                <FormattedMessage id="lpMatrixDisplayV2.history.matrixDisplayID" />
                <span className="margin-left-5 margin-right-5">-</span>
                {matrixDisplay.id}
            </div>
        </div>
    );
}
