import DefineOneSensorId from './Option/defineOneSensorId';
import DefineSensorId from './Option/defineSensorId';
import LPCmdBus from './Option/LPCmdBus';
import NoOptions from './Option/noOptions';
import resetBusCounterError from './Option/resetBusCounterError';
import SetGPIO from './Option/setGPIO';
import StartEnumeration from './Option/startEnumeration';
import TcmCmdBus from './Option/tcmCmdBus';
import TestBus from './Option/testBus';
import UpdateBusConfig from './Option/UpdateBusConfig';
import UpdateIpCanConfig from './Option/UpdateIpCanConfig';

export default function IpCanOptionsCell(description, options) {
    switch (description) {
        case 'set gpio':
            return SetGPIO(options);
        case 'test bus':
            return TestBus(options);
        case 'start enumeration':
            return StartEnumeration(options);
        case 'tcm cmd bus':
            return TcmCmdBus(options);
        case 'lp cmd bus':
            return LPCmdBus(options);
        case 'update bus config':
            return UpdateBusConfig();
        case 'define sensor id':
            return DefineSensorId(options);
        case 'update config':
            return UpdateIpCanConfig(options);
        case 'reset bus counter error':
            return resetBusCounterError(options);
        case 'define one sensor id':
            return DefineOneSensorId(options);
        default:
            return NoOptions();
    }
}
