import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Whisper } from 'rsuite';

export default function placeCameraUpdateHyperParamConfig() {
    return (
        <div data-cy="history-placeCameraUpdateHyperParamConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.placeCameraUpdateHyperParamConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faTools} style={{ color: 'red' }} size="lg" />
            </Whisper>
        </div>
    );
}
