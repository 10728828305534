import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid, List, Loader, Tag } from 'rsuite';
import PlaceCamera, { PlaceCameraState } from '../../../../handlers/Camera/PlaceCamera';
import { sensorPlaceTypeHandler } from '../../../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    place: PlaceCamera;
};

export const CameraPlaceInformationViewMode = (props: Props) => {
    const [currentSensorPlaceType, setCurrentSensorPlaceType] = useState<any>(null);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/sensor-place-type', { headers: authHeader() })
            .then(response => {
                const sensorsPlaceType = response.data.map(sensorPlaceType => sensorPlaceTypeHandler(sensorPlaceType));

                setCurrentSensorPlaceType(
                    sensorsPlaceType.find(
                        sensorPlaceType => sensorPlaceType.id === props.place.getSensorPlaceType()?.getId()
                    )
                );
            });
    }, []);

    return (
        <List>
            {/* LABEL */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.place.information.name" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.place.getLabel()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* CREATION */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.place.information.creation" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.place.getCreation()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* UPDATE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.place.information.update" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.place.getUpdate()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* PLACE TYPE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.place.information.placeType" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {currentSensorPlaceType ? (
                            <>
                                <img
                                    alt={`${currentSensorPlaceType?.placeType.label}-logo`}
                                    src={`data:image/${currentSensorPlaceType?.placeType.imageType};base64, ${currentSensorPlaceType?.placeType.image}`}
                                    height="20"
                                />
                                <span className="margin-left-5">{currentSensorPlaceType?.placeType.label}</span>
                            </>
                        ) : (
                            <Loader />
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* OVERSTAY TIME */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.place.information.overstayTime" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{`${props.place.getOverstayTime()} s`}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* STATE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.place.information.state" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {props.place.getState() === PlaceCameraState.FREE && (
                            <Tag color="green">
                                <FormattedMessage id="cameras.cameraPlace.state.free" />
                            </Tag>
                        )}
                        {props.place.getState() === PlaceCameraState.OCCUPIED && (
                            <Tag color="red">
                                <FormattedMessage id="cameras.cameraPlace.state.occupied" />
                            </Tag>
                        )}
                        {props.place.getState() === PlaceCameraState.OVERSTAY && (
                            <Tag color="orange">
                                <FormattedMessage id="cameras.cameraPlace.state.overstay" />
                            </Tag>
                        )}
                        {props.place.getState() === PlaceCameraState.UNKNOWN && (
                            <Tag color="yellow">
                                <FormattedMessage id="cameras.cameraPlace.state.unknown" />
                            </Tag>
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* DETECTION STATE TIME */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.place.information.detectionStateTime" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.place.getDetectionStateTime()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* IS FORCE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.place.information.isForce" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon
                            icon={props.place.getIsForce() ? faCheck : faTimes}
                            color={props.place.getIsForce() ? 'green' : 'red'}
                            size="lg"
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            {props.place.getIsForce() && (
                <>
                    {/* FORCE TYPE */}
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item className="bold">
                                <FormattedMessage id="camera.place.information.forceType" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                {props.place.getForceType() === PlaceCameraState.FREE && (
                                    <Tag color="green">
                                        <FormattedMessage id="cameras.cameraPlace.force.free" />
                                    </Tag>
                                )}
                                {props.place.getForceType() === PlaceCameraState.OCCUPIED && (
                                    <Tag color="red">
                                        <FormattedMessage id="cameras.cameraPlace.force.occupied" />
                                    </Tag>
                                )}
                                {props.place.getForceType() === PlaceCameraState.OVERSTAY && (
                                    <Tag color="violet">
                                        <FormattedMessage id="cameras.cameraPlace.force.overstay" />
                                    </Tag>
                                )}
                                {props.place.getForceType() === PlaceCameraState.UNKNOWN && (
                                    <Tag color="blue">
                                        <FormattedMessage id="cameras.cameraPlace.force.unknown" />
                                    </Tag>
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    {/* END FORCE TIME */}
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item className="bold">
                                <FormattedMessage id="camera.place.information.endForceTime" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>{props.place.getEndForceTime()}</FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                </>
            )}
        </List>
    );
};
