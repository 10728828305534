import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';

export default function TestBus(options) {
    const { bus, startStop, timing } = options;

    return (
        <Fragment>
            <FormattedMessage id="controller.history.testBus.options.bus" />
            <Tag className="margin-left-5" color="violet">
                {bus}
            </Tag>
            <span className="margin-left-5">-</span>
            <FontAwesomeIcon
                data-cy="historic-testBus-etat"
                className="margin-left-5"
                icon={startStop ? faPlay : faStop}
                color={startStop ? 'green' : 'red'}
                size="lg"
            />
            <span data-cy="historic-testBus-time" className="margin-left-5">{`(${timing})`}</span>
        </Fragment>
    );
}
