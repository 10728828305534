import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';

export default class FlowNode {
    protected _id: string;
    protected _display: LPDisplay;

    constructor(id: string, display: LPDisplay) {
        this._id = id;
        this._display = display;
    }

    public getId(): string {
        return this._id;
    }

    public getDisplay(): LPDisplay {
        return this._display;
    }
}
