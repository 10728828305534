import React from 'react';
import Counter from '../../../../handlers/Counter/Counter';
import { SensorPlaceType } from '../../../../handlers/sensorPlaceType/SensorPlaceType';

export default function CountersRow(
    counterConfig: { counters: number[]; sensorPlaceType: [number[]] },
    counters: Counter[],
    sensorPlaceTypes: SensorPlaceType[]
) {
    return (
        <div>
            {counterConfig.counters.map((counter, index) => {
                const currentCounter = counters.find(c => c.id === counter);
                const currentCounterSensorPlaceType = counterConfig.sensorPlaceType.map(currentSensorPlaceType => {
                    return currentSensorPlaceType.map(spt =>
                        sensorPlaceTypes.find(fetchedSensorsPlaceTypes => {
                            return fetchedSensorsPlaceTypes.getId() === spt;
                        })
                    );
                });

                if (currentCounter)
                    return (
                        <div key={`counter-${index}`} className="margin-bottom-5">
                            {currentCounter.label} -{' '}
                            {currentCounterSensorPlaceType[index].map(placeType => (
                                <img
                                    src={`data:image/${placeType
                                        ?.getPlaceType()
                                        ?.getImageType()};base64, ${placeType?.getPlaceType()?.generateImage()}`}
                                    height={20}
                                    key={`counter-image-${placeType?.getId()}`}
                                    className="margin-right-5"
                                />
                            ))}
                        </div>
                    );
            })}
        </div>
    );
}
