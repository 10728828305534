import { FormattedMessage } from 'react-intl';
import { Tag, TagGroup } from 'rsuite';

type DailyObject = {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thirsday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
};

export default function DailyCell(id: number, daily: DailyObject) {
    return (
        <TagGroup data-cy="event-list-daily">
            {Object.keys(daily).map(day => {
                if (daily[day]) {
                    return (
                        <Tag color="violet" key={`${id}-${day}`}>
                            <FormattedMessage id={`event.daily.day.${day}`} />
                        </Tag>
                    );
                }
            })}
        </TagGroup>
    );
}
