import { TriggerInterface } from '../../../../../handlers/lpDisplays/LPDisplayTopology';
import FlowEdge from './FlowEdge';

export default class FlowEdgeTrigger extends FlowEdge {
    private _trigger: TriggerInterface;
    private _topologyIndexSource: number;
    private _topologyIndexTarget: number;
    private _topologyTargetNumber: number;

    constructor(
        source: string,
        sourceHandle: string,
        target: string,
        targetHandle: string,
        trigger: TriggerInterface,
        topologyIndexSource: number,
        topologyIndexTarget: number,
        topologyTargetNumber: number,
        color: string
    ) {
        super(source, sourceHandle, target, targetHandle, color);

        this._trigger = trigger;
        this._topologyIndexSource = topologyIndexSource;
        this._topologyIndexTarget = topologyIndexTarget;
        this._topologyTargetNumber = topologyTargetNumber;
    }

    public toRender = () => {
        return {
            id: `trigger-topology-${this._topologyIndexSource}-${this._topologyIndexTarget}-${this._topologyTargetNumber}`,
            source: this._source,
            sourceHandle: this._sourceHandle,
            target: this._target,
            targetHandle: this._targetHandle,
            type: 'edgeTrigger',
            data: {
                trigger: this._trigger,
            },
            style: {
                stroke: this._color,
                strokeWidth: 3,
            },
        };
    };
}
