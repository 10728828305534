import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Button, FlexboxGrid, List, Panel } from 'rsuite';
import { Parking } from '../../../../../handlers/parking/Parking';
import { User } from '../../../../../handlers/profile/User';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import SecuredFragment from '../../../../Auth/SecuredFragment';
import PanelHeader from '../../../../Custom/PanelHeader';
import { EditUserParkings } from '../Modal/EditUserParkings';

type Props = {
    user: User;
    reloadUsers: () => void;
};

export const UserParkings = (props: Props) => {
    const intl = useIntl();

    const [parkings, setParkings] = React.useState<Parking[]>([]);
    const [isEditModalOpen, setIsEditModalOpen] = React.useState<boolean>(false);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/parkings', { headers: authHeader() })
            .then(response => {
                setParkings(response.data.map(parking => new Parking(parking)));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'user.fetchParkings.error' }));
            });
    }, [isEditModalOpen]);

    return (
        <>
            <EditUserParkings
                isOpen={isEditModalOpen}
                user={props.user}
                parkings={parkings}
                reloadUsers={props.reloadUsers}
                onClose={() => setIsEditModalOpen(false)}
            />

            <Panel
                shaded
                bordered
                bodyFill
                className="panel-small"
                data-cy="users-parkings"
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'user.parkings' })}
                        buttons={[
                            <SecuredFragment authorizedRoles={[]}>
                                <Button
                                    data-cy="userParkings-editButton"
                                    onClick={() => setIsEditModalOpen(true)}
                                    appearance="primary"
                                    size="sm">
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            </SecuredFragment>,
                        ]}
                    />
                }>
                <List hover>
                    {parkings.map(parking => {
                        if (!props.user.getParkingList().includes(parking.getId())) {
                            return (
                                <List.Item className="padding-left-10 padding-right-10" key={parking.getId()}>
                                    <FlexboxGrid
                                        className="padding-left-0 padding-right-0"
                                        align="middle"
                                        justify="space-between">
                                        <FlexboxGrid.Item data-cy="userParkings-title" className="bold">
                                            {parking.getName()}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            <FontAwesomeIcon
                                                data-cy="userParkings-check"
                                                icon={faCheck}
                                                color={'green'}
                                                size="2x"
                                            />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <p className="text-muted" style={{ paddingLeft: 2, paddingRight: 2 }}>
                                        {parking.getDescription()}
                                    </p>
                                </List.Item>
                            );
                        } else {
                            return null;
                        }
                    })}
                </List>
            </Panel>
        </>
    );
};
