import { Handle, NodeComponentProps, Position } from 'react-flow-renderer';
import { useIntl } from 'react-intl';
import { FlexboxGrid, Tag } from 'rsuite';
import { LPMatrixDisplayV2VirtualDisplayForceType } from '../../LPMatrixDisplayFactory';
import { LPMatrixDisplayChartStepNodeType } from '../LPMatrixDisplayChartStepNode';
import { LPMatrixDisplayForceWidget } from './LPMatrixDisplayForceWidget';

type Props = NodeComponentProps<LPMatrixDisplayChartStepNodeType>;

export const LPMatrixDisplayChartStepNodeComponent = (props: Props) => {
    const intl = useIntl();

    const isBgGray =
        props.data.virtualDisplayForceMode?.mode === LPMatrixDisplayV2VirtualDisplayForceType.OVERRIDE &&
        props.data.step.getStepOrder() !== 999;

    const isCounterStep = props.data.step.getMode() >= 101 && props.data.step.getMode() <= 132;

    const isFirstAndLast = props.data.isFirst && props.data.isLast;

    const displaySourceStepToStep =
        props.data.virtualDisplayForceMode?.mode !== LPMatrixDisplayV2VirtualDisplayForceType.OVERRIDE &&
        !isFirstAndLast;

    return (
        <div
            className={`matrix-display-node ${props.data.isForce ? 'step-forced' : ''} ${
                !isCounterStep ? 'step-not-counter' : ''
            }`}
            style={{ backgroundColor: isBgGray ? 'grey' : 'white' }}>
            <h5 className="text-center margin-bottom-10">
                {props.data.label}
                {props.data.step.getIsForcedStep() && (
                    <Tag color="orange" className="margin-left-5">
                        {intl.formatMessage({ id: 'lpMatrixDisplayV2.virtualDisplay.step.forced' })}
                    </Tag>
                )}
            </h5>

            {props.data.isFirst && (
                <>
                    <Handle
                        id="target-virtual-display-step"
                        type="source"
                        position={Position.Left}
                        style={{ background: '#555' }}
                        isConnectable={false}
                    />
                    {displaySourceStepToStep && (
                        <Handle
                            id="target-back-step-to-step"
                            type="source"
                            position={Position.Right}
                            style={{ background: '#555', top: '70%' }}
                            isConnectable={false}
                        />
                    )}
                </>
            )}

            {displaySourceStepToStep && (
                <Handle
                    id="source-step-to-step"
                    type="source"
                    position={props.data.isLast ? Position.Left : Position.Right}
                    style={{ background: '#555', top: props.data.isLast ? '70%' : '30%' }}
                    isConnectable={false}
                />
            )}

            {!isFirstAndLast && props.data.isLast && displaySourceStepToStep && (
                <Handle
                    id="source-back-step-to-step"
                    type="source"
                    position={Position.Left}
                    style={{ background: '#555', top: '70%' }}
                    isConnectable={false}
                />
            )}

            {!isFirstAndLast && !props.data.isFirst && displaySourceStepToStep && (
                <Handle
                    id="target-step-to-step"
                    type="target"
                    position={Position.Left}
                    style={{ background: '#555', top: '30%' }}
                    isConnectable={false}
                />
            )}

            <FlexboxGrid align="middle" justify="center">
                <FlexboxGrid.Item>
                    <LPMatrixDisplayForceWidget
                        backgroundColor={props.data.backgroundColor || '#000'}
                        display={props.data.display}
                        step={props.data.step}
                        isForce={props.data.isForce}
                        forceMode={props.data.forceMode}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};
