import { faArrowRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ButtonGroup, Modal, Tag } from 'rsuite';
import { DisplayPreviewInformation } from '../../../../../../handlers/lpDisplays/LPDisplay';
import { TriggerInterface } from '../../../../../../handlers/lpDisplays/LPDisplayTopology';
import { ActionObserver, ActionObserverEvent } from '../../../handlers/Observers/ActionObserver';

type Props = {
    trigger?: TriggerInterface;
    topologyIndex: number;
    triggerIndex?: number;
    show: boolean;
    onHide: Function;
    display: DisplayPreviewInformation;
};

export const DeleteTriggerModal = (props: Props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const intl = useIntl();

    const generateTriggerMode = (mode: number, source: number, value: number): string | null => {
        switch (mode) {
            case 1: {
                return `${intl.formatMessage({ id: 'ipCanDevices.lpDisplays.topology.sourceTime' })} (${value}s)`;
            }
            case 2: {
                return `${intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.topology.sourceCounterMin',
                })} ${source} < ${value}`;
            }
            case 3: {
                return `${intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.topology.sourceCounterMax',
                })} ${source} > ${value}`;
            }
            case 4: {
                return `${intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.topology.sourceGpio',
                })} ${value + 1}`;
            }
            case 5: {
                return `${intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.topology.sourceInvertGPIO',
                })} ${value + 1}`;
            }
            default:
                return null;
        }
    };

    const handleDeleteTrigger = () => {
        if (typeof props.triggerIndex !== 'undefined') {
            ActionObserver.emit(ActionObserverEvent.DELETE_TOPOLOGY_TRIGGER, {
                topologyIndex: props.topologyIndex,
                triggerIndex: props.triggerIndex,
            });

            props.onHide();

            // const topologies = display.getTopologyConfig().getTopologys();

            // topologies[props.topologyIndex].triggers[props.triggerIndex] = {
            //     mode: 0,
            //     next: 0,
            //     source: 0,
            //     value: 0,
            // };

            // axiosService
            //     .getAxios()
            //     .put(
            //         '/devices/lp-matrixdisplays-v2/updateTopologysConfig',
            //         {
            //             id: display.getId(),
            //             topology: { topologys: topologies },
            //         },
            //         {
            //             headers: authHeader(),
            //         }
            //     )
            //     .then(() => {
            //         setIsDeleting(false);

            //         Alert.success(
            //             intl.formatMessage({
            //                 id: 'ipCanDevices.lpDisplays.updateTopologys.deleteTrigger.success',
            //             })
            //         );

            //         props.onHide();
            //     })
            //     .catch(err => {
            //         console.error(err);

            //         setIsDeleting(false);

            //         Alert.error(
            //             intl.formatMessage({
            //                 id: 'ipCanDevices.lpDisplays.updateTopologys.deleteTrigger.error',
            //             })
            //         );
            //     });
        }
    };

    return (
        <Modal show={props.show} onHide={() => props.onHide()} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="configuration.topology.triggers.deleteTrigger.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormattedMessage id="configuration.topology.triggers.deleteTrigger.body" />

                {props.trigger && (
                    <p className="margin-top-10">
                        <Tag color="blue">
                            {generateTriggerMode(props.trigger.trigger, props.trigger.source, props.trigger.value)}
                        </Tag>
                        <FontAwesomeIcon icon={faArrowRight} className="margin-left-5 margin-right-5" />
                        <Tag color="orange">{`Trigger ${props.trigger.next + 1}`}</Tag>
                    </p>
                )}
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={() => props.onHide()} color="red" disabled={isDeleting}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Button onClick={handleDeleteTrigger} color="green" loading={isDeleting}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
