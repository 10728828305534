import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Message, Modal } from 'rsuite';
import { VirtualDisplayPreviewInformation } from '../../../../../handlers/lpDisplays/VirtualDisplay';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import {
    ActionObserver,
    ActionObserverEvent,
} from '../../../LPMatrixDisplayV2Config/handlers/Observers/ActionObserver';

type Props = {
    virtualDisplay: VirtualDisplayPreviewInformation;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isDeleting: boolean;
    hasError: boolean;
    error?: any;
};

class DeleteVirtualDisplayModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isDeleting: false,
            hasError: false,
            error: undefined,
        };
    }

    hideModal = () => {
        if (!this.state.isDeleting) {
            this.props.onHide();
        }
    };

    deleteVirtualDisplay = () => {
        this.setState({
            isDeleting: true,
            hasError: false,
            error: undefined,
        });

        axiosService
            .getAxios()
            .delete('/devices/lp-matrixdisplays-v2/virtualDisplay', {
                headers: authHeader(),
                data: {
                    tabId: [this.props.virtualDisplay.id],
                },
            })
            .then(deleteVirtualDisplaysResponse => {
                Alert.success(
                    this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplays.virtualDisplay.delete.success' })
                );

                this.setState({
                    isDeleting: false,
                });

                ActionObserver.emit(ActionObserverEvent.DELETE_VIRTUAL_DISPLAY, {
                    virtualDisplays: deleteVirtualDisplaysResponse.data,
                });

                this.props.onHide(true);
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                    isDeleting: false,
                });
            });
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.hideModal()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCanDevices.lpDisplays.virtualDisplay.delete.title" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.state.hasError && (
                        <Message
                            type="error"
                            description={this.props.intl.formatMessage({
                                id: 'ipCanDevices.lpDisplays.virtualDisplay.delete.error',
                            })}
                        />
                    )}

                    <FormattedMessage id="ipCanDevices.lpDisplays.virtualDisplay.delete.message" />
                </Modal.Body>

                <Modal.Footer>
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="lpDisplay-virtualDisplay-delete-cancel"
                                    onClick={() => this.hideModal()}
                                    color="red"
                                    disabled={this.state.isDeleting}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    data-cy="lpDisplay-virtualDisplay-delete-valid"
                                    onClick={this.deleteVirtualDisplay}
                                    appearance="primary"
                                    loading={this.state.isDeleting}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(DeleteVirtualDisplayModal);
