import { faCheck, faCheckDouble, faExchangeAlt, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Drawer, FlexboxGrid, Message, Tooltip, Whisper } from 'rsuite';
import TCMSensor from '../../../../handlers/ipCanDevices/TCMSensor';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import { DeviceStateInformation } from '../../Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../Cards/DeviceStateInformation/deviceStateInformationGenerator';
import CalibrationCard from './CalibrationCard';
import ColorCard from './ColorCard';
import ConfigurationCard from './ConfigurationCard';
import InfosCard from './InfosCard';
import DeleteSensorModal from './Modal/DeleteSensorModal';
import SetOneIdModal from './Modal/SetOneIdModal';
import TCMSensorHistoryModal from './Modal/TCMSensorHistoryModal';
import TimeCard from './TimeCard';

type Props = {
    sensor: TCMSensor;
    close: Function;
    show: boolean;
    ipCanId: number;
    sensorsList: TCMSensor[];
};

type State = {
    loading: boolean;
    sensor: TCMSensor | undefined;
    error: undefined;
    showDeleteModal: boolean;
    isCheckingDatabase: boolean;
    showTCMSensorHistoryModal: boolean;
    showSetOneIdModal: boolean;
    fetchingDatabase: boolean;
    fetchDatabaseError: boolean;
    databaseData: Record<string, any> | undefined;
    colorsLoading: boolean;
    timeLoading: boolean;
    calibrationLoading: boolean;
};
export default class SensorDrawer extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            sensor: undefined,
            error: undefined,
            showDeleteModal: false,
            isCheckingDatabase: false,
            showTCMSensorHistoryModal: false,
            showSetOneIdModal: false,
            fetchingDatabase: false,
            fetchDatabaseError: false,
            databaseData: undefined,
            colorsLoading: false,
            timeLoading: false,
            calibrationLoading: false,
        };

        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.setCheckingDatabase = this.setCheckingDatabase.bind(this);
        this.unsetCheckingDatabase = this.unsetCheckingDatabase.bind(this);
        this.showTCMSensorHistoryModal = this.showTCMSensorHistoryModal.bind(this);
        this.hideTCMSensorHistoryModal = this.hideTCMSensorHistoryModal.bind(this);
        this.hideSetOneIdModal = this.hideSetOneIdModal.bind(this);
        this.showSetOneIdModal = this.showSetOneIdModal.bind(this);
        this.updateCalibration = this.updateCalibration.bind(this);
        this.updateTime = this.updateTime.bind(this);
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.sensor.color !== this.props.sensor.color) {
    //         this.reload();
    //     }
    // }
    reload() {
        throw new Error('Method not implemented.');
    }

    showTCMSensorHistoryModal() {
        this.setState({
            showTCMSensorHistoryModal: true,
        });
    }

    hideTCMSensorHistoryModal() {
        this.setState({
            showTCMSensorHistoryModal: false,
        });
    }

    showSetOneIdModal() {
        this.setState({
            showSetOneIdModal: true,
        });
    }

    hideSetOneIdModal() {
        this.setState({
            showSetOneIdModal: false,
        });
    }

    unsetCheckingDatabase() {
        this.setState({
            isCheckingDatabase: false,
        });
    }

    setCheckingDatabase() {
        this.setState({
            fetchingDatabase: true,
        });

        axiosService
            .getAxios()
            .get(`/devices/tcm-sensor/config/${this.props.sensor.id}`, { headers: authHeader() })
            .then(tcmSensorConfigResponse => {
                this.setState({
                    databaseData: tcmSensorConfigResponse.data,
                    isCheckingDatabase: true,
                });
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    fetchDatabaseError: true,
                });
            })
            .finally(() => {
                this.setState({
                    fetchingDatabase: false,
                });
            });
    }

    hideDeleteModal(shouldReload: boolean) {
        this.setState({
            showDeleteModal: false,
        });

        this.props.close(shouldReload);
    }

    showDeleteModal() {
        this.setState({
            showDeleteModal: true,
        });
    }

    updateTime() {
        this.setState({
            timeLoading: true,
        });

        // this.props.sensor.updateTime(time).then(() => {
        //     this.setState({
        //         timeLoading: false,
        //     });
        // });
    }

    updateCalibration() {
        this.setState({
            calibrationLoading: true,
        });

        // this.props.sensor.updateCalibration(calibration).then(() => {
        //     this.setState({
        //         calibrationLoading: false,
        //     });
        // });
    }

    render() {
        return (
            <Drawer backdrop={true} show={this.props.show} onHide={() => this.props.close()}>
                <Drawer.Header data-cy="IPCanDrawer">
                    <Drawer.Title data-cy="IPCanDrawer-title">
                        {this.props.sensor && (
                            <>
                                <FormattedMessage id="ipCanDevices.sensor.title" />
                                <span className="margin-left-5">
                                    {this.props.sensor.bus + 1} - {this.props.sensor.deviceId}
                                </span>
                            </>
                        )}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body data-cy="ipcan-elements-sensor-drawer" className="drawer-body-without-avatar">
                    <Fragment>
                        <DeleteSensorModal
                            show={this.state.showDeleteModal}
                            onHide={this.hideDeleteModal}
                            id={[this.props.sensor.id]}
                        />

                        {this.props.sensor && this.state.showTCMSensorHistoryModal && (
                            <TCMSensorHistoryModal
                                show={this.state.showTCMSensorHistoryModal}
                                onHide={this.hideTCMSensorHistoryModal}
                                sensor={this.props.sensor}
                                ipCanId={this.props.ipCanId}
                            />
                        )}

                        {this.state.fetchDatabaseError && (
                            <Message
                                type="error"
                                description={<FormattedMessage id="ipCanDevices.sensor.fetchDataError" />}
                            />
                        )}

                        <InfosCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            sensor={this.props.sensor}
                            databaseData={this.state.databaseData}
                        />
                        <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.UPDATE_MAINTENANCE_STATE]}>
                            <DeviceStateInformation
                                device={this.props.sensor}
                                editMaintenanceStateUrl={MaintenanceStateInformationEditURL.TCM_SENSOR}
                            />
                        </SecuredFragment>
                        <ConfigurationCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            config={this.props.sensor.config}
                            databaseData={this.state.databaseData}
                        />
                        <ColorCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            sensor={this.props.sensor}
                            databaseData={this.state.databaseData}
                        />

                        <TimeCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            sensor={this.props.sensor}
                            databaseData={this.state.databaseData}
                        />

                        <CalibrationCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            sensor={this.props.sensor}
                            databaseData={this.state.databaseData}
                        />

                        <SetOneIdModal
                            sensor={this.props.sensor}
                            show={this.state.showSetOneIdModal}
                            onHide={this.hideSetOneIdModal}
                            sensorsList={this.props.sensorsList}
                            ipCanId={this.props.ipCanId}
                        />
                        {/* <ColorCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            color={this.props.sensor.color.getColors()}
                            databaseData={this.state.databaseData}
                            updateColors={this.updateColors}
                            isLoading={this.state.colorsLoading}
                        />
                        <TimeCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            time={this.props.sensor.time}
                            databaseData={this.state.databaseData}
                            updateTime={this.updateTime}
                            isLoading={this.state.timeLoading}
                        />
                        <CalibrationCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            calibration={this.props.sensor.calibration}
                            databaseData={this.state.databaseData}
                            updateCalibration={this.updateCalibration}
                            isLoading={this.state.calibrationLoading}
                        /> */}
                    </Fragment>
                </Drawer.Body>
                <Drawer.Footer>
                    {this.state.isCheckingDatabase ? (
                        <Button
                            block
                            onClick={this.unsetCheckingDatabase}
                            color="green"
                            data-cy="IPCan-check-sensor-button-valid">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    ) : (
                        <FlexboxGrid align="middle" justify="end">
                            <SecuredFragment authorizedRoles={[rolesConstants.controller.SET_ONE_ID]}>
                                <FlexboxGrid.Item componentClass={Col} md={3} xs={4}>
                                    <Button
                                        color="red"
                                        block
                                        onClick={this.showSetOneIdModal}
                                        data-cy="tcmSensor-action-showSetOnId">
                                        <FontAwesomeIcon icon={faExchangeAlt} size="lg" />
                                    </Button>
                                </FlexboxGrid.Item>
                            </SecuredFragment>

                            <SecuredFragment authorizedRoles={[rolesConstants.historics.VIEW_TCM_SENSOR]}>
                                <FlexboxGrid.Item componentClass={Col} md={3} xs={4}>
                                    <Whisper
                                        placement="left"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="tcmSensor.action.showHistory" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            color="blue"
                                            block
                                            onClick={this.showTCMSensorHistoryModal}
                                            data-cy="tcmSensor-action-showHistory">
                                            <FontAwesomeIcon icon={faHistory} size="lg" />
                                        </Button>
                                    </Whisper>
                                </FlexboxGrid.Item>
                            </SecuredFragment>

                            <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.VIEW]}>
                                <FlexboxGrid.Item componentClass={Col} xs={4}>
                                    <Button
                                        block
                                        onClick={this.setCheckingDatabase}
                                        color="orange"
                                        loading={this.state.fetchingDatabase}
                                        data-cy="IPCan-check-sensor-button">
                                        <FontAwesomeIcon icon={faCheckDouble} />
                                    </Button>
                                </FlexboxGrid.Item>
                            </SecuredFragment>

                            <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.DELETE]}>
                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    <Button
                                        block
                                        onClick={() => {
                                            this.showDeleteModal();
                                        }}
                                        color="red"
                                        data-cy="IPCan-delete-sensor-button"
                                        disabled={this.props.sensor.getOnline()}>
                                        <FormattedMessage id="sensor.delete.button" />
                                    </Button>
                                </FlexboxGrid.Item>
                            </SecuredFragment>
                        </FlexboxGrid>
                    )}
                </Drawer.Footer>
            </Drawer>
        );
    }
}
