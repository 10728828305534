import { LatLngExpression } from 'leaflet';
import moment from 'moment';

export class MapDeviceBase {
    private _createdAt: Date;
    private _createdBy: string;
    private _geoJSON: LatLngExpression;
    private _id: number;
    private _label: string;
    private _updatedAt: Date;
    private _updatedBy: string;

    constructor(data) {
        this._createdAt = data.createdAt;
        this._createdBy = data.createdBy;
        this._geoJSON = data.geoJSON;
        this._id = data.id;
        this._label = data.label;
        this._updatedAt = data.updatedAt;
        this._updatedBy = data.updatedBy;
    }

    public get createdAt() {
        return moment(this._createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public get createdBy() {
        return this._createdBy;
    }

    public get createInformations() {
        return `${this.createdBy} - ${this.createdAt}`;
    }

    public get geoJSON() {
        return this._geoJSON;
    }

    public get id() {
        return this._id;
    }

    public get label() {
        return this._label;
    }

    public get updatedAt() {
        return moment(this._updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public get updatedBy() {
        return this._updatedBy;
    }

    public get updateInformations() {
        return `${this.updatedBy} - ${this.updatedAt}`;
    }

    public setCreatedAt(createdAt_) {
        if (this._createdAt !== createdAt_) {
            this._createdAt = createdAt_;
        }
    }

    public setCreatedBy(createdBy_) {
        if (this._createdBy !== createdBy_) {
            this._createdBy = createdBy_;
        }
    }

    public setGeoJSON(geoJSON_) {
        if (this._geoJSON !== geoJSON_) {
            this._geoJSON = geoJSON_;
        }
    }

    public setLabel(label_) {
        if (this._label !== label_) {
            this._label = label_;
        }
    }

    public setUpdatedAt(updatedAt_) {
        if (this._updatedAt !== updatedAt_) {
            this._updatedAt = updatedAt_;
        }
    }
    public setUpdatedBy(updatedBy_) {
        if (this._updatedBy !== updatedBy_) {
            this._updatedBy = updatedBy_;
        }
    }
}
