import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Circle } from 'react-leaflet';
import { connect } from 'react-redux';
import { DrawerInformationInterface } from '../..';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { TCMSensorMapInformation_I } from '../../../../handlers/map/MapTCMSensor';
import { SensorPlaceType } from '../../../../handlers/sensorPlaceType/SensorPlaceType';
import TCMSensorPlacePopup from './TCMSensorPlacePopup';

type Props = {
    sensor: TCMSensorMapInformation_I;
    circleRadius: number;
    controllers: IpCan[];
    placeTypes: SensorPlaceType[];
    reloadSensors: Function;
    editable?: boolean;
    editMode: boolean;
    sensorStateDisplayed: boolean;
    auth?: Record<string, any>;
    hoverPopup?: boolean;
    service: any;
    blinkState: number;
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
    isEditingSensors: boolean;
} & WrappedComponentProps;

type State = {
    occupiedPercent: number;
    forcedPercent: number;
    hoverPopup: boolean;
    isAdmin: boolean;
    controller: IpCan | undefined;
    blinkState: number;
};

class TCMSensorPlaceIcon extends React.PureComponent<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            occupiedPercent: 0.65,
            forcedPercent: 0.3,
            hoverPopup: false,
            isAdmin: false,
            controller: undefined,
            blinkState: 0,
        };

        // this.generatePopup = this.generatePopup.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.openPopup = this.openPopup.bind(this);
    }

    componentDidMount() {
        const isAdmin =
            this.props.auth?.user.roles.find(data => {
                return data.name === 'Admin';
            }) || false;

        const controller = this.props.controllers.find(controller => controller.id === this.props.sensor.ipCanId);

        this.setState({
            isAdmin,
            controller,
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.blinkState !== this.props.blinkState) {
            const detectionStateIndex = this.props.sensor.detectionState - 1;
            if (detectionStateIndex >= 0 && detectionStateIndex <= 3) {
                if (this.props.sensor.scenes[detectionStateIndex].getLength() > 1) {
                    this.setState({
                        blinkState: this.props.blinkState,
                    });
                }
            }
        }
    }

    onMouseOver(e) {
        this.setState({
            hoverPopup: true,
        });
        e.target.openPopup();
    }

    onMouseOut(e) {
        if (this.state.hoverPopup) {
            e.target.closePopup();
        }
    }

    openPopup(e) {
        this.setState({
            hoverPopup: false,
        });
        e.target.openPopup();
    }

    generateForceColor() {
        switch (this.props.sensor.forceType) {
            case 1: {
                try {
                    return this.props.sensor.scenes[0].getStep(this.props.blinkState).color;
                } catch (e) {
                    return 'transparent';
                }
            }
            case 2:
                try {
                    return this.props.sensor.scenes[1].getStep(this.props.blinkState).color;
                } catch (e) {
                    return 'transparent';
                }
            case 3:
                try {
                    return this.props.sensor.scenes[2].getStep(this.props.blinkState).color;
                } catch (e) {
                    return 'transparent';
                }
            case 4:
                try {
                    return this.props.sensor.scenes[3].getStep(this.props.blinkState).color;
                } catch (e) {
                    return 'transparent';
                }
            case 11:
                return '#008000';
            case 12:
                return '#F44336';
            case 13:
                return '#000000';
            case 14:
                return '#008000';
            case 15:
                return '#F44336';
            case 16:
                return '#ff9800';
            case 17:
                return '#F44336';
            default:
                return 'transparent';
        }
    }

    generateColor = () => {
        try {
            return this.props.sensor.scenes[0].getStep(this.props.blinkState).color;
        } catch (e) {
            return 'transparent';
        }
    };

    generateCircleColor = () => {
        const { isForce, detectionState, online } = this.props.sensor;

        if (!online) {
            try {
                return this.props.sensor.scenes[0].getStep(0).color;
            } catch {
                return 'transparent';
            }
        }

        try {
            if (this.props.sensorStateDisplayed || !this.state.isAdmin) {
                if (this.props.sensor.eventForce.forceType === 'tcm-sensor_force_color') {
                    return this.props.sensor.eventForce.color;
                } else if (isForce) {
                    return this.generateForceColor();
                } else {
                    return this.props.sensor.scenes[detectionState - 1].getStep(this.props.blinkState).color;
                }
            } else {
                return this.props.sensor.scenes[0].getStep(this.props.blinkState).color;
            }
        } catch {
            return 'transparent';
        }
    };

    render() {
        const shouldShowCurrentSelectedElement =
            this.props.drawerInformation.type === 'tcm-sensor' &&
            this.props.drawerInformation.id === this.props.sensor.id;
        // NOT CONNECTED
        // if (!this.props.sensor.tcmSensor.online) {

        const shouldShowForceEventCircle =
            this.props.sensor.tcmSensor.eventForce.forceType === 'tcm-sensor_force_color' &&
            !this.props.editMode &&
            this.props.sensorStateDisplayed &&
            !this.props.isEditingSensors;

        const shouldShowForceCircle =
            this.props.sensor.isForce &&
            !this.props.editMode &&
            this.props.sensorStateDisplayed &&
            !this.props.isEditingSensors &&
            !shouldShowForceEventCircle;

        return (
            <Fragment>
                <Circle
                    radius={this.props.circleRadius}
                    color={this.generateCircleColor()}
                    fillColor={this.generateColor()}
                    opacity={1}
                    weight={5}
                    center={this.props.sensor.geoJSON}
                    fillOpacity={!this.props.sensor.online && this.state.isAdmin ? 0.3 : 1}
                    stroke={true}
                    name={`${this.props.sensor.mapSensorId}`}
                    onmouseover={this.onMouseOver}
                    onmouseout={this.onMouseOut}
                    onclick={this.openPopup}>
                    <TCMSensorPlacePopup
                        sensor={this.props.sensor}
                        editMode={this.props.editMode}
                        controller={this.state.controller}
                        placeTypes={this.props.placeTypes}
                        reloadSensors={this.props.reloadSensors}
                        hoverPopup={this.state.hoverPopup}
                        controllers={this.props.controllers}
                        service={this.props.service}
                        drawerInformation={this.props.drawerInformation}
                        openDrawer={this.props.openDrawer}
                        closeDrawer={this.props.closeDrawer}
                    />
                    {shouldShowCurrentSelectedElement && !this.props.isEditingSensors && (
                        <Circle
                            radius={this.props.circleRadius + 4}
                            weight={10}
                            color="#FF851B"
                            fillOpacity={0}
                            center={this.props.sensor.geoJSON}
                        />
                    )}
                </Circle>

                {shouldShowForceEventCircle && (
                    <Circle
                        radius={3}
                        color="#EE6352"
                        fillColor="#EE6352"
                        fillOpacity={1}
                        opacity={1}
                        stroke={false}
                        center={this.props.sensor.geoJSON}
                        onmouseover={this.onMouseOver}
                        onmouseout={this.onMouseOut}
                        onclick={this.openPopup}>
                        <TCMSensorPlacePopup
                            // type={this.props.sensor.type}
                            sensor={this.props.sensor}
                            editMode={this.props.editMode}
                            controller={this.state.controller}
                            placeTypes={this.props.placeTypes}
                            reloadSensors={this.props.reloadSensors}
                            hoverPopup={this.state.hoverPopup}
                            name={this.props.sensor.id.toString()}
                            controllers={this.props.controllers}
                            service={this.props.service}
                            drawerInformation={this.props.drawerInformation}
                            openDrawer={this.props.openDrawer}
                            closeDrawer={this.props.closeDrawer}
                        />
                    </Circle>
                )}

                {shouldShowForceCircle && (
                    <Circle
                        radius={3}
                        color="#000"
                        fillColor="#000"
                        fillOpacity={1}
                        opacity={1}
                        stroke={false}
                        center={this.props.sensor.geoJSON}
                        onmouseover={this.onMouseOver}
                        onmouseout={this.onMouseOut}
                        onclick={this.openPopup}>
                        <TCMSensorPlacePopup
                            // type={this.props.sensor.type}
                            sensor={this.props.sensor}
                            editMode={this.props.editMode}
                            controller={this.state.controller}
                            placeTypes={this.props.placeTypes}
                            reloadSensors={this.props.reloadSensors}
                            hoverPopup={this.state.hoverPopup}
                            name={this.props.sensor.id.toString()}
                            controllers={this.props.controllers}
                            service={this.props.service}
                            drawerInformation={this.props.drawerInformation}
                            openDrawer={this.props.openDrawer}
                            closeDrawer={this.props.closeDrawer}
                        />
                    </Circle>
                )}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { auth } = state;

    return { auth };
}

export default connect(mapStateToProps)(injectIntl(TCMSensorPlaceIcon));
