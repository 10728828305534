import React from 'react';

export default function updateGeoJSON(option) {
    return (
        <div className="padding-top-5 padding-bottom-5">
            {option.geoJSON.map(geoJSON => {
                return (
                    <div>
                        x: {geoJSON[1]}, y: {geoJSON[0]}
                    </div>
                );
            })}
        </div>
    );
}
