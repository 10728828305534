import { placeTypeConstants } from '../constants';

type State = {
    isLoading: boolean;
    placeTypesList: Array<Record<string, any>>;
    error: undefined | string;
    fetchedPlaceType: undefined | Record<string, any>;
    create_modal_openned: boolean;
    delete_modal_openned: boolean;
    update_modal_openned: boolean;
    view_drawer_openned: boolean;
    placeTypeDrawer: Record<string, any> | undefined;
};

const initialState = {
    isLoading: false,
    placeTypesList: [],
    error: undefined,
    fetchedPlaceType: undefined,
    create_modal_openned: false,
    delete_modal_openned: false,
    update_modal_openned: false,
    view_drawer_openned: false,
    placeTypeDrawer: undefined,
};

export function placeTypes(state: State = initialState, action) {
    switch (action.type) {
        case placeTypeConstants.GET_ALL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case placeTypeConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                error: undefined,
                placeTypesList: action.placeTypes.map(placeType => {
                    return { ...placeType, isLoading: false };
                }),
                isLoading: false,
            };
        case placeTypeConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case placeTypeConstants.CREATE_REQUEST:
            return {
                ...state,
                placeTypesList: state.placeTypesList.concat({
                    ...action.placeType,
                }),
                isLoading: true,
            };
        case placeTypeConstants.CREATE_SUCCESS:
            return {
                ...state,
                placeTypesList: state.placeTypesList.map(placeType => {
                    if (placeType.id === action.id) {
                        return { ...action.placeType };
                    } else {
                        return placeType;
                    }
                }),
                create_modal_openned: false,
                isLoading: false,
            };
        case placeTypeConstants.CREATE_FAILURE:
            return {
                ...state,
                placeTypesList: state.placeTypesList.filter(placeType => placeType.id !== action.id),
                create_modal_openned: false,
                isLoading: false,
            };
        case placeTypeConstants.UPDATE_REQUEST:
            return {
                ...state,
                placeTypesList: state.placeTypesList.map(placeType =>
                    placeType.id === action.id ? { ...placeType, isLoading: true } : placeType
                ),
            };
        case placeTypeConstants.UPDATE_SUCCESS:
            return {
                ...state,
                placeTypesList: state.placeTypesList.map(placeType => {
                    if (placeType.id === action.placeType.id) {
                        let newplaceType = action.placeType;
                        newplaceType.levels = placeType.levels;
                        return { ...newplaceType, isLoading: false };
                    } else {
                        return placeType;
                    }
                }),
                placeTypeDrawer: { ...state.placeTypeDrawer, ...action.placeType },
                // TODO
            };
        case placeTypeConstants.UPDATE_FAILURE:
            return {
                ...state,
                placeTypesList: state.placeTypesList.map(placeType => {
                    if (placeType.id === action.id) {
                        return { ...placeType, isLoading: false };
                    } else {
                        return placeType;
                    }
                }),
            };
        case placeTypeConstants.DELETE_REQUEST:
            // add 'deleting:true' property to placeType being deleted
            return {
                ...state,
                placeTypesList: state.placeTypesList.map(placeType =>
                    placeType.id === action.id ? { ...placeType, isLoading: true } : placeType
                ),
            };
        case placeTypeConstants.DELETE_SUCCESS:
            // remove deleted placeType from state
            return {
                ...state,
                placeTypesList: state.placeTypesList.filter(placeType => placeType.id !== action.placeType.id),
            };
        case placeTypeConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to placeType
            return {
                ...state,
                placeTypesList: state.placeTypesList.map(placeType => {
                    if (placeType.id === action.id) {
                        // make copy of placeType without 'deleting:true' property
                        const { ...placeTypeCopy } = placeType;
                        // return copy of placeType with 'deleteError:[error]' property
                        return { ...placeTypeCopy, deleteError: action.error };
                    }

                    return placeType;
                }),
            };
        // case placeTypeConstants.GETONE_REQUEST:
        //     return {
        //         ...state,
        //         fetchedplaceType: {},
        //     };
        // case placeTypeConstants.GETONE_SUCCESS:
        //     return {
        //         ...state,
        //         fetchedplaceType: action.placeType,
        //     };
        // case placeTypeConstants.GETONE_FAILURE:
        //     return {
        //         ...state,
        //         error: action.error,
        //     };
        case placeTypeConstants.OPEN_CREATE_MODAL:
            return {
                ...state,
                create_modal_openned: true,
                delete_modal_openned: false,
                update_modal_openned: false,
                view_drawer_openned: false,
                placeTypeDrawer: undefined,
            };
        case placeTypeConstants.CLOSE_CREATE_MODAL:
            return {
                ...state,
                create_modal_openned: false,
                delete_modal_openned: false,
                update_modal_openned: false,
                view_drawer_openned: false,
                placeTypeDrawer: undefined,
            };
        case placeTypeConstants.OPEN_VIEW_DRAWER_SUCCESS:
            return {
                ...state,
                view_drawer_openned: true,
                placeTypeDrawer: action.placeType,
            };
        case placeTypeConstants.CLOSE_VIEW_DRAWER:
            return {
                ...state,
                view_drawer_openned: false,
                placeTypeDrawer: undefined,
            };
        case placeTypeConstants.OPEN_DELETE_MODAL_REQUEST:
            return {
                ...state,
                view_drawer_openned: false,
                placeTypeDrawer: undefined,
            };

        case placeTypeConstants.OPEN_DELETE_MODAL_SUCCESS:
            return {
                ...state,
                delete_modal_openned: true,
                placeTypeDrawer: action.placeType,
            };
        case placeTypeConstants.OPEN_DELETE_MODAL_FAILURE:
            return {
                ...state,
                delete_modal_openned: false,
                placeTypeDrawer: undefined,
            };
        case placeTypeConstants.CLOSE_DELETE_MODAL:
            return {
                ...state,
                delete_modal_openned: false,
                placeTypeDrawer: undefined,
            };
        default:
            return state;
    }
}
