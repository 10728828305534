import { faCopy, faPlusCircle, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Panel, Tag } from 'rsuite';
import { Profile } from '../../../../handlers/profile/Profile';
import PanelHeader from '../../../Custom/PanelHeader';
import ElementTable from '../../../ReactDataTableComponent/ElementTable';
import { ProfileDrawer } from './Drawer';
import { CopyProfileModal } from './Modals/CopyProfileModal';
import { CreateProfileModal } from './Modals/CreateProfileModal';

type ProfileDrawerInfo = {
    open: boolean;
    profile?: Profile;
};

type ProfileCopyInfo = {
    open: boolean;
    profile?: Profile;
};

type Props = {
    loading: boolean;
    profiles: Profile[];
    reloadUsers: () => void;
    reloadProfiles: () => void;
};

export const ProfilesTable = (props: Props) => {
    const intl = useIntl();

    const [profileDrawerInfo, setProfileDrawerInfo] = React.useState<ProfileDrawerInfo>({
        open: false,
    });

    const [profileCopyInfo, setProfileCopyInfo] = React.useState<ProfileCopyInfo>({
        open: false,
    });

    const [counter, setCounter] = React.useState<number>(0);

    const [createProfileModalOpen, setCreateProfileModalOpen] = React.useState<boolean>(false);

    const columns: TableColumn<Profile>[] = [
        {
            name: intl.formatMessage({ id: 'profiles.name' }),
            cell: row => row.getName(),
            sortable: true,
            selector: row => row.getName(),
            center: true,
        },
        {
            name: intl.formatMessage({ id: 'profiles.description' }),
            cell: row => row.getDescription(),
            sortable: true,
            selector: row => row.getDescription(),
            center: true,
        },
        {
            name: intl.formatMessage({ id: 'profiles.users' }),
            cell: row => (
                <Tag color="blue">{`${row.getUsers().length} ${intl.formatMessage({ id: 'profiles.users' })}`}</Tag>
            ),
            sortable: true,
            selector: row => row.getUsers(),
            center: true,
        },
        {
            name: intl.formatMessage({ id: 'profiles.creation' }),
            cell: row => row.getCreation(),
            selector: row => row.getCreation(),
            center: true,
        },
        {
            name: intl.formatMessage({ id: 'profiles.update' }),
            cell: row => row.getUpdate(),
            selector: row => row.getUpdate(),
            center: true,
        },
        {
            name: intl.formatMessage({ id: 'profiles.duplicate' }),
            cell: row => (
                <Button color="violet" onClick={event => handleOpenCreateProfileModal(event, row)}>
                    <FontAwesomeIcon icon={faCopy} />
                </Button>
            ),
            center: true,
        },
    ];

    const handleCloseCreateProfileModal = (shouldReload: boolean) => {
        setCreateProfileModalOpen(false);

        if (shouldReload) {
            props.reloadProfiles();
            props.reloadUsers();
        }
    };

    const handleOpenCreateProfileModal = (e: React.SyntheticEvent, profile: Profile) => {
        e.stopPropagation();

        setProfileCopyInfo({ open: true, profile });
    };

    const handleCloseCopyProfileModal = (shouldReload: boolean) => {
        setProfileCopyInfo({ open: false, profile: undefined });

        if (shouldReload) {
            props.reloadProfiles();
            props.reloadUsers();
        }
    };

    return (
        <>
            <CreateProfileModal open={createProfileModalOpen} onClose={handleCloseCreateProfileModal} />

            <CopyProfileModal
                open={profileCopyInfo.open}
                profile={profileCopyInfo.profile}
                onClose={handleCloseCopyProfileModal}
            />

            <ProfileDrawer
                profile={profileDrawerInfo.profile}
                open={profileDrawerInfo.open}
                onClose={() => setProfileDrawerInfo({ open: false })}
                updateProfiles={() => setCounter(counter + 1)}
                reloadProfiles={props.reloadProfiles}
                reloadUsers={props.reloadUsers}
            />

            <Panel
                className="panel-big dashboard-card-header"
                shaded
                bordered
                header={
                    <PanelHeader
                        icon={faUserLock}
                        title={intl.formatMessage({ id: 'profiles.title' })}
                        tagValue={props.profiles.length}
                        buttons={[
                            <Button
                                color="red"
                                key="profile-create"
                                size="sm"
                                onClick={() => setCreateProfileModalOpen(true)}
                                data-cy="profile-addprofile-button">
                                <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                <FormattedMessage id="profile.addProfile" />
                            </Button>,
                        ]}
                    />
                }
                bodyFill>
                <ElementTable
                    progressPending={props.loading}
                    columns={columns}
                    data={props.profiles}
                    onRowClicked={row => setProfileDrawerInfo({ open: true, profile: row })}
                    striped
                    highlightOnHover
                />
            </Panel>
        </>
    );
};
