import { CRS } from 'leaflet';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { ImageOverlay, Map } from 'react-leaflet';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Loader, Message } from 'rsuite';
import { interpret } from 'xstate';
import Camera from '../../handlers/Camera/Camera';
import Counter from '../../handlers/Counter/Counter';
import { Parking } from '../../handlers/parking/Parking';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import ProgressBars from './Components/ProgressBars';
import ActivityMap from './Controls/ActivityMap';
import BottomLeftControls from './Controls/BottomLeftControls';
import TopLeftControls from './Controls/TopLeftControls';
import TopRightControls from './Controls/TopRightControls';
import { MapActionsMachine } from './Hooks/MapActionsMachine';
import DisplaysLayer from './Layers/DisplaysLayer';
import PassCountLayer from './Layers/PassCountLayer';
import PlacesLayer from './Layers/PlacesLayer';
import ZonesLayer from './Layers/ZonesLayer';
import CalibrationMenu from './Menus/CalibrationMenu';

type Props = {
    parking: Record<string, any>;
    computedMatch: Record<string, any>;
} & WrappedComponentProps &
    RouteComponentProps;

type State = {
    levelId: number;
    zoom: number;
    center: number[];
    current: Record<string, any>;
    reloadingCounter: boolean;
    redirect: boolean;
    redirectTo: undefined;
    drawerInformation: DrawerInformationInterface;
    counters: Counter[];
    cameras: Camera[];
    parkings: Parking[];
    mapZones: any[];
    shouldUpdateUI: boolean;
};

export interface DrawerInformationInterface {
    isOpen: boolean;
    type: 'tcm-sensor' | 'tcm-display' | 'lp-sensor' | 'lp-display' | 'place-camera' | null;
    id: number | null;
}

let threadUI: any = null;

class ParkingMap extends React.Component<Props, State> {
    service: any;
    private mapRef = React.createRef<any>()


    constructor(props: Props) {
        super(props);

        this.mapRef = React.createRef();

        this.state = {
            levelId: parseInt(props.computedMatch.params.levelId),
            zoom: 1,
            center: [483, 450],
            current: MapActionsMachine.initialState,
            reloadingCounter: false,
            redirect: false,
            redirectTo: undefined,
            shouldUpdateUI: false,
            drawerInformation: {
                isOpen: false,
                type: null,
                id: null,
            },
            counters: [],
            cameras: [],
            parkings: [],
            mapZones: [],
        };

        // Service for top left buttons (sensors, places...)
        this.service = interpret(
            MapActionsMachine.withContext({ ...MapActionsMachine.context, levelId: props.computedMatch.params.levelId })
        ).onTransition(current => {
            // ---
            this.setState({ current });

            let timing = 10;

            // console.log(current);
            switch (current.event.type) {
                case 'SENSOR_UPDATE':
                case 'LPSENSOR_UPDATE':
                case 'CAMERA_SENSOR_UPDATE':
                case 'LPMATRIXDISPLAY_V2_UPDATE':
                case 'DISPLAY_UPDATE':
                case 'UPDATE_TCM_DISPLAY':
                case 'UPDATE_LP_MATRIX_DISPLAY':
                case 'VEHICLE_COUNTER_UPDATE': {
                    let nbTotalElements = 0;

                    nbTotalElements += current.context.tcmSensors.length;
                    nbTotalElements += current.context.displays.length;
                    nbTotalElements += current.context.lpSensors.length;
                    nbTotalElements += current.context.lpMatrixDisplayV2.length;
                    nbTotalElements += current.context.placeCameraSensors.length;
                    nbTotalElements += current.context.vehicleCounters.length;

                    const totalTime = nbTotalElements * 2;

                    if (totalTime > 10) {
                        timing = totalTime;
                    }

                    break;
                }
                default:
                    break;
            }
            // ---
            if (threadUI === null) {
                try {
                    threadUI = setTimeout(() => {
                        threadUI = null;
                        this.setState({
                            shouldUpdateUI: true,
                        });
                    }, timing);
                } catch (err) {
                    console.error(err);
                }
            }
        });

        this.retry = this.retry.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.reload = this.reload.bind(this);
        this.updateTcmSensor = this.updateTcmSensor.bind(this);
        this.updateTcmDisplay = this.updateTcmDisplay.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.toggleSensorState = this.toggleSensorState.bind(this);
        this.updateLogicCountingSync = this.updateLogicCountingSync.bind(this);
        this.reloadCounters = this.reloadCounters.bind(this);
        this.updateCounter = this.updateCounter.bind(this);
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (
            nextState.shouldUpdateUI ||
            this.state.drawerInformation !== nextState.drawerInformation ||
            this.props.computedMatch.params.levelId !== nextProps.computedMatch.params.levelId
        ) {
            this.setState({
                shouldUpdateUI: false,
            });

            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.computedMatch.params.levelId !== this.props.computedMatch.params.levelId) {
            this.service.stop();

            this.service = interpret(
                MapActionsMachine.withContext({
                    ...MapActionsMachine.context,
                    levelId: this.props.computedMatch.params.levelId,
                })
            ).onTransition(current => {
                // ---
                this.setState({ current });

                let timing = 10;

                // console.log(current);
                switch (current.event.type) {
                    case 'SENSOR_UPDATE':
                    case 'LPSENSOR_UPDATE':
                    case 'CAMERA_SENSOR_UPDATE':
                    case 'LPMATRIXDISPLAY_V2_UPDATE':
                    case 'DISPLAY_UPDATE':
                    case 'UPDATE_TCM_DISPLAY':
                    case 'UPDATE_LP_MATRIX_DISPLAY':
                    case 'VEHICLE_COUNTER_UPDATE': {
                        let nbTotalElements = 0;

                        nbTotalElements += current.context.tcmSensors.length;
                        nbTotalElements += current.context.displays.length;
                        nbTotalElements += current.context.lpSensors.length;
                        nbTotalElements += current.context.lpMatrixDisplayV2.length;
                        nbTotalElements += current.context.placeCameraSensors.length;
                        nbTotalElements += current.context.vehicleCounters.length;

                        const totalTime = nbTotalElements * 2;

                        if (totalTime > 10) {
                            timing = totalTime;
                        }

                        break;
                    }
                    default:
                        break;
                }

                // ---
                if (threadUI === null) {
                    try {
                        threadUI = setTimeout(() => {
                            threadUI = null;
                            this.setState({
                                shouldUpdateUI: true,
                            });
                        }, timing);
                    } catch (err) {
                        console.error(err);
                    }
                }
            });

            this.service.start();
        }
    }

    componentDidMount() {
        this.service.start();

        webSocketService.joinRoom('tcm-sensor');
        webSocketService.joinRoom('tcm-display');
        webSocketService.joinRoom('lp-sensor');
        webSocketService.joinRoom('counter');
        webSocketService.joinRoom('vehicle-counter');
        webSocketService.joinRoom('lp-matrixDisplayV2');
        webSocketService.joinRoom('ip-camera');
        webSocketService.joinRoom('place-camera');

        webSocketService.onEvent('tcm-sensor:updateStatus', this.updateTcmSensor);
        webSocketService.onEvent('tcm-display:updateStatus', this.updateTcmDisplay);
        webSocketService.onEvent('lp-sensor:updateStatus', this.updateLpSensor);
        webSocketService.onEvent('counter:update', this.updateCounter);
        webSocketService.onEvent('vehicle-counter:updateStatus', this.updateVehicleCounter);
        webSocketService.onEvent('lp-matrixDisplayV2:updateStatus', this.updateLpMatrixDisplay);
        webSocketService.onEvent('ip-camera:updateStatus', this.updateCamera);
        webSocketService.onEvent('ip-camera:reload', this.reloadCameras);
        webSocketService.onEvent('place-camera:updateDetection', this.updateCameraPlaceDetection);

        axiosService
            .getAxios()
            .get('/counters', {
                headers: authHeader(),
            })
            .then(countersResponse => {
                this.setState({ counters: countersResponse.data });
            });

        axiosService
            .getAxios()
            .get('/ip-cameras', { headers: authHeader() })
            .then(camerasResponse => {
                this.setState({ cameras: camerasResponse.data.map(camera => new Camera(camera)) });
            });

        axiosService
            .getAxios()
            .get('/parkings', { headers: authHeader() })
            .then(parkingResponse => {
                this.setState({ parkings: parkingResponse.data.map(park => new Parking(park)) });
            });

        axiosService
            .getAxios()
            .get('/map-zones', { headers: authHeader() })
            .then(mapZonesResponse => {
                this.setState({ mapZones: mapZonesResponse.data });
            });
    }

    componentWillUnmount() {
        this.service.stop();

        webSocketService.offEvent('tcm-sensor:updateStatus', this.updateTcmSensor);
        webSocketService.offEvent('tcm-display:updateStatus', this.updateTcmDisplay);
        webSocketService.offEvent('lp-sensor:updateStatus', this.updateLpSensor);
        webSocketService.offEvent('counter:update', this.updateCounter);
        webSocketService.offEvent('vehicle-counter:updateStatus', this.updateVehicleCounter);
        webSocketService.offEvent('lp-matrixDisplayV2:updateStatus', this.updateLpMatrixDisplay);
        webSocketService.offEvent('ip-camera:updateStatus', this.updateCamera);
    }

    updateTcmSensor(data) {
        if (!this.state.current.context.isEdit) {
            const { context } = this.state.current;
            //---
            if (context.tcmSensors.find(sensor => sensor.sensor.id === data.id)) {
                this.service.send('SENSOR_UPDATE', { sensor: data });
            }
        }
    }

    updateTcmDisplay(data) {
        if (!this.state.current.context.isEdit) {
            const { context } = this.state.current;
            //---
            if (context.displays.find(display => display.tcmDisplay.id === data.id)) {
                this.service.send('DISPLAY_UPDATE', { display: data });
            }
        }
    }

    updateLpSensor = data => {
        // if (!this.state.current.context.isEdit) {
        const { context } = this.state.current;
        // ---
        if (context.lpSensors.find(sensor => sensor.sensor.id === data.id)) {
            this.service.send('LPSENSOR_UPDATE', {
                sensor: data,
            });
        }
        // }
    };

    updateLpMatrixDisplay = data => {
        const { context } = this.state.current;

        if (context.lpMatrixDisplayV2.find(display => display.getLpMatrixDisplayV2().getId() === data.id)) {
            this.service.send('LPMATRIXDISPLAY_V2_UPDATE', {
                display: data,
            });
        }
    };

    updateVehicleCounter = data => {
        if (!this.state.current.context.isEdit) {
            const { context } = this.state.current;

            if (context.vehicleCounters.find(counter => counter.getVehicleCounter().getId() === data.id)) {
                this.service.send('VEHICLE_COUNTER_UPDATE', {
                    vehicleCounter: data,
                });
            }
        }
    };

    updateCamera = data => {
        const cameras = [...this.state.cameras];
        const currentCamera = cameras.find(camera => camera.getId() === data.id);

        if (currentCamera) {
            currentCamera.updateCameraWS(data);

            this.setState({
                cameras,
            });
        }
    };

    updateCameraPlaceDetection = data => {
        const { context } = this.state.current;
        // ---
        if (context.placeCameraSensors.find(sensor => sensor.getPlaceCamera().getId() === data.id)) {
            this.service.send('CAMERA_SENSOR_UPDATE', {
                sensor: data,
            });
        }
    };

    reloadCameras = () => {
        axiosService
            .getAxios()
            .get('/ip-cameras', { headers: authHeader() })
            .then(camerasResponse => {
                this.setState({ cameras: camerasResponse.data.map(camera => new Camera(camera)) });
            });
    };

    updateLogicCountingSync(value) {
        this.service.send('UPDATE_LOGIC_COUNTING_SYNC', { value });
    }

    handleButtonClick(command) {
        this.service.send(command);
    }

    retry(type) {
        switch (type) {
            case 'placeType':
                this.service.send('RELOAD_PLACE_TYPE');
                break;
            case 'controllers':
                this.service.send('RELOAD_CONTROLLERS');
                break;
            case 'levels':
                this.service.send('RELOAD_LEVELS');
                break;
            case 'sensors':
                this.service.send('RELOAD_SENSORS');
                break;
            case 'places_camera':
                this.service.send('RELOAD_PLACES_CAMERA');
                break;
            case 'displays':
                this.service.send('RELOAD_DISPLAYS');
                break;
            case 'zones':
                this.service.send('RELOAD_ZONES');
                break;
            default:
                break;
        }
    }

    reload(name) {
        this.service.send('RELOAD', { name });
        this.setState({ shouldUpdateUI: true });
    }

    handleRedirect(levelId) {
        this.props.history.push(`/map/${levelId}`);
    }

    toggleSensorState() {
        this.service.send('TOGGLE_SENSOR_STATE');
    }

    reloadCounters() {
        this.service.send('RELOAD_COUNTERS');
    }

    updateCounter(e) {
        this.service.send('COUNTER_UPDATE', { data: e });
    }

    openDrawer = (type, id) => {
        this.setState({
            drawerInformation: {
                isOpen: true,
                type,
                id,
            },
        });
    };

    closeDrawer = () => {
        this.setState({
            drawerInformation: {
                isOpen: false,
                type: null,
                id: null,
            },
        });
    };

    render() {
        const { context } = this.state.current;

        if (this.state.current.matches('loadPlaceTypeFailure')) {
            return (
                <Message
                    showIcon
                    type="error"
                    description={
                        <p>
                            Error while fetching place types
                            <br />
                            <Button appearance="default" onClick={() => this.retry('placeType')}>
                                Retry
                            </Button>
                        </p>
                    }
                />
            );
        } else if (this.state.current.matches('loadControllerFailure')) {
            return (
                <Message
                    showIcon
                    type="error"
                    description={
                        <p>
                            Error while fetching controllers
                            <br />
                            <Button appearance="default" onClick={() => this.retry('controllers')}>
                                Retry
                            </Button>
                        </p>
                    }
                />
            );
        } else if (this.state.current.matches('loadLevelFailure')) {
            return (
                <Message
                    showIcon
                    type="error"
                    description={
                        <p>
                            Error while fetching levels
                            <br />
                            <Button appearance="default" onClick={() => this.retry('levels')}>
                                Retry
                            </Button>
                        </p>
                    }
                />
            );
        } else if (this.state.current.matches('loadSensorsFailure')) {
            return (
                <Message
                    showIcon
                    type="error"
                    description={
                        <p>
                            Error while fetching sensors
                            <br />
                            <Button appearance="default" onClick={() => this.retry('sensors')}>
                                Retry
                            </Button>
                        </p>
                    }
                />
            );
        } else if (this.state.current.matches('loadDisplaysFailure')) {
            return (
                <Message
                    showIcon
                    type="error"
                    description={
                        <p>
                            Error while fetching displays
                            <br />
                            <Button appearance="default" onClick={() => this.retry('displays')}>
                                Retry
                            </Button>
                        </p>
                    }
                />
            );
        } else if (this.state.current.matches('loadZonesFailure')) {
            return (
                <Message
                    showIcon
                    type="error"
                    description={
                        <p>
                            Error while fetching zones
                            <br />
                            <Button appearance="default" onClick={() => this.retry('zones')}>
                                Retry
                            </Button>
                        </p>
                    }
                />
            );
        } else if (this.state.current.matches('loadVehicleCountersFailure')) {
            return (
                <Message
                    showIcon
                    type="error"
                    description={
                        <p>
                            Error while fetching vehicle counters
                            <br />
                            <Button appearance="default" onClick={() => this.retry('zones')}>
                                Retry
                            </Button>
                        </p>
                    }
                />
            );
        } else if (context.level && context.levels.length > 0 && !context.loading) {
            return (
                <Fragment>
                    <ProgressBars
                        sensorPlaceTypes={context.sensorPlaceTypes}
                        parking={context.level.parking}
                        handleButtonClick={this.handleButtonClick}
                        context={context}
                        counters={this.state.counters}
                        parkings={this.state.parkings}
                        mapZones={this.state.mapZones}
                    />

                    <Map
                        zoomAnimation
                        crs={CRS.Simple}
                        center={context.level.positionZoom}
                        zoom={context.level.defaultZoom}
                        maxZoom={10.0}
                        minZoom={-10.0}
                        attributionControl={false}
                        zoomControl={true}
                        doubleClickZoom={true}
                        scrollWheelZoom={true}
                        dragging={true}
                        zoomDelta={0.1}
                        zoomSnap={0}
                        animate={true}
                        ref={this.mapRef}
                        style={{ height: 'calc(100vh - 170px)', width: '100%' }}
                        easeLinearity={0.35}>
                        <ImageOverlay
                            zIndex={0}
                            url={context.level.levelImage}
                            bounds={[
                                [context.level.imageHeight, context.level.imageWidth],
                                [0, 0],
                            ]}
                        />

                        <TopRightControls
                            levels={context.levels}
                            levelId={context.level.id}
                            handleRedirect={this.handleRedirect}
                            tcmSensors={context.tcmSensors}
                            lpSensors={context.lpSensors}
                            placeCameraSensors={context.placeCameraSensors}
                            isEdit={context.isEdit}
                            reload={this.reload}
                        />

                        <TopLeftControls
                            editMode={context.isEdit}
                            context={context}
                            current={this.state.current}
                            levelId={this.state.levelId}
                            handleButtonClick={this.handleButtonClick}
                            reload={this.reload}
                            needReload={context.needUpdate}
                            logicCountingSync={context.logicCountingSync}
                            calibration={context.level.calibrationPlace}
                            updateLogicCountingSync={this.updateLogicCountingSync}
                        />

                        <ActivityMap
                            levelId={context.level.id}
                            sensorStateDisplayed={context.sensorStateDisplayed}
                            toggleSensorState={this.toggleSensorState}
                            reloadCounters={this.reloadCounters}
                        />

                        {/* Affichage des différentes couches */}
                        {/* {this.state.current.matches('editPlaces') && ( */}
                        {context.sensorLayer && (
                            <PlacesLayer
                                levelId={context.level.id}
                                placeTypes={context.sensorPlaceTypes}
                                controllers={context.controllers}
                                reloadSensors={() => this.reload('sensors')}
                                sensors={context.tcmSensors}
                                lpSensors={context.lpSensors}
                                cameraSensors={context.placeCameraSensors}
                                cameras={this.state.cameras}
                                calibration={context.level.calibrationPlace}
                                calibrationCm={context.level.calibrationCm}
                                editMode={this.state.current.matches('editPlaces')}
                                isEdit={context.isEdit}
                                sensorStateDisplayed={context.sensorStateDisplayed}
                                service={this.service}
                                drawerInformation={this.state.drawerInformation}
                                openDrawer={this.openDrawer}
                                closeDrawer={this.closeDrawer}
                            />
                        )}

                        {context.displayLayer && !this.state.current.matches('loadDisplays') && (
                            <DisplaysLayer
                                levelId={context.level.id}
                                placeTypes={context.sensorPlaceTypes}
                                controllers={context.controllers}
                                reloadDisplays={this.reload}
                                displays={context.displays}
                                lpMatrixDisplayV2={context.lpMatrixDisplayV2}
                                editMode={context.isEdit}
                                isEdit={context.isEdit}
                                drawerInformation={this.state.drawerInformation}
                                openDrawer={this.openDrawer}
                                closeDrawer={this.closeDrawer}
                                service={this.service}
                                counters={this.state.counters}
                            />
                        )}

                        <SecuredFragment authorizedRoles={[rolesConstants.mapZones.VIEW_LIST]}>
                            {context.zoneLayer && (
                                <ZonesLayer
                                    levelId={context.level.id}
                                    reloadZones={this.reload}
                                    zones={context.zones}
                                    editMode={this.state.current.matches('editZones')}
                                    isEdit={context.isEdit}
                                />
                            )}
                        </SecuredFragment>

                        <SecuredFragment authorizedRoles={[rolesConstants.mapVehicleCounters.VIEW_LIST]}>
                            {context.passCountLayer && !this.state.current.matches('loadVehicleCounters') && (
                                <PassCountLayer
                                    levelId={context.level.id}
                                    reloadPassCount={this.reload}
                                    passCounts={context.vehicleCounters}
                                    editMode={this.state.current.matches('editPassCount')}
                                    isEdit={context.isEdit}
                                />
                            )}
                        </SecuredFragment>

                        {this.state.current.matches('calibration') && (
                            <CalibrationMenu levelId={context.level.id} reloadLevel={() => this.reload('placeType')} />
                        )}
                        {/* Switch between view and edit mode */}
                        <BottomLeftControls
                            service={this.service}
                            isEdit={context.isEdit}
                            current={this.state.current}
                        />
                    </Map>
                </Fragment>
            );
        } else {
            return (
                <Loader
                    style={{ marginTop: '25%', marginLeft: '50%' }}
                    size="lg"
                    vertical
                    content={<FormattedMessage id="app.loading" />}
                />
            );
        }
    }
}

export default withRouter(injectIntl(ParkingMap));
