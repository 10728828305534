import React from 'react';
import { Drawer } from 'rsuite';
import Camera from '../../../../../handlers/Camera/Camera';
import { CameraSensorMapInformation_I } from '../../../../../handlers/map/MapCameraSensor';
import { SensorPlaceType } from '../../../../../handlers/sensorPlaceType/SensorPlaceType';
import { CameraInformationCard } from '../../../../Cameras/Drawer/Cards/Information';
import { DeviceStateInformation } from '../../../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../../../IpCanElementsPage/Cards/DeviceStateInformation/deviceStateInformationGenerator';
import { MapPlaceCameraInformation } from './MapPlaceCameraInformation/MapPlaceCameraInformation';

type Props = {
    camera: Camera;
    mapPlaceCamera: CameraSensorMapInformation_I;
    open: boolean;
    onHide: () => void;
    placeTypes: SensorPlaceType[];
    service: any;
};

export const MapPlaceCameraDrawer = (props: Props) => {
    return (
        <Drawer backdrop={false} show={props.open} onHide={props.onHide}>
            <Drawer.Header>
                <Drawer.Title>
                    {props.mapPlaceCamera.label} - {props.mapPlaceCamera.placeCamera.getIdPlace()} (
                    {props.camera.getIp()} - {props.camera.getMacStr()})
                </Drawer.Title>
            </Drawer.Header>

            <Drawer.Body>
                <MapPlaceCameraInformation
                    mapCameraSensor={props.mapPlaceCamera}
                    placeTypes={props.placeTypes}
                    service={props.service}
                />

                <DeviceStateInformation
                    device={props.camera}
                    editMaintenanceStateUrl={MaintenanceStateInformationEditURL.IP_CAMERA_PLACE}
                />

                <CameraInformationCard camera={props.camera} />
            </Drawer.Body>
        </Drawer>
    );
};
