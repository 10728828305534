import React from 'react';
import { FlexboxGrid, Tag } from 'rsuite';
import noImage from '../../../style/assets/eye-slash-solid.svg';

export default function PlaceTypeCell(placeType) {
    if (!placeType) return null;
    const { label, image, imageType } = placeType;

    if (!image) {
        return (
            <div>
                <FlexboxGrid align="middle" justify="center">
                    <FlexboxGrid.Item>
                        <div
                            data-cy="placeType-image-vide"
                            style={{
                                height: 40,
                                background: '#f5f5f5',
                                marginTop: 2,
                                overflow: 'hidden',
                                display: 'inline-block',
                            }}>
                            <img
                                src={noImage}
                                style={{
                                    width: 44,
                                    backgroundColor: 'white',
                                    lineHeight: 1,
                                }}
                                alt="empty"
                            />
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Tag color="blue">{label}</Tag>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
        );
    } else {
        return (
            <div>
                <FlexboxGrid align="middle" justify="center">
                    <FlexboxGrid.Item>
                        <div
                            data-cy="placeType-image"
                            style={{
                                height: 40,
                                background: '#f5f5f5',
                                marginTop: 2,
                                overflow: 'hidden',
                                display: 'inline-block',
                            }}>
                            <img alt={`${label}-logo`} src={`data:image/${imageType};base64, ${image}`} width="44" />
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Tag color="blue">{label}</Tag>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
        );
    }
}
