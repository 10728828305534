import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { TableColumn } from 'react-data-table-component';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Redirect } from 'react-router-dom';
import { Panel, Tag, TagGroup } from 'rsuite';
import { User, UserInterface } from '../../../handlers/profile/User';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import ElementTable from '../../ReactDataTableComponent/ElementTable';

type Props = WrappedComponentProps;

type State = {
    users: User[];
    redirect: boolean;
    loading: boolean;
};

class UsersCards extends Component<Props, State> {
    columns: TableColumn<User>[];

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            redirect: false,
            loading: true,
        };

        this.handleClick = this.handleClick.bind(this);

        this.columns = [
            {
                name: this.props.intl.formatMessage({ id: 'users.name' }),
                center: true,
                cell: row => (
                    <div data-cy="users-lastName">
                        {row.getFirstName()} {row.getLastName()}
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({ id: 'users.profiles' }),
                center: true,
                cell: row => (
                    <TagGroup>
                        {row.getProfiles().map(profile => (
                            <Tag data-cy="users-role" color={profile.getName() === 'Admin' ? 'red' : 'violet'}>
                                {profile.getName()}
                            </Tag>
                        ))}
                    </TagGroup>
                ),
            },
            {
                name: this.props.intl.formatMessage({ id: 'users.loggedIn' }),
                center: true,
                cell: row => (
                    <div data-cy="users-isConnected">
                        <FontAwesomeIcon icon={faPlug} style={{ color: row.getLoggedIn() ? 'green' : 'red' }} />
                    </div>
                ),
            },
        ];
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get<UserInterface[]>('/users', { headers: authHeader() })
            .then(response =>
                this.setState({
                    loading: false,
                    users: response.data.map(user => new User(user)),
                })
            )
            .catch(err => {
                console.error(err);
            });
    }

    handleClick() {
        this.setState({
            redirect: true,
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/users" />;
        } else {
            return (
                <Loader show={this.state.loading}>
                    <Panel
                        header={this.props.intl.formatMessage({ id: 'dashboard.panel.users' })}
                        data-cy="dashboard-users-card"
                        onClick={this.handleClick}
                        className="dashboard-card-header hoverable"
                        bodyFill
                        shaded>
                        <ElementTable
                            columns={this.columns}
                            data={this.state.users}
                            progressPending={this.state.loading}
                        />
                    </Panel>
                </Loader>
            );
        }
    }
}

export default injectIntl(UsersCards);
