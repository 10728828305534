import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-leaflet';
import { Tag } from 'rsuite';
import { PlaceTextInformationType } from '.';
import PlaceCamera, { PlaceCameraState } from '../../../handlers/Camera/PlaceCamera';

type Props = {
    place: PlaceCamera;
    currentViewMode: PlaceTextInformationType;
};

type ChildrenProps = {
    place: PlaceCamera;
};

export const PlaceCameraTooltip = (props: Props) => {
    const generatePlaceCameraTooltipName = () => {
        return `${props.place.getLabel()}`;
    };

    if (props.currentViewMode === PlaceTextInformationType.NONE) {
        return null;
    }

    return (
        <Tooltip opacity={0.7} permanent direction="center">
            {props.currentViewMode === PlaceTextInformationType.NAME && generatePlaceCameraTooltipName()}
            {props.currentViewMode === PlaceTextInformationType.STATUS && (
                <PlaceCameraTooltipState place={props.place} />
            )}
            {props.currentViewMode === PlaceTextInformationType.FORCE && (
                <PlaceCameraTooltipForce place={props.place} />
            )}
        </Tooltip>
    );
};

const PlaceCameraTooltipState = (props: ChildrenProps) => {
    return (
        <>
            {props.place.getState() === PlaceCameraState.FREE && (
                <Tag color="green">
                    <FormattedMessage id="cameras.cameraPlace.state.free" />
                </Tag>
            )}
            {props.place.getState() === PlaceCameraState.OCCUPIED && (
                <Tag color="red">
                    <FormattedMessage id="cameras.cameraPlace.state.occupied" />
                </Tag>
            )}
            {props.place.getState() === PlaceCameraState.OVERSTAY && (
                <Tag color="orange">
                    <FormattedMessage id="cameras.cameraPlace.state.overstay" />
                </Tag>
            )}
            {props.place.getState() === PlaceCameraState.UNKNOWN && (
                <Tag color="yellow">
                    <FormattedMessage id="cameras.cameraPlace.state.unknown" />
                </Tag>
            )}
            <small className="margin-left-5">({props.place.getStatePrediction().toFixed(2)}%)</small>
        </>
    );
};

const PlaceCameraTooltipForce = (props: ChildrenProps) => {
    if (props.place.getIsForce()) {
        return (
            <>
                {props.place.getForceType() === PlaceCameraState.FREE && (
                    <span>
                        <FontAwesomeIcon icon={faLock} color="green" />
                    </span>
                )}
                {props.place.getForceType() === PlaceCameraState.OCCUPIED && (
                    <span>
                        <FontAwesomeIcon icon={faLock} color="red" />
                    </span>
                )}
                {props.place.getForceType() === PlaceCameraState.OVERSTAY && (
                    <span>
                        <FontAwesomeIcon icon={faLock} color="violet" />
                    </span>
                )}
                {props.place.getForceType() === PlaceCameraState.UNKNOWN && (
                    <span>
                        <FontAwesomeIcon icon={faLock} color="blue" />
                    </span>
                )}
            </>
        );
    } else {
        return (
            <Tag>
                <FormattedMessage id="cameras.cameraPlace.force.noForce" />
            </Tag>
        );
    }
};
