import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Panel } from 'rsuite';
import { Parking } from '../../../handlers/parking/Parking';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import OccupationGraph from '../../CountersPage/OccupationGraph';

type Props = {
    parking: Parking;
    loading: boolean;
} & WrappedComponentProps;

type State = {
    counter: Record<string, any> | undefined;
};

class ParkingStats extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            counter: undefined,
        };
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get(`/counters/parking/${this.props.parking.getId()}`, { headers: authHeader() })
            .then(counterParkingResponse => {
                this.setState({
                    counter: counterParkingResponse.data.counter,
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <Loader show={this.props.loading}>
                <Panel
                    className="dashboard-card-header"
                    header={`${this.props.parking.getName()} - ${this.props.intl.formatMessage({
                        id: 'dashboard.panel.dailyFillingRate',
                    })}`}
                    shaded
                    data-cy={'dashboard-parking-stats'}
                    style={{ height: window.innerHeight * 0.4 }}>
                    {this.state.counter && (
                        <OccupationGraph
                            height={window.innerHeight * 0.4 - 75}
                            counterId={this.state.counter.id}
                            displayLegend={false}
                            responsive
                        />
                    )}
                </Panel>
            </Loader>
        );
    }
}

export default injectIntl(ParkingStats);
