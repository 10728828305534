import React from 'react';
import { Layer, Rect, Stage } from 'react-konva';
import { FlexboxGrid, Tag } from 'rsuite';
import { FontValueInterface } from '..';

type Props = {
    font: FontValueInterface;
    isSelected: boolean;
    fontSize: number;
    index: number;
    isCopied: boolean;
};

const PREVIEW_SIZE = 80;

export const FontPreview = (props: Props) => {
    const squareWidth = PREVIEW_SIZE / props.font.value.length;
    const squareHeight = PREVIEW_SIZE / props.fontSize;

    let squareSize: number = PREVIEW_SIZE / props.fontSize;

    if (props.font.value.length > props.fontSize) {
        squareSize = PREVIEW_SIZE / props.font.value.length;
    }

    return (
        <FlexboxGrid
            justify="space-around"
            align="middle"
            className="padding-right-10 padding-left-10"
            style={{ backgroundColor: props.isSelected ? 'lightgreen' : '#FFF' }}>
            <FlexboxGrid.Item colspan={5}>
                <Tag color={props.isCopied ? 'orange' : 'blue'} className="bold">
                    {props.font.name}
                </Tag>
                <br />
                <Tag color="violet">({props.index})</Tag>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={10} className="padding-top-5 padding-bottom-5">
                <Stage width={squareSize * props.font.value.length} height={PREVIEW_SIZE}>
                    {squareWidth !== Infinity && squareWidth !== 0 && squareHeight !== Infinity && squareHeight !== 0 && (
                        <Layer>
                            {props.font.value.map((column, columnIndex) => {
                                return column.map((pixel, pixelIndex) => (
                                    <Rect
                                        x={columnIndex * squareSize}
                                        y={pixelIndex * squareSize}
                                        width={squareSize}
                                        height={squareSize}
                                        fill={pixel ? '#000' : '#FFF'}
                                    />
                                ));
                            })}
                        </Layer>
                    )}
                </Stage>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};
