import { faMapMarkerAlt, faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlexboxGrid, Message, Modal } from 'rsuite';
import PlaceCamera from '../../../handlers/Camera/PlaceCamera';
import placeCameraHistoryHandler from '../../../handlers/history/IPCameraHistoryHandler';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import HistorySortPart from '../../History/SortPart';
import PlaceCameraDescCell from '../../History/Table/placeCameraDescCell';
import PlaceCameraOptionCell from '../../History/Table/PlaceCameraOptionCell';
import HistoryTable from '../../ReactDataTableComponent/HistoryTable';

type Props = {
    show: boolean;
    onHide: () => void;
    placeCamera: PlaceCamera;
};

type FormValue = {
    user: string | null;
    before: Date | null;
    after: Date | null;
    nbElements: number;
};

type UserSelector = {
    label: string;
    value: string;
};

export const PlaceCameraHistoryModal = (props: Props) => {
    const intl = useIntl();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [history, setHistory] = React.useState<Array<any>>([]);
    const [hasError, setHasError] = React.useState<boolean>(false);
    const [users, setUsers] = React.useState<UserSelector[]>([]);
    const [formValues, setFormValues] = React.useState<FormValue>({
        user: null,
        before: null,
        after: null,
        nbElements: 100,
    });

    const columns: TableColumn<RowRecord>[] = [
        {
            name: intl.formatMessage({
                id: 'history.placeCamera.user',
            }),
            selector: 'user',
            sortable: true,
            cell: row => {
                if (row.user === 'MQTT') {
                    return <FontAwesomeIcon icon={faRobot} size="lg" />;
                } else {
                    return row.user;
                }
            },
        },
        {
            name: intl.formatMessage({
                id: 'history.placeCamera.createdAt',
            }),
            selector: 'createdAt',
            sortable: true,
        },
        {
            name: intl.formatMessage({
                id: 'history.placeCamera.placeCameraLabel',
            }),
            selector: 'placeCameraLabel',
            sortable: true,
        },
        {
            name: intl.formatMessage({
                id: 'history.placeCamera.desc',
            }),
            selector: 'desc',
            sortable: true,
            cell: row => PlaceCameraDescCell(row.desc),
        },
        {
            name: intl.formatMessage({
                id: 'history.placeCamera.option',
            }),
            selector: 'option',
            cell: row => PlaceCameraOptionCell(row.desc, row.option),
        },
    ];

    const loadHistory = () => {
        setIsLoading(true);
        setHasError(false);

        axiosService
            .getAxios()
            .post(
                '/historics/place-camera',
                {
                    nbResult: formValues.nbElements || 100,
                    user: formValues.user,
                    id: props.placeCamera.getId(),
                    before: formValues.before ? moment(formValues.before).toISOString() : null,
                    after: formValues.after ? moment(formValues.after).toISOString() : null,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(placeCameraHistoryResponse => {
                let users: Array<string> = [];

                const histories = placeCameraHistoryResponse.data.map(history => {
                    const placeCamera = new placeCameraHistoryHandler(history);

                    if (!users.includes(history.user)) {
                        users.push(history.user);
                    }

                    return placeCamera.getHistory();
                });

                setHistory(histories);
                setUsers(users.map(user => ({ label: user, value: user })));
            })
            .catch(err => {
                console.error(err);

                setHasError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setFormValues({
            user: null,
            before: null,
            after: null,
            nbElements: 100,
        });

        setAfter(null);
        setBefore(null);
        setNbElements(100);
        setUser(null);

        if (props.show) {
            loadHistory();
        }
    }, [props.show]);

    const setUser = user => {
        setFormValues({ ...formValues, user });
    };

    const setBefore = before => {
        setFormValues({ ...formValues, before });
    };

    const setAfter = after => {
        setFormValues({ ...formValues, after });
    };

    const setNbElements = nbElements => {
        setFormValues({ ...formValues, nbElements });
    };

    return (
        <Modal
            full
            backdrop="static"
            show={props.show}
            onHide={props.onHide}
            style={{ height: '95%' }}
            className="no-modal-padding">
            <Modal.Header style={{ backgroundColor: '#212121' }}>
                <HistorySortPart
                    users={users}
                    setUser={setUser}
                    setBefore={setBefore}
                    setAfter={setAfter}
                    valid={loadHistory}
                    setNbElements={setNbElements}
                />
            </Modal.Header>
            <Modal.Body>
                {hasError ? (
                    <Message type="error">
                        <FormattedMessage id="history.placeCamera.fetchHistoryError" />
                    </Message>
                ) : (
                    <Fragment>
                        <FlexboxGrid align="middle" justify="center">
                            <FlexboxGrid.Item>
                                <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="margin-right-5" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <h4
                                    data-cy="history-controller-label-name"
                                    className="text-center">{`${props.placeCamera.getLabel()}`}</h4>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <HistoryTable
                            columns={columns}
                            data={history}
                            progressPending={isLoading}
                            expandableRows
                            expandOnRowClicked
                            expandableRowDisabled={row => row.disabled}
                        />
                    </Fragment>
                )}
            </Modal.Body>
        </Modal>
    );
};
