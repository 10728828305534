import { parkingConstants } from '../constants';

type State = {
    isLoading: boolean;
    parkingList: Array<Record<string, any>>;
    error: string | undefined;
    fetchedParking: undefined | Record<string, any>;
    create_modal_openned: boolean;
    delete_modal_openned: boolean;
    update_modal_openned: boolean;
    view_drawer_openned: boolean;
    parkingDrawer: undefined | Record<string, any>;
};

const initialState = {
    isLoading: false,
    parkingList: [],
    error: undefined,
    fetchedParking: undefined,
    create_modal_openned: false,
    delete_modal_openned: false,
    update_modal_openned: false,
    view_drawer_openned: false,
    parkingDrawer: undefined,
};

export function parkings(state: State = initialState, action) {
    switch (action.type) {
        case parkingConstants.GET_ALL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case parkingConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                error: undefined,
                parkingList: action.parkings.map(parking => {
                    return { ...parking, isLoading: false };
                }),
                isLoading: false,
            };
        case parkingConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case parkingConstants.CREATE_REQUEST:
            return {
                ...state,
                parkingList: state.parkingList.concat({
                    ...action.parking,
                }),
                isLoading: true,
            };
        case parkingConstants.CREATE_SUCCESS:
            return {
                ...state,
                parkingList: state.parkingList.map(parking => {
                    if (parking.id === action.id) {
                        return { ...action.parking };
                    } else {
                        return parking;
                    }
                }),
                create_modal_openned: false,
                isLoading: false,
            };
        case parkingConstants.CREATE_FAILURE:
            return {
                ...state,
                parkingList: state.parkingList.filter(parking => parking.id !== action.id),
                create_modal_openned: false,
                isLoading: false,
            };
        case parkingConstants.UPDATE_REQUEST:
            return {
                ...state,
                parkingList: state.parkingList.map(parking =>
                    parking.id === action.id ? { ...parking, isLoading: true } : parking
                ),
            };
        case parkingConstants.UPDATE_SUCCESS:
            return {
                ...state,
                parkingList: state.parkingList.map(parking => {
                    if (parking.id === action.parking.id) {
                        let newParking = action.parking;
                        newParking.levels = parking.levels;
                        return { ...parking, ...action.parking, isLoading: false };
                    } else {
                        return parking;
                    }
                }),
                parkingDrawer: { ...state.parkingDrawer, ...action.parking },
                // TODO
            };
        case parkingConstants.UPDATE_FAILURE:
            return {
                ...state,
                parkingList: state.parkingList.map(parking => {
                    if (parking.id === action.id) {
                        return { ...parking, isLoading: false };
                    } else {
                        return parking;
                    }
                }),
            };
        case parkingConstants.DELETE_REQUEST:
            // add 'deleting:true' property to parking being deleted
            return {
                ...state,
                parkingList: state.parkingList.map(parking =>
                    parking.id === action.id ? { ...parking, isLoading: true } : parking
                ),
            };
        case parkingConstants.DELETE_SUCCESS:
            // remove deleted parking from state
            return {
                ...state,
                parkingList: state.parkingList.filter(parking => parking.id !== action.parking.id),
            };
        case parkingConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to parking
            return {
                ...state,
                parkingList: state.parkingList.map(parking => {
                    if (parking.id === action.id) {
                        // make copy of parking without 'deleting:true' property
                        const { ...parkingCopy } = parking;
                        // return copy of parking with 'deleteError:[error]' property
                        return { ...parkingCopy, deleteError: action.error };
                    }

                    return parking;
                }),
            };
        // case parkingConstants.GETONE_REQUEST:
        //     return {
        //         ...state,
        //         fetchedParking: {},
        //     };
        // case parkingConstants.GETONE_SUCCESS:
        //     return {
        //         ...state,
        //         fetchedParking: action.parking,
        //     };
        // case parkingConstants.GETONE_FAILURE:
        //     return {
        //         ...state,
        //         error: action.error,
        //     };
        case parkingConstants.OPEN_CREATE_MODAL:
            return {
                ...state,
                create_modal_openned: true,
                delete_modal_openned: false,
                update_modal_openned: false,
                view_drawer_openned: false,
                parkingDrawer: undefined,
            };
        case parkingConstants.CLOSE_CREATE_MODAL:
            return {
                ...state,
                create_modal_openned: false,
                delete_modal_openned: false,
                update_modal_openned: false,
                view_drawer_openned: false,
                parkingDrawer: undefined,
            };
        case parkingConstants.OPEN_VIEW_DRAWER_SUCCESS:
            return {
                ...state,
                view_drawer_openned: true,
                parkingDrawer: action.parking,
            };
        case parkingConstants.CLOSE_VIEW_DRAWER:
            return {
                ...state,
                view_drawer_openned: false,
                parkingDrawer: undefined,
            };
        case parkingConstants.OPEN_DELETE_MODAL_REQUEST:
            return {
                ...state,
                view_drawer_openned: false,
                parkingDrawer: undefined,
            };

        case parkingConstants.OPEN_DELETE_MODAL_SUCCESS:
            return {
                ...state,
                delete_modal_openned: true,
                parkingDrawer: action.parking,
            };
        case parkingConstants.OPEN_DELETE_MODAL_FAILURE:
            return {
                ...state,
                delete_modal_openned: false,
                parkingDrawer: undefined,
            };
        case parkingConstants.CLOSE_DELETE_MODAL:
            return {
                ...state,
                delete_modal_openned: false,
                parkingDrawer: undefined,
            };
        default:
            return state;
    }
}
