import { faRobot, faServer, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Message, Panel, Button, Col, FlexboxGrid, Alert } from 'rsuite';
import { saveAs } from 'file-saver';
import IpCanHistory from '../../handlers/history/IPCanHistoric.handler';
import { IpCan } from '../../handlers/ipcan/IpCan';
import { createIpCan } from '../../handlers/ipcan/IpCanHelper';
import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';
import PanelHeader from '../Custom/PanelHeader';
import HistoryTable from '../ReactDataTableComponent/HistoryTable';
import HistorySortPart from './SortPart';
import UpdateBusConfigExpand from './Table/Expandable/UpdateBusConfigExpand';
import IpCanDescCell from './Table/IpCanDescCell';
import IpCanOptionsCell from './Table/ipCanOptionsCell';

type Props = {
    intl: Record<string, any>;
} & WrappedComponentProps;

type State = {
    histories: Array<Record<string, any>>;
    isLoading: boolean;
    hasError: boolean;
    users: Array<Record<string, any>>;
    formValues: FormValues;
    ipcans: Array<IpCan>;
};

type FormValues = {
    user: string | null;
    before: Date | null;
    after: Date | null;
    nbElements: number;
};

class TCMDisplayHistory extends React.Component<Props, State> {
    columns: TableColumn<RowRecord>[];
    constructor(props) {
        super(props);

        this.state = {
            histories: [],
            isLoading: true,
            hasError: false,
            users: [],
            formValues: {
                user: null,
                before: null,
                after: null,
                nbElements: 100,
            },
            ipcans: [],
        };

        this.setUser = this.setUser.bind(this);
        this.setBefore = this.setBefore.bind(this);
        this.setAfter = this.setAfter.bind(this);
        this.setNbElements = this.setNbElements.bind(this);
        this.loadHistory = this.loadHistory.bind(this);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.user',
                }),
                selector: 'user',
                sortable: true,
                cell: row => {
                    if (row.user === 'MQTT') {
                        return <FontAwesomeIcon icon={faRobot} size="lg" />;
                    } else {
                        return row.user;
                    }
                },
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'history.ipcanModule.createdAt',
                }),
                selector: 'createdAt',

                sortable: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'history.ipcanModule.ipcanmoduleName',
                }),
                selector: 'ipcanmoduleName',
                sortable: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'history.ipcanModule.desc',
                }),
                selector: 'desc',
                sortable: true,
                cell: row => IpCanDescCell(row.desc),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'history.ipcanModule.option',
                }),
                selector: 'option',
                cell: row => IpCanOptionsCell(row.desc, row.option),
            },
        ];
    }

    componentDidMount() {
        this.loadHistory();
    }

    loadHistory() {
        this.setState({
            isLoading: true,
        });

        axiosService
            .getAxios()
            .post(
                '/historics/ipcanmodules',
                {
                    nbResult: this.state.formValues.nbElements || 100,
                    user: this.state.formValues.user,
                    after: this.state.formValues.after ? moment(this.state.formValues.after).toISOString() : null,
                    before: this.state.formValues.before ? moment(this.state.formValues.before).toISOString() : null,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(ipCanHistoryResponse => {
                let users: Array<string> = [];

                const histories = ipCanHistoryResponse.data.map(history => {
                    const tcmDisplayHistory = new IpCanHistory(history);

                    users.indexOf(tcmDisplayHistory.getUser()) === -1 && users.push(tcmDisplayHistory.getUser());

                    let formattedHistory = tcmDisplayHistory.getHistory();

                    if (
                        formattedHistory.desc !== 'update bus config' ||
                        (formattedHistory.desc === 'update bus config' && formattedHistory.option.error)
                    ) {
                        formattedHistory.disabled = true;
                    } else {
                        formattedHistory.disabled = false;
                    }

                    return formattedHistory;
                });

                axiosService
                    .getAxios()
                    .get('/ipcanmodules', { headers: authHeader() })
                    .then(ipCanResponse => {
                        this.setState({
                            ipcans: ipCanResponse.data.map(ipcan => createIpCan(ipcan)),
                            histories,
                            users: users.map(user => {
                                return { label: user, value: user };
                            }),
                        });
                    });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    setUser(user) {
        this.setState({
            formValues: { ...this.state.formValues, user },
        });
    }

    setBefore(before) {
        this.setState({
            formValues: { ...this.state.formValues, before: moment(before).add(1, 'hour').toDate() },
        });
    }

    setAfter(after) {
        this.setState({
            formValues: { ...this.state.formValues, after: moment(after).add(1, 'hour').toDate() },
        });
    }

    setNbElements(nbElements) {
        this.setState({
            formValues: { ...this.state.formValues, nbElements },
        });
    }

    exportipCanModuleHistory = () => {
        let allText = '';
        let nbLines = this.state.histories.length;
        let sortLines = this.state.formValues.nbElements;
        let sortUser = this.state.formValues.user;
        let sortBegining = moment(this.state.formValues.before).toISOString();
        let sortStop = moment(this.state.formValues.after).toISOString();
        let fileName = 'export-ipCanModuleHistory';

        if (nbLines > 0) {
            if ('-' + sortUser != '-null') {
                allText = allText + 'user:' + sortUser;
                fileName = fileName + '-user:' + sortUser;
            }
            if (sortStop + '-' + sortBegining != 'null-null') {
                allText = allText + ';start: ' + sortBegining + ';end: ' + sortStop;
                fileName = fileName + '-start:' + sortBegining + '-end:' + sortStop;
            } else {
                allText = allText + ';;';
            }

            fileName = fileName + '-lines:' + sortLines + '-date:' + moment().format('YYYY-MM-DD_HH:mm:ss') + '.csv';
            allText =
                allText +
                ';lines: ' +
                sortLines +
                '\nhistory.ipCanModule;nbLines: ' +
                nbLines +
                '\nuser;createdAt;ipCanModuleName;description;option\n';

            for (let i = 0; i < nbLines; i++) {
                allText =
                    allText +
                    this.state.histories[i].user +
                    ';' +
                    this.state.histories[i].createdAt +
                    ';' +
                    this.state.histories[i].ipcanmoduleName +
                    ';' +
                    this.state.histories[i].desc +
                    ';' +
                    JSON.stringify(this.state.histories[i].option) +
                    '\n';
            }

            const csvData = new Blob([allText], { type: 'text/csv;charset=utf-8;' });
            saveAs(csvData, fileName);

            Alert.success(this.props.intl.formatMessage({ id: 'ipCanModuleHistory.exportCSV.success' }));
        } else {
            Alert.error(this.props.intl.formatMessage({ id: 'ipCanModuleHistory.exportCSV.error' }));
        }
    };

    render() {
        if (this.state.hasError) {
            return (
                <Message type="error">
                    <FormattedMessage id="history.ipCanModule.fetchHistoryError" />
                </Message>
            );
        } else {
            return (
                <Panel
                    className="panel-big dashboard-card-header"
                    shaded
                    bordered
                    header={
                        <PanelHeader
                            icon={faServer}
                            title={this.props.intl.formatMessage({ id: 'history.ipCanModule' })}
                            buttons={[
                                <FlexboxGrid.Item componentClass={Col} xs={2.5}>
                                    <Button
                                        color="orange"
                                        block
                                        onClick={this.exportipCanModuleHistory}
                                        data-cy="history-export">
                                        <FontAwesomeIcon icon={faFileExport} />
                                        Exporter
                                    </Button>
                                </FlexboxGrid.Item>,
                            ]}
                        />
                    }
                    bodyFill>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <HistorySortPart
                            users={this.state.users}
                            setUser={this.setUser}
                            setBefore={this.setBefore}
                            setAfter={this.setAfter}
                            valid={this.loadHistory}
                            setNbElements={this.setNbElements}
                        />
                    </div>

                    <HistoryTable
                        columns={this.columns}
                        data={this.state.histories}
                        progressPending={this.state.isLoading}
                        expandableRows
                        expandOnRowClicked
                        expandableRowDisabled={row => row.disabled}
                        expandableRowsComponent={UpdateBusConfigExpand}
                        expandableRowsComponentProps={{ ipcans: this.state.ipcans }}
                    />
                </Panel>
            );
        }
    }
}

export default injectIntl(TCMDisplayHistory);
