import { Level, LevelInterface } from '../Level/Level';

export class Parking {
    private _id: number;
    private _name: string;
    private _description: string;
    private _createdAt: Date;
    private _updatedAt: Date;
    private _createdBy: string;
    private _updatedBy: string;
    private _imageType: string;
    private _image?: any;
    private _levels: Level[];

    constructor(parking: ParkingInterface) {
        this._id = parking.id;
        this._name = parking.name;
        this._description = parking.description;
        this._createdAt = parking.createdAt;
        this._updatedAt = parking.updatedAt;
        this._createdBy = parking.createdBy;
        this._updatedBy = parking.updatedBy;
        this._imageType = parking.imageType;
        this._image = parking.image;
        this._levels = parking.levels.map(level => new Level(level));
    }

    public getId(): number {
        return this._id;
    }

    public getName(): string {
        return this._name;
    }

    public getDescription(): string {
        return this._description;
    }

    public getCreatedAt(): Date {
        return this._createdAt;
    }

    public getUpdatedAt(): Date {
        return this._updatedAt;
    }

    public getCreatedBy(): string {
        return this._createdBy;
    }

    public getUpdatedBy(): string {
        return this._updatedBy;
    }

    public getImageType(): string {
        return this._imageType;
    }

    public getImage(): any | undefined {
        return this._image;
    }

    getBufferImage(): string | undefined {
        if (!this._image) {
            return undefined;
        }

        return Buffer.from(this._image, 'base64').toString('base64');
    }

    public getLevels(): Level[] {
        return this._levels;
    }
}

export interface ParkingInterface {
    id: number;
    name: string;
    description: string;
    createdAt: Date;
    updatedAt: Date;
    createdBy: string;
    updatedBy: string;
    imageType: string;
    image?: any;
    levels: LevelInterface[];
}
