import { authConstants } from '../constants';

const user = JSON.parse(localStorage.getItem('user') || '{}');

const initialState = {
    loggingIn: false,
    loggedIn: user.email ? true : false,
    loggingOut: false,
    user: user,
};

export function auth(state = initialState, action) {
    switch (action.type) {
        // LOGIN PART
        case authConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggingIn: false,
                user: action.user,
                loggedIn: true,
            };
        case authConstants.LOGIN_FAILURE:
            return {
                ...state,
                loggingIn: false,
                user: undefined,
                loggedIn: false,
            };

        // LOGOUT PART
        case authConstants.LOGOUT_REQUEST:
            return {
                ...state,
                loggingOut: true,
            };
        case authConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                loggingOut: false,
                user: undefined,
                loggedIn: false,
            };
        // case authConstants.LOGOUT_FAILURE:
        //     return {
        //         ...state,
        //         loggingOut: false,
        //     };
        default:
            return state;
    }
}
