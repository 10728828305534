import { faCar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { IntlShape } from 'react-intl';

export enum CameraYoloObjectTypes {
    // Person = 0,
    // Bicycle = 1,
    Car = 0,
    // Motorbike = 3,
    // Bus = 5,
    // Truck = 7,
    // TafficLight = 9,
    // StopSign = 11,
    // ParkingMeter = 12,
}

export const generateYoloObjectValue = (intl: IntlShape, type: number): ReactElement => {
    switch (type) {
        // case CameraYoloObjectTypes.Person:
        //     return <FontAwesomeIcon icon={faMale} />;
        // case CameraYoloObjectTypes.Bicycle:
        //     return <FontAwesomeIcon icon={faBicycle} />;
        case CameraYoloObjectTypes.Car:
            return <FontAwesomeIcon icon={faCar} />;
        // case CameraYoloObjectTypes.Motorbike:
        //     return <FontAwesomeIcon icon={faMotorcycle} />;
        // case CameraYoloObjectTypes.Bus:
        //     return <FontAwesomeIcon icon={faBusAlt} />;
        // case CameraYoloObjectTypes.Truck:
        //     return <FontAwesomeIcon icon={faTruckMoving} />;
        // case CameraYoloObjectTypes.TafficLight:
        //     return <FontAwesomeIcon icon={faTrafficLight} />;
        // case CameraYoloObjectTypes.StopSign:
        //     return <span>{intl.formatMessage({ id: 'camera.yolo.object.type.stopSign' })}</span>;
        // case CameraYoloObjectTypes.ParkingMeter:
        //     return <FontAwesomeIcon icon={faMoneyBillAlt} />;
        default:
            return <span>{type}</span>;
    }
};

export const CameraYoloObjectTypesSelector = (intl: IntlShape) => [
    // {
    //     label: intl.formatMessage({ id: 'camera.yolo.object.type.person' }),
    //     value: CameraYoloObjectTypes.Person,
    //     icon: faMale,
    // },
    // {
    //     label: intl.formatMessage({ id: 'camera.yolo.object.type.bicycle' }),
    //     value: CameraYoloObjectTypes.Bicycle,
    //     icon: faBicycle,
    // },
    {
        label: intl.formatMessage({ id: 'camera.yolo.object.type.car' }),
        value: CameraYoloObjectTypes.Car,
        icon: faCar,
    },
    // {
    //     label: intl.formatMessage({ id: 'camera.yolo.object.type.motorbike' }),
    //     value: CameraYoloObjectTypes.Motorbike,
    //     icon: faMotorcycle,
    // },
    // {
    //     label: intl.formatMessage({ id: 'camera.yolo.object.type.bus' }),
    //     value: CameraYoloObjectTypes.Bus,
    //     icon: faBusAlt,
    // },
    // {
    //     label: intl.formatMessage({ id: 'camera.yolo.object.type.truck' }),
    //     value: CameraYoloObjectTypes.Truck,
    //     icon: faTruckMoving,
    // },
    // {
    //     label: intl.formatMessage({ id: 'camera.yolo.object.type.trafficLight' }),
    //     value: CameraYoloObjectTypes.TafficLight,
    //     icon: faTrafficLight,
    // },
    // {
    //     label: intl.formatMessage({ id: 'camera.yolo.object.type.stopSign' }),
    //     value: CameraYoloObjectTypes.StopSign,
    // },
    // {
    //     label: intl.formatMessage({ id: 'camera.yolo.object.type.parkingMeter' }),
    //     value: CameraYoloObjectTypes.ParkingMeter,
    //     icon: faMoneyBillAlt,
    // },
];
