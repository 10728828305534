import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Col, FlexboxGrid, Panel } from 'rsuite';
import { LPSensor } from '../../../handlers/ipCanDevices/LPSensor';
import TCMSensor from '../../../handlers/ipCanDevices/TCMSensor';

type Props = {
    sensors: Array<LPSensor> | Array<TCMSensor>;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    loading: boolean;
};
class SensorsDetectionState extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    render() {
        let donutSize = '100%';

        const state = {
            total: 0,
            free: { total: 0, percent: 0 },
            occupied: { total: 0, percent: 0 },
            overstayFree: { total: 0, percent: 0 },
            overstayOccupied: { total: 0, percent: 0 },
            unknow: { total: 0, percent: 0 },
        };

        const dataDoughnut = {
            title: '',
            datasets: [
                {
                    data: [0, 0, 0, 0, 0],
                    backgroundColor:
                        this.props.sensors[0] instanceof LPSensor
                            ? ['#f44336', '#008000', '#FFA500', '#808080']
                            : ['#f44336', '#008000', '#FF00F3', '#808080'],
                },
            ],
            labels: [
                this.props.intl.formatMessage({ id: 'ipCanElements.sensors.detectionState.occupied' }),
                this.props.intl.formatMessage({ id: 'ipCanElements.sensors.detectionState.free' }),
                this.props.intl.formatMessage({ id: 'ipCanElements.sensors.detectionState.overstay' }),
                this.props.intl.formatMessage({ id: 'ipCanElements.sensors.detectionState.unknown' }),
            ],
        };

        // ---
        for (let s in this.props.sensors) {
            let sensor = this.props.sensors[s];

            if (sensor instanceof LPSensor) {
                if (sensor.detectionState === 1) state.free.total++;
                else if (sensor.detectionState === 2) state.occupied.total++;
                else if (sensor.detectionState === 3) state.overstayOccupied.total++;
                else state.unknow.total++;
            } else {
                //---
                if (sensor.detectionState === 1) state.free.total++;
                else if (sensor.detectionState === 2) state.occupied.total++;
                else if (sensor.detectionState === 3) state.overstayFree.total++;
                else if (sensor.detectionState === 4) state.overstayOccupied.total++;
                else state.unknow.total++;
            }

            //---
            state.total++;
        }

        //---
        state.unknow.percent = parseFloat(((state.unknow.total / state.total) * 100).toFixed(1));
        state.free.percent = parseFloat(((state.free.total / state.total) * 100).toFixed(1));
        state.occupied.percent = parseFloat(((state.occupied.total / state.total) * 100).toFixed(1));
        state.overstayFree.percent = parseFloat(((state.overstayFree.total / state.total) * 100).toFixed(1));
        state.overstayOccupied.percent = parseFloat(((state.overstayOccupied.total / state.total) * 100).toFixed(1));
        //---
        dataDoughnut.datasets[0].data[0] = state.occupied.total;
        dataDoughnut.datasets[0].data[1] = state.free.total;
        dataDoughnut.datasets[0].data[2] = state.overstayOccupied.total;
        dataDoughnut.datasets[0].data[3] = state.unknow.total;

        return (
            <Loader show={this.props.isLoading}>
                <Panel
                    data-cy="dashboard-sensors-cardDetectionState"
                    className={'dashboard-card-header hoverable'}
                    header={<FormattedMessage id="sensors.cardDetectionState.title" />}
                    shaded>
                    <FlexboxGrid align="middle" justify="space-between" style={{ height: donutSize }}>
                        <FlexboxGrid.Item componentClass={Col} colspan={12} xs={8} style={{ height: donutSize }}>
                            <Doughnut
                                data={dataDoughnut}
                                // height={'100%'}
                                // width={donutSize}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    aspectRatio: 1,
                                    legend: {
                                        display: false,
                                    },
                                }}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} colspan={24} xs={8}>
                            <div
                                data-cy="sensors-cardDectectionState-free"
                                className="parking-legend-title"
                                style={{ color: '#008000' }}>
                                <FormattedMessage id="sensors.cardDetectionState.free" />
                            </div>
                            <div data-cy="sensors-cardDectectionState-free-value" className="parking-legend-value">
                                {state.free.total} <FormattedMessage id="sensors.cardDetectionState.sensors" />
                                <span className="margin-left-5 small free">({state.free.percent}%)</span>
                            </div>

                            <div
                                data-cy="sensors-cardDectectionState-occupied"
                                className="parking-legend-title"
                                style={{ color: '#f44336' }}>
                                <FormattedMessage id="sensors.cardDetectionState.occupied" />
                            </div>
                            <div data-cy="sensors-cardDectectionState-occupied-value" className="parking-legend-value">
                                {state.occupied.total} <FormattedMessage id="sensors.cardDetectionState.sensors" />
                                <span className="margin-left-5 small occupied">({state.occupied.percent}%)</span>
                            </div>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} xs={8}>
                            <div
                                data-cy="sensors-cardDectectionState-overstay"
                                className="parking-legend-title"
                                style={{ color: this.props.sensors[0] instanceof LPSensor ? '#FFA500' : '#FF00F3' }}>
                                <FormattedMessage id="sensors.cardDetectionState.overstayOccupied" />
                            </div>
                            <div data-cy="sensors-cardDectectionState-overstay-value" className="parking-legend-value">
                                {state.overstayOccupied.total}{' '}
                                <FormattedMessage id="sensors.cardDetectionState.sensors" />
                                <span
                                    className="margin-left-5 small"
                                    style={{
                                        color: this.props.sensors[0] instanceof LPSensor ? '#FFA500' : '#FF00F3',
                                    }}>
                                    ({state.overstayOccupied.percent}%)
                                </span>
                            </div>
                            <div
                                data-cy="sensors-cardDectectionState-unknow"
                                className="parking-legend-title"
                                style={{ color: '#808080' }}>
                                <FormattedMessage id="sensors.cardDetectionState.unknow" />
                            </div>
                            <div data-cy="sensors-cardDectectionState-unknow-value" className="parking-legend-value">
                                {state.unknow.total} <FormattedMessage id="sensors.cardDetectionState.sensors" />
                                <span className="margin-left-5 small" style={{ color: '#808080' }}>
                                    ({state.unknow.percent}%)
                                </span>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Panel>
            </Loader>
        );
    }
}

export default injectIntl(SensorsDetectionState);
