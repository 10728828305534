import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Whisper } from 'rsuite';

export default function tcmCmdBus() {
    return (
        <Whisper
            placement="right"
            trigger="hover"
            speaker={
                <Tooltip>
                    <FormattedMessage id="history.tcmCmdBus" />
                </Tooltip>
            }>
            <FontAwesomeIcon icon={faTerminal} size="lg" style={{ color: '#2184da' }} />
        </Whisper>
    );
}
