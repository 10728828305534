import { faEllipsisH, faEye, faSitemap, faSyncAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, ButtonToolbar, Popover, Tooltip, Whisper } from 'rsuite';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';

export default function LPMatrixDisplayV2_busInformation(onClick, bus, ipCan, online: number) {
    return (
        <Whisper
            placement="top"
            trigger="click"
            speaker={<MenuPopover onClick={onClick} bus={bus} ipCan={ipCan} online={online} />}>
            <Button size="xs" color="blue" data-cy="ipcan-element-bus-action">
                <FontAwesomeIcon icon={faEllipsisH} />
            </Button>
        </Whisper>
    );
}

const MenuPopover = ({ onClick, bus, ipCan, online, ...rest }) => (
    <Popover {...rest} full>
        <ButtonToolbar>
            <ButtonGroup data-cy="ipcan-element-actions">
                <SecuredFragment key="informations.enumeration" authorizedRoles={[rolesConstants.controller.ENUM_BUS]}>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="controller.busInformations.actions.enumeration" />
                            </Tooltip>
                        }>
                        <Button data-cy="ipcan-element-bus-enumeration" color="cyan" onClick={() => onClick(1, bus)}>
                            <FontAwesomeIcon icon={faSitemap} />
                        </Button>
                    </Whisper>
                </SecuredFragment>

                <SecuredFragment
                    key="informations.reset_errors"
                    authorizedRoles={[rolesConstants.controller.COMMAND_TCM]}>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="controller.busInformations.actions.resetErrors" />
                            </Tooltip>
                        }>
                        <Button data-cy="ipcan-element-bus-resetErrors" color="yellow" onClick={() => onClick(2)}>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </Button>
                    </Whisper>
                </SecuredFragment>

                <SecuredFragment
                    key="informations.commandTCM"
                    authorizedRoles={[rolesConstants.lpMatrixDisplayV2.VIEW_LIST]}>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="controller.busInformations.actions.showBus" />
                            </Tooltip>
                        }>
                        <Button data-cy="ipcan-element-bus-view" color="green" onClick={() => onClick(3, bus, ipCan)}>
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </Whisper>
                </SecuredFragment>

                <SecuredFragment key="informations.deleteBus" authorizedRoles={[rolesConstants.controller.DELETE_BUS]}>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="controller.busInformations.actions.destroyBus" />
                            </Tooltip>
                        }>
                        <Button
                            data-cy="ipcan-element-bus-destroy"
                            color="red"
                            onClick={() => onClick(4, bus, ipCan)}
                            disabled={online !== 0}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Whisper>
                </SecuredFragment>

                <Whisper
                    trigger="hover"
                    placement="top"
                    speaker={
                        <Tooltip>
                            <FormattedMessage id="controller.busInformations.actions.moreActions" />
                        </Tooltip>
                    }>
                    <Button data-cy="ipcan-element-bus-moreActions" color="blue" onClick={() => onClick(5, bus, ipCan)}>
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </Button>
                </Whisper>
            </ButtonGroup>
        </ButtonToolbar>
    </Popover>
);
