export class TCMSensorEventForceSensor {
    protected _forceType: string;
    protected _color: string;

    constructor(forceType_, color_) {
        this._forceType = forceType_;
        this._color = color_;
    }

    public get forceType() {
        return this._forceType;
    }

    public get color() {
        return this._color;
    }

    public set forceType(forceType_: string) {
        if (this._forceType !== forceType_) this._forceType = forceType_;
    }

    public set color(color_: string) {
        if (this._color !== color_) this._color = color_;
    }

    setEventForce(type: string, color: string) {
        this.forceType = type;
        this.color = color;
    }
}
