import { faCheck, faSquareFull, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

type Props = {
    intl: Record<string, any>;
    option: Record<string, any>;
} & WrappedComponentProps;

class ForceLPSensor extends React.Component<Props> {
    constructor(props) {
        super(props);

        this.generateForceType = this.generateForceType.bind(this);
    }

    generateForceType(forceType) {
        switch (forceType) {
            case 0:
                return this.props.intl.formatMessage({
                    id: 'lpSensor.force.noForce',
                });
            case 1:
                return this.props.intl.formatMessage({
                    id: 'lpSensor.force.free',
                });
            case 2:
                return this.props.intl.formatMessage({
                    id: 'lpSensor.force.occupied',
                });
            case 3:
                return this.props.intl.formatMessage({
                    id: 'lpSensor.force.overstayOccupied',
                });
            case 4:
                return this.props.intl.formatMessage({
                    id: 'lpSensor.force.reserved',
                });
            case 10:
                return this.props.intl.formatMessage({
                    id: 'lpSensor.force.color',
                });
            default:
                return null;
        }
    }

    render() {
        return (
            <div>
                <div data-cy="historic-lpSensor-isForce">
                    <FormattedMessage id="lpSensor.history.isForce" />
                    <span className="margin-left-5">-</span>
                    <FontAwesomeIcon
                        className="margin-left-5"
                        icon={this.props.option.isForce ? faCheck : faTimes}
                        color={this.props.option.isForce ? 'green' : 'red'}
                    />
                </div>
                <div data-cy="historic-lpSensor-forceState">
                    <FormattedMessage id="lpSensor.history.forceState" />
                    <span className="margin-left-5">-</span>
                    <span className="margin-left-5">
                        {this.generateForceType(this.props.option.forceType.forceType)}
                    </span>
                    {this.props.option.forceType.forceType === 10 && (
                        <FontAwesomeIcon
                            className={'margin-left-5'}
                            icon={faSquareFull}
                            style={{
                                color: `rgb(${this.props.option.forceType.color.red}, ${this.props.option.forceType.color.green}, ${this.props.option.forceType.color.blue})`,
                            }}
                        />
                    )}
                </div>
                <div data-cy="historic-lpSensor-endForceTime">
                    <FormattedMessage id="lpSensor.history.endForceTime" />
                    <span className="margin-left-5">-</span>
                    <span className="margin-left-5">
                        {moment(this.props.option.endForceTime).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </div>
            </div>
        );
    }
}

export default injectIntl(ForceLPSensor);
