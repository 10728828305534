import { Col, FlexboxGrid, Progress } from 'rsuite';
import ProgressComponent from './ProgressComponent';

type Props = {
    counterValue: any;
    sensorPlaceTypes: any[];
    key: string;
};

type CounterByPlaceType = {
    placeTypeId: number;
    image: string;
    imageType: string;
    color: string;
    occupied: number;
    free: number;
    total: number;
    counters: {
        label: string;
        occupied: number;
        free: number;
        total: number;
    }[];
};

export const SensorPlaceTypesProgress = (props: Props) => {
    const counterByPlaceType: CounterByPlaceType[] = [];

    {
        Object.keys(props.counterValue).map(key => {
            if (key !== 'all') {
                const currentSensorPlaceType = props.sensorPlaceTypes.find(
                    sensorPlaceType => sensorPlaceType.id === parseInt(key)
                );

                if (currentSensorPlaceType) {
                    const existingPlaceTypeIndex = counterByPlaceType.findIndex(counter => {
                        return counter.placeTypeId === currentSensorPlaceType.placeType.id;
                    });
                    if (existingPlaceTypeIndex === -1) {
                        counterByPlaceType.push({
                            placeTypeId: currentSensorPlaceType.placeType.id,
                            image: currentSensorPlaceType.placeType.image,
                            imageType: currentSensorPlaceType.placeType.imageType,
                            color: currentSensorPlaceType.scenes[0].color,
                            occupied: props.counterValue[key].occupied,
                            free: props.counterValue[key].free,
                            total: props.counterValue[key].total,
                            counters: [
                                {
                                    label: currentSensorPlaceType.label,
                                    occupied: props.counterValue[key].occupied,
                                    free: props.counterValue[key].free,
                                    total: props.counterValue[key].total,
                                },
                            ],
                        });
                    } else {
                        counterByPlaceType[existingPlaceTypeIndex].occupied += props.counterValue[key].occupied;
                        counterByPlaceType[existingPlaceTypeIndex].free += props.counterValue[key].free;
                        counterByPlaceType[existingPlaceTypeIndex].total += props.counterValue[key].total;
                        counterByPlaceType[existingPlaceTypeIndex].counters.push({
                            label: currentSensorPlaceType.label,
                            occupied: props.counterValue[key].occupied,
                            free: props.counterValue[key].free,
                            total: props.counterValue[key].total,
                        });
                    }
                }
            }
        });
    }

    if (counterByPlaceType.length === 0) return null;

    return (
        <>
            {counterByPlaceType.map(placeType => {
                return (
                    <>
                        <FlexboxGrid
                            align="middle"
                            justify="space-between"
                            style={{ backgroundColor: 'rgba(33, 33, 33, 0.35)' }}>
                            <FlexboxGrid.Item componentClass={Col} xs={5} className="text-center">
                                <img
                                    className="text-center"
                                    src={`data:image/${placeType.imageType};base64,${placeType.image}`}
                                    alt="placeType"
                                    height={20}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={4}>
                                <div
                                    style={{
                                        height: 'fit-content',
                                        width: '100%',
                                        backgroundColor: '#F44336',
                                        color: '#FFF',
                                    }}>
                                    <p className="text-center bold">
                                        {placeType.occupied}{' '}
                                        <span className="small-text-white">
                                            {parseInt(((placeType.occupied / placeType.total) * 100).toFixed(0)) || 0}%
                                        </span>
                                    </p>
                                </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={1}>
                                <p className="text-center"> - </p>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={3}>
                                <div
                                    style={{
                                        height: 'fit-content',
                                        width: '100%',
                                        backgroundColor: placeType.color || '#008000',
                                        color: '#FFF',
                                    }}>
                                    <p className="text-center bold">{placeType.free}</p>
                                </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={9}>
                                <Progress.Line
                                    className="progress-shadow"
                                    showInfo={false}
                                    strokeColor="#F44336"
                                    trailColor={placeType.color || '#008000'}
                                    percent={parseInt(((placeType.occupied / placeType.total) * 100).toFixed(0)) || 0}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={2}>
                                <p className="bold text-center text-white">{placeType.total}</p>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        {placeType.counters.length > 1 &&
                            placeType.counters.map(counter => {
                                return (
                                    <ProgressComponent
                                        key={counter.label}
                                        label={counter.label}
                                        total={counter.total}
                                        free={counter.free}
                                        occupied={counter.occupied}
                                        color={placeType.color}
                                    />
                                );
                            })}
                    </>
                );
            })}
        </>
    );
};
