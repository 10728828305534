import React from 'react';
import { Handle, NodeComponentProps, Position } from 'react-flow-renderer';
import PreviewTopologyPart from '../../../../../../../IpCanElementsPage/LPMatrixDisplayV2Config/Nodes/TopologyNode/PreviewTopologyPart';
import { LPMatrixDisplayChartTopologyNodeType } from '../LPMatrixDisplayChartTopologyNode';

type Props = NodeComponentProps<LPMatrixDisplayChartTopologyNodeType>;

export const LPMatrixDisplayChartTopologyNodeComponent = (props: Props) => {
    return (
        <div className={`matrix-display-node ${props.data.isForce && 'topology-forced'}`}>
            <Handle type="source" position={Position.Right} style={{ background: '#555' }} isConnectable={false} />

            <Handle
                type="target"
                id="target-triggers"
                position={Position.Top}
                style={{ background: '#555' }}
                isConnectable={false}
            />

            <Handle
                type="source"
                id="source-trigger-0"
                position={Position.Bottom}
                style={{ background: '#555', left: '20%' }}
                isConnectable={false}
            />

            <Handle
                type="source"
                id="source-trigger-1"
                position={Position.Bottom}
                style={{ background: '#555', left: '40%' }}
                isConnectable={false}
            />

            <Handle
                type="source"
                id="source-trigger-2"
                position={Position.Bottom}
                style={{ background: '#555', left: '60%' }}
                isConnectable={false}
            />

            <Handle
                type="source"
                id="source-trigger-3"
                position={Position.Bottom}
                style={{ background: '#555', left: '80%' }}
                isConnectable={false}
            />

            <h5 className="text-center margin-bottom-10">{props.data.label}</h5>

            <PreviewTopologyPart display={props.data.display} topology={props.data.topology} />
        </div>
    );
};
