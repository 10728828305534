import { faCheck, faLock, faTimes, faTrash, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, ButtonGroup, Icon, IconButton, Panel } from 'rsuite';
import { LPSensor } from '../../handlers/ipCanDevices/LPSensor';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import LPSensorDrawer from './Drawer/LPSensor';
import CopySensorModal from './LPSensors/CopySensorModal';
import DeleteLPSensorModal from './LPSensors/DeleteLPSensorModal';
import ForceLPSensorModal from './LPSensors/ForceLPSensorModal';
import detectionStateCell from './Table/detectionStateCell';
import { filterCell } from './Table/filterCell';
import loginStateCell from './Table/loginStateCell';
import { lpSensorForceCell } from './Table/lpSensorForceCell';
import { maintenanceStateInformation } from './Table/maintenanceStateInformation';
import usHeightCell from './Table/usHeightCell';
import usSensCell from './Table/usSensCell';

type Props = {
    isLoading: boolean;
    sensors: Array<LPSensor>;
    reloadDevices: Function;
    ipCanId: number;
    ipCanLabel?: string;
} & WrappedComponentProps;

type State = {
    sensorDrawerOpen: boolean;
    selectedSensor: LPSensor | undefined;
    sensorsChecked: LPSensor[];
    showDeleteModal: boolean;
    showForceModal: boolean;
    toggledClearRows: boolean;
    isLoading: boolean;
    sensorToCopy: LPSensor | null;
    sensorsToCopyOn: Array<LPSensor>;
    showSensorCopyModal: boolean;
};

class LPSensorsTable extends React.Component<Props, State> {
    columns: TableColumn<LPSensor>[];

    constructor(props: Props) {
        super(props);

        this.state = {
            sensorDrawerOpen: false,
            selectedSensor: undefined,
            sensorsChecked: [],
            showDeleteModal: false,
            showForceModal: false,
            toggledClearRows: false,
            isLoading: false,
            sensorToCopy: null,
            sensorsToCopyOn: [],
            showSensorCopyModal: false,
        };

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.online',
                }),
                selector: row => row.online,
                center: true,
                sortable: true,
                grow: 2,
                cell: row => loginStateCell(row.online, row.lastOnlineStateDateFormatted),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.detectionState',
                }),
                center: true,
                grow: 2,
                sortable: true,
                selector: row => row.detectionState,
                cell: row => detectionStateCell(row.detectionState, row.detectionStateTimeFormatted),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.maintenanceState',
                }),
                selector: row => row.getMaintenanceState(),
                center: true,
                sortable: true,
                grow: 2,
                cell: row => (
                    <SecuredFragment authorizedRoles={[rolesConstants.LPSensor.UPDATE_MAINTENANCE_STATE]}>
                        {maintenanceStateInformation(row.getMaintenanceState(), row.getNotepad())}
                    </SecuredFragment>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.deviceId',
                }),
                selector: row => row.deviceId,
                sortable: true,
                center: true,
                grow: 1,
                cell: row => <div data-cy="lpSensor-deviceId">{row.deviceId}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.softwareVersion',
                }),
                center: true,
                grow: 1,
                cell: row => <div data-cy="lpSensor-softwareVersion">{row.informations.softwareVersion}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.voltage',
                }),
                center: true,
                grow: 1,
                cell: row => <div data-cy="lpSensor-voltage">{`${row.informations.voltage / 10} V`}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.usHeight',
                }),
                center: true,
                grow: 2,
                cell: row => usHeightCell(row.us1Config.height, row.us2Config.height),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.usSens',
                }),
                center: true,
                grow: 2,
                cell: row => usSensCell(row.us1Config.sens, row.us2Config.sens),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.filterFree',
                }),
                center: true,
                grow: 2,
                cell: row =>
                    filterCell(
                        row.filterConfiguration.maskFree,
                        row.filterConfiguration.filter.filter.freeToOccupied,
                        row.filterConfiguration.filter.median.freeToOccupied
                    ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.filterOccupied',
                }),
                center: true,
                grow: 2,
                cell: row =>
                    filterCell(
                        row.filterConfiguration.maskOccupied,
                        row.filterConfiguration.filter.filter.occupiedToFree,
                        row.filterConfiguration.filter.median.occupiedToFree
                    ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.isForce',
                }),
                center: true,
                grow: 2,
                cell: row => lpSensorForceCell(row.isForce, row.forceType, row.endForceTimeFormatted),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.copy',
                }),
                center: true,
                grow: 1,
                button: true,
                cell: row => {
                    if (this.state.sensorToCopy?.id === row.id) {
                        return (
                            <ButtonGroup size="sm">
                                <Button color="red" onClick={() => this.unsetCopyMode()}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    color="green"
                                    disabled={this.state.sensorsToCopyOn.length === 0}
                                    onClick={() => this.showSensorCopyModal()}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        );
                    } else if (!this.state.sensorToCopy) {
                        return (
                            <IconButton
                                data-cy="lpSensor-copy"
                                color="blue"
                                icon={<Icon icon="copy" />}
                                size="sm"
                                onClick={() => this.setCopyMode(row)}
                            />
                        );
                    } else {
                        return null;
                    }
                },
            },
        ];
    }

    showForceModal = event => {
        event.stopPropagation();

        this.setState({
            showForceModal: true,
        });
    };

    hideForceModal = () => {
        this.setState({
            showForceModal: false,
            toggledClearRows: false,
        });
    };

    showDeleteModal = event => {
        event.stopPropagation();

        this.setState({
            showDeleteModal: true,
        });
    };

    hideDeleteModal = (shouldReloadDevices: boolean = false) => {
        this.setState({
            showDeleteModal: false,
            sensorsChecked: [],
            toggledClearRows: true,
        });

        if (shouldReloadDevices) {
            this.props.reloadDevices();
        }
    };

    selectedRowsChange = ({ selectedRows }) => {
        if (this.state.sensorToCopy) {
            this.setState({
                sensorsToCopyOn: selectedRows,
            });
        } else {
            this.setState({
                sensorsChecked: selectedRows,
            });
        }
    };

    clickRow = (row: RowRecord) => {
        const sensor = row as LPSensor;
        this.setState({
            selectedSensor: sensor,
            sensorDrawerOpen: true,
        });
    };

    setCopyMode = id => {
        this.setState({
            sensorToCopy: id,
            toggledClearRows: !this.state.toggledClearRows,
        });
    };

    unsetCopyMode = () => {
        this.setState({
            sensorToCopy: null,
            toggledClearRows: !this.state.toggledClearRows,
            sensorsToCopyOn: [],
        });
    };

    showSensorCopyModal = () => {
        this.setState({
            showSensorCopyModal: true,
        });
    };

    hideSensorCopyModal = (shouldReloadDevices: boolean = false) => {
        this.setState({
            showSensorCopyModal: false,
            sensorToCopy: null,
            toggledClearRows: !this.state.toggledClearRows,
            sensorsToCopyOn: [],
        });

        if (shouldReloadDevices) {
            this.props.reloadDevices();
        }
    };

    showDrawer = () => {
        this.setState({
            sensorDrawerOpen: true,
        });
    };

    hideDrawer = shouldReloadDevices => {
        this.setState({
            sensorDrawerOpen: false,
        });

        if (shouldReloadDevices) {
            this.props.reloadDevices();
        }
    };

    shouldDisableDeleteButton = (): boolean => {
        if (this.state.sensorsChecked.length === 0) return true;

        return this.state.sensorsChecked.some(sensor => sensor.getOnline());
    };

    render() {
        const conditionalRowStyles = [
            {
                when: row => row.isForce,
                style: {
                    backgroundColor: 'rgba(230, 81, 0, 0.15)',
                },
            },
            {
                when: row => row.id === this.state.sensorToCopy?.id,
                style: {
                    backgroundColor: 'rgba(0, 200, 0, 0.40)',
                },
            },
            {
                when: row => this.state.sensorToCopy && this.state.sensorsToCopyOn.find(sensor => sensor.id === row.id),
                style: {
                    backgroundColor: 'rgba(128, 0, 128, 0.40)',
                },
            },
        ];

        return (
            <Fragment>
                {/* SENSOR COPY MODAL */}
                <SecuredFragment authorizedRoles={[rolesConstants.LPSensor.COPY_CONFIG]}>
                    <CopySensorModal
                        sensorToCopy={this.state.sensorToCopy}
                        sensorsToCopyOn={this.state.sensorsToCopyOn}
                        show={this.state.showSensorCopyModal}
                        onHide={this.hideSensorCopyModal}
                    />
                </SecuredFragment>

                {/* SENSOR FORCE MODAL */}
                <SecuredFragment authorizedRoles={[rolesConstants.LPSensor.FORCE_SENSOR]}>
                    <ForceLPSensorModal
                        sensors={this.state.sensorsChecked}
                        show={this.state.showForceModal}
                        onHide={this.hideForceModal}
                    />
                </SecuredFragment>

                {/* SENSOR DELETE MODAL */}
                <SecuredFragment authorizedRoles={[rolesConstants.LPSensor.DELETE]}>
                    <DeleteLPSensorModal
                        sensors={this.state.sensorsChecked}
                        show={this.state.showDeleteModal}
                        onHide={this.hideDeleteModal}
                    />
                </SecuredFragment>

                {/* DRAWER */}
                <SecuredFragment authorizedRoles={[rolesConstants.LPSensor.VIEW]}>
                    <LPSensorDrawer
                        sensor={this.state.selectedSensor}
                        show={this.state.sensorDrawerOpen}
                        onHide={this.hideDrawer}
                        ipCanLabel={this.props.ipCanLabel}
                    />
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.LPSensor.VIEW_LIST]}>
                    <Panel
                        className={'panel-big element-table dashboard-card-header rs-panel-collapsible-no-carret'}
                        shaded
                        bordered
                        header={
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'ipCanDevices.lpSensors' })}
                                tagValue={this.props.sensors.length}
                                icon={faWifi}
                                iconRotation={180}
                                buttons={[
                                    <Button
                                        key="moduleIPCan-sensors-table-multiForce"
                                        color="orange"
                                        data-cy="moduleIPCan-sensors-table-multiForce"
                                        placement="left"
                                        disabled={this.state.sensorsChecked.length === 0}
                                        onClick={this.showForceModal}>
                                        <FontAwesomeIcon icon={faLock} className="margin-right-10" />
                                        <FormattedMessage id="ipCanDevices.lpSensors.forceMode" />
                                    </Button>,
                                    <Button
                                        key="moduleIPCan-sensors-table-multiDelete"
                                        color="red"
                                        data-cy="moduleIPCan-sensors-table-multiDelete"
                                        disabled={this.shouldDisableDeleteButton()}
                                        onClick={this.showDeleteModal}>
                                        <FontAwesomeIcon icon={faTrash} className="margin-right-10" />
                                        <FormattedMessage id="ipCanDevices.lpSensors.deleteMode" />
                                    </Button>,
                                ]}
                            />
                        }
                        collapsible
                        defaultExpanded={this.props.sensors.length > 0}
                        bodyFill
                        data-cy="moduleIPCan-sensors-table">
                        <ElementTable
                            columns={this.columns}
                            data={this.props.sensors}
                            progressPending={this.props.isLoading}
                            selectableRows
                            onSelectedRowsChange={this.selectedRowsChange}
                            clearSelectedRows={this.state.toggledClearRows}
                            onRowClicked={row => this.clickRow(row)}
                            conditionalRowStyles={conditionalRowStyles}
                            selectableRowDisabled={row => row.id === this.state.sensorToCopy?.id}
                        />
                    </Panel>
                </SecuredFragment>
            </Fragment>
        );
    }
}

export default injectIntl(LPSensorsTable);
