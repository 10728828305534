import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

export default function loginStateCell(online, lastOnlineStateDate) {
    return (
        <div data-cy="display-onlineState" data-tag="allowRowEvents" className="text-center">
            <FontAwesomeIcon data-tag="allowRowEvents" icon={faPlug} color={online ? 'green' : 'red'} />
            <br />
            <small data-tag="allowRowEvents">{lastOnlineStateDate}</small>
        </div>
    );
}

loginStateCell.propTypes = {
    online: PropTypes.number,
};
