import { faCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { Button, ButtonGroup, Col, FlexboxGrid, Tag } from 'rsuite';
import { IpCan } from '../../../../../../handlers/ipcan/IpCan';
import { VehicleCounterExtension } from '../../../../../../handlers/vehicleCounter/VehicleCounterExtension';
import DeleteExtensionModal from '../DeleteExtensionModal';
import EditExtensionModal from '../EditExtensionModal';

type Props = {
    ipcans: IpCan[];
    extension: VehicleCounterExtension;
    fetchExtensions: Function;
};

type State = {
    showDeleteModal: boolean;
    isDeleted: boolean;
    showEditModal: boolean;
};

export class GpioExtensionList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            isDeleted: false,
            showEditModal: false,
        };
    }

    hideDeleteModal = (isDeleted: boolean = false) => {
        this.setState({
            showDeleteModal: false,
            isDeleted,
        });

        if (isDeleted) {
            this.props.fetchExtensions();
        }
    };

    showDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    hideEditModal = (isEdited: boolean = false) => {
        this.setState({
            showEditModal: false,
        });

        if (isEdited) {
            this.props.fetchExtensions();
        }
    };

    showEditModal = () => {
        this.setState({
            showEditModal: true,
        });
    };

    render() {
        const ipcan = this.props.ipcans.find(ipcan => ipcan.id === this.props.extension.getConfigGpio().ipCanId);
        const gpio = generateGPIO(this.props.extension.getConfigGpio().gpio);

        return (
            <Fragment>
                <DeleteExtensionModal
                    show={this.state.showDeleteModal}
                    onHide={this.hideDeleteModal}
                    extension={this.props.extension}
                />

                {this.state.showEditModal && (
                    <EditExtensionModal
                        show={this.state.showEditModal}
                        onHide={this.hideEditModal}
                        extension={this.props.extension}
                    />
                )}

                <FlexboxGrid justify={'center'} align="middle">
                    <FlexboxGrid.Item
                        componentClass={Col}
                        xs={20}
                        style={{ textOverflow: 'clip', overflow: 'auto', whiteSpace: 'normal' }}>
                        <strong>{this.props.extension.getName()}</strong>
                        <Tag data-cy="map-passCount-showType" color="blue" className="margin-left-5">
                            {this.props.extension.getType()}
                        </Tag>
                        <Tag data-cy="map-passCount-showName" color="violet">
                            {`${ipcan?.getLabel()} - ${ipcan?.getGpioConfig().gpioIn[gpio.number - 1].name}`}
                            <FontAwesomeIcon
                                className="margin-left-5"
                                icon={faCircle}
                                color={gpio.open ? 'green' : 'red'}
                            />
                        </Tag>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={4}>
                        <ButtonGroup size="sm">
                            <Button
                                data-cy="map-passCount-editGPIO"
                                onClick={this.showEditModal}
                                appearance="primary"
                                loading={false}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button
                                data-cy="map-passCount-deleteGPIO"
                                onClick={this.showDeleteModal}
                                color="red"
                                loading={false}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Fragment>
        );
    }
}

type GPIOType = {
    open: boolean;
    number: number;
};

function generateGPIO(type: number): GPIOType {
    let gpio = {
        open: true,
        number: 0,
    };

    if (type > 3) {
        gpio.open = false;
    }

    if (type < 3) {
        gpio.number = type + 1;
    } else {
        gpio.number = type - 3;
    }

    return gpio;
}
