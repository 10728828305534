type Props = {
    color: string;
};

export const TimesIcon = ({ color }: Props) => (
    <svg width="100%" height="100%" version="1.1" viewBox="0 0 135 135" xmlns="http://www.w3.org/2000/svg">
        <g fill={color}>
            <rect x="8" y="8" width="17" height="17" stroke-width=".3" />
            <rect x="13" y="13" width="17" height="17" stroke-width=".3" />
            <rect x="17" y="17" width="17" height="17" stroke-width=".3" />
            <rect x="25" y="25" width="17" height="17" stroke-width=".3" />
            <rect x="30" y="30" width="17" height="17" stroke-width=".3" />
            <rect x="38" y="38" width="17" height="17" stroke-width=".3" />
            <rect x="42" y="42" width="17" height="17" stroke-width=".3" />
            <rect x="76" y="42" width="17" height="17" stroke-width=".3" />
            <rect x="80" y="38" width="17" height="17" stroke-width=".3" />
            <rect x="85" y="34" width="17" height="17" stroke-width=".3" />
            <rect x="89" y="30" width="17" height="17" stroke-width=".3" />
            <rect x="34" y="34" width="17" height="17" stroke-width=".3" />
            <rect x="21" y="21" width="17" height="17" stroke-width=".3" />
            <rect x="47" y="47" width="17" height="17" stroke-width=".3" />
            <rect x="72" y="47" width="17" height="17" stroke-width=".3" />
            <rect x="8" y="110" width="17" height="17" stroke-width=".3" />
            <rect x="13" y="106" width="17" height="17" stroke-width=".3" />
            <rect x="17" y="102" width="17" height="17" stroke-width=".3" />
            <rect x="25" y="93" width="17" height="17" stroke-width=".3" />
            <rect x="30" y="89" width="17" height="17" stroke-width=".3" />
            <rect x="38" y="80" width="17" height="17" stroke-width=".3" />
            <rect x="42" y="76" width="17" height="17" stroke-width=".3" />
            <rect x="34" y="85" width="17" height="17" stroke-width=".3" />
            <rect x="21" y="97" width="17" height="17" stroke-width=".3" />
            <rect x="47" y="72" width="17" height="17" stroke-width=".3" />
            <rect x="51" y="51" width="34" height="34" stroke-width=".2" />
            <rect x="93" y="25" width="17" height="17" stroke-width=".3" />
            <rect x="97" y="21" width="17" height="17" stroke-width=".3" />
            <rect x="102" y="17" width="17" height="17" stroke-width=".3" />
            <rect x="106" y="13" width="17" height="17" stroke-width=".3" />
            <rect x="110" y="8" width="17" height="17" stroke-width=".3" />
            <rect x="72" y="72" width="17" height="17" stroke-width=".3" />
            <rect x="76" y="76" width="17" height="17" stroke-width=".3" />
            <rect x="80" y="80" width="17" height="17" stroke-width=".3" />
            <rect x="85" y="85" width="17" height="17" stroke-width=".3" />
            <rect x="89" y="89" width="17" height="17" stroke-width=".3" />
            <rect x="93" y="93" width="17" height="17" stroke-width=".3" />
            <rect x="97" y="97" width="17" height="17" stroke-width=".3" />
            <rect x="102" y="102" width="17" height="17" stroke-width=".3" />
            <rect x="106" y="106" width="17" height="17" stroke-width=".3" />
            <rect x="110" y="110" width="17" height="17" stroke-width=".3" />
        </g>
    </svg>
);
