import React from 'react';
import { List } from 'rsuite';
import SensorItem from './SensorItem';

type Props = {
    sensors: Array<Record<string, any>>;
    placeTypeId: number;
};

type State = {
    sensors: Array<Record<string, any>>;
};

export default class SensorPlaceType extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            sensors: [],
        };
    }

    componentDidMount() {
        this.setState({
            sensors: this.props.sensors.filter(sensor => sensor.placeType.id === this.props.placeTypeId),
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sensors !== this.props.sensors) {
            this.setState({
                sensors: this.props.sensors.filter(sensor => sensor.placeType.id === this.props.placeTypeId),
            });
        }
    }

    render() {
        return (
            <List size="sm">
                {this.state.sensors.map(sensor => {
                    return <SensorItem key={sensor.id} sensor={sensor} />;
                })}
            </List>
        );
    }
}
