export const sceneSensorPlaceTypeConstants = {
    GET_ALL_REQUEST: 'SCENE_SENSOR_PLACE_TYPES_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'SCENE_SENSOR_PLACE_TYPES_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'SCENE_SENSOR_PLACE_TYPES_GET_ALL_FAILURE',

    GET_ONE_REQUEST: 'SCENE_SENSOR_PLACE_TYPES_GET_ONE_REQUEST',
    GET_ONE_SUCCESS: 'SCENE_SENSOR_PLACE_TYPES_GET_ONE_SUCCESS',
    GET_ONE_ERROR: 'SCENE_SENSOR_PLACE_TYPES_GET_ONE_ERROR',

    DELETE_REQUEST: 'SCENE_SENSOR_PLACE_TYPES_DELETE_REQUEST',
    DELETE_SUCCESS: 'SCENE_SENSOR_PLACE_TYPES_DELETE_SUCCESS',
    DELETE_FAILURE: 'SCENE_SENSOR_PLACE_TYPES_DELETE_FAILURE',

    CREATE_REQUEST: 'SCENE_SENSOR_PLACE_TYPES_CREATE_REQUEST',
    CREATE_SUCCESS: 'SCENE_SENSOR_PLACE_TYPES_CREATE_SUCCESS',
    CREATE_FAILURE: 'SCENE_SENSOR_PLACE_TYPES_CREATE_FAILURE',

    UPDATE_REQUEST: 'SCENE_SENSOR_PLACE_TYPES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SCENE_SENSOR_PLACE_TYPES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SCENE_SENSOR_PLACE_TYPES_UPDATE_FAILURE',

    UPDATE_ORDER_REQUEST: 'SCENE_SENSOR_PLACE_TYPES_UPDATE_ORDER_REQUEST',
    UPDATE_ORDER_SUCCESS: 'SCENE_SENSOR_PLACE_TYPES_UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_FAILURE: 'SCENE_SENSOR_PLACE_TYPES_UPDATE_ORDER_FAILURE',
};
