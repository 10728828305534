import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Form, FormControl, List } from 'rsuite';
import { Profile, ProfileInterface } from '../../../../../../handlers/profile/Profile';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';

type Props = {
    profile: Profile;
    handleViewMode: () => void;
    reloadProfiles: () => void;
    reloadUsers: () => void;
};

type FormValue = {
    name: string;
    description: string;
};

export const EditProfileInformationPart = (props: Props) => {
    const intl = useIntl();

    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

    const [formValue, setFormValue] = React.useState<FormValue>({
        name: props.profile.getName(),
        description: props.profile.getDescription(),
    });

    const handleCancel = () => {
        setFormValue({
            name: props.profile.getName(),
            description: props.profile.getDescription(),
        });

        props.handleViewMode();
    };

    const handleValid = () => {
        setIsUpdating(true);

        axiosService
            .getAxios()
            .put<ProfileInterface>(
                '/profile',
                {
                    id: props.profile.getId(),
                    name: formValue.name,
                    description: formValue.description,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateProfileResponse => {
                props.profile.updateProfile(updateProfileResponse.data);

                Alert.success(intl.formatMessage({ id: 'profiles.update.success' }));

                props.reloadProfiles();
                props.reloadUsers();

                props.handleViewMode();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'profiles.update.error' }));
            })
            .finally(() => {
                setIsUpdating(false);
            });
    };

    return (
        <Form formValue={formValue} fluid onChange={value => setFormValue(value as FormValue)}>
            <List>
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="profile.information.name" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="name" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="profile.information.description" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="description" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button color="red" onClick={handleCancel} disabled={isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button color="green" onClick={handleValid} loading={isUpdating}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );
};
