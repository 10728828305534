import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Loader,
    Modal,
    SelectPicker,
    Steps
} from 'rsuite';
import { LPSensor } from '../../../handlers/ipCanDevices/LPSensor';
import { LPSensorUsHeightValues } from '../../../handlers/ipCanDevices/Sensor/LPSensorUsConfig';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';

type Props = {
    show: boolean;
    onHide: Function;
    sensor: LPSensor;
} & WrappedComponentProps;

type State = {
    currentStep: number;
    formValues: FormValues;
    isLoading: boolean;
    data: any;
};

type FormValues = {
    us1: number;
    us2: number;
};

class LPAutoCalibrationModal extends React.Component<Props, State> {
    usHeightValues: { label: string; value: number }[];
    constructor(props: Props) {
        super(props);

        this.state = {
            currentStep: 0,
            formValues: {
                us1: 0,
                us2: 0,
            },
            isLoading: false,
            data: {},
        };

        this.usHeightValues = [
            {
                label: '0 cm',
                value: 0,
            },
            {
                label: '175 cm',
                value: 240,
            },
            {
                label: '200 cm',
                value: 180,
            },
            {
                label: '225 cm',
                value: 130,
            },
            {
                label: '250 cm',
                value: 110,
            },
            {
                label: '275 cm',
                value: 90,
            },
            {
                label: '300 cm',
                value: 70,
            },
            {
                label: '325 cm',
                value: 60,
            },
            {
                label: '350 cm',
                value: 50,
            },
            {
                label: '375 cm',
                value: 30,
            },
            {
                label: '400 cm',
                value: 10,
            },
        ];
    }

    onChange = formValues => {
        this.setState({
            formValues,
        });
    };

    nextStep = () => {
        const { us1, us2 } = this.state.formValues;

        if (this.state.currentStep === 0) {
            webSocketService.onEvent('lp-sensor:updateAutoCalibValue', this.updateAutoCalibValue);
            this.setState({
                currentStep: 1,
                isLoading: true,
            });

            axiosService.getAxios().put(
                '/devices/lp-sensor/autoCalibration',
                {
                    id: this.props.sensor.id,
                    us1: us1,
                    us2: us2,
                },
                {
                    headers: authHeader(),
                }
            );
        }

        if (this.state.currentStep === 2) {
            this.props.sensor
                .updateUsConfig(this.state.data.autoCalibValue.us1.height, this.state.data.autoCalibValue.us2.height)
                .then(() => {
                    Alert.success(
                        this.props.intl.formatMessage({
                            id: 'lpSensor.autoCalibration.updateCalibration.success',
                        })
                    );

                    this.closeModal();
                })
                .catch(err => {
                    Alert.success(
                        this.props.intl.formatMessage(
                            {
                                id: 'lpSensor.autoCalibration.updateCalibration.success',
                            },
                            {
                                error: err,
                            }
                        )
                    );
                });
        }
    };

    closeModal = () => {
        webSocketService.offEvent('lp-sensor:updateAutoCalibValue', this.updateAutoCalibValue);

        this.setState({
            isLoading: false,
            data: {},
            currentStep: 0,
            formValues: {
                us1: 0,
                us2: 0,
            },
        });

        this.props.onHide();
    };

    updateAutoCalibValue = data => {
        this.setState({
            data,
            isLoading: false,
            currentStep: 2,
        });
    };

    restartProcess = () => {
        this.setState({
            currentStep: 0,
            data: {},
            isLoading: false,
        });
    };

    renderForm = () => {
        return (
            <Form formValue={this.state.formValues} onChange={this.onChange} fluid>
                <FlexboxGrid align="middle" justify="center">
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="lpSensor.autoCalibration.us1.startHeight" />
                            </ControlLabel>
                            <FormControl
                                data-cy="ipCanDevices-lpSensor-autoCalibration-us1Height"
                                accepter={SelectPicker}
                                data={this.usHeightValues}
                                name="us1"
                                cleanable={false}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <div
                                            data-cy={`lpSensor-us1Height-value-${item.value}`}>
                                            {label}
                                        </div>
                                    );
                                }}
                            />
                        </FormGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="lpSensor.autoCalibration.us2.startHeight" />
                            </ControlLabel>
                            <FormControl
                                data-cy="ipCanDevices-lpSensor-autoCalibration-us2Height"
                                accepter={SelectPicker}
                                data={this.usHeightValues}
                                name="us2"
                                cleanable={false}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <div
                                            data-cy={`lpSensor-us2Height-value-${item.value}`}>
                                            {label}
                                        </div>
                                    );
                                }}
                            />
                        </FormGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        );
    };

    renderWaitingScreen = () => {
        return (
            <FlexboxGrid align="middle" justify="center">
                <FlexboxGrid.Item>
                    <Loader
                        content={this.props.intl.formatMessage({ id: 'lpSensor.autoCalibration.running' })}
                        size="lg"
                        vertical
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        );
    };

    renderValidationScreen = () => {
        return (
            <FlexboxGrid align="middle" justify="end">
                <FlexboxGrid.Item componentClass={Col} xs={24}>
                    <h4>
                        <FormattedMessage id="lpSensor.autoCalibration.validation.title" />
                    </h4>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={24}>
                    <p>
                        <FormattedMessage id="lpSensor.autoCalibration.validation.us1Height" /> -{' '}
                        {LPSensorUsHeightValues(this.state.data.autoCalibValue.us1.height)}
                    </p>
                    <p>
                        <FormattedMessage id="lpSensor.autoCalibration.validation.us2Height" /> -{' '}
                        {LPSensorUsHeightValues(this.state.data.autoCalibValue.us2.height)}
                    </p>
                    <p>
                        <FormattedMessage id="lpSensor.autoCalibration.validation.saveMessage" />
                    </p>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <Button color="yellow" onClick={this.restartProcess}>
                        <FormattedMessage id="lpSensor.autoCalibration.restart" />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        );
    };

    render = () => (
        <Modal backdrop="static" show={this.props.show} onHide={() => this.props.onHide()}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="lpSensor.autoCalibration.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Steps current={this.state.currentStep} style={{ marginBottom: 30 }}>
                    <Steps.Item
                        title={this.props.intl.formatMessage({ id: 'lpSensor.autoCalibration.configuration' })}
                    />
                    <Steps.Item title={this.props.intl.formatMessage({ id: 'lpSensor.autoCalibration.calibration' })} />
                    <Steps.Item title={this.props.intl.formatMessage({ id: 'lpSensor.autoCalibration.validation' })} />
                </Steps>
                {this.state.currentStep === 0 && this.renderForm()}
                {this.state.currentStep === 1 && this.renderWaitingScreen()}
                {this.state.currentStep === 2 && this.renderValidationScreen()}
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button data-cy="ipCanDevices-lpSensor-autoCalibration-cancel" onClick={() => this.closeModal()} color="red" disabled={this.state.isLoading}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button data-cy="ipCanDevices-lpSensor-autoCalibration-valid" onClick={() => this.nextStep()} appearance="primary" loading={this.state.isLoading}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

export default injectIntl(LPAutoCalibrationModal);
