import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Media, RowRecord, TableColumn } from 'react-data-table-component';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, ButtonGroup } from 'rsuite';
import { Configuration } from '../../handlers/Configuration/Configuration';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import DeleteConfigurationModal from './Modal/DeleteConfigurationModal';
import { EditConfigurationModal } from './Modal/EditConfigurationModal';

type Props = RowRecord & WrappedComponentProps;

type State = {
    editModalOpen: boolean;
    configuration?: Configuration;
    deleteModalOpen: boolean;
};

class AdvancedConfigurationExpandable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editModalOpen: false,
            configuration: undefined,
            deleteModalOpen: false,
        };
    }

    generateColumns = (): TableColumn<Configuration>[] => {
        return [
            {
                name: this.props.intl.formatMessage({ id: 'configuration.section' }),
                center: true,
                cell: row => row.getSection(),
                hide: Media.SM,
            },
            {
                name: this.props.intl.formatMessage({ id: 'configuration.key' }),
                center: true,
                cell: row => row.getKey(),
            },
            {
                name: this.props.intl.formatMessage({ id: 'configuration.value' }),
                center: true,
                cell: row => row.getValue(),
            },

            {
                name: this.props.intl.formatMessage({ id: 'configuration.lastUpdate' }),
                center: true,
                cell: row => (
                    <span>{`${moment(row.getUpdatedAt()).format('DD/MM/YYYY HH:mm:ss')} - ${row.getUpdatedBy()}`}</span>
                ),
                hide: Media.SM,
            },

            {
                name: this.props.intl.formatMessage({ id: 'configuration.actions' }),
                center: true,
                cell: row => (
                    <ButtonGroup>
                        <Button
                            size="sm"
                            color="blue"
                            disabled={!row.getIsEditable()}
                            onClick={() => this.handleClickEdit(row)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                            size="sm"
                            color="red"
                            disabled={!row.getIsDeletable()}
                            onClick={() => this.handleClickDelete(row)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </ButtonGroup>
                ),
            },
        ];
    };

    handleClickEdit = (configuration: Configuration) => {
        this.setState({
            editModalOpen: true,
            configuration,
        });
    };

    handleClickDelete = (configuration: Configuration) => {
        this.setState({
            deleteModalOpen: true,
            configuration,
        });
    };

    handleCloseModal = shouldReload => {
        this.setState({
            editModalOpen: false,
            configuration: undefined,
            deleteModalOpen: false,
        });

        if (shouldReload) {
            this.props.reloadConfigurations();
        }
    };

    render = () => {
        return (
            <>
                <DeleteConfigurationModal
                    show={this.state.deleteModalOpen}
                    configuration={this.state.configuration}
                    closeDeleteConfigurationModal={this.handleCloseModal}
                />

                <EditConfigurationModal
                    show={this.state.editModalOpen}
                    configuration={this.state.configuration}
                    onHide={this.handleCloseModal}
                />

                <ElementTable
                    small
                    columns={this.generateColumns()}
                    data={this.props.data.configurations}
                    striped
                    highlightOnHover
                    // conditionalRowStyles={conditionalRowStyles}
                />
            </>
        );
    };
}

export default injectIntl(AdvancedConfigurationExpandable);
