class IpCanObserverClass {
    private ipcanObeservers: Array<any>;

    constructor() {
        this.ipcanObeservers = [];
    }

    subscribe(fn: CallableFunction): void {
        this.ipcanObeservers.push(fn);
    }

    emit(): void {
        this.ipcanObeservers.forEach(observer => {
            observer.call();
        });
    }

    unsubscribe(fn: CallableFunction): void {
        this.ipcanObeservers = this.ipcanObeservers.filter(observer => observer !== fn);
    }
}

const IpCanObserver = new IpCanObserverClass();
export { IpCanObserver };
