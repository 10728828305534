import { Alert } from 'rsuite';
import { assign, Machine } from 'xstate';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type DisplayForm = {
    label: string;
    type: string | null;
    ipcan: number | null;
    bus: Record<string, any> | null;
    id: number;
    mapArrowRotation?: number;
    isShow: boolean;
    nbPixelX: number;
    nbPixelY: number;
    typeMatrix: number;
    brightness: number;
    origin: number;
    gmt: number;
};

interface DisplayMachineStateSchema {
    states: {
        entrypoint: {};
        createDisplayForm: {};
        createDisplay: {};
        editDisplays: {};
        deleteDisplays: {};
        reload: {};
    };
}

type DisplayMachineEvent =
    | { type: 'CREATE_DISPLAY'; geojson: Array<number> }
    | { type: 'EDIT_DISPLAY' }
    | { type: 'START_EDIT_DISPLAY_MODE'; displaysTcm: Array<Record<string, any>> }
    | { type: 'CANCEL_EDIT_DISPLAY_MODE' }
    | { type: 'DELETE_DISPLAY' }
    | { type: 'CREATE_DISPLAY_FORM_CHANGE'; value: DisplayForm }
    | { type: 'VALID_DISPLAY_FORM' }
    | { type: 'CANCEL_CREATE_DISPLAY' }
    | { type: 'DISPLAY_RELOADED' };

interface DisplayMachineContext {
    createDisplayModalOpen: boolean;
    geojson: Array<number>;
    createDisplayForm: DisplayForm;
    createDisplayFormLoading: boolean;
    levelId: number | undefined;
    editMode: boolean;
    creationError: boolean;
    intl: Record<string, any>;
    loading: boolean;
}

const readLocalStorage = () => {
    const stored = localStorage.getItem('latestDisplayConfig');

    let config = {
        label: '',
        type: null,
        ipcan: null,
        bus: null,
        id: 1,
        mapArrowRotation: 0,
        isShow: true,
        nbPixelX: 32,
        nbPixelY: 16,
        typeMatrix: 1,
        brightness: 10,
        origin: 1,
        gmt: 1,
    };

    if (!stored) return Promise.resolve(config);

    const latestDisplayConfig = JSON.parse(stored);

    config = {
        label: latestDisplayConfig.label,
        type: latestDisplayConfig.type,
        ipcan: latestDisplayConfig.ipcan,
        bus: latestDisplayConfig.bus,
        id: parseInt(latestDisplayConfig.id) + 1,
        mapArrowRotation: latestDisplayConfig.mapArrowRotation,
        isShow: latestDisplayConfig.isShow,
        nbPixelX: latestDisplayConfig.nbPixelX,
        nbPixelY: latestDisplayConfig.nbPixelY,
        typeMatrix: latestDisplayConfig.typeMatrix,
        brightness: latestDisplayConfig.brightness,
        origin: latestDisplayConfig.origin,
        gmt: latestDisplayConfig.gmt,
    };

    localStorage.removeItem('latestDisplayConfig');

    return Promise.resolve(config);
};

export const DisplayMachine = Machine<DisplayMachineContext, DisplayMachineStateSchema, DisplayMachineEvent>(
    {
        id: 'displays',
        initial: 'entrypoint',
        context: {
            createDisplayModalOpen: false,
            geojson: [],
            createDisplayForm: {
                label: '',
                type: null,
                ipcan: null,
                bus: null,
                id: 1,
                mapArrowRotation: 0,
                isShow: true,
                nbPixelX: 32,
                nbPixelY: 16,
                typeMatrix: 1,
                brightness: 10,
                origin: 1,
                gmt: 1,
            },
            createDisplayFormLoading: false,
            levelId: undefined,
            editMode: false,
            creationError: false,
            loading: false,
            intl: {},
        },
        states: {
            entrypoint: {
                // entry: ['resetFormValues'],
                invoke: {
                    src: () => () => {
                        return readLocalStorage();
                    },
                    onDone: {
                        actions: assign({
                            createDisplayForm: (_, event: any) => {
                                return event.data;
                            },
                        }),
                    },
                },
                on: {
                    CREATE_DISPLAY: {
                        target: 'createDisplayForm',
                        actions: 'openCreateDisplayForm',
                    },
                    EDIT_DISPLAY: {
                        target: 'editDisplays',
                    },
                    START_EDIT_DISPLAY_MODE: {
                        actions: 'startEditDisplayMode',
                    },
                    CANCEL_EDIT_DISPLAY_MODE: {
                        actions: 'cancelEditDisplayMode',
                    },
                    DELETE_DISPLAY: {
                        target: 'deleteDisplays',
                    },
                },
            },
            createDisplayForm: {
                on: {
                    CREATE_DISPLAY_FORM_CHANGE: {
                        target: 'createDisplayForm',
                        actions: 'createDisplayFormChange',
                    },
                    VALID_DISPLAY_FORM: {
                        target: 'createDisplay',
                        actions: 'validDisplayForm',
                    },
                    CANCEL_CREATE_DISPLAY: {
                        target: 'entrypoint',
                        actions: 'cancelCreateDisplay',
                    },
                },
            },
            createDisplay: {
                invoke: {
                    id: 'create-map-display',
                    src: context => () => {
                        const headers = { headers: authHeader() };
                        const {
                            label,
                            type,
                            ipcan,
                            bus,
                            id,
                            mapArrowRotation,
                            isShow,
                            nbPixelX,
                            nbPixelY,
                            typeMatrix,
                            brightness,
                            origin,
                            gmt,
                        } = context.createDisplayForm;

                        switch (type) {
                            case 'tcm': {
                                const data = [
                                    {
                                        label,
                                        geoJSON: context.geojson,
                                        levelId: context.levelId,
                                        tcmDisplay: {
                                            id_ipcanmodule: ipcan,
                                            bus,
                                            deviceId: id,
                                        },
                                        mapArrowRotation,
                                        isShow,
                                    },
                                ];

                                return axiosService.getAxios().post('/map-tcm-displays', data, headers);
                            }

                            case 'lpDisplayMatrixV2': {
                                const data = [
                                    {
                                        label,
                                        geoJSON: context.geojson,
                                        levelId: context.levelId,
                                        lpMatrixDisplayV2: {
                                            id_ipcanmodule: ipcan,
                                            bus,
                                            deviceId: id,
                                            baseConfig: {
                                                nbPixelX,
                                                nbPixelY,
                                                typeMatrix,
                                                brightness,
                                                origin,
                                                gmt,
                                            },
                                        },
                                        mapArrowRotation,
                                        isShow,
                                    },
                                ];

                                return axiosService.getAxios().post('/map-lp-matrix-displays-v2', data, headers);
                            }

                            default: {
                                return new Promise((_, reject) => {
                                    return reject();
                                });
                            }
                        }
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'createDisplaySuccess',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.displays.create.success' })),
                            context => {
                                localStorage.setItem('latestDisplayConfig', JSON.stringify(context.createDisplayForm));
                            },
                        ],
                    },
                    onError: {
                        target: 'createDisplayForm',
                        actions: 'createDisplayError',
                    },
                },
            },
            editDisplays: {
                entry: 'setLoading',
                invoke: {
                    src: (_, event: Record<string, any>) => () => {
                        const displaysTcm = event.displaysTcm;
                        const displaysLp = event.displaysLp;

                        if (displaysLp.length > 0 && displaysTcm.length > 0) {
                            return axiosService
                                .getAxios()
                                .put('/map-tcm-displays', { mapTcmDisplayTab: displaysTcm }, { headers: authHeader() })
                                .then(() => {
                                    return axiosService
                                        .getAxios()
                                        .put(
                                            '/map-lp-matrix-displays-v2',
                                            { mapLpMatrixDisplayV2Tab: displaysLp },
                                            { headers: authHeader() }
                                        );
                                });
                        } else if (displaysLp.length > 0) {
                            return axiosService
                                .getAxios()
                                .put(
                                    '/map-lp-matrix-displays-v2',
                                    { mapLpMatrixDisplayV2Tab: displaysLp },
                                    { headers: authHeader() }
                                );
                        } else if (displaysTcm.length > 0) {
                            return axiosService
                                .getAxios()
                                .put('/map-tcm-displays', { mapTcmDisplayTab: displaysTcm }, { headers: authHeader() });
                        }
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.displays.edit.success' })),
                            context => {
                                localStorage.setItem(
                                    'mapTCMDisplayLatestConfig',
                                    JSON.stringify(context.createDisplayForm)
                                );
                            },
                        ],
                    },
                    onError: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.error(context.intl.formatMessage({ id: 'map.displays.edit.error' })),
                        ],
                    },
                },
            },
            deleteDisplays: {
                entry: 'setLoading',
                invoke: {
                    src: (_, event: Record<string, any>) => () => {
                        const tcmDisplaysIds = event.tcmDisplayIds;
                        const lpDisplaysIds = event.lpDisplayIds;

                        if (lpDisplaysIds.length > 0 && tcmDisplaysIds.length > 0) {
                            return axiosService
                                .getAxios()
                                .delete('/map-tcm-displays', {
                                    headers: authHeader(),
                                    data: { tabId: tcmDisplaysIds },
                                })
                                .then(() => {
                                    return axiosService.getAxios().delete('/map-lp-matrix-displays-v2', {
                                        headers: authHeader(),
                                        data: { tabId: lpDisplaysIds },
                                    });
                                });
                        } else if (lpDisplaysIds.length > 0) {
                            return axiosService.getAxios().delete('/map-lp-matrix-displays-v2', {
                                headers: authHeader(),
                                data: { tabId: lpDisplaysIds },
                            });
                        } else if (tcmDisplaysIds.length > 0) {
                            return axiosService.getAxios().delete('/map-tcm-displays', {
                                headers: authHeader(),
                                data: { tabId: tcmDisplaysIds },
                            });
                        }
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.displays.delete.success' })),
                        ],
                    },
                    onError: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.error(context.intl.formatMessage({ id: 'map.displays.delete.error' })),
                        ],
                    },
                },
            },
            reload: {
                on: {
                    DISPLAY_RELOADED: 'entrypoint',
                },
            },
        },
    },
    {
        actions: {
            openCreateDisplayForm: assign<DisplayMachineContext, DisplayMachineEvent>({
                createDisplayModalOpen: true,
                geojson: (_, event: Record<string, any>) => event.geojson,
            }),
            startEditDisplayMode: assign<DisplayMachineContext, DisplayMachineEvent>({
                editMode: true,
            }),
            cancelEditDisplayMode: assign<DisplayMachineContext, DisplayMachineEvent>({
                editMode: false,
            }),
            createDisplayFormChange: assign<DisplayMachineContext, DisplayMachineEvent>({
                createDisplayForm: (_, event: Record<string, any>) => event.value,
            }),
            validDisplayForm: assign<DisplayMachineContext, DisplayMachineEvent>({
                createDisplayFormLoading: true,
                creationError: false,
            }),
            cancelCreateDisplay: assign<DisplayMachineContext, DisplayMachineEvent>({
                createDisplayModalOpen: false,
            }),
            createDisplaySuccess: assign<DisplayMachineContext, DisplayMachineEvent>({
                createDisplayFormLoading: false,
                createDisplayModalOpen: false,
            }),
            createDisplayError: assign<DisplayMachineContext, DisplayMachineEvent>({
                createDisplayFormLoading: false,
                creationError: true,
            }),
            setLoading: assign<DisplayMachineContext, DisplayMachineEvent>({
                loading: true,
            }),
            unsetLoading: assign<DisplayMachineContext, DisplayMachineEvent>({
                loading: false,
            }),
        },
    }
);
