import moment from 'moment';
import { LPSensor } from '../ipCanDevices/LPSensor';
import TCMSensor from '../ipCanDevices/TCMSensor';

interface VehicleCounterExtensionConfigGpio {
    ipCanId: number;
    gpio: number;
}

export class VehicleCounterExtension {
    private _id: number;
    private _createdAt: string;
    private _createdBy: string;
    private _updatedAt: string;
    private _updatedBy: string;
    private _name: string;
    private _sensType: string;
    private _type: 'GPIO' | 'LP_SENSOR_1' | 'LP_SENSOR_2' | 'TCM_SENSOR_1' | 'TCM_SENSOR_2';
    private _configGpio: VehicleCounterExtensionConfigGpio;
    private _timeoutSensor: number;
    private _eventCpt: number;
    private _lpSensor1: LPSensor | null;
    private _lpSensor2: LPSensor | null;
    private _tcmSensor1: TCMSensor | null;
    private _tcmSensor2: TCMSensor | null;

    constructor(extension) {
        const {
            id,
            createdAt,
            createdBy,
            updatedAt,
            updatedBy,
            name,
            sensType,
            type,
            configGpio,
            timeoutSensor,
            eventCpt,
            lpSensor1,
            lpSensor2,
            tcmSensor1,
            tcmSensor2,
        } = extension;

        this._id = id;
        this._createdAt = createdAt;
        this._createdBy = createdBy;
        this._updatedAt = updatedAt;
        this._updatedBy = updatedBy;
        this._name = name;
        this._sensType = sensType;
        this._type = type;
        this._configGpio = configGpio;
        this._timeoutSensor = timeoutSensor;
        this._eventCpt = eventCpt;
        this._lpSensor1 = lpSensor1 ? new LPSensor(lpSensor1, null, null, null) : null;
        this._lpSensor2 = lpSensor2 ? new LPSensor(lpSensor2, null, null, null) : null;
        this._tcmSensor1 = tcmSensor1 ? new TCMSensor(tcmSensor1, null, null, null) : null;
        this._tcmSensor2 = tcmSensor2 ? new TCMSensor(tcmSensor2, null, null, null) : null;
    }

    public getId() {
        return this._id;
    }

    public getCreatedAt() {
        return moment(this._createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreatedBy() {
        return this._createdBy;
    }

    public getUpdatedAt() {
        return moment(this._updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getUpdatedBy() {
        return this._updatedBy;
    }

    public getName() {
        return this._name;
    }

    public getSensType() {
        return this._sensType;
    }

    public getType(): 'GPIO' | 'LP_SENSOR_1' | 'LP_SENSOR_2' | 'TCM_SENSOR_1' | 'TCM_SENSOR_2' {
        return this._type;
    }

    public getConfigGpio() {
        return this._configGpio;
    }

    public getTimeoutSensor() {
        return this._timeoutSensor;
    }

    public getEventCpt() {
        return this._eventCpt;
    }

    public getLPSensor1() {
        return this._lpSensor1;
    }

    public getLPSensor2() {
        return this._lpSensor2;
    }

    public getTCMSensor1() {
        return this._tcmSensor1;
    }

    public getTCMSensor2() {
        return this._tcmSensor2;
    }
}
