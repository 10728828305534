import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Modal } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { IpCanObserver } from '../../../handlers/ipcan/observers/ipCanObserver';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    show: boolean;
    onHide: Function;
    ipCan: IpCan;
    bus: number;
    createNotification: Function;
    getDbValue: Function;
    intl: Record<string, any>;
} & WrappedComponentProps;

type State = {
    loading: boolean;
};
class DestroyBus extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
            loading: true,
        });

        axiosService
            .getAxios()
            .delete(`/ipcanmodules/${this.props.ipCan.getId()}/deleteBus/${this.props.bus}`, {
                headers: authHeader(),
            })
            .then(() => {
                // this.props.getDbValue(this.props.ipCan);

                try {
                    IpCanObserver.emit();
                } catch (err) {
                    console.error(err);
                }

                this.props.onHide();

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'ipcan.actions.deleteDevices.success',
                    })
                );
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'ipcan.actions.deleteDevices.error',
                    })
                );
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        return (
            <Modal overflow={false} show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCan.bus.deleteElements.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="ipCan.bus.deleteElements.message" />
                </Modal.Body>
                <Modal.Footer>
                    <FlexboxGrid justify="end" align="middle">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="ipcan-bus-cancel"
                                    loading={this.state.loading}
                                    onClick={() => this.props.onHide()}
                                    color="red">
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    data-cy="ipcan-bus-delete"
                                    loading={this.state.loading}
                                    onClick={this.handleClick}
                                    color="green">
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(DestroyBus);
