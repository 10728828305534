import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, FlexboxGrid, Panel } from 'rsuite';
import { DisplayPreviewInformation } from '../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../handlers/lpDisplays/VirtualDisplay';
import PanelHeader from '../../../Custom/PanelHeader';
import EditVirtualDisplayShapeModal from '../Nodes/VirtualDisplayNode/VirtualDisplayDrawer/Modal/EditVirtualDisplayShapeModal';
import VirtualDisplayPreview from '../Nodes/VirtualDisplayNode/VirtualDisplayPreview';

type Props = {
    display: DisplayPreviewInformation;
    virtualDisplay: VirtualDisplayPreviewInformation;
    color: string;
    updateInformation: Function;
};

export const VirtualDisplayPreviewDrawer = (props: Props) => {
    const intl = useIntl();

    const [editModalOpen, setEditModalOpen] = useState(false);

    return (
        <Fragment>
            <EditVirtualDisplayShapeModal
                display={props.display}
                virtualDisplay={props.virtualDisplay}
                show={editModalOpen}
                onHide={() => setEditModalOpen(false)}
                updateInformation={props.updateInformation}
            />
            <Panel
                shaded
                className="panel-small"
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'configuration.topology.preview' })}
                        buttons={[
                            <Button
                                data-cy="topology-preview-edit"
                                color="blue"
                                size="sm"
                                onClick={() => setEditModalOpen(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>,
                        ]}
                    />
                }>
                <FlexboxGrid align="middle" justify="center">
                    <FlexboxGrid.Item data-cy="topology-preview">
                        <VirtualDisplayPreview
                            color={props.color}
                            virtualDisplay={props.virtualDisplay}
                            display={props.display}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Panel>
        </Fragment>
    );
};
