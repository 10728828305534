import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, FlexboxGrid, List, Modal } from 'rsuite';
import { sceneSensorPlaceTypeActions } from '../../../../redux/actions';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import CheckCell from '../../Drawer/List/CheckCell';
import ColorCell from '../../Drawer/List/ColorCell';

const styleCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
};

const headerCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '16px',
};

const mapDispatchToProps = dispatch => ({
    deleteScene: id => dispatch(sceneSensorPlaceTypeActions.remove(id)),
});

type Props = {
    deleteScene: Function;
    scene: Record<string, any>;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isRemoving: boolean;
};

class DeleteSceneModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isRemoving: false,
        };

        this.handleDelete = this.handleDelete.bind(this);
    }

    handleDelete() {
        this.props.deleteScene(this.props.scene.id);
    }

    render() {
        if (this.props.scene) {
            return (
                <Modal
                    size="lg"
                    show={this.props.show}
                    onHide={() => this.props.onHide()}
                    data-cy="sensorPlaceTypeScene-deleteSensorPlaceTypeScene-modal">
                    <SecuredFragment authorizedRoles={[rolesConstants.sceneSensorPlaceType.DELETE]}>
                        <Modal.Header>
                            <Modal.Title>
                                <FormattedMessage id="sensorPlaceTypeScene.delete.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body data-cy="sensorPlaceTypeScene-delete-modal">
                            <FormattedMessage id="scene.delete.message" />

                            <List hover style={{ marginTop: 16 }}>
                                <List.Item disabled={true}>
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item style={headerCenter} colspan={3}>
                                            <FormattedMessage id="sensorPlaceTypeScene.delete.led1" />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={headerCenter} colspan={3}>
                                            <FormattedMessage id="sensorPlaceTypeScene.delete.led2" />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={headerCenter} colspan={3}>
                                            <FormattedMessage id="sensorPlaceTypeScene.delete.led3" />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={headerCenter} colspan={3}>
                                            <FormattedMessage id="sensorPlaceTypeScene.delete.led4" />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={headerCenter} colspan={3}>
                                            <FormattedMessage id="sensorPlaceTypeScene.delete.led5" />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={headerCenter} colspan={3}>
                                            <FormattedMessage id="sensorPlaceTypeScene.delete.led6" />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={headerCenter} colspan={3}>
                                            <FormattedMessage id="sensorPlaceTypeScene.delete.time" />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={headerCenter} colspan={3}>
                                            <FormattedMessage id="sensorPlaceTypeScene.delete.color" />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                                <List.Item>
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                            <CheckCell isChecked={this.props.scene.led1} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                            <CheckCell isChecked={this.props.scene.led2} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                            <CheckCell isChecked={this.props.scene.led3} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                            <CheckCell isChecked={this.props.scene.led4} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                            <CheckCell isChecked={this.props.scene.led5} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                            <CheckCell isChecked={this.props.scene.led6} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                            <span className="margin-right-5">{this.props.scene.time}</span>
                                            <FormattedMessage id="sensorPlaceTypeScene.delete.seconds" />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                            <ColorCell color={this.props.scene.color} />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            </List>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonGroup>
                                <Button
                                    onClick={() => this.props.onHide()}
                                    data-cy="delete-sensorPlaceTypeScene-cancel"
                                    color="red"
                                    loading={this.props.scene.isLoading}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    onClick={this.handleDelete}
                                    data-cy="delete-sensorPlaceTypeScene-valid"
                                    appearance="primary"
                                    loading={this.props.scene.isLoading}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </SecuredFragment>
                </Modal>
            );
        } else {
            return null;
        }
    }
}

export default injectIntl(connect(null, mapDispatchToProps)(DeleteSceneModal));
