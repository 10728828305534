import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, ButtonGroup, FlexboxGrid, List, Panel, Tag } from 'rsuite';
import { DisplayPreviewInformation } from '../../../../../handlers/lpDisplays/LPDisplay';
import { TopologyInterface, TriggerInterface } from '../../../../../handlers/lpDisplays/LPDisplayTopology';
import PanelHeader from '../../../../Custom/PanelHeader';
import { DeleteTriggerModal } from '../../Nodes/TopologyNode/Modal/DeleteTriggerModal';
import EditTriggerModal from '../../Nodes/TopologyNode/Modal/EditTriggerModal';

type Props = {
    topology: TopologyInterface;
    topologyIndex: number;
    display: DisplayPreviewInformation;
};

type TriggerModalInformation = {
    isOpen: boolean;
    trigger?: TriggerInterface;
    triggerIndex?: number;
};

const generateTriggerMode = (trigger: number, source: number, value: number): string | null => {
    const intl = useIntl();

    switch (trigger) {
        case 1: {
            return `${intl.formatMessage({ id: 'ipCanDevices.lpDisplays.topology.sourceTime' })} (${value}s)`;
        }
        case 2: {
            return `${intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.topology.sourceCounterMin',
            })} ${source} < ${value}`;
        }
        case 3: {
            return `${intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.topology.sourceCounterMax',
            })} ${source} > ${value}`;
        }
        case 4: {
            return `${intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.topology.sourceGpio',
            })} ${value + 1}`;
        }
        case 5: {
            return `${intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.topology.sourceInvertGPIO',
            })} ${value + 1}`;
        }
        default:
            return null;
    }
};

export const TriggerPartDrawer = (props: Props) => {
    const intl = useIntl();

    const [deleteModalInformation, setDeleteModalInformation] = useState<TriggerModalInformation>({
        isOpen: false,
    });

    const [editModalInformation, setEditModalInformation] = useState<TriggerModalInformation>({
        isOpen: false,
    });

    return (
        <Fragment>
            <DeleteTriggerModal
                show={deleteModalInformation.isOpen}
                onHide={() => setDeleteModalInformation({ isOpen: false })}
                topologyIndex={props.topologyIndex}
                trigger={deleteModalInformation.trigger}
                triggerIndex={deleteModalInformation.triggerIndex}
                display={props.display}
            />

            <EditTriggerModal
                show={editModalInformation.isOpen}
                onHide={() => setEditModalInformation({ isOpen: false })}
                topologyIndex={props.topologyIndex}
                trigger={editModalInformation.trigger}
                triggerIndex={editModalInformation.triggerIndex}
                display={props.display}
            />

            <Panel
                shaded
                className="panel-small"
                bodyFill
                header={
                    <PanelHeader title={intl.formatMessage({ id: 'configuration.topology.triggers' })} buttons={[]} />
                }>
                <List>
                    {props.topology.triggers.map((trigger, index) => {
                        if (trigger && trigger.trigger !== 0) {
                            return (
                                <List.Item className="margin-left-5 margin-right-5" key={`trigger-${index}`}>
                                    <FlexboxGrid align="middle" justify="space-between">
                                        <FlexboxGrid.Item>
                                            <Tag color="blue" className="bold">
                                                {generateTriggerMode(trigger.trigger, trigger.source, trigger.value)}
                                            </Tag>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            <Tag color="orange">{`Trigger ${trigger.next + 1}`}</Tag>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() =>
                                                        setEditModalInformation({
                                                            isOpen: true,
                                                            trigger: trigger,
                                                            triggerIndex: index,
                                                        })
                                                    }
                                                    color="blue"
                                                    size="sm">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        setDeleteModalInformation({
                                                            isOpen: true,
                                                            trigger: trigger,
                                                            triggerIndex: index,
                                                        })
                                                    }
                                                    color="red"
                                                    size="sm">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </ButtonGroup>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            );
                        }
                    })}
                </List>
            </Panel>
        </Fragment>
    );
};
