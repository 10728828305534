import { faSquareFull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TableCellProps } from 'rsuite';

type Props = {
    color: string;
} & TableCellProps;
export default class ColorCell extends React.Component<Props> {
    render() {
        return <FontAwesomeIcon icon={faSquareFull} color={this.props.color} size="2x" />;
    }
}
