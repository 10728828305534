export default class TCMSensorConfig {
    protected _typePlace: number;
    protected _id: number;
    protected _numPlace: number;
    protected _numPulsation: number;
    protected _baudrate: number;
    protected _parity: number;

    constructor(config_) {
        const { typePlace, id, numPlace, numPulsation, baudrate, parity } = config_;

        this._typePlace = typePlace;
        this._id = id;
        this._numPlace = numPlace;
        this._numPulsation = numPulsation;
        this._baudrate = baudrate;
        this._parity = parity;
    }

    public get typePlace() {
        return this._typePlace;
    }

    public get id() {
        return this._id;
    }

    public get numPlace() {
        return this._numPlace;
    }

    public get numPulsation() {
        return this._numPulsation;
    }

    public get baudrate() {
        return this._baudrate;
    }

    public get parity() {
        return this._parity;
    }
}
