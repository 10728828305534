import { faArrowUp, faCheck, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CompactPicker } from 'react-color';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Modal,
    Panel,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { LPDisplay } from '../../../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayStep } from '../../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { hexToRgb } from '../../../../../../../utils/colorUtils';
import PanelHeader from '../../../../../../Custom/PanelHeader';
import {
    LPVirtualDisplayArrow,
    LPVirtualDisplayArrowAnimation,
    LPVirtualDisplayPicto,
    LPVirtualDisplayPosition,
    LPVirtualDisplayRGBPicto,
    LPVirtualDisplaySize,
    LPVirtualDisplayStepColor,
    LPVirtualDisplayTextTransformation,
} from '../../../../../../IpCanElementsPage/LPDisplays/VirtualDisplay/Drawer/Form/LPVirtualDisplayStepFormValues';
import { LPMatrixDisplayWidget } from '../../../../../../IpCanElementsPage/LPDisplays/Widgets/LPMatrixDisplayWidget';
import { LPVirtualDisplayMode } from '../../../../../../IpCanElementsPage/LPMatrixDisplayV2Config/Nodes/VirtualDisplayNode/VirtualDisplayDrawer/Form/LPVirtualDisplayStepFormValues';
import { ForceVirtualDisplayModalInformation } from '../../ForceLPMatrixDisplayModal';
import LPMatrixDisplayFactory, { LPMatrixDisplayV2VirtualDisplayForceType } from '../LPMatrixDisplayFactory';
import { EditUserTextModal } from './EditUserTextModal';

type Props = {
    modalInformation: ForceVirtualDisplayModalInformation;
    onHide: () => void;
    factory: LPMatrixDisplayFactory;
    display: LPDisplay;
};

type FormValue = {
    forceMode: number;
    color: string;
    mode: number;
    textTransformation: number;
    transition: number;
    time: number;
    pictoSize: number;
    picto: number;
    pictoPosition: number;
    arrowSize: number;
    arrow: number;
    arrowOnMap: number;
    arrowPosition: number;
    arrowAnimation: number;
    arrowRepeat: boolean;
    text: number;
    userText: number;
};

export type EditUserTextModalInformationType = {
    isOpen: boolean;
    userTextIndex: number;
};

export const ForceVirtualDisplayModal = (props: Props) => {
    const intl = useIntl();

    const hideModal = () => {
        props.onHide();
    };

    const [
        editUserTextModalInformation,
        setEditUserTextModalInformation,
    ] = React.useState<EditUserTextModalInformationType>({ isOpen: false, userTextIndex: -1 });

    const validEdition = () => {
        if (formValue.forceMode === LPMatrixDisplayV2VirtualDisplayForceType.NO_FORCE) {
            props.factory.removeVirtualDisplaysForced(props.modalInformation.virtualDisplay!.posId);
            props.onHide();
        } else {
            // const addStepIndex =
            //     props.modalInformation.virtualDisplay!.steps.filter(
            //         step => step.getMode() === VirtualDisplayStepState.ENABLED
            //     ).length - 1;

            props.factory.addVirtualDisplaysForced(props.modalInformation.virtualDisplay!.posId, formValue.forceMode, {
                color: hexToRgb(formValue.color),
                mode: formValue.mode,
                time: formValue.time,
                pictoSize: formValue.pictoSize + formValue.pictoPosition,
                picto: formValue.picto,
                arrowSize:
                    formValue.arrowSize +
                    formValue.arrowPosition +
                    formValue.arrowAnimation +
                    (formValue.arrowRepeat ? 30000 : 0),
                arrow: formValue.arrow,
                arrowOnMap: formValue.arrowOnMap,
                stepOrder: 999,
                state: 1,
                transition: formValue.transition,
                textTransformation: formValue.textTransformation,
                triggerMax: {
                    value: 100,
                    action: 0,
                },
                triggerMin: {
                    value: 0,
                    action: 0,
                },
            });

            props.onHide();
        }
    };

    const [formValue, setFormValue] = React.useState<FormValue>(
        (): FormValue => {
            if (props.modalInformation.forceMode) {
                const step = new VirtualDisplayStep(props.modalInformation.forceMode.step);

                return {
                    forceMode: props.modalInformation.forceMode.mode,
                    color: step.getColorHex(),
                    mode: step.getMode(),
                    time: step.getTime(),
                    pictoSize: step.getPictoSize(),
                    picto: step.getPicto(),
                    pictoPosition: step.getPictoPositionNumber(),
                    arrowSize: step.getArrowSize(),
                    arrow: step.getArrow(),
                    arrowPosition: step.getArrowPositionNumber(),
                    arrowAnimation: step.getArrowAnimationNumber(),
                    arrowRepeat: step.getArrowRepeat(),
                    arrowOnMap: step.getArrowOnMap(),
                    textTransformation: step.getTextTransformation(),
                    transition: step.getTransition(),
                    text: step.getTextNumber(),
                    userText: step.getUserTextNumber(),
                };
            } else {
                return {
                    forceMode: LPMatrixDisplayV2VirtualDisplayForceType.NO_FORCE,
                    color: '#C00000',
                    mode: 1,
                    textTransformation: 3,
                    transition: 0,
                    time: 2,
                    pictoSize: 16,
                    picto: 1,
                    pictoPosition: 0,
                    arrowSize: 16,
                    arrow: 1,
                    arrowOnMap: 1,
                    arrowPosition: 0,
                    arrowAnimation: 10000,
                    arrowRepeat: false,
                    text: 1,
                    userText: 1,
                };
            }
        }
    );

    const [previewStep, setPreviewStep] = React.useState<VirtualDisplayStep>(() => {
        if (props.modalInformation.forceMode) {
            const step = new VirtualDisplayStep(props.modalInformation.forceMode.step);

            return new VirtualDisplayStep({
                state: step.getState(),
                color: step.getColor(),
                mode: step.getMode(),
                textTransformation: step.getTextTransformation(),
                transition: step.getTransition(),
                time: step.getTime(),
                pictoSize: step.getPictoSize(),
                picto: step.getPicto(),
                arrowSize: step.getTotalArrowSize(),
                arrowOnMap: step.getArrowOnMap(),
                arrow: step.getArrow(),
                stepOrder: step.getStepOrder(),
                triggerMax: {
                    value: step.getTriggerMax().getValue(),
                    action: step.getTriggerMax().getAction(),
                },
                triggerMin: {
                    value: step.getTriggerMin().getValue(),
                    action: step.getTriggerMin().getAction(),
                },
            });
        } else {
            return new VirtualDisplayStep({
                state: 1,
                color: hexToRgb('#C00000'),
                mode: 1,
                textTransformation: 3,
                transition: 0,
                time: 2,
                pictoSize: 16,
                picto: 1,
                arrowSize: 10016,
                arrowOnMap: 1,
                arrow: 1,
                stepOrder: 0,
                triggerMin: {
                    value: 100,
                    action: 0,
                },
                triggerMax: {
                    value: 100,
                    action: 0,
                },
            });
        }
    });

    const onFormChange = formValueChanged => {
        const currentFormValue = formValueChanged;

        if (currentFormValue.mode !== 1 && currentFormValue.arrowPosition === 0) currentFormValue.arrowPosition = 1000;
        if (currentFormValue.mode !== 2 && currentFormValue.mode !== 20 && currentFormValue.pictoPosition === 0)
            currentFormValue.pictoPosition = 1000;

        if (currentFormValue.mode === 1 && currentFormValue.arrow === 0) currentFormValue.arrow = 1;
        if ((currentFormValue.mode === 2 || currentFormValue.mode === 20) && currentFormValue.picto === 0)
            currentFormValue.picto = 1;

        currentFormValue.time = parseInt(currentFormValue.time);

        setPreviewStep(
            new VirtualDisplayStep({
                ...formValue,
                ...currentFormValue,
                pictoSize: currentFormValue.pictoSize + currentFormValue.pictoPosition,
                arrowSize:
                    currentFormValue.arrowSize +
                    currentFormValue.arrowPosition +
                    currentFormValue.arrowAnimation +
                    (formValue.arrowRepeat ? 30000 : 0),
                color: hexToRgb(currentFormValue.color),
                state: 1,
                stepOrder: 0,
                triggerMax: {
                    value: 0,
                    action: 0,
                },
                triggerMin: {
                    value: 0,
                    action: 0,
                },
            })
        );

        setFormValue({ ...formValue, ...currentFormValue });
    };

    const handleColorChange = color => {
        setPreviewStep(
            new VirtualDisplayStep({
                ...formValue,
                pictoSize: formValue.pictoSize + formValue.pictoPosition,
                arrowSize:
                    formValue.arrowSize +
                    formValue.arrowPosition +
                    formValue.arrowAnimation +
                    (formValue.arrowRepeat ? 30000 : 0),
                color: { red: color.rgb.r, green: color.rgb.g, blue: color.rgb.b },
                state: 1,
                stepOrder: 0,
                triggerMax: {
                    value: 0,
                    action: 0,
                },
                triggerMin: {
                    value: 0,
                    action: 0,
                },
            })
        );

        setFormValue({ ...formValue, color: color.hex.toUpperCase() });
    };

    const renderArrowSection = () => (
        <Panel
            className="panel-small"
            shaded
            bordered
            header={
                <PanelHeader
                    title={intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.arrowPart',
                    })}
                    buttons={[]}
                />
            }>
            {/* === ARROW STYLE === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.arrow" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-add-step-arrow"
                            name="arrow"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayArrow(intl)}
                            renderMenuItem={(label, item: any) => {
                                if (item.value === 0) return label;

                                return (
                                    <FontAwesomeIcon
                                        data-cy={`lpDisplay-add-step-arrow-${item.value}`}
                                        icon={faArrowUp}
                                        transform={{ rotate: item.orientation }}
                                    />
                                );
                            }}
                            renderValue={(value, item: any) => {
                                if (value === 0) return item.label;
                                return <FontAwesomeIcon icon={faArrowUp} transform={{ rotate: item.orientation }} />;
                            }}
                            searchable={false}
                            cleanable={false}
                            disabledItemValues={formValue.mode === 1 ? [0] : []}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === ARROW SIZE === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.arrowSize" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-add-step-arrowSize"
                            name="arrowSize"
                            accepter={SelectPicker}
                            data={LPVirtualDisplaySize(intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={formValue.arrow === 0}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-add-step-arrowSize-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === ARROW POSITION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.arrowPosition" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-add-step-arrowPosition"
                            name="arrowPosition"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayPosition(intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={formValue.arrow === 0}
                            disabledItemValues={formValue.mode !== 1 ? [0] : []}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-add-step-arrowPosition-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === ARROW ANIMATION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.arrowAnimation" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-add-step-arrowAnimation"
                            name="arrowAnimation"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayArrowAnimation(intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={formValue.arrow === 0}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-add-step-arrowAnimation-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === ARROW REPEAT === */}
            {formValue.mode === 1 && (
                <FormGroup>
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={10}>
                            <ControlLabel>
                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.arrowRepeat" />
                            </ControlLabel>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>
                            <FormControl
                                data-cy="lpDisplay-add-step-arrowRepeat"
                                name="arrowRepeat"
                                accepter={Toggle}
                                defaultChecked={formValue.arrowRepeat}
                                checked={formValue.arrowRepeat}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FormGroup>
            )}
        </Panel>
    );

    const renderRGBPictoSection = () => (
        <Panel
            className="panel-small"
            shaded
            bordered
            header={
                <PanelHeader
                    title={intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.pictoPart',
                    })}
                    buttons={[]}
                />
            }>
            {/* === PICTO STYLE === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.picto" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            name="picto"
                            data-cy="lpDisplay-add-step-rgb-picto"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayRGBPicto()}
                            placement="top"
                            searchable={false}
                            cleanable={false}
                            disabledItemValues={formValue.mode === 2 ? [0] : []}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === PICTO POSITION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.pictoPosition" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-add-step-pictoPosition"
                            name="pictoPosition"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayPosition(intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={formValue.picto === 0}
                            placement="top"
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-add-step-pictoPosition-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>
        </Panel>
    );

    const renderPictoSection = () => (
        <Panel
            className="panel-small"
            shaded
            bordered
            header={
                <PanelHeader
                    title={intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.pictoPart',
                    })}
                    buttons={[]}
                />
            }>
            {/* === PICTO STYLE === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.picto" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            name="picto"
                            data-cy="lpDisplay-add-step-picto"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayPicto(intl)}
                            renderMenuItem={label => (
                                <div data-cy="lpDisplay-add-step-picto-number">
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item style={{ height: 20, width: 20 }}>{label}</FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </div>
                            )}
                            renderValue={(_, item: any) => (
                                <div>
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item style={{ height: 20, width: 20 }}>
                                            {item.label}
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </div>
                            )}
                            searchable={false}
                            cleanable={false}
                            disabledItemValues={formValue.mode === 2 ? [0] : []}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === PICTO SIZE === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.pictoSize" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-add-step-pictoSize"
                            name="pictoSize"
                            accepter={SelectPicker}
                            data={LPVirtualDisplaySize(intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={formValue.picto === 0}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-add-step-pictoSize-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === PICTO POSITION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.pictoPosition" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-add-step-pictoPosition"
                            name="pictoPosition"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayPosition(intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={formValue.picto === 0}
                            disabledItemValues={formValue.mode !== 2 ? [0] : []}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-add-step-pictoPosition-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>
        </Panel>
    );

    const renderTextSection = () => (
        <Panel
            className="panel-small"
            shaded
            bordered
            header={
                <PanelHeader
                    title={intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.textPart',
                    })}
                    buttons={[]}
                />
            }>
            {/* === TEXT TRANSFORMATION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.textTransformation" />
                        </ControlLabel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-virtualDisplay-textTransformation"
                            name="textTransformation"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayTextTransformation(intl)}
                            searchable={false}
                            cleanable={false}
                            renderMenuItem={(label, item) => {
                                return (
                                    <div data-cy={`lpDisplay-virtualDisplay-step-textTransformation-${item.value}`}>
                                        {label}
                                    </div>
                                );
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>
        </Panel>
    );

    const mode = formValue.mode;
    const shouldShowArrowPart =
        mode === 1 || (mode >= 101 && mode <= 132) || (mode >= 201 && mode <= 232) || (mode >= 301 && mode <= 308);
    const shouldShowPictoPart =
        mode === 2 || (mode >= 101 && mode <= 132) || (mode >= 201 && mode <= 232) || (mode >= 301 && mode <= 308);
    const shouldShowRGBPictoPart = mode === 20;
    const shouldShowTextPart = (mode >= 101 && mode <= 308) || (mode >= 5 && mode <= 7);

    return (
        <>
            <EditUserTextModal
                display={props.display}
                editUserInformation={editUserTextModalInformation}
                onHide={() => setEditUserTextModalInformation({ isOpen: false, userTextIndex: -1 })}
            />

            <Modal show={props.modalInformation.isOpen} onHide={props.onHide} backdrop="static" size="md">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="map.lpMatrixDisplayV2.force.virtualDisplay.title" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form fluid onChange={formValueChanged => onFormChange(formValueChanged)} formValue={formValue}>
                        {formValue.forceMode !== LPMatrixDisplayV2VirtualDisplayForceType.NO_FORCE && (
                            <FlexboxGrid justify="center" style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                                <FlexboxGrid.Item className="margin-bottom-10">
                                    <LPMatrixDisplayWidget
                                        display={props.display}
                                        backgroundColor={props.modalInformation.virtualDisplay!.backgroundColor}
                                        step={previewStep}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        )}
                        <Panel
                            className="panel-small"
                            shaded
                            bordered
                            header={
                                <PanelHeader
                                    title={intl.formatMessage({
                                        id: 'map.lpMatrixDisplayV2.force.virtualDisplay.forceType',
                                    })}
                                    buttons={[]}
                                />
                            }>
                            <FormGroup>
                                <FlexboxGrid align="middle" justify="space-between">
                                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                                        <ControlLabel>
                                            <FormattedMessage id="map.lpMatrixDisplayV2.force.virtualDisplay.type" />
                                        </ControlLabel>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <FormControl
                                            name="forceMode"
                                            accepter={SelectPicker}
                                            data={ForceVirtualDisplayType(intl)}
                                            block
                                            searchable={false}
                                            cleanable={false}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </FormGroup>
                        </Panel>

                        {formValue.forceMode !== LPMatrixDisplayV2VirtualDisplayForceType.NO_FORCE && (
                            <Panel
                                className="panel-small"
                                shaded
                                bordered
                                header={
                                    <PanelHeader
                                        title={intl.formatMessage({
                                            id: 'map.lpMatrixDisplayV2.force.virtualDisplay.configuration',
                                        })}
                                        buttons={[]}
                                    />
                                }>
                                {/* === MODE === */}
                                <FormGroup>
                                    <FlexboxGrid align="middle" justify="space-between">
                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <ControlLabel>
                                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.mode" />
                                            </ControlLabel>
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            xs={formValue.mode >= 301 && formValue.mode <= 308 ? 11 : 12}>
                                            <FormControl
                                                data-cy="lpDisplay-add-step-mode"
                                                name="mode"
                                                accepter={SelectPicker}
                                                data={LPVirtualDisplayMode(intl, props.display)}
                                                searchable={false}
                                                cleanable={false}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`lpDisplay-add-step-mode-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>

                                        {formValue.mode >= 301 && formValue.mode <= 308 && (
                                            <FlexboxGrid.Item componentClass={Col} xs={1}>
                                                <Button
                                                    appearance="link"
                                                    onClick={() =>
                                                        setEditUserTextModalInformation({
                                                            isOpen: true,
                                                            userTextIndex: formValue.mode - 301,
                                                        })
                                                    }>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </FlexboxGrid.Item>
                                        )}
                                    </FlexboxGrid>
                                </FormGroup>

                                {/* === COLOR === */}
                                {mode !== 20 && (
                                    <FormGroup>
                                        <FlexboxGrid align="middle" justify="space-between">
                                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                                <ControlLabel>
                                                    <FormattedMessage id="map.lpMatrixDisplayV2.force.virtualDisplay.step.color" />
                                                </ControlLabel>
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={12}
                                                className="padding-top-5 padding-bottom-5">
                                                <CompactPicker
                                                    colors={LPVirtualDisplayStepColor()}
                                                    color={formValue.color}
                                                    onChangeComplete={color => handleColorChange(color)}
                                                />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </FormGroup>
                                )}

                                {/* === TIME === */}
                                <FormGroup>
                                    <FlexboxGrid align="middle" justify="space-between">
                                        <FlexboxGrid.Item componentClass={Col} xs={10}>
                                            <ControlLabel>
                                                <FormattedMessage id="map.lpMatrixDisplayV2.force.virtualDisplay.step.time" />
                                            </ControlLabel>
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <FormControl name="time" min={0} type="number" />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </FormGroup>
                            </Panel>
                        )}

                        {formValue.forceMode !== LPMatrixDisplayV2VirtualDisplayForceType.NO_FORCE && (
                            <>
                                {shouldShowArrowPart && renderArrowSection()}
                                {shouldShowPictoPart && renderPictoSection()}
                                {shouldShowRGBPictoPart && renderRGBPictoSection()}
                                {shouldShowTextPart && renderTextSection()}
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => hideModal()}
                            data-cy="lpDisplayV2-forceVirtualDisplay-cancel"
                            color="red">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={() => validEdition()}
                            data-cy="lpDisplayV2-forceVirtualDisplay-valid"
                            appearance="primary">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export const ForceVirtualDisplayType = (intl: IntlShape): { label: string; value: number }[] => [
    {
        label: intl.formatMessage({ id: 'map.lpMatrixDisplayV2.force.virtualDisplay.type.noForce' }),
        value: LPMatrixDisplayV2VirtualDisplayForceType.NO_FORCE,
    },
    {
        label: intl.formatMessage({ id: 'map.lpMatrixDisplayV2.force.virtualDisplay.type.override' }),
        value: LPMatrixDisplayV2VirtualDisplayForceType.OVERRIDE,
    },
    {
        label: intl.formatMessage({ id: 'map.lpMatrixDisplayV2.force.virtualDisplay.type.addForceStep' }),
        value: LPMatrixDisplayV2VirtualDisplayForceType.ADD_FORCE_STEP,
    },
];
