export default class LPDisplayCounters {
    private _addCounter: CounterInterface[];
    private _substractCounter: CounterInterface[];

    constructor(counters_: LPDisplayCountersInterface) {
        this._addCounter = counters_.addCounter;
        this._substractCounter = counters_.substractCounter;
    }

    public getAddCounter(): CounterInterface[] {
        return this._addCounter;
    }

    public getSubstractCounter(): CounterInterface[] {
        return this._substractCounter;
    }

    public websocketUpdateCounterConfig(counterConfig_: LPDisplayCountersInterface): boolean {
        this._addCounter = counterConfig_.addCounter;
        this._substractCounter = counterConfig_.substractCounter;
        return true;
    }
}

export interface LPDisplayCountersInterface {
    addCounter: CounterInterface[];
    substractCounter: CounterInterface[];
}

export interface CounterInterface {
    counterId: number;
    placeTypeId: number[];
}
