import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import { Profile } from '../../../../../handlers/profile/Profile';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    profile: Profile;
    show: boolean;
    onHide: (shouldReload: boolean) => void;
};

export const DeleteProfileModal = (props: Props) => {
    const intl = useIntl();
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

    const handleValid = () => {
        setIsDeleting(true);

        axiosService
            .getAxios()
            .delete('/profile', {
                data: {
                    id: props.profile.getId(),
                },
                headers: authHeader(),
            })
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'profiles.deleteProfile.success' }));

                props.onHide(true);
            })
            .catch(() => {
                Alert.error(intl.formatMessage({ id: 'profiles.deleteProfile.error' }));
            })
            .finally(() => {
                setIsDeleting(false);
            });
    };

    const handleCloseModal = () => {
        props.onHide(false);
    };

    return (
        <Modal show={props.show} onHide={handleCloseModal}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="profile.deleteProfile.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="bold margin-bottom-5">{`${props.profile.getName()}`}</p>
                <FormattedMessage id="profile.deleteProfile.message" />
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={handleCloseModal} color="red" disabled={isDeleting}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button onClick={handleValid} appearance="primary" loading={isDeleting}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
