import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { LP_MATRIXDISPLAYV2_DEVICE_CMD } from './LPDisplayAdvancedCommandsModal';

type Props = {
    onClose: (hasExecutedCommand: boolean) => void;
    isOpen: boolean;
    command?: LP_MATRIXDISPLAYV2_DEVICE_CMD;
    displayId: number;
};

export const SendCommandConfirmationModal = (props: Props) => {
    const intl = useIntl();

    const [isLoading, setIsLoading] = React.useState(false);

    const handleCancel = () => {
        props.onClose(false);
    };

    const handleValid = () => {
        setIsLoading(true);

        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/deviceCmd',
                {
                    id: props.displayId,
                    cmd: props.command,
                },
                { headers: authHeader() }
            )
            .then(() => {
                Alert.success(
                    intl.formatMessage({
                        id: `ipCanDevices.lpDisplays.advancedCommands.${props.command}.success`,
                    })
                );

                props.onClose(true);
            })
            .catch(err => {
                Alert.error(
                    intl.formatMessage(
                        {
                            id: `ipCanDevices.lpDisplays.advancedCommands.${props.command}.success`,
                        },
                        {
                            err,
                        }
                    )
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Modal show={props.isOpen} onHide={handleCancel} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id={`ipCanDevices.lpDisplays.advancedCommands.${props.command}.title`} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id={`ipCanDevices.lpDisplays.advancedCommands.${props.command}.message`} />
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={handleCancel} color="red" disabled={isLoading}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button onClick={handleValid} appearance="primary" loading={isLoading}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
