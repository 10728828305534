import {
    faArrowAltCircleUp,
    faCheck,
    faEdit,
    faEye,
    faEyeSlash,
    faPlug,
    faTimes,
    faTimesCircle,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    Input,
    List,
    Message,
    Panel,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';
import ForceType from '../../../../IpCanElementsPage/Elements/ForceType';

type Props = {
    display: any;
    controllers: IpCan[];
    currentIpCan?: IpCan;
    service: any;
} & WrappedComponentProps;

type State = {
    isEditMode: boolean;
    isUpdating: boolean;
    hasError: boolean;
    formValue: FormValue;
};

type FormValue = {
    label: string;
    mapArrowRotation: number;
    isShow: boolean;
    ipcan: number;
    bus: number;
    deviceId: number;
};

type MapArrowRotationFormValue = {
    label: string;
    value: number;
    rotation: number;
    icon: IconDefinition;
};

type FormSelector = {
    label: string;
    value: number;
};

class EditDisplayPart extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            isUpdating: false,
            hasError: false,
            formValue: {
                label: props.display.label,
                mapArrowRotation: props.display.mapArrowRotation,
                isShow: props.display.isShow,
                ipcan: props.display.tcmDisplay.ipcanmodule.id,
                bus: props.display.tcmDisplay.bus,
                deviceId: props.display.tcmDisplay.deviceId,
            },
        };
    }

    setEditMode = () => {
        this.setState({
            isEditMode: true,
        });
    };

    cancelEditMode = () => {
        this.setState({
            isEditMode: false,
            formValue: {
                label: this.props.display.label,
                mapArrowRotation: this.props.display.mapArrowRotation,
                isShow: this.props.display.isShow,
                ipcan: this.props.display.tcmDisplay.ipcanmodule.id,
                bus: this.props.display.tcmDisplay.bus,
                deviceId: this.props.display.tcmDisplay.deviceId,
            },
        });
    };

    validEdition = () => {
        this.setState({
            isUpdating: true,
            hasError: false,
        });

        axiosService
            .getAxios()
            .put(
                '/map-tcm-displays/updateDisplay',
                {
                    id: this.props.display.id,
                    mapArrowRotation: this.state.formValue.mapArrowRotation,
                    isShow: this.state.formValue.isShow,
                    label: this.state.formValue.label,
                    tcmDisplay: {
                        id_ipcanmodule: this.state.formValue.ipcan,
                        bus: this.state.formValue.bus,
                        deviceId: this.state.formValue.deviceId,
                    },
                },
                {
                    headers: authHeader(),
                }
            )
            .then(tcmDisplayEditResponse => {
                this.setState({
                    isUpdating: false,
                    isEditMode: false,
                });

                this.props.service.send('UPDATE_TCM_DISPLAY', { display: tcmDisplayEditResponse.data });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'map.tcmDisplay.edit.information.success',
                    })
                );
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    isUpdating: false,
                    hasError: true,
                });
            });
    };

    generateControllersForm = (): FormSelector[] => {
        let controllers: Array<FormSelector> = [];

        this.props.controllers.forEach(controller =>
            controllers.push({
                label: `${controller.label} (${controller.macStr})`,
                value: controller.id,
            })
        );

        return controllers;
    };

    generateBusForm = (): FormSelector[] => {
        const selectedIpCan = this.props.controllers.find(controller => controller.id === this.state.formValue.ipcan);

        return selectedIpCan?.nbDevicesOnBus.map((_, index) => {
            return {
                label: index + 1,
                value: index,
            };
        });
    };

    generateMapArrowRotationForm = (): MapArrowRotationFormValue[] => {
        return [
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.noArrow',
                }),
                icon: faTimesCircle,
                rotation: 0,
                value: 0,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.left',
                }),
                icon: faArrowAltCircleUp,
                rotation: 270,
                value: 1,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.right',
                }),
                icon: faArrowAltCircleUp,
                rotation: 90,
                value: 2,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.up',
                }),
                icon: faArrowAltCircleUp,
                rotation: 0,
                value: 3,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.down',
                }),
                icon: faArrowAltCircleUp,
                rotation: 180,
                value: 4,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.upLeft',
                }),
                icon: faArrowAltCircleUp,
                rotation: 315,
                value: 5,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.upRight',
                }),
                icon: faArrowAltCircleUp,
                rotation: 45,
                value: 6,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.downLeft',
                }),
                icon: faArrowAltCircleUp,
                rotation: 225,
                value: 7,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.downRight',
                }),
                icon: faArrowAltCircleUp,
                rotation: 135,
                value: 8,
            },
        ];
    };

    generateMapArrowRotation = (): {
        icon: IconDefinition;
        rotation: number;
    } => {
        switch (this.props.display.mapArrowRotation) {
            case 1: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 270,
                };
            }
            case 2: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 90,
                };
            }
            case 3: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 0,
                };
            }
            case 4: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 180,
                };
            }
            case 5: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 315,
                };
            }
            case 6: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 45,
                };
            }
            case 7: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 225,
                };
            }
            case 8: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 135,
                };
            }
            default: {
                return {
                    icon: faTimesCircle,
                    rotation: 0,
                };
            }
        }
    };

    handleFormChange = formValue => {
        this.setState({
            formValue: {
                label: formValue.label,
                mapArrowRotation: formValue.mapArrowRotation,
                isShow: formValue.isShow,
                ipcan: formValue.ipcan,
                bus: parseInt(formValue.bus),
                deviceId: parseInt(formValue.deviceId),
            },
        });
    };

    generateViewPart = () => {
        const mapArrowRotation = this.generateMapArrowRotation();
        return (
            <List>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.label" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmDisplay-view-label">{this.props.display.label}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* ONLINE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.online" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmDisplay-view-online">
                            <FontAwesomeIcon
                                size="lg"
                                icon={faPlug}
                                color={this.props.display.tcmDisplay.online ? 'green' : 'red'}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IS SHOW */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.isShow" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmDisplay-view-isShow">
                            <FontAwesomeIcon
                                size="lg"
                                icon={this.props.display.isShow ? faCheck : faTimes}
                                color={this.props.display.isShow ? 'green' : 'red'}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* MAP ARROW ROTATION */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.mapArrowRotation" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmDisplay-view-mapArrowRotation">
                            <FontAwesomeIcon
                                size="lg"
                                icon={mapArrowRotation.icon}
                                style={{ transform: `rotate(${mapArrowRotation.rotation}deg)` }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IP CAN MODULE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.ipcanModule" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmDisplay-view-ipCanModule">{`${this.props.currentIpCan?.label} (${this.props.currentIpCan?.macStr})`}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.bus" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmDisplay-view-bus">
                            {this.props.display.tcmDisplay.bus + 1}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* DEVICE ID */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.deviceId" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmDisplay-view-deviceId">
                            {this.props.display.tcmDisplay.deviceId}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IS FORCE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.isForce" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmDisplay-view-isForce">
                            <FontAwesomeIcon
                                size="lg"
                                icon={this.props.display.tcmDisplay.isForce ? faCheck : faTimes}
                                color={this.props.display.tcmDisplay.isForce ? 'green' : 'red'}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {this.props.display.tcmDisplay.isForce && (
                    <Fragment>
                        {/* FORCE MODE */}
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                    <FormattedMessage id="map.tcmDisplay.edit.information.forceMode" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item data-cy="tcmDisplay-view-forceType">
                                    <ForceType value={this.props.display.tcmDisplay.forceType} />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {/* START FORCE TIME */}
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                    <FormattedMessage id="map.tcmDisplay.edit.information.startForceTime" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item data-cy="tcmDisplay-view-startForceTime">
                                    {moment(this.props.display.tcmDisplay.startForceTime).format('DD/MM/YYYY HH:mm')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {/* END FORCE TIME */}
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                    <FormattedMessage id="map.tcmDisplay.edit.information.endForceTime" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item data-cy="tcmDisplay-view-endForceTime">
                                    {moment(this.props.display.tcmDisplay.endForceTime).format('DD/MM/YYYY HH:mm')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                    </Fragment>
                )}
            </List>
        );
    };

    generateEditPart = () => (
        <Form fluid formValue={this.state.formValue} onChange={this.handleFormChange}>
            <List>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.label" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl data-cy="tcmDisplay-edit-label" name="label" accepter={Input} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* MAP ARROW ROTATION */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.mapArrowRotation" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="tcmDisplay-edit-mapArrowRotation"
                                name="mapArrowRotation"
                                accepter={SelectPicker}
                                data={this.generateMapArrowRotationForm()}
                                cleanable={false}
                                searchable={false}
                                renderMenuItem={(label, item: any) => {
                                    const style = {
                                        transform: `rotate(${item.rotation}deg)`,
                                    };
                                    return (
                                        <div data-cy={`editDisplay-arrow-value-${item.value}`}>
                                            <FontAwesomeIcon
                                                className="margin-right-15"
                                                icon={item.icon}
                                                style={style}
                                            />
                                            {label}
                                        </div>
                                    );
                                }}
                                renderValue={(_, item: any) => {
                                    const style = {
                                        transform: `rotate(${item.rotation}deg)`,
                                    };
                                    return (
                                        <div data-cy={`editDisplay-arrow-value-${item.value}`}>
                                            <FontAwesomeIcon
                                                className="margin-right-15"
                                                icon={item.icon}
                                                style={style}
                                            />
                                            {item.label}
                                        </div>
                                    );
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* IS SHOW */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.isShow" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <FormControl
                                data-cy="tcmDisplay-edit-isShow"
                                name="isShow"
                                value={this.state.formValue.isShow}
                                accepter={Toggle}
                                defaultChecked={this.state.formValue.isShow}
                                checkedChildren={<FontAwesomeIcon icon={faEye} />}
                                unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} />}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* IPCAN */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.ipcanModule" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="tcmDisplay-edit-ipCan"
                                name="ipcan"
                                cleanable={false}
                                accepter={SelectPicker}
                                data={this.generateControllersForm()}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* BUS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmDisplay.edit.information.bus" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="tcmDisplay-edit-bus"
                                name="bus"
                                accepter={SelectPicker}
                                data={this.generateBusForm()}
                                cleanable={false}
                                searchable={false}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* DEVICE ID */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcpDisplay.edit.information.deviceId" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="tcmDisplay-edit-deviceId"
                                name="deviceId"
                                accepter={Input}
                                type="number"
                                min={1}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="tcmDisplay-edit-cancel"
                                    color="red"
                                    onClick={this.cancelEditMode}
                                    disabled={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    data-cy="tcmDisplay-edit-valid"
                                    color="green"
                                    onClick={this.validEdition}
                                    loading={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );

    render = () => (
        <Panel
            className="panel-small"
            bordered
            bodyFill
            shaded
            header={
                <PanelHeader
                    title={this.props.intl.formatMessage({
                        id: 'map.tcmDisplay.edit.information',
                    })}
                    buttons={[
                        <Button
                            data-cy="tcmDisplay-edit-information"
                            color="blue"
                            size="sm"
                            onClick={this.setEditMode}
                            disabled={this.state.isEditMode}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {this.state.hasError && (
                <Message
                    type="error"
                    description={this.props.intl.formatMessage({ id: 'map.tcmDisplay.edit.information.error' })}
                />
            )}
            {this.state.isEditMode ? this.generateEditPart() : this.generateViewPart()}
        </Panel>
    );
}

export default injectIntl(EditDisplayPart);
