import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    option: Record<string, any>;
};

type State = {
    primaryColor: boolean;
    free: '"currentcolor"';
    occupied: '"currentcolor"';
    overstayFree: '"currentcolor"';
    overstay: '"currentcolor"';
    forced: '"currentcolor"';
};
export default class UpdateColor extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            primaryColor: true,
            free: props.option.free.color,
            occupied: props.option.occupied.color,
            overstayFree: props.option.overstayFree.color,
            overstay: props.option.overstay.color,
            forced: props.option.forced.color,
        };

        this.changeColor = this.changeColor.bind(this);
    }

    componentDidMount() {
        setInterval(this.changeColor, 1000);
    }

    changeColor() {
        if (this.state.primaryColor) {
            this.setState({
                primaryColor: false,
                free: this.props.option.free.secondColor,
                occupied: this.props.option.occupied.secondColor,
                overstayFree: this.props.option.overstayFree.secondColor,
                overstay: this.props.option.overstay.secondColor,
                forced: this.props.option.forced.secondColor,
            });
        } else {
            this.setState({
                primaryColor: true,
                free: this.props.option.free.color,
                occupied: this.props.option.occupied.color,
                overstayFree: this.props.option.overstayFree.color,
                overstay: this.props.option.overstay.color,
                forced: this.props.option.forced.color,
            });
        }
    }

    render() {
        return (
            <div>
                <div data-cy="historic-updateColor-free">
                    <FormattedMessage id="sensor.history.updateColor.free" /> :{' '}
                    <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                            color: this.state.free,
                        }}
                    />
                </div>
                <div data-cy="historic-updateColor-occupied">
                    <FormattedMessage id="sensor.history.updateColor.occupied" /> :{' '}
                    <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                            color: this.state.occupied,
                        }}
                    />
                </div>
                <div data-cy="historic-updateColor-overstayFree">
                    <FormattedMessage id="sensor.history.updateColor.overstayFree" /> :{' '}
                    <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                            color: this.state.overstayFree,
                        }}
                    />
                </div>
                <div data-cy="historic-updateColor-overstay">
                    <FormattedMessage id="sensor.history.updateColor.overstay" /> :{' '}
                    <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                            color: this.state.overstay,
                        }}
                    />
                </div>
                <div data-cy="historic-updateColor-forced">
                    <FormattedMessage id="sensor.history.updateColor.forced" /> :{' '}
                    <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                            color: this.state.forced,
                        }}
                    />
                </div>
            </div>
        );
    }
}
