import PropTypes from 'prop-types';
import React from 'react';
import Text from '../Elements/Text';

export default function textCell(text) {
    return <Text data-tag="allowRowEvents" value={text} />;
}

textCell.propTypes = {
    text: PropTypes.number,
};
