import { faPlug, faPlusCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Panel, Tag, TagGroup } from 'rsuite';
import { User } from '../../../../handlers/profile/User';
import PanelHeader from '../../../Custom/PanelHeader';
import ElementTable from '../../../ReactDataTableComponent/ElementTable';
import { UserDrawer } from './Drawer';
import { CreateUserModal } from './Modal/CreateUserModal';

type Props = {
    loading: boolean;
    users: User[];
    reloadUsers: () => void;
    reloadProfiles: () => void;
};

type UserDrawerInformation = {
    open: boolean;
    userId?: number;
};

export const UsersTable = (props: Props) => {
    const intl = useIntl();

    const [createUserModalOpenned, setCreateUserModalOpenned] = React.useState(false);
    const [userDrawerInformation, setUserDrawerInformation] = React.useState<UserDrawerInformation>({
        open: false,
    });

    const columns: TableColumn<User>[] = [
        {
            name: intl.formatMessage({ id: 'users.lastName' }),
            center: true,
            cell: row => <div data-cy="user-lastName">{row.getLastName()}</div>,
        },
        {
            name: intl.formatMessage({ id: 'users.firstName' }),
            center: true,
            cell: row => <div data-cy="user-firstName">{row.getFirstName()}</div>,
        },
        {
            name: intl.formatMessage({ id: 'users.email' }),
            center: true,
            cell: row => <div data-cy="user-email">{row.getEmail()}</div>,
        },
        {
            name: intl.formatMessage({ id: 'users.profiles' }),
            center: true,
            cell: row => (
                <TagGroup>
                    {row.getProfiles().map(profile => (
                        <Tag color={profile.getName() === 'Admin' ? 'red' : 'violet'}>{profile.getName()}</Tag>
                    ))}
                </TagGroup>
            ),
        },
        {
            name: intl.formatMessage({ id: 'users.creation' }),
            center: true,
            cell: row => (
                <div data-cy="user-creation">
                    <p className="text-center">{row.getCreatedBy()}</p>
                    <p className="text-center">{moment(row.getCreatedAt()).format('DD/MM/YYYY HH:mm:ss')}</p>
                </div>
            ),
        },
        {
            name: intl.formatMessage({ id: 'users.lastLogin' }),
            center: true,
            cell: row => <div data-cy="users-lastLogin">{row.getLastLogin()}</div>,
        },
        {
            name: intl.formatMessage({ id: 'users.loggedIn' }),
            center: true,
            cell: row => (
                <div data-cy="user-loggedIn">
                    <FontAwesomeIcon icon={faPlug} style={{ color: row.getLoggedIn() ? 'green' : 'red' }} />
                </div>
            ),
        },
    ];

    const currentUserSelected = props.users.find(user => user.getId() === userDrawerInformation.userId);

    return (
        <>
            <CreateUserModal
                open={createUserModalOpenned}
                onClose={() => setCreateUserModalOpenned(false)}
                reloadUsers={props.reloadUsers}
            />

            <UserDrawer
                open={userDrawerInformation.open}
                onHide={() =>
                    setUserDrawerInformation({
                        open: false,
                        userId: undefined,
                    })
                }
                user={currentUserSelected}
                reloadUsers={props.reloadUsers}
                reloadProfiles={props.reloadProfiles}
            />

            <Panel
                className="panel-big dashboard-card-header"
                shaded
                bordered
                header={
                    <PanelHeader
                        icon={faUserCircle}
                        title={intl.formatMessage({ id: 'users.title' })}
                        tagValue={props.users.length}
                        buttons={[
                            <Button
                                color="red"
                                key="user-create"
                                size="sm"
                                onClick={() => setCreateUserModalOpenned(true)}
                                data-cy="user-adduser-button">
                                <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                <FormattedMessage id="users.addUser" />
                            </Button>,
                        ]}
                    />
                }
                bodyFill>
                <ElementTable
                    columns={columns}
                    data={props.users}
                    progressPending={props.loading}
                    onRowClicked={row => setUserDrawerInformation({ open: true, userId: row.getId() })}
                    small={false}
                />
            </Panel>
        </>
    );
};
