import { Alert } from 'rsuite';
import { assign, Machine } from 'xstate';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

interface ZoneMachineStateSchema {
    states: {
        entrypoint: {};
        createZoneForm: {};
        createZone: {};
        editZone: {};
        deleteZones: {};
        reload: {};
    };
}

// The events that the machine handles
type ZoneMachineEvent =
    | { type: 'CREATE_ZONE'; geojson: Array<number> }
    | { type: 'EDIT_ZONE'; mapZoneTab: Record<string, any> }
    | { type: 'START_EDIT_ZONE_MODE' }
    | { type: 'CANCEL_EDIT_ZONE_MODE' }
    | { type: 'DELETE_ZONE'; zonesId: Array<number> }
    | { type: 'UPDATE_FORM'; formValue: FormValue }
    | { type: 'VALID_EDIT_FORM' }
    | { type: 'CANCEL_EDIT_FORM' }
    | { type: 'RELOADED' };

// The context (extended state) of the machine
interface ZoneMachineContext {
    createZoneModalOpen: boolean;
    createZoneFormValue: FormValue;
    geojson: Array<number>;
    levelId: number;
    editMode: boolean;
    creatingZone: boolean;
    loading: boolean;
    creationError: boolean;
    intl: Record<string, any>;
}

type FormValue = {
    label: string;
    isView: boolean;
};

export const ZoneMachine = Machine<ZoneMachineContext, ZoneMachineStateSchema, ZoneMachineEvent>(
    {
        key: 'zone',
        initial: 'entrypoint',
        context: {
            createZoneModalOpen: false,
            createZoneFormValue: {
                label: '',
                isView: false,
            },
            geojson: [],
            levelId: 0,
            editMode: false,
            creatingZone: false,
            loading: false,
            creationError: false,
            intl: {},
        },
        states: {
            entrypoint: {
                entry: 'resetForm',
                on: {
                    CREATE_ZONE: {
                        target: 'createZoneForm',
                        actions: 'openCreateZoneModal',
                    },
                    EDIT_ZONE: 'editZone',
                    START_EDIT_ZONE_MODE: {
                        actions: 'setEditMode',
                    },
                    CANCEL_EDIT_ZONE_MODE: {
                        actions: 'unsetEditMode',
                    },
                    DELETE_ZONE: {
                        target: 'deleteZones',
                    },
                },
            },
            createZoneForm: {
                on: {
                    UPDATE_FORM: {
                        target: 'createZoneForm',
                        actions: 'updateFormValue',
                    },
                    VALID_EDIT_FORM: {
                        target: 'createZone',
                        actions: 'validEditForm',
                    },
                    CANCEL_EDIT_FORM: {
                        target: 'entrypoint',
                        actions: 'cancelEditForm',
                    },
                },
            },
            createZone: {
                invoke: {
                    id: 'create-map-zone',
                    src: (context: ZoneMachineContext) => () => {
                        const { levelId, createZoneFormValue, geojson } = context;

                        let data = {
                            label: createZoneFormValue.label,
                            isView: createZoneFormValue.isView,
                            geoJSON: geojson,
                            levelId,
                        };

                        return axiosService.getAxios().post('/map-zones', data, { headers: authHeader() });
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'zoneCreated',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.zone.create.success' })),
                        ],
                    },
                    onError: {
                        target: 'createZoneForm',
                        actions: 'zoneCreationError',
                    },
                },
            },
            editZone: {
                entry: 'setLoading',
                invoke: {
                    id: 'edit-map-zone',
                    src: (_, event: Record<string, any>) => () => {
                        return axiosService
                            .getAxios()
                            .put('/map-zones', { mapZoneTab: event.mapZoneTab }, { headers: authHeader() });
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            'unsetEditMode',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.zone.edit.success' })),
                        ],
                    },
                    onError: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            'unsetEditMode',
                            context => Alert.error(context.intl.formatMessage({ id: 'map.zone.edit.error' })),
                        ],
                    },
                },
            },
            deleteZones: {
                entry: 'setLoading',
                invoke: {
                    id: 'delete-map-zone',
                    src: (_, event: Record<string, any>) => () => {
                        const data = {
                            headers: authHeader(),
                            data: { tabId: event.zonesId },
                        };

                        return axiosService.getAxios().delete('/map-zones', data);
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.success(context.intl.formatMessage({ id: 'map.zone.delete.success' })),
                        ],
                    },
                    onError: {
                        target: 'reload',
                        actions: [
                            'unsetLoading',
                            context => Alert.error(context.intl.formatMessage({ id: 'map.zone.delete.error' })),
                        ],
                    },
                },
            },
            reload: {
                on: {
                    RELOADED: 'entrypoint',
                },
            },
        },
    },
    {
        actions: {
            resetForm: assign<ZoneMachineContext, ZoneMachineEvent>({
                createZoneFormValue: {
                    label: '',
                    isView: false,
                },
            }),
            openCreateZoneModal: assign<ZoneMachineContext, ZoneMachineEvent>({
                createZoneModalOpen: true,
                geojson: (_, event: Record<string, any>): Array<number> => {
                    return event.geojson;
                },
            }),
            updateFormValue: assign<ZoneMachineContext, ZoneMachineEvent>({
                createZoneFormValue: (_, event: Record<string, any>): FormValue => {
                    return event.value;
                },
            }),
            validEditForm: assign<ZoneMachineContext, ZoneMachineEvent>({
                creatingZone: true,
                creationError: false,
            }),
            cancelEditForm: assign<ZoneMachineContext, ZoneMachineEvent>({
                createZoneModalOpen: false,
                geojson: [],
            }),
            setEditMode: assign<ZoneMachineContext, ZoneMachineEvent>({
                editMode: true,
            }),
            unsetEditMode: assign<ZoneMachineContext, ZoneMachineEvent>({
                editMode: false,
            }),
            zoneCreated: assign<ZoneMachineContext, ZoneMachineEvent>({
                creatingZone: false,
                createZoneModalOpen: false,
            }),
            zoneCreationError: assign<ZoneMachineContext, ZoneMachineEvent>({
                creatingZone: false,
                creationError: true,
            }),
            setLoading: assign<ZoneMachineContext, ZoneMachineEvent>({
                loading: true,
            }),
            unsetLoading: assign<ZoneMachineContext, ZoneMachineEvent>({
                loading: false,
            }),
        },
    }
);
