import { LatLngExpression } from 'leaflet';
import PlaceCamera from '../Camera/PlaceCamera';
import { Scene } from '../scene/Scene';
import { MapSensorBase } from './MapSensorBase';

export class MapCameraSensor extends MapSensorBase {
    private _placeCamera: PlaceCamera;
    private _ipCameraId: number;

    constructor(data, sensorPlaceType) {
        super(data, sensorPlaceType);

        this._placeCamera = new PlaceCamera(data.placeCamera);
        this._ipCameraId = data.placeCamera.ipCamera.id;
    }

    public getPlaceCamera(): PlaceCamera {
        return this._placeCamera;
    }

    public setPlaceCamera(data): void {
        this._placeCamera = new PlaceCamera(data);
    }

    public updateSensor(placeCamera, sensorPlaceType?): void {
        this.setLabel(placeCamera.label);
        this.setUpdatedAt(placeCamera.updatedAt);
        this.setUpdatedBy(placeCamera.updatedBy);
        this.setGeoJSON(placeCamera.geoJSON);
        if (sensorPlaceType) {
            this.setSensorPlaceType(sensorPlaceType);
        }

        this.getPlaceCamera().updatePlace(placeCamera);
    }

    public updateSensorWS(placeCamera) {
        this._placeCamera.updatePlace(placeCamera);
    }

    public getMapCameraSensor = (): CameraSensorMapInformation_I => {
        return {
            createdAt: this.createdAt,
            createdBy: this.createdBy,
            geoJSON: this.geoJSON,
            id: this.id,
            label: this.label,
            placeCamera: this._placeCamera,
            updatedAt: this.updatedAt,
            updatedBy: this.updatedBy,
            scenes: this.getScenes(),
            idCamera: this._ipCameraId,
        };
    };
}

export interface CameraSensorMapInformation_I {
    createdAt: string;
    createdBy: string;
    geoJSON: LatLngExpression;
    id: number;
    label: string;
    placeCamera: PlaceCamera;
    updatedAt: string;
    updatedBy: string;
    scenes: [Scene, Scene, Scene, Scene];
    idCamera: number;
}
