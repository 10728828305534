import { faCalendarAlt, faCheck, faDotCircle, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, Message, Panel, Tag, TagGroup } from 'rsuite';
import Counter from '../../handlers/Counter/Counter';
import { EventConditionType, EventWSAction } from '../../handlers/EventAction/EventAction';
import { eventHandler } from '../../handlers/event.handler';
import { IpCan } from '../../handlers/ipcan/IpCan';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import { EventHistoricTable } from '../History/EventHistoricTable';
import { minutesToHours } from '../PixelArtFontEditor/RightSideBar/utils';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import EventDrawer from './Drawer/EventDrawer';
import { CreateEventModal } from './Modal/CreateEventModal';
import DailyCell from './TableCells/DailyCell';
import LaunchCell from './TableCells/LaunchCell';

type Props = WrappedComponentProps;

type State = {
    eventDrawer: Record<string, any> | null;
    counters: Counter[];
    ipCans: IpCan[];
    loading: boolean;
    error: boolean;
    events: Array<Record<string, any>>;
    isEventDrawerOpen: boolean;
    isCreateEventModalOpen: boolean;
};

class Event extends React.Component<Props, State> {
    columns: TableColumn<RowRecord>[];

    constructor(props) {
        super(props);

        this.state = {
            events: [],
            counters: [],
            ipCans: [],
            loading: true,
            error: false,
            // Drawer
            isEventDrawerOpen: false,
            eventDrawer: null,
            // Create event
            isCreateEventModalOpen: false,
        };

        this.fetchEvents = this.fetchEvents.bind(this);
        this.fetchCounters = this.fetchCounters.bind(this);
        this.fetchIpCanModules = this.fetchIpCanModules.bind(this);
        this.openEventDrawer = this.openEventDrawer.bind(this);
        this.closeEventDrawer = this.closeEventDrawer.bind(this);
        this.openCreateEventModal = this.openCreateEventModal.bind(this);
        this.closeCreateEventModal = this.closeCreateEventModal.bind(this);
        this.onWebSocketEvent = this.onWebSocketEvent.bind(this);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'event.type',
                }),
                center: true,
                width: '70px',
                cell: row => {
                    if (!row.eventCondition) return <>EMPTY</>;
                    return (
                        <>
                            {row.eventCondition.eventConditionType === EventConditionType.CALENDAR && (
                                <Tag color="blue" data-cy="event-type-calendar">
                                    <FontAwesomeIcon size="lg" icon={faCalendarAlt} data-tag="allowRowEvents" />
                                </Tag>
                            )}

                            {row.eventCondition.eventConditionType === EventConditionType.COUNTERS && (
                                <Tag color="blue" data-tag="allowRowEvents" data-cy="event-type-counters">
                                    123
                                </Tag>
                            )}

                            {row.eventCondition.eventConditionType === EventConditionType.GPIO && (
                                <Tag color="blue" data-tag="allowRowEvents" data-cy="event-type-gpio">
                                    I/o
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'event.name',
                }),
                center: true,
                cell: row => (
                    <div data-cy="event-list-name" data-tag="allowRowEvents">
                        {row.name}
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'event.isEnable',
                }),
                width: '70px',
                center: true,
                cell: row => (
                    <div data-cy="event-list-isEnable" data-tag="allowRowEvents">
                        <FontAwesomeIcon
                            size="lg"
                            icon={row.isEnable ? faCheck : faTimes}
                            style={{ color: row.isEnable ? 'green' : 'red' }}
                        />
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'event.isInfinite',
                }),
                width: '70px',
                center: true,
                cell: row => (
                    <div data-cy="event-list-isInfinite" data-tag="allowRowEvents">
                        <FontAwesomeIcon
                            size="lg"
                            icon={row.isInfinite ? faCheck : faTimes}
                            style={{ color: row.isInfinite ? 'green' : 'red' }}
                        />
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'event.dates',
                }),
                center: true,
                cell: row =>
                    row.isInfinite ? (
                        <FontAwesomeIcon size="lg" icon={faTimes} data-tag="allowRowEvents" />
                    ) : (
                        <div data-cy="event-list-startDate" data-tag="allowRowEvents">
                            {row.startDate} - {row.endDate}
                        </div>
                    ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'event.daily',
                }),
                center: true,
                grow: 1,
                cell: row => <div data-tag="allowRowEvents">{DailyCell(row.id, row.daily)}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'event.hour',
                }),
                center: true,
                cell: row => (
                    <div data-cy="event-list-hour" data-tag="allowRowEvents">
                        {minutesToHours(row.eventCondition.startTime)}
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'event.counters',
                }),
                grow: 1,
                center: true,
                cell: row => {
                    if (row.eventCondition.eventConditionType === EventConditionType.COUNTERS) {
                        return (
                            <TagGroup data-cy="event-list-counters" data-tag="allowRowEvents">
                                {row.eventCondition.countersId.map((counter, index) => {
                                    const currentCounter = this.state.counters.find(c => c.id === counter.counterId);

                                    if (currentCounter) {
                                        return (
                                            <Tag
                                                color="cyan"
                                                key={`${index}-${currentCounter.id}`}
                                                data-cy="event-counter">
                                                {currentCounter.label} {counter.triggerType} {counter.triggerValue}
                                            </Tag>
                                        );
                                    }

                                    return null;
                                })}
                            </TagGroup>
                        );
                    } else if (row.eventCondition.eventConditionType === EventConditionType.GPIO) {
                        const foundIpCan = this.state.ipCans.find(
                            ipCan => ipCan.id === row.eventCondition.gpioCondition.moduleIpCanId
                        );

                        if (foundIpCan) {
                            return (
                                <Tag data-cy="event-gpio" data-tag="allowRowEvents">
                                    <FontAwesomeIcon
                                        icon={faDotCircle}
                                        color={row.eventCondition.gpioCondition.gpioState ? 'green' : 'red'}
                                        style={{ marginRight: 6 }}
                                    />
                                    {foundIpCan.label} ({foundIpCan.macStr}) -{' '} {foundIpCan.getGpioConfig().gpioIn[row.eventCondition.gpioCondition.gpioNumber - 1].name}
                                </Tag>
                            );
                        }
                    } else {
                        return (
                            <div data-cy="event-list-counters" data-tag="allowRowEvents">
                                <FontAwesomeIcon size="lg" icon={faTimes} />
                            </div>
                        );
                    }
                },
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'event.lauchTasks',
                }),
                width: '100px',
                button: true,
                center: true,
                cell: row => <LaunchCell id={row.id} name={row.name} />,
            },
        ];
    }

    componentDidMount() {
        this.fetchEvents();
        this.fetchCounters();
        this.fetchIpCanModules();

        // webSocketService.joinRoom('events');
        webSocketService.onEvent('event:resetTrigger', this.onWebSocketEvent);
        webSocketService.onEvent('event:play', this.onWebSocketEvent);
    }

    componentWillUnmount() {
        webSocketService.offEvent('event:resetTrigger', this.onWebSocketEvent);
        webSocketService.offEvent('event:play', this.onWebSocketEvent);
    }

    onWebSocketEvent = (data: EventWSAction) => {
        const currentEvents = [...this.state.events];

        const eventIndex = currentEvents.findIndex(event => event.id === data.id);

        if (eventIndex !== -1) {
            currentEvents[eventIndex] = {
                ...currentEvents[eventIndex],
                ...eventHandler(data),
            };
        }

        this.setState({
            events: currentEvents,
        });
    };

    fetchIpCanModules() {
        axiosService
            .getAxios()
            .get('/ipcanmodules', { headers: authHeader() })
            .then(ipCanModulesResponse => {
                let ipCanModules = ipCanModulesResponse.data.map(ipCanModule => new IpCan(ipCanModule));

                this.setState({
                    ipCans: ipCanModules,
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    error: true,
                });
            });
    }

    fetchCounters() {
        axiosService
            .getAxios()
            .get('/counters', { headers: authHeader() })
            .then(countersResponse => {
                let counters = countersResponse.data.map(counter => new Counter(counter));

                this.setState({
                    counters,
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    error: true,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    fetchEvents() {
        this.setState({
            loading: true,
            error: false,
        });

        axiosService
            .getAxios()
            .get('/event-actions/event', { headers: authHeader() })
            .then(eventResponse => {
                let events = eventResponse.data.map(event => eventHandler(event));

                let eventDrawer;

                if (this.state.eventDrawer) eventDrawer = events.find(event => event.id === this.state.eventDrawer?.id);

                this.setState({
                    events,
                    eventDrawer: eventDrawer || null,
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    error: true,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    /**
     * Open the details drawer of the selected event
     * @param {Object} event
     */
    openEventDrawer(event) {
        this.setState({
            isEventDrawerOpen: true,
            eventDrawer: event,
        });
    }

    /**
     * Close the details drawer of an event
     */
    closeEventDrawer() {
        this.setState({
            isEventDrawerOpen: false,
            eventDrawer: null,
        });
    }

    openCreateEventModal() {
        this.setState({
            isCreateEventModalOpen: true,
        });
    }

    closeCreateEventModal() {
        this.setState({
            isCreateEventModalOpen: false,
        });

        this.fetchEvents();
    }

    render() {
        const conditionalRowStyles = [
            {
                when: row => {
                    return row.isTriggered;
                },
                style: {
                    backgroundColor: 'rgba(0, 255, 0, 0.15)',
                },
            },
            {
                when: row => {
                    return !row.isEnable;
                },
                style: {
                    backgroundColor: '#e3e2ef',
                    color: 'rgba(0, 0, 0, 0.5)',
                },
            },
        ];

        return (
            <Fragment>
                {this.state.error && (
                    <Message
                        type="error"
                        description={
                            <p>
                                <FormattedMessage id="events.fetch.error" />
                                <br />
                                <Button appearance="link" onClick={this.fetchEvents}>
                                    <FormattedMessage id="events.fetch.retry" />
                                </Button>
                            </p>
                        }
                    />
                )}

                <SecuredFragment authorizedRoles={[rolesConstants.eventAction.CREATE]}>
                    <CreateEventModal show={this.state.isCreateEventModalOpen} onHide={this.closeCreateEventModal} />
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.eventAction.VIEW]}>
                    <>
                        {this.state.eventDrawer && (
                            <EventDrawer
                                reloadEvents={this.fetchEvents}
                                event={this.state.eventDrawer}
                                show={this.state.isEventDrawerOpen}
                                onHide={this.closeEventDrawer}
                                counters={this.state.counters}
                                ipCanModules={this.state.ipCans}
                            />
                        )}
                    </>
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.eventAction.VIEW_LIST]}>
                    <Panel
                        className="panel-big dashboard-card-header"
                        shaded
                        data-cy="event-list-panel"
                        bordered
                        header={
                            <PanelHeader
                                icon={faCalendarAlt}
                                title={this.props.intl.formatMessage({ id: 'events.title' })}
                                tagValue={this.state.events.length}
                                buttons={[
                                    <SecuredFragment
                                        key="event-create"
                                        authorizedRoles={[rolesConstants.eventAction.CREATE]}>
                                        <Button
                                            color="red"
                                            placement="left"
                                            size="sm"
                                            onClick={this.openCreateEventModal}
                                            data-cy="event-addEvent-button">
                                            <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                            <FormattedMessage id="event.addEvent" />
                                        </Button>
                                    </SecuredFragment>,
                                ]}
                            />
                        }
                        bodyFill>
                        <SecuredFragment authorizedRoles={[rolesConstants.eventAction.VIEW_LIST]}>
                            <ElementTable
                                columns={this.columns}
                                data={this.state.events}
                                progressPending={this.state.loading}
                                onRowClicked={row => this.openEventDrawer(row)}
                                small={false}
                                conditionalRowStyles={conditionalRowStyles}
                            />
                        </SecuredFragment>
                    </Panel>
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.historics.VIEW_EVENT]}>
                    <EventHistoricTable />
                </SecuredFragment>
            </Fragment>
        );
    }
}

export default injectIntl(Event);
