import NbDisplays from './NbDisplays';

export default class BusInformations {
    name: string;
    errors: number;
    errorType: Record<string, number>;
    bus: number;
    devicesOnBus: NbDisplays;
    ipcanId: number;
    constructor(nbDevicesOnBus, busIndex, ipcanId) {
        this.name = `Bus ${busIndex + 1}`;
        this.errors = 0;
        this.bus = busIndex;
        this.errorType = {};

        if (nbDevicesOnBus && nbDevicesOnBus[busIndex]) {
            this.devicesOnBus = new NbDisplays(
                nbDevicesOnBus[busIndex].onDB,
                nbDevicesOnBus[busIndex].onModule,
                nbDevicesOnBus[busIndex].online
            );
        } else {
            this.devicesOnBus = new NbDisplays(-1, -1, -1);
        }
        this.ipcanId = ipcanId;
        // this.bus = bus;
        // this.name = name;
        // this.devicesOnBus = nbDevicesOnBus ? nbDevicesOnBus : { onDB: -1, onModule: -1, online: -1 };
        // this.errors = 0;
    }

    // getBus() {
    //     return parseInt(this.bus) + 1;
    // }

    getName() {
        return this.name;
    }

    getDevicesOnBus() {
        return {
            onDB: this.devicesOnBus.onDB,
            onModule: this.devicesOnBus.onModule,
            online: this.devicesOnBus.online,
        };
    }

    getErrors() {
        return this.errors;
    }

    getBusInformations() {
        return {
            bus: this.bus,
            name: this.getName(),
            devicesOnBus: this.getDevicesOnBus(),
            errors: this.getErrors(),
            errorType: this.errorType,
            ipcanId: this.ipcanId,
        };
    }

    setDevicesOnBus(devices) {
        const { total, online } = devices.devices;
        this.errors = devices.errors;
        this.errorType = devices.errorType;
        this.devicesOnBus.updateValues(total, online);
    }

    public resetError() {
        this.errors = 0;
        this.errorType = {};
    }
}
