export interface LPSensorForceColorConf_I {
    red: number;
    green: number;
    blue: number;
    ledMask: number;
    exitLed: boolean;
}

export class LPSensorForceColorConf {
    private _red: number;
    private _green: number;
    private _blue: number;
    private _ledMask: number;
    private _exitLed: boolean;

    constructor({ red, green, blue, ledMask, exitLed }: LPSensorForceColorConf_I) {
        this._red = red;
        this._green = green;
        this._blue = blue;
        this._ledMask = ledMask;
        this._exitLed = exitLed;
    }

    public get red(): number {
        return this._red;
    }

    public setRed(red_: number): boolean {
        if (this._red !== red_) {
            this._red = red_;
            return true;
        }
        return false;
    }

    public get green(): number {
        return this._green;
    }

    public setGreen(green_: number): boolean {
        if (this._green !== green_) {
            this._green = green_;
            return true;
        }
        return false;
    }

    public get blue(): number {
        return this._blue;
    }

    public setBlue(blue_: number): boolean {
        if (this._blue !== blue_) {
            this._blue = blue_;
            return true;
        }
        return false;
    }

    public get ledMask(): number {
        return this._ledMask;
    }

    public setLedMask(ledMask_: number): boolean {
        if (this._ledMask !== ledMask_) {
            this._ledMask = ledMask_;
            return true;
        }
        return false;
    }

    public get exitLed(): boolean {
        return this._exitLed;
    }

    public setExitLed(exitLed_: boolean): boolean {
        if (this._exitLed !== exitLed_) {
            this._exitLed = exitLed_;
            return true;
        }
        return false;
    }

    public setForceColorConf(forceColorConf: LPSensorForceColorConf) {
        let result = false;

        if (this.setBlue(forceColorConf.blue)) result = true;
        if (this.setGreen(forceColorConf.green)) result = true;
        if (this.setRed(forceColorConf.red)) result = true;
        if (this.setExitLed(forceColorConf.exitLed)) result = true;
        if (this.setLedMask(forceColorConf.ledMask)) result = true;

        return result;
    }
}