import { faCalendarAlt, faDotCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Modal, Tag, TagGroup } from 'rsuite';
import Counter from '../../../handlers/Counter/Counter';
import { eventHandler } from '../../../handlers/event.handler';
import { EventAction, EventConditionType } from '../../../handlers/EventAction/EventAction';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';
import LaunchCell from '../../Event/TableCells/LaunchCell';
import ElementTable from '../../ReactDataTableComponent/ElementTable';

type Props = {
    show: boolean;
    onHide: () => void;
};

export const EventListModal = (props: Props) => {
    const intl = useIntl();

    const [events, setEvents] = useState<EventAction[]>([]);
    const [ipCans, setIpCans] = useState<IpCan[]>([]);
    const [counters, setCounters] = useState<Counter[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        // webSocketService.joinRoom('events');
        webSocketService.onEvent('event:resetTrigger', onWebSocketEvent);
        webSocketService.onEvent('event:play', onWebSocketEvent);

        return () => {
            webSocketService.offEvent('event:resetTrigger', onWebSocketEvent);
            webSocketService.offEvent('event:play', onWebSocketEvent);
        };
    }, []);

    const onWebSocketEvent = (event: any) => {
        const index = events.findIndex(e => e.id === event.eventId);

        if (index > -1) {
            const newEvents = [...events];
            newEvents[index] = { ...newEvents[index], ...eventHandler(event) };
            setEvents(newEvents);
        }
    };

    const columns = useMemo(() => {
        return [
            {
                name: intl.formatMessage({
                    id: 'event.type',
                }),
                center: true,
                width: '70px',
                cell: row => {
                    if (!row.eventCondition) return <>EMPTY</>;
                    return (
                        <>
                            {row.eventCondition.eventConditionType === EventConditionType.CALENDAR && (
                                <Tag color="blue">
                                    <FontAwesomeIcon size="lg" icon={faCalendarAlt} data-tag="allowRowEvents" />
                                </Tag>
                            )}

                            {row.eventCondition.eventConditionType === EventConditionType.COUNTERS && (
                                <Tag color="blue">123</Tag>
                            )}

                            {row.eventCondition.eventConditionType === EventConditionType.GPIO && (
                                <Tag color="blue">I/o</Tag>
                            )}
                        </>
                    );
                },
            },
            {
                name: intl.formatMessage({
                    id: 'event.name',
                }),
                center: true,
                cell: row => (
                    <div data-cy="event-list-name" data-tag="allowRowEvents">
                        {row.name}
                    </div>
                ),
            },
            {
                name: intl.formatMessage({
                    id: 'event.counters',
                }),
                grow: 1,
                center: true,
                cell: row => {
                    if (row.eventCondition.eventConditionType === EventConditionType.COUNTERS) {
                        return (
                            <TagGroup data-cy="event-list-counters" data-tag="allowRowEvents">
                                {row.eventCondition.countersId.map((counter, index) => {
                                    const currentCounter = counters.find(c => c.id === counter.counterId);

                                    if (currentCounter) {
                                        return (
                                            <Tag color="cyan" key={`${index}-${currentCounter.id}`}>
                                                {currentCounter.label} {counter.triggerType} {counter.triggerValue}
                                            </Tag>
                                        );
                                    }

                                    return null;
                                })}
                            </TagGroup>
                        );
                    } else if (row.eventCondition.eventConditionType === EventConditionType.GPIO) {
                        const foundIpCan = ipCans.find(
                            ipCan => ipCan.id === row.eventCondition.gpioCondition.moduleIpCanId
                        );

                        if (foundIpCan) {
                            return (
                                <Tag data-cy="event-gpio" data-tag="allowRowEvents">
                                    <FontAwesomeIcon
                                        icon={faDotCircle}
                                        color={row.eventCondition.gpioCondition.gpioState ? 'green' : 'red'}
                                        style={{ marginRight: 6 }}
                                    />
                                    {foundIpCan.label} ({foundIpCan.macStr}) -{' '}
                                    {row.eventCondition.gpioCondition.gpioNumber}
                                </Tag>
                            );
                        }
                    } else {
                        return (
                            <div data-cy="event-list-counters" data-tag="allowRowEvents">
                                <FontAwesomeIcon size="lg" icon={faTimes} />
                            </div>
                        );
                    }
                },
            },
            {
                name: intl.formatMessage({
                    id: 'event.lauchTasks',
                }),
                width: '100px',
                button: true,
                center: true,
                cell: row => <LaunchCell id={row.id} name={row.name} />,
            },
        ];
    }, [intl, events, ipCans, counters]);

    const conditionalRowStyles = useMemo(
        () => [
            {
                when: row => {
                    return row.isTriggered;
                },
                style: {
                    backgroundColor: 'rgba(0, 255, 0, 0.15)',
                },
            },
            {
                when: row => {
                    return !row.isEnable;
                },
                style: {
                    backgroundColor: '#e3e2ef',
                    color: 'rgba(0, 0, 0, 0.5)',
                },
            },
        ],
        []
    );

    useEffect(() => {
        axiosService
            .getAxios()
            .get<Counter[]>('/counters', {
                headers: authHeader(),
            })
            .then(countersResponse => {
                setCounters(countersResponse.data);
            })
            .catch(error => {
                console.error(error);
                Alert.error(intl.formatMessage({ id: 'counters.fetch.error' }));
            });
    }, []);

    useEffect(() => {
        axiosService
            .getAxios()
            .get<IpCan[]>('/event-actions/event', {
                headers: authHeader(),
            })
            .then(ipCanResponse => {
                setIpCans(ipCanResponse.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                Alert.error(intl.formatMessage({ id: 'ipcan.fetch.error' }));
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        axiosService
            .getAxios()
            .get<EventAction[]>('/event-actions/event', {
                headers: authHeader(),
            })
            .then(eventsResponse => {
                setEvents(eventsResponse.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                Alert.error(intl.formatMessage({ id: 'event.fetch.error' }));
                setIsLoading(false);
            });
    }, []);

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header>
                <Modal.Title>{intl.formatMessage({ id: 'event.modal.list' })}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ElementTable
                    columns={columns}
                    data={events}
                    progressPending={isLoading}
                    small={false}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </Modal.Body>
        </Modal>
    );
};
