import React from 'react';
import { BanIcon } from './BanIcon';
import { CarPoolingIcon } from './CarPoolingIcon';
import { CircleIcon } from './CircleIcon';
import { CoveredParkIcon } from './CoveredParkIcon';
import { DirIcon } from './DirIcon';
import { ElectricalIcon } from './ElectricalIcon';
import { FamilyIcon } from './FamilyIcon';
import { HomeIcon } from './HomeIcon';
import { ParkingIcon } from './ParkingIcon';
import { PMRIcon } from './PMRIcon';
import { StarAltIcon } from './StarAltIcon';
import { StarIcon } from './StarIcon';
import { TaxiAltIcon } from './TaxiAltIcon';
import { TaxiIcon } from './TaxiIcon';
import { ThreeLinesCardIcon } from './ThreeLinesCardIcon';
import { ThreeMIcon } from './ThreeMIcon';
import { TimesIcon } from './TimesIcon';
import { TwoLinesCardAltIcon } from './TwoLinesCardAltIcon';
import { TwoLinesCardIcon } from './TwoLinesCardIcon';
import { VipIcon } from './VipIcon';
import { WrongWayIcon } from './WrongWayIcon';
import { WrongWayInvertedIcon } from './WrongWayInvertedIcon';

type Props = {
    icon: string;
    color: string;
    isMap?: boolean;
    size?: number;
};

const SelectedIcon = ({ icon, color }: Props) => {
    switch (icon) {
        case 'park':
            return <ParkingIcon color={color} />;
        case 'pmr':
            return <PMRIcon color={color} />;
        case 'electrical':
            return <ElectricalIcon color={color} />;
        case 'family':
            return <FamilyIcon color={color} />;
        case 'taxi':
            return <TaxiIcon color={color} />;
        case 'taxi_alt':
            return <TaxiAltIcon color={color} />;
        case 'star':
            return <StarIcon color={color} />;
        case 'star_alt':
            return <StarAltIcon color={color} />;
        case 'two_lines_card':
            return <TwoLinesCardIcon color={color} />;
        case 'two_lines_card_alt':
            return <TwoLinesCardAltIcon color={color} />;
        case 'vip':
            return <VipIcon color={color} />;
        case 'covered_park':
            return <CoveredParkIcon color={color} />;
        case 'carpooling':
            return <CarPoolingIcon color={color} />;
        case 'three_lines_card':
            return <ThreeLinesCardIcon color={color} />;
        case 'dir':
            return <DirIcon color={color} />;
        case 'three_m':
            return <ThreeMIcon color={color} />;
        case 'home':
            return <HomeIcon color={color} />;
        case 'times':
            return <TimesIcon color={color} />;
        case 'ban':
            return <BanIcon color={color} />;
        case 'circle':
            return <CircleIcon color={color} />;
        case 'wrong_way':
            return <WrongWayIcon color={color} />;
        case 'wrong_way_inverted':
            return <WrongWayInvertedIcon color={color} />;
        default:
            return <div data-cy="lpDisplay-noIcons">{null}</div>;
    }
};

export const RenderLPDisplayIcon = ({ icon, color, isMap, size }: Props) => {
    if (!size) size = 1;
    return (
        <div
            style={{
                height: isMap ? 16 * size : 30,
                width: isMap ? 16 * size : 30,
            }}>
            <SelectedIcon icon={icon} color={color} />
        </div>
    );
};
