import { Component, Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Alert } from 'rsuite';
import AppWithIntl from './AppWithIntl';
import { DisconnectedModal } from './Components/DisconnectModal/DisconnectedModal';
import RebootModal from './Components/DisconnectModal/RebootModal';
import Notifications from './Components/Notification';
import { authActions } from './redux/actions';
import { authHeader } from './redux/helpers';
import { axiosService, webSocketService } from './redux/services';
import './style/theme.less';
import message_ch from './translations/ch.json';
import message_de from './translations/de.json';
import message_es from './translations/es.json';
import message_fr from './translations/fr.json';
import message_it from './translations/it.json';
import message_us from './translations/us.json';

type Props = {
    logout: Function;
    language: string;
    auth: Record<string, any>;
    notification: Record<string, any>;
};

type State = {
    rebooting: boolean;
    disconnected: boolean;
};

type messageType = {
    [key: string]: Record<string, any>;
};

const messages: messageType = {
    us: message_us,
    fr: message_fr,
    ch: message_ch,
    de: message_de,
    es: message_es,
    it: message_it,
};

const mapDispatchToProps = (dispatch: Function) => ({
    logout: (): void => dispatch(authActions.logout(true)),
});

const USER_LOCAL_STORAGE = localStorage.getItem('user');

class App extends Component<Props, State> {
    socketInformation: ReturnType<typeof setTimeout> | undefined;

    constructor(props: Props) {
        super(props);

        this.state = {
            rebooting: false,
            disconnected: false,
        };

        this.disconnected = this.disconnected.bind(this);
        this.connect = this.connect.bind(this);

        Alert.config({
            duration: 3000,
        });
    }

    componentDidMount = (): void => {
        this.socketInformation = setTimeout(this.disconnected, 5000);

        webSocketService.onDisconnected(this.disconnected);
        webSocketService.onConnected(this.connect);
        webSocketService.onEvent('reboot', () => {
            this.setState({
                rebooting: true,
            });
        });

        if (USER_LOCAL_STORAGE) {
            let currentUser = JSON.parse(USER_LOCAL_STORAGE);

            axiosService
                .getAxios()
                .get(`/users/${currentUser.id}`, { headers: authHeader() })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        this.props.logout();
                    }
                });
        }
    };

    disconnected() {
        // console.log('On disconnected');
        this.setState({
            disconnected: true,
        });
    }

    connect() {
        // console.log('On connected');

        this.socketInformation && clearTimeout(this.socketInformation);

        this.setState({
            disconnected: false,
        });

        if (this.state.rebooting) {
            window.location.reload();
        }
    }

    render() {
        const msg = messages[this.props.language];
        return (
            <Fragment>
                <IntlProvider defaultLocale="us" locale={this.props.language} messages={msg} onError={() => null}>
                    {this.state.rebooting ? (
                        <RebootModal show={this.state.rebooting} />
                    ) : (
                        <DisconnectedModal show={this.state.disconnected} />
                    )}

                    <AppWithIntl />

                    <Notifications notification={this.props.notification} />
                </IntlProvider>
            </Fragment>
        );
    }
}

function mapStateToProps(state: Record<string, any>) {
    const { language } = state.app;
    const { notification, auth } = state;

    return { language, notification, auth };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);