import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, Input, Modal } from 'rsuite';
import { LPDisplay } from '../../../../../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../../../../../redux/helpers';
import { axiosService } from '../../../../../../../redux/services';
import { EditUserTextModalInformationType } from './ForceVirtualDisplayModal';

type Props = {
    editUserInformation: EditUserTextModalInformationType;
    display: LPDisplay;
    onHide: () => void;
};

export const EditUserTextModal = (props: Props) => {
    const intl = useIntl();

    const [userText, setUserText] = React.useState<string>(
        props.display.getTextConfig().getUserText()[props.editUserInformation.userTextIndex]
    );

    const [isEditing, setIsEditing] = React.useState<boolean>(false);

    useEffect(() => {
        setUserText(props.display.getTextConfig().getUserText()[props.editUserInformation.userTextIndex]);
    }, [props.editUserInformation.userTextIndex]);

    const handleCloseModal = () => {
        if (!isEditing) {
            setUserText(props.display.getTextConfig().getUserText()[props.editUserInformation.userTextIndex]);

            props.onHide();
        }
    };

    const editUserText = () => {
        setIsEditing(true);

        const texts = [...props.display.getTextConfig().getUserText()];
        texts[props.editUserInformation.userTextIndex] = userText;

        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/updateUserTexts',
                {
                    id: props.display.getId(),
                    userTexts: {
                        texts,
                    },
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateUserTextsResponse => {
                props.display.getTextConfig().updateUserText(updateUserTextsResponse.data.textConfig.userTexts.texts);

                Alert.success(intl.formatMessage({ id: 'lpDisplay.forceVirtualDisplay.editUserText.success' }));

                setIsEditing(false);

                props.onHide();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'lpDisplay.forceVirtualDisplay.editUserText.error' }));
            });
    };

    return (
        <Modal show={props.editUserInformation.isOpen} onHide={handleCloseModal} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="lpMatrixDisplay.force.editUserText.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Input value={userText} onChange={value => setUserText(value)} maxLength={50} />
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={handleCloseModal} color="red" disabled={isEditing}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button onClick={editUserText} appearance="primary" loading={isEditing}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
