import { faCheck, faCircle, faEdit, faPlug, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    Input,
    List,
    Message,
    Panel,
    SelectPicker,
    Tag,
} from 'rsuite';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import { TCMSensorMapInformation_I } from '../../../../../handlers/map/MapTCMSensor';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';

type Props = {
    sensor: TCMSensorMapInformation_I;
    controllers: IpCan[];
    placeTypes: any[];
    currentIpCan?: IpCan;
    service: any;
} & WrappedComponentProps;

type State = {
    isEditMode: boolean;
    isUpdating: boolean;
    hasError: boolean;
    formValue: FormValue;
};

type FormSelector = {
    label: string;
    value: number;
};

type FormSelectorWithImage = {
    label: string;
    value: number;
    image: any;
    imageType?: string;
};

type FormValue = {
    id_ipcanmodule: number;
    id_sensorPlaceType: number;
    bus: number;
    deviceId: number;
    label: string;
};

class EditPlacePart extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            isUpdating: false,
            hasError: false,
            formValue: {
                id_ipcanmodule: this.props.sensor.ipCanId,
                id_sensorPlaceType: this.props.sensor.sensorPlaceType,
                bus: this.props.sensor.bus,
                deviceId: this.props.sensor.deviceId,
                label: this.props.sensor.label,
            },
        };
    }

    generateControllersForm = (): FormSelector[] => {
        let controllers: Array<FormSelector> = [];

        this.props.controllers.forEach(controller =>
            controllers.push({
                label: `${controller.label} (${controller.macStr})`,
                value: controller.id,
            })
        );

        return controllers;
    };

    generateSensorPlaceTypeForm = (): FormSelectorWithImage[] => {
        let sensorPlaceTypes: FormSelectorWithImage[] = [];

        this.props.placeTypes.forEach(placeType => {
            sensorPlaceTypes.push({
                label: placeType.label,
                value: placeType.id,
                image: placeType.placeType?.image,
                imageType: placeType.placeType?.imageType,
            });
        });

        return sensorPlaceTypes;
    };

    generateBusForm = (): FormSelector[] => {
        return [
            {
                label: 'Bus 1',
                value: 0,
            },
            {
                label: 'Bus 2',
                value: 1,
            },
        ];
    };

    handleFormChange = formValues => {
        this.setState({
            formValue: {
                id_ipcanmodule: formValues.id_ipcanmodule,
                id_sensorPlaceType: formValues.id_sensorPlaceType,
                bus: parseInt(formValues.bus),
                deviceId: parseInt(formValues.deviceId),
                label: formValues.label,
            },
        });
    };

    setEditMode = () => {
        this.setState({
            isEditMode: true,
        });
    };

    cancelEditMode = () => {
        this.setState({
            isEditMode: false,
            formValue: {
                id_ipcanmodule: this.props.sensor.ipCanId,
                id_sensorPlaceType: this.props.sensor.sensorPlaceType,
                bus: this.props.sensor.bus,
                deviceId: this.props.sensor.deviceId,
                label: this.props.sensor.label,
            },
        });
    };

    validEdition = () => {
        this.setState({
            hasError: false,
            isUpdating: true,
        });

        let label = this.state.formValue.label;

        if (label === '') label = ' ';

        axiosService
            .getAxios()
            .put(
                '/map-tcm-sensors/updateSensor',
                {
                    id: this.props.sensor.mapSensorId,
                    label: label,
                    tcmSensor: {
                        id_ipcanmodule: this.state.formValue.id_ipcanmodule,
                        id_sensorPlaceType: this.state.formValue.id_sensorPlaceType,
                        bus: this.state.formValue.bus,
                        deviceId: this.state.formValue.deviceId,
                    },
                },
                {
                    headers: authHeader(),
                }
            )
            .then(responseData => {
                this.setState({
                    isUpdating: false,
                    isEditMode: false,
                });

                this.props.service.send('TCMSENSOR_EDIT_CONFIGURATION', {
                    sensor: responseData.data,
                });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'map.tcmSensor.edit.information.success',
                    })
                );
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                    isUpdating: false,
                });
            });
    };

    generateForceType = () => {
        switch (this.props.sensor.forceType) {
            case 1:
                return (
                    <Tag data-cy="ipCanSensor-forced-free" color="green">
                        <FormattedMessage id="sensor.force.free" />
                    </Tag>
                );
            case 2:
                return (
                    <Tag data-cy="ipCanSensor-forced-occupied" color="red">
                        <FormattedMessage id="sensor.force.occupied" />
                    </Tag>
                );
            case 3:
                return (
                    <Tag data-cy="ipCanSensor-forced-overstayFree" color="green">
                        <FormattedMessage id="sensor.force.overstayFree" />
                    </Tag>
                );
            case 4:
                return (
                    <Tag data-cy="ipCanSensor-forced-overstayOccupied" color="red">
                        <FormattedMessage id="sensor.force.overstayOccupied" />
                    </Tag>
                );
            case 11:
                return (
                    <Tag data-cy="ipCanSensor-forced-green" color="green">
                        <FormattedMessage id="sensor.force.green" />
                    </Tag>
                );
            case 12:
                return (
                    <Tag data-cy="ipCanSensor-forced-red" color="red">
                        <FormattedMessage id="sensor.force.red" />
                    </Tag>
                );
            case 13:
                return (
                    <Tag data-cy="ipCanSensor-forced-black" style={{ backgroundColor: '#212121', color: 'white' }}>
                        <FormattedMessage id="sensor.force.black" />
                    </Tag>
                );
            case 14:
                return (
                    <Tag data-cy="ipCanSensor-forced-greenBlink" color="green">
                        <FormattedMessage id="sensor.force.greenBlink" />
                    </Tag>
                );
            case 15:
                return (
                    <Tag data-cy="ipCanSensor-forced-redBlink" color="red">
                        <FormattedMessage id="sensor.force.redBlink" />
                    </Tag>
                );
            case 17:
                return (
                    <Tag data-cy="ipCanSensor-forced-redGreenBlink" color="red">
                        <FormattedMessage id="sensor.force.redGreenBlink" />
                    </Tag>
                );
            default:
                return null;
        }
    };

    generateViewPart = () => {
        const sensorPlaceType = this.props.placeTypes.find(spt => spt.id === this.props.sensor.sensorPlaceType);

        let sensorColor = 'black';

        switch (this.props.sensor.detectionState) {
            case 1:
                sensorColor = 'green';
                break;
            case 2:
                sensorColor = 'red';
                break;
            case 3:
                sensorColor = 'orange';
                break;
            case 4:
                sensorColor = 'purple';
                break;
            default:
                sensorColor = 'black';
        }

        return (
            <List>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.label" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmSensor-information-label">
                            {this.props.sensor.label}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* SENSOR PLACE TYPE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.sensorPlaceType" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmSensor-information-placeType">
                            <img
                                alt={sensorPlaceType?.label}
                                style={{ height: 20 }}
                                className="margin-right-5"
                                src={`data:image/${sensorPlaceType?.placeType?.imageType};base64, ${sensorPlaceType?.placeType?.image}`}
                            />
                            {sensorPlaceType?.label}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* ONLINE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.online" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <FontAwesomeIcon
                                data-cy="tcmSensor-information-online"
                                size="lg"
                                icon={faPlug}
                                color={this.props.sensor.online ? 'green' : 'red'}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* DETECTION STATE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.detectionState" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <FontAwesomeIcon
                                icon={faCircle}
                                color={sensorColor}
                                data-cy="tcmSensor-information-detectionState"
                            />{' '}
                            ({this.props.sensor.detectionStateTime})
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IP CAN MODULE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.ipcanModule" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmSensor-information-ipCan">{`${this.props.currentIpCan?.label} (${this.props.currentIpCan?.macStr})`}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.bus" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmSensor-information-bus">
                            {this.props.sensor.bus + 1}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* DEVICE ID */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.deviceId" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmSensor-information-deviceId">
                            {this.props.sensor.deviceId}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IS FORCE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.isForce" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmSensor-information-isForced">
                            <FontAwesomeIcon
                                size="lg"
                                icon={this.props.sensor.isForce ? faCheck : faTimes}
                                color={this.props.sensor.isForce ? 'green' : 'red'}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {this.props.sensor.isForce && (
                    <Fragment>
                        {/* FORCE TYPE */}
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                    <FormattedMessage id="map.tcmSensor.edit.information.forceType" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item data-cy="tcmSensor-information-force">
                                    {this.generateForceType()}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {/* START FORCE TIME */}
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                    <FormattedMessage id="map.tcmSensor.edit.information.startForceDate" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item data-cy="tcmSensor-information-startForce">
                                    {moment(this.props.sensor.startForceTime).format('DD/MM/YYYY HH:mm')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {/* END FORCE TIME */}
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                    <FormattedMessage id="map.tcmSensor.edit.information.endForceDate" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item data-cy="tcmSensor-information-endForce">
                                    {moment(this.props.sensor.endForceTime).format('DD/MM/YYYY HH:mm')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                    </Fragment>
                )}
            </List>
        );
    };

    generateEditPart = () => (
        <Form fluid formValue={this.state.formValue} onChange={this.handleFormChange}>
            <List>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.label" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl data-cy="tcmSensor-edit-label" name="label" accepter={Input} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* SENSOR PLACE TYPE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.sensorPlaceType" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="tcmSensor-edit-placeType"
                                name="id_sensorPlaceType"
                                accepter={SelectPicker}
                                data={this.generateSensorPlaceTypeForm()}
                                cleanable={false}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <FlexboxGrid align="middle">
                                            <FlexboxGrid.Item>
                                                <img
                                                    alt={item.label}
                                                    style={{ height: 20 }}
                                                    src={`data:image/${item.imageType};base64, ${item.image}`}
                                                />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy={`editPlace-placeType-value-${item.value}`}>
                                                {label}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    );
                                }}
                                renderValue={(_, item) => {
                                    return (
                                        <FlexboxGrid align="middle">
                                            <FlexboxGrid.Item>
                                                <img
                                                    alt={item.label}
                                                    style={{ height: 20 }}
                                                    src={`data:image/${item.imageType};base64, ${item.image}`}
                                                />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item>{item.label}</FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    );
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IPCAN */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.ipcanModule" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="tcmSensor-edit-ipCan"
                                name="id_ipcanmodule"
                                cleanable={false}
                                accepter={SelectPicker}
                                data={this.generateControllersForm()}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`tcmSensor-edit-ipCan-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.bus" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="tcmSensor-edit-bus"
                                name="bus"
                                accepter={SelectPicker}
                                data={this.generateBusForm()}
                                cleanable={false}
                                searchable={false}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`tcmSensor-edit-bus-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* DEVICE ID */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.tcmSensor.edit.information.deviceId" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="tcmSensor-edit-deviceId"
                                name="deviceId"
                                accepter={Input}
                                type="number"
                                min={1}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="tcmSensor-edit-cancel"
                                    color="red"
                                    onClick={this.cancelEditMode}
                                    disabled={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    data-cy="tcmSensor-edit-valid"
                                    color="green"
                                    onClick={this.validEdition}
                                    loading={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );

    render = () => {
        return (
            <Panel
                className="panel-small"
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({ id: 'map.tcmSensor.edit.information' })}
                        buttons={[
                            <Button
                                data-cy="tcmSensor-edit-information"
                                color="blue"
                                size="sm"
                                onClick={this.setEditMode}
                                disabled={this.state.isEditMode}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>,
                        ]}
                    />
                }>
                {this.state.hasError && (
                    <Message
                        type="error"
                        description={this.props.intl.formatMessage({ id: 'map.tcmSensor.edit.information.error' })}
                    />
                )}
                {this.state.isEditMode ? this.generateEditPart() : this.generateViewPart()}
            </Panel>
        );
    };
}

export default injectIntl(EditPlacePart);
