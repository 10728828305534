import {
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faHome,
    faPlay,
    faSave,
    faSearchMinus,
    faSearchPlus,
    faStop,
    faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Input, InputGroup, Modal, Toggle } from 'rsuite';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import { IPCameraAPIResponse } from '../../../handlers/Camera/ApiCamera';
import Camera from '../../../handlers/Camera/Camera';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    camera: Camera;
};

type CommandData = {
    pan: number;
    tilt: number;
    zoom: number;
};

const STREAM_IMAGE_TIME = 0.1;

export const EditCameraPosition = (props: Props) => {
    const intl = useIntl();
    const [currentImage, setCurrentImage] = React.useState<string | null>(null);
    const [isStreaming, setIsStream] = React.useState<boolean>(false);
    const [ptzTimeout, setPtzTimeout] = React.useState<number>(0.2);

    useEffect(() => {
        webSocketService.onEvent('ip-camera:stream', handleCameraStream);
        axiosService
            .getAxios()
            .get<IPCameraAPIResponse>(`/ip-cameras/${props.camera.getId()}`, {
                headers: authHeader(),
            })
            .then(ipcamera => {
                if (ipcamera.data.image) {
                    props.camera.setImage(ipcamera.data.image.data);

                    setCurrentImage(Buffer.from(ipcamera.data.image, 'binary').toString('base64'));
                }
            });

        return function cleanup() {
            webSocketService.offEvent('ip-camera:stream', handleCameraStream);
        };
    }, []);

    const handleCloseModal = () => {
        if (isStreaming) {
            axiosService
                .getAxios()
                .put(
                    '/ip-cameras/stream',
                    {
                        id: props.camera.getId(),
                        status: false,
                        time: STREAM_IMAGE_TIME,
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    setIsStream(false);
                });
        }

        props.onHide();
    };

    const handleCameraStream = (data: any) => {
        if (data.target === props.camera.getMac()) {
            setCurrentImage(Buffer.from(data.payload, 'binary').toString('base64'));
        }
    };

    const handleSaveCameraHome = () => {
        axiosService
            .getAxios()
            .put(
                '/ip-cameras/savePreset',
                {
                    id: props.camera.getId(),
                    presetNumber: 1,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'camera.home.saved' }));
            })
            .catch(error => {
                console.error(error);
                Alert.error(intl.formatMessage({ id: 'camera.home.error' }));
            });
    };

    const handleGoToPreset = () => {
        axiosService
            .getAxios()
            .put(
                '/ip-cameras/goToPreset',
                {
                    id: props.camera.getId(),
                    presetNumber: 1,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'camera.goToPreset.success' }));
            })
            .catch(error => {
                console.error(error);
                Alert.error(intl.formatMessage({ id: 'camera.goToPreset.error' }));
            });
    };

    const sendCommand = (commandData: CommandData) => {
        axiosService.getAxios().put(
            '/ip-cameras/movePTZ',
            {
                id: props.camera.getId(),
                pan: commandData.pan,
                tilt: commandData.tilt,
                zoom: commandData.zoom,
                timeout: ptzTimeout,
            },
            {
                headers: authHeader(),
            }
        );
    };

    const handleClickZoomIn = () => {
        sendCommand({
            pan: 0,
            tilt: 0,
            zoom: 1,
        });
    };

    const handleClickZoomOut = () => {
        sendCommand({
            pan: 0,
            tilt: 0,
            zoom: -1,
        });
    };

    const handleClickLeft = () => {
        sendCommand({
            pan: -1,
            tilt: 0,
            zoom: 0,
        });
    };

    const handleClickRight = () => {
        sendCommand({
            pan: 1,
            tilt: 0,
            zoom: 0,
        });
    };

    const handleClickTop = () => {
        sendCommand({
            pan: 0,
            tilt: 1,
            zoom: 0,
        });
    };

    const handleClickBottom = () => {
        sendCommand({
            pan: 0,
            tilt: -1,
            zoom: 0,
        });
    };

    const toggleStream = (shouldStream: boolean) => {
        axiosService
            .getAxios()
            .put(
                '/ip-cameras/stream',
                {
                    id: props.camera.getId(),
                    status: shouldStream,
                    time: STREAM_IMAGE_TIME,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                setIsStream(shouldStream);

                if (shouldStream) {
                    Alert.info(intl.formatMessage({ id: 'camera.stream.started' }));
                } else {
                    Alert.info(intl.formatMessage({ id: 'camera.stream.stopped' }));
                }
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'camera.stream.error' }));
            });
    };

    return (
        <Modal show={props.isOpen} onHide={handleCloseModal} size="lg">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="camera.position.editPosition" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FlexboxGrid
                    align="middle"
                    justify="space-between"
                    className="margin-bottom-5 margin-top-10 margin-left-20 margin-right-20">
                    <FlexboxGridItem>
                        <FormattedMessage id="camera.position.shouldStream" />
                        <Toggle
                            className="margin-left-5"
                            checked={isStreaming}
                            onChange={toggleStream}
                            checkedChildren={<FontAwesomeIcon icon={faPlay} />}
                            unCheckedChildren={<FontAwesomeIcon icon={faStop} />}
                        />
                    </FlexboxGridItem>

                    <FlexboxGridItem>
                        <InputGroup>
                            <InputGroup.Addon>
                                <FontAwesomeIcon icon={faStopwatch} />
                            </InputGroup.Addon>
                            <Input
                                style={{ width: '100px' }}
                                type="number"
                                value={ptzTimeout.toString()}
                                onChange={value => {
                                    const currentValue = parseFloat(value);
                                    if (currentValue > 10) {
                                        setPtzTimeout(10);
                                    } else if (currentValue < 0) {
                                        setPtzTimeout(0.1);
                                    } else {
                                        setPtzTimeout(typeof value === 'string' ? parseFloat(value) : value);
                                    }
                                }}
                                min={0.1}
                                max={10}
                                step={0.1}
                            />
                        </InputGroup>
                    </FlexboxGridItem>
                </FlexboxGrid>
                {/* MOVE CAMERA */}
                <Button
                    onClick={handleClickTop}
                    appearance="ghost"
                    style={{
                        position: 'fixed',
                        zIndex: 999,
                        top: '15%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}>
                    <FontAwesomeIcon size="3x" icon={faChevronUp} color="black" />
                </Button>

                <Button
                    onClick={handleClickBottom}
                    appearance="ghost"
                    style={{
                        position: 'fixed',
                        zIndex: 999,
                        top: '81%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}>
                    <FontAwesomeIcon size="3x" icon={faChevronDown} color="black" />
                </Button>

                <Button
                    onClick={handleClickLeft}
                    appearance="ghost"
                    style={{
                        position: 'fixed',
                        zIndex: 999,
                        left: '5%',
                        top: '47%',
                        transform: 'translateY(-50%)',
                    }}>
                    <FontAwesomeIcon size="3x" icon={faChevronLeft} color="black" />
                </Button>

                <Button
                    onClick={handleClickRight}
                    appearance="ghost"
                    style={{
                        position: 'fixed',
                        zIndex: 999,
                        right: '5%',
                        top: '47%',
                        transform: 'translateY(-50%)',
                    }}>
                    <FontAwesomeIcon size="3x" icon={faChevronRight} color="black" />
                </Button>

                {/* ZOOM CAMERA */}
                <ButtonGroup
                    vertical
                    style={{
                        position: 'fixed',
                        top: '18%',
                        right: '5%',
                        zIndex: 999,
                        transform: 'translateY(-50%)',
                    }}>
                    <Button color="green" onClick={handleClickZoomIn}>
                        <FontAwesomeIcon icon={faSearchPlus} />
                    </Button>
                    <Button color="red" onClick={handleClickZoomOut}>
                        <FontAwesomeIcon icon={faSearchMinus} />
                    </Button>
                </ButtonGroup>

                {/* <Toggle checked={false} onChange={} */}
                {currentImage ? (
                    <div className="text-center">
                        <img
                            alt={'camera-image'}
                            src={`data:image/${props.camera.getImageType()}; base64, ${currentImage}`}
                            width={'95%'}
                            height={'95%'}
                        />
                    </div>
                ) : (
                    <div style={{ height: '68vh' }} className="text-center">
                        <p className="bold" style={{ paddingTop: '30%' }}>
                            <FormattedMessage id="camera.position.noImage" />
                        </p>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <FlexboxGrid align="middle" justify="end">
                    <FlexboxGrid.Item>
                        <Button color="orange" onClick={handleGoToPreset}>
                            <FontAwesomeIcon className="margin-right-5" icon={faHome} />
                            <FormattedMessage id="camera.position.home" />
                        </Button>
                        <Button color="green" onClick={handleSaveCameraHome}>
                            <FontAwesomeIcon className="margin-right-5" icon={faSave} />
                            <FormattedMessage id="camera.position.save" />
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    );
};
