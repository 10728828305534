import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Fragment } from 'react';
import { FlexboxGrid, Loader } from 'rsuite';
import { DisplayPreviewInformation } from '../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayStep } from '../../../../handlers/lpDisplays/VirtualDisplayStep';
import { RenderLPDisplayIcon } from '../../../IpCanElementsPage/LPDisplays/Icons/RenderLPDisplayIcon';

type Props = {
    step: VirtualDisplayStep;
    display: DisplayPreviewInformation;
    disconnectedText: String;
    arrowOrientation: number;
    virtualDisplay: number;
    isMirror: boolean;
};

export const LPMatrixDisplayPreview = ({
    step,
    display,
    disconnectedText,
    arrowOrientation,
    virtualDisplay,
    isMirror,
}: Props) => {
    if (!display.online) return <div>{disconnectedText}</div>;

    const stepMode = step.getMode();

    const shouldShowArrow =
        (stepMode === 1 ||
            (stepMode >= 201 && stepMode <= 232) ||
            (stepMode >= 301 && stepMode <= 316) ||
            (stepMode >= 101 && stepMode <= 132)) &&
        step.getShouldDisplayArrow();

    const shouldShowPicto =
        (stepMode === 2 ||
            (stepMode >= 201 && stepMode <= 232) ||
            (stepMode >= 301 && stepMode <= 316) ||
            (stepMode >= 101 && stepMode <= 132)) &&
        step.getShouldDisplayPicto();

    let justifyContent: any = 'space-around';

    if (stepMode === 1) {
        if (step.getArrowPosition() === 'right') {
            justifyContent = 'end';
        }

        // if (step.getArrowPosition() === 'center' || step.getPictoPosition() === 'center') {
        //     justifyContent = 'space-around';
        // }

        if (step.getArrowPosition() === 'left') {
            justifyContent = 'start';
        }
    }

    if (stepMode === 2) {
        if (step.getPictoPosition() === 'right') {
            justifyContent = 'end';
        }

        if (step.getPictoPosition() === 'left') {
            justifyContent = 'start';
        }
    }

    if (stepMode === 5 || stepMode === 6 || stepMode === 7) {
        justifyContent = 'space-around';
    }

    if (stepMode >= 101 && stepMode <= 132) {
        if (shouldShowArrow && shouldShowPicto) {
            justifyContent = 'space-around';
        }
    }

    const arrowSize = (16 * step.getArrowSize()) / 16;
    const pictoSize = step.getPictoSize() / 16;

    let arrowRotation = step.getArrowRotationOnMap();
    let currentArrowPosition = arrowRotation > 0 && arrowRotation < 180 ? 'right' : 'left';

    if (isMirror) {
        arrowRotation = 360 - arrowRotation;

        if (arrowRotation > 0 && arrowRotation < 180) {
            currentArrowPosition = 'right';
        } else {
            currentArrowPosition = 'left';
        }
    }

    const shouldDisplayLeftPart =
        (shouldShowArrow && currentArrowPosition !== 'right') ||
        (shouldShowPicto && step.getPictoPosition() !== 'right');

    return (
        <FlexboxGrid align="middle" justify={justifyContent} style={{ overflow: 'hidden', flexFlow: 'wrap' }}>
            {shouldDisplayLeftPart && (
                <FlexboxGrid align="middle">
                    {shouldShowArrow && currentArrowPosition !== 'right' && (
                        <FlexboxGrid.Item style={{ overflow: 'hidden' }}>
                            <FontAwesomeIcon
                                data-cy="lpDisplay-virtualDisplay-step-arrow"
                                icon={faArrowUp}
                                color={step.getColorHex()}
                                transform={{ rotate: arrowRotation }}
                                style={{ fontSize: arrowSize }}
                                className={step.getArrowAnimationOnMap(0, isMirror)}
                            />
                        </FlexboxGrid.Item>
                    )}

                    {shouldShowPicto && step.getPictoPosition() !== 'right' && (
                        <FlexboxGrid.Item data-cy="lpDisplay-virtualDisplay-step-picto">
                            <RenderLPDisplayIcon
                                color={step.getColorHex()}
                                icon={step.getPictoImage()}
                                isMap
                                size={pictoSize}
                            />
                        </FlexboxGrid.Item>
                    )}
                </FlexboxGrid>
            )}

            {stepMode >= 5 && stepMode <= 7 && (
                <FlexboxGrid.Item
                    data-cy="lpDisplay-virtualDisplay-displayMatrix"
                    className="matrix-display-demo-text"
                    style={{ color: step.getColorHex(), height: 'fit-content', width: 'fit-content' }}>
                    <p
                        style={{
                            width: 'fit-content',
                            height: 'fit-content',
                            overflow: 'auto',
                            overflowWrap: 'break-word',
                            textAlign: 'center',
                        }}>
                        {stepMode === 5 && moment().format('HH:mm:ss')}
                        {stepMode === 6 && moment().format('DD/MM/YYYY')}
                        {stepMode === 7 && moment().format('DD/MM/YYYY HH:mm:ss')}
                    </p>
                </FlexboxGrid.Item>
            )}

            {stepMode >= 101 && stepMode <= 132 && (
                <FlexboxGrid.Item
                    data-cy="lpDisplay-virtualDisplay-displayMatrix"
                    className="matrix-display-demo-text"
                    style={{ color: step.getColorHex(), height: 'fit-content', width: 'fit-content' }}>
                    {display.countersValue ? (
                        <p
                            style={{
                                width: 'fit-content',
                                height: 'fit-content',
                                overflow: 'auto',
                                overflowWrap: 'break-word',
                                textAlign: 'center',
                                fontSize:
                                    display.countersValue[stepMode - 101] === 0
                                        ? 12
                                        : 17 * display.virtualDisplays[virtualDisplay].getFont(),
                            }}>
                            <Fragment>
                                {display.countersValue[stepMode - 101] === 0
                                    ? display.systemText[0][0]
                                    : display.countersValue[stepMode - 101]}
                            </Fragment>
                        </p>
                    ) : (
                        <Loader />
                    )}
                </FlexboxGrid.Item>
            )}

            {stepMode >= 201 && stepMode <= 232 && (
                <FlexboxGrid.Item
                    data-cy="lpDisplay-virtualDisplay-displayMatrix"
                    className="matrix-display-demo-text"
                    style={{ color: step.getColorHex(), height: 'fit-content', width: 'fit-content' }}>
                    <p
                        style={{
                            width: 'fit-content',
                            height: 'fit-content',
                            overflow: 'auto',
                            overflowWrap: 'break-word',
                            textAlign: 'center',
                            fontSize: 14,
                        }}>
                        {display.systemText[stepMode - 201][0]}
                    </p>
                </FlexboxGrid.Item>
            )}

            {stepMode >= 301 && stepMode <= 316 && (
                <FlexboxGrid.Item
                    data-cy="lpDisplay-virtualDisplay-displayMatrix"
                    className="matrix-display-demo-text"
                    style={{ color: step.getColorHex(), height: 'fit-content', width: 'fit-content' }}>
                    <p
                        style={{
                            width: 'fit-content',
                            height: 'fit-content',
                            overflow: 'auto',
                            overflowWrap: 'break-word',
                            textAlign: 'center',
                            fontSize: 14,
                        }}>
                        {display.userText[stepMode - 301]}
                    </p>
                </FlexboxGrid.Item>
            )}

            {stepMode === 1 && step.getArrowRepeat() && (
                <FlexboxGrid.Item style={{ overflow: 'hidden' }}>
                    <FontAwesomeIcon
                        icon={faArrowUp}
                        color={step.getColorHex()}
                        transform={{ rotate: arrowRotation }}
                        style={{ fontSize: arrowSize }}
                        className={step.getArrowAnimationOnMap(arrowOrientation, isMirror)}
                    />
                </FlexboxGrid.Item>
            )}

            {shouldShowPicto && step.getPictoPosition() === 'right' && (
                <FlexboxGrid.Item>
                    <RenderLPDisplayIcon
                        color={step.getColorHex()}
                        icon={step.getPictoImage()}
                        isMap
                        size={pictoSize}
                    />
                </FlexboxGrid.Item>
            )}

            {shouldShowArrow && currentArrowPosition === 'right' && (
                <FlexboxGrid.Item style={{ overflow: 'hidden' }}>
                    <FontAwesomeIcon
                        icon={faArrowUp}
                        color={step.getColorHex()}
                        transform={{ rotate: arrowRotation }}
                        style={{ fontSize: arrowSize }}
                        className={step.getArrowAnimationOnMap(0, isMirror)}
                    />
                </FlexboxGrid.Item>
            )}
        </FlexboxGrid>
    );
};
