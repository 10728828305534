export default class FlowEdge {
    protected _source: string;
    protected _sourceHandle: string;
    protected _target: string;
    protected _targetHandle: string;
    protected _color: string;

    constructor(source: string, sourceHandle: string, target: string, targetHandle: string, color: string) {
        this._source = source;
        this._sourceHandle = sourceHandle;
        this._target = target;
        this._targetHandle = targetHandle;
        this._color = color;
    }

    public getSource(): string {
        return this._source;
    }

    public getSourceHandle(): string {
        return this._sourceHandle;
    }

    public getTarget(): string {
        return this._target;
    }

    public getTargetHandle(): string {
        return this._targetHandle;
    }

    public toRender() {
        return {
            id: `${this._source}-${this._target}`,
            source: this._source,
            sourceHandle: this._sourceHandle,
            target: this._target,
            targetHandle: this._targetHandle,
            style: {
                stroke: this._color,
                strokeWidth: 3,
            },
        };
    }
}
