import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Drawer } from 'rsuite';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { TopologyInterface } from '../../../../handlers/lpDisplays/LPDisplayTopology';
import { VirtualDisplayPreviewInformation } from '../../../../handlers/lpDisplays/VirtualDisplay';
import DeleteVirtualDisplayModal from '../../LPDisplays/VirtualDisplay/modal/DeleteVirtualDisplayModal';
import { ActionObserver, ActionObserverEvent } from '../handlers/Observers/ActionObserver';
import { TriggerPartDrawer } from './Topology/TriggerPartDrawer';
import { TopologyPreviewDrawer } from './TopologyPreviewDrawer';
import { VirtualDisplayInformationPart } from './VirtualDisplay/VirtualDisplayInformationPart';
import VirtualDisplaySteps from './VirtualDisplay/VirtualDisplaySteps';
import { VirtualDisplayPreviewDrawer } from './VirtualDisplayPreviewDrawer';

type Props = {
    show: boolean;
    onHide: Function;
    data?: DataProps;
    type?: 'topology' | 'virtual-display';
};

export type DataProps = {
    label: string;
    display: LPDisplay;
    virtualDisplay?: VirtualDisplayPreviewInformation;
    topology?: TopologyInterface;
    color?: string;
};

export const LPMatrixDisplayV2ConfigDrawer = (props: Props) => {
    const updateInformation = updatedVirtualDisplay => {
        ActionObserver.emit(ActionObserverEvent.UPDATE_VIRTUAL_DISPLAY, {
            color: props.data?.color,
            label: props.data?.label,
            // display: props.data?.display,
            virtualDisplay: updatedVirtualDisplay,
            topology: props.data?.topology,
        });
    };

    const updateSteps = updatedSteps => {
        ActionObserver.emit(ActionObserverEvent.UPDATE_VIRTUAL_DISPLAY_STEPS, {
            color: props.data?.color,
            label: props.data?.label,
            // display: props.data?.display,
            virtualDisplay: updatedSteps,
            topology: props.data?.topology,
        });
    };

    const updateStepOrder = (oldIndex, newIndex) => {
        ActionObserver.emit(ActionObserverEvent.UPDATE_VIRTUAL_DISPLAY_STEPS_ORDER, {
            virtualDisplay: props.data?.virtualDisplay,
            oldIndex: oldIndex,
            newIndex: newIndex,
            color: props.data?.color,
            label: props.data?.label,
            // display: props.data?.display,
            topology: props.data?.topology,
        });
    };

    const [isDeleteVirtualDisplayModalOpen, setIsDeleteVirtualDisplayModalOpen] = useState(false);

    return (
        <Drawer
            backdrop={false}
            show={props.show}
            size={props.type === 'virtual-display' ? 'md' : 'sm'}
            onHide={() => props.onHide()}>
            {props.data && props.data.display && (
                <Fragment>
                    <Drawer.Header>
                        <Drawer.Title>{props.data.label}</Drawer.Title>
                    </Drawer.Header>

                    <Drawer.Body className="drawer-body-without-avatar">
                        {props.type === 'topology' && props.data.topology && (
                            <Fragment>
                                <TopologyPreviewDrawer display={props.data.display} topology={props.data.topology} />
                                <TriggerPartDrawer
                                    topology={props.data.topology}
                                    topologyIndex={parseInt(props.data.label.replace(/\D/g, '')) - 1}
                                    display={props.data.display.getPreviewInformation()}
                                />
                            </Fragment>
                        )}

                        {props.type === 'virtual-display' && props.data.virtualDisplay && props.data.color && (
                            <Fragment>
                                <VirtualDisplayPreviewDrawer
                                    display={props.data.display.getPreviewInformation()}
                                    virtualDisplay={props.data.virtualDisplay}
                                    color={props.data.color}
                                    updateInformation={updateInformation}
                                />

                                <VirtualDisplayInformationPart
                                    virtualDisplay={props.data.virtualDisplay}
                                    updateInformation={updateInformation}
                                />

                                <VirtualDisplaySteps
                                    display={props.data.display}
                                    virtualDisplay={props.data.virtualDisplay}
                                    updateInformation={updateSteps}
                                    updateStepOrder={updateStepOrder}
                                />
                            </Fragment>
                        )}
                    </Drawer.Body>

                    {props.type === 'virtual-display' && props.data.virtualDisplay && (
                        <>
                            <DeleteVirtualDisplayModal
                                show={isDeleteVirtualDisplayModalOpen}
                                onHide={() => setIsDeleteVirtualDisplayModalOpen(false)}
                                virtualDisplay={props.data.virtualDisplay}
                            />
                            <Drawer.Footer>
                                <Button color="red" onClick={() => setIsDeleteVirtualDisplayModalOpen(true)}>
                                    <FormattedMessage id="ipCan.element.virtualDisplay.delete" />
                                </Button>
                            </Drawer.Footer>
                        </>
                    )}
                </Fragment>
            )}
        </Drawer>
    );
};
