const generateMaskSelector = () => {
    const values: { label: string; value: number }[] = [];

    for (let i = 1; i <= 16; i++) {
        values.push({
            label: i.toString(),
            value: i,
        });
    }

    return values;
};

export const FILTER_US_SENS_VALUE = [
    {
        label: 'Min',
        value: 255,
    },
    {
        label: 'Low',
        value: 240,
    },
    {
        label: 'Medium',
        value: 150,
    },
    {
        label: 'High',
        value: 70,
    },
    {
        label: 'Max',
        value: 0,
    },
];

export const FILTER_MASK_SELECTOR = generateMaskSelector();

export const FILTER_US_HEIGHT_VALUE = [
    {
        label: '175 cm',
        value: 240,
    },
    {
        label: '200 cm',
        value: 180,
    },
    {
        label: '225 cm',
        value: 130,
    },
    {
        label: '250 cm',
        value: 110,
    },
    {
        label: '275 cm',
        value: 90,
    },
    {
        label: '300 cm',
        value: 70,
    },
    {
        label: '325 cm',
        value: 60,
    },
    {
        label: '350 cm',
        value: 50,
    },
    {
        label: '375 cm',
        value: 30,
    },
    {
        label: '400 cm',
        value: 10,
    },
    {
        label: 'DISABLED',
        value: 0,
    },
];

export const FILTER_MEDIAN_VALUE = [
    {
        label: '0',
        value: 0,
    },
    {
        label: '1',
        value: 1,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '5',
        value: 5,
    },
    {
        label: '7',
        value: 7,
    },
    {
        label: '9',
        value: 9,
    },
];
