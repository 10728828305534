import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ControlLabel, FlexboxGrid, Form, FormControl, FormGroup, SelectPicker } from 'rsuite';
import { StepTaskData } from '.';
import { TaskTypeEnum, taskTypes } from '../Tasks/CreateTaskType';

type Props = {
    onClickNext: (name: string, description: string, type: TaskTypeEnum) => void;
    currentTask: StepTaskData;
};

type FormValue = {
    name: string;
    description: string;
    type: TaskTypeEnum;
};

export function FirstStep(props: Props) {
    const intl = useIntl();

    const [formValue, setFormValue] = React.useState<FormValue>({
        name: props.currentTask.name,
        description: props.currentTask.description,
        type: props.currentTask.type,
    });

    const isFormValid = (): boolean => {
        return formValue.name.length > 0 && formValue.description.length > 0 && formValue.type.length > 0;
    };

    const handleClickNext = () => {
        props.onClickNext(formValue.name, formValue.description, formValue.type);
    };

    return (
        <div className="margin-top-10">
            <Form fluid formValue={formValue} onChange={value => setFormValue(value as FormValue)}>
                <FormGroup>
                    <ControlLabel>{intl.formatMessage({ id: 'event.createTask.name' })}</ControlLabel>
                    <FormControl name="name" data-cy="event-createTask-name" />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>{intl.formatMessage({ id: 'event.createTask.description' })}</ControlLabel>
                    <FormControl name="description" data-cy="event-createTask-description" />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>{intl.formatMessage({ id: 'event.createTask.type' })}</ControlLabel>
                    <FormControl
                        data-cy="event-createTask-type"
                        accepter={SelectPicker}
                        searchable={false}
                        cleanable={false}
                        data={taskTypes(intl)}
                        name="type"
                    />
                </FormGroup>
            </Form>

            <FlexboxGrid align="middle" justify="end" className="margin-top-20">
                <Button
                    color="blue"
                    onClick={handleClickNext}
                    disabled={!isFormValid()}
                    data-cy="event-createTask-nextStep">
                    <FormattedMessage id="event.createTask.nextStep" />
                    <FontAwesomeIcon icon={faArrowRight} className="margin-left-5" />
                </Button>
            </FlexboxGrid>
        </div>
    );
}
