import debugMode from './Desc/debugMode';
import forceElement from './Desc/forceElement';
import lpSensorAutoCalibration from './Desc/lpSensorAutoCalibration';
import offline from './Desc/offline';
import online from './Desc/online';
import readRealSensorConfig from './Desc/readRealSensorConfig';
import readSceneConfig from './Desc/readSceneConfig';
import sendScene from './Desc/sendScene';
import updateFilterConfig from './Desc/updateFilterConfig';
import updateMaintenanceState from './Desc/updateMaintenanceState';
import updatePlaceType from './Desc/updatePlaceType';
import updateUsAndFilterConfig from './Desc/updateUsAndFilterConfig';
import updateUSConfig from './Desc/updateUSConfig';

// wait for online, total and db as data
// wait for controler online or not

export default function LPSensorDescCell(props) {
    switch (props) {
        case 'online':
            return online();
        case 'offline':
            return offline();
        case 'update filter config':
            return updateFilterConfig();
        case 'update us config':
            return updateUSConfig();
        case 'update us and filter config':
            return updateUsAndFilterConfig();
        case 'update place type':
            return updatePlaceType();
        case 'force sensor':
            return forceElement();
        case 'debug mode':
            return debugMode();
        case 'start auto calibration':
            return lpSensorAutoCalibration(true);
        case 'end auto calibration':
            return lpSensorAutoCalibration(false);
        case 'read config':
            return readRealSensorConfig();
        case 'read scene config':
            return readSceneConfig();
        case 'send scenes':
            return sendScene();
        case 'update maintenance state':
            return updateMaintenanceState();
        default:
            return props;
    }
}
