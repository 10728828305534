import { IpCan } from './IpCan';

export class IPCanHybrid extends IpCan {
    // /**
    //  * Enumerate elements in the given bus
    //  * @param {number} bus
    //  * @param {boolean} startStop
    //  * @param {Object[]} jump
    //  * @param {number} startId
    //  */
    // sendEnumCommand(bus, startStop, jump, startId) {
    //     if (bus === 3) {
    //         // ONLY TCM DISPLAYS ARE ALLOWED HERE
    //         this.TCM_enumBusDisplayCommand(bus, startStop);
    //     } else {
    //         // ONLY LP SENSORS ARE ALLOWED HERE
    //         this.LPSensor_enumBusCommand(bus, startStop, jump, startId);
    //     }
    // }
}
