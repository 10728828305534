import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    pictoNb: number;
};

export const PictoLoader = (props: Props) => {
    return (
        <>
            <FormattedMessage id="pixelArt.pictoEditor.loading" />
            <FormattedMessage id="pixelArt.pictoEditor.pictoNb" /> {props.pictoNb}
        </>
    );
};
