import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

export default class ConfigDisplayTypeTCM {
    generic: Array<number>;
    pmr: Array<number>;
    family: Array<number>;
    electrical: Array<number>;
    requireUIUpdate: boolean;

    constructor({ generic, pmr, family, electrical }) {
        this.generic = generic;
        this.pmr = pmr;
        this.family = family;
        this.electrical = electrical;
        this.requireUIUpdate = false;
    }

    updateConfigDisplayTypeTCM(displayId, generic, pmr, family, electrical) {
        return axiosService
            .getAxios()
            .put(
                '/devices/tcm-display/updateConfigType',
                {
                    id: displayId,
                    configType: {
                        generic,
                        pmr,
                        family,
                        electrical,
                    },
                },
                { headers: authHeader() }
            )
            .then(() => {
                this.setGeneric(generic);
                this.setPmr(pmr);
                this.setFamily(family);
                this.setElectrical(electrical);
            });
    }

    setConfigDisplayTypeTCM({ generic, pmr, family, electrical }) {
        this.setGeneric(generic);
        this.setPmr(pmr);
        this.setFamily(family);
        this.setElectrical(electrical);

        return this.requireUIUpdate;
    }

    setGeneric(generic) {
        if (!this.areObjectsEquals(generic, this.generic)) {
            this.generic = generic;
            this.requireUIUpdate = true;
        }
    }

    setPmr(pmr) {
        if (!this.areObjectsEquals(pmr, this.pmr)) {
            this.pmr = pmr;
            this.requireUIUpdate = true;
        }
    }

    setFamily(family) {
        if (!this.areObjectsEquals(family, this.family)) {
            this.family = family;
            this.requireUIUpdate = true;
        }
    }

    setElectrical(electrical) {
        if (!this.areObjectsEquals(electrical, this.electrical)) {
            this.electrical = electrical;
            this.requireUIUpdate = true;
        }
    }

    areObjectsEquals(obj1, obj2) {
        if (!obj1 || !obj2) return false;

        const obj1Keys = Object.keys(obj1);
        const obj2Keys = Object.keys(obj2);

        if (obj1Keys.length !== obj2Keys.length) {
            return false;
        }

        for (let objKey of obj1Keys) {
            if (obj1[objKey] !== obj2[objKey]) {
                return false;
            }
        }

        return true;
    }
}
