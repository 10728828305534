import { faPlusCircle, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Button, Col, FlexboxGrid, Panel } from 'rsuite';
import { IpCan } from '../../handlers/ipcan/IpCan';
import { createIpCan } from '../../handlers/ipcan/IpCanHelper';
import { IpCanObserver } from '../../handlers/ipcan/observers/ipCanObserver';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import IpCanDeviceDb from './Cards/IpCanDeviceDb';
import IpCanDeviceOnline from './Cards/IpCanDeviceOnline';
import IpCanOnlineState from './Cards/IpCanOnline';
import IpCanDrawer from './Drawer';
import CreateIpCanModal from './Modal/CreateIpCanModal';
import nbElements from './Table/nbElements';
import onlineState from './Table/onlineState';

type Props = WrappedComponentProps;
type State = {
    isLoading: boolean;
    hasError: boolean;
    controllersData: Array<IpCan>;
    sortedControllers: Array<IpCan>;
    selectedController: number | null;
    showIpCanDrawer: boolean;
    showIpCanModal: boolean;
    renderBoolean: boolean;
    sortDirection: 'asc' | 'desc';
};
class IPCanPage extends React.Component<Props, State> {
    columns: TableColumn<RowRecord>[];

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasError: false,
            controllersData: [],
            sortedControllers: [],
            selectedController: null,
            showIpCanDrawer: false,
            showIpCanModal: false,
            renderBoolean: false,
            sortDirection: 'asc',
        };

        IpCanObserver.subscribe(() => this.loadIpCan());

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.label',
                }),
                selector: row => (
                    <div data-tag="allowRowEvents" data-cy="controllers-label">
                        {row.label}
                    </div>
                ),
                id: 'label',
                sortable: true,
                grow: 1,
                center: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.online',
                }),
                selector: row => row.online,
                sortable: true,
                grow: 1,
                center: true,
                cell: row => onlineState(row.online),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.macAddress',
                }),
                sortable: true,
                selector: row => row.macStr,
                grow: 1,
                center: true,
                cell: row => (
                    <div data-tag="allowRowEvents" data-cy="controllers-macAddress">
                        {row.macStr}
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.ipAddress',
                }),
                sortable: true,
                grow: 1,
                center: true,
                cell: row => (
                    <div data-tag="allowRowEvents" data-cy="controllers-ipAddress">
                        {row.ip}
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.version',
                }),
                grow: 1,
                center: true,
                cell: row => (
                    <div data-tag="allowRowEvents" data-cy="controllers-version">
                        {row.version}
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.nbReboot',
                }),
                grow: 1,
                center: true,
                cell: row => (
                    <div data-tag="allowRowEvents" data-cy="controllers-nbReboot">
                        {row.nbReboot}
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.lastOnlineStateDate',
                }),
                grow: 2,
                center: true,
                cell: row => (
                    <div data-tag="allowRowEvents" data-cy="controllers-lastOnlineStateDate">
                        {row.lastOnlineStateDate}
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.sensors',
                }),
                grow: 1,
                center: true,
                cell: row => nbElements(row.online, row.nbSensors, false),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.displays',
                }),
                grow: 1,
                center: true,
                cell: row => nbElements(row.online, row.nbDisplays, false),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controllers.creationInformations',
                }),
                grow: 2,
                center: true,
                cell: row => (
                    <div data-tag="allowRowEvents" data-cy="controllers-creationInformations">
                        {row.creationInformations}
                    </div>
                ),
            },
        ];

        this.showIpCanDrawer = this.showIpCanDrawer.bind(this);
        this.hideIpCanDrawer = this.hideIpCanDrawer.bind(this);
        this.handleRowClicked = this.handleRowClicked.bind(this);
        this.updateIpCanList = this.updateIpCanList.bind(this);
        this.keepAlive = this.keepAlive.bind(this);
        this.loadIpCan = this.loadIpCan.bind(this);
        this.openCreateIpCanModal = this.openCreateIpCanModal.bind(this);
        this.closeCreateIpCanModal = this.closeCreateIpCanModal.bind(this);
    }

    componentDidMount() {
        webSocketService.joinRoom('ipcanmodule');

        this.setState({
            isLoading: true,
        });

        this.loadIpCan();

        webSocketService.onEvent('ipcanmodule:updateStatus', this.updateIpCanList);
        webSocketService.onEvent('ipcanmodule:keepAlive', this.keepAlive);
    }

    loadIpCan() {
        this.setState({ isLoading: true });
        axiosService
            .getAxios()
            .get('/ipcanmodules', { headers: authHeader() })
            .then(controllersResponse => {
                let controllers: Array<IpCan> = controllersResponse.data.map(controller => {
                    return createIpCan(controller);
                });

                let sortedControllers: Array<IpCan> = [];

                if (this.state.sortDirection === 'asc') {
                    sortedControllers = controllers.sort((a, b) => {
                        return a.label.localeCompare(b.label, 'fr', {
                            numeric: true,
                            sensitivity: 'variant',
                            ignorePunctuation: true,
                            caseFirst: 'upper',
                        });
                    });
                } else {
                    sortedControllers = controllers.sort((a, b) => {
                        return b.label.localeCompare(a.label, 'fr', {
                            numeric: true,
                            sensitivity: 'variant',
                            ignorePunctuation: true,
                            caseFirst: 'upper',
                        });
                    });
                }

                this.setState({ controllersData: controllers, sortedControllers: sortedControllers });
            })
            .catch(err => {
                // console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    handleSetSortDirection = (direction: 'asc' | 'desc') => {
        const sortedControllers = [...this.state.controllersData];

        if (direction === 'asc') {
            sortedControllers.sort((a, b) => {
                return a.label.localeCompare(b.label, 'fr', {
                    numeric: true,
                    sensitivity: 'variant',
                    ignorePunctuation: true,
                    caseFirst: 'upper',
                });
            });
        } else {
            sortedControllers.sort((a, b) => {
                return b.label.localeCompare(a.label, 'fr', {
                    numeric: true,
                    sensitivity: 'variant',
                    ignorePunctuation: true,
                    caseFirst: 'upper',
                });
            });
        }

        this.setState({
            sortDirection: direction,
            sortedControllers: sortedControllers,
        });
    };

    componentWillUnmount() {
        webSocketService.offEvent('ipcanmodule:updateStatus', this.updateIpCanList);
        webSocketService.offEvent('ipcanmodule:keepAlive', this.keepAlive);
        IpCanObserver.unsubscribe(() => this.loadIpCan());
    }

    updateIpCanList(data) {
        const controllers = this.state.controllersData;

        const index = controllers.findIndex(controller => controller.mac === data.mac);

        if (index !== -1) {
            controllers[index].setWebsocketUpdateIpCanList(data);

            this.setState({
                controllersData: controllers,
            });
        } else {
            this.loadIpCan();
        }
    }

    keepAlive(data) {
        // console.log(data);
        let shouldUpdate = false;

        const controllers = this.state.controllersData;

        const index = controllers.findIndex(controller => controller.mac === data.macAddress);

        if (index !== -1) {
            controllers[index].setWebsocketInformations(data);

            if (controllers[index].nbSensors.onDB < controllers[index].nbSensors.onModule) shouldUpdate = true;
            if (controllers[index].nbDisplays.onDB < controllers[index].nbDisplays.onModule) shouldUpdate = true;
            if (controllers[index].getNbDevicesOnBus()[0].onDB < controllers[index].getNbDevicesOnBus()[0].onModule)
                shouldUpdate = true;
            if (controllers[index].getNbDevicesOnBus()[1].onDB < controllers[index].getNbDevicesOnBus()[1].onModule)
                shouldUpdate = true;
            if (controllers[index].getNbDevicesOnBus()[2]?.onDB < controllers[index].getNbDevicesOnBus()[2]?.onModule)
                shouldUpdate = true;

            if (shouldUpdate) {
                this.loadIpCan();
            } else {
                this.setState({
                    controllersData: controllers,
                });
            }
        }
    }

    showIpCanDrawer() {
        this.setState({
            showIpCanDrawer: true,
        });
    }

    hideIpCanDrawer() {
        this.setState({
            showIpCanDrawer: false,
        });
    }

    handleRowClicked(row) {
        this.setState(
            {
                selectedController: row.id,
            },
            () => {
                this.showIpCanDrawer();
            }
        );
    }

    openCreateIpCanModal() {
        this.setState({
            showIpCanModal: true,
        });
    }

    closeCreateIpCanModal() {
        this.setState({
            showIpCanModal: false,
        });

        this.loadIpCan();
    }

    render() {
        const selectedController = this.state.controllersData.find(
            controller => controller.id === this.state.selectedController
        );

        const conditionalRowStyles = [
            {
                when: row => {
                    if (!row.online) return true;

                    const nbSensorsOnDB = row.nbSensors.onDB;
                    const nbSensorsOnModule = row.nbSensors.onModule;
                    const nbSensorsOnline = row.nbSensors.online;

                    const nbDisplaysOnDB = row.nbDisplays.onDB;
                    const nbDisplaysOnModule = row.nbDisplays.onModule;
                    const nbDisplaysOnline = row.nbDisplays.online;

                    if (
                        nbSensorsOnDB !== nbSensorsOnModule ||
                        nbSensorsOnDB !== nbSensorsOnline ||
                        nbSensorsOnModule !== nbSensorsOnline
                    )
                        return true;
                    if (
                        nbDisplaysOnDB !== nbDisplaysOnModule ||
                        nbDisplaysOnDB !== nbDisplaysOnline ||
                        nbDisplaysOnModule !== nbDisplaysOnline
                    )
                        return true;

                    return false;
                },
                style: {
                    backgroundColor: 'rgba(255, 0, 0, 0.15)',
                },
            },
        ];

        return (
            <Fragment>
                {selectedController && (
                    <IpCanDrawer
                        ipcan={selectedController}
                        show={this.state.showIpCanDrawer}
                        onHide={this.hideIpCanDrawer}
                        loadIpCan={this.loadIpCan}
                        isLoading={this.state.isLoading}
                    />
                )}
                <CreateIpCanModal
                    createModalOpen={this.state.showIpCanModal}
                    closeCreateModal={this.closeCreateIpCanModal}
                />
                <Panel
                    className="panel-big element-table dashboard-card-header hoverable"
                    shaded
                    bordered
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'controllers.title' })}
                            tagValue={this.state.sortedControllers.length}
                            icon={faServer}
                            buttons={[
                                <SecuredFragment
                                    authorizedRoles={[rolesConstants.controller.CREATE]}
                                    key="ipcan-create">
                                    <Button
                                        color="red"
                                        size="sm"
                                        onClick={() => this.openCreateIpCanModal()}
                                        data-cy="IPCan-addIPCan-button">
                                        <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                        <FormattedMessage id="controllers.add" />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }
                    bodyFill>
                    <ElementTable
                        columns={this.columns}
                        data={this.state.sortedControllers.map(controller => controller.getTableData())}
                        progressPending={this.state.isLoading}
                        onRowClicked={this.handleRowClicked}
                        small={false}
                        conditionalRowStyles={conditionalRowStyles}
                        defaultSortAsc={true}
                        defaultSortFieldId="label"
                        onSort={(_, direction) => {
                            this.handleSetSortDirection(direction);
                        }}
                    />
                </Panel>

                <FlexboxGrid>
                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <IpCanDeviceOnline listIpCan={this.state.controllersData} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <IpCanDeviceDb listIpCan={this.state.controllersData} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <IpCanOnlineState listIpCan={this.state.controllersData} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Fragment>
        );
    }
}

export default injectIntl(IPCanPage);
