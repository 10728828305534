import {
    faCheck,
    faCogs,
    faExchangeAlt,
    faLightbulb,
    faTimesCircle,
    faTrashAlt,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Col, FlexboxGrid, Grid, Modal, Row } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import IPCanTcm from '../../../handlers/ipcan/IpCanTCM';
import { notificationActions } from '../../../redux/actions';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import SetManyIdModal from './SetManyIdModal';
import SetOneIdModal from './SetOneIdModal';
import StopForceTCMModal from './StopForceTCMModal';

const mapDispatchToProps = dispatch => ({
    createNotification: (type, messageId) => dispatch(notificationActions.createNotification(type, messageId)),
});

type Props = {
    ipCan: IpCan;
    bus: number;
    createNotification: Function;
    onHide: Function;
    show: boolean;
};

type State = {
    loading: boolean;
    showSetManyIdModal: boolean;
    openStopForceModal: boolean;
    isFetching: boolean;
    hasError: boolean;
    ipcan?: IPCanTcm;
    showResetModal: boolean;
    showSetOneIdModal: boolean;
};

class TCMBusActionsModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showSetManyIdModal: false,
            openStopForceModal: false,
            isFetching: true,
            hasError: false,
            ipcan: undefined,
            showResetModal: false,
            showSetOneIdModal: false,
        };

        this.showResetModal = this.showResetModal.bind(this);
        this.hideResetModal = this.hideResetModal.bind(this);

        this.showSetManyIdModal = this.showSetManyIdModal.bind(this);
        this.hideSetManyIdModal = this.hideSetManyIdModal.bind(this);

        this.openStopForceModal = this.openStopForceModal.bind(this);
        this.closeStopForceModal = this.closeStopForceModal.bind(this);

        this.showSetOneIdModal = this.showSetOneIdModal.bind(this);
        this.hideSetOneIdModal = this.hideSetOneIdModal.bind(this);
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get(`/ipcanmodules/${this.props.ipCan?.id}`, { headers: authHeader() })
            .then(ipcanmoduleResponse => {
                const ipcan = new IPCanTcm(ipcanmoduleResponse.data);

                this.setState({
                    ipcan: ipcan,
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isFetching: false,
                });
            });
    }

    handleClick(cmd) {
        this.setState({
            loading: true,
        });

        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/cmdTCM',
                {
                    id: this.state.ipcan?.id,
                    bus: this.props.bus,
                    cmd,
                },
                { headers: authHeader() }
            )
            .then(() => {
                this.props.createNotification('success', `ipcan.actions.${cmd}.success`);

                this.setState({
                    loading: false,
                });
            })
            .catch(err => {
                console.error(err);

                this.props.createNotification('error', `ipcan.actions.${cmd}.error`);

                this.setState({
                    loading: false,
                });
            });
    }

    hideResetModal() {
        this.setState({
            showResetModal: false,
        });
    }

    showResetModal() {
        this.setState({
            showResetModal: true,
        });
    }

    showSetOneIdModal() {
        this.setState({
            showSetOneIdModal: true,
        });
    }

    hideSetOneIdModal() {
        this.setState({
            showSetOneIdModal: false,
        });
    }

    showSetManyIdModal() {
        this.setState({
            showSetManyIdModal: true,
        });
    }

    hideSetManyIdModal() {
        this.setState({
            showSetManyIdModal: false,
        });
    }

    openStopForceModal() {
        this.setState({
            openStopForceModal: true,
        });
    }

    closeStopForceModal() {
        this.setState({
            openStopForceModal: false,
        });
        this.props.onHide();
    }

    render() {
        return (
            <Fragment>
                {!this.state.isFetching && (
                    <Fragment>
                        <ResetBusModal
                            ipCan={this.state.ipcan?.getId()}
                            bus={this.props.bus}
                            show={this.state.showResetModal}
                            onHide={this.hideResetModal}
                            createNotification={this.props.createNotification}
                        />

                        <SetOneIdModal
                            ipCan={this.state.ipcan}
                            bus={this.props.bus}
                            show={this.state.showSetOneIdModal}
                            onHide={this.hideSetOneIdModal}
                        />

                        <SetManyIdModal
                            ipCan={this.state.ipcan}
                            bus={this.props.bus}
                            show={this.state.showSetManyIdModal}
                            onHide={this.hideSetManyIdModal}
                        />

                        <StopForceTCMModal
                            show={this.state.openStopForceModal}
                            onHide={this.closeStopForceModal}
                            createNotification={this.props.createNotification}
                            ipcanId={this.state.ipcan?.getId()}
                            bus={this.props.bus}
                        />
                    </Fragment>
                )}

                <Modal show={this.props.show} onHide={this.openStopForceModal} overflow={false}>
                    <Modal.Header>
                        <Modal.Title data-cy="ipCan-bus-title">
                            <FormattedMessage id="ipCan.bus.actions.title" />
                            <span className="margin-left-5">
                                {' '}
                                - {this.state.ipcan ? this.state.ipcan.label : '...'} - {this.props.bus + 1}
                            </span>
                        </Modal.Title>
                        <Modal.Body style={{ height: '100%' }}>
                            {/* Forçage */}
                            <Grid fluid>
                                <Row>
                                    <SecuredFragment authorizedRoles={[rolesConstants.controller.COMMAND_TCM]}>
                                        <Col xs={12}>
                                            <Button
                                                data-cy="ipCan-force-black"
                                                className="button-icon-black"
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={() => this.handleClick('forceAllSensorToBlack')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceBlack" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-green"
                                                color="green"
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={() => this.handleClick('forceAllSensorToGreen')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceGreen" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-red"
                                                color="red"
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={() => this.handleClick('forceAllSensorToRed')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceRed" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-redGreen"
                                                className="button-icon-redGreen"
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={() => this.handleClick('forceAllSensorToRedGreen')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceRedAndGreen" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-redBlink"
                                                className="button-icon-redBlink"
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={() => this.handleClick('forceAllSensorToRedBlink')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceRedBlink" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-greenBlink"
                                                className="button-icon-greenBlink"
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={() => this.handleClick('forceAllSensorToGreenBlink')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceGreenBlink" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-force-violet"
                                                color="violet"
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={() => this.handleClick('disableforcedAllSensor')}
                                                block>
                                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.disableForce" />
                                            </Button>
                                        </Col>
                                    </SecuredFragment>
                                    {/* Other actions */}
                                    <Col xs={12}>
                                        <SecuredFragment authorizedRoles={[rolesConstants.controller.SET_ONE_ID]}>
                                            <Button
                                                data-cy="ipCan-setOneId"
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={this.showSetOneIdModal}
                                                color="orange"
                                                block>
                                                <FontAwesomeIcon icon={faExchangeAlt} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.defineOneId" />
                                            </Button>
                                        </SecuredFragment>

                                        <SecuredFragment authorizedRoles={[rolesConstants.controller.SET_MANY_ID]}>
                                            <Button
                                                data-cy="ipCan-setManyId"
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={this.showSetManyIdModal}
                                                color="orange"
                                                block>
                                                <FontAwesomeIcon icon={faWrench} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.defineManyId" />
                                            </Button>
                                        </SecuredFragment>

                                        <SecuredFragment authorizedRoles={[rolesConstants.controller.COMMAND_TCM]}>
                                            <Button
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={() => this.handleClick('sendAllDevicesConfig')}
                                                color="orange"
                                                data-cy="ipCan-bus-sendBackConfiguration"
                                                block>
                                                <FontAwesomeIcon icon={faCogs} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.sendBackConfiguration" />
                                            </Button>
                                        </SecuredFragment>

                                        <SecuredFragment authorizedRoles={[rolesConstants.controller.DELETE_BUS]}>
                                            <Button
                                                loading={this.state.loading || this.state.isFetching}
                                                onClick={this.showResetModal}
                                                block
                                                data-cy="ipCan-bus-resetBus"
                                                color="red">
                                                <FontAwesomeIcon icon={faTrashAlt} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.resetBus" />
                                            </Button>
                                        </SecuredFragment>
                                    </Col>
                                </Row>
                            </Grid>
                        </Modal.Body>
                    </Modal.Header>
                </Modal>
            </Fragment>
        );
    }
}

type ResetBusProps = {
    ipCan: number | undefined;
    bus: number;
    createNotification: Function;
    onHide: Function;
    show: boolean;
};

type ResetBusState = {
    loading: boolean;
    isFetching: boolean;
};

class ResetBusModal extends Component<ResetBusProps, ResetBusState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            isFetching: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
            loading: true,
        });

        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/cmdTCM',
                {
                    id: this.props.ipCan,
                    bus: this.props.bus,
                    cmd: 'clearBus',
                },
                { headers: authHeader() }
            )
            .then(() => {
                this.props.createNotification('success', 'ipcan.actions.clearBus.success');

                this.setState({
                    loading: false,
                });

                this.props.onHide();
            })
            .catch(err => {
                console.error(err);

                this.props.createNotification('error', 'ipcan.actions.clearBus.error');

                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        return (
            <Modal overflow={false} show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCan.bus.reset.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="ipCan.bus.reset.message" />
                </Modal.Body>
                <Modal.Footer>
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="ipCan-bus-reset-cancel"
                                    loading={this.state.loading || this.state.isFetching}
                                    onClick={() => this.props.onHide()}
                                    color="red">
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    data-cy="ipCan-bus-reset-valid"
                                    loading={this.state.loading || this.state.isFetching}
                                    onClick={this.handleClick}
                                    color="green">
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(null, mapDispatchToProps)(TCMBusActionsModal);
