import { faCheck, faSquareFull, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayStep } from '../../../../handlers/lpDisplays/VirtualDisplayStep';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { LPVirtualDisplayStepArrow } from '../../../IpCanElementsPage/LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepArrow';
import { LPVirtualDisplayStepCounter } from '../../../IpCanElementsPage/LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepCounter';
import { LPVirtualDisplayStepDate } from '../../../IpCanElementsPage/LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepDate';
import { LPVirtualDisplayStepPicto } from '../../../IpCanElementsPage/LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepPicto';
import { LPVirtualDisplayStepText } from '../../../IpCanElementsPage/LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepText';
import VirtualDisplayPreview from '../../../IpCanElementsPage/LPMatrixDisplayV2Config/Nodes/VirtualDisplayNode/VirtualDisplayPreview';

type Props = {
    option: Record<string, any>;
} & WrappedComponentProps;

class ForceLPMatrixDisplayV2 extends React.Component<Props> {
    render() {
        return (
            <div>
                <div data-cy="historic-lpMatrixDisplayV2-isForce">
                    <FormattedMessage id="lpMatrixDisplayV2.history.isForce" />
                    <span className="margin-left-5">-</span>
                    <FontAwesomeIcon
                        className="margin-left-5"
                        icon={this.props.option.isForce ? faCheck : faTimes}
                        color={this.props.option.isForce ? 'green' : 'red'}
                    />
                </div>
                <div data-cy="historic-lpMatrixDisplayV2-endForceTime">
                    <FormattedMessage id="lpMatrixDisplayV2.history.endForceTime" />
                    <span className="margin-left-5">-</span>
                    <span className="margin-left-5">
                        {moment(this.props.option.endForceTime).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </div>
                {this.props.option.isBlack && (
                    <div data-cy="historic-lpMatrixDisplayV2-isBlack">
                        <FormattedMessage id="lpMatrixDisplayV2.history.display-off" />
                    </div>
                )}
                {!this.props.option.isBlack && this.props.option.topologyNum > -1 && (
                    <div data-cy="historic-lpMatrixDisplayV2-topologyNum">
                        <FormattedMessage id="lpMatrixDisplayV2.history.topologyNum" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.option.topologyNum + 1}</span>
                    </div>
                )}
                {!this.props.option.isBlack && this.props.option.counters.length > 0 && (
                    <div data-cy="historic-lpMatrixDisplayV2-NBcounters">
                        <FormattedMessage id="lpMatrixDisplayV2.history.NBcounters" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.option.counters.length}</span>
                    </div>
                )}
                {!this.props.option.isBlack && this.props.option.virtualDisplays.length > 0 && (
                    <div data-cy="historic-lpMatrixDisplayV2-NBvirtualDisplay">
                        <FormattedMessage id="lpMatrixDisplayV2.history.NBvirtualDisplay" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.option.virtualDisplays.length}</span>
                    </div>
                )}
            </div>
        );
    }
}

export default injectIntl(ForceLPMatrixDisplayV2);
