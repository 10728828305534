import L from 'leaflet';
import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Loader, Modal } from 'rsuite';
import { interpret } from 'xstate';
import { DrawerInformationInterface } from '..';
import Counter from '../../../handlers/Counter/Counter';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { MapLPMatrixDisplayV2 } from '../../../handlers/map/MapLPMatrixDisplayV2';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import { DisplayMachine } from '../Hooks/DisplayMachine';
import LPMatrixDisplayV2Icon from '../Icons/LPMatrixDisplayV2/LPMatrixDisplayV2Icon';
import TCMCreateDisplayIcon from '../Icons/TCMCreateDisplayIcon';
import TCMDisplayIcon from '../Icons/TCMDisplay/TCMDisplayIcon';
import CreateDisplayModal from '../Modal/Display/CreateDisplayModal';

type Props = {
    levelId: number;
    placeTypes: Array<Record<string, any>>;
    controllers: IpCan[];
    reloadDisplays: Function;
    displays: Array<Record<string, any>>;
    lpMatrixDisplayV2: MapLPMatrixDisplayV2[];
    isEdit: boolean;
    editMode: boolean;
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
    service: any;
    counters: Counter[];
} & WrappedComponentProps;

type State = {
    current: Record<string, any>;
    key: number;
    currentChange: number;
    reload: number;
};

let stepChangeInterval;
const STEP_CHANGE_INTERVAL = 1000;
class DisplaysLayer extends React.Component<Props, State> {
    service: any;
    constructor(props) {
        super(props);

        this.state = {
            current: DisplayMachine.initialState,
            key: 0,
            currentChange: 0,
            reload: 0,
        };

        this.cancelCreation = this.cancelCreation.bind(this);
        this.validCreation = this.validCreation.bind(this);
        this.onChange = this.onChange.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.stopEdition = this.stopEdition.bind(this);
        this.displayCreated = this.displayCreated.bind(this);
        this.displayEdited = this.displayEdited.bind(this);
        this.displayDeleted = this.displayDeleted.bind(this);
        this.onTransition = this.onTransition.bind(this);

        this.service = interpret(
            DisplayMachine.withContext({
                ...DisplayMachine.context,
                levelId: this.props.levelId,
                intl: this.props.intl,
            })
        ).onTransition(current => {
            this.onTransition(current);
        });
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.displays.length !== this.props.displays.length) {
            this.setState({
                key: this.state.key + 1,
            });
        }
    };

    componentDidMount() {
        this.service.start();

        stepChangeInterval = setInterval(() => {
            let shouldReload = false;

            this.props.lpMatrixDisplayV2.forEach(lpMatrixDisplayV2 => {
                let result = lpMatrixDisplayV2.getLpMatrixDisplayV2().updateVirtualDisplaysDisplay();
                if (!shouldReload) {
                    shouldReload = result;
                }
            });

            this.setState({
                reload: this.state.reload + 1,
            });
        }, STEP_CHANGE_INTERVAL);
    }

    componentWillUnmount() {
        if (stepChangeInterval) clearInterval(stepChangeInterval);
        this.service.stop();
    }

    cancelCreation() {
        this.service.send('CANCEL_CREATE_DISPLAY');
    }

    validCreation() {
        this.service.send('VALID_DISPLAY_FORM');
    }

    displayCreated(event) {
        let geojson = [event.layer._latlng.lat, event.layer._latlng.lng];

        event.layer.remove();

        this.service.send('CREATE_DISPLAY', { geojson });
    }

    displayDeleted(event) {
        let tcmDisplayIds: Array<number> = [];
        let lpDisplayIds: Array<number> = [];

        for (let l in event.layers._layers) {
            const name = event.layers._layers[l].options.name;

            if (name.includes('tcmDisplay')) {
                tcmDisplayIds.push(parseInt(name.split('-')[1]));
            }

            if (name.includes('lpDisplay')) {
                lpDisplayIds.push(parseInt(name.split('-')[1]));
            }
        }

        this.service.send('DELETE_DISPLAY', { tcmDisplayIds, lpDisplayIds });
    }

    displayEdited(event) {
        let displaysTcm: Array<Record<string, any>> = [];
        let displaysLp: Array<Record<string, any>> = [];

        for (let l in event.layers._layers) {
            const name = event.layers._layers[l].options.name;

            if (name.includes('tcmDisplay')) {
                displaysTcm.push({
                    id: parseInt(name.split('-')[1]),
                    geoJSON: [event.layers._layers[l]._latlng.lat, event.layers._layers[l]._latlng.lng],
                });
            }

            if (name.includes('lpDisplay')) {
                displaysLp.push({
                    id: parseInt(name.split('-')[1]),
                    geoJSON: [event.layers._layers[l]._latlng.lat, event.layers._layers[l]._latlng.lng],
                });
            }
        }

        if (displaysTcm.length > 0 || displaysLp.length > 0) {
            this.service.send('EDIT_DISPLAY', { displaysTcm, displaysLp });
        }
    }

    onChange(value) {
        this.service.send('CREATE_DISPLAY_FORM_CHANGE', { value });
    }

    onTransition(current) {
        this.setState(
            {
                current,
            },
            () => {
                if (current.matches('reload')) {
                    this.props.reloadDisplays('displays');
                    this.service.send('DISPLAY_RELOADED');
                }
            }
        );
    }

    startEdition() {
        this.service.send('START_EDIT_DISPLAY_MODE');
        clearInterval(stepChangeInterval);
    }

    stopEdition() {
        this.service.send('CANCEL_EDIT_DISPLAY_MODE');
        stepChangeInterval = setInterval(() => {
            this.props.lpMatrixDisplayV2.forEach(lpMatrixDisplayV2 =>
                lpMatrixDisplayV2.getLpMatrixDisplayV2().updateVirtualDisplaysDisplay()
            );
        }, STEP_CHANGE_INTERVAL);
    }

    render() {
        const { current } = this.state;

        // @ts-ignore
        L.EditToolbar.Delete.include({
            removeAllLayers: false,
        });

        return (
            <FeatureGroup>
                <Modal backdrop={'static'} show={current.context.loading}>
                    <Modal.Body className="text-center">
                        <Loader content="Chargement des afficheurs..." vertical />
                    </Modal.Body>
                </Modal>

                <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.VIEW_LIST]}>
                    {this.props.displays.map(display => {
                        return (
                            <TCMDisplayIcon
                                display={display}
                                key={`tcm-display-${display.id}`}
                                controllers={this.props.controllers}
                                reloadDisplays={this.props.reloadDisplays}
                                editMode={this.props.editMode}
                                placeTypes={this.props.placeTypes}
                                drawerInformation={this.props.drawerInformation}
                                openDrawer={this.props.openDrawer}
                                closeDrawer={this.props.closeDrawer}
                                service={this.props.service}
                            />
                        );
                    })}
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.VIEW_LIST]}>
                    {this.props.lpMatrixDisplayV2.map(display => {
                        return (
                            <LPMatrixDisplayV2Icon
                                key={`lp-display-${display.id}`}
                                display={display}
                                controllers={this.props.controllers}
                                editMode={this.props.editMode}
                                drawerInformation={this.props.drawerInformation}
                                openDrawer={this.props.openDrawer}
                                closeDrawer={this.props.closeDrawer}
                                service={this.props.service}
                                counters={this.props.counters}
                                placeTypes={this.props.placeTypes}
                                currentChange={this.state.currentChange}
                                reload={this.state.reload}
                            />
                        );
                    })}
                </SecuredFragment>

                {this.props.editMode && (
                    <Fragment>
                        <EditControl
                            key={this.state.key}
                            position="topright"
                            onCreated={this.displayCreated}
                            onDeleted={this.displayDeleted}
                            onEdited={this.displayEdited}
                            onEditStart={this.startEdition}
                            onEditStop={this.stopEdition}
                            onDeleteStart={this.startEdition}
                            onDeleteStop={this.stopEdition}
                            draw={{
                                rectangle: false,
                                circle: false,
                                marker: {
                                    icon: TCMCreateDisplayIcon,
                                },
                                circlemarker: false,
                                polygon: false,
                                polyline: false,
                            }}
                            edit={{
                                delete: {
                                    removeAllLayers: false,
                                },
                            }}
                        />

                        <SecuredFragment authorizedRoles={[rolesConstants.mapTCMDisplays.CREATE]}>
                            <CreateDisplayModal
                                cancelCreation={this.cancelCreation}
                                validCreation={this.validCreation}
                                onChange={this.onChange}
                                controllers={this.props.controllers}
                                values={current.context.createDisplayForm}
                                show={current.context.createDisplayModalOpen}
                                loading={current.context.createDisplayFormLoading}
                                error={current.context.creationError}
                            />
                        </SecuredFragment>
                    </Fragment>
                )}
            </FeatureGroup>
        );
    }
}

export default injectIntl(DisplaysLayer);
