import moment from 'moment';

export function userHandler(user) {
    const roles: any[] = [];

    user.profiles?.forEach(profile => {
        profile.roles.forEach(role => {
            roles.push(role);
        });
    });

    return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        name: `${user.firstName} ${user.lastName}`,
        enabled: user.enabled,
        email: user.email,
        updateInformations: user.updatedAt
            ? `${user.updatedBy} - ${moment(user.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`
            : '-',
        createInformations: `${user.createdBy} - ${moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss')}`,
        loggedIn: user.loggedIn,
        lastLogin: user.lastLogin ? moment(user.lastLogin).format('DD/MM/YYYY HH:mm:ss') : '-',
        profiles: user.profiles,
        roles: roles,
        createdAt: moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: user.createdBy,
        updatedAt: user.updatedAt ? moment(user.updatedAt).format('DD/MM/YYYY HH:mm:ss') : '-',
        updatedBy: user.updatedBy,
        parkingList: user.parkingList,
    };
}
