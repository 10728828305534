import React, { Fragment } from 'react';
import { RowRecord } from 'react-data-table-component';
import { FormattedMessage, useIntl } from 'react-intl';
import ElementTable from '../../../ReactDataTableComponent/ElementTable';

type Props = RowRecord;

export const LPMatrixDisplayV2TableKeyText = (props: Props) => {
    const intl = useIntl();
    const columns = [
        {
            name: intl.formatMessage({
                id: 'ipCanDevices.LPMatrixDisplayV2.systemText.label',
            }),
            center: true,
            cell: row => (
                <div data-cy="LPMatrixDisplayV2-systemText-edit-label">
                    <FormattedMessage id={'ipCanDevices.LPMatrixDisplayV2.systemText.key'} />
                    <span className="margin-left-5">{row.index + 1}</span>
                </div>
            ),
        },
        {
            name: intl.formatMessage({
                id: 'ipCanDevices.LPMatrixDisplayV2.systemText.lang0',
            }),
            center: true,
            cell: row => row.entry.text[0],
        },
        {
            name: intl.formatMessage({
                id: 'ipCanDevices.LPMatrixDisplayV2.systemText.lang1',
            }),
            center: true,
            cell: row => row.entry.text[1],
        },
        {
            name: intl.formatMessage({
                id: 'ipCanDevices.LPMatrixDisplayV2.systemText.lang2',
            }),
            center: true,
            cell: row => row.entry.text[2],
        },
        {
            name: intl.formatMessage({
                id: 'ipCanDevices.LPMatrixDisplayV2.systemText.lang3',
            }),
            center: true,
            cell: row => row.entry.text[3],
        },
    ];

    const data = props.data.option.entrys.map((entry, index) => {
        return { index, entry };
    });

    return <ElementTable columns={columns} data={data} />;
};
