import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

export default class TCMSensorCalibration {
    protected _state: boolean;
    protected _value: number;

    constructor(calibration_) {
        const { state, value } = calibration_;

        this._state = typeof state === 'boolean' ? state : state === 1;
        this._value = value;
    }

    public get state() {
        return this._state;
    }

    public get value() {
        return this._value;
    }

    public set state(state_: boolean) {
        this._state = state_;
    }

    public set value(value_: number) {
        this._value = value_;
    }

    public get fetchCalibration() {
        return {
            state: this._state,
            value: this._value,
        };
    }

    public updateCalibration(calibration) {
        this._state = calibration.state;
        this._value = calibration.value;
    }

    public setCalibration(sensorId_: number, state_: boolean, value_: number) {
        this._state = state_;
        this._value = value_;

        return axiosService.getAxios().put(
            '/devices/tcm-sensor/updateCalibrationConfig',
            {
                id: sensorId_,
                state: state_,
                value: value_,
            },
            {
                headers: authHeader(),
            }
        );
    }
}
