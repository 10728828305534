import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    Button,
    ButtonToolbar,
    Col,
    Container,
    Content,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Panel,
} from 'rsuite';
import { authActions } from '../../../redux/actions/auth.actions';
import logoLeaderPark from '../../../style/assets/Logo-Leader-Park.png';

type Props = {
    login: Function;
    auth: Record<string, any>;
};

type State = {
    formValue: FormValue;
};

interface FormValue {
    email: string;
    password: string;
}

const mapDispatchToProps = (dispatch: Function) => ({
    login: (email: string, password: string) => dispatch(authActions.login(email, password)),
});

class LoginPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            formValue: {
                email: '',
                password: '',
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(formValue: Record<string, any>) {
        const values = formValue as FormValue;

        this.setState({
            formValue: {
                email: values.email,
                password: values.password,
            },
        });
    }

    handleSubmit() {
        const { email, password } = this.state.formValue;

        if (email && password) {
            this.props.login(email, password);
        }
    }

    render() {
        if (this.props.auth.loggedIn) {
            // return this.props.auth.user.roles.find((role: Record<string, any>) => role.id === 1 || role.id === 20) ? (
            return <Redirect to="/" />;
            // ) : (
            //     <Redirect to="/counters" />
            // );
        } else {
            return (
                <div className="login-page">
                    <Container className="full-height demo-parking">
                        <Content className="full-height">
                            <FlexboxGrid justify="center">
                                <FlexboxGrid.Item componentClass={Col} colspan={20} md={10}>
                                    <Panel className="big-shadow">
                                        <img className="logo" src={logoLeaderPark} alt="Logo" />

                                        <Form fluid onChange={this.handleChange} onSubmit={this.handleSubmit}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    <FormattedMessage id="login.email" />
                                                </ControlLabel>
                                                <FormControl
                                                    placeholder="you@email.com"
                                                    name="email"
                                                    value={this.state.formValue.email}
                                                    disabled={this.props.auth.loggingIn}
                                                    data-cy="login-email"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>
                                                    <FormattedMessage id="login.password" />
                                                </ControlLabel>
                                                <FormControl
                                                    name="password"
                                                    type="password"
                                                    value={this.state.formValue.password}
                                                    placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
                                                    disabled={this.props.auth.loggingIn}
                                                    data-cy="login-password"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ButtonToolbar>
                                                    <Button
                                                        type="submit"
                                                        appearance="primary"
                                                        loading={this.props.auth.loggingIn}
                                                        data-cy="login-button">
                                                        <FormattedMessage id="login.valid" />
                                                    </Button>
                                                </ButtonToolbar>
                                            </FormGroup>
                                        </Form>
                                    </Panel>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Content>
                    </Container>
                </div>
            );
        }
    }
}

function mapStateToProps(state: Record<string, any>) {
    const { auth } = state;

    return { auth };
}

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
export default Login;
