import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, FlexboxGrid, Panel } from 'rsuite';
import Camera, { CameraErrorFlag } from '../../../handlers/Camera/Camera';

type Props = {
    cameras: Camera[];
};

const OK_COLOR = '#008000';
const BAD_CONFIG_COLOR = '#f44336';
const BAD_LOGIN_COLOR = '#ff9800';

export const ErrorFlagGraph = (props: Props) => {
    const intl = useIntl();

    const nbCamerasOk = props.cameras.filter(camera => camera.getErrorFlag() === CameraErrorFlag.OK).length;
    const nbCamerasBadConfig = props.cameras.filter(camera => camera.getErrorFlag() === CameraErrorFlag.BAD_CONFIG)
        .length;
    const nbCamerasBadLogin = props.cameras.filter(camera => camera.getErrorFlag() === CameraErrorFlag.BAD_LOGIN)
        .length;

    const percentageCamerasOk = props.cameras.length === 0 ? 0 : (nbCamerasOk / props.cameras.length) * 100;
    const percentageCamerasBadConfig = props.cameras.length === 0 ? 0 : (nbCamerasBadConfig / props.cameras.length) * 100;
    const percentageCamerasBadLogin = props.cameras.length === 0 ? 0 : (nbCamerasBadLogin / props.cameras.length) * 100;

    // const nbTotalPlacesCamera = nbPlacesByCamera.reduce((a, b) => a + b, 0);

    const dataDoughnut = {
        title: '',
        datasets: [
            {
                data: [nbCamerasOk, nbCamerasBadConfig, nbCamerasBadLogin],
                backgroundColor: [OK_COLOR, BAD_CONFIG_COLOR, BAD_LOGIN_COLOR],
            },
        ],
        labels: [
            intl.formatMessage({ id: 'cameras.errorFlag.ok' }),
            intl.formatMessage({ id: 'cameras.errorFlag.badConfig' }),
            intl.formatMessage({ id: 'cameras.errorFlag.badLogin' }),
        ],
    };

    let donutSize: string | number = '100%';
    let donutColSize = 12;

    donutSize = window.innerHeight * 0.2;

    return (
        <Panel
            data-cy="cameras-errorFlag-status"
            className={'dashboard-card-header hoverable'}
            header={<FormattedMessage id="cameras.errorFlag.title" />}
            shaded>
            <FlexboxGrid align="middle" justify="space-between" style={{ height: donutSize }}>
                <FlexboxGrid.Item componentClass={Col} colspan={12} xs={donutColSize} style={{ height: donutSize }}>
                    <Doughnut
                        data={dataDoughnut}
                        // height={'100%'}
                        // width={donutSize}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            aspectRatio: 1,
                            legend: {
                                display: false,
                            },
                        }}
                    />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} colspan={24} xs={24 - donutColSize}>
                    <div
                        data-cy="cameras-errorFlag-online"
                        className="parking-legend-title margin-top-15"
                        style={{ color: OK_COLOR }}>
                        <FormattedMessage id="cameras.errorFlag.ok" />
                    </div>
                    <div data-cy="ipCan-errorFlag-online-value" className="parking-legend-value">
                        {nbCamerasOk} <FormattedMessage id="cameras-errorFlag-camera" />
                        <span className="margin-left-5 small" style={{ color: OK_COLOR }}>
                            ({percentageCamerasOk.toFixed(2)}%)
                        </span>
                    </div>

                    <div
                        data-cy="cameras-errorFlag-online"
                        className="parking-legend-title margin-top-15"
                        style={{ color: BAD_CONFIG_COLOR }}>
                        <FormattedMessage id="cameras.errorFlag.badConfig" />
                    </div>
                    <div data-cy="ipCan-errorFlag-online-value" className="parking-legend-value">
                        {nbCamerasBadConfig} <FormattedMessage id="cameras-errorFlag-camera" />
                        <span className="margin-left-5 small" style={{ color: BAD_CONFIG_COLOR }}>
                            ({percentageCamerasBadConfig.toFixed(2)}%)
                        </span>
                    </div>

                    <div
                        data-cy="cameras-errorFlag-online"
                        className="parking-legend-title margin-top-15"
                        style={{ color: BAD_LOGIN_COLOR }}>
                        <FormattedMessage id="cameras.errorFlag.badLogin" />
                    </div>
                    <div data-cy="ipCan-errorFlag-online-value" className="parking-legend-value">
                        {nbCamerasBadLogin} <FormattedMessage id="cameras-errorFlag-camera" />
                        <span className="margin-left-5 small" style={{ color: BAD_LOGIN_COLOR }}>
                            ({percentageCamerasBadLogin.toFixed(2)}%)
                        </span>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    );
};
