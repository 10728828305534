import { Layer, Rect, Stage } from 'react-konva';
import { LPVirtualDisplayColors } from '../../../../../handlers/ipCanDevices/const/colors';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { TopologyInterface } from '../../../../../handlers/lpDisplays/LPDisplayTopology';

type Props = {
    display: LPDisplay;
    topology: TopologyInterface;
};

interface CoordInterface {
    color: string;
}

const PreviewTopologyPart = (props: Props) => {
    const MATRIX_WIDTH = 32;
    const MATRIX_HEIGHT = 32;
    const SEPARATOR_WIDTH = 2;
    const SEMI_SEPARATOR_WIDTH = SEPARATOR_WIDTH / 2;

    const stageWidth =
        (props.display.getBaseConfig().getNbMatrixX() + 1) * SEPARATOR_WIDTH +
        props.display.getBaseConfig().getNbMatrixX() * SEMI_SEPARATOR_WIDTH +
        props.display.getBaseConfig().getNbMatrixX() * 2 * MATRIX_WIDTH;

    const stageHeight =
        props.display.getBaseConfig().getNbMatrixY() * MATRIX_HEIGHT +
        (props.display.getBaseConfig().getNbMatrixY() + 1) * SEPARATOR_WIDTH;

    let squareArray: CoordInterface[][] = [];

    for (let i = 0; i < props.display.getBaseConfig().getNbMatrixY(); i++) {
        let displays: CoordInterface[] = [];
        for (let j = 0; j < props.display.getBaseConfig().getNbMatrixX() * 2; j++) {
            displays.push({
                color: 'grey',
            });
        }
        squareArray[i] = displays;
    }

    for (let vd = 0; vd < props.topology.virtualDisplays.length; vd++) {
        const virtualDisplayPosId = props.topology.virtualDisplays[vd];
        const virtualDisplay = props.display.getVirtualDisplays().find(vd => vd.getPosId() + 1 === virtualDisplayPosId);

        if (virtualDisplay) {
            const indexStartY = virtualDisplay.getFormattedStartY();
            const indexStartX = virtualDisplay.getFormattedStartX();
            const sizeY = virtualDisplay.getFormattedSizeY();
            const sizeX = virtualDisplay.getFormattedSizeX();
            for (let i = indexStartY; i < indexStartY + sizeY; i++) {
                for (let j = indexStartX; j < indexStartX + sizeX; j++) {
                    if (
                        i <= props.display.getBaseConfig().getNbMatrixY() &&
                        j <= props.display.getBaseConfig().getNbMatrixX() * 2 &&
                        squareArray[i] &&
                        squareArray[i][j]
                    ) {
                        squareArray[i][j].color = LPVirtualDisplayColors[virtualDisplay.getPosId()];
                    }
                }
            }
        }
    }

    return (
        <Stage width={stageWidth} height={stageHeight} className="margin-bottom-5">
            <Layer>
                <Rect width={stageWidth} height={stageHeight} x={0} y={0} fill="#000" />
            </Layer>
            <Layer>
                {squareArray.map((displays, indexY) => {
                    let previousX = SEPARATOR_WIDTH;
                    return displays.map((disp, indexX) => {
                        let x;
                        if (indexX > 0) {
                            x = previousX + MATRIX_WIDTH;

                            if (indexX % 2 !== 0) {
                                x += SEMI_SEPARATOR_WIDTH;
                            } else {
                                x += SEPARATOR_WIDTH;
                            }

                            previousX = x;
                        } else {
                            x = previousX;
                        }

                        let y = indexY * MATRIX_HEIGHT + (indexY + 1) * SEPARATOR_WIDTH;

                        return (
                            <Rect
                                key={`${indexX}_${indexY}`}
                                width={MATRIX_WIDTH}
                                height={MATRIX_HEIGHT}
                                fill={disp.color}
                                x={x}
                                y={y}
                            />
                        );
                    });
                })}
            </Layer>
        </Stage>
    );
};

export default PreviewTopologyPart;
