import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Circle, Polygon, Popup } from 'react-leaflet';
import { PlaceTextInformationType } from '.';
import PlaceCamera from '../../../handlers/Camera/PlaceCamera';
import { PlaceCameraTooltip } from './Tooltip';

type Props = {
    selectedPlaceId: number | null;
    place: PlaceCamera;
    sensorPlaceTypes: any[];
    currentViewMode: PlaceTextInformationType;
    shouldDisplayPlaceState: boolean;
    shouldDisplayPlacesBoxes: boolean;
    shouldDisplayPlacesPoints: boolean;
    editMode: boolean;
    setSelectedElement: (id: number) => void;
};

export const PlaceCameraView = (props: Props) => {
    const [currentSensorPlaceType, setCurrentSensorPlaceType] = React.useState<any | undefined>(undefined);

    useEffect(() => {
        setCurrentSensorPlaceType(
            props.sensorPlaceTypes.find(spt => spt.id === props.place.getSensorPlaceType()?.getId())
        );
    }, [props.place.getSensorPlaceType(), props.sensorPlaceTypes]);

    const generateColor = () => {
        try {
            if (props.shouldDisplayPlaceState) {
                if (props.place.getIsForce()) {
                    return currentSensorPlaceType?.scenes[props.place.getForceType()].color;
                } else {
                    return currentSensorPlaceType?.scenes[props.place.getState()].color;
                }
            } else {
                return currentSensorPlaceType?.scenes[0].color;
            }
        } catch {
            return 'transparent';
        }
    }

    const handlePolygonClick = () => {
        props.setSelectedElement(props.place.getId());
    }

    if (props.selectedPlaceId === props.place.getId() || props.selectedPlaceId === null) {
        return (
            <>
                {props.shouldDisplayPlacesPoints && (
                    <>
                        <Circle
                            radius={14}
                            color={currentSensorPlaceType?.scenes[0].color}
                            fillColor={generateColor()}
                            opacity={1}
                            weight={4}
                            center={props.place.getPosGeoJSON()}
                            fillOpacity={1}
                            stroke={true}
                            name={`${props.place.getId()}`}
                            key={props.place.getId()}>
                            <PlaceCameraTooltip currentViewMode={props.currentViewMode} place={props.place} />

                            <PlaceCameraPopup place={props.place} sensorPlaceType={currentSensorPlaceType} />
                        </Circle>
                        {props.place.getIsForce() && !props.editMode && (
                            <Circle
                                radius={3}
                                color="#000"
                                fillColor="#000"
                                fillOpacity={1}
                                opacity={1}
                                stroke={true}
                                center={props.place.getPosGeoJSON()}>
                                <PlaceCameraPopup place={props.place} sensorPlaceType={currentSensorPlaceType} />
                            </Circle>
                        )}
                    </>
                )}

                {props.shouldDisplayPlacesBoxes && (
                    <Polygon
                        interactive={true}
                        weight={2}
                        onclick={handlePolygonClick}
                        positions={props.place.getGeoJSON()}
                        key={`${props.place.getId()}-polygon`}
                        name={`${props.place.getId()}-polygon`}
                        color={'#0000FF'}
                        fillOpacity={0}></Polygon>
                )}
            </>
        );
    } else {
        return null;
    }
};

type PlaceCameraPopupProps = {
    sensorPlaceType?: any;
    place: PlaceCamera;
};

const PlaceCameraPopup = (props: PlaceCameraPopupProps) => {
    return (
        <Popup keepInView>
            <div>
                <img
                    alt={`${props.sensorPlaceType?.placeType.label}-logo`}
                    src={`data:image/${props.sensorPlaceType?.placeType.imageType};base64, ${props.sensorPlaceType?.placeType.image}`}
                    height="20"
                />{' '}
                <span className="popup-title margin-left-5">{props.sensorPlaceType?.placeType.label}</span>
            </div>

            <p>
                <FormattedMessage id="camera.place.detectionStateTime" />
                <span className="margin-left-5">{props.place.getDetectionStateTime()}</span>
            </p>

            <p>
                <FormattedMessage id="camera.place.creation" />
                <span className="margin-left-5">{props.place.getCreation()}</span>
            </p>
            <p>
                <FormattedMessage id="camera.place.update" />
                <span className="margin-left-5">{props.place.getUpdate()}</span>
            </p>
        </Popup>
    );
}