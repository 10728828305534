import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'react-leaflet';
import { Button, Tag } from 'rsuite';
import { DrawerInformationInterface } from '../..';
import Camera from '../../../../handlers/Camera/Camera';
import { PlaceCameraState } from '../../../../handlers/Camera/PlaceCamera';
import { CameraSensorMapInformation_I } from '../../../../handlers/map/MapCameraSensor';
import { SensorPlaceType } from '../../../../handlers/sensorPlaceType/SensorPlaceType';
import SecuredFragment from '../../../Auth/SecuredFragment';
import { ForceCameraPlaceModal } from '../../../CameraPlaces/Modal/ForceCameraPlaceModal';
import { PlaceCameraHistoryModal } from '../../../CameraPlaces/Modal/PlaceCameraHistoryModal';
import { DeviceStateInformationLogo } from '../../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformationLogo';
import { MapPlaceCameraDrawer } from './Drawer/MapPlaceCameraDrawer';

type Props = {
    mapPlaceCamera: CameraSensorMapInformation_I;
    editMode: boolean;
    drawerInformation: DrawerInformationInterface;
    camera?: Camera;
    hoverPopup: boolean;
    openDrawer: Function;
    closeDrawer: Function;
    placeTypes: SensorPlaceType[];
    service: any;
};

export const PlaceCameraPopup = (props: Props) => {
    const [forceModalOpen, setForceModalOpen] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<string>('');
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

    let timer: ReturnType<typeof setTimeout> | undefined;

    const generateTime = () => {
        let currentTime = moment(new Date());
        let timeToCompare = moment(props.mapPlaceCamera.placeCamera.getDetectionStateTime(), 'DD-MM-YYYY HH:mm:ss');
        //---
        const calculated = (currentTime.valueOf() - timeToCompare.valueOf()) / 1000;
        const duration = moment.duration(calculated, 'seconds');

        let finalString = '';
        let days = duration.days();
        let hours = duration.hours();
        let minutes = duration.minutes();
        let seconds = duration.seconds();

        if (days > 0) {
            finalString += days < 10 ? '0' + days : days;
            finalString += ' jour(s), ';
        }

        if (hours > 0) {
            finalString += hours < 10 ? '0' + hours : hours;
            finalString += ' heure(s), ';
        }

        if (minutes > 0) {
            finalString += minutes < 10 ? '0' + minutes : minutes;
            finalString += ' minute(s) et ';
        }

        finalString += seconds < 10 ? '0' + seconds : seconds;
        finalString += ' seconde(s)';

        return finalString;
    };

    const startTimer = () => {
        setCurrentTime(generateTime());

        timer = setInterval(() => {
            setCurrentTime(generateTime());
        }, 1000);
    };

    const stopTimer = () => {
        if (timer) {
            clearInterval(timer);
        }
    };

    const generateForceType = () => {
        switch (props.mapPlaceCamera.placeCamera.getForceType()) {
            case PlaceCameraState.FREE:
                return (
                    <Tag color="green">
                        <FormattedMessage id="sensor.force.free" />
                    </Tag>
                );
            case PlaceCameraState.OCCUPIED:
                return (
                    <Tag color="red">
                        <FormattedMessage id="sensor.force.occupied" />
                    </Tag>
                );
            case PlaceCameraState.OVERSTAY:
                return (
                    <Tag color="orange">
                        <FormattedMessage id="sensor.force.overstayOccupied" />
                    </Tag>
                );
            case PlaceCameraState.UNKNOWN:
                return (
                    <Tag color="violet">
                        <FormattedMessage id="sensor.force.unknown" />
                    </Tag>
                );
            default:
                return null;
        }
    };

    const generateDetectionStateStatus = () => {
        switch (props.mapPlaceCamera.placeCamera.getState()) {
            case 0:
                return (
                    <Tag color="green">
                        <FormattedMessage id="camera.placeCamera.detectionState.free" />
                    </Tag>
                );
            case 1:
                return (
                    <Tag color="red">
                        <FormattedMessage id="camera.placeCamera.detectionState.occupied" />
                    </Tag>
                );
            case 2:
                return (
                    <Tag color="orange">
                        <FormattedMessage id="camera.placeCamera.detectionState.overstay_occupied" />
                    </Tag>
                );
            case 3:
                return (
                    <Tag color="violet">
                        <FormattedMessage id="camera.placeCamera.detectionState.unknown" />
                    </Tag>
                );
            default:
                return null;
        }
    };

    const shouldShowDrawer =
        props.editMode &&
        props.drawerInformation.isOpen &&
        props.drawerInformation.type === 'place-camera' &&
        props.drawerInformation.id === props.mapPlaceCamera.id;

    return (
        <>
            {shouldShowDrawer && props.camera && (
                <MapPlaceCameraDrawer
                    camera={props.camera}
                    mapPlaceCamera={props.mapPlaceCamera}
                    open={props.drawerInformation.isOpen}
                    onHide={() => props.closeDrawer()}
                    placeTypes={props.placeTypes}
                    service={props.service}
                />
            )}

            <Popup key={`Camera-Sensor-${props.mapPlaceCamera.idCamera}-${props.hoverPopup}`} autoPan={!props.hoverPopup} onOpen={startTimer} onClose={stopTimer}>
                <h5>
                    <SecuredFragment authorizedRoles={[]}>
                        <FontAwesomeIcon
                            icon={faPlug}
                            color={props.camera?.getOnline() ? 'green' : 'red'}
                            className="margin-right-5"
                        />
                    </SecuredFragment>
                    <span className="margin-right-5">{props.mapPlaceCamera.placeCamera.getLabel()}</span>
                    <SecuredFragment authorizedRoles={[]}>
                        <DeviceStateInformationLogo
                            maintenanceState={props.mapPlaceCamera.placeCamera.getMaintenanceState()}
                        />
                    </SecuredFragment>
                </h5>
                <p>
                    {props.camera?.getName()} ({props.camera?.getIp()} - {props.camera?.getMacStr()})
                </p>
                <p>
                    <FormattedMessage id="map.placeCamera.placeStatus" />
                    {generateDetectionStateStatus()}
                </p>

                {props.mapPlaceCamera.placeCamera.getState() === 1 && (
                    <Fragment>
                        <p>
                            <FormattedMessage id="map.placeCamera.detectionDate" />{' '}
                            {props.mapPlaceCamera.placeCamera.getDetectionStateTime()}
                        </p>
                        <p>
                            <FormattedMessage id="map.placeCamera.occupiedSince" /> {currentTime}
                        </p>
                    </Fragment>
                )}

                <SecuredFragment authorizedRoles={[]}>
                    <p>
                        <FormattedMessage id="map.camera.lastOnlineStateDate" /> :{' '}
                        {props.camera?.getLastOnlineStateDate()}
                    </p>
                </SecuredFragment>

                {props.mapPlaceCamera.placeCamera.getIsForce() && (
                    <Fragment>
                        <p>
                            Forçage : {generateForceType()}
                            <br />
                            <FormattedMessage id="map.displays.popup.startForceDate" />
                            <span className="margin-left-5">
                                {props.mapPlaceCamera.placeCamera.getStartForceTime()}
                            </span>
                            <br />
                            <FormattedMessage id="map.displays.popup.endForceDate" />
                            <span className="margin-left-5">{props.mapPlaceCamera.placeCamera.getEndForceTime()}</span>
                        </p>
                    </Fragment>
                )}

                {!props.hoverPopup && (
                    <Fragment>
                        <SecuredFragment authorizedRoles={[]}>
                            <Button size="sm" block color="green" onClick={() => setHistoryModalOpen(true)}>
                                <FormattedMessage id="map.placeCamera.showHistory" />
                            </Button>

                            <PlaceCameraHistoryModal
                                show={historyModalOpen}
                                onHide={() => setHistoryModalOpen(false)}
                                placeCamera={props.mapPlaceCamera.placeCamera}
                            />
                        </SecuredFragment>

                        <SecuredFragment authorizedRoles={[]}>
                            <Button size="sm" block color="blue" onClick={() => setForceModalOpen(true)}>
                                <FormattedMessage id="map.placeCamera.forceSensor" />
                            </Button>

                            <ForceCameraPlaceModal
                                places={[props.mapPlaceCamera.placeCamera]}
                                show={forceModalOpen}
                                onClose={() => setForceModalOpen(false)}
                            />

                            <ForceCameraPlaceModal
                                show={forceModalOpen}
                                onClose={() => setForceModalOpen(false)}
                                places={[props.mapPlaceCamera.placeCamera]}
                            />
                        </SecuredFragment>
                        {props.editMode && (
                            <SecuredFragment authorizedRoles={[]}>
                                <Button
                                    block
                                    size="sm"
                                    color="orange"
                                    onClick={() => props.openDrawer('place-camera', props.mapPlaceCamera.id)}>
                                    <FormattedMessage id="map.placeCamera.edit" />
                                </Button>
                            </SecuredFragment>
                        )}
                    </Fragment>
                )}
            </Popup>
        </>
    );
};
