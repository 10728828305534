import { faCheck, faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord } from 'react-data-table-component';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid } from 'rsuite';

type Props = RowRecord;

type State = {
    primaryColor: boolean;
    free: '"currentcolor"';
    occupied: '"currentcolor"';
    overstayFree: '"currentcolor"';
    overstay: '"currentcolor"';
    forced: '"currentcolor"';
};
export default class ReadRealSensorExpand extends React.Component<Props, State> {
    timer: ReturnType<typeof setTimeout> | undefined;
    constructor(props) {
        super(props);

        this.state = {
            primaryColor: true,
            free: props.data.option.color.free.color,
            occupied: props.data.option.color.occupied.color,
            overstayFree: props.data.option.color.overstayFree.color,
            overstay: props.data.option.color.overstay.color,
            forced: props.data.option.color.forced.color,
        };

        this.changeColor = this.changeColor.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(this.changeColor, 1000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    changeColor() {
        if (this.state.primaryColor) {
            this.setState({
                primaryColor: false,
                free: this.props.data.option.color.free.secondColor,
                occupied: this.props.data.option.color.occupied.secondColor,
                overstayFree: this.props.data.option.color.overstayFree.secondColor,
                overstay: this.props.data.option.color.overstay.secondColor,
                forced: this.props.data.option.color.forced.secondColor,
            });
        } else {
            this.setState({
                primaryColor: true,
                free: this.props.data.option.color.free.color,
                occupied: this.props.data.option.color.occupied.color,
                overstayFree: this.props.data.option.color.overstayFree.color,
                overstay: this.props.data.option.color.overstay.color,
                forced: this.props.data.option.color.forced.color,
            });
        }
    }

    render() {
        return (
            <Fragment>
                <FlexboxGrid style={{ margin: 15 }} justify="space-around">
                    {/* Config */}
                    <FlexboxGrid.Item>
                        <h5 data-cy="expandSensor-config">
                            <FormattedMessage id="sensor.history.readRealSensor.config" />
                        </h5>

                        <div data-cy="expandSensor-config-typePlace">
                            <FormattedMessage id="sensor.history.readRealSensor.config.typePlace" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.config.typePlace}</span>
                        </div>

                        <div data-cy="expandSensor-config-id">
                            <FormattedMessage id="sensor.history.readRealSensor.config.id" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.config.id}</span>
                        </div>

                        <div data-cy="expandSensor-config-numPlace">
                            <FormattedMessage id="sensor.history.readRealSensor.config.numPlace" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.config.numPlace}</span>
                        </div>

                        <div data-cy="expandSensor-config-numPulsation">
                            <FormattedMessage id="sensor.history.readRealSensor.config.numPulsation" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.config.numPulsation}</span>
                        </div>

                        <div data-cy="expandSensor-config-baudrate">
                            <FormattedMessage id="sensor.history.readRealSensor.config.baudrate" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.config.baudrate}</span>
                        </div>

                        <div data-cy="expandSensor-config-parity">
                            <FormattedMessage id="sensor.history.readRealSensor.config.parity" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.config.parity}</span>
                        </div>
                    </FlexboxGrid.Item>

                    {/* Color */}
                    <FlexboxGrid.Item>
                        <h5 data-cy="expandSensor-colors">
                            <FormattedMessage id="sensor.history.readRealSensor.colors" />
                        </h5>

                        <div data-cy="expandSensor-colors-free">
                            <FormattedMessage id="sensor.history.updateColor.color.free" /> :{' '}
                            <FontAwesomeIcon
                                icon={faCircle}
                                style={{
                                    color: this.state.free,
                                }}
                            />
                        </div>

                        <div data-cy="expandSensor-colors-occupied">
                            <FormattedMessage id="sensor.history.updateColor.color.occupied" /> :{' '}
                            <FontAwesomeIcon
                                icon={faCircle}
                                style={{
                                    color: this.state.occupied,
                                }}
                            />
                        </div>

                        <div data-cy="expandSensor-colors-overstayFree">
                            <FormattedMessage id="sensor.history.updateColor.color.overstayFree" /> :{' '}
                            <FontAwesomeIcon
                                icon={faCircle}
                                style={{
                                    color: this.state.overstayFree,
                                }}
                            />
                        </div>

                        <div data-cy="expandSensor-colors-overstay">
                            <FormattedMessage id="sensor.history.updateColor.color.overstay" /> :{' '}
                            <FontAwesomeIcon
                                icon={faCircle}
                                style={{
                                    color: this.state.overstay,
                                }}
                            />
                        </div>

                        <div data-cy="expandSensor-colors-forced">
                            <FormattedMessage id="sensor.history.updateColor.color.forced" /> :{' '}
                            <FontAwesomeIcon
                                icon={faCircle}
                                style={{
                                    color: this.state.forced,
                                }}
                            />
                        </div>
                    </FlexboxGrid.Item>

                    {/* Time */}
                    <FlexboxGrid.Item>
                        <h5 data-cy="expandSensor-time">
                            <FormattedMessage id="sensor.history.readRealSensor.time" />
                        </h5>

                        <div data-cy="expandSensor-time-maxFree">
                            <FormattedMessage id="tcmSensor.history.time.maxFree" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.time.maxFree}</span>
                        </div>

                        <div data-cy="expandSensor-time-maxOccupied">
                            <FormattedMessage id="tcmSensor.history.time.maxOccupied" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.time.maxOccupied}</span>
                        </div>

                        <div data-cy="expandSensor-time-provOccupied">
                            <FormattedMessage id="tcmSensor.history.time.provOccupied" />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.time.provOccupied}</span>
                        </div>
                    </FlexboxGrid.Item>

                    {/* Calibration */}
                    <FlexboxGrid.Item>
                        <h5 data-cy="expandSensor-calibration">
                            <FormattedMessage id="sensor.history.readRealSensor.calibration" />
                        </h5>

                        <div data-cy="expandSensor-calibration-value">
                            <FontAwesomeIcon
                                icon={this.props.data.option.calibration.state ? faCheck : faTimes}
                                color={this.props.data.option.calibration.state ? 'green' : 'red'}
                            />
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.calibration.value}</span>{' '}
                        </div>
                    </FlexboxGrid.Item>

                    {/* Other */}
                    <FlexboxGrid.Item>
                        <h5 data-cy="expandSensor-other">
                            <FormattedMessage id="sensor.history.readRealSensor.other" />
                        </h5>

                        <div data-cy="expandSensor-time-other-versionSoft">
                            <span className="margin-left-5">
                                <FormattedMessage id="sensor.history.readRealSensor.other.versionSoft" />
                            </span>{' '}
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">{this.props.data.option.versionSoft}</span>
                        </div>

                        <div data-cy="expandSensor-time-other-detectionState">
                            <span className="margin-left-5">
                                <FormattedMessage id="sensor.history.readRealSensor.other.detectionState" />
                            </span>{' '}
                            <span className="margin-left-5">-</span>
                            <span className="margin-left-5">
                                {renderDetectionState(this.props.data.option.versionSoft)}
                            </span>
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Fragment>
        );
    }
}

function renderDetectionState(detectionState) {
    switch (detectionState) {
        case 1: {
            return <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }} />;
        }
        case 2: {
            return <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} />;
        }
        case 3: {
            return <FontAwesomeIcon icon={faCircle} style={{ color: 'orange' }} />;
        }
        case 4: {
            return <FontAwesomeIcon icon={faCircle} style={{ color: 'purple' }} />;
        }
        default: {
            return <FontAwesomeIcon icon={faCircle} style={{ color: 'black' }} />;
        }
    }
}
