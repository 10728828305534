import moment from 'moment';

export function webSocketTcmSensorsHandler(tcmSensor) {
    return {
        bus: tcmSensor.bus,
        calibration: {
            state: tcmSensor.calibration.state,
            value: tcmSensor.calibration.value,
        },
        detectionState: tcmSensor.detectionState,
        detectionStateTime: moment.utc(tcmSensor.detectionStateTime).local().format('DD/MM/YYYY HH:mm:ss'),
        deviceId: tcmSensor.deviceId,
        id: tcmSensor.id,
        ipCanId: tcmSensor.ipcanmoduleId,
        online: tcmSensor.online,
        isForce: tcmSensor.isForce,
        forceType: tcmSensor.forceType,
        endForceTime: moment(tcmSensor.endForceTime).local().format('DD/MM/YYYY HH:mm'),
        lastOnlineStateDate: moment(tcmSensor.lastOnlineStateDate).isValid()
            ? moment(tcmSensor.lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss')
            : null,
    };
}
