import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Message, Modal } from 'rsuite';
import { LPSensor } from '../../../handlers/ipCanDevices/LPSensor';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    sensors: LPSensor[];
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isLoading: boolean;
    hasError: boolean;
    error: any | null;
};

class DeleteLPSensorModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            hasError: false,
            error: null,
        };
    }

    sendDelete = () => {
        this.setState({
            isLoading: true,
            hasError: false,
            error: null,
        });

        axiosService
            .getAxios()
            .delete('/devices/lp-sensor', {
                headers: authHeader(),
                data: {
                    tabId: this.props.sensors.map(sensor => sensor.id),
                },
            })
            .then(() => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'lpSensors.delete.success',
                    })
                );

                this.props.onHide(true);
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    closeModal = () => {
        !this.state.isLoading && this.props.onHide();
    };

    render = () => {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="lpSensors.delete.title" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.state.hasError && (
                        <Message
                            type="error"
                            description={this.props.intl.formatMessage(
                                {
                                    id: 'lpSensors.delete.error',
                                },
                                {
                                    error: this.state.error,
                                }
                            )}
                        />
                    )}
                    <FormattedMessage id="lpSensors.delete.message" />
                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            data-cy="ipCanDevices-lpSensor-delete-cancel"
                            onClick={() => this.closeModal()}
                            color="red"
                            disabled={this.state.isLoading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            data-cy="ipCanDevices-lpSensor-delete-valid"
                            onClick={() => this.sendDelete()}
                            appearance="primary"
                            loading={this.state.isLoading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default injectIntl(DeleteLPSensorModal);
