import { IntlShape } from 'react-intl';

type FormValuesInput = {
    label: string;
    value: number;
};

export const LPDisplayNbPixelX = (intl: IntlShape): FormValuesInput[] => {
    let nbPixelXFormValues: FormValuesInput[] = [];
    for (let i = 1; i <= 16; i++) {
        nbPixelXFormValues.push({
            label: `${i} ${intl.formatMessage({
                id: 'ipCanDevices.lpDisplay.baseConfiguration.nbMatrix',
            })} (${i * 32}px)`,
            value: i * 32,
        });
    }

    return nbPixelXFormValues;
};

export const LPDisplayNbPixelY = (intl: IntlShape): FormValuesInput[] => {
    let nbPixelXFormValues: FormValuesInput[] = [];
    for (let i = 1; i <= 16; i++) {
        nbPixelXFormValues.push({
            label: `${i} ${intl.formatMessage({
                id: 'ipCanDevices.lpDisplay.baseConfiguration.nbMatrix',
            })} (${i * 16}px)`,
            value: i * 16,
        });
    }

    return nbPixelXFormValues;
};

export const LPDisplayTypeSelector = (): FormValuesInput[] => {
    let typeFormValue: FormValuesInput[] = [];

    for (let i = 1; i <= 8; i++) {
        typeFormValue.push({
            label: i.toString(),
            value: i,
        });
    }

    return typeFormValue;
};

export const LPDisplayOriginSelector = (intl: IntlShape): FormValuesInput[] => {
    return [
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.origin.topLeft' }),
            value: 1,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.origin.topRight' }),
            value: 2,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.origin.bottomLeft' }),
            value: 3,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.origin.bottomRight' }),
            value: 4,
        },
    ];
};

export const LPDisplayGMTSelector = (): FormValuesInput[] => {
    let typeFormValue: FormValuesInput[] = [];

    for (let i = -11; i <= 12; i++) {
        typeFormValue.push({
            label: i >= 0 ? `GMT +${i.toString()}` : `GMT ${i.toString()}`,
            value: i,
        });
    }

    return typeFormValue;
};
