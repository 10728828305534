import { faExchangeAlt, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Drawer, FlexboxGrid, Tooltip, Whisper } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import ControllerHistoryModal from '../Modal/ControllerHistoryModal';
import DeleteIpCanModal from '../Modal/DeleteIpCanModal';
import InvertControllersModal from '../Modal/InvertControllersModal';
import Actions from './Actions';
import Bus from './Bus';
import Configuration from './Configuration';
import Informations from './Informations';
import PhysicalInformations from './PhysicalInformations';

type Props = {
    ipcan: IpCan;
    show: boolean;
    onHide: Function;
    loadIpCan: () => void;
    fetchControllers?: () => void;
    openDeleteModal?: Function;
    isLoading: boolean;
};

type State = {
    showInvertControllers: boolean;
    showIpCanModuleHistoryModal: boolean;
    fetchedIpcan: Record<string, any>;
    busInformations: Array<Record<string, any>>;
    fetching: boolean;
    hasError: boolean;
    busConfig: Array<Record<string, any>>;
    ipcanModule: Record<string, any>;
    physicalInformation: Record<string, any>;
    ipCanInformations: Record<string, any>;
    showInvertModal: boolean;
    showDeleteIpCanModal: boolean;
};

export default class IpCanDrawer extends Component<Props, State> {
    ipcanModule: Record<string, any> | undefined;
    constructor(props) {
        super(props);

        this.state = {
            showInvertControllers: false,
            showIpCanModuleHistoryModal: false,
            fetchedIpcan: {},
            busInformations: [],
            fetching: true,
            hasError: false,
            busConfig: [],
            ipcanModule: {},
            physicalInformation: {},
            ipCanInformations: {},
            showInvertModal: false,
            showDeleteIpCanModal: false,
        };

        this.showInvertControllersModal = this.showInvertControllersModal.bind(this);
        this.hideInvertControllersModal = this.hideInvertControllersModal.bind(this);

        this.showIpCanModuleHistoryModal = this.showIpCanModuleHistoryModal.bind(this);
        this.hideIpCanModuleHistoryModal = this.hideIpCanModuleHistoryModal.bind(this);

        this.updateInformations = this.updateInformations.bind(this);
        this.updateConfigs = this.updateConfigs.bind(this);

        this.showDeleteIpCanModal = this.showDeleteIpCanModal.bind(this);
        this.hideDeleteIpCanModal = this.hideDeleteIpCanModal.bind(this);

        // this.fetchIpCanModule = this.fetchIpCanModule.bind(this);
    }

    showInvertControllersModal() {
        this.setState({
            showInvertModal: true,
        });
    }

    hideInvertControllersModal(fetchControllers) {
        this.setState({
            showInvertModal: false,
        });

        if (fetchControllers && this.props.fetchControllers) {
            this.props.fetchControllers();
        }
    }

    showIpCanModuleHistoryModal() {
        this.setState({
            showIpCanModuleHistoryModal: true,
        });
    }

    hideIpCanModuleHistoryModal() {
        this.setState({
            showIpCanModuleHistoryModal: false,
        });
    }

    showDeleteIpCanModal() {
        this.setState({
            showDeleteIpCanModal: true,
        });
    }

    hideDeleteIpCanModal(deleted) {
        this.setState({
            showDeleteIpCanModal: false,
        });

        if (deleted) {
            this.props.onHide();
            this.props.loadIpCan();
        }
    }

    updateInformations(label, ip, macStr) {
        this.ipcanModule?.updateInformations(label, ip, macStr).then(() => {
            this.setState({
                fetchedIpcan: this.ipcanModule?.getTableData(),
                busInformations: this.ipcanModule?.busInfo,
                physicalInformation: this.ipcanModule?.getPhysicalInformation(),
                ipCanInformations: this.ipcanModule?.getInformations(),
            });

            this.props.loadIpCan();
        });
    }

    updateConfigs(configs) {
        this.ipcanModule?.updateBusConfigs(configs);
    }

    render() {
        return (
            <Fragment>
                <InvertControllersModal
                    ipcan={this.props.ipcan.getTableData()}
                    onHide={this.hideInvertControllersModal}
                    show={this.state.showInvertModal}
                    controller={0}
                />

                {this.state.showIpCanModuleHistoryModal && (
                    <ControllerHistoryModal
                        show={this.state.showIpCanModuleHistoryModal}
                        onHide={this.hideIpCanModuleHistoryModal}
                        controller={this.props.ipcan.getTableData()}
                    />
                )}

                <DeleteIpCanModal
                    closeDeleteModal={this.hideDeleteIpCanModal}
                    fetchedIpcan={this.props.ipcan.getTableData()}
                    deleteModalOpen={this.state.showDeleteIpCanModal}
                />

                <Drawer backdrop={true} show={this.props.show} onHide={() => this.props.onHide()} size="md">
                    <Drawer.Header data-cy="IPCanDrawer">
                        <Drawer.Title data-cy="IPCanDrawer-title">
                            {this.state.hasError && <FormattedMessage id="global.error" />}
                            <span>{this.props.ipcan.getTableData().label}</span>
                        </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body data-cy="ipCan-drawer" className="drawer-body-without-avatar">
                        <Fragment>
                            <Bus ipcanModule={this.props.ipcan} />
                            <Actions ipcanModule={this.props.ipcan} />
                            <PhysicalInformations ipcanModule={this.props.ipcan} />
                            <Informations
                                ipcanModule={this.props.ipcan}
                                reloadIpCanModules={this.props.loadIpCan}
                                isLoading={this.props.isLoading}
                            />
                            <Configuration ipcanModule={this.props.ipcan} />
                        </Fragment>
                    </Drawer.Body>
                    <Drawer.Footer>
                        <FlexboxGrid align="middle" justify="end">
                            <SecuredFragment authorizedRoles={[rolesConstants.historics.VIEW_IP_CAN_MODULE]}>
                                <FlexboxGrid.Item componentClass={Col} md={3} xs={4}>
                                    <Whisper
                                        placement="top"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="controller.action.showHistory" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            color="blue"
                                            data-cy="ipCan-see-historyModal"
                                            block
                                            onClick={this.showIpCanModuleHistoryModal}>
                                            <FontAwesomeIcon icon={faHistory} size="lg" />
                                        </Button>
                                    </Whisper>
                                </FlexboxGrid.Item>
                            </SecuredFragment>

                            <SecuredFragment authorizedRoles={[rolesConstants.controller.REPLACE_MODULE]}>
                                <FlexboxGrid.Item componentClass={Col} md={4} xs={6}>
                                    <Whisper
                                        placement="top"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="controller.action.invertControllers" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            data-cy="ipCan-invert-controllers"
                                            color="orange"
                                            block
                                            onClick={this.showInvertControllersModal}>
                                            <FontAwesomeIcon icon={faExchangeAlt} size="lg" />
                                        </Button>
                                    </Whisper>
                                </FlexboxGrid.Item>
                            </SecuredFragment>

                            <SecuredFragment authorizedRoles={[rolesConstants.controller.DELETE]}>
                                <FlexboxGrid.Item componentClass={Col} md={8} xs={12}>
                                    <Whisper
                                        placement="top"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="ipCan.delete" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            onClick={() => this.showDeleteIpCanModal()}
                                            color="red"
                                            data-cy="IPCan-delete-button"
                                            disabled={this.props.ipcan.getOnline() !== 0}>
                                            <FormattedMessage id="controller.action.deleteController" />
                                        </Button>
                                    </Whisper>
                                </FlexboxGrid.Item>
                            </SecuredFragment>
                        </FlexboxGrid>
                    </Drawer.Footer>
                </Drawer>
            </Fragment>
        );
    }
}
