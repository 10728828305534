export interface LPSensorUsConfig_I {
    height: number;
    sens: number;
}

export function LPSensorUsSensValues(sens) {
    switch (sens) {
        case 255:
            return 'Min';
        case 240:
            return 'Low';
        case 150:
            return 'Medium';
        case 70:
            return 'High';
        case 0:
            return 'Max';
        default:
            return 'ERROR';
    }
}

export function LPSensorUsHeightValues(height) {
    switch (height) {
        case 240:
            return '175 cm';
        case 180:
            return '200 cm';
        case 130:
            return '225 cm';
        case 110:
            return '250 cm';
        case 90:
            return '275 cm';
        case 70:
            return '300 cm';
        case 60:
            return '325 cm';
        case 50:
            return '350 cm';
        case 30:
            return '375 cm';
        case 10:
            return '400 cm';
        default:
            return 'DISABLED';
    }
}

export class LPSensorUsConfig {
    private _height: number;
    private _sens: number;
    // private _debugSens: number;

    constructor({ height, sens }: LPSensorUsConfig_I) {
        this._height = height;
        this._sens = sens;
        // this._debugSens = sens;
    }

    public get height() {
        return this._height;
    }

    public get sens() {
        return this._sens;
    }

    // public get debugSens() {
    //     return this._debugSens;
    // }

    public setHeight(height_) {
        this._height = height_;
    }

    public setSens(sens_) {
        this._sens = sens_;
    }

    // public setDebugSens(debugSens_) {
    //     console.log('DEBUGSENS', debugSens_);
    //     this._debugSens = debugSens_;
    // }

    public setSensorUsConfig(height_, sens_) {
        this._height = height_;
        this._sens = sens_;
    }

    public compare(comparedUsConfig: LPSensorUsConfig): boolean {
        let response: boolean = true;

        if (this._height !== comparedUsConfig.height) response = false;
        if (this._sens !== comparedUsConfig.sens) response = false;

        return response;
    }
}
