import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Avatar, Button, Drawer } from 'rsuite';
import { placeTypeActions } from '../../../../redux/actions';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import DeletePlaceTypeModal from '../../Modal/PlaceType/DeletePlaceTypeModal';
import PlaceTypeInformations from './PlaceTypeInformations';

const mapDispatchToProps = dispatch => ({
    closeViewDrawer: () => dispatch(placeTypeActions.closeViewDrawer()),
    openDeleteModal: id => dispatch(placeTypeActions.openDeleteModal(id)),
});

type Props = {
    placeTypeDrawer: Record<string, any>;
    view_drawer_openned: boolean;
    openDeleteModal: Function;
    closeViewDrawer: Function;
} & WrappedComponentProps;

class PlaceTypeDrawer extends React.Component<Props> {
    render() {
        if (this.props.placeTypeDrawer) {
            return (
                <Fragment>
                    <Drawer
                        backdrop={true}
                        show={this.props.view_drawer_openned}
                        onHide={() => this.props.closeViewDrawer()}>
                        <Drawer.Header data-cy="placeTypeDrawer">
                            <Drawer.Title className="with-avatar" data-cy="placeTypeDrawer-title">
                                <Avatar
                                    src={`data:image/${this.props.placeTypeDrawer.imageType};base64, ${this.props.placeTypeDrawer.image}`}
                                    alt={this.props.placeTypeDrawer.label.charAt(0).toUpperCase()}
                                />{' '}
                                {this.props.placeTypeDrawer.label}
                            </Drawer.Title>
                        </Drawer.Header>
                        <Drawer.Body className="drawer-body-with-avatar">
                            <PlaceTypeInformations placeType={this.props.placeTypeDrawer} />
                        </Drawer.Body>
                        <Drawer.Footer>
                            <Button
                                onClick={() => {
                                    this.props.openDeleteModal(this.props.placeTypeDrawer.id);
                                    this.props.closeViewDrawer();
                                }}
                                data-cy="delete-placeType-button"
                                color="red">
                                <FormattedMessage id="placeType.deleteButton" />
                            </Button>
                        </Drawer.Footer>
                    </Drawer>

                    <SecuredFragment authorizedRoles={[rolesConstants.placeType.DELETE]}>
                        <DeletePlaceTypeModal />
                    </SecuredFragment>
                </Fragment>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    let { placeTypeDrawer, view_drawer_openned } = state.placeTypes;

    return { view_drawer_openned, placeTypeDrawer };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlaceTypeDrawer));
