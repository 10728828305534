import { FlowElement } from 'react-flow-renderer';
import { LPDisplay } from '../../../../../../../handlers/lpDisplays/LPDisplay';
import { TopologyInterface } from '../../../../../../../handlers/lpDisplays/LPDisplayTopology';
import { virtualDisplayYPosition } from '../../../../../../IpCanElementsPage/LPMatrixDisplayV2Config/handlers/Nodes/FlowNodeExporter';
import { LPMatrixDisplayFlowElement } from '../LPMatrixDisplayChartElement';

export default class LPMatrixDisplayChartTopologyNode extends LPMatrixDisplayFlowElement {
    private _index: number;
    private _id: string;
    private _display: LPDisplay;
    private _topolgy: TopologyInterface;
    private _isForce: boolean;
    private _label?: string;

    constructor(
        index: number,
        id: string,
        display: LPDisplay,
        topology: TopologyInterface,
        isForce: boolean,
        label?: string
    ) {
        super(id, label);
        this._index = index;
        this._id = id;
        this._display = display;
        this._topolgy = topology;
        this._isForce = isForce;
        this._label = label;
    }

    public render(): FlowElement<LPMatrixDisplayChartTopologyNodeType> {
        const position = virtualDisplayYPosition();

        return {
            id: `${this._id}-force`,
            type: 'forceTopologyNode',
            data: {
                index: this._index,
                label: this._label || '',
                display: this._display,
                topology: this._topolgy,
                isForce: this._isForce,
            },
            position: {
                x: position.initialPositionX,
                y:
                    position.initialPositionY +
                    (position.baseTopologyHeight +
                        position.lineHeight * this._display.getBaseConfig().getNbMatrixY() +
                        position.gapY) *
                        this._index,
            },
        };
    }
}

export type LPMatrixDisplayChartTopologyNodeType = {
    index: number;
    label: string;
    display: LPDisplay;
    topology: TopologyInterface;
    isForce: boolean;
};
