import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Input, Modal } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

interface Props {
    ipcanModule: IpCan;
    isOpen: boolean;
    onClose: () => void;
}

interface FormValues {
    gpio1Name: string;
    gpio2Name: string;
}

export const EditGpioOutModal = (props: Props) => {
    const intl = useIntl();

    const [isEditing, setIsEditing] = useState(false);

    const [formValues, setFormValues] = useState<FormValues>({
        gpio1Name: props.ipcanModule.gpioConfig.gpioOut[0].name,
        gpio2Name: props.ipcanModule.gpioConfig.gpioOut[1].name,
    });

    const handleFormChange = (formValues: any) => {
        setFormValues(formValues);
    }

    const handleSave = () => {
        setIsEditing(true);

        axiosService.getAxios().put('/ipcanmodules/updateGpioConfiguration', {
            id: props.ipcanModule.id,
            config: {
                gpioOut: [
                    { name: formValues.gpio1Name },
                    { name: formValues.gpio2Name },
                ],
                gpioIn: props.ipcanModule.gpioConfig.gpioIn,
            }
        }, { headers: authHeader() }).then((updatedIpCanResponse) => {
            props.ipcanModule.setGpioConfig(
                updatedIpCanResponse.data.gpioConfig,
            );

            props.onClose();
        }).catch((err) => {
            Alert.error(intl.formatMessage({ id: 'ipcanModule.gpio.edit.error' }));

            console.error(err);
        }).finally(() => {
            setIsEditing(false);
        })
    }

    const handleCancel = () => {
        props.onClose();
    }

    return (
        <Modal
            show={props.isOpen}
            onClose={props.onClose}
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="ipcanModule.gpio.edit.outNames" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form fluid formValue={formValues} onChange={handleFormChange}>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="ipcanModule.gpio.entry1.name" />
                        </ControlLabel>
                        <FormControl name="gpio1Name" accepter={Input} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="ipcanModule.gpio.entry2.name" />
                        </ControlLabel>
                        <FormControl name="gpio2Name" accepter={Input} />
                    </FormGroup>
                </Form>
            </Modal.Body >
            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={handleCancel}
                        color="red"
                        disabled={isEditing}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={handleSave}
                        appearance="primary"
                        loading={isEditing}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal >
    );
}