import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MaintenanceState } from '../../../handlers/MaintenanceInformation';

export const maintenanceStateInformation = (state: number, notepad: string) => {
    return (
        <div data-tag="allowRowEvents" className="text-center">
            {state === MaintenanceState.EVERYTHING_OK && (
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="green"
                    size="lg"
                    data-tag="allowRowEvents"
                    className="margin-top-5"
                />
            )}
            {state === MaintenanceState.LOW && (
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="#FFDA00"
                    size="lg"
                    data-tag="allowRowEvents"
                    className="margin-top-5"
                />
            )}
            {state === MaintenanceState.MEDIUM && (
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="orange"
                    size="lg"
                    data-tag="allowRowEvents"
                    className="margin-top-5"
                />
            )}
            {state === MaintenanceState.HIGH && (
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="#FF5349"
                    size="lg"
                    data-tag="allowRowEvents"
                    className="margin-top-5"
                />
            )}
            {state === MaintenanceState.VERY_HIGH && (
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    color="red"
                    size="lg"
                    data-tag="allowRowEvents"
                    className="margin-top-5"
                />
            )}
            <p className="margin-top-5 margin-bottom-5" style={{ whiteSpace: 'break-spaces' }}>
                {notepad}
            </p>
        </div>
    );
};
