export const parkingConstants = {
    GET_ALL_REQUEST: 'PARKINGS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PARKINGS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PARKINGS_GET_ALL_FAILURE',

    GET_ONE_REQUEST: 'PARKINGS_GET_ONE_REQUEST',
    GET_ONE_SUCCESS: 'PARKINGS_GET_ONE_SUCCESS',
    GET_ONE_ERROR: 'PARKINGS_GET_ONE_ERROR',

    DELETE_REQUEST: 'PARKINGS_DELETE_REQUEST',
    DELETE_SUCCESS: 'PARKINGS_DELETE_SUCCESS',
    DELETE_FAILURE: 'PARKINGS_DELETE_FAILURE',

    CREATE_REQUEST: 'PARKINGS_CREATE_REQUEST',
    CREATE_SUCCESS: 'PARKINGS_CREATE_SUCCESS',
    CREATE_FAILURE: 'PARKINGS_CREATE_FAILURE',

    UPDATE_REQUEST: 'PARKINGS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PARKINGS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PARKINGS_UPDATE_FAILURE',

    OPEN_VIEW_DRAWER_REQUEST: 'PARKINGS_OPEN_VIEW_DRAWER_REQUEST',
    OPEN_VIEW_DRAWER_SUCCESS: 'PARKINGS_OPEN_VIEW_DRAWER_SUCCESS',
    OPEN_VIEW_DRAWER_FAILURE: 'PARKINGS_OPEN_VIEW_DRAWER_FAILURE',

    CLOSE_VIEW_DRAWER: 'PARKINGS_CLOSE_VIEW_DRAWER_REQUEST',

    OPEN_CREATE_MODAL: 'PARKINGS_OPEN_CREATE_MODAL',
    CLOSE_CREATE_MODAL: 'PARKINGS_CLOSE_CREATE_MODAL',

    OPEN_DELETE_MODAL_REQUEST: 'PARKINGS_OPEN_DELETE_MODAL_REQUEST',
    OPEN_DELETE_MODAL_SUCCESS: 'PARKINGS_OPEN_DELETE_MODAL_SUCCESS',
    OPEN_DELETE_MODAL_FAILURE: 'PARKINGS_OPEN_DELETE_MODAL_FAILURE',

    CLOSE_DELETE_MODAL: 'PARKINGS_CLOSE_DELETE_MODAL',
};
