import { VehicleCounterObserver } from '../ipcan/observers/VehicleCounterObserver';

export class VehicleCounterValue {
    private _value: number;
    private _showUpArrow: boolean;
    private _showDownArrow: boolean;
    private _upArrowTimeout: ReturnType<typeof setTimeout> | null;
    private _downArrowTimeout: ReturnType<typeof setTimeout> | null;

    constructor(value_: number) {
        this._value = value_;
        this._showUpArrow = false;
        this._showDownArrow = false;
        this._upArrowTimeout = null;
        this._downArrowTimeout = null;
    }

    public getValue(): number {
        return this._value;
    }

    public getShowUpArrow(): boolean {
        return this._showUpArrow;
    }

    public getShowDownArrow(): boolean {
        return this._showDownArrow;
    }

    private setShowUpArrow() {
        this._showUpArrow = true;
        this._showDownArrow = false;

        this._upArrowTimeout = setTimeout(() => {
            this._showUpArrow = false;
            VehicleCounterObserver.emit();
        }, 5000);
    }

    private setShowDownArrow() {
        this._showDownArrow = true;
        this._showUpArrow = false;

        this._downArrowTimeout = setTimeout(() => {
            this._showDownArrow = false;
            VehicleCounterObserver.emit();
        }, 5000);
    }

    public setValue(value_: number) {
        if (value_ > this._value) {
            this.setShowUpArrow();
            this._value = value_;
        }

        if (value_ < this._value) {
            this.setShowDownArrow();
            this._value = value_;
        }
    }
}
