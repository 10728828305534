import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Col, FlexboxGrid, Tag, TagGroup } from 'rsuite';
import { LPDisplay } from '../../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { LPMatrixDisplayWidget } from '../../../Widgets/LPMatrixDisplayWidget';
import LPVirtualDisplayStepEditAndDelete from './Parts/LPVirtualDisplayStepEditAndDelete';

type Props = {
    step: VirtualDisplayStep;
    userText?: boolean;
    backgroundColor: string;
    virtualDisplay: VirtualDisplayPreviewInformation;
    reloadDisplay: Function;
    display: LPDisplay;
    updateInformation: Function;
};

export const LPVirtualDisplayStepText = ({
    step,
    userText,
    backgroundColor,
    virtualDisplay,
    reloadDisplay,
    display,
    updateInformation,
}: Props) => {
    return (
        <FlexboxGrid align="middle" justify="space-between">
            <FlexboxGrid.Item data-cy="lpDisplay-virtualDisplay-step-order" componentClass={Col} xs={2}>
                <Avatar circle size="sm">
                    {step.getStepOrder() + 1}
                </Avatar>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item componentClass={Col} xs={6}>
                {!userText && (
                    <Tag color="blue" data-cy="lpDisplay-virtualDisplay-step-systemText">
                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.text" />
                    </Tag>
                )}
                {userText && (
                    <Tag color="blue" data-cy="lpDisplay-virtualDisplay-step-userText">
                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.userText" />
                    </Tag>
                )}
            </FlexboxGrid.Item>

            <FlexboxGrid.Item componentClass={Col} xs={13}>
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <LPMatrixDisplayWidget backgroundColor={backgroundColor} step={step} display={display} />
                        <TagGroup>
                            {step.getState() === 0 && (
                                <Tag color="red">
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.mode.disabled" />
                                </Tag>
                            )}
                            {step.getState() === 1 && (
                                <Tag color="green">
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.mode.enabled" />
                                </Tag>
                            )}
                            {step.getState() === 2 && (
                                <Tag color="orange">
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.mode.waiting" />
                                </Tag>
                            )}
                            <Tag color="cyan" data-cy="lpDisplay-virtualDisplay-step-seconds">
                                <span className="margin-right-5">{step.getTime()}</span>
                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.seconds" />
                            </Tag>
                            <Tag color="violet" data-cy="lpDisplay-virtualDisplay-step-textTransformation">
                                <FormattedMessage
                                    id={`ipCanDevices.lpDisplay.virtualDisplay.textTransformation.${step.getTextTransformationString()}`}
                                />
                            </Tag>
                        </TagGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item componentClass={Col} xs={3}>
                <LPVirtualDisplayStepEditAndDelete
                    step={step}
                    virtualDisplay={virtualDisplay}
                    reloadDisplay={reloadDisplay}
                    display={display}
                    updateInformation={updateInformation}
                />
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};
