import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import Task from '../../../handlers/Event/Task';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    task: Task;
    intl: Record<string, any>;
    reloadEvent: Function;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isDeleting: boolean;
    hasError: boolean;
};

class DeleteTaskModal extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isDeleting: false,
            hasError: false,
        };

        this.handleDeleteTask = this.handleDeleteTask.bind(this);
    }

    /**
     * Launch delete request from axios
     */
    handleDeleteTask() {
        this.setState({
            isDeleting: true,
            hasError: false,
        });
        // Define headers and eventId to delete event
        const data = {
            headers: authHeader(),
            data: { id: this.props.task.getId() },
        };

        axiosService
            .getAxios()
            .delete('/event-actions/tasks', data)
            .then(() => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'deleteTask.deleteSuccess',
                    })
                );
                this.props.reloadEvent();
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'deleteTask.deleteError',
                    })
                );

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isDeleting: false,
                });
            });
    }

    closeModal() {
        this.props.onHide();
    }

    render() {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={() => this.closeModal()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage data-cy="modal-task-delete" id="event.task.delete.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="event.task.delete.message" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.closeModal()}
                            data-cy="event-task-deleteModal-cancel"
                            color="red"
                            loading={this.state.isDeleting}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.handleDeleteTask}
                            data-cy="event-task-deleteModal-valid"
                            appearance="primary"
                            loading={this.state.isDeleting}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(DeleteTaskModal);
