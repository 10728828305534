// wait for online, total and db as data
// wait for controler online or not

import placeCameraForceState from './Desc/placeCameraForceState';
import placeCameraUpdateGeojson from './Desc/placeCameraUpdateGeojson';
import placeCameraUpdateHyperParamConfig from './Desc/placeCameraUpdateHyperParamConfig';
import updateBasicConf from './Desc/updateBasicConf';
import updateMaintenanceState from './Desc/updateMaintenanceState';

export default function PlaceCameraDescCell(props) {
    switch (props) {
        case 'force state':
            return placeCameraForceState();
        case 'update geojson':
            return placeCameraUpdateGeojson();
        case 'update hyper param config':
            return placeCameraUpdateHyperParamConfig();
        case 'update maintenance state':
            return updateMaintenanceState();
        case 'update basic conf':
            return updateBasicConf();
        default:
            return props;
    }
}
