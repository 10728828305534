import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import { Button, FlexboxGrid, List, Panel } from 'rsuite';
import { sceneSensorPlaceTypeActions, sensorPlaceTypeActions } from '../../../../redux/actions';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';
import CreateSceneModal from '../../Modal/Scene/CreateSceneModal';
import Scene from './Scene';

const headerCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '16px',
};

const mapDispatchToProps = dispatch => ({
    getAllSensorPlaceTypes: () => dispatch(sensorPlaceTypeActions.getAll()),
    updateOrder: (data, sensorPlaceTypeId) =>
        dispatch(sceneSensorPlaceTypeActions.updateOrder(data, sensorPlaceTypeId)),
});

type Props = {
    scenes: Array<Record<string, any>>;
    updateOrder: Function;
    sensorId: number;
    title: string;
    state: string;
} & WrappedComponentProps;

type State = {
    loading: boolean;
    sceneToDelete: any;
    deleteSceneOpen: boolean;
    addSceneOpen: boolean;
};

type Payload = {
    collection: number | string;
    node: Record<string, any>;
    newIndex: number;
    oldIndex: number;
};

class SensorPlaceTypeScene extends React.Component<Props, State> {
    ref: React.RefObject<null>;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sceneToDelete: undefined,
            deleteSceneOpen: false,
            addSceneOpen: false,
        };

        this.ref = React.createRef();

        this.handleSortEnd = this.handleSortEnd.bind(this);
        this.openAddSceneModal = this.openAddSceneModal.bind(this);
        this.closeAddSceneModal = this.closeAddSceneModal.bind(this);
    }

    handleSortEnd(payload: Payload | undefined): void {
        if (payload) {
            const moveData = this.props.scenes.splice(payload.oldIndex, 1);
            const newData = [...this.props.scenes];
            newData.splice(payload.newIndex, 0, moveData[0]);

            this.props.updateOrder(
                newData.map(data => data.id),
                this.props.sensorId
            );
        }
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.scenes[0] !== this.props.scenes[0]) {
    //         this.setState({
    //             scenes: this.props.scenes,
    //         });
    //     }
    // }

    openAddSceneModal() {
        this.setState({
            addSceneOpen: true,
        });
    }

    closeAddSceneModal() {
        this.setState({
            addSceneOpen: false,
        });
    }

    render() {
        return (
            <Loader show={this.state.loading}>
                <CreateSceneModal
                    show={this.state.addSceneOpen}
                    closeModal={this.closeAddSceneModal}
                    state={this.props.scenes[0].state}
                    sensorId={this.props.sensorId}
                />
                <Panel
                    className="panel-small"
                    header={
                        <PanelHeader
                            buttons={[
                                <SecuredFragment
                                    key="scenesensorPlaceType.create"
                                    authorizedRoles={[rolesConstants.sceneSensorPlaceType.CREATE]}>
                                    <Button
                                        color="blue"
                                        data-cy={`sensorPlaceType-scene-${this.props.state}-createScene`}
                                        key="sensorPlaceTypeSceneAdd"
                                        onClick={this.openAddSceneModal}
                                        size="sm"
                                        disabled={this.props.scenes.length >= 16}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                            title={this.props.title}
                            tagValue={`${this.props.scenes.length} / 16`}
                            tagColor={'blue'}
                        />
                    }
                    bordered
                    shaded
                    bodyFill>
                    <List hover sortable onSort={data => this.handleSortEnd(data)}>
                        <List.Item disabled={true}>
                            <FlexboxGrid>
                                <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                    <FormattedMessage id="sensorPlaceTypeScene.edit.led1" />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                    <FormattedMessage id="sensorPlaceTypeScene.edit.led2" />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                    <FormattedMessage id="sensorPlaceTypeScene.edit.led3" />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                    <FormattedMessage id="sensorPlaceTypeScene.edit.led4" />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                    <FormattedMessage id="sensorPlaceTypeScene.edit.led5" />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                    <FormattedMessage id="sensorPlaceTypeScene.edit.led6" />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item style={headerCenter} colspan={4}>
                                    <FormattedMessage id="sensorPlaceTypeScene.edit.time" />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item style={headerCenter} colspan={5}>
                                    <FormattedMessage id="sensorPlaceTypeScene.edit.color" />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item style={headerCenter} colspan={3}>
                                    <FormattedMessage id="sensorPlaceTypeScene.edit.actions" />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {this.props.scenes.map((scene, index) => {
                            return (
                                <Scene
                                    colorPickerRef={this.ref}
                                    key={`${scene.id}-${index}`}
                                    scene={scene}
                                    index={index}
                                    shouldDisplayDeleteButton={this.props.scenes.length > 1}
                                />
                            );
                        })}
                    </List>
                </Panel>
            </Loader>
        );
    }
}

function mapStateToProps(state) {
    let { scenesSensorPlaceTypeList } = state.scenesSensorPlaceType;

    return { scenesSensorPlaceTypeList };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SensorPlaceTypeScene));
