import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Drawer } from 'rsuite';
import { Profile } from '../../../../../handlers/profile/Profile';
import { DeleteProfileModal } from '../Modals/DeleteProfileModal';
import { ProfileInformationCard } from './InformationCard/ProfileInformationCard';
import { RoleCard } from './RoleCard';

type Props = {
    open: boolean;
    onClose: () => void;
    profile?: Profile;
    updateProfiles: () => void;
    reloadProfiles: () => void;
    reloadUsers: () => void;
};

export const ProfileDrawer = (props: Props) => {
    const [isDeleteProfileModalOpen, setIsDeleteProfileModalOpen] = React.useState<boolean>(false);

    const handleCloseDeleteProfileModal = (shouldReload: boolean) => {
        setIsDeleteProfileModalOpen(false);

        if (shouldReload) {
            props.onClose();
            props.reloadProfiles();
            props.reloadUsers();
        }
    };

    if (!props.profile) return null;

    return (
        <>
            <Drawer backdrop={true} show={props.open} onHide={props.onClose}>
                <DeleteProfileModal
                    profile={props.profile}
                    show={isDeleteProfileModalOpen}
                    onHide={handleCloseDeleteProfileModal}
                />
                <Drawer.Header>
                    <Drawer.Title>{props.profile.getName()}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className="drawer-body-without-avatar">
                    <ProfileInformationCard
                        profile={props.profile}
                        updateProfiles={props.updateProfiles}
                        reloadProfiles={props.reloadProfiles}
                        reloadUsers={props.reloadUsers}
                    />
                    {/* <UserCard profile={props.profile} updateProfiles={props.updateProfiles} /> */}
                    <RoleCard
                        profile={props.profile}
                        updateProfiles={props.updateProfiles}
                        reloadProfiles={props.reloadProfiles}
                        reloadUsers={props.reloadUsers}
                    />
                </Drawer.Body>
                <Drawer.Footer>
                    <Button
                        onClick={() => setIsDeleteProfileModalOpen(true)}
                        disabled={props.profile.getName() === 'Admin'}
                        data-cy="delete-profile-button"
                        color="red">
                        <FontAwesomeIcon icon={faTrash} className="margin-right-5" />
                        <FormattedMessage id="profile.deleteButton" />
                    </Button>
                </Drawer.Footer>
            </Drawer>
        </>
    );
};
