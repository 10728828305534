import { faCircle, faEdit, faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Alert, Col, FlexboxGrid, IconButton, List, Loader, Panel, Popover, Toggle, Whisper } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import PanelHeader from '../../Custom/PanelHeader';
import { EditGpioInModal } from '../Modal/EditGpioInModal';
import { EditGpioOutModal } from '../Modal/EditGpioOutModal';

type Props = {
    ipcanModule: IpCan;
} & WrappedComponentProps;

type State = {
    isEditGpioInModalOpen: boolean;
    isEditGpioOutModalOpen: boolean;
};

class PhysicalInformations extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.handleGpioChange = this.handleGpioChange.bind(this);
        this.renderValue = this.renderValue.bind(this);

        this.handleOpenEditGpioInModal = this.handleOpenEditGpioInModal.bind(this);
        this.handleCloseEditGpioInModal = this.handleCloseEditGpioInModal.bind(this);

        this.handleOpenEditGpioOutModal = this.handleOpenEditGpioOutModal.bind(this);
        this.handleCloseEditGpioOutModal = this.handleCloseEditGpioOutModal.bind(this);

        this.state = {
            isEditGpioInModalOpen: false,
            isEditGpioOutModalOpen: false,
        }
    }

    handleGpioChange(index, checked) {
        const ipCanModuleId = this.props.ipcanModule.id;
        const gpio = this.props.ipcanModule.physicalInformation.gpio;

        gpio.setOutputState(index, checked, ipCanModuleId)
            .then(() => {
                Alert.success(
                    this.props.intl.formatMessage({ id: 'ipcan.physicalInformations.setGpioOutput.success' })
                );
            })
            .catch(err =>
                Alert.error(
                    this.props.intl.formatMessage(
                        { id: 'ipcan.physicalInformations.setGpioOutput.error' },
                        { error: err }
                    )
                )
            );
    }

    handleOpenEditGpioInModal() {
        this.setState({ isEditGpioInModalOpen: true });
    }

    handleCloseEditGpioInModal() {
        this.setState({ isEditGpioInModalOpen: false });
    }

    handleOpenEditGpioOutModal() {
        this.setState({ isEditGpioOutModalOpen: true });
    }

    handleCloseEditGpioOutModal() {
        this.setState({ isEditGpioOutModalOpen: false });
    }

    renderValue(infoName) {
        const physicalInformation = this.props.ipcanModule.getPhysicalInformation();

        if (this.props.ipcanModule.getOnline() === 0 && !physicalInformation.cpuTemp) {
            return (
                <p className="text-right">
                    <FontAwesomeIcon icon={faPlug} color="orange" className="text-right" />
                </p>
            );
        }

        if (physicalInformation) {
            switch (infoName) {
                case 'cpu': {
                    const cpuTemp = physicalInformation.cpuTemp;
                    if (cpuTemp) {
                        return (
                            <p style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                {cpuTemp}
                            </p>
                        );
                    } else {
                        return <Loader />;
                    }
                }
                case 'cardTemp': {
                    const cardTemp = physicalInformation.cardTemp;
                    if (cardTemp) {
                        return (
                            <p style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                {cardTemp}
                            </p>
                        );
                    } else {
                        return <Loader />;
                    }
                }
                case 'voltage': {
                    const voltage = physicalInformation.voltage;
                    if (voltage) {
                        return (
                            <p style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                {voltage}
                            </p>
                        );
                    } else {
                        return <Loader />;
                    }
                }
                case 'power': {
                    const power = physicalInformation.power;
                    if (power) {
                        return (
                            <p style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                {power}
                            </p>
                        );
                    } else {
                        return <Loader />;
                    }
                }
                case 'bus0': {
                    const bus = physicalInformation.bus;
                    if (bus.length >= 1) {
                        return (
                            <FlexboxGrid justify="space-around">
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    xs={8}
                                    className="text-right"
                                    style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                    {bus[0].volt}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    xs={8}
                                    className="text-right"
                                    style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                    {bus[0].amp}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    xs={8}
                                    className="text-right"
                                    style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                    {bus[0].p}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        );
                    } else {
                        return (
                            <FlexboxGrid justify="end">
                                <FlexboxGrid.Item>
                                    <Loader className="text-right" />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        );
                    }
                }
                case 'bus1': {
                    const bus = physicalInformation.bus;
                    if (bus.length >= 2) {
                        return (
                            <FlexboxGrid justify="space-around">
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    xs={8}
                                    className="text-right"
                                    style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                    {bus[1].volt}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    xs={8}
                                    className="text-right"
                                    style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                    {bus[1].amp}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    xs={8}
                                    className="text-right"
                                    style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                    {bus[1].p}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        );
                    } else {
                        return (
                            <FlexboxGrid justify="end">
                                <FlexboxGrid.Item>
                                    <Loader className="text-right" />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        );
                    }
                }
                case 'bus2': {
                    const bus = physicalInformation.bus;
                    if (bus.length >= 3) {
                        return (
                            <FlexboxGrid justify="space-around">
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    xs={8}
                                    className="text-right"
                                    style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                    {bus[2].volt}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    xs={8}
                                    className="text-right"
                                    style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                    {bus[2].amp}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    componentClass={Col}
                                    xs={8}
                                    className="text-right"
                                    style={{ color: this.props.ipcanModule.getOnline() === 0 ? 'orange' : 'black' }}>
                                    {bus[2].p}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        );
                    } else {
                        return (
                            <FlexboxGrid justify="end">
                                <FlexboxGrid.Item>
                                    <Loader className="text-right" />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        );
                    }
                }
                case 'gpioIn': {
                    const gpioIn = physicalInformation.gpio.in;

                    if (gpioIn.length > 0) {
                        return (
                            <FlexboxGrid justify="space-between">
                                {gpioIn.map((gpio, index) => {
                                    return (
                                        <FlexboxGrid.Item key={index} className="text-right">
                                            <Whisper
                                                trigger="hover"
                                                placement="topEnd"
                                                speaker={<Popover title={this.props.ipcanModule.getGpioConfig().gpioIn[index].name}>
                                                    <FormattedMessage id="controller.physicalInformations.gpio.nbEvent" />: {physicalInformation.gpio.in[index].nbEvent}<br />
                                                    <FormattedMessage id="controller.physicalInformations.gpio.lastEvent" />: {moment(physicalInformation.gpio.in[index].lastEvent).format('DD/MM/YYYY HH:mm:ss')}<br />
                                                </Popover>}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircle}
                                                    style={{ color: gpio.state ? 'green' : 'red' }}
                                                />
                                            </Whisper>
                                        </FlexboxGrid.Item>
                                    );
                                })}
                            </FlexboxGrid>
                        );
                    } else {
                        return (
                            <FlexboxGrid justify="end">
                                <FlexboxGrid.Item>
                                    <Loader className="text-right" />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        );
                    }
                }
                case 'gpioOut': {
                    const gpioOut = physicalInformation.gpio.out;

                    if (gpioOut.length > 0) {
                        return (
                            <FlexboxGrid justify="space-between" className="text-right">
                                {gpioOut.map((gpio, index) => {
                                    return (
                                        <Whisper
                                            trigger="hover"
                                            placement="topEnd"
                                            speaker={<Popover title={this.props.ipcanModule.getGpioConfig().gpioOut[index].name}>
                                                <FormattedMessage id="controller.physicalInformations.gpio.nbEvent" />: {physicalInformation.gpio.out[index].nbEvent}<br />
                                                <FormattedMessage id="controller.physicalInformations.gpio.lastEvent" />: {moment(physicalInformation.gpio.out[index].lastEvent).format('DD/MM/YYYY HH:mm:ss')}<br />
                                            </Popover>}
                                        >
                                            <Toggle
                                                key={`gpio-out-${index}`}
                                                defaultChecked={gpio.state}
                                                disabled={this.props.ipcanModule.getOnline() === 0}
                                                onChange={checked => {
                                                    this.handleGpioChange(index, checked);
                                                }}
                                            />
                                        </Whisper>
                                    );
                                })}
                            </FlexboxGrid>
                        );
                    } else {
                        return (
                            <FlexboxGrid justify="end">
                                <FlexboxGrid.Item>
                                    <Loader className="text-right" />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        );
                    }
                }
                default: {
                    return null;
                }
            }
        }
    }

    render() {
        return (
            <>
                <EditGpioInModal ipcanModule={this.props.ipcanModule} isOpen={this.state.isEditGpioInModalOpen} onClose={this.handleCloseEditGpioInModal} />
                <EditGpioOutModal ipcanModule={this.props.ipcanModule} isOpen={this.state.isEditGpioOutModalOpen} onClose={this.handleCloseEditGpioOutModal} />

                <Panel
                    shaded
                    bodyFill
                    bordered
                    className="panel-small"
                    data-cy="ipcan-physical-informations"
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'controller.physicalInformations.title' })}
                            buttons={[]}
                        />
                    }>
                    <List hover>
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                    <div data-cy="controller-physicalInfo-cpuTemp-title">
                                        <FormattedMessage id="controller.physicalInformations.CPUTemperature" />
                                    </div>
                                </FlexboxGrid.Item>
                                <div data-cy="controller-physicalInfo-cpuTemp-value">
                                    <FlexboxGrid.Item>{this.renderValue('cpu')}</FlexboxGrid.Item>
                                </div>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                    <div data-cy="controller-physicalInfo-cardTemp-title">
                                        <FormattedMessage id="controller.physicalInformations.cardTemperature" />
                                    </div>
                                </FlexboxGrid.Item>
                                <div data-cy="controller-physicalInfo-cardTemp-value">
                                    <FlexboxGrid.Item>{this.renderValue('cardTemp')}</FlexboxGrid.Item>
                                </div>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                    <div data-cy="controller-physicalInfo-supplyVoltage-title">
                                        <FormattedMessage id="controller.physicalInformations.supplyVoltage" />
                                    </div>
                                </FlexboxGrid.Item>
                                <div data-cy="controller-physicalInfo-supplyVoltage-value">
                                    <FlexboxGrid.Item>{this.renderValue('voltage')}</FlexboxGrid.Item>
                                </div>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item data-cy="controller-physicalInfo-bus0" className="panel-list">
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={12}>
                                    <FormattedMessage id="controller.physicalInformations.bus0" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    {this.renderValue('bus0')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item data-cy="controller-physicalInfo-bus1" className="panel-list">
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={12}>
                                    <FormattedMessage id="controller.physicalInformations.bus1" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    {this.renderValue('bus1')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {!this.props.ipcanModule.getVersion().includes('TCM') && (
                            <List.Item data-cy="controller-physicalInfo-bus2" className="panel-list">
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={12}>
                                        <FormattedMessage id="controller.physicalInformations.bus2" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        {this.renderValue('bus2')}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        )}
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                    <div data-cy="controller-physicalInfo-power-title">
                                        <FormattedMessage id="controller.physicalInformations.power" />
                                    </div>
                                </FlexboxGrid.Item>
                                <div data-cy="controller-physicalInfo-power-value">
                                    <FlexboxGrid.Item>{this.renderValue('power')}</FlexboxGrid.Item>
                                </div>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item data-cy="controller-physicalInfo-gpioIn" className="panel-list">
                            <FlexboxGrid justify="space-between" align='middle'>
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={12}>
                                    <FormattedMessage id="controller.physicalInformations.gpio.in" />
                                    <IconButton onClick={this.handleOpenEditGpioInModal} circle icon={<FontAwesomeIcon icon={faEdit} />} appearance="link" size='sm' />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    {this.renderValue('gpioIn')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item data-cy="controller-physicalInfo-gpioOut" className="panel-list">
                            <FlexboxGrid justify="space-between" align='middle'>
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={12}>
                                    <FormattedMessage id="controller.physicalInformations.gpio.out" />
                                    <IconButton onClick={this.handleOpenEditGpioOutModal} circle icon={<FontAwesomeIcon icon={faEdit} />} appearance="link" size='sm' />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    {this.renderValue('gpioOut')}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                    </List>
                </Panel>
            </>
        );
    }
}

export default injectIntl(PhysicalInformations);
