import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, FlexboxGrid, Panel } from 'rsuite';
import Camera from '../../../handlers/Camera/Camera';

type Props = {
    cameras: Camera[];
};

const CONNECTED_COLOR = '#008000';
const DISCONNECTED_COLOR = '#f44336';

export const ConnectionState = (props: Props) => {
    const intl = useIntl();

    const nbCamerasConnected = props.cameras.filter(camera => camera.getOnline()).length;
    const nbCamerasDisconnected = props.cameras.length - nbCamerasConnected;

    const percentageConnected = props.cameras.length === 0 ? 0 : (nbCamerasConnected / props.cameras.length) * 100;
    const percentageDisconnected = props.cameras.length === 0 ? 0 : (nbCamerasDisconnected / props.cameras.length) * 100;

    const dataDoughnut = {
        title: '',
        datasets: [
            {
                data: [nbCamerasConnected, nbCamerasDisconnected],
                backgroundColor: [CONNECTED_COLOR, DISCONNECTED_COLOR],
            },
        ],
        labels: [
            intl.formatMessage({ id: 'cameras.state.online' }),
            intl.formatMessage({ id: 'cameras.state.offline' }),
        ],
    };

    let donutSize: string | number = '100%';
    let donutColSize = 12;

    donutSize = window.innerHeight * 0.2;

    return (
        <Panel
            data-cy="cameras-connection-status"
            className={'dashboard-card-header hoverable'}
            header={<FormattedMessage id="cameras.cardOnline.title" />}
            shaded>
            <FlexboxGrid align="middle" justify="space-between" style={{ height: donutSize }}>
                <FlexboxGrid.Item componentClass={Col} colspan={12} xs={donutColSize} style={{ height: donutSize }}>
                    <Doughnut
                        data={dataDoughnut}
                        // height={'100%'}
                        // width={donutSize}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            aspectRatio: 1,
                            legend: {
                                display: false,
                            },
                        }}
                    />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} colspan={24} xs={24 - donutColSize}>
                    <div data-cy="cameras-cardOnline-online" className="parking-legend-title margin-top-15 free">
                        <FormattedMessage id="cameras.cardOnline.online" />
                    </div>
                    <div data-cy="ipCan-cardOnline-online-value" className="parking-legend-value">
                        {nbCamerasConnected} <FormattedMessage id="cameras.cardOnline.camera" />
                        <span className="margin-left-5 small free">({percentageConnected.toFixed(2)}%)</span>
                    </div>

                    <div data-cy="cameras-cardOnline-offline" className="parking-legend-title margin-top-15 occupied">
                        <FormattedMessage id="cameras.cardOnline.offline" />
                    </div>
                    <div data-cy="ipCan-cardOnline-offline-value" className="parking-legend-value">
                        {nbCamerasDisconnected} <FormattedMessage id="cameras.cardOnline.camera" />
                        <span className="margin-left-5 small occupied">({percentageDisconnected.toFixed(2)}%)</span>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    );
};
