import { faCheck, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Alert, Button, ButtonGroup, FlexboxGrid, List, Panel, PanelGroup, Toggle } from 'rsuite';
import { RoleData } from '.';
import { Profile, ProfileInterface } from '../../../../../../handlers/profile/Profile';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';

type Props = {
    profile: Profile;
    roles: RoleData[];
    setViewMode: () => void;
    reloadProfiles: () => void;
    reloadUsers: () => void;
    auth: Record<string, any>;
};

const EditProfileRolesPart = (props: Props) => {
    const intl = useIntl();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [checkedValues, setCheckedValues] = useState<number[]>(props.profile.getRoles().map(role => role.getId()));

    const currentUserIsAdmin =
        props.auth?.user.roles.find(role => {
            return role.id === 1;
        }) || false;

    const currentUserRolesIds: number[] = props.auth?.user.roles.map(role => role.id);

    const handleValid = () => {
        setIsEditing(true);

        axiosService
            .getAxios()
            .put<ProfileInterface>(
                '/profile/updateRoles',
                {
                    id: props.profile.getId(),
                    roles: checkedValues,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateRolesResponse => {
                props.profile.updateProfile(updateRolesResponse.data);

                Alert.success(intl.formatMessage({ id: 'profile.edit.roles.success' }));

                props.reloadProfiles();
                props.reloadUsers();

                props.setViewMode();
            })
            .catch(err => {
                Alert.error(intl.formatMessage({ id: 'profile.edit.roles.error' }));
                console.error(err);
            })
            .finally(() => {
                setIsEditing(false);
            });
    };

    // HANDLE CHECKBOX FOR SECTION CHANGE
    const handleSectionToggleChange = (event, checked: boolean, roleIndex: number) => {
        event.stopPropagation();

        const sectionRolesId = props.roles[roleIndex].children.map(role => role.value);
        if (checked) {
            setCheckedValues([...checkedValues, ...sectionRolesId]);
        } else {
            setCheckedValues(checkedValues.filter(value => !sectionRolesId.includes(value)));
        }
    };

    // HANDLE CHECKBOX FOR ROLE CHANGE
    const handleRoleToggleChange = (checked: boolean, roleId: number) => {
        if (checked) {
            setCheckedValues([...checkedValues, roleId]);
        } else {
            setCheckedValues(checkedValues.filter(id => id !== roleId));
        }
    };

    const checkAllElementsAreSelected = (sectionIndex: number) => {
        const selectedElements = props.roles[sectionIndex].children.map(role => role.value);

        return selectedElements.every(s => checkedValues.includes(s));
    };

    const handleCancelEdit = () => {
        setCheckedValues(props.profile.getRoles().map(role => role.getId()));
        props.setViewMode();
    };

    return (
        <PanelGroup accordion style={{ borderRadius: 0 }}>
            {props.roles.map((role, index) => {
                const rolesId = role.children.map(child => child.value);

                const shouldDisplayRole = rolesId.some(roleId => currentUserRolesIds.includes(roleId));

                if (currentUserIsAdmin || shouldDisplayRole) {
                    return (
                        <Panel
                            className="rs-panel-collapsible-no-carret panel-roles panel-small no-radius margin-bottom-0"
                            bodyFill
                            header={
                                <FlexboxGrid align="middle" justify="space-between">
                                    <FlexboxGrid.Item data-cy={`global-${role.label}`}>{role.label}</FlexboxGrid.Item>
                                    <FlexboxGrid.Item>
                                        <Toggle
                                            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                                            unCheckedChildren={<FontAwesomeIcon icon={faTimesCircle} />}
                                            onChange={(value, event) => handleSectionToggleChange(event, value, index)}
                                            checked={checkAllElementsAreSelected(index)}
                                            data-cy={`activation-${role.label}`}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            }>
                            <List>
                                {role.children.map(r => {
                                    return (
                                        <List.Item
                                            key={`${props.roles[index].label}-${r.value}`}
                                            className="margin-left-5 margin-right-5 list-profile-role">
                                            <FlexboxGrid align="middle" justify="space-between">
                                                <FlexboxGrid.Item>{r.label}</FlexboxGrid.Item>
                                                <FlexboxGrid.Item>
                                                    <Toggle
                                                        checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                                                        unCheckedChildren={<FontAwesomeIcon icon={faTimesCircle} />}
                                                        onChange={value => handleRoleToggleChange(value, r.value)}
                                                        checked={checkedValues.includes(r.value)}
                                                        data-cy={`${role.label}-${r.label}`}
                                                    />
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </List.Item>
                                    );
                                })}
                            </List>
                        </Panel>
                    );
                }
                return null;
            })}
            <hr className="margin-top-5 margin-bottom-5" />
            <FlexboxGrid justify="end" align="middle">
                <FlexboxGrid.Item>
                    <ButtonGroup className="margin-bottom-5 margin-right-10">
                        <Button color="red" onClick={() => handleCancelEdit()} disabled={isEditing}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        <Button
                            color="green"
                            onClick={() => handleValid()}
                            loading={isEditing}
                            data-cy="profile-validate">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </PanelGroup>
    );
};

function mapStateToProps(state) {
    const { auth } = state;

    return { auth };
}

export default connect(mapStateToProps)(EditProfileRolesPart);
