import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import { VirtualDisplayPreviewInformation } from '../../../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { authHeader } from '../../../../../../../redux/helpers';
import { axiosService } from '../../../../../../../redux/services';

type Props = {
    step: VirtualDisplayStep;
    virtualDisplay: VirtualDisplayPreviewInformation;
    show: boolean;
    onHide: Function;
    updateInformation: Function;
} & WrappedComponentProps;

type State = {
    isDeleting: boolean;
    hasError: boolean;
};

class DeleteLPVirtualDisplayStepModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isDeleting: false,
            hasError: false,
        };
    }

    deleteStep = () => {
        this.setState({
            isDeleting: true,
        });
        axiosService
            .getAxios()
            .delete('/devices/lp-matrixdisplays-v2/virtualDisplay/step', {
                data: {
                    id_virtualDisplay: this.props.virtualDisplay.id,
                    tabStepOrder: [this.props.step.getStepOrder()],
                },
                headers: authHeader(),
            })
            .then(deleteVirtualDisplayStepResponse => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.step.delete.success',
                    })
                );

                this.props.updateInformation(deleteVirtualDisplayStepResponse.data);

                // this.props.virtualDisplay.updateVirtualDisplaySteps(deleteVirtualDisplayStepResponse.data.steps);

                this.props.onHide(true);
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.step.delete.error',
                    })
                );
            })
            .finally(() => {
                this.setState({
                    isDeleting: false,
                });
            });
    };

    render = () => (
        <Modal show={this.props.show} onHide={() => this.props.onHide()} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.delete.title" />
                </Modal.Title>
                <Modal.Body>
                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.delete.message" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            data-cy="lpDisplay-virtualDisplay-delete-cancel"
                            onClick={() => this.props.onHide()}
                            disabled={this.state.isDeleting}
                            color="red">
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        <Button
                            data-cy="lpDisplay-virtualDisplay-delete-valid"
                            onClick={this.deleteStep}
                            color="green"
                            loading={this.state.isDeleting}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Header>
        </Modal>
    );
}

export default injectIntl(DeleteLPVirtualDisplayStepModal);
