import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Modal } from 'rsuite';
import { User } from '../../../../../handlers/profile/User';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    open: boolean;
    user?: User;
    onHide: (shouldReload: boolean) => void;
};

export const DeleteUserModal = (props: Props) => {
    const intl = useIntl();
    const [isDeleting, setIsDeleting] = React.useState(false);

    const handleDeleteUser = () => {
        setIsDeleting(true);

        axiosService
            .getAxios()
            .delete('/users', {
                data: {
                    id: props.user?.getId(),
                },
                headers: authHeader(),
            })
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'users.delete.success' }));
                props.onHide(true);
            })
            .catch(err => {
                Alert.error(intl.formatMessage({ id: 'users.delete.success' }));

                console.error(err);
            })
            .then(() => {
                setIsDeleting(false);
            });
    };

    return (
        <Modal size="xs" show={props.open} onHide={() => props.onHide(false)} data-cy="user-deleteuser-modal">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="user.delete.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body data-cy="user-delete-modal">
                <FormattedMessage id="user.delete.message" />

                <p className="bold">
                    {props.user?.getFirstName()} {props.user?.getLastName()}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <FlexboxGrid align="middle" justify="end">
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button
                                data-cy="delete-user-cancel"
                                onClick={() => props.onHide(false)}
                                disabled={isDeleting}
                                color="red">
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                data-cy="delete-user-valid"
                                onClick={handleDeleteUser}
                                color="green"
                                loading={isDeleting}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    );
};
