import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Col, FlexboxGrid, Panel } from 'rsuite';

type Props = {
    listIpCan: Array<Record<string, any>>;
};

type State = {
    loading: false;
};
export default class IpCanDeviceDb extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    render() {
        let donutSize = window.innerHeight * 0.2;
        let donutColSize = 12;

        const state: { total: number; tabIpCanDevice: Array<Record<string, any>> } = {
            total: 0,
            tabIpCanDevice: [],
        };

        const colorTab = ['#ffc107', '#fd7e14', '#17a2b8', '#20c997', '#007bff', '#6610f2', '#6c757d'];

        const dataDoughnut: { title: string; datasets: Array<Record<string, any>>; labels: Array<string> } = {
            title: '',
            datasets: [
                {
                    data: [],
                    backgroundColor: colorTab,
                },
            ],
            labels: [],
        };

        //---
        for (let l in this.props.listIpCan) {
            let ipcan = this.props.listIpCan[l];
            //---
            state.tabIpCanDevice.push({
                name: ipcan.label,
                devices: ipcan.nbDisplays.onDB + ipcan.nbSensors.onDB,
            });
            //---
            state.total += ipcan.nbDisplays.onDB + ipcan.nbSensors.onDB;
        }

        //---
        for (const i in state.tabIpCanDevice) {
            const resultTemp = state.tabIpCanDevice[i];
            resultTemp.percent = ((resultTemp.devices / state.total) * 100).toFixed(1);
            //---
            dataDoughnut.labels.push(resultTemp.name);
            dataDoughnut.datasets[0].data.push(resultTemp.devices);
        }

        //---

        return (
            <Loader show={this.state.loading}>
                <Panel
                    data-cy="controller-connection"
                    className={'dashboard-card-header hoverable'}
                    header={<FormattedMessage id="controllers.cardDevices.title" />}
                    shaded>
                    <FlexboxGrid align="middle" justify="space-between" style={{ height: 'fit-content' }}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={12}
                            xs={donutColSize}
                            style={{ height: donutSize }}>
                            <Doughnut
                                data={dataDoughnut}
                                // width={donutSize}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    aspectRatio: 1,
                                    legend: {
                                        display: false,
                                    },
                                }}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} colspan={24} xs={24 - donutColSize}>
                            <ul>
                                {state.tabIpCanDevice.map((element, index) => {
                                    return (
                                        <li key={index} className="list-ipCan-devices">
                                            <div
                                                data-cy="ipcan-legend-title"
                                                className="ipcan-legend-title"
                                                style={{ color: colorTab[index] }}>
                                                {element.name}
                                            </div>
                                            <div data-cy="ipcan-legend-value" className="ipcan-legend-value">
                                                {element.devices}{' '}
                                                <FormattedMessage id="controllers.cardDevices.devices" />
                                                <span
                                                    className="margin-left-5 small "
                                                    style={{ color: colorTab[index] }}>
                                                    ({element.percent}%)
                                                </span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Panel>
            </Loader>
        );
    }
}
