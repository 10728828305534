import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DataTable, { RowRecord } from 'react-data-table-component';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import IPCanTcm from '../../../../handlers/ipcan/IpCanTCM';

type Props = RowRecord & WrappedComponentProps;

class UpdateBusConfigExpand extends React.Component<Props> {
    customStyles: any;

    constructor(props: Props) {
        super(props);

        this.customStyles = {
            headRow: {
                style: {
                    backgroundColor: 'rgb(33, 132, 218)',
                },
            },
            headCells: {
                style: {
                    color: '#FFFFFF',
                },
            },
            rows: {
                style: {
                    backgroundColor: '#E3F2FD',
                },
                stripedStyle: {
                    backgroundColor: '#BBDEFB',
                },
            },
        };
    }

    getColumns = () => {
        const ipcan = this.props.ipcans.find(controller => controller.id === this.props.data.idIpcanmodule);

        if (ipcan instanceof IPCanTcm) {
            this.props.data.length > 2 && this.props.data.splice(-1, 1);

            return [
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.name',
                    }),
                    selector: 'name',
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.speed',
                    }),
                    selector: 'speed',
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.isPolling',
                    }),
                    selector: 'isPolling',
                    cell: row => booleanRender(row.isPolling),
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.crcIsActiv',
                    }),
                    selector: 'crcIsActiv',
                    cell: row => booleanRender(row.crcIsActiv),
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.isLoadConf',
                    }),
                    selector: 'isLoadConf',
                    cell: row => booleanRender(row.isLoadConf),
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.isActiv',
                    }),
                    selector: 'isActiv',
                    cell: row => booleanRender(row.isActiv),
                    center: true,
                },
            ];
        } else {
            return [
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.name',
                    }),
                    selector: 'name',
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.speed',
                    }),
                    selector: 'speed',
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.isPolling',
                    }),
                    selector: 'isPolling',
                    cell: row => booleanRender(row.isPolling),
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.pollingTime',
                    }),
                    selector: 'pollingTime',
                    cell: row => `${row.pollingTime} ms`,
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.isLoadConf',
                    }),
                    selector: 'isLoadConf',
                    cell: row => booleanRender(row.isLoadConf),
                    center: true,
                },
                {
                    name: this.props.intl.formatMessage({
                        id: 'history.ipcanModule.updateBusConfig.isActiv',
                    }),
                    selector: 'isActiv',
                    cell: row => booleanRender(row.isActiv),
                    center: true,
                },
            ];
        }
    };

    render = () => {
        return (
            <div style={{ marginRight: 20, marginLeft: 20, marginTop: 15, marginBottom: 15 }}>
                <DataTable
                    customStyles={this.customStyles}
                    highlightOnHover
                    striped
                    persistTableHead
                    columns={this.getColumns()}
                    data={this.props.data.option}
                />
            </div>
        );
    };
}

export default injectIntl(UpdateBusConfigExpand);

function booleanRender(isChecked) {
    if (isChecked) {
        return <FontAwesomeIcon icon={faCheck} color="green" size="lg" />;
    } else {
        return <FontAwesomeIcon icon={faTimesCircle} color="red" size="lg" />;
    }
}
