import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import Control from 'react-leaflet-control';
import { Alert, ControlLabel, Form, FormControl, FormGroup, InputNumber, Toggle } from 'rsuite';

type Props = {
    handleStream: (fps: number, checked: boolean) => void;
    isStreaming: boolean
};

type FormValue = {
    fps: string;
    checked: boolean;
};

let updateFormTimeout: ReturnType<typeof setTimeout> | undefined;

export const BottomLeftControl = (props: Props) => {
    const intl = useIntl();

    const [formValue, setFormValue] = React.useState<FormValue>({
        fps: '3',
        checked: false,
    });

    const handleFormChange = (value: FormValue) => {
        if (updateFormTimeout) clearTimeout(updateFormTimeout);

        setFormValue(value);

        updateFormTimeout = setTimeout(() => {
            const fps = parseInt(value.fps) * 10;
            if (fps) {
                props.handleStream(fps, value.checked);
                Alert.info(intl.formatMessage({ id: 'camera.place.stream.updated' }));
            }
        }, 500);
    };

    return (
        <Control position="bottomleft">
            <Form
                style={{ backgroundColor: 'white', paddingTop: 4, paddingBottom: 4, paddingLeft: 10, marginRight: 5 }}
                formValue={formValue}
                layout="inline"
                onChange={formValue => handleFormChange(formValue as FormValue)}>
                <FormGroup style={{ marginBottom: 0, marginTop: 3 }}>
                    <ControlLabel srOnly>Stream</ControlLabel>
                    <FormControl
                        defaultChecked={props.isStreaming}
                        checked={props.isStreaming}
                        accepter={Toggle}
                        checkedChildren={<FontAwesomeIcon icon={faPlay} />}
                        unCheckedChildren={<FontAwesomeIcon icon={faStop} />}
                        name="checked"
                    />
                </FormGroup>
                <FormGroup style={{ marginBottom: 0, marginTop: 5 }}>
                    <ControlLabel srOnly>Refresh rate</ControlLabel>
                    <FormControl style={{ width: '130px' }} accepter={InputNumber} name="fps" min={2} size="sm" />
                </FormGroup>
            </Form>
        </Control>
    );
};
