import moment from 'moment';
import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';
import BusInformations from './BusInformation';
import NbDisplays from './NbDisplays';
import PhysicalInformation from './PhysicalInformation';
import TCMBusConfig from './TCMBusConfig';

export class IpCan {
    autoSetScene: Record<string, any>;
    config: Array<TCMBusConfig>;
    busInfo: Array<BusInformations>;
    createdAt: string;
    createdBy: string;
    gpioConfig: {
        gpioIn: [
            {
                name: string;
            },
            {
                name: string;
            },
            {
                name: string;
            },
            {
                name: string;
            }
        ];
        gpioOut: [
            {
                name: string;
            },
            {
                name: string;
            }
        ];
    };
    gpioState: Record<string, any>;
    id: number;
    ip: string;
    label: string;
    desc: string;
    lastOnlineStateDate: string;
    mac: string;
    macStr: string;
    nbDevicesOnBus: Record<string, any>;
    nbDisplays: Record<string, any>;
    nbReboot: number;
    nbSensors: Record<string, any>;
    online: number;
    serialNumber: string;
    serialNumberStr: string;
    startAt: string;
    updatedAt: string;
    updatedBy: string;
    version: string;
    physicalInformation: PhysicalInformation;
    syncState: number;
    fifoLength: number;
    isEnumRunning: boolean;
    isEnumLoading: boolean;
    hasEnumError: boolean;
    private _disableTimeout: boolean;
    constructor(ipcanObject) {
        const {
            autoSetScene,
            config,
            createdAt,
            createdBy,
            gpioState,
            gpioConfig,
            id,
            ip,
            label,
            desc,
            lastOnlineStateDate,
            mac,
            macStr,
            nbDevicesOnBus,
            nbDisplays,
            nbReboot,
            nbSensors,
            online,
            serialNumber,
            serialNumberStr,
            startAt,
            updatedAt,
            updatedBy,
            version,
        } = ipcanObject;


        this.autoSetScene = autoSetScene;
        this.config = config.map((conf, index) => new TCMBusConfig(conf, index));
        this.busInfo = config.map((_, index) => new BusInformations(nbDevicesOnBus, index, id));
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.gpioState = gpioState;
        this.gpioConfig = gpioConfig;
        this.id = id;
        this.ip = ip;
        this.label = label;
        this.desc = desc;
        this.lastOnlineStateDate = lastOnlineStateDate;
        this.mac = mac;
        this.macStr = macStr;
        this.nbDevicesOnBus = nbDevicesOnBus;
        if (nbDisplays) {
            this.nbDisplays = new NbDisplays(nbDisplays.onDB, nbDisplays.onModule, nbDisplays.online);
        } else {
            this.nbDisplays = new NbDisplays(0, 0, 0);
        }
        this.nbReboot = nbReboot;
        if (nbSensors) {
            this.nbSensors = new NbDisplays(nbSensors.onDB, nbSensors.onModule, nbSensors.online);
        } else {
            this.nbSensors = new NbDisplays(0, 0, 0);
        }
        this.online = online;
        this.physicalInformation = new PhysicalInformation();
        this.serialNumber = serialNumber;
        this.serialNumberStr = serialNumberStr;
        this.startAt = startAt;
        this.updatedAt = updatedAt;
        this.updatedBy = updatedBy;
        this.version = version;
        this.syncState = -1;
        this.fifoLength = 0;

        // ENUMERATION
        this.isEnumRunning = false;
        this.isEnumLoading = false;
        this.hasEnumError = false;
        this._disableTimeout = false;
    }

    public getId() {
        return this.id;
    }

    public getLabel() {
        return this.label;
    }

    public getDesc() {
        return this.desc;
    }

    public getMac() {
        return this.mac;
    }

    public getMacStr() {
        return this.macStr;
    }

    public getIp() {
        return this.ip;
    }

    public getConfig() {
        return this.config;
    }

    public getSerialNumber() {
        return this.serialNumber;
    }

    public getSerialNumberStr() {
        return this.serialNumberStr;
    }

    public getOnline() {
        return this.online;
    }

    public getAutoSetScene() {
        return this.autoSetScene;
    }

    public getVersion() {
        return this.version;
    }

    public getGpioState() {
        return this.gpioState;
    }

    public getLastOnlineStateDate() {
        return moment(this.lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreatedAt() {
        return moment(this.createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getUpdatedAt() {
        return moment(this.updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreatedBy() {
        return this.createdBy;
    }

    public getUpdatedBy() {
        return this.updatedBy;
    }

    public getNbDevicesOnBus() {
        return this.nbDevicesOnBus;
    }

    public getNbReboot() {
        return this.nbReboot;
    }

    public getGpioConfig() {
        return this.gpioConfig;
    }

    public getStartAt() {
        return moment(this.startAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreationInformations() {
        return `${this.getCreatedBy()} - ${this.getCreatedAt()}`;
    }

    public getUpdateInformations() {
        return `${this.getUpdatedBy()} - ${this.getUpdatedAt()}`;
    }

    public getNbDisplays() {
        const { onDB, onModule, online } = this.nbDisplays;
        return {
            onDB: parseInt(onDB),
            onModule: parseInt(onModule),
            online: parseInt(online),
        };
    }

    public getNbSensors() {
        const { onDB, onModule, online } = this.nbSensors;
        return {
            onDB: parseInt(onDB),
            onModule: parseInt(onModule),
            online: parseInt(online),
        };
    }

    public getTableData() {
        return {
            id: this.getId(),
            label: this.getLabel(),
            online: this.getOnline(),
            macStr: this.getMacStr(),
            ip: this.getIp(),
            nbReboot: this.getNbReboot(),
            nbDisplays: this.getNbDisplays(),
            nbSensors: this.getNbSensors(),
            lastOnlineStateDate: this.getLastOnlineStateDate(),
            creationInformations: this.getCreationInformations(),
            version: this.version,
        };
    }

    public getBusInformations() {
        return this.busInfo;
    }

    public getPhysicalInformation() {
        return this.physicalInformation.getPhysicalInformation();
    }

    public resetBusCounterError(busIndex) {
        this.busInfo[busIndex].resetError();
    }

    setNbDisplays(displays) {
        this.nbDisplays.setWebsocketInformations(displays);
    }

    setNbSensors(sensors) {
        this.nbSensors.setWebsocketInformations(sensors);
    }

    setNbReboot(reboot) {
        this.nbReboot = reboot;
    }

    setOnlineState(state) {
        this.online = state;
    }

    setDevicesOnBus(busNumber, devices) {
        if (this.busInfo) {
            let configBus = this.busInfo;
            configBus[busNumber].setDevicesOnBus(devices);

            this.busInfo = configBus;
        }
    }

    setSyncState(state) {
        this.syncState = state;
    }

    setFifoLength(length) {
        this.fifoLength = length;
    }

    setGpioConfig(gpioConfig) {
        this.gpioConfig = gpioConfig;
    }

    getInformations() {
        return {
            label: this.label,
            desc : this.desc,
            online: this.online,
            syncState: this.syncState,
            fifoLength: this.fifoLength,
            nbReboot: this.nbReboot,
            ip: this.ip,
            macStr: this.macStr,
            version: this.version,
            serialNumberStr: this.serialNumberStr,
            creationInformations: this.getCreationInformations(),
            updateInformations: this.getUpdateInformations(),
        };
    }

    setWebsocketUpdateIpCanList(data) {
        this.online = data.online;
        this.lastOnlineStateDate = data.lastOnlineStateDate;
        this.version = data.version;
        this.label = data.label;
        this.desc = data.desc;
        this.serialNumber = data.serialNumber;
        this.serialNumberStr = data.serialNumberStr;
    }

    updateInformations(label, desc, ip, macStr) {
        const ipcanId = this.id;

        return axiosService
            .getAxios()
            .put(
                '/ipcanmodules',
                {
                    id: ipcanId,
                    label,
                    desc,
                    ip,
                    macStr,
                },
                { headers: authHeader() }
            )
            .then(() => {
                this.label = label;
                this.ip = ip;
                this.macStr = macStr;
            });
    }

    updateType(type) {
        const ipcanId = this.id;

        return axiosService.getAxios().put(
            'ipcanmodules/updateType',
            {
                id: ipcanId,
                type,
            },
            {
                headers: authHeader(),
            }
        );
    }

    setWebsocketInformations(data) {
        const {
            boardTemperature,
            bus,
            displays,
            externalVoltage,
            gpio,
            macAddress,
            mcuTemperature,
            nbReboot,
            sensors,
            syncState,
            msgFifoLength,
            onlineState,
        } = data;

        this.setNbDisplays(displays);
        this.setNbSensors(sensors);
        this.setNbReboot(nbReboot);

        for (let i = 0; i < this.busInfo.length; i++) {
            this.setDevicesOnBus(i, data['devicesOnBus' + i]);
        }
        this.setSyncState(syncState);
        this.setFifoLength(msgFifoLength);
        this.setOnlineState(onlineState);
        // this.setGpioConfig(gpioConfig);

        this.physicalInformation.setPhysicalInformation(boardTemperature, mcuTemperature, externalVoltage, bus, gpio);
    }

    getBusConfig() {
        return this.config.map(conf => conf.getBusConfig());
    }

    updateBusConfigs(busConfig) {
        return axiosService
            .getAxios()
            .put(
                '/ipcanmodules/updateBusConfiguration',
                {
                    id: this.id,
                    config: busConfig,
                },
                { headers: authHeader() }
            )
            .then(() => {
                this.config.forEach((config, index) => config.updateBusConfig(busConfig[index]));
            })
            .catch(err => {
                console.error(err);
            });
    }

    /**
     * Enumerate a complete bus with TCM Sensors and TCM Displays
     * @param {number} bus
     * @param {boolean} startStop
     * @param {string} startId
     * @param {string} stopId
     */
    TCM_enumBusCommand(bus, startStop, startId, stopId, enumUpdate) {
        let timeoutFunction;

        this._disableTimeout = false;

        if (typeof timeoutFunction !== 'undefined') {
            clearTimeout(timeoutFunction);
        }

        axiosService
            .getAxios()
            .put(
                'ipcanmodules/cmdEnumBus',
                {
                    id: this.id,
                    bus: bus,
                    startStop: startStop,
                    startId: parseInt(startId),
                    stopId: parseInt(stopId),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.isEnumLoading = !this.isEnumLoading;
                this.isEnumRunning = !this.isEnumRunning;
                this.hasEnumError = false;
                enumUpdate();
            })
            .catch(err => {
                console.error(err);

                this.hasEnumError = true;
                enumUpdate();
            });

        timeoutFunction = setTimeout(() => {
            if (this.isEnumRunning && !this._disableTimeout) {
                axiosService
                    .getAxios()
                    .put(
                        'ipcanmodules/cmdEnumBus',
                        {
                            id: this.id,
                            bus: bus,
                            startStop: false,
                            startId: parseInt(startId),
                            stopId: parseInt(stopId),
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(() => {
                        this.isEnumLoading = !this.isEnumLoading;
                        this.isEnumRunning = !this.isEnumRunning;
                        this.hasEnumError = true;
                        enumUpdate();
                    });
            }
        }, 10000);
    }

    /**
     * Enumerate Leaderpark sensor for the given bus
     * @param {number} bus
     * @param {boolean} startStop
     * @param {Object[]} jump
     * @param {string} startId
     */
    LPSensor_enumBusCommand(bus, startStop, jump, startId, enumUpdate) {
        this.hasEnumError = false;

        let timeoutFunction;

        this._disableTimeout = false;

        if (typeof timeoutFunction !== 'undefined') {
            clearTimeout(timeoutFunction);
        }

        axiosService
            .getAxios()
            .put(
                'ipcanmodules/cmdEnumBusLpSensor',
                {
                    id: this.id,
                    bus: bus,
                    startStop: startStop,
                    jump: jump, // TODO: Fullfill the part
                    startId: parseInt(startId),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.isEnumLoading = !this.isEnumLoading;
                this.isEnumRunning = !this.isEnumRunning;
                this.hasEnumError = false;
                enumUpdate();
            })
            .catch(err => {
                console.error(err);

                this.hasEnumError = true;
                enumUpdate();
            });

        timeoutFunction = setTimeout(() => {
            if (this.isEnumRunning && !this._disableTimeout) {
                axiosService
                    .getAxios()
                    .put(
                        'ipcanmodules/cmdEnumBusTcmDisplay',
                        {
                            id: this.id,
                            bus: bus,
                            startStop: false,
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(() => {
                        this.isEnumLoading = !this.isEnumLoading;
                        this.isEnumRunning = !this.isEnumRunning;
                        this.hasEnumError = true;
                        enumUpdate();
                    });
            }
        }, 10000);
    }

    /**
     * Enumerate TCM displays for the given bus
     * @param {number} bus
     * @param {boolean} startStop
     */
    TCM_enumBusDisplayCommand(bus, startStop, enumUpdate) {
        this.hasEnumError = false;

        let timeoutFunction;

        this._disableTimeout = false;

        if (typeof timeoutFunction !== 'undefined') {
            clearTimeout(timeoutFunction);
        }

        axiosService
            .getAxios()
            .put(
                'ipcanmodules/cmdEnumBusTcmDisplay',
                {
                    id: this.id,
                    bus: bus,
                    startStop: startStop,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.isEnumLoading = !this.isEnumLoading;
                this.isEnumRunning = !this.isEnumRunning;
                this.hasEnumError = false;
                enumUpdate();
            })
            .catch(err => {
                console.error(err);

                this.hasEnumError = true;
                enumUpdate();
            });

        timeoutFunction = setTimeout(() => {
            if (this.isEnumRunning && !this._disableTimeout) {
                axiosService
                    .getAxios()
                    .put(
                        'ipcanmodules/cmdEnumBusTcmDisplay',
                        {
                            id: this.id,
                            bus: bus,
                            startStop: false,
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(() => {
                        this.isEnumLoading = !this.isEnumLoading;
                        this.isEnumRunning = !this.isEnumRunning;
                        this.hasEnumError = true;
                        enumUpdate();
                    });
            }
        }, 10000);
    }

    stopEnumeration(enumUpdate) {
        this.isEnumLoading = false;
        this.isEnumRunning = false;
        enumUpdate();
    }

    setDisableTimeout(disableTimeout) {
        this._disableTimeout = disableTimeout;
    }

    /**
     * Enumerate Leaderpark sensor for the given bus
     * @param {number} bus
     * @param {boolean} startStop
     * @param {string} startId
     * @param {string} endId
     * @param {Function} enumUpdate
     */
    enumLPDisplayMatrixV2Command(bus, startStop, startId, stopId, enumUpdate) {
        this.hasEnumError = false;

        let timeoutFunction;

        this._disableTimeout = false;

        if (typeof timeoutFunction !== 'undefined') {
            clearTimeout(timeoutFunction);
        }

        axiosService
            .getAxios()
            .put(
                'ipcanmodules/cmdEnumBusLpMatrixDisplayV2',
                {
                    id: this.id,
                    bus: bus,
                    startStop: startStop,
                    startId: parseInt(startId),
                    stopId: parseInt(stopId),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.isEnumLoading = !this.isEnumLoading;
                this.isEnumRunning = !this.isEnumRunning;
                this.hasEnumError = false;
                enumUpdate();
            })
            .catch(err => {
                console.error(err);

                this.hasEnumError = true;
                enumUpdate();
            });

        timeoutFunction = setTimeout(() => {
            if (this.isEnumRunning && !this._disableTimeout) {
                axiosService
                    .getAxios()
                    .put(
                        'ipcanmodules/cmdEnumBusLpMatrixDisplayV2',
                        {
                            id: this.id,
                            bus: bus,
                            startStop: false,
                            startId: parseInt(startId),
                            stopId: parseInt(stopId),
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(() => {
                        this.isEnumLoading = !this.isEnumLoading;
                        this.isEnumRunning = !this.isEnumRunning;
                        this.hasEnumError = true;
                        enumUpdate();
                    });
            }
        }, 20000);
    }
}
