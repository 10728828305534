import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Input, Modal } from 'rsuite';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import ElementTable from '../../../ReactDataTableComponent/ElementTable';

type Props = {
    display: LPDisplay;
    show: boolean;
    onHide: Function;
    reloadDisplay?: () => void;
} & WrappedComponentProps;

type State = {
    formValue: FormValue;
    isUpdating: boolean;
    hasError: boolean;
    error: any;
};

type FormValue = {
    textValue: TextValue[];
};

type TextValue = {
    index: number;
    lang0: string;
    lang1: string;
    lang2: string;
    lang3: string;
};

class UpdateLPDisplaySystemText extends React.Component<Props, State> {
    columns: TableColumn<RowRecord>[];
    constructor(props: Props) {
        super(props);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplay.systemText.label',
                }),
                center: true,
                cell: row => (
                    <div data-cy="lpDisplay-systemText-edit-label">
                        <FormattedMessage id={'ipCanDevices.lpDisplay.systemText.key'} />
                        <span className="margin-left-5">{row.index + 1}</span>
                    </div>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplay.systemText.lang0',
                }),
                center: true,
                cell: row => (
                    <Input
                        data-cy={`lpDisplay-systemText-edit-lang0-${row.index}`}
                        onChange={value => this.handleChange(row.index, 'lang0', value)}
                        value={this.state.formValue.textValue[row.index]['lang0']}
                        maxLength={32}
                    />
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplay.systemText.lang1',
                }),
                center: true,
                cell: row => (
                    <Input
                        data-cy={`lpDisplay-systemText-edit-lang1-${row.index}`}
                        onChange={value => this.handleChange(row.index, 'lang1', value)}
                        value={this.state.formValue.textValue[row.index]['lang1']}
                        maxLength={32}
                    />
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplay.systemText.lang2',
                }),
                center: true,
                cell: row => (
                    <Input
                        data-cy={`lpDisplay-systemText-edit-lang2-${row.index}`}
                        onChange={value => this.handleChange(row.index, 'lang2', value)}
                        value={this.state.formValue.textValue[row.index]['lang2']}
                        maxLength={32}
                    />
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplay.systemText.lang3',
                }),
                center: true,
                cell: row => (
                    <Input
                        data-cy={`lpDisplay-systemText-edit-lang3-${row.index}`}
                        onChange={value => this.handleChange(row.index, 'lang3', value)}
                        value={this.state.formValue.textValue[row.index]['lang3']}
                        maxLength={32}
                    />
                ),
            },
        ];

        this.state = {
            formValue: {
                textValue: props.display.getTextConfig().getSystemTextEntries(),
            },
            isUpdating: false,
            hasError: false,
            error: '',
        };
    }

    hideModal = () => {
        if (!this.state.isUpdating) {
            this.setState({
                formValue: { textValue: this.props.display.getTextConfig().getSystemTextEntries() },
            });

            this.props.onHide();
        }
    };

    handleChange = (index: number, textNumber: string, value: string) => {
        const textValue = this.state.formValue.textValue;

        textValue[index][textNumber] = value;

        this.setState({
            formValue: {
                textValue,
            },
        });
    };

    handleEditSystemText = () => {
        this.setState({
            isUpdating: true,
            hasError: false,
            error: '',
        });

        let data: { text: string[] }[] = [];

        Object.keys(this.state.formValue.textValue).forEach((_, index) => {
            data.push({
                text: [
                    this.state.formValue.textValue[index].lang0,
                    this.state.formValue.textValue[index].lang1,
                    this.state.formValue.textValue[index].lang2,
                    this.state.formValue.textValue[index].lang3,
                ],
            });
        });

        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixDisplays-v2/updateDictionnaryEntry',
                {
                    id: this.props.display.getId(),
                    dictionnaryEntrys: { entrys: data },
                },
                { headers: authHeader() }
            )
            .then(updateSystemTextsResponse => {
                Alert.success(
                    this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplays.systemText.update.success' }),
                    5000
                );

                this.setState({
                    isUpdating: false,
                });

                this.props.display
                    .getTextConfig()
                    .updateSystemText(updateSystemTextsResponse.data.textConfig.dictionnaryEntrys.entrys);

                if (this.props.reloadDisplay) {
                    this.props.reloadDisplay();
                }

                this.props.onHide();
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                    isUpdating: false,
                });
            });
    };

    render = () => (
        <Modal show={this.props.show} onHide={() => this.hideModal()} full backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="ipCanDevices.lpDisplays.systemText.update" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ElementTable
                    columns={this.columns}
                    data={this.state.formValue.textValue}
                    fixedHeaderScrollHeight="65vh"
                />
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        data-cy="lpDisplay-systemText-update-cancel"
                        onClick={() => this.hideModal()}
                        color="red"
                        loading={this.state.isUpdating}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        data-cy="lpDisplay-systemText-update-valid"
                        onClick={this.handleEditSystemText}
                        appearance="primary"
                        loading={this.state.isUpdating}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

export default injectIntl(UpdateLPDisplaySystemText);
