import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Button, List, Panel } from 'rsuite';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../handlers/lpDisplays/VirtualDisplayStep';
import PanelHeader from '../../../../Custom/PanelHeader';
import { LPVirtualDisplayStepArrow } from '../../../LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepArrow';
import { LPVirtualDisplayStepCounter } from '../../../LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepCounter';
import { LPVirtualDisplayStepDate } from '../../../LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepDate';
import { LPVirtualDisplayStepPicto } from '../../../LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepPicto';
import { LPVirtualDisplayStepPictoRGB } from '../../../LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepPictoRGB';
import { LPVirtualDisplayStepText } from '../../../LPDisplays/VirtualDisplay/Drawer/Steps/LPVirtualDisplayStepText';
import CreateLPVirtualDisplayStepModal from '../../../LPDisplays/VirtualDisplay/Drawer/Steps/Modal/CreateLPVirtualDisplayStepModal';

type Payload = {
    collection: number | string;
    node: Record<string, any>;
    newIndex: number;
    oldIndex: number;
};

type Props = {
    virtualDisplay: VirtualDisplayPreviewInformation;
    display: LPDisplay;
    updateInformation: Function;
    updateStepOrder: Function;
};

const VirtualDisplaySteps = (props: Props) => {
    const intl = useIntl();

    const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

    const handleSortEnd = (payload?: Payload): void => {
        if (payload) {
            props.updateStepOrder(payload.oldIndex, payload.newIndex);
        }
    };

    const generateStepElement = (step: VirtualDisplayStep) => {
        const mode = step.getMode();
        const textModeArray = Array.from({ length: 32 }, (_, i) => i + 201);
        const usertextModeArray = Array.from({ length: 16 }, (_, i) => i + 301);
        const counterModeArray = Array.from({ length: 32 }, (_, i) => i + 101);

        const virtualDisplayBackgroundColor = props.virtualDisplay.backgroundColor;

        if (mode === 1)
            return (
                <LPVirtualDisplayStepArrow
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={props.virtualDisplay}
                    display={props.display}
                    reloadDisplay={() => null}
                    updateInformation={props.updateInformation}
                />
            );
        if (mode === 2)
            return (
                <LPVirtualDisplayStepPicto
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={props.virtualDisplay}
                    display={props.display}
                    reloadDisplay={() => null}
                    updateInformation={props.updateInformation}
                />
            );
        if (mode === 5)
            return (
                <LPVirtualDisplayStepDate
                    step={step}
                    hour
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={props.virtualDisplay}
                    display={props.display}
                    reloadDisplay={() => null}
                    updateInformation={props.updateInformation}
                />
            );
        if (mode === 6)
            return (
                <LPVirtualDisplayStepDate
                    step={step}
                    date
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={props.virtualDisplay}
                    display={props.display}
                    reloadDisplay={() => null}
                    updateInformation={props.updateInformation}
                />
            );
        if (mode === 7)
            return (
                <LPVirtualDisplayStepDate
                    step={step}
                    hour
                    date
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={props.virtualDisplay}
                    display={props.display}
                    reloadDisplay={() => null}
                    updateInformation={props.updateInformation}
                />
            );
        if (mode === 20) {
            return (
                <LPVirtualDisplayStepPictoRGB
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={props.virtualDisplay}
                    reloadDisplay={() => null}
                    display={props.display}
                    updateInformation={props.updateInformation}
                />
            );
        }
        if (counterModeArray.includes(mode))
            return (
                <LPVirtualDisplayStepCounter
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={props.virtualDisplay}
                    display={props.display}
                    reloadDisplay={() => null}
                    updateInformation={props.updateInformation}
                />
            );
        if (textModeArray.includes(mode))
            return (
                <LPVirtualDisplayStepText
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={props.virtualDisplay}
                    display={props.display}
                    reloadDisplay={() => null}
                    updateInformation={props.updateInformation}
                />
            );
        if (usertextModeArray.includes(mode))
            return (
                <LPVirtualDisplayStepText
                    step={step}
                    userText
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={props.virtualDisplay}
                    display={props.display}
                    reloadDisplay={() => null}
                    updateInformation={props.updateInformation}
                />
            );

        return null;
    };

    return (
        <Fragment>
            <CreateLPVirtualDisplayStepModal
                display={props.display}
                onHide={() => setIsCreateModalOpen(false)}
                show={isCreateModalOpen}
                virtualDisplay={props.virtualDisplay}
                updateInformation={props.updateInformation}
            />

            <Panel
                className="panel-small"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({
                            id: 'ipCanDevices.lpDiplay.virtualDisplay.steps',
                        })}
                        buttons={[
                            <Button
                                data-cy="virtualDisplay-steps-add"
                                appearance="primary"
                                size="sm"
                                color="blue"
                                onClick={() => setIsCreateModalOpen(true)}
                                disabled={props.virtualDisplay.steps.length === 8}>
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </Button>,
                        ]}
                        tagValue={`${props.virtualDisplay.steps.length} / 8`}
                    />
                }>
                <List hover sortable={true} onSortEnd={handleSortEnd}>
                    {props.virtualDisplay.steps
                        .filter(step => !step.getIsForcedStep())
                        .map((step, index) => (
                            <List.Item
                                data-cy={`step-${index}`}
                                key={index}
                                className={`panel-list ${step.getState() === 0 && 'list-step-disabled'} ${
                                    step.getState() === 2 && 'list-step-waiting'
                                }`}
                                index={step.getStepOrder()}>
                                {generateStepElement(step)}
                            </List.Item>
                        ))}
                </List>
            </Panel>
        </Fragment>
    );
};

export default VirtualDisplaySteps;
