import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Message, Modal } from 'rsuite';
import ModalFooter from 'rsuite/lib/Modal/ModalFooter';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    closeRestartModal: Function;
    show: boolean;
};

type State = {
    loading: boolean;
    error: boolean;
    showRestartModal: boolean;
    reloading: boolean;
};

export default class RestartModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            showRestartModal: false,
            reloading: false,
        };

        this.restartAutomaton = this.restartAutomaton.bind(this);
    }

    // componentDidMount() {
    //     webSocketService.onConnected(this.connect);
    // }

    restartAutomaton() {
        this.setState({
            loading: true,
            error: false,
        });

        axiosService
            .getAxios()
            .put('/configuration/rebootAutomate', {}, { headers: authHeader() })
            .then(() => {
                this.setState({
                    loading: false,
                    showRestartModal: true,
                });
                this.props.closeRestartModal();
            })
            .catch(() => {
                this.setState({
                    error: true,
                    loading: false,
                });
            });
    }

    render() {
        return (
            <Fragment>
                <Modal
                    full
                    size="lg"
                    show={this.props.show}
                    overflow={false}
                    onHide={() => this.props.closeRestartModal()}>
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage id="configuration.restartModal.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormattedMessage id="configuration.restartModal.message" />
                        {this.state.error && (
                            <Message
                                className="margin-top-15"
                                type="error"
                                description={<FormattedMessage id="configuration.restart.error" />}
                            />
                        )}
                    </Modal.Body>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button
                                onClick={() => this.props.closeRestartModal()}
                                data-cy="configuration.restartModal.cancel"
                                color="red"
                                loading={this.state.loading}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                onClick={this.restartAutomaton}
                                data-cy="configuration.restartModal.restart"
                                appearance="primary"
                                loading={this.state.loading}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}
