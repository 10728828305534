import { faCarSide, faPlusCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Panel } from 'rsuite';
import { placeTypeActions } from '../../redux/actions';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import imageCell from '../Parkings/Table/ImageCell';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import PlaceTypeDrawer from './Drawer/PlaceType';
import CreatePlaceTypeModal from './Modal/PlaceType/CreatePlaceTypeModal';

const mapDispatchToProps = dispatch => ({
    getAllPlaceTypes: () => dispatch(placeTypeActions.getAll()),
    openCreatePlaceTypeModal: () => dispatch(placeTypeActions.openCreateModal()),
    openPlaceTypeDrawer: id => dispatch(placeTypeActions.openViewDrawer(id)),
});

type Props = {
    getAllPlaceTypes: Function;
    placeTypes: Record<string, any>;
    openCreatePlaceTypeModal: Function;
    openPlaceTypeDrawer: Function;
} & WrappedComponentProps;

class PlaceTypeTable extends React.Component<Props> {
    columns: TableColumn<RowRecord>[];

    constructor(props: Props) {
        super(props);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'placeType.image',
                }),
                grow: 1,
                center: true,
                cell: row => imageCell(row.name, row.image, row.imageType),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'placeType.label',
                }),
                grow: 1,
                center: true,
                cell: row => <div data-cy="placeType-label">{row.label}</div>,
            },
        ];
    }

    componentDidMount() {
        this.props.getAllPlaceTypes();
    }

    render() {
        return (
            <Fragment>
                <SecuredFragment authorizedRoles={[rolesConstants.placeType.CREATE]}>
                    <CreatePlaceTypeModal />
                </SecuredFragment>
                <SecuredFragment authorizedRoles={[rolesConstants.placeType.VIEW]}>
                    <PlaceTypeDrawer />
                </SecuredFragment>
                <Panel
                    className="panel-big dashboard-card-header"
                    shaded
                    bordered
                    bodyFill
                    header={
                        <PanelHeader
                            icon={faCarSide}
                            title={this.props.intl.formatMessage({ id: 'placeType.title' })}
                            tagValue={this.props.placeTypes.placeTypesList.length}
                            buttons={[
                                <SecuredFragment
                                    key="placeType-reload"
                                    authorizedRoles={[rolesConstants.parking.VIEW_LIST]}>
                                    <Button
                                        appearance="primary"
                                        size="sm"
                                        onClick={() => this.props.getAllPlaceTypes()}
                                        data-cy="placeType-reloadPlaceType-button">
                                        <FontAwesomeIcon icon={faSyncAlt} className="margin-right-10" />
                                        <FormattedMessage id="placeType.reloadList" />
                                    </Button>
                                </SecuredFragment>,
                                <SecuredFragment key="user-create" authorizedRoles={[rolesConstants.parking.CREATE]}>
                                    <Button
                                        color="red"
                                        size="sm"
                                        onClick={() => this.props.openCreatePlaceTypeModal()}
                                        data-cy="placeType-createPlaceType-button">
                                        <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                        <FormattedMessage id="placeType.addPlaceType" />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }>
                    <ElementTable
                        columns={this.columns}
                        data={this.props.placeTypes.placeTypesList}
                        progressPending={this.props.placeTypes.isLoading}
                        onRowClicked={row => this.props.openPlaceTypeDrawer(row.id)}
                        small={false}
                        data-cy="placeType-table"
                    />
                </Panel>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { placeTypes } = state;

    return { placeTypes };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlaceTypeTable));
