import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ChromePicker } from 'react-color';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    Message,
    Progress,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { authHeader } from '../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../redux/services';

type Props = {
    sensors: Array<number>;
    cancelForce: Function;
    nextStep?: Function;
} & WrappedComponentProps;

type State = {
    isLoading: boolean;
    hasError: boolean;
    error: any | null;
    formValue: FormValue;
    color: {
        r: number;
        g: number;
        b: number;
        a: number;
    };
    forced: Array<number>;
};

type FormValue = {
    isForce: boolean;
    forceType: number;
    // ledMask: number; FF
    // exitLed: boolean; !isForce
    endForceTime: Date;
};

class ForceLPSensorPart extends React.Component<Props, State> {
    forceValues: Array<any>;
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            hasError: false,
            error: null,
            formValue: {
                isForce: false,
                forceType: 1,
                // ledMask: 0,
                // exitLed: false,
                endForceTime: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            },
            color: {
                r: 0,
                g: 0,
                b: 0,
                a: 1,
            },
            forced: [],
        };

        this.forceValues = [
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceFree',
                }),
                value: 1,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceOccupied',
                }),
                value: 2,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceOverstayOccupied',
                }),
                value: 3,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceReserved',
                }),
                value: 4,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceColor',
                }),
                value: 10,
            },
        ];
    }

    sendForce = () => {
        this.setState({
            isLoading: true,
            hasError: false,
            error: null,
        });

        webSocketService.onEvent('lp-sensor:updateStatus', this.updateStatus);

        axiosService
            .getAxios()
            .put(
                '/devices/lp-sensor/forceSensor',
                {
                    tabId: this.props.sensors,
                    isForce: this.state.formValue.isForce,
                    forceType: {
                        forceType: this.state.formValue.isForce ? this.state.formValue.forceType : 0,
                        color: {
                            red:
                                this.state.formValue.isForce && this.state.formValue.forceType === 10
                                    ? this.state.color.r
                                    : 0,
                            green:
                                this.state.formValue.isForce && this.state.formValue.forceType === 10
                                    ? this.state.color.g
                                    : 0,
                            blue:
                                this.state.formValue.isForce && this.state.formValue.forceType === 10
                                    ? this.state.color.b
                                    : 0,
                            ledMask: 0xff,
                            exitLed: !this.state.formValue.isForce,
                        },
                    },
                    endForceTime: this.state.formValue.isForce ? this.state.formValue.endForceTime : new Date(),
                    startForceTime: new Date(),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.setState({
                    isLoading: false,
                });

                if (this.props.nextStep) {
                    this.props.nextStep();
                } else {
                    this.props.cancelForce();
                }
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                    forced: [],
                });

                webSocketService.offEvent('lp-sensor:updateStatus', this.updateStatus);
            });
    };

    updateStatus = e => {
        let forcedArray = this.state.forced;

        if (forcedArray.indexOf(e.id) === -1 && this.props.sensors.indexOf(e.id) !== -1) {
            forcedArray.push(e.id);

            this.setState({
                forced: forcedArray,
            });
        }
    };

    cancel = () => {
        this.props.cancelForce();
    };

    handleChange = formValue => {
        this.setState({
            formValue,
        });
    };

    handleColorChange = color => {
        this.setState({
            color: {
                r: color.rgb.r,
                g: color.rgb.g,
                b: color.rgb.b,
                a: 1,
            },
        });
    };

    render = () => {
        return (
            <FlexboxGrid align="middle" justify="space-around">
                {this.state.hasError && (
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <Message
                            type="error"
                            description={this.props.intl.formatMessage(
                                {
                                    id: 'lpSensors.force.error',
                                },
                                {
                                    error: this.state.error,
                                }
                            )}
                        />
                    </FlexboxGrid.Item>
                )}

                {this.state.isLoading ? (
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <Progress.Line
                            percent={(this.state.forced.length / this.props.sensors.length) * 100}
                            showInfo={false}
                        />

                        <p className="text-center">
                            {this.state.forced.length} / {this.props.sensors.length}
                        </p>
                    </FlexboxGrid.Item>
                ) : (
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <Form formValue={this.state.formValue} onChange={this.handleChange}>
                            <FlexboxGrid justify="center" align="middle">
                                <FlexboxGrid.Item>
                                    <FormControl
                                        data-cy="lpSensor-force-isForced"
                                        name="isForce"
                                        accepter={Toggle}
                                        size="lg"
                                        checkedChildren={this.props.intl.formatMessage({
                                            id: 'lpSensors.force.forced',
                                        })}
                                        unCheckedChildren={this.props.intl.formatMessage({
                                            id: 'lpSensors.force.unforced',
                                        })}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            {this.state.formValue.isForce && (
                                <FlexboxGrid className="margin-top-15">
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <ControlLabel>
                                            <FormattedMessage id="lpSensors.force.type" />
                                            <FormControl
                                                data-cy="lpSensor-force-type"
                                                name="forceType"
                                                accepter={SelectPicker}
                                                data={this.forceValues}
                                                searchable={false}
                                                cleanable={false}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`lpSensors-force-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </ControlLabel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <ControlLabel>
                                            <FormattedMessage id="lpSensors.force.endForceTime" />
                                            <FormControl
                                                name="endForceTime"
                                                accepter={DatePicker}
                                                data={this.forceValues}
                                                format="DD/MM/YYYY HH:mm:ss"
                                                cleanable={false}
                                            />
                                        </ControlLabel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            )}

                            {this.state.formValue.forceType === 10 && (
                                <FlexboxGrid justify="center" align="middle" className="margin-top-10">
                                    <FlexboxGrid.Item>
                                        <ControlLabel>
                                            <FormattedMessage id="lpSensors.force.color" />
                                            <ChromePicker
                                                disableAlpha={true}
                                                onChange={this.handleColorChange}
                                                color={this.state.color}
                                            />
                                        </ControlLabel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            )}

                            <FlexboxGrid justify="end" align="middle">
                                <FlexboxGrid.Item>
                                    <ButtonGroup>
                                        <Button
                                            data-cy="lpSensor-force-cancel"
                                            color="red"
                                            loading={this.state.isLoading}
                                            onClick={() => this.cancel()}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                        <Button
                                            data-cy="lpSensor-force-valid"
                                            color="green"
                                            loading={this.state.isLoading}
                                            onClick={() => this.sendForce()}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </Button>
                                    </ButtonGroup>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Form>
                    </FlexboxGrid.Item>
                )}
            </FlexboxGrid>
        );
    };
}

export default injectIntl(ForceLPSensorPart);
