import { faArrowsAlt, faHistory, faHome, faTrash, faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Alert, Button, Col, Drawer, FlexboxGrid } from 'rsuite';
import Camera from '../../../handlers/Camera/Camera';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import { DeviceStateInformation } from '../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../IpCanElementsPage/Cards/DeviceStateInformation/deviceStateInformationGenerator';
import { DeleteCameraModal } from '../Modal/DeleteCameraModal';
import { EditCameraPosition } from '../Modal/EditCameraPosition';
import { IpCameraHistoryModal } from '../Modal/IpCameraHistoryModal';
import { CameraInformationCard } from './Cards/Information';
import { CameraPictureCard } from './Cards/Picture';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    reloadCameras: () => void;
    camera?: Camera;
};

export const CameraDrawer = (props: Props) => {
    const intl = useIntl();

    const history = useHistory();

    if (!props.camera) {
        return null;
    }

    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

    const [isEditPositionModalOpen, setIsEditPositionModalOpen] = React.useState<boolean>(false);

    const [goToHomeLoading, setGoToHomeLoading] = React.useState<boolean>(false);

    const [historyModalOpen, setHistoryModalOpen] = React.useState<boolean>(false);

    const handleCloseDeleteModal = (deleted: boolean) => {
        if (deleted) {
            props.onHide();
            props.reloadCameras();
        }

        setIsDeleteModalOpen(false);
    };

    const goToHome = () => {
        if (props.camera) {
            setGoToHomeLoading(true);
            axiosService
                .getAxios()
                .put(
                    '/ip-cameras/goToPreset',
                    {
                        id: props.camera.getId(),
                        presetNumber: 1,
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'camera.drawer.goToHome.success' }));
                })
                .catch(err => {
                    console.error(err);
                    Alert.error(intl.formatMessage({ id: 'camera.drawer.goToHome.error' }));
                })
                .finally(() => {
                    setGoToHomeLoading(false);
                });
        }
    };

    return (
        <>
            <DeleteCameraModal cameras={[props.camera]} show={isDeleteModalOpen} onHide={handleCloseDeleteModal} />

            {props.camera && (
                <>
                    <EditCameraPosition
                        isOpen={isEditPositionModalOpen}
                        onHide={() => setIsEditPositionModalOpen(false)}
                        camera={props.camera}
                    />

                    <IpCameraHistoryModal
                        camera={props.camera}
                        onHide={() => setHistoryModalOpen(false)}
                        show={historyModalOpen}
                    />
                </>
            )}

            <Drawer backdrop show={props.isOpen} onHide={props.onHide}>
                <Drawer.Header>
                    <Drawer.Title>
                        {props.camera.getName()} ({props.camera.getIp()})
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className="drawer-body-without-avatar">
                    <CameraPictureCard camera={props.camera} />

                    <CameraInformationCard camera={props.camera} />
                    <SecuredFragment authorizedRoles={[rolesConstants.ipCameras.UPDATE_MAINTENANCE_STATE]}>
                        <DeviceStateInformation
                            device={props.camera}
                            editMaintenanceStateUrl={MaintenanceStateInformationEditURL.IP_CAMERA}
                        />
                    </SecuredFragment>
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <Button
                                block
                                color="blue"
                                onClick={() => setHistoryModalOpen(true)}
                                disabled={!props.camera}>
                                <FontAwesomeIcon icon={faHistory} className="margin-right-5" />
                                <FormattedMessage id="camera.history" />
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <Button
                                block
                                color="orange"
                                onClick={() => history.push(`/cameras/${props.camera?.getId()}`)}
                                disabled={!props.camera}>
                                <FontAwesomeIcon icon={faVectorSquare} className="margin-right-5" />
                                <FormattedMessage id="camera.editPlaces" />
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Drawer.Body>
                <Drawer.Footer>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={8}>
                            <Button color="blue" onClick={goToHome} block loading={goToHomeLoading}>
                                <FontAwesomeIcon icon={faHome} className="margin-right-5" />{' '}
                                {intl.formatMessage({ id: 'camera.goToHome' })}
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <Button color="orange" onClick={() => setIsEditPositionModalOpen(true)} block>
                                <FontAwesomeIcon icon={faArrowsAlt} className="margin-right-5" />{' '}
                                {intl.formatMessage({ id: 'camera.editPosition' })}
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <Button color="red" onClick={() => setIsDeleteModalOpen(true)} block>
                                <FontAwesomeIcon icon={faTrash} className="margin-right-5" />{' '}
                                {intl.formatMessage({ id: 'camera.delete' })}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Drawer.Footer>
            </Drawer>
        </>
    );
};
