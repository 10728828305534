export abstract class MaintenanceInformation {
    private _maintenanceState: MaintenanceState;
    private _notepad: string;

    constructor(maintenanceState: MaintenanceState, notepad: string) {
        this._maintenanceState = maintenanceState;
        this._notepad = notepad;
    }

    getMaintenanceState(): MaintenanceState {
        return this._maintenanceState;
    }

    getNotepad(): string {
        return this._notepad;
    }

    updateMaintenanceInformation(data: MaintenanceInformationInterface): void {
        this._maintenanceState = data.maintenanceState;
        this._notepad = data.notepad;
    }
}

export interface MaintenanceInformationInterface {
    maintenanceState: MaintenanceState;
    notepad: string;
}

export enum MaintenanceState {
    EVERYTHING_OK = 1,
    LOW = 2,
    MEDIUM = 3,
    HIGH = 4,
    VERY_HIGH = 5,
}
