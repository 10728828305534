import React from 'react';
import { RowRecord } from 'react-data-table-component';
import { LPMatrixDisplayV2TableKeyText } from './LPMatrixDisplayV2TableKeyText';

type Props = RowRecord;

export default class LPMatrixDisplayV2Expandable extends React.Component<Props> {
    render = () => {
        if (this.props.data.desc === 'update key text config')
            return <LPMatrixDisplayV2TableKeyText data={this.props.data} />;
        return null;
    };
}
