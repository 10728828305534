import React from 'react';
import { ArrowHeadType, EdgeProps, getBezierPath, getEdgeCenter, getMarkerEnd, Position } from 'react-flow-renderer';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { VirtualDisplayStepTrigger } from '../../../../handlers/lpDisplays/VirtualDisplayStepTrigger';

type State = {
    isDeleting: boolean;
};

type VirtualDisplayStepEdgeProps = {
    trigger: VirtualDisplayStepTrigger;
    type: 'min' | 'max';
};

type Props = EdgeProps<VirtualDisplayStepEdgeProps> & WrappedComponentProps;

// virtualDisplayPosId: number,
// topologyIndex: number,
// deleteEdge: Function,
// display: LPDisplay,

const EdgeVirtualDisplayStepTrigger = (props: Props) => {
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        targetX: props.targetX,
        targetY: props.targetY,
    });

    const foreignObjectSize = 40;

    const edgePath = getBezierPath({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        sourcePosition: props.sourcePosition,
        targetX: props.targetX,
        targetY: props.targetY,
        targetPosition: props.targetPosition,
        // borderRadius: 100,
    });

    const markerEnd = getMarkerEnd(ArrowHeadType.Arrow, props.markerEndId);

    return (
        <>
            <path
                id={props.id}
                style={props.style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />

            <foreignObject
                width={foreignObjectSize}
                height={25}
                x={props.sourceX + (props.sourcePosition === Position.Left ? -50 : 10)}
                y={props.sourceY - 15}
                className="text-center"
                requiredExtensions="http://www.w3.org/1999/xhtml">
                <div style={{ backgroundColor: 'white', fontWeight: 'bolder', borderRadius: 5, fontSize: 16 }}>
                    {props.data?.type === 'min' && `< ${props.data.trigger.getValue()}`}
                    {props.data?.type === 'max' && `> ${props.data.trigger.getValue()}`}
                </div>
            </foreignObject>
        </>
    );
};

export default injectIntl(EdgeVirtualDisplayStepTrigger);
