import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Modal,
    SelectPicker,
    Toggle,
} from 'rsuite';
import TCMDisplay from '../../../../../handlers/ipCanDevices/TCMDisplay';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    id: Array<number>;
    onHide: Function;
    show: boolean;
    forceDisplays?: Function;
    display?: TCMDisplay;
} & WrappedComponentProps;

type State = {
    forceValues: Array<Record<string, any>>;
    isForce: boolean | undefined;
    formValue: Record<string, any>;
    forceType: Record<string, any> | number;
    endForceTime: Date | undefined;
    loading: boolean;
};
class ForceDisplayModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            forceValues: [],
            isForce: this.props.display ? this.props.display.isForce : false,
            formValue: {
                generic: this.props.display ? this.props.display.forceType.generic : 0,
                pmr: this.props.display ? this.props.display.forceType.pmr : 0,
                family: this.props.display ? this.props.display.forceType.family : 0,
                electrical: this.props.display ? this.props.display.forceType.electrical : 0,
            },
            forceType: this.props.display ? this.props.display.forceType.forceType : 0,
            endForceTime: moment().add(1, 'year').set({ second: 0, millisecond: 0 }).toDate(),
            loading: false,
        };

        this.forceDisplays = this.forceDisplays.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            isForce: this.props.display ? this.props.display.isForce : false,
            formValue: {
                generic: this.props.display ? this.props.display.forceType.generic : 0,
                pmr: this.props.display ? this.props.display.forceType.pmr : 0,
                family: this.props.display ? this.props.display.forceType.family : 0,
                electrical: this.props.display ? this.props.display.forceType.electrical : 0,
            },
            forceType: this.props.display ? this.props.display.forceType.forceType : 0,
            endForceTime: moment().add(1, 'year').set({ second: 0, millisecond: 0 }).toDate(),
            loading: false,
        });

        this.props.onHide();
    }

    forceDisplays() {
        const forceValues = {
            forceState: {
                forceType: this.state.forceType,
                generic: parseInt(this.state.formValue.generic),
                pmr: parseInt(this.state.formValue.pmr),
                family: parseInt(this.state.formValue.family),
                electrical: parseInt(this.state.formValue.electrical),
            },
            isForce: this.state.isForce,
            endForceTime: this.state.endForceTime,
        };

        axiosService
            .getAxios()
            .put(
                '/devices/tcm-display/forceDisplay',
                {
                    tabId: this.props.id,
                    isForce: forceValues.isForce,
                    forceState: { ...forceValues.forceState },
                    endForceTime: forceValues.endForceTime,
                    startForceTime: new Date(),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'display.force.success',
                    })
                );

                this.props.onHide();
                // const forceDisplays = forceDisplaysResponse.data;

                // forceDisplays.map(forceDisplay => {
                //     const display = this.props.displays.find(display => forceDisplay.id === display.id);
                //     if (display) display.updateForceDisplay(forceDisplay);
                //     return null;
                // });
            });
    }

    componentDidMount() {
        this.setState({
            forceValues: [
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.force.value',
                    }),
                    value: 1,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.force.relative',
                    }),
                    value: 2,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.force.full',
                    }),
                    value: 3,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.force.close',
                    }),
                    value: 4,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.force.free',
                    }),
                    value: 5,
                },
            ],
        });
    }

    handleChange(formValue) {
        this.setState({ formValue });
    }

    render() {
        return (
            <Modal backdrop={true} show={this.props.show} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="display.force.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="ipcan-elements-sensor-force-modal">
                    <FlexboxGrid justify="center" className="margin-bottom-15">
                        <Toggle
                            data-cy="ipcan-elements-isForce"
                            checked={this.state.isForce}
                            onChange={checked => this.setState({ isForce: checked })}
                            size="lg"
                            checkedChildren={this.props.intl.formatMessage({
                                id: 'display.force.forced',
                            })}
                            unCheckedChildren={this.props.intl.formatMessage({
                                id: 'display.force.unforced',
                            })}
                        />
                    </FlexboxGrid>

                    {this.state.isForce && (
                        <Fragment>
                            <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <SelectPicker
                                        data-cy="ipcan-elements-forceType"
                                        value={this.state.forceType}
                                        block
                                        data={this.state.forceValues}
                                        onChange={value => this.setState({ forceType: value })}
                                        renderMenuItem={(label, item) => {
                                            return <div data-cy={`ipcan-forceType-value-${item.value}`}>{label}</div>;
                                        }}
                                    />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <DatePicker
                                        block
                                        format="DD/MM/YYYY HH:mm"
                                        ranges={[
                                            {
                                                label: this.props.intl.formatMessage({ id: 'calendar.now' }),
                                                value: new Date(),
                                            },
                                        ]}
                                        defaultValue={this.state.endForceTime}
                                        onChange={date => this.setState({ endForceTime: moment(date).toDate() })}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            {(this.state.forceType === 1 || this.state.forceType === 2) && (
                                <Form fluid formValue={this.state.formValue} onChange={this.handleChange}>
                                    <FlexboxGrid className="margin-top-15 margin-bottom-15">
                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    <FormattedMessage id="display.force.generic" />
                                                </ControlLabel>
                                                <FormControl data-cy="ipCanDisplays-force-generic" name="generic" />
                                            </FormGroup>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    <FormattedMessage id="display.force.pmr" />
                                                </ControlLabel>
                                                <FormControl data-cy="ipCanDisplays-force-pmr" name="pmr" />
                                            </FormGroup>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    <FormattedMessage id="display.force.electric" />
                                                </ControlLabel>
                                                <FormControl
                                                    data-cy="ipCanDisplays-force-electrical"
                                                    name="electrical"
                                                />
                                            </FormGroup>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <FormGroup>
                                                <ControlLabel>
                                                    <FormattedMessage id="display.force.family" />
                                                </ControlLabel>
                                                <FormControl data-cy="ipCanDisplays-force-family" name="family" />
                                            </FormGroup>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </Form>
                            )}
                        </Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    onClick={this.closeModal}
                                    color="red"
                                    data-cy="ipcan-elements-displays-force-cancel">
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    onClick={this.forceDisplays}
                                    loading={this.state.loading}
                                    color="green"
                                    data-cy="ipcan-elements-displays-force-valid">
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(ForceDisplayModal);
