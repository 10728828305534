type Props = {
    color: string;
};

export const TaxiIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} x="192" y="32" width="128" height="32" />
            <rect fill={color} x="96" y="480" width="96" height="32" />
            <rect fill={color} x="160" y="96" width="192" height="32" />
            <rect fill={color} x="160" y="352" width="192" height="32" />
            <rect fill={color} x="32" y="256" width="448" height="32" />
            <rect fill={color} x="32" y="416" width="448" height="32" />
            <g>
                <rect fill={color} x="192" y="32" width="32" height="96" />
                <rect fill={color} x="96" y="416" width="32" height="96" />
                <rect fill={color} x="160" y="416" width="32" height="96" />
                <rect fill={color} x="320" y="480" width="96" height="32" />
                <rect fill={color} x="320" y="416" width="32" height="96" />
                <rect fill={color} x="384" y="416" width="32" height="96" />
                <rect fill={color} x="288" y="32" width="32" height="96" />
            </g>
            <g>
                <rect fill={color} x="384" y="160" width="32" height="128" />
                <rect fill={color} x="96" y="160" width="32" height="128" />
                <rect fill={color} y="288" width="32" height="128" />
                <rect fill={color} x="480" y="288" width="32" height="128" />
            </g>
            <g>
                <rect fill={color} x="352" y="128" width="32" height="32" />
                <rect fill={color} x="128" y="128" width="32" height="32" />
                <rect fill={color} x="64" y="320" width="32" height="32" />
                <rect fill={color} x="416" y="320" width="32" height="32" />
            </g>
        </g>
    </svg>
);
