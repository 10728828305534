import moment from 'moment';
import PlaceType from '../PlaceType';

export function updateSensorPlaceTypeHandler(sensorPlaceType) {
    let placeType = new PlaceType(sensorPlaceType.placeType);

    return {
        createdAt: moment(sensorPlaceType.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: sensorPlaceType.createdBy,
        updatedBy: sensorPlaceType.updatedBy,
        updatedAt: moment(sensorPlaceType.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        id: sensorPlaceType.id,
        label: sensorPlaceType.label,
        description: sensorPlaceType.description,
        placeType: placeType.getPlaceType(),
    };
}

// function sceneHandler(scene) {
//     return {
//         "id": scene.id,
//         "led1": scene.led1,
//         "led2": scene.led2,
//         "led3": scene.led3,
//         "led4": scene.led4,
//         "led5": scene.led5,
//         "led6": scene.led6,
//         "color": scene.color,
//         "state": scene.state,
//         "time": scene.time,
//         "order": scene.order,
//         "createdAt": moment(scene.createdAt).format('DD/MM/YYYY HH:mm:ss'),
//         "updatedAt": moment(scene.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
//         "createdBy": scene.createdAt,
//         "updatedBy": scene.updatedBy
//     }
// }
