import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Modal, Steps } from 'rsuite';
import ForceLPSensorPart from './Force/ForceLPSensorPart';
import ForcePlaceCameraSensorPart from './Force/ForcePlaceCameraSensorPart';
import ForceTCMSensorPart from './Force/ForceTCMSensorPart';

type Props = {
    lpSensors: Array<number>;
    tcmSensors: Array<number>;
    placeCameraSensors: Array<number>;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    currentStep: number;
};

class MapForceSensorsModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let currentStep = 0;

        if (props.tcmSensors.length === 0) {
            currentStep = 1;

            if (props.lpSensors.length === 0) {
                currentStep = 2;
            }
        }

        this.state = {
            currentStep,
        };
    }

    componentDidUpdate = (prevProps: Props) => {
        if (
            prevProps.tcmSensors !== this.props.tcmSensors ||
            prevProps.lpSensors !== this.props.lpSensors ||
            prevProps.placeCameraSensors !== this.props.placeCameraSensors
        ) {
            let currentStep = 0;

            if (this.props.tcmSensors.length === 0) {
                currentStep = 1;

                if (this.props.lpSensors.length === 0) {
                    currentStep = 2;
                }
            }

            this.setState({
                currentStep,
            });
        }
    };

    nextStep = () => {
        const currentStep = this.state.currentStep;
        let newStep = currentStep + 1;

        if (newStep === 1 && this.props.lpSensors.length === 0) {
            newStep = 2;
        }

        if (newStep === 2 && this.props.placeCameraSensors.length === 0) {
            this.props.onHide();
        }

        if (newStep > 2) {
            newStep = 0;
            this.props.onHide();
        }

        this.setState({
            currentStep: newStep,
        });
    };

    getTCMSensorStatus = (): 'finish' | 'wait' | 'process' | 'error' => {
        if (this.props.tcmSensors.length === 0) return 'error';

        if (this.state.currentStep === 0) {
            return 'process';
        } else {
            return 'finish';
        }
    };

    getLPSensorStatus = (): 'finish' | 'wait' | 'process' | 'error' => {
        if (this.props.lpSensors.length === 0) return 'error';

        if (this.state.currentStep === 1) {
            return 'process';
        }

        if (this.state.currentStep > 1) {
            return 'finish';
        } else {
            return 'wait';
        }
    };

    getPlaceCameraSensorStatus = (): 'finish' | 'wait' | 'process' | 'error' => {
        if (this.props.placeCameraSensors.length === 0) return 'error';

        if (this.state.currentStep === 2) {
            return 'process';
        }

        if (this.state.currentStep > 2) {
            return 'finish';
        } else {
            return 'wait';
        }
    };

    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="map.sensors.forceSensors" />
                    </Modal.Title>
                    <Modal.Body style={{ maxHeight: 600 }}>
                        <Fragment>
                            <Steps current={this.state.currentStep} className="margin-bottom-10">
                                <Steps.Item
                                    title={this.props.intl.formatMessage({ id: 'map.forceSensor.tcmSensor' })}
                                    status={this.getTCMSensorStatus()}
                                />

                                <Steps.Item
                                    title={this.props.intl.formatMessage({ id: 'map.forceSensor.lpSensor' })}
                                    status={this.getLPSensorStatus()}
                                />

                                <Steps.Item
                                    title={this.props.intl.formatMessage({
                                        id: 'map.forceSensor.placeCameraSensor',
                                    })}
                                    status={this.getPlaceCameraSensorStatus()}
                                />
                            </Steps>

                            {this.state.currentStep === 0 && (
                                <ForceTCMSensorPart
                                    sensors={this.props.tcmSensors}
                                    cancelForce={this.props.onHide}
                                    nextStep={this.nextStep}
                                />
                            )}

                            {this.state.currentStep === 1 && (
                                <ForceLPSensorPart
                                    sensors={this.props.lpSensors}
                                    cancelForce={this.props.onHide}
                                    nextStep={this.nextStep}
                                />
                            )}

                            {this.state.currentStep === 2 && (
                                <ForcePlaceCameraSensorPart
                                    sensors={this.props.placeCameraSensors}
                                    cancelForce={this.props.onHide}
                                />
                            )}
                        </Fragment>
                    </Modal.Body>
                </Modal.Header>
            </Modal>
        );
    }
}

export default injectIntl(MapForceSensorsModal);
