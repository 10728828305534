export const LPVirtualDisplayLanguages = (language: number) => {
    switch (language) {
        case 1:
            return 'FR';
        case 2:
            return 'LANG 1';
        case 4:
            return 'LANG 2';
        case 8:
            return 'LANG 3';
        default:
            return null;
    }
};
