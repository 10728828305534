import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';
import SecuredFragment from '../../../../../Auth/SecuredFragment';
import PanelHeader from '../../../../../Custom/PanelHeader';
import { MapTCMEditDeviceStateInformation } from './MapTCMEditDeviceStateInformation';
import { MapTCMViewDeviceStateInformation } from './MapTCMViewDeviceStateInformation';

type Props = {
    device: any;
    service: any;
};

export const MapTCMDeviceStateInformation = (props: Props) => {
    const intl = useIntl();
    const [isEditMode, setIsEditMode] = React.useState(false);

    return (
        <SecuredFragment authorizedRoles={[]}>
            <Panel
                className="panel-small"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.title' })}
                        buttons={[
                            <Button
                                key="maintenanceInformation-edit"
                                color="blue"
                                size="sm"
                                disabled={isEditMode}
                                onClick={() => setIsEditMode(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>,
                        ]}
                    />
                }>
                {isEditMode ? (
                    <MapTCMEditDeviceStateInformation
                        device={props.device}
                        setIsEditMode={setIsEditMode}
                        service={props.service}
                    />
                ) : (
                    <MapTCMViewDeviceStateInformation device={props.device} />
                )}
            </Panel>
        </SecuredFragment>
    );
};
