import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Drawer } from 'rsuite';
import PlaceCamera from '../../../handlers/Camera/PlaceCamera';
import { DeviceStateInformation } from '../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../IpCanElementsPage/Cards/DeviceStateInformation/deviceStateInformationGenerator';
import { PlaceCameraHistoryModal } from '../Modal/PlaceCameraHistoryModal';
import { HyperParamPlaceCamera } from './HyperParamPlaceCamera/HyperParamPlaceCamera';
import { CameraPlaceViewInformation } from './Information/CameraPlaceViewInformation';

type Props = {
    show: boolean;
    onHide: () => void;
    place?: PlaceCamera;
    reloadPlaces: () => void;
};

export const CameraPlaceDrawer = (props: Props) => {
    const [historyModalOpen, setHistoryModalOpen] = React.useState<boolean>(false);

    if (!props.place) return null;

    return (
        <>
            <PlaceCameraHistoryModal
                placeCamera={props.place}
                show={historyModalOpen}
                onHide={() => setHistoryModalOpen(false)}
            />

            <Drawer backdrop="static" show={props.show} onHide={props.onHide}>
                <Drawer.Header>
                    <Drawer.Title>
                        <FormattedMessage id="cameras.placeCamera.title" /> - {props.place.getIdPlace()} -{' '}
                        {props.place.getLabel()}
                    </Drawer.Title>
                </Drawer.Header>

                <Drawer.Body className="drawer-body-without-avatar">
                    <CameraPlaceViewInformation place={props.place} reloadPlaces={props.reloadPlaces} />

                    <DeviceStateInformation
                        device={props.place}
                        editMaintenanceStateUrl={MaintenanceStateInformationEditURL.IP_CAMERA_PLACE}
                    />

                    <HyperParamPlaceCamera place={props.place} reloadPlaces={props.reloadPlaces} />
                </Drawer.Body>
                <Drawer.Footer>
                    <Button color="violet" onClick={() => setHistoryModalOpen(true)}>
                        <FontAwesomeIcon icon={faHistory} className="margin-right-5" />
                        <FormattedMessage id="cameras.placeCamera.history" />
                    </Button>
                </Drawer.Footer>
            </Drawer>
        </>
    );
};
