import { useEffect, useState } from 'react';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { VersionLine } from '../VersionTable';

export function useVersionFromAPIHook(type: VersionEnum) {
    const [apiVersion, setApiVersion] = useState<VersionLine>({
        appName: type,
        version: '-',
        dateTag: '-',
        isConnected: false,
        startDate: '-',
        versionTag: '-',
        isActiv: false,
    });

    useEffect(() => {
        let url = '';
        let name = '';

        if (type === VersionEnum.API) {
            url = '/configuration/status/api';
            name = 'API';
        }
        if (type === VersionEnum.ENGINE) {
            url = '/configuration/status/engine';
            name = 'Engine';
        }

        if (url !== '') {
            axiosService
                .getAxios()
                .get(url, {
                    headers: authHeader(),
                })
                .then(config => {
                    setApiVersion({
                        appName: name,
                        isConnected: config.data.isConnect,
                        version: config.data.version,
                        versionTag: config.data.buildTag,
                        dateTag: config.data.buildDate,
                        startDate: config.data.startDate,
                        isActiv: config.data.isActiv,
                    });
                });
        }
    }, []);

    return apiVersion;
}

export enum VersionEnum {
    API = 'API',
    ENGINE = 'ENGINE',
}
