import { placeCameraUpdateHyperParamConfigDesc } from './Desc/PlaceCameraUpdateHyperParamConfigDesc';
import { placeCameraUpdateConfigDesc } from './Desc/PlaceCameraUpdateHyperParamConfigDesc copy';
import ForcePlaceCameraDesc from './Option/ForcePlaceCameraDesc';
import NoOptions from './Option/noOptions';
import updateGeoJSON from './Option/updateGeoJSON';
import updateMaintenanceState from './Option/updateMaintenanceState';

// wait for online, total and db as data
// wait for controler online or not

export default function PlaceCameraOptionCell(desc, option) {
    switch (desc) {
        case 'force state':
            return <ForcePlaceCameraDesc option={option} />;
        case 'update geojson':
            return updateGeoJSON(option);
        case 'update hyper param config':
            return placeCameraUpdateHyperParamConfigDesc(option);
        case 'update maintenance state':
            return updateMaintenanceState(option);
        case 'update basic conf':
            return placeCameraUpdateConfigDesc(option);
        default:
            return NoOptions();
    }
}
