import handleError from '../../handlers/error.handler';
import { sceneSensorPlaceTypeHandler } from '../../handlers/sceneSensorPlaceType.handler';
import { authHeader } from '../helpers';
import { axiosService } from './axios.services';

export const sceneSensorPlaceTypeService = {
    getScenes,
    getById,
    create,
    update,
    updateOrder,
    remove: remove,
};

function getScenes(sensorPlaceTypeId) {
    return axiosService
        .getAxios()
        .get('/scene-sensor-place-type', { headers: authHeader() })
        .then(response => response.data.map(sensorPlaceType => sceneSensorPlaceTypeHandler(sensorPlaceType)))
        .then(scenesSensorPlaceType => {
            let scenesSorted: Array<Record<string, any>> = [[], [], [], []];

            let scenes = scenesSensorPlaceType.filter(
                sceneSensorPlaceType => sceneSensorPlaceType.sensorPlaceType.id === sensorPlaceTypeId
            );

            for (let i = 0; i < scenes.length; i++) {
                scenesSorted[scenes[i].state - 1].push(scenes[i]);
            }

            return scenesSorted;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function getById(id) {
    return axiosService
        .getAxios()
        .get(`/scene-sensor-place-type/${id}`, { headers: authHeader() })
        .then(response => {
            return sceneSensorPlaceTypeHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function create(led1: boolean, led2: boolean, led3: boolean, led4: boolean, led5: boolean, led6: boolean, color: string, time: number, state: number, sensorPlaceTypeId: number) {
    const parameters = { headers: authHeader() };
    const data = {
        led1,
        led2,
        led3,
        led4,
        led5,
        led6,
        color: color.toUpperCase(),
        time,
        state,
        sensorPlaceTypeId,
    };
    return axiosService
        .getAxios()
        .post('/scene-sensor-place-type', data, parameters)
        .then(response => {
            return sceneSensorPlaceTypeHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function update(id, led1, led2, led3, led4, led5, led6, color, time, state, sensorPlaceTypeId) {
    const parameters = { headers: authHeader() };
    const data = { id, led1, led2, led3, led4, led5, led6, color, time, state, sensorPlaceTypeId };

    return axiosService
        .getAxios()
        .put('/scene-sensor-place-type', data, parameters)
        .then(response => {
            return sceneSensorPlaceTypeHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateOrder(tabId) {
    const parameters = { headers: authHeader() };
    const data = { tabId };

    return axiosService
        .getAxios()
        .put('/scene-sensor-place-type/scenesOrder', data, parameters)
        .then(response => {
            return response.data.map(sceneSensorPlaceType => sceneSensorPlaceTypeHandler(sceneSensorPlaceType));
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function remove(id) {
    const data = {
        headers: authHeader(),
        data: { id: id },
    };
    return axiosService
        .getAxios()
        .delete('/scene-sensor-place-type', data)
        .then(response => {
            return response.data.id;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}
