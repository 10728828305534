import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Table, TableCellProps, Toggle } from 'rsuite';

type Props = {
    editMode: boolean;
    dataKey: string;
} & TableCellProps;

type State = {
    enabled: boolean;
};
export default class EditableSwitchCell extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            enabled: props.rowData[props.dataKey],
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({
            enabled: e,
        });

        this.props.onChange(this.props.rowData?.['name'], this.props.dataKey, e);
    }

    render() {
        let { editMode, ...props } = this.props;

        return (
            <Table.Cell {...props}>
                {editMode ? (
                    <Toggle
                        size="md"
                        className="no-padding-top"
                        defaultChecked={props.rowData?.[props.dataKey]}
                        onChange={this.onChange}
                        name={props.rowData?.['name'] + '_' + props.dataKey}>
                        {' '}
                    </Toggle>
                ) : (
                    <FontAwesomeIcon
                        icon={faCircle}
                        style={{
                            color: this.state.enabled ? 'green' : 'red',
                        }}
                    />
                )}
            </Table.Cell>
        );
    }
}
