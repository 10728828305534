import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Alert, Button, List, Panel } from 'rsuite';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplay } from '../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';
import { LPVirtualDisplayStepArrow } from './Steps/LPVirtualDisplayStepArrow';
import { LPVirtualDisplayStepCounter } from './Steps/LPVirtualDisplayStepCounter';
import { LPVirtualDisplayStepDate } from './Steps/LPVirtualDisplayStepDate';
import { LPVirtualDisplayStepPicto } from './Steps/LPVirtualDisplayStepPicto';
import { LPVirtualDisplayStepPictoRGB } from './Steps/LPVirtualDisplayStepPictoRGB';
import { LPVirtualDisplayStepText } from './Steps/LPVirtualDisplayStepText';
import CreateLPVirtualDisplayStepModal from './Steps/Modal/CreateLPVirtualDisplayStepModal';

type Props = {
    virtualDisplay: VirtualDisplay;
    display: LPDisplay;
    reloadDisplay: Function;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    isUpdatingOrder: boolean;
    isCreateStepModalOpen: boolean;
};

type Payload = {
    collection: number | string;
    node: Record<string, any>;
    newIndex: number;
    oldIndex: number;
};

class LPVirtualDisplaySteps extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isUpdatingOrder: false,
            isCreateStepModalOpen: false,
        };
    }

    handleSortEnd = (payload?: Payload): void => {
        if (payload) {
            this.setState({
                isUpdatingOrder: true,
            });

            const moveData = this.props.virtualDisplay.getSteps().splice(payload.oldIndex, 1);
            const newData = [...this.props.virtualDisplay.getSteps()];
            newData.splice(payload.newIndex, 0, moveData[0]);

            axiosService
                .getAxios()
                .put(
                    '/devices/lp-matrixdisplays-v2/virtualDisplay/step/orders',
                    {
                        id_virtualDisplay: this.props.virtualDisplay.getId(),
                        tabStepOrder: newData.map(data => data.getStepOrder()),
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(
                        this.props.intl.formatMessage({
                            id: 'ipCanDevices.lpDisplay.virtualDisplay.step.reorder.valid',
                        })
                    );
                    this.props.reloadDisplay();
                })
                .catch(err => {
                    Alert.error(
                        this.props.intl.formatMessage(
                            {
                                id: 'ipCanDevices.lpDisplay.virtualDisplay.step.reorder.error',
                            },
                            { err }
                        )
                    );

                    this.props.reloadDisplay();
                })
                .finally(() => {
                    this.setState({
                        isUpdatingOrder: false,
                    });
                });
        }
    };

    openCreateStepModal = () => {
        this.setState({
            isCreateStepModalOpen: true,
        });
    };

    closeCreateStepModal = (shouldReload: boolean = false) => {
        this.setState({
            isCreateStepModalOpen: false,
        });

        if (shouldReload) {
            this.props.reloadDisplay();
        }
    };

    generateStepElement = (step: VirtualDisplayStep) => {
        const mode = step.getMode();
        const textModeArray = Array.from({ length: 32 }, (_, i) => i + 201);
        const usertextModeArray = Array.from({ length: 16 }, (_, i) => i + 301);
        const counterModeArray = Array.from({ length: 32 }, (_, i) => i + 101);

        const virtualDisplayBackgroundColor = this.props.virtualDisplay.getBackgroundColor();

        if (mode === 1)
            return (
                <LPVirtualDisplayStepArrow
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    reloadDisplay={this.props.reloadDisplay}
                    display={this.props.display}
                    updateInformation={() => null}
                />
            );
        if (mode === 2)
            return (
                <LPVirtualDisplayStepPicto
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    reloadDisplay={this.props.reloadDisplay}
                    display={this.props.display}
                    updateInformation={() => null}
                />
            );
        if (mode === 5)
            return (
                <LPVirtualDisplayStepDate
                    step={step}
                    hour
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    reloadDisplay={this.props.reloadDisplay}
                    display={this.props.display}
                    updateInformation={() => null}
                />
            );
        if (mode === 6)
            return (
                <LPVirtualDisplayStepDate
                    step={step}
                    date
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    reloadDisplay={this.props.reloadDisplay}
                    display={this.props.display}
                    updateInformation={() => null}
                />
            );
        if (mode === 7) {
            return (
                <LPVirtualDisplayStepDate
                    step={step}
                    hour
                    date
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    reloadDisplay={this.props.reloadDisplay}
                    display={this.props.display}
                    updateInformation={() => null}
                />
            );
        }
        if (mode === 20) {
            return (
                <LPVirtualDisplayStepPictoRGB
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    reloadDisplay={this.props.reloadDisplay}
                    display={this.props.display}
                    updateInformation={() => null}
                />
            );
        }

        if (counterModeArray.includes(mode))
            return (
                <LPVirtualDisplayStepCounter
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    reloadDisplay={this.props.reloadDisplay}
                    display={this.props.display}
                    updateInformation={() => null}
                />
            );
        if (textModeArray.includes(mode))
            return (
                <LPVirtualDisplayStepText
                    step={step}
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    reloadDisplay={this.props.reloadDisplay}
                    display={this.props.display}
                    updateInformation={() => null}
                />
            );
        if (usertextModeArray.includes(mode))
            return (
                <LPVirtualDisplayStepText
                    step={step}
                    userText
                    backgroundColor={virtualDisplayBackgroundColor}
                    virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    reloadDisplay={this.props.reloadDisplay}
                    display={this.props.display}
                    updateInformation={() => null}
                />
            );

        return null;
    };

    render = () => (
        <Fragment>
            <CreateLPVirtualDisplayStepModal
                show={this.state.isCreateStepModalOpen}
                onHide={this.closeCreateStepModal}
                virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                display={this.props.display}
                updateInformation={() => null}
            />
            <Panel
                data-cy="lpDisplay-steps-card"
                className="panel-small"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({
                            id: 'ipCanDevices.lpDisplay.virtualDisplay.steps',
                        })}
                        buttons={[
                            <Button
                                appearance="primary"
                                size="sm"
                                color="blue"
                                disabled={this.props.virtualDisplay.getSteps().length >= 8}
                                onClick={this.openCreateStepModal}>
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </Button>,
                        ]}
                        tagValue={`${this.props.virtualDisplay.getSteps().length} / 8`}
                        tagColor={'blue'}
                    />
                }>
                <Loader show={this.props.isLoading || this.state.isUpdatingOrder}>
                    <List sortable onSort={this.handleSortEnd}>
                        {this.props.virtualDisplay.getSteps().map((step, index) => (
                            <List.Item
                                className={`panel-list ${step.getState() === 0 && 'list-step-disabled'} ${
                                    step.getState() === 2 && 'list-step-waiting'
                                }`}
                                index={step.getStepOrder()}
                                key={`virtualDisplay-${this.props.virtualDisplay.getId()}-${index}`}>
                                {this.generateStepElement(step)}
                            </List.Item>
                        ))}
                    </List>
                </Loader>
            </Panel>
        </Fragment>
    );
}

export default injectIntl(LPVirtualDisplaySteps);
