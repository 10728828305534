import { LPVirtualDisplayColors } from '../../../../../handlers/ipCanDevices/const/colors';
import { ActionObserver, ActionObserverEvent } from '../Observers/ActionObserver';
import FlowEdge from './FlowEdge';

export class FlowEdgeWithDeleteButton extends FlowEdge {
    _topologyIndex: number;
    _virtualDisplayIndex: number;
    _virtualDisplayId: number;

    constructor(
        source: string,
        sourceHandle: string,
        target: string,
        targetHandle: string,
        topologyIndex: number,
        virtualDisplayIndex: number,
        virtualDisplayId: number
    ) {
        super(source, sourceHandle, target, targetHandle, LPVirtualDisplayColors[topologyIndex]);

        this._topologyIndex = topologyIndex;
        this._virtualDisplayIndex = virtualDisplayIndex;
        this._virtualDisplayId = virtualDisplayId;
    }

    public handleButtonClick = () => {
        ActionObserver.emit(ActionObserverEvent.REMOVE_VIRTUAL_DISPLAY_FROM_TOPOLOGY, {
            virtualDisplayId: this._virtualDisplayId,
            topologyIndex: this._topologyIndex,
        });
        // this.toggleLoading();
    };

    // private deleteEdge = () => {
    //     console.log('DELETING EDGE');

    //     this.toggleLoading();

    //     axiosService.getAxios().put(`/devices/lp-matrixdisplays-v2/updateTopologysConfig`, {
    //         id: 1, // TODO: Change to LPDisplay id
    //     })
    // };

    // private toggleLoading = () => {
    //     console.log(!this._isLoading);
    //     this._isLoading = !this._isLoading;
    // };

    public toRender = () => {
        return {
            id: `topology-${this._topologyIndex}_virtual-display-${this._virtualDisplayIndex}`,
            source: this._source,
            sourceHandle: this._sourceHandle,
            target: this._target,
            targetHandle: this._targetHandle,
            type: 'edgeWithButton',
            animated: true,
            data: {
                handleButtonClick: this.handleButtonClick,
                // isLoading: this._isLoading,
            },
            style: {
                stroke: this._color,
                strokeWidth: 3,
            },
        };
    };
}
