import React from 'react';
import { Tag, TagGroup } from 'rsuite';

const lang = {
    1: 'PL',
    2: 'ES',
    8: 'FR',
    16: 'EN',
    32: 'PT',
    64: 'NL',
    128: 'DE',
};

type Props = { value: number; noTag?: boolean };
type State = { tags: Array<string> };

export default class Languages extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            tags: [],
        };
    }

    componentDidMount() {
        if (this.props.value !== 0) {
            let tags: Array<string> = [];
            let v = this.props.value;

            for (let i = 1; i <= 128; i <<= 1) {
                if ((v & i) !== 0) {
                    tags.push(lang[i]);
                }
            }

            this.setState({
                tags,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            if (this.props.value !== 0) {
                let tags: Array<string> = [];
                let v = this.props.value;

                for (let i = 1; i <= 128; i <<= 1) {
                    if ((v & i) !== 0) {
                        tags.push(lang[i]);
                    }
                }

                this.setState({
                    tags,
                });
            } else {
                this.setState({
                    tags: [],
                });
            }
        }
    }

    render() {
        if (this.props.noTag) {
            return this.state.tags.map(tag => {
                return (
                    <span key={tag} className="margin-right-5">
                        {tag}
                    </span>
                );
            });
        } else {
            return (
                <TagGroup data-tag="allowRowEvents">
                    {this.state.tags.map(tag => {
                        return (
                            <Tag key={tag} data-cy="display-language" data-tag="allowRowEvents" color="blue">
                                <span data-tag="allowRowEvents">{tag}</span>
                            </Tag>
                        );
                    })}
                </TagGroup>
            );
        }
    }
}
