import { useIntl } from 'react-intl';
import { Message } from 'rsuite';
import { StepTaskData, StepTypeEnum } from '.';
import Task from '../../../../handlers/Event/Task';
import { TaskTypeEnum, translatedTaskTypes } from '../Tasks/CreateTaskType';
import { LPMatrixDisplayV2ForceBlackStepType } from './StepTypes/LPMatrixDisplayV2ForceBlackStepType';
import { LPMatrixDisplayV2ForceStepType } from './StepTypes/LPMatrixDisplayV2ForceStepType';
import { LPMatrixDisplayV2UnforceStepType } from './StepTypes/LPMatrixDisplayV2UnforceStepType';
import { LPSensorForceColorStepType } from './StepTypes/LPSensorForceColorStepType';
import { LPSensorUnforceColorStepType } from './StepTypes/LPSensorUnforceColorStepType';
import { SetGpioStepType } from './StepTypes/SetGpioStepType';
import { TCMDisplayForceStepType } from './StepTypes/TCMDisplayForceStepType';
import { TCMDisplayUnforceStepType } from './StepTypes/TCMDisplayUnforceStepType';
import { TCMSensorForceColorStepType } from './StepTypes/TCMSensorForceColorStepType';
import { TCMSensorUnforceColorStepType } from './StepTypes/TCMSensorUnforceColorStepType';

type Props = {
    onClickBack: () => void;
    currentTask: StepTaskData;
    closeModal: () => void;
    type: StepTypeEnum;
    existingTask?: Task;
    onClickNext: (lpDisplay: number) => void;
};

export function SecondStep(props: Props) {
    const intl = useIntl();

    const generateElement = () => {
        switch (props.currentTask.type) {
            case TaskTypeEnum.TCM_SENSOR_FORCE_COLOR:
                return (
                    <TCMSensorForceColorStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                    />
                );
            case TaskTypeEnum.TCM_SENSOR_DISABLE_FORCE:
                return (
                    <TCMSensorUnforceColorStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                    />
                );
            case TaskTypeEnum.TCM_DISPLAY_FORCE:
                return (
                    <TCMDisplayForceStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                    />
                );
            case TaskTypeEnum.TCM_DISPLAY_DISABLE_FORCE:
                return (
                    <TCMDisplayUnforceStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                    />
                );
            case TaskTypeEnum.LP_SENSOR_FORCE_COLOR:
                return (
                    <LPSensorForceColorStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                    />
                );
            case TaskTypeEnum.LP_SENSOR_DISABLE_FORCE:
                return (
                    <LPSensorUnforceColorStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                    />
                );
            case TaskTypeEnum.LP_MATRIX_DISPLAY_V2_ENABLE_FORCE:
                return (
                    <LPMatrixDisplayV2ForceStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                        onClickNext={props.onClickNext}
                    />
                );
            case TaskTypeEnum.LP_MATRIX_DISPLAY_V2_BLACK_FORCE:
                return (
                    <LPMatrixDisplayV2ForceBlackStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                    />
                );
            case TaskTypeEnum.LP_MATRIX_DISPLAY_V2_DISABLE_FORCE:
                return (
                    <LPMatrixDisplayV2UnforceStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                    />
                );
            case TaskTypeEnum.FORCE_IPCAN_GPIO:
                return (
                    <SetGpioStepType
                        onClickBack={props.onClickBack}
                        currentTask={props.currentTask}
                        closeModal={props.closeModal}
                        type={props.type}
                        existingTask={props.existingTask}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="margin-top-15">
            <Message description={translatedTaskTypes(intl, props.currentTask.type)} />

            <div className="margin-top-15">{generateElement()}</div>
        </div>
    );
}
