import { Scene } from '../scene/Scene';
import { MapDeviceBase } from './MapDeviceBase';

export class MapSensorBase extends MapDeviceBase {
    _placeType: any;
    constructor(device_, placeType_) {
        super(device_);

        this._placeType = placeType_;
    }

    getScenes(): [Scene, Scene, Scene, Scene] {
        let steps: [Scene, Scene, Scene, Scene] = [
            new Scene(this._placeType.scenes.filter(placeType => placeType.state === 1)),
            new Scene(this._placeType.scenes.filter(placeType => placeType.state === 2)),
            new Scene(this._placeType.scenes.filter(placeType => placeType.state === 3)),
            new Scene(this._placeType.scenes.filter(placeType => placeType.state === 4)),
        ];

        return steps;
    }

    setSensorPlaceType(placeType_) {
        this._placeType = placeType_;
    }
}
