import React from 'react';
import ForceDisplay from './Option/ForceDisplay';
import NoOptions from './Option/noOptions';
import readRealConfigOptions from './Option/readRealConfigOptions';
import UpdateConfig from './Option/UpdateConfig';
import updateCounters from './Option/updateCounters';
import UpdateCountersAssign from './Option/UpdateCountersAssign';
import updateMaintenanceState from './Option/updateMaintenanceState';
import updateSubstractCounters from './Option/updateSubstractCounters';

export default function TCMDisplayOptionCell(desc, option) {
    switch (desc) {
        case 'update config':
            return UpdateConfig(option);
        case 'update counters assign':
            return <UpdateCountersAssign option={option} />;
        case 'update counters':
            return updateCounters(option);
        case 'update substract counters':
            return updateSubstractCounters(option);
        case 'read real sensor config':
            return readRealConfigOptions(option);
        case 'force display':
            return <ForceDisplay option={option} />;
        case 'update maintenance state':
            return updateMaintenanceState(option);
        default:
            return NoOptions();
    }
}
