import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function updateFilterConfig(option) {
    const { nbSalveMin, nbSalveMax, nbInterSalveMin, nbInterSalveMax, maskFree, maskOccupied } = option;

    return (
        <div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.nbSalveMin" /> :
                </b>{' '}
                {nbSalveMin}
            </div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.nbSalveMax" /> :
                </b>{' '}
                {nbSalveMax}
            </div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.nbInterSalveMin" /> :
                </b>{' '}
                {nbInterSalveMin}
            </div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.nbInterSalveMax" /> :
                </b>{' '}
                {nbInterSalveMax}
            </div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.maskFree" /> :
                </b>{' '}
                {maskFree}
            </div>
            <div>
                <b>
                    <FormattedMessage id="lpSensor.history.maskOccupied" /> :
                </b>{' '}
                {maskOccupied}
            </div>
        </div>
    );
}
