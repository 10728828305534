import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Tag } from 'rsuite';
type Props = {
    value: Record<string, any>;
    noTag?: boolean;
} & WrappedComponentProps;

type State = {
    forceType: Record<string, any>;
};
class ForceType extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            forceType: {
                0: this.props.intl.formatMessage({
                    id: 'display.force.noForce',
                }),
                1: this.props.intl.formatMessage({
                    id: 'display.force.value',
                }),
                2: this.props.intl.formatMessage({
                    id: 'display.force.relative',
                }),
                3: this.props.intl.formatMessage({
                    id: 'display.force.full',
                }),
                4: this.props.intl.formatMessage({
                    id: 'display.force.close',
                }),
                5: this.props.intl.formatMessage({
                    id: 'display.force.free',
                }),
            },
        };
    }

    render() {
        if (this.props.noTag) {
            return <span>{this.state.forceType[this.props.value.forceType]}</span>;
        } else {
            return <Tag color="blue">{this.state.forceType[this.props.value.forceType]}</Tag>;
        }
    }
}
export default injectIntl(ForceType);
