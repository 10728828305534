import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    List,
    Message,
    Panel,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { TCMSensorMapInformation_I } from '../../../../../handlers/map/MapTCMSensor';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';

const calibrationValues = [
    { label: '3 cm', value: 1 },
    { label: '7 cm', value: 2 },
    { label: '10 cm', value: 3 },
    { label: '13 cm', value: 4 },
    { label: '16 cm', value: 5 },
    { label: '20 cm', value: 6 },
    { label: '23 cm', value: 7 },
    { label: '26 cm', value: 8 },
    { label: '29 cm', value: 9 },
    { label: '33 cm', value: 10 },
    { label: '36 cm', value: 11 },
    { label: '39 cm', value: 12 },
    { label: '42 cm', value: 13 },
    { label: '46 cm', value: 14 },
    { label: '49 cm', value: 15 },
    { label: '52 cm', value: 16 },
    { label: '55 cm', value: 17 },
    { label: '59 cm', value: 18 },
    { label: '62 cm', value: 19 },
    { label: '65 cm', value: 20 },
    { label: '69 cm', value: 21 },
    { label: '72 cm', value: 22 },
    { label: '75 cm', value: 23 },
    { label: '78 cm', value: 24 },
    { label: '82 cm', value: 25 },
    { label: '85 cm', value: 26 },
    { label: '88 cm', value: 27 },
    { label: '91 cm', value: 28 },
    { label: '95 cm', value: 29 },
    { label: '98 cm', value: 30 },
    { label: '101 cm', value: 31 },
    { label: '104 cm', value: 32 },
    { label: '108 cm', value: 33 },
    { label: '111 cm', value: 34 },
    { label: '114 cm', value: 35 },
    { label: '118 cm', value: 36 },
    { label: '121 cm', value: 37 },
    { label: '124 cm', value: 38 },
    { label: '127 cm', value: 39 },
    { label: '131 cm', value: 40 },
    { label: '134 cm', value: 41 },
    { label: '137 cm', value: 42 },
    { label: '140 cm', value: 43 },
    { label: '144 cm', value: 44 },
    { label: '147 cm', value: 45 },
    { label: '150 cm', value: 46 },
    { label: '153 cm', value: 47 },
    { label: '157 cm', value: 48 },
    { label: '160 cm', value: 49 },
    { label: '163 cm', value: 50 },
    { label: '166 cm', value: 51 },
    { label: '170 cm', value: 52 },
    { label: '173 cm', value: 53 },
    { label: '176 cm', value: 54 },
    { label: '180 cm', value: 55 },
    { label: '183 cm', value: 56 },
    { label: '186 cm', value: 57 },
    { label: '189 cm', value: 58 },
    { label: '193 cm', value: 59 },
    { label: '196 cm', value: 60 },
    { label: '199 cm', value: 61 },
    { label: '202 cm', value: 62 },
    { label: '206 cm', value: 63 },
    { label: '209 cm', value: 64 },
    { label: '212 cm', value: 65 },
    { label: '215 cm', value: 66 },
    { label: '219 cm', value: 67 },
    { label: '222 cm', value: 68 },
    { label: '225 cm', value: 69 },
    { label: '228 cm', value: 70 },
    { label: '232 cm', value: 71 },
    { label: '235 cm', value: 72 },
    { label: '238 cm', value: 73 },
    { label: '242 cm', value: 74 },
    { label: '245 cm', value: 75 },
    { label: '248 cm', value: 76 },
    { label: '251 cm', value: 77 },
    { label: '255 cm', value: 78 },
    { label: '258 cm', value: 79 },
    { label: '261 cm', value: 80 },
    { label: '264 cm', value: 81 },
    { label: '268 cm', value: 82 },
    { label: '271 cm', value: 83 },
    { label: '274 cm', value: 84 },
    { label: '277 cm', value: 85 },
    { label: '281 cm', value: 86 },
    { label: '284 cm', value: 87 },
    { label: '287 cm', value: 88 },
    { label: '290 cm', value: 89 },
    { label: '294 cm', value: 90 },
    { label: '297 cm', value: 91 },
    { label: '300 cm', value: 92 },
    { label: '304 cm', value: 93 },
    { label: '307 cm', value: 94 },
    { label: '310 cm', value: 95 },
    { label: '313 cm', value: 96 },
    { label: '317 cm', value: 97 },
    { label: '320 cm', value: 98 },
    { label: '323 cm', value: 99 },
    { label: '326 cm', value: 100 },
];

type Props = {
    sensor: TCMSensorMapInformation_I;
    service: any;
} & WrappedComponentProps;

type State = {
    isEditMode: boolean;
    isUpdating: boolean;
    hasError: boolean;
    formValue: {
        state: boolean;
        value: number;
    };
};

class CalibrationPart extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            isUpdating: false,
            hasError: false,
            formValue: {
                state: this.props.sensor.calibration.state,
                value: this.props.sensor.calibration.value,
            },
        };
    }

    setEditMode = () => {
        this.setState({
            isEditMode: true,
        });
    };

    cancelEdition = () => {
        this.setState({
            isEditMode: false,
            formValue: {
                state: this.props.sensor.calibration.state,
                value: this.props.sensor.calibration.value,
            },
        });
    };

    handleFormChange = formValue => {
        this.setState({
            formValue: {
                state: formValue.state,
                value: formValue.value,
            },
        });
    };

    validEdition = () => {
        this.setState({
            hasError: false,
            isUpdating: true,
        });

        axiosService
            .getAxios()
            .put(
                '/devices/tcm-sensor/updateCalibrationConfig',
                {
                    id: this.props.sensor.id,
                    state: this.state.formValue.state,
                    value: this.state.formValue.value,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(responseData => {
                this.setState({
                    isUpdating: false,
                    isEditMode: false,
                });

                this.props.service.send('TCMSENSOR_EDIT_CALIBRATION', {
                    sensor: responseData.data,
                });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'map.tcmSensor.edit.calibration.success',
                    })
                );
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                    isUpdating: false,
                });
            });
    };

    renderForm = () => (
        <Form fluid formValue={this.state.formValue} onChange={this.handleFormChange}>
            <List>
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormattedMessage id="map.tcmSensor.edit.calibration.state" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="tcmSensor-edit-calibration-state">
                            <FormControl name="state" accepter={Toggle} defaultChecked={this.state.formValue.state} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormattedMessage id="map.tcmSensor.edit.calibration.value" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="tcmSensor-edit-calibration-value"
                                cleanable={false}
                                name="value"
                                accepter={SelectPicker}
                                placement="top"
                                data={calibrationValues}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`tcmSensor-edit-value-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="tcmSensor-edit-calibration-cancel"
                                    color="red"
                                    onClick={this.cancelEdition}
                                    disabled={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    data-cy="tcmSensor-edit-calibration-valid"
                                    color="green"
                                    onClick={this.validEdition}
                                    loading={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );

    renderView = () => (
        <List>
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormattedMessage id="map.tcmSensor.edit.calibration.state" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item data-cy="tcmSensor-calibration-state">
                        {this.props.sensor.calibration.state ? (
                            <FontAwesomeIcon icon={faCheck} color="green" />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormattedMessage id="map.tcmSensor.edit.calibration.value" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item data-cy="tcmSensor-calibration-value">
                        {(this.props.sensor.calibration.value * 3.264).toFixed(0)} cm
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );

    render = () => (
        <Panel
            className="panel-small"
            shaded
            bordered
            bodyFill
            header={
                <PanelHeader
                    title={this.props.intl.formatMessage({
                        id: 'map.tcmSensor.edit.calibration',
                    })}
                    buttons={[
                        <Button
                            data-cy="tcmSensor-calibration-edit"
                            color="blue"
                            size="sm"
                            onClick={this.setEditMode}
                            disabled={this.state.isEditMode}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {this.state.hasError && (
                <Message
                    type="error"
                    description={this.props.intl.formatMessage({ id: 'map.tcmSensor.edit.calibration.error' })}
                />
            )}
            {this.state.isEditMode ? this.renderForm() : this.renderView()}
        </Panel>
    );
}

export default injectIntl(CalibrationPart);
