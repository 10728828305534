import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Steps } from 'rsuite';
import FileUploader from './Manual/FileUploader';
import { Reboot } from './Manual/Reboot';
import Validate from './Manual/Validate';

type Props = {
    close: Function;
};

type State = {
    step: number;
    changelog_api: string;
    changelog_engine: string;
    changelog_dashboard: string;
    changelog_camera_sensor: string | undefined;
    changelog_occupancy_sensor: string | undefined;
    changelog_service_ml: string | undefined;
};

export default class ManualUpdate extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            changelog_api: '',
            changelog_engine: '',
            changelog_dashboard: '',
            changelog_camera_sensor: undefined,
            changelog_occupancy_sensor: undefined,
            changelog_service_ml: undefined,
        };

        this.switcher = this.switcher.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.setChangelog = this.setChangelog.bind(this);
    }

    nextStep() {
        this.setState({
            step: this.state.step + 1,
        });
    }

    switcher() {
        switch (this.state.step) {
            case 0:
                return <FileUploader nextStep={this.nextStep} setChangelog={this.setChangelog} />;
            case 1:
                return (
                    <Validate
                        changelog_api={this.state.changelog_api}
                        changelog_dashboard={this.state.changelog_dashboard}
                        changelog_engine={this.state.changelog_engine}
                        changelog_camera_sensor={this.state.changelog_camera_sensor}
                        changelog_occupancy_sensor={this.state.changelog_occupancy_sensor}
                        changelog_service_ml={this.state.changelog_service_ml}
                        nextStep={this.nextStep}
                    />
                );
            case 2:
                return <Reboot closeModal={this.props.close} />;
            default:
                return null;
        }
    }

    setChangelog(changelog_api, changelog_engine, changelog_dashboard) {
        this.setState({
            changelog_api,
            changelog_engine,
            changelog_dashboard,
        });
    }

    render() {
        return (
            <Fragment>
                <h3 className="margin-bottom-10">
                    <FormattedMessage id="update.manual.title" />
                </h3>

                <Steps current={this.state.step}>
                    <Steps.Item title={<FormattedMessage id="update.manual.fileSelector" />} />
                    <Steps.Item title={<FormattedMessage id="update.manual.validate" />} />
                    <Steps.Item title={<FormattedMessage id="update.manual.reboot" />} />
                </Steps>

                <div className="margin-top-10">{this.switcher()}</div>
            </Fragment>
        );
    }
}
