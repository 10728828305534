import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';
import { PictoRGBValueInterface } from '..';
import PanelHeader from '../../../Custom/PanelHeader';
import { PictoPreview } from './PictoPreview';

type Props = {
    setClickedPictoIndex: (index: number) => void;
    pictos: PictoRGBValueInterface[];
    selectedPictoIndex: number;
    pictoToCopy: number;
    handleClickDelete: (index: number) => void;
    handleClickAdd: () => void;
};

export const LeftSideBar = (props: Props) => {
    const intl = useIntl();

    return (
        <Panel
            bordered
            style={{ maxHeight: '97vh', overflow: 'scroll' }}
            bodyFill
            shaded
            className="panel-small panel-sticky"
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'editPictoRGB.pictoRGBSelector' })}
                    buttons={[
                        <Button
                            size="xs"
                            color="green"
                            disabled={props.pictos.length >= 10}
                            onClick={props.handleClickAdd}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>,
                    ]}
                />
            }>
            {props.pictos.map((picto, index) => {
                return (
                    <div
                        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.4)' }}
                        key={index}
                        data-cy={`pictoRGB-${index}`}
                        onClick={() => props.setClickedPictoIndex(index)}>
                        <PictoPreview
                            picto={picto}
                            isSelected={index === props.selectedPictoIndex}
                            isCopied={props.pictoToCopy === index}
                            handleClickDelete={props.handleClickDelete}
                            index={index}
                        />
                    </div>
                );
            })}
        </Panel>
    );
};
