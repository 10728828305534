import { LPVirtualDisplayColors } from '../../../../../../handlers/ipCanDevices/const/colors';
import { LPDisplay } from '../../../../../../handlers/lpDisplays/LPDisplay';
import {
    LPDisplayForceTypeCountersInterface,
    LPDisplayForceTypeVirtualDisplaysInterface,
    LPDisplayForceTypeVirtualDisplaysStepInterface,
} from '../../../../../../handlers/lpDisplays/LPDisplayForceType';
import { VirtualDisplay } from '../../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import LPMatrixDisplayChartStepToStepEdge from './Edges/LPMatrixDisplayChartStepToStepEdge';
import LPMatrixDisplayChartTopologyTriggerEdge from './Edges/LPMatrixDisplayChartTopologyTriggerEdge';
import LPMatrixDisplayChartTopologyVirtualDisplayEdge from './Edges/LPMatrixDisplayChartTopologyVirtualDisplayEdge';
import LPMatrixDisplayChartVirtualDisplayStepEdge from './Edges/LPMatrixDisplayChartVirtualDisplayStepEdge';
import {
    FlowChartElementInterface,
    LPMatrixDisplayChartElement,
    LPMatrixDisplayChartElementType,
    LPMatrixDisplayFlowElement,
    LPMatrixDisplayForceRole,
} from './LPMatrixDisplayChartElement';
import LPMatrixDisplayChartStepNode from './Nodes/LPMatrixDisplayChartStepNode';
import LPMatrixDisplayChartTopologyNode from './Nodes/LPMatrixDisplayChartTopologyNode';
import LPMatrixDisplayChartVirtualDisplayNode from './Nodes/LPMatrixDisplayChartVirtualDisplayNode';

const TOPOLOGY_TRIGGER_COLORS = [
    '#f427b7',
    '#bb1fef',
    '#f751f7',
    '#5de0f4',
    '#6497c1',
    '#061266',
    '#aaa6fc',
    '#28ff28',
    '#eae87c',
    '#68d4ff',
    '#7caaf4',
    '#113f9b',
    '#b7650c',
    '#64e56f',
    '#27aa78',
    '#1aaf62',
    '#7678ed',
    '#0d1c72',
    '#cbef88',
    '#80db53',
    '#1cd108',
    '#99f7b7',
    '#fafc9f',
    '#39ef4e',
];
export default class LPMatrixDisplayFactory extends LPMatrixDisplayChartElement {
    private _display: LPDisplay;
    private _forceData: ForceDataInterface;

    constructor(display: LPDisplay, forceData: ForceDataInterface) {
        super(display, forceData);

        this._display = display;
        this._forceData = forceData;
    }

    public getFlowChartElement(data: FlowChartElementInterface): LPMatrixDisplayFlowElement | undefined {
        switch (data.type) {
            case LPMatrixDisplayChartElementType.NODE_TOPOLOGY:
                if (data.role === LPMatrixDisplayForceRole.ROLE_ADVANCED) {
                    return new LPMatrixDisplayChartTopologyNode(
                        data.index,
                        data.id,
                        this._display,
                        this._display.getTopologyConfig().getTopologys()[data.index],
                        this._forceData.topologyNum === data.index,
                        data.label
                    );
                }

                break;

            case LPMatrixDisplayChartElementType.NODE_VIRTUAl_DISPLAY: {
                if (
                    data.role === LPMatrixDisplayForceRole.ROLE_ADVANCED ||
                    data.role === LPMatrixDisplayForceRole.ROLE_NORMAL
                ) {
                    if (data.virtualDisplay) {
                        const virtualDisplayForceInformation = this.getVirtualDisplayForceInformation(
                            data.virtualDisplay
                        );

                        return new LPMatrixDisplayChartVirtualDisplayNode(
                            data.index,
                            data.id,
                            this._display.getPreviewInformation(),
                            LPVirtualDisplayColors[data.index],
                            data.virtualDisplay?.getPreviewInformation(),
                            this.getVirtualDisplayIsForce(data.virtualDisplay),
                            virtualDisplayForceInformation,
                            data.label
                        );
                    }
                    throw new Error('virtualDisplay is undefined');
                }

                break;
            }
            case LPMatrixDisplayChartElementType.NODE_STEP: {
                if (
                    data.role === LPMatrixDisplayForceRole.ROLE_ADVANCED ||
                    data.role === LPMatrixDisplayForceRole.ROLE_NORMAL ||
                    data.role === LPMatrixDisplayForceRole.ROLE_BASIC
                ) {
                    if (data.virtualDisplay && data.steps && typeof data.virtualDisplayIndex === 'number') {
                        const step = data.steps[data.index];

                        return new LPMatrixDisplayChartStepNode({
                            index: data.index,
                            virtualDisplayIndex: data.virtualDisplayIndex,
                            id: data.id,
                            display: this._display.getPreviewInformation(),
                            step,
                            backgroundColor: data.virtualDisplay.getBackgroundColor(),
                            isForce: this.getStepIsForced(step),
                            isFirst: data.index === 0,
                            isLast: data.index === data.steps.length - 1,
                            forceMode: this._forceData.counters?.find(
                                counter => counter.numCounter === step.getMode() - 101
                            ),
                            label: data.label,
                            virtualDisplayForceMode: this.getVirtualDisplayForceInformation(data.virtualDisplay),
                        });
                    } else {
                        throw new Error('Virtual display not found');
                    }
                }
                break;
            }
            case LPMatrixDisplayChartElementType.EDGE_TRIGGER_TO_TOPOLOGY: {
                if (
                    data.role === LPMatrixDisplayForceRole.ROLE_ADVANCED &&
                    typeof data.topologyIndex === 'number' &&
                    typeof data.index === 'number'
                ) {
                    const topologyTrigger = this._display.getTopologyConfig().getTopologys()[data.topologyIndex]
                        .triggers[data.index];

                    if (topologyTrigger.trigger !== 0) {
                        return new LPMatrixDisplayChartTopologyTriggerEdge({
                            id: `force-link-topology-${data.topologyIndex}-trigger-${data.index}`,
                            source: `topology-${data.topologyIndex}-force`,
                            sourceHandle: `source-trigger-${data.index}`,
                            target: `topology-${topologyTrigger.next}-force`,
                            targetHandle: 'target-triggers',
                            index: data.index,
                            trigger: topologyTrigger,
                            label: '',
                        });
                    }
                }
                break;
            }
            case LPMatrixDisplayChartElementType.EDGE_VIRTUAL_DISPLAY_TO_TOPOLOGY: {
                if (
                    data.role === LPMatrixDisplayForceRole.ROLE_ADVANCED &&
                    typeof data.virtualDisplayIndex === 'number' &&
                    typeof data.topologyIndex === 'number'
                ) {
                    return new LPMatrixDisplayChartTopologyVirtualDisplayEdge({
                        color: TOPOLOGY_TRIGGER_COLORS[data.topologyIndex],
                        source: `topology-${data.topologyIndex}-force`,
                        target: `virtual-display-${data.virtualDisplayIndex}-force`,
                        targetHandle: 'target-virtual-display-topology',
                        label: `force-link-topology-${data.topologyIndex}-virtualDisplay-${data.virtualDisplayIndex}`,
                        id: `force-link-topology-${data.topologyIndex}-virtualDisplay-${data.virtualDisplayIndex}`,
                        index: data.index,
                    });
                }

                break;
            }
            case LPMatrixDisplayChartElementType.EDGE_VIRTUAL_DISPLAY_TO_STEP: {
                if (
                    (data.role === LPMatrixDisplayForceRole.ROLE_ADVANCED ||
                        data.role === LPMatrixDisplayForceRole.ROLE_NORMAL) &&
                    typeof data.stepIndex === 'number' &&
                    typeof data.virtualDisplayIndex === 'number'
                ) {
                    return new LPMatrixDisplayChartVirtualDisplayStepEdge({
                        color: LPVirtualDisplayColors[data.virtualDisplayIndex],
                        source: `virtual-display-${data.virtualDisplayIndex}-force`,
                        sourceHandle: 'source-virtual-display-step',
                        target: `step-${data.virtualDisplayIndex}-${data.stepIndex}-force`,
                        targetHandle: 'target-virtual-display-step',
                        label: `force-link-virtualDisplay-${data.virtualDisplayIndex}-step-${data.stepIndex}`,
                        id: `force-link-topology-${data.virtualDisplayIndex}-step-${data.stepIndex}`,
                        index: data.index,
                    });
                }
                break;
            }
            case LPMatrixDisplayChartElementType.EDGE_STEP_TO_FIRST_STEP: {
                if (
                    (data.role === LPMatrixDisplayForceRole.ROLE_ADVANCED ||
                        data.role === LPMatrixDisplayForceRole.ROLE_NORMAL ||
                        data.role === LPMatrixDisplayForceRole.ROLE_BASIC) &&
                    typeof data.stepIndex === 'number' &&
                    typeof data.virtualDisplayIndex === 'number' &&
                    data.steps &&
                    data.steps[data.stepIndex].getTime() > 0
                ) {
                    const virtualDisplayForceInformation = this.getVirtualDisplayForceInformation(
                        this._display.getVirtualDisplays()[data.virtualDisplayIndex]
                    );

                    if (virtualDisplayForceInformation?.mode !== LPMatrixDisplayV2VirtualDisplayForceType.OVERRIDE) {
                        return new LPMatrixDisplayChartStepToStepEdge({
                            time: data.steps[data.stepIndex].getTime(),
                            source: `step-${data.virtualDisplayIndex}-${data.stepIndex}-force`,
                            sourceHandle: 'source-back-step-to-step',
                            target: `step-${data.virtualDisplayIndex}-0-force`,
                            targetHandle: 'target-back-step-to-step',
                            label: `force-link-step-${data.stepIndex}-step-${data.stepIndex + 1}-back`,
                            id: `force-link-step-${data.stepIndex}-step-${data.stepIndex + 1}-back`,
                            index: data.index,
                        });
                    }
                }
                break;
            }
            case LPMatrixDisplayChartElementType.EDGE_STEP_TO_STEP: {
                if (
                    (data.role === LPMatrixDisplayForceRole.ROLE_ADVANCED ||
                        data.role === LPMatrixDisplayForceRole.ROLE_NORMAL ||
                        data.role === LPMatrixDisplayForceRole.ROLE_BASIC) &&
                    typeof data.stepIndex === 'number' &&
                    typeof data.virtualDisplayIndex === 'number' &&
                    data.steps &&
                    data.steps[data.stepIndex - 1].getTime() > 0
                ) {
                    const virtualDisplayForceInformation = this.getVirtualDisplayForceInformation(
                        this._display.getVirtualDisplays()[data.virtualDisplayIndex]
                    );

                    if (virtualDisplayForceInformation?.mode !== LPMatrixDisplayV2VirtualDisplayForceType.OVERRIDE) {
                        return new LPMatrixDisplayChartStepToStepEdge({
                            time: data.steps[data.stepIndex - 1].getTime(),
                            source: `step-${data.virtualDisplayIndex}-${data.stepIndex - 1}-force`,
                            sourceHandle: 'source-step-to-step',
                            target: `step-${data.virtualDisplayIndex}-${data.stepIndex}-force`,
                            targetHandle: 'target-step-to-step',
                            label: `force-link-step-${data.stepIndex}-step-${data.stepIndex + 1}`,
                            id: `force-link-step-${data.stepIndex}-step-${data.stepIndex + 1}`,
                            index: data.index,
                        });
                    }
                }
                break;
            }
            default:
                throw new Error('Unknown type');
        }
    }

    public getForceData(): ForceDataInterface {
        return this._forceData;
    }

    private getStepIsForced(step: VirtualDisplayStep): boolean {
        if (step.getMode() >= 101 && step.getMode() <= 132 && this._forceData.counters) {
            const currentCounter = step.getMode() - 101;

            return this._forceData.counters.some(counter => counter.numCounter === currentCounter);
        }

        return false;
    }

    private getVirtualDisplayIsForce(virtualDisplay: VirtualDisplay): boolean {
        return this._forceData.virtualDisplays.some(
            virtualDisplayForceData => virtualDisplayForceData.numVirtualDisplay === virtualDisplay.getPosId()
        );
    }

    private getVirtualDisplayForceInformation(
        virtualDisplay: VirtualDisplay
    ): LPDisplayForceTypeVirtualDisplaysInterface | undefined {
        return this._forceData.virtualDisplays.find(
            virtualDisplayForceData => virtualDisplayForceData.numVirtualDisplay === virtualDisplay.getPosId()
        );
    }

    public addVirtualDisplaysForced(
        virtualDisplayIndex: number,
        mode: LPMatrixDisplayV2VirtualDisplayForceType,
        step: LPDisplayForceTypeVirtualDisplaysStepInterface
    ) {
        const foundForceVirtualDisplay = this._forceData.virtualDisplays.findIndex(
            virtualDisplay => virtualDisplay.numVirtualDisplay === virtualDisplayIndex
        );

        if (foundForceVirtualDisplay === -1) {
            this._forceData.virtualDisplays.push({
                numVirtualDisplay: virtualDisplayIndex,
                mode,
                step,
            });
        } else {
            this._forceData.virtualDisplays[foundForceVirtualDisplay].mode = mode;
            this._forceData.virtualDisplays[foundForceVirtualDisplay].step = step;
        }
    }

    public removeVirtualDisplaysForced(virtualDisplayIndex: number) {
        const newVirtualDisplays = this._forceData.virtualDisplays.filter(
            virtualDisplay => virtualDisplay.numVirtualDisplay !== virtualDisplayIndex
        );

        this._forceData.virtualDisplays = [...newVirtualDisplays];
    }

    public addCounterForced(counterIndex: number, mode: LPMatrixDisplayV2CounterForceType, value: number) {
        const foundForceCounter = this._forceData.counters.findIndex(counter => counter.numCounter === counterIndex);

        if (foundForceCounter === -1) {
            this._forceData.counters.push({
                numCounter: counterIndex,
                mode,
                value,
            });
        } else {
            this._forceData.counters[foundForceCounter].mode = mode;
            this._forceData.counters[foundForceCounter].value = value;
        }
    }

    public removeCounterForced(counterIndex: number) {
        this._forceData.counters = this._forceData.counters.filter(
            virtualDisplay => virtualDisplay.numCounter !== counterIndex
        );
    }

    public setTopologyForced(topologyIndex: number) {
        this._forceData.topologyNum = topologyIndex;
    }

    public unsetTopologyForced() {
        this._forceData.topologyNum = -1;
    }
}

export interface ForceDataInterface {
    counters: LPDisplayForceTypeCountersInterface[];
    virtualDisplays: LPDisplayForceTypeVirtualDisplaysInterface[];
    topologyNum: number;
}

export enum LPMatrixDisplayV2CounterForceType {
    NO_FORCE = 0,
    FORCE_VALUE = 1,
    FORCE_RELATIVE = 2,
}

export enum LPMatrixDisplayV2VirtualDisplayForceType {
    NO_FORCE = 0,
    OVERRIDE = 1,
    ADD_FORCE_STEP = 2,
}
