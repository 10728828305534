import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, FlexboxGrid, Tag } from 'rsuite';

export default function StartEnumeration(options) {
    const { bus, startStop, startId, stopId } = options;

    return (
        <FlexboxGrid align="middle">
            <FlexboxGrid.Item
                data-cy="history-startEnum-stopEnum"
                componentClass={Col}
                xs={12}
                style={{ textOverflow: 'initial', overflow: 'visible', whiteSpace: 'normal' }}>
                <FormattedMessage id="controller.history.startEnumeration.options.bus" />
                <Tag className="margin-left-5" color="violet">
                    {bus}
                </Tag>
                <span className="margin-left-5">-</span>
                <FontAwesomeIcon
                    className="margin-left-5"
                    icon={startStop ? faPlay : faStop}
                    color={startStop ? 'green' : 'red'}
                    size="lg"
                />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
                data-cy="history-id"
                componentClass={Col}
                xs={12}
                style={{ textOverflow: 'initial', overflow: 'visible', whiteSpace: 'normal' }}>
                <FormattedMessage id="controller.history.startEnumeration.options.startId" />
                <Tag className="margin-left-5" color="green">
                    {startId}
                </Tag>
                {stopId && (
                    <Fragment>
                        <br />
                        <FormattedMessage id="controller.history.startEnumeration.options.stopId" />
                        <Tag className="margin-left-5" color="red">
                            {stopId}
                        </Tag>
                    </Fragment>
                )}
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
}
