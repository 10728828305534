import axios, { AxiosResponse } from 'axios';
import L, { CRS, LatLng, LatLngExpression } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FeatureGroup, Map } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Alert, Loader } from 'rsuite';
import { PlaceIPCamera } from '../../../handlers/Camera/ApiCamera';
import Camera from '../../../handlers/Camera/Camera';
import PlaceCamera from '../../../handlers/Camera/PlaceCamera';
import { sensorPlaceTypeHandler } from '../../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';
import { useMLCameraPlace } from '../Hooks/useMLCameraPlace';
import { useOccupancySensorPlace } from '../Hooks/useOccupancySensorPlace';
import { CreatePlaceModal } from '../Modal/CreatePlaceModal';
import { BottomLeftControl } from './BottomLeftControl';
import { BottomRightControl } from './BottomRightControl';
import { MLPlaceCameraView } from './MLPlaceCameraView';
import { OccupancyPlaceCameraView } from './OccupancyPlaceCameraView';
import { PlaceCameraView } from './PlaceCameraView';
import { TopLeftControl } from './TopLeftControl';

type Props = {
    camera: Camera;
    places: PlaceCamera[];
    selectedPlaceId: number | null;
    reloadPlaces: () => void;
    reloadCamera: () => void;
};

export enum PlaceTextInformationType {
    NAME = 'name',
    STATUS = 'status',
    FORCE = 'force',
    NONE = 'none',
}

type PlaceModalInfo = {
    isOpen: boolean;
    coordinated?: LatLng[];
};

let canEdit = true;

export const ImagePlacePicker = (props: Props) => {
    const intl = useIntl();

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingPlaces, setIsLoadingPlaces] = useState(false);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [sensorPlaceTypes, setSensorPlaceTypes] = useState<any[]>([]);
    const [currentViewMode, setCurrentViewMode] = useState<PlaceTextInformationType>(PlaceTextInformationType.NONE);
    const [displayPlaceState, setDisplayPlaceState] = useState<boolean>(true);
    const [createPlaceModalInfo, setCreatePlaceModalInfo] = useState<PlaceModalInfo>({ isOpen: false });
    const [shouldDisplayPlacesBoxes, setShouldDisplayPlacesBoxes] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isAssociationMode, setIsAssociationMode] = useState<boolean>(false);
    const [selectedElement, setSelectedElement] = useState<number>(-1);
    const [shouldDisplayPlacesPoints, setShouldDisplayPlacesPoints] = useState<boolean>(true);
    const editRef = useRef();

    const {
        mlBoxesActive,
        percentageActive,
        isStreaming,
        mlBoxes,
        toggleServiceML,
        togglePercentageActive,
        toggleStreaming,
    } = useMLCameraPlace(props.camera.getMac());

    const {
        occupancySensorActive,
        statePredictionActive,
        occupancyBoxes,
        toggleServiceOccupancySensor,
        toggleStatePredictionActive,
    } = useOccupancySensorPlace(props.camera.getMac(), isStreaming);

    const toggleDisplayPlaceState = () => {
        setDisplayPlaceState(!displayPlaceState);
    };

    const mapRef = useRef<any>(null);

    let imageOverlay1Ref: L.ImageOverlay | null = null;
    let imageOverlay2Ref: L.ImageOverlay | null = null;

    useEffect(() => {
        if (props.camera.getImage()) {
            const currentImage = new Image();

            const imageData = Buffer.from(props.camera.getImage(), 'base64').toString('base64');

            currentImage.src = `data:image/${props.camera.getImageType()};base64, ${imageData}`;

            currentImage.onload = () => {
                setIsLoading(false);
                setImage(currentImage);

                const imageRef2 = L.imageOverlay(
                    currentImage.src,
                    [
                        [currentImage.naturalHeight * -1, 0],
                        [0, currentImage.naturalWidth],
                    ],
                    {
                        zIndex: -1,
                    }
                );

                const map = mapRef?.current?.leafletElement;

                imageRef2.addTo(map);

                imageOverlay1Ref = imageRef2;
            };
        }

        return function cleanup() {
            axiosService.getAxios().put(
                '/ip-cameras/stream',
                {
                    id: props.camera.getId(),
                    status: false,
                    time: 2,
                },
                {
                    headers: authHeader(),
                }
            );
        };
    }, []);

    useEffect(() => {
        webSocketService.joinRoom('ip-camera');
        webSocketService.onEvent('ip-camera:stream', handleCameraStream);

        return function cleanup() {
            webSocketService.offEvent('ip-camera:stream', handleCameraStream);
        };
    }, []);

    const handleStream = (fps: number, checked: boolean) => {
        axiosService
            .getAxios()
            .put(
                '/ip-cameras/stream',
                {
                    id: props.camera.getId(),
                    status: checked,
                    time: fps,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                if (checked) {
                    Alert.info(intl.formatMessage({ id: 'camera.stream.started' }));
                    toggleStreaming();
                } else {
                    Alert.info(intl.formatMessage({ id: 'camera.stream.stopped' }));
                    toggleStreaming();
                }
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'camera.stream.error' }));
            });
    };

    const handleCameraStream = (data: any) => {
        if (data.target === props.camera.getMac()) {
            if (mapRef.current) {
                // Catch map reference
                const map = mapRef.current.leafletElement;

                const currentImage = new Image();

                const imageData = Buffer.from(data.payload, 'binary').toString('base64');

                currentImage.src = `data:image/${props.camera.getImageType()};base64, ${imageData}`;

                // While image is loaded
                currentImage.onload = () => {
                    // Set the image globally
                    setImage(currentImage);

                    // Create an image reference
                    const imageRef2 = L.imageOverlay(
                        currentImage.src,
                        [
                            [currentImage.naturalHeight * -1, 0],
                            [0, currentImage.naturalWidth],
                        ],
                        {
                            zIndex: -1,
                        }
                    );

                    // Add the image to the map
                    imageRef2.addTo(map);

                    imageOverlay2Ref = imageRef2;

                    // Remove the old image
                    imageOverlay1Ref?.removeFrom(map);

                    // Set the new image as the old image
                    imageOverlay1Ref = imageOverlay2Ref;

                    // Reset the image reference
                    imageOverlay2Ref = null;
                };
            }
        }
    };

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/sensor-place-type', { headers: authHeader() })
            .then(response => {
                setSensorPlaceTypes(response.data.map(sensorPlaceType => sensorPlaceTypeHandler(sensorPlaceType)));
            });
    }, []);

    const handleDeleteStart = () => {
        if (mlBoxesActive) {
            toggleServiceML();
        }

        if (occupancySensorActive) {
            toggleServiceOccupancySensor();
        }
        setShouldDisplayPlacesBoxes(false);
        setEditMode(true);
    }

    const handleDeleteStop = () => {
        setEditMode(false);
    }

    const handleEditStart = () => {
        if (mlBoxesActive) {
            toggleServiceML();
        }

        if (occupancySensorActive) {
            toggleServiceOccupancySensor();
        }

        setEditMode(true);
    }

    const handleEditStop = () => {
        setEditMode(false);
        setShouldDisplayPlacesPoints(true);
    };

    const handleCreate = e => {
        if (canEdit) {
            canEdit = false;
            const coordinates = e.layer._latlngs;

            if (coordinates.length !== 2) {
                Alert.error(intl.formatMessage({ id: 'place.create.error' }));
                canEdit = true;
                return;
            }

            e.layer.remove();

            setCreatePlaceModalInfo({ isOpen: true, coordinated: coordinates });
            canEdit = true;
        }
    };

    const closeCreatePlaceModal = () => {
        setCreatePlaceModalInfo({ isOpen: false });

        props.reloadPlaces();
    };

    const handleDelete = e => {
        if (canEdit) {
            canEdit = false;

            let placesId: Array<number> = [];

            for (let l in e.layers._layers) {
                const placeId = parseInt(e.layers._layers[l].options.name);

                if (placeId && !isNaN(placeId)) placesId.push(parseInt(e.layers._layers[l].options.name));
            }

            axiosService
                .getAxios()
                .delete('/places-camera', {
                    data: {
                        tabId: placesId,
                    },
                    headers: authHeader(),
                })
                .then(() => {
                    props.camera.deletePlacesCamera(placesId);

                    props.reloadPlaces();

                    Alert.success(intl.formatMessage({ id: 'cameras.placeCamera.delete.success' }));
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(intl.formatMessage({ id: 'cameras.placeCamera.delete.error' }));
                })
                .finally(() => {
                    canEdit = true;
                });
        }
    };

    const handleEdit = e => {
        if (canEdit) {
            canEdit = false;

            setIsLoadingPlaces(true);

            let places: Array<{ id: number; geoJSON: LatLngExpression }> = [];
            let placesBox: Array<{ id: number; geoJSON: LatLngExpression[] }> = [];

            for (let l in e.layers._layers) {
                if (e.layers._layers[l].options.name.includes('polygon')) {
                    const currentId = parseInt(e.layers._layers[l].options.name.replace('-polygon', ''));
                    placesBox.push({
                        id: currentId,
                        geoJSON: e.layers._layers[l]._latlngs[0].map(coordinate => [parseInt(coordinate.lat), parseInt(coordinate.lng)]),
                    })
                } else {
                    places.push({
                        id: parseInt(e.layers._layers[l].options.name),
                        geoJSON: [parseInt(e.layers._layers[l]._latlng.lat), parseInt(e.layers._layers[l]._latlng.lng)],
                    });
                }
            }

            const requests: Promise<AxiosResponse<any>>[] = [];

            if (placesBox.length > 0) {
                requests.push(axiosService
                    .getAxios()
                    .put(
                        '/places-camera/updateBoxPlaceCamera',
                        {
                            placeCameraTab: placesBox,
                        },
                        {
                            headers: authHeader(),
                        }
                    ))
            }

            if (places.length > 0) {
                requests.push(axiosService
                    .getAxios()
                    .put(
                        '/places-camera',
                        {
                            placeCameraTab: places,
                        },
                        {
                            headers: authHeader(),
                        }
                    ))
            }

            axios
                .all(requests)
                .then(() => {
                    props.camera.editPlacesCamera(places);
                    props.camera.editPlacesBoxCamera(placesBox);

                    // props.reloadPlaces();

                    Alert.success(intl.formatMessage({ id: 'cameras.placeCamera.edit.success' }));
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(intl.formatMessage({ id: 'cameras.placeCamera.edit.error' }));
                })
                .finally(() => {
                    setIsLoadingPlaces(false);

                    canEdit = true;
                });
        }
    };

    const handleAssociationMode = () => {
        setIsAssociationMode(true);
        setShouldDisplayPlacesBoxes(true);
        if (!isStreaming) {
            handleStream(3, true);
        }
        if (!occupancySensorActive) {
            toggleServiceML();
        }
    }

    const handleLeaveAssociationMode = () => {
        setIsAssociationMode(false);
        setSelectedElement(-1);
    }

    const handleAssociateBox = (coordinates: LatLngExpression[]) => {
        if (selectedElement > 0 && isAssociationMode) {
            const data = [{
                id: selectedElement,
                geoJSON: coordinates,
            }]

            axiosService.getAxios().put<PlaceIPCamera>('/places-camera/updateBoxPlaceCamera', {
                placeCameraTab: data,
            }, {
                headers: authHeader(),
            }).then((response) => {
                props.camera.editPlacesBoxCamera(data);

                props.reloadPlaces();

                Alert.success(intl.formatMessage({ id: 'cameras.placeCamera.edit.success' }));
            }).catch((err) => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'cameras.placeCamera.edit.error' }));
            }).finally(() => {
                setSelectedElement(-1);
            })
        }
    }

    const handleSetSelectedElement = (selectedElement: number) => {
        if (isAssociationMode) {
            setSelectedElement(selectedElement);
        }
    }

    if (image && !isLoading) {
        let currentPlacesToDisplay: PlaceCamera[] = [];

        if (selectedElement > 0) {
            currentPlacesToDisplay = props.places.filter(p => p.getId() === selectedElement);
        } else {
            currentPlacesToDisplay = props.places;
        }

        return (
            <>
                <CreatePlaceModal
                    camera={props.camera}
                    isOpen={createPlaceModalInfo.isOpen}
                    onClose={closeCreatePlaceModal}
                    coordinated={createPlaceModalInfo.coordinated}
                />
                <Map
                    ref={mapRef}
                    center={[(image.naturalHeight / 2) * -1, image.naturalWidth / 2]}
                    zoom={-1.5}
                    zoomSnap={0.25}
                    zoomDelta={0.25}
                    crs={CRS.Simple}
                    scrollWheelZoom
                    doubleClickZoom
                    attributionControl={false}
                    minZoom={-10}>
                    <TopLeftControl
                        toggleServiceML={toggleServiceML}
                        mlBoxesActive={mlBoxesActive}
                        percentageActive={percentageActive}
                        setPercentageActive={togglePercentageActive}
                        isStreaming={isStreaming}
                        occupancySensorActive={occupancySensorActive}
                        statePredictionActive={statePredictionActive}
                        toggleServiceOccupancySensor={toggleServiceOccupancySensor}
                        toggleStatePredictionActive={toggleStatePredictionActive}
                        handleLeaveAssociationMode={handleLeaveAssociationMode}
                        handleAssociationMode={handleAssociationMode}
                        isAssociationMode={isAssociationMode}
                    />

                    <BottomRightControl
                        currentMode={currentViewMode}
                        onChangeMode={setCurrentViewMode}
                        shouldDisplayPlaceState={displayPlaceState}
                        onChangeShouldDisplayPlaceState={toggleDisplayPlaceState}
                        isLoading={isLoading}
                        shouldDisplayPlacesBoxes={shouldDisplayPlacesBoxes}
                        toggleShouldDisplayPlacesBoxes={() => setShouldDisplayPlacesBoxes(!shouldDisplayPlacesBoxes)}
                        editMode={editMode}
                        shouldDisplayPlacesPoints={shouldDisplayPlacesPoints}
                        toggleShouldDisplayPlacesPoints={() => setShouldDisplayPlacesPoints(!shouldDisplayPlacesPoints)}
                    />

                    <BottomLeftControl handleStream={handleStream} isStreaming={isStreaming} />

                    {!isLoadingPlaces && (
                        <FeatureGroup>
                            <EditControl
                                position="topright"
                                onCreated={handleCreate}
                                onEdited={e => handleEdit(e)}
                                onDeleted={handleDelete}
                                onDeleteStart={handleDeleteStart}
                                onDeleteStop={handleDeleteStop}
                                onEditStart={handleEditStart}
                                onEditStop={handleEditStop}
                                draw={{
                                    polyline: true,
                                    rectangle: false,
                                    circle: false,
                                    marker: false,
                                    circlemarker: false,
                                    polygon: false,
                                }}
                            />

                            {currentPlacesToDisplay.map(place => {
                                return (
                                    <PlaceCameraView
                                        place={place}
                                        selectedPlaceId={props.selectedPlaceId}
                                        sensorPlaceTypes={sensorPlaceTypes}
                                        currentViewMode={currentViewMode}
                                        shouldDisplayPlaceState={displayPlaceState}
                                        shouldDisplayPlacesBoxes={shouldDisplayPlacesBoxes}
                                        shouldDisplayPlacesPoints={shouldDisplayPlacesPoints}
                                        editMode={editMode}
                                        setSelectedElement={handleSetSelectedElement}
                                    />
                                );
                            })}
                            
                            {mlBoxesActive &&
                                mlBoxes.map((box, index) => {
                                    return (
                                        <MLPlaceCameraView
                                            box={box}
                                            index={index}
                                            percentageActive={percentageActive}
                                            shouldBeFilled={selectedElement > 0}
                                            handleAssociateBox={handleAssociateBox}
                                        />
                                    );
                                })}

                            {occupancySensorActive &&
                                occupancyBoxes.map((box, index) => {
                                    return (
                                        <OccupancyPlaceCameraView
                                            box={box}
                                            index={index}
                                            statePredictionActive={statePredictionActive}
                                        />
                                    );
                                })}

                        </FeatureGroup>
                    )}
                </Map>
            </>
        );
    } else {
        return <Loader />;
    }
};
