import moment from 'moment';
import { tcmDisplaysHandler } from '../ipCanDevices';

export function mapDisplaysHandler(display) {
    return {
        ...display,
        createdAt: moment(display.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: display.createdBy,
        createInformations: `${display.createdBy} - ${moment(display.createdAt).format('DD/MM/YYYY HH:mm:ss')}`,
        geoJSON: display.geoJSON,
        id: display.id,
        label: display.label,
        tcmDisplay: tcmDisplaysHandler(display.tcmDisplay),
        updatedAt: moment(display.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        updatedBy: display.updatedBy,
        updateInformations: display.updatedAt
            ? `${display.updatedBy} - ${moment(display.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`
            : '-',
    };
}
