import { tcmDisplaysHandler } from '../../handlers/ipCanDevices';
import { webSocketTcmDisplaysHandler } from '../../handlers/ipCanDevices/webSocketTcmDisplays.handler';
import { webSocketTcmSensorsHandler } from '../../handlers/ipCanDevices/webSocketTcmSensors.handler';
import { ipCanDevicesConstants } from '../constants';
import { ipCanDevicesService } from '../services';
import { notificationActions } from './notification.actions';

export const ipCanDevicesActions = {
    getIpCanDevices,
    updateDisplayConfiguration,
    deleteDisplay,
    updateSensorColors,
    updateSensorTimes,
    updateSensorCalibration,
    deleteSensor,
    webSocketUpdateTcmSensor,
    webSocketUpdateTcmDisplay,
    updateSensorForce,
    updateDisplayForce,
};

function getIpCanDevices(ipCanId, busNumber) {
    return dispatch => {
        dispatch(request());

        ipCanDevicesService.getBusDevices(ipCanId, busNumber).then(
            response => dispatch(success(response)),
            error => {
                dispatch(failure(error));
                dispatch(notificationActions.createNotification('error', 'controllerDevices.error.fetchDevicesError'));
            }
        );
    };

    function request() {
        return {
            type: ipCanDevicesConstants.GET_BUS_DEVICES_REQUEST,
        };
    }

    function success(response) {
        return {
            type: ipCanDevicesConstants.GET_BUS_DEVICES_SUCCESS,
            sensors: response.tcmSensors,
            displays: response.tcmDisplays,
            ipCan: response.ipCan,
        };
    }

    function failure(error) {
        return {
            type: ipCanDevicesConstants.GET_BUS_DEVICES_FAILURE,
            error: error,
        };
    }
}

function updateDisplayConfiguration(id, arrow, lang, text, type) {
    return dispatch => {
        dispatch(request(id));

        ipCanDevicesService.updateDisplayConfiguration(id, arrow, lang, text, type).then(
            display => {
                dispatch(success(display));
                dispatch(notificationActions.createNotification('success', 'display.success.updateDisplaySuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'display.error.updateDisplayError'));
            }
        );
    };

    function request(id) {
        return {
            type: ipCanDevicesConstants.UPDATE_DISPLAY_CONFIGURATION_REQUEST,
            id,
        };
    }
    function success(display) {
        return {
            type: ipCanDevicesConstants.UPDATE_DISPLAY_CONFIGURATION_SUCCESS,
            display,
        };
    }
    function failure(error, id) {
        return {
            type: ipCanDevicesConstants.UPDATE_DISPLAY_CONFIGURATION_FAILURE,
            error,
            id,
        };
    }
}

function deleteDisplay(id) {
    return dispatch => {
        dispatch(request(id));

        ipCanDevicesService.deleteDisplay(id).then(
            display => {
                dispatch(success(display));
                dispatch(notificationActions.createNotification('success', 'display.success.deleteDisplaySuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'display.error.deleteDisplayError'));
            }
        );
    };

    function request(id) {
        return { type: ipCanDevicesConstants.DELETE_DISPLAY_REQUEST, id };
    }
    function success(display) {
        return { type: ipCanDevicesConstants.DELETE_DISPLAY_SUCCESS, display };
    }
    function failure(error, id) {
        return {
            type: ipCanDevicesConstants.DELETE_DISPLAY_FAILURE,
            error,
            id,
        };
    }
}

function updateSensorColors(id, forced, free, occupied, overstay, overstayFree) {
    return dispatch => {
        dispatch(request(id));

        ipCanDevicesService.updateSensorColors(id, forced, free, occupied, overstay, overstayFree).then(
            sensor => {
                dispatch(success(sensor));
                dispatch(notificationActions.createNotification('success', 'sensor.success.updateColorsSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'sensor.error.updateColorsError'));
            }
        );
    };

    function request(id) {
        return { type: ipCanDevicesConstants.UPDATE_SENSOR_REQUEST, id };
    }
    function success(sensor) {
        return {
            type: ipCanDevicesConstants.UPDATE_SENSOR_SUCCESS,
            sensor,
        };
    }
    function failure(error, id) {
        return {
            type: ipCanDevicesConstants.UPDATE_SENSOR_FAILURE,
            error,
            id,
        };
    }
}

function updateSensorCalibration(id, state, value) {
    return dispatch => {
        dispatch(request(id));

        ipCanDevicesService.updateSensorCalibration(id, state, value).then(
            sensor => {
                dispatch(success(sensor));
                dispatch(notificationActions.createNotification('success', 'sensor.success.updateTimeSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'sensor.error.updateTimeError'));
            }
        );
    };

    function request(id) {
        return { type: ipCanDevicesConstants.UPDATE_SENSOR_REQUEST, id };
    }
    function success(sensor) {
        return {
            type: ipCanDevicesConstants.UPDATE_SENSOR_SUCCESS,
            sensor,
        };
    }
    function failure(error, id) {
        return {
            type: ipCanDevicesConstants.UPDATE_SENSOR_FAILURE,
            error,
            id,
        };
    }
}

function updateSensorTimes(id, maxFree, maxOccupied, provOccupied) {
    return dispatch => {
        dispatch(request(id));

        ipCanDevicesService.updateSensorTimes(id, maxFree, maxOccupied, provOccupied).then(
            sensor => {
                dispatch(success(sensor));
                dispatch(notificationActions.createNotification('success', 'sensor.success.updateTimeSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'sensor.error.updateTimeError'));
            }
        );
    };

    function request(id) {
        return { type: ipCanDevicesConstants.UPDATE_SENSOR_REQUEST, id };
    }
    function success(sensor) {
        return {
            type: ipCanDevicesConstants.UPDATE_SENSOR_SUCCESS,
            sensor,
        };
    }
    function failure(error, id) {
        return {
            type: ipCanDevicesConstants.UPDATE_SENSOR_FAILURE,
            error,
            id,
        };
    }
}

function deleteSensor(id) {
    return dispatch => {
        dispatch(request(id));

        ipCanDevicesService.deleteSensor(id).then(
            sensor => {
                dispatch(success(sensor));
                dispatch(notificationActions.createNotification('success', 'sensor.success.deleteSensorSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'sensor.error.deleteSensorError'));
            }
        );
    };

    function request(id) {
        return { type: ipCanDevicesConstants.DELETE_SENSOR_REQUEST, id };
    }
    function success(sensor) {
        return { type: ipCanDevicesConstants.DELETE_SENSOR_SUCCESS, sensor };
    }
    function failure(error, id) {
        return {
            type: ipCanDevicesConstants.DELETE_SENSOR_FAILURE,
            error,
            id,
        };
    }
}

function webSocketUpdateTcmSensor(bus, data, ipCanId) {
    return dispatch => {
        dispatch(request(bus, data, ipCanId));
    };

    function request(bus, data, ipCanId) {
        return {
            type: ipCanDevicesConstants.WEBSOCKET_UPDATE_SENSOR,
            data: webSocketTcmSensorsHandler(data),
            bus: parseInt(bus),
            ipCanId,
        };
    }
}

function webSocketUpdateTcmDisplay(bus, data, ipCanId) {
    return dispatch => {
        dispatch(request(bus, data, ipCanId));
    };

    function request(bus, data, ipCanId) {
        return {
            type: ipCanDevicesConstants.WEBSOCKET_UPDATE_DISPLAY,
            data: webSocketTcmDisplaysHandler(data),
            bus: parseInt(bus),
            ipCanId,
        };
    }
}

function updateDisplayForce(data) {
    return dispatch => {
        dispatch(request(data));
    };

    function request(data) {
        return {
            type: ipCanDevicesConstants.UPDATE_DISPLAY_FORCE,
            data: data.map(d => tcmDisplaysHandler(d)),
        };
    }
}

function updateSensorForce(data) {
    return dispatch => {
        dispatch(request(data));
    };

    function request(data) {
        return {
            type: ipCanDevicesConstants.UPDATE_SENSOR_FORCE,
            data: data.map(d => webSocketTcmSensorsHandler(d)),
        };
    }
}
