import { FormattedMessage, useIntl } from 'react-intl';
import { Tag } from 'rsuite';
import Task from '../../../../../handlers/Event/Task';
import { translatedTaskTCMDisplayForce } from '../../../Modal/Tasks/CreateTaskType';
import { TCMDisplaysPart } from './Parts/TCMDisplaysPart';

type Props = {
    task: Task;
};

export const TCMDisplayForce = (props: Props) => {
    const intl = useIntl();
    const forceType = props.task.getForceOption().getText();

    return (
        <>
            <FormattedMessage id="tasks.forceTCMDisplay" />

            {typeof forceType !== 'undefined' && (
                <Tag color="blue" className="margin-left-5" data-cy="tasks-forceTCMDisplay-text">
                    {translatedTaskTCMDisplayForce(intl, forceType)}
                </Tag>
            )}

            <TCMDisplaysPart tcmDisplay={props.task.getTcmDisplay()} />
        </>
    );
};
