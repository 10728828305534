import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Avatar, Button, Drawer } from 'rsuite';
import { parkingActions } from '../../../redux/actions';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import ParkingInformations from './ParkingInformations';
import ParkingLevels from './ParkingLevels';

const mapDispatchToProps = dispatch => ({
    closeViewParkingDrawer: () => dispatch(parkingActions.closeViewDrawer()),
    openDeleteParkingModal: id => dispatch(parkingActions.openDeleteModal(id)),
});

type Props = {
    parkingDrawer: Record<string, any>;
    view_drawer_openned: boolean;
    closeViewParkingDrawer: Function;
    openDeleteParkingModal: Function;
} & WrappedComponentProps;

class ParkingDrawer extends React.Component<Props> {
    render() {
        if (this.props.parkingDrawer) {
            return (
                <Drawer
                    backdrop={true}
                    show={this.props.view_drawer_openned}
                    onHide={() => this.props.closeViewParkingDrawer()}>
                    <Drawer.Header data-cy="parkingDrawer">
                        <Drawer.Title className="with-avatar" data-cy="parkingDrawer-title">
                            <Avatar
                                src={`data:image/${this.props.parkingDrawer.imageType};base64, ${this.props.parkingDrawer.image}`}
                                alt={this.props.parkingDrawer.name.charAt(0).toUpperCase()}
                            />
                            {this.props.parkingDrawer.name}
                        </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body className="drawer-body-with-avatar">
                        <SecuredFragment authorizedRoles={[rolesConstants.parking.VIEW]}>
                            <ParkingInformations parking={this.props.parkingDrawer} />
                        </SecuredFragment>

                        <SecuredFragment authorizedRoles={[rolesConstants.level.VIEW_LIST]}>
                            <ParkingLevels
                                levels={this.props.parkingDrawer.levels}
                                parking={this.props.parkingDrawer.id}
                            />
                        </SecuredFragment>
                    </Drawer.Body>
                    <Drawer.Footer>
                        <SecuredFragment authorizedRoles={[rolesConstants.parking.DELETE]}>
                            <Button
                                onClick={() => {
                                    this.props.openDeleteParkingModal(this.props.parkingDrawer.id);
                                    this.props.closeViewParkingDrawer();
                                }}
                                data-cy="delete-parking-button"
                                color="red">
                                <FormattedMessage id="parking.deleteButton" />
                            </Button>
                        </SecuredFragment>
                    </Drawer.Footer>
                </Drawer>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    let { view_drawer_openned, parkingDrawer } = state.parkings;

    return { view_drawer_openned, parkingDrawer };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ParkingDrawer));
