type Props = {
    color: string;
};

export const ThreeLinesCardIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} x="32" y="96" width="448" height="32" />
            <rect fill={color} x="32" y="416" width="448" height="32" />
            <rect fill={color} x="32" y="288" width="448" height="64" />
            <rect fill={color} x="32" y="96" width="32" height="352" />
            <rect fill={color} x="448" y="96" width="32" height="352" />
            <rect fill={color} x="192" y="96" width="64" height="256" />
            <rect fill={color} x="192" y="224" width="288" height="32" />
            <rect fill={color} x="192" y="160" width="288" height="32" />
            <rect fill={color} x="416" y="96" width="64" height="256" />
            <rect fill={color} x="352" y="96" width="96" height="160" />
        </g>
    </svg>
);
