import moment from 'moment';
import History from './History';

export default class TcmDisplayHistoric extends History {
    idTcmDisplay: number;
    tcmDisplayName: string;
    constructor(historyObject) {
        const { idTcmDisplay, tcmDisplayName } = historyObject;

        super(historyObject);

        this.idTcmDisplay = idTcmDisplay;
        this.tcmDisplayName = tcmDisplayName;
    }

    getIdTcmDisplay() {
        return this.idTcmDisplay;
    }

    setIdTcmDisplay(idTcmDisplay) {
        this.idTcmDisplay = idTcmDisplay;
    }

    getTcmDisplayName() {
        return this.tcmDisplayName;
    }

    setTcmDisplayName(tcmDisplayName) {
        this.tcmDisplayName = tcmDisplayName;
    }

    getOption() {
        if (!this.option) {
            return null;
        }

        switch (this.getDesc()) {
            case 'update config':
                return {
                    lang: parseInt(this.option.lang),
                    text: parseInt(this.option.text),
                    arrow: parseInt(this.option.arrow),
                    type: parseInt(this.option.type),
                };
            case 'update counters assign':
                return {
                    configType: {
                        generic: this.option.configType.generic,
                        pmr: this.option.configType.pmr,
                        electrical: this.option.configType.electrical,
                        family: this.option.configType.family,
                    },
                };
            case 'force display':
                return {
                    isForce: this.option.isForce,
                    forceState: {
                        forceType: parseInt(this.option.forceState.forceType),
                        generic: parseInt(this.option.forceState.generic),
                        pmr: parseInt(this.option.forceState.pmr),
                        electrical: parseInt(this.option.forceState.electrical),
                        family: parseInt(this.option.forceState.family),
                    },
                    endForceTime: moment(this.option.endForceTime).format('DD/MM/YYYY HH:mm:ss'),
                };
            default:
                return this.option;
        }
    }

    getHistory() {
        return {
            id: this.getId(),
            user: this.getUser(),
            createdAt: this.getCreatedAt(),
            createdBy: this.getCreatedBy(),
            desc: this.getDesc(),
            option: this.getOption(),
            idTcmDisplay: this.getIdTcmDisplay(),
            tcmDisplayName: this.getTcmDisplayName(),
            disabled: this.getDisabled(),
        };
    }
}
