import { Doughnut } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { Col, FlexboxGrid } from 'rsuite';
import { PlaceTypeCounterValue } from '../ParkingPanel';

type Props = {
    placeTypeCounterValue: PlaceTypeCounterValue;
    height: number;
};

export const PlaceTypeDoughnut = (props: Props) => {
    const intl = useIntl();

    const data = {
        title: props.placeTypeCounterValue.label,
        datasets: [
            {
                data: [props.placeTypeCounterValue.free, props.placeTypeCounterValue.occupied],
                backgroundColor: [props.placeTypeCounterValue.color, '#f44336'],
            },
        ],
        labels: [
            intl.formatMessage({ id: 'parking.occupationByLevel.tooltip.free' }),
            intl.formatMessage({ id: 'parking.occupationByLevel.tooltip.occupied' }),
        ],
    };

    const imageData = Buffer.from(props.placeTypeCounterValue.image, 'base64').toString('base64');

    const occupiedPercent =
        (props.placeTypeCounterValue.occupied /
            (props.placeTypeCounterValue.free + props.placeTypeCounterValue.occupied)) *
        100;
    const freePercent =
        (props.placeTypeCounterValue.free / (props.placeTypeCounterValue.free + props.placeTypeCounterValue.occupied)) *
        100;

    return (
        <>
            <p className="parking-level-name margin-bottom-10 padding-bottom-10">{props.placeTypeCounterValue.label}</p>
            <FlexboxGrid align="middle" justify="space-between">
                <FlexboxGrid.Item componentClass={Col} xs={14}>
                    <Doughnut
                        data={data}
                        height={props.height}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            aspectRatio: 1,
                            legend: {
                                display: false,
                            },
                        }}
                    />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} xs={10} style={{ width: 80 }}>
                    <img
                        src={`data:image/${props.placeTypeCounterValue.imageType};base64, ${imageData}`}
                        style={{
                            height: 40,
                            backgroundColor: 'white',
                            // lineHeight: 1,
                        }}
                        className="margin-bottom-10"
                        alt="Logo"
                    />
                    <div className="parking-legend-value occupied">
                        {props.placeTypeCounterValue.occupied}
                        <span className="margin-left-5 small">
                            ({isNaN(occupiedPercent) ? 0 : occupiedPercent.toFixed(1)}%)
                        </span>
                    </div>

                    <div className="parking-legend-value" style={{ color: props.placeTypeCounterValue.color }}>
                        {props.placeTypeCounterValue.free}
                        <span className="margin-left-5 small">
                            ({isNaN(freePercent) ? 0 : freePercent.toFixed(1)}%)
                        </span>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};
