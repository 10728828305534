import { faCheck, faEdit, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Button, ButtonGroup, Col, ControlLabel, FlexboxGrid, Form, FormControl } from 'rsuite';
import handleError from '../../../handlers/error.handler';
import { levelHandler } from '../../../handlers/levelHandler';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import noImage from '../../../style/assets/eye-slash-solid.svg';
import SecuredFragment from '../../Auth/SecuredFragment';
import DeleteParkingModal from './Modal/DeleteLevelModal';

type Props = {
    level: Record<string, any>;
    deleteModalOpen?: boolean;
    deleteLevel: Function;
    updateLevel: Function;
};

type State = {
    edit: boolean;
    formValue: Record<string, any>;
    level: Record<string, any>;
    loading: boolean;
    deleteModalOpen: boolean;
    deleted: boolean;
};

export default class Level extends React.Component<Props, State> {
    fileInput: any;
    constructor(props) {
        super(props);

        this.state = {
            edit: false,
            formValue: {},
            level: {},
            loading: true,
            deleteModalOpen: false,
            deleted: false,
        };

        this.setFormValue = this.setFormValue.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.edit = this.edit.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.fileInput = React.createRef();
        this.validImage = this.validImage.bind(this);
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get(`/levels/${this.props.level.id}`, { headers: authHeader() })
            .then(response => {
                let level = levelHandler(response.data);
                this.setState({
                    level: level,
                    loading: false,
                    formValue: {
                        name: response.data.name,
                        description: response.data.description,
                    },
                });
            });
    }

    setFormValue(formValue) {
        this.setState({
            formValue: formValue,
        });
    }

    toggleEditMode(e) {
        e.stopPropagation();
        this.setState({
            edit: !this.state.edit,
            formValue: {
                name: this.state.level.name,
                description: this.state.level.description,
            },
        });
    }

    edit(e) {
        e.stopPropagation();

        this.setState({
            loading: true,
            edit: false,
        });

        const parameters = { headers: authHeader() };
        const data = {
            id: this.state.level.id,
            name: this.state.formValue.name,
            description: this.state.formValue.description,
        };

        axiosService
            .getAxios()
            .put('/levels', data, parameters)
            .then(response => {
                this.setState({
                    loading: false,
                    level: { ...levelHandler(response.data), image: this.state.level.image },
                });
            });
    }

    openDeleteModal() {
        this.setState({
            deleteModalOpen: true,
        });
    }

    closeDeleteModal(deleted) {
        if (deleted) {
            this.setState({
                deleteModalOpen: false,
                deleted: true,
            });
        } else {
            this.setState({
                deleteModalOpen: false,
            });
        }
    }

    validImage() {
        this.setState({
            loading: true,
            edit: false,
        });

        const parameters = { headers: authHeader() };
        let data = new FormData();
        data.append('file', this.fileInput.current.files[0]);

        return axiosService
            .getAxios()
            .post(`/levels/picture/${this.state.level.id}`, data, parameters)
            .then(response => {
                this.setState({
                    loading: false,
                    edit: false,
                    level: levelHandler(response.data),
                });
            })
            .catch(err => {
                console.error(err);
                handleError(err);
                this.setState({
                    loading: false,
                });
                return Promise.reject(err);
            });
    }

    render() {
        return (
            <Loader show={this.state.loading}>
                <DeleteParkingModal
                    show={this.state.deleteModalOpen}
                    close={this.closeDeleteModal}
                    level={this.state.level}
                    deleteLevel={this.props.deleteLevel}
                />
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item data-cy="level-image-view" componentClass={Col} xs={4}>
                        {this.state.level.image ? (
                            <img
                                data-cy="image"
                                src={`data:image/${this.state.level.imageType};base64, ${this.state.level.image}`}
                                style={{
                                    height: 48,
                                    backgroundColor: 'white',
                                    lineHeight: 1,
                                }}
                                alt="Logo"
                            />
                        ) : (
                            <img
                                src={noImage}
                                style={{
                                    height: 48,
                                    backgroundColor: 'white',
                                    lineHeight: 1,
                                }}
                                alt="empty"
                            />
                        )}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={this.state.edit ? 20 : 16}>
                        <Form
                            data-cy="level-edit"
                            formValue={this.state.formValue}
                            onChange={formValue => this.setFormValue(formValue)}
                            fluid>
                            <FlexboxGrid align="middle" justify="end">
                                <FlexboxGrid.Item componentClass={Col} xs={10}>
                                    <ControlLabel className="form-control-label">
                                        <FormattedMessage id="level.edit.name" />
                                    </ControlLabel>
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item componentClass={Col} xs={14}>
                                    <FormControl name="name" plaintext={!this.state.edit} data-cy="level-edit-name" />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item componentClass={Col} xs={10}>
                                    <ControlLabel className="form-control-label">
                                        <FormattedMessage id="level.edit.description" />
                                    </ControlLabel>
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item componentClass={Col} xs={14}>
                                    <FormControl
                                        name="description"
                                        plaintext={!this.state.edit}
                                        data-cy="level-edit-description"
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            {this.state.edit && (
                                <Fragment>
                                    <FlexboxGrid align="middle" justify="end" style={{ marginTop: 10 }}>
                                        <FlexboxGrid.Item>
                                            <ButtonGroup>
                                                <Button
                                                    data-cy="level-edit-cancel"
                                                    color="red"
                                                    onClick={this.toggleEditMode}>
                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                </Button>

                                                <Button data-cy="level-edit-valid" color="green" onClick={this.edit}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </Button>
                                            </ButtonGroup>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>

                                    <FlexboxGrid align="middle" justify="end">
                                        <SecuredFragment authorizedRoles={[rolesConstants.level.UPDATE_PICTURE]}>
                                            <FlexboxGrid.Item componentClass={Col} xs={24}>
                                                <hr />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                                <ControlLabel className="form-control-label">
                                                    <FormattedMessage id="level.edit.image" />
                                                </ControlLabel>
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item data-cy="level-image" componentClass={Col} xs={14}>
                                                <input type="file" accept="image/*" ref={this.fileInput} />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item style={{ marginTop: 10 }}>
                                                <Button
                                                    data-cy="level-image-valid"
                                                    color="green"
                                                    onClick={this.validImage}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                </Button>
                                            </FlexboxGrid.Item>
                                        </SecuredFragment>
                                    </FlexboxGrid>
                                </Fragment>
                            )}
                        </Form>
                    </FlexboxGrid.Item>
                    {!this.state.edit && (
                        <FlexboxGrid.Item componentClass={Col} xs={4}>
                            <ButtonGroup>
                                <SecuredFragment authorizedRoles={[rolesConstants.level.UPDATE]}>
                                    <Button data-cy="level-edit-button" color="blue" onClick={this.toggleEditMode}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </SecuredFragment>

                                <SecuredFragment authorizedRoles={[rolesConstants.level.DELETE]}>
                                    <Button data-cy="level-delete-button" color="red" onClick={this.openDeleteModal}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </SecuredFragment>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    )}
                </FlexboxGrid>
            </Loader>
        );
    }
}
