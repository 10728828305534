import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, FlexboxGrid } from 'rsuite';
import { C_Values } from '../CountersPage';

type Props = {
    title: string;
    counterValue: C_Values;
    height: number;
};

export const ParkingStatDoughnut = (props: Props) => {
    const intl = useIntl();

    const data = {
        title: props.title,
        datasets: [
            {
                data: [props.counterValue.free, props.counterValue.occupied],
                backgroundColor: ['#008000', '#f44336'],
            },
        ],
        labels: [
            intl.formatMessage({ id: 'parking.occupationByLevel.tooltip.free' }),
            intl.formatMessage({ id: 'parking.occupationByLevel.tooltip.occupied' }),
        ],
    };

    const occupiedPercent =
        (props.counterValue.occupied / (props.counterValue.free + props.counterValue.occupied)) * 100;

    const freePercent = (props.counterValue.free / (props.counterValue.free + props.counterValue.occupied)) * 100;

    return (
        <>
            <p className="parking-level-name margin-bottom-10 padding-bottom-10">{props.title}</p>
            <FlexboxGrid align="middle" justify="space-between">
                <FlexboxGrid.Item componentClass={Col} xs={14}>
                    <Doughnut
                        data={data}
                        // height={this.props.size}
                        // width={donutSize}
                        height={props.height}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            aspectRatio: 1,
                            legend: {
                                display: false,
                            },
                        }}
                    />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} xs={10} style={{ width: 130 }}>
                    <div className="parking-legend-title">
                        <FormattedMessage id="counter.parking.occupied" />
                    </div>

                    <div className="parking-legend-value">
                        {props.counterValue.occupied} <FormattedMessage id="counter.parking.parkingSpace" />
                        <span className="margin-left-5 small occupied">
                            ({isNaN(occupiedPercent) ? 0 : occupiedPercent.toFixed(1)}%)
                        </span>
                    </div>

                    <div className="parking-legend-title margin-top-5">
                        <FormattedMessage id="counter.parking.free" />
                    </div>
                    <div className="parking-legend-value">
                        {props.counterValue.free} <FormattedMessage id="counter.parking.parkingSpace" />
                        <span className="margin-left-5 small" style={{ color: '#008000' }}>
                            ({isNaN(freePercent) ? 0 : freePercent.toFixed(1)}%)
                        </span>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};
