import React from 'react';
import { useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { Loader, Message } from 'rsuite';

type Props = {
    isLoading: boolean;
    title: string;
    changeLog?: string;
    hasError: boolean;
};

export const AutomaticUpdateChangelog = ({ isLoading, title, changeLog, hasError }: Props) => {
    const intl = useIntl();
    // return (
    //     <Fragment>
    // <h4 style={{ color: '#673ab7' }}>
    //     {title}
    // </h4>
    //         {isLoading && <Loader />}
    //         {hasError && <Message type="error" description={intl.formatMessage({ id: 'update.automatic.fetchChangelogError' }) } />}
    //         {changeLog && <ReactMarkdown className="changelog" source={changeLog} />}
    //     </Fragment>
    // );

    return (
        <>
            {isLoading && <Loader />}
            {hasError && (
                <Message
                    type="error"
                    description={intl.formatMessage({ id: 'update.automatic.fetchChangelogError' })}
                />
            )}
            {changeLog && <ReactMarkdown className="changelog" source={changeLog} />}
        </>
    );
};
