import moment from 'moment';
import {
    VehicleCounter,
    VehicleCounterInterface,
    VehicleCounterWebSocketInterface,
} from '../vehicleCounter/VehicleCounter';

export class MapVehicleCounter {
    private _createdAt: string;
    private _createdBy: string;
    private _displayValue: string[];
    private _geoJSON: number[];
    private _id: number;
    private _isShow: boolean;
    private _label: string;
    private _updatedAt: string;
    private _updatedBy: string;
    private _vehicleCounter: VehicleCounter;

    constructor(mapVehicleCounter_) {
        const {
            createdAt,
            createdBy,
            displayValue,
            geoJSON,
            id,
            isShow,
            label,
            updatedAt,
            updatedBy,
            vehicleCounter,
        } = mapVehicleCounter_;

        this._createdAt = createdAt;
        this._createdBy = createdBy;
        this._displayValue = displayValue;
        this._geoJSON = geoJSON;
        this._id = id;
        this._isShow = isShow;
        this._label = label;
        this._updatedAt = updatedAt;
        this._updatedBy = updatedBy;
        this._vehicleCounter = new VehicleCounter(vehicleCounter);
    }

    public getCreatedAt() {
        return moment(this._createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreatedBy() {
        return this._createdBy;
    }

    public getDisplayValue() {
        return this._displayValue;
    }

    public getGeoJSON() {
        return this._geoJSON;
    }

    public getId() {
        return this._id;
    }

    public getIsShow() {
        return this._isShow;
    }

    public getLabel() {
        return this._label;
    }

    public getUpdatedAt() {
        return moment(this._updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getUpdatedBy() {
        return this._updatedBy;
    }

    public getVehicleCounter() {
        return this._vehicleCounter;
    }

    private setUpdatedAt(updatedAt_) {
        if (updatedAt_ !== this._updatedAt) {
            this._updatedAt = updatedAt_;
        }
    }

    private setUpdatedBy(updatedBy_) {
        if (updatedBy_ !== this._updatedBy) {
            this._updatedBy = updatedBy_;
        }
    }

    private setIsShow(isShow_) {
        if (isShow_ !== this._isShow) {
            this._isShow = isShow_;
        }
    }

    private setDisplayValue(displayValue_) {
        if (displayValue_.length !== this._displayValue.length) this._displayValue = displayValue_;
        if (JSON.stringify(displayValue_) !== JSON.stringify(this._displayValue)) this._displayValue = displayValue_;
    }

    public updateMapVehicleCounter(values: UpdateMapVehicleCounter) {
        const { updatedAt, updatedBy, isShow, displayValue, vehicleCounter, isShowOnZone } = values;

        this.setUpdatedAt(updatedAt);
        this.setUpdatedBy(updatedBy);
        this.setIsShow(isShow);
        this.setDisplayValue(displayValue);
        this._vehicleCounter.updateVehicleCounter(vehicleCounter);
    }

    public updateVehicleCounter(vehicleCounter: VehicleCounterWebSocketInterface) {
        this._vehicleCounter.updateVehicleCounterWebSocket(vehicleCounter);
    }
}

interface UpdateMapVehicleCounter {
    id: number;
    label: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    geoJSON: Array<number>;
    isShow: boolean;
    isShowOnZone: boolean;
    displayValue: Array<string>;
    vehicleCounter: VehicleCounterInterface;
}
