import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';
import Task from '../../../../../handlers/Event/Task';
import { CountersPart } from './Parts/CountersPart';

type Props = {
    task: Task;
};

export const LPSensorForceColor = (props: Props) => {
    return (
        <>
            <FormattedMessage id="tasks.forceLPSensorColor" />
            <Tag
                data-cy="tasks-forceLPSensorColor"
                className="margin-left-5"
                style={{
                    backgroundColor: props.task.getForceOption().getHexColor(),
                    color: '#FFF',
                }}>
                <FormattedMessage id="sensor.force.color" />
            </Tag>

            <CountersPart counters={props.task.getCounters()} />
        </>
    );
};
