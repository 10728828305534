type Props = {
    color: string;
};

export const ParkingIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} width="64" height="512" />
            <rect fill={color} x="448" width="64" height="512" />
            <rect fill={color} x="64" width="384" height="64" fill-rule="evenodd" />
            <rect fill={color} x="64" y="448" width="384" height="64" fill-rule="evenodd" />
            <rect fill={color} x="128" y="96" width="64" height="320" />
            <rect fill={color} x="192" y="96" width="160" height="64" />
            <rect fill={color} x="192" y="256" width="160" height="64" />
            <rect fill={color} x="320" y="128" width="64" height="160" />
        </g>
    </svg>
);
