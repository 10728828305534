import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Control from 'react-leaflet-control';
import { Button, ButtonGroup, Tooltip, Whisper } from 'rsuite';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';

const BottomLeftControls = props => {
    const isEditMode =
        props.current.value === 'globalView' ||
        props.current.value === 'editPlaces' ||
        props.current.value === 'editZones' ||
        props.current.value === 'editDisplays' ||
        props.current.value === 'editPassCount' ||
        props.current.value === 'calibration';
    return (
        <Control position="bottomleft">
            <ButtonGroup>
                <Whisper
                    trigger="hover"
                    placement={'topStart'}
                    speaker={
                        <Tooltip>
                            <FormattedMessage id="map.mode.viewMode" />
                        </Tooltip>
                    }>
                    <Button
                        onClick={() => props.service.send('SET_VIEW_MODE')}
                        data-cy="view-Mode"
                        className="button-map"
                        appearance={props.isEdit ? 'ghost' : 'primary'}
                        color="green"
                        size="md">
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </Whisper>
                <SecuredFragment authorizedRoles={[rolesConstants.map.EDIT]}>
                    <Whisper
                        trigger="hover"
                        placement={'topStart'}
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="map.mode.editMode" />
                            </Tooltip>
                        }>
                        <Button
                            onClick={() => {
                                props.service.send('SET_EDIT_MODE');
                            }}
                            data-cy="edit-Mode"
                            loading={props.current.value !== 'viewMode' && !isEditMode}
                            disabled={props.current.value !== 'viewMode'}
                            className="button-map"
                            appearance={props.isEdit ? 'primary' : 'ghost'}
                            color="orange"
                            size="md">
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </Whisper>
                </SecuredFragment>
            </ButtonGroup>
        </Control>
    );
};

BottomLeftControls.propTypes = {
    service: PropTypes.object,
    isEdit: PropTypes.bool,
    current: PropTypes.any,
};

export default BottomLeftControls;
