import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    ControlLabel,
    DatePicker,
    Form,
    FormControl,
    FormGroup,
    Modal,
    Toggle,
} from 'rsuite';
import { User } from '../../../../../../../handlers/profile/User';
import { authHeader } from '../../../../../../../redux/helpers';
import { axiosService } from '../../../../../../../redux/services';

type Props = {
    open: boolean;
    onHide: (shouldReload) => void;
    user?: User;
};

type FormValue = {
    expirationDate: Date;
    label: string;
    path: string;
    isEnable: boolean;
};

export const CreateRestFullModal = (props: Props) => {
    const intl = useIntl();

    const [formValue, setFormValue] = React.useState<FormValue>({
        expirationDate: moment().add(1, 'year').toDate(),
        label: '',
        path: '',
        isEnable: true,
    });

    const [isCreating, setIsCreating] = React.useState<boolean>(false);

    useEffect(() => {
        if (props.open) {
            setFormValue({
                expirationDate: moment().add(1, 'year').toDate(),
                label: '',
                path: '',
                isEnable: true,
            });
        }
    }, [props.open]);

    const handleFormChange = (value: FormValue) => {
        setFormValue(value);
    };

    const handleValid = () => {
        setIsCreating(true);

        if (!props.user) {
            axiosService
                .getAxios()
                .post(
                    '/rest-full',
                    {
                        expirationDate: formValue.expirationDate,
                        label: formValue.label,
                        path: formValue.path,
                        isEnable: formValue.isEnable,
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'user.restFull.create.success' }), 5000);
                    props.onHide(true);
                })
                .catch(err => {
                    console.error(err);
                    Alert.error(intl.formatMessage({ id: 'user.restFull.create.error' }), 5000);
                })
                .finally(() => {
                    setIsCreating(false);
                });
        } else {
            axiosService
                .getAxios()
                .post(
                    '/rest-full/forUser',
                    {
                        userId: props.user.getId(),
                        expirationDate: formValue.expirationDate,
                        label: formValue.label,
                        path: formValue.path,
                        isEnable: formValue.isEnable,
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'user.restFull.create.success' }), 5000);
                    props.onHide(true);
                })
                .catch(err => {
                    console.error(err);
                    Alert.error(intl.formatMessage({ id: 'user.restFull.create.error' }), 5000);
                })
                .finally(() => {
                    setIsCreating(false);
                });
        }
    };

    const handleCancel = () => {
        if (!isCreating) props.onHide(false);
    };

    return (
        <Modal backdrop="static" show={props.open} onHide={handleCancel}>
            <Modal.Header>
                <Modal.Title>{intl.formatMessage({ id: 'user.restFull.create.title' })}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form formValue={formValue} onChange={formValue => handleFormChange(formValue as FormValue)} fluid>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.restFull.create.label" />
                        </ControlLabel>
                        <FormControl name="label" data-cy="user-restFull-create-label" />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.restFull.create.path" />
                        </ControlLabel>
                        <FormControl name="path" data-cy="user-restFull-create-path" />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.restFull.create.expirationDate" />
                        </ControlLabel>
                        <FormControl
                            accepter={DatePicker}
                            format="DD/MM/YYYY HH:mm"
                            name="expirationDate"
                            onChangeCalendarDate={value => setFormValue({ ...formValue, expirationDate: value })}
                            data-cy="user-restFull-create-expirationDate"
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.restFull.create.isEnable" />
                        </ControlLabel>
                        <FormControl
                            accepter={Toggle}
                            name="isEnable"
                            value={formValue.isEnable}
                            checked={formValue.isEnable}
                            data-cy="user-restFull-create-isEnable"
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={handleCancel}
                        data-cy="user-restFull-create-cancel"
                        color="red"
                        disabled={isCreating}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={handleValid}
                        data-cy="user-restFull-create-valid"
                        appearance="primary"
                        loading={isCreating}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
