import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Loader, Tag, TagGroup } from 'rsuite';
import { LPDisplay } from '../../../../../../handlers/lpDisplays/LPDisplay';
import { MapLPMatrixDisplayV2 } from '../../../../../../handlers/map/MapLPMatrixDisplayV2';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';

type Props = {
    lpDisplay: LPDisplay[];
};

export const LPDisplayPart = (props: Props) => {
    const [isLoading, setIsLoading] = React.useState(true);

    const [mapLPDisplay, setMapLPDisplay] = React.useState<MapLPMatrixDisplayV2[] | null>(null);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/map-lp-matrix-displays-v2', { headers: authHeader() })
            .then(mapLPDisplaysResponse => {
                setMapLPDisplay(mapLPDisplaysResponse.data.map(data => new MapLPMatrixDisplayV2(data)));
                setIsLoading(false);
            });
    }, []);

    if (isLoading || !mapLPDisplay) {
        return <Loader />;
    }

    return (
        <>
            <Divider style={{ color: 'purple' }}>
                <FormattedMessage id="event.task.displays" />
            </Divider>
            <TagGroup>
                {props.lpDisplay.map(display => {
                    const currentMapLPDisplay = mapLPDisplay.find(
                        mapDisplay => mapDisplay.getLpMatrixDisplayV2().getId() === display.id
                    );

                    if (currentMapLPDisplay) {
                        return (
                            <Tag
                                data-cy="task-level"
                                color="blue"
                                key={currentMapLPDisplay.getLpMatrixDisplayV2().getId()}>
                                {currentMapLPDisplay.label}
                            </Tag>
                        );
                    }
                })}
            </TagGroup>
        </>
    );
};
