import { TCMDisplayForceEnum } from '../../Components/Event/Modal/Tasks/CreateTaskType';
import { rgbToHex } from '../../utils/colorUtils';
import {
    LPDisplayForceTypeCountersInterface,
    LPDisplayForceTypeVirtualDisplaysInterface,
} from '../lpDisplays/LPDisplayForceType';
import { TaskColorOption } from './Task';

export default class TaskForceOption {
    private _color?: string | TaskColorOption;
    private _text?: TCMDisplayForceEnum;
    private _forceType?: TCMDisplayForceEnum;
    private _counters?: LPDisplayForceTypeCountersInterface[];
    private _topologyNum?: number;
    private _virtualDisplays?: LPDisplayForceTypeVirtualDisplaysInterface[];
    private _gpioNumber?: number;
    private _gpioEnabled?: boolean;

    constructor(data: TaskForceOptionInterface) {
        this._color = data.color;
        this._text = data.text;
        this._forceType = data.forceType;
        this._counters = data.counters;
        this._topologyNum = data.topologyNum;
        this._virtualDisplays = data.virtualDisplays;
        this._gpioNumber = data.gpioNumber;
        this._gpioEnabled = data.gpioEnabled;
    }

    public getStringColor(): string | undefined {
        if (!this._color || typeof this._color === 'string') {
            return this._color;
        }
    }

    public getHexColor(): string | undefined {
        if (!this._color || typeof this._color === 'string') {
            return this._color;
        } else {
            return rgbToHex(this._color.red, this._color.green, this._color.blue);
        }
    }

    public getText(): TCMDisplayForceEnum | undefined {
        return this._text;
    }

    public getForceType(): TCMDisplayForceEnum | undefined {
        return this._forceType;
    }

    public getForceTypeData(): TaskForceOptionInterface {
        return {
            color: this.getHexColor() || this.getStringColor(),
            text: this.getText(),
            forceType: this.getForceType(),
            counters: this.getCounters(),
            topologyNum: this.getTopologyNum(),
            virtualDisplays: this.getVirtualDisplays(),
        };
    }

    public getCounters(): LPDisplayForceTypeCountersInterface[] | undefined {
        return this._counters;
    }

    public getTopologyNum(): number | undefined {
        return this._topologyNum;
    }

    public getVirtualDisplays(): LPDisplayForceTypeVirtualDisplaysInterface[] | undefined {
        return this._virtualDisplays;
    }

    public getGpioNumber(): number | undefined {
        return this._gpioNumber;
    }

    public getGpioEnabled(): boolean | undefined {
        return this._gpioEnabled;
    }
}

export interface TaskForceOptionInterface {
    color?: string;
    text?: TCMDisplayForceEnum;
    forceType?: TCMDisplayForceEnum;
    counters?: LPDisplayForceTypeCountersInterface[];
    topologyNum?: number;
    virtualDisplays?: LPDisplayForceTypeVirtualDisplaysInterface[];
    gpioNumber?: number;
    gpioEnabled?: boolean;
}
