import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';
import { Profile } from '../../../../../../handlers/profile/Profile';
import PanelHeader from '../../../../../Custom/PanelHeader';
import { EditProfileInformationPart } from './EditProfileInformationPart';
import { ViewProfileInformationPart } from './ViewProfileInformationPart';

type Props = {
    profile: Profile;
    updateProfiles: () => void;
    reloadProfiles: () => void;
    reloadUsers: () => void;
};

export const ProfileInformationCard = (props: Props) => {
    const intl = useIntl();
    const [editMode, setEditMode] = React.useState(false);

    const handleSetEditMode = (editMode: boolean) => {
        setEditMode(editMode);

        if (!editMode) {
            props.updateProfiles();
        }
    };

    return (
        <Panel
            shaded
            bordered
            className="panel-small"
            data-cy="profile-information-card"
            bodyFill
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'profile.information' })}
                    buttons={[
                        <Button
                            data-cy="profileInformation.editButton"
                            onClick={() => handleSetEditMode(true)}
                            appearance="primary"
                            disabled={props.profile.getName() === 'Admin' || editMode}
                            size="sm">
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {editMode ? (
                <EditProfileInformationPart
                    handleViewMode={() => handleSetEditMode(false)}
                    profile={props.profile}
                    reloadProfiles={props.reloadProfiles}
                    reloadUsers={props.reloadUsers}
                />
            ) : (
                <ViewProfileInformationPart profile={props.profile} />
            )}
        </Panel>
    );
};
