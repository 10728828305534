import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Modal } from 'rsuite';
import { webSocketService } from '../../../../../redux/services';
import AutomaticUpdate from './Automatic';
import ManualUpdate from './Manual';

type Props = {
    show: boolean;
    closeUpdateModal: Function;
};

type State = {
    automatic: boolean;
    step: number;
};

export default class UpdateModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            automatic: true,
            step: 1,
        };

        this.toggleAutomaticMode = this.toggleAutomaticMode.bind(this);
        this.toggleManualMode = this.toggleManualMode.bind(this);
    }

    toggleAutomaticMode() {
        this.setState({
            automatic: true,
        });
    }

    toggleManualMode() {
        this.setState({
            automatic: false,
        });
    }

    componentDidMount() {
        webSocketService.joinRoom('configurations');
    }

    render() {
        return (
            <Modal
                full
                size="lg"
                show={this.props.show}
                overflow={false}
                onHide={() => this.props.closeUpdateModal(false)}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="configuration.updateModal.title" />
                    </Modal.Title>
                    <Modal.Body>
                        <ButtonGroup justified className="margin-bottom-20">
                            <Button
                                onClick={this.toggleAutomaticMode}
                                appearance={this.state.automatic ? 'primary' : 'ghost'}>
                                <FormattedMessage id="update.automatic" />
                            </Button>
                            <Button
                                onClick={this.toggleManualMode}
                                appearance={this.state.automatic ? 'ghost' : 'primary'}>
                                <FormattedMessage id="update.manual" />
                            </Button>
                        </ButtonGroup>

                        {this.state.automatic ? (
                            <AutomaticUpdate step={this.state.step} close={this.props.closeUpdateModal} />
                        ) : (
                            <ManualUpdate close={this.props.closeUpdateModal} />
                        )}
                    </Modal.Body>
                </Modal.Header>
            </Modal>
        );
    }
}
