import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Col, ControlLabel, FlexboxGrid, Form, FormControl, Modal, Toggle } from 'rsuite';
import ModalFooter from 'rsuite/lib/Modal/ModalFooter';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    network: Record<string, any>;
    updateNetwork: Function;
    closeModal: Function;
    show: boolean;
};

type State = {
    loading: boolean;
    formValue: FormValue;
};

type FormValue = {
    ipAddress: string;
    mask: string;
    gateway: string;
    isDHCP: boolean;
};

export default class EditNetworkModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            formValue: {
                ipAddress: this.props.network.ipAddress,
                mask: this.props.network.mask,
                gateway: this.props.network.gateway,
                isDHCP: this.props.network.isDHCP,
            },
        };

        this.editNetwork = this.editNetwork.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.network !== this.props.network) {
            this.setState({
                formValue: {
                    ipAddress: this.props.network.ipAddress,
                    mask: this.props.network.mask,
                    gateway: this.props.network.gateway,
                    isDHCP: this.props.network.isDHCP,
                },
            });
        }
    }

    editNetwork() {
        this.setState({
            loading: true,
        });

        axiosService
            .getAxios()
            .put('/configuration/network', this.state.formValue, { headers: authHeader() })
            .then(() => {
                this.setState({
                    loading: false,
                });
                this.props.updateNetwork(this.state.formValue);
                this.props.closeModal();
            });
    }

    onChange(formValue) {
        let gateway = formValue.gateway;

        if (formValue.isDHCP) {
            gateway = '';
        }

        this.setState({
            formValue: { ...formValue, gateway },
        });
    }

    render() {
        return (
            <Modal size="lg" show={this.props.show} overflow={true} onHide={() => this.props.closeModal()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="configuration.networkConfig.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onChange={this.onChange} formValue={this.state.formValue} fluid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={24} md={12} className="margin-bottom-15">
                                <ControlLabel>
                                    <FormattedMessage id="configuration.networkConfig.isDHCP" />
                                </ControlLabel>
                                <FormControl
                                    accepter={Toggle}
                                    name="isDHCP"
                                    checkedChildren="DHCP"
                                    unCheckedChildren="Static"
                                    defaultChecked={this.state.formValue.isDHCP}
                                    disabled={this.state.loading}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={24} md={12} className="margin-bottom-15">
                                <ControlLabel>
                                    <FormattedMessage id="configuration.networkConfig.ipAddress" />
                                </ControlLabel>
                                <FormControl name="ipAddress" disabled={this.state.formValue.isDHCP || this.state.loading} />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={24} md={12} className="margin-bottom-15">
                                <ControlLabel>
                                    <FormattedMessage id="configuration.networkConfig.mask" />
                                </ControlLabel>
                                <FormControl name="mask" disabled={this.state.formValue.isDHCP || this.state.loading} />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={24} md={12} className="margin-bottom-15">
                                <ControlLabel>
                                    <FormattedMessage id="configuration.networkConfig.gateway" />
                                </ControlLabel>
                                <FormControl name="gateway" disabled={this.state.formValue.isDHCP || this.state.loading} />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </Modal.Body>
                <ModalFooter>
                    <ButtonGroup>
                        <Button color="red" onClick={() => this.props.closeModal()} disabled={this.state.loading}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                        <Button color="green" onClick={this.editNetwork} loading={this.state.loading}>
                            <FontAwesomeIcon icon={faCheck}/>
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
        );
    }
}
