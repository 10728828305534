import React, { Fragment } from 'react';
import { RowRecord } from 'react-data-table-component';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid } from 'rsuite';
import { secondsToHours } from '../../../../handlers/helper';
import {
    LPSensorUsHeightValues,
    LPSensorUsSensValues,
} from '../../../../handlers/ipCanDevices/Sensor/LPSensorUsConfig';

type Props = RowRecord;

export default class LPSensorUpdateUsAndFilterConfig extends React.Component<Props> {
    render = () => (
        <Fragment>
            <FlexboxGrid style={{ margin: 15 }} justify="space-around">
                {/* Config */}
                <FlexboxGrid.Item>
                    <h5>
                        <FormattedMessage id="lpSensor.history.updateFilterConfig" />
                    </h5>

                    <div data-cy="lpSensor-history-nbSalveMin">
                        <FormattedMessage id="lpSensor.history.nbSalveMin" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filter.nbSalveMin}</span>
                    </div>
                    <div data-cy="lpSensor-history-nbSalveMax">
                        <FormattedMessage id="lpSensor.history.nbSalveMax" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filter.nbSalveMax}</span>
                    </div>
                    <div data-cy="lpSensor-history-nbInterSalveMin">
                        <FormattedMessage id="lpSensor.history.nbInterSalveMin" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filter.nbInterSalveMin}</span>
                    </div>
                    <div data-cy="lpSensor-history-nbInterSalveMax">
                        <FormattedMessage id="lpSensor.history.nbInterSalveMax" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filter.nbInterSalveMax}</span>
                    </div>
                    <div data-cy="lpSensor-history-maskFreeToOccupied">
                        <FormattedMessage id="lpSensor.history.maskFreeToOccupied" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filter.maskFree}</span>
                    </div>
                    <div data-cy="lpSensor-history-maskOccupiedToFree">
                        <FormattedMessage id="lpSensor.history.maskOccupiedToFree" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filter.maskOccupied}</span>
                    </div>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item>
                    <h5>
                        <FormattedMessage id="lpSensor.history.updateSensorConfig" />
                    </h5>
                    <div data-cy="lpSensor-history-c1">
                        <FormattedMessage id="lpSensor.history.c1" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            {LPSensorUsHeightValues(this.props.data.option.us.us1.height)}
                        </span>
                    </div>
                    <div data-cy="lpSensor-history-c2">
                        <FormattedMessage id="lpSensor.history.c2" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            {LPSensorUsHeightValues(this.props.data.option.us.us2.height)}
                        </span>
                    </div>
                    <div data-cy="lpSensor-history-sDetection">
                        <FormattedMessage id="lpSensor.history.sDetection" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            {LPSensorUsSensValues(this.props.data.option.us.us1.sens)}
                        </span>
                    </div>
                    <div data-cy="lpSensor-history-sRelease">
                        <FormattedMessage id="lpSensor.history.sRelease" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            {LPSensorUsSensValues(this.props.data.option.us.us2.sens)}
                        </span>
                    </div>
                    <div data-cy="lpSensor-history-overstay">
                        <FormattedMessage id="lpSensor.history.overstay" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            {secondsToHours(this.props.data.option.us.overstay).stringified}
                        </span>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Fragment>
    );
}
