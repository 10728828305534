import L from 'leaflet';
import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Alert, Loader, Modal } from 'rsuite';
import { interpret } from 'xstate';
import { MapVehicleCounter } from '../../../handlers/map/MapVehicleCounter';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import { PassCountMachine } from '../Hooks/PassCountMachine';
import CreatePassCountIcon from '../Icons/CreatePassCountIcon';
import PassCountIcon from '../Icons/PassCount/PassCountIcon';
import CreatePassCountModal from '../Modal/PassCount/CreatePassCountModal';

type Props = {
    levelId: number;
    reloadPassCount: Function;
    passCounts: MapVehicleCounter[];
    editMode: boolean;
    isEdit: boolean;
} & WrappedComponentProps;

type State = {
    current: any;
    createPassCountOpen: boolean;
    createPassCountGeoJSON: any;
};

class PassCountLayer extends React.Component<Props, State> {
    service: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            current: PassCountMachine.initialState,
            createPassCountOpen: false,
            createPassCountGeoJSON: [],
        };

        this.service = interpret(
            PassCountMachine.withContext({
                ...PassCountMachine.context,
                levelId: this.props.levelId,
                intl: this.props.intl,
            })
        ).onTransition(current => {
            this.onTransition(current);
        });
    }

    onTransition = current => {
        this.setState(
            {
                current,
            },
            () => {
                if (current.matches('reload')) {
                    this.props.reloadPassCount('placeCounts');
                    this.service.send('vehicleCounters');
                }
            }
        );
    };

    passCountCreated = event => {
        let geojson = [event.layer._latlng.lat, event.layer._latlng.lng];

        event.layer.remove();

        this.setState({
            createPassCountOpen: true,
            createPassCountGeoJSON: geojson,
        });
    };

    passCountDeleted = event => {
        let passCountsId: Array<number> = [];

        for (let l in event.layers._layers) {
            passCountsId.push(parseInt(event.layers._layers[l].options.name));
        }

        axiosService
            .getAxios()
            .delete('/map-vehicle-counters', {
                data: {
                    tabId: passCountsId,
                },
                headers: authHeader(),
            })
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'map.passCount.deletePassCount.success' }));
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage(
                        { id: 'map.passCount.deletePassCount.error' },
                        {
                            error: err,
                        }
                    )
                );
            })
            .finally(() => {
                this.props.reloadPassCount();
            });
    };

    passCountEdited = event => {
        let passCountIds: any = [];

        for (let l in event.layers._layers) {
            passCountIds.push({
                id: parseInt(event.layers._layers[l].options.name),
                geoJSON: [event.layers._layers[l]._latlng.lat, event.layers._layers[l]._latlng.lng],
            });
        }

        axiosService
            .getAxios()
            .put(
                '/map-vehicle-counters',
                {
                    mapVehicleCounterTab: passCountIds,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'map.passCount.editPassCount.success' }));
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage(
                        { id: 'map.passCount.editPassCount.error' },
                        {
                            error: err,
                        }
                    )
                );
            })
            .finally(() => {
                this.props.reloadPassCount();
            });
    };

    reloadPassCount = () => {
        this.props.reloadPassCount('vehicleCounters');
    };

    closeCreatePassCountModal = shouldReload => {
        this.setState({
            createPassCountOpen: false,
            createPassCountGeoJSON: [],
        });

        if (shouldReload) {
            this.props.reloadPassCount();
        }
    };

    render() {
        const { current } = this.state;

        // @ts-ignore
        L.EditToolbar.Delete.include({
            removeAllLayers: false,
        });

        return (
            <FeatureGroup>
                <Modal backdrop={'static'} show={current.context.loading}>
                    <Modal.Body className="text-center">
                        <Loader content={this.props.intl.formatMessage({ id: 'map.passCount.loading' })} vertical />
                    </Modal.Body>
                </Modal>

                {this.props.editMode && (
                    <Fragment>
                        <EditControl
                            onCreated={this.passCountCreated}
                            onDeleted={this.passCountDeleted}
                            onEdited={this.passCountEdited}
                            position="topright"
                            draw={{
                                rectangle: false,
                                circle: false,
                                marker: {
                                    icon: CreatePassCountIcon,
                                },
                                circlemarker: false,
                                polygon: false,
                                polyline: false,
                            }}
                            edit={{
                                delete: {
                                    removeAllLayers: false,
                                },
                            }}
                        />

                        <SecuredFragment authorizedRoles={[rolesConstants.mapVehicleCounters.CREATE]}>
                            <CreatePassCountModal
                                onHide={this.closeCreatePassCountModal}
                                show={this.state.createPassCountOpen}
                                service={this.service}
                                geoJSON={this.state.createPassCountGeoJSON}
                            />
                        </SecuredFragment>
                    </Fragment>
                )}

                {this.props.passCounts.map(passCount => {
                    if (passCount.getIsShow()) {
                        return (
                            <PassCountIcon
                                editMap={this.props.isEdit}
                                editMode={this.props.editMode}
                                passCount={passCount}
                                key={passCount.getId()}
                                reloadPassCount={this.reloadPassCount}
                            />
                        );
                    } else {
                        return (
                            <SecuredFragment
                                authorizedRoles={[rolesConstants.mapVehicleCounters.UPDATE_SENSOR]}
                                key={passCount.getId()}>
                                <PassCountIcon
                                    editMap={this.props.isEdit}
                                    editMode={this.props.editMode}
                                    passCount={passCount}
                                    key={passCount.getId()}
                                    reloadPassCount={this.reloadPassCount}
                                />
                            </SecuredFragment>
                        );
                    }
                })}
            </FeatureGroup>
        );
    }
}

export default injectIntl(PassCountLayer);
