import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Loader, Message } from 'rsuite';
import { Parking, ParkingInterface } from '../../handlers/parking/Parking';
import { SensorPlaceType } from '../../handlers/sensorPlaceType/SensorPlaceType';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { ParkingPanel } from './ParkingPanel';

type Props = {} & WrappedComponentProps;

type State = {
    parkings: Parking[];
    sensorPlaceTypes: SensorPlaceType[];
    counters: CountersValues;
    loading: boolean;
    shouldUpdateUI: boolean;
};

export type CountersValues = {
    parkings: ParkingCounter[];
    levels: LevelCounter[];
};

export type ParkingCounter = {
    id: number;
    parkingId: number;
    counterValue: CounterValue;
    parkingName: string;
};

export type LevelCounter = { id: number; levelId: number; counterValue: CounterValue; levelName: string };

export type CounterValue = {
    [key: string]: C_Values;
    all: C_Values;
};

export type C_Values = {
    forced: number;
    free: number;
    occupied: number;
    overstayFree: number;
    overstayOccupied: number;
    total: number;
};

let threadUI: any = null;

class CountersPage extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            parkings: [],
            sensorPlaceTypes: [],
            counters: {
                parkings: [],
                levels: [],
            },
            loading: true,
            shouldUpdateUI: false,
        };
    }

    componentDidMount() {
        const fetchSensorPlaceTypes = axiosService.getAxios().get('/sensor-place-type', { headers: authHeader() });

        const fetchParking = axiosService.getAxios().get<ParkingInterface[]>('/parkings', {
            headers: authHeader(),
        });

        const fetchCounters = axiosService.getAxios().get<any[]>('/counters', {
            headers: authHeader(),
        });

        Promise.all([fetchSensorPlaceTypes, fetchParking, fetchCounters]).then(values => {
            const sensorPlaceTypes = values[0].data.map(sensorPlaceType => new SensorPlaceType(sensorPlaceType));
            const parkings = values[1].data.map(parking => new Parking(parking));
            const countersWithoutZones = values[2].data.filter(counter => counter.type !== 'Zone');

            const parkingsCounters: ParkingCounter[] = [];
            const levelsCounters: LevelCounter[] = [];

            countersWithoutZones.forEach(counter => {
                if (counter.type === 'Park') {
                    parkingsCounters.push({
                        id: counter.id,
                        parkingId: counter.parking.id,
                        parkingName: counter.parking.name,
                        counterValue: {
                            all: {
                                forced: 0,
                                free: 0,
                                occupied: 0,
                                overstayFree: 0,
                                overstayOccupied: 0,
                                total: 0,
                            },
                        },
                    });
                } else if (counter.type === 'Level') {
                    levelsCounters.push({
                        id: counter.id,
                        levelId: counter.level.id,
                        levelName: counter.level.name,
                        counterValue: {
                            all: {
                                forced: 0,
                                free: 0,
                                occupied: 0,
                                overstayFree: 0,
                                overstayOccupied: 0,
                                total: 0,
                            },
                        },
                    });
                }
            });

            this.setState({
                sensorPlaceTypes,
                parkings,
                counters: {
                    parkings: parkingsCounters,
                    levels: levelsCounters,
                },
                loading: false,
                shouldUpdateUI: true,
            });

            // Ask data to initialize all counters
            axiosService.getAxios().get('/counters/status', { headers: authHeader() });
        });

        webSocketService.joinRoom('counter');

        webSocketService.onEvent('counter:update', this.handleWebSocket);
    }

    componentWillUnmount() {
        webSocketService.offEvent('counter:update', this.handleWebSocket);
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (nextState.shouldUpdateUI) {
            this.setState({
                shouldUpdateUI: false,
            });

            return true;
        }

        return false;
    }

    handleWebSocket = data => {
        const currentParkingsCounters = [...this.state.counters.parkings];
        const currentLevelsCounters = [...this.state.counters.levels];

        const foundParkingCounter = currentParkingsCounters.find(counter => counter.id === data.id);
        const foundLevelCounter = currentLevelsCounters.find(counter => counter.id === data.id);

        if (foundParkingCounter) {
            foundParkingCounter.counterValue = data.counterValue;

            this.setState({
                counters: {
                    ...this.state.counters,
                    parkings: currentParkingsCounters,
                },
            });
        }

        if (foundLevelCounter) {
            foundLevelCounter.counterValue = data.counterValue;

            this.setState({
                counters: {
                    ...this.state.counters,
                    levels: currentLevelsCounters,
                },
            });
        }

        if ((foundParkingCounter || foundLevelCounter) && threadUI === null) {
            threadUI = setTimeout(() => {
                this.setState({
                    shouldUpdateUI: true,
                });
                threadUI = null;
            }, 2000);
        }

        return;
    };

    render() {
        if (this.state.loading) {
            return (
                <Message
                    type="info"
                    className="text-center"
                    description={
                        <Loader
                            content={this.props.intl.formatMessage({ id: 'counters.fetchParkings.loading' })}
                            vertical
                        />
                    }
                />
            );
        }

        if (this.state.parkings.length === 0) {
            return (
                <Message
                    type="warning"
                    className="text-center"
                    description={this.props.intl.formatMessage({ id: 'parking.noParkings' })}
                />
            );
        }

        return (
            <>
                {this.state.parkings.map(parking => {
                    return (
                        <ParkingPanel
                            key={parking.getId()}
                            parking={parking}
                            sensorPlaceTypes={this.state.sensorPlaceTypes}
                            counters={this.state.counters}
                        />
                    );
                })}
            </>
        );
    }
}

export default injectIntl(CountersPage);
