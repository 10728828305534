import { faCar, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid, Tooltip, Whisper } from 'rsuite';

export default function lpSensorAutoCalibration(isStaring) {
    return (
        <Whisper
            placement="right"
            trigger="hover"
            speaker={
                <Tooltip>
                    <FormattedMessage id="history.lpSensorAutoCalibration" />
                </Tooltip>
            }>
            <FlexboxGrid data-cy="history-lpSensor-AutoCalibration">
                <FlexboxGrid.Item>
                    <FontAwesomeIcon
                        icon={faWifi}
                        rotation={90}
                        style={{ color: isStaring ? 'green' : 'red' }}
                        size="lg"
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <FontAwesomeIcon icon={faCar} style={{ color: isStaring ? 'green' : 'red' }} size="lg" />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Whisper>
    );
}
