import { LPSensorForceColorConf } from './LPSensorForceColorConf';

export class LPSensorForceType {
    private _forceType: number;
    private _color: LPSensorForceColorConf;

    constructor(data) {
        this._forceType = data.forceType;
        this._color = new LPSensorForceColorConf(data.color);
    }

    public get forceType() {
        return this._forceType;
    }

    public get color() {
        return this._color;
    }

    private setForceType(forceType_: number): boolean {
        if (this._forceType !== forceType_) {
            this._forceType = forceType_;
            return true;
        }

        return false;
    }

    public setForceTypeInformations(forceType: LPSensorForceType): boolean {
        let result = false;

        if (this.setForceType(forceType.forceType)) result = true;
        if (this._color.setForceColorConf(forceType.color)) result = true;

        return result;
    }
}
