import { faChartBar, faInfoCircle, faLayerGroup, faParking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Col, FlexboxGrid, Loader, Message, Panel } from 'rsuite';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import ProgressComponent from '../Map/Components/Progress/ProgressComponent';
import ElementTable from '../ReactDataTableComponent/ElementTable';

type Props = WrappedComponentProps;

type State = {
    eventDrawer: Record<string, any> | null;
    loading: boolean;
    error: boolean;
    counters: Array<Record<string, any>>;
    isEventDrawerOpen: boolean;
    isCreateEventModalOpen: boolean;
};

class ListCounters extends React.Component<Props, State> {
    columns: TableColumn<RowRecord>[];

    constructor(props) {
        super(props);

        this.state = {
            counters: [],
            loading: true,
            error: false,
            // Drawer
            isEventDrawerOpen: false,
            eventDrawer: null,
            // Create event
            isCreateEventModalOpen: false,
        };

        this.fetchEvents = this.fetchEvents.bind(this);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'counter.name',
                }),
                center: true,
                cell: row => (
                    <div data-cy="event-list-name" data-tag="allowRowEvents">
                        {row.label}
                    </div>
                ),
                grow: 1,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'counter.type',
                }),
                center: true,
                cell: row => {
                    if (row.type == 'Park') return <FontAwesomeIcon icon={faLayerGroup} color="green" />;
                    else if (row.type == 'Level') return <FontAwesomeIcon icon={faLayerGroup} color="blue" />;
                    else if (row.type == 'Zone') return <FontAwesomeIcon icon={faParking} />;
                    else if (row.type == 'cpt vehicle') return <FontAwesomeIcon icon={faInfoCircle} color="orange" />;
                    else {
                        return (
                            <div data-cy="event-list-name" data-tag="allowRowEvents">
                                {row.type}
                            </div>
                        );
                    }
                },
                grow: 1,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'counter.nbSensors',
                }),
                center: true,
                cell: row => (
                    <div data-cy="counter-nbSensors" data-tag="allowRowEvents">
                        {row.nbSensors}
                    </div>
                ),
                grow: 1,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'counter.values',
                }),
                center: true,
                cell: row =>
                    row.counterValue ? (
                        <FlexboxGrid.Item componentClass={Col} className="flexbox-grid-item-counters" xs={24}>
                            <ProgressComponent
                                label={''}
                                total={row.counterValue.all.total}
                                free={row.counterValue.all.free}
                                occupied={row.counterValue.all.occupied}
                                size={12}
                            />
                        </FlexboxGrid.Item>
                    ) : (
                        <Loader vertical />
                    ),
                grow: 5,
            },
        ];
    }

    componentDidMount() {
        this.fetchEvents();
        webSocketService.joinRoom('counter');

        webSocketService.onEvent('counter:update', this.handleWebSocket);
    }

    componentWillUnmount() {
        webSocketService.offEvent('counter:update', this.handleWebSocket);
    }

    handleWebSocket = data => {
        const currentCounters = [...this.state.counters];

        const foundParkingCounter = currentCounters.find(counter => counter.id === data.id);
        //---
        if (foundParkingCounter) foundParkingCounter['counterValue'] = data.counterValue;

        this.setState({
            counters: currentCounters,
        });

        return;
    };

    fetchEvents() {
        this.setState({
            loading: true,
            error: false,
        });

        axiosService
            .getAxios()
            .get('/counters', { headers: authHeader() })
            .then(counterResponse => {
                //---
                let counters = counterResponse.data.map(counter => counter);
                this.setState({
                    counters: counters,
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    error: true,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
                // Ask data to initialize all counters
                axiosService.getAxios().get('/counters/status', { headers: authHeader() });
            });
    }

    render() {
        return (
            <Fragment>
                {this.state.error && (
                    <Message
                        type="error"
                        description={
                            <p>
                                <FormattedMessage id="listcounters.fetchEvents.error" />
                                <br />
                                <Button appearance="link" onClick={this.fetchEvents}>
                                    <FormattedMessage id="listcounters.fetchEvents.retry" />
                                </Button>
                            </p>
                        }
                    />
                )}

                <SecuredFragment authorizedRoles={[rolesConstants.counters.VIEW_LIST]}>
                    <Panel
                        className="panel-big dashboard-card-header"
                        shaded
                        bordered
                        header={
                            <PanelHeader
                                icon={faChartBar}
                                title={this.props.intl.formatMessage({ id: 'listcounters.title' })}
                                tagValue={this.state.counters.length}
                            />
                        }
                        bodyFill>
                        <SecuredFragment authorizedRoles={[rolesConstants.counters.VIEW_LIST]}>
                            <ElementTable
                                columns={this.columns}
                                data={this.state.counters}
                                progressPending={this.state.loading}
                                small={false}
                            />
                        </SecuredFragment>
                    </Panel>
                </SecuredFragment>
            </Fragment>
        );
    }
}

export default injectIntl(ListCounters);
