import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MaintenanceState } from '../../../../handlers/MaintenanceInformation';

type Props = {
    maintenanceState: MaintenanceState;
};

export const DeviceStateInformationLogo = (props: Props) => {
    return (
        <span>
            {props.maintenanceState === MaintenanceState.EVERYTHING_OK && (
                <FontAwesomeIcon icon={faExclamationCircle} color="green" />
            )}
            {props.maintenanceState === MaintenanceState.LOW && (
                <FontAwesomeIcon icon={faExclamationCircle} color="#FFDA00" />
            )}
            {props.maintenanceState === MaintenanceState.MEDIUM && (
                <FontAwesomeIcon icon={faExclamationCircle} color="orange" />
            )}
            {props.maintenanceState === MaintenanceState.HIGH && (
                <FontAwesomeIcon icon={faExclamationCircle} color="#FF5349" />
            )}
            {props.maintenanceState === MaintenanceState.VERY_HIGH && (
                <FontAwesomeIcon icon={faExclamationCircle} color="red" />
            )}
        </span>
    );
};
