import { faCheck, faEye, faEyeSlash, faTag, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import L from 'leaflet';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    InputGroup,
    Loader,
    Message,
    Modal,
    Toggle,
} from 'rsuite';
import { interpret } from 'xstate';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import { ZoneMachine } from '../Hooks/ZoneMachine';
import ZonesIcon from '../Icons/ZonesIcon';

type Props = {
    levelId: number;
    reloadZones: Function;
    zones: Array<Record<string, any>>;
    editMode: boolean;
    isEdit: boolean;
    handleCounterNeedReload?: Function;
    validUpdate?: Function;
    cancelUpdate?: Function;
    show?: boolean;
    loading?: boolean;
    onChange?: Function;
    values?: Record<string, any>;
    error?: boolean;
} & WrappedComponentProps;

type State = {
    current: Record<string, any>;
};

class ZonesLayer extends React.Component<Props, State> {
    service: any;
    constructor(props) {
        super(props);

        this.state = {
            current: ZoneMachine.initialState,
            // calibrationValue: 0,
        };

        this.cancelUpdate = this.cancelUpdate.bind(this);
        this.validUpdate = this.validUpdate.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.stopEdition = this.stopEdition.bind(this);
        this.onChange = this.onChange.bind(this);
        this.zoneCreated = this.zoneCreated.bind(this);
        this.zoneEdited = this.zoneEdited.bind(this);
        this.zoneDeleted = this.zoneDeleted.bind(this);
        this.onTransition = this.onTransition.bind(this);

        this.service = interpret(
            ZoneMachine.withContext({ ...ZoneMachine.context, levelId: this.props.levelId, intl: this.props.intl })
        ).onTransition(current => {
            this.onTransition(current);
        });
    }

    onTransition(current) {
        this.setState(
            {
                current,
            },
            () => {
                if (current.matches('reload')) {
                    this.props.reloadZones('zones');
                    this.service.send('RELOADED');
                }
            }
        );
    }

    componentDidMount() {
        this.service.start();
    }

    componentWillUnmount() {
        this.service.stop();
    }

    onChange(value) {
        this.service.send('UPDATE_FORM', { value });
    }

    cancelUpdate() {
        this.service.send('CANCEL_EDIT_FORM');
    }

    validUpdate() {
        this.service.send('VALID_EDIT_FORM');
    }

    zoneCreated(event) {
        let points = event.layer.editing.latlngs[0][0];
        let geojson: Array<Array<number>> = [];

        for (let p in points) {
            geojson.push([points[p].lat, points[p].lng]);
        }

        event.layer.remove();

        this.service.send('CREATE_ZONE', { geojson });

        return false;
    }

    zoneDeleted(event) {
        let zonesId: Array<string> = [];

        for (let l in event.layers._layers) {
            zonesId.push(event.layers._layers[l].options.name);
        }

        this.service.send('DELETE_ZONE', { zonesId });
    }

    zoneEdited(event) {
        let zonesTcm: Array<Record<string, any>> = [];

        for (let l in event.layers._layers) {
            zonesTcm.push({
                id: event.layers._layers[l].options.name,
                geoJSON: event.layers._layers[l]._latlngs[0].map(latLng => {
                    return [latLng.lat, latLng.lng];
                }),
            });
        }

        if (zonesTcm.length > 0) {
            this.service.send('EDIT_ZONE', { mapZoneTab: zonesTcm });
        }
    }

    startEdition() {
        this.service.send('START_EDIT_ZONE_MODE');
    }

    stopEdition() {
        this.service.send('CANCEL_EDIT_ZONE_MODE');
    }

    render() {
        const { current } = this.state;

        // @ts-ignore
        L.EditToolbar.Delete.include({
            removeAllLayers: false,
        });

        return (
            <FeatureGroup>
                <Modal backdrop={'static'} show={current.context.loading}>
                    <Modal.Body className="text-center">
                        <Loader content="Chargement des zones..." vertical />
                    </Modal.Body>
                </Modal>

                {this.props.editMode && (
                    <Fragment>
                        <EditControl
                            position="topright"
                            onCreated={this.zoneCreated}
                            onDeleted={this.zoneDeleted}
                            onEdited={this.zoneEdited}
                            onEditStart={this.startEdition}
                            onEditStop={this.stopEdition}
                            onDeleteStart={this.startEdition}
                            onDeleteStop={this.stopEdition}
                            draw={{
                                rectangle: false,
                                circle: false,
                                marker: false,
                                circlemarker: false,
                                polygon: true,
                                polyline: false,
                            }}
                            edit={{
                                delete: {
                                    removeAllLayers: false,
                                },
                            }}
                        />

                        <CreateZoneModal
                            cancelUpdate={this.cancelUpdate}
                            validUpdate={this.validUpdate}
                            show={current.context.createZoneModalOpen}
                            loading={current.context.creatingZone}
                            values={current.context.createZoneFormValue}
                            onChange={this.onChange}
                            error={current.context.creationError}
                        />
                    </Fragment>
                )}

                <ZonesIcon zones={this.props.zones} reload={this.props.reloadZones} editMode={this.props.editMode} />
            </FeatureGroup>
        );
    }
}

type CreateZoneModalProps = {
    cancelUpdate: Function;
    validUpdate: Function;
    show: boolean;
    loading: boolean;
    values: Record<string, any>;
    onChange: Function;
    error: boolean;
};

class CreateZoneModal extends React.Component<CreateZoneModalProps> {
    render() {
        return (
            <Fragment>
                <SecuredFragment authorizedRoles={[rolesConstants.mapZones.CREATE]}>
                    <Modal backdrop={'static'} show={this.props.show} onHide={() => this.props.cancelUpdate()}>
                        <Modal.Header>
                            <Modal.Title>
                                <FormattedMessage id="map.zone.createZone" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.props.error && (
                                <Message
                                    className="margin-bottom-15"
                                    type="error"
                                    description={<FormattedMessage id="map.places.creationError" />}
                                />
                            )}
                            <Form fluid onChange={values => this.props.onChange(values)} formValue={this.props.values}>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <ControlLabel>
                                            <FormattedMessage id="map.zone.label" />
                                        </ControlLabel>

                                        <InputGroup style={{ marginTop: 0 }}>
                                            <InputGroup.Addon>
                                                <FontAwesomeIcon icon={faTag} />
                                            </InputGroup.Addon>
                                            <FormControl name="label" />
                                        </InputGroup>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <ControlLabel>
                                            <FormattedMessage id="map.zone.isShow" />
                                        </ControlLabel>
                                        <FormControl
                                            checked={this.props.values.isView}
                                            size="lg"
                                            style={{ left: '35%' }}
                                            accepter={Toggle}
                                            checkedChildren={<FontAwesomeIcon icon={faEye} />}
                                            unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} />}
                                            name="isView"
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonGroup>
                                <Button
                                    onClick={() => this.props.cancelUpdate()}
                                    color="red"
                                    data-cy="create-zone-modal-cancel">
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    onClick={() => this.props.validUpdate()}
                                    color="green"
                                    data-cy="create-zone-modal-valid">
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </Modal>
                </SecuredFragment>
            </Fragment>
        );
    }
}

export default injectIntl(ZonesLayer);
