import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, FlexboxGrid, List, Panel } from 'rsuite';
import { User } from '../../../../../handlers/profile/User';
import { rolesConstants } from '../../../../../static/roles';
import SecuredFragment from '../../../../Auth/SecuredFragment';
import PanelHeader from '../../../../Custom/PanelHeader';
import { AddUserProfileModal } from '../Modal/AddUserProfileModal';

type Props = {
    user: User;
    reloadUsers: () => void;
    reloadProfiles: () => void;
};

export const UserRoles = (props: Props) => {
    const intl = useIntl();

    const [openAddUserProfileModal, setOpenAddUserProfileModal] = React.useState(false);

    return (
        <>
            <AddUserProfileModal
                user={props.user}
                open={openAddUserProfileModal}
                onClose={() => setOpenAddUserProfileModal(false)}
                reloadUsers={props.reloadUsers}
                reloadProfiles={props.reloadProfiles}
            />

            <Panel
                shaded
                bordered
                bodyFill
                className="panel-small"
                data-cy="users-right"
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'user.rights' })}
                        buttons={[
                            <SecuredFragment authorizedRoles={[rolesConstants.user.UPDATE_PROFILES]}>
                                <Button
                                    onClick={() => setOpenAddUserProfileModal(true)}
                                    appearance="primary"
                                    size="sm"
                                    data-cy="userRights-edit-button">
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            </SecuredFragment>,
                        ]}
                    />
                }>
                <List hover>
                    {props.user.getProfiles().map(profile => (
                        <List.Item className="padding-left-10 padding-right-10" key={profile.getName()}>
                            <FlexboxGrid
                                className="padding-left-0 padding-right-0"
                                align="middle"
                                justify="space-between">
                                <FlexboxGrid.Item data-cy="userRights-title" className="bold">
                                    {profile.getName()}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <FontAwesomeIcon
                                        data-cy="userRights-check"
                                        icon={faCheck}
                                        color={'green'}
                                        size="2x"
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            <p className="text-muted" style={{ paddingLeft: 2, paddingRight: 2 }}>
                                {profile.getDescription()}
                            </p>
                        </List.Item>
                    ))}
                </List>
            </Panel>
        </>
    );
};
