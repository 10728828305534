import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function updateMaintenanceState(option) {

    let color = '#000000';
    switch (option.maintenanceState) {
        case 1:
            color = 'green';
            break;
        case 2:
            color = '#FFDA00';
            break;
        case 3:
            color = 'orange';
            break;
        case 4:
            color = '#FF5349';
            break;
        case 5:
            color = 'red';
            break;
        default:
            color = '#000000';
    }

    return (
        <div>
            <FontAwesomeIcon color={color} icon={faExclamationCircle}/>
            <span className='margin-left-10'>{option.notepad }</span>
        </div>
    );
}
