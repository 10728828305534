import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Polygon, Popup } from 'react-leaflet';
import { Button } from 'rsuite';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import EditZoneModal from '../Modal/Zone/EditZoneModal';

type Props = {
    zones: Array<Record<string, any>>;
    zone?: Record<string, any>;
    reload: Function;
    editMode: boolean;
    editable?: boolean;
    hoverPopup?: boolean;
};

type State = {
    hoverPopup: boolean;
    zoneClicked: boolean;
};

export default class ZonesIcon extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            hoverPopup: false,
            zoneClicked: false,
        };

        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    onMouseOver(e) {
        if (!this.state.zoneClicked) {
            this.setState({
                hoverPopup: true,
            });
            e.target.openPopup();
        }
    }

    onMouseOut(e) {
        if (this.state.hoverPopup) {
            e.target.closePopup();
        }
    }

    openPopup(e) {
        if (!this.state.zoneClicked) {
            this.setState({
                hoverPopup: false,
                zoneClicked: true,
            });
            e.target.openPopup();
        }
    }

    handleClose() {
        this.setState({
            zoneClicked: false,
            hoverPopup: false,
        });
    }

    render() {
        return this.props.zones.map(zone => {
            return (
                <Polygon
                    onmouseover={this.onMouseOver}
                    onmouseout={this.onMouseOut}
                    onclick={this.openPopup}
                    name={zone.id}
                    key={`zone-${zone.id}`}
                    positions={zone.geoJSON}
                    color="blue">
                    <ZonePopup
                        zone={zone}
                        reload={this.props.reload}
                        editMode={this.props.editMode}
                        hoverPopup={this.state.hoverPopup}
                        handleClose={this.handleClose}
                    />
                </Polygon>
            );
        });
    }
}

type ZonePopupProps = {
    zone: Record<string, any>;
    reload: Function;
    editMode: boolean;
    hoverPopup: boolean;
    handleClose: () => void;
};

type ZonePopupState = {
    editModalOpen: boolean;
};

class ZonePopup extends React.Component<ZonePopupProps, ZonePopupState> {
    constructor(props) {
        super(props);

        this.state = {
            editModalOpen: false,
        };

        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
    }

    openEditModal() {
        this.setState({
            editModalOpen: true,
        });
    }

    closeEditModal() {
        this.setState({
            editModalOpen: false,
        });

        this.props.reload('zones');
    }

    render() {
        return (
            <Popup  key={`Counters-${this.props.zone.id}-${this.props.hoverPopup}`} autoPan={!this.props.hoverPopup}  offset={[0, 0]} onClose={this.props.handleClose}>
                <h5>{this.props.zone.label}</h5>
                <p>Capteurs : {this.props.zone.counter.nbSensors}</p>
                {this.props.editMode && !this.props.hoverPopup && (
                    <Fragment>
                        <SecuredFragment authorizedRoles={[rolesConstants.mapZones.UPDATE]}>
                            <Button
                                appearance="default"
                                block
                                color="orange"
                                onClick={this.openEditModal}
                                data-cy={'map-zone-popup-edit-zone-button'}
                                className="margin-botttom-15">
                                <FormattedMessage id="map.zones.popup.editZone" />
                            </Button>

                            <EditZoneModal
                                zone={this.props.zone}
                                onHide={this.closeEditModal}
                                show={this.state.editModalOpen}
                            />
                        </SecuredFragment>
                    </Fragment>
                )}
            </Popup>
        );
    }
}
