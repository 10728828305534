import { faCheck, faCogs, faPowerOff, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Alert, Button, ButtonGroup, Col, FlexboxGrid, Grid, Modal, Row } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { notificationActions } from '../../../redux/actions';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import { RebootModal } from './LPMatrixDisplayV2Actions/RebootModal';
import { SendAllConfigModal } from './LPMatrixDisplayV2Actions/SendAllConfigModal';

const mapDispatchToProps = dispatch => ({
    createNotification: (type, messageId) => dispatch(notificationActions.createNotification(type, messageId)),
});

type Props = {
    ipCan: IpCan;
    bus: number;
    createNotification: Function;
    onHide: Function;
    show: boolean;
} & WrappedComponentProps;

type State = {
    loading: boolean;
    isFetching: boolean;
    hasError: boolean;
    ipcan?: IpCan;
    showRebootModal: boolean;
    showSendAllDevicesConfigModal: boolean;
};

class LPMatrixDisplayV2ActionsModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            isFetching: true,
            hasError: false,
            ipcan: undefined,
            showRebootModal: false,
            showSendAllDevicesConfigModal: false,
        };
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get(`/ipcanmodules/${this.props.ipCan?.id}`, { headers: authHeader() })
            .then(ipcanmoduleResponse => {
                const ipcan = new IpCan(ipcanmoduleResponse.data);

                this.setState({
                    ipcan: ipcan,
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isFetching: false,
                });
            });
    }

    handleClick(cmd) {
        switch (cmd) {
            case LPMatrixDisplayV2Actions.REBOOT:
                this.setState({ showRebootModal: true });
                break;
            case LPMatrixDisplayV2Actions.SEND_ALL_DEVICES_CONFIG:
                this.setState({ showSendAllDevicesConfigModal: true });
                break;
            default:
                Alert.error(this.props.intl.formatMessage({ id: 'ipcan.actions.unknownCommandError' }));
        }

        // axiosService
        //     .getAxios()
        //     .put(
        //         '/ipcanmodules/cmdLPmatrixDisplayV2', // TODO: Add correct path to send command
        //         {
        //             id: this.state.ipcan?.id,
        //             bus: this.props.bus,
        //             cmd,
        //         },
        //         { headers: authHeader() }
        //     )
        //     .then(() => {
        //         this.props.createNotification('success', `ipcan.actions.${cmd}.success`);

        //         this.setState({
        //             loading: false,
        //         });
        //     })
        //     .catch(err => {
        //         console.error(err);

        //         this.props.createNotification('error', `ipcan.actions.${cmd}.error`);

        //         this.setState({
        //             loading: false,
        //         });
        //     });
    }

    closeModal = () => {
        this.props.onHide();
    };

    handleOpenRebootModal = () => {
        this.setState({
            showRebootModal: true,
        });
    };

    handleCloseRebootModal = () => {
        this.setState({
            showRebootModal: false,
        });
    };

    handleOpenSendAllDevicesConfigModal = () => {
        this.setState({
            showSendAllDevicesConfigModal: true,
        });
    };

    handleCloseSendAllDevicesConfigModal = () => {
        this.setState({
            showSendAllDevicesConfigModal: false,
        });
    };

    render() {
        return (
            <Fragment>
                {!this.state.isFetching && this.state.ipcan && (
                    <Fragment>
                        <RebootModal
                            show={this.state.showRebootModal}
                            onHide={this.handleCloseRebootModal}
                            bus={this.props.bus}
                            ipcan={this.state.ipcan}
                        />

                        <SendAllConfigModal
                            show={this.state.showSendAllDevicesConfigModal}
                            onHide={this.handleCloseSendAllDevicesConfigModal}
                            bus={this.props.bus}
                            ipcan={this.state.ipcan}
                        />
                    </Fragment>
                )}

                <Modal show={this.props.show} onHide={this.closeModal} overflow={false}>
                    <Modal.Header>
                        <Modal.Title data-cy="ipCan-bus-title">
                            <FormattedMessage id="ipCan.bus.actions.title" />
                            <span className="margin-left-5">
                                {' '}
                                - {this.state.ipcan ? this.state.ipcan.label : '...'} - {this.props.bus + 1}
                            </span>
                        </Modal.Title>
                        <Modal.Body style={{ height: '100%' }}>
                            {/* Forçage */}
                            <Grid fluid>
                                <Row>
                                    <SecuredFragment authorizedRoles={[rolesConstants.controller.COMMAND_TCM]}>
                                        <Col xs={12}>
                                            <Button
                                                data-cy="ipCan-reboot"
                                                color="orange"
                                                loading={this.state.isFetching}
                                                onClick={() => this.handleClick(LPMatrixDisplayV2Actions.REBOOT)}
                                                block>
                                                <FontAwesomeIcon icon={faPowerOff} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.reboot" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-sendAllDevicesConfig"
                                                color="red"
                                                loading={this.state.isFetching}
                                                onClick={() =>
                                                    this.handleClick(LPMatrixDisplayV2Actions.SEND_ALL_DEVICES_CONFIG)
                                                }
                                                block>
                                                <FontAwesomeIcon icon={faCogs} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.sendAllDevicesConfig" />
                                            </Button>
                                        </Col>
                                        {/* <Col xs={12}>
                                            <Button
                                                data-cy="ipCan-forceOff"
                                                color="blue"
                                                loading={this.state.isFetching}
                                                onClick={() => this.handleClick('forceOff')}
                                                block>
                                                <FontAwesomeIcon icon={faStop} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.forceOff" />
                                            </Button>
                                            <Button
                                                data-cy="ipCan-disableForce"
                                                color="blue"
                                                loading={this.state.isFetching}
                                                onClick={() => this.handleClick('disableForce')}
                                                block>
                                                <FontAwesomeIcon icon={faPlay} className="margin-right-10" />
                                                <FormattedMessage id="ipCan.bus.actions.disableForce" />
                                            </Button>
                                        </Col> */}
                                    </SecuredFragment>
                                </Row>
                            </Grid>
                        </Modal.Body>
                    </Modal.Header>
                </Modal>
            </Fragment>
        );
    }
}

type ResetBusProps = {
    ipCan: number | undefined;
    bus: number;
    createNotification: Function;
    onHide: Function;
    show: boolean;
};

type ResetBusState = {
    loading: boolean;
    isFetching: boolean;
};

class ResetBusModal extends Component<ResetBusProps, ResetBusState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            isFetching: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
            loading: true,
        });

        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/cmdTCM',
                {
                    id: this.props.ipCan,
                    bus: this.props.bus,
                    cmd: 'clearBus',
                },
                { headers: authHeader() }
            )
            .then(() => {
                this.props.createNotification('success', 'ipcan.actions.clearBus.success');

                this.setState({
                    loading: false,
                });

                this.props.onHide();
            })
            .catch(err => {
                console.error(err);

                this.props.createNotification('error', 'ipcan.actions.clearBus.error');

                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        return (
            <Modal overflow={false} show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCan.bus.reset.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="ipCan.bus.reset.message" />
                </Modal.Body>
                <Modal.Footer>
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button loading={this.state.isFetching} onClick={() => this.props.onHide()} color="red">
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button loading={this.state.isFetching} onClick={this.handleClick} color="green">
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(connect(null, mapDispatchToProps)(LPMatrixDisplayV2ActionsModal));

export enum LPMatrixDisplayV2Actions {
    REBOOT = 'reboot',
    SEND_ALL_DEVICES_CONFIG = 'sendAllDevicesConfig',
}
