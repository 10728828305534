import React from 'react';
import jsonToArray from '../../IpCanElementsPage/Drawer/Display/jsonToArrayHandler';
import { LPMATRIXDISPLAYV2_EVENTS } from './LPMatrixDisplayV2DescCell';
import debugMode from './Option/debugMode';
import ForceLPMatrixDisplayV2 from './Option/ForceLPMatrixDisplayV2';
import NoOptions from './Option/noOptions';
import readRealConfigOptions from './Option/readRealConfigOptions';
import UpdateBasicConfig from './Option/UpdateBasicConfig';
import updateCalibration from './Option/updateCalibration';
import updateCounters from './Option/updateCounters';
import UpdateCountersAssign from './Option/UpdateCountersAssign';
import updateMaintenanceState from './Option/updateMaintenanceState';
import updateSubstractCounters from './Option/updateSubstractCounters';
import UpdateTopologyConfig from './Option/UpdateTopologyConfig';
import UpdateKeyTextConfig from './Option/UpdateKeyTextConfig';
import UpdateUserTextConfig from './Option/UpdateUserTextConfig';
import UpdateVirtualDisplayConfig from './Option/UpdateVirtualDisplayConfig';
import viewMore from './Option/viewMore';

// wait for online, total and db as data
// wait for controler online or not

export default function LPMatrixDisplayV2OptionCell(desc: LPMATRIXDISPLAYV2_EVENTS, option) {
    switch (desc) {
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_BASIC_CONFIG:
            return UpdateBasicConfig(option);
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_TOPOLOGY_CONFIG:
            return UpdateTopologyConfig(option);
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_VIRTUAL_DISPLAY_CONFIG:
            return UpdateVirtualDisplayConfig(option);
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_KEY_TEXT_CONFIG:
            return UpdateKeyTextConfig(option);
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_USER_TEXT_CONFIG:
            return UpdateUserTextConfig(option);
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_COUNTERS:
            return updateCounters(option);
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_SUBSTRACT_COUNTERS:
            return updateSubstractCounters(option);
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_COUNTERS_ASSIGN:
            return <UpdateCountersAssign option={option} />;
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_MAINTENANCE_STATE:
            return updateMaintenanceState(option);
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_FORCE_DISPLAY:
            return <ForceLPMatrixDisplayV2 option={option} />;
        default:
            return NoOptions();
    }
}
