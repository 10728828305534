import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Loader, Tag, TagGroup } from 'rsuite';
import TCMDisplay from '../../../../../../handlers/ipCanDevices/TCMDisplay';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';

type Props = {
    tcmDisplay: TCMDisplay[];
};

export const TCMDisplaysPart = (props: Props) => {
    const [isLoading, setIsLoading] = React.useState(true);

    const [mapTCMDisplay, setMapTCMDisplay] = React.useState<any | null>(null);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/map-tcm-displays', { headers: authHeader() })
            .then(mapTCMDisplaysResponse => {
                setMapTCMDisplay(mapTCMDisplaysResponse.data);
                setIsLoading(false);
            });
    }, []);

    if (isLoading || !mapTCMDisplay) {
        return <Loader />;
    }

    return (
        <>
            <Divider style={{ color: 'purple' }}>
                <FormattedMessage id="event.task.displays" />
            </Divider>
            <TagGroup>
                {props.tcmDisplay.map(display => {
                    const currentMapTCMDisplay = mapTCMDisplay.find(
                        mapDisplay => mapDisplay.tcmDisplay.id === display.id
                    );

                    if (currentMapTCMDisplay) {
                        return (
                            <Tag data-cy="task-level" color="blue" key={mapTCMDisplay.id}>
                                {currentMapTCMDisplay.label}
                            </Tag>
                        );
                    }
                })}
            </TagGroup>
        </>
    );
};
