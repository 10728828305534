import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Col, FlexboxGrid, Panel } from 'rsuite';
import PlaceType from '../../../handlers/PlaceType';
import { sensorPlaceTypeHandler } from '../../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import SensorPlaceType from './SensorPlaceType';

type Props = {};

type State = {
    placeTypeLoader: boolean;
    sensorPlaceTypeLoader: boolean;
    placeTypes: Array<Record<string, any>>;
    sensorPlaceTypes: Array<Record<string, any>>;
    redirect: boolean;
};

export default class PlaceTypeCard extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            placeTypeLoader: true,
            sensorPlaceTypeLoader: true,
            placeTypes: [],
            sensorPlaceTypes: [],
            redirect: false,
        };

        this.fetchPlaceTypes = this.fetchPlaceTypes.bind(this);
        this.fetchSensorPlaceTypes = this.fetchSensorPlaceTypes.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.cancelFullClick = this.cancelFullClick.bind(this);
    }

    componentDidMount() {
        this.fetchPlaceTypes();
        this.fetchSensorPlaceTypes();
    }

    fetchPlaceTypes() {
        axiosService
            .getAxios()
            .get('/place-type', { headers: authHeader() })
            .then(response => {
                this.setState({
                    placeTypes: response.data.map(placeType => {
                        const type = new PlaceType(placeType);
                        return type.getPlaceType();
                    }),
                    placeTypeLoader: false,
                });
            });
    }

    fetchSensorPlaceTypes() {
        axiosService
            .getAxios()
            .get('/sensor-place-type', { headers: authHeader() })
            .then(response => {
                this.setState({
                    sensorPlaceTypes: response.data.map(sensorPlaceType => sensorPlaceTypeHandler(sensorPlaceType)),
                    sensorPlaceTypeLoader: false,
                });
            });
    }

    handleClick() {
        this.setState({
            redirect: true,
        });
    }

    cancelFullClick(e) {
        e.stopPropagation();
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/places" />;
        } else {
            return (
                <Panel
                    data-cy="dashboard-placeType-card"
                    className="dashboard-card-header hoverable panel-fixed-height"
                    header={
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} colspan={24} xs={12}>
                                <FormattedMessage id="dashboard.panel.placeType" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    }
                    shaded
                    onClick={this.handleClick}>
                    {this.state.placeTypes.map(placeType => {
                        return (
                            <Panel
                                onClick={this.cancelFullClick}
                                key={placeType.id}
                                className="panel-contains-panel no-radius "
                                collapsible
                                bodyFill
                                header={
                                    <FlexboxGrid align="middle">
                                        <FlexboxGrid.Item colspan={24} componentClass={Col} xs={3}>
                                            <img
                                                alt={`${placeType.label}-logo`}
                                                src={`data:image/${placeType.imageType};base64, ${placeType.image}`}
                                                height="40"
                                            />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={24} componentClass={Col} xs={18}>
                                            {placeType.label}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={24} componentClass={Col} xs={2}>
                                            {placeType.sensorPlaceTypes.length}
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                }>
                                <SensorPlaceType placeTypeId={placeType.id} sensors={this.state.sensorPlaceTypes} />
                            </Panel>
                        );
                    })}
                </Panel>
            );
        }
    }
}
