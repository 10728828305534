type Props = {
    color: string;
};

export const StarIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} x="224" width="64" height="64" />
            <rect fill={color} x="192" y="64" width="32" height="32" />
            <rect fill={color} y="160" width="160" height="32" />
            <rect fill={color} x="352" y="160" width="160" height="32" />
            <rect fill={color} x="32" y="480" width="96" height="32" />
            <rect fill={color} x="384" y="480" width="96" height="32" />
            <g>
                <rect fill={color} x="288" y="64" width="32" height="32" />
                <rect fill={color} y="192" width="32" height="32" />
                <rect fill={color} x="32" y="224" width="32" height="32" />
                <rect fill={color} x="64" y="256" width="32" height="32" />
                <rect fill={color} x="96" y="288" width="32" height="32" />
                <rect fill={color} x="128" y="320" width="32" height="32" />
                <rect fill={color} x="96" y="352" width="32" height="32" />
                <rect fill={color} x="384" y="352" width="32" height="32" />
                <rect fill={color} x="416" y="384" width="32" height="32" />
                <rect fill={color} x="352" y="448" width="32" height="32" />
                <rect fill={color} x="320" y="416" width="32" height="32" />
                <rect fill={color} x="288" y="384" width="32" height="32" />
                <rect fill={color} x="128" y="448" width="32" height="32" />
                <rect fill={color} x="160" y="416" width="32" height="32" />
                <rect fill={color} x="192" y="384" width="32" height="32" />
            </g>
            <rect fill={color} x="224" y="352" width="64" height="32" />
            <g>
                <rect fill={color} x="64" y="384" width="32" height="32" />
                <rect fill={color} x="384" y="288" width="32" height="32" />
                <rect fill={color} x="480" y="192" width="32" height="32" />
                <rect fill={color} x="448" y="224" width="32" height="32" />
                <rect fill={color} x="352" y="320" width="32" height="32" />
                <rect fill={color} x="416" y="256" width="32" height="32" />
            </g>
            <rect fill={color} x="320" y="96" width="32" height="64" />
            <rect fill={color} x="448" y="416" width="32" height="96" />
            <rect fill={color} x="32" y="416" width="32" height="96" />
            <g>
                <rect fill={color} y="160" width="32" height="64" />
                <rect fill={color} x="480" y="160" width="32" height="64" />
                <rect fill={color} x="160" y="96" width="32" height="64" />
            </g>
        </g>
    </svg>
);
