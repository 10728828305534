import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    InputNumber,
    List,
    Panel,
    SelectPicker,
} from 'rsuite';
import { originValue, timezoneValue } from '../../../../../handlers/lpDisplays/Enums/LPDisplayEnums';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import { rolesConstants } from '../../../../../static/roles';
import SecuredFragment from '../../../../Auth/SecuredFragment';
import PanelHeader from '../../../../Custom/PanelHeader';
import {
    LPDisplayGMTSelector,
    LPDisplayNbPixelX,
    LPDisplayNbPixelY,
    LPDisplayOriginSelector,
    LPDisplayTypeSelector,
} from '../Form/LPDisplaySelect';

type Props = {
    display: LPDisplay;
    isLoading: boolean;
    reloadDisplay?: () => void;
} & WrappedComponentProps;

type State = {
    isEditMode: boolean;
    isEditing: boolean;
    formValue: FormValue;
};

type FormValue = {
    nbPixelX: number;
    nbPixelY: number;
    typeMatrix: number;
    brightness: string;
    origin: number;
    gmt: number;
};

// interface handleFormValueChangeInterface {
//     nbPixelX: string;
//     nbPixelY: string;
//     typeMatrix: string;
//     brightness: string;
//     origin: string;
//     gmt: string;
// }

class LPDisplayBaseConfigurationCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            isEditing: false,
            formValue: {
                nbPixelX: this.props.display.getBaseConfig().getNbPixelX(),
                nbPixelY: this.props.display.getBaseConfig().getNbPixelY(),
                typeMatrix: this.props.display.getBaseConfig().getTypeMatrix(),
                brightness: this.props.display.getBaseConfig().getBrightness().toString(),
                origin: this.props.display.getBaseConfig().getOrigin(),
                gmt: this.props.display.getBaseConfig().getGmt(),
            },
        };
    }

    handleFormChange = formValue => {
        this.setState({
            formValue: {
                nbPixelX: parseInt(formValue.nbPixelX),
                nbPixelY:
                    (formValue.nbPixelX / 32) * (formValue.nbPixelY / 16) > 16 ? 16 : parseInt(formValue.nbPixelY),
                typeMatrix: parseInt(formValue.typeMatrix),
                brightness: formValue.brightness,
                origin: parseInt(formValue.origin),
                gmt: parseInt(formValue.gmt),
            },
        });
    };

    setEditMode = () => {
        this.setState({
            isEditMode: true,
        });
    };

    cancelEdition = () => {
        this.setState({
            isEditMode: false,
            formValue: {
                nbPixelX: this.props.display.getBaseConfig().getNbPixelX(),
                nbPixelY: this.props.display.getBaseConfig().getNbPixelY(),
                typeMatrix: this.props.display.getBaseConfig().getTypeMatrix(),
                brightness: this.props.display.getBaseConfig().getBrightness().toString(),
                origin: this.props.display.getBaseConfig().getOrigin(),
                gmt: this.props.display.getBaseConfig().getGmt(),
            },
        });
    };

    validEdition = () => {
        const { nbPixelX, nbPixelY, typeMatrix, brightness, origin, gmt } = this.state.formValue;
        if ((nbPixelX / 32) * (nbPixelY / 16) <= 16) {
            this.setState({
                isEditing: true,
            });

            axiosService
                .getAxios()
                .put(
                    '/devices/lp-matrixdisplays-v2/updateConfig',
                    {
                        nbPixelX: nbPixelX,
                        nbPixelY: nbPixelY,
                        typeMatrix: typeMatrix,
                        brightness: parseInt(brightness),
                        origin: origin,
                        gmt: gmt,
                        id: this.props.display.id,
                    },
                    { headers: authHeader() }
                )
                .then(updateConfigDisplayResponse => {
                    Alert.success(
                        this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.update.success' })
                    );

                    this.setState({ isEditMode: false });

                    this.props.display.updateConfig(updateConfigDisplayResponse.data);

                    if (this.props.reloadDisplay) {
                        this.props.reloadDisplay();
                    }
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(
                        this.props.intl.formatMessage(
                            { id: 'ipCanDevices.lpDisplay.baseConfiguration.update.error' },
                            { error: err }
                        )
                    );
                })
                .finally(() => {
                    this.setState({
                        isEditing: false,
                    });
                });
        } else {
            Alert.error(
                this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.update.tooMuchMatrix' })
            );
        }
    };

    renderForm = () => (
        <Form formValue={this.state.formValue} onChange={this.handleFormChange} fluid>
            <List hover>
                {/* NB PIXEL Y */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.nbPixelY" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormControl
                                data-cy="lpDisplay-baseConfiguration-edit-nbPixelsY"
                                className="form-margin"
                                placement="auto"
                                name="nbPixelY"
                                accepter={SelectPicker}
                                data={LPDisplayNbPixelY(this.props.intl)}
                                disabledItemValues={LPDisplayNbPixelY(this.props.intl)
                                    .map(nbPixel => nbPixel.value)
                                    .map(value => {
                                        if ((value / 16) * (this.state.formValue.nbPixelX / 32) > 16) return value;
                                    })}
                                cleanable={false}
                                searchable={false}
                                disabled={this.state.isEditing}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <div data-cy={`baseConfiguration-nbPixelsY-value-${item.value}`}>{label}</div>
                                    );
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* NB PIXEL X */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.nbPixelX" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormControl
                                data-cy="lpDisplay-baseConfiguration-edit-nbPixelsX"
                                className="form-margin"
                                placement="auto"
                                name="nbPixelX"
                                accepter={SelectPicker}
                                data={LPDisplayNbPixelX(this.props.intl)}
                                cleanable={false}
                                searchable={false}
                                disabled={this.state.isEditing}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <div data-cy={`baseConfiguration-nbPixelsX-value-${item.value}`}>{label}</div>
                                    );
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* TYPE MATRIX */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.typeMatrix" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormControl
                                data-cy="lpDisplay-baseConfiguration-edit-typeMatrix"
                                className="form-margin"
                                name="typeMatrix"
                                accepter={SelectPicker}
                                cleanable={false}
                                data={LPDisplayTypeSelector()}
                                searchable={false}
                                disabled={this.state.isEditing}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <div data-cy={`baseConfiguration-typeMatrix-value-${item.value}`}>{label}</div>
                                    );
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* BRIGHTNESS */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.brightness" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormControl
                                data-cy="lpDisplay-baseConfiguration-edit-brightness"
                                className="form-margin"
                                name="brightness"
                                accepter={InputNumber}
                                cleanable={false}
                                min={0}
                                max={100}
                                disabled={this.state.isEditing}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* origin */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.origin" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormControl
                                data-cy="lpDisplay-baseConfiguration-edit-origin"
                                className="form-margin"
                                name="origin"
                                accepter={SelectPicker}
                                cleanable={false}
                                searchable={false}
                                placement="auto"
                                disabled={this.state.isEditing}
                                data={LPDisplayOriginSelector(this.props.intl)}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`baseConfiguration-origin-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* gmt */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item className="bold" componentClass={Col} xs={14}>
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.gmt" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormControl
                                data-cy="lpDisplay-baseConfiguration-edit-gmt"
                                className="form-margin"
                                name="gmt"
                                accepter={SelectPicker}
                                cleanable={false}
                                searchable={false}
                                placement="auto"
                                disabled={this.state.isEditing}
                                data={LPDisplayGMTSelector()}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`baseConfiguration-gmt-value-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup data-cy="lpDisplay-edit-baseConfiguration-grpButton">
                                <Button
                                    data-cy="lpDisplay-edit-baseConfiguration-cancel"
                                    color="red"
                                    onClick={this.cancelEdition}
                                    disabled={this.state.isEditing}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    data-cy="lpDisplay-edit-baseConfiguration-valid"
                                    color="green"
                                    onClick={this.validEdition}
                                    loading={this.state.isEditing}
                                    disabled={this.state.formValue.brightness === ''}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );

    renderView = () => {
        const origin = originValue(this.props.display.getBaseConfig().getOrigin());
        const gmt = timezoneValue(this.props.display.getBaseConfig().getGmt());
        return (
            <List hover>
                {/* Creation */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.creation" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-creation">
                            {this.props.display.createdAt} - {this.props.display.createdBy}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* update */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.update" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-update">
                            {this.props.display.updatedAt} - {this.props.display.updatedBy}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* manufacturer */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.manufacturer" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-manufacturer">
                            {this.props.display.getInfoConfig().getManufacturer()}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* model name */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.productCode" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-productCode">
                            {this.props.display.getInfoConfig().getModelName()}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* software version */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.softwareVersion" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-softwareVersion">
                            {this.props.display.getInfoConfig().getSoftwareVersion()}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* NB PIXEL Y */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.nbPixelY" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-nbPixelsY">
                            {`${this.props.display.getBaseConfig().getNbPixelY()}px (${
                                this.props.display.getBaseConfig().getNbPixelY() / 16
                            } ${this.props.intl.formatMessage({
                                id: 'ipCanDevices.lpDisplay.baseConfiguration.nbMatrix',
                            })})`}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* NB PIXEL X */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.nbPixelX" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-nbPixelsX">
                            {`${this.props.display.getBaseConfig().getNbPixelX()}px (${
                                this.props.display.getBaseConfig().getNbPixelX() / 32
                            } ${this.props.intl.formatMessage({
                                id: 'ipCanDevices.lpDisplay.baseConfiguration.nbMatrix',
                            })})`}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* TYPE MATRIX */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.typeMatrix" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-typeMatrix">
                            {this.props.display.getBaseConfig().getTypeMatrix()}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* BRIGHTNESS */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.brightness" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-brightness">
                            {this.props.display.getBaseConfig().getBrightness()}%
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* origin */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.origin" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-origin">
                            <FormattedMessage id={`ipCanDevices.lpDisplay.baseConfiguration.origin.${origin}`} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* gmt */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.gmt" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-gmt">{gmt}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* IS FORCE */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.isForce" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-isForce">
                            {this.props.display.getIsForce() ? (
                                <FontAwesomeIcon icon={faCheck} color="green" />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* END FORCE TIME */}
                {this.props.display.getIsForce() && (
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item className="bold">
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.endForceTime" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-endForceTime">
                                {moment(this.props.display.endForceTime).format('DD/MM/YYYY HH:mm:ss')}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}
            </List>
        );
    };

    render = () => (
        <Loader show={this.props.isLoading}>
            <Panel
                className={'panel-small'}
                shaded
                data-cy="ipcan-elements-lpDisplay-drawer-baseConfiguration"
                bordered
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration' })}
                        buttons={[
                            <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_CONFIG]}>
                                <Button
                                    data-cy="lpDisplay-baseConfiguration-edit"
                                    appearance="primary"
                                    size="sm"
                                    color="blue"
                                    disabled={this.state.isEditMode}
                                    onClick={this.setEditMode}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            </SecuredFragment>,
                        ]}
                    />
                }
                bodyFill>
                {this.state.isEditMode ? this.renderForm() : this.renderView()}
            </Panel>
        </Loader>
    );
}

export default injectIntl(LPDisplayBaseConfigurationCard);
