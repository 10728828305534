import moment from 'moment';
import { useEffect, useState } from 'react';
import { VersionLine } from '../VersionTable';

export function useReactVersion() {
    const [reactVersion, setReactVersion] = useState<VersionLine>({
        appName: 'React',
        version: '-',
        dateTag: '-',
        isConnected: false,
        startDate: '-',
        versionTag: '-',
        isActiv: true,
    });

    useEffect(() => {
        let buildDate = process.env.REACT_APP_DATETAG || '-';

        if (process.env.REACT_APP_DATETAG) {
            try {
                let date = moment(process.env.REACT_APP_DATETAG);
                if (moment(date).isValid()) {
                    buildDate = moment(date).format('DD/MM/YYYY HH:mm:ss');
                }
            } catch (err) {
                console.error(err);
            }
        }

        setReactVersion({
            appName: 'React',
            isConnected: true,
            version: process.env.REACT_APP_VERSION || '-',
            versionTag: process.env.REACT_APP_TAG || '-',
            dateTag: buildDate,
            startDate: '-',
            isActiv: true,
        });
    }, []);

    return reactVersion;
}
