import { faPlay, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, TableCellProps } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';

type Props = {
    id: number;
    intl: Record<string, any>;
    name: string;
} & WrappedComponentProps &
    TableCellProps;

type State = {
    launchingTasks: boolean;
    resettingTrigger: boolean;
};
class LaunchCell extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            launchingTasks: false,
            resettingTrigger: false,
        };

        this.launchTasks = this.launchTasks.bind(this);
        this.resetTrigger = this.resetTrigger.bind(this);
    }

    launchTasks(e) {
        e.stopPropagation();

        this.setState({
            launchingTasks: true,
        });
        axiosService
            .getAxios()
            .put('/event-actions/event/play', { id: this.props.id }, { headers: authHeader() })
            .then(() => {
                Alert.warning(
                    `${this.props.intl.formatMessage({
                        id: 'tasks.launch.eventLauched',
                    })} : ${this.props.name}`,
                    5000
                );
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    `${this.props.intl.formatMessage({
                        id: 'tasks.launch.eventNotLauched',
                    })} : ${this.props.name}`,
                    5000
                );
            })
            .finally(() => {
                this.setState({
                    launchingTasks: false,
                });
            });
    }

    resetTrigger(e) {
        e.stopPropagation();

        this.setState({
            resettingTrigger: true,
        });
        axiosService
            .getAxios()
            .put('/event-actions/event/resetTrigger', { id: this.props.id }, { headers: authHeader() })
            .then(() => {
                Alert.warning(
                    `${this.props.intl.formatMessage({
                        id: 'tasks.resetTrigger.eventLauched',
                    })} : ${this.props.name}`,
                    5000
                );
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    `${this.props.intl.formatMessage({
                        id: 'tasks.resetTrigger.eventNotLauched',
                    })} : ${this.props.name}`,
                    5000
                );
            })
            .finally(() => {
                this.setState({
                    resettingTrigger: false,
                });
            });
    }

    render() {
        return (
            <div>
                <ButtonGroup>
                    <SecuredFragment authorizedRoles={[rolesConstants.eventAction.PLAY]}>
                        <Button
                            data-cy="event-list-startTasks"
                            color="green"
                            onClick={this.launchTasks}
                            loading={this.state.launchingTasks}>
                            <FontAwesomeIcon icon={faPlay} />
                        </Button>
                    </SecuredFragment>

                    <SecuredFragment authorizedRoles={[rolesConstants.eventAction.RESET_TRIGGER]}>
                        <Button
                            data-cy="event-list-resetTrigger"
                            color="orange"
                            onClick={this.resetTrigger}
                            loading={this.state.resettingTrigger}>
                            <FontAwesomeIcon icon={faRedo} />
                        </Button>
                    </SecuredFragment>
                </ButtonGroup>
            </div>
        );
    }
}

export default injectIntl(LaunchCell);
