import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, FlexboxGrid, Panel } from 'rsuite';
import Camera from '../../../handlers/Camera/Camera';

type Props = {
    cameras: Camera[];
};

const COLORS = [
    '#64a171',
    '#a62467',
    '#f00713',
    '#671974',
    '#3caf95',
    '#a0ec53',
    '#7e4fc5',
    '#ee0dc',
    '#9727e8',
    '#70c9b3',
    '#849c4e',
    '#1d57de',
    '#5e644',
    '#7b44a1',
    '#48a025',
    '#c68163',
    '#9cb857',
    '#90ba98',
    '#db0d63',
    '#8c0dfe',
];

export const PlaceRepartition = (props: Props) => {
    const nbPlacesByCamera = props.cameras.map(camera => camera.getPlacesCamera().length);

    const nbTotalPlacesCamera = nbPlacesByCamera.reduce((a, b) => a + b, 0);

    const intl = useIntl();

    const dataDoughnut = {
        title: '',
        datasets: [
            {
                data: nbPlacesByCamera,
                backgroundColor: COLORS,
            },
        ],
        labels: props.cameras.map(
            camera =>
                `${intl.formatMessage({ id: 'cameras.placeRepartition.camera' })} ${camera.getPlacesCamera().length}`
        ),
    };

    let donutSize: string | number = '100%';
    let donutColSize = 12;

    donutSize = window.innerHeight * 0.2;

    return (
        <Panel
            data-cy="cameras-placeRepartition-status"
            className={'dashboard-card-header hoverable'}
            header={<FormattedMessage id="cameras.placeRepartition.title" />}
            shaded>
            <FlexboxGrid align="middle" justify="space-between" style={{ height: 'fit-content' }}>
                <FlexboxGrid.Item componentClass={Col} colspan={12} xs={donutColSize} style={{ height: donutSize }}>
                    <Doughnut
                        data={dataDoughnut}
                        // height={'100%'}
                        // width={donutSize}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            aspectRatio: 1,
                            legend: {
                                display: false,
                            },
                        }}
                    />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} colspan={24} xs={24 - donutColSize}>
                    {nbPlacesByCamera.map((nbPlaces, index) => {
                        const percentage = nbPlaces === 0 ? 0 : (nbPlaces / nbTotalPlacesCamera) * 100;
                        return (
                            <div>
                                <div
                                    data-cy="cameras-placeRepartition-online"
                                    className="parking-legend-title margin-top-15"
                                    style={{ color: COLORS[index] }}>
                                    <FormattedMessage id="cameras.placeRepartition.camera" /> {index + 1}
                                </div>
                                <div data-cy="ipCan-placeRepartition-online-value" className="parking-legend-value">
                                    {nbPlaces} <FormattedMessage id="cameras.placeRepartition.places" />
                                    <span className="margin-left-5 small" style={{ color: COLORS[index] }}>
                                    ({percentage.toFixed(2)}%)
                                    </span>
                                </div>
                            </div>
                        )})}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    );
};
