export const placeCameraUpdateHyperParamConfigDesc = option => {
    return (
        <div className="padding-top-5 padding-bottom-5">
            {Object.keys(option).map((key, index) => {
                return (
                    <div>
                        <span className="bold">{key}</span>
                        <span> - </span>
                        <span>{option[key]}</span>
                    </div>
                );
            })}
        </div>
    );
};
