import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Input,
    Modal,
    Schema,
    Toggle,
} from 'rsuite';
import { PlaceIPCamera } from '../../../handlers/Camera/ApiCamera';
import PlaceCamera from '../../../handlers/Camera/PlaceCamera';
import { Configuration } from '../../../handlers/Configuration/Configuration';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    show: boolean;
    onClose: () => void;
    placesCameraSelected: PlaceCamera[];
    // historySize: number;
    // priorCoeffKx: number;
    // priorCoeffKy: number;
    // priorCoeffOx: number;
    // priorCoeffOy: number;
};

type FormValue = {
    historySizeEnabled: boolean;
    historySize?: string;
    priorCoeffKxEnabled: boolean;
    priorCoeffKx?: string;
    priorCoeffKyEnabled: boolean;
    priorCoeffKy?: string;
    priorCoeffOxEnabled: boolean;
    priorCoeffOx?: string;
    priorCoeffOyEnabled: boolean;
    priorCoeffOy?: string;
};

export const EditHyperParamPlaceCameraModal = (props: Props) => {
    const intl = useIntl();

    const [formValue, setFormValue] = useState<FormValue>({
        historySizeEnabled: false,
        priorCoeffKxEnabled: false,
        priorCoeffKyEnabled: false,
        priorCoeffOxEnabled: false,
        priorCoeffOyEnabled: false,
    });

    const [formError, setFormError] = useState<any>({});

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [configurations, setConfigurations] = useState<Configuration[]>([]);

    const initHistorySize = (): string | undefined => {
        if (props.placesCameraSelected.length >= 1) {
            const placeCameraHistorySize = props.placesCameraSelected[0].getConfig().getHistorySize();

            if (placeCameraHistorySize && props.placesCameraSelected.length === 1) {
                return placeCameraHistorySize.toString();
            }

            const foundConfiguration = configurations.find(configuration => configuration.getKey() === 'history_size');

            if (foundConfiguration) {
                return foundConfiguration.getValue();
            }
        }
    };

    const initPriorCoeffKx = (): string | undefined => {
        if (props.placesCameraSelected.length >= 1) {
            const placePriorCoeffKx = props.placesCameraSelected[0].getConfig().getPriorCoeffKx();

            if (placePriorCoeffKx && props.placesCameraSelected.length === 1) {
                return placePriorCoeffKx.toString();
            }

            const foundConfiguration = configurations.find(
                configuration => configuration.getKey() === 'prior_coeff_kx'
            );

            if (foundConfiguration) {
                return foundConfiguration.getValue();
            }
        }
    };

    const initPriorCoeffKy = (): string | undefined => {
        if (props.placesCameraSelected.length >= 1) {
            const placePriorCoeffKy = props.placesCameraSelected[0].getConfig().getPriorCoeffKy();

            if (placePriorCoeffKy && props.placesCameraSelected.length === 1) {
                return placePriorCoeffKy.toString();
            }

            const foundConfiguration = configurations.find(
                configuration => configuration.getKey() === 'prior_coeff_ky'
            );

            if (foundConfiguration) {
                return foundConfiguration.getValue();
            }
        }
    };

    const initPriorCoeffOx = (): string | undefined => {
        if (props.placesCameraSelected.length >= 1) {
            const placePriorCoeffOx = props.placesCameraSelected[0].getConfig().getPriorCoeffOx();

            if (placePriorCoeffOx && props.placesCameraSelected.length === 1) {
                return placePriorCoeffOx.toString();
            }

            const foundConfiguration = configurations.find(
                configuration => configuration.getKey() === 'prior_coeff_ox'
            );

            if (foundConfiguration) {
                return foundConfiguration.getValue();
            }
        }
    };

    const initPriorCoeffOy = (): string | undefined => {
        if (props.placesCameraSelected.length >= 1) {
            const placePriorCoeffOy = props.placesCameraSelected[0].getConfig().getPriorCoeffOy();

            if (placePriorCoeffOy && props.placesCameraSelected.length === 1) {
                return placePriorCoeffOy.toString();
            }

            const foundConfiguration = configurations.find(
                configuration => configuration.getKey() === 'prior_coeff_oy'
            );

            if (foundConfiguration) {
                return foundConfiguration.getValue();
            }
        }
    };

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/configuration', { headers: authHeader() })
            .then(configurationResponse => {
                setConfigurations(configurationResponse.data.map(configuration => new Configuration(configuration)));
            })
            .catch(err => {
                Alert.error(intl.formatMessage({ id: 'placeCamera.editHyperParam.fetchConfigurationError' }));

                console.error(err);
            });
    }, []);

    useEffect(() => {
        setFormValue(() => {
            if (props.placesCameraSelected.length === 1) {
                const placeCamera = props.placesCameraSelected[0];

                if (placeCamera) {
                    return {
                        historySizeEnabled: typeof placeCamera.getConfig().getHistorySize() !== 'undefined',
                        historySize: initHistorySize(),
                        priorCoeffKxEnabled: typeof placeCamera.getConfig().getPriorCoeffKx() !== 'undefined',
                        priorCoeffKx: initPriorCoeffKx(),
                        priorCoeffKyEnabled: typeof placeCamera.getConfig().getPriorCoeffKy() !== 'undefined',
                        priorCoeffKy: initPriorCoeffKy(),
                        priorCoeffOxEnabled: typeof placeCamera.getConfig().getPriorCoeffOx() !== 'undefined',
                        priorCoeffOx: initPriorCoeffOx(),
                        priorCoeffOyEnabled: typeof placeCamera.getConfig().getPriorCoeffOy() !== 'undefined',
                        priorCoeffOy: initPriorCoeffOy(),
                    };
                } else {
                    return {
                        historySizeEnabled: false,
                        historySize: initHistorySize(),
                        priorCoeffKxEnabled: false,
                        priorCoeffKx: initPriorCoeffKx(),
                        priorCoeffKyEnabled: false,
                        priorCoeffKy: initPriorCoeffKy(),
                        priorCoeffOxEnabled: false,
                        priorCoeffOx: initPriorCoeffOx(),
                        priorCoeffOyEnabled: false,
                        priorCoeffOy: initPriorCoeffOy(),
                    };
                }
            } else {
                return {
                    historySizeEnabled: false,
                    historySize: initHistorySize(),
                    priorCoeffKxEnabled: false,
                    priorCoeffKx: initPriorCoeffKx(),
                    priorCoeffKyEnabled: false,
                    priorCoeffKy: initPriorCoeffKy(),
                    priorCoeffOxEnabled: false,
                    priorCoeffOx: initPriorCoeffOx(),
                    priorCoeffOyEnabled: false,
                    priorCoeffOy: initPriorCoeffOy(),
                };
            }
        });
    }, [configurations]);

    const shouldDisableValidButton = () => {
        /**
         * History size should be even min 1
         */
        if (formValue.historySizeEnabled) {
            if (!formValue.historySize) return true;

            const historySize = parseInt(formValue.historySize);
            if (isNaN(historySize)) return true;

            if (historySize % 2 === 0 || historySize < 1) return true;
        }

        /**
         * Prior coeff KX should be positive
         */
        if (formValue.priorCoeffKxEnabled) {
            if (!formValue.priorCoeffKx) return true;

            const priorCoeffKx = parseFloat(formValue.priorCoeffKx);

            if (isNaN(priorCoeffKx)) return true;

            if (priorCoeffKx <= 0) return true;
        }

        /**
         * Prior coeff KY should be positive
         */
        if (formValue.priorCoeffKyEnabled) {
            if (!formValue.priorCoeffKy) return true;

            const priorCoeffKy = parseFloat(formValue.priorCoeffKy);

            if (isNaN(priorCoeffKy)) return true;

            if (priorCoeffKy <= 0) return true;
        }

        /**
         * Prior coeff OX should exists
         */
        if (formValue.priorCoeffOxEnabled) {
            if (!formValue.priorCoeffOx) return true;

            const priorCoeffOx = parseFloat(formValue.priorCoeffOx);

            if (isNaN(priorCoeffOx)) return true;
        }

        /**
         * Prior coeff OY should exists
         */
        if (formValue.priorCoeffOyEnabled) {
            if (!formValue.priorCoeffOy) return true;

            const priorCoeffOy = parseFloat(formValue.priorCoeffOy);

            if (isNaN(priorCoeffOy)) return true;
        }

        return false;
    };

    const handleValid = () => {
        setIsEditing(true);

        axiosService
            .getAxios()
            .put<PlaceIPCamera[]>(
                '/places-camera/updateHyperParamPlaceCamera',
                {
                    tabId: props.placesCameraSelected.map(place => place.getId()),
                    history_size:
                        formValue.historySizeEnabled && formValue.historySize
                            ? parseInt(formValue.historySize)
                            : undefined,
                    prior_coeff_kx:
                        formValue.priorCoeffKxEnabled && formValue.priorCoeffKx
                            ? parseFloat(formValue.priorCoeffKx)
                            : undefined,
                    prior_coeff_ky:
                        formValue.priorCoeffKyEnabled && formValue.priorCoeffKy
                            ? parseFloat(formValue.priorCoeffKy)
                            : undefined,
                    prior_coeff_ox:
                        formValue.priorCoeffOxEnabled && formValue.priorCoeffOx
                            ? parseFloat(formValue.priorCoeffOx)
                            : undefined,
                    prior_coeff_oy:
                        formValue.priorCoeffOyEnabled && formValue.priorCoeffOy
                            ? parseFloat(formValue.priorCoeffOy)
                            : undefined,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updatePlaceCameraResponse => {
                Alert.success(intl.formatMessage({ id: 'placeCamera.editHyperParam.success' }));

                updatePlaceCameraResponse.data.forEach(placeCameraResponse => {
                    const currentPlaceCamera = props.placesCameraSelected.find(
                        pc => pc.getId() === placeCameraResponse.id
                    );

                    if (currentPlaceCamera) currentPlaceCamera.updatePlace(placeCameraResponse);
                });

                props.onClose();
            })
            .catch(err => {
                console.error(err);
                Alert.error(intl.formatMessage({ id: 'placeCamera.editHyperParam.error' }));
            })
            .finally(() => {
                setIsEditing(false);
            });
    };

    const model = Schema.Model({
        historySize: Schema.Types.NumberType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidHistorySize' })
        ).addRule(value => {
            if (value % 2 === 0 || value < 1) return false;
            return true;
        }),

        priorCoeffKx: Schema.Types.NumberType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidPriorCoeffKx' })
        ).addRule(value => {
            if (value <= 0) {
                return false;
            } else {
                return true;
            }
        }),

        priorCoeffKy: Schema.Types.NumberType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidPriorCoeffKy' })
        ).addRule(value => {
            if (value <= 0) {
                return false;
            } else {
                return true;
            }
        }),

        priorCoeffOx: Schema.Types.NumberType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidPriorCoeffOx' })
        ),

        priorCoeffOy: Schema.Types.NumberType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidPriorCoeffOy' })
        ),
    });

    return (
        <Modal show={props.show} onHide={() => props.onClose()} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="placeCamera.editHyperParam.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form
                    fluid
                    formValue={formValue}
                    onCheck={formError => setFormError(formError)}
                    onChange={formValue => setFormValue(formValue as FormValue)}
                    model={model}>
                    {/* HISTORY SIZE */}
                    <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <ControlLabel>
                                <FormattedMessage id="placeCamera.hyperParam.historySize" />
                            </ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormGroup>
                                <FormControl
                                    className={formError.historySize && 'rs-form-error'}
                                    name="historySize"
                                    accepter={Input}
                                    min={1}
                                    type="number"
                                    disabled={!formValue.historySizeEnabled}
                                    errorMessage={null}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={2}>
                            <FormGroup>
                                <FormControl
                                    name="historySizeEnabled"
                                    accepter={Toggle}
                                    checked={formValue.historySizeEnabled}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    {/* PRIOR COEFF KX ENABLED */}
                    <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="placeCamera.hyperParam.priorCoeffKx" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormGroup>
                                <FormControl
                                    className={formError.priorCoeffKx && 'rs-form-error'}
                                    name="priorCoeffKx"
                                    accepter={Input}
                                    min={0}
                                    type="number"
                                    disabled={!formValue.priorCoeffKxEnabled}
                                    errorMessage={null}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={2}>
                            <FormGroup>
                                <FormControl
                                    name="priorCoeffKxEnabled"
                                    accepter={Toggle}
                                    checked={formValue.priorCoeffKxEnabled}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    {/* PRIOR COEFF KY ENABLED */}
                    <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="placeCamera.hyperParam.priorCoeffKy" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormGroup>
                                <FormControl
                                    className={formError.priorCoeffKy && 'rs-form-error'}
                                    name="priorCoeffKy"
                                    accepter={Input}
                                    min={0}
                                    type="number"
                                    disabled={!formValue.priorCoeffKyEnabled}
                                    errorMessage={null}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={2}>
                            <FormGroup>
                                <FormControl
                                    name="priorCoeffKyEnabled"
                                    accepter={Toggle}
                                    checked={formValue.priorCoeffKyEnabled}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    {/* PRIOR COEFF OX ENABLED */}
                    <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="placeCamera.hyperParam.priorCoeffOx" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormGroup>
                                <FormControl
                                    className={formError.priorCoeffOx && 'rs-form-error'}
                                    name="priorCoeffOx"
                                    accepter={Input}
                                    type="number"
                                    disabled={!formValue.priorCoeffOxEnabled}
                                    errorMessage={null}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={2}>
                            <FormGroup>
                                <FormControl
                                    name="priorCoeffOxEnabled"
                                    accepter={Toggle}
                                    checked={formValue.priorCoeffOxEnabled}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    {/* PRIOR COEFF OY ENABLED */}
                    <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="placeCamera.hyperParam.priorCoeffOy" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                            <FormGroup>
                                <FormControl
                                    className={formError.priorCoeffOy && 'rs-form-error'}
                                    name="priorCoeffOy"
                                    accepter={Input}
                                    type="number"
                                    disabled={!formValue.priorCoeffOyEnabled}
                                    errorMessage={null}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={2}>
                            <FormGroup>
                                <FormControl
                                    name="priorCoeffOyEnabled"
                                    accepter={Toggle}
                                    checked={formValue.priorCoeffOyEnabled}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button color="red" onClick={() => props.onClose()} disabled={isEditing}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Button
                        color="green"
                        onClick={handleValid}
                        disabled={shouldDisableValidButton()}
                        loading={isEditing}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
