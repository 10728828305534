import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Modal,
    SelectPicker,
} from 'rsuite';
import TCMSensor from '../../../../../handlers/ipCanDevices/TCMSensor';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    sensor: TCMSensor;
    sensorsList: TCMSensor[];
    onHide: () => void;
    show: boolean;
    ipCanId: number;
} & WrappedComponentProps;

type State = {
    isEditing: boolean;
    formValue: {
        newId: number;
    };
};

class SetOneIdModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            formValue: {
                newId: -1,
            },
        };

        this.deleteSensor = this.deleteSensor.bind(this);
    }

    deleteSensor() {
        this.setState({
            isEditing: true,
        });

        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/cmdTCMSetOneId',
                {
                    id: this.props.ipCanId,
                    bus: this.props.sensor.getBus(),
                    oldId: this.props.sensor.getDeviceId(),
                    withButton: false,
                    newId: this.state.formValue.newId,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.setState({
                    isEditing: false,
                });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'ipCan.setOneId.success',
                    }),
                    5000
                );

                this.props.onHide();
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'ipCan.setOneId.error',
                    }),
                    5000
                );
            });
    }

    handleFormChange = value => {
        this.setState({
            formValue: {
                ...this.state.formValue,
                ...value,
            },
        });
    };

    render() {
        return (
            <Modal backdrop={true} show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="sensor.setOneId.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid onChange={this.handleFormChange} formValue={this.state.formValue}>
                        <FormGroup>
                            <ControlLabel>{this.props.intl.formatMessage({ id: 'ipCan.setOneId.newId' })}</ControlLabel>
                            <FormControl
                                name="newId"
                                accepter={SelectPicker}
                                data={this.props.sensorsList
                                    .filter(s => s.getBus() === this.props.sensor.getBus() && !s.getOnline())
                                    .map(s => {
                                        return {
                                            label: `${this.props.intl.formatMessage({
                                                id: 'ipCanDevices.sensor.title',
                                            })} ${s.getBus() + 1} - ${s.getDeviceId()}`,
                                            value: s.getDeviceId(),
                                        };
                                    })}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <FlexboxGrid justify="end" align="middle">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button onClick={() => this.props.onHide()} color="red" loading={this.state.isEditing}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button onClick={this.deleteSensor} appearance="primary" loading={this.state.isEditing}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(SetOneIdModal);
