export class VirtualDisplayStepTrigger {
    private _value: number;
    private _action: number;

    constructor(stepTrigger_: VirtualDisplayStepTriggerInterface) {
        this._value = stepTrigger_.value;
        this._action = stepTrigger_.action;
    }

    public getValue(): number {
        return this._value;
    }

    public getAction(): number {
        return this._action;
    }

    public getActionValue(): number {
        let action = this._action;

        if (action > 2000) return action - 2000;
        if (action > 1000) return action - 1000;
        if (action > 100) return action - 100;
        if (action > 0) return action;

        return -1;
    }

    public getTriggerType(): number {
        let action = this._action;

        if (action > 2000) return 2000;
        if (action > 1000) return 1000;
        if (action > 100) return 100;
        if (action > 0) return 0;

        return -1;
    }

    private setValue(value_: number) {
        this._value = value_;
    }

    private setAction(action_: number) {
        this._action = action_;
    }

    public getActionInformation(): { name: string; number: number } {
        if (this._action > 0 && this._action <= 8) return { name: 'step', number: this._action };
        if (this._action > 100 && this._action <= 116) return { name: 'virtualDisplay', number: this._action - 100 };
        if (this._action > 1000 && this._action <= 1032) return { name: 'text', number: this._action - 1000 };
        if (this._action > 2000 && this._action <= 2016) return { name: 'userText', number: this._action - 2000 };

        return { name: '', number: 0 };
    }

    public updateStepTrigger(stepTrigger_: VirtualDisplayStepTriggerInterface) {
        this.setAction(stepTrigger_.action);
        this.setValue(stepTrigger_.value);
    }

    public getStepTrigger(): VirtualDisplayStepTriggerInterface {
        return {
            value: this._value,
            action: this._action,
        };
    }
}

export interface VirtualDisplayStepTriggerInterface {
    value: number;
    action: number;
}
