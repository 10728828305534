import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid, List } from 'rsuite';
import { Profile } from '../../../../../../handlers/profile/Profile';

type Props = {
    profile: Profile;
};

export const ViewProfileInformationPart = (props: Props) => {
    return (
        <List>
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="profile.information.name" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.profile.getName()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="profile.information.description" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.profile.getDescription()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="profile.information.creation" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.profile.getCreation()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="profile.information.update" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.profile.getUpdate()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
};
