import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Modal,
    SelectPicker,
} from 'rsuite';
import { DisplayPreviewInformation } from '../../../../../../handlers/lpDisplays/LPDisplay';
import { TriggerInterface } from '../../../../../../handlers/lpDisplays/LPDisplayTopology';
import { ActionObserver, ActionObserverEvent } from '../../../handlers/Observers/ActionObserver';
import {
    generateGPIOTrigger,
    generateTriggerCounter,
    generateTriggerType,
} from '../../../Modal/Form/TriggerFormValues';

type Props = {
    show: boolean;
    onHide: Function;
    topologyIndex: number;
    trigger?: TriggerInterface;
    triggerIndex?: number;
    display: DisplayPreviewInformation;
};

type State = {
    isEditing: boolean;
    formValue: FormValue;
};

type FormValue = {
    trigger: number;
    source: number;
    value: number;
};

const EditTriggerModal = (props: Props) => {
    const intl = useIntl();

    const [formValue, setFormValue] = React.useState<FormValue>({
        trigger: 1,
        source: 0,
        value: 0,
    });

    useEffect(() => {
        if (props.trigger) {
            setFormValue({
                trigger: props.trigger.trigger,
                source: props.trigger.source,
                value: props.trigger.value,
            });
        }
    }, [props.trigger]);

    const handleFormChange = value => {
        setFormValue({
            source: parseInt(value.source),
            trigger: parseInt(value.trigger),
            value: parseInt(value.value),
        });
    };

    const handleEditTrigger = () => {
        if (typeof props.triggerIndex !== 'undefined') {
            ActionObserver.emit(ActionObserverEvent.UPDATE_TOPOLOGY_TRIGGER, {
                topologyIndex: props.topologyIndex,
                triggerIndex: props.triggerIndex,
                formValue,
            });

            props.onHide();
        }
    };

    return (
        <Modal show={props.show} onHide={() => props.onHide()} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="configuration.topology.triggers.editTrigger.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form fluid formValue={formValue} onChange={formValue => handleFormChange(formValue)}>
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
                            <FormGroup>
                                <ControlLabel>
                                    {intl.formatMessage({
                                        id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.trigger',
                                    })}
                                </ControlLabel>
                                <FormControl
                                    name="trigger"
                                    accepter={SelectPicker}
                                    cleanable={false}
                                    data={generateTriggerType(intl)}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>

                        {(formValue.trigger === 2 || formValue.trigger === 3) && (
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
                                <FormGroup>
                                    <ControlLabel>
                                        {intl.formatMessage({
                                            id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.source',
                                        })}
                                    </ControlLabel>
                                    <FormControl
                                        name="source"
                                        cleanable={false}
                                        accepter={SelectPicker}
                                        data={generateTriggerCounter(intl)}
                                    />
                                </FormGroup>
                            </FlexboxGrid.Item>
                        )}

                        {formValue.trigger === 4 || formValue.trigger === 5 ? (
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
                                <FormGroup>
                                    <ControlLabel>
                                        {intl.formatMessage({
                                            id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.gpio',
                                        })}
                                    </ControlLabel>
                                    <FormControl
                                        name="value"
                                        cleanable={false}
                                        searchable={false}
                                        accepter={SelectPicker}
                                        data={generateGPIOTrigger(intl)}
                                    />
                                </FormGroup>
                            </FlexboxGrid.Item>
                        ) : (
                            <FlexboxGrid.Item componentClass={Col} xs={24}>
                                <FormGroup>
                                    <ControlLabel>
                                        {intl.formatMessage({
                                            id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.value',
                                        })}
                                    </ControlLabel>
                                    <FormControl name="value" type="number" min={0} />
                                </FormGroup>
                            </FlexboxGrid.Item>
                        )}
                    </FlexboxGrid>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={() => props.onHide()} color="red">
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Button onClick={handleEditTrigger} color="green">
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

export default EditTriggerModal;
