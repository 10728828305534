import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, FlexboxGrid, List, Loader, Panel, Tag, TagGroup, TagPicker } from 'rsuite';
import TCMDisplay from '../../../../handlers/ipCanDevices/TCMDisplay';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';

type Props = {
    display: TCMDisplay;
    isCheckingDatabase: boolean;
    ipCanId: number;
} & WrappedComponentProps;

type State = {
    displayCounters: Record<string, any>;
    editCounters: boolean;
    editSubstractionCounters: boolean;
    loading: boolean;
    counters: Array<Record<string, any>>;
};
class CountersCard extends React.Component<Props, State> {
    putData: Function;
    display!: TCMDisplay;
    constructor(props) {
        super(props);

        this.state = {
            displayCounters: {
                counters: [],
                substractionCounters: [],
            },
            editCounters: false,
            editSubstractionCounters: false,
            loading: true,
            counters: [],
        };

        this.putData = (url, data) => {
            return axiosService
                .getAxios()
                .put(url, data, { headers: authHeader() })
                .then(response => response.data);
        };

        this.setEditCounters = this.setEditCounters.bind(this);
        this.setEditSubstractionCounters = this.setEditSubstractionCounters.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.updateCounters = this.updateCounters.bind(this);
        this.updateSubstractionCounters = this.updateSubstractionCounters.bind(this);
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get(`/devices/tcm-display/${this.props.display.id}`, { headers: authHeader() })
            .then(tcmDisplayResponse => {
                const tcmDisplay = new TCMDisplay(tcmDisplayResponse.data, this.props.ipCanId, this.putData, undefined);

                this.display = tcmDisplay;

                axiosService
                    .getAxios()
                    .get('/counters', { headers: authHeader() })
                    .then(counterResponse => {
                        this.setState({
                            counters: counterResponse.data,
                            loading: false,
                            displayCounters: tcmDisplay.counters,
                        });
                    });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    loading: false,
                });
            });
    }

    setEditCounters() {
        this.setState({
            displayCounters: { ...this.state.displayCounters },
            editCounters: true,
            editSubstractionCounters: false,
        });
    }

    setEditSubstractionCounters() {
        this.setState({
            displayCounters: this.state.displayCounters,
            editCounters: false,
            editSubstractionCounters: true,
        });
    }

    cancelEdit() {
        this.setState({
            displayCounters: this.state.displayCounters,
            editCounters: false,
            editSubstractionCounters: false,
        });
    }

    updateCounters() {
        this.cancelEdit();
        this.setState({
            loading: true,
        });

        this.display.updateCounters(this.state.displayCounters.counters).then(() => {
            this.setState({ loading: false, displayCounters: this.display.counters });
        });
    }

    updateSubstractionCounters() {
        this.cancelEdit();
        this.setState({
            loading: true,
        });

        this.display.updateSubstractionCounters(this.state.displayCounters.substractionCounters).then(() => {
            this.setState({ loading: false, displayCounters: this.display.counters });
        });
    }

    render() {
        const shouldDisplaySubstractionCounterButton =
            !this.state.editSubstractionCounters && !this.props.isCheckingDatabase && !this.state.loading;
        return (
            <Panel
                className={'panel-small'}
                shaded
                bordered
                data-cy="ipcan-elements-display-drawer-counters"
                header={
                    <PanelHeader title={this.props.intl.formatMessage({ id: 'ipCanDevices.counters' })} buttons={[]} />
                }
                bodyFill>
                <List hover className="list-transparent">
                    <List.Item className="panel-list list-transparent-black-text">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item data-cy="ipCanDevices-countersList-title">
                                <FormattedMessage id="ipCanDevices.counters.countersList" />
                                {!this.state.editCounters && !this.props.isCheckingDatabase && !this.state.loading && (
                                    <Button
                                        data-cy="ipCanDevices-countersList"
                                        onClick={this.setEditCounters}
                                        appearance="link">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                )}
                            </FlexboxGrid.Item>
                            <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.ADD_COUNTER]}>
                                {this.state.loading ? (
                                    <Loader />
                                ) : (
                                    <FlexboxGrid.Item>
                                        {!this.state.editCounters ? (
                                            <TagGroup>
                                                {this.state.displayCounters.counters.map(counter => (
                                                    <Tag
                                                        data-cy="ipCan-display-counters-elementAdd"
                                                        color="blue"
                                                        key={`counter-${counter.id}`}>
                                                        {counter.label}
                                                    </Tag>
                                                ))}
                                            </TagGroup>
                                        ) : (
                                            <TagPicker
                                                style={{ width: 300 }}
                                                data-cy="ipCanDevices-countersList-select"
                                                defaultValue={this.state.displayCounters.counters.map(c => c.id)}
                                                data={this.state.counters.map(c => {
                                                    return { label: c.label, value: c.id };
                                                })}
                                                placement="topEnd"
                                                onChange={value =>
                                                    this.setState({
                                                        displayCounters: {
                                                            ...this.state.displayCounters,
                                                            counters: value,
                                                        },
                                                    })
                                                }
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`counters-add-value-${item.value}`}>{label}</div>
                                                    );
                                                }}
                                            />
                                        )}
                                    </FlexboxGrid.Item>
                                )}
                            </SecuredFragment>
                        </FlexboxGrid>
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item>
                                {this.state.editCounters && (
                                    <Fragment>
                                        <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.ADD_COUNTER]}>
                                            <Button
                                                data-cy="ipCanDevices-countersList-valid"
                                                color="green"
                                                onClick={this.updateCounters}>
                                                <FormattedMessage id="ipCanDevices.counters.countersList.valid" />
                                            </Button>
                                            <Button
                                                data-cy="ipCanDevices-countersList-cancel"
                                                appearance="link"
                                                onClick={this.cancelEdit}>
                                                <FormattedMessage id="ipCanDevices.counters.countersList.cancel" />
                                            </Button>
                                        </SecuredFragment>
                                    </Fragment>
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    <List.Item className="panel-list list-transparent-black-text">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item data-cy="ipCanDevices-substract-countersList-title">
                                <FormattedMessage id="ipCanDevices.counters.substractionCountersList" />
                                {shouldDisplaySubstractionCounterButton && (
                                    <Button
                                        data-cy="ipCanDevices-substract-countersList"
                                        onClick={this.setEditSubstractionCounters}
                                        appearance="link">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                )}
                            </FlexboxGrid.Item>
                            <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.ADD_SUBSTRACTION_COUNTER]}>
                                {this.state.loading ? (
                                    <Loader />
                                ) : (
                                    <FlexboxGrid.Item>
                                        {!this.state.editSubstractionCounters ? (
                                            <TagGroup>
                                                {this.state.displayCounters.substractionCounters.map(counter => (
                                                    <Tag
                                                        data-cy="ipCan-display-counters-elementRemove"
                                                        color="blue"
                                                        key={`counter-${counter.id}`}>
                                                        {counter.label}
                                                    </Tag>
                                                ))}
                                            </TagGroup>
                                        ) : (
                                            <TagPicker
                                                style={{ width: 300 }}
                                                data-cy="ipCanDevices-substract-countersList-select"
                                                defaultValue={this.state.displayCounters.substractionCounters.map(
                                                    c => c.id
                                                )}
                                                data={this.state.counters.map(c => {
                                                    return { label: c.label, value: c.id };
                                                })}
                                                placement="topEnd"
                                                onChange={value =>
                                                    this.setState({
                                                        displayCounters: {
                                                            ...this.state.displayCounters,
                                                            substractionCounters: value,
                                                        },
                                                    })
                                                }
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`counters-remove-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        )}
                                    </FlexboxGrid.Item>
                                )}
                            </SecuredFragment>
                        </FlexboxGrid>
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item>
                                {this.state.editSubstractionCounters && (
                                    <Fragment>
                                        <SecuredFragment
                                            authorizedRoles={[rolesConstants.TCMDisplay.ADD_SUBSTRACTION_COUNTER]}>
                                            <Button
                                                data-cy="ipCanDevices-substract-countersList-valid"
                                                color="green"
                                                onClick={this.updateSubstractionCounters}>
                                                <FormattedMessage id="ipCanDevices.counters.substractionCountersList.valid" />
                                            </Button>
                                            <Button
                                                data-cy="ipCanDevices-substract-countersList-cancel"
                                                appearance="link"
                                                onClick={this.cancelEdit}>
                                                <FormattedMessage id="ipCanDevices.counters.substractionCountersList.cancel" />
                                            </Button>
                                        </SecuredFragment>
                                    </Fragment>
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                </List>
            </Panel>
        );
    }
}

export default injectIntl(CountersCard);
