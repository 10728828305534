import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    Message,
    Progress,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { authHeader } from '../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../redux/services';
import { generatePlaceCameraForceTypes } from '../../../CameraPlaces/Types/PlaceCameraTypes';

type Props = {
    sensors: Array<number>;
    cancelForce: Function;
    nextStep?: Function;
} & WrappedComponentProps;

type State = {
    isLoading: boolean;
    hasError: boolean;
    error: any | null;
    formValue: FormValue;
    forced: Array<number>;
};

type FormValue = {
    isForce: boolean;
    forceType: number;
    // ledMask: number; FF
    // exitLed: boolean; !isForce
    endForceTime: Date;
};

class ForcePlaceCameraSensorPart extends React.Component<Props, State> {
    forceValues: Array<any>;
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            hasError: false,
            error: null,
            formValue: {
                isForce: false,
                forceType: 1,
                // ledMask: 0,
                // exitLed: false,
                endForceTime: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            },
            forced: [],
        };

        this.forceValues = [
            {
                label: this.props.intl.formatMessage({
                    id: 'camera.force.forceType.free',
                }),
                value: 1,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'camera.force.forceType.occupied',
                }),
                value: 2,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'camera.force.forceType.overstay',
                }),
                value: 3,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'camera.force.forceType.unknown',
                }),
                value: 4,
            },
        ];
    }

    sendForce = () => {
        this.setState({
            isLoading: true,
            hasError: false,
            error: null,
        });

        webSocketService.onEvent('place-camera:updateDetection', this.updateStatus);

        axiosService
            .getAxios()
            .put(
                '/places-camera/forcePlaceCamera',
                {
                    tabId: this.props.sensors,
                    isForce: this.state.formValue.isForce,
                    forceType: this.state.formValue.forceType,
                    endForceTime: this.state.formValue.isForce ? this.state.formValue.endForceTime : new Date(),
                    startForceTime: new Date(),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.setState({
                    isLoading: false,
                });

                if (this.props.nextStep) {
                    this.props.nextStep();
                } else {
                    this.props.cancelForce();
                }
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                    forced: [],
                });

                webSocketService.offEvent('place-camera:updateDetection', this.updateStatus);
            });
    };

    updateStatus = e => {
        let forcedArray = this.state.forced;

        if (forcedArray.indexOf(e.id) === -1 && this.props.sensors.indexOf(e.id) !== -1) {
            forcedArray.push(e.id);

            this.setState({
                forced: forcedArray,
            });
        }
    };

    cancel = () => {
        this.props.cancelForce();
    };

    handleChange = formValue => {
        this.setState({
            formValue,
        });
    };

    render = () => {
        return (
            <FlexboxGrid align="middle" justify="space-around">
                {this.state.hasError && (
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <Message
                            type="error"
                            description={this.props.intl.formatMessage(
                                {
                                    id: 'camera.places.forcePlace.error',
                                },
                                {
                                    error: this.state.error,
                                }
                            )}
                        />
                    </FlexboxGrid.Item>
                )}

                {this.state.isLoading ? (
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <Progress.Line
                            percent={(this.state.forced.length / this.props.sensors.length) * 100}
                            showInfo={false}
                        />

                        <p className="text-center">
                            {this.state.forced.length} / {this.props.sensors.length}
                        </p>
                    </FlexboxGrid.Item>
                ) : (
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <Form formValue={this.state.formValue} onChange={this.handleChange}>
                            <FlexboxGrid justify="center" align="middle">
                                <FlexboxGrid.Item>
                                    <FormControl
                                        data-cy="lpSensor-force-isForced"
                                        name="isForce"
                                        accepter={Toggle}
                                        size="lg"
                                        checkedChildren={this.props.intl.formatMessage({
                                            id: 'sensor.force.forced',
                                        })}
                                        unCheckedChildren={this.props.intl.formatMessage({
                                            id: 'sensor.force.unforced',
                                        })}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            {this.state.formValue.isForce && (
                                <FlexboxGrid className="margin-top-15">
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <ControlLabel>
                                            <FormattedMessage id="sensor.force.type" />
                                            <FormControl
                                                name="forceType"
                                                accepter={SelectPicker}
                                                data={generatePlaceCameraForceTypes(this.props.intl)}
                                                searchable={false}
                                                cleanable={false}
                                            />
                                        </ControlLabel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <ControlLabel>
                                            <FormattedMessage id="sensor.force.endForceTime" />
                                            <FormControl
                                                name="endForceTime"
                                                accepter={DatePicker}
                                                data={this.forceValues}
                                                format="DD/MM/YYYY HH:mm:ss"
                                                cleanable={false}
                                            />
                                        </ControlLabel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            )}

                            <FlexboxGrid justify="end" align="middle">
                                <FlexboxGrid.Item>
                                    <ButtonGroup>
                                        <Button
                                            data-cy="placeCamera-sensor-force-cancel"
                                            color="red"
                                            loading={this.state.isLoading}
                                            onClick={() => this.cancel()}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                        <Button
                                            data-cy="placeCamera-sensor-force-valid"
                                            color="green"
                                            loading={this.state.isLoading}
                                            onClick={() => this.sendForce()}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </Button>
                                    </ButtonGroup>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Form>
                    </FlexboxGrid.Item>
                )}
            </FlexboxGrid>
        );
    };
}

export default injectIntl(ForcePlaceCameraSensorPart);
