import { LatLngExpression } from 'leaflet';
import React from 'react';
import { useIntl } from 'react-intl';
import { Polygon, Tooltip } from 'react-leaflet';
import { generateYoloObjectValue } from '../../../handlers/Camera/CameraYoloObjectTypes';
import { MLBox } from '../Hooks/useMLCameraPlace';

type Props = {
    box: MLBox;
    index: number;
    percentageActive: boolean;
    shouldBeFilled: boolean;
    handleAssociateBox: (coordinates: LatLngExpression[]) => void;
};

export const MLPlaceCameraView = (props: Props) => {
    const intl = useIntl();

    const handleClick = () => {
        props.handleAssociateBox(props.box.points);
    }

    return (
        <Polygon positions={props.box.points} key={props.index} name={props.index} color={'#FF0000'} fillOpacity={0} fill={props.shouldBeFilled} onclick={handleClick}>
            {props.percentageActive && (
                <Tooltip opacity={0.7} permanent direction="center">
                    <p>
                        {props.box.confidence}% ({generateYoloObjectValue(intl, props.box.class)})
                    </p>
                </Tooltip>
            )}
        </Polygon>
    );
};
