import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function UpdateTopologyConfig(option) {
    return (
        <div>
            <div data-cy="lpMatrixDisplayV2-historic-virtualDisplays">
                <FormattedMessage id="lpMatrixDisplayV2.history.NBvirtualDisplays" />
                <span className="margin-left-5 margin-right-5">-</span>
                {option.length}
            </div>
        </div>
    );
}
