import { usersModalConstants } from '../constants';

const initialState = {
    create_modal_openned: false,
    delete_modal_openned: false,
    update_modal_openned: false,
    view_drawer_openned: false,
    modal_user: undefined,
};

export function usersModals(state = initialState, action) {
    switch (action.type) {
        case usersModalConstants.OPEN_CREATE_MODAL_SUCCESS:
            return {
                ...state,
                create_modal_openned: true,
            };
        case usersModalConstants.CLOSE_CREATE_MODAL:
            return {
                ...state,
                create_modal_openned: false,
            };
        case usersModalConstants.OPEN_DELETE_MODAL_REQUEST:
            return {
                ...state,
                modal_user: action.user,
            };
        case usersModalConstants.OPEN_DELETE_MODAL_SUCCESS:
            return {
                ...state,
                delete_modal_openned: true,
                modal_user: action.user,
            };
        case usersModalConstants.CLOSE_DELETE_MODAL:
            return {
                ...state,
                modal_user: undefined,
                delete_modal_openned: false,
            };
        case usersModalConstants.OPEN_VIEW_DRAWER_REQUEST:
            return {
                ...state,
                modal_user: action.user,
            };
        case usersModalConstants.OPEN_VIEW_DRAWER_SUCCESS:
            return {
                ...state,
                view_drawer_openned: true,
                modal_user: action.user,
            };
        case usersModalConstants.OPEN_VIEW_DRAWER_FAILURE:
            return {
                ...state,
                view_drawer_openned: false,
                modal_user: undefined,
            };
        case usersModalConstants.CLOSE_VIEW_DRAWER:
            return {
                ...state,
                modal_user: undefined,
                view_drawer_openned: false,
            };
        default:
            return { ...state };
    }
}
