import { faCircle, faCogs, faPowerOff, faUnlock, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';

export default function LPCmdBus(options) {
    const { bus, cmd, red, green, blue } = options;

    if (cmd === 'forceAllSensorToColor') {
        return (
            <Fragment>
                <FormattedMessage id="controller.history.lpCmdBus.options.bus" />
                <Tag className="margin-left-5" color="violet">
                    {bus + 1}
                </Tag>
                <span className="margin-left-5">-</span>
                <div data-cy="history-forceColor">
                    <FontAwesomeIcon
                        className={'margin-left-5'}
                        icon={faCircle}
                        color={`rgb(${red}, ${green}, ${blue})`}
                        size="lg"
                    />
                </div>
            </Fragment>
        );
    }

    if (cmd === 'disableforcedAllSensor') {
        return (
            <Fragment>
                <FormattedMessage id="controller.history.lpCmdBus.options.bus" />
                <Tag className="margin-left-5" color="violet">
                    {bus + 1}
                </Tag>
                <span className="margin-left-5">-</span>
                <div data-cy="history-disableForce">
                    <FontAwesomeIcon className={'margin-left-5'} icon={faUnlock} size="lg" />
                </div>
            </Fragment>
        );
    }

    if (cmd === 'sendAllDevicesConfig') {
        return (
            <Fragment>
                <FormattedMessage id="controller.history.lpCmdBus.options.bus" />
                <Tag className="margin-left-5" color="violet">
                    {bus + 1}
                </Tag>
                <span className="margin-left-5">-</span>
                <div data-cy="history-sendAllDevicesConfig">
                    <FontAwesomeIcon className={'margin-left-5'} icon={faWrench} size="lg" />
                </div>
            </Fragment>
        );
    }

    if (cmd === 'rebootAll') {
        return (
            <Fragment>
                <FormattedMessage id="controller.history.lpCmdBus.options.bus" />
                <Tag className="margin-left-5" color="violet">
                    {bus + 1}
                </Tag>
                <span className="margin-left-5">-</span>
                <div data-cy="history-rebootAll">
                    <FontAwesomeIcon className={'margin-left-5'} icon={faPowerOff} size="lg" color="orange" />
                </div>
            </Fragment>
        );
    }

    if (cmd === 'sendAllDevicesScene') {
        return (
            <Fragment>
                <FormattedMessage id="controller.history.lpCmdBus.options.bus" />
                <Tag className="margin-left-5" color="violet">
                    {bus + 1}
                </Tag>
                <span className="margin-left-5">-</span>
                <div data-cy="history-sendAllDevicesScene">
                    <FontAwesomeIcon className={'margin-left-5'} icon={faCogs} size="lg" />
                </div>
            </Fragment>
        );
    }

    return null;
}
