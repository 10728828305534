import moment from 'moment';
import { colorsValues } from '../ipCanDevices/const/colors';
import History from './History';

export default class TcmSensorHistoricHandler extends History {
    idTcmSensor: number;
    tcmSensorName: string;
    constructor(historyObject) {
        const { idTcmSensor, tcmSensorName } = historyObject;

        super(historyObject);

        this.idTcmSensor = idTcmSensor;
        this.tcmSensorName = tcmSensorName;
    }

    getIdTcmSensor() {
        return this.idTcmSensor;
    }

    setIdTcmSensor(idTcmSensor) {
        this.idTcmSensor = idTcmSensor;
    }

    getTcmSensorName() {
        return this.tcmSensorName;
    }

    setTcmSensorName(tcmSensorName) {
        this.tcmSensorName = tcmSensorName;
    }

    getOption() {
        if (!this.option) {
            return null;
        }

        switch (this.getDesc()) {
            case 'update calibration':
                return {
                    state: this.option.state,
                    value: `${(parseInt(this.option.value) * 3.264).toFixed(0)} cm`,
                };
            case 'update time':
                return {
                    maxFree: `${parseInt(this.option.maxFree)} ms`,
                    maxOccupied: `${parseInt(this.option.maxOccupied)} ms`,
                    provOccupied: `${parseInt(this.option.provOccupied)} ms`,
                };
            case 'update color':
                return {
                    free: colorsValues[this.option.free],
                    occupied: colorsValues[this.option.occupied],
                    overstayFree: colorsValues[this.option.overstayFree],
                    overstay: colorsValues[this.option.overstay],
                    forced: colorsValues[this.option.forced],
                };
            case 'force sensor': {
                const { isForce, forceState, endForceTime } = this.option;
                return {
                    isForce,
                    forceState: forceState,
                    endForceTime: moment(endForceTime).format('DD/MM/YYYY HH:mm:ss'),
                };
            }
            case 'read real sensor config': {
                const { err, config, time, color, calibration, versionSoft, detectionState } = this.option;

                if (err) {
                    return {
                        error: err,
                    };
                } else {
                    const { typePlace, id, numPlace, numPulsation, baudrate, parity } = config;
                    const { maxFree, maxOccupied, provOccupied } = time;
                    const { free, occupied, overstayFree, overstay, forced } = color;
                    const { state, value } = calibration;

                    return {
                        config: {
                            typePlace: parseInt(typePlace),
                            id: parseInt(id),
                            numPlace: parseInt(numPlace),
                            numPulsation: parseInt(numPulsation),
                            baudrate: parseInt(baudrate),
                            parity: parseInt(parity),
                        },
                        time: {
                            maxFree: `${parseInt(maxFree)} ms`,
                            maxOccupied: `${parseInt(maxOccupied)} ms`,
                            provOccupied: `${parseInt(provOccupied)} ms`,
                        },
                        color: {
                            free: colorsValues[free],
                            occupied: colorsValues[occupied],
                            overstayFree: colorsValues[overstayFree],
                            overstay: colorsValues[overstay],
                            forced: colorsValues[forced],
                        },
                        calibration: {
                            state: parseInt(state),
                            value: `${(parseInt(value) * 3.264).toFixed(0)} cm`,
                        },
                        versionSoft: versionSoft,
                        detectionState: parseInt(detectionState),
                    };
                }
            }
            case 'update maintenance state': {
                return {
                    notepad: this.option.notepad,
                    maintenanceState: this.option.maintenanceState,
                };
            }
            default:
                return this.option;
        }
    }

    getHistory(): Record<string, any> {
        return {
            id: this.getId(),
            user: this.getUser(),
            createdAt: this.getCreatedAt(),
            createdBy: this.getCreatedBy(),
            desc: this.getDesc(),
            option: this.getOption(),
            idTcmSensor: this.getIdTcmSensor(),
            tcmSensorName: this.getTcmSensorName(),
        };
    }
}
