import { faEraser, faLightbulb, faPowerOff, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Alert, Button, Col, FlexboxGrid, Panel, Tooltip, Whisper } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { notificationActions } from '../../../redux/actions';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import PanelHeader from '../../Custom/PanelHeader';
import RestartIpCanModal from '../Modal/RestartIpCanModal';
import ResetIpCanModal from '../Modal/RestIpCanModal';
import TestBusModal from '../Modal/TestBusModal';

const mapDispatchToProps = dispatch => ({
    createNotification: (type, messageId) => dispatch(notificationActions.createNotification(type, messageId)),
});

type Props = {
    ipcanModule: IpCan;
} & WrappedComponentProps;

type State = {
    ledTestLoading: boolean;
    busTestLoading: boolean;
    showTestBusModal: boolean;
    showRestartModal: boolean;
    showResetModal: boolean;
    showInvertModal: boolean;
};
class Actions extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            ledTestLoading: false,
            busTestLoading: false,
            showTestBusModal: false,
            showRestartModal: false,
            showResetModal: false,
            showInvertModal: false,
        };

        this.testLedCommand = this.testLedCommand.bind(this);
        this.showTestBusModal = this.showTestBusModal.bind(this);
        this.hideTestBusModal = this.hideTestBusModal.bind(this);
        this.showRestartModal = this.showRestartModal.bind(this);
        this.hideRestartModal = this.hideRestartModal.bind(this);
        this.showResetModal = this.showResetModal.bind(this);
        this.hideResetModal = this.hideResetModal.bind(this);
    }

    showRestartModal() {
        this.setState({
            showRestartModal: true,
        });
    }

    hideRestartModal() {
        this.setState({
            showRestartModal: false,
        });
    }

    showResetModal() {
        this.setState({
            showResetModal: true,
        });
    }

    hideResetModal() {
        this.setState({
            showResetModal: false,
        });
    }

    testLedCommand() {
        this.setState({
            ledTestLoading: true,
        });
        axiosService
            .getAxios()
            .put('/ipcanmodules/cmdTestLed', { id: this.props.ipcanModule.id }, { headers: authHeader() })
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'ipcan.actions.testLed.success' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(this.props.intl.formatMessage({ id: 'ipcan.actions.testLed.error' }, { error: err }));
            })
            .finally(() => {
                this.setState({
                    ledTestLoading: false,
                });
            });
    }

    showTestBusModal() {
        this.setState({
            showTestBusModal: true,
        });
    }

    hideTestBusModal() {
        this.setState({
            showTestBusModal: false,
        });
    }

    render() {
        return (
            <Fragment>
                <Fragment>
                    <TestBusModal
                        ipCan={this.props.ipcanModule}
                        show={this.state.showTestBusModal}
                        onHide={this.hideTestBusModal}
                    />

                    <RestartIpCanModal
                        ipcanId={this.props.ipcanModule.id}
                        onHide={this.hideRestartModal}
                        show={this.state.showRestartModal}
                    />

                    <ResetIpCanModal
                        ipcanId={this.props.ipcanModule.id}
                        onHide={this.hideResetModal}
                        show={this.state.showResetModal}
                    />
                </Fragment>

                <Panel
                    shaded
                    bodyFill
                    bordered
                    className="panel-small"
                    data-cy="ipcan-action-controller"
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'controller.actions.title' })}
                            buttons={[]}
                        />
                    }>
                    <FlexboxGrid justify="space-between">
                        <SecuredFragment
                            key="informations.testLed"
                            authorizedRoles={[rolesConstants.controller.TEST_LED]}>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                colspan={24}
                                md={6}
                                xs={12}
                                className="margin-top-5 margin-bottom-5">
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <FormattedMessage id="controller.action.ledTest" />
                                        </Tooltip>
                                    }>
                                    <Button
                                        color="blue"
                                        block
                                        data-cy="controller-test-led"
                                        onClick={this.testLedCommand}
                                        loading={this.state.ledTestLoading}>
                                        <FontAwesomeIcon icon={faLightbulb} size="lg" />
                                    </Button>
                                </Whisper>
                            </FlexboxGrid.Item>
                        </SecuredFragment>

                        <SecuredFragment
                            key="informations.testBus"
                            authorizedRoles={[rolesConstants.controller.TEST_BUS]}>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                colspan={24}
                                md={6}
                                xs={12}
                                className="margin-top-5 margin-bottom-5">
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <FormattedMessage id="controller.action.busTest" />
                                        </Tooltip>
                                    }>
                                    <Button
                                        color="orange"
                                        block
                                        onClick={() => this.showTestBusModal()}
                                        data-cy="controller-test-bus">
                                        <FontAwesomeIcon icon={faProjectDiagram} size="lg" />
                                    </Button>
                                </Whisper>
                            </FlexboxGrid.Item>
                        </SecuredFragment>

                        <SecuredFragment key="informations.reboot" authorizedRoles={[rolesConstants.controller.REBOOT]}>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                colspan={24}
                                md={6}
                                xs={12}
                                className="margin-top-5 margin-bottom-5">
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <FormattedMessage id="controller.action.reboot" />
                                        </Tooltip>
                                    }>
                                    <Button
                                        color="red"
                                        block
                                        data-cy="controller-reboot"
                                        onClick={this.showRestartModal}>
                                        <FontAwesomeIcon icon={faPowerOff} size="lg" />
                                    </Button>
                                </Whisper>
                            </FlexboxGrid.Item>
                        </SecuredFragment>

                        <SecuredFragment
                            key="informations.resetMemory"
                            authorizedRoles={[rolesConstants.controller.RESET_MEMORY]}>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                colspan={24}
                                md={6}
                                xs={12}
                                className="margin-top-5 margin-bottom-5">
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <FormattedMessage id="controller.action.memoryReset" />
                                        </Tooltip>
                                    }>
                                    <Button
                                        color="red"
                                        block
                                        data-cy="controller-reset-memory"
                                        onClick={this.showResetModal}>
                                        <FontAwesomeIcon icon={faEraser} size="lg" />
                                    </Button>
                                </Whisper>
                            </FlexboxGrid.Item>
                        </SecuredFragment>
                    </FlexboxGrid>
                </Panel>
            </Fragment>
        );
    }
}

export default injectIntl(connect(null, mapDispatchToProps)(Actions));
