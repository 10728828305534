import { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert } from 'rsuite';

type Props = {
    notification: Record<string, any>;
} & WrappedComponentProps;
class Notifications extends Component<Props> {
    componentDidMount() {
        if (this.props.notification.message) {
            const message = this.props.intl.formatMessage({
                id: this.props.notification.message,
            });
            switch (this.props.notification.notificationType) {
                case 'info':
                    Alert.info(message, this.props.notification.duration);
                    break;
                case 'success':
                    Alert.success(message, this.props.notification.duration);
                    break;
                case 'warning':
                    Alert.warning(message, this.props.notification.duration);
                    break;
                case 'error':
                    Alert.error(message, this.props.notification.duration);
                    break;
                default:
                    return null;
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.notification !== prevProps.notification && this.props.notification.message) {
            const message = this.props.intl.formatMessage({
                id: this.props.notification.message,
            });
            switch (this.props.notification.notificationType) {
                case 'info':
                    Alert.info(message, this.props.notification.duration);
                    break;
                case 'success':
                    Alert.success(message, this.props.notification.duration);
                    break;
                case 'warning':
                    Alert.warning(message, this.props.notification.duration);
                    break;
                case 'error':
                    Alert.error(message, this.props.notification.duration);
                    break;
                default:
                    return null;
            }
        }
    }

    render() {
        return null;
    }
}

// function mapStateToProps(state: Record<string, any>) {
//     const { notification } = state;

//     return { notification };
// }

export default injectIntl(Notifications);
