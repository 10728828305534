import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Modal,
    SelectPicker,
} from 'rsuite';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { TriggerModalInformation } from '../FlowChart';
import { ActionObserver, ActionObserverEvent } from '../handlers/Observers/ActionObserver';
import { generateGPIOTrigger, generateTriggerCounter, generateTriggerType } from './Form/TriggerFormValues';

type Props = {
    onHide: Function;
    display: LPDisplay;
    triggerInformation: TriggerModalInformation;
};

export type AddTopologyInformationType = {
    topologyIndexSource: number;
    topologyIndexTarget: number;
    topologyTriggerIndex: number;
};

type FormValues = {
    trigger: number;
    value: number;
    source: number;
};

const CreateTopologyTriggerModal = (props: Props) => {
    const intl = useIntl();

    const [formValue, setFormValue] = React.useState<FormValues>({
        trigger: 1,
        value: 0,
        source: 0,
    });

    const [isCreating, setIsCreating] = React.useState(false);

    useEffect(() => {
        setFormValue({
            trigger: 1,
            value: 0,
            source: 0,
        });
    }, [props.triggerInformation.isOpen]);

    const handleCreateTrigger = () => {
        const topologySourceIndex = props.triggerInformation.connection?.source?.replace('topology-', '');
        const topologyTargetIndex = props.triggerInformation.connection?.target?.replace('topology-', '');
        const triggerIndex = props.triggerInformation.connection?.sourceHandle?.replace('source-topology-', '');

        if (topologySourceIndex && topologyTargetIndex && triggerIndex) {
            setIsCreating(true);

            const topologies = props.display.getTopologyConfig().getTopologys();

            topologies[topologySourceIndex].triggers[triggerIndex] = {
                trigger: formValue.trigger,
                next: parseInt(topologyTargetIndex),
                source: formValue.source,
                value: formValue.value,
            };

            axiosService
                .getAxios()
                .put(
                    '/devices/lp-matrixdisplays-v2/updateTopologysConfig',
                    {
                        id: props.display.getId(),
                        topology: { topologys: topologies },
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(
                        intl.formatMessage({
                            id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.success',
                        })
                    );

                    setIsCreating(false);

                    ActionObserver.emit(ActionObserverEvent.ADD_TOPOLOGY_TRIGGER);

                    props.onHide();
                })
                .catch(err => {
                    console.error(err);

                    setIsCreating(false);

                    Alert.error(
                        intl.formatMessage({
                            id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.error',
                        })
                    );
                });
        }
    };

    const handleFormChange = formValue => {
        setFormValue({
            trigger: parseInt(formValue.trigger),
            source: parseInt(formValue.source),
            value: parseInt(formValue.value),
        });
    };

    return (
        <Modal backdrop="static" show={props.triggerInformation.isOpen} onHide={() => props.onHide()}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="ipCanDevices.lpDisplays.updateTopologys.createTrigger" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form fluid formValue={formValue} onChange={formValue => handleFormChange(formValue)}>
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
                            <FormGroup>
                                <ControlLabel>
                                    {intl.formatMessage({
                                        id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.trigger',
                                    })}
                                </ControlLabel>
                                <FormControl
                                    name="trigger"
                                    accepter={SelectPicker}
                                    cleanable={false}
                                    data={generateTriggerType(intl)}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>

                        {(formValue.trigger === 2 || formValue.trigger === 3) && (
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
                                <FormGroup>
                                    <ControlLabel>
                                        {intl.formatMessage({
                                            id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.source',
                                        })}
                                    </ControlLabel>
                                    <FormControl
                                        name="source"
                                        cleanable={false}
                                        accepter={SelectPicker}
                                        data={generateTriggerCounter(intl)}
                                    />
                                </FormGroup>
                            </FlexboxGrid.Item>
                        )}

                        {formValue.trigger === 4 || formValue.trigger === 5 ? (
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
                                <FormGroup>
                                    <ControlLabel>
                                        {intl.formatMessage({
                                            id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.gpio',
                                        })}
                                    </ControlLabel>
                                    <FormControl
                                        name="value"
                                        cleanable={false}
                                        searchable={false}
                                        accepter={SelectPicker}
                                        data={generateGPIOTrigger(intl)}
                                    />
                                </FormGroup>
                            </FlexboxGrid.Item>
                        ) : (
                            <FlexboxGrid.Item componentClass={Col} xs={24}>
                                <FormGroup>
                                    <ControlLabel>
                                        {intl.formatMessage({
                                            id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.value',
                                        })}
                                    </ControlLabel>
                                    <FormControl name="value" type="number" min={0} />
                                </FormGroup>
                            </FlexboxGrid.Item>
                        )}
                    </FlexboxGrid>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={() => props.onHide()} color="red" disabled={isCreating}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button onClick={handleCreateTrigger} appearance="primary" loading={isCreating}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateTopologyTriggerModal;

// class CreateTopologyTriggerModal extends React.Component<Props, State> {
//     constructor(props: Props) {
//         super(props);

//         this.state = {
//             isCreating: false,
//             formValues: {
//                 mode: 0,
//                 value: 0,
//                 source: 0,
//             },
//         };
//     }

//     onHide = () => {
//         if (!this.state.isCreating) {
//             this.props.onHide();
//         }
//     };

//     handleCreate = () => {
//         const topologies = this.props.display.getTopologyConfig().getTopologys();

//         topologies[this.props.addTopologyInformation.topologyIndexSource].triggers[
//             this.props.addTopologyInformation.topologyTriggerIndex
//         ] = {
//             mode: formValue.mode,
//             next: this.props.addTopologyInformation.topologyIndexTarget,
//             source: formValue.source,
//             value: formValue.value,
//         };

//         axiosService
//             .getAxios()
//             .put(
//                 '/devices/lp-matrixdisplays-v2/updateTopologysConfig',
//                 {
//                     id: this.props.display.getId(),
//                     topology: { topologys: topologies },
//                 },
//                 {
//                     headers: authHeader(),
//                 }
//             )
//             .then(() => {
//                 Alert.success(
//                     intl.formatMessage({
//                         id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.success',
//                     })
//                 );

//                 // TODO: Add edge to graph
//                 this.props.addEdgeConnection(
//                     this.props.addTopologyInformation,
//                     formValue.mode,
//                     formValue.source,
//                     formValue.value
//                 );

//                 this.props.onHide();
//             })
//             .catch(err => {
//                 console.error(err);

//                 Alert.error(
//                     intl.formatMessage({
//                         id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.error',
//                     })
//                 );
//             });
//     };

//     handleChange = formValues => {
//         const formValue: FormValues = formValues;

//         this.setState({
//             formValues: {
//                 mode: formValue.mode,
//                 source: formValue.source,
//                 value: formValue.value,
//             },
//         });
//     };

//     render = () => (
//         <Modal backdrop="static" show={this.props.show} onHide={this.onHide}>
//             <Modal.Header>
//                 <Modal.Title>
//                     <FormattedMessage id="ipCanDevices.lpDisplays.updateTopologys.createTrigger" />
//                 </Modal.Title>
//             </Modal.Header>

//             <Modal.Body>
//                 <Fragment>
//                     {/* <p>
//                         {this.props.addTopologyInformation.topologyIndexSource} -{' '}
//                         {this.props.addTopologyInformation.topologyIndexTarget} -{' '}
//                         {this.props.addTopologyInformation.topologyTriggerIndex}
//                     </p> */}
//                     <Form fluid formValue={formValue} onChange={this.handleChange}>
//                         <FlexboxGrid>
//                             <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
//                                 <FormGroup>
//                                     <ControlLabel>
//                                         {intl.formatMessage({
//                                             id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.mode',
//                                         })}
//                                     </ControlLabel>
//                                     <FormControl
//                                         name="mode"
//                                         accepter={SelectPicker}
//                                         cleanable={false}
//                                         data={generateTriggerType(intl)}
//                                     />
//                                 </FormGroup>
//                             </FlexboxGrid.Item>

//                             {(formValue.mode === 2 || formValue.mode === 3) && (
//                                 <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
//                                     <FormGroup>
//                                         <ControlLabel>
//                                             {intl.formatMessage({
//                                                 id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.source',
//                                             })}
//                                         </ControlLabel>
//                                         <FormControl
//                                             name="source"
//                                             cleanable={false}
//                                             accepter={SelectPicker}
//                                             data={generateTriggerCounter(intl)}
//                                         />
//                                     </FormGroup>
//                                 </FlexboxGrid.Item>
//                             )}

//                             {formValue.mode === 4 || formValue.mode === 5 ? (
//                                 <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
//                                     <FormGroup>
//                                         <ControlLabel>
//                                             {intl.formatMessage({
//                                                 id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.gpio',
//                                             })}
//                                         </ControlLabel>
//                                         <FormControl
//                                             name="value"
//                                             cleanable={false}
//                                             searchable={false}
//                                             accepter={SelectPicker}
//                                             data={generateGPIOTrigger(intl)}
//                                         />
//                                     </FormGroup>
//                                 </FlexboxGrid.Item>
//                             ) : (
//                                 <FlexboxGrid.Item componentClass={Col} xs={24}>
//                                     <FormGroup>
//                                         <ControlLabel>
//                                             {intl.formatMessage({
//                                                 id: 'ipCanDevices.lpDisplays.updateTopologys.createTrigger.value',
//                                             })}
//                                         </ControlLabel>
//                                         <FormControl name="value" type="number" min={0} />
//                                     </FormGroup>
//                                 </FlexboxGrid.Item>
//                             )}
//                         </FlexboxGrid>
//                     </Form>
//                 </Fragment>
//             </Modal.Body>
//             <Modal.Footer>
//                 <ButtonGroup>
//                     <Button onClick={() => this.onHide()} color="red" disabled={this.state.isCreating}>
//                         <FontAwesomeIcon icon={faTimesCircle} />
//                     </Button>
//                     <Button onClick={this.handleCreate} appearance="primary" loading={this.state.isCreating}>
//                         <FontAwesomeIcon icon={faCheck} />
//                     </Button>
//                 </ButtonGroup>
//             </Modal.Footer>
//         </Modal>
//     );
// }

// export default injectIntl(CreateTopologyTriggerModal);
