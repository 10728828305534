import { FlowElement } from 'react-flow-renderer';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { TopologyInterface } from '../../../../../handlers/lpDisplays/LPDisplayTopology';
import FlowNode from './FlowNode';
import { virtualDisplayYPosition } from './FlowNodeExporter';

export interface ColoredPointsInterface {
    color: string;
    isConnected: boolean;
}

export default class TopologyFlowNode extends FlowNode {
    protected _topology: TopologyInterface;
    protected _label: string;
    protected _topologyIndex: number;
    protected _topologiesTriggersColors: ColoredPointsInterface[];
    protected _targetVirtualDisplayColor: ColoredPointsInterface;
    protected _targetTriggerColor: ColoredPointsInterface;

    constructor(
        id: string,
        label: string,
        topologyIndex: number,
        display: LPDisplay,
        topology: TopologyInterface,
        topologiesTriggersColors: ColoredPointsInterface[],
        targetVirtualDisplayColor: ColoredPointsInterface,
        targetTriggerColor: ColoredPointsInterface
    ) {
        super(id, display);
        this._label = label;
        this._topology = topology;
        this._topologyIndex = topologyIndex;
        this._topologiesTriggersColors = topologiesTriggersColors;
        this._targetVirtualDisplayColor = targetVirtualDisplayColor;
        this._targetTriggerColor = targetTriggerColor;
    }

    public toRender(): FlowElement {
        const position = virtualDisplayYPosition();
        return {
            id: this.getId(),
            type: 'topologyNode',
            data: {
                label: this._label,
                display: this._display,
                topology: this._topology,
                topologiesTriggersColors: this._topologiesTriggersColors,
                targetVirtualDisplayColor: this._targetVirtualDisplayColor,
                targetTriggerColor: this._targetTriggerColor,
            },
            position: {
                x: position.initialPositionX,
                y:
                    position.initialPositionY +
                    (position.baseTopologyHeight +
                        position.lineHeight * this._display.getBaseConfig().getNbMatrixY() +
                        position.gapY) *
                        this._topologyIndex,
            },
        };
    }
}
