import { TableColumn } from 'react-data-table-component';
import ElementTable from '../../../ReactDataTableComponent/ElementTable';

export default function RealTimeTable(array: Array<Record<string, any>>) {
    const columnsTable: TableColumn<Record<string, any>>[] = [
        {
            name: 'C1>1',
            center: true,
            compact: true,
            cell: row => row.c1_distance1,
            width: '55px',
            style: {
                backgroundColor: 'rgba(120,99,255, 0.4)',
                fontWeight: 'bold',
            },
        },
        {
            name: 'P1>1',
            center: true,
            compact: true,
            cell: row => row.c1_power1,
            width: '55px',
            style: {
                backgroundColor: 'rgba(255,0,120, 0.4)',
                fontWeight: 'bold',
                borderRight: '1px solid black',
            },
        },
        {
            name: 'C1>2',
            center: true,
            compact: true,
            cell: row => row.c1_distance2,
            width: '55px',
            style: {
                backgroundColor: 'rgba(0,120,0, 0.4)',
                fontWeight: 'bold',
            },
        },
        {
            name: 'P1>2',
            center: true,
            compact: true,
            cell: row => row.c1_power2,
            width: '55px',
            style: {
                backgroundColor: 'rgba(255,99,132, 0.4)',
                fontWeight: 'bold',
                borderRight: '1px solid black',
            },
        },
        {
            name: 'C2>1',
            center: true,
            compact: true,
            cell: row => row.c2_distance1,
            width: '55px',
            style: {
                backgroundColor: 'rgba(64,64,0, 0.4)',
                fontWeight: 'bold',
            },
        },
        {
            name: 'P2>1',
            center: true,
            compact: true,
            cell: row => row.c2_power1,
            width: '55px',
            style: {
                backgroundColor: 'rgba(76,16,76, 0.4)',
                fontWeight: 'bold',
                borderRight: '1px solid black',
            },
        },
        {
            name: 'C2>2',
            center: true,
            compact: true,
            cell: row => row.c2_distance2,
            width: '55px',
            style: {
                backgroundColor: 'rgba(0,175,64, 0.4)',
                fontWeight: 'bold',
            },
        },
        {
            name: 'P2>2',
            center: true,
            compact: true,
            cell: row => row.c2_power2,
            width: '55px',
            style: {
                backgroundColor: 'rgba(189,98,53, 0.4)',
                fontWeight: 'bold',
            },
        },
    ];

    return (
        <div data-cy="realTime-filter-table">
            <ElementTable
                data={array}
                columns={columnsTable}
                dense
                fixedHeaderScrollHeight="45vh"
                fixedHeader
                responsive
            />
        </div>
    );
}
