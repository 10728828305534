import { FlowElement } from 'react-flow-renderer';
import { TriggerInterface } from '../../../../../../../handlers/lpDisplays/LPDisplayTopology';
import { LPMatrixDisplayFlowElement } from '../LPMatrixDisplayChartElement';

export default class LPMatrixDisplayChartTopologyTriggerEdge extends LPMatrixDisplayFlowElement {
    private _index: number;
    private _trigger: TriggerInterface;
    private _source: string;
    private _sourceHandle?: string;
    private _target: string;
    private _targetHandle?: string;

    constructor(data: LPMatrixDisplayChartTopologyTriggerEdgeInterface) {
        super(data.id, data.label);
        this._index = data.index;
        this._trigger = data.trigger;
        this._source = data.source;
        this._sourceHandle = data.sourceHandle;
        this._target = data.target;
        this._targetHandle = data.targetHandle;
    }

    public render(): FlowElement<LPMatrixDisplayChartTopologyTriggerEdgeType> {
        return {
            id: `${this.id}-force`,
            type: 'forceVirtualDisplayTopologyTriggerNode',
            data: {
                trigger: this._trigger,
            },
            source: this._source,
            sourceHandle: this._sourceHandle,
            target: this._target,
            targetHandle: this._targetHandle,
        };
    }
}

export interface LPMatrixDisplayChartTopologyTriggerEdgeInterface {
    id: string;
    index: number;
    trigger: TriggerInterface;
    label: string;
    source: string;
    sourceHandle?: string;
    target: string;
    targetHandle?: string;
}

export type LPMatrixDisplayChartTopologyTriggerEdgeType = {
    trigger: TriggerInterface;
};
