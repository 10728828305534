import { faChevronRight, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Button, Col, FlexboxGrid, List, Loader, Message, Panel, Progress } from 'rsuite';

// STEP 3
type Props = {
    dockerError: boolean;
    retryButton: boolean;
    retryUpdate: Function;
    images: Array<Record<string, any>>;
    imagesOnError: Array<Record<string, any>>;
    nextStep: Function;
    downloadComplete: boolean;
};

enum STATUS {
    ACTIVE = 'active',
    SUCCESS = 'success',
    FAIL = 'fail',
}

export const Downloader = ({ dockerError, retryButton, retryUpdate, images, imagesOnError, nextStep, downloadComplete }: Props) => {
    const intl = useIntl();

    if (dockerError) {
        return (
            <Fragment>
                <Message
                    showIcon
                    type="error"
                    description={intl.formatMessage({id: 'update.automatic.updateError'})}
                />

                {retryButton && (
                    <FlexboxGrid justify="end" className="margin-top-10">
                        <FlexboxGrid.Item>
                            <Button onClick={() => retryUpdate()} color="orange">
                                <FontAwesomeIcon icon={faSyncAlt} className="margin-right-10" />
                                {intl.formatMessage({ id: 'update.automatic.retry' })}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
            </Fragment>
        );
    }
    if (images.length === 0) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Loader size="md" />
            </div>
        );
    } else {
        return (
            <Fragment>
                <FlexboxGrid>
                    {images.map(image => {
                        let status = image.state;
    
                        if (imagesOnError.findIndex(img => img.name === image.name) !== -1) {
                            status = STATUS.FAIL;
                        }
    
                        return (
                            <FlexboxGrid.Item
                                componentClass={Col}
                                colspan={24}
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}
                                key={image.name}>
                                <Panel header={image.name} bodyFill shaded bordered>
                                    <Progress.Line percent={100} status={status} showInfo={false} />
                                    {image.updateErrorMessage && (
                                        <Message showIcon type="error" description={image.updateErrorMessage} />
                                    )}
                                    {image.layers.length > 0 && (
                                        <Panel
                                            header={intl.formatMessage({ id: 'update.automatic.moreInformations'})}
                                            collapsible>
                                            <List>
                                                {image.layers.map(layer => {
                                                    let percent = layer.percent;
                                                    let layerStatus = STATUS.ACTIVE;
    
                                                    if (
                                                        layer.status === 'Already exists' ||
                                                        layer.status === 'Pull complete'
                                                    ) {
                                                        percent = 100;
                                                        layerStatus = STATUS.SUCCESS;
                                                    }
                                                    return (
                                                        <List.Item key={layer.id}>
                                                            <FlexboxGrid align="middle">
                                                                <FlexboxGrid.Item
                                                                    colspan={24}
                                                                    componentClass={Col}
                                                                    xs={8}
                                                                    className="bold">
                                                                    {layer.id}
                                                                </FlexboxGrid.Item>
                                                                <FlexboxGrid.Item
                                                                    colspan={24}
                                                                    componentClass={Col}
                                                                    xs={16}>
                                                                    {/* <FlexboxGrid>
                                                                    <FlexboxGrid.Item colspan={}
                                                                </FlexboxGrid> */}
                                                                    <Progress.Line
                                                                        percent={percent}
                                                                        status={layerStatus}
                                                                        showInfo={true}
                                                                    />
                                                                </FlexboxGrid.Item>
                                                            </FlexboxGrid>
                                                        </List.Item>
                                                    );
                                                })}
                                            </List>
                                        </Panel>
                                    )}
                                </Panel>
                            </FlexboxGrid.Item>
                        );
                    })}
                </FlexboxGrid>
                {retryButton && (
                    <FlexboxGrid justify="end" className="margin-top-10">
                        <FlexboxGrid.Item>
                            <Button onClick={() => retryUpdate()} color="orange">
                                <FontAwesomeIcon icon={faSyncAlt} className="margin-right-10" />
                                {intl.formatMessage({ id: 'update.automatic.retry' })}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
                {downloadComplete && (
                    <FlexboxGrid justify="end" className="margin-top-10">
                        <FlexboxGrid.Item>
                            <Button onClick={() => nextStep()} color="green">
                                <FontAwesomeIcon icon={faChevronRight} className="margin-right-10" />
                                {intl.formatMessage({ id: 'update.automatic.downloadComplete' })}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
            </Fragment>
        );
    }
}