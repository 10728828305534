import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Col, FlexboxGrid, Panel } from 'rsuite';

type Props = {
    displays: Array<Record<string, any>>;
    isLoading: boolean;
} & WrappedComponentProps;

class DisplaysForcedState extends React.Component<Props> {
    render() {
        let donutSize = '100%';
        let donutColSize = 12;

        const colorTab = ['#17a2b8', '#20c997'];

        const state = {
            total: 0,
            forced: { total: 0, percent: 0 },
            unForced: { total: 0, percent: 0 },
        };

        const dataDoughnut = {
            title: '',
            datasets: [
                {
                    data: [0, 0],
                    backgroundColor: colorTab,
                },
            ],
            labels: [
                this.props.intl.formatMessage({ id: 'ipCanElements.displays.forceState.unforced' }),
                this.props.intl.formatMessage({ id: 'ipCanElements.displays.forceState.forced' }),
            ],
        };
        //---
        for (let d in this.props.displays) {
            let display = this.props.displays[d];

            //---
            if (display.isForce) state.forced.total++;
            else state.unForced.total++;
            //---
            state.total++;
        }
        //---
        state.forced.percent = parseFloat(((state.forced.total / state.total) * 100).toFixed(1));
        state.unForced.percent = parseFloat(((state.unForced.total / state.total) * 100).toFixed(1));
        //---
        dataDoughnut.datasets[0].data[0] = state.unForced.total;
        dataDoughnut.datasets[0].data[1] = state.forced.total;

        return (
            <div data-cy="dashboard-counters-card">
                <Loader show={this.props.isLoading}>
                    <Panel
                        className={'dashboard-card-header hoverable'}
                        header={<FormattedMessage id="displays.cardForced.title" />}
                        shaded>
                        <FlexboxGrid align="middle" justify="space-between" style={{ height: donutSize }}>
                            <FlexboxGrid.Item
                                componentClass={Col}
                                colspan={12}
                                xs={donutColSize}
                                style={{ height: donutSize }}>
                                <Doughnut
                                    data={dataDoughnut}
                                    // height={'100%'}
                                    // width={donutSize}
                                    options={{
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        aspectRatio: 1,
                                        legend: {
                                            display: false,
                                        },
                                    }}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} colspan={24} xs={24 - donutColSize}>
                                <div
                                    data-cy="displays-cardForced-forced"
                                    className="parking-legend-title margin-top-15"
                                    style={{ color: '#20c997' }}>
                                    <FormattedMessage id="displays.cardForced.forced" />
                                </div>
                                <div data-cy="displays-cardForced-forced-value" className="parking-legend-value">
                                    {state.forced.total} <FormattedMessage id="displays.cardForced.displays" />
                                    <span className="margin-left-5 small " style={{ color: colorTab[1] }}>
                                        ({state.forced.percent}%)
                                    </span>
                                </div>

                                <div
                                    data-cy="displays-cardForced-unForced"
                                    className="parking-legend-title"
                                    style={{ color: '#17a2b8' }}>
                                    <FormattedMessage id="displays.cardForced.unForced" />
                                </div>
                                <div data-cy="displays-cardForced-unForced-value" className="parking-legend-value">
                                    {state.unForced.total} <FormattedMessage id="displays.cardForced.displays" />
                                    <span className="margin-left-5 small " style={{ color: colorTab[0] }}>
                                        ({state.unForced.percent}%)
                                    </span>
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                </Loader>
            </div>
        );
    }
}

export default injectIntl(DisplaysForcedState);
