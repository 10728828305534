import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Media, TableColumn } from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { Panel } from 'rsuite';
import ElementTable from '../../ReactDataTableComponent/ElementTable';
import { StatusToggle } from './Actions/StatusToggle';
import { useCameraOccupancySensor } from './Hooks/CameraOccupancySensorHook';
import { useCameraSensorVersion } from './Hooks/CameraSensorVersionHook';
import { useReactVersion } from './Hooks/ReactVersionHook';
import { useServiceMLVersionHook } from './Hooks/ServiceMLVersionHook';
import { useVersionFromAPIHook, VersionEnum } from './Hooks/VersionFromApiHook';

export type VersionLine = {
    appName: string;
    isConnected: boolean;
    version: string;
    versionTag: string;
    dateTag: string;
    startDate: string;
    isActiv: boolean;
};

export const VersionTable = () => {
    const intl = useIntl();

    const columns: TableColumn<VersionLine>[] = [
        {
            name: intl.formatMessage({ id: 'configuration.version.appName' }),
            center: true,
            cell: (row: VersionLine) => row.appName,
        },
        {
            name: intl.formatMessage({ id: 'configuration.version.isConnected' }),
            center: true,
            cell: (row: VersionLine) => <FontAwesomeIcon icon={faPlug} color={row.isConnected ? 'green' : 'red'} />,
        },
        {
            name: intl.formatMessage({ id: 'configuration.version.version' }),
            center: true,
            cell: (row: VersionLine) => row.version,
            hide: Media.SM,
        },
        {
            name: intl.formatMessage({ id: 'configuration.version.versionTag' }),
            center: true,
            cell: (row: VersionLine) => row.versionTag,
            hide: Media.SM,
        },
        {
            name: intl.formatMessage({ id: 'configuration.version.dateTag' }),
            center: true,
            cell: (row: VersionLine) => row.dateTag,
            hide: Media.SM,
        },
        {
            name: intl.formatMessage({ id: 'configuration.version.startDate' }),
            center: true,
            cell: (row: VersionLine) => row.startDate,
            hide: Media.SM,
        },
        {
            name: intl.formatMessage({ id: 'configuration.version.state' }),
            center: true,
            cell: (row: VersionLine) => StatusToggle(row.appName, row.isActiv),
        },
    ];

    return (
        <Panel
            className="panel-small"
            bodyFill
            shaded
            header={intl.formatMessage({ id: 'configuration.version.title' })}>
            <ElementTable
                columns={columns}
                data={[
                    useReactVersion(),
                    useVersionFromAPIHook(VersionEnum.API),
                    useVersionFromAPIHook(VersionEnum.ENGINE),
                    useCameraSensorVersion(),
                    useCameraOccupancySensor(),
                    useServiceMLVersionHook(),
                ]}
                small={false}
            />
        </Panel>
    );
};
