import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { colorsValues } from '../const/colors';

export default class TCMSensorColor {
    protected _free: number;
    protected _occupied: number;
    protected _overstayFree: number;
    protected _overstay: number;
    protected _forced: number;

    constructor(color_) {
        const { free, occupied, overstayFree, overstay, forced } = color_;

        this._free = free;
        this._occupied = occupied;
        this._overstayFree = overstayFree;
        this._overstay = overstay;
        this._forced = forced;
    }

    public get free() {
        return this._free;
    }

    public get occupied() {
        return this._occupied;
    }

    public get overstayFree() {
        return this._overstayFree;
    }

    public get overstay() {
        return this._overstay;
    }

    public get forced() {
        return this._forced;
    }

    public set free(free_: number) {
        this._free = free_;
    }

    public set occupied(occupied_: number) {
        this._occupied = occupied_;
    }

    public set overstayFree(overstayFree_: number) {
        this._overstayFree = overstayFree_;
    }

    public set overstay(overstay_: number) {
        this._overstay = overstay_;
    }

    public set forced(forced_: number) {
        this._forced = forced_;
    }

    public get fetchColors() {
        return {
            free: colorsValues[this._free],
            occupied: colorsValues[this._occupied],
            overstayFree: colorsValues[this._overstayFree],
            overstay: colorsValues[this._overstay],
            forced: colorsValues[this._forced],
        }
    }

    setColors(sensorId_: number, free_: number, occupied_: number, overstayFree_: number, overstay_: number, forced_: number) {
        this._free = free_;
        this._occupied = occupied_;
        this._overstayFree = overstayFree_;
        this._overstay = overstay_;
        this._forced = forced_;

        return axiosService.getAxios().put('/devices/tcm-sensor/updateColorConfig', {
            id: sensorId_,
            free: free_,
            occupied: occupied_,
            overstayFree: overstayFree_,
            overstay: overstay_,
            forced: forced_,
        }, {
            headers: authHeader()
        });
    }
}
