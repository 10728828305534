import getPreview from './Desc/IpCamera/getPreview';
import getPreviewFinish from './Desc/IpCamera/getPreviewFinish';
import goHome from './Desc/IpCamera/goHome';
import movePtz from './Desc/IpCamera/movePtz';
import saveHome from './Desc/IpCamera/saveHome';
import sendStreamConf from './Desc/IpCamera/sendStreamConf';
import offline from './Desc/offline';
import online from './Desc/online';
import updateConfig from './Desc/updateConfig';
import updateMaintenanceState from './Desc/updateMaintenanceState';

export enum IP_CAMERA_HISTORY_EVENTS {
    ONLINE = 'online',
    OFFLINE = 'offline',
    UPDATE_CONF = 'update config',
    GET_PREVIEW = 'get preview',
    GET_PREVIEW_FINISH = 'get preview finish',
    SEND_STREAM_CONF = 'send stream config',
    MOVE_PTZ = 'move ptz',
    SAVE_HOME = 'save home',
    GO_HOME = 'go home',
    UPDATE_MAINTENANCE_STATE = 'update maintenance state',
}

export default function IpCameraDescCell(props: IP_CAMERA_HISTORY_EVENTS) {
    switch (props) {
        case IP_CAMERA_HISTORY_EVENTS.ONLINE:
            return online();
        case IP_CAMERA_HISTORY_EVENTS.OFFLINE:
            return offline();
        case IP_CAMERA_HISTORY_EVENTS.UPDATE_CONF:
            return updateConfig();
        case IP_CAMERA_HISTORY_EVENTS.GET_PREVIEW:
            return getPreview();
        case IP_CAMERA_HISTORY_EVENTS.GET_PREVIEW_FINISH:
            return getPreviewFinish();
        case IP_CAMERA_HISTORY_EVENTS.SEND_STREAM_CONF:
            return sendStreamConf();
        case IP_CAMERA_HISTORY_EVENTS.MOVE_PTZ:
            return movePtz();
        case IP_CAMERA_HISTORY_EVENTS.SAVE_HOME:
            return saveHome();
        case IP_CAMERA_HISTORY_EVENTS.GO_HOME:
            return goHome();
        case IP_CAMERA_HISTORY_EVENTS.UPDATE_MAINTENANCE_STATE:
            return updateMaintenanceState();
        default:
            return null;
    }
}
