import React from 'react';
import { FlexboxGrid, List } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    sensor: Record<string, any>;
};

type State = {
    number: number;
};
export default class SensorItem extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            number: 0,
        };
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get(`/devices/nbSensorOnSensorPlaceType/${this.props.sensor.id}`, { headers: authHeader() })
            .then(response => {
                this.setState({
                    number: response.data,
                });
            });
    }

    render() {
        return (
            <List.Item key={this.props.sensor.id} className="list-small padding-left-20 padding-right-20">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        {this.props.sensor.label}{' '}
                        <span className="parking-level-description">- {this.props.sensor.description}</span>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{this.state.number}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        );
    }
}
