import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Message, Modal } from 'rsuite';
import { Configuration } from '../../../handlers/Configuration/Configuration';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type State = {
    error: boolean;
    loading: boolean;
};

type Props = {
    configuration?: Configuration;
    closeDeleteConfigurationModal: (shouldReload: boolean) => void;
    show: boolean;
} & WrappedComponentProps;
class DeleteConfigurationModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            loading: false,
        };

        this.deleteConfiguration = this.deleteConfiguration.bind(this);
    }

    deleteConfiguration() {
        this.setState({
            loading: true,
        });

        const data = {
            headers: authHeader(),
            data: { id: this.props.configuration?.getId() },
        };

        axiosService
            .getAxios()
            .delete('/configuration', data)
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'configuration.delete.success' }));

                this.props.closeDeleteConfigurationModal(true);
            })
            .catch(err => {
                Alert.error(this.props.intl.formatMessage({ id: 'configuration.delete.error' }));

                console.error(err);

                this.setState({
                    error: true,
                });
            });
    }

    render() {
        return (
            <Modal
                backdrop="static"
                show={this.props.show}
                onHide={() => this.props.closeDeleteConfigurationModal(false)}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="configuration.advanced.deleteConfiguration.title" /> -{' '}
                        {this.props.configuration?.getSection()} - {this.props.configuration?.getKey()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="configuration-advanced-deleteModal">
                    {this.state.error && <Message type="error" description="Une erreur s'est produite..." />}
                    <FormattedMessage id="configuration.advanced.deleteConfiguration.message" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.props.closeDeleteConfigurationModal(false)}
                            data-cy="configuration-advanced-deleteModal-cancel"
                            color="red"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.deleteConfiguration}
                            data-cy="configuration-advanced-deleteModal-valid"
                            appearance="primary"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(DeleteConfigurationModal);
