import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, FlexboxGrid, List, Loader } from 'rsuite';
import PlaceCamera from '../../../../handlers/Camera/PlaceCamera';
import { Configuration } from '../../../../handlers/Configuration/Configuration';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    place: PlaceCamera;
};

export const HyperParamPlaceCameraView = (props: Props) => {
    const intl = useIntl();

    const [configurations, setConfigurations] = useState<Configuration[]>([]);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/configuration', { headers: authHeader() })
            .then(configurationResponse => {
                setConfigurations(
                    configurationResponse.data
                        .map(configuration => new Configuration(configuration))
                        .filter(configuration => configuration.getSection() === 'CameraOccupancySensor')
                );
            })
            .catch(err => {
                Alert.error(intl.formatMessage({ id: 'placeCamera.hyperParam.fetchConfigurationError' }));
                console.error(err);
            });
    }, []);

    // History size
    const getHistorySize = () => {
        if (props.place.getConfig().getHistorySize()) {
            return props.place.getConfig().getHistorySize();
        }

        const foundConfiguration = configurations.find(configuration => configuration.getKey() === 'history_size');

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    // Prior coeff KX
    const getPriorCoeffKx = () => {
        if (props.place.getConfig().getPriorCoeffKx()) {
            return props.place.getConfig().getPriorCoeffKx();
        }

        const foundConfiguration = configurations.find(configuration => configuration.getKey() === 'prior_coeff_kx');

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    // Prior coeff KY
    const getPriorCoeffKy = () => {
        if (props.place.getConfig().getPriorCoeffKy()) {
            return props.place.getConfig().getPriorCoeffKy();
        }

        const foundConfiguration = configurations.find(configuration => configuration.getKey() === 'prior_coeff_ky');

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    // Prior coeff Ox
    const getPriorCoeffOx = () => {
        if (props.place.getConfig().getPriorCoeffOx()) {
            return props.place.getConfig().getPriorCoeffOx();
        }

        const foundConfiguration = configurations.find(configuration => configuration.getKey() === 'prior_coeff_ox');

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    // Prior coeff Oy
    const getPriorCoeffOy = () => {
        if (props.place.getConfig().getPriorCoeffOy()) {
            return props.place.getConfig().getPriorCoeffOy();
        }

        const foundConfiguration = configurations.find(configuration => configuration.getKey() === 'prior_coeff_oy');

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    return (
        <List>
            {/* History size */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.historySize" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{getHistorySize()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* Prior coeff Kx */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.priorCoeffKx" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{getPriorCoeffKx()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* Prior coeff Ky */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.priorCoeffKy" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{getPriorCoeffKy()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* Prior coeff Ox */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.priorCoeffOx" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{getPriorCoeffOx()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* Prior coeff Oy */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.priorCoeffOy" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{getPriorCoeffOy()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
};
