import { faDownload, faExclamationTriangle, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Alert, Button, ButtonGroup, FlexboxGrid, List, Panel, Popover, Tooltip, Whisper } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import ExportDatabaseModal from './Modal/ExportDatabaseModal';
import ImportDatabaseModal from './Modal/ImportDatabaseModal';
import ResetDatabaseModal from './Modal/ResetDatabaseModal';

type Props = {
    database: string;
} & WrappedComponentProps;

type State = {
    currentVersion: number;
    tableSize: number;
    tableLines: number;
    databaseTables: Array<Record<string, any>>;
    isLoading: boolean;
    exportDatabaseOpened: boolean;
    importDatabaseOpened: boolean;
    resetDatabaseOpened: boolean;
    hasError: boolean;
    error: any;
};

class DatabaseCard extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            currentVersion: 0,
            tableSize: 0,
            tableLines: 0,
            databaseTables: [],
            isLoading: true,
            exportDatabaseOpened: false,
            importDatabaseOpened: false,
            resetDatabaseOpened: false,
            hasError: false,
            error: null,
        };

        this.openExportDatabaseModal = this.openExportDatabaseModal.bind(this);
        this.closeExportDatabaseModal = this.closeExportDatabaseModal.bind(this);
        this.openImportDatabaseModal = this.openImportDatabaseModal.bind(this);
        this.closeImportDatabaseModal = this.closeImportDatabaseModal.bind(this);
        this.openResetDatabaseModal = this.openResetDatabaseModal.bind(this);
        this.closeResetDatabaseModal = this.closeResetDatabaseModal.bind(this);
        this.getDatabaseInformations = this.getDatabaseInformations.bind(this);
    }

    componentDidMount() {
        this.getDatabaseInformations();
    }

    getDatabaseInformations() {
        this.setState({
            isLoading: true,
        });

        axiosService
            .getAxios()
            .get(`/configuration/database/infos/${this.props.database}`, {
                headers: authHeader(),
            })
            .then(configuration => {
                this.setState({
                    currentVersion: configuration.data.currentVersion || 0,
                    tableSize: configuration.data.tableSize || 0,
                    tableLines: configuration.data.tableLines || 0,
                    databaseTables:
                        configuration.data.databaseTables.map(table => {
                            return {
                                ...table,
                                rows: parseInt(table.rows),
                                size: parseFloat(table.size).toFixed(2),
                            };
                        }) || [],
                    hasError: false,
                    error: null,
                });
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage(
                        {
                            id: `configuration.database.${this.props.database}.error`,
                        },
                        {
                            error: err,
                        }
                    ),
                    10000
                );

                this.setState({
                    hasError: true,
                    error: err,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    openExportDatabaseModal() {
        this.setState({
            exportDatabaseOpened: true,
        });
    }

    closeExportDatabaseModal() {
        this.setState({
            exportDatabaseOpened: false,
        });
    }

    openImportDatabaseModal() {
        this.setState({
            importDatabaseOpened: true,
        });
    }

    closeImportDatabaseModal(shouldReload: boolean = false) {
        this.setState({
            importDatabaseOpened: false,
            isLoading: shouldReload,
        });

        if (shouldReload) {
            this.getDatabaseInformations();
        }
    }

    openResetDatabaseModal() {
        this.setState({
            resetDatabaseOpened: true,
        });
    }

    closeResetDatabaseModal() {
        this.getDatabaseInformations();
        this.setState({
            resetDatabaseOpened: false,
        });
    }

    render() {
        return (
            <Fragment>
                <SecuredFragment authorizedRoles={[rolesConstants.configuration.VIEW_LIST]}>
                    <Panel
                        bodyFill
                        shaded
                        className="panel-small"
                        header={<FormattedMessage id={'configuration.database.' + this.props.database} />}>
                        <Loader show={this.state.isLoading}>
                            <List hover>
                                <List.Item className="panel-list">
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                            <FormattedMessage
                                                id={'configuration.database.' + this.props.database + '.currentVersion'}
                                            />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>
                                            {this.state.currentVersion}{' '}
                                            {this.state.hasError && (
                                                <Whisper
                                                    trigger="hover"
                                                    speaker={
                                                        <Popover
                                                            title={this.props.intl.formatMessage({
                                                                id: `configuration.database.${this.props.database}.error.title`,
                                                            })}>
                                                            <p>
                                                                <FormattedMessage
                                                                    id={`configuration.database.${this.props.database}.error`}
                                                                />
                                                            </p>
                                                        </Popover>
                                                    }
                                                    placement="auto">
                                                    <FontAwesomeIcon icon={faExclamationTriangle} color="orange" />
                                                </Whisper>
                                            )}
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>

                                <List.Item className="panel-list">
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                            <FormattedMessage
                                                id={'configuration.database.' + this.props.database + '.tableSize'}
                                            />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>{this.state.tableSize.toFixed(2)} Mb</FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>

                                <List.Item className="panel-list">
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                            <FormattedMessage
                                                id={'configuration.database.' + this.props.database + '.tableLines'}
                                            />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item>{this.state.tableLines}</FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>

                                <List.Item className="panel-table">
                                    <Panel
                                        className="panel-list margin-top-0"
                                        header={
                                            <FormattedMessage
                                                id={'configuration.database.' + this.props.database + '.tables'}
                                            />
                                        }
                                        collapsible
                                        bodyFill>
                                        {this.state.databaseTables.map(table => {
                                            return (
                                                <FlexboxGrid
                                                    key={table.name}
                                                    justify="space-between"
                                                    className="padding-10">
                                                    <FlexboxGrid.Item className="bold">{table.name}</FlexboxGrid.Item>
                                                    <FlexboxGrid.Item>
                                                        {table.rows}{' '}
                                                        <FormattedMessage id="configuration.database.rows" /> (
                                                        {table.size} Mb)
                                                    </FlexboxGrid.Item>
                                                </FlexboxGrid>
                                            );
                                        })}
                                    </Panel>
                                </List.Item>

                                <List.Item className="panel-list">
                                    <ButtonGroup justified>
                                        <SecuredFragment
                                            authorizedRoles={[rolesConstants.configuration.IMPORT_DATABASE]}>
                                            <Whisper
                                                placement="top"
                                                trigger="hover"
                                                speaker={
                                                    <Tooltip>
                                                        <FormattedMessage id="configuration.database.import" />
                                                    </Tooltip>
                                                }>
                                                <Button
                                                    color="orange"
                                                    onClick={this.openImportDatabaseModal}
                                                    data-cy={`import-database-${this.props.database}-button`}>
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>
                                            </Whisper>
                                        </SecuredFragment>

                                        <SecuredFragment
                                            authorizedRoles={[rolesConstants.configuration.EXPORT_DATABASE]}>
                                            <Whisper
                                                placement="top"
                                                trigger="hover"
                                                speaker={
                                                    <Tooltip>
                                                        <FormattedMessage id="configuration.database.export" />
                                                    </Tooltip>
                                                }>
                                                <Button
                                                    color="green"
                                                    onClick={this.openExportDatabaseModal}
                                                    data-cy={`export-database-${this.props.database}-button`}>
                                                    <FontAwesomeIcon icon={faSave} />
                                                </Button>
                                            </Whisper>
                                        </SecuredFragment>

                                        <SecuredFragment
                                            authorizedRoles={[rolesConstants.configuration.RESET_DATABASE]}>
                                            <Whisper
                                                placement="top"
                                                trigger="hover"
                                                speaker={
                                                    <Tooltip>
                                                        <FormattedMessage id="configuration.database.reset" />
                                                    </Tooltip>
                                                }>
                                                <Button
                                                    color="red"
                                                    onClick={this.openResetDatabaseModal}
                                                    data-cy={`reset-database-${this.props.database}-button`}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </Button>
                                            </Whisper>
                                        </SecuredFragment>
                                    </ButtonGroup>
                                </List.Item>
                            </List>
                        </Loader>
                    </Panel>

                    <SecuredFragment authorizedRoles={[rolesConstants.configuration.EXPORT_DATABASE]}>
                        <ExportDatabaseModal
                            show={this.state.exportDatabaseOpened}
                            close={this.closeExportDatabaseModal}
                            database={this.props.database}
                        />
                    </SecuredFragment>
                    <SecuredFragment authorizedRoles={[rolesConstants.configuration.IMPORT_DATABASE]}>
                        <ImportDatabaseModal
                            show={this.state.importDatabaseOpened}
                            close={this.closeImportDatabaseModal}
                            database={this.props.database}
                        />
                    </SecuredFragment>
                    <SecuredFragment authorizedRoles={[rolesConstants.configuration.RESET_DATABASE]}>
                        <ResetDatabaseModal
                            show={this.state.resetDatabaseOpened}
                            close={this.closeResetDatabaseModal}
                            database={this.props.database}
                        />
                    </SecuredFragment>
                </SecuredFragment>
            </Fragment>
        );
    }
}

export default injectIntl(DatabaseCard);
