import { faCheck, faEdit, faPalette, faTimesCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { CompactPicker } from 'react-color';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import { Button, ButtonGroup, ButtonToolbar, FlexboxGrid, Form, FormControl, InputNumber, List, Toggle } from 'rsuite';
import { sceneSensorPlaceTypeActions, sensorPlaceTypeActions } from '../../../../redux/actions';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import DeleteSceneModal from '../../Modal/Scene/DeleteSceneModal';
import CheckCell from '../List/CheckCell';
import ColorCell from '../List/ColorCell';

const styleCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
};

const colors = [
    '#000000',
    '#000055',
    '#0000AA',
    '#0000FF',
    '#005500',
    '#005555',
    '#0055AA',
    '#0055FF',
    '#00AA00',
    '#00AA55',
    '#00AAAA',
    '#00AAFF',
    '#550000',
    '#550055',
    '#5500AA',
    '#5500FF',
    '#555500',
    '#555555',
    '#5555AA',
    '#5555FF',
    '#55AA00',
    '#55AA55',
    '#55AAAA',
    '#55AAFF',
    '#AA0000',
    '#AA0055',
    '#AA00AA',
    '#AA00FF',
    '#AA5500',
    '#AA5555',
    '#AA55AA',
    '#AA55FF',
    '#AAAA00',
    '#AAAA55',
    '#AAAAAA',
    '#AAAAFF',
    '#FF0000',
    '#FF0055',
    '#FF00AA',
    '#FF00FF',
    '#FF5500',
    '#FF5555',
    '#FF55AA',
    '#FF55FF',
    '#FFAA00',
    '#FFAA55',
    '#FFAAAA',
    '#FFAAFF',
    '#00FF00',
    '#00FF55',
    '#00FFAA',
    '#00FFFF',

    '#55FF00',
    '#55FF55',
    '#55FFAA',
    '#55FFFF',
    '#AAFF00',
    '#AAFF55',
    '#AAFFAA',
    '#AAFFFF',
    '#FFFF00',
    '#FFFF55',
    '#FFFFAA',
    '#FFFFFF',

    '#000000',
    '#040404',
    '#080808',
    '#0C0C0C',
    '#101010',
    '#141414',
    '#181818',
    '#1C1C1C',
    '#202020',
    '#242424',
    '#282828',
    '#2C2C2C',
    '#303030',
    '#343434',
    '#383838',
    '#3C3C3C',
    '#404040',
    '#444444',
    '#484848',
    '#4C4C4C',
    '#505050',
    '#555555',
    '#595959',
    '#5D5D5D',
    '#616161',
    '#656565',
    '#696969',
    '#6D6D6D',
    '#717171',
    '#757575',
    '#797979',
    '#7D7D7D',
    '#818181',
    '#858585',
    '#898989',
    '#8D8D8D',
    '#919191',
    '#959595',
    '#999999',
    '#9D9D9D',
    '#A1A1A1',
    '#A5A5A5',
    '#AAAAAA',
    '#AEAEAE',
    '#B2B2B2',
    '#B6B6B6',
    '#BABABA',
    '#BEBEBE',
    '#C2C2C2',
    '#C6C6C6',
    '#CACACA',
    '#CECECE',
    '#D2D2D2',
    '#D6D6D6',
    '#DADADA',
    '#DEDEDE',
    '#E2E2E2',
    '#E6E6E6',
    '#EAEAEA',
    '#EEEEEE',
    '#F2F2F2',
    '#F6F6F6',
    '#FAFAFA',
    '#FFFFFF',
];

const mapDispatchToProps = dispatch => ({
    getSensorPlaceTypes: () => dispatch(sensorPlaceTypeActions.getAll()),
    updateScene: (id, led1, led2, led3, led4, led5, led6, color, time, state, sensorPlaceTypeId) =>
        dispatch(
            sceneSensorPlaceTypeActions.update(
                id,
                led1,
                led2,
                led3,
                led4,
                led5,
                led6,
                color,
                time,
                state,
                sensorPlaceTypeId
            )
        ),
});

type Props = {
    scene: Record<string, any>;
    updateScene: Function;
    index: number;
    colorPickerRef: Record<string, any>;
    shouldDisplayDeleteButton: boolean;
} & WrappedComponentProps;

type State = {
    deleteModalOpen: boolean;
    editMode: boolean;
    formValue: Record<string, any>;
    showColorPicker: boolean;
};

class Scene extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            deleteModalOpen: false,
            editMode: false,
            formValue: this.props.scene,
            showColorPicker: false,
        };

        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.setEditMode = this.setEditMode.bind(this);
        this.unsetEditMode = this.unsetEditMode.bind(this);
        this.handleEditScene = this.handleEditScene.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeColor = this.handleChangeColor.bind(this);
        this.handleShowColorPicker = this.handleShowColorPicker.bind(this);
    }

    hideDeleteModal() {
        this.setState({
            deleteModalOpen: false,
        });
    }

    openDeleteModal() {
        this.setState({
            deleteModalOpen: true,
        });
    }

    setEditMode() {
        this.setState({
            editMode: true,
        });
    }

    unsetEditMode() {
        this.setState({
            editMode: false,
            formValue: this.props.scene,
        });
    }

    handleEditScene() {
        this.setState({
            editMode: false,
        });

        this.props.updateScene(
            parseInt(this.props.scene.id),
            this.state.formValue.led1,
            this.state.formValue.led2,
            this.state.formValue.led3,
            this.state.formValue.led4,
            this.state.formValue.led5,
            this.state.formValue.led6,
            this.state.formValue.color,
            parseInt(this.state.formValue.time),
            this.props.scene.state
        );
    }

    handleChange(value) {
        this.setState({
            formValue: { ...this.state.formValue, ...value },
        });
    }

    handleChangeColor(color) {
        this.setState({
            formValue: { ...this.state.formValue, color: color.hex },
        });
    }

    handleShowColorPicker() {
        this.setState({
            showColorPicker: !this.state.showColorPicker,
        });
    }

    render() {
        if (this.props.scene) {
            return (
                <Fragment>
                    <SecuredFragment authorizedRoles={[rolesConstants.sceneSensorPlaceType.UPDATE]}>
                        <DeleteSceneModal
                            show={this.state.deleteModalOpen}
                            onHide={this.hideDeleteModal}
                            scene={this.props.scene}
                        />
                        <Loader show={this.props.scene.isLoading}>
                            <List.Item
                                index={this.props.index}
                                disabled={this.state.editMode}
                                data-cy={`sceneSensor-${this.props.scene.state}`}>
                                {this.state.editMode ? (
                                    <Form formValue={this.state.formValue} onChange={this.handleChange}>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                                <FormControl
                                                    data-cy="sceneSensor-edit-led1"
                                                    name="led1"
                                                    accepter={Toggle}
                                                    defaultChecked={this.state.formValue.led1}
                                                />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                                <FormControl
                                                    data-cy="sceneSensor-edit-led2"
                                                    name="led2"
                                                    accepter={Toggle}
                                                    defaultChecked={this.state.formValue.led2}
                                                />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                                <FormControl
                                                    data-cy="sceneSensor-edit-led3"
                                                    name="led3"
                                                    accepter={Toggle}
                                                    defaultChecked={this.state.formValue.led3}
                                                />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                                <FormControl
                                                    data-cy="sceneSensor-edit-led4"
                                                    name="led4"
                                                    accepter={Toggle}
                                                    defaultChecked={this.state.formValue.led4}
                                                />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                                <FormControl
                                                    data-cy="sceneSensor-edit-led5"
                                                    name="led5"
                                                    accepter={Toggle}
                                                    defaultChecked={this.state.formValue.led5}
                                                />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                                <FormControl
                                                    data-cy="sceneSensor-edit-led6"
                                                    name="led6"
                                                    accepter={Toggle}
                                                    defaultChecked={this.state.formValue.led6}
                                                />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item style={styleCenter} colspan={4}>
                                                <FormControl
                                                    data-cy="sceneSensor-edit-time"
                                                    fluid
                                                    name="time"
                                                    accepter={InputNumber}
                                                    style={{ width: 120 }}
                                                />
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item style={styleCenter} colspan={5}>
                                                <FlexboxGrid justify="center">
                                                    <FlexboxGrid.Item colspan={24} style={{ textAlign: 'center' }}>
                                                        <Button
                                                            block
                                                            data-cy="sensorPlaceTypeScene-edit-color"
                                                            onClick={this.handleShowColorPicker}
                                                            style={{
                                                                backgroundColor: this.state.formValue.color,
                                                                border: '1px solid black',
                                                            }}>
                                                            <FontAwesomeIcon icon={faPalette} />
                                                        </Button>
                                                    </FlexboxGrid.Item>
                                                    <FlexboxGrid.Item>
                                                        {this.state.showColorPicker && (
                                                            <CompactPicker
                                                                color={this.state.formValue.color}
                                                                onChangeComplete={this.handleChangeColor}
                                                                colors={colors}
                                                            />
                                                        )}
                                                    </FlexboxGrid.Item>
                                                </FlexboxGrid>
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                                <ButtonToolbar>
                                                    <ButtonGroup>
                                                        {this.state.editMode ? (
                                                            <Fragment>
                                                                <Button
                                                                    data-cy="sceneSensor-edit-valid"
                                                                    size="sm"
                                                                    color="green"
                                                                    onClick={this.handleEditScene}>
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </Button>
                                                                <Button
                                                                    data-cy="sceneSensor-edit-cancel"
                                                                    size="sm"
                                                                    color="red"
                                                                    onClick={this.unsetEditMode}>
                                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                                </Button>
                                                            </Fragment>
                                                        ) : (
                                                            <Button
                                                                data-cy="sceneSensor-edit-button"
                                                                size="sm"
                                                                color="blue"
                                                                onClick={this.setEditMode}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                        )}
                                                    </ButtonGroup>
                                                </ButtonToolbar>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </Form>
                                ) : (
                                    <FlexboxGrid align="middle">
                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <CheckCell isChecked={this.props.scene.led1} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <CheckCell isChecked={this.props.scene.led2} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <CheckCell isChecked={this.props.scene.led3} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <CheckCell isChecked={this.props.scene.led4} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <CheckCell isChecked={this.props.scene.led5} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <CheckCell isChecked={this.props.scene.led6} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item
                                            style={styleCenter}
                                            colspan={4}
                                            data-cy="sceneSensor-view-time">
                                            <span className="margin-right-5">{this.props.scene.time}</span>
                                            <FormattedMessage id="sensorPlaceTypeScene.edit.seconds" />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item
                                            style={styleCenter}
                                            data-cy="sceneSensor-view-color"
                                            colspan={5}>
                                            <ColorCell color={this.props.scene.color} />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={3}>
                                            <ButtonToolbar>
                                                <ButtonGroup>
                                                    <SecuredFragment
                                                        authorizedRoles={[rolesConstants.sceneSensorPlaceType.UPDATE]}>
                                                        <Button
                                                            data-cy="sceneSensor-edit-button"
                                                            size="sm"
                                                            color="blue"
                                                            onClick={this.setEditMode}>
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Button>
                                                    </SecuredFragment>
                                                    {this.props.shouldDisplayDeleteButton && (
                                                        <SecuredFragment
                                                            authorizedRoles={[
                                                                rolesConstants.sceneSensorPlaceType.DELETE,
                                                            ]}>
                                                            <Button
                                                                data-cy="sceneSensor-delete-button"
                                                                onClick={this.openDeleteModal}
                                                                size="sm"
                                                                color="red">
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </Button>
                                                        </SecuredFragment>
                                                    )}
                                                </ButtonGroup>
                                            </ButtonToolbar>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                )}
                            </List.Item>
                        </Loader>
                    </SecuredFragment>
                </Fragment>
            );
        } else {
            return null;
        }
    }
}

export default injectIntl(connect(null, mapDispatchToProps)(Scene));
