type Props = {
    color: string;
};

export const TaxiAltIcon = ({ color }: Props) => (
    <svg
        data-cy="Taxi-Alt-Icon"
        id="SVGRoot"
        width="100%"
        height="100%"
        version="1.1"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} x="192" y="32" width="128" height="96" />
            <rect fill={color} x="96" y="480" width="96" height="32" />
            <rect fill={color} transform="scale(1,-1)" x="160" y="-128" width="192" height="32" />
            <rect fill={color} y="352" width="160" height="32" />
            <rect fill={color} x="-1.9073e-6" y="320" width="64" height="32" />
            <rect fill={color} x="448" y="320" width="64" height="32" />
            <rect fill={color} x="352" y="352" width="160" height="32" />
            <rect fill={color} x="96" y="320" width="320" height="32" />
            <rect fill={color} x="32" y="256" width="448" height="32" />
            <rect fill={color} y="288" width="512" height="32" />
            <rect fill={color} y="384" width="512" height="32" />
            <rect fill={color} x="32" y="416" width="448" height="32" />
            <rect fill={color} x="192" y="32" width="32" height="96" />
            <rect fill={color} x="96" y="416" width="96" height="96" />
            <rect fill={color} x="160" y="416" width="32" height="96" />
            <rect fill={color} x="320" y="480" width="96" height="32" />
            <rect fill={color} x="320" y="416" width="96" height="96" />
            <rect fill={color} x="384" y="416" width="32" height="96" />
            <rect fill={color} x="288" y="32" width="32" height="96" />
            <rect fill={color} x="384" y="160" width="32" height="128" />
            <rect fill={color} x="96" y="160" width="32" height="128" />
            <rect fill={color} x="352" y="128" width="32" height="32" />
            <rect fill={color} x="128" y="128" width="32" height="32" />
            <g>
                <rect fill={color} x="32" y="256" width="448" height="64" />
                <rect fill={color} y="288" width="64" height="128" />
                <rect fill={color} x="448" y="288" width="64" height="128" />
            </g>
            <rect fill={color} x="96" y="288" width="320" height="64" />
            <rect fill={color} x="96" y="320" width="64" height="96" />
            <rect fill={color} x="352" y="320" width="64" height="96" />
            <rect fill={color} x="352" y="352" width="128" height="96" />
            <rect fill={color} x="32" y="352" width="128" height="96" />
            <rect fill={color} x="160" y="384" width="192" height="64" />
        </g>
    </svg>
);
