import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';

export default function updateCalibration(option) {
    const { state, value } = option;

    return (
        <Fragment>
            <FontAwesomeIcon icon={state ? faCheck : faTimes} color={state ? 'green' : 'red'} />
            <span className="margin-left-5">-</span>
            <span data-cy="historic-updateCalibration" className="margin-left-5">
                {value}
            </span>
        </Fragment>
    );
}
