import { EdgeProps, getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';
import { Icon, IconButton } from 'rsuite';

type DataType = {
    handleButtonClick: Function;
    // isLoading: boolean;
};

type Props = EdgeProps<DataType>;

const EdgeWithButton = (props: Props) => {
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        targetX: props.targetX,
        targetY: props.targetY,
    });

    const FOREIGN_OBJECT_SIZE = 25;

    const edgePath = getBezierPath({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        sourcePosition: props.sourcePosition,
        targetX: props.targetX,
        targetY: props.targetY,
        targetPosition: props.targetPosition,
    });

    const markerEnd = getMarkerEnd(props.arrowHeadType, props.markerEndId);

    return (
        <>
            <path
                id={props.id}
                d={edgePath}
                style={props.style}
                className="react-flow__edge-path"
                markerEnd={markerEnd}
            />

            <foreignObject
                width={FOREIGN_OBJECT_SIZE}
                height={FOREIGN_OBJECT_SIZE}
                x={edgeCenterX - FOREIGN_OBJECT_SIZE / 2}
                y={edgeCenterY - FOREIGN_OBJECT_SIZE / 2}>
                <IconButton
                    size="xs"
                    icon={<Icon icon="close" />}
                    circle
                    color="red"
                    // loading={props.data?.isLoading}
                    onClick={() => props.data?.handleButtonClick()}
                />
            </foreignObject>
        </>
    );
};

export default EdgeWithButton;
