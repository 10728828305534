import React from 'react';

const arrow = ['Full', 'X', 'NoPsign', 'No'];

type Props = {
    value: number;
};
export default class Text extends React.Component<Props> {
    render() {
        return (
            <span data-cy="display-text" data-tag="allowRowEvents">
                {arrow[this.props.value]}
            </span>
        );
    }
}
