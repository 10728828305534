import React from 'react';
import { useIntl } from 'react-intl';
import { match, useRouteMatch } from 'react-router';
import { Message } from 'rsuite';
import { PictoMonochrome } from './PictoMonochrome';
import { PictoRGB } from './PictoRGB';

export const PixelArtPictoEditor = () => {
    const routeParams: match<RouteParams> | null = useRouteMatch(
        '/ipCan/:ipCanId/bus/:busNumber/matrix-display/:displayId/picto/:type'
    );

    const intl = useIntl();

    if (!routeParams) {
        return <Message full type="error" description={intl.formatMessage({ id: 'pixelArt.pictoEditor.error' })} />;
    }

    const type = parseInt(routeParams.params.type, 10);

    if (type === 10) {
        return <PictoRGB />;
    } else {
        return <PictoMonochrome />;
    }
};

type RouteParams = {
    ipCanId: string;
    busNumber: string;
    displayId: string;
    type: string;
};
