import { FlowElement } from 'react-flow-renderer';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../handlers/lpDisplays/VirtualDisplayStep';
import FlowNode from './FlowNode';
import { virtualDisplayXPosition, virtualDisplayYPosition } from './FlowNodeExporter';

export default class VirtualDisplayStepFlowNode extends FlowNode {
    private _virtualDisplay: VirtualDisplayPreviewInformation;
    private _virtualDisplayIndex: number;
    private _step: VirtualDisplayStep;
    private _stepIndex: number;
    private _isFirst: boolean;
    private _isLast: boolean;

    constructor(
        id: string,
        display: LPDisplay,
        virtualDisplay: VirtualDisplayPreviewInformation,
        virtualDisplayIndex: number,
        step: VirtualDisplayStep,
        stepIndex: number,
        isFirst: boolean,
        isLast: boolean
    ) {
        super(id, display);
        this._virtualDisplay = virtualDisplay;
        this._virtualDisplayIndex = virtualDisplayIndex;
        this._step = step;
        this._stepIndex = stepIndex;
        this._isFirst = isFirst;
        this._isLast = isLast;
    }

    public getVirtualDisplay(): VirtualDisplayPreviewInformation {
        return this._virtualDisplay;
    }

    public getVirtualDisplayStep(): VirtualDisplayStep {
        return this._step;
    }

    public toRender(): FlowElement {
        const xPosition = virtualDisplayXPosition();
        const yPosition = virtualDisplayYPosition();

        return {
            id: this.getId(),
            type: 'virtualDisplayStepNode',
            data: {
                stepIndex: this._stepIndex,
                step: this.getVirtualDisplayStep(),
                display: this.getDisplay(),
                virtualDisplay: this.getVirtualDisplay(),
                isFirst: this._isFirst,
                isLast: this._isLast,
            },
            position: {
                x:
                    xPosition.initialPositionX +
                    (xPosition.baseTopologyWidth +
                        xPosition.columnWidth * this._display.getBaseConfig().getNbMatrixX() * 2 +
                        xPosition.gapX) *
                        (this._stepIndex + 2),
                y:
                    yPosition.initialPositionY +
                    (yPosition.baseTopologyHeight +
                        yPosition.lineHeight * this._display.getBaseConfig().getNbMatrixY() +
                        yPosition.gapY) *
                        this._virtualDisplayIndex,
            },
        };
    }
}
