import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Message, Modal } from 'rsuite';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    id: Array<number>;
    onHide: Function;
    show: boolean;
    intl: Record<string, any>;
} & WrappedComponentProps;

type State = {
    isDeleting: boolean;
    hasError: boolean;
};
class DeleteSensorModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isDeleting: false,
            hasError: false,
        };

        this.deleteSensor = this.deleteSensor.bind(this);
    }

    deleteSensor() {
        this.setState({
            isDeleting: true,
            hasError: false,
        });

        axiosService
            .getAxios()
            .delete('/devices/tcm-sensor', {
                headers: authHeader(),
                data: {
                    tabId: this.props.id,
                },
            })
            .then(() => {
                this.setState({
                    isDeleting: false,
                });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'sensor.delete.success',
                    }),
                    5000
                );

                this.props.onHide(true);
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            });
    }

    render() {
        return (
            <Modal backdrop={true} show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="sensor.delete.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="ipcan-elements-sensor-delete-modal">
                    {this.state.hasError && (
                        <Message type="error" description={<FormattedMessage id="sensor.delete.error" />} />
                    )}
                    <FormattedMessage id="sensor.delete.message" />
                </Modal.Body>
                <Modal.Footer>
                    <FlexboxGrid justify="end" align="middle">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    onClick={() => this.props.onHide()}
                                    data-cy="ipcan-elements-sensor-delete-cancel"
                                    color="red"
                                    loading={this.state.isDeleting}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    onClick={this.deleteSensor}
                                    data-cy="ipcan-elements-sensor-delete-valid"
                                    appearance="primary"
                                    loading={this.state.isDeleting}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(DeleteSensorModal);
