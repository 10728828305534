import { TaskTypeEnum } from '../../Components/Event/Modal/Tasks/CreateTaskType';
import Counter from '../Counter/Counter';
import { IpCan } from '../ipcan/IpCan';
import TCMDisplay from '../ipCanDevices/TCMDisplay';
import { LPDisplay, LPDisplayData } from '../lpDisplays/LPDisplay';
import TaskForceOption from './TaskForceOption';

export default class Task {
    private _id: number;
    private _counters: Counter[];
    private _createdAt: Date;
    private _createdBy: string;
    private _desc: string;
    private _forceOption: TaskForceOption;
    private _forceType: TaskTypeEnum;
    private _name: string;
    private _tcmDisplays: TCMDisplay[];
    private _lpMatrixDisplaysV2: LPDisplay[];
    private _ipCans: IpCan[];
    private _updatedAt: Date;
    private _updatedBy: string;

    constructor(data: TaskData) {
        this._id = data.id;
        this._counters = data.counters.map(counter => new Counter(counter));
        this._createdAt = data.createdAt;
        this._createdBy = data.createdBy;
        this._desc = data.desc;
        this._forceOption = new TaskForceOption(data.forceOption);
        this._forceType = data.forceType;
        this._name = data.name;
        this._tcmDisplays = data.tcmDisplays.map(tcmDisplay => new TCMDisplay(tcmDisplay, 0, null, null));
        this._lpMatrixDisplaysV2 = data.lPMatrixDisplaysV2.map(lpDisplay => new LPDisplay(lpDisplay, 0, null));
        this._ipCans = data.ipCanModules.map(ipCan => new IpCan(ipCan));
        this._updatedAt = data.updatedAt;
        this._updatedBy = data.updatedBy;
    }

    public getId(): number {
        return this._id;
    }

    public getCounters(): Counter[] {
        return this._counters;
    }

    public getCreatedAt(): Date {
        return this._createdAt;
    }

    public getCreatedBy(): string {
        return this._createdBy;
    }

    public getDesc(): string {
        return this._desc;
    }

    public getForceOption(): TaskForceOption {
        return this._forceOption;
    }

    public getForceType(): TaskTypeEnum {
        return this._forceType;
    }

    public getName(): string {
        return this._name;
    }

    public getTcmDisplay(): TCMDisplay[] {
        return this._tcmDisplays;
    }

    public getLpMatrixDisplaysV2(): LPDisplay[] {
        return this._lpMatrixDisplaysV2;
    }

    public getIpCans(): IpCan[] {
        return this._ipCans;
    }

    public getUpdatedAt(): Date {
        return this._updatedAt;
    }

    public getUpdatedBy(): string {
        return this._updatedBy;
    }
}

export interface TaskData {
    id: number;
    counters: any[];
    createdAt: Date;
    createdBy: string;
    desc: string;
    forceOption: any;
    forceType: TaskTypeEnum;
    name: string;
    tcmDisplays: any[];
    lPMatrixDisplaysV2: LPDisplayData[];
    ipCanModules: any;
    updatedAt: Date;
    updatedBy: string;
}

export interface TaskColorOption {
    red: number;
    green: number;
    blue: number;
    ledMask: number;
}
