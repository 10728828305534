import React from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import { OccupancyBox } from '../Hooks/useOccupancySensorPlace';

type Props = {
    box: OccupancyBox;
    index: number;
    statePredictionActive: boolean;
};

export const OccupancyPlaceCameraView = (props: Props) => {
    const historyValue = (
        (props.box.history.reduce((acc, curr) => acc + curr, 0) / props.box.history.length) *
        100
    ).toFixed(2);

    return (
        <Polygon positions={props.box.prior} key={props.index} name={props.index} color={'#FF00FF'} fillOpacity={0}>
            {props.statePredictionActive && (
                <Tooltip opacity={0.7} permanent direction="center">
                    {props.box.statePrediction ? (
                        <p>
                            {(props.box.statePrediction * 100).toFixed(2)}% ({historyValue}%)
                        </p>
                    ) : (
                        <p>0% ({historyValue}%)</p>
                    )}
                </Tooltip>
            )}
        </Polygon>
    );
};
