import { LatLngExpression } from 'leaflet';
import moment from 'moment';
import { MaintenanceInformation } from '../MaintenanceInformation';
import { SensorPlaceType } from '../sensorPlaceType/SensorPlaceType';
import { PlaceIPCamera } from './ApiCamera';
import { HyperParametersConfig } from './HyperParametersConfig';

export default class PlaceCamera extends MaintenanceInformation {
    private _id: number;
    private _state: PlaceCameraState;
    private _statePrediction: number;
    private _overstayTime: number;
    private _geoJSON: LatLngExpression[];
    private _idPlace: number;
    private _label: string;
    private _detectionStateTime: Date;
    private _isForce: boolean;
    private _forceType: PlaceCameraState;
    private _endForceTime: Date;
    private _startForceTime: Date;
    private _createdAt: Date;
    private _updatedAt: Date;
    private _createdBy: string;
    private _updatedBy: string;
    private _sensorPlaceType: SensorPlaceType | null;
    private _config: HyperParametersConfig;
    private _posGeoJSON: LatLngExpression;

    constructor(place: PlaceIPCamera) {
        super(place.maintenanceState, place.notepad);

        this._id = place.id;
        this._state = place.state;
        this._statePrediction = place.statePrediction;
        this._overstayTime = place.overstayTime;
        this._geoJSON = place.geoJSON;
        this._idPlace = place.idPlace;
        this._label = place.label;
        this._detectionStateTime = place.detectionStateTime;
        this._isForce = place.isForce;
        this._forceType = place.forceType;
        this._endForceTime = place.endForceTime;
        this._startForceTime = place.startForceTime;
        this._config = new HyperParametersConfig(place.config);
        this._createdAt = place.createdAt;
        this._updatedAt = place.updatedAt;
        this._createdBy = place.createdBy;
        this._updatedBy = place.updatedBy;
        this._posGeoJSON = place.posGeoJSON;
        this._sensorPlaceType = place.sensorPlaceType ? new SensorPlaceType(place.sensorPlaceType) : null;
    }

    public getId(): number {
        return this._id;
    }

    public getState(): PlaceCameraState {
        return this._state;
    }

    public getStatePrediction(): number {
        return this._statePrediction;
    }

    public getOverstayTime(): number {
        return this._overstayTime;
    }

    public getGeoJSON(): LatLngExpression[] {
        return this._geoJSON;
    }

    public getIdPlace(): number {
        return this._idPlace;
    }

    public getLabel(): string {
        return this._label;
    }

    public getDetectionStateTime(): string {
        return moment(this._detectionStateTime).format('DD/MM/YYYY HH:mm:ss');
    }

    public getIsForce(): boolean {
        return this._isForce;
    }

    public getForceType(): PlaceCameraState {
        return this._forceType;
    }

    public getEndForceTime(): string {
        return moment(this._endForceTime).format('DD/MM/YYYY HH:mm:ss');
    }

    public getStartForceTime(): string {
        return moment(this._startForceTime).format('DD/MM/YYYY HH:mm:ss');
    }

    public getConfig(): HyperParametersConfig {
        return this._config;
    }

    public getCreatedAt(): string {
        return moment(this._createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getUpdatedAt(): string {
        return moment(this._updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreatedBy(): string {
        return this._createdBy;
    }

    public getUpdatedBy(): string {
        return this._updatedBy;
    }

    public getSensorPlaceType(): SensorPlaceType | null {
        return this._sensorPlaceType;
    }

    public getCreation(): string {
        return this.getCreatedAt() + ' - ' + this.getCreatedBy();
    }

    public getUpdate(): string {
        return this.getUpdatedAt() + ' - ' + this.getUpdatedBy();
    }

    public setGeoJSON(geoJSON: LatLngExpression[]): void {
        this._geoJSON = geoJSON;
    }

    public getPosGeoJSON(): LatLngExpression {
        return this._posGeoJSON;
    }

    public setPosGeoJSON(posGeoJSON: LatLngExpression): void {
        this._posGeoJSON = posGeoJSON;
    }

    public updatePlace(place: PlaceIPCamera): void {
        this._state = place.state;
        this._statePrediction = place.statePrediction;
        this._overstayTime = place.overstayTime;
        this._geoJSON = place.geoJSON;
        this._posGeoJSON = place.posGeoJSON;
        this._idPlace = place.idPlace;
        this._label = place.label;
        this._detectionStateTime = place.detectionStateTime;
        this._isForce = place.isForce;
        this._forceType = place.forceType;
        this._endForceTime = place.endForceTime;
        this._startForceTime = place.startForceTime;
        if (place.config) {
            this._config = new HyperParametersConfig(place.config);
        }
        this._createdAt = place.createdAt;
        this._updatedAt = place.updatedAt;
        this._createdBy = place.createdBy;
        this._updatedBy = place.updatedBy;

        if (place.sensorPlaceType) {
            this._sensorPlaceType = new SensorPlaceType(place.sensorPlaceType);
        }
    }

    public updatePlaceWS(place: WSPlaceIPCamera) {
        this._state = place.state;
        this._statePrediction = place.statePrediction;
        this._overstayTime = place.overstayTime;
        this._label = place.label;
        this._isForce = place.isForce;
        this._forceType = place.forceType;
        this._endForceTime = place.endForceTime;
        this._startForceTime = place.startForceTime;
    }
}

export enum PlaceCameraState {
    FREE = 0,
    OCCUPIED = 1,
    OVERSTAY = 2,
    UNKNOWN = 3,
}

export interface PlaceCameraConfig {}

export interface ServiceMLConvertWSInterface {
    target: number;
    bbox: ServiceMLConvertBoxInterface[];
}

interface ServiceMLConvertBoxInterface {
    confidence: number;
    class: number;
    bbox: number[][];
}

export interface ServiceOccupancySensorWSInterface {
    target: number;
    infos: ServiceOccupencySensorInterface[];
}

interface ServiceOccupencySensorInterface {
    id: number;
    state: number;
    overstayTime: number;
    idPlace: number;
    history: number[];
    slot: number[][];
    prior: number[][];
    continuous_state: number;
    statePrediction: number;
}

export interface WSPlaceIPCamera {
    endForceTime: Date;
    forceType: PlaceCameraState;
    id: number;
    isForce: boolean;
    label: string;
    overstayTime: number;
    startForceTime: Date;
    state: PlaceCameraState;
    statePrediction: number;
}
