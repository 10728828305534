import { faArrowCircleUp, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const arrow = [0, 270, 90, 0, 180, 315, 45, 225, 135];

type Props = {
    value: number;
    history?: boolean;
};
export default class Arrows extends React.Component<Props> {
    render() {
        if (this.props.value === 0) {
            return (
                <FontAwesomeIcon
                    data-cy="display-no-arrows"
                    data-tag="allowRowEvents"
                    size={this.props.history ? '1x' : '2x'}
                    icon={faTimesCircle}
                    color="red"
                />
            );
        } else {
            return (
                <FontAwesomeIcon
                    data-cy="display-arrows"
                    data-tag="allowRowEvents"
                    size={this.props.history ? '1x' : '2x'}
                    icon={faArrowCircleUp}
                    style={{ transform: `rotate(${arrow[this.props.value]}deg)` }}
                />
            );
        }
    }
}
