import { Component } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Alert } from 'rsuite';
import Login from './Components/Auth/Login/login';
import { ProtectedRoute } from './Components/Custom/ProtectedRoute';
import Dashboard from './Components/Dashboard/dashboard';
import { authActions } from './redux/actions';
import { authHeader } from './redux/helpers';
import { axiosService } from './redux/services';

type Props = {
    auth: Record<string, any>;
} & WrappedComponentProps;

const mapDispatchToProps = (dispatch: Function) => ({
    logout: (): void => dispatch(authActions.logout(true)),
});

class AppWithIntl extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.state = {
            disconnectTime: Infinity,
        }

        Alert.config({
            duration: 3000,
        });
    }
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/login" component={Login} />
                    <ProtectedRoute isAuthenticated={this.props.auth.loggedIn} path="/" component={Dashboard} />
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state: Record<string, any>) {
    const { auth } = state;

    return { auth };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AppWithIntl));