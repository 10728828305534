import { sceneSensorPlaceTypeConstants } from '../constants';
import { sceneSensorPlaceTypeService } from '../services/sceneSensorPlaceType.services';
import { notificationActions } from './notification.actions';
import { sensorPlaceTypeActions } from './sensorPlaceType.actions';

export const sceneSensorPlaceTypeActions = {
    getScenes,
    // getOne,
    create,
    remove,
    update,
    updateOrder,
};

function getScenes(sensorPlaceTypeId) {
    return dispatch => {
        dispatch(request());

        sceneSensorPlaceTypeService.getScenes(sensorPlaceTypeId).then(
            scenesSensorPlaceType => dispatch(success(scenesSensorPlaceType)),
            error => {
                dispatch(failure(error));
                dispatch(notificationActions.createNotification('error', 'sceneSensorPlaceType.error.fetchScenes'));
            }
        );
    };

    function request() {
        return { type: sceneSensorPlaceTypeConstants.GET_ALL_REQUEST };
    }
    function success(scenesSensorPlaceType) {
        return { type: sceneSensorPlaceTypeConstants.GET_ALL_SUCCESS, scenesSensorPlaceType };
    }
    function failure(error) {
        return { type: sceneSensorPlaceTypeConstants.GET_ALL_FAILURE, error };
    }
}

// function getOne(id) {
//     return dispatch => {
//         dispatch(request());

//         sceneSensorPlaceTypeService.getById(id).then(
//             sensorPlaceType => dispatch(success(sensorPlaceType)),
//             error => {
//                 dispatch(failure(error));
//                 dispatch(
//                     notificationActions.createNotification(
//                         'error',
//                         'sceneSensorPlaceType.error.fetchsensorPlaceTypeError'
//                     )
//                 );
//             }
//         );
//     };

//     function request() {
//         return { type: sceneSensorPlaceTypeConstants.GETONE_REQUEST };
//     }
//     function success(sensorPlaceType) {
//         return { type: sceneSensorPlaceTypeConstants.GETONE_SUCCESS, sensorPlaceType };
//     }
//     function failure(error) {
//         return { type: sceneSensorPlaceTypeConstants.GETONE_FAILURE, error };
//     }
// }

function remove(id) {
    return dispatch => {
        dispatch(request(id));

        sceneSensorPlaceTypeService.remove(id).then(
            data => {
                dispatch(success(data));
                dispatch(
                    notificationActions.createNotification(
                        'success',
                        'sceneSensorPlaceType.success.removesensorPlaceTypeSuccess'
                    )
                );
            },
            error => {
                dispatch(failure(id, error));
                dispatch(
                    notificationActions.createNotification(
                        'error',
                        'sceneSensorPlaceType.error.removesensorPlaceTypeError'
                    )
                );
            }
        );
    };

    function request(id) {
        return { type: sceneSensorPlaceTypeConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: sceneSensorPlaceTypeConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: sceneSensorPlaceTypeConstants.DELETE_FAILURE, id, error };
    }
}

function create(led1, led2, led3, led4, led5, led6, color, time, state, sensorPlaceTypeId) {
    const crypto = window.crypto;
    var array = new Uint32Array(1);

    return dispatch => {
        let id = crypto.getRandomValues(array);
        let newsensorPlaceType = {
            id: id,
            led1,
            led2,
            led3,
            led4,
            led5,
            led6,
            color,
            time,
            state,
            sensorPlaceTypeId,
        };
        dispatch(request(newsensorPlaceType, state));

        sceneSensorPlaceTypeService
            .create(led1, led2, led3, led4, led5, led6, color, time, state, sensorPlaceTypeId)
            .then(
                sceneSensorPlaceType => {
                    dispatch(success(sceneSensorPlaceType, id, state));
                    dispatch(
                        notificationActions.createNotification(
                            'success',
                            'sceneSensorPlaceType.success.createsensorPlaceTypeSuccess'
                        )
                    );
                },
                error => {
                    dispatch(failure(error, id));
                    dispatch(
                        notificationActions.createNotification(
                            'error',
                            'sceneSensorPlaceType.error.createsensorPlaceTypeError'
                        )
                    );
                }
            );
    };

    function request(sceneSensorPlaceType, state) {
        return { type: sceneSensorPlaceTypeConstants.CREATE_REQUEST, sceneSensorPlaceType, state };
    }
    function success(sceneSensorPlaceType, id, state) {
        return { type: sceneSensorPlaceTypeConstants.CREATE_SUCCESS, sceneSensorPlaceType, id: id, state };
    }
    function failure(error, id) {
        return { type: sceneSensorPlaceTypeConstants.CREATE_FAILURE, error, id: id };
    }
}

function update(id, led1, led2, led3, led4, led5, led6, color, time, state, sensorPlaceTypeId) {
    return dispatch => {
        dispatch(request(id));

        sceneSensorPlaceTypeService
            .update(id, led1, led2, led3, led4, led5, led6, color, time, state, sensorPlaceTypeId)
            .then(
                sceneSensorPlaceType => {
                    dispatch(success(sceneSensorPlaceType));
                    dispatch(
                        notificationActions.createNotification(
                            'success',
                            'sceneSensorPlaceType.success.updatesensorPlaceTypeSuccess'
                        )
                    );
                    dispatch(sensorPlaceTypeActions.getAll());
                },
                error => {
                    dispatch(failure(error, id));
                    dispatch(
                        notificationActions.createNotification(
                            'error',
                            'sceneSensorPlaceType.error.updatesensorPlaceTypeError'
                        )
                    );
                }
            );
    };

    function request(id) {
        return { type: sceneSensorPlaceTypeConstants.UPDATE_REQUEST, id };
    }
    function success(sceneSensorPlaceType) {
        return { type: sceneSensorPlaceTypeConstants.UPDATE_SUCCESS, sceneSensorPlaceType };
    }
    function failure(error, id) {
        return { type: sceneSensorPlaceTypeConstants.UPDATE_FAILURE, error, id };
    }
}

function updateOrder(tabId, state) {
    return dispatch => {
        dispatch(request());

        sceneSensorPlaceTypeService.updateOrder(tabId).then(
            sceneSensorPlaceType => {
                dispatch(success(sceneSensorPlaceType));
                dispatch(
                    notificationActions.createNotification(
                        'success',
                        'sceneSensorPlaceType.success.updatesensorPlaceTypeSuccess'
                    )
                );
            },
            error => {
                dispatch(failure(error));
                dispatch(
                    notificationActions.createNotification(
                        'error',
                        'sceneSensorPlaceType.error.updatesensorPlaceTypeError'
                    )
                );
            }
        );
    };

    function request() {
        return { type: sceneSensorPlaceTypeConstants.UPDATE_ORDER_REQUEST };
    }
    function success(scenesSensorPlaceType) {
        return { type: sceneSensorPlaceTypeConstants.UPDATE_ORDER_SUCCESS, scenesSensorPlaceType, state };
    }
    function failure(error) {
        return { type: sceneSensorPlaceTypeConstants.UPDATE_ORDER_FAILURE, error };
    }
}
