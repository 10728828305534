import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, List, Panel } from 'rsuite';
import { RestFull } from '../../../../../../handlers/profile/RestFull';
import { User } from '../../../../../../handlers/profile/User';
import { rolesConstants } from '../../../../../../static/roles';
import SecuredFragment from '../../../../../Auth/SecuredFragment';
import PanelHeader from '../../../../../Custom/PanelHeader';
import { CreateRestFullModal } from './Modal/CreateRestFullModal';
import { RestFullListItem } from './RestFullListItem';

type Props = {
    user: User;
    reloadUsers: () => void;
};

export const UserRestFull = (props: Props) => {
    const intl = useIntl();

    const authenticatedUser = useSelector((state: any) => state.auth.user);

    const [createModalOpen, setCreateModalOpen] = React.useState<boolean>(false);

    const handleCloseCreateModal = (shouldReload: boolean) => {
        setCreateModalOpen(false);
        if (shouldReload) {
            props.reloadUsers();
        }
    };

    return (
        <>
            <CreateRestFullModal
                open={createModalOpen}
                onHide={handleCloseCreateModal}
                user={props.user.getId() !== authenticatedUser.id ? props.user : undefined}
            />

            <Panel
                shaded
                bordered
                className="panel-small"
                data-cy="users-resetFull"
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'user.restFull' })}
                        buttons={[
                            <SecuredFragment
                                key="userRestfull.create"
                                authorizedRoles={
                                    props.user.getId() === authenticatedUser.id
                                        ? [rolesConstants.restFull.CREATE]
                                        : [rolesConstants.restFull.CREATE_FOR_USER]
                                }>
                                <Button
                                    data-cy="userRestfull-createButton"
                                    onClick={() => setCreateModalOpen(true)}
                                    appearance="primary"
                                    size="sm">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </Button>
                            </SecuredFragment>,
                        ]}
                    />
                }>
                <List>
                    {props.user.getRestFulls().map((restFull: RestFull) => (
                        <RestFullListItem
                            key={restFull.getId()}
                            restFullItem={restFull}
                            reloadUsers={props.reloadUsers}
                        />
                    ))}
                </List>
            </Panel>
        </>
    );
};
