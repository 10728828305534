import { faCheck, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, Col, ControlLabel, FlexboxGrid, Form, FormControl, Panel, Toggle } from 'rsuite';
import { User } from '../../../../../handlers/profile/User';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import { rolesConstants } from '../../../../../static/roles';
import SecuredFragment from '../../../../Auth/SecuredFragment';
import PanelHeader from '../../../../Custom/PanelHeader';

type Props = {
    user: User;
    reloadUsers: () => void;
};

type FormValue = {
    firstName: string;
    lastName: string;
    email: string;
    enabled: boolean;
};

export const UserInformations = (props: Props) => {
    const intl = useIntl();

    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const [formValue, setFormValue] = React.useState<FormValue>({
        firstName: props.user.getFirstName(),
        lastName: props.user.getLastName(),
        email: props.user.getEmail(),
        enabled: props.user.getEnabled(),
    });
    const [isEditing, setIsEditing] = React.useState<boolean>(false);

    const handleCancel = () => {
        setIsEditMode(false);
        setFormValue({
            firstName: props.user.getFirstName(),
            lastName: props.user.getLastName(),
            email: props.user.getEmail(),
            enabled: props.user.getEnabled(),
        });
    };

    const handleFormChange = (value: FormValue) => {
        setFormValue(value);
    };

    const handleValid = () => {
        setIsEditing(true);

        axiosService
            .getAxios()
            .put(
                '/users/updateInformations',
                {
                    id: props.user.getId(),
                    firstName: formValue.firstName,
                    lastName: formValue.lastName,
                    email: formValue.email,
                    enabled: formValue.enabled,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'user.update.success' }));

                setIsEditMode(false);
                props.reloadUsers();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'user.update.error' }));
            })
            .finally(() => {
                setIsEditing(false);
            });
    };

    const checkDisabledButton = () => {
        return formValue.firstName === '' || formValue.lastName === '' || formValue.email === '';
    };

    return (
        <Panel
            shaded
            bordered
            className="panel-small"
            data-cy="users-informations"
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'user.information' })}
                    buttons={[
                        <SecuredFragment
                            key="userInformations.edit"
                            authorizedRoles={[rolesConstants.user.UPDATE_INFORMATIONS]}>
                            <Button
                                data-cy="userInformations-editButton"
                                onClick={() => setIsEditMode(true)}
                                appearance="primary"
                                size="sm">
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        </SecuredFragment>,
                    ]}
                />
            }>
            <Form
                formValue={formValue}
                onChange={formValue => handleFormChange(formValue as FormValue)}
                fluid
                style={{ marginTop: 10 }}>
                <FlexboxGrid align="middle" justify="end">
                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <ControlLabel className="form-control-label">
                            <FormattedMessage id="user.edit.firstName" />
                        </ControlLabel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={16}>
                        <FormControl data-cy="edit-firstName" size="sm" name="firstName" plaintext={!isEditMode} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <ControlLabel className="form-control-label">
                            <FormattedMessage id="user.edit.lastName" />
                        </ControlLabel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={16}>
                        <FormControl data-cy="edit-lastName" size="sm" name="lastName" plaintext={!isEditMode} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <ControlLabel className="form-control-label">
                            <FormattedMessage id="user.edit.email" />
                        </ControlLabel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={16}>
                        <FormControl data-cy="edit-email" size="sm" name="email" type="email" plaintext={!isEditMode} />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <ControlLabel className="form-control-label">
                            <FormattedMessage id="user.edit.enabled" />
                        </ControlLabel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={16}>
                        <FormControl
                            accepter={Toggle}
                            data-cy="edit-enabled"
                            disabled={!isEditMode}
                            defaultChecked={props.user.getEnabled()}
                            name="enabled">
                            <FormattedMessage id="user.edit.authorizedToLogIn" />
                        </FormControl>
                    </FlexboxGrid.Item>
                    {isEditMode && (
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="userInformations-edit-cancel"
                                    color="red"
                                    onClick={handleCancel}
                                    disabled={isEditing}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    data-cy="userInformations-edit-valid"
                                    color="green"
                                    onClick={handleValid}
                                    disabled={checkDisabledButton()}
                                    loading={isEditing}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    )}
                </FlexboxGrid>
            </Form>
        </Panel>
    );
};
