import { faDesktop, faLock, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Button, Panel } from 'rsuite';
import TCMDisplay from '../../handlers/ipCanDevices/TCMDisplay';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import DisplayDrawer from './Drawer/Display/DisplayDrawer';
import DeleteDisplayModal from './Drawer/Display/Modal/DeleteDisplayModal';
import ForceDisplayModal from './Drawer/Display/Modal/ForceDisplayModal';
import Languages from './Elements/Language';
import arrowCell from './Table/arrowCell';
import displayForcedCell from './Table/displayForcedCell';
import displayValueCell from './Table/displayValueCell';
import loginStateCell from './Table/loginStateCell';
import { maintenanceStateInformation } from './Table/maintenanceStateInformation';
import textCell from './Table/textCell';
import typeCell from './Table/typeCell';

type Props = {
    isLoading: boolean;
    displays: Array<TCMDisplay>;
    reloadDevices: Function;
    ipCanId: number;
} & WrappedComponentProps;

type State = {
    displayDrawerOpen: boolean;
    selectedDisplay: TCMDisplay | undefined;
    displaysChecked: Array<number>;
    showDeleteModal: boolean;
    showForceModal: boolean;
    toggledClearRows: boolean;
    isLoading: boolean;
};
class DisplaysTable extends React.Component<Props, State> {
    columns: TableColumn<TCMDisplay>[];
    conditionalRowStyles: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            displayDrawerOpen: false,
            selectedDisplay: undefined,
            displaysChecked: [],
            showDeleteModal: false,
            showForceModal: false,
            toggledClearRows: false,
            isLoading: false,
        };

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.displays.online',
                }),
                selector: row => row.online,
                center: true,
                sortable: true,
                grow: 1,
                cell: row => loginStateCell(row.online, row.lastOnlineStateDateFormatted),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.LPSensors.maintenanceState',
                }),
                selector: row => row.getMaintenanceState(),
                center: true,
                sortable: true,
                grow: 2,
                cell: row => (
                    <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_MAINTENACE_STATE]}>
                        {maintenanceStateInformation(row.getMaintenanceState(), row.getNotepad())}
                    </SecuredFragment>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.displays.deviceId',
                }),
                grow: 1,
                sortable: true,
                center: true,
                selector: row => row.deviceId,
                cell: row => <div data-cy="display-deviceId">{row.deviceId}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.displays.arrow',
                }),
                center: true,
                grow: 1,
                cell: row => arrowCell(row.configuration.arrow),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.displays.languages',
                }),
                maxWidth: '200px',
                center: true,
                grow: 4,
                cell: row => <Languages data-tag="allowRowEvents" value={row.configuration.lang} />,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.displays.text',
                }),
                center: true,
                grow: 1,
                cell: row => textCell(row.configuration.text),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.displays.type',
                }),
                center: true,
                maxWidth: '200px',
                grow: 4,
                cell: row => typeCell(row.configuration.type),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.displays.displayValue',
                }),
                center: true,
                grow: 2,
                cell: row => displayValueCell(row.configuration.type, row.displayValue),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.displays.isForced',
                }),
                center: true,
                grow: 1,

                cell: row => displayForcedCell(row.isForce, row.endForceTimeFormatted, row.forceType),
            },
        ];

        this.conditionalRowStyles = [
            {
                when: row => row.isForce,
                style: {
                    backgroundColor: 'rgba(230, 81, 0, 0.15)',
                },
            },
        ];

        this.clickRow = this.clickRow.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.showForceModal = this.showForceModal.bind(this);
        this.hideForceModal = this.hideForceModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.displays !== prevProps.displays) {
            const displayIndex = this.props.displays.findIndex(
                display => display.id === this.state.selectedDisplay?.id
            );

            this.setState({
                selectedDisplay: this.props.displays[displayIndex],
            });
        }
    }

    clickRow(data) {
        const displayIndex = this.props.displays.findIndex(display => display.id === data.id);

        this.setState({
            selectedDisplay: this.props.displays[displayIndex],
            displayDrawerOpen: true,
        });
    }

    closeDrawer(shouldReload: boolean = false) {
        this.setState({
            displayDrawerOpen: false,
            selectedDisplay: undefined,
        });

        if (shouldReload) {
            this.props.reloadDevices();
        }
    }

    showDeleteModal(e) {
        e.stopPropagation();

        this.setState({
            showDeleteModal: true,
        });
    }

    hideDeleteModal(shouldReload) {
        this.setState({
            showDeleteModal: false,
            toggledClearRows: false,
        });

        if (shouldReload) {
            this.props.reloadDevices();
        }
    }

    showForceModal(e) {
        e.stopPropagation();
        this.setState({
            showForceModal: true,
        });
    }

    hideForceModal() {
        this.setState({
            showForceModal: false,
            toggledClearRows: false,
        });
    }

    selectedRowsChange(rows) {
        this.setState({
            displaysChecked: rows.selectedRows.map(row => row.id),
        });
    }

    shouldDisableDeleteButton = (): boolean => {
        if (this.state.displaysChecked.length === 0) return true;

        const selectedDisplays = this.props.displays.filter(display => this.state.displaysChecked.includes(display.id));

        return selectedDisplays.some(display => display.online);
    };

    render() {
        return (
            <Loader show={this.props.isLoading}>
                <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.DELETE]}>
                    <DeleteDisplayModal
                        show={this.state.showDeleteModal}
                        onHide={this.hideDeleteModal}
                        id={this.state.displaysChecked}
                    />
                </SecuredFragment>

                {this.state.selectedDisplay && (
                    <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.VIEW]}>
                        <DisplayDrawer
                            show={this.state.displayDrawerOpen}
                            display={this.state.selectedDisplay}
                            close={this.closeDrawer}
                            ipCanId={this.props.ipCanId}
                        />
                    </SecuredFragment>
                )}

                <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.FORCE_DISPLAY]}>
                    <ForceDisplayModal
                        show={this.state.showForceModal}
                        onHide={this.hideForceModal}
                        id={this.state.displaysChecked}
                    />
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.VIEW_LIST]}>
                    <Panel
                        className={'panel-big element-table dashboard-card-header rs-panel-collapsible-no-carret'}
                        shaded
                        data-cy="moduleIPCan-displays-table"
                        bordered
                        header={
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'ipCanDevices.displays' })}
                                tagValue={this.props.displays.length}
                                icon={faDesktop}
                                buttons={[
                                    <Button
                                        key="moduleIPCan-sensors-table-multiForce"
                                        color="orange"
                                        data-cy="moduleIPCan-displays-table-multiForce"
                                        onClick={this.showForceModal}
                                        placement="left"
                                        disabled={this.state.displaysChecked.length === 0}>
                                        <FontAwesomeIcon icon={faLock} className="margin-right-10" />
                                        <FormattedMessage id="ipCanDevices.displays.forceMode" />
                                    </Button>,
                                    <Button
                                        key="moduleIPCan-displays-table-multiDelete"
                                        color="red"
                                        data-cy="moduleIPCan-displays-table-multiDelete"
                                        onClick={this.showDeleteModal}
                                        disabled={this.shouldDisableDeleteButton()}>
                                        <FontAwesomeIcon icon={faTrashAlt} className="margin-right-10" />
                                        <FormattedMessage id="ipCanDevices.displays.deleteMode" />
                                    </Button>,
                                ]}
                            />
                        }
                        collapsible
                        defaultExpanded={this.props.displays.length > 0}
                        bodyFill>
                        <ElementTable
                            columns={this.columns}
                            data={this.props.displays}
                            progressPending={this.state.isLoading}
                            selectableRows
                            onSelectedRowsChange={rows => this.selectedRowsChange(rows)}
                            clearSelectedRows={this.state.toggledClearRows}
                            onRowClicked={row => this.clickRow(row)}
                            conditionalRowStyles={this.conditionalRowStyles}
                        />
                    </Panel>
                </SecuredFragment>
            </Loader>
        );
    }
}

export default injectIntl(DisplaysTable);
