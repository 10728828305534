import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { ChromePicker } from 'react-color';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, CheckPicker, ControlLabel, FlexboxGrid, Form, FormControl, FormGroup } from 'rsuite';
import { StepTaskData, StepTypeEnum } from '..';
import Task from '../../../../../handlers/Event/Task';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import { hexToRgb } from '../../../../../utils/colorUtils';

type CountersState = {
    label: string;
    value: number;
};

type Props = {
    onClickBack: () => void;
    currentTask: StepTaskData;
    closeModal: () => void;
    type: StepTypeEnum;
    existingTask?: Task;
};

type FormValue = {
    color: string;
    counters: number[];
};

export const LPSensorForceColorStepType = (props: Props) => {
    const intl = useIntl();

    const [counters, setCounters] = React.useState<CountersState[]>([]);

    const [formValue, setFormValue] = React.useState<FormValue>({
        counters: props.currentTask.counters,
        color: props.currentTask.color,
    });

    const [isCreating, setIsCreating] = React.useState(false);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/counters', {
                headers: authHeader(),
            })
            .then(countersResponse => {
                setCounters(
                    countersResponse.data.map(counter => {
                        return {
                            label: counter.label,
                            value: counter.id,
                        };
                    })
                );
            });
    }, []);

    const handleValid = () => {
        setIsCreating(true);

        const colorRGB = hexToRgb(formValue.color);

        if (props.type === StepTypeEnum.CREATE) {
            axiosService
                .getAxios()
                .post(
                    '/event-actions/tasks',
                    {
                        idEvent: props.currentTask.eventId,
                        name: props.currentTask.name,
                        desc: props.currentTask.description,
                        forceType: props.currentTask.type,
                        forceOption: {
                            color: {
                                red: colorRGB.red,
                                green: colorRGB.green,
                                blue: colorRGB.blue,
                                ledMask: 255,
                                exitLed: false,
                            },
                        },
                        tabTcmDisplayId: [],
                        tabLpMatrixDisplayV2Id: [],
                        tabCounterId: formValue.counters,
                        tabIpcanId: [],
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'event.createTask.success' }));

                    setIsCreating(false);

                    props.closeModal();
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(intl.formatMessage({ id: 'event.createTask.error' }));

                    setIsCreating(false);
                });
        } else {
            axiosService
                .getAxios()
                .put(
                    '/event-actions/tasks',
                    {
                        id: props.existingTask?.getId(),
                        name: props.currentTask.name,
                        desc: props.currentTask.description,
                        forceType: props.currentTask.type,
                        forceOption: {
                            color: {
                                red: colorRGB.red,
                                green: colorRGB.green,
                                blue: colorRGB.blue,
                                ledMask: 255,
                                exitLed: false,
                            },
                        },
                        tabTcmDisplayId: [],
                        tabLpMatrixDisplayV2Id: [],
                        tabCounterId: formValue.counters,
                        tabIpcanId: [],
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'event.editTask.success' }));

                    setIsCreating(false);

                    props.closeModal();
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(intl.formatMessage({ id: 'event.editTask.error' }));

                    setIsCreating(false);
                });
        }
    };

    return (
        <div>
            <Form formValue={formValue} onChange={value => setFormValue(value as FormValue)} fluid>
                <FormGroup>
                    <ControlLabel data-cy="event-createTask-counters-title">
                        {intl.formatMessage({ id: 'event.createTask.counters' })}
                    </ControlLabel>
                    <FormControl
                        data-cy="event-createTask-counters"
                        accepter={CheckPicker}
                        searchable={false}
                        cleanable={false}
                        data={counters}
                        name="counters"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel data-cy="event-createTask-color-title">
                        {intl.formatMessage({ id: 'event.createTask.color' })}
                    </ControlLabel>
                    <div data-cy="event-createTask-color">
                        <ChromePicker
                            color={formValue.color}
                            onChange={color => setFormValue({ ...formValue, color: color.hex })}
                        />
                    </div>
                </FormGroup>
            </Form>

            <FlexboxGrid align="middle" justify="end" className="margin-top-15">
                <FlexboxGrid.Item>
                    <Button
                        onClick={() => props.onClickBack()}
                        color="blue"
                        className="margin-right-5"
                        disabled={isCreating}>
                        <FontAwesomeIcon icon={faArrowLeft} className="margin-right-5" />
                        <FormattedMessage id="event.createTask.back" />
                    </Button>

                    <Button
                        data-cy="event-createTask-valid"
                        color="green"
                        onClick={() => handleValid()}
                        loading={isCreating}
                        disabled={formValue.counters.length === 0}>
                        <FontAwesomeIcon icon={faCheck} className="margin-right-5" />
                        <FormattedMessage id="event.createTask.valid" />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};
