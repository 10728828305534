type Props = {
    color: string;
};

export const StarAltIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <rect fill={color} x="224" width="64" height="64" />
        <rect fill={color} x="224" y="32" width="64" height="64" />
        <rect fill={color} x="192" y="64" width="128" height="64" />
        <rect fill={color} x="160" y="96" width="192" height="96" />
        <rect fill={color} x="192" y="64" width="128" height="32" />
        <rect fill={color} y="160" width="512" height="64" />
        <rect fill={color} x="32" y="480" width="96" height="32" />
        <rect fill={color} x="384" y="480" width="96" height="32" />
        <rect fill={color} x="384" y="448" width="96" height="64" />
        <rect fill={color} x="32" y="448" width="96" height="64" />
        <rect fill={color} x="32" y="224" width="448" height="32" />
        <rect fill={color} x="32" y="192" width="448" height="64" />
        <rect fill={color} x="64" y="256" width="384" height="32" />
        <rect fill={color} x="64" y="224" width="384" height="64" />
        <rect fill={color} x="64" y="384" width="384" height="32" />
        <rect fill={color} x="96" y="288" width="320" height="32" />
        <rect fill={color} x="96" y="256" width="320" height="64" />
        <rect fill={color} x="96" y="352" width="320" height="32" />
        <rect fill={color} x="96" y="352" width="320" height="64" />
        <rect fill={color} x="128" y="320" width="256" height="32" />
        <rect fill={color} x="32" y="416" width="160" height="32" />
        <rect fill={color} x="64" y="384" width="128" height="64" />
        <rect fill={color} x="320" y="416" width="160" height="32" />
        <rect fill={color} x="32" y="448" width="128" height="32" />
        <rect fill={color} x="32" y="416" width="128" height="64" />
        <rect fill={color} x="352" y="416" width="128" height="64" />
        <rect fill={color} x="352" y="448" width="128" height="32" />
        <rect fill={color} x="160" y="96" width="192" height="64" />
        <rect fill={color} x="320" y="384" width="128" height="64" />
        <rect fill={color} x="128" y="288" width="256" height="64" />
    </svg>
);
