import React, { Fragment } from 'react';
import Control from 'react-leaflet-control';
import { SelectPicker } from 'rsuite';
import { MapCameraSensor } from '../../../handlers/map/MapCameraSensor';
import { MapLPSensor } from '../../../handlers/map/MapLPSensor';
import { MapTCMSensor } from '../../../handlers/map/MapTCMSensor';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import ForceSensors from './ForceSensors';

type Props = {
    levels: Array<Record<string, any>>;
    levelId: number;
    handleRedirect: Function;
    tcmSensors: MapTCMSensor[];
    lpSensors: MapLPSensor[];
    placeCameraSensors: MapCameraSensor[];
    isEdit: boolean;
    reload: Function;
};

type State = {
    levels: Array<Record<string, any>>;
};
export default class TopRightControls extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        let levels = props.levels.map(level => {
            return {
                label: level.name,
                value: level.id,
                order: level.order,
                parking: level.parking.name,
            };
        });

        levels.sort((a, b) => {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
        });

        this.state = {
            levels,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.levels !== this.props.levels) {
            let levels = this.props.levels;
            levels = levels.map(level => {
                return {
                    label: level.name,
                    value: level.id,
                    order: level.order,
                    parking: level.parking.name,
                };
            });
            levels.sort((x, y) => {
                return x.order - y.order;
            });

            this.setState({
                levels: levels,
            });
        }
    }

    handleChange(value) {
        this.props.handleRedirect(value);
    }

    handleShowForceButton = (): boolean => {
        if (this.props.isEdit) {
            return false;
        }

        if (
            this.props.tcmSensors.length === 0 &&
            this.props.lpSensors.length === 0 &&
            this.props.placeCameraSensors.length === 0
        )
            return false;

        return true;
    };

    render() {
        return (
            <Fragment>
                <Control position="topright">
                    {this.state.levels.length > 0 && (
                        <SelectPicker
                            cleanable={false}
                            searchable={false}
                            block
                            data={this.state.levels}
                            value={this.props.levelId}
                            style={{ width: 200 }}
                            onChange={this.handleChange}
                            groupBy="parking"
                        />
                    )}
                    {this.handleShowForceButton() && (
                        <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.FORCE_SENSOR]}>
                            <ForceSensors
                                reload={this.props.reload}
                                tcmSensors={this.props.tcmSensors}
                                lpSensors={this.props.lpSensors}
                                placeCameraSensors={this.props.placeCameraSensors}
                            />
                        </SecuredFragment>
                    )}
                </Control>
            </Fragment>
        );
    }
}
