import { placeTypeConstants } from '../constants';
import { placeTypeService } from '../services';
import { notificationActions } from './notification.actions';

export const placeTypeActions = {
    getAll,
    // getOne,
    create,
    remove,
    update,
    updateImage,
    openViewDrawer,
    closeViewDrawer,
    openCreateModal,
    closeCreateModal,
    openDeleteModal,
    closeDeleteModal,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        placeTypeService.getAll().then(
            placeTypes => dispatch(success(placeTypes)),
            error => {
                dispatch(failure(error));
                dispatch(notificationActions.createNotification('error', 'placeType.error.fetchAllplaceTypesError'));
            }
        );
    };

    function request() {
        return { type: placeTypeConstants.GET_ALL_REQUEST };
    }
    function success(placeTypes) {
        return { type: placeTypeConstants.GET_ALL_SUCCESS, placeTypes };
    }
    function failure(error) {
        return { type: placeTypeConstants.GET_ALL_FAILURE, error };
    }
}

// function getOne(id) {
//     return dispatch => {
//         dispatch(request());

//         placeTypeService.getById(id).then(
//             placeType => dispatch(success(placeType)),
//             error => {
//                 dispatch(failure(error));
//                 dispatch(
//                     notificationActions.createNotification(
//                         'error',
//                         'placeType.error.fetchplaceTypeError'
//                     )
//                 );
//             }
//         );
//     };

//     function request() {
//         return { type: placeTypeConstants.GETONE_REQUEST };
//     }
//     function success(placeType) {
//         return { type: placeTypeConstants.GETONE_SUCCESS, placeType };
//     }
//     function failure(error) {
//         return { type: placeTypeConstants.GETONE_FAILURE, error };
//     }
// }

function remove(id) {
    return dispatch => {
        dispatch(request(id));

        placeTypeService.remove(id).then(
            data => {
                dispatch(success(data));
                dispatch(notificationActions.createNotification('success', 'placeType.success.removeplaceTypeSuccess'));
            },
            error => {
                dispatch(failure(id, error));
                dispatch(notificationActions.createNotification('error', 'placeType.error.removeplaceTypeError'));
            }
        );
    };

    function request(id) {
        return { type: placeTypeConstants.DELETE_REQUEST, id };
    }
    function success(placeType) {
        return { type: placeTypeConstants.DELETE_SUCCESS, placeType };
    }
    function failure(id, error) {
        return { type: placeTypeConstants.DELETE_FAILURE, id, error };
    }
}

function create(label) {
    const crypto = window.crypto;
    var array = new Uint32Array(1);

    return dispatch => {
        let id = crypto.getRandomValues(array);
        let newplaceType = {
            id: id,
            label,
        };
        dispatch(request(newplaceType));

        placeTypeService.create(label).then(
            placeType => {
                dispatch(success(placeType, id));
                dispatch(notificationActions.createNotification('success', 'placeType.success.createplaceTypeSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'placeType.error.createplaceTypeError'));
            }
        );
    };

    function request(placeType) {
        return { type: placeTypeConstants.CREATE_REQUEST, placeType };
    }
    function success(placeType, id) {
        return { type: placeTypeConstants.CREATE_SUCCESS, placeType, id: id };
    }
    function failure(error, id) {
        return { type: placeTypeConstants.CREATE_FAILURE, error, id: id };
    }
}

function update(id, label) {
    return dispatch => {
        dispatch(request(id));

        placeTypeService.update(id, label).then(
            placeType => {
                dispatch(success(placeType));
                dispatch(notificationActions.createNotification('success', 'placeType.success.updateplaceTypeSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'placeType.error.updateplaceTypeError'));
            }
        );
    };

    function request(id) {
        return { type: placeTypeConstants.UPDATE_REQUEST, id };
    }
    function success(placeType) {
        return { type: placeTypeConstants.UPDATE_SUCCESS, placeType };
    }
    function failure(error, id) {
        return { type: placeTypeConstants.UPDATE_FAILURE, error, id };
    }
}

function updateImage(id, image) {
    return dispatch => {
        dispatch(request(id));

        placeTypeService.updateImage(id, image).then(
            placeType => {
                dispatch(success(placeType));
                dispatch(notificationActions.createNotification('success', 'placeType.success.updateplaceTypeSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'placeType.error.updateplaceTypeError'));
            }
        );
    };

    function request(id) {
        return { type: placeTypeConstants.UPDATE_REQUEST, id };
    }
    function success(placeType) {
        return { type: placeTypeConstants.UPDATE_SUCCESS, placeType };
    }
    function failure(error, id) {
        return { type: placeTypeConstants.UPDATE_FAILURE, error, id };
    }
}

function openViewDrawer(id) {
    return dispatch => {
        dispatch(request());

        placeTypeService.getById(id).then(
            placeType => {
                dispatch(success(placeType));
            },
            error => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: placeTypeConstants.OPEN_VIEW_DRAWER_REQUEST,
        };
    }

    function success(placeType) {
        return {
            type: placeTypeConstants.OPEN_VIEW_DRAWER_SUCCESS,
            placeType: placeType,
        };
    }

    function failure(error) {
        return {
            type: placeTypeConstants.OPEN_VIEW_DRAWER_FAILURE,
            error,
        };
    }
}

function closeViewDrawer() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: placeTypeConstants.CLOSE_VIEW_DRAWER,
        };
    }
}

function openCreateModal() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: placeTypeConstants.OPEN_CREATE_MODAL,
        };
    }
}

function closeCreateModal() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: placeTypeConstants.CLOSE_CREATE_MODAL,
        };
    }
}

function openDeleteModal(id) {
    return dispatch => {
        dispatch(request());

        placeTypeService.getById(id).then(
            placeType => {
                dispatch(success(placeType));
            },
            error => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: placeTypeConstants.OPEN_DELETE_MODAL_REQUEST,
            id,
        };
    }

    function success(placeType) {
        return {
            type: placeTypeConstants.OPEN_DELETE_MODAL_SUCCESS,
            placeType,
        };
    }

    function failure(error) {
        return {
            type: placeTypeConstants.OPEN_DELETE_MODAL_FAILURE,
            error,
        };
    }
}

function closeDeleteModal() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: placeTypeConstants.CLOSE_DELETE_MODAL,
        };
    }
}
