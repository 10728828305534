export class Level {
    private _id: number;
    private _order: number;
    private _name: string;
    private _description: string;
    private _createdAt: Date;
    private _updatedAt: Date;
    private _createdBy: string;
    private _updatedBy: string;
    private _imageType: string;
    private _image?: string;
    private _defaultZoom: number;
    private _positionZoom: number[];
    private _calibrationCm: number;
    private _calibrationPlace: number;
    private _isUpToDate: boolean;

    constructor(level: LevelInterface) {
        this._id = level.id;
        this._order = level.order;
        this._name = level.name;
        this._description = level.description;
        this._createdAt = level.createdAt;
        this._updatedAt = level.updatedAt;
        this._createdBy = level.createdBy;
        this._updatedBy = level.updatedBy;
        this._imageType = level.imageType;
        this._image = level.image;
        this._defaultZoom = level.defaultZoom;
        this._positionZoom = level.positionZoom;
        this._calibrationCm = level.calibrationCm;
        this._calibrationPlace = level.calibrationPlace;
        this._isUpToDate = level.isUpToDate;
    }

    public getId(): number {
        return this._id;
    }

    public getOrder(): number {
        return this._order;
    }

    public getName(): string {
        return this._name;
    }

    public getDescription(): string {
        return this._description;
    }

    public getCreatedAt(): Date {
        return this._createdAt;
    }

    public getUpdatedAt(): Date {
        return this._updatedAt;
    }

    public getCreatedBy(): string {
        return this._createdBy;
    }

    public getUpdatedBy(): string {
        return this._updatedBy;
    }

    public getImageType(): string {
        return this._imageType;
    }

    public getImage(): string | undefined {
        return this._image;
    }

    public getDefaultZoom(): number {
        return this._defaultZoom;
    }

    public getPositionZoom(): number[] {
        return this._positionZoom;
    }

    public getCalibrationCm(): number {
        return this._calibrationCm;
    }

    public getCalibrationPlace(): number {
        return this._calibrationPlace;
    }

    public getIsUpToDate(): boolean {
        return this._isUpToDate;
    }
}

export interface LevelInterface {
    id: number;
    order: number;
    name: string;
    description: string;
    createdAt: Date;
    updatedAt: Date;
    createdBy: string;
    updatedBy: string;
    imageType: string;
    image?: string;
    defaultZoom: number;
    positionZoom: number[];
    calibrationCm: number;
    calibrationPlace: number;
    isUpToDate: boolean;
}
