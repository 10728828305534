import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, Col, FlexboxGrid, Form, FormControl, List, SelectPicker } from 'rsuite';
import Camera from '../../../../../../handlers/Camera/Camera';
import { CameraSensorMapInformation_I } from '../../../../../../handlers/map/MapCameraSensor';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';

type Props = {
    mapCameraSensor: CameraSensorMapInformation_I;
    setViewMode: () => void;
    currentPlaceType: any;
    cameras: Camera[];
    placeTypes: any[];
    service: any;
};

type FormValue = {
    label: string;
    id_camera: number;
    id_sensorPlaceType: number;
    idPlace: number;
};

export const MapPlaceCameraEditInformation = (props: Props) => {
    const intl = useIntl();

    const [isUpdating, setIsUpdating] = React.useState(false);

    const [formValue, setFormValue] = React.useState<FormValue>({
        label: props.mapCameraSensor.label,
        id_camera: props.mapCameraSensor.idCamera,
        id_sensorPlaceType: props.currentPlaceType.id,
        idPlace: props.mapCameraSensor.placeCamera.getId(),
    });

    const placesCamera = props.cameras
        .find(camera => camera.getId() === formValue.id_camera)
        ?.getPlacesCamera()
        .map(placeCamera => {
            return {
                label: placeCamera.getLabel(),
                value: placeCamera.getId(),
            };
        });

    const handleClickCancel = () => {
        setFormValue({
            label: props.mapCameraSensor.label,
            id_camera: props.mapCameraSensor.idCamera,
            id_sensorPlaceType: props.currentPlaceType.id,
            idPlace: props.mapCameraSensor.placeCamera.getId(),
        });

        props.setViewMode();
    };

    const handleValidSubmit = () => {
        setIsUpdating(true);

        const { label, id_camera, id_sensorPlaceType, idPlace } = formValue;

        axiosService
            .getAxios()
            .put(
                '/map-place-camera/updatePlaceCamera',
                {
                    id: props.mapCameraSensor.id,
                    label,
                    id_camera,
                    id_sensorPlaceType,
                    idPlace,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(responseData => {
                Alert.success(intl.formatMessage({ id: 'map.cameraPlace.edit.success' }));

                props.service.send('PLACECAMERA_EDIT_CONFIGURATION', {
                    sensor: responseData.data,
                });

                setIsUpdating(false);

                props.setViewMode();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'map.cameraPlace.edit.error' }));

                setIsUpdating(false);
            });
    };

    const shouldEnableValidButton = () => {
        return formValue.id_camera && formValue.id_sensorPlaceType && formValue.idPlace;
    };

    const onFormChange = (value: FormValue) => {
        if (value.id_camera !== formValue.id_camera) {
            value.idPlace = 0;
        }

        setFormValue(value);
    };

    return (
        <List>
            <Form formValue={formValue} onChange={value => onFormChange(value as FormValue)} fluid>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.cameraPlace.mapInformation.label" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl name="label" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* SENSOR PLACE TYPE */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.cameraPlace.mapInformation.sensorPlaceType" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                name="id_sensorPlaceType"
                                accepter={SelectPicker}
                                cleanable={false}
                                searchable={false}
                                data={props.placeTypes.map(pt => {
                                    return {
                                        label: pt.label,
                                        value: pt.id,
                                    };
                                })}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* CAMERA */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.cameraPlace.mapInformation.camera" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                name="id_camera"
                                accepter={SelectPicker}
                                cleanable={false}
                                data={props.cameras.map(camera => {
                                    return {
                                        label: camera.getName(),
                                        value: camera.getId(),
                                    };
                                })}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* SENSOR PLACE TYPE */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                            <FormattedMessage id="map.cameraPlace.mapInformation.place" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                name="idPlace"
                                accepter={SelectPicker}
                                cleanable={false}
                                searchable={false}
                                data={placesCamera ? placesCamera : []}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* SENSOR PLACE TYPE */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="end" align="middle">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button color="red" onClick={handleClickCancel} disabled={isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    color="green"
                                    onClick={handleValidSubmit}
                                    loading={isUpdating}
                                    disabled={!shouldEnableValidButton()}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </Form>
        </List>
    );
};
