import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, Col, Drawer, FlexboxGrid } from 'rsuite';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import { LPSensorMapInformation_I } from '../../../../../handlers/map/MapLPSensor';
import { SensorPlaceType } from '../../../../../handlers/sensorPlaceType/SensorPlaceType';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../../redux/services';
import { DeviceStateInformation } from '../../../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../../../IpCanElementsPage/Cards/DeviceStateInformation/deviceStateInformationGenerator';
import { DebugValue, GraphDebugValue } from '../../../../IpCanElementsPage/Drawer/LPSensor';
import { CalibrationCard } from '../../../../IpCanElementsPage/Drawer/LPSensor/CalibrationCard';
import LPFilter from '../../../../IpCanElementsPage/Drawer/LPSensor/LPFilter';
import realTimeGraph from '../../../../IpCanElementsPage/Drawer/LPSensor/RealTimeGraph';
import RealTimeTable from '../../../../IpCanElementsPage/Drawer/LPSensor/RealTimeTable';
import LPSensorInformationPart from './LPSensorInformationPart';

type Props = {
    show: boolean;
    onHide: Function;
    sensor: LPSensorMapInformation_I;
    controllers: IpCan[];
    placeTypes: SensorPlaceType[];
    service: any;
} & WrappedComponentProps;

type State = {
    debugArray: DebugValue[];
    graphDebugArray: GraphDebugValue[];
    showRealTimeGraph: boolean;
    maxPoints: number;
    realTimeChange: boolean;
    compareMode: boolean;
    compareModeLoading: boolean;
    comparedConfig: {};
};

class LPSensorPlaceDrawer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            debugArray: [],
            graphDebugArray: [],
            showRealTimeGraph: false,
            maxPoints: 30,
            realTimeChange: false,
            compareMode: false,
            compareModeLoading: false,
            comparedConfig: {},
        };
    }

    hideDrawer = () => {
        this.props.onHide();
    };

    setRealTimeChange = toggled => {
        this.setState({
            showRealTimeGraph: toggled,
        });

        if (!toggled) {
            this.disableWebsocket();
        } else {
            this.enableWebsocket();
        }
    };

    resetArray = () => {
        this.setState({
            debugArray: [],
            graphDebugArray: [],
        });
    };

    setMaxPoints = points => {
        this.setState({
            maxPoints: parseInt(points),
        });
    };

    enableWebsocket = () => {
        this.setState({
            realTimeChange: true,
        });

        axiosService.getAxios().put(
            '/devices/lp-sensor/debugMode',
            {
                id: this.props.sensor?.id,
                activDebug: true,
            },
            {
                headers: authHeader(),
            }
        );

        webSocketService.onEvent('lp-sensor:debugValues', this.addDebugValue);
    };

    disableWebsocket = () => {
        this.setState({
            realTimeChange: false,
        });

        axiosService.getAxios().put(
            '/devices/lp-sensor/debugMode',
            {
                id: this.props.sensor?.id,
                activDebug: false,
            },
            {
                headers: authHeader(),
            }
        );

        webSocketService.offEvent('lp-sensor:debugValues', this.addDebugValue);
    };

    toggleWebsocket = () => {
        const websocketActivated = !this.state.realTimeChange;

        this.setState({
            realTimeChange: !this.state.realTimeChange,
        });

        axiosService.getAxios().put(
            '/devices/lp-sensor/debugMode',
            {
                id: this.props.sensor?.id,
                activDebug: websocketActivated,
            },
            {
                headers: authHeader(),
            }
        );

        if (websocketActivated) {
            webSocketService.onEvent('lp-sensor:debugValues', this.addDebugValue);
        } else {
            webSocketService.offEvent('lp-sensor:debugValues', this.addDebugValue);
        }
    };

    toggleCompareMode = () => {
        if (this.state.compareMode) {
            this.setState({
                compareMode: false,
            });
        } else {
            this.setState({
                compareModeLoading: true,
            });
            axiosService
                .getAxios()
                .get(`/devices/lp-sensor/config/${this.props.sensor?.id}`, { headers: authHeader() })
                .then(lpSensorConfigResponse => {
                    this.setState({
                        compareMode: true,
                        compareModeLoading: false,
                        comparedConfig: lpSensorConfigResponse.data,
                    });
                })
                .catch(err => {
                    Alert.error(
                        this.props.intl.formatMessage(
                            {
                                id: 'ipCanDevices.LPSensor.fetchConfigError',
                            },
                            {
                                error: err,
                            }
                        )
                    );

                    this.setState({
                        compareModeLoading: false,
                    });
                });
        }
    };

    addDebugValue = data => {
        if (data.bus === this.props.sensor?.bus && data.deviceId === this.props.sensor?.deviceId) {
            const debugArray = [...this.state.debugArray].slice(0, this.state.maxPoints - 1);
            let graphDebugArray = [...this.state.graphDebugArray];

            if (this.state.graphDebugArray.length >= this.state.maxPoints) {
                graphDebugArray = [...this.state.graphDebugArray].slice(1, this.state.maxPoints);
            }

            let sDetection = 512;

            if (this.props.sensor!.us1Config.sens < 10) {
                sDetection = 128;
            } else if (this.props.sensor!.us1Config.sens < 80) {
                sDetection = 192;
            } else if (this.props.sensor!.us1Config.sens < 160) {
                sDetection = 256;
            } else if (this.props.sensor!.us1Config.sens < 250) {
                sDetection = 384;
            }

            let sRelease = 358;

            if (this.props.sensor!.us2Config.sens < 10) {
                sRelease = 89;
            } else if (this.props.sensor!.us2Config.sens < 80) {
                sRelease = 134;
            } else if (this.props.sensor!.us2Config.sens < 160) {
                sRelease = 179;
            } else if (this.props.sensor!.us2Config.sens < 250) {
                sRelease = 269;
            }

            debugArray.unshift({
                c1_distance1: data.debugValues.us1.distance1,
                c1_power1: data.debugValues.us1.power1,
                c1_distance2: data.debugValues.us1.distance2,
                c1_power2: data.debugValues.us1.power2,
                c2_distance1: data.debugValues.us2.distance1,
                c2_power1: data.debugValues.us2.power1,
                c2_distance2: data.debugValues.us2.distance2,
                c2_power2: data.debugValues.us2.power2,
            });

            graphDebugArray.push({
                c1_distance1: data.debugValues.us1.distance1,
                c1_power1: data.debugValues.us1.power1,
                c1_distance2: data.debugValues.us1.distance2,
                c1_power2: data.debugValues.us1.power2,
                c2_distance1: data.debugValues.us2.distance1,
                c2_power1: data.debugValues.us2.power1,
                c2_distance2: data.debugValues.us2.distance2,
                c2_power2: data.debugValues.us2.power2,
                value: data.debugValues.valueFiltered,
                date: new Date(),
                sDetection: sDetection,
                sRelease: sRelease,
            });

            this.setState({
                debugArray,
                graphDebugArray,
            });
        }
    };

    render = () => {
        const currentIpCan = this.props.controllers.find(controller => controller.id === this.props.sensor.ipCanId);

        return (
            <Drawer
                backdrop={false}
                show={this.props.show}
                onHide={this.hideDrawer}
                full={this.state.showRealTimeGraph}>
                <Drawer.Header>
                    <Drawer.Title>
                        {/* {currentIpCan?.label} - {this.props.sensor.bus + 1} - {this.props.sensor.deviceId} */}
                        <span>
                            <FormattedMessage
                                id="ipCanDevices.LPSensor.title"
                                values={{
                                    bus: this.props.sensor.bus + 1,
                                    address: this.props.sensor.deviceId,
                                }}
                            />
                            {this.props.sensor.label && <span>{` (${this.props.sensor.label})`}</span>}
                            {this.state.showRealTimeGraph && (
                                <span>{` - ${currentIpCan?.label} / ${this.props.sensor.lpSensor.getBus() + 1} / ${this.props.sensor.lpSensor.getDeviceId()}`}</span>
                            )}
                            {this.state.showRealTimeGraph && (
                                <span style={{ fontWeight: 'normal' }}>{` - (${this.props.sensor.lpSensor.informations.uniqueId.join('.')})`}</span>
                            )}
                        </span>
                    </Drawer.Title>
                </Drawer.Header>

                <Drawer.Body className="drawer-body-without-avatar">
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} xs={this.state.showRealTimeGraph ? 6 : 24}>
                            <LPSensorInformationPart
                                sensor={this.props.sensor}
                                controllers={this.props.controllers}
                                placeTypes={this.props.placeTypes}
                                service={this.props.service}
                                currentIpCan={currentIpCan}
                                showRealTimeGraph={this.state.showRealTimeGraph}
                            />

                            {!this.state.showRealTimeGraph && this.props.sensor && this.state.comparedConfig && (
                                <div data-cy="ipCanDevices-lpSensor-card-calibration">
                                    <CalibrationCard
                                        sensor={this.props.sensor.lpSensor}
                                        realTimeChange={this.setRealTimeChange}
                                        isRealTime={this.state.showRealTimeGraph}
                                        comparedData={this.state.comparedConfig}
                                        compareMode={this.state.compareMode}
                                    />
                                </div>
                            )}

                            <div style={{ marginBottom: 12 }}>
                                {this.state.showRealTimeGraph && RealTimeTable(this.state.debugArray)}
                            </div>
                            <DeviceStateInformation
                                device={this.props.sensor.lpSensor}
                                editMaintenanceStateUrl={MaintenanceStateInformationEditURL.LP_SENSOR}
                            />

                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={this.state.showRealTimeGraph ? 18 : 24}>
                            <FlexboxGrid>
                                {this.state.showRealTimeGraph && (
                                    <FlexboxGridItem componentClass={Col} xs={6}>
                                        <div data-cy="ipCanDevices-lpSensor-card-calibration">
                                            <CalibrationCard
                                                sensor={this.props.sensor.lpSensor}
                                                realTimeChange={this.setRealTimeChange}
                                                isRealTime={this.state.showRealTimeGraph}
                                                comparedData={this.state.comparedConfig}
                                                compareMode={this.state.compareMode}
                                            />
                                        </div>
                                    </FlexboxGridItem>
                                )}

                                <FlexboxGridItem componentClass={Col} xs={this.state.showRealTimeGraph ? 18 : 24}>
                                    <LPFilter
                                        sensor={this.props.sensor.lpSensor}
                                        isRealTime={this.state.showRealTimeGraph}
                                        realTimeChange={this.setRealTimeChange}
                                        compareMode={this.state.compareMode}
                                        comparedData={this.state.comparedConfig}
                                    />
                                </FlexboxGridItem>
                            </FlexboxGrid>

                            {this.state.showRealTimeGraph &&
                                realTimeGraph(
                                    this.state.realTimeChange,
                                    this.state.graphDebugArray,
                                    this.toggleWebsocket,
                                    this.resetArray,
                                    this.setMaxPoints
                                )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Drawer.Body>
                <Drawer.Footer>
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item componentClass={Col} xs={3}>
                            <Button
                                block
                                data-cy="ipCanDevices-lpSensor-compareMode"
                                onClick={() => {
                                    this.toggleCompareMode();
                                }}
                                color={'orange'}
                                loading={this.state.compareModeLoading}
                                disabled={!this.props.sensor.online}>
                                <FontAwesomeIcon icon={faCheckDouble} />
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Drawer.Footer>
            </Drawer>
        );
    };
}

export default injectIntl(LPSensorPlaceDrawer);
