import { FormattedMessage } from 'react-intl';
import Task from '../../../../../handlers/Event/Task';
import { TCMDisplaysPart } from './Parts/TCMDisplaysPart';

type Props = {
    task: Task;
};

export const TCMDisplayUnforce = (props: Props) => {
    return (
        <>
            <FormattedMessage id="tasks.disableForceTCMDisplay" />
            <TCMDisplaysPart tcmDisplay={props.task.getTcmDisplay()} />
        </>
    );
};
