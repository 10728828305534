import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Col, FlexboxGrid } from 'rsuite';
import { EventHistoricTable } from './EventHistoricTable';
import IPCameraHistory from './IPCameraHistory';
import IpCanModuleHistory from './ipCanModuleHistory';
import LPMatrixDisplayV2History from './LPMatrixDisplayV2History';
import LPSensorHistory from './LPSensorHistory';
import PlaceCameraHistory from './placeCameraHistory';
import TCMDisplayHistory from './tcmDisplayHistory';
import TCMSensorHistory from './tcmSensorHistory';

type Props = {
    selectedTab: string;
};

type State = {
    selectedTab: string;
};
export default class History extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 'ipcan',
        };

        this.handleChangeTab = this.handleChangeTab.bind(this);
    }

    handleChangeTab(currentTab) {
        this.setState({
            selectedTab: currentTab,
        });
    }

    render() {
        return (
            <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} xs={24}>
                    <ButtonGroup size="lg" justified>
                        <Button
                            data-cy="historic-ipCanModule-button"
                            appearance={this.state.selectedTab === 'ipcan' ? 'primary' : 'ghost'}
                            onClick={() => this.handleChangeTab('ipcan')}>
                            <FormattedMessage id="history.ipCanModule" />
                        </Button>
                        <Button
                            data-cy="historic-tcmDisplay-button"
                            appearance={this.state.selectedTab === 'tcmDisplay' ? 'primary' : 'ghost'}
                            onClick={() => this.handleChangeTab('tcmDisplay')}>
                            <FormattedMessage id="history.TCMDisplay" />
                        </Button>
                        <Button
                            data-cy="historic-tcmSensor-button"
                            appearance={this.state.selectedTab === 'tcmSensor' ? 'primary' : 'ghost'}
                            onClick={() => this.handleChangeTab('tcmSensor')}>
                            <FormattedMessage id="history.TCMSensor" />
                        </Button>
                        <Button
                            data-cy="historic-lpSensor-button"
                            appearance={this.state.selectedTab === 'lpSensor' ? 'primary' : 'ghost'}
                            onClick={() => this.handleChangeTab('lpSensor')}>
                            <FormattedMessage id="history.LPSensor" />
                        </Button>
                        <Button
                            data-cy="historic-LPMatrixDisplayV2-button"
                            appearance={this.state.selectedTab === 'LPMatrixDisplayV2' ? 'primary' : 'ghost'}
                            onClick={() => this.handleChangeTab('LPMatrixDisplayV2')}>
                            <FormattedMessage id="history.LPMatrixDisplayV2" />
                        </Button>
                        <Button
                            data-cy="historic-ipCamera-button"
                            appearance={this.state.selectedTab === 'ipCamera' ? 'primary' : 'ghost'}
                            onClick={() => this.handleChangeTab('ipCamera')}>
                            <FormattedMessage id="history.ipCamera" />
                        </Button>
                        <Button
                            data-cy="historic-placeCamera-button"
                            appearance={this.state.selectedTab === 'placeCamera' ? 'primary' : 'ghost'}
                            onClick={() => this.handleChangeTab('placeCamera')}>
                            <FormattedMessage id="history.placeCamera" />
                        </Button>
                        <Button
                            data-cy="historic-event-button"
                            appearance={this.state.selectedTab === 'event' ? 'primary' : 'ghost'}
                            onClick={() => this.handleChangeTab('event')}>
                            <FormattedMessage id="history.event" />
                        </Button>
                    </ButtonGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={24}>
                    {this.state.selectedTab === 'ipcan' && <IpCanModuleHistory />}
                    {this.state.selectedTab === 'tcmSensor' && <TCMSensorHistory />}
                    {this.state.selectedTab === 'tcmDisplay' && <TCMDisplayHistory />}
                    {this.state.selectedTab === 'lpSensor' && <LPSensorHistory />}
                    {this.state.selectedTab === 'LPMatrixDisplayV2' && <LPMatrixDisplayV2History />}
                    {this.state.selectedTab === 'ipCamera' && <IPCameraHistory />}
                    {this.state.selectedTab === 'placeCamera' && <PlaceCameraHistory />}
                    {this.state.selectedTab === 'event' && <EventHistoricTable />}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        );
    }
}
