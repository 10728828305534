import { faArrowAltCircleUp, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import {
    Button,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    List,
    Panel,
    SelectPicker,
    Tag,
    TagPicker
} from 'rsuite';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';
import Arrows from '../../Elements/Arrow';
import Languages from '../../Elements/Language';
import Text from '../../Elements/Text';
import Types from '../../Elements/Types';
import jsonToArray from './jsonToArrayHandler';

const languages = { 1: 'PL', 2: 'ES', 8: 'FR', 16: 'EN', 32: 'PT', 64: 'NL', 128: 'DE' };

const text = [
    {
        label: 'Full',
        value: 0,
    },
    {
        label: 'X',
        value: 1,
    },
    {
        label: 'NoPsign',
        value: 2,
    },
    {
        label: 'No',
        value: 3,
    },
];

const types = { 1: 'Generic', 2: 'PMR', 4: 'Electric', 8: 'Family' };

type Props = {
    display: Record<string, any>;
    updateDisplayConfig: Function;
    loading: boolean;
    intl: Record<string, any>;
    isCheckingDatabase: boolean;
    databaseData: Record<string, any> | undefined;
} & WrappedComponentProps;

type State = {
    loading: boolean;
    editMode: boolean;
    formValue: Record<string, any>;
    arrow: Record<string, any> | null;
    lang: Array<Record<string, any>>;
    text: Record<string, any> | null;
    type: Array<Record<string, any>>;
    arrowsForm: Array<Record<string, any>>;
};
class ConfigurationCard extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            editMode: false,
            formValue: {
                arrow: null,
                lang: [],
                text: null,
                type: [],
            },
            arrow: null,
            lang: [],
            text: null,
            type: [],
            arrowsForm: [
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.arrows.noArrow',
                    }),
                    value: 0,
                    rotation: 0,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.arrows.left',
                    }),
                    value: 1,
                    rotation: 270,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.arrows.right',
                    }),
                    value: 2,
                    rotation: 90,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.arrows.up',
                    }),
                    value: 3,
                    rotation: 0,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.arrows.down',
                    }),
                    value: 4,
                    rotation: 180,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.arrows.upLeft',
                    }),
                    value: 5,
                    rotation: 315,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.arrows.upRight',
                    }),
                    value: 6,
                    rotation: 45,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.arrows.downLeft',
                    }),
                    value: 7,
                    rotation: 225,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'display.arrows.downRight',
                    }),
                    value: 8,
                    rotation: 135,
                },
            ],
        };

        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.cancel = this.cancel.bind(this);
        this.valid = this.valid.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setFormValue = this.setFormValue.bind(this);
    }

    componentDidMount() {
        this.setFormValue();
    }

    setFormValue() {
        const { arrow, lang, text, type } = this.props.display;

        let langs: Array<number> = [];
        for (let i = 1; i <= 128; i <<= 1) {
            if ((lang & i) !== 0) {
                langs.push(i);
            }
        }

        let typesArray: Array<number> = [];
        for (let i = 1; i <= 8; i <<= 1) {
            if ((type & i) !== 0) {
                typesArray.push(i);
            }
        }

        this.setState({
            editMode: false,
            formValue: {
                arrow,
                lang: langs,
                text,
                type: typesArray,
            },
            arrow: arrow,
            lang: lang,
            text: text,
            type: type,
        });
    }

    toggleEditMode() {
        this.setState({
            editMode: !this.state.editMode,
        });
    }

    cancel() {
        this.setFormValue();

        this.setState({
            editMode: false,
        });
    }

    valid() {
        this.setState({
            editMode: false,
        });

        let langValue = 0;
        let typeValue = 0;

        const { arrow, lang, text, type } = this.state.formValue;

        for (let l in lang) {
            langValue += lang[l];
        }

        for (let t in type) {
            typeValue += type[t];
        }

        this.props.updateDisplayConfig(parseInt(arrow), langValue, parseInt(text), typeValue);
    }

    handleChange(formValue) {
        this.setState({ formValue: formValue });
    }

    componentDidUpdate(prevProps) {
        if (this.props.display !== prevProps.display) {
            this.setFormValue();

            this.setState({
                arrow: this.props.display.arrow,
                lang: this.props.display.lang,
                text: this.props.display.text,
                type: this.props.display.type,
            });
        }
    }

    render() {
        let arrowClass = '';
        let langClass = '';
        let textClass = '';
        let typeClass = '';

        if (this.props.isCheckingDatabase) {
            arrowClass = this.props.databaseData?.arrow === this.props.display.arrow ? 'list-green' : 'list-red';
            langClass = this.props.databaseData?.lang === this.props.display.lang ? 'list-green' : 'list-red';
            textClass = this.props.databaseData?.text === this.props.display.text ? 'list-green' : 'list-red';
            typeClass = this.props.databaseData?.type === this.props.display.type ? 'list-green' : 'list-red';
        }

        return (
            <Fragment>
                <Loader show={this.props.loading || this.state.loading}>
                    <Panel
                        className={'panel-small'}
                        shaded
                        data-cy="ipcan-elements-display-drawer-configuration"
                        bordered
                        header={
                            <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.UPDATE_CONFIG]}>
                                <PanelHeader
                                    title={this.props.intl.formatMessage({ id: 'ipCanDevices.display.configuration' })}
                                    buttons={
                                        this.props.isCheckingDatabase
                                            ? []
                                            : [
                                                    <Button
                                                        key="displayConfiguration-edit"
                                                        data-cy="displayConfiguration-edit"
                                                        color="blue"
                                                        size="sm"
                                                        disabled={this.state.editMode}
                                                        onClick={this.toggleEditMode}>
                                                        <FontAwesomeIcon icon={faEdit} className="margin-right-10" />
                                                        <FormattedMessage id="ipCanDevices.display.configuration.edit" />
                                                    </Button>,
                                                ]
                                    }
                                />
                            </SecuredFragment>
                        }
                        bodyFill>
                        {this.state.editMode ? (
                            <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.UPDATE_CONFIG]}>
                                <Form formValue={this.state.formValue} onChange={this.handleChange} fluid>
                                    <List hover data-cy="ipcan-elements-display-drawer-configuration">
                                        <List.Item className="panel-list">
                                            <FlexboxGrid align="middle" justify="space-between">
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                    xs={12}
                                                    style={{ fontWeight: 'bold' }}>
                                                    <FormattedMessage id="ipCanDevices.display.configuration.arrow" />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                    <FormGroup>
                                                        <FormControl
                                                            data-cy="ipCanDevices-display-arrow"
                                                            name="arrow"
                                                            accepter={SelectPicker}
                                                            data={this.state.arrowsForm}
                                                            cleanable={false}
                                                            searchable={false}
                                                            placement="top"
                                                            renderMenuItem={(label, item) => {
                                                                const style = {
                                                                    transform: `rotate(${item.rotation}deg)`,
                                                                };

                                                                if (item.value === 0) {
                                                                    return (
                                                                        <div data-cy="ipCanDevices-display-none">
                                                                            <FontAwesomeIcon
                                                                                className="margin-right-15"
                                                                                icon={faTimesCircle}
                                                                            />
                                                                            {label}
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <div
                                                                            data-cy={`configuration-display-value-${item.value}`}>
                                                                            <FontAwesomeIcon
                                                                                className="margin-right-15"
                                                                                icon={faArrowAltCircleUp}
                                                                                style={style}
                                                                            />
                                                                            {label}
                                                                        </div>
                                                                    );
                                                                }
                                                            }}
                                                            renderValue={(value, item) => {
                                                                const style = {
                                                                    transform: `rotate(${item.rotation}deg)`,
                                                                };
                                                                if (value === 0) {
                                                                    return (
                                                                        <div
                                                                            data-cy={`configuration-display-value-${item.value}`}>
                                                                            <FontAwesomeIcon
                                                                                style={style}
                                                                                className="margin-right-15"
                                                                                icon={faTimesCircle}
                                                                            />
                                                                            {item.label}
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <div
                                                                            data-cy={`configuration-display-value-${item.value}`}>
                                                                            <FontAwesomeIcon
                                                                                style={style}
                                                                                className="margin-right-15"
                                                                                icon={faArrowAltCircleUp}
                                                                            />
                                                                            {item.label}
                                                                        </div>
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </List.Item>

                                        <List.Item className="panel-list">
                                            <FlexboxGrid justify="space-between" align="middle">
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                    xs={12}
                                                    style={{ fontWeight: 'bold' }}>
                                                    <FormattedMessage id="ipCanDevices.display.configuration.language" />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                    <FormGroup>
                                                        <FormControl
                                                            data-cy="ipCanDevices-display-lang"
                                                            name="lang"
                                                            data={jsonToArray(languages)}
                                                            accepter={TagPicker}
                                                            cleanable={false}
                                                            renderMenuItem={(label, item) => {
                                                                return (
                                                                    <div
                                                                        data-cy={`configuration-lang-value-${item.value}`}>
                                                                        {label}
                                                                    </div>
                                                                );
                                                            }}
                                                            renderValue={(_, item) => {
                                                                return item.map(i => {
                                                                    return (
                                                                        <Tag color="blue" key={`type-${i.label}`}>
                                                                            {i.label}
                                                                        </Tag>
                                                                    );
                                                                });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    {/* <FormControl type="number" name="lang" inline /> */}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </List.Item>

                                        <List.Item className="panel-list">
                                            <FlexboxGrid justify="space-between" align="middle">
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                    xs={12}
                                                    style={{ fontWeight: 'bold' }}>
                                                    <FormattedMessage id="ipCanDevices.display.configuration.text" />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                    <FormGroup>
                                                        <FormControl
                                                            data-cy="ipCanDevices-display-text"
                                                            name="text"
                                                            accepter={SelectPicker}
                                                            data={text}
                                                            cleanable={false}
                                                            searchable={false}
                                                            placement="top"
                                                            renderMenuItem={(label, item) => {
                                                                return (
                                                                    <div
                                                                        data-cy={`configuration-text-value-${item.value}`}>
                                                                        {label}
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>{' '}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </List.Item>

                                        <List.Item className="panel-list">
                                            <FlexboxGrid justify="space-between" align="middle">
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                    xs={12}
                                                    style={{ fontWeight: 'bold' }}>
                                                    <FormattedMessage id="ipCanDevices.display.configuration.type" />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                    <FormControl
                                                        data-cy="ipCanDevices-display-type"
                                                        name="type"
                                                        data={jsonToArray(types)}
                                                        accepter={TagPicker}
                                                        cleanable={false}
                                                        renderMenuItem={(label, item) => {
                                                            return (
                                                                <div
                                                                    data-cy={`configuration-type-value-${item.value}`}>
                                                                    {label}
                                                                </div>
                                                            );
                                                        }}
                                                        renderValue={(_, item) => {
                                                            return item.map(i => {
                                                                return (
                                                                    <Tag color="blue" key={`type-${i.label}`}>
                                                                        {i.label}
                                                                    </Tag>
                                                                );
                                                            });
                                                        }}
                                                    />
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </List.Item>

                                        <List.Item className="panel-list">
                                            <FlexboxGrid justify="space-between" align="middle">
                                                <FlexboxGrid.Item
                                                    componentClass={Col}
                                                    xs={12}
                                                    style={{ fontWeight: 'bold' }}>
                                                    <Button
                                                        data-cy="ipCanDevices-display-edit-valid"
                                                        color="green"
                                                        onClick={this.valid}>
                                                        <FormattedMessage id="ipCanDevices.display.configuration.edit.valid" />
                                                    </Button>
                                                    <Button
                                                        data-cy="ipCanDevices-display-edit-cancel"
                                                        appearance="link"
                                                        onClick={this.cancel}>
                                                        <FormattedMessage id="ipCanDevices.display.configuration.edit.cancel" />
                                                    </Button>{' '}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </List.Item>
                                    </List>
                                </Form>
                            </SecuredFragment>
                        ) : (
                            <List hover data-cy="ipcan-elements-display-drawer-configuration">
                                <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.UPDATE_ARROW_CONFIG]}>
                                    <List.Item className={`panel-list ${arrowClass}`}>
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                data-cy="ipCanDevices-display-arrow"
                                                style={{ fontWeight: 'bold' }}
                                                componentClass={Col}
                                                xs={4}>
                                                <FormattedMessage id="ipCanDevices.display.configuration.arrow" />
                                            </FlexboxGrid.Item>

                                            {/* CHECKING DATABASE */}
                                            {this.props.isCheckingDatabase && (
                                                <FlexboxGrid.Item
                                                    data-cy="ipcan-elements-display-arrow-bdd"
                                                    componentClass={Col}
                                                    xs={8}>
                                                    <Arrows value={this.props.databaseData?.arrow} />
                                                </FlexboxGrid.Item>
                                            )}
                                            <div data-cy="ipCanDevices-display-arrow-value">
                                                <FlexboxGrid.Item>
                                                    <Arrows value={this.props.display.arrow} />
                                                </FlexboxGrid.Item>
                                            </div>
                                        </FlexboxGrid>
                                    </List.Item>
                                </SecuredFragment>

                                <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.UPDATE_LANG_CONFIG]}>
                                    <List.Item className={`panel-list ${langClass}`}>
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                data-cy="ipCanDevices-display-lang"
                                                style={{ fontWeight: 'bold' }}
                                                componentClass={Col}
                                                xs={4}>
                                                <FormattedMessage id="ipCanDevices.display.configuration.language" />
                                            </FlexboxGrid.Item>

                                            {/* CHECKING DATABASE */}
                                            {this.props.isCheckingDatabase && (
                                                <FlexboxGrid.Item
                                                    data-cy="ipcan-elements-display-lang-bdd"
                                                    componentClass={Col}
                                                    xs={8}>
                                                    <Languages value={this.props.databaseData?.lang} />
                                                </FlexboxGrid.Item>
                                            )}

                                            <FlexboxGrid.Item>
                                                <div data-cy="ipCanDevices-display-lang-value">
                                                    <FlexboxGrid.Item>
                                                        <Languages value={this.props.display.lang} />
                                                    </FlexboxGrid.Item>
                                                </div>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                </SecuredFragment>

                                <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.UPDATE_TEXT_CONFIG]}>
                                    <List.Item className={`panel-list ${textClass}`}>
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                data-cy="ipCanDevices-display-text"
                                                style={{ fontWeight: 'bold' }}
                                                componentClass={Col}
                                                xs={4}>
                                                <FormattedMessage id="ipCanDevices.display.configuration.text" />
                                            </FlexboxGrid.Item>
                                            {/* CHECKING DATABASE */}
                                            {this.props.isCheckingDatabase && (
                                                <FlexboxGrid.Item
                                                    data-cy="ipcan-elements-display-text-bdd"
                                                    componentClass={Col}
                                                    xs={8}>
                                                    <Text value={this.props.databaseData?.text} />
                                                </FlexboxGrid.Item>
                                            )}
                                            <div data-cy="ipCanDevices-display-text-value">
                                                <FlexboxGrid.Item>
                                                    <Text value={this.props.display.text} />
                                                </FlexboxGrid.Item>
                                            </div>
                                        </FlexboxGrid>
                                    </List.Item>
                                </SecuredFragment>

                                <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.UPDATE_TYPE_CONFIG]}>
                                    <List.Item className={`panel-list ${typeClass}`}>
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                data-cy="ipCanDevices-display-configuration-type"
                                                style={{ fontWeight: 'bold' }}
                                                componentClass={Col}
                                                xs={4}>
                                                <FormattedMessage id="ipCanDevices.display.configuration.type" />
                                            </FlexboxGrid.Item>

                                            {/* CHECKING DATABASE */}
                                            {this.props.isCheckingDatabase && (
                                                <FlexboxGrid.Item
                                                    data-cy="ipcan-elements-display-type-bdd"
                                                    componentClass={Col}
                                                    xs={8}>
                                                    <Types value={this.props.databaseData?.type} />
                                                </FlexboxGrid.Item>
                                            )}

                                            <FlexboxGrid.Item>
                                                <FlexboxGrid align="middle" justify="end">
                                                    <div data-cy="ipCanDevices-display-configuration-type-value">
                                                        <FlexboxGrid.Item>
                                                            <Types value={this.props.display.type} />
                                                        </FlexboxGrid.Item>
                                                    </div>
                                                </FlexboxGrid>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                </SecuredFragment>
                            </List>
                        )}
                    </Panel>
                </Loader>
            </Fragment>
        );
    }
}

export default injectIntl(ConfigurationCard);
