import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal } from 'rsuite';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    open: boolean;
    onClose: (shouldReload: boolean) => void;
};

type FormValue = {
    name: string;
    description: string;
};

export const CreateProfileModal = (props: Props) => {
    const intl = useIntl();

    const [isCreating, setIsCreating] = React.useState<boolean>(false);
    const [formValue, setFormValue] = React.useState<FormValue>({
        name: '',
        description: '',
    });

    const handleCloseModal = (shouldReload: boolean) => {
        if (!isCreating) {
            setFormValue({
                name: '',
                description: '',
            });

            props.onClose(shouldReload);
        }
    };

    const handleCreateProfile = () => {
        setIsCreating(true);

        axiosService
            .getAxios()
            .post(
                '/profile',
                {
                    name: formValue.name,
                    description: formValue.description,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'profiles.create.error' }));

                handleCloseModal(true);
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'profiles.create.error' }));
            })
            .finally(() => {
                setIsCreating(false);
            });
    };

    return (
        <Modal show={props.open} onHide={() => handleCloseModal(false)}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="profile.create.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form fluid formValue={formValue} onChange={value => setFormValue(value as FormValue)}>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="profile.create.name" />
                        </ControlLabel>
                        <FormControl name="name" data-cy="profile-create-name" />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="profile.create.description" />
                        </ControlLabel>
                        <FormControl name="description" data-cy="profile-create-description" />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={() => handleCloseModal(false)}
                        color="red"
                        disabled={isCreating}
                        data-cy="profile-create-cancel">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={handleCreateProfile}
                        appearance="primary"
                        loading={isCreating}
                        data-cy="profile-create-valid">
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
