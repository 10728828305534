import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal } from 'rsuite';
import ModalFooter from 'rsuite/lib/Modal/ModalFooter';
import { Configuration } from '../../../handlers/Configuration/Configuration';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    show: boolean;
    onHide: (shouldReload: boolean) => void;
    configuration?: Configuration;
};

type FormValue = {
    value: string;
};

export const EditConfigurationModal = (props: Props) => {
    const intl = useIntl();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [formValue, setFormValue] = useState<FormValue>({ value: props.configuration?.getValue() || '' });

    useEffect(() => {
        setFormValue({
            value: props.configuration?.getValue() || '',
        });
    }, [props.configuration?.getId()]);

    const handleEdit = () => {
        setIsEditing(true);

        axiosService
            .getAxios()
            .put(
                '/configuration',
                {
                    id: props.configuration?.getId(),
                    value: formValue.value,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'configuration.edit.success' }));
                props.onHide(true);
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'configuration.edit.error' }));
            })
            .finally(() => {
                setIsEditing(false);
            });
    };

    return (
        <Modal show={props.show} onHide={() => props.onHide(false)} backdrop="static">
            <Modal.Header>
                <Modal.Title>{intl.formatMessage({ id: 'configuration.edit.title' })}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <b>
                    {props.configuration?.getSection()} - {props.configuration?.getKey()}
                </b>

                <Form fluid formValue={formValue} onChange={value => setFormValue(value as FormValue)}>
                    <FormGroup>
                        <ControlLabel>{intl.formatMessage({ id: 'configuration.edit.value' })}</ControlLabel>
                        <FormControl name="value" />
                    </FormGroup>
                </Form>
            </Modal.Body>

            <ModalFooter>
                <ButtonGroup>
                    <Button color="red" disabled={isEditing} onClick={() => props.onHide(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <Button color="green" loading={isEditing} onClick={handleEdit}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </ModalFooter>
        </Modal>
    );
};
