import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal } from 'rsuite';
import { IPCameraAPIResponse } from '../../../handlers/Camera/ApiCamera';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    reloadCameras: () => void;
};

type FormValue = {
    name: string;
    macStr: string;
    ip: string;
    login: string;
    password: string;
    url: string;
    port: string;
};

export const CreateCameraModal = (props: Props) => {
    const intl = useIntl();

    const [isCreating, setIsCreating] = React.useState<boolean>(false);

    const [formValue, setFormValue] = React.useState<FormValue>({
        name: '',
        macStr: '',
        ip: '',
        login: '',
        password: '',
        url: '',
        port: '',
    });

    const handleCancel = () => {
        setFormValue({
            name: '',
            macStr: '',
            ip: '',
            login: '',
            password: '',
            url: '',
            port: '',
        });
        props.onHide();
    };

    const handleValid = () => {
        setIsCreating(true);

        const { name, macStr, ip, login, password, port, url } = formValue;

        axiosService
            .getAxios()
            .post<IPCameraAPIResponse>(
                '/ip-cameras',
                {
                    name,
                    macStr,
                    ip,
                    login,
                    password,
                    url,
                    port: parseInt(port),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'camera.create.success' }));

                props.reloadCameras();

                setIsCreating(false);

                handleCancel();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'camera.create.error' }));

                setIsCreating(false);
            });
    };

    const formIsValid = (): boolean => {
        const { name, macStr, ip } = formValue;

        if (name && macStr && ip) {
            return true;
        }

        return false;
    };

    return (
        <Modal show={props.isOpen} onHide={handleCancel} backdrop="static" size="xs">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="camera.create.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form formValue={formValue} onChange={value => setFormValue(value as FormValue)} fluid>
                    {/* NAME */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.create.form.name" />
                        </ControlLabel>
                        <FormControl name="name" />
                    </FormGroup>

                    {/* MAC STR */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.create.form.macStr" />
                        </ControlLabel>
                        <FormControl name="macStr" />
                    </FormGroup>

                    {/* IP */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.create.form.ip" />
                        </ControlLabel>
                        <FormControl name="ip" />
                    </FormGroup>

                    {/* LOGIN */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.create.form.login" />
                        </ControlLabel>
                        <FormControl name="login" />
                    </FormGroup>

                    {/* PASSWORD */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.create.form.password" />
                        </ControlLabel>
                        <FormControl name="password" />
                    </FormGroup>

                    {/* URL */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.create.form.url" />
                        </ControlLabel>
                        <FormControl name="url" />
                    </FormGroup>

                    {/* PORT */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.create.form.port" />
                        </ControlLabel>
                        <FormControl name="port" type="number" min={0} max={65535} />
                    </FormGroup>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={handleCancel}
                        data-cy="calendar-event-deleteModal-cancel"
                        color="red"
                        disabled={isCreating}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={handleValid}
                        data-cy="calendar-event-deleteModal-valid"
                        appearance="primary"
                        loading={isCreating}
                        disabled={!formIsValid()}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
