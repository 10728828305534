import { faDesktop, faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FlexboxGrid, Message, Modal } from 'rsuite';
import TcmDisplayHistoric from '../../../../../handlers/history/TcmDisplayHistoric.handler';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import HistorySortPart from '../../../../History/SortPart';
import ReadRealDisplayExpand from '../../../../History/Table/Expandable/ReadRealDisplayExpand';
import TCMDisplayDescCell from '../../../../History/Table/TCMDisplayDescCell';
import TCMDisplayOptionCell from '../../../../History/Table/TCMDisplayOptionCell';
import HistoryTable from '../../../../ReactDataTableComponent/HistoryTable';

type Props = {
    display: Record<string, any>;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    histories: Array<Record<string, any>>;
    isLoading: boolean;
    hasError: boolean;
    users: Array<Record<string, any>>;
    formValues: FormValue;
};

type FormValue = {
    user: Array<Record<string, any>> | null;
    before: Moment | null;
    after: Moment | null;
    nbElements: number;
};

class TCMDisplayHistoryModal extends React.Component<Props, State> {
    columns: TableColumn<Record<string, any>>[];
    constructor(props) {
        super(props);

        this.state = {
            histories: [],
            isLoading: true,
            hasError: false,
            users: [],
            formValues: {
                user: null,
                before: null,
                after: null,
                nbElements: 100,
            },
        };

        this.setUser = this.setUser.bind(this);
        this.setBefore = this.setBefore.bind(this);
        this.setAfter = this.setAfter.bind(this);
        this.setNbElements = this.setNbElements.bind(this);
        this.loadHistory = this.loadHistory.bind(this);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.user',
                }),
                selector: 'user',
                sortable: true,
                cell: row => {
                    if (row.user === 'MQTT') {
                        return <FontAwesomeIcon icon={faRobot} size="lg" />;
                    } else {
                        return row.user;
                    }
                },
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.createdAt',
                }),
                selector: 'createdAt',
                sortable: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.desc',
                }),
                selector: 'desc',
                sortable: true,
                cell: row => TCMDisplayDescCell(row.desc),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.option',
                }),
                selector: 'option',
                cell: row => TCMDisplayOptionCell(row.desc, row.option),
            },
        ];
    }

    setUser(user) {
        this.setState({
            formValues: { ...this.state.formValues, user },
        });
    }

    setBefore(before) {
        this.setState({
            formValues: { ...this.state.formValues, before: moment(before).add(1, 'hour') },
        });
    }

    setAfter(after) {
        this.setState({
            formValues: { ...this.state.formValues, after: moment(after).add(1, 'hour') },
        });
    }

    setNbElements(nbElements) {
        this.setState({
            formValues: { ...this.state.formValues, nbElements },
        });
    }

    loadHistory() {
        this.setState({
            isLoading: true,
        });

        axiosService
            .getAxios()
            .post(
                '/historics/tcm-displays',
                {
                    nbResult: this.state.formValues.nbElements || 100,
                    id: this.props.display.id,
                    user: this.state.formValues.user,
                    after: this.state.formValues.after ? moment(this.state.formValues.after).toISOString() : null,
                    before: this.state.formValues.before ? moment(this.state.formValues.before).toISOString() : null,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(tcmDisplayHistoryResponse => {
                let users: Array<string> = [];

                const histories = tcmDisplayHistoryResponse.data.map(history => {
                    const tcmDisplayHistory = new TcmDisplayHistoric(history);

                    users.indexOf(tcmDisplayHistory.getUser()) === -1 && users.push(tcmDisplayHistory.getUser());

                    let formattedHistory: Record<string, any> = tcmDisplayHistory.getHistory();

                    if (
                        formattedHistory.desc !== 'read real sensor config' ||
                        (formattedHistory.desc === 'read real sensor config' && formattedHistory.option?.error)
                    ) {
                        formattedHistory.disabled = true;
                    } else {
                        formattedHistory.disabled = false;
                    }

                    return formattedHistory;
                });

                this.setState({
                    histories,
                    users: users.map(user => {
                        return { label: user, value: user };
                    }),
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    componentDidMount() {
        this.loadHistory();
    }

    render() {
        return (
            <Modal
                full
                backdrop="static"
                show={this.props.show}
                onHide={() => this.props.onHide()}
                style={{ height: '95%' }}
                className="no-modal-padding">
                <Modal.Header style={{ backgroundColor: '#212121' }}>
                    <HistorySortPart
                        users={this.state.users}
                        setUser={this.setUser}
                        setBefore={this.setBefore}
                        setAfter={this.setAfter}
                        valid={this.loadHistory}
                        setNbElements={this.setNbElements}
                    />
                </Modal.Header>
                <Modal.Body>
                    {this.state.hasError ? (
                        <Message type="error">
                            <FormattedMessage id="history.tcmDisplay.fetchHistoryError" />
                        </Message>
                    ) : (
                        <Fragment>
                            <FlexboxGrid align="middle" justify="center">
                                <FlexboxGrid.Item>
                                    <FontAwesomeIcon icon={faDesktop} size="2x" className="margin-right-5" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <h4 className="text-center">{`${this.props.intl.formatMessage({
                                        id: 'history.tcmDisplay.history.title',
                                    })}-${this.props.display.bus + 1}-${this.props.display.deviceId}`}</h4>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            <div data-cy="history-tcmDisplay-history-table">
                                <HistoryTable
                                    columns={this.columns}
                                    data={this.state.histories}
                                    progressPending={this.state.isLoading}
                                    expandableRows
                                    expandOnRowClicked
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={ReadRealDisplayExpand}
                                />
                            </div>
                        </Fragment>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}
export default injectIntl(TCMDisplayHistoryModal);
