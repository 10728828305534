import { FormattedMessage, useIntl } from 'react-intl';
import { Tag } from 'rsuite';
import Task from '../../../../../handlers/Event/Task';
import { CountersPart } from './Parts/CountersPart';

type Props = {
    task: Task;
};

export const TCMSensorForceColor = (props: Props) => {
    const intl = useIntl();

    const colors = {
        green: {
            className: 'task-color-green',
            label: intl.formatMessage({
                id: 'sensor.color.green',
            }),
        },
        red: {
            className: 'task-color-red',
            label: intl.formatMessage({
                id: 'sensor.color.red',
            }),
        },
        black: {
            className: 'task-color-black',
            label: intl.formatMessage({
                id: 'sensor.color.black',
            }),
        },
        green_blink: {
            className: 'task-color-green-blink',
            label: intl.formatMessage({
                id: 'sensor.color.greenBlink',
            }),
        },
        red_blink: {
            className: 'task-color-red-blink',
            label: intl.formatMessage({
                id: 'sensor.color.redBlink',
            }),
        },
        red_green_blink: {
            className: 'task-color-red-green-blink',
            label: intl.formatMessage({
                id: 'sensor.color.redGreenBlink',
            }),
        },
    };

    return (
        <>
            <FormattedMessage id="tasks.forceTCMSensorColor" />

            <Tag
                data-cy="tasks-forceTCMSensorColor-color"
                className={`margin-left-5 ${colors[props.task.getForceOption().getStringColor() || 0].className}`}>
                {colors[props.task.getForceOption().getStringColor() || 0].label}
            </Tag>

            <CountersPart counters={props.task.getCounters()} />
        </>
    );
};
