import { faCheck, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, ButtonGroup, Col, FlexboxGrid, List, Loader, Message, Modal, Panel } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    show: boolean;
    onHide: Function;
    sensorId: number;
    currentSensorPlaceType: any;
} & WrappedComponentProps;

type State = {
    isLoading: boolean;
    sensorData: SensorStates[];
    databaseData: SensorStates[];
    hasError: boolean;
    error: any;
};

type SensorStates = {
    label: string;
    steps: {
        led1: boolean;
        led2: boolean;
        led3: boolean;
        led4: boolean;
        led5: boolean;
        led6: boolean;
        color: string;
        time: number;
    }[];
};

const tabColor = [
    '#000000',
    '#000055',
    '#0000AA',
    '#0000FF',
    '#005500',
    '#005555',
    '#0055AA',
    '#0055FF',
    '#00AA00',
    '#00AA55',
    '#00AAAA',
    '#00AAFF',
    '#00FF00',
    '#00FF55',
    '#00FFAA',
    '#00FFFF',
    '#550000',
    '#550055',
    '#5500AA',
    '#5500FF',
    '#555500',
    '#555555',
    '#5555AA',
    '#5555FF',
    '#55AA00',
    '#55AA55',
    '#55AAAA',
    '#55AAFF',
    '#55FF00',
    '#55FF55',
    '#55FFAA',
    '#55FFFF',
    '#AA0000',
    '#AA0055',
    '#AA00AA',
    '#AA00FF',
    '#AA5500',
    '#AA5555',
    '#AA55AA',
    '#AA55FF',
    '#AAAA00',
    '#AAAA55',
    '#AAAAAA',
    '#AAAAFF',
    '#AAFF00',
    '#AAFF55',
    '#AAFFAA',
    '#AAFFFF',
    '#FF0000',
    '#FF0055',
    '#FF00AA',
    '#FF00FF',
    '#FF5500',
    '#FF5555',
    '#FF55AA',
    '#FF55FF',
    '#FFAA00',
    '#FFAA55',
    '#FFAAAA',
    '#FFAAFF',
    '#FFFF00',
    '#FFFF55',
    '#FFFFAA',
    '#FFFFFF',
    '#000000',
    '#040404',
    '#080808',
    '#0C0C0C',
    '#101010',
    '#141414',
    '#181818',
    '#1C1C1C',
    '#202020',
    '#242424',
    '#282828',
    '#2C2C2C',
    '#303030',
    '#343434',
    '#383838',
    '#3C3C3C',
    '#404040',
    '#444444',
    '#484848',
    '#4C4C4C',
    '#505050',
    '#555555',
    '#595959',
    '#5D5D5D',
    '#616161',
    '#656565',
    '#696969',
    '#6D6D6D',
    '#717171',
    '#757575',
    '#797979',
    '#7D7D7D',
    '#818181',
    '#858585',
    '#898989',
    '#8D8D8D',
    '#919191',
    '#959595',
    '#999999',
    '#9D9D9D',
    '#A1A1A1',
    '#A5A5A5',
    '#AAAAAA',
    '#AEAEAE',
    '#B2B2B2',
    '#B6B6B6',
    '#BABABA',
    '#BEBEBE',
    '#C2C2C2',
    '#C6C6C6',
    '#CACACA',
    '#CECECE',
    '#D2D2D2',
    '#D6D6D6',
    '#DADADA',
    '#DEDEDE',
    '#E2E2E2',
    '#E6E6E6',
    '#EAEAEA',
    '#EEEEEE',
    '#F2F2F2',
    '#F6F6F6',
    '#FAFAFA',
    '#FFFFFF',
];

class LPSensorTypeComparissionModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            sensorData: [],
            databaseData: [],
            hasError: false,
            error: null,
        };
    }

    componentDidMount = () => {
        axiosService
            .getAxios()
            .get(`/devices/lp-sensor/sceneConfig/${this.props.sensorId}`, { headers: authHeader() })
            .then(sceneConfigResponse => {
                // ONLY INFORMATIONS PASSED VIA PROPS
                let databaseData: SensorStates[] = [
                    {
                        label: this.props.intl.formatMessage({
                            id: 'sensorPlaceTypeScene.free',
                        }),
                        steps: [],
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'sensorPlaceTypeScene.occupied',
                        }),
                        steps: [],
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'sensorPlaceTypeScene.overstay',
                        }),
                        steps: [],
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'sensorPlaceTypeScene.reserved',
                        }),
                        steps: [],
                    },
                ];

                for (let i = 0; i < this.props.currentSensorPlaceType.scenes.length; i++) {
                    let currentScene = this.props.currentSensorPlaceType.scenes[i];

                    let sceneConfigObject: any = {
                        led1: currentScene.led1,
                        led2: currentScene.led2,
                        led3: currentScene.led3,
                        led4: currentScene.led4,
                        led5: currentScene.led5,
                        led6: currentScene.led6,
                        color: currentScene.color,
                        time: currentScene.time,
                    };

                    databaseData[currentScene.state - 1].steps.push(sceneConfigObject);
                }

                // ---
                // ONLY JUST GETTED INFORMATIONS VIA API
                const data = sceneConfigResponse.data;

                let sensorData: SensorStates[] = [
                    {
                        label: this.props.intl.formatMessage({
                            id: 'sensorPlaceTypeScene.free',
                        }),
                        steps: [],
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'sensorPlaceTypeScene.occupied',
                        }),
                        steps: [],
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'sensorPlaceTypeScene.overstay',
                        }),
                        steps: [],
                    },
                    {
                        label: this.props.intl.formatMessage({
                            id: 'sensorPlaceTypeScene.reserved',
                        }),
                        steps: [],
                    },
                ];

                for (let i = 1; i < data.scenes.length; i++) {
                    let currentScene = data.scenes[i];

                    for (let j = 0; j < currentScene.step.length; j++) {
                        let sceneConfigObject: any = {
                            led1: false,
                            led2: false,
                            led3: false,
                            led4: false,
                            led5: false,
                            led6: false,
                            color: '#FFFFFF',
                            time: 0,
                        };

                        const currentStep = currentScene.step[j];
                        let index = 1;
                        for (let l = 1; l < 64; l <<= 1) {
                            sceneConfigObject[`led${index}`] = (currentStep.ledMask & l) !== 0;
                            index++;
                        }
                        sceneConfigObject.color = tabColor[currentStep.colorIdx];
                        sceneConfigObject.time = currentStep.time;
                        sensorData[currentScene.sceneIdx - 1].steps.push(sceneConfigObject);
                    }
                }

                this.setState({
                    sensorData: sensorData,
                    databaseData: databaseData,
                });
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    hasError: true,
                    error: err,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    generateListLine = (scene: SensorStates, index) => {
        let listLine: any[] = [];
        let listClass = 'list-green';

        if (
            // Testing length first
            scene.steps.length !== this.state.sensorData[index].steps.length ||
            scene.steps.length !== this.state.databaseData[index].steps.length
        ) {
            listClass = 'list-red';
        } else {
            let correct = true;

            for (let i = 0; i < scene.steps.length; i++) {
                let currentStep = scene.steps[i];

                for (let j = 1; j <= 6; j++) {
                    if (
                        currentStep[`led${j}`] !== this.state.sensorData[index].steps[i][`led${j}`] ||
                        currentStep[`led${j}`] !== this.state.databaseData[index].steps[i][`led${j}`]
                    )
                        correct = false;
                }

                if (
                    currentStep.color.toUpperCase() !== this.state.sensorData[index].steps[i].color.toUpperCase() ||
                    currentStep.color.toUpperCase() !== this.state.databaseData[index].steps[i].color.toUpperCase()
                ) {
                    correct = false;
                }

                if (
                    currentStep.time !== this.state.sensorData[index].steps[i].time ||
                    currentStep.time !== this.state.databaseData[index].steps[i].time
                ) {
                    correct = false;
                }

                if (!correct) listClass = 'list-red';
            }
        }

        for (let s in scene.steps) {
            let ledActive: any[] = [];

            const step = scene.steps[s];

            for (let i = 1; i <= 6; i++) {
                ledActive.push(
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon
                            icon={step[`led${i}`] ? faCheck : faTimes}
                            color={step[`led${i}`] ? 'green' : 'red'}
                            size="lg"
                        />
                    </FlexboxGrid.Item>
                );
            }

            ledActive.push(
                <Fragment>
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon icon={faSquare} color={JSON.parse(JSON.stringify(step.color))} size="lg" />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>{step.time} s</FlexboxGrid.Item>
                </Fragment>
            );

            listLine.push(ledActive);
        }

        return (
            <Fragment>
                {listLine.map(led => (
                    <List.Item className={listClass}>
                        <FlexboxGrid justify="space-between" align="middle">
                            {led}
                        </FlexboxGrid>
                    </List.Item>
                ))}
            </Fragment>
        );
    };

    render() {
        return (
            <Modal size="lg" show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="lpSensor.informations.sensorPlaceType.compare.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: '45vh' }}>
                    {this.state.hasError && (
                        <Message
                            type="error"
                            description={this.props.intl.formatMessage(
                                {
                                    id: 'lpSensor.informations.sensorPlaceType.compare.error',
                                },
                                {
                                    error: this.state.error,
                                }
                            )}
                        />
                    )}
                    {this.state.isLoading && <Loader />}
                    {!this.state.isLoading && (
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <Panel
                                    className="panel-small"
                                    bordered
                                    bodyFill
                                    header={this.props.intl.formatMessage({
                                        id: 'lpSensor.informations.sensorPlaceType.compare.sensor',
                                    })}>
                                    <List bordered hover>
                                        {this.state.sensorData.map((scene: SensorStates, index: number) => {
                                            return (
                                                <Fragment>
                                                    <List.Item className="separator-filter">
                                                        <FlexboxGrid justify="center" align="middle">
                                                            <FlexboxGrid.Item>{scene.label}</FlexboxGrid.Item>
                                                        </FlexboxGrid>
                                                    </List.Item>

                                                    {this.generateListLine(scene, index)}
                                                </Fragment>
                                            );
                                        })}
                                    </List>
                                </Panel>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <Panel
                                    className="panel-small"
                                    bordered
                                    bodyFill
                                    header={this.props.intl.formatMessage({
                                        id: 'lpSensor.informations.sensorPlaceType.compare.database',
                                    })}>
                                    <List bordered hover>
                                        {this.state.databaseData.map((scene: SensorStates, index: number) => {
                                            return (
                                                <Fragment>
                                                    <List.Item className="separator-filter">
                                                        <FlexboxGrid justify="center" align="middle">
                                                            <FlexboxGrid.Item>{scene.label}</FlexboxGrid.Item>
                                                        </FlexboxGrid>
                                                    </List.Item>

                                                    {this.generateListLine(scene, index)}
                                                </Fragment>
                                            );
                                        })}
                                    </List>
                                </Panel>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            data-cy="ipCanDevices-lpSensors-compare-valid"
                            onClick={() => this.props.onHide()}
                            color="green"
                            disabled={this.state.isLoading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(LPSensorTypeComparissionModal);
