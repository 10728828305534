import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, SyntheticEvent } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Button, ButtonGroup, FlexboxGrid, List, Panel } from 'rsuite';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { rolesConstants } from '../../../../../static/roles';
import SecuredFragment from '../../../../Auth/SecuredFragment';
import PanelHeader from '../../../../Custom/PanelHeader';
import UpdateLPDisplaySystemText from '../../../LPDisplays/Modal/UpdateLPDisplaySystemText';

type Props = {
    display: LPDisplay;
    isLoading: boolean;
    isCollapsible?: boolean;
    reloadDisplay?: () => void;
} & WrappedComponentProps;

type State = {
    showEditSystemTextModal: boolean;
    languageSelected: number;
};

class LPDisplaySystemTextCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showEditSystemTextModal: false,
            languageSelected: 0,
        };
    }

    toggleOpenEditSystemTextModal = (e?: SyntheticEvent) => {
        e?.stopPropagation();
        this.setState({
            showEditSystemTextModal: !this.state.showEditSystemTextModal,
        });
    };

    handleSelectedLanguageChange = index => {
        this.setState({
            languageSelected: index,
        });
    };

    render = () => (
        <Fragment>
            <UpdateLPDisplaySystemText
                display={this.props.display}
                show={this.state.showEditSystemTextModal}
                onHide={this.toggleOpenEditSystemTextModal}
                reloadDisplay={this.props.reloadDisplay}
            />
            <Loader show={this.props.isLoading}>
                <Panel
                    className="panel-small rs-panel-collapsible-no-carret"
                    shaded
                    bordered
                    data-cy="ipcan-elements-lpDisplay-systemText"
                    bodyFill
                    collapsible={this.props.isCollapsible}
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplay.systemText.list' })}
                            buttons={[
                                <SecuredFragment
                                    authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_DICTIONNARY_ENTRY]}>
                                    <Button
                                        data-cy="lpDisplay-systemText-edit-button"
                                        appearance="primary"
                                        size="sm"
                                        color="blue"
                                        onClick={this.toggleOpenEditSystemTextModal}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }>
                    <List>
                        <List.Item className="panel-list">
                            <ButtonGroup size="sm" justified>
                                <Button
                                    data-cy="lpDisplay-change-lang1"
                                    appearance={this.state.languageSelected === 0 ? 'primary' : 'ghost'}
                                    onClick={() => this.handleSelectedLanguageChange(0)}>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.systemText.french" />
                                </Button>
                                <Button
                                    data-cy="lpDisplay-change-lang2"
                                    appearance={this.state.languageSelected === 1 ? 'primary' : 'ghost'}
                                    onClick={() => this.handleSelectedLanguageChange(1)}>
                                    LANG 2
                                </Button>
                                <Button
                                    data-cy="lpDisplay-change-lang3"
                                    appearance={this.state.languageSelected === 2 ? 'primary' : 'ghost'}
                                    onClick={() => this.handleSelectedLanguageChange(2)}>
                                    LANG 3
                                </Button>
                                <Button
                                    data-cy="lpDisplay-change-lang4"
                                    appearance={this.state.languageSelected === 3 ? 'primary' : 'ghost'}
                                    onClick={() => this.handleSelectedLanguageChange(3)}>
                                    LANG 4
                                </Button>
                            </ButtonGroup>
                        </List.Item>
                        {this.props.display
                            .getTextConfig()
                            .getSystemTextEntries()
                            .map((text, index) => (
                                <List.Item className="panel-list" key={`system-text-${index}`}>
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item className="bold">
                                            <FormattedMessage id={'ipCanDevices.lpDisplay.systemText.key'} />
                                            <span className="margin-left-5">{index + 1}</span>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item data-cy={'lpDisplay-systemText-word'}>
                                            {
                                                this.props.display.getTextConfig().getEntries()[index][
                                                    this.state.languageSelected
                                                ]
                                            }
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            ))}
                    </List>
                </Panel>
            </Loader>
        </Fragment>
    );
}

export default injectIntl(LPDisplaySystemTextCard);
