export default function jsonToArray(json) {
    let array: Array<Record<string, any>> = [];

    for (const j in json) {
        array.push({
            label: json[j],
            value: parseInt(j),
        });
    }

    return array;
}
