import History from './History';

export default class IpCanHistory extends History {
    idIpcanmodule: number;
    ipcanmoduleName: string;

    constructor(historyObject) {
        const { idIpcanmodule, ipcanmoduleName } = historyObject;

        super(historyObject);

        this.idIpcanmodule = idIpcanmodule;
        this.ipcanmoduleName = ipcanmoduleName;
    }

    getIdIpcanmodule() {
        return this.idIpcanmodule;
    }

    setIdIpcanmodule(idIpcanmodule) {
        this.idIpcanmodule = idIpcanmodule;
    }

    getIpcanmoduleName() {
        return this.ipcanmoduleName;
    }

    setIpcanmoduleName(ipcanmoduleName) {
        this.ipcanmoduleName = ipcanmoduleName;
    }

    getOption() {
        if (!this.option) {
            return null;
        }

        switch (this.getDesc()) {
            case 'tcm cmd bus':
                return {
                    id: this.option.id,
                    bus: parseInt(this.option.bus) + 1,
                    cmd: this.option.cmd,
                };
            case 'start enumeration':
                return {
                    // id: this.option.id,
                    bus: parseInt(this.option.bus) + 1,
                    startStop: this.option.startStop,
                    startId: this.option.startId,
                    stopId: this.option.stopId,
                };
            case 'test bus':
                return {
                    // id: this.option.id,
                    bus: this.option.bus + 1,
                    startStop: this.option.startStop,
                    timing: `${this.option.timing} ms`,
                };
            case 'set gpio':
                return {
                    // id: this.option.id,
                    output: this.option.output + 1,
                    value: this.option.value,
                };
            case 'define sensor id':
                return {
                    // id: this.option.id,
                    bus: this.option.bus,
                    startStop: this.option.startStop,
                    startId: this.option.startId,
                    stopId: this.option.stopId,
                };
            case 'update bus config':
                return this.option.map(option => {
                    return {
                        crcIsActiv: option.crcIsActiv,
                        isActiv: option.isActiv,
                        isLoadConf: option.isLoadConf,
                        isPolling: option.isPolling,
                        name: option.name,
                        pollingTime: parseInt(option.pollingTime),
                        speed: parseInt(option.speed),
                    };
                });
            case 'update config': {
                return {
                    label: this.option.label,
                    ip: this.option.ip,
                    macStr: this.option.macStr,
                };
            }
            case 'lp cmd bus': {
                return this.option;
            }
            case 'reset bus counter error': {
                return {
                    bus: this.option.bus,
                };
            }
            case 'define one sensor id': {
                return {
                    id: this.option.id,
                    bus: this.option.bus,
                    withButton: this.option.withButton,
                    oldId: this.option.oldId,
                    newId: this.option.newId,
                };
            }
            default:
                return null;
        }
    }

    getHistory() {
        return {
            id: this.getId(),
            user: this.getUser(),
            createdAt: this.getCreatedAt(),
            createdBy: this.getCreatedBy(),
            desc: this.getDesc(),
            option: this.getOption(),
            idIpcanmodule: this.getIdIpcanmodule(),
            ipcanmoduleName: this.getIpcanmoduleName(),
            disabled: this.getDisabled(),
        };
    }
}
