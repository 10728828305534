import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, SyntheticEvent } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Button, FlexboxGrid, List, Panel } from 'rsuite';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { rolesConstants } from '../../../../../static/roles';
import SecuredFragment from '../../../../Auth/SecuredFragment';
import PanelHeader from '../../../../Custom/PanelHeader';
import UpdateLPDisplayUserText from '../../../LPDisplays/Modal/UpdateLPDisplayUserText';

type Props = {
    display: LPDisplay;
    isLoading: boolean;
    isCollapsible?: boolean;
    reloadDisplay?: () => void;
} & WrappedComponentProps;

type State = {
    showEditUserTextModal: boolean;
};

class LPDisplayUserTextCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showEditUserTextModal: false,
        };
    }

    openEditUserTextModal = (e: SyntheticEvent) => {
        e.stopPropagation();
        this.setState({
            showEditUserTextModal: true,
        });
    };

    closeEditUserTextModal = () => {
        this.setState({
            showEditUserTextModal: false,
        });
    };

    render = () => (
        <Fragment>
            <UpdateLPDisplayUserText
                display={this.props.display}
                show={this.state.showEditUserTextModal}
                onHide={this.closeEditUserTextModal}
                reloadDisplay={this.props.reloadDisplay}
            />
            <Loader show={this.props.isLoading}>
                <Panel
                    className="panel-small rs-panel-collapsible-no-carret"
                    shaded
                    data-cy="ipcan-elements-lpDisplay-userText"
                    bordered
                    collapsible={this.props.isCollapsible}
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplay.userText.list' })}
                            buttons={[
                                <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_USER_TEXTS]}>
                                    <Button
                                        appearance="primary"
                                        size="sm"
                                        color="blue"
                                        data-cy="ipcan-elements-lpDisplay-update-userText"
                                        onClick={this.openEditUserTextModal}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }
                    bodyFill>
                    <List>
                        {this.props.display
                            .getTextConfig()
                            .getUserText()
                            .map((text, index) => {
                                return (
                                    <List.Item className="panel-list" key={`lpDisplay-userText-${index}`}>
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item className="bold">
                                                <FormattedMessage
                                                    id={`ipCanDevices.lpDisplay.userText.userText${index + 1}`}
                                                />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy={`ipCanDevices-lpDisplay-userText${index}`}>
                                                {text}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                );
                            })}
                    </List>
                </Panel>
            </Loader>
        </Fragment>
    );
}

export default injectIntl(LPDisplayUserTextCard);
