import { faArrowLeft, faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    ControlLabel,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Schema,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { AutoResetTrigger, EventConditionType } from '../../../../handlers/EventAction/EventAction';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { EventActionFormDataType } from '../CreateEventModal';

type Props = {
    formData: EventActionFormDataType;
    setFormData: (data: EventActionFormDataType) => void;
    handleCancel: () => void;
    handleGoBack: () => void;
};

const { DateType, NumberType } = Schema.Types;

export const StepThreeGpio = (props: Props) => {
    const intl = useIntl();

    const formRef = useRef<any>(null);

    const [isCreating, setIsCreating] = useState(false);
    const [ipCanModules, setIpCanModules] = useState<IpCan[]>([]);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/ipcanmodules', {
                headers: authHeader(),
            })
            .then(ipCanModulesResponse => {
                setIpCanModules(ipCanModulesResponse.data.map(ipcan => new IpCan(ipcan)));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'error.fetchIpCansError' }));
            });
    }, []);

    const model = useMemo(() => {
        return Schema.Model({
            autoResetTriggerTime: DateType().addRule((value, data) => {
                if (data.autoResetTrigger === AutoResetTrigger.TIME && !value) {
                    return false;
                }

                return true;
            }),
            moduleIpCan: NumberType().isRequired(),
            gpioNumber: NumberType().isRequired(),
        });
    }, [intl]);

    const autoResetTriggerData = useMemo(() => {
        return [
            {
                label: intl.formatMessage({ id: 'autoResetTrigger.none' }),
                value: AutoResetTrigger.NONE,
            },
            {
                label: intl.formatMessage({ id: 'autoResetTrigger.auto' }),
                value: AutoResetTrigger.AUTO,
            },
            {
                label: intl.formatMessage({ id: 'autoResetTrigger.time' }),
                value: AutoResetTrigger.TIME,
            },
        ];
    }, [intl]);

    const handleSubmit = () => {
        if (formRef.current?.check()) {
            setIsCreating(true);

            const dailyValues = {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            };

            props.formData.daily.forEach((day: string) => {
                dailyValues[day] = true;
            });

            const formattedStartTime = moment(props.formData.startTime);
            const formattedAutoResetTriggerTime = moment(props.formData.autoResetTriggerTime);

            const ipCanMac = ipCanModules.find(ipCanModule => ipCanModule.id === props.formData.moduleIpCan)?.mac;

            axiosService
                .getAxios()
                .post(
                    '/event-actions/event',
                    {
                        name: props.formData.name,
                        desc: props.formData.desc,
                        isInfinite: props.formData.isInfinite,
                        daily: dailyValues,
                        startDate: props.formData.startDate,
                        endDate: props.formData.endDate,
                        eventCondition: {
                            eventConditionType: EventConditionType.GPIO,
                            startTime: formattedStartTime.get('hours') * 60 + formattedStartTime.get('minutes'),
                            gpioCondition: {
                                moduleIpCanId: props.formData.moduleIpCan,
                                gpioNumber: props.formData.gpioNumber,
                                gpioState: props.formData.gpioState,
                                moduleIpCanMac: ipCanMac,
                            },
                            autoResetTrigger: props.formData.autoResetTrigger,
                            autoResetTriggerTime:
                                formattedAutoResetTriggerTime.get('hours') * 60 +
                                formattedAutoResetTriggerTime.get('minutes'),
                        },
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    setIsCreating(false);
                    props.handleCancel();
                })
                .catch(err => {
                    setIsCreating(false);

                    Alert.error(intl.formatMessage({ id: 'event.createTask.error' }));

                    console.error(err);
                });

            return;
        }
    };

    const handleFormChange = (value: any) => {
        props.setFormData({ ...props.formData, ...value });
    };

    const currentIpCanModule = ipCanModules.find(ipCanModule => ipCanModule.id === props.formData.moduleIpCan);

    return (
        <>
            <Form
                fluid
                formValue={props.formData}
                onChange={handleFormChange}
                ref={formRef}
                model={model}
                style={{ marginBottom: 16 }}>
                <FlexboxGrid align="bottom">
                    <FlexboxGrid.Item colspan={12}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="event.createEvent.autoResetTrigger" />
                            </ControlLabel>
                            <FormControl
                                data-cy="event-add-autoResetTrigger"
                                name="autoResetTrigger"
                                accepter={SelectPicker}
                                data={autoResetTriggerData}
                                searchable={false}
                                cleanable={false}
                            />
                        </FormGroup>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={12}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="event.createEvent.autoResetTriggerTime" />
                            </ControlLabel>
                            <FormControl
                                data-cy="event-add-autoResetTriggerTime"
                                name="autoResetTriggerTime"
                                accepter={DatePicker}
                                format="HH:mm"
                                disabled={props.formData.autoResetTrigger !== AutoResetTrigger.TIME}
                            />
                        </FormGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FormGroup style={{ marginTop: 16 }}>
                    <ControlLabel>
                        <FormattedMessage id="event.createEvent.moduleIpCan" />
                    </ControlLabel>
                    <FormControl
                        data-cy="event-add-moduleIpCan"
                        name="moduleIpCan"
                        accepter={SelectPicker}
                        cleanable={false}
                        data={ipCanModules.map(ipCanModule => {
                            return {
                                label: `${ipCanModule.label} (${ipCanModule.macStr})`,
                                value: ipCanModule.id,
                            };
                        })}
                    />
                </FormGroup>

                {props.formData.moduleIpCan > 0 && (
                    <FlexboxGrid align="bottom">
                        <FlexboxGrid.Item colspan={12}>
                            <FormGroup>
                                <ControlLabel>
                                    <FormattedMessage id="event.createEvent.gpioNumber" />
                                </ControlLabel>
                                <FormControl
                                    data-cy="event-add-gpioNumber"
                                    name="gpioNumber"
                                    accepter={SelectPicker}
                                    data={[
                                        { label: currentIpCanModule?.getGpioConfig().gpioIn[0].name, value: 1 },
                                        { label: currentIpCanModule?.getGpioConfig().gpioIn[1].name, value: 2 },
                                        { label: currentIpCanModule?.getGpioConfig().gpioIn[2].name, value: 3 },
                                        { label: currentIpCanModule?.getGpioConfig().gpioIn[3].name, value: 4 },
                                    ]}
                                    searchable={false}
                                    cleanable={false}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item colspan={12}>
                            <FormGroup>
                                <ControlLabel>
                                    <FormattedMessage id="event.createEvent.gpioState" />
                                </ControlLabel>
                                <FormControl
                                    data-cy="event-add-gpioState"
                                    name="gpioState"
                                    accepter={Toggle}
                                    defaultChecked={props.formData.gpioState}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
            </Form>

            <FlexboxGrid style={{ marginTop: 12 }} justify="space-between">
                <FlexboxGrid.Item>
                    <Button
                        onClick={props.handleCancel}
                        appearance="ghost"
                        color="red"
                        data-cy="event-createModal-cancel">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <ButtonGroup>
                        <Button
                            onClick={props.handleGoBack}
                            data-cy="event-createModal-back"
                            appearance="ghost"
                            loading={isCreating}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            loading={isCreating}
                            data-cy="event-createModal-valid"
                            appearance="primary">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};
