import React from 'react';
import debugMode from './Option/debugMode';
import ForceLPSensor from './Option/ForceLPSensor';
import NoOptions from './Option/noOptions';
import readRealConfigOptions from './Option/readRealConfigOptions';
import updateCalibration from './Option/updateCalibration';
import updateFilterConfig from './Option/updateFilterConfig';
import updateMaintenanceState from './Option/updateMaintenanceState';
import updateTime from './Option/updateTime';
import updateUsConfig from './Option/updateUsConfig';
import viewMore from './Option/viewMore';

// wait for online, total and db as data
// wait for controler online or not

export default function LPSensorOptionCell(desc, option) {
    switch (desc) {
        case 'update us config':
            return updateUsConfig(option);
        case 'update filter config':
            return updateFilterConfig(option);
        case 'update time':
            return updateTime(option);
        case 'update calibration':
            return updateCalibration(option);
        case 'read real sensor config':
            return readRealConfigOptions(option);
        case 'debug mode':
            return debugMode(option);
        case 'force sensor':
            return <ForceLPSensor option={option} />;
        case 'update us and filter config':
        case 'read config':
        case 'read scene config':
        case 'send scenes':
        case 'update place type':
            return viewMore();
        case 'update maintenance state':
            return updateMaintenanceState(option);
        default:
            return NoOptions();
    }
}
