import React from 'react';
import { FormattedMessage } from 'react-intl';
import Types from '../../../IpCanElementsPage/Elements/Types';

export default function UpdateBasicConfig(option) {
    const { typeMatrix, nbPixelX, nbPixelY, brightness, origin, gmt } = option;

    return (
        <div>
            <div data-cy="historic-typeMatrix">
                <FormattedMessage id="lpMatrixDisplayV2.history.typeMatrix" />
                <span className="margin-left-5 margin-right-5">-</span>
                <Types noTag value={typeMatrix} />
            </div>
            <div data-cy="historic-nbPixelX">
                <FormattedMessage id="lpMatrixDisplayV2.history.nbPixelX" />
                <span className="margin-left-5 margin-right-5">-</span>
                {nbPixelX}
            </div>
            <div data-cy="historic-nbPixelY">
                <FormattedMessage id="lpMatrixDisplayV2.history.nbPixelY" />
                <span className="margin-left-5 margin-right-5">-</span>
                {nbPixelY}
            </div>
            <div data-cy="historic-brightness">
                <FormattedMessage id="lpMatrixDisplayV2.history.brightness" />
                <span className="margin-left-5 margin-right-5">-</span>
                {brightness}
            </div>
            <div data-cy="historic-origin">
                <FormattedMessage id="lpMatrixDisplayV2.history.origin" />
                <span className="margin-left-5 margin-right-5">-</span>
                {origin}
            </div>
            <div data-cy="historic-gmt">
                <FormattedMessage id="lpMatrixDisplayV2.history.gmt" />
                <span className="margin-left-5 margin-right-5">-</span>
                {gmt}
            </div>
        </div>
    );
}
