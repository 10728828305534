import { faArrowRight, faCheck, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Col, FlexboxGrid, Message, Modal, SelectPicker } from 'rsuite';
import { ipCanHandler } from '../../../handlers/ipcan';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    show: boolean;
    onHide: Function;
    controller: number;
    ipcan: Record<string, any>;
    intl: Record<string, any>;
} & WrappedComponentProps;

type State = {
    controllers: Array<Record<string, any>>;
    newController: Record<string, any> | undefined;
    hasError: boolean;
    isSubmitted: boolean;
    fetching: boolean;
};
class InvertControllersModal extends React.PureComponent<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            controllers: [],
            newController: undefined,
            hasError: false,
            isSubmitted: false,
            fetching: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fetchControllers = this.fetchControllers.bind(this);
    }

    fetchControllers() {
        this.setState({
            fetching: true,
        });

        axiosService
            .getAxios()
            .get('/ipcanmodules', { headers: authHeader() })
            .then(ipcanResponse => ipcanResponse.data.map(ipcan => ipCanHandler(ipcan)))
            .then(controllers => {
                const controllersFormValues = controllers.map(controller => {
                    return {
                        label: `${controller.label} (${controller.macStr})`,
                        value: controller.id,
                    };
                });

                this.setState({
                    controllers: controllersFormValues,
                });
            })
            .catch(e => {
                this.setState({
                    hasError: true,
                });
                console.error(e);
            })
            .finally(() => {
                this.setState({
                    fetching: false,
                });
            });
    }

    handleSubmit() {
        this.setState({ isSubmitted: true });
        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/replaceModule',
                {
                    oldId: this.props.ipcan.id,
                    newId: this.state.newController,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'ipCan.invertController.success',
                    }),
                    5000
                );
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'ipCan.invertController.error',
                    }),
                    5000
                );
            })
            .finally(() => {
                this.setState({
                    isSubmitted: false,
                });

                this.props.onHide(true);
            });
    }

    onChange(value) {
        this.setState({
            newController: value,
        });
    }

    render() {
        return (
            <Modal
                backdrop="static"
                show={this.props.show}
                onHide={() => this.props.onHide(false)}
                data-cy="ipCan-invertController-modal">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCan.invertController.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.hasError && (
                        <Message
                            type="error"
                            description={<FormattedMessage id="ipCan.invertController.fetchControllerError" />}
                        />
                    )}
                    <FormattedMessage id="ipCan.invertController.message" />

                    <FlexboxGrid align="middle">
                        <FlexboxGrid.Item data-cy="ipCan-invertController-select" componentClass={Col} xs={11}>
                            <SelectPicker
                                data={this.state.controllers}
                                block
                                disabled={!this.props.ipcan}
                                cleanable={false}
                                value={this.state.newController}
                                onChange={this.onChange}
                                onOpen={this.fetchControllers}
                                disabledItemValues={this.props.ipcan ? [this.props.ipcan.id] : []}
                                renderMenu={menu => {
                                    if (this.state.controllers.length === 0) {
                                        return (
                                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                <FontAwesomeIcon icon={faSpinner} spin />{' '}
                                                <FormattedMessage id="loading" />
                                            </p>
                                        );
                                    }
                                    return menu;
                                }}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={2} style={{ textAlign: 'center' }}>
                            <FontAwesomeIcon icon={faArrowRight} size="2x" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={11}>
                            {this.props.ipcan.label} ({this.props.ipcan.macStr})
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.props.onHide(false)}
                            color="red"
                            disabled={this.state.isSubmitted}
                            data-cy="ipCan-invertController-cancel">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.handleSubmit}
                            loading={this.state.isSubmitted}
                            color="green"
                            disabled={typeof this.state.newController === 'undefined'}
                            data-cy="ipCan-invertController-valid">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(InvertControllersModal);
