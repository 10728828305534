import { FormattedMessage, useIntl } from 'react-intl';
import { ControlLabel, FlexboxGrid, FormGroup, List, Loader } from 'rsuite';
import Camera from '../../../../handlers/Camera/Camera';
import { Configuration } from '../../../../handlers/Configuration/Configuration';

type Props = {
    camera: Camera;
    configurations: Configuration[];
};

export const ViewHyperParamCameraModal = (props: Props) => {
    const intl = useIntl();

    // History size
    const getHistorySize = () => {
        if (props.camera.getConfig().getHistorySize()) {
            return props.camera.getConfig().getHistorySize();
        }

        const foundConfiguration = props.configurations.find(
            configuration => configuration.getKey() === 'history_size'
        );

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    // IOU threshold
    const getIouThreshold = () => {
        if (props.camera.getConfig().getIouTreshold()) {
            return props.camera.getConfig().getIouTreshold();
        }

        const foundConfiguration = props.configurations.find(
            configuration => configuration.getKey() === 'iou_treshold'
        );

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    // OBJECT CLASS WHITE LIST
    // const getObjectClassWhiteList = () => {
    //     if (props.camera.getConfig().getObjectClassWhiteList()) {
    //         return (
    //             <TagGroup>
    //                 {props.camera
    //                     .getConfig()
    //                     .getObjectClassWhiteList()
    //                     ?.filter(objectClass => objectClass === 0)
    //                     ?.map(object => (
    //                         <Tag color="violet">{generateYoloObjectValue(intl, object)}</Tag>
    //                     ))}
    //             </TagGroup>
    //         );
    //     }

    //     const foundConfiguration = props.configurations.find(
    //         configuration => configuration.getKey() === 'object_class_whitelist'
    //     );

    //     if (foundConfiguration) {
    //         const objectClassWhiteList: number[] = JSON.parse(foundConfiguration.getValue()).filter(
    //             objectClass => objectClass === 0
    //         );
    //         return (
    //             <TagGroup>
    //                 {objectClassWhiteList.map(object => (
    //                     <Tag color="blue">{generateYoloObjectValue(intl, object)}</Tag>
    //                 ))}
    //             </TagGroup>
    //         );
    //     }

    //     return <Loader />;
    // };

    // Yolo det iou
    const getYoloDetIou = () => {
        if (props.camera.getConfig().getYoloDetIou()) {
            return props.camera.getConfig().getYoloDetIou();
        }

        const foundConfiguration = props.configurations.find(
            configuration => configuration.getKey() === 'yolo_det_iou'
        );

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    // Yolo nms iou
    const getYoloNmsIou = () => {
        if (props.camera.getConfig().getYoloNmsIou()) {
            return props.camera.getConfig().getYoloNmsIou();
        }

        const foundConfiguration = props.configurations.find(
            configuration => configuration.getKey() === 'yolo_nms_iou'
        );

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    // Add blur
    const getAddBlur = () => {
        if (props.camera.getConfig().getAddBlur()) {
            return props.camera.getConfig().getAddBlur();
        }

        const foundConfiguration = props.configurations.find(configuration => configuration.getKey() === 'add_blur');

        if (foundConfiguration) {
            return <span className="bold italic">{foundConfiguration.getValue()}</span>;
        }

        return <Loader />;
    };

    return (
        <List>
            {/* HISTORY SIZE */}
            <List.Item>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <ControlLabel>
                            <FormattedMessage id="placeCamera.hyperParam.historySize" />
                        </ControlLabel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <FormGroup>{getHistorySize()}</FormGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            {/* IOU TRESHOLD */}
            <List.Item>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.iouTreshold" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{getIouThreshold()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            {/* OBJECT CLASS WHITE LIST */}
            {/* <List.Item>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.objectClassWhiteList" />
                    </FlexboxGrid.Item>
                    <div>{getObjectClassWhiteList()}</div>
                </FlexboxGrid>
            </List.Item> */}

            <List.Item>
                {/* Yolo det iou */}
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.yoloDetIou" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{getYoloDetIou()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            <List.Item>
                {/* Yolo nms iou */}
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.yoloNmsIou" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{getYoloNmsIou()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            <List.Item>
                {/* Add blur */}
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="placeCamera.hyperParam.addBlur" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{getAddBlur()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
};
