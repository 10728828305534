import { useIntl } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Col, FlexboxGrid, Panel } from 'rsuite';
import { Parking } from '../../../handlers/parking/Parking';
import { CounterStatus } from '../../CountersPage/ParkingPanel';
import { ParkingLevel } from './ParkingLevel';

type Props = {
    parking: Parking;
    loading: boolean;
    counterStatus: CounterStatus[];
};

export const ParkingCard = (props: Props) => {
    const intl = useIntl();

    let gridSize = 8;

    if (props.parking.getLevels().length === 1) {
        gridSize = 24;
    } else if (props.parking.getLevels().length < 5) {
        gridSize = 12;
    }

    return (
        <Loader show={props.loading}>
            <Panel
                className="dashboard-card-header"
                header={`${props.parking.getName()} - ${intl.formatMessage({
                    id: 'homepage.panel.occupationByLevel',
                })}`}
                shaded
                data-cy={'dashboard-parking-stats'}
                style={{ height: window.innerHeight * 0.4, overflowY: 'auto' }}>
                <FlexboxGrid justify="start">
                    {props.parking.getLevels().map(level => {
                        return (
                            <FlexboxGrid.Item
                                key={`parking-${props.parking.getId()}-level-${level.getId()}`}
                                componentClass={Col}
                                colspan={24}
                                xs={gridSize}>
                                <ParkingLevel
                                    // placeTypes={this.state.placeTypes}
                                    // sensorPlaceTypes={this.state.sensorPlaceTypes}
                                    levelId={level.getId()}
                                    levelsLength={props.parking.getLevels().length}
                                    counter={props.counterStatus.find(counter => counter.name === level.getName())}
                                />
                            </FlexboxGrid.Item>
                        );
                    })}
                </FlexboxGrid>
            </Panel>
        </Loader>
    );
};
