import { faHome, faPlug, faPlusCircle, faTrash, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, FlexboxGrid, Loader, Panel, Tag } from 'rsuite';
import { IPCameraAPIResponse } from '../../handlers/Camera/ApiCamera';
import Camera from '../../handlers/Camera/Camera';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import { maintenanceStateInformation } from '../IpCanElementsPage/Table/maintenanceStateInformation';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import { ErrorFlag } from './DataTable/ErrorFlag';
import { CameraDrawer } from './Drawer';
import { ConnectionState } from './Graph/ConnectionState';
import { ErrorFlagGraph } from './Graph/ErrorFlagGraph';
import { PlaceRepartition } from './Graph/PlacesRepartition';
import { CreateCameraModal } from './Modal/CreateCameraModal';
import { DeleteCameraModal } from './Modal/DeleteCameraModal';

type Props = {};

interface CameraDrawerInterface {
    camera?: Camera;
    isOpen: boolean;
}

export const CamerasPage = () => {
    const intl = useIntl();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const [cameras, setCameras] = React.useState<Camera[]>([]);

    const [createCameraModalOpen, setCreateCameraModalOpen] = React.useState<boolean>(false);

    const [cameraDrawer, setCameraDrawer] = React.useState<CameraDrawerInterface>({ isOpen: false });

    const [selectedCameras, setSelectedCameras] = React.useState<Camera[]>([]);

    const [showDeleteCamerasModalOpen, setShowDeleteCamerasModalOpen] = React.useState<boolean>(false);

    const [cleared, setCleared] = React.useState<boolean>(false);

    // HACK FOR WEBSOCKETS
    const camerasRef = useRef<Camera[]>(cameras);

    React.useEffect(() => {
        camerasRef.current = cameras;
    }, [cameras]);

    React.useEffect(() => {
        loadCameras();
    }, []);

    React.useEffect(() => {
        webSocketService.joinRoom('ip-camera');

        webSocketService.onEvent('ip-camera:updateStatus', handleUpdateStatus);
        webSocketService.onEvent('ip-camera:reload', handleWSReload);

        return function cleanup() {
            webSocketService.offEvent('ip-camera:updateStatus', handleUpdateStatus);
            webSocketService.offEvent('ip-camera:reload', handleWSReload);
        };
    }, []);

    React.useEffect(() => {
        if (cleared) {
            setCleared(false);
        }
    }, [cleared]);

    const loadCameras = () => {
        setIsLoading(true);

        axiosService
            .getAxios()
            .get<IPCameraAPIResponse[]>('/ip-cameras', {
                headers: authHeader(),
            })
            .then(response => {
                const fetchedCameras = response.data.map(cameraData => new Camera(cameraData));

                setCameras(fetchedCameras);

                camerasRef.current = fetchedCameras;
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleUpdateStatus = (cameraData: IPCameraAPIResponse) => {
        const cameraUpdated = camerasRef.current.find(camera => camera.getId() === cameraData.id);

        if (cameraUpdated) {
            cameraUpdated.updateCameraWS(cameraData);

            setCameras([...camerasRef.current]);

            if (cameraDrawer.camera && cameraDrawer.camera.getId() === cameraData.id) {
                setCameraDrawer({ ...cameraDrawer, camera: cameraUpdated });
            }
        } else {
            camerasRef.current.push(new Camera(cameraData));

            setCameras(camerasRef.current);
        }
    };

    const reloadCameras = () => {
        loadCameras();
    };

    const handleWSReload = () => {
        loadCameras();
    };

    const columns: TableColumn<Camera>[] = [
        {
            name: intl.formatMessage({ id: 'camera.name' }),
            selector: row => row.getName(),
            sortable: true,
            center: true,
            grow: 2,
        },
        {
            name: intl.formatMessage({ id: 'camera.online' }),
            cell: row => <FontAwesomeIcon icon={faPlug} color={row.getOnline() ? 'green' : 'red'} size="lg" />,
            selector: row => row.getOnline(),
            center: true,
            sortable: true,
            grow: 1,
        },
        {
            name: intl.formatMessage({ id: 'camera.errorFlag' }),
            cell: row => <ErrorFlag errorFlag={row.getErrorFlag()} />,
            selector: row => row.getErrorFlag(),
            center: true,
            sortable: true,
            grow: 1,
        },
        {
            name: intl.formatMessage({
                id: 'camera.maintenanceState',
            }),
            selector: row => row.getMaintenanceState(),
            center: true,
            sortable: true,
            grow: 1,
            cell: row => (
                <SecuredFragment authorizedRoles={[rolesConstants.ipCameras.UPDATE_MAINTENANCE_STATE]}>
                    {maintenanceStateInformation(row.getMaintenanceState(), row.getNotepad())}
                </SecuredFragment>
            ),
        },
        {
            name: intl.formatMessage({ id: 'camera.ip' }),
            selector: row => row.getIp(),
            cell: row => `${row.getIp()} (${row.getMacStr()})`,
            center: true,
            grow: 2,
        },
        {
            name: intl.formatMessage({ id: 'camera.nbPlaces' }),
            selector: row => row.getPlacesCamera().length,
            cell: row => (
                <Tag color="blue" data-tag="allowRowEvents">
                    {row.getPlacesCamera().length}
                </Tag>
            ),
            center: true,
            grow: 2,
        },
        {
            name: intl.formatMessage({ id: 'camera.hardware' }),
            selector: row => row.getHardware(),
            cell: row => `${row.getHardware()} (${row.getFirmware()})`,
            center: true,
            grow: 2,
        },
        {
            name: intl.formatMessage({ id: 'camera.lastOnlineStateDate' }),
            selector: row => row.getLastOnlineStateDate(),
            center: true,
            grow: 2,
        },
        {
            name: intl.formatMessage({ id: 'camera.creation' }),
            selector: row => row.getCreation(),
            center: true,
            grow: 3,
        },
    ];

    const sendAllGoBackHome = () => {
        axiosService
            .getAxios()
            .put('/ip-cameras/allGoToPreset', {}, { headers: authHeader() })
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'camera.goAllBackHome.success' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'camera.goAllBackHome.error' }));
            });
    };

    const handleSelectedRowsChange = ({ selectedRows }: { selectedRows: Camera[] }) => {
        setSelectedCameras(selectedRows);
    };

    const handleCloseDeleteModal = (deleted: boolean) => {
        if (deleted) {
            setShowDeleteCamerasModalOpen(false);
            toggleCleared();
            reloadCameras();
        }
    };

    const toggleCleared = () => {
        setCleared(!cleared);
    };

    return (
        <>
            <CreateCameraModal
                isOpen={createCameraModalOpen}
                onHide={() => setCreateCameraModalOpen(false)}
                reloadCameras={reloadCameras}
            />

            <CameraDrawer
                isOpen={cameraDrawer.isOpen}
                onHide={() => setCameraDrawer({ isOpen: false })}
                camera={cameraDrawer.camera}
                reloadCameras={reloadCameras}
            />

            <DeleteCameraModal
                cameras={selectedCameras}
                show={showDeleteCamerasModalOpen}
                onHide={handleCloseDeleteModal}
            />

            <Panel
                className="panel-big element-table dashboard-card-header hoverable"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'camera.title' })}
                        tagValue={cameras.length}
                        icon={faVideo}
                        buttons={[
                            <SecuredFragment authorizedRoles={[rolesConstants.ipCameras.CREATE]} key="ipcan-create">
                                <Button
                                    className="margin-right-5"
                                    color="red"
                                    size="sm"
                                    onClick={() => setShowDeleteCamerasModalOpen(true)}
                                    disabled={isLoading || selectedCameras.length === 0}
                                    data-cy="camera-allBackToHome">
                                    <FontAwesomeIcon icon={faTrash} className="margin-right-5" />
                                    <FormattedMessage id="camera.delete" />
                                </Button>
                                <Button
                                    className="margin-right-5"
                                    color="blue"
                                    size="sm"
                                    onClick={sendAllGoBackHome}
                                    disabled={isLoading}
                                    data-cy="camera-allBackToHome">
                                    <FontAwesomeIcon icon={faHome} className="margin-right-5" />
                                    <FormattedMessage id="camera.allBackToHome" />
                                </Button>
                                <Button
                                    color="green"
                                    size="sm"
                                    onClick={() => setCreateCameraModalOpen(true)}
                                    disabled={isLoading}
                                    data-cy="camera-addCameraButton">
                                    <FontAwesomeIcon icon={faPlusCircle} className="margin-right-5" />
                                    <FormattedMessage id="camera.add" />
                                </Button>
                            </SecuredFragment>,
                        ]}
                    />
                }>
                <ElementTable
                    columns={columns}
                    data={cameras}
                    progressPending={isLoading}
                    progressComponent={<Loader backdrop />}
                    onRowClicked={(row: Camera) => setCameraDrawer({ camera: row, isOpen: true })}
                    selectableRows
                    onSelectedRowsChange={handleSelectedRowsChange}
                    clearSelectedRows={cleared}
                />
            </Panel>

            <FlexboxGrid>
                <FlexboxGrid.Item colspan={8}>
                    <ConnectionState cameras={cameras} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                    <PlaceRepartition cameras={cameras} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                    <ErrorFlagGraph cameras={cameras} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};
