import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { match, useHistory, useRouteMatch } from 'react-router';
import { Alert, FlexboxGrid, Loader, Message } from 'rsuite';
import { IpCan } from '../../handlers/ipcan/IpCan';
import { LPDisplay } from '../../handlers/lpDisplays/LPDisplay';
import { getColumn, PixelArtFontImport, PixelArtFontWS, PixelArtFontWSValue } from '../../handlers/pixelArt/pixelArtWS';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { clone } from '../../utils/clone';
import { FontEditor } from './Editor';
import { LeftSideBar } from './LeftSideBar';
import { FontLoader } from './Loader';
import { RightSideBar } from './RightSideBar';

const FONT_TIMEOUT = 10000;

export const PixelArtFontEditor = () => {
    let fontTimeout: ReturnType<typeof setTimeout>;
    let changeWidthTimeout: ReturnType<typeof setTimeout>;

    const routeParams: match<RouteParams> | null = useRouteMatch(
        '/ipCan/:ipCanId/bus/:busNumber/matrix-display/:displayId/font/:size'
    );
    const intl = useIntl();

    const history = useHistory();

    if (!routeParams)
        return <Message full type="error" description={intl.formatMessage({ id: 'pixelArt.fontEditor.error' })} />;

    // PARSE ROUTE PARAMS TO NUMBER
    const busNumber = parseInt(routeParams.params.busNumber, 10);
    const displayId = parseInt(routeParams.params.displayId, 10);
    const size = parseInt(routeParams.params.size, 10);
    const ipCanId = parseInt(routeParams.params.ipCanId, 10);

    // LP MATRIX DISPLAY V2
    const [lpMatrixDisplay, setLPMatrixDisplay] = React.useState<LPDisplay | undefined>(undefined);
    // FONT ARRAY
    const [font, setFont] = React.useState<PixelArtFontWSValue[]>([]);
    // MatrixDisplay
    const [ipCanModule, setIpCanModule] = React.useState<IpCan | undefined>(undefined);
    // SELECTED FONT INDEX
    const [selectedFontIndex, setSelectedFontIndex] = React.useState<number>(0);
    // LOADING STATE
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    // ERROR STATE
    const [hasError, setHasError] = React.useState<boolean>(false);
    // PICTO TO COPY
    const [fontToCopy, setFontToCopy] = React.useState<number>(-1);

    // Websocket connection
    useEffect(() => {
        webSocketService.joinRoom('lp-matrixDisplayV2');

        return function cleanup() {
            webSocketService.offEvent('lp-matrixDisplayV2:readFont', handleWebsocket);
        };
    }, []);

    // // REQUEST MATRIX DISPLAY DATA
    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/devices/lp-matrixdisplays-v2/${displayId}`, {
                headers: authHeader(),
            })
            .then(matrixDisplayResponse => {
                setLPMatrixDisplay(new LPDisplay(matrixDisplayResponse.data, ipCanId, null));
            })
            .catch(err => {
                console.error(err);
            });
    }, [displayId]);

    // REQUEST IPCAN DATA
    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/ipcanmodules/${ipCanId}`, { headers: authHeader() })
            .then(ipCanResponse => {
                setIpCanModule(new IpCan(ipCanResponse.data));
            })
            .catch(err => {
                console.error(err);
            });
    }, [ipCanId]);

    // REQUEST WEBSOCKET DATA
    useEffect(() => {
        readFontFromAPI();
    }, [size]);

    // Websocket handling
    const handleWebsocket = (data: PixelArtFontWS) => {
        if (data.id === displayId) {
            if (fontTimeout) {
                clearTimeout(fontTimeout);
            }

            setFont(data.fontValue.font);

            if (data.fontValue.fontWasComplete) {
                setIsLoading(false);

                webSocketService.offEvent('lp-matrixDisplayV2:readFont', handleWebsocket);
            } else {
                fontTimeout = setTimeout(() => {
                    setIsLoading(false);
                    setHasError(true);
                }, FONT_TIMEOUT);
            }
        }
    };

    const readFontFromAPI = () => {
        setFont([]);
        setIsLoading(true);
        setHasError(false);

        webSocketService.onEvent('lp-matrixDisplayV2:readFont', handleWebsocket);

        setTimeout(() => {
            axiosService.getAxios().put(
                '/devices/lp-matrixdisplays-v2/readFont',
                {
                    id: displayId,
                    fontType: size,
                },
                {
                    headers: authHeader(),
                }
            );

            fontTimeout = setTimeout(() => {
                setIsLoading(false);
                setHasError(true);
            }, FONT_TIMEOUT);
        }, 1000);
    };

    const generateFonts = () => {
        const finalValue: FontValueInterface[] = [];

        font.map(f => {
            const keys = Object.keys(f);
            keys.forEach(key => {
                const columns: boolean[][] = [];
                f[key].forEach(pixel => {
                    columns.push(getColumn(size, BigInt(pixel)));
                });
                finalValue.push({
                    name: key,
                    value: columns,
                });
            });
        });

        return finalValue;
    };

    const handleFontWidthChange = width => {
        if (changeWidthTimeout) {
            clearTimeout(changeWidthTimeout);
        }

        changeWidthTimeout = setTimeout(() => {
            let finalWidth = width;

            if (width > 100) finalWidth = 100;

            if (width < 0) finalWidth = 0;

            const fonts = [...font];
            const currentFont = fonts[selectedFontIndex];

            const keys = Object.keys(currentFont);

            keys.forEach(key => {
                const currentFontValue = currentFont[key];

                const diff = currentFontValue.length - finalWidth;
                // > 0 means we need to remove columns
                if (diff > 0) {
                    currentFontValue.splice(finalWidth, diff);
                }
                // < 0 means we need to add columns
                if (diff < 0) {
                    currentFontValue.push(...Array(Math.abs(diff)).fill('0'));
                }
            });

            setFont(fonts);
        }, 500);
    };

    const handleClick = (column: number, lineValue: number) => {
        const fonts = [...font];
        const currentFont = fonts[selectedFontIndex];

        const keys = Object.keys(currentFont);

        keys.forEach(key => {
            const currentFontValue = BigInt(currentFont[key][column]);

            const lineBitValue = BigInt(Math.pow(2, lineValue));

            let newValue = currentFontValue;

            if ((currentFontValue & BigInt(lineBitValue)) === BigInt(0)) {
                newValue = currentFontValue | BigInt(lineBitValue);
            } else {
                newValue = currentFontValue & BigInt(~lineBitValue);
            }

            currentFont[key][column] = newValue.toString();
        });

        setFont(fonts);
    };

    const handleSaveFont = () => {
        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/writeFont',
                {
                    tabId: [displayId],
                    fontValue: {
                        font: font,
                        fontSize: size,
                    },
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'pixelArt.fontEditor.startSave' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'pixelArt.fontEditor.saveError' }));
            });
    };

    const handleSaveToManyFont = (displays: number[]) => {
        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/writeFont',
                {
                    id: displayId,
                    fontValue: {
                        font: font,
                        fontSize: size,
                    },
                    tabId: displays,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'pixelArt.fontEditor.startSave' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'pixelArt.fontEditor.saveError' }));
            });
    };

    const handleFontUpload = (receivedFont: PixelArtFontImport) => {
        webSocketService.offEvent('lp-matrixDisplayV2:readFont', handleWebsocket);

        setFont(receivedFont.font);
    };

    const handleSizeChange = (size: number) => {
        setSelectedFontIndex(0);
        history.push(`/ipCan/${ipCanId}/bus/${busNumber}/matrix-display/${displayId}/font/${size}`);
    };

    const handleClickCopy = () => {
        setFontToCopy(selectedFontIndex);
    };

    const handleClickPaste = () => {
        const fonts = [...font];

        let keyCurrentFont = Object.keys(fonts[selectedFontIndex])[0];
        let keyFontToCopy = Object.keys(fonts[fontToCopy])[0];

        fonts[selectedFontIndex][keyCurrentFont] = clone(font[fontToCopy][keyFontToCopy]);

        setFont(fonts);
    };

    const handleClickReset = () => {
        const fonts = [...font];

        let keyCurrentFont = Object.keys(fonts[selectedFontIndex])[0];

        fonts[selectedFontIndex][keyCurrentFont].fill('0');

        setFont(fonts);
    };

    return (
        <>
            {/* <Modal backdrop="static" show={font.length === 0 && !hasError} size="sm">
                <Modal.Body style={{ height: '10vh' }}>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item>
                            <Loader size="lg" content={intl.formatMessage({ id: 'pixelArt.fontEditor.loading' })} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>
            </Modal> */}

            {hasError && (
                <Message
                    className="margin-bottom-10"
                    type="error"
                    description={intl.formatMessage({ id: 'pixelArt.fontEditor.error' })}
                    closable
                />
            )}

            {isLoading && <Message description={<Loader center content={<FontLoader fontNb={font.length} />} />} />}

            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={4} className="padding-left-0 padding-right-0">
                    {font.length > 0 && (
                        <LeftSideBar
                            setClickedFontIndex={setSelectedFontIndex}
                            fonts={generateFonts()}
                            selectedFontIndex={selectedFontIndex}
                            fontSize={size}
                            fontToCopy={fontToCopy}
                        />
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    {font.length > 0 && (
                        <FontEditor
                            fonts={generateFonts()}
                            selectedFontIndex={selectedFontIndex}
                            fontSize={size}
                            handleClick={handleClick}
                        />
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={4} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    <RightSideBar
                        fontSize={size}
                        fonts={generateFonts()}
                        selectedFontIndex={selectedFontIndex}
                        isLoading={isLoading}
                        fontsFromApi={font}
                        handleFontWidthChange={handleFontWidthChange}
                        handleFontUpload={handleFontUpload}
                        readFontFromAPI={readFontFromAPI}
                        handleSizeChange={handleSizeChange}
                        handleSaveFont={handleSaveFont}
                        handleSaveToManyFont={handleSaveToManyFont}
                        handleClickCopy={handleClickCopy}
                        handleClickPaste={handleClickPaste}
                        handleClickReset={handleClickReset}
                        isPasteButtonEnabled={fontToCopy >= 0}
                        display={lpMatrixDisplay}
                        ipcan={ipCanModule}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};

type RouteParams = {
    ipCanId: string;
    busNumber: string;
    displayId: string;
    size: string;
};

export interface FontValueInterface {
    name: string;
    value: boolean[][];
}
