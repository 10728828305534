import { faPlusCircle, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';
import { Configuration } from '../../handlers/Configuration/Configuration';
import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';
import PanelHeader from '../Custom/PanelHeader';
import AdvancedConfigurationExpandable from './AdvancedConfigurationExpandable';
import AddConfigurationModal from './Modal/AddConfigurationModal';

export type ConfigurationState = {
    section: string;
    configurations: Configuration[];
    defaultExpand: true;
};

export const AdvancedConfigurationPart = () => {
    const intl = useIntl();

    const [configurations, setConfigurations] = React.useState<ConfigurationState[]>([]);
    const [createModalOpen, setCreateModalOpen] = React.useState(false);

    React.useEffect(() => {
        fetchConfigurations();
    }, []);

    const columns: TableColumn<ConfigurationState>[] = [
        {
            name: intl.formatMessage({ id: 'configuration.section' }),
            center: true,
            cell: row => row.section,
        },
    ];

    const handleCloseConfigModal = () => {
        setCreateModalOpen(false);

        fetchConfigurations();
    };

    const fetchConfigurations = () => {
        axiosService
            .getAxios()
            .get('/configuration', { headers: authHeader() })
            .then(configurationResponse => {
                const fetchedConfigurations = configurationResponse.data.map(config => new Configuration(config));
                const newConfigurations: ConfigurationState[] = [];

                fetchedConfigurations.forEach(config => {
                    const existingConfig = newConfigurations.find(c => c.section === config.getSection());

                    if (existingConfig) {
                        existingConfig.configurations.push(config);
                    } else {
                        newConfigurations.push({
                            section: config.getSection(),
                            configurations: [config],
                            defaultExpand: true,
                        });
                    }
                });

                setConfigurations(newConfigurations);
            });
    };

    const customStyle = {
        header: {
            style: {
                display: 'none',
            },
        },
        headRow: {
            style: {
                display: 'none',
            },
        },
        rows: {
            style: {
                fontSize: '18px',
                fontWeight: '900',
                color: '#FFFFFF',
                backgroundColor: 'rgba(67, 129, 193, 0.7)',
                minHeight: '40px',
            },
        },
        expanderButton: {
            style: {
                color: '#FFF',
                fill: '#FFF',
                backgroundColor: 'transparent',
                borderRadius: '2px',
                transition: '0.25s',
                height: '100%',
                width: '100%',
                // '&:hover:enabled': {
                //     cursor: 'pointer',
                // },
                // '&:disabled': {
                //     color: theme.button.disabled,
                // },
                // '&:hover:not(:disabled)': {
                //     cursor: 'pointer',
                //     backgroundColor: theme.button.hover,
                // },
                // '&:focus': {
                //     outline: 'none',
                //     backgroundColor: theme.button.focus,
                // },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    return (
        <>
            <AddConfigurationModal show={createModalOpen} closeCreateConfigurationModal={handleCloseConfigModal} />
            <Panel
                header={
                    <PanelHeader
                        icon={faTools}
                        title={intl.formatMessage({ id: 'advancedConfiguration.title' })}
                        tagValue={configurations.length}
                        buttons={[
                            <Button
                                color="blue"
                                size="sm"
                                onClick={() => setCreateModalOpen(true)}
                                data-cy="configuration-button-addConfiguration">
                                <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                <FormattedMessage id="advancedConfiguration.addConfiguration" />
                            </Button>,
                        ]}
                    />
                }
                bordered
                shaded
                bodyFill
                className="panel-small"
                data-cy="configuration-advanced-section">
                <DataTable
                    columns={columns}
                    data={configurations}
                    customStyles={customStyle}
                    noHeader
                    expandableRows
                    expandableRowExpanded={row => row.defaultExpand}
                    expandableRowsComponent={AdvancedConfigurationExpandable}
                    expandableRowsComponentProps={{ reloadConfigurations: fetchConfigurations }}
                    // conditionalRowStyles={conditionalRowStyles}
                />
            </Panel>
        </>
    );
};
