import React from 'react';
import { FormattedMessage } from 'react-intl';
import Arrows from '../../../IpCanElementsPage/Elements/Arrow';
import Languages from '../../../IpCanElementsPage/Elements/Language';
import Text from '../../../IpCanElementsPage/Elements/Text';
import Types from '../../../IpCanElementsPage/Elements/Types';

export default function UpdateConfig(option) {
    const { lang, arrow, text, type } = option;

    return (
        <div>
            <div data-cy="historic-lang">
                <FormattedMessage id="tcmSensor.history.lang" />
                <span className="margin-left-5 margin-right-5">-</span>
                <Languages noTag value={lang} />
            </div>
            <div data-cy="historic-text">
                <FormattedMessage id="tcmSensor.history.text" />
                <span className="margin-left-5 margin-right-5">-</span>
                <Text value={text} />
            </div>
            <div data-cy="historic-arrow">
                <FormattedMessage id="tcmSensor.history.arrow" />
                <span className="margin-left-5 margin-right-5">-</span>
                <Arrows history value={arrow} />
            </div>
            <div data-cy="historic-type">
                <FormattedMessage id="tcmSensor.history.type" />
                <span className="margin-left-5 margin-right-5">-</span>
                <Types noTag history value={type} />
            </div>
        </div>
    );
}
