export class HyperParametersCameraConfig {
    private _historySize?: number;
    private _yoloDetIou?: number;
    private _yoloNmsIou?: number;
    private _objectClassWhiteList?: number[];
    private _iouTreshold?: number;
    private _addBlur?: number;

    constructor(config?: HyperParametersConfigInterface) {
        this._historySize = config?.history_size;
        this._yoloDetIou = config?.yolo_det_iou;
        this._yoloNmsIou = config?.yolo_nms_iou;
        this._objectClassWhiteList = config?.object_class_whitelist;
        this._iouTreshold = config?.iou_treshold;
        this._addBlur = config?.add_blur;
    }

    public getHistorySize(): number | undefined {
        return this._historySize;
    }

    public getYoloDetIou(): number | undefined {
        return this._yoloDetIou;
    }

    public getYoloNmsIou(): number | undefined {
        return this._yoloNmsIou;
    }

    public getObjectClassWhiteList(): number[] | undefined {
        return this._objectClassWhiteList;
    }

    public getIouTreshold(): number | undefined {
        return this._iouTreshold;
    }

    public getAddBlur(): number | undefined {
        return this._addBlur;
    }
}

export interface HyperParametersConfigInterface {
    history_size?: number;
    yolo_det_iou?: number;
    yolo_nms_iou?: number;
    iou_treshold?: number;
    object_class_whitelist?: number[];
    add_blur?: number;
}
