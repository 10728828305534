import { IconProp, RotateProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ButtonToolbar, Col, FlexboxGrid, Tag } from 'rsuite';
import { TypeAttributes } from 'rsuite/lib/@types/common';

type Props = {
    buttons?: Array<Record<string, any>>;
    icon?: IconProp;
    iconRotation?: RotateProp;
    title: string | Element;
    tagValue?: string | number;
    tagColor?: TypeAttributes.Color;
    input?: any;
    style?: React.CSSProperties;
};

type State = {};

export default class PanelHeader extends React.Component<Props, State> {
    render() {
        return (
            <FlexboxGrid justify="space-between" align="middle" style={this.props.style}>
                <FlexboxGrid.Item
                    componentClass={Col}
                    colspan={24}
                    md={this.props.buttons && this.props.buttons.length >= 1 ? 12 : 24}
                    xs={12}
                    className="title">
                    {/* Icon */}
                    {typeof this.props.icon !== 'undefined' && (
                        <FontAwesomeIcon
                            icon={this.props.icon}
                            style={{ marginRight: 12 }}
                            rotation={this.props.iconRotation}
                        />
                    )}

                    {/* Title */}
                    {this.props.title}

                    {/* Counter */}
                    {typeof this.props.tagValue !== 'undefined' && (
                        <Tag color={this.props.tagColor || 'orange'} className="tag-panel-header">
                            {this.props.tagValue}
                        </Tag>
                    )}
                </FlexboxGrid.Item>

                {this.props.input && (
                    <FlexboxGrid.Item componentClass={Col} xs={4}>
                        {this.props.input}
                    </FlexboxGrid.Item>
                )}

                {this.props.buttons && (
                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <ButtonToolbar style={{ float: 'right' }}>
                            {this.props.buttons.map((button, index) => {
                                return <span key={index}>{button}</span>;
                            })}
                        </ButtonToolbar>
                    </FlexboxGrid.Item>
                )}
            </FlexboxGrid>
        );
    }
}
