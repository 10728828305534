import { faEllipsisV, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function readRealConfigOptions(option) {
    if (option.error) {
        return <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'orange' }} size="lg" />;
    } else {
        return <FontAwesomeIcon icon={faEllipsisV} size="lg" />;
    }
}
