import { UserInterface } from './User';

export class RestFull {
    private _id: number;
    private _label: string;
    private _createdAt: Date;
    private _createdBy: string;
    private _updatedAt: Date;
    private _updatedBy: string;
    private _isOnline: boolean;
    private _isEnable: boolean;
    private _lastOnline: Date;
    private _path: string;
    private _expirationDate: Date;

    constructor(restFull: RestFullInterface) {
        this._id = restFull.id;
        this._label = restFull.label;
        this._createdAt = restFull.createdAt;
        this._createdBy = restFull.createdBy;
        this._updatedAt = restFull.updatedAt;
        this._updatedBy = restFull.updatedBy;
        this._isOnline = restFull.isOnline;
        this._isEnable = restFull.isEnable;
        this._lastOnline = restFull.lastOnline;
        this._path = restFull.path;
        this._expirationDate = restFull.expirationDate;
    }

    public getId(): number {
        return this._id;
    }

    public getLabel(): string {
        return this._label;
    }

    public getCreatedAt(): Date {
        return this._createdAt;
    }

    public getCreatedBy(): string {
        return this._createdBy;
    }

    public getUpdatedAt(): Date {
        return this._updatedAt;
    }

    public getUpdatedBy(): string {
        return this._updatedBy;
    }

    public getIsOnline(): boolean {
        return this._isOnline;
    }

    public getIsEnable(): boolean {
        return this._isEnable;
    }

    public getLastOnline(): Date {
        return this._lastOnline;
    }

    public getPath(): string {
        return this._path;
    }

    public getExpirationDate(): Date {
        return this._expirationDate;
    }

    public wsUpdate(data: RestFullWSInterface) {
        if (data.id === this._id) {
            this._createdAt = data.createdAt;
            this._createdBy = data.createdBy;
            this._updatedAt = data.updatedAt;
            this._updatedBy = data.updatedBy;
            this._isOnline = data.isOnline;
            this._isEnable = data.isEnable;
            this._lastOnline = data.lastOnline;
            this._path = data.path;
            this._expirationDate = data.expirationDate;
        }
    }
}

export interface RestFullInterface {
    id: number;
    label: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    isOnline: boolean;
    isEnable: boolean;
    lastOnline: Date;
    path: string;
    expirationDate: Date;
}

export interface RestFullWSInterface {
    createdAt: Date;
    createdBy: string;
    expirationDate: Date;
    id: number;
    isEnable: boolean;
    isOnline: boolean;
    label: string;
    lastOnline: Date;
    path: string;
    updatedAt: Date;
    updatedBy: string;
    user: UserInterface;
}
