import { IntlShape } from 'react-intl';

export type SelectPickerData = {
    label: string;
    value: number;
};

export const generateTriggerType = (intl: IntlShape) => {
    return [
        // {
        //     label: intl.formatMessage({
        //         id: 'ipCanDevices.lpDisplays.topology.disabled',
        //     }),
        //     value: 0,
        // },
        {
            label: intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.topology.time',
            }),
            value: 1,
        },
        {
            label: intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.topology.counterMin',
            }),
            value: 2,
        },
        {
            label: intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.topology.counterMax',
            }),
            value: 3,
        },
        {
            label: intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.topology.gpio',
            }),
            value: 4,
        },
        {
            label: intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.topology.gpioInvert',
            }),
            value: 5,
        },
    ];
};

export const generateTriggerCounter = (intl: IntlShape) => {
    let triggerCounter: SelectPickerData[] = [];

    for (let i = 1; i <= 32; i++) {
        triggerCounter.push({
            label: intl.formatMessage(
                {
                    id: 'ipCanDevices.lpDisplays.topology.next.counterNumber',
                },
                {
                    number: i + 1,
                }
            ),
            value: i,
        });
    }

    return triggerCounter;
};

export const generateTriggerNext = (intl: IntlShape) => {
    let triggerNext: SelectPickerData[] = [];

    for (let i = 1; i <= 4; i++) {
        triggerNext.push({
            label: intl.formatMessage(
                {
                    id: 'ipCanDevices.lpDisplays.topology.next.topologyNumber',
                },
                {
                    number: i + 1,
                }
            ),
            value: 100 + i,
        });
    }

    return triggerNext;
};

export const generateGPIOTrigger = (intl: IntlShape) => [
    {
        label: intl.formatMessage({
            id: 'ipCanDevices.lpDisplays.topology.gpio.gpio1',
        }),
        value: 0,
    },
    {
        label: intl.formatMessage({
            id: 'ipCanDevices.lpDisplays.topology.gpio.gpio2',
        }),
        value: 1,
    },
];
