import { faCheck, faCircle, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    CheckPicker,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    List,
    Panel,
    SelectPicker,
    Tag,
} from 'rsuite';
import { LPVirtualDisplayLanguages } from '../../../../../handlers/lpDisplays/Enums/LPVirtualDisplayEnums';
import {
    VirtualDisplayPreviewInformation,
    VirtualDisplayStateEnum,
} from '../../../../../handlers/lpDisplays/VirtualDisplay';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import { hexToRgb } from '../../../../../utils/colorUtils';
import PanelHeader from '../../../../Custom/PanelHeader';
import {
    LPVirtualDisplayFont,
    LPVirtualDisplayLangs,
    LPVirtualDisplaySpeed,
    LPVirtualDisplayState,
} from '../../../LPDisplays/VirtualDisplay/Drawer/Form/LPVirtualDisplayFormValues';
import { LPVirtualDisplayStepColor } from '../../../LPDisplays/VirtualDisplay/Drawer/Form/LPVirtualDisplayStepFormValues';

type Props = {
    virtualDisplay: VirtualDisplayPreviewInformation;
    updateInformation: Function;
};

type FormValue = {
    font: number;
    languages: number[];
    arrowSpeed: number;
    textSpeed: number;
    state: number;
};

export const VirtualDisplayInformationPart = (props: Props) => {
    const intl = useIntl();

    useEffect(() => {
        setFormValue({
            font: props.virtualDisplay.font,
            languages: props.virtualDisplay.language,
            arrowSpeed: props.virtualDisplay.arrowSpeed,
            textSpeed: props.virtualDisplay.textSpeed,
            state: props.virtualDisplay.state,
        });

        setColorFormValue(props.virtualDisplay.backgroundColor);
        setIsEditMode(false);
    }, [props.virtualDisplay]);

    const [formValue, setFormValue] = useState<FormValue>({
        font: props.virtualDisplay.font,
        languages: props.virtualDisplay.language,
        arrowSpeed: props.virtualDisplay.arrowSpeed,
        textSpeed: props.virtualDisplay.textSpeed,
        state: props.virtualDisplay.state,
    });

    const [colorFormValue, setColorFormValue] = useState<string>(props.virtualDisplay.backgroundColor);

    const [isEditing, setIsEditing] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const handleSubmit = () => {
        setIsEditing(true);

        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixDisplays-v2/virtualDisplay/updateConfig',
                {
                    id: props.virtualDisplay.id,
                    backgroundColor: hexToRgb(colorFormValue),
                    startX: props.virtualDisplay.startX,
                    startY: props.virtualDisplay.startY,
                    sizeX: props.virtualDisplay.sizeX,
                    sizeY: props.virtualDisplay.sizeY,
                    font: formValue.font,
                    language: formValue.languages.reduce((acc, curr) => acc + curr),
                    arrowSpeed: formValue.arrowSpeed,
                    textSpeed: formValue.textSpeed,
                    state: formValue.state,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateConfigData => {
                Alert.success(intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.update.success' }));

                props.updateInformation(updateConfigData.data);

                setIsEditMode(false);
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    intl.formatMessage({ id: 'ipCanDevices.lpDisplay.baseConfiguration.update.error' }, { err })
                );
            })
            .finally(() => {
                setIsEditing(false);
            });
    };

    const renderView = () => {
        return (
            <List hover>
                {/* Creation */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.creation" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-creation">
                            {props.virtualDisplay.createdAt} - {props.virtualDisplay.createdBy}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* update */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.update" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-update">
                            {props.virtualDisplay.updatedAt} - {props.virtualDisplay.updatedBy}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* State */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.state" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-state">
                            {props.virtualDisplay.state === VirtualDisplayStateEnum.DISABLED && (
                                <Tag color="red">
                                    <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.state.disabled" />
                                </Tag>
                            )}
                            {props.virtualDisplay.state === VirtualDisplayStateEnum.FORCED_ENABLED && (
                                <Tag color="orange">
                                    <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.state.enabled" />
                                </Tag>
                            )}
                            {props.virtualDisplay.state === VirtualDisplayStateEnum.ENABLED_WAIT_MODBUS && (
                                <Tag color="green">
                                    <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.state.enabledWaitModBus" />
                                </Tag>
                            )}
                            {props.virtualDisplay.state === VirtualDisplayStateEnum.WAIT && (
                                <Tag color="orange">
                                    <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.state.wait" />
                                </Tag>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* COLOR */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.color" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <FontAwesomeIcon
                                data-cy="lpDisplay-baseConfiguration-color"
                                className="margin-right-5"
                                icon={faCircle}
                                color={props.virtualDisplay.backgroundColor}
                            />
                            {props.virtualDisplay.backgroundColor}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* FONT */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.font" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-font">{`MLE_BIG_${props.virtualDisplay.font}`}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* LANGUAGES */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.languages" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-language">
                            {props.virtualDisplay.language.map(lang => (
                                <Tag color="blue" key={`lang-${lang}`}>
                                    {LPVirtualDisplayLanguages(lang)}
                                </Tag>
                            ))}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* TEXT SPEED */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.textSpeed" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-textSpeed">
                            {props.virtualDisplay.textSpeed}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* ARROW SPEED */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.arrowSpeed" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item data-cy="lpDisplay-baseConfiguration-arrowSpeed">
                            {props.virtualDisplay.arrowSpeed}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        );
    };

    const renderForm = () => {
        return (
            <Form formValue={formValue} onChange={value => setFormValue(value as FormValue)} fluid>
                <List hover>
                    {/* STATE */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={12}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.state" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                data-cy="lpDisplay-baseConfiguration-edit-state"
                                componentClass={Col}
                                xs={12}
                                className="padding-top-5 padding-bottom-5">
                                <FormControl
                                    data-cy="lpDisplay-baseConfiguration-edit-state"
                                    className="form-margin"
                                    placement="auto"
                                    name="state"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplayState(intl)}
                                    cleanable={false}
                                    searchable={false}
                                    disabled={isEditing}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div data-cy={`lpDisplay-baseConfiguration-edit-state-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* COLOR */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={12}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.color" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item
                                data-cy="lpDisplay-baseConfiguration-edit-color"
                                componentClass={Col}
                                xs={12}
                                className="padding-top-5 padding-bottom-5">
                                <CompactPicker
                                    colors={LPVirtualDisplayStepColor()}
                                    color={colorFormValue}
                                    onChangeComplete={color => setColorFormValue(color.hex)}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* FONT */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={12}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.font" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-baseConfiguration-edit-font"
                                    className="form-margin"
                                    placement="auto"
                                    name="font"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplayFont()}
                                    cleanable={false}
                                    searchable={false}
                                    disabled={isEditing}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div data-cy={`lpDisplay-baseConfiguration-edit-font-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* LANGUAGES */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={12}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.languages" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-baseConfiguration-edit-language"
                                    className="form-margin"
                                    name="languages"
                                    accepter={CheckPicker}
                                    cleanable={false}
                                    data={LPVirtualDisplayLangs()}
                                    searchable={false}
                                    disabled={isEditing}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div data-cy={`lpDisplay-baseConfiguration-edit-lang-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* TEXT SPEED */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={12}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.textSpeed" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-baseConfiguration-edit-textSpeed"
                                    className="form-margin"
                                    name="textSpeed"
                                    accepter={SelectPicker}
                                    disabled={isEditing}
                                    data={LPVirtualDisplaySpeed()}
                                    searchable={false}
                                    cleanable={false}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div data-cy={`lpDisplay-baseConfiguration-edit-textSpeed-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* ARROW SPEED */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={12}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.baseConfiguration.arrowSpeed" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-baseConfiguration-edit-arrowSpeed"
                                    className="form-margin"
                                    name="arrowSpeed"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplaySpeed()}
                                    disabled={isEditing}
                                    searchable={false}
                                    cleanable={false}
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div data-cy={`lpDisplay-baseConfiguration-edit-arrowSpeed-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* BUTTONS */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="end">
                            <FlexboxGrid.Item>
                                <ButtonGroup>
                                    <Button
                                        data-cy="lpDisplay-baseConfiguration-edit-valid"
                                        color="green"
                                        onClick={() => handleSubmit()}
                                        loading={isEditing}
                                        disabled={formValue.languages.length === 0}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                    <Button
                                        data-cy="lpDisplay-baseConfiguration-edit-cancel"
                                        color="red"
                                        onClick={() => setIsEditMode(false)}
                                        disabled={isEditing}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </ButtonGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                </List>
            </Form>
        );
    };

    return (
        <Panel
            data-cy="lpDisplay-baseConfiguration-card"
            className="panel-small"
            shaded
            bordered
            bodyFill
            header={
                <PanelHeader
                    title={intl.formatMessage({
                        id: 'ipCanDevices.lpDiplay.virtualDisplay.configuration',
                    })}
                    buttons={[
                        <Button
                            data-cy="lpDisplay-baseConfiguration-editMode"
                            appearance="primary"
                            size="sm"
                            color="blue"
                            onClick={() => setIsEditMode(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {isEditMode ? renderForm() : renderView()}
        </Panel>
    );
};
