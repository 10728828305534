import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LatLng } from 'leaflet';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Input,
    InputNumber,
    Modal,
    SelectPicker
} from 'rsuite';
import { PlaceIPCamera } from '../../../handlers/Camera/ApiCamera';
import Camera from '../../../handlers/Camera/Camera';
import PlaceCamera from '../../../handlers/Camera/PlaceCamera';
import { sensorPlaceTypeHandler } from '../../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    coordinated?: LatLng[];
    camera: Camera;
};

type FormValue = {
    label: string;
    labelNumber: string;
    id_sensorPlaceType?: number;
    overstayTime: string;
    nbPlacesToCreate: string;
};

export const CreatePlaceModal = (props: Props) => {
    const intl = useIntl();
    const [sensorPlaceTypes, setSensorPlaceTypes] = React.useState<any[]>([]);
    const [formValue, setFormValue] = React.useState<FormValue>({
        label: '',
        labelNumber: '1',
        overstayTime: '86400',
        nbPlacesToCreate: '1',
        id_sensorPlaceType: 1,
    });
    const [isCreating, setIsCreating] = React.useState<boolean>(false);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/sensor-place-type', { headers: authHeader() })
            .then(response => {
                setSensorPlaceTypes(response.data.map(sensorPlaceType => sensorPlaceTypeHandler(sensorPlaceType)));
            });
    }, []);

    const handleClose = () => {
        props.onClose();
    };

    const handleValid = async () => {
        setIsCreating(true);

        const nbPlacesToCreate = parseInt(formValue.nbPlacesToCreate, 10);
        const labelNumber = parseInt(formValue.labelNumber, 10);
        const coordinates = props.coordinated || [];

        if (coordinates.length === 2 && formValue.id_sensorPlaceType) {
            const placesToCreate: Array<{
                label: string,
                geoJSON: number[],
                id_sensorPlaceType: number,
                overstayTime: number,
                id_ipCamera: number,
            }> = [];

            // get max coordinates (size) with lat and lng
            let lineCoordinateMax = {
                lat: coordinates[1].lat - coordinates[0].lat,
                lng: coordinates[1].lng - coordinates[0].lng,
            }

            // Quantums
            const quantumPlaceLat = lineCoordinateMax.lat / nbPlacesToCreate;
            const quantumPlaceLng = lineCoordinateMax.lng / nbPlacesToCreate;

            let currentPoint = [parseInt((coordinates[0].lat + quantumPlaceLat / 2).toFixed(0)), parseInt((coordinates[0].lng + quantumPlaceLng / 2).toFixed(0))];

            for (let i = 0; i < nbPlacesToCreate; i++) {
                placesToCreate.push({
                    label: `${formValue.label} - ${labelNumber + i}`,
                    geoJSON: currentPoint,
                    id_sensorPlaceType: formValue.id_sensorPlaceType,
                    overstayTime: parseInt(formValue.overstayTime),
                    id_ipCamera: props.camera.getId()
                });

                if (i < nbPlacesToCreate - 1) {
                    currentPoint = [
                        parseInt((currentPoint[0] + quantumPlaceLat).toFixed(0)),
                        parseInt((currentPoint[1] + quantumPlaceLng).toFixed(0)),
                    ];
                }
            }

            let createdPlaces = 0;

            for (let p = 0; p < placesToCreate.length; p++) {
                await axiosService
                    .getAxios()
                    .post<PlaceIPCamera>('/places-camera', placesToCreate[p], { headers: authHeader() })
                    .then(response => {
                        props.camera.addPlaceCamera(new PlaceCamera(response.data));

                        createdPlaces++;

                        if (createdPlaces === placesToCreate.length) {
                            setFormValue({
                                ...formValue,
                                labelNumber: `${labelNumber + nbPlacesToCreate}`,
                            });

                            setTimeout(() => {
                                setIsCreating(false);
                                handleClose();
                            }, 100 * nbPlacesToCreate);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        } else {
            Alert.error(intl.formatMessage({ id: 'camera.place.create.error' }));

            setIsCreating(false);
        }
    };

    const shouldEnableValidButton = (): boolean => {
        return (
            typeof formValue.id_sensorPlaceType !== 'undefined' &&
            typeof props.coordinated !== 'undefined' &&
            formValue.overstayTime.length >= 0 &&
            formValue.label.length >= 0 &&
            !isNaN(parseInt(formValue.labelNumber))
        );
    };

    const handleFormChange = value => {
        setFormValue({
            ...value,
        });
    };

    return (
        <Modal show={props.isOpen} onHide={handleClose} backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="camera.place.create.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form fluid formValue={formValue} onChange={handleFormChange}>
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item colspan={16}>
                            <FormGroup>
                                <ControlLabel>
                                    <FormattedMessage id="camera.place.create.label" />
                                </ControlLabel>
                                <FormControl name="label" />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <FormGroup>
                                <ControlLabel>
                                    <FormattedMessage id="camera.place.create.labelNumber" />
                                </ControlLabel>
                                <FormControl accepter={Input} type="number" name="labelNumber" />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.place.create.nbPlacesToCreate" />
                        </ControlLabel>
                        <FormControl accepter={Input} type="number" name="nbPlacesToCreate" min={1} />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.place.create.sensorPlaceType" />
                        </ControlLabel>
                        <FormControl
                            name="id_sensorPlaceType"
                            accepter={SelectPicker}
                            data={sensorPlaceTypes.map(spt => {
                                return {
                                    label: spt.placeType.label,
                                    value: spt.id,
                                };
                            })}
                            cleanable={false}
                            searchable={false}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="camera.place.create.overstayTime" />
                        </ControlLabel>
                        <FormControl name="overstayTime" accepter={InputNumber} min={0} />
                    </FormGroup>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <FlexboxGrid justify="end" align="middle">
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button color="red" onClick={handleClose} disabled={isCreating}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                            <Button
                                color="green"
                                onClick={handleValid}
                                disabled={!shouldEnableValidButton()}
                                loading={isCreating}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    );
};
