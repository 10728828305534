import { faCheck, faCircle, faCog, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    Divider,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    InputNumber,
    Modal,
    SelectPicker,
} from 'rsuite';
import { LPSensor } from '../../../../../handlers/ipCanDevices/LPSensor';
import TCMSensor from '../../../../../handlers/ipCanDevices/TCMSensor';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import { VehicleCounterExtension } from '../../../../../handlers/vehicleCounter/VehicleCounterExtension';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    show: boolean;
    onHide: Function;
    extension: VehicleCounterExtension;
} & WrappedComponentProps;

type State = {
    ipcans: IpCan[];
    ipcanLoading: boolean;
    sensorsLoading: boolean;
    formValues: FormValues;
    isCreating: boolean;
    sensors_1: { label: string; value: number }[];
    sensors_2: { label: string; value: number }[];
};

type FormValues = {
    label: string;
    type: string;
    ipCanId: number;
    gpio: number;
    timeoutSensor: number;
    bus_sensor_1: number | null;
    ipcanId_sensor_1: number | null;
    bus_sensor_2: number | null;
    ipcanId_sensor_2: number | null;
    lpSensorId1: number | null;
    lpSensorId2: number | null;
    tcmSensorId1: number | null;
    tcmSensorId2: number | null;
};

class EditExtensionModal extends React.Component<Props, State> {
    types: { label: string; value: string }[] = [
        {
            label: 'GPIO',
            value: 'GPIO',
        },
        {
            label: 'LP_SENSOR_1',
            value: 'LP_SENSOR_1',
        },
        {
            label: 'LP_SENSOR_2',
            value: 'LP_SENSOR_2',
        },
        {
            label: 'TCM_SENSOR_1',
            value: 'TCM_SENSOR_1',
        },
        {
            label: 'TCM_SENSOR_2',
            value: 'TCM_SENSOR_2',
        },
    ];

    busSelector: { label: string; value: number }[] = [
        {
            label: this.props.intl.formatMessage({ id: 'map.passCounter.extension.bus1' }),
            value: 1,
        },
        {
            label: this.props.intl.formatMessage({ id: 'map.passCounter.extension.bus2' }),
            value: 2,
        },
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            ipcans: [],
            ipcanLoading: true,
            sensorsLoading: true,
            formValues: {
                label: this.props.extension.getName(),
                type: this.props.extension.getType(),
                ipCanId: this.props.extension.getConfigGpio().ipCanId,
                gpio: this.props.extension.getConfigGpio().gpio,
                timeoutSensor: this.props.extension.getTimeoutSensor(),
                bus_sensor_1: null,
                ipcanId_sensor_1: null,
                bus_sensor_2: null,
                ipcanId_sensor_2: null,
                lpSensorId1: this.props.extension.getLPSensor1() ? this.props.extension.getLPSensor1()!.id : null,
                lpSensorId2: this.props.extension.getLPSensor2() ? this.props.extension.getLPSensor2()!.id : null,
                tcmSensorId1: this.props.extension.getTCMSensor1() ? this.props.extension.getTCMSensor1()!.id : null,
                tcmSensorId2: this.props.extension.getTCMSensor2() ? this.props.extension.getTCMSensor2()!.id : null,
            },
            isCreating: false,
            sensors_1: [],
            sensors_2: [],
        };
    }

    componentDidMount() {
        if (this.props.extension.getType() === 'LP_SENSOR_1' || this.props.extension.getType() === 'LP_SENSOR_2') {
            axiosService
                .getAxios()
                .get(`/devices/lp-sensor/${this.props.extension.getLPSensor1()!.id}`, { headers: authHeader() })
                .then(lpSensorResponse => {
                    const lpSensor = new LPSensor(
                        lpSensorResponse.data,
                        lpSensorResponse.data.ipcanmodule.id,
                        null,
                        null
                    );
                    this.setState({
                        formValues: {
                            ...this.state.formValues,
                            bus_sensor_1: lpSensor.bus + 1,
                            ipcanId_sensor_1: lpSensor.ipCanId,
                        },
                    });

                    axiosService
                        .getAxios()
                        .get(`/ipcanmodules/devices/${lpSensor.ipCanId}/bus/${lpSensor.bus}`, { headers: authHeader() })
                        .then(ipcanModuleResponse => {
                            if (this.state.formValues.type.includes('TCM')) {
                                this.setState({
                                    sensors_1: ipcanModuleResponse.data.tcmSensors.map(sensor => {
                                        return {
                                            label: sensor.deviceId,
                                            value: sensor.id,
                                        };
                                    }),
                                });
                            } else {
                                this.setState({
                                    sensors_1: ipcanModuleResponse.data.lpSensors.map(sensor => {
                                        return {
                                            label: sensor.deviceId,
                                            value: sensor.id,
                                        };
                                    }),
                                });
                            }
                        });
                });
        }

        if (this.props.extension.getType() === 'LP_SENSOR_2') {
            axiosService
                .getAxios()
                .get(`/devices/lp-sensor/${this.props.extension.getLPSensor2()!.id}`, { headers: authHeader() })
                .then(lpSensorResponse => {
                    const lpSensor = new LPSensor(
                        lpSensorResponse.data,
                        lpSensorResponse.data.ipcanmodule.id,
                        null,
                        null
                    );
                    this.setState({
                        formValues: {
                            ...this.state.formValues,
                            bus_sensor_2: lpSensor.bus + 1,
                            ipcanId_sensor_2: lpSensor.ipCanId,
                        },
                    });

                    axiosService
                        .getAxios()
                        .get(`/ipcanmodules/devices/${lpSensor.ipCanId}/bus/${lpSensor.bus}`, { headers: authHeader() })
                        .then(ipcanModuleResponse => {
                            if (this.state.formValues.type.includes('TCM')) {
                                this.setState({
                                    sensors_2: ipcanModuleResponse.data.tcmSensors.map(sensor => {
                                        return {
                                            label: sensor.deviceId,
                                            value: sensor.id,
                                        };
                                    }),
                                });
                            } else {
                                this.setState({
                                    sensors_2: ipcanModuleResponse.data.lpSensors.map(sensor => {
                                        return {
                                            label: sensor.deviceId,
                                            value: sensor.id,
                                        };
                                    }),
                                });
                            }
                        });
                });
        }

        if (this.props.extension.getType() === 'TCM_SENSOR_1' || this.props.extension.getType() === 'TCM_SENSOR_2') {
            axiosService
                .getAxios()
                .get(`/devices/tcm-sensor/${this.props.extension.getTCMSensor1()!.id}`, { headers: authHeader() })
                .then(tcmSensorResponse => {
                    const tcmSensor = new TCMSensor(
                        tcmSensorResponse.data,
                        tcmSensorResponse.data.ipcanmodule.id,
                        null,
                        null
                    );
                    this.setState({
                        formValues: {
                            ...this.state.formValues,
                            bus_sensor_1: tcmSensor.bus + 1,
                            ipcanId_sensor_1: tcmSensor.ipCanId,
                        },
                    });

                    axiosService
                        .getAxios()
                        .get(`/ipcanmodules/devices/${tcmSensor.ipCanId}/bus/${tcmSensor.bus}`, {
                            headers: authHeader(),
                        })
                        .then(ipcanModuleResponse => {
                            if (this.state.formValues.type.includes('TCM')) {
                                this.setState({
                                    sensors_1: ipcanModuleResponse.data.tcmSensors.map(sensor => {
                                        return {
                                            label: sensor.deviceId,
                                            value: sensor.id,
                                        };
                                    }),
                                });
                            } else {
                                this.setState({
                                    sensors_1: ipcanModuleResponse.data.lpSensors.map(sensor => {
                                        return {
                                            label: sensor.deviceId,
                                            value: sensor.id,
                                        };
                                    }),
                                });
                            }
                        });
                });
        }

        if (this.props.extension.getType() === 'TCM_SENSOR_2') {
            axiosService
                .getAxios()
                .get(`/devices/tcm-sensor/${this.props.extension.getTCMSensor2()!.id}`, { headers: authHeader() })
                .then(tcmSensorResponse => {
                    const tcmSensor = new TCMSensor(
                        tcmSensorResponse.data,
                        tcmSensorResponse.data.ipcanmodule.id,
                        null,
                        null
                    );
                    this.setState({
                        formValues: {
                            ...this.state.formValues,
                            bus_sensor_2: tcmSensor.bus + 1,
                            ipcanId_sensor_2: tcmSensor.ipCanId,
                        },
                    });

                    axiosService
                        .getAxios()
                        .get(`/ipcanmodules/devices/${tcmSensor.ipCanId}/bus/${tcmSensor.bus}`, {
                            headers: authHeader(),
                        })
                        .then(ipcanModuleResponse => {
                            if (this.state.formValues.type.includes('TCM')) {
                                this.setState({
                                    sensors_2: ipcanModuleResponse.data.tcmSensors.map(sensor => {
                                        return {
                                            label: sensor.deviceId,
                                            value: sensor.id,
                                        };
                                    }),
                                });
                            } else {
                                this.setState({
                                    sensors_2: ipcanModuleResponse.data.lpSensors.map(sensor => {
                                        return {
                                            label: sensor.deviceId,
                                            value: sensor.id,
                                        };
                                    }),
                                });
                            }
                        });
                });
        }

        this.fetchIPCans();
    }

    gpioTypes = (ipcanModule?: IpCan) => {
        if (!ipcanModule) {
            return [];
        }

        return [
            {
                label: <span><FontAwesomeIcon icon={faCircle} color="green" /> {ipcanModule.getGpioConfig().gpioIn[0].name}</span>,
                value: 0,
            },
            {
                label: <span><FontAwesomeIcon icon={faCircle} color="green" /> {ipcanModule.getGpioConfig().gpioIn[1].name}</span>,
                value: 1,
            },
            {
                label: <span><FontAwesomeIcon icon={faCircle} color="green" /> {ipcanModule.getGpioConfig().gpioIn[2].name}</span>,
                value: 2,
            },
            {
                label: <span><FontAwesomeIcon icon={faCircle} color="green" /> {ipcanModule.getGpioConfig().gpioIn[3].name}</span>,
                value: 3,
            },
            {
                label: <span><FontAwesomeIcon icon={faCircle} color="red" /> {ipcanModule.getGpioConfig().gpioIn[0].name}</span>,
                value: 4,
            },
            {
                label: <span><FontAwesomeIcon icon={faCircle} color="red" /> {ipcanModule.getGpioConfig().gpioIn[1].name}</span>,
                value: 5,
            },
            {
                label: <span><FontAwesomeIcon icon={faCircle} color="red" /> {ipcanModule.getGpioConfig().gpioIn[2].name}</span>,
                value: 6,
            },
            {
                label: <span><FontAwesomeIcon icon={faCircle} color="red" /> {ipcanModule.getGpioConfig().gpioIn[3].name}</span>,
                value: 7,
            },
        ];
    }

    fetchIPCans = () => {
        return axiosService
            .getAxios()
            .get('/ipcanmodules', { headers: authHeader() })
            .then(ipcanResponse => {
                this.setState({
                    ipcans: ipcanResponse.data.map(ipcan => {
                        return new IpCan(ipcan);
                    }),
                    ipcanLoading: false,
                });
            });
    };

    hideModal = (shouldReload: boolean = false) => {
        if (!this.state.isCreating) {
            this.props.onHide(shouldReload);
        }
    };

    fetchSensors = number => {
        if (number === 1) {
            this.setState({
                sensors_1: [],
                formValues: {
                    ...this.state.formValues,
                    lpSensorId1: null,
                    tcmSensorId1: null,
                },
            });
            axiosService
                .getAxios()
                .get(
                    `/ipcanmodules/devices/${this.state.formValues.ipcanId_sensor_1}/bus/${this.state.formValues.bus_sensor_1 && this.state.formValues.bus_sensor_1 - 1
                    }`,
                    { headers: authHeader() }
                )
                .then(ipcanModuleResponse => {
                    if (this.state.formValues.type.includes('TCM')) {
                        this.setState({
                            sensors_1: ipcanModuleResponse.data.tcmSensors.map(sensor => {
                                return {
                                    label: sensor.deviceId,
                                    value: sensor.id,
                                };
                            }),
                        });
                    } else {
                        this.setState({
                            sensors_1: ipcanModuleResponse.data.lpSensors.map(sensor => {
                                return {
                                    label: sensor.deviceId,
                                    value: sensor.id,
                                };
                            }),
                        });
                    }
                });
        } else {
            this.setState({
                sensors_2: [],
                formValues: {
                    ...this.state.formValues,
                    lpSensorId2: null,
                    tcmSensorId2: null,
                },
            });

            axiosService
                .getAxios()
                .get(
                    `/ipcanmodules/devices/${this.state.formValues.ipcanId_sensor_2}/bus/${this.state.formValues.bus_sensor_2 && this.state.formValues.bus_sensor_2 - 1
                    }`,
                    { headers: authHeader() }
                )
                .then(ipcanModuleResponse => {
                    if (this.state.formValues.type.includes('TCM')) {
                        this.setState({
                            sensors_2: ipcanModuleResponse.data.tcmSensors.map(sensor => {
                                return {
                                    label: sensor.deviceId,
                                    value: sensor.id,
                                };
                            }),
                        });
                    } else {
                        this.setState({
                            sensors_2: ipcanModuleResponse.data.lpSensors.map(sensor => {
                                return {
                                    label: sensor.deviceId,
                                    value: sensor.id,
                                };
                            }),
                        });
                    }
                });
        }
    };

    onChange = values => {
        let beforeValues = this.state.formValues;

        this.setState(
            {
                formValues: {
                    label: values.label,
                    type: values.type,
                    ipCanId: parseInt(values.ipCanId),
                    gpio: parseInt(values.gpio),
                    timeoutSensor: parseInt(values.timeoutSensor),
                    bus_sensor_1: values.bus_sensor_1 ? parseInt(values.bus_sensor_1) : null,
                    ipcanId_sensor_1: values.ipcanId_sensor_1 ? parseInt(values.ipcanId_sensor_1) : null,
                    bus_sensor_2: values.bus_sensor_2 ? parseInt(values.bus_sensor_2) : null,
                    ipcanId_sensor_2: values.ipcanId_sensor_2 ? parseInt(values.ipcanId_sensor_2) : null,
                    lpSensorId1: values.lpSensorId1 ? parseInt(values.lpSensorId1) : null,
                    lpSensorId2: values.lpSensorId2 ? parseInt(values.lpSensorId2) : null,
                    tcmSensorId1: values.tcmSensorId1 ? parseInt(values.tcmSensorId1) : null,
                    tcmSensorId2: values.tcmSensorId2 ? parseInt(values.tcmSensorId2) : null,
                },
            },
            () => {
                if (
                    values.bus_sensor_1 &&
                    values.ipcanId_sensor_1 &&
                    values.type &&
                    (beforeValues.bus_sensor_1 !== values.bus_sensor_1 ||
                        beforeValues.ipcanId_sensor_1 !== values.ipcanId_sensor_1 ||
                        beforeValues.type !== values.type)
                ) {
                    this.fetchSensors(1);
                }

                if (
                    values.bus_sensor_2 &&
                    values.ipcanId_sensor_2 &&
                    values.type &&
                    (beforeValues.bus_sensor_2 !== values.bus_sensor_2 ||
                        beforeValues.ipcanId_sensor_2 !== values.ipcanId_sensor_2 ||
                        beforeValues.type !== values.type)
                ) {
                    this.fetchSensors(2);
                }
            }
        );
    };

    handleCreateExtension = () => {
        this.setState({
            isCreating: true,
        });

        const {
            label,
            type,
            ipCanId,
            gpio,
            timeoutSensor,
            lpSensorId1,
            lpSensorId2,
            tcmSensorId1,
            tcmSensorId2,
        } = this.state.formValues;

        let data = {};

        switch (type) {
            case 'GPIO': {
                data = {
                    name: label,
                    sensType: this.props.extension.getSensType(),
                    type: type,
                    configGpio: {
                        ipCanId,
                        gpio,
                    },
                    timeoutSensor: 0,
                    lpSensorId1: 1,
                    lpSensorId2: 2,
                    tcmSensorId1: 1,
                    tcmSensorId2: 2,
                    id: this.props.extension.getId(),
                };
                break;
            }
            case 'LP_SENSOR_1': {
                data = {
                    name: label,
                    sensType: this.props.extension.getSensType(),
                    type: type,
                    configGpio: {
                        ipCanId,
                        gpio,
                    },
                    timeoutSensor: 0,
                    lpSensorId1,
                    lpSensorId2: 2,
                    tcmSensorId1: 1,
                    tcmSensorId2: 2,
                    id: this.props.extension.getId(),
                };
                break;
            }
            case 'LP_SENSOR_2': {
                data = {
                    name: label,
                    sensType: this.props.extension.getSensType(),
                    type: type,
                    configGpio: {
                        ipCanId,
                        gpio,
                    },
                    timeoutSensor,
                    lpSensorId1,
                    lpSensorId2,
                    tcmSensorId1: 1,
                    tcmSensorId2: 2,
                    id: this.props.extension.getId(),
                };
                break;
            }
            case 'TCM_SENSOR_1': {
                data = {
                    name: label,
                    sensType: this.props.extension.getSensType(),
                    type: type,
                    configGpio: {
                        ipCanId,
                        gpio,
                    },
                    timeoutSensor: 0,
                    lpSensorId1: 1,
                    lpSensorId2: 2,
                    tcmSensorId1,
                    tcmSensorId2: 2,
                    id: this.props.extension.getId(),
                };
                break;
            }
            case 'TCM_SENSOR_2': {
                data = {
                    name: label,
                    sensType: this.props.extension.getSensType(),
                    type: type,
                    configGpio: {
                        ipCanId,
                        gpio,
                    },
                    timeoutSensor,
                    lpSensorId1: 1,
                    lpSensorId2: 2,
                    tcmSensorId1,
                    tcmSensorId2,
                    id: this.props.extension.getId(),
                };
                break;
            }
        }

        axiosService
            .getAxios()
            .put('/devices/vehicle-counters/extension', data, { headers: authHeader() })
            .then(() => {
                this.setState({ isCreating: false }, () => {
                    this.hideModal(true);
                });
            })
            .catch(err => {
                this.setState({
                    isCreating: false,
                });
            });
    };

    render() {
        const selectedIpCan = this.state.ipcans.find(ipcan => ipcan.id === this.state.formValues.ipCanId);

        return (
            <Modal backdrop="static" show={this.props.show} onHide={() => this.hideModal()} size="md">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id={'map.passCounter.extension.edit'} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid onChange={this.onChange} formValue={this.state.formValues}>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormGroup>
                                    <ControlLabel>
                                        <FormattedMessage id="map.passCounter.extension.create.label" />
                                    </ControlLabel>
                                    <FormControl
                                        data-cy="map-passCount-editExtension-name"
                                        name="label"
                                        cleanable={false}
                                    />
                                </FormGroup>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormGroup>
                                    <ControlLabel>
                                        <FormattedMessage id="map.passCounter.extension.create.type" />
                                    </ControlLabel>
                                    <FormControl
                                        data-cy="map-passCount-editExtension-type"
                                        name="type"
                                        accepter={SelectPicker}
                                        data={this.types}
                                        cleanable={false}
                                        searchable={false}
                                        renderMenuItem={(label, item) => {
                                            return (
                                                <div data-cy={`extension-edit-type-value-${item.value}`}>{label}</div>
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={24}>
                                <Divider />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        {this.state.formValues.type === 'GPIO' && (
                            <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <FormGroup>
                                        <ControlLabel>
                                            <FormattedMessage id="map.passCounter.extension.create.ipCan" />
                                        </ControlLabel>
                                        <FormControl
                                            data-cy="map-passCount-createExtension-ipCan"
                                            name="ipCanId"
                                            accepter={SelectPicker}
                                            data={this.state.ipcans.map(ipcan => {
                                                return {
                                                    label: `${ipcan.getLabel()} (${ipcan.getMacStr()})`,
                                                    value: ipcan.getId(),
                                                };
                                            })}
                                            cleanable={false}
                                            renderMenu={menu => {
                                                if (this.state.ipcans.length === 0) {
                                                    return (
                                                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                            <FontAwesomeIcon icon={faCog} spin />
                                                        </p>
                                                    );
                                                }
                                                return menu;
                                            }}
                                        />
                                    </FormGroup>
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <FormGroup>
                                        <ControlLabel>
                                            <FormattedMessage id="map.passCounter.extension.create.gpio" />
                                        </ControlLabel>
                                        <FormControl
                                            data-cy="map-passCount-editExtension-gpioList"
                                            name="gpio"
                                            accepter={SelectPicker}
                                            data={this.gpioTypes(selectedIpCan)}
                                            cleanable={false}
                                            renderMenuItem={(label, item) => {
                                                return (
                                                    <div data-cy={`extension-edit-gpio-value-${item.value}`}>
                                                        {label}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        )}

                        {this.state.formValues.type !== 'GPIO' && (
                            <Fragment>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                        <FormGroup>
                                            <ControlLabel>
                                                <FormattedMessage id="map.passCounter.extension.create.ipcanId_sensor_1" />
                                            </ControlLabel>
                                            <FormControl
                                                data-cy="map-passCount-editExtension-ipCanId1"
                                                name="ipcanId_sensor_1"
                                                accepter={SelectPicker}
                                                data={this.state.ipcans.map(ipcan => {
                                                    return {
                                                        label: `${ipcan.getLabel()} (${ipcan.getMacStr()})`,
                                                        value: ipcan.getId(),
                                                    };
                                                })}
                                                cleanable={false}
                                                className="form-margin"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`extension-edit-ipCanId-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                        <FormGroup>
                                            <ControlLabel>
                                                <FormattedMessage id="map.passCounter.extension.create.bus_sensor_1" />
                                            </ControlLabel>
                                            <FormControl
                                                data-cy="map-passCount-editExtension-busSensor1"
                                                name="bus_sensor_1"
                                                accepter={SelectPicker}
                                                data={this.busSelector}
                                                searchable={false}
                                                cleanable={false}
                                                className="form-margin"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`extension-edit-bus-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                        <FormGroup>
                                            <ControlLabel>
                                                <FormattedMessage id="map.passCounter.extension.create.sensor_1" />
                                            </ControlLabel>
                                            <FormControl
                                                data-cy="map-passCount-editExtension-idSensor1"
                                                name={
                                                    this.state.formValues.type.includes('TCM')
                                                        ? 'tcmSensorId1'
                                                        : 'lpSensorId1'
                                                }
                                                accepter={SelectPicker}
                                                data={this.state.sensors_1}
                                                cleanable={false}
                                                className="form-margin"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`extension-edit-sensor-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </Fragment>
                        )}

                        {this.state.formValues.type.includes('2') && (
                            <Fragment>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                                        <Divider />
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                        <FormGroup>
                                            <ControlLabel>
                                                <FormattedMessage id="map.passCounter.extension.create.ipcanId_sensor_2" />
                                            </ControlLabel>
                                            <FormControl
                                                data-cy="map-passCount-editExtension-ipCanId2"
                                                name="ipcanId_sensor_2"
                                                accepter={SelectPicker}
                                                data={this.state.ipcans.map(ipcan => {
                                                    return {
                                                        label: `${ipcan.getLabel()} (${ipcan.getMacStr()})`,
                                                        value: ipcan.getId(),
                                                    };
                                                })}
                                                cleanable={false}
                                                className="form-margin"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`extension-edit-ipCanId2-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                        <FormGroup>
                                            <ControlLabel>
                                                <FormattedMessage id="map.passCounter.extension.create.bus_sensor_2" />
                                            </ControlLabel>
                                            <FormControl
                                                data-cy="map-passCount-editExtension-busSensor2"
                                                name="bus_sensor_2"
                                                accepter={SelectPicker}
                                                data={this.busSelector}
                                                searchable={false}
                                                cleanable={false}
                                                className="form-margin"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`extension-edit-bus2-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                        <FormGroup>
                                            <ControlLabel>
                                                <FormattedMessage id="map.passCounter.extension.create.sensor_2" />
                                            </ControlLabel>
                                            <FormControl
                                                data-cy="map-passCount-editExtension-idSensor2"
                                                name={
                                                    this.state.formValues.type.includes('TCM')
                                                        ? 'tcmSensorId2'
                                                        : 'lpSensorId2'
                                                }
                                                accepter={SelectPicker}
                                                data={this.state.sensors_2}
                                                cleanable={false}
                                                className="form-margin"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`extension-edit-sensor2-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                <FlexboxGrid justify="center">
                                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                                        <Divider />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={6}>
                                        <FormGroup>
                                            <ControlLabel>
                                                <FormattedMessage id="map.passCounter.extension.create.sensor_timeout" />
                                            </ControlLabel>
                                            <FormControl
                                                data-cy="map-passCount-editExtension-timeOutSensor"
                                                name="timeoutSensor"
                                                accepter={InputNumber}
                                                cleanable={false}
                                                className="form-margin"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`extension-edit-timeout-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                                        <Divider />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </Fragment>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.hideModal()}
                            data-cy="map-passCount-editExtension-cancel"
                            color="red"
                            disabled={this.state.isCreating}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.handleCreateExtension}
                            data-cy="map-passCount-editExtension-valid"
                            appearance="primary"
                            loading={this.state.isCreating}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(EditExtensionModal);
