import React from 'react';
import { FormattedMessage } from 'react-intl';
import PlaceType from '../../../../handlers/PlaceType';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    option: Record<string, any>;
};

type State = {
    generic: Array<Record<string, any>>;
    pmr: Array<Record<string, any>>;
    family: Array<Record<string, any>>;
    electrical: Array<Record<string, any>>;
    isLoading: boolean;
};

export default class UpdateCountersAssign extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            generic: [],
            pmr: [],
            family: [],
            electrical: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get('/place-type', { headers: authHeader() })
            .then(response =>
                response.data.map(placeType => {
                    const type = new PlaceType(placeType);

                    return type.getPlaceType();
                })
            )
            .then(placesTypes => {
                const { generic, pmr, family, electrical } = this.props.option.configType;

                let newGenericArray = generic.map(g => placesTypes.find(pt => pt.id === g));
                let newPmrArray = pmr.map(g => placesTypes.find(pt => pt.id === g));
                let newFamilyArray = family.map(g => placesTypes.find(pt => pt.id === g));
                let newElectrocalArray = electrical.map(g => placesTypes.find(pt => pt.id === g));

                this.setState({
                    isLoading: false,
                    generic: newGenericArray,
                    pmr: newPmrArray,
                    family: newFamilyArray,
                    electrical: newElectrocalArray,
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <div>
                <div data-cy="historic-generic">
                    <FormattedMessage id="ipCanDevices.display.configType.generic" /> :{' '}
                    {this.state.generic.map(g => {
                        return <span key={`generic-${g.label}`}>{g.label}</span>;
                    })}
                </div>
                <div data-cy="historic-pmr">
                    <FormattedMessage id="ipCanDevices.display.configType.pmr" /> :{' '}
                    {this.state.pmr.map(p => {
                        return <span key={`pmr-${p.label}`}>{p.label}</span>;
                    })}
                </div>
                <div data-cy="historic-family">
                    <FormattedMessage id="ipCanDevices.display.configType.family" /> :{' '}
                    {this.state.family.map(f => {
                        return <span key={`family-${f.label}`}>{f.label}</span>;
                    })}
                </div>
                <div data-cy="historic-electrical">
                    <FormattedMessage id="ipCanDevices.display.configType.electrical" /> :{' '}
                    {this.state.electrical.map(e => {
                        return <span key={`electrical-${e.label}`}>{e.label}</span>;
                    })}
                </div>
            </div>
        );
    }
}
