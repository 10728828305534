import { faArrowAltCircleUp, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, FlexboxGrid, List, Panel } from 'rsuite';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import RestartModal from './Modal/RestartModal';
import UpdateModal from './Modal/UpdateModal';

type Props = {};

type State = {
    showUpdateModal: boolean;
    showRestartModal: boolean;
    showRestartingModal: boolean;
};

export class ConfigurationActions extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            showUpdateModal: false,
            showRestartModal: false,
            showRestartingModal: false,
        };

        this.showUpdateModal = this.showUpdateModal.bind(this);
        this.closeUpdateModal = this.closeUpdateModal.bind(this);

        this.showRestartModal = this.showRestartModal.bind(this);
        this.closeRestartModal = this.closeRestartModal.bind(this);
    }

    showUpdateModal() {
        this.setState({ showUpdateModal: true });
    }

    closeUpdateModal(displayRestarting) {
        this.setState({ showUpdateModal: false, showRestartingModal: displayRestarting });
    }

    showRestartModal() {
        this.setState({ showRestartModal: true });
    }

    closeRestartModal() {
        this.setState({ showRestartModal: false });
    }

    render() {
        return (
            <Fragment>
                <Panel bodyFill className="panel-small" shaded header={<FormattedMessage id="configuration.actions" />}>
                    <List>
                        <SecuredFragment authorizedRoles={[rolesConstants.configuration.UPDATE_SYSTEM]}>
                            <List.Item key="configuration.update" index={0} className="panel-list">
                                <FlexboxGrid align="middle">
                                    <FlexboxGrid.Item className="bold" componentClass={Col} colspan={24} xs={16}>
                                        <FormattedMessage id="configuration.action.update" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} colspan={24} xs={8}>
                                        <Button size="sm" color="orange" block onClick={this.showUpdateModal}>
                                            <FontAwesomeIcon icon={faArrowAltCircleUp} />
                                        </Button>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        </SecuredFragment>
                        <SecuredFragment authorizedRoles={[rolesConstants.configuration.REBOOT_AUTOMATE]}>
                            <List.Item key="configuration.restart" index={1} className="panel-list">
                                <FlexboxGrid align="middle">
                                    <FlexboxGrid.Item className="bold" componentClass={Col} colspan={24} xs={16}>
                                        <FormattedMessage id="configuration.action.restart" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} colspan={24} xs={8}>
                                        <Button size="sm" color="red" block onClick={this.showRestartModal}>
                                            <FontAwesomeIcon icon={faPowerOff} />
                                        </Button>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        </SecuredFragment>
                    </List>
                </Panel>

                <UpdateModal show={this.state.showUpdateModal} closeUpdateModal={this.closeUpdateModal} />
                <RestartModal show={this.state.showRestartModal} closeRestartModal={this.closeRestartModal} />
            </Fragment>
        );
    }
}
