import { faCheck, faEdit, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Col, ControlLabel, FlexboxGrid, Form, FormControl, Panel, SelectPicker } from 'rsuite';
import { sensorPlaceTypeActions } from '../../../../redux/actions';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';

const mapDispatchToProps = dispatch => ({
    update: (id, label, description, placeType) =>
        dispatch(sensorPlaceTypeActions.update(id, label, description, placeType)),
});

type Props = {
    sensorPlaceType: Record<string, any>;
    update: Function;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    editMode: boolean;
    formValue: Record<string, any>;
    placeTypes: Array<Record<string, any>>;
    loading: boolean;
};

class SensorPlaceTypeInformations extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            formValue: {
                label: this.props.sensorPlaceType.label,
                description: this.props.sensorPlaceType.description,
                placeTypeId: this.props.sensorPlaceType.placeType.id,
                type: this.props.sensorPlaceType.type,
                createdAt: this.props.sensorPlaceType.createdAt,
                createdBy: this.props.sensorPlaceType.createdBy,
                updatedAt: this.props.sensorPlaceType.updatedAt,
                updatedBy: this.props.sensorPlaceType.updatedBy,
            },
            placeTypes: [],
            loading: false,
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.unsetEditMode = this.unsetEditMode.bind(this);
        this.cancelEditMode = this.cancelEditMode.bind(this);
        this.setFormValue = this.setFormValue.bind(this);
        this.updateSensorPlaceType = this.updateSensorPlaceType.bind(this);
        this.handleFetchPlaceType = this.handleFetchPlaceType.bind(this);
    }

    componentDidMount() {
        this.handleFetchPlaceType();
    }

    handleFetchPlaceType() {
        if (this.state.placeTypes.length === 0) {
            axiosService
                .getAxios()
                .get('/place-type', { headers: authHeader() })
                .then(response =>
                    response.data.map(placeType => {
                        return {
                            label: placeType.label,
                            value: placeType.id,
                        };
                    })
                )
                .then(placeTypes => {
                    this.setState({
                        placeTypes,
                    });
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    setEditMode() {
        this.setState({
            editMode: true,
        });
    }

    cancelEditMode() {
        this.setState({
            editMode: false,
            formValue: {
                label: this.props.sensorPlaceType.label,
                description: this.props.sensorPlaceType.description,
                placeTypeId: this.props.sensorPlaceType.placeType.id,
                type: this.props.sensorPlaceType.type,
                createdAt: this.props.sensorPlaceType.createdAt,
                createdBy: this.props.sensorPlaceType.createdBy,
                updatedAt: this.props.sensorPlaceType.updatedAt,
                updatedBy: this.props.sensorPlaceType.updatedBy,
            },
        });
    }

    unsetEditMode() {
        this.setState({
            editMode: false,
        });
    }

    updateSensorPlaceType() {
        this.unsetEditMode();
        this.props.update(
            this.props.sensorPlaceType.id,
            this.state.formValue.label,
            this.state.formValue.description,
            this.state.formValue.placeTypeId
        );
    }

    setFormValue(formValue) {
        this.setState({
            formValue,
        });
    }

    render() {
        return (
            <Loader show={this.props.isLoading}>
                <Panel
                    shaded
                    bordered
                    className="panel-small"
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'sensorPlaceType.drawer.information' })}
                            buttons={[
                                <SecuredFragment
                                    key="sensorPlaceType.edit"
                                    authorizedRoles={[rolesConstants.sensorPlaceType.UPDATE]}>
                                    <Button
                                        data-cy="sensorPlaceType-editButton"
                                        onClick={this.setEditMode}
                                        appearance="primary"
                                        size="sm">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }>
                    <Form
                        formValue={this.state.formValue}
                        onChange={formValue => this.setFormValue(formValue)}
                        fluid
                        style={{ marginTop: 10 }}>
                        <FlexboxGrid align="middle" justify="end">
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="sensorPlaceType.edit.label" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="sensorPlaceType-edit-label"
                                    size="sm"
                                    name="label"
                                    plaintext={!this.state.editMode}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="sensorPlaceType.edit.description" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="sensorPlaceType-edit-description"
                                    size="sm"
                                    name="description"
                                    plaintext={!this.state.editMode}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="sensorPlaceType.edit.type" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    block
                                    data-cy="sensorPlaceType-edit-type"
                                    name="placeTypeId"
                                    accepter={SelectPicker}
                                    data={this.state.placeTypes}
                                    renderMenu={menu => {
                                        if (this.state.placeTypes.length === 0) {
                                            return (
                                                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                </p>
                                            );
                                        }
                                        return menu;
                                    }}
                                    plaintext={!this.state.editMode}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="sensorPlaceType.edit.createdAt" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="sensorPlaceType-edit-createdAt"
                                    size="sm"
                                    name="createdAt"
                                    plaintext={true}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="sensorPlaceType.edit.createdBy" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="sensorPlaceType-edit-createdBy"
                                    size="sm"
                                    name="createdBy"
                                    plaintext={true}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="sensorPlaceType.edit.updatedAt" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="sensorPlaceType-edit-updatedAt"
                                    size="sm"
                                    name="updatedAt"
                                    plaintext={true}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="sensorPlaceType.edit.updatedBy" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="sensorPlaceType-edit-updateBy"
                                    size="sm"
                                    name="updatedBy"
                                    plaintext={true}
                                />
                            </FlexboxGrid.Item>
                            {this.state.editMode && (
                                <FlexboxGrid.Item>
                                    <ButtonGroup>
                                        <Button
                                            data-cy="sensorPlaceType-edit-cancel"
                                            color="red"
                                            onClick={this.cancelEditMode}
                                            disabled={this.state.loading}>
                                            <FontAwesomeIcon icon={faTimesCircle} />
                                        </Button>
                                        <Button
                                            data-cy="sensorPlaceType-edit-valid"
                                            color="green"
                                            onClick={this.updateSensorPlaceType}
                                            loading={this.state.loading}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </Button>
                                    </ButtonGroup>
                                </FlexboxGrid.Item>
                            )}
                        </FlexboxGrid>
                    </Form>
                </Panel>
            </Loader>
        );
    }
}

function mapStateToProps(state) {
    let { isLoading } = state.sensorPlaceTypes;

    return { isLoading };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SensorPlaceTypeInformations));
