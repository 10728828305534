export class LPDisplayTextConfig {
    private _entries: string[][];
    private _userText: string[];

    constructor(textConfig: LPDisplayTextConfigInterface) {
        let entries = Array(32).fill(Array(4).fill(''));

        textConfig.dictionnaryEntrys.entrys.forEach((entry, entryIndex) => (entries[entryIndex] = entry.text));

        this._entries = entries;

        this._userText = [...textConfig.userTexts.texts];
    }

    public getEntries() {
        return this._entries;
    }

    public getUserText() {
        return this._userText;
    }

    public getSystemTextEntries() {
        const texts: { index: number; lang0: string; lang1: string; lang2: string; lang3: string }[] = [];

        for (let i = 0; i < 32; i++) {
            texts.push({
                index: i,
                lang0: this._entries[i][0],
                lang1: this._entries[i][1],
                lang2: this._entries[i][2],
                lang3: this._entries[i][3],
            });
        }

        return texts;
    }

    public updateUserText(userTexts: string[]) {
        this._userText = userTexts;
    }

    public updateSystemText(
        systemTexts: {
            text: string[];
        }[]
    ) {
        this._entries = systemTexts.map(entry => entry.text);
    }

    public websocketUpdateLPDisplayTextConfig(textConfig_: LPDisplayTextConfigInterface): boolean {
        let entries = Array(32).fill(Array(4).fill(''));

        textConfig_.dictionnaryEntrys.entrys.forEach((entry, entryIndex) => (entries[entryIndex] = entry.text));

        this._entries = entries;
        this._userText = [...textConfig_.userTexts.texts];

        return true;
    }
}

export interface LPDisplayTextConfigInterface {
    dictionnaryEntrys: {
        entrys: {
            text: string[];
        }[];
    };
    userTexts: {
        texts: string[];
    };
}
