import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    show: boolean;
    onHide: () => void;
    ipcan: IpCan;
    bus: number;
};

export const SendAllConfigModal = (props: Props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const intl = useIntl();

    const handleClickValid = () => {
        setIsLoading(true);

        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/cmdLPmatrixDisplayV2',
                {
                    id: props.ipcan?.id,
                    bus: props.bus,
                    cmd: 'sendAllDevicesConfig',
                },
                { headers: authHeader() }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'ipcan.actions.sendAllDevicesConfig.success' }));

                setIsLoading(false);

                props.onHide();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'ipcan.actions.sendAllDevicesConfig.error' }));

                setIsLoading(false);
            });
    };

    const handleClickCancel = () => {
        props.onHide();
    };

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="ipcan.actions.sendAllDevicesConfig.title" />
                </Modal.Title>
                <Modal.Body>
                    <FormattedMessage id="ipcan.actions.sendAllDevicesConfig.body" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={handleClickCancel} color="red" disabled={isLoading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button onClick={handleClickValid} appearance="primary" loading={isLoading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Header>
        </Modal>
    );
};
