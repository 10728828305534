import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Drawer } from 'rsuite';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import CounterConfiguration from './CounterConfiguration';
import { MapTCMDeviceStateInformation } from './DeviceStateInformation/MapTCMDeviceStateInformation';
import DisplayConfigPart from './DisplayConfigPart';
import EditDisplayPart from './EditDisplayPart';
import TypeConfiguration from './TypeConfiguration';

type Props = {
    show: boolean;
    onHide: Function;
    display: any;
    controllers: IpCan[];
    service: any;
    placeTypes: any[];
} & WrappedComponentProps;

type State = {
    generic: number[];
    pmr: number[];
    family: number[];
    electrical: number[];
};

class TCMDisplayDrawer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    hideDrawer = () => {
        this.props.onHide();
    };

    render() {
        const currentDisplay = this.props.display.tcmDisplay;

        const currentIpCan = this.props.controllers.find(controller => controller.id === currentDisplay.ipcanmodule.id);

        return (
            <Drawer backdrop={false} show={this.props.show} onHide={this.hideDrawer} className="drawer-no-footer">
                <Drawer.Header>
                    <Drawer.Title>
                        {currentIpCan?.label} - {currentDisplay.bus + 1} - {currentDisplay.deviceId}
                    </Drawer.Title>
                </Drawer.Header>

                <Drawer.Body>
                    <EditDisplayPart
                        controllers={this.props.controllers}
                        display={this.props.display}
                        currentIpCan={currentIpCan}
                        service={this.props.service}
                    />

                    <MapTCMDeviceStateInformation device={this.props.display} service={this.props.service} />

                    <DisplayConfigPart display={this.props.display} service={this.props.service} />

                    <TypeConfiguration
                        display={this.props.display}
                        service={this.props.service}
                        placeTypes={this.props.placeTypes}
                    />

                    <CounterConfiguration display={this.props.display} service={this.props.service} />
                </Drawer.Body>
            </Drawer>
        );
    }
}

export default injectIntl(TCMDisplayDrawer);
