import { faCircle, faCogs, faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';

export default function TcmCmdBus(options) {
    const { bus, cmd } = options;

    let sensorClass = '';
    let force = false;

    switch (cmd) {
        case 'forceAllSensorToBlack':
            sensorClass = 'sensor-black';
            force = true;
            break;
        case 'forceAllSensorToGreen':
            sensorClass = 'sensor-green';
            force = true;
            break;
        case 'forceAllSensorToRed':
            sensorClass = 'sensor-red';
            force = true;
            break;
        case 'forceAllSensorToRedGreen':
            sensorClass = 'sensor-red-green';
            force = true;
            break;
        case 'forceAllSensorToRedBlink':
            sensorClass = 'sensor-red-blink';
            force = true;
            break;
        case 'forceAllSensorToGreenBlink':
            sensorClass = 'sensor-green-blink';
            force = true;
            break;
        case 'disableforcedAllSensor':
            sensorClass = 'sensor-purple';
            force = true;
            break;
        default:
            sensorClass = '';
            force = false;
    }

    if (force) {
        return (
            <Fragment>
                <FormattedMessage id="controller.history.testBus.options.bus" />
                <Tag className="margin-left-5" color="violet">
                    {bus + 1}
                </Tag>
                <span className="margin-left-5">-</span>
                <div data-cy="history-forceColor">
                    <FontAwesomeIcon className={`margin-left-5 ${sensorClass}`} icon={faCircle} size="lg" />
                </div>
            </Fragment>
        );
    } else if (cmd === 'sendAllDevicesConfig') {
        return (
            <Fragment>
                <FormattedMessage id="controller.history.testBus.options.bus" />
                <Tag className="margin-left-5" color="violet">
                    {bus}
                </Tag>
                <span className="margin-left-5">-</span>
                <div data-cy="history-sendAllDevicesConfig">
                    <FontAwesomeIcon className="margin-left-5" icon={faCogs} size="lg" />
                </div>
            </Fragment>
        );
    } else if (cmd === 'clearBus') {
        return (
            <Fragment>
                <FormattedMessage id="controller.history.testBus.options.bus" />
                <Tag className="margin-left-5" color="violet">
                    {bus}
                </Tag>
                <span className="margin-left-5">-</span>
                <div data-cy="history-clearBus">
                    <FontAwesomeIcon className="margin-left-5" icon={faEraser} size="lg" />
                </div>
            </Fragment>
        );
    } else {
        return null;
    }
}
