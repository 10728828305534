import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Tag } from 'rsuite';
import ForceType from '../../../IpCanElementsPage/Elements/ForceType';

type Props = {
    intl: Record<string, any>;
    option: Record<string, any>;
} & WrappedComponentProps;

class ForceDisplay extends React.Component<Props> {
    constructor(props) {
        super(props);

        this.generateForceType = this.generateForceType.bind(this);
    }

    generateForceType(forceType) {
        switch (forceType) {
            case 0:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.noForce',
                });
            case 1:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.free',
                });
            case 2:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.occupied',
                });
            case 3:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.overstayFree',
                });
            case 4:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.overstayOccupied',
                });
            case 11:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.green',
                });
            case 12:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.red',
                });
            case 13:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.black',
                });
            case 14:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.greenBlink',
                });
            case 15:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.redBlink',
                });
            case 17:
                return this.props.intl.formatMessage({
                    id: 'sensor.force.redGreenBlink',
                });
            default:
                return null;
        }
    }

    render() {
        return (
            <div>
                <div data-cy="historic-tcmDisplay-isForce">
                    <FormattedMessage id="tcmSensor.history.isForce" />
                    <span className="margin-left-5">-</span>
                    <FontAwesomeIcon
                        className="margin-left-5"
                        icon={this.props.option.isForce ? faCheck : faTimes}
                        color={this.props.option.isForce ? 'green' : 'red'}
                    />
                </div>
                <div data-cy="historic-tcmDisplay-forceState">
                    <FormattedMessage id="tcmSensor.history.forceState" />
                    <span className="margin-left-5">-</span>
                    <span className="margin-left-5">
                        <ForceType value={this.props.option.forceState} noTag />
                    </span>
                </div>

                {(this.props.option.forceState.forceType === 1 || this.props.option.forceState.forceType === 2) && (
                    <div>
                        <Tag componentClass="span" color="green">
                            {this.props.option.forceState.generic}
                        </Tag>
                        <Tag componentClass="span" color="blue">
                            {this.props.option.forceState.pmr}
                        </Tag>
                        <Tag componentClass="span" color="cyan">
                            {this.props.option.forceState.electrical}
                        </Tag>
                        <Tag componentClass="span" color="violet">
                            {this.props.option.forceState.family}
                        </Tag>
                    </div>
                )}

                <div data-cy="historic-tcmDisplay-endForceTime">
                    <FormattedMessage id="tcmSensor.history.endForceTime" />
                    <span className="margin-left-5">-</span>
                    <span className="margin-left-5">{this.props.option.endForceTime}</span>
                </div>
            </div>
        );
    }
}

export default injectIntl(ForceDisplay);
