import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Input,
    Schema,
    Toggle,
} from 'rsuite';
import { IPCameraAPIResponse } from '../../../../handlers/Camera/ApiCamera';
import Camera from '../../../../handlers/Camera/Camera';
import { Configuration } from '../../../../handlers/Configuration/Configuration';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    camera: Camera;
    cancelEdit: () => void;
    configurations: Configuration[];
};

type FormValue = {
    historySizeEnabled: boolean;
    historySize?: string;
    iouTresholdEnabled: boolean;
    iouTreshold?: string;
    // objectClassWhiteListEnabled: boolean;
    // objectClassWhiteList?: number[];
    yoloDetIouEnabled: boolean;
    yoloDetIou?: string;
    yoloNmsIouEnabled: boolean;
    yoloNmsIou?: string;
    addBlurEnabled: boolean;
    addBlur?: string;
};

export const EditHyperParamCameraModal = (props: Props) => {
    const intl = useIntl();

    const [formValue, setFormValue] = useState<FormValue>({
        historySizeEnabled: false,
        iouTresholdEnabled: false,
        // objectClassWhiteListEnabled: false,
        yoloDetIouEnabled: false,
        yoloNmsIouEnabled: false,
        addBlurEnabled: false,
    });

    const [formError, setFormError] = useState<any>({});

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const initHistorySize = (): string | undefined => {
        const cameraHistorySize = props.camera.getConfig().getHistorySize();

        if (cameraHistorySize) {
            return cameraHistorySize.toString();
        }

        const foundConfiguration = props.configurations.find(
            configuration => configuration.getKey() === 'history_size'
        );

        if (foundConfiguration) {
            return foundConfiguration.getValue();
        }

        return '';
    };

    const initIouTreshold = (): string | undefined => {
        const cameraIouTreshold = props.camera.getConfig().getIouTreshold();

        if (cameraIouTreshold) {
            return cameraIouTreshold.toString();
        }

        const foundConfiguration = props.configurations.find(
            configuration => configuration.getKey() === 'iou_treshold'
        );

        if (foundConfiguration) {
            return foundConfiguration.getValue();
        }

        return '';
    };

    // const initObjectClassWhiteList = (): number[] | undefined => {
    //     const cameraObjectClassWhiteList = props.camera
    //         .getConfig()
    //         .getObjectClassWhiteList()
    //         ?.filter(value => value === 0);

    //     if (cameraObjectClassWhiteList) {
    //         return cameraObjectClassWhiteList;
    //     }

    //     const foundConfiguration = props.configurations.find(
    //         configuration => configuration.getKey() === 'object_class_whitelist'
    //     );

    //     if (foundConfiguration) {
    //         return JSON.parse(foundConfiguration.getValue()).filter(value => value === 0);
    //     }
    // };

    const initYoloDetIou = (): string | undefined => {
        const cameraYoloDetIou = props.camera.getConfig().getYoloDetIou();

        if (cameraYoloDetIou) {
            return cameraYoloDetIou.toString();
        }

        const foundConfiguration = props.configurations.find(
            configuration => configuration.getKey() === 'yolo_det_iou'
        );

        if (foundConfiguration) {
            return foundConfiguration.getValue();
        }
    };

    const initYoloNmsIou = (): string | undefined => {
        const cameraYoloNmsIou = props.camera.getConfig().getYoloNmsIou();

        if (cameraYoloNmsIou) {
            return cameraYoloNmsIou.toString();
        }

        const foundConfiguration = props.configurations.find(
            configuration => configuration.getKey() === 'yolo_nms_iou'
        );

        if (foundConfiguration) {
            return foundConfiguration.getValue();
        }
    };

    const initAddBlur = (): string | undefined => {
        const cameraAddBlur = props.camera.getConfig().getAddBlur();

        if (cameraAddBlur) {
            return cameraAddBlur.toString();
        }

        const foundConfiguration = props.configurations.find(configuration => configuration.getKey() === 'add_blur');

        if (foundConfiguration) {
            return foundConfiguration.getValue();
        }
    };

    useEffect(() => {
        setFormValue(() => {
            if (props.camera) {
                return {
                    historySizeEnabled: typeof props.camera.getConfig().getHistorySize() !== 'undefined',
                    historySize: initHistorySize(),
                    iouTresholdEnabled: typeof props.camera.getConfig().getIouTreshold() !== 'undefined',
                    iouTreshold: initIouTreshold(),
                    // objectClassWhiteListEnabled:
                    //     typeof props.camera.getConfig().getObjectClassWhiteList() !== 'undefined',
                    // objectClassWhiteList: initObjectClassWhiteList(),
                    yoloDetIouEnabled: typeof props.camera.getConfig().getYoloDetIou() !== 'undefined',
                    yoloDetIou: initYoloDetIou(),
                    yoloNmsIouEnabled: typeof props.camera.getConfig().getYoloNmsIou() !== 'undefined',
                    yoloNmsIou: initYoloNmsIou(),
                    addBlurEnabled: typeof props.camera.getConfig().getAddBlur() !== 'undefined',
                    addBlur: initAddBlur(),
                };
            } else {
                return {
                    historySizeEnabled: false,
                    historySize: initHistorySize(),
                    iouTresholdEnabled: false,
                    iouTreshold: initIouTreshold(),
                    // objectClassWhiteListEnabled: false,
                    // objectClassWhiteList: initObjectClassWhiteList(),
                    yoloDetIouEnabled: false,
                    yoloDetIou: initYoloDetIou(),
                    yoloNmsIouEnabled: false,
                    yoloNmsIou: initYoloNmsIou(),
                    addBlurEnabled: false,
                    addBlur: initAddBlur(),
                };
            }
        });
    }, [props.camera, props.configurations]);

    const shouldDisableValidButton = () => {
        /**
         * History size should be even min 1
         */
        if (formValue.historySizeEnabled) {
            if (!formValue.historySize) return true;

            const historySize = parseInt(formValue.historySize);
            if (isNaN(historySize)) return true;

            if (historySize % 2 === 0 || historySize < 1) return true;
        }

        /**
         * IOU Treshold should be between 0 and 1
         */
        if (formValue.iouTresholdEnabled) {
            if (!formValue.iouTreshold) return true;

            const iouTreshold = parseFloat(formValue.iouTreshold);

            if (isNaN(iouTreshold)) return true;

            if (iouTreshold <= 0 || iouTreshold > 1) return true;
        }

        /**
         * Object class white list should be an array of numbers
         */
        // if (formValue.objectClassWhiteListEnabled) {
        //     if (!formValue.objectClassWhiteList) return true;

        //     if (formValue.objectClassWhiteList.length === 0) return true;
        // }

        /**
         * Yolo Det Iou should be between 0 and 1
         */
        if (formValue.yoloDetIouEnabled) {
            if (!formValue.yoloDetIou) return true;

            const yoloDetIou = parseFloat(formValue.yoloDetIou);

            if (isNaN(yoloDetIou)) return true;

            if (yoloDetIou <= 0 || yoloDetIou > 1) return true;
        }

        /**
         * Prior coeff OY should exists
         */
        if (formValue.yoloNmsIouEnabled) {
            if (!formValue.yoloNmsIou) return true;

            const yoloNmsIou = parseFloat(formValue.yoloNmsIou);

            if (isNaN(yoloNmsIou)) return true;

            if (yoloNmsIou <= 0 || yoloNmsIou > 1) return true;
        }

        /**
         * Add blur should exists
         */
        if (formValue.addBlurEnabled) {
            if (!formValue.addBlur) return true;

            const addBlur = parseFloat(formValue.addBlur);

            if (isNaN(addBlur)) return true;

            if (addBlur < 0) return true;
        }

        return false;
    };

    const handleValid = () => {
        setIsEditing(true);

        axiosService
            .getAxios()
            .put<IPCameraAPIResponse>(
                '/ip-cameras/updateHyperParam',
                {
                    id: props.camera.getId(),
                    history_size:
                        formValue.historySizeEnabled && formValue.historySize
                            ? parseInt(formValue.historySize)
                            : undefined,
                    iou_treshold:
                        formValue.iouTresholdEnabled && formValue.iouTreshold
                            ? parseFloat(formValue.iouTreshold)
                            : undefined,
                    // object_class_whitelist:
                    //     formValue.objectClassWhiteListEnabled && formValue.objectClassWhiteList
                    //         ? formValue.objectClassWhiteList
                    //         : undefined,
                    yolo_det_iou:
                        formValue.yoloDetIouEnabled && formValue.yoloDetIou
                            ? parseFloat(formValue.yoloDetIou)
                            : undefined,
                    yolo_nms_iou:
                        formValue.yoloNmsIouEnabled && formValue.yoloNmsIou
                            ? parseFloat(formValue.yoloNmsIou)
                            : undefined,
                    add_blur: formValue.addBlurEnabled && formValue.addBlur ? parseInt(formValue.addBlur) : undefined,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updatePlaceCameraResponse => {
                Alert.success(intl.formatMessage({ id: 'placeCamera.editHyperParam.success' }));

                props.camera.updateHyperParameters(updatePlaceCameraResponse.data);

                props.cancelEdit();
            })
            .catch(err => {
                console.error(err);
                Alert.error(intl.formatMessage({ id: 'placeCamera.editHyperParam.error' }));
            })
            .finally(() => {
                setIsEditing(false);
            });
    };

    const model = Schema.Model({
        historySize: Schema.Types.NumberType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidHistorySize' })
        ).addRule(value => {
            if (value % 2 === 0 || value < 1) return false;
            return true;
        }),

        iouTreshold: Schema.Types.NumberType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidIouTreshold' })
        ).addRule(value => {
            if (value < 0 || value > 1) {
                return false;
            }

            return true;
        }),

        objectClassWhiteList: Schema.Types.ArrayType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidObjectClassWhiteList' })
        ).addRule(value => {
            if (value.length === 0) {
                return false;
            } else {
                return true;
            }
        }),

        yoloDetIou: Schema.Types.NumberType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidYoloDetIou' })
        ).addRule(value => {
            if (value < 0 || value > 1) {
                return false;
            }

            return true;
        }),

        yoloNmsIou: Schema.Types.NumberType(
            intl.formatMessage({ id: 'placeCamera.editHyperParam.invalidYoloNmsIou' })
        ).addRule(value => {
            if (value < 0 || value > 1) {
                return false;
            }

            return true;
        }),
    });

    const handleCancelForm = () => {
        setFormValue(() => {
            if (props.camera) {
                return {
                    historySizeEnabled: typeof props.camera.getConfig().getHistorySize() !== 'undefined',
                    historySize: initHistorySize(),
                    iouTresholdEnabled: typeof props.camera.getConfig().getIouTreshold() !== 'undefined',
                    iouTreshold: initIouTreshold(),
                    // objectClassWhiteListEnabled:
                    //     typeof props.camera.getConfig().getObjectClassWhiteList() !== 'undefined',
                    // objectClassWhiteList: initObjectClassWhiteList(),
                    yoloDetIouEnabled: typeof props.camera.getConfig().getYoloDetIou() !== 'undefined',
                    yoloDetIou: initYoloDetIou(),
                    yoloNmsIouEnabled: typeof props.camera.getConfig().getYoloNmsIou() !== 'undefined',
                    yoloNmsIou: initYoloNmsIou(),
                    addBlurEnabled: typeof props.camera.getConfig().getAddBlur() !== 'undefined',
                    addBlur: initAddBlur(),
                };
            } else {
                return {
                    historySizeEnabled: false,
                    historySize: initHistorySize(),
                    iouTresholdEnabled: false,
                    iouTreshold: initIouTreshold(),
                    // objectClassWhiteListEnabled: false,
                    // objectClassWhiteList: initObjectClassWhiteList(),
                    yoloDetIouEnabled: false,
                    yoloDetIou: initYoloDetIou(),
                    yoloNmsIouEnabled: false,
                    yoloNmsIou: initYoloNmsIou(),
                    addBlurEnabled: false,
                    addBlur: initAddBlur(),
                };
            }
        });

        props.cancelEdit();
    };

    return (
        <Form
            fluid
            formValue={formValue}
            onCheck={formError => setFormError(formError)}
            onChange={formValue => setFormValue(formValue as FormValue)}
            model={model}>
            {/* HISTORY SIZE */}
            <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                    <ControlLabel>
                        <FormattedMessage id="placeCamera.hyperParam.historySize" />
                    </ControlLabel>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={10}>
                    <FormGroup>
                        <FormControl
                            className={formError.historySize && 'rs-form-error'}
                            name="historySize"
                            accepter={Input}
                            min={1}
                            type="number"
                            disabled={!formValue.historySizeEnabled}
                            errorMessage={null}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={2}>
                    <FormGroup>
                        <FormControl
                            name="historySizeEnabled"
                            accepter={Toggle}
                            checked={formValue.historySizeEnabled}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            {/* IOU TRESHOLD */}
            <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                    <FormattedMessage id="placeCamera.hyperParam.iouTreshold" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={10}>
                    <FormGroup>
                        <FormControl
                            className={formError.iouTreshold && 'rs-form-error'}
                            name="iouTreshold"
                            accepter={Input}
                            min={0}
                            max={1}
                            type="number"
                            disabled={!formValue.iouTresholdEnabled}
                            errorMessage={null}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={2}>
                    <FormGroup>
                        <FormControl
                            name="iouTresholdEnabled"
                            accepter={Toggle}
                            checked={formValue.iouTresholdEnabled}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            {/* OBJECT CLASS WHITE LIST */}
            {/* <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                    <FormattedMessage id="placeCamera.hyperParam.objectClassWhiteList" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={10}>
                    <FormGroup>
                        <FormControl
                            className={formError.objectClassWhiteList && 'rs-form-error'}
                            name="objectClassWhiteList"
                            accepter={CheckPicker}
                            data={CameraYoloObjectTypesSelector(intl)}
                            renderMenuItem={(label, item) => {
                                if (item.icon) {
                                    return (
                                        <>
                                            <FontAwesomeIcon icon={item.icon} className="margin-right-5" />{' '}
                                            <span>{label}</span>
                                        </>
                                    );
                                } else {
                                    return label;
                                }
                            }}
                            renderValue={(_, item) => {
                                return item.map(i => {
                                    if (i.icon) {
                                        return <FontAwesomeIcon icon={i.icon} className="margin-right-5" />;
                                    } else {
                                        return i.label;
                                    }
                                });
                            }}
                            disabled={!formValue.objectClassWhiteListEnabled}
                            errorMessage={null}
                            searchable={false}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={2}>
                    <FormGroup>
                        <FormControl
                            name="objectClassWhiteListEnabled"
                            accepter={Toggle}
                            checked={formValue.objectClassWhiteListEnabled}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid> */}
            {/* PRIOR COEFF OX ENABLED */}
            <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                    <FormattedMessage id="placeCamera.hyperParam.yoloDetIou" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={10}>
                    <FormGroup>
                        <FormControl
                            className={formError.yoloDetIou && 'rs-form-error'}
                            name="yoloDetIou"
                            accepter={Input}
                            type="number"
                            min={0}
                            max={1}
                            disabled={!formValue.yoloDetIouEnabled}
                            errorMessage={null}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={2}>
                    <FormGroup>
                        <FormControl name="yoloDetIouEnabled" accepter={Toggle} checked={formValue.yoloDetIouEnabled} />
                    </FormGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            {/* PRIOR COEFF OY ENABLED */}
            <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                    <FormattedMessage id="placeCamera.hyperParam.yoloNmsIou" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={10}>
                    <FormGroup>
                        <FormControl
                            className={formError.yoloNmsIou && 'rs-form-error'}
                            name="yoloNmsIou"
                            accepter={Input}
                            type="number"
                            min={0}
                            max={1}
                            disabled={!formValue.yoloNmsIouEnabled}
                            errorMessage={null}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={2}>
                    <FormGroup>
                        <FormControl name="yoloNmsIouEnabled" accepter={Toggle} checked={formValue.yoloNmsIouEnabled} />
                    </FormGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            {/* ADD BLUR */}
            <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-20">
                <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                    <FormattedMessage id="placeCamera.hyperParam.addBlur" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={10}>
                    <FormGroup>
                        <FormControl
                            className={formError.addBlur && 'rs-form-error'}
                            name="addBlur"
                            accepter={Input}
                            type="number"
                            min={0}
                            disabled={!formValue.addBlurEnabled}
                            errorMessage={null}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={2}>
                    <FormGroup>
                        <FormControl name="addBlurEnabled" accepter={Toggle} checked={formValue.addBlurEnabled} />
                    </FormGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <FlexboxGrid justify="end">
                <FlexboxGrid.Item>
                    <ButtonGroup>
                        <Button color="red" onClick={() => handleCancelForm()} disabled={isEditing}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                        <Button
                            color="green"
                            onClick={handleValid}
                            disabled={shouldDisableValidButton()}
                            loading={isEditing}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Form>
    );
};
