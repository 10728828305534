import moment from 'moment';
import { DeviceBase } from './DeviceBase';
import { default as TCMSensorCalibration } from './Sensor/TCMSensorCalibration';
import { default as TCMSensorColor } from './Sensor/TCMSensorColor';
import { default as TCMSensorConfig } from './Sensor/TCMSensorConfig';
import { TCMSensorEventForceSensor } from './Sensor/TCMSensorEventForceSensor';
import { default as TCMSensorTime } from './Sensor/TCMSensorTime';

export default class TCMSensor extends DeviceBase {
    private _detectionState: number;
    private _detectionStateTime: Date;
    // ---
    private _eventForce: TCMSensorEventForceSensor;
    //---
    private _config: TCMSensorConfig;
    private _color: TCMSensorColor;
    private _time: TCMSensorTime;
    private _calibration: TCMSensorCalibration;
    // ---
    private _forceType: number;
    private _sensorPlaceType: {
        id: number;
    };
    //---
    putData: Function;
    updateElements: Function;
    constructor(tcmSensor_, ipCanId_, putData_, updateElements_) {
        super(
            tcmSensor_.id,
            tcmSensor_.bus,
            tcmSensor_.deviceId,
            tcmSensor_.online,
            tcmSensor_.createdAt,
            tcmSensor_.updatedAt,
            tcmSensor_.createdBy,
            tcmSensor_.updatedBy,
            tcmSensor_.versionSoft,
            tcmSensor_.startForceTime,
            tcmSensor_.endForceTime,
            tcmSensor_.isForce,
            tcmSensor_.lastOnlineStateDate,
            tcmSensor_.maintenanceState,
            tcmSensor_.notepad,
            ipCanId_
        );

        this._eventForce = new TCMSensorEventForceSensor(tcmSensor_.eventForceType, tcmSensor_.eventForceColor);
        this._calibration = new TCMSensorCalibration(tcmSensor_.calibration);
        this._color = new TCMSensorColor(tcmSensor_.color);
        this._config = new TCMSensorConfig(tcmSensor_.config);
        this._detectionState = tcmSensor_.detectionState;
        this._detectionStateTime = tcmSensor_.detectionStateTime;
        this._time = new TCMSensorTime(tcmSensor_.time);
        this._forceType = tcmSensor_.forceType;
        this.putData = putData_;
        this.updateElements = updateElements_;
        this._sensorPlaceType = tcmSensor_.sensorPlaceType;
    }

    //      ____ _____ _____ _____ _____ ____  ____
    //     / ___| ____|_   _|_   _| ____|  _ \/ ___|
    //    | |  _|  _|   | |   | | |  _| | |_) \___ \
    //    | |_| | |___  | |   | | | |___|  _ < ___) |
    //     \____|_____| |_|   |_| |_____|_| \_\____/

    public get calibration() {
        return this._calibration;
    }

    public get eventForce() {
        return this._eventForce;
    }

    public get detectionState() {
        return this._detectionState;
    }

    public get detectionStateTimeFormatted() {
        return moment(this._detectionStateTime).format('DD/MM/YYYY HH:mm');
    }

    public get config() {
        return this._config;
    }

    public get online() {
        return this._online;
    }

    public get deviceId() {
        return this._deviceId;
    }

    public get informations() {
        return {
            online: this.online,
            deviceId: this.deviceId,
            versionSoft: this.versionSoft,
            detectionState: this.detectionState,
            detectionStateTime: this.detectionStateTimeFormatted,
            creation: this.creationInformations,
            edition: this.updateInformations,
        };
    }

    public get creationInformations() {
        return `${this.createdBy} - ${this.createdAt}`;
    }

    public get updateInformations() {
        return `${this.updatedBy} - ${this.updatedAt}`;
    }

    public get color() {
        return this._color;
    }

    public get time() {
        return this._time;
    }

    public get forceType() {
        return this._forceType;
    }

    public get sensorPlaceType() {
        return this._sensorPlaceType;
    }

    public get detectionStateTime() {
        return this._detectionStateTime;
    }

    //  ____  _____ _____ _____ _____ ____  ____
    // / ___|| ____|_   _|_   _| ____|  _ \/ ___|
    // \___ \|  _|   | |   | | |  _| | |_) \___ \
    //  ___) | |___  | |   | | | |___|  _ < ___) |
    // |____/|_____| |_|   |_| |_____|_| \_\____/

    public setEventForce(type, color) {
        if (this._eventForce.color !== color || this._eventForce.forceType !== type) {
            this._eventForce.setEventForce(type, color);

            return true;
        }

        return false;
    }

    public setDetectionStateTime(detectionStateTime_: Date): boolean {
        if (this._detectionStateTime !== detectionStateTime_) {
            this._detectionStateTime = detectionStateTime_;
            return true;
        }

        return false;
    }

    public setDetectionState(detectionState_: number): boolean {
        if (this._detectionState !== detectionState_) {
            this._detectionState = detectionState_;
            return true;
        }

        return false;
    }

    public setForceType(forceType_: number): boolean {
        if (this._forceType !== forceType_) {
            this._forceType = forceType_;
            return true;
        }
        return false;
    }

    public webSocketUpdate(data: TCMSensorWebsocket_I) {
        let nbShouldUpdate = 0;

        if (this.setIsForce(data.isForce)) nbShouldUpdate++;
        if (this.setForceType(data.forceType)) nbShouldUpdate++;
        if (this.setEndForceTime(data.endForceTime)) nbShouldUpdate++;
        if (this.setDetectionStateTime(data.detectionStateTime)) nbShouldUpdate++;
        if (this.setDetectionState(data.detectionState)) nbShouldUpdate++;
        if (this.setOnline(data.online)) nbShouldUpdate++;
        if (this.setLastOnlineStateDate(data.lastOnlineStateDate)) nbShouldUpdate++;
        if (this.setEventForce(data.eventForceType, data.eventForceColor)) nbShouldUpdate++;

        if (this.updateElements && nbShouldUpdate > 0) {
            this.updateElements();
        }
    }

    public setSensorPlaceType(sensorPlaceType: any): boolean {
        if (this._sensorPlaceType.id !== sensorPlaceType.id) {
            this._sensorPlaceType = sensorPlaceType;

            return true;
        }

        return false;
    }

    public updateSensor(data) {
        let nbElementsUpdated = 0;

        if (this.setDetectionState(data.detectionState)) nbElementsUpdated++;
        if (this.setDetectionStateTime(data.detectionStateTime)) nbElementsUpdated++;
        if (this.setEndForceTime(data.endForceTime)) nbElementsUpdated++;
        if (this.setIsForce(data.isForce)) nbElementsUpdated++;
        if (this.setOnline(data.online)) nbElementsUpdated++;
        if (this.setLastOnlineStateDate(data.lastOnlineStateDate)) nbElementsUpdated++;
        if (this.setSensorPlaceType(data.sensorPlaceType)) nbElementsUpdated++;
    }

    public updateSensorCalibration(calibration) {
        this._calibration.updateCalibration(calibration);
    }
}

export interface TCMSensorWebsocket_I {
    bus: number;
    calibration: TCMSensorCalibration;
    detectionState: number;
    detectionStateTime: Date;
    deviceId: number;
    endForceTime: Date;
    eventForceColor: string;
    eventForceType: string;
    forceType: number;
    id: number;
    ipcanmoduleId: number;
    isForce: boolean;
    lastOnlineStateDate: Date;
    online: boolean;
    versionSoft: number;
}
