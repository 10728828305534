import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Grid,
    Row,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { StepTaskData, StepTypeEnum } from '..';
import Task from '../../../../../handlers/Event/Task';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    onClickBack: () => void;
    currentTask: StepTaskData;
    closeModal: () => void;
    type: StepTypeEnum;
    existingTask?: Task;
};

type FormValue = {
    ipCanId?: number;
    outputNumber?: number;
    outputEnabled?: boolean;
};

export const SetGpioStepType = (props: Props) => {
    const intl = useIntl();
    // STATE
    const [isCreating, setIsCreating] = React.useState(false);
    const [ipCans, setIpCans] = React.useState<IpCan[]>([]);
    const [formValue, setFormValue] = React.useState<FormValue>(() => {
        if (props.existingTask) {
            return {
                ipCanId: props.existingTask.getIpCans()[0].id,
                outputNumber: props.existingTask.getForceOption().getGpioNumber(),
                outputEnabled: props.existingTask.getForceOption().getGpioEnabled(),
            };
        }
        return {
            outputNumber: 1,
            outputEnabled: true,
        };
    });

    // EFFECTS
    useEffect(() => {
        axiosService
            .getAxios()
            .get('ipcanmodules', {
                headers: authHeader(),
            })
            .then(ipCansResponse => {
                setIpCans(ipCansResponse.data.map(ipcan => new IpCan(ipcan)));
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const handleValid = () => {
        setIsCreating(true);

        if (props.type === StepTypeEnum.CREATE) {
            axiosService
                .getAxios()
                .post(
                    '/event-actions/tasks',
                    {
                        idEvent: props.currentTask.eventId,
                        name: props.currentTask.name,
                        desc: props.currentTask.description,
                        forceType: props.currentTask.type,
                        forceOption: {
                            gpioNumber: formValue.outputNumber,
                            gpioEnabled: formValue.outputEnabled,
                        },
                        tabTcmDisplayId: [],
                        tabLpMatrixDisplayV2Id: [],
                        tabCounterId: [],
                        tabIpcanId: [formValue.ipCanId],
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'event.createTask.success' }));

                    setIsCreating(false);

                    props.closeModal();
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(intl.formatMessage({ id: 'event.createTask.error' }));

                    setIsCreating(false);
                });
        } else {
            axiosService
                .getAxios()
                .put(
                    '/event-actions/tasks',
                    {
                        id: props.existingTask?.getId(),
                        name: props.currentTask.name,
                        desc: props.currentTask.description,
                        forceType: props.currentTask.type,
                        forceOption: {
                            gpioNumber: formValue.outputNumber,
                            gpioEnabled: formValue.outputEnabled,
                        },
                        tabTcmDisplayId: [],
                        tabLpMatrixDisplayV2Id: [],
                        tabCounterId: [],
                        tabIpcanId: [formValue.ipCanId],
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'event.editTask.success' }));

                    setIsCreating(false);

                    props.closeModal();
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(intl.formatMessage({ id: 'event.editTask.error' }));

                    setIsCreating(false);
                });
        }
    };

    const handleChange = (formValue: FormValue) => {
        setFormValue(formValue);
    };

    const currentIpCan = ipCans.find(ipcan => ipcan.getId() === formValue.ipCanId);

    return (
        <div>
            <Form fluid formValue={formValue} onChange={formValue => handleChange(formValue as FormValue)}>
                <FormGroup>
                    <ControlLabel data-cy="event-createTask-ipCanId-title">
                        {intl.formatMessage({ id: 'event.createTask.ipCanId' })}
                    </ControlLabel>
                    <FormControl
                        data-cy="event-createTask-ipCanId"
                        accepter={SelectPicker}
                        searchable={false}
                        cleanable={false}
                        data={ipCans.map(ipcan => {
                            return {
                                label: `${ipcan.label} (${ipcan.macStr})`,
                                value: ipcan.getId(),
                            };
                        })}
                        name="ipCanId"
                    />
                </FormGroup>

                {currentIpCan && (
                    <Grid fluid>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <ControlLabel data-cy="event-createTask-outputNumber-title">
                                        {intl.formatMessage({ id: 'event.createTask.outputNumber' })}
                                    </ControlLabel>
                                    <FormControl
                                        data-cy="event-createTask-outputNumber"
                                        accepter={SelectPicker}
                                        searchable={false}
                                        cleanable={false}
                                        data={[
                                            { label: currentIpCan?.getGpioConfig().gpioOut[0].name, value: 1 },
                                            { label: currentIpCan?.getGpioConfig().gpioOut[1].name, value: 2 },
                                        ]}
                                        name="outputNumber"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <ControlLabel data-cy="event-createTask-outputEnabled-title">
                                        {intl.formatMessage({ id: 'event.createTask.outputEnabled' })}
                                    </ControlLabel>
                                    <FormControl
                                        data-cy="event-createTask-outputEnabled"
                                        accepter={Toggle}
                                        name="outputEnabled"
                                        defaultChecked={formValue.outputEnabled}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Grid>
                )}
            </Form>

            <FlexboxGrid align="middle" justify="end" className="margin-top-15">
                <FlexboxGrid.Item>
                    <Button onClick={() => props.onClickBack()} color="blue" className="margin-right-5">
                        <FontAwesomeIcon icon={faArrowLeft} className="margin-right-5" />
                        <FormattedMessage id="event.createTask.back" />
                    </Button>

                    <Button
                        color="green"
                        onClick={handleValid}
                        disabled={typeof formValue.ipCanId !== 'number'}
                        data-cy="event-createTask-valid">
                        <FontAwesomeIcon icon={faCheck} className="margin-right-5" />
                        <FormattedMessage id="event.createTask.valid" />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};
