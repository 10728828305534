import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Col, FlexboxGrid, Panel } from 'rsuite';

type Props = {
    listIpCan: Array<Record<string, any>>;
} & WrappedComponentProps;

type State = {
    loading: boolean;
};

class IpCanOnlineState extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    render() {
        let donutSize: string | number = '100%';
        let donutColSize = 12;

        donutSize = window.innerHeight * 0.2;

        const state = {
            total: 0,
            online: { total: 0, percent: 0 },
            offline: { total: 0, percent: 0 },
            starting: { total: 0, percent: 0 },
        };

        const dataDoughnut = {
            title: '',
            datasets: [
                {
                    data: [0, 0, 0],
                    backgroundColor: ['#f44336', '#008000', '#ffa500'],
                },
            ],
            labels: [
                this.props.intl.formatMessage({ id: 'ipCan.state.offline' }),
                this.props.intl.formatMessage({ id: 'ipCan.state.online' }),
                this.props.intl.formatMessage({ id: 'ipCan.state.starting' }),
            ],
        };

        state.total = 0;
        state.online.total = 0;
        state.offline.total = 0;
        state.starting.total = 0;
        //---
        for (let l in this.props.listIpCan) {
            let ipcan = this.props.listIpCan[l];

            //---
            if (ipcan.online === 2) state.online.total++;
            else if (ipcan.online === 0) state.offline.total++;
            else if (ipcan.online === 1) state.starting.total++;
            //---
            state.total++;
        }
        //---
        state.online.percent = parseFloat(((state.online.total / state.total) * 100).toFixed(1));
        state.offline.percent = parseFloat(((state.offline.total / state.total) * 100).toFixed(1));
        state.starting.percent = parseFloat(((state.starting.total / state.total) * 100).toFixed(1));
        //---
        dataDoughnut.datasets[0].data[0] = state.offline.total;
        dataDoughnut.datasets[0].data[1] = state.online.total;
        dataDoughnut.datasets[0].data[2] = state.starting.total;

        return (
            <Loader show={this.state.loading}>
                <Panel
                    data-cy="controller-connection-status"
                    className={'dashboard-card-header hoverable'}
                    header={<FormattedMessage id="controllers.cardOnline.title" />}
                    shaded>
                    <FlexboxGrid align="middle" justify="space-between" style={{ height: donutSize }}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={12}
                            xs={donutColSize}
                            style={{ height: donutSize }}>
                            <Doughnut
                                data={dataDoughnut}
                                // height={'100%'}
                                // width={donutSize}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    aspectRatio: 1,
                                    legend: {
                                        display: false,
                                    },
                                }}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} colspan={24} xs={24 - donutColSize}>
                            <div data-cy="ipCan-cardOnline-online" className="parking-legend-title margin-top-15 free">
                                <FormattedMessage id="controllers.cardOnline.online" />
                            </div>
                            <div data-cy="ipCan-cardOnline-online-value" className="parking-legend-value">
                                {state.online.total} <FormattedMessage id="controllers.cardOnline.ipcanmodule" />
                                <span className="margin-left-5 small free">({state.online.percent}%)</span>
                            </div>

                            <div data-cy="ipCan-cardOnline-starting" className="parking-legend-title occupied">
                                <FormattedMessage id="controllers.cardOnline.starting" />
                            </div>
                            <div data-cy="ipCan-cardOnline-starting-value" className="parking-legend-value">
                                {state.starting.total} <FormattedMessage id="controllers.cardOnline.ipcanmodule" />
                                <span className="margin-left-5 small occupied">({state.starting.percent}%)</span>
                            </div>

                            <div data-cy="ipCan-cardOnline-occupied" className="parking-legend-title occupied">
                                <FormattedMessage id="controllers.cardOnline.offline" />
                            </div>
                            <div data-cy="ipCan-cardOnline-occupied-value" className="parking-legend-value">
                                {state.offline.total} <FormattedMessage id="controllers.cardOnline.ipcanmodule" />
                                <span className="margin-left-5 small occupied">({state.offline.percent}%)</span>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Panel>
            </Loader>
        );
    }
}

export default injectIntl(IpCanOnlineState);
