import { Fragment, useState } from 'react';
import { Circle } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { DrawerInformationInterface } from '../..';
import Camera from '../../../../handlers/Camera/Camera';
import { PlaceCameraState } from '../../../../handlers/Camera/PlaceCamera';
import { CameraSensorMapInformation_I } from '../../../../handlers/map/MapCameraSensor';
import { SensorPlaceType } from '../../../../handlers/sensorPlaceType/SensorPlaceType';
import { PlaceCameraPopup } from './PlaceCameraPopup';

type Props = {
    sensor: CameraSensorMapInformation_I;
    placeTypes: SensorPlaceType[];
    circleRadius: number;
    cameras: Camera[];
    reloadSensors: Function;
    editable?: boolean;
    editMode: boolean;
    sensorStateDisplayed: boolean;
    service: any;
    blinkState: number;
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
    isEditingSensors: boolean;
};

export const PlaceCameraIcon = (props: Props) => {
    const auth = useSelector((state: any) => state.auth);

    const [hoverPopup, setHoverPopup] = useState(false);

    const isAdmin = auth.user.roles.find(role => role.name === 'Admin') || false;
    const currentCamera = props.cameras.find(camera => camera.getId() === props.sensor.idCamera);

    const onMouseOver = e => {
        setHoverPopup(true);
        e.target.openPopup();
    };

    const onMouseOut = e => {
        if (hoverPopup) {
            setHoverPopup(false);
            e.target.closePopup();
        }
    };

    const openPopup = e => {
        setHoverPopup(false);
        e.target.openPopup();
    };

    const generateForceColor = () => {
        switch (props.sensor.placeCamera.getForceType()) {
            case PlaceCameraState.FREE:
                return props.sensor.scenes[0].getStep(0).color;
            case PlaceCameraState.OCCUPIED:
                return props.sensor.scenes[1].getStep(0).color;
            case PlaceCameraState.OVERSTAY:
                return props.sensor.scenes[2].getStep(0).color;
            case PlaceCameraState.UNKNOWN:
                return '#000000';
            default:
                return 'transparent';
        }
    };

    const generateCircleColor = () => {
        const isForce = props.sensor.placeCamera.getIsForce();
        const detectionState = props.sensor.placeCamera.getState();
        const online = currentCamera?.getOnline();

        // console.log(placeType?.getPlaceType().scene[2]);

        if (!online) {
            try {
                return props.sensor.scenes[0].getStep(0).color;
            } catch {
                return 'transparent';
            }
        }

        try {
            if (props.sensorStateDisplayed || !isAdmin) {
                if (isForce) {
                    return generateForceColor();
                } else {
                    return props.sensor.scenes[detectionState].getStep(props.blinkState).color;
                }
            } else {
                return props.sensor.scenes[0].getStep(props.blinkState).color;
            }
        } catch {
            return 'transparent';
        }
    };

    const generateColor = () => {
        try {
            return props.sensor.scenes[0].getStep(0).color;
        } catch (e) {
            return 'transparent';
        }
    };

    const shouldShowCurrentSelectedElement =
        props.drawerInformation.type === 'place-camera' && props.drawerInformation.id === props.sensor.id;

    return (
        <Fragment>
            <Circle
                radius={props.circleRadius}
                color={generateCircleColor()}
                fillColor={generateColor()}
                opacity={1}
                weight={5}
                center={props.sensor.geoJSON}
                fillOpacity={!currentCamera?.getOnline() && isAdmin ? 0.3 : 1}
                stroke={true}
                name={`${props.sensor.id}-place-camera`}
                onmouseover={onMouseOver}
                onmouseout={onMouseOut}
                onclick={openPopup}>
                <PlaceCameraPopup
                    camera={currentCamera}
                    drawerInformation={props.drawerInformation}
                    editMode={props.editMode}
                    mapPlaceCamera={props.sensor}
                    hoverPopup={hoverPopup}
                    openDrawer={props.openDrawer}
                    closeDrawer={props.closeDrawer}
                    placeTypes={props.placeTypes}
                    service={props.service}
                />
                {shouldShowCurrentSelectedElement && !props.isEditingSensors && (
                    <Circle
                        radius={props.circleRadius + 4}
                        weight={10}
                        color="#FF851B"
                        fillOpacity={0}
                        center={props.sensor.geoJSON}></Circle>
                )}
                {/* <LPSensorPopup
                    sensor={this.props.sensor}
                    editMode={this.props.editMode}
                    controller={this.state.controller}
                    placeTypes={this.props.placeTypes}
                    overPopup={this.state.hoverPopup}
                    controllers={this.props.controllers}
                    service={this.props.service}
                    drawerInformation={this.props.drawerInformation}
                    openDrawer={this.props.openDrawer}
                    closeDrawer={this.props.closeDrawer}
                /> */}
            </Circle>
            {props.sensor.placeCamera.getIsForce() && props.sensorStateDisplayed && !props.isEditingSensors && (
                <Circle
                    radius={3}
                    color="#000"
                    fillColor="#000"
                    fillOpacity={1}
                    opacity={1}
                    stroke={false}
                    center={props.sensor.geoJSON}
                    onmouseover={onMouseOver}
                    onmouseout={onMouseOut}
                    onclick={openPopup}>
                    {/* <LPSensorPopup
                        sensor={this.props.sensor}
                        editMode={this.props.editMode}
                        controller={this.state.controller}
                        placeTypes={this.props.placeTypes}
                        overPopup={this.state.hoverPopup}
                        controllers={this.props.controllers}
                        service={this.props.service}
                        drawerInformation={this.props.drawerInformation}
                        openDrawer={this.props.openDrawer}
                        closeDrawer={this.props.closeDrawer}
                    /> */}
                    <PlaceCameraPopup
                        camera={currentCamera}
                        drawerInformation={props.drawerInformation}
                        editMode={props.editMode}
                        mapPlaceCamera={props.sensor}
                        hoverPopup={hoverPopup}
                        openDrawer={props.openDrawer}
                        closeDrawer={props.closeDrawer}
                        placeTypes={props.placeTypes}
                        service
                    />
                </Circle>
            )}
        </Fragment>
    );
};
