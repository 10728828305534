import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import ReactFlow, {
    Background,
    BackgroundVariant,
    Connection,
    Controls,
    Edge,
    FlowElement,
    OnLoadParams,
    useStoreActions,
} from 'react-flow-renderer';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, Loader } from 'rsuite';
import { LPDisplay } from '../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { DataProps, LPMatrixDisplayV2ConfigDrawer } from './Drawer';
import EdgeTrigger from './Edges/EdgeTrigger';
import EdgeVirtualDisplayStep from './Edges/EdgeVirtualDisplayStep';
import EdgeVirtualDisplayStepTrigger from './Edges/EdgeVirtualDisplayStepTrigger';
import EdgeWithButton from './Edges/EdgeWithButton';
import FlowChartFactory from './handlers/FlowChartFactory';
import { ActionObserver, ActionObserverEvent } from './handlers/Observers/ActionObserver';
import { DrawerObserver, DrawerObserverEvent, DrawerObserverType } from './handlers/Observers/DrawerObserver';
import CreateTopologyTriggerModal from './Modal/CreateTopologyTriggerModal';
import CreateVirtualDisplayModal from './Modal/CreateVirtualDisplayModal';
import TopologyNode from './Nodes/TopologyNode/TopologyNode';
import VirtualDisplayNode from './Nodes/VirtualDisplayNode/VirtualDisplayNode';
import VirtualDisplayStepNode from './Nodes/VirtualDisplayStepNode/VirtualDisplayStepNode';

type Props = {
    display: LPDisplay;
};

const nodeTypes = {
    topologyNode: TopologyNode,
    virtualDisplayNode: VirtualDisplayNode,
    virtualDisplayStepNode: VirtualDisplayStepNode,
};

const edgeTypes = {
    edgeWithButton: EdgeWithButton,
    edgeTrigger: EdgeTrigger,
    edgeVirtualDisplayStep: EdgeVirtualDisplayStep,
    edgeVirtualDisplayStepTrigger: EdgeVirtualDisplayStepTrigger,
};

type DrawerState = {
    isOpen: boolean;
    type?: DrawerObserverType;
    data?: DataProps;
};

export type TriggerModalInformation = {
    isOpen: boolean;
    connection?: Edge | Connection;
};

export const FlowChart = (props: Props) => {
    const intl = useIntl();
    const flowChartFactory = new FlowChartFactory(props.display);

    const [elements, setElements] = React.useState<FlowElement[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const [drawerState, setDrawerState] = React.useState<DrawerState>({
        isOpen: false,
    });

    const [isCreateVirtualDisplayModalOpen, setIsCreateVirtualDisplayModalOpen] = React.useState<boolean>(false);
    const [triggerModalInformation, setTriggerModalInformation] = React.useState<TriggerModalInformation>({
        isOpen: false,
    });

    const [reactflowInstance, setReactflowInstance] = React.useState<OnLoadParams | null>(null);

    const resetSelectedElement = useStoreActions(actions => actions.resetSelectedElements);

    useEffect(() => {
        DrawerObserver.subscribe(onEvent);
        ActionObserver.subscribe(onActionObserved);

        setElements(flowChartFactory.getElements());

        setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return function cleanup() {
            DrawerObserver.unsubscribe(onEvent);
            ActionObserver.unsubscribe(onActionObserved);
        };
    }, [props.display]);

    useEffect(() => {
        if (reactflowInstance && elements.length > 0) {
            reactflowInstance.fitView({ padding: 0.1, includeHiddenNodes: false });
        }
    }, [reactflowInstance, elements.length]);

    const onActionObserved = (type: ActionObserverEvent, data: any) => {
        switch (type) {
            case ActionObserverEvent.CREATE_VIRTUAL_DISPLAY: {
                setElements(flowChartFactory.getElements());
                break;
            }

            case ActionObserverEvent.UPDATE_VIRTUAL_DISPLAY_STEPS: {
                const currentVirtualDisplay = props.display
                    .getVirtualDisplays()
                    .find(vd => vd.getId() === data.virtualDisplay.id);

                if (currentVirtualDisplay) {
                    currentVirtualDisplay.updateVirtualDisplaySteps(data.virtualDisplay.steps);

                    DrawerObserver.emit(DrawerObserverEvent.DRAWER_OPEN, DrawerObserverType.VIRTUAL_DISPLAY, {
                        color: data.color,
                        label: data.label,
                        display: props.display,
                        topology: props.display.getTopologyConfig().getTopologys()[0],
                        virtualDisplay: currentVirtualDisplay.getPreviewInformation(),
                    });
                }

                setElements(flowChartFactory.getElements());

                break;
            }

            case ActionObserverEvent.UPDATE_VIRTUAL_DISPLAY: {
                const currentVirtualDisplay = props.display
                    .getVirtualDisplays()
                    .find(vd => vd.getId() === data.virtualDisplay.id);

                if (currentVirtualDisplay) {
                    currentVirtualDisplay.updateVirtualDisplay(data.virtualDisplay);

                    DrawerObserver.emit(DrawerObserverEvent.DRAWER_OPEN, DrawerObserverType.VIRTUAL_DISPLAY, {
                        color: data.color,
                        label: data.label,
                        display: props.display,
                        topology: data.topology,
                        virtualDisplay: currentVirtualDisplay.getPreviewInformation(),
                    });
                }
                setElements(flowChartFactory.getElements());

                break;
            }

            case ActionObserverEvent.REMOVE_VIRTUAL_DISPLAY_FROM_TOPOLOGY: {
                const topologies = [...props.display.getTopologyConfig().getTopologys()];

                topologies[data.topologyIndex].virtualDisplays = topologies[data.topologyIndex].virtualDisplays.filter(
                    vd => vd !== data.virtualDisplayId
                );

                axiosService
                    .getAxios()
                    .put(
                        '/devices/lp-matrixdisplays-v2/updateTopologysConfig',
                        {
                            id: props.display.getId(),
                            topology: {
                                topologys: topologies,
                            },
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(updateTopologysConfigResponse => {
                        props.display.updateTopologysConfig(updateTopologysConfigResponse.data);

                        setElements(flowChartFactory.getElements());

                        Alert.success(
                            intl.formatMessage({
                                id: 'ipCanDevices.lpDisplays.updateTopologys.removeVirtualDisplay.success',
                            })
                        );
                    })
                    .catch(err => {
                        console.error(err);

                        Alert.error(
                            intl.formatMessage({
                                id: 'ipCanDevices.lpDisplays.updateTopologys.removeVirtualDisplay.error',
                            })
                        );
                    });
                break;
            }

            case ActionObserverEvent.ADD_VIRTUAL_DISPLAY_TO_TOPOLOGY: {
                const virtualDisplayIndex = parseInt(data.connection.source.replace('virtualDisplay-', ''));
                const topologyIndex = parseInt(data.connection.target.replace('topology-', ''));

                if (!isNaN(virtualDisplayIndex) && !isNaN(topologyIndex)) {
                    const virtualDisplay = props.display
                        .getVirtualDisplays()
                        .find(vd => vd.getPosId() === virtualDisplayIndex);

                    if (virtualDisplay) {
                        const topologies = [...props.display.getTopologyConfig().getTopologys()];

                        topologies[topologyIndex].virtualDisplays.push(virtualDisplay.getPosId() + 1);

                        axiosService
                            .getAxios()
                            .put(
                                '/devices/lp-matrixdisplays-v2/updateTopologysConfig',
                                {
                                    id: props.display.getId(),
                                    topology: { topologys: topologies },
                                },
                                {
                                    headers: authHeader(),
                                }
                            )
                            .then(updateTopologysConfigResponse => {
                                props.display.updateTopologysConfig(updateTopologysConfigResponse.data);

                                setElements(flowChartFactory.getElements());

                                Alert.success(
                                    intl.formatMessage({
                                        id: 'ipCanDevices.lpDisplays.updateTopologys.success',
                                    })
                                );
                            })
                            .catch(err => {
                                console.error(err);

                                Alert.error(
                                    intl.formatMessage({
                                        id: 'ipCanDevices.lpDisplays.updateTopologys.error',
                                    })
                                );
                            });
                    }
                }

                break;
            }

            case ActionObserverEvent.DELETE_TOPOLOGY_TRIGGER: {
                const topologies = props.display.getTopologyConfig().getTopologys();
                topologies[data.topologyIndex].triggers[data.triggerIndex] = {
                    trigger: 0,
                    source: 0,
                    value: 0,
                    next: 0,
                };

                axiosService
                    .getAxios()
                    .put(
                        '/devices/lp-matrixdisplays-v2/updateTopologysConfig',
                        {
                            id: props.display.getId(),
                            topology: { topologys: topologies },
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(updateTopologysConfigResponse => {
                        props.display.updateTopologysConfig(updateTopologysConfigResponse.data);

                        setElements(flowChartFactory.getElements());

                        Alert.success(
                            intl.formatMessage({
                                id: 'ipCanDevices.lpDisplays.updateTopologys.deleteTrigger.success',
                            })
                        );
                    })
                    .catch(err => {
                        console.error(err);

                        Alert.error(
                            intl.formatMessage({
                                id: 'ipCanDevices.lpDisplays.updateTopologys.deleteTrigger.error',
                            })
                        );
                    });
                break;
            }

            case ActionObserverEvent.ADD_TOPOLOGY_TRIGGER: {
                setElements(flowChartFactory.getElements());

                break;
            }

            case ActionObserverEvent.DELETE_VIRTUAL_DISPLAY: {
                props.display.deleteVirtualDisplays(data.virtualDisplays);

                DrawerObserver.emit(DrawerObserverEvent.DRAWER_CLOSE, DrawerObserverType.VIRTUAL_DISPLAY);

                setElements(flowChartFactory.getElements());

                break;
            }

            case ActionObserverEvent.UPDATE_TOPOLOGY_TRIGGER: {
                const topologies = props.display.getTopologyConfig().getTopologys();
                topologies[data.topologyIndex].triggers[data.triggerIndex] = {
                    trigger: data.formValue.trigger,
                    source: data.formValue.source,
                    value: data.formValue.value,
                    next: topologies[data.topologyIndex].triggers[data.triggerIndex].next,
                };

                axiosService
                    .getAxios()
                    .put(
                        '/devices/lp-matrixdisplays-v2/updateTopologysConfig',
                        {
                            id: props.display.getId(),
                            topology: { topologys: topologies },
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(updateTopologysConfigResponse => {
                        props.display.updateTopologysConfig(updateTopologysConfigResponse.data);

                        setElements(flowChartFactory.getElements());

                        Alert.success(
                            intl.formatMessage({
                                id: 'ipCanDevices.lpDisplays.updateTopologys.updateTrigger.success',
                            })
                        );
                    })
                    .catch(err => {
                        console.error(err);

                        Alert.error(
                            intl.formatMessage({
                                id: 'ipCanDevices.lpDisplays.updateTopologys.updateTrigger.error',
                            })
                        );
                    });
                break;
            }

            case ActionObserverEvent.UPDATE_VIRTUAL_DISPLAY_ORDER: {
                props.display.updateVirtualDisplayOrder(data.virtualDisplays);

                const updatedVirtualDisplay = props.display.getVirtualDisplays().find(vd => {
                    return vd.getPosId() === data.virtualDisplay.posId;
                });

                DrawerObserver.emit(DrawerObserverEvent.DRAWER_OPEN, DrawerObserverType.VIRTUAL_DISPLAY, {
                    color: data.color,
                    label: data.label,
                    display: props.display,
                    topology: data.topology,
                    virtualDisplay: updatedVirtualDisplay?.getPreviewInformation(),
                });

                setElements(flowChartFactory.getElements());

                break;
            }

            case ActionObserverEvent.UPDATE_VIRTUAL_DISPLAY_STEPS_ORDER: {
                const virtualDisplay = props.display
                    .getVirtualDisplays()
                    .find(vd => vd.getId() === data.virtualDisplay.id);

                if (virtualDisplay) {
                    const steps = virtualDisplay.getSteps().filter(step => !step.getIsForcedStep());
                    const moveData = steps.splice(data.oldIndex, 1);
                    const newData = [...steps];
                    newData.splice(data.newIndex, 0, moveData[0]);

                    axiosService
                        .getAxios()
                        .put(
                            '/devices/lp-matrixdisplays-v2/virtualDisplay/step/orders',
                            {
                                id_virtualDisplay: virtualDisplay.getId(),
                                tabStepOrder: newData.map(data => data.getStepOrder()),
                            },
                            {
                                headers: authHeader(),
                            }
                        )
                        .then(updateStepOrderResponse => {
                            Alert.success(
                                intl.formatMessage({
                                    id: 'ipCanDevices.lpDisplay.virtualDisplay.step.reorder.valid',
                                })
                            );

                            virtualDisplay.updateVirtualDisplaySteps(updateStepOrderResponse.data.steps);
                            setElements(flowChartFactory.getElements());

                            DrawerObserver.emit(DrawerObserverEvent.DRAWER_OPEN, DrawerObserverType.VIRTUAL_DISPLAY, {
                                color: data.color,
                                label: data.label,
                                display: props.display,
                                topology: data.topology,
                                virtualDisplay: virtualDisplay.getPreviewInformation(),
                            });
                        })
                        .catch(err => {
                            Alert.error(
                                intl.formatMessage(
                                    {
                                        id: 'ipCanDevices.lpDisplay.virtualDisplay.step.reorder.error',
                                    },
                                    { err }
                                )
                            );
                        });
                }
            }
        }
    };

    const onEvent = (event: DrawerObserverEvent, type: DrawerObserverType, data?: DataProps) => {
        setDrawerState({
            isOpen: event === DrawerObserverEvent.DRAWER_OPEN,
            type,
            data: data,
        });

        if (event === DrawerObserverEvent.DRAWER_CLOSE) {
            resetSelectedElement();
        }
    };

    const onSelectionChange = (flowElement: FlowElement[] | null) => {
        if (!flowElement) {
            DrawerObserver.emit(DrawerObserverEvent.DRAWER_CLOSE);
        } else {
            switch (flowElement[0].type) {
                case 'virtualDisplayNode': {
                    DrawerObserver.emit(
                        DrawerObserverEvent.DRAWER_OPEN,
                        DrawerObserverType.VIRTUAL_DISPLAY,
                        flowElement[0].data
                    );
                    break;
                }
                case 'topologyNode': {
                    DrawerObserver.emit(
                        DrawerObserverEvent.DRAWER_OPEN,
                        DrawerObserverType.TOPOLOGY,
                        flowElement[0].data
                    );
                    break;
                }
                default:
                    DrawerObserver.emit(DrawerObserverEvent.DRAWER_CLOSE);
            }
        }
    };

    const onLoad = (rfi: OnLoadParams) => {
        if (!reactflowInstance) {
            setReactflowInstance(rfi);
        }
    };

    const handleConnect = (connection: Edge | Connection) => {
        if (connection.source?.includes('virtualDisplay') && connection.target?.includes('topology')) {
            // setTriggerModalInformation({ isOpen: true, connection });
            ActionObserver.emit(ActionObserverEvent.ADD_VIRTUAL_DISPLAY_TO_TOPOLOGY, {
                connection,
            });
        }

        if (connection.source?.includes('topology') && connection.target?.includes('topology')) {
            setTriggerModalInformation({ isOpen: true, connection });
        }
    };

    return (
        <>
            <CreateVirtualDisplayModal
                display={props.display}
                show={isCreateVirtualDisplayModalOpen}
                onHide={() => setIsCreateVirtualDisplayModalOpen(false)}
            />

            <LPMatrixDisplayV2ConfigDrawer
                show={drawerState.isOpen}
                onHide={() => DrawerObserver.emit(DrawerObserverEvent.DRAWER_CLOSE)}
                data={drawerState.data}
                type={drawerState.type}
            />

            <CreateTopologyTriggerModal
                triggerInformation={triggerModalInformation}
                onHide={() => setTriggerModalInformation({ isOpen: false })}
                display={props.display}
            />

            <>
                {isLoading ? (
                    <Loader center backdrop />
                ) : (
                    <>
                        {elements.length > 0 && (
                            <div style={{ height: '80vh' }}>
                                <ReactFlow
                                    edgeTypes={edgeTypes}
                                    nodeTypes={nodeTypes}
                                    onLoad={onLoad}
                                    onlyRenderVisibleElements={true}
                                    defaultZoom={1}
                                    elements={elements}
                                    snapToGrid={true}
                                    snapGrid={[20, 20]}
                                    onSelectionChange={e => onSelectionChange(e)}
                                    elementsSelectable={true}
                                    minZoom={-2}
                                    onConnect={handleConnect}>
                                    <Background variant={BackgroundVariant.Lines} gap={1} />
                                    <Controls />
                                    <div
                                        data-cy="lpDisplays-updateTopologys-addVirtualDisplay"
                                        style={{ position: 'absolute', left: 10, top: 10, zIndex: 4 }}>
                                        <Button
                                            color="blue"
                                            onClick={() => setIsCreateVirtualDisplayModalOpen(true)}
                                            disabled={props.display.getVirtualDisplays().length >= 16}>
                                            <FontAwesomeIcon className="margin-right-5" icon={faPlusCircle} />{' '}
                                            <FormattedMessage id="ipCanDevices.lpDisplays.updateTopologys.addVirtualDisplay" />
                                        </Button>
                                    </div>
                                </ReactFlow>
                            </div>
                        )}
                    </>
                )}
            </>
        </>
    );
};
