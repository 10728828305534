import { LatLngExpression } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { ServiceMLConvertWSInterface } from '../../../handlers/Camera/PlaceCamera';
import { webSocketService } from '../../../redux/services';

export interface MLBox {
    confidence: string;
    class: number;
    points: LatLngExpression[];
}

export function useMLCameraPlace(mac: number) {
    const [mlBoxesActive, setMlBoxesActive] = React.useState(false);
    const [percentageActive, setPercentageActive] = React.useState(false);
    const [isStreaming, setIsStreaming] = React.useState(false);
    const [mlBoxes, setMLBoxes] = useState<MLBox[]>([]);

    useEffect(() => {
        webSocketService.joinRoom(`ip-camera_serviceML_${mac}`);
    }, []);

    const toggleServiceML = () => {
        if (!mlBoxesActive) {
            webSocketService.onEvent('ip-camera:serviceMlConvert', refServiceML.current);
            setMlBoxesActive(true);
        } else {
            // refServiceML = React.useRef(handleServiceML);
            webSocketService.offEvent('ip-camera:serviceMlConvert', refServiceML.current);
            setMlBoxesActive(false);
        }
    };

    const handleServiceML = (data: ServiceMLConvertWSInterface) => {
        if (data.target === mac && refStreaming.current) {
            const boxes: MLBox[] = [];

            data.bbox.forEach(box => {
                const points: LatLngExpression[] = [];
                const topLeft = box.bbox[0];
                const bottomRight = box.bbox[1];

                points.push([topLeft[1] * -1, topLeft[0]]); // Top left
                points.push([bottomRight[1] * -1, topLeft[0]]); // Top right
                points.push([bottomRight[1] * -1, bottomRight[0]]); // Bottom right
                points.push([topLeft[1] * -1, bottomRight[0]]); // Bottom left

                boxes.push({
                    confidence: (box.confidence * 100).toFixed(0),
                    class: box.class,
                    points: points,
                });
            });

            setMLBoxes(boxes);
        }
    };

    // HACK TO KEEP REFERENCE OF WEBSOCKET HANDLER
    const refServiceML = React.useRef(handleServiceML);
    const refStreaming = React.useRef(isStreaming);

    const togglePercentageActive = () => {
        setPercentageActive(!percentageActive);
    };

    const toggleStreaming = () => {
        setIsStreaming(!isStreaming);
        refStreaming.current = !refStreaming.current;
    }

    return {
        mlBoxesActive,
        percentageActive,
        isStreaming,
        mlBoxes,
        toggleServiceML,
        togglePercentageActive,
        toggleStreaming,
    };
}
