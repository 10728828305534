import moment from 'moment';

export function ipCanUpdateHandler(ipCan) {
    let config = ipCan.config;

    if (ipCan.version.includes('TCM') && config.length > 2) {
        config.pop();
    }

    return {
        autoSetScene: ipCan.autoSetScene,
        config: config,
        createdAt: moment(ipCan.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: ipCan.createdBy,
        creationInformations: `${ipCan.createdBy} - ${moment(ipCan.createdAt).format('DD/MM/YYYY HH:mm:ss')}`,
        gpioState: ipCan.gpioState,
        id: ipCan.id,
        ip: ipCan.ip,
        crcIsActiv: ipCan.crcIsActiv,
        label: ipCan.label,
        mac: ipCan.mac,
        macStr: ipCan.macStr,
        online: ipCan.online,
        serialNumber: ipCan.serialNumber,
        serialNumberStr: ipCan.serialNumberStr,
        updatedAt: moment(ipCan.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        updateInformations: `${ipCan.updatedBy} - ${moment(ipCan.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`,
        version: ipCan.version,
        lastOnlineStateDate: moment(ipCan.lastOnlineStateDate).isValid()
            ? moment(ipCan.lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss')
            : null,
    };
}
