import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';
import PlaceCamera from '../../../../handlers/Camera/PlaceCamera';
import PanelHeader from '../../../Custom/PanelHeader';
import { CameraPlaceInformationEditMode } from './CameraPlaceInformationEditMode';
import { CameraPlaceInformationViewMode } from './CameraPlaceInformationViewMode';

type Props = {
    place: PlaceCamera;
    reloadPlaces: () => void;
};

export const CameraPlaceViewInformation = (props: Props) => {
    const [isEditMode, setIsEditMode] = React.useState(false);

    const intl = useIntl();

    return (
        <Panel
            className="panel-small"
            shaded
            bordered
            bodyFill
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'camera.place.information' })}
                    buttons={[
                        <Button
                            key="maintenanceInformation-edit"
                            color="blue"
                            size="sm"
                            disabled={isEditMode}
                            onClick={() => setIsEditMode(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {isEditMode ? (
                <CameraPlaceInformationEditMode
                    place={props.place}
                    setViewMode={() => setIsEditMode(false)}
                    reloadPlaces={props.reloadPlaces}
                />
            ) : (
                <CameraPlaceInformationViewMode place={props.place} />
            )}
        </Panel>
    );
};
