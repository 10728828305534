import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag, TagGroup } from 'rsuite';

export default function displayValueCell(type, displayValue) {
    if (type === 0)
        return <FontAwesomeIcon data-cy="ipCanDisplays-noValue" icon={faTimesCircle} color="red" size="2x" />;

    return (
        <TagGroup data-cy="ipCanDisplays-type" data-tag="allowRowEvents">
            {generateTag('generic', type, displayValue)}
            {displayValue && displayValue.generic.free !== 65535 && (
                <Fragment>
                    {generateTag('pmr', type, displayValue)}
                    {generateTag('electrical', type, displayValue)}
                    {generateTag('family', type, displayValue)}
                </Fragment>
            )}
        </TagGroup>
    );
}

function generateTag(typeName, typeValue, displayValue) {
    let type = 0;

    const TCM_TYPE = {
        generic: {
            color: 'green',
        },
        pmr: {
            color: 'blue',
        },
        electrical: {
            color: 'cyan',
        },
        family: {
            color: 'violet',
        },
    };

    switch (typeName) {
        case 'generic': {
            type = 1;
            break;
        }
        case 'pmr': {
            type = 2;
            break;
        }
        case 'electrical': {
            type = 4;
            break;
        }
        case 'family': {
            type = 8;
            break;
        }
        default: {
            type = 0;
        }
    }

    if (displayValue && (typeValue & type) !== 0 && displayValue[typeName].free === -1)
        return (
            <Tag data-cy="ipCanDisplay-type-loading" data-tag="allowRowEvents" color={TCM_TYPE[typeName].color}>
                <FontAwesomeIcon data-tag="allowRowEvents" icon={faSpinner} spin />
            </Tag>
        );

    if (displayValue && (typeValue & type) !== 0)
        return (
            <Tag data-cy={`ipCanDisplays-value-${typeName}`} data-tag="allowRowEvents" color={TCM_TYPE[typeName].color}>
                {generateValue(displayValue[typeName].free)}
            </Tag>
        );

    return null;
}

function generateValue(value) {
    if (value === 10000) {
        return <FormattedMessage id="display.force.forcedFree" />;
    } else if (value === 65535) {
        return <FormattedMessage id="display.force.forcedClose" />;
    } else {
        return value;
    }
}
