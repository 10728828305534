import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Alert, Container } from 'rsuite';
import { WriteFontProgress, WritePictoProgress } from '../../handlers/pixelArt/pixelArtWS';
import { authActions } from '../../redux/actions';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import SecuredFragment from '../Auth/SecuredFragment';
import Sidenavbar from '../Navigation/sidenavbar';
import SidenavbarMobile from '../Navigation/sidenavbar-mobile';
import Topnav from '../Navigation/topnav';
import { WriteFontNotification } from '../PixelArtFontEditor/WriteFontNotification';
import { WritePictoNotification } from '../PixelArtPictoEditor/WritePictoNotification';
import { AppRouter } from './Router';

type Props = {
    auth: Record<string, any>;
    logout: Function;

} & WrappedComponentProps & RouteComponentProps;

type State = {
    expanded: boolean;
    drawerOpenned: boolean;
    mobile: boolean;
    disconnected: boolean;
    disconnectTime: number;
    hideNav: boolean;
    writeFontProgress: WriteFontProgress[];
    writePictoProgress: WritePictoProgress[];
    isOnMap: boolean;
};

let disconnectTimeout: ReturnType<typeof setTimeout>;
let keepAliveTimeout: ReturnType<typeof setTimeout>;

const mapDispatchToProps = (dispatch: Function) => ({
    logout: (): void => dispatch(authActions.logout(true)),
});

class Dashboard extends React.Component<Props, State> {
    socketInformation!: ReturnType<typeof setTimeout>;
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            drawerOpenned: false,
            mobile: false,
            disconnected: false,
            hideNav: false,
            disconnectTime: 60 * 60 * 1000,
            writeFontProgress: [],
            writePictoProgress: [],
            isOnMap: false,
        };

        this.handleClick = this.handleClick.bind(this);
        this.keepAlive = this.keepAlive.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();

        webSocketService.joinRoom('events');
        webSocketService.onEvent('event:play', data => {
            Alert.success('Event start : ' + data.name, 5000);
        });
        webSocketService.onEvent('event:resetTrigger', data => {
            Alert.success('Event resetTrigger : ' + data.name, 5000);
        });

        if (this.props.auth.loggedIn) {
            const isAdmin =
                this.props.auth?.user.roles.find(data => {
                    return data.name === 'Admin';
                }) || false;

            if (isAdmin) {
                webSocketService.joinRoom('lp-matrixDisplayV2');

                webSocketService.onEvent('lp-matrixDisplayV2:writeFontProgress', this.handleWriteFontProgress);
                webSocketService.onEvent('lp-matrixDisplayV2:writePictoProgress', this.handleWritePictoProgress);
            }
        }

        webSocketService.onDisconnected(this.disconnected);
        webSocketService.onConnected(this.connect);

        document.addEventListener('mousedown', this.handleClick);
        document.addEventListener('keydown', this.handleClick);

        axiosService.getAxios().get('/configuration/system/disconnectTime', { headers: authHeader() }).then((data) => {
            const finalDisconnectTime = (data.data ?? 24 * 60) * 60 * 1000;

            disconnectTimeout = setTimeout(() => {
                if (this.props.auth.loggedIn) {
                    Alert.info(this.props.intl.formatMessage({ id: 'app.disconnectedDueToInactivity' }));
                    this.props.logout();
                }
            }, finalDisconnectTime);

            this.setState({ disconnectTime: finalDisconnectTime });
        });

        this.keepAlive();
    }

    componentWillUnmount = () => {
        webSocketService.offEvent('event:play', () => { });
        webSocketService.offEvent('event:resetTrigger', () => { });
        webSocketService.offEvent('lp-matrixDisplayV2:writeFontProgress', () => { });
        webSocketService.offEvent('lp-matrixDisplayV2:writePictoProgress', () => { });

        document.removeEventListener('mousedown', this.handleClick);
        document.removeEventListener('keydown', this.handleClick);

        clearTimeout(disconnectTimeout);
        clearTimeout(keepAliveTimeout);

    };

    handleClick = () => {
        if (disconnectTimeout) {
            clearTimeout(disconnectTimeout);
        }

        disconnectTimeout = setTimeout(() => {
            if (this.props.auth.loggedIn) {
                Alert.info(this.props.intl.formatMessage({ id: 'app.disconnectedDueToInactivity' }));
                this.props.logout();
            }

            clearTimeout(disconnectTimeout);
        }, this.state.disconnectTime);
    }

    keepAlive = () => {
        if (keepAliveTimeout) clearTimeout(keepAliveTimeout);

        keepAliveTimeout = setTimeout(() => {
            axiosService.getAxios().get('/configuration/system/date', { headers: authHeader() }).then(() => {
                this.keepAlive();
            }).catch(err => {
                console.error(err);

                if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                    Alert.error(this.props.intl.formatMessage({ id: 'app.disconnected' }));
                    this.props.logout();
                } else {
                    this.keepAlive();
                }
            });
        }, 5000);
    }

    resize = () => {
        let currentHideNav = window.innerWidth <= 760;
        if (currentHideNav !== this.state.hideNav) {
            this.setState({ hideNav: currentHideNav });
        }
    };

    handleToggle = () => {
        this.setState({
            expanded: !this.state.expanded,
        });
    };

    closeSidenav = () => {
        this.setState({
            expanded: false,
        });
    };

    openDrawer = () => {
        this.setState({
            drawerOpenned: true,
        });
    };

    closeDrawer = () => {
        this.setState({
            drawerOpenned: false,
        });
    };

    disconnected = () => {
        this.setState({
            disconnected: true,
        });
    };

    connect = () => {
        if (this.socketInformation) {
            clearTimeout(this.socketInformation);
        }
        this.setState({
            disconnected: false,
        });
    };

    handleWriteFontProgress = (progress: WriteFontProgress) => {
        const writeFontProgressIndex = this.state.writeFontProgress.findIndex(fontProgress => {
            return fontProgress.id === progress.id;
        });

        if (writeFontProgressIndex === -1) {
            this.setState({
                writeFontProgress: [...this.state.writeFontProgress, progress],
            });
        } else {
            const newWriteFontProgress = [...this.state.writeFontProgress];
            newWriteFontProgress[writeFontProgressIndex] = progress;

            this.setState({
                writeFontProgress: newWriteFontProgress,
            });
        }

        if (progress.writeProgress.err) {
            Alert.error(progress.writeProgress.err, 5000);
        }

        if (progress.writeProgress.size === progress.writeProgress.totalSize) {
            Alert.success(this.props.intl.formatMessage({ id: 'writeFont.success' }), 5000);
        }

        if (progress.writeProgress.err || progress.writeProgress.size === progress.writeProgress.totalSize) {
            setTimeout(() => {
                const newWriteFontProgress = [...this.state.writeFontProgress];
                const writeFontProgressIndex = newWriteFontProgress.findIndex(fontProgress => {
                    return fontProgress.id === progress.id;
                });

                newWriteFontProgress.splice(writeFontProgressIndex, 1);

                this.setState({
                    writeFontProgress: newWriteFontProgress,
                });
            }, 10000);
        }
    };

    handleWritePictoProgress = (progress: WritePictoProgress) => {
        const writePictoProgressIndex = this.state.writePictoProgress.findIndex(fontProgress => {
            return fontProgress.id === progress.id;
        });

        if (writePictoProgressIndex === -1) {
            this.setState({
                writePictoProgress: [...this.state.writePictoProgress, progress],
            });
        } else {
            const newWritePictoProgress = [...this.state.writePictoProgress];
            newWritePictoProgress[writePictoProgressIndex] = progress;

            this.setState({
                writePictoProgress: newWritePictoProgress,
            });
        }

        if (progress.writeProgress.err) {
            Alert.error(progress.writeProgress.err, 5000);
        }

        if (progress.writeProgress.size === progress.writeProgress.totalSize) {
            Alert.success(this.props.intl.formatMessage({ id: 'writePicto.success' }), 5000);
        }

        if (progress.writeProgress.err || progress.writeProgress.size === progress.writeProgress.totalSize) {
            setTimeout(() => {
                const newWritePictoProgress = [...this.state.writePictoProgress];
                const writePictoProgressIndex = newWritePictoProgress.findIndex(fontProgress => {
                    return fontProgress.id === progress.id;
                });

                newWritePictoProgress.splice(writePictoProgressIndex, 1);

                this.setState({
                    writePictoProgress: newWritePictoProgress,
                });
            }, 10000);
        }
    };

    render() {
        if (this.props.auth.loggedIn) {
            return (
                <Fragment>
                    <Router>
                        <Loader show={this.props.auth.loggingOut} message="Déconnexion...">
                            <Container>
                                <Sidenavbar
                                    expanded={this.state.expanded}
                                    handleToggle={this.handleToggle}
                                    shouldDisplayCountersPart={true}
                                />

                                <SidenavbarMobile
                                    expanded={this.state.expanded}
                                    handleToggle={this.handleToggle}
                                    shouldDisplayCountersPart={true}
                                />

                                <Container>
                                    <div className="hide-on-desktop">
                                        <Topnav
                                            expanded={this.state.expanded}
                                            handleToggle={this.handleToggle}></Topnav>
                                    </div>

                                    <AppRouter auth={this.props.auth} expanded={this.state.expanded} />

                                    <SecuredFragment authorizedRoles={[]}>
                                        <div
                                            style={{
                                                width: '20vw',
                                                position: 'fixed',
                                                bottom: 0,
                                                right: 10,
                                                zIndex: 100000,
                                            }}>
                                            {this.state.writeFontProgress.map((progress, index) => (
                                                <WriteFontNotification writeFontProgress={progress} key={index} />
                                            ))}
                                            {this.state.writePictoProgress.map((progress, index) => (
                                                <WritePictoNotification writePictoProgress={progress} key={index} />
                                            ))}
                                        </div>
                                    </SecuredFragment>
                                </Container>
                            </Container>
                        </Loader>
                    </Router>
                </Fragment>
            );
        } else {
            return <Redirect to="/login" />;
        }
    }
}

function mapStateToProps(state) {
    const { auth } = state;

    return { auth };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Dashboard)));
