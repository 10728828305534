import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Drawer } from 'rsuite';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplay } from '../../../../handlers/lpDisplays/VirtualDisplay';
import LPVirtualDisplayInformations from './Drawer/LPVirtualDisplayInformations';
import LPVirtualDisplaySteps from './Drawer/LPVirtualDisplaySteps';
import DeleteVirtualDisplayModal from './modal/DeleteVirtualDisplayModal';

type Props = {
    show: boolean;
    onHide: Function;
    virtualDisplay?: VirtualDisplay;
    display: LPDisplay;
    updateValue: Function;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    showDeleteModal: boolean;
};

class VirtualDisplayDrawer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showDeleteModal: false,
        };
    }

    closeDeleteModal = (shouldUpdate: boolean = false) => {
        this.setState({
            showDeleteModal: false,
        });

        if (shouldUpdate) {
            this.props.updateValue();
        }
    };

    openDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    render() {
        if (this.props.virtualDisplay) {
            return (
                <Fragment>
                    <DeleteVirtualDisplayModal
                        show={this.state.showDeleteModal}
                        onHide={this.closeDeleteModal}
                        virtualDisplay={this.props.virtualDisplay.getPreviewInformation()}
                    />
                    <Drawer show={this.props.show} onHide={() => this.props.onHide()} size="md">
                        <Drawer.Header>
                            <Drawer.Title>{`${this.props.intl.formatMessage({
                                id: 'ipCanDevices.lpDisplay.virtualDisplay',
                            })} ${this.props.display.bus + 1} - ${this.props.display.deviceId} - ${
                                this.props.virtualDisplay?.getPosId() + 1
                            }`}</Drawer.Title>
                        </Drawer.Header>

                        <Drawer.Body className="drawer-body-without-avatar">
                            {this.props.virtualDisplay && (
                                <Fragment>
                                    <LPVirtualDisplayInformations
                                        display={this.props.virtualDisplay}
                                        updateValue={this.props.updateValue}
                                    />
                                    <LPVirtualDisplaySteps
                                        virtualDisplay={this.props.virtualDisplay}
                                        display={this.props.display}
                                        reloadDisplay={this.props.updateValue}
                                        isLoading={this.props.isLoading}
                                    />
                                </Fragment>
                            )}
                        </Drawer.Body>

                        <Drawer.Footer>
                            <Button
                                data-cy="lpDisplay-virtualDisplay-deletes"
                                onClick={this.openDeleteModal}
                                appearance="primary"
                                color="red">
                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.delete" />
                            </Button>
                        </Drawer.Footer>
                    </Drawer>
                </Fragment>
            );
        } else {
            return null;
        }
    }
}

export default injectIntl(VirtualDisplayDrawer);
