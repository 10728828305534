export const usersModalConstants = {
    OPEN_CREATE_MODAL_SUCCESS: 'USERS_OPEN_CREATE_MODAL_SUCCESS',
    CLOSE_CREATE_MODAL: 'USERS_CLOSE_CREATE_MODAL',

    OPEN_DELETE_MODAL_REQUEST: 'USERS_OPEN_DELETE_MODAL_REQUEST',
    OPEN_DELETE_MODAL_SUCCESS: 'USERS_OPEN_DELETE_MODAL_SUCCESS',
    OPEN_DELETE_MODAL_FAILURE: 'USERS_OPEN_DELETE_MODAL_FAILURE',
    CLOSE_DELETE_MODAL: 'USERS_CLOSE_DELETE_MODAL',

    OPEN_VIEW_DRAWER_REQUEST: 'USERS_OPEN_VIEW_DRAWER_REQUEST',
    OPEN_VIEW_DRAWER_SUCCESS: 'USERS_OPEN_VIEW_DRAWER_SUCCESS',
    OPEN_VIEW_DRAWER_FAILURE: 'USERS_OPEN_VIEW_DRAWER_FAILURE',
    CLOSE_VIEW_DRAWER: 'USERS_CLOSE_VIEW_MODAL',
};
