import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Layer, Rect, Stage } from 'react-konva';
import { FontValueInterface } from '..';

type Props = {
    fonts: FontValueInterface[];
    selectedFontIndex: number;
    fontSize: number;
    handleClick: (column: number, bitValue: number) => void;
};

const PREVIEW_SIZE = 850;

export const FontEditor = (props: Props) => {
    const font = props.fonts[props.selectedFontIndex];

    let squareSize: number = PREVIEW_SIZE / props.fontSize;

    const handleClick = (event: KonvaEventObject<MouseEvent>) => {
        const column = parseInt(event.target.attrs.id);
        const line = parseInt(event.target.attrs.name);

        props.handleClick(column, line);
    };

    if (font.value.length > props.fontSize) {
        squareSize = PREVIEW_SIZE / font.value.length;
    }

    return (
        <>
            <h3 className="text-center margin-bottom-15" style={{ color: 'black' }}>
                <FormattedMessage id="editFont.font" /> - {font.name}{' '}
                <span style={{ fontSize: '20px' }}>({props.selectedFontIndex})</span>
            </h3>
            <div data-cy="font-canvas">
                <Stage width={squareSize * font.value.length} height={PREVIEW_SIZE}>
                    {squareSize !== Infinity && squareSize !== 0 && (
                        <Layer>
                            {font.value.map((column, columnIndex) => {
                                return column.map((pixel, pixelIndex) => (
                                    <Rect
                                        x={columnIndex * squareSize}
                                        y={pixelIndex * squareSize}
                                        strokeWidth={1}
                                        stroke="grey"
                                        width={squareSize}
                                        height={squareSize}
                                        fill={pixel ? '#000' : '#FFF'}
                                        id={columnIndex.toString()}
                                        name={pixelIndex.toString()}
                                        onClick={handleClick}
                                    />
                                ));
                            })}
                        </Layer>
                    )}
                </Stage>
            </div>
        </>
    );
};
