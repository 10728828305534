type Props = {
    color: string;
};

export const TwoLinesCardAltIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} width="512" height="96" />
            <rect fill={color} y="448" width="512" height="64" />
            <rect fill={color} y="352" width="512" height="64" />
            <rect fill={color} width="32" height="512" />
            <rect fill={color} x="480" y="7.6294e-6" width="32" height="512" />
            <rect fill={color} x="64" y="128" width="128" height="160" />
            <rect fill={color} x="256" y="256" width="160" height="32" />
            <rect fill={color} x="256" y="192" width="96" height="32" />
        </g>
    </svg>
);
