export class LPDisplayTopology {
    private _topologys: TopologyInterface[];

    constructor(topology_: LPDisplayTopologyInterface) {
        this._topologys = topology_.topologys;
    }

    public getTopologys(): TopologyInterface[] {
        return this._topologys;
    }

    public websocketUpdateLPDisplayTopology(lpDisplayTopology: LPDisplayTopologyInterface): boolean {
        this._topologys = lpDisplayTopology.topologys;
        return false;
    }

    public updateTopologies(topologiesConfig: LPDisplayTopologyInterface) {
        this._topologys = [...topologiesConfig.topologys];
    }
}

export interface LPDisplayTopologyInterface {
    topologys: TopologyInterface[];
}

export interface TopologyInterface {
    triggers: TriggerInterface[];
    virtualDisplays: number[];
}

export interface TriggerInterface {
    trigger: number;
    value: number;
    next: number;
    source: number;
}
