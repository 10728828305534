import { FlowElement } from 'react-flow-renderer';
import { LPVirtualDisplayColors } from '../../../../../handlers/ipCanDevices/const/colors';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../handlers/lpDisplays/VirtualDisplay';
import FlowNode from './FlowNode';
import { virtualDisplayXPosition, virtualDisplayYPosition } from './FlowNodeExporter';
import { ColoredPointsInterface } from './TopologyFlowNode';

export default class VirtualDisplayFlowNode extends FlowNode {
    private _label: string;
    private _virtualDisplay: VirtualDisplayPreviewInformation;
    private _virtualDisplayIndex: number;
    private _sourceTopologyColor: ColoredPointsInterface;

    constructor(
        id: string,
        label: string,
        virtualDisplayIndex: number,
        display: LPDisplay,
        virtualDisplay: VirtualDisplayPreviewInformation,
        sourceTopologyColor: ColoredPointsInterface
    ) {
        super(id, display);
        this._label = label;
        this._virtualDisplay = virtualDisplay;
        this._virtualDisplayIndex = virtualDisplayIndex;
        this._sourceTopologyColor = sourceTopologyColor;
    }

    public toRender(): FlowElement {
        const yPosition = virtualDisplayYPosition();
        const xPosition = virtualDisplayXPosition();

        return {
            id: this.getId(),
            type: 'virtualDisplayNode',
            data: {
                label: this._label,
                display: this._display,
                virtualDisplay: this._virtualDisplay,
                color: LPVirtualDisplayColors[this._virtualDisplayIndex],
                virtualDisplayIndex: this._virtualDisplayIndex,
                sourceTopologyColor: this._sourceTopologyColor,
                isFirst: this._virtualDisplayIndex === 0,
                isLast: this._virtualDisplayIndex === this._display.getVirtualDisplays().length - 1,
            },
            position: {
                x:
                    xPosition.initialPositionX +
                    (xPosition.baseTopologyWidth +
                        xPosition.columnWidth * this._display.getBaseConfig().getNbMatrixX() * 2 +
                        xPosition.gapX),
                y:
                    yPosition.initialPositionY +
                    (yPosition.baseTopologyHeight +
                        yPosition.lineHeight * this._display.getBaseConfig().getNbMatrixY() +
                        yPosition.gapY) *
                        this._virtualDisplayIndex,
            },
        };
    }
}
