import { IntlShape } from 'react-intl';

export const selectFontSizeData = () => [
    {
        label: '16px',
        value: 16,
    },
    {
        label: '32px',
        value: 32,
    },
    {
        label: '48px',
        value: 48,
    },
    {
        label: '64px',
        value: 64,
    },
    {
        label: '80px',
        value: 80,
    },
];

export const selectPictoSizeData = (intl: IntlShape) => [
    {
        label: '16px',
        value: 1,
    },
    {
        label: '32px',
        value: 2,
    },
    // {
    //     label: '48px',
    //     value: 3,
    // },
    {
        label: intl.formatMessage({ id: 'editPicto.pictoRGB' }),
        value: 10,
    },
];

export const downloadFile = (data: string, filename: string) => {
    const blob = new Blob([data], { type: 'text/json' });

    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);

    const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    a.dispatchEvent(clickEvent);
    a.remove();
};

export const minutesToHours = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const min = minutes % 60;

    // Should return two digits
    if (min < 10) {
        return `${hours}:0${min}`;
    }

    return `${hours}:${min}`;
};
