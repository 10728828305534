import * as gju from 'geojson-utils';
import L, { LatLngExpression } from 'leaflet';
import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Loader, Modal } from 'rsuite';
import { interpret } from 'xstate';
import { DrawerInformationInterface } from '..';
import Camera from '../../../handlers/Camera/Camera';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { MapCameraSensor } from '../../../handlers/map/MapCameraSensor';
import { MapLPSensor } from '../../../handlers/map/MapLPSensor';
import { MapTCMSensor } from '../../../handlers/map/MapTCMSensor';
import { SensorPlaceType } from '../../../handlers/sensorPlaceType/SensorPlaceType';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import { PlaceMachine } from '../Hooks/PlaceMachine';
import LPSensorPlaceIcon from '../Icons/LPSensor/LPSensorPlaceIcon';
import { PlaceCameraIcon } from '../Icons/PlaceCamera/PlaceCameraIcon';
import TCMSensorPlaceIcon from '../Icons/TCMSensor/TCMSensorPlaceIcon';
import EditPlacesIdModal from '../Modal/TCMSensor/EditPlacesIdModal';
import { CreatePlacesModal } from './Modal/CreatePlacesModal';

type Props = {
    levelId: number;
    placeTypes: SensorPlaceType[];
    controllers: IpCan[];
    reloadSensors: Function;
    sensors: MapTCMSensor[];
    lpSensors: MapLPSensor[];
    cameraSensors: MapCameraSensor[];
    cameras: Camera[];
    calibration: number;
    calibrationCm: number;
    editMode: boolean;
    isEdit: boolean;
    sensorStateDisplayed: boolean;
    validCreation?: Function;
    cancelCreation?: Function;
    show?: boolean;
    loading?: boolean;
    onChange?: Function;
    values?: Record<string, any>;
    error?: boolean;
    errorMessage?: string;
    service: any;
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
} & WrappedComponentProps;

type State = {
    current: Record<string, any>;
    showEditPlacesIdModalOpen: boolean;
    blinkState: number;
    isEditMode: boolean;
};

// type States = {
//     placeTypeSelector: Array<Record<string, any>>;
//     controllerSelector: Array<Record<string, any>>;
//     sensorTypeSelector: Array<Record<string, any>>;
//     busSelector: Array<Record<string, any>>;
// };

let blinkInterval;
const BLINKTIME = 1000;

class PlacesLayer extends React.Component<Props, State> {
    service: any;
    constructor(props) {
        super(props);

        this.state = {
            current: PlaceMachine.initialState,
            showEditPlacesIdModalOpen: false,
            blinkState: 0,
            isEditMode: false,
            // calibrationValue: 0,
        };

        this.service = interpret(
            PlaceMachine.withContext({ ...PlaceMachine.context, intl: this.props.intl, levelId: this.props.levelId })
        ).onTransition(current => this.onTransition(current));
    }

    componentDidMount() {
        this.service.start();

        blinkInterval = setInterval(() => {
            this.setState({
                blinkState: parseInt((new Date().getTime() / 1000).toFixed(0)),
            });
        }, BLINKTIME);
    }

    componentWillUnmount() {
        this.service.stop();
    }

    onTransition = current => {
        this.setState(
            {
                current,
            },
            () => {
                if (current.matches('reload')) {
                    this.props.reloadSensors('sensors');
                    this.service.send('RELOADED');
                }
            }
        );
    };

    cancelCreation = () => {
        this.service.send('CANCEL_CREATE_PLACE');
    };

    validCreation = () => {
        this.service.send('VALID_CREATE_PLACE', {
            calibrationCm: this.props.calibrationCm,
            calibration: this.props.calibration,
        });

        setTimeout(function () {
            let element: HTMLElement = document.getElementsByClassName('leaflet-draw-draw-polyline')[0] as HTMLElement;
            if (element) element.click();
        }, 2000);
    };

    created = event => {
        if (event.layerType === 'polyline') {
            this.lineCreated(event);
        } else if (event.layerType === 'polygon') {
            this.polygonCreated(event);
        }
    };

    polygonCreated = event => {
        let coordPoints: Array<Array<number>> = [];

        for (let p in event.layer.editing.latlngs[0][0]) {
            coordPoints.push([event.layer.editing.latlngs[0][0][p].lat, event.layer.editing.latlngs[0][0][p].lng]);
        }

        let sensors: Array<{ sensor: MapLPSensor | MapTCMSensor; type: string }> = [];

        for (let s in this.props.sensors) {
            let sensor: MapTCMSensor = this.props.sensors[s];

            if (
                gju.pointInPolygon(
                    { type: 'Point', coordinates: sensor.geoJSON },
                    { type: 'Polygon', coordinates: [coordPoints] }
                )
            ) {
                sensors.push({ sensor, type: 'tcm' });
            }
        }

        for (let s in this.props.lpSensors) {
            let sensor: MapLPSensor = this.props.lpSensors[s];

            if (
                gju.pointInPolygon(
                    { type: 'Point', coordinates: sensor.geoJSON },
                    { type: 'Polygon', coordinates: [coordPoints] }
                )
            ) {
                sensors.push({ sensor, type: 'lp' });
            }
        }

        event.layer.remove();

        this.service.send('EDIT_PLACES_FORM', { sensors });
    };

    hideEditPlacesIdModal = () => {
        this.service.send('CANCEL_PLACE_IDS_FORM');
    };

    lineCreated = event => {
        let precision = 1;
        let lat0 = event.layer._latlngs[0].lat * precision;
        let lat1 = event.layer._latlngs[1].lat * precision;
        let lng0 = event.layer._latlngs[0].lng * precision;
        let lng1 = event.layer._latlngs[1].lng * precision;

        const geojson = { lat0, lng0, lat1, lng1 };

        event.layer.remove();

        this.service.send('CREATE_PLACE', { geojson });

        return false;
    };

    deleted = event => {
        let sensors: Array<{ id: number; type: string }> = [];

        for (let l in event.layers._layers) {
            if (event.layers._layers[l].options.name.includes('lp')) {
                let id = event.layers._layers[l].options.name.replace('-lp', '');

                sensors.push({ id: parseInt(id), type: 'lp' });
            } else if (event.layers._layers[l].options.name.includes('place-camera')) {
                let id = event.layers._layers[l].options.name.replace('-place-camera', '');

                sensors.push({ id: parseInt(id), type: 'place-camera' });
            } else {
                sensors.push({ id: parseInt(event.layers._layers[l].options.name), type: 'tcm' });
            }
        }

        this.service.send('DELETE_PLACE', { sensors });
    };

    edited = event => {
        let sensors: Array<{ id: number; type: string; geoJSON: LatLngExpression }> = [];

        for (let l in event.layers._layers) {
            if (event.layers._layers[l].options.name.includes('lp')) {
                let id = event.layers._layers[l].options.name.replace('-lp', '');

                sensors.push({
                    id: parseInt(id),
                    type: 'lp',
                    geoJSON: [event.layers._layers[l]._latlng.lat, event.layers._layers[l]._latlng.lng],
                });
            } else if (event.layers._layers[l].options.name.includes('place-camera')) {
                let id = event.layers._layers[l].options.name.replace('-place-camera', '');

                sensors.push({
                    id: parseInt(id),
                    type: 'place-camera',
                    geoJSON: [event.layers._layers[l]._latlng.lat, event.layers._layers[l]._latlng.lng],
                });
            } else {
                sensors.push({
                    id: parseInt(event.layers._layers[l].options.name),
                    type: 'tcm',
                    geoJSON: [event.layers._layers[l]._latlng.lat, event.layers._layers[l]._latlng.lng],
                });
            }
        }

        if (sensors.length > 0) {
            this.service.send('EDIT_PLACE', { sensors });
        }
    };

    onChange = formValue => {
        const value = {
            ...formValue,
            nbOfPlaces: parseInt(formValue.nbOfPlaces),
            nbPlaceBetweenColumn: parseInt(formValue.nbPlaceBetweenColumn),
            columnWitdh: parseInt(formValue.columnWidth),
            id: parseInt(formValue.id),
            increment: parseInt(formValue.increment),
            prefixIncrement: parseInt(formValue.prefixIncrement),
            prefixNumber: formValue.prefixNumber && parseInt(formValue.prefixNumber),
        };

        this.service.send('EDIT_CREATE_PLACE_FORM', { value });
    };

    startEdition = () => {
        this.service.send('START_EDIT_PLACE_MODE');
        this.setState({ isEditMode: true });
    };

    stopEdition = () => {
        this.service.send('CANCEL_EDIT_PLACE_MODE');
        this.setState({ isEditMode: false });
    };

    render = () => {
        const { current } = this.state;

        // @ts-ignore
        L.EditToolbar.Delete.include({
            removeAllLayers: false,
        });

        return (
            <FeatureGroup>
                <Modal backdrop={'static'} show={current.context.loading}>
                    <Modal.Body className="text-center">
                        <Loader content="Chargement des places..." vertical />
                    </Modal.Body>
                </Modal>

                {this.props.editMode && (
                    <Fragment>
                        <EditControl
                            position="topright"
                            onCreated={this.created}
                            onDeleted={this.deleted}
                            onEdited={this.edited}
                            onEditStart={this.startEdition}
                            onEditStop={this.stopEdition}
                            onDeleteStart={this.startEdition}
                            onDeleteStop={this.stopEdition}
                            draw={{
                                rectangle: false,
                                circle: false,
                                marker: false,
                                circlemarker: false,
                                polygon: {
                                    shapeOptions: {
                                        color: 'red',
                                        weight: 4,
                                    },
                                    icon: new L.DivIcon({
                                        iconSize: new L.Point(10, 10),
                                    }),
                                    showLength: false,
                                    guidelineDistance: 10,
                                },
                                polyline: {
                                    shapeOptions: {
                                        color: 'red',
                                        weight: 4,
                                    },
                                    icon: new L.DivIcon({
                                        iconSize: new L.Point(10, 10),
                                    }),
                                    showLength: false,
                                    guidelineDistance: 10,
                                },
                            }}
                            edit={{
                                delete: {
                                    removeAllLayers: false,
                                },
                            }}
                        />

                        <CreatePlacesModal
                            cancelCreation={this.cancelCreation}
                            validCreation={this.validCreation}
                            values={current.context.createPlaceFormValue}
                            show={current.context.createPlacesModalOpen}
                            loading={current.context.creatingPlace}
                            cm={current.context.cm}
                            onChange={this.onChange}
                            placeTypes={this.props.placeTypes}
                            controllers={this.props.controllers}
                            error={current.context.creationError}
                            errorMessage={current.context.creationErrorText}
                            cameras={this.props.cameras}
                        />

                        <EditPlacesIdModal
                            sensors={this.props.sensors}
                            selectedSensors={current.context.placesIdToEdit}
                            loading={current.context.updatingPlacesId}
                            formValue={current.context.editPlacesIdsFormValue}
                            show={current.context.editPlacesIdModalOpen}
                            onHide={this.hideEditPlacesIdModal}
                            handleChange={value => this.service.send('EDIT_PLACE_IDS_FORM', { value })}
                            handleSubmit={() => this.service.send('VALID_PLACE_IDS_FORM')}
                            context={current.context}
                        />
                    </Fragment>
                )}

                <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.VIEW_LIST]}>
                    {this.props.sensors.map(mapTCMSensor => {
                        return (
                            <TCMSensorPlaceIcon
                                sensor={mapTCMSensor.getMapSensor()}
                                circleRadius={(this.props.calibration / 2) * 0.65}
                                controllers={this.props.controllers}
                                placeTypes={this.props.placeTypes}
                                reloadSensors={this.props.reloadSensors}
                                editMode={this.props.isEdit}
                                sensorStateDisplayed={this.props.sensorStateDisplayed}
                                key={`tcmSensor-${mapTCMSensor.id}`}
                                service={this.props.service}
                                blinkState={this.state.blinkState}
                                drawerInformation={this.props.drawerInformation}
                                openDrawer={this.props.openDrawer}
                                closeDrawer={this.props.closeDrawer}
                                isEditingSensors={this.state.isEditMode}
                            />
                        );
                    })}
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.LPSensor.VIEW_LIST]}>
                    {this.props.lpSensors.map(mapLpSensor => {
                        return (
                            <LPSensorPlaceIcon
                                sensor={mapLpSensor.getMapSensor()}
                                circleRadius={(this.props.calibration / 2) * 0.65}
                                controllers={this.props.controllers}
                                placeTypes={this.props.placeTypes}
                                editMode={this.props.isEdit}
                                sensorStateDisplayed={this.props.sensorStateDisplayed}
                                key={`lpSensor-${mapLpSensor.sensor.id}`}
                                service={this.props.service}
                                blinkState={this.state.blinkState}
                                drawerInformation={this.props.drawerInformation}
                                openDrawer={this.props.openDrawer}
                                closeDrawer={this.props.closeDrawer}
                                isEditingSensors={this.state.isEditMode}
                            />
                        );
                    })}
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.placesCamera.VIEW_LIST]}>
                    {this.props.cameraSensors.map(mapCameraSensor => {
                        return (
                            <PlaceCameraIcon
                                sensor={mapCameraSensor.getMapCameraSensor()}
                                placeTypes={this.props.placeTypes}
                                circleRadius={(this.props.calibration / 2) * 0.65}
                                cameras={this.props.cameras}
                                blinkState={this.state.blinkState}
                                closeDrawer={this.props.closeDrawer}
                                openDrawer={this.props.openDrawer}
                                drawerInformation={this.props.drawerInformation}
                                key={`camera-${mapCameraSensor.getMapCameraSensor().id}`}
                                service={this.props.service}
                                isEditingSensors={this.state.isEditMode}
                                editMode={this.props.isEdit}
                                sensorStateDisplayed={this.props.sensorStateDisplayed}
                                reloadSensors={this.props.reloadSensors}
                            />
                        );
                    })}
                </SecuredFragment>
            </FeatureGroup>
        );
    };
}

export default injectIntl(PlacesLayer);
