import React from 'react';
import { Col, FlexboxGrid, Progress, Tooltip, Whisper } from 'rsuite';

type Props = {
    label: string;
    total: number;
    free: number;
    occupied: number;
    color?: string;
    size?: number;
};

export default class ProgressComponent extends React.PureComponent<Props> {
    render() {
        return (
            <FlexboxGrid align="middle" justify="space-between">
                <FlexboxGrid.Item componentClass={Col} xs={5}>
                    <Whisper trigger="hover" placement="right" speaker={<Tooltip>{this.props.label}</Tooltip>}>
                        <p className="text-bold">{this.props.label}</p>
                    </Whisper>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={4}>
                    <div style={{ height: 'fit-content', width: '100%', backgroundColor: '#F44336', color: '#FFF' }}>
                        <p className="text-center bold">
                            {this.props.occupied}{' '}
                            <span className="small-text-white">
                                {parseInt(((this.props.occupied / this.props.total) * 100).toFixed(0)) || 0}%
                            </span>
                        </p>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={1}>
                    <p className="text-center"> - </p>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={3}>
                    <div
                        style={{
                            height: 'fit-content',
                            width: '100%',
                            backgroundColor: this.props.color || '#008000',
                            color: '#FFF',
                        }}>
                        <p className="text-center bold">{this.props.free}</p>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={9}>
                    <Progress.Line
                        className="progress-shadow"
                        showInfo={false}
                        strokeWidth={this.props.size}
                        strokeColor="#F44336"
                        trailColor={this.props.color || '#008000'}
                        percent={parseInt(((this.props.occupied / this.props.total) * 100).toFixed(0)) || 0}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={2}>
                    <p className="bold text-center">{this.props.total}</p>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        );
    }
}
