import History from './History';

export default class PlaceCameraHistoricHandler extends History {
    _idPlaceCamera: number;
    _placeCameraName: string;

    constructor(historyObject) {
        super(historyObject);
        const { idPlaceCamera, placeCameraName } = historyObject;

        this._idPlaceCamera = idPlaceCamera;
        this._placeCameraName = placeCameraName;
    }

    public get idPlaceCamera() {
        return this._idPlaceCamera;
    }

    public get placeCameraName() {
        return this._placeCameraName;
    }

    getOption(): any {
        return this.option;
    }

    getHistory(): History_I {
        return {
            id: this.getId(),
            user: this.getUser(),
            createdAt: this.getCreatedAt(),
            createdBy: this.getCreatedBy(),
            desc: this.getDesc(),
            option: this.getOption(),
            idPlaceCamera: this.idPlaceCamera,
            placeCameraName: this.placeCameraName,
            disabled: this.shouldBeDisabled(),
        };
    }

    private shouldBeDisabled(): boolean {
        let result = true;

        return result;
    }
}

interface History_I {
    id: number;
    user: string;
    createdAt: string;
    createdBy: string;
    desc: string;
    option: Record<string, any>;
    idPlaceCamera: number;
    placeCameraName: string;
    disabled: boolean;
}
