class VehicleCounterObserverClass {
    private vehicleCounterObeservers: Array<any>;

    constructor() {
        this.vehicleCounterObeservers = [];
    }

    subscribe(fn: CallableFunction): void {
        this.vehicleCounterObeservers.push(fn);
    }

    emit(): void {
        this.vehicleCounterObeservers.forEach(observer => {
            observer.call();
        });
    }

    unsubscribe(fn: CallableFunction): void {
        this.vehicleCounterObeservers = this.vehicleCounterObeservers.filter(observer => observer !== fn);
    }
}

const VehicleCounterObserver = new VehicleCounterObserverClass();
export { VehicleCounterObserver };
