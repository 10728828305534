import React from 'react';
import DataTable, { TableProps } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Loader, Placeholder } from 'rsuite';

type Props = WrappedComponentProps & TableProps<any>;

type State = {
    histories: Array<Record<string, any>>;
    isLoading: boolean;
    hasError: boolean;
    users: Array<Record<string, any>>;
    formValues: FormValue;
};

type FormValue = {
    user: Record<string, any> | null;
    before: Date | null;
    after: Date | null;
    nbElements: number;
};

const customStyles = {
    headRow: {
        style: {
            backgroundColor: '#757575',
            minHeight: '56px',
        },
    },
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 500,
            color: '#FFF',
        },
        activeSortStyle: {
            color: '#BDBDBD',
            '&:focus': {
                outline: 'none',
            },
            '&:hover:not(:focus)': {
                color: '#BDBDBD',
            },
        },
        inactiveSortStyle: {
            '&:focus': {
                outline: 'none',
                color: '#BDBDBD',
            },
            '&:hover': {
                color: '#BDBDBD',
            },
        },
    },
};
class HistoryTable extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            histories: [],
            isLoading: true,
            hasError: false,
            users: [],
            formValues: {
                user: null,
                before: null,
                after: null,
                nbElements: 100,
            },
        };
    }

    render() {
        return (
            <DataTable
                {...this.props}
                pagination
                paginationPerPage={25}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={{
                    rowsPerPageText: this.props.intl.formatMessage({
                        id: 'rowsPerPage',
                    }),
                    rangeSeparatorText: this.props.intl.formatMessage({
                        id: 'rangeSeparator',
                    }),
                }}
                customStyles={customStyles}
                progressComponent={
                    <Placeholder.Grid rows={8} columns={4}>
                        <Loader center size="lg" content={<FormattedMessage id="loading" />} />
                    </Placeholder.Grid>
                }
                persistTableHead
                highlightOnHover
                striped
            />
        );
    }
}

export default injectIntl(HistoryTable);
