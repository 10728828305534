import forceElement from './Desc/forceElement';
import offline from './Desc/offline';
import online from './Desc/online';
import updateCounters from './Desc/updateCounters';
import updateMaintenanceState from './Desc/updateMaintenanceState';
import updateSubstractCounters from './Desc/updateSubstractCounters';
import updateCountersAssign from './Desc/updateCountersAssign';
import {
    lpMatrixDisplayV2_readUserTextsValue,
    lpMatrixDisplayV2_readDictionnaryValue,
    lpMatrixDisplayV2_sendAllBasicConfig,
    lpMatrixDisplayV2_sendBasicConfig,
    lpMatrixDisplayV2_sendCounterConfig,
    lpMatrixDisplayV2_sendDBConfig,
    lpMatrixDisplayV2_sendKeyTextConfig,
    lpMatrixDisplayV2_sendMatrixOff,
    lpMatrixDisplayV2_sendMatrixOn,
    lpMatrixDisplayV2_sendReboot,
    lpMatrixDisplayV2_sendReloadConfig,
    lpMatrixDisplayV2_sendResetMemory,
    lpMatrixDisplayV2_sendTopologyConfig,
    lpMatrixDisplayV2_sendTXTConfig,
    lpMatrixDisplayV2_sendUserTextConfig,
    lpMatrixDisplayV2_sendVirtualDisplayConfig,
    lpMatrixDisplayV2_updateBasicConfig,
    lpMatrixDisplayV2_updateTopologyConfig,
    lpMatrixDisplayV2_updateUserTextConfig,
    lpMatrixDisplayV2_updateVirtualDisplayConfig,
    lpMatrixDisplayV2_updateKeyTextConfig,
} from './Desc/lpMatrixDisplayV2Desc';

// wait for online, total and db as data
// wait for controler online or not
export enum LPMATRIXDISPLAYV2_EVENTS {
    LPMATRIXDISPLAYV2_ONLINE = 'online',
    LPMATRIXDISPLAYV2_OFFLINE = 'offline',
    LPMATRIXDISPLAYV2_UPDATE_BASIC_CONFIG = 'update basic config',
    LPMATRIXDISPLAYV2_UPDATE_TOPOLOGY_CONFIG = 'update topology config',
    LPMATRIXDISPLAYV2_UPDATE_VIRTUAL_DISPLAY_CONFIG = 'update virtual displays config',
    LPMATRIXDISPLAYV2_UPDATE_KEY_TEXT_CONFIG = 'update key text config',
    LPMATRIXDISPLAYV2_UPDATE_USER_TEXT_CONFIG = 'update user text config',
    LPMATRIXDISPLAYV2_UPDATE_COUNTERS = 'update counters',
    LPMATRIXDISPLAYV2_UPDATE_SUBSTRACT_COUNTERS = 'update substract counters',
    LPMATRIXDISPLAYV2_UPDATE_COUNTERS_ASSIGN = 'update counters assign',
    LPMATRIXDISPLAYV2_UPDATE_MAINTENANCE_STATE = 'update maintenance state',
    LPMATRIXDISPLAYV2_FORCE_DISPLAY = 'force display',
    LPMATRIXDISPLAYV2_SEND_DB_CONFIG = 'send DB config',
    LPMATRIXDISPLAYV2_SEND_TXT_CONFIG = 'send text config',
    LPMATRIXDISPLAYV2_SEND_RELOAD_CONFIG = 'send reload config',
    LPMATRIXDISPLAYV2_SEND_RESET_MEMORY = 'send reset memory',
    LPMATRIXDISPLAYV2_SEND_REBOOT = 'send reboot',
    LPMATRIXDISPLAYV2_SEND_MATRIX_OFF = 'send matrix OFF',
    LPMATRIXDISPLAYV2_SEND_MATRIX_ON = 'send matrix ON',
    LPMATRIXDISPLAYV2_SEND_KEY_TEXT_CONFIG = 'send key text config',
    LPMATRIXDISPLAYV2_SEND_USER_TEXT_CONFIG = 'send user text config',
    LPMATRIXDISPLAYV2_SEND_COUNTER_CONFIG = 'send counter config',
    LPMATRIXDISPLAYV2_SEND_VIRTUAL_DISPLAY_CONFIG = 'send virtual display config',
    LPMATRIXDISPLAYV2_SEND_BASIC_CONFIG = 'send basic config',
    LPMATRIXDISPLAYV2_SEND_ALL_BASIC_CONFIG = 'send all basic config',
    LPMATRIXDISPLAYV2_SEND_TOPOLOGY_CONFIG = 'send topology config',
    LPMATRIXDISPLAYV2_READ_DICTIONNARY_VALUE = 'read dictionnary value',
    LPMATRIXDISPLAYV2_READ_USER_TEXTS_VALUE = 'read user texts value',
}

export default function LPMatrixDisplayV2DescCell(props: LPMATRIXDISPLAYV2_EVENTS) {
    switch (props) {
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_ONLINE:
            return online();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_OFFLINE:
            return offline();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_MAINTENANCE_STATE:
            return updateMaintenanceState();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_FORCE_DISPLAY:
            return forceElement();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_COUNTERS:
            return updateCounters();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_SUBSTRACT_COUNTERS:
            return updateSubstractCounters();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_COUNTERS_ASSIGN:
            return updateCountersAssign();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_BASIC_CONFIG:
            return lpMatrixDisplayV2_updateBasicConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_TOPOLOGY_CONFIG:
            return lpMatrixDisplayV2_updateTopologyConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_VIRTUAL_DISPLAY_CONFIG:
            return lpMatrixDisplayV2_updateVirtualDisplayConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_KEY_TEXT_CONFIG:
            return lpMatrixDisplayV2_updateKeyTextConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_UPDATE_USER_TEXT_CONFIG:
            return lpMatrixDisplayV2_updateUserTextConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_DB_CONFIG:
            return lpMatrixDisplayV2_sendDBConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_TXT_CONFIG:
            return lpMatrixDisplayV2_sendTXTConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_RELOAD_CONFIG:
            return lpMatrixDisplayV2_sendReloadConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_RESET_MEMORY:
            return lpMatrixDisplayV2_sendResetMemory();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_REBOOT:
            return lpMatrixDisplayV2_sendReboot();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_MATRIX_OFF:
            return lpMatrixDisplayV2_sendMatrixOff();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_MATRIX_ON:
            return lpMatrixDisplayV2_sendMatrixOn();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_KEY_TEXT_CONFIG:
            return lpMatrixDisplayV2_sendKeyTextConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_USER_TEXT_CONFIG:
            return lpMatrixDisplayV2_sendUserTextConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_COUNTER_CONFIG:
            return lpMatrixDisplayV2_sendCounterConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_VIRTUAL_DISPLAY_CONFIG:
            return lpMatrixDisplayV2_sendVirtualDisplayConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_BASIC_CONFIG:
            return lpMatrixDisplayV2_sendBasicConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_ALL_BASIC_CONFIG:
            return lpMatrixDisplayV2_sendAllBasicConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_SEND_TOPOLOGY_CONFIG:
            return lpMatrixDisplayV2_sendTopologyConfig();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_READ_DICTIONNARY_VALUE:
            return lpMatrixDisplayV2_readDictionnaryValue();
        case LPMATRIXDISPLAYV2_EVENTS.LPMATRIXDISPLAYV2_READ_USER_TEXTS_VALUE:
            return lpMatrixDisplayV2_readUserTextsValue();
        default:
            return props;
    }
}
