import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert, Button, ButtonGroup, CheckPicker, Loader, Modal } from 'rsuite';
import { Profile, ProfileInterface } from '../../../../../handlers/profile/Profile';
import { User } from '../../../../../handlers/profile/User';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    user: User;
    open: boolean;
    onClose: () => void;
    reloadUsers: () => void;
    reloadProfiles: () => void;
};

export const AddUserProfileModal = (props: Props) => {
    const intl = useIntl();

    const authenticatedUser = useSelector((state: any) => state.auth.user);

    const [isAdding, setIsAdding] = React.useState<boolean>(false);
    const [profiles, setProfiles] = React.useState<Profile[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [profilesToSet, setProfilesToSet] = React.useState<number[]>(
        props.user.getProfiles().map(profile => profile.getId())
    );

    const handleValid = () => {
        setIsAdding(true);

        axiosService
            .getAxios()
            .put(
                '/users/updateProfiles',
                {
                    id: props.user.getId(),
                    profiles: profilesToSet,
                },
                { headers: authHeader() }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'user.updateProfiles.success' }));

                props.reloadUsers();
                props.reloadProfiles();

                props.onClose();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'user.updateProfiles.error' }));
            })
            .finally(() => {
                setIsAdding(false);
            });
    };

    const handleClose = () => {
        if (!isAdding) {
            setProfilesToSet(props.user.getProfiles().map(profile => profile.getId()));
            props.onClose();
        }
    };

    useEffect(() => {
        if (props.open) {
            setIsLoading(true);

            axiosService
                .getAxios()
                .get<ProfileInterface[]>('/profile', {
                    headers: authHeader(),
                })
                .then(profilesResponse => {
                    const profiles: Profile[] = profilesResponse.data.map(profile => new Profile(profile));
                    const currentUserProfilesIds = authenticatedUser.profiles.map(profile => profile.id);

                    const finalProfiles: Profile[] = [];

                    profiles.forEach(profile => {
                        if (currentUserProfilesIds.includes(1)) {
                            finalProfiles.push(profile);
                        } else if (currentUserProfilesIds.includes(profile.getId())) {
                            finalProfiles.push(profile);
                        }
                    });

                    setProfiles(finalProfiles);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [props.open]);

    return (
        <Modal backdrop="static" show={props.open} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="user.addProfile.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <b>
                    {props.user.getFirstName()} {props.user.getLastName()}
                </b>
                <br />
                {isLoading ? (
                    <Loader />
                ) : (
                    <CheckPicker
                        data-cy="addProfile-picker"
                        data={profiles.map(profile => {
                            return {
                                label: profile.getName(),
                                value: profile.getId(),
                            };
                        })}
                        block
                        value={profilesToSet}
                        onChange={value => setProfilesToSet(value)}
                    />
                )}
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={handleClose} color="red" disabled={isAdding} data-cy="addProfile-cancel">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button onClick={handleValid} color="green" loading={isAdding} data-cy="addProfile-validate">
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
