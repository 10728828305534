import { notificationConstants } from '../constants';

export const notificationActions = {
    createNotification,
};

function createNotification(type, i18nKey, duration?) {
    return dispatch => {
        dispatch(request());
        dispatch(remove());
    };

    function request() {
        return {
            type: notificationConstants.CREATE_NOTIFICATION,
            message: i18nKey,
            notificationType: type,
            // duration: duration || 5000,
        };
    }

    function remove() {
        return {
            type: notificationConstants.REMOVE_NOTIFICATION,
        };
    }
}
