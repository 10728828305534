import { LPSensorUsConfig, LPSensorUsConfig_I } from './LPSensorUsConfig';

const lpSensorUsConfigDefault: LPSensorUsConfig_I = {
    height: 0,
    sens: 0
}
export class LPSensorAutoCalibrationValue {
    private _us1Remove: number;
    private _us2Remove: number;

    private _us1: LPSensorUsConfig;
    private _us2: LPSensorUsConfig;

    private _autoCalibrationLoading: boolean;

    constructor(us1Remove_, us2Remove_) {
        this._us1Remove = us1Remove_;
        this._us2Remove = us2Remove_;

        this._us1 = new LPSensorUsConfig(lpSensorUsConfigDefault);
        this._us2 = new LPSensorUsConfig(lpSensorUsConfigDefault);

        this._autoCalibrationLoading = false;
    }

    public get us1Remove() {
        return this._us1Remove
    }

    public get us2Remove() {
        return this._us2Remove
    }

    public get us1() {
        return this._us1;
    }

    public get us2() {
        return this._us2;
    }

    public get autoCalibrationLoading() {
        return this._autoCalibrationLoading;
    }

    public set us1Remove(us1Remove_: number) {
        this._us1Remove = us1Remove_;
    }

    public set us2Remove(us2Remove_: number) {
        this._us2Remove = us2Remove_;
    }

    public set autoCalibrationLoading(autocalibrationLoading_) {
        this._autoCalibrationLoading = autocalibrationLoading_;
    }
}