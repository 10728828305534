import { faCheckCircle, faCogs, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-advanced';
import { FlexboxGrid, Panel } from 'rsuite';
import ProgressLine from 'rsuite/lib/Progress/ProgressLine';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';

type Props = {};

type State = {
    loading: boolean;
    connected: boolean;
    version: string;
    isActive: boolean;
};
export default class EngineCard extends React.Component<Props, State> {
    handleClick: any;
    handleShowError: any;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            connected: false,
            version: '0.0.0',
            //date: '-',
            isActive: false,
        };
    }

    componentDidMount() {
        webSocketService.joinRoom('engine');

        webSocketService.onEvent('engine:disconnected', this.engineDisconnected);
        webSocketService.onEvent('engine:connected', this.engineConnected);

        this.fetchInformation();
    }

    fetchInformation = () => {
        return axiosService
            .getAxios()
            .get('/configuration/status/engine', { headers: authHeader() })
            .then(response => {
                this.setState({
                    loading: false,
                    connected: response.data.isConnect,
                    version: response.data.version,
                    isActive: response.data.isActiv,
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    connected: false,
                    loading: false,
                });
            });
    };

    componentWillUnmount() {
        webSocketService.offEvent('engine:disconnected', this.engineDisconnected);
        webSocketService.offEvent('engine:connected', this.engineConnected);
    }

    engineDisconnected = () => {
        this.setState({
            connected: false,
        });
    };

    engineConnected = () => {
        this.setState({
            connected: true,
        });
    };

    render() {
        return (
            <Loader show={this.state.loading}>
                <Panel
                    onClick={this.handleClick}
                    className="hoverable status-card"
                    bodyFill
                    data-cy={'dashboard-engine-card'}>
                    <h6>
                        <FormattedMessage id={'dashboard.engine.title'} />
                    </h6>
                    <h4>{this.state.version}</h4>

                    <ProgressLine
                        percent={this.state.connected && this.state.isActive ? 100 : 0}
                        showInfo={false}
                        strokeWidth={4}
                        strokeColor="#06d6a0"
                        trailColor={!this.state.connected ? '#fb0327' : '#fb8b24'}
                    />
                    <FlexboxGrid justify="space-between" onClick={this.handleShowError}>
                        <FlexboxGrid.Item>
                            <p className="text-muted padding-top-10 padding-bottom-10 padding-left-15">
                                {this.state.isActive ? (
                                    <FormattedMessage id="dashboard.engine.isActive" />
                                ) : (
                                    <FormattedMessage id="dashboard.engine.isNotActive" />
                                )}
                            </p>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item className="zindex10">
                            {!this.state.connected ? (
                                <p className="padding-top-10 padding-bottom-10 padding-right-15">
                                    <FontAwesomeIcon icon={faTimesCircle} size="lg" color="#FF3333" />
                                </p>
                            ) : (
                                <p className="padding-top-10 padding-bottom-10 padding-right-15">
                                    <FontAwesomeIcon icon={faCheckCircle} size="lg" color="#06d6a0" />
                                </p>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FontAwesomeIcon className="bg-icon" icon={faCogs} />
                </Panel>
            </Loader>
        );
    }
}
