import { faAngleLeft, faBars, faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Nav, Navbar, Popover, Whisper } from 'rsuite';
import { authActions } from '../../redux/actions/auth.actions';
import DropdownLanguage from './language/DropdownLanguage';

type Props = {
    auth: Record<string, any>;
    logout: Function;
    onChange: Function;
    expanded: boolean;
};

type State = {};

const mapDispatchToProps = (dispatch: Function) => ({
    logout: () => dispatch(authActions.logout(false)),
});
class NavbarToggle extends React.Component<Props, State> {
    render() {
        return (
            <Navbar appearance="default" className="nav-toggle">
                <Nav className="hide-on-mobile">
                    <DropdownLanguage />
                </Nav>
                <Navbar.Body>
                    <Nav className="hide-on-mobile">
                        <Whisper
                            trigger="click"
                            placement="right"
                            speaker={
                                <Popover title={this.props.auth.user.name}>
                                    <Button
                                        onClick={() => this.props.logout()}
                                        block
                                        color="orange"
                                        data-cy="sidenav-logout">
                                        <FontAwesomeIcon icon={faSignOutAlt} className="margin-right-10" />
                                        <FormattedMessage id="sidenav.logout" />
                                    </Button>
                                </Popover>
                            }>
                            <Nav.Item style={{ width: 56, textAlign: 'center' }}>
                                <FontAwesomeIcon data-cy="sidenav-loggedInUser" size="3x" icon={faUserCircle} />
                            </Nav.Item>
                        </Whisper>
                    </Nav>

                    <Nav>
                        <Nav.Item onClick={() => this.props.onChange()} style={{ width: 56 }}>
                            <FontAwesomeIcon size="2x" icon={this.props.expanded ? faAngleLeft : faBars} />
                        </Nav.Item>
                    </Nav>
                </Navbar.Body>
            </Navbar>
        );
    }
}

function mapStateToProps(state) {
    const { auth } = state;

    return { auth };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarToggle);
