type Props = {
    color: string;
};

export const TwoLinesCardIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <g fill-rule="evenodd">
                <rect fill={color} x="32" y="64" width="450" height="32" />
                <rect fill={color} x="32" y="384" width="450" height="32" />
                <rect fill={color} x="32" y="256" width="450" height="64" />
                <rect fill={color} x="192" y="64" width="290" height="96" />
                <rect fill={color} x="192" y="192" width="290" height="32" />
                <rect fill={color} x="32" y="64" width="32" height="256" />
                <rect fill={color} x="418" y="64" width="64" height="256" />
                <rect fill={color} x="192" y="64" width="64" height="256" />
            </g>
            <rect fill={color} x="354" y="128" width="96" height="96" />
        </g>
    </svg>
);
