import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';

export default function updateSubstractCounters(option) {
    return (
        <div>
            <div data-cy="historic-substract-nbOfCounters">
                <FormattedMessage id="display.history.nbOfSubstractCounters" /> :{' '}
                <Tag componentClass="span" color="blue">
                    {option.length}
                </Tag>
            </div>
            <div data-cy="historic-substract-nbOfCounters-name">
                {option.map(o => (
                    <Tag color="red" key={o.label}>
                        {o.label}
                    </Tag>
                ))}
            </div>
        </div>
    );
}
