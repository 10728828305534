import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Col, FlexboxGrid, List, Panel } from 'rsuite';
import PanelHeader from '../../../Custom/PanelHeader';

type Props = {
    isCheckingDatabase: boolean;
    display: Record<string, any>;
    databaseData: Record<string, any> | undefined;
    loading?: boolean;
} & WrappedComponentProps;
class InfosCard extends React.Component<Props> {
    render() {
        let versionClass = '';
        let modelNameClass = '';
        let manufacturerClass = '';

        if (this.props.isCheckingDatabase) {
            versionClass = this.props.databaseData?.version === this.props.display.version ? 'list-green' : 'list-red';
            modelNameClass =
                this.props.databaseData?.modelName === this.props.display.modelName ? 'list-green' : 'list-red';
            manufacturerClass =
                this.props.databaseData?.manufacturer === this.props.display.manufacturer ? 'list-green' : 'list-red';
        }
        return (
            <Fragment>
                <Loader show={this.props.loading || false}>
                    <Panel
                        className={'panel-small'}
                        shaded
                        data-cy="ipcan-elements-display-drawer-infos"
                        bordered
                        header={
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'ipCanDevices.display' })}
                                buttons={[]}
                            />
                        }
                        bodyFill>
                        <List hover>
                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item componentClass={Col} xs={8} style={{ fontWeight: 'bold' }}>
                                        <div data-cy="ipcan-elements-display-deviceId">
                                            <FormattedMessage id="ipCanDevices.display.deviceId" />
                                        </div>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item
                                        data-cy="ipcan-elements-display-deviceId-value"
                                        style={{ fontWeight: 'bold' }}>
                                        {this.props.display.deviceId}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item componentClass={Col} xs={8} style={{ fontWeight: 'bold' }}>
                                        <div data-cy="ipcan-elements-display-online">
                                            <FormattedMessage id="ipCanDevices.display.online" />
                                        </div>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item
                                        style={{ fontWeight: 'bold' }}
                                        data-cy="ipcan-elements-display-online-value">
                                        <FontAwesomeIcon
                                            icon={faPlug}
                                            style={{
                                                color: this.props.display.online ? 'green' : 'red',
                                            }}></FontAwesomeIcon>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${versionClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item componentClass={Col} xs={8} style={{ fontWeight: 'bold' }}>
                                        <div data-cy="ipcan-elements-display-version">
                                            <FormattedMessage id="ipCanDevices.display.version" />
                                        </div>
                                    </FlexboxGrid.Item>

                                    {/* CHECKING DATABASE */}
                                    {this.props.isCheckingDatabase && (
                                        <FlexboxGrid.Item
                                            data-cy="ipcan-elements-display-version-bdd"
                                            componentClass={Col}
                                            xs={8}>
                                            {this.props.databaseData?.version}
                                        </FlexboxGrid.Item>
                                    )}

                                    <FlexboxGrid.Item
                                        data-cy="ipcan-elements-display-version-value"
                                        style={{ fontWeight: 'bold' }}>
                                        {this.props.display.version}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${modelNameClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item componentClass={Col} xs={8} style={{ fontWeight: 'bold' }}>
                                        <div data-cy="ipcan-elements-display-modelName">
                                            <FormattedMessage id="ipCanDevices.display.modelName" />
                                        </div>
                                    </FlexboxGrid.Item>

                                    {/* CHECKING DATABASE */}
                                    {this.props.isCheckingDatabase && (
                                        <FlexboxGrid.Item
                                            data-cy="ipcan-elements-display-modelName-bdd"
                                            componentClass={Col}
                                            xs={8}>
                                            {this.props.databaseData?.modelName}
                                        </FlexboxGrid.Item>
                                    )}

                                    <FlexboxGrid.Item
                                        data-cy="ipcan-elements-display-modelName-value"
                                        style={{ fontWeight: 'bold' }}>
                                        {this.props.display.modelName}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${manufacturerClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item componentClass={Col} xs={8} style={{ fontWeight: 'bold' }}>
                                        <div data-cy="ipcan-elements-display-manufacturer">
                                            <FormattedMessage id="ipCanDevices.display.manufacturer" />
                                        </div>
                                    </FlexboxGrid.Item>

                                    {/* CHECKING DATABASE */}
                                    {this.props.isCheckingDatabase && (
                                        <FlexboxGrid.Item
                                            data-cy="ipcan-elements-display-manufacturer-bdd"
                                            componentClass={Col}
                                            xs={8}>
                                            {this.props.databaseData?.manufacturer}
                                        </FlexboxGrid.Item>
                                    )}

                                    <FlexboxGrid.Item
                                        data-cy="ipcan-elements-display-manufacturer-value"
                                        style={{ fontWeight: 'bold' }}>
                                        {this.props.display.manufacturer}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item componentClass={Col} xs={8} style={{ fontWeight: 'bold' }}  data-cy="ipcan-elements-display-creation-title">
                                        <FormattedMessage id="ipCanDevices.display.creation" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }} data-cy="ipcan-elements-display-creation">
                                        {this.props.display.creationInformation}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }} data-cy="ipcan-elements-display-update-title">
                                        <FormattedMessage id="ipCanDevices.display.update" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }} data-cy="ipcan-elements-display-update">
                                        {this.props.display.updateInformation}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        </List>
                    </Panel>
                </Loader>
            </Fragment>
        );
    }
}

export default injectIntl(InfosCard);
