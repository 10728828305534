import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Steps } from 'rsuite';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../../redux/services';
import Changelog from './Automatic/Changelog';
import { Downloader } from './Automatic/Downloader';
import { Restarter } from './Automatic/Restarter';
import { Validation } from './Automatic/Validation';

type Props = {
    close: Function;
    step: number;
} & WrappedComponentProps;

type State = {
    images: Array<Record<string, any>>;
    step: number;
    imagesOnError: Array<Record<string, any>>;
    retryButton: boolean;
    dockerError: boolean;
    downloadComplete: boolean;
};

class AutomaticUpdate extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            images: [],
            imagesOnError: [],
            retryButton: false,
            dockerError: false,
            downloadComplete: false,
        };

        this.switcher = this.switcher.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.updateDockerProgress = this.updateDockerProgress.bind(this);
        this.updateDockerFinished = this.updateDockerFinished.bind(this);
        this.updateDockerCompleted = this.updateDockerCompleted.bind(this);
        this.updateDockerFailed = this.updateDockerFailed.bind(this);
        this.updateDockerImageNewStatus = this.updateDockerImageNewStatus.bind(this);
        this.updateFailed = this.updateFailed.bind(this);
        this.retryUpdate = this.retryUpdate.bind(this);
    }

    componentDidMount() {
        webSocketService.onEvent('configurations:onUpdateDockerProgress', this.updateDockerProgress);
        webSocketService.onEvent('configurations:onUpdateDockerFinished', this.updateDockerFinished);
        webSocketService.onEvent('configurations:updateCompleted', this.updateDockerCompleted);
        webSocketService.onEvent('configurations:onUpdateDockerFailed', this.updateDockerFailed);
        webSocketService.onEvent('configurations:onImageNewStatus', this.updateDockerImageNewStatus);
        webSocketService.onEvent('configurations:onUpdateFailed', this.updateFailed);
    }

    updateDockerProgress(data) {
        if (data.image.name && data.data) {
            const foundIndex = this.state.images.findIndex(image => image.name === data.image.name);

            if (foundIndex === -1) {
                let images = this.state.images;

                images.push({
                    name: data.image.name,
                    layers: data.data,
                    openned: false,
                    state: 'active',
                    info: undefined,
                    updateErrorMessage: undefined,
                });

                this.setState({
                    images,
                });
            } else {
                this.setState({
                    images: this.state.images.map(image => {
                        if (image.name === data.image.name) {
                            return { ...image, layers: data.data };
                        } else {
                            return image;
                        }
                    }),
                });
            }
        }
    }

    updateDockerFinished(data) {
        const foundIndex = this.state.images.findIndex(image => image.name === data.image.name);

        if (foundIndex === -1) {
            let images = this.state.images;

            images.push({
                name: data.image.name,
                layers: [],
                openned: false,
                state: 'success',
                info: undefined,
                updateErrorMessage: undefined,
            });

            this.setState({
                images,
            });
        } else {
            this.setState({
                images: this.state.images.map(image => {
                    if (image.name === data.image.name) {
                        return { ...image, state: 'success' };
                    } else {
                        return image;
                    }
                }),
            });
        }
    }

    updateDockerCompleted() {
        if (this.state.imagesOnError.length !== 0) {
            this.setState({
                retryButton: true,
            });
        } else {
            this.setState({
                downloadComplete: true,
            });
        }
    }

    updateDockerFailed(data) {
        let newImagesOnError = this.state.imagesOnError;
        newImagesOnError.push({
            name: data.image.name,
            error: data.message,
        });

        const foundIndex = this.state.images.findIndex(image => image.name === data.image.name);

        if (foundIndex === -1) {
            let images = this.state.images;

            images.push({
                name: data.image.name,
                layers: [],
                openned: false,
                state: 'error',
                info: undefined,
                updateErrorMessage: data.message,
            });

            this.setState({
                images,
            });
        } else {
            this.setState({
                images: this.state.images.map(image => {
                    if (image.name === data.image.name) {
                        return { ...image, state: 'error', updateErrorMessage: data.message };
                    } else {
                        return image;
                    }
                }),

                imagesOnError: newImagesOnError,
            });
        }
    }

    updateFailed(err) {
        console.error('updateFailed', err);
        this.setState({
            dockerError: true,
            retryButton: true,
        });
    }

    updateDockerImageNewStatus(data) {
        this.setState({
            images: this.state.images.map(image => {
                if (image.name === data.image.name) {
                    return { ...image, info: data.status };
                } else {
                    return image;
                }
            }),
        });
    }

    switcher() {
        switch (this.state.step) {
            case 0:
                return <Validation nextStep={() => this.nextStep()} />;
            case 1:
                return <Changelog closeModal={() => this.props.close()} nextStep={this.nextStep} />;
            case 2:
                return (
                    <Downloader
                        nextStep={() => this.nextStep()}
                        images={this.state.images}
                        imagesOnError={this.state.imagesOnError}
                        retryButton={this.state.retryButton}
                        retryUpdate={this.retryUpdate}
                        dockerError={this.state.dockerError}
                        downloadComplete={this.state.downloadComplete}
                    />
                );
            case 3:
                return <Restarter closeModal={() => this.props.close()} />;
            default:
                return null;
        }
    }

    nextStep() {
        this.setState({
            step: this.state.step + 1,
        });
    }

    retryUpdate() {
        this.setState({
            images: [],
            imagesOnError: [],
            retryButton: false,
            dockerError: false,
        });

        axiosService
            .getAxios()
            .post('/configuration/updateApp', { appName: 'All' }, { headers: authHeader() })
            .catch(() => {
                setTimeout(() => {
                    this.retryUpdate();
                }, 5000);
            });
    }

    render() {
        return (
            <Fragment>
                <h3 className="margin-bottom-10">
                    <FormattedMessage id="update.automatic.title" />
                </h3>

                <Steps
                    current={this.state.step}
                    currentStatus={this.state.imagesOnError.length === 0 ? 'process' : 'error'}>
                    <Steps.Item title={<FormattedMessage id="update.automatic.validation" />} />
                    <Steps.Item title={<FormattedMessage id="update.automatic.changelogs" />} />
                    <Steps.Item title={<FormattedMessage id="update.automatic.download" />} />
                    <Steps.Item title={<FormattedMessage id="update.automatic.restart" />} />
                </Steps>

                <div className="margin-top-10">{this.switcher()}</div>
            </Fragment>
        );
    }
}

export default injectIntl(AutomaticUpdate);