import { faRobot, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FlexboxGrid, Message, Modal } from 'rsuite';
import LPSensorHistoric from '../../../handlers/history/LPSensorHistoric.handler';
import { LPSensor } from '../../../handlers/ipCanDevices/LPSensor';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import HistorySortPart from '../../History/SortPart';
import LPSensorExpandable from '../../History/Table/Expandable/LPSensorExpandable';
import LPSensorDescCell from '../../History/Table/LPSensorDescCell';
import LPSensorOptionCell from '../../History/Table/LPSensorOptionCell';
import HistoryTable from '../../ReactDataTableComponent/HistoryTable';

type Props = {
    sensor: LPSensor;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    histories: Array<Record<string, any>>;
    isLoading: boolean;
    hasError: boolean;
    users: Array<Record<string, any>>;
    formValue: FormValue;
};

type FormValue = {
    user: Array<Record<string, any>> | null;
    before: Moment | null;
    after: Moment | null;
    nbElements: number;
};

class LPSensorHistoryModal extends React.Component<Props, State> {
    columns: TableColumn<Record<string, any>>[];

    constructor(props: Props) {
        super(props);

        this.state = {
            histories: [],
            isLoading: true,
            hasError: false,
            users: [],
            formValue: {
                user: null,
                before: null,
                after: null,
                nbElements: 100,
            },
        };

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.user',
                }),
                selector: 'user',
                sortable: true,
                cell: row => {
                    if (row.user === 'MQTT') {
                        return <FontAwesomeIcon icon={faRobot} size="lg" />;
                    } else {
                        return row.user;
                    }
                },
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.createdAt',
                }),
                selector: 'createdAt',
                sortable: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.desc',
                }),
                selector: 'desc',
                sortable: true,
                cell: row => LPSensorDescCell(row.desc),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.option',
                }),
                selector: 'option',
                cell: row => LPSensorOptionCell(row.desc, row.option),
            },
        ];
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.show && !prevProps.show) {
            this.loadHistory();
        }
    }

    setUser = user => {
        this.setState({
            formValue: { ...this.state.formValue, user },
        });
    };

    setBefore = before => {
        this.setState({
            formValue: { ...this.state.formValue, before: moment(before).add(1, 'hour') },
        });
    };

    setAfter = after => {
        this.setState({
            formValue: { ...this.state.formValue, before: moment(after).add(1, 'hour') },
        });
    };

    setNbElements = nbElements => {
        this.setState({
            formValue: { ...this.state.formValue, nbElements },
        });
    };

    loadHistory = () => {
        this.setState({
            isLoading: true,
        });

        axiosService
            .getAxios()
            .post(
                '/historics/lp-sensors',
                {
                    nbResult: 100,
                    id: this.props.sensor.id,
                    user: this.state.formValue.user,
                    after: this.state.formValue.after ? moment(this.state.formValue.after).toISOString() : null,
                    before: this.state.formValue.before ? moment(this.state.formValue.before).toISOString() : null,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(lpSensorHistoryResponse => {
                let users: Array<string> = [];

                const histories = lpSensorHistoryResponse.data.map(history => {
                    const lpSensorHistory = new LPSensorHistoric(history);

                    users.indexOf(lpSensorHistory.getUser()) === -1 && users.push(lpSensorHistory.getUser());

                    return lpSensorHistory.getHistory();
                });

                this.setState({
                    histories,
                    users: users.map(user => {
                        return { label: user, value: user };
                    }),
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    render() {
        return (
            <Modal
                full
                backdrop="static"
                show={this.props.show}
                onHide={() => this.props.onHide()}
                style={{ height: '95%' }}
                className="no-modal-padding">
                <Modal.Header style={{ backgroundColor: '#212121' }}>
                    <HistorySortPart
                        users={this.state.users}
                        setUser={this.setUser}
                        setBefore={this.setBefore}
                        setAfter={this.setAfter}
                        valid={this.loadHistory}
                        setNbElements={this.setNbElements}
                    />
                </Modal.Header>
                <Modal.Body>
                    {this.state.hasError ? (
                        <Message type="error">
                            <FormattedMessage id="history.lpSensor.fetchHistoryError" />
                        </Message>
                    ) : (
                        <Fragment>
                            <FlexboxGrid align="middle" justify="center">
                                <FlexboxGrid.Item>
                                    <FontAwesomeIcon
                                        icon={faWifi}
                                        rotation={180}
                                        size="2x"
                                        className="margin-right-5"
                                    />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <h4 className="text-center">{`${this.props.intl.formatMessage({
                                        id: 'history.lpSensor.history.title',
                                    })}-${this.props.sensor.bus + 1}-${this.props.sensor.deviceId}`}</h4>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            <div data-cy="history-lpSensor-history-table">
                                <HistoryTable
                                    columns={this.columns}
                                    data={this.state.histories}
                                    progressPending={this.state.isLoading}
                                    expandableRows
                                    expandOnRowClicked
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={LPSensorExpandable}
                                />
                            </div>
                        </Fragment>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}

export default injectIntl(LPSensorHistoryModal);
