import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, FlexboxGrid, Form, FormControl, List, Panel, Tag, TagPicker } from 'rsuite';
import TCMDisplay from '../../../../handlers/ipCanDevices/TCMDisplay';
import PlaceType from '../../../../handlers/PlaceType';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';

type Props = {
    display: TCMDisplay;
    isCheckingDatabase: boolean;
    loading: boolean;
    updateDisplayTypeConfig: Function;
} & WrappedComponentProps;

type State = {
    editMode: boolean;
    formValue: FormValue;
    placeTypes: Array<Record<string, any>>;
    placeTypesForm: Array<Record<string, any>>;
    isUpdating: boolean;
};

type FormValue = {
    generic: Array<number>;
    pmr: Array<number>;
    family: Array<number>;
    electrical: Array<number>;
};

class TypeConfiguration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editMode: false,
            formValue: this.props.display.configType,
            placeTypes: [],
            placeTypesForm: [],
            isUpdating: false,
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.updateConfigType = this.updateConfigType.bind(this);
        this.valid = this.valid.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get('/place-type', { headers: authHeader() })
            .then(response => {
                const placeTypes = response.data.map(placeType => {
                    const type = new PlaceType(placeType);

                    return type.getPlaceType();
                });

                this.setState({
                    placeTypes: placeTypes,
                    placeTypesForm: placeTypes.map(placeType => {
                        return { label: placeType.label, value: placeType.id };
                    }),
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    // componentDidUpdate(prevProps) {
    //     if (
    //         this.props.display.configType.generic !== prevProps.display.generic ||
    //         this.props.display.configType.pmr !== prevProps.display.pmr ||
    //         this.props.display.configType.electrical !== prevProps.display.electrical ||
    //         this.props.display.configType.family !== prevProps.display.family
    //     ) {
    //         this.setState(
    //             {
    //                 isLoading: true,
    //             },
    //             () => {
    //                 this.updateConfigType();
    //             }
    //         );
    //     }
    // }

    // updateConfigType() {
    //     let generic: Record<string, any> | undefined = undefined;
    //     let pmr: Record<string, any> | undefined = undefined;
    //     let family: Record<string, any> | undefined = undefined;
    //     let electrical: Record<string, any> | undefined = undefined;

    //     if (this.state.placeTypes) {
    //         for (let g in this.props.display.configType.generic) {
    //             let type = this.props.display.configType.generic[g];

    //             generic = this.state.placeTypes.find(pt => pt.id === type);
    //         }

    //         for (let p in this.props.display.configType.pmr) {
    //             let type = this.props.display.configType.pmr[p];

    //             pmr = this.state.placeTypes.find(pt => pt.id === type);
    //         }

    //         for (let f in this.props.display.configType.family) {
    //             let type = this.props.display.configType.family[f];

    //             family = this.state.placeTypes.find(pt => pt.id === type);
    //         }

    //         for (let e in this.props.display.configType.electrical) {
    //             let type = this.props.display.configType.electrical[e];

    //             electrical = this.state.placeTypes.find(pt => pt.id === type);
    //         }

    //         this.setState({
    //             generic,
    //             pmr,
    //             family,
    //             electrical,
    //             isLoading: false,
    //         });
    //     }
    // }

    setEditMode() {
        this.setState({
            editMode: true,
        });
    }

    handleChange(formValue) {
        this.setState({
            formValue,
        });
    }

    valid() {
        this.setState({
            isUpdating: true,
        });

        this.props.display.configType
            .updateConfigDisplayTypeTCM(
                this.props.display.id,
                this.state.formValue.generic,
                this.state.formValue.pmr,
                this.state.formValue.family,
                this.state.formValue.electrical
            )
            .then(() => {
                this.setState({
                    editMode: false,
                });

                Alert.success(this.props.intl.formatMessage({ id: 'tcmDisplay.updateConfigurationType.success' }));
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage({ id: 'tcmDisplay.updateConfigurationType.error' }, { error: err })
                );
            })
            .finally(() => {
                this.setState({
                    isUpdating: false,
                });
            });

        // this.props.updateDisplayTypeConfig(configType);
    }

    cancel() {
        this.setState({
            editMode: false,
        });
    }

    render() {
        const configType = this.props.display.configType;
        let filledGeneric: Array<any> = [];
        let filledPmr: Array<any> = [];
        let filledElectrical: Array<any> = [];
        let filledFamily: Array<any> = [];

        if (!this.state.editMode) {
            for (let pt in this.state.placeTypes) {
                for (let c in configType.generic) {
                    if (this.state.placeTypes[pt].id === configType.generic[c]) {
                        filledGeneric.push(this.state.placeTypes[pt]);
                    }
                }
                for (let c in configType.pmr) {
                    if (this.state.placeTypes[pt].id === configType.pmr[c]) {
                        filledPmr.push(this.state.placeTypes[pt]);
                    }
                }
                for (let c in configType.electrical) {
                    if (this.state.placeTypes[pt].id === configType.electrical[c]) {
                        filledElectrical.push(this.state.placeTypes[pt]);
                    }
                }
                for (let c in configType.family) {
                    if (this.state.placeTypes[pt].id === configType.family[c]) {
                        filledFamily.push(this.state.placeTypes[pt]);
                    }
                }
            }
        }

        return (
            <Panel
                className={'panel-small'}
                shaded
                data-cy="ipcan-elements-display-drawer-type-configuration"
                bordered
                header={
                    <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.UPDATE_CONFIG_TYPE]}>
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'ipCanDevices.display' })}
                            buttons={[
                                <Fragment>
                                    {!this.props.isCheckingDatabase && (
                                        <Button
                                            onClick={this.setEditMode}
                                            data-cy="typeconfiguration-edit"
                                            key="typeconfiguration_edit"
                                            color="blue"
                                            size="sm">
                                            <FontAwesomeIcon icon={faEdit} className="margin-right-10" />
                                            <FormattedMessage id="ipCanDevices.display.configType.edit" />
                                        </Button>
                                    )}
                                </Fragment>,
                            ]}
                        />
                    </SecuredFragment>
                }
                bodyFill>
                <Form onChange={this.handleChange} formValue={this.state.formValue}>
                    <List hover data-cy="ipcan-elements-display-drawer-infos">
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                    <div data-cy="typeconfiguration-generic-title">
                                        <FormattedMessage id="ipCanDevices.display.configType.generic" />
                                    </div>
                                </FlexboxGrid.Item>
                                {this.state.editMode ? (
                                    <FlexboxGrid.Item>
                                        <FormControl
                                            data-cy="typeconfiguration-generic"
                                            accepter={TagPicker}
                                            name="generic"
                                            data={this.state.placeTypesForm}
                                            style={{ width: 300 }}
                                            renderMenuItem={(label, item) => {
                                                return (
                                                    <div data-cy={`configuration-generic-value-${item.value}`}>
                                                        {label}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </FlexboxGrid.Item>
                                ) : (
                                    <div data-cy="typeconfiguration-generic">
                                        <FlexboxGrid.Item>
                                            {filledGeneric.map(g => (
                                                <Tag color="blue" key={`typeconfiguration-generic-${g.id}`}>
                                                    {g.label}
                                                </Tag>
                                            ))}
                                        </FlexboxGrid.Item>
                                    </div>
                                )}
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item style={{ fontWeight: 'bold' }} data-cy="typeconfiguration-pmr-title">
                                    <FormattedMessage id="ipCanDevices.display.configType.pmr" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                data-cy="typeconfiguration-pmr"
                                                accepter={TagPicker}
                                                name="pmr"
                                                data={this.state.placeTypesForm}
                                                style={{ width: 300 }}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`configuration-pmr-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <div data-cy="typeconfiguration-pmr">
                                            <FlexboxGrid.Item>
                                                {filledPmr.map(g => (
                                                    <Tag color="blue">{g.label}</Tag>
                                                ))}
                                            </FlexboxGrid.Item>
                                        </div>
                                    )}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item
                                    style={{ fontWeight: 'bold' }}
                                    data-cy="typeconfiguration-family-title">
                                    <FormattedMessage id="ipCanDevices.display.configType.family" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                data-cy="typeconfiguration-family"
                                                accepter={TagPicker}
                                                name="family"
                                                data={this.state.placeTypesForm}
                                                style={{ width: 300 }}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`configuration-family-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <div data-cy="typeconfiguration-family">
                                            <FlexboxGrid.Item>
                                                {filledFamily.map(g => (
                                                    <Tag color="blue">{g.label}</Tag>
                                                ))}
                                            </FlexboxGrid.Item>
                                        </div>
                                    )}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item className="panel-list">
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item
                                    style={{ fontWeight: 'bold' }}
                                    data-cy="typeconfiguration-electrical-title">
                                    <FormattedMessage id="ipCanDevices.display.configType.electrical" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                data-cy="typeconfiguration-electrical"
                                                accepter={TagPicker}
                                                name="electrical"
                                                data={this.state.placeTypesForm}
                                                style={{ width: 300 }}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`configuration-electrical-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <div data-cy="typeconfiguration-electrical">
                                            <FlexboxGrid.Item>
                                                {filledElectrical.map(g => (
                                                    <Tag color="blue">{g.label}</Tag>
                                                ))}
                                            </FlexboxGrid.Item>
                                        </div>
                                    )}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        {this.state.editMode && (
                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                        <Button
                                            data-cy="ipCanDevices-display-edit-valid"
                                            color="green"
                                            onClick={this.valid}>
                                            <FormattedMessage id="ipCanDevices.display.configType.edit.valid" />
                                        </Button>
                                        <Button
                                            data-cy="ipCanDevices-display-edit-cancel"
                                            appearance="link"
                                            onClick={this.cancel}>
                                            <FormattedMessage id="ipCanDevices.display.configType.edit.cancel" />
                                        </Button>{' '}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        )}
                    </List>
                </Form>
            </Panel>
        );
    }
}

export default injectIntl(TypeConfiguration);
