import { faCheck, faCheckDouble, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Drawer, FlexboxGrid, Message, Tooltip, Whisper } from 'rsuite';
import ConfigDisplayTCM from '../../../../handlers/ipCanDevices/Display/ConfigDisplayTCM';
import ConfigDisplayTypeTCM from '../../../../handlers/ipCanDevices/Display/ConfigDisplayTypeTCM';
import TCMDisplay from '../../../../handlers/ipCanDevices/TCMDisplay';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import { DeviceStateInformation } from '../../Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../Cards/DeviceStateInformation/deviceStateInformationGenerator';
import ConfigurationCard from './ConfigurationCard';
import CountersCard from './CountersCard';
import InfosCard from './InfosCard';
import DeleteDisplayModal from './Modal/DeleteDisplayModal';
import TCMDisplayHistoryModal from './Modal/TCMDisplayHistoryModal';
import TypeConfiguration from './TypeConfiguration';

type Props = {
    show: boolean;
    display: TCMDisplay;
    close: Function;
    ipCanId: number;
    updateDisplayConfig?: Function;
};

type State = {
    showDeleteModal: boolean;
    isCheckingDatabase: boolean;
    fetchingDatabase: boolean;
    databaseData: Record<string, any> | undefined;
    fetchDatabaseError: boolean;
    showTCMDisplayHistoryModal: boolean;
    isLoadingConfig: boolean;
    isLoadingTypeConfig: boolean;
};
export default class DisplayDrawer extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            isCheckingDatabase: false,
            fetchingDatabase: false,
            databaseData: undefined,
            fetchDatabaseError: false,
            showTCMDisplayHistoryModal: false,
            // Loaders
            isLoadingConfig: false,
            isLoadingTypeConfig: false,
        };

        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        // this.updateDisplay = this.updateDisplay.bind(this);
        this.setCheckingDatabase = this.setCheckingDatabase.bind(this);
        this.unsetCheckingDatabase = this.unsetCheckingDatabase.bind(this);
        this.showTCMDisplayHistoryModal = this.showTCMDisplayHistoryModal.bind(this);
        this.hideTCMDisplayHistoryModal = this.hideTCMDisplayHistoryModal.bind(this);
        this.updateDisplayConfig = this.updateDisplayConfig.bind(this);
        this.updateDisplayTypeConfig = this.updateDisplayTypeConfig.bind(this);
    }

    unsetCheckingDatabase() {
        this.setState({
            isCheckingDatabase: false,
        });
    }

    setCheckingDatabase() {
        this.setState({
            fetchingDatabase: true,
        });

        axiosService
            .getAxios()
            .get(`/devices/tcm-display/config/${this.props.display.id}`, { headers: authHeader() })
            .then(tcmSensorConfigResponse => {
                this.setState({
                    databaseData: tcmSensorConfigResponse.data,
                    isCheckingDatabase: true,
                });
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    fetchDatabaseError: true,
                });
            })
            .finally(() => {
                this.setState({
                    fetchingDatabase: false,
                });
            });
    }

    hideDeleteModal(shouldReload: boolean) {
        this.setState({
            showDeleteModal: false,
        });

        this.props.close(shouldReload);
    }

    showDeleteModal() {
        this.setState({
            showDeleteModal: true,
        });
    }

    showTCMDisplayHistoryModal() {
        this.setState({
            showTCMDisplayHistoryModal: true,
        });
    }

    hideTCMDisplayHistoryModal() {
        this.setState({
            showTCMDisplayHistoryModal: false,
        });
    }

    // componentDidMount() {
    //     this.updateDisplay();
    // }

    // componentDidUpdate(prevProps) {
    // if (this.props.display.id !== prevProps.display.id) {
    //     this.updateDisplay();
    // }
    // }

    // updateDisplay() {
    //     axiosService
    //         .getAxios()
    //         .get(`/devices/tcm-display/${this.props.display.id}`, {
    //             headers: authHeader(),
    //         })
    //         .then(response => tcmDisplaysHandler(response.data))
    //         .then(display => {
    //             this.setState({
    //                 display,
    //                 loading: false,
    //             });
    //         })
    //         .catch(err => {
    //             this.setState({
    //                 error: err,
    //                 loading: false,
    //             });
    //         });
    // }

    updateDisplayConfig(arrow, lang, text, type) {
        this.setState({
            isLoadingConfig: true,
        });

        const displayConfig = new ConfigDisplayTCM(arrow, lang, text, type);

        this.props.display.updateConfigDisplayTCM(displayConfig).then(() => {
            this.setState({
                isLoadingConfig: false,
            });
        });
    }

    updateDisplayTypeConfig(displayType) {
        this.setState({
            isLoadingTypeConfig: true,
        });

        const displayTypeConfig = new ConfigDisplayTypeTCM(displayType);

        this.props.display.updateConfigDisplayType(displayTypeConfig).then(() => {
            this.setState({
                isLoadingTypeConfig: false,
            });
        });
    }

    render() {
        return (
            <Drawer backdrop={true} show={this.props.show} onHide={() => this.props.close()}>
                <Drawer.Header data-cy="IPCanDrawer">
                    <Drawer.Title data-cy="IPCanDrawer-title">AFFICHEUR</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body data-cy="ipcan-elements-display-drawer" className="drawer-body-without-avatar">
                    <Fragment>
                        <DeleteDisplayModal
                            show={this.state.showDeleteModal}
                            onHide={this.hideDeleteModal}
                            id={[this.props.display.id]}
                        />

                        {this.state.showTCMDisplayHistoryModal && (
                            <TCMDisplayHistoryModal
                                show={this.state.showTCMDisplayHistoryModal}
                                onHide={this.hideTCMDisplayHistoryModal}
                                display={this.props.display}
                            />
                        )}

                        {this.state.fetchDatabaseError && (
                            <Message
                                type="error"
                                description={<FormattedMessage id="ipCanDevices.display.fetchDataError" />}
                            />
                        )}

                        <InfosCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            display={this.props.display.getDisplayInformation()}
                            databaseData={this.state.databaseData}
                        />
                        <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.UPDATE_MAINTENANCE_STATE]}>
                            <DeviceStateInformation
                                device={this.props.display}
                                editMaintenanceStateUrl={MaintenanceStateInformationEditURL.TCM_DISPLAY}
                            />
                        </SecuredFragment>
                        <ConfigurationCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            display={this.props.display.configTCMDisplay}
                            updateDisplayConfig={this.updateDisplayConfig}
                            loading={this.state.isLoadingConfig}
                            databaseData={this.state.databaseData}
                        />
                        <TypeConfiguration
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            display={this.props.display}
                            loading={this.state.isLoadingTypeConfig}
                            updateDisplayTypeConfig={this.updateDisplayTypeConfig}
                        />
                        <CountersCard
                            isCheckingDatabase={this.state.isCheckingDatabase}
                            display={this.props.display}
                            ipCanId={this.props.ipCanId}
                        />
                    </Fragment>
                </Drawer.Body>
                <Drawer.Footer>
                    {this.state.isCheckingDatabase ? (
                        <Button
                            block
                            onClick={this.unsetCheckingDatabase}
                            color="green"
                            data-cy="IPCan-check-display-button-valid">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    ) : (
                        <FlexboxGrid align="middle" justify="end">
                            <SecuredFragment authorizedRoles={[rolesConstants.historics.VIEW_TCM_DISPLAY]}>
                                <FlexboxGrid.Item componentClass={Col} md={3} xs={4}>
                                    <Whisper
                                        placement="left"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="tcmDisplay.action.showHistory" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            color="blue"
                                            block
                                            onClick={this.showTCMDisplayHistoryModal}
                                            data-cy="tcmDisplay-action-showHistory">
                                            <FontAwesomeIcon icon={faHistory} size="lg" />
                                        </Button>
                                    </Whisper>
                                </FlexboxGrid.Item>
                            </SecuredFragment>

                            <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.VIEW]}>
                                <FlexboxGrid.Item componentClass={Col} xs={4}>
                                    <Button
                                        block
                                        loading={this.state.fetchingDatabase}
                                        onClick={this.setCheckingDatabase}
                                        color="orange"
                                        data-cy="IPCan-check-sensor-button">
                                        <FontAwesomeIcon icon={faCheckDouble} />
                                    </Button>
                                </FlexboxGrid.Item>
                            </SecuredFragment>
                            <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.DELETE]}>
                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    <Button
                                        block
                                        onClick={() => {
                                            this.showDeleteModal();
                                        }}
                                        color="red"
                                        data-cy="IPCan-delete-display-button"
                                        disabled={this.props.display.getOnline()}>
                                        <FormattedMessage id="tcmDisplay.action.delete" />
                                    </Button>
                                </FlexboxGrid.Item>
                            </SecuredFragment>
                        </FlexboxGrid>
                    )}
                </Drawer.Footer>
            </Drawer>
        );
    }
}
