import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    CheckPicker,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    SelectPicker,
} from 'rsuite';
import { StepTaskData, StepTypeEnum } from '..';
import Task from '../../../../../handlers/Event/Task';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import { TCMDisplayForceForm } from '../../Tasks/CreateTaskType';

type CountersState = {
    label: string;
    value: number;
};

type Props = {
    onClickBack: () => void;
    currentTask: StepTaskData;
    closeModal: () => void;
    type: StepTypeEnum;
    existingTask?: Task;
};

type FormValue = {
    tcmDisplays: number[];
    forceType: number;
};

export const TCMDisplayForceStepType = (props: Props) => {
    const intl = useIntl();

    const [tcmDisplays, setTcmDisplays] = React.useState<CountersState[]>([]);

    const [formValue, setFormValue] = React.useState<FormValue>({
        tcmDisplays: props.currentTask.tcmDisplays,
        forceType: props.currentTask.forceOption.text || 0,
    });

    const [isCreating, setIsCreating] = React.useState(false);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/map-tcm-displays', {
                headers: authHeader(),
            })
            .then(tcmDisplayResponse => {
                setTcmDisplays(
                    tcmDisplayResponse.data.map(mapTcmDisplay => {
                        return {
                            label: `${mapTcmDisplay.label}`,
                            value: mapTcmDisplay.tcmDisplay.id,
                        };
                    })
                );
            });
    }, []);

    const handleValid = () => {
        setIsCreating(true);

        if (props.type === StepTypeEnum.CREATE) {
            axiosService
                .getAxios()
                .post(
                    '/event-actions/tasks',
                    {
                        idEvent: props.currentTask.eventId,
                        name: props.currentTask.name,
                        desc: props.currentTask.description,
                        forceType: props.currentTask.type,
                        forceOption: {
                            text: formValue.forceType,
                        },
                        tabTcmDisplayId: formValue.tcmDisplays,
                        tabLpMatrixDisplayV2Id: [],
                        tabCounterId: [],
                        tabIpcanId: [],
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'event.createTask.success' }));

                    setIsCreating(false);

                    props.closeModal();
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(intl.formatMessage({ id: 'event.createTask.error' }));

                    setIsCreating(false);
                });
        } else {
            axiosService
                .getAxios()
                .put(
                    '/event-actions/tasks',
                    {
                        id: props.existingTask?.getId(),
                        name: props.currentTask.name,
                        desc: props.currentTask.description,
                        forceType: props.currentTask.type,
                        forceOption: {
                            text: formValue.forceType,
                        },
                        tabTcmDisplayId: formValue.tcmDisplays,
                        tabLpMatrixDisplayV2Id: [],
                        tabCounterId: [],
                        tabIpcanId: [],
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'event.editTask.success' }));

                    setIsCreating(false);

                    props.closeModal();
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(intl.formatMessage({ id: 'event.editTask.error' }));

                    setIsCreating(false);
                });
        }
    };

    return (
        <div>
            <Form formValue={formValue} onChange={value => setFormValue(value as FormValue)} fluid>
                <FormGroup>
                    <ControlLabel data-cy="event-createTask-tcmDisplays-title">
                        {intl.formatMessage({ id: 'event.createTask.tcmDisplays' })}
                    </ControlLabel>
                    <FormControl
                        data-cy="event-createTask-tcmDisplays"
                        accepter={CheckPicker}
                        searchable={false}
                        cleanable={false}
                        data={tcmDisplays}
                        name="tcmDisplays"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel data-cy="event-createTask-forceType-title">
                        {intl.formatMessage({ id: 'event.createTask.forceType' })}
                    </ControlLabel>
                    <FormControl
                        data-cy="event-createTask-forceType-title"
                        accepter={SelectPicker}
                        searchable={false}
                        cleanable={false}
                        data={TCMDisplayForceForm(intl)}
                        name="forceType"
                    />
                </FormGroup>
            </Form>

            <FlexboxGrid align="middle" justify="end" className="margin-top-15">
                <FlexboxGrid.Item>
                    <Button
                        onClick={() => props.onClickBack()}
                        color="blue"
                        className="margin-right-5"
                        disabled={isCreating}>
                        <FontAwesomeIcon icon={faArrowLeft} className="margin-right-5" />
                        <FormattedMessage id="event.createTask.back" />
                    </Button>

                    <Button
                        data-cy="event-createTask-valid"
                        color="green"
                        onClick={() => handleValid()}
                        loading={isCreating}
                        disabled={formValue.tcmDisplays.length === 0}>
                        <FontAwesomeIcon icon={faCheck} className="margin-right-5" />
                        <FormattedMessage id="event.createTask.valid" />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};
