import { faPause, faPlay, faRecycle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Line } from 'react-chartjs-2';
import { FormattedMessage } from 'react-intl';
import { Button, InputNumber, Panel, Tooltip, Whisper } from 'rsuite';
import PanelHeader from '../../../Custom/PanelHeader';

export default function realTimeGraph(
    realtimeChange: boolean,
    data: any[],
    toggleWebsocket: Function,
    resetArray: Function,
    setMaxPoints: Function
) {
    // const intl = useIntl();

    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    ticks: {
                        display: false,
                    },
                    barThickness: 6,
                    // unit: 'second',
                    beginAtZero: false,
                    // type: 'time',
                    distribution: 'linear',
                    // time: {
                    //     displayFormats: {
                    //         second: 'HH:mm:ss',
                    //     },
                    // },
                },
            ],
        },
        tooltips: {
            enabled: !realtimeChange,
        },
    };

    const datasets = [
        {
            label: 'C1>1 (old)',
            show: false,
            fill: true,
            hidden: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(255,0,120,0.4)',
            borderColor: 'rgba(255,0,120,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(255,0,120,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(255,0,120,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.c1_power1),
        },
        {
            label: 'C1>1',
            show: false,
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(120,99,255,0.4)',
            borderColor: 'rgba(120,99,255,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(120,99,255,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(120,99,255,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.c1_distance1),
        },
        {
            label: 'C1>2 (old)',
            show: false,
            fill: true,
            hidden: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(255,99,132,0.4)',
            borderColor: 'rgba(255,99,132,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(255,99,132,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(255,99,132,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.c1_power2),
        },
        {
            label: 'C1>2',
            show: false,
            fill: true,
            hidden: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(0,120,0,0.4)',
            borderColor: 'rgba(0,120,0,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(0,120,0,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(0,120,0,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.c1_distance2),
        },
        {
            label: 'C2>1 (old)',
            show: false,
            fill: true,
            hidden: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(76,16,76,0.4)',
            borderColor: 'rgba(76,16,76,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(76,16,76,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(76,16,76,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.c2_power1),
        },
        {
            label: 'C2>1',
            show: false,
            fill: true,
            hidden: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(64,64,0,0.4)',
            borderColor: 'rgba(64,64,0,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(64,64,0,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(64,64,0,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.c2_distance1),
        },
        {
            label: 'C2>2 (old)',
            show: false,
            fill: true,
            hidden: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(189,98,53,0.4)',
            borderColor: 'rgba(189,98,53,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(189,98,53,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(189,98,53,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.c2_power2),
        },
        {
            label: 'C2>2',
            show: false,
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(0,175,64,0.4)',
            borderColor: 'rgba(0,175,64,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(0,175,64,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(0,175,64,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.c2_distance2),
        },
        {
            label: 'Value',
            show: false,
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(0,75,255,0.4)',
            borderColor: 'rgba(0,75,255,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(0,75,255,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(0,75,255,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.value),
        },
        {
            label: 'sensDetect',
            show: false,
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(244,67,54,0.4)',
            borderColor: 'rgba(244,67,54,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            borderWidth: 6,
            pointBorderColor: 'rgba(244,67,54,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(244,67,54,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.sDetection),
        },
        {
            label: 'sensRelease',
            show: false,
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(0,192,25,0.4)',
            borderColor: 'rgba(0,192,25,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            borderWidth: 6,
            pointBorderColor: 'rgba(0,192,25,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(0,192,25,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: data.map(d => d.sRelease),
        },
    ];

    const labels = data.map(d => d.date);

    return (
        <Panel
            data-cy="ipCanDevices-LPSensor-debugGraph"
            className="panel-small"
            shaded
            bordered
            bodyFill
            style={{ height: 470 }}
            header={
                <PanelHeader
                    title={'ipCanDevices.LPSensor.debugGraph'}
                    input={
                        <InputNumber
                            min={10}
                            max={100}
                            defaultValue={'30'}
                            onChange={value => setMaxPoints(value)}
                            disabled={realtimeChange}
                            size="xs"
                            className="form-margin"
                        />
                    }
                    buttons={[
                        <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={
                                <Tooltip>
                                    <FormattedMessage id="lpSensor.debug.reset" />
                                </Tooltip>
                            }>
                            <Button data-cy="ipCanDevices-lpSensor-resetArray" size="sm" color="orange" onClick={() => resetArray()} disabled={realtimeChange}>
                                <FontAwesomeIcon icon={faRecycle} />
                            </Button>
                        </Whisper>,
                        <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={
                                <Tooltip>
                                    <FormattedMessage id={realtimeChange ? 'lpSensor.debug.stop' : 'lpSensor.debug.start'} />
                                </Tooltip>
                            }>
                            <Button data-cy="ipCanDevices-lpSensor-start-stop" size="sm" color={realtimeChange ? 'red' : 'green'} onClick={() => toggleWebsocket()}>
                                <FontAwesomeIcon icon={realtimeChange ? faPause : faPlay} />
                            </Button>
                        </Whisper>,
                    ]}
                />
            }>
            <Line data={{ labels, datasets }} options={options} height={410} />
        </Panel>
    );
}
