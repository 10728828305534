import { faRobot, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FlexboxGrid, Loader, Message, Modal } from 'rsuite';
import TcmSensorHistoricHandler from '../../../../../handlers/history/TcmSensorHistoric.handler';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import HistorySortPart from '../../../../History/SortPart';
import ReadRealSensorExpand from '../../../../History/Table/Expandable/ReadRealSensorExpand';
import TCMSensorDescCell from '../../../../History/Table/TCMSensorDescCell';
import TCMSensorOptionCell from '../../../../History/Table/TCMSensorOptionCell';
import HistoryTable from '../../../../ReactDataTableComponent/HistoryTable';

type Props = {
    show: boolean;
    onHide: Function;
    sensor: Record<string, any>;
    intl: Record<string, any>;
    ipCanId: number;
} & WrappedComponentProps;

type State = {
    histories: Array<Record<string, any>>;
    isLoading: boolean;
    hasError: boolean;
    users: Array<Record<string, any>>;
    formValues: Record<string, any>;
    ipcanModule: Record<string, any>;
};
class TCMSensorHistoryModal extends React.Component<Props, State> {
    columns: TableColumn<Record<string, any>>[];
    constructor(props) {
        super(props);

        this.state = {
            histories: [],
            isLoading: true,
            hasError: false,
            users: [],
            formValues: {
                user: null,
                before: null,
                after: null,
            },
            ipcanModule: {},
        };

        this.setUser = this.setUser.bind(this);
        this.setBefore = this.setBefore.bind(this);
        this.setAfter = this.setAfter.bind(this);
        this.setNbElements = this.setNbElements.bind(this);
        this.loadHistory = this.loadHistory.bind(this);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.user',
                }),
                selector: 'user',
                sortable: true,
                cell: row => {
                    if (row.user === 'MQTT') {
                        return <FontAwesomeIcon icon={faRobot} size="lg" />;
                    } else {
                        return row.user;
                    }
                },
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.createdAt',
                }),
                selector: 'createdAt',
                sortable: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.desc',
                }),
                selector: 'desc',
                sortable: true,
                cell: row => TCMSensorDescCell(row.desc),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.option',
                }),
                selector: 'option',
                cell: row => TCMSensorOptionCell(row.desc, row.option),
            },
        ];
    }

    setUser(user) {
        this.setState({
            formValues: { ...this.state.formValues, user },
        });
    }

    setBefore(before) {
        this.setState({
            formValues: { ...this.state.formValues, before: moment(before).add(1, 'hour') },
        });
    }

    setAfter(after) {
        this.setState({
            formValues: { ...this.state.formValues, after: moment(after).add(1, 'hour') },
        });
    }

    setNbElements(nbElements) {
        this.setState({
            formValues: { ...this.state.formValues, nbElements },
        });
    }

    loadHistory() {
        this.setState({
            isLoading: true,
        });

        axiosService
            .getAxios()
            .get(`/ipcanmodules/${this.props.ipCanId}`, { headers: authHeader() })
            .then(ipcanModuleResponse => {
                this.setState({
                    ipcanModule: ipcanModuleResponse.data,
                });

                axiosService
                    .getAxios()
                    .post(
                        '/historics/tcm-sensors',
                        {
                            nbResult: 100,
                            id: this.props.sensor.id,
                            user: this.state.formValues.user,
                            after: this.state.formValues.after
                                ? moment(this.state.formValues.after).toISOString()
                                : null,
                            before: this.state.formValues.before
                                ? moment(this.state.formValues.before).toISOString()
                                : null,
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(tcmSensorHistoryResponse => {
                        let users: Array<string> = [];

                        const histories = tcmSensorHistoryResponse.data.map(history => {
                            const tcmDisplayHistory = new TcmSensorHistoricHandler(history);

                            users.indexOf(tcmDisplayHistory.getUser()) === -1 &&
                                users.push(tcmDisplayHistory.getUser());

                            let formattedHistory = tcmDisplayHistory.getHistory();

                            if (
                                formattedHistory.desc !== 'read real sensor config' ||
                                (formattedHistory.desc === 'read real sensor config' && formattedHistory.option.error)
                            ) {
                                formattedHistory.disabled = true;
                            } else {
                                formattedHistory.disabled = false;
                            }

                            return formattedHistory;
                        });

                        this.setState({
                            histories,
                            users: users.map(user => {
                                return { label: user, value: user };
                            }),
                        });
                    })
                    .catch(err => {
                        console.error(err);

                        this.setState({
                            hasError: true,
                        });
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            });
    }

    componentDidMount() {
        this.loadHistory();
    }

    render() {
        return (
            <Modal
                full
                backdrop="static"
                show={this.props.show}
                onHide={() => this.props.onHide()}
                style={{ height: '95%' }}
                className="no-modal-padding">
                <Modal.Header style={{ backgroundColor: '#212121' }}>
                    <HistorySortPart
                        users={this.state.users}
                        setUser={this.setUser}
                        setBefore={this.setBefore}
                        setAfter={this.setAfter}
                        valid={this.loadHistory}
                        setNbElements={this.setNbElements}
                    />
                </Modal.Header>
                <Modal.Body>
                    {this.state.hasError ? (
                        <Message type="error">
                            <FormattedMessage id="history.tcmDisplay.fetchHistoryError" />
                        </Message>
                    ) : (
                        <Fragment>
                            <FlexboxGrid align="middle" justify="center">
                                <FlexboxGrid.Item>
                                    <FontAwesomeIcon
                                        icon={faWifi}
                                        rotation={180}
                                        size="2x"
                                        className="margin-right-5"
                                    />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <h4 className="text-center">
                                        {this.state.isLoading ? (
                                            <Loader />
                                        ) : (
                                            `${this.state.ipcanModule.label}-${this.props.sensor.bus + 1}-${
                                                this.props.sensor.deviceId
                                            }`
                                        )}
                                    </h4>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            <div data-cy="history-tcmSensor-history-table">
                                <HistoryTable
                                    columns={this.columns}
                                    data={this.state.histories}
                                    progressPending={this.state.isLoading}
                                    expandableRows
                                    expandOnRowClicked
                                    expandableRowDisabled={row => row.disabled}
                                    expandableRowsComponent={ReadRealSensorExpand}
                                />
                            </div>
                        </Fragment>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}
export default injectIntl(TCMSensorHistoryModal);
