import {
    faArrowAltCircleUp,
    faCheck,
    faEthernet,
    faEye,
    faEyeSlash,
    faNetworkWired,
    faServer,
    faTag,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    InputGroup,
    InputNumber,
    Message,
    Modal,
    SelectPicker,
    Toggle,
} from 'rsuite';
import {
    LPDisplayGMTSelector,
    LPDisplayNbPixelX,
    LPDisplayNbPixelY,
    LPDisplayOriginSelector,
    LPDisplayTypeSelector,
} from '../../../IpCanElementsPage/Drawer/LPDisplay/Form/LPDisplaySelect';
import {
    DisplayBusSelector,
    DisplayTypeSelector,
    TCMDisplayArrowFormValue
} from './Form/DisplayFormValue';

type Props = {
    validCreation: Function;
    cancelCreation: Function;
    show: boolean;
    loading: boolean;
    onChange: Function;
    values: Record<string, any>;
    controllers: Array<Record<string, any>>;
    error: boolean;
    intl: Record<string, any>;
} & WrappedComponentProps;

type State = {
    controllerSelector: Array<Record<string, any>>;
    loading: boolean;
};
class CreateDisplayModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        let controllerSelector: Array<Record<string, any>> = [];

        for (let c in this.props.controllers) {
            controllerSelector.push({
                label: `${this.props.controllers[c].label} (${this.props.controllers[c].macStr})`,
                value: this.props.controllers[c].id,
            });
        }

        this.state = {
            loading: false,
            controllerSelector: controllerSelector,
        };
    }

    render() {
        return (
            <Modal backdrop={'static'} show={this.props.show} onHide={() => this.props.cancelCreation()} size="md">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="map.display.createDisplay" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.error && (
                        <Message
                            className="margin-bottom-15"
                            type="error"
                            description={<FormattedMessage id="map.display.creationError" />}
                        />
                    )}
                    <Form fluid onChange={values => this.props.onChange(values)} formValue={this.props.values}>
                        {/* Label of display */}
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-5">
                                <h5 className="text-purple">
                                    <FormattedMessage id="map.display.labelling" />
                                </h5>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel>
                                    <FormattedMessage id="map.display.label" />
                                </ControlLabel>
                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faTag} />
                                    </InputGroup.Addon>
                                    <FormControl data-cy="display-create-label" name="label" />
                                </InputGroup>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel>
                                    <FormattedMessage id="map.display.isShow" />
                                </ControlLabel>
                                <FormControl
                                    data-cy="display-create-isShow"
                                    checked={this.props.values.isShow}
                                    size="lg"
                                    style={{ left: '35%' }}
                                    accepter={Toggle}
                                    checkedChildren={<FontAwesomeIcon icon={faEye} />}
                                    unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} />}
                                    name="isShow"
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <hr />
                        {/* Type of display */}
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-5">
                                <h5 className="text-purple">
                                    <FormattedMessage id="map.display.type" />
                                </h5>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel>
                                    <FormattedMessage id="map.display.type" />
                                </ControlLabel>
                                <FormControl
                                    data-cy="display-create-type"
                                    accepter={SelectPicker}
                                    cleanable={false}
                                    data={DisplayTypeSelector()}
                                    name="type"
                                    renderMenuItem={(label, item) => {
                                        return <div data-cy={`display-create-type-value-${item.value}`}>{label}</div>;
                                    }}
                                />
                            </FlexboxGrid.Item>

                            {this.props.values.type === 'tcm' && (
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                    <ControlLabel>
                                        <FormattedMessage id="map.display.mapArrowRotation" />
                                    </ControlLabel>
                                    <FormControl
                                        data-cy="display-create-mapArrowRotation"
                                        accepter={SelectPicker}
                                        cleanable={false}
                                        searchable={false}
                                        data={TCMDisplayArrowFormValue(this.props.intl)}
                                        name="mapArrowRotation"
                                        renderMenuItem={(label, item: any) => {
                                            const style = {
                                                transform: `rotate(${item.rotation}deg)`,
                                            };

                                            if (item.value === 0) {
                                                return (
                                                    <div
                                                        data-cy={`display-create-mapArrowRotation-value-${item.value}`}>
                                                        <FontAwesomeIcon
                                                            className="margin-right-15"
                                                            icon={faTimesCircle}
                                                        />
                                                        {label}
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        data-cy={`display-create-mapArrowRotation-value-${item.value}`}>
                                                        <FontAwesomeIcon
                                                            className="margin-right-15"
                                                            icon={faArrowAltCircleUp}
                                                            style={style}
                                                        />
                                                        {label}
                                                    </div>
                                                );
                                            }
                                        }}
                                        renderValue={(value, item: any) => {
                                            const style = {
                                                transform: `rotate(${item.rotation}deg)`,
                                            };
                                            if (value === 0) {
                                                return (
                                                    <div>
                                                        <FontAwesomeIcon
                                                            style={style}
                                                            className="margin-right-15"
                                                            icon={faTimesCircle}
                                                        />
                                                        {item.label}
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div>
                                                        <FontAwesomeIcon
                                                            style={style}
                                                            className="margin-right-15"
                                                            icon={faArrowAltCircleUp}
                                                        />
                                                        {item.label}
                                                    </div>
                                                );
                                            }
                                        }}
                                    />
                                </FlexboxGrid.Item>
                            )}

                            {/* {this.props.values.type === 'lpDisplayMatrixV2' && (
                                <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                    <ControlLabel>
                                        <FormattedMessage id="map.display.mapArrowRotation" />
                                    </ControlLabel>
                                    <FormControl
                                        data-cy="display-create-mapArrowRotation"
                                        accepter={SelectPicker}
                                        cleanable={false}
                                        searchable={false}
                                        data={LPDisplayArrowFormValue()}
                                        name="mapArrowRotation"
                                    />
                                </FlexboxGrid.Item>
                            )} */}
                        </FlexboxGrid>
                        <hr />
                        {/* Controller informations */}
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-5">
                                <h5 className="text-purple">
                                    <FormattedMessage id="map.display.controllerAndAddress" />
                                </h5>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-bottom-15">
                                <ControlLabel>
                                    <FormattedMessage id="map.display.controller" />
                                </ControlLabel>

                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faServer} />
                                    </InputGroup.Addon>
                                    <FormControl
                                        data-cy="display-create-controller"
                                        accepter={SelectPicker}
                                        cleanable={false}
                                        data={this.state.controllerSelector}
                                        name="ipcan"
                                        renderMenuItem={(label, item) => {
                                            return (
                                                <div data-cy={`display-create-controller-value-${item.value}`}>
                                                    {label}
                                                </div>
                                            );
                                        }}
                                    />
                                </InputGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                <ControlLabel>
                                    <FormattedMessage id="map.display.bus" />
                                </ControlLabel>

                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faEthernet} />
                                    </InputGroup.Addon>
                                    <FormControl
                                        data-cy="display-create-bus"
                                        accepter={SelectPicker}
                                        cleanable={false}
                                        data={DisplayBusSelector()}
                                        searchable={false}
                                        name="bus"
                                        renderMenuItem={(label, item) => {
                                            return (
                                                <div data-cy={`display-create-bus-value-${item.value}`}>{label}</div>
                                            );
                                        }}
                                    // disabledItemValues={this.props.values.type === 'tcm' ? [2] : []}
                                    />
                                </InputGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                <ControlLabel>
                                    <FormattedMessage id="map.display.id" />
                                </ControlLabel>
                                <InputGroup style={{ marginTop: 0 }}>
                                    <InputGroup.Addon>
                                        <FontAwesomeIcon icon={faNetworkWired} />
                                    </InputGroup.Addon>
                                    <FormControl data-cy="display-create-id" type="number" name="id" />
                                </InputGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <hr />
                        {this.props.values.type === 'lpDisplayMatrixV2' && (
                            <Fragment>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                        <ControlLabel>
                                            <FormattedMessage id="map.display.nbPixelX" />
                                        </ControlLabel>

                                        <FormControl
                                            className="form-margin"
                                            placement="auto"
                                            name="nbPixelX"
                                            accepter={SelectPicker}
                                            data={LPDisplayNbPixelX(this.props.intl)}
                                            cleanable={false}
                                            searchable={false}
                                        />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                        <ControlLabel>
                                            <FormattedMessage id="map.display.nbPixelY" />
                                        </ControlLabel>
                                        <FormControl
                                            className="form-margin"
                                            placement="auto"
                                            name="nbPixelY"
                                            accepter={SelectPicker}
                                            data={LPDisplayNbPixelY(this.props.intl)}
                                            disabledItemValues={LPDisplayNbPixelY(this.props.intl)
                                                .map(nbPixel => nbPixel.value)
                                                .map(value => {
                                                    if ((value / 16) * (this.props.values.nbPixelX / 32) > 16)
                                                        return value;
                                                })}
                                            cleanable={false}
                                            searchable={false}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>

                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                        <ControlLabel>
                                            <FormattedMessage id="map.display.typeMatrix" />
                                        </ControlLabel>

                                        <FormControl
                                            className="form-margin"
                                            name="typeMatrix"
                                            accepter={SelectPicker}
                                            cleanable={false}
                                            data={LPDisplayTypeSelector()}
                                            searchable={false}
                                        />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                        <ControlLabel>
                                            <FormattedMessage id="map.display.brightness" />
                                        </ControlLabel>
                                        <FormControl
                                            className="form-margin"
                                            name="brightness"
                                            accepter={InputNumber}
                                            cleanable={false}
                                            min={0}
                                            max={100}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>

                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                        <ControlLabel>
                                            <FormattedMessage id="map.display.origin" />
                                        </ControlLabel>

                                        <FormControl
                                            className="form-margin"
                                            name="origin"
                                            accepter={SelectPicker}
                                            cleanable={false}
                                            searchable={false}
                                            placement="auto"
                                            data={LPDisplayOriginSelector(this.props.intl)}
                                        />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} className="margin-bottom-15">
                                        <ControlLabel>
                                            <FormattedMessage id="map.display.gmt" />
                                        </ControlLabel>
                                        <FormControl
                                            className="form-margin"
                                            name="gmt"
                                            accepter={SelectPicker}
                                            cleanable={false}
                                            searchable={false}
                                            placement="auto"
                                            data={LPDisplayGMTSelector()}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </Fragment>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={() => this.props.cancelCreation()} data-cy="display-create-cancel" color="red">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={() => this.props.validCreation()}
                            loading={this.state.loading}
                            data-cy="display-create-valid"
                            color="green">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(CreateDisplayModal);
