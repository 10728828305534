
import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, CheckPicker, Modal } from 'rsuite';
import { Parking } from '../../../../../handlers/parking/Parking';
import { User } from '../../../../../handlers/profile/User';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    user: User;
    parkings: Parking[];
    reloadUsers: () => void;
    onClose: () => void;
    isOpen: boolean;
}

export const EditUserParkings = (props: Props) => {
    const intl = useIntl();

    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [parkingsToSet, setParkingsToSet] = React.useState<number[]>(() => {
        const currentUserParkings: number[] = [];
        props.parkings.forEach(parking => {
            if (!props.user.getParkingList().includes(parking.getId())) {
                currentUserParkings.push(parking.getId());
            }
        });

        return currentUserParkings;
    });

    useEffect(() => {
        const currentUserParkings: number[] = [];
        props.parkings.forEach(parking => {
            if (!props.user.getParkingList().includes(parking.getId())) {
                currentUserParkings.push(parking.getId());
            }
        });
        setParkingsToSet(currentUserParkings);
    }, [props.user.getId(), props.parkings.length])

    const handleValid = () => {
        setIsEditing(true);

        axiosService.getAxios().put('/users/updateListParking', {
            id: props.user.getId(),
            parkings: props.parkings.filter(park => !parkingsToSet.includes(park.getId())).map(park => park.getId()),
        }, {
            headers: authHeader()
        }).then(() => {
            Alert.success(intl.formatMessage({ id: 'user.updateListParking.success' }));

            props.reloadUsers();

            props.onClose();
        }).catch((err) => {
            console.error(err);

            Alert.error(intl.formatMessage({ id: 'user.updateListParking.error' }));
        }).finally(() => {
            setIsEditing(false);
        });
    }

    const handleClose = () => {
        if (!isEditing) {
            setParkingsToSet(props.user.getParkingList());
            props.onClose();
        }
    }

    return (
        <Modal backdrop="static" show={props.isOpen} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="user.updateParkingList.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <b>
                    {props.user.getFirstName()} {props.user.getLastName()}
                </b>
                <br />
                <CheckPicker
                    data-cy="editUserParkingList-picker"
                    data={props.parkings.map(parking => {
                        return {
                            label: parking.getName(),
                            value: parking.getId(),
                        };
                    })}
                    block
                    value={parkingsToSet}
                    onChange={value => setParkingsToSet(value)}
                />
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={handleClose} color="red" disabled={isEditing} data-cy="editUserParkingList-cancel">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button onClick={handleValid} color="green" loading={isEditing} data-cy="editUserParkingList-validate">
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}