import { LatLngExpression } from 'leaflet';
import { LPSensor } from '../ipCanDevices/LPSensor';
import { LPSensorForceColorConf } from '../ipCanDevices/Sensor/LPSensorForceColorConf';
import { LPSensorForceType } from '../ipCanDevices/Sensor/LPSensorForceType';
import { LPSensorUsConfig } from '../ipCanDevices/Sensor/LPSensorUsConfig';
import { Scene } from '../scene/Scene';
import { MapSensorBase } from './MapSensorBase';

export class MapLPSensor extends MapSensorBase {
    private _sensor: LPSensor;

    constructor(data, sensorPlaceType) {
        super(data, sensorPlaceType);

        this._sensor = new LPSensor(data.lpSensor, data.lpSensor.ipcanmodule.id, null, null);
    }

    public get sensor() {
        return this._sensor;
    }

    public setSensor(sensor_) {
        this._sensor.setWebsocketInformation(sensor_.sensor);
    }

    public updateSensor(sensor_, sensorPlaceType_) {
        this.setLabel(sensor_.label);
        this.setUpdatedAt(sensor_.updatedAt);
        this.setUpdatedBy(sensor_.updatedBy);
        this.setGeoJSON(sensor_.geoJSON);
        this.setSensorPlaceType(sensorPlaceType_);
        this._sensor.updateSensor(sensor_.lpSensor);
    }

    public getMapSensor(): LPSensorMapInformation_I {
        return {
            detectionState: this._sensor.detectionState,
            detectionStateTime: this._sensor.detectionStateTimeFormatted,
            online: this._sensor.online,
            label: this.label,
            ipCanId: this._sensor.ipCanId,
            bus: this._sensor.bus,
            deviceId: this._sensor.deviceId,
            lastOnlineStateDate: this._sensor.lastOnlineStateDateFormatted,
            forceType: this._sensor.forceType,
            forceColorConf: this._sensor.forceColorConf,
            geoJSON: this.geoJSON,
            isForce: this._sensor.isForce,
            id: this._sensor.id,
            sensorPlaceType: this._sensor.sensorPlaceType?.id,
            us1Config: this._sensor.us1Config,
            us2Config: this._sensor.us2Config,
            type: 'lp',
            mapSensorId: this.id,
            lpSensor: this._sensor,
            scenes: this.getScenes(),
        };
    }
}

export interface LPSensorMapInformation_I {
    detectionState: number;
    detectionStateTime: string;
    online: boolean;
    label: string;
    ipCanId: number;
    bus: number;
    deviceId: number;
    lastOnlineStateDate: string;
    forceType: LPSensorForceType;
    forceColorConf: LPSensorForceColorConf;
    geoJSON: LatLngExpression;
    isForce: boolean;
    id: number;
    sensorPlaceType?: number;
    us1Config: LPSensorUsConfig;
    us2Config: LPSensorUsConfig;
    type: string;
    mapSensorId: number;
    lpSensor: LPSensor;
    scenes: [Scene, Scene, Scene, Scene];
}
