import { faCheck, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Col, ControlLabel, FlexboxGrid, Form, FormControl, Panel } from 'rsuite';
import { userActions } from '../../../../../redux/actions';
import { rolesConstants } from '../../../../../static/roles';
import SecuredFragment from '../../../../Auth/SecuredFragment';
import PanelHeader from '../../../../Custom/PanelHeader';

const mapDispatchToProps = dispatch => ({
    updateUserPassword: (id, user) => {
        dispatch(userActions.updateUserPassword(id, user.password, user.repeatedPassword));
    },
});

type Props = {
    isLoading: boolean;
    user: Record<string, any>;
    updateUserPassword: Function;
} & WrappedComponentProps;

type State = {
    editMode: boolean;
    formValue: FormValue;
    enableValid: boolean;
};

type FormValue = {
    password: string;
    repeatedPassword: string;
};
class UserInformations extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            formValue: {
                password: '',
                repeatedPassword: '',
            },
            enableValid: false,
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.unsetEditMode = this.unsetEditMode.bind(this);
        this.cancelEditMode = this.cancelEditMode.bind(this);
        this.setFormValue = this.setFormValue.bind(this);
        this.validEdition = this.validEdition.bind(this);
    }

    setEditMode() {
        this.setState({ editMode: true });
    }

    unsetEditMode() {
        this.setState({ editMode: false });
    }

    cancelEditMode() {
        this.setState({
            editMode: false,
            formValue: {
                password: '',
                repeatedPassword: '',
            },
        });
    }

    validEdition() {
        this.unsetEditMode();
        this.props.updateUserPassword(this.props.user.id, this.state.formValue);
        this.setState({
            formValue: {
                password: '',
                repeatedPassword: '',
            },
        });
    }

    setFormValue(formValue) {
        const { password, repeatedPassword } = formValue;

        this.setState({
            formValue: {
                password,
                repeatedPassword,
            },
            enableValid: password && repeatedPassword && repeatedPassword === password,
        });
    }

    render() {
        return (
            <Loader show={this.props.isLoading} message="loading">
                <Panel
                    shaded
                    bordered
                    className="panel-small"
                    data-cy="users-password"
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'user.password' })}
                            buttons={[
                                <SecuredFragment
                                    key="user.editPassword"
                                    authorizedRoles={[rolesConstants.user.UPDATE_PASSWORD]}>
                                    <Button
                                        data-cy="edit-userPassword-button"
                                        onClick={this.setEditMode}
                                        appearance="primary"
                                        size="sm">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }>
                    <Form
                        formValue={this.state.formValue}
                        onChange={formValue => this.setFormValue(formValue)}
                        fluid
                        style={{ marginTop: 10 }}>
                        <FlexboxGrid align="middle" justify="end">
                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="user.edit.password" />
                                </ControlLabel>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={16}>
                                <FormControl
                                    size="sm"
                                    name="password"
                                    type="password"
                                    data-cy="edit-userPassword"
                                    disabled={!this.state.editMode}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="user.edit.repeatPassword" />
                                </ControlLabel>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={16}>
                                <FormControl
                                    size="sm"
                                    name="repeatedPassword"
                                    type="password"
                                    data-cy="edit-userRepeatPassword"
                                    disabled={!this.state.editMode}
                                />
                            </FlexboxGrid.Item>

                            {this.state.editMode && (
                                <FlexboxGrid.Item style={{ marginTop: 5 }}>
                                    <ButtonGroup>
                                        <Button
                                            data-cy="edit-userPassword-cancel"
                                            color="red"
                                            onClick={this.cancelEditMode}>
                                            <FontAwesomeIcon icon={faTimesCircle} />
                                        </Button>
                                        <Button
                                            data-cy="edit-userPassword-valid"
                                            color="green"
                                            onClick={this.validEdition}
                                            disabled={!this.state.enableValid}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </Button>
                                    </ButtonGroup>
                                </FlexboxGrid.Item>
                            )}
                        </FlexboxGrid>
                    </Form>
                </Panel>
            </Loader>
        );
    }
}

function mapStateToProps(state) {
    let { isLoading } = state.users;

    return { isLoading };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserInformations));
