import { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, FlexboxGrid } from 'rsuite';
import { Level } from '../../handlers/Level/Level';
import { SensorPlaceType } from '../../handlers/sensorPlaceType/SensorPlaceType';
import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';
import { rolesConstants } from '../../static/roles';
import noImage from '../../style/assets/eye-slash-solid.svg';
import SecuredFragment from '../Auth/SecuredFragment';
import { LevelCounter } from './CountersPage';
import { ParkingStatDoughnut } from './Doughnut/ParkingStatDoughnut';
import { PlaceTypeDoughnut } from './Doughnut/PlaceTypeDoughnut';
import OccupationGraphModal from './Modal/OccupationGraphModal';
import OccupationGraph from './OccupationGraph';
import { PlaceTypeCounterValue } from './ParkingPanel';

type Props = {
    level: Level;
    levelCounter?: LevelCounter;
    sensorPlaceTypes: SensorPlaceType[];
    levelLength: number;
};

export const LevelPanel = (props: Props) => {
    const history = useHistory();

    const [image, setImage] = useState<string | null>(null);
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/levels/${props.level.getId()}`, {
                headers: authHeader(),
            })
            .then(response => {
                if (response.data.image) {
                    setImage(Buffer.from(response.data.image, 'base64').toString('base64'));
                }
            });
    }, []);

    const placeTypeCounterValue: PlaceTypeCounterValue[] = [];

    if (props.sensorPlaceTypes.length > 0 && props.levelCounter) {
        Object.keys(props.levelCounter.counterValue).forEach(placeTypeId => {
            if (placeTypeId !== 'all') {
                const currentSensorPlaceType = props.sensorPlaceTypes.find(
                    sensorPlaceType => sensorPlaceType.getId() === parseInt(placeTypeId)
                );

                if (currentSensorPlaceType) {
                    const currentPlaceType = currentSensorPlaceType.getPlaceType();

                    if (currentPlaceType) {
                        placeTypeCounterValue.push({
                            id: currentSensorPlaceType.getId(),
                            label: currentSensorPlaceType.getLabel(),
                            color: currentSensorPlaceType._scenes[0].color,
                            imageType: currentPlaceType.imageType,
                            image: currentSensorPlaceType.getPlaceType()?.generateImage(),
                            free: props.levelCounter?.counterValue[placeTypeId].free || 0,
                            occupied: props.levelCounter?.counterValue[placeTypeId].occupied || 0,
                        });
                    }
                }
            }
        });
    }

    let totalHeight = props.levelLength === 2 ? 180 : 130;
    let placeTypeHeight = props.levelLength === 2 ? 130 : 100;

    const handleOpenOccupationGraph = (event: SyntheticEvent) => {
        event.stopPropagation();

        setHistoryModalOpen(true);
    };

    return (
        <SecuredFragment authorizedRoles={[rolesConstants.counters.VIEW_LIST]}>
            <FlexboxGrid
                data-cy="level-panel"
                align="middle"
                className={'flexbox-scrollable flex-hoverable panel-counter-level'}
                onClick={() => history.push(`/map/${props.level.getId()}`)}>
                <FlexboxGrid.Item
                    componentClass={Col}
                    xs={2}
                    className="text-center margin-side-10"
                    style={{ width: 80 }}
                    onClick={() => history.push(`/map/${props.level.getId()}`)}>
                    {image ? (
                        <img
                            src={`data:image/${props.level.getImageType()};base64, ${image}`}
                            style={{
                                height: 75,
                                backgroundColor: 'white',
                                lineHeight: 1,
                            }}
                            alt="Logo"
                        />
                    ) : (
                        <img
                            src={noImage}
                            style={{
                                width: 44,
                                backgroundColor: 'white',
                                lineHeight: 1,
                            }}
                            alt="empty"
                        />
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center margin-side-10" onClick={handleOpenOccupationGraph}>
                    {props.levelCounter && (
                        <OccupationGraph height={180} counterId={props.levelCounter.id} displayLegend={false} />
                    )}
                </FlexboxGrid.Item>
                {/* TOTAL */}
                <FlexboxGrid.Item className="text-center margin-side-10">
                    <ParkingStatDoughnut
                        title={props.level.getName()}
                        counterValue={props.levelCounter!.counterValue.all}
                        height={totalHeight}
                    />
                </FlexboxGrid.Item>
                {placeTypeCounterValue.map(placeTypeCounterValue => {
                    return (
                        <FlexboxGrid.Item key={placeTypeCounterValue.id} className="text-center margin-side-10">
                            <PlaceTypeDoughnut placeTypeCounterValue={placeTypeCounterValue} height={placeTypeHeight} />
                        </FlexboxGrid.Item>
                    );
                })}
            </FlexboxGrid>
            {props.levelCounter && (
                <OccupationGraphModal
                    show={historyModalOpen}
                    onHide={() => setHistoryModalOpen(false)}
                    counterId={props.levelCounter.id}
                    level={props.level}
                />
            )}
        </SecuredFragment>
    );
};
