import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    fontNb: number;
};

export const FontLoader = (props: Props) => {
    return (
        <>
            <FormattedMessage id="pixelArt.fontEditor.loading" />
            <FormattedMessage id="pixelArt.fontEditor.fontNb" /> {props.fontNb}
        </>
    );
};
