import { faCheck, faCheckDouble, faHistory, faRuler } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Alert, Button, Col, Drawer, FlexboxGrid, Tooltip, Whisper } from 'rsuite';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import { LPSensor } from '../../../../handlers/ipCanDevices/LPSensor';
import { authHeader } from '../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import { DeviceStateInformation } from '../../Cards/DeviceStateInformation/DeviceStateInformation';
import { MaintenanceStateInformationEditURL } from '../../Cards/DeviceStateInformation/deviceStateInformationGenerator';
import DeleteLPSensorModal from '../../LPSensors/DeleteLPSensorModal';
import LPAutoCalibrationModal from '../../LPSensors/LPAutoCalibrationModal';
import LPSensorHistoryModal from '../../LPSensors/LPSensorHistoryModal';
import { CalibrationCard } from './CalibrationCard';
import LPSensorInformationsCard from './InformationsCard';
import LPFilter from './LPFilter';
import realTimeGraph from './RealTimeGraph';
import RealTimeTable from './RealTimeTable';

type Props = {
    sensor: LPSensor | undefined;
    show: boolean;
    onHide: Function;
    ipCanLabel?: string;
} & WrappedComponentProps;

type State = {
    showDeleteModal: boolean;
    showHistoryModal: boolean;
    realTimeChange: boolean;
    debugArray: DebugValue[];
    graphDebugArray: GraphDebugValue[];
    showRealTimeGraph: boolean;
    showAutoCalibrationModal: boolean;
    compareMode: boolean;
    compareModeLoading: boolean;
    comparedConfig: {};
    maxPoints: number;
};

export type GraphDebugValue = {
    c1_distance1: number;
    c1_power1: number;
    c1_distance2: number;
    c1_power2: number;
    c2_distance1: number;
    c2_power1: number;
    c2_distance2: number;
    c2_power2: number;
    value: number;
    date: Date;
    sDetection: number;
    sRelease: number;
};

export type DebugValue = {
    c1_distance1: number;
    c1_power1: number;
    c1_distance2: number;
    c1_power2: number;
    c2_distance1: number;
    c2_power1: number;
    c2_distance2: number;
    c2_power2: number;
};

class LPSensorDrawer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            showHistoryModal: false,
            realTimeChange: false,
            showRealTimeGraph: false,
            debugArray: [],
            graphDebugArray: [],
            showAutoCalibrationModal: false,
            compareMode: false,
            compareModeLoading: false,
            comparedConfig: {},
            maxPoints: 30,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.sensor?.id !== this.props.sensor?.id) {
            this.setState({
                debugArray: [],
                graphDebugArray: [],
            });
        }
    }

    showDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    hideDeleteModal = shouldReloadDevices => {
        this.setState({
            showDeleteModal: false,
        });

        if (shouldReloadDevices) {
            this.props.onHide(true);
        }
    };

    showHistoryModal = () => {
        this.setState({
            showHistoryModal: true,
        });
    };

    hideHistoryModal = shouldReloadDevices => {
        this.setState({
            showHistoryModal: false,
        });

        if (shouldReloadDevices) {
            this.props.onHide(true);
        }
    };

    showAutoCalibrationModal = () => {
        this.setState({
            showAutoCalibrationModal: true,
        });
    };

    hideAutoCalibrationModal = () => {
        this.setState({
            showAutoCalibrationModal: false,
        });
    };

    setRealTimeChange = toggled => {
        this.setState({
            showRealTimeGraph: toggled,
        });

        if (!toggled) {
            this.disableWebsocket();
        } else {
            this.enableWebsocket();
        }
    };

    enableWebsocket = () => {
        this.setState({
            realTimeChange: true,
        });

        axiosService.getAxios().put(
            '/devices/lp-sensor/debugMode',
            {
                id: this.props.sensor?.id,
                activDebug: true,
            },
            {
                headers: authHeader(),
            }
        );

        webSocketService.onEvent('lp-sensor:debugValues', this.addDebugValue);
    };

    disableWebsocket = () => {
        this.setState({
            realTimeChange: false,
        });

        axiosService.getAxios().put(
            '/devices/lp-sensor/debugMode',
            {
                id: this.props.sensor?.id,
                activDebug: false,
            },
            {
                headers: authHeader(),
            }
        );

        webSocketService.offEvent('lp-sensor:debugValues', this.addDebugValue);
    };

    toggleWebsocket = () => {
        if (this.state.realTimeChange) {
            this.disableWebsocket();
        } else {
            this.enableWebsocket();
        }
    };

    addDebugValue = data => {
        if (data.bus === this.props.sensor?.bus && data.deviceId === this.props.sensor?.deviceId) {
            const debugArray = [...this.state.debugArray].slice(0, this.state.maxPoints - 1);
            let graphDebugArray = [...this.state.graphDebugArray];

            if (this.state.graphDebugArray.length >= this.state.maxPoints) {
                graphDebugArray = [...this.state.graphDebugArray].slice(1, this.state.maxPoints);
            }

            let sDetection = 512;

            if (this.props.sensor!.us1Config.sens < 10) {
                sDetection = 128;
            } else if (this.props.sensor!.us1Config.sens < 80) {
                sDetection = 192;
            } else if (this.props.sensor!.us1Config.sens < 160) {
                sDetection = 256;
            } else if (this.props.sensor!.us1Config.sens < 250) {
                sDetection = 384;
            }

            let sRelease = 358;

            if (this.props.sensor!.us2Config.sens < 10) {
                sRelease = 89;
            } else if (this.props.sensor!.us2Config.sens < 80) {
                sRelease = 134;
            } else if (this.props.sensor!.us2Config.sens < 160) {
                sRelease = 179;
            } else if (this.props.sensor!.us2Config.sens < 250) {
                sRelease = 269;
            }

            debugArray.unshift({
                c1_distance1: data.debugValues.us1.distance1,
                c1_power1: data.debugValues.us1.power1,
                c1_distance2: data.debugValues.us1.distance2,
                c1_power2: data.debugValues.us1.power2,
                c2_distance1: data.debugValues.us2.distance1,
                c2_power1: data.debugValues.us2.power1,
                c2_distance2: data.debugValues.us2.distance2,
                c2_power2: data.debugValues.us2.power2,
            });

            graphDebugArray.push({
                c1_distance1: data.debugValues.us1.distance1,
                c1_power1: data.debugValues.us1.power1,
                c1_distance2: data.debugValues.us1.distance2,
                c1_power2: data.debugValues.us1.power2,
                c2_distance1: data.debugValues.us2.distance1,
                c2_power1: data.debugValues.us2.power1,
                c2_distance2: data.debugValues.us2.distance2,
                c2_power2: data.debugValues.us2.power2,
                value: data.debugValues.valueFiltered,
                date: new Date(),
                sDetection: sDetection,
                sRelease: sRelease,
            });

            this.setState({
                debugArray,
                graphDebugArray,
            });
        }
    };

    toggleCompareMode = () => {
        if (this.state.compareMode) {
            this.setState({
                compareMode: false,
            });
        } else {
            this.setState({
                compareModeLoading: true,
            });
            axiosService
                .getAxios()
                .get(`/devices/lp-sensor/config/${this.props.sensor?.id}`, { headers: authHeader() })
                .then(lpSensorConfigResponse => {
                    this.setState({
                        compareMode: true,
                        compareModeLoading: false,
                        comparedConfig: lpSensorConfigResponse.data,
                    });
                })
                .catch(err => {
                    Alert.error(
                        this.props.intl.formatMessage(
                            {
                                id: 'ipCanDevices.LPSensor.fetchConfigError',
                            },
                            {
                                error: err,
                            }
                        )
                    );

                    this.setState({
                        compareModeLoading: false,
                    });
                });
        }
    };

    hideDrawer = () => {
        this.disableWebsocket();
        this.setRealTimeChange(false);
        this.props.onHide();
    };

    resetArray = () => {
        this.setState({
            debugArray: [],
            graphDebugArray: [],
        });
    };

    setMaxPoints = points => {
        this.setState({
            maxPoints: parseInt(points),
        });
    };

    render = () => (
        <Fragment>
            {this.props.sensor && (
                <Fragment>
                    <LPSensorHistoryModal
                        show={this.state.showHistoryModal}
                        onHide={this.hideHistoryModal}
                        sensor={this.props.sensor}
                    />

                    <DeleteLPSensorModal
                        show={this.state.showDeleteModal}
                        onHide={this.hideDeleteModal}
                        sensors={[this.props.sensor]}
                    />

                    <LPAutoCalibrationModal
                        show={this.state.showAutoCalibrationModal}
                        onHide={this.hideAutoCalibrationModal}
                        sensor={this.props.sensor}
                    />
                </Fragment>
            )}
            <Drawer
                backdrop={true}
                show={this.props.show}
                onHide={() => this.hideDrawer()}
                full={this.state.showRealTimeGraph}>
                <Drawer.Header>
                    <Drawer.Title>
                        {this.props.sensor && (
                            <span>
                                <FormattedMessage
                                    id="ipCanDevices.LPSensor.title"
                                    values={{
                                        bus: this.props.sensor.bus + 1,
                                        address: this.props.sensor.deviceId,
                                    }}
                                />
                                {this.state.showRealTimeGraph && (
                                    <span>{` - ${this.props.ipCanLabel} / ${this.props.sensor.getBus() + 1} / ${this.props.sensor.getDeviceId()}`}</span>
                                )}
                                {this.state.showRealTimeGraph && (
                                    <span style={{ fontWeight: 'normal' }}>{` - (${this.props.sensor.informations.uniqueId.join('.')})`}</span>
                                )}
                            </span>
                        )}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className="drawer-body-without-avatar">
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} xs={this.state.showRealTimeGraph ? 6 : 24}>
                            {this.props.sensor && (
                                <div data-cy="ipCanDevices-lpSensor-card-informations">
                                    <LPSensorInformationsCard
                                        sensor={this.props.sensor}
                                        showRealTimeGraph={this.state.showRealTimeGraph}
                                    />

                                    {!this.state.showRealTimeGraph && this.props.sensor && this.state.comparedConfig && (
                                        <div data-cy="ipCanDevices-lpSensor-card-calibration">
                                            <CalibrationCard
                                                sensor={this.props.sensor}
                                                realTimeChange={this.setRealTimeChange}
                                                isRealTime={this.state.showRealTimeGraph}
                                                comparedData={this.state.comparedConfig}
                                                compareMode={this.state.compareMode}
                                            />
                                        </div>
                                    )}

                                    <div style={{ marginBottom: 12 }}>
                                        {this.state.showRealTimeGraph && RealTimeTable(this.state.debugArray)}
                                    </div>

                                    <SecuredFragment
                                        authorizedRoles={[rolesConstants.LPSensor.UPDATE_MAINTENANCE_STATE]}>
                                        <DeviceStateInformation
                                            device={this.props.sensor}
                                            editMaintenanceStateUrl={MaintenanceStateInformationEditURL.LP_SENSOR}
                                            isMinimized={this.state.showRealTimeGraph}
                                        />
                                    </SecuredFragment>
                                </div>
                            )}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={this.state.showRealTimeGraph ? 18 : 24}>
                            {this.props.sensor && (
                                <FlexboxGrid>
                                    {this.state.showRealTimeGraph && (
                                        <FlexboxGridItem componentClass={Col} xs={6}>
                                            <div data-cy="ipCanDevices-lpSensor-card-calibration">
                                                <CalibrationCard
                                                    sensor={this.props.sensor}
                                                    realTimeChange={this.setRealTimeChange}
                                                    isRealTime={this.state.showRealTimeGraph}
                                                    comparedData={this.state.comparedConfig}
                                                    compareMode={this.state.compareMode}
                                                />
                                            </div>
                                        </FlexboxGridItem>
                                    )}
                                    <FlexboxGridItem componentClass={Col} xs={this.state.showRealTimeGraph ? 18 : 24}>
                                        <div data-cy="ipCanDevices-lpSensor-card-filter">
                                            <LPFilter
                                                sensor={this.props.sensor}
                                                realTimeChange={this.setRealTimeChange}
                                                isRealTime={this.state.showRealTimeGraph}
                                                comparedData={this.state.comparedConfig}
                                                compareMode={this.state.compareMode}
                                            />
                                        </div>
                                    </FlexboxGridItem>
                                </FlexboxGrid>
                            )}
                            <div data-cy="ipCanDevices-lpSensor-card-realTimeGraph">
                                {this.state.showRealTimeGraph &&
                                    realTimeGraph(
                                        this.state.realTimeChange,
                                        this.state.graphDebugArray,
                                        this.toggleWebsocket,
                                        this.resetArray,
                                        this.setMaxPoints
                                    )}
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    {/* {this.props.sensor && <LPCalibrationCard sensor={this.props.sensor} />} */}
                </Drawer.Body>
                {this.props.sensor && (
                    <Drawer.Footer>
                        {this.state.compareMode ? (
                            <FlexboxGrid align="middle" justify="end">
                                <FlexboxGridItem componentClass={Col} xs={24}>
                                    <Button
                                        data-cy="ipCanDevices-lpSensor-confirm"
                                        block
                                        onClick={() => {
                                            this.toggleCompareMode();
                                        }}
                                        color={'green'}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </FlexboxGridItem>
                            </FlexboxGrid>
                        ) : (
                            <FlexboxGrid align="middle" justify="end">
                                <FlexboxGrid.Item componentClass={Col} xs={3}>
                                    <Whisper
                                        placement="top"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="device.autoCalibration" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            block
                                            data-cy="ipCanDevices-lpSensor-autoCalibration"
                                            onClick={() => {
                                                this.showAutoCalibrationModal();
                                            }}
                                            color="orange"
                                            disabled={!this.props.sensor.online}>
                                            <FontAwesomeIcon icon={faRuler} />
                                        </Button>
                                    </Whisper>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={3}>
                                    <Whisper
                                        placement="top"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="device.compareMode" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            block
                                            data-cy="ipCanDevices-lpSensor-compareMode"
                                            onClick={() => {
                                                this.toggleCompareMode();
                                            }}
                                            color={'orange'}
                                            loading={this.state.compareModeLoading}
                                            disabled={!this.props.sensor.online}>
                                            <FontAwesomeIcon icon={faCheckDouble} />
                                        </Button>
                                    </Whisper>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={3}>
                                    <Whisper
                                        placement="top"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="device.showHistory" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            block
                                            data-cy="ipCanDevices-lpSensor-showHistory"
                                            onClick={() => {
                                                this.showHistoryModal();
                                            }}
                                            color="blue">
                                            <FontAwesomeIcon icon={faHistory} />
                                        </Button>
                                    </Whisper>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    <Whisper
                                        placement="top"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage id="device.delete" />
                                            </Tooltip>
                                        }>
                                        <Button
                                            block
                                            data-cy="ipCanDevices-lpSensor-delete"
                                            onClick={() => {
                                                this.showDeleteModal();
                                            }}
                                            color="red"
                                            disabled={this.props.sensor.online}>
                                            <FormattedMessage id="ipCanDevices.LPSensor.delete" />
                                        </Button>
                                    </Whisper>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        )}
                    </Drawer.Footer>
                )}
            </Drawer>
        </Fragment>
    );
}

export default injectIntl(LPSensorDrawer);
