import { faCheck, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, Col, FlexboxGrid, Form, FormControl, List, Panel, SelectPicker, Toggle } from 'rsuite';
import TCMSensor from '../../../../handlers/ipCanDevices/TCMSensor';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';

const calibrationValues = [
    { label: '3 cm', value: 1 },
    { label: '7 cm', value: 2 },
    { label: '10 cm', value: 3 },
    { label: '13 cm', value: 4 },
    { label: '16 cm', value: 5 },
    { label: '20 cm', value: 6 },
    { label: '23 cm', value: 7 },
    { label: '26 cm', value: 8 },
    { label: '29 cm', value: 9 },
    { label: '33 cm', value: 10 },
    { label: '36 cm', value: 11 },
    { label: '39 cm', value: 12 },
    { label: '42 cm', value: 13 },
    { label: '46 cm', value: 14 },
    { label: '49 cm', value: 15 },
    { label: '52 cm', value: 16 },
    { label: '55 cm', value: 17 },
    { label: '59 cm', value: 18 },
    { label: '62 cm', value: 19 },
    { label: '65 cm', value: 20 },
    { label: '69 cm', value: 21 },
    { label: '72 cm', value: 22 },
    { label: '75 cm', value: 23 },
    { label: '78 cm', value: 24 },
    { label: '82 cm', value: 25 },
    { label: '85 cm', value: 26 },
    { label: '88 cm', value: 27 },
    { label: '91 cm', value: 28 },
    { label: '95 cm', value: 29 },
    { label: '98 cm', value: 30 },
    { label: '101 cm', value: 31 },
    { label: '104 cm', value: 32 },
    { label: '108 cm', value: 33 },
    { label: '111 cm', value: 34 },
    { label: '114 cm', value: 35 },
    { label: '118 cm', value: 36 },
    { label: '121 cm', value: 37 },
    { label: '124 cm', value: 38 },
    { label: '127 cm', value: 39 },
    { label: '131 cm', value: 40 },
    { label: '134 cm', value: 41 },
    { label: '137 cm', value: 42 },
    { label: '140 cm', value: 43 },
    { label: '144 cm', value: 44 },
    { label: '147 cm', value: 45 },
    { label: '150 cm', value: 46 },
    { label: '153 cm', value: 47 },
    { label: '157 cm', value: 48 },
    { label: '160 cm', value: 49 },
    { label: '163 cm', value: 50 },
    { label: '166 cm', value: 51 },
    { label: '170 cm', value: 52 },
    { label: '173 cm', value: 53 },
    { label: '176 cm', value: 54 },
    { label: '180 cm', value: 55 },
    { label: '183 cm', value: 56 },
    { label: '186 cm', value: 57 },
    { label: '189 cm', value: 58 },
    { label: '193 cm', value: 59 },
    { label: '196 cm', value: 60 },
    { label: '199 cm', value: 61 },
    { label: '202 cm', value: 62 },
    { label: '206 cm', value: 63 },
    { label: '209 cm', value: 64 },
    { label: '212 cm', value: 65 },
    { label: '215 cm', value: 66 },
    { label: '219 cm', value: 67 },
    { label: '222 cm', value: 68 },
    { label: '225 cm', value: 69 },
    { label: '228 cm', value: 70 },
    { label: '232 cm', value: 71 },
    { label: '235 cm', value: 72 },
    { label: '238 cm', value: 73 },
    { label: '242 cm', value: 74 },
    { label: '245 cm', value: 75 },
    { label: '248 cm', value: 76 },
    { label: '251 cm', value: 77 },
    { label: '255 cm', value: 78 },
    { label: '258 cm', value: 79 },
    { label: '261 cm', value: 80 },
    { label: '264 cm', value: 81 },
    { label: '268 cm', value: 82 },
    { label: '271 cm', value: 83 },
    { label: '274 cm', value: 84 },
    { label: '277 cm', value: 85 },
    { label: '281 cm', value: 86 },
    { label: '284 cm', value: 87 },
    { label: '287 cm', value: 88 },
    { label: '290 cm', value: 89 },
    { label: '294 cm', value: 90 },
    { label: '297 cm', value: 91 },
    { label: '300 cm', value: 92 },
    { label: '304 cm', value: 93 },
    { label: '307 cm', value: 94 },
    { label: '310 cm', value: 95 },
    { label: '313 cm', value: 96 },
    { label: '317 cm', value: 97 },
    { label: '320 cm', value: 98 },
    { label: '323 cm', value: 99 },
    { label: '326 cm', value: 100 },
];

type Props = {
    sensor: TCMSensor;
    isCheckingDatabase: boolean;
    databaseData: Record<string, any> | undefined;
} & WrappedComponentProps;

type State = {
    editMode: boolean;
    formValue: Record<string, any>;
    isUpdating: boolean;
};
class CalibrationCard extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        const calibration = this.props.sensor.calibration.fetchCalibration;

        this.state = {
            editMode: false,
            formValue: {
                state: calibration.state,
                value: calibration.value,
            },
            isUpdating: false,
        };

        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.cancel = this.cancel.bind(this);
        this.valid = this.valid.bind(this);
    }

    cancel() {
        const calibration = this.props.sensor.calibration.fetchCalibration;
        this.setState({
            formValue: {
                state: calibration.state,
                value: calibration.value,
            },
            editMode: false,
        });
    }

    valid() {
        const { state, value } = this.state.formValue;

        this.setState({
            isUpdating: true,
        });

        this.props.sensor.calibration
            .setCalibration(this.props.sensor.id, state, value)
            .then(() => {
                this.setState({
                    editMode: false,
                });
                Alert.success(this.props.intl.formatMessage({ id: 'tcmSensor.calibration.update.success' }));
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage({ id: 'tcmSensor.calibration.update.error' }, { error: err })
                );
            })
            .finally(() => {
                this.setState({
                    isUpdating: false,
                });
            });
    }

    toggleEditMode() {
        this.setState({
            editMode: !this.state.editMode,
        });
    }

    render() {
        let stateClass;
        let valueClass;

        if (this.props.isCheckingDatabase) {
            stateClass =
                (this.props.databaseData?.calibration.state === 1) ===
                this.props.sensor.calibration.fetchCalibration.state
                    ? 'list-green'
                    : 'list-red';
            valueClass =
                this.props.databaseData?.calibration.value === this.props.sensor.calibration.fetchCalibration.value
                    ? 'list-green'
                    : 'list-red';
        }

        return (
            <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.UPDATE_CALIBRATION_CONFIG]}>
                <Panel
                    className={'panel-small'}
                    shaded
                    data-cy="ipcan-elements-sensor-drawer-calibration"
                    bordered
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'ipCanDevices.sensor.calibration' })}
                            buttons={[
                                <Button
                                    data-cy="sensor-calibration-edit"
                                    key="calibration-edit"
                                    color="blue"
                                    size="sm"
                                    onClick={this.toggleEditMode}
                                    disabled={this.state.editMode || this.props.isCheckingDatabase}>
                                    <FontAwesomeIcon icon={faEdit} className="margin-right-10" />
                                    <FormattedMessage id="ipCanDevices.sensor.calibration.edit" />
                                </Button>,
                            ]}
                        />
                    }
                    bodyFill>
                    <Form onChange={formValue => this.setState({ formValue })} formValue={this.state.formValue}>
                        <List hover data-cy="ipcan-elements-sensor-drawer-calibration">
                            <List.Item className={`panel-list ${stateClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item
                                        data-cy="sensor-calibration-state"
                                        style={{ fontWeight: 'bold' }}
                                        componentClass={Col}
                                        xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.calibration.state" />
                                    </FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                data-cy="ipCanDevices-sensor-calibration-isChecked"
                                                accepter={Toggle}
                                                name="state"
                                                defaultChecked={this.state.formValue.state}
                                                disabled={this.state.isUpdating}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <Fragment>
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                {/* Database checking */}
                                                {this.props.isCheckingDatabase && (
                                                    <Fragment>
                                                        {this.props.databaseData?.calibration.state ? (
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                style={{ color: 'green' }}
                                                                size="lg"
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faTimesCircle}
                                                                style={{ color: 'red' }}
                                                                size="lg"
                                                            />
                                                        )}
                                                    </Fragment>
                                                )}
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item data-cy="sensor-calibration-state-value">
                                                <Fragment>
                                                    {this.state.formValue.state ? (
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            style={{ color: 'green' }}
                                                            size="lg"
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faTimesCircle}
                                                            style={{ color: 'red' }}
                                                            size="lg"
                                                        />
                                                    )}
                                                </Fragment>
                                            </FlexboxGrid.Item>
                                        </Fragment>
                                    )}
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${valueClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item
                                        data-cy="sensor-calibration-value"
                                        style={{ fontWeight: 'bold' }}
                                        componentClass={Col}
                                        xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.calibration.value" />
                                    </FlexboxGrid.Item>
                                    {this.state.editMode ? (
                                        <FlexboxGrid.Item>
                                            <FormControl
                                                data-cy="ipCanDevices-sensor-calibration-value"
                                                accepter={SelectPicker}
                                                name="value"
                                                searchable={false}
                                                placement="auto"
                                                defaultValue={this.state.formValue.value}
                                                data={calibrationValues}
                                                style={{ width: 224 }}
                                                disabled={this.state.isUpdating}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`calibration-value-${item.value}`}>{label}</div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <Fragment>
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                {this.props.isCheckingDatabase && (
                                                    <Fragment>
                                                        {(this.props.databaseData?.calibration.value * 3.264).toFixed(
                                                            0
                                                        )}
                                                        cm
                                                    </Fragment>
                                                )}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="sensor-calibration-trueValue">
                                                {(this.state.formValue.value * 3.264).toFixed(0)} cm
                                            </FlexboxGrid.Item>
                                        </Fragment>
                                    )}
                                </FlexboxGrid>
                            </List.Item>

                            {this.state.editMode && (
                                <List.Item className={'panel-list'}>
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                            <Fragment>
                                                <Button
                                                    data-cy="ipCanDevices-display-edit-valid"
                                                    color="green"
                                                    onClick={this.valid}
                                                    loading={this.state.isUpdating}>
                                                    <FormattedMessage id="ipCanDevices.sensor.calibration.edit.valid" />
                                                </Button>
                                                <Button
                                                    data-cy="ipCanDevices-display-edit-cancel"
                                                    appearance="link"
                                                    onClick={this.cancel}
                                                    disabled={this.state.isUpdating}>
                                                    <FormattedMessage id="ipCanDevices.sensor.calibration.edit.cancel" />
                                                </Button>
                                            </Fragment>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            )}
                        </List>
                    </Form>
                </Panel>
            </SecuredFragment>
        );
    }
}

export default injectIntl(CalibrationCard);
