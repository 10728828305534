import {
    faCogs,
    faDatabase,
    faEraser,
    faFileWord,
    faLightbulb,
    faPlay,
    faPowerOff,
    faStop,
    faSync,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, Col, FlexboxGrid, Message, Modal } from 'rsuite';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { SendCommandConfirmationModal } from './SendCommandConfirmationModal';

type Props = {
    lpDisplay: LPDisplay;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isSendingCommand: boolean;
    currentSendedCommand: string;
    counter: number;
    confirmationModalInformation: {
        isOpen: boolean;
        command?: LP_MATRIXDISPLAYV2_DEVICE_CMD;
    };
};

export enum LP_MATRIXDISPLAYV2_DEVICE_CMD {
    SEND_ALL_DEVICES_CONFIG = 'sendAllDevicesConfig',
    SEND_ALL_DB_CONFIG = 'sendAllDbConfig',
    SEND_ALL_TEXT_CONFIG = 'sendAllTextConfig',
    RESET_MEMORY = 'resetMemory',
    REBOOT = 'reboot',
    FORCE_MATRIX_OFF = 'forceMatrixOff',
    FORCE_MATRIX_ON = 'forceMatrixOn',
}

class LPDisplayAdvancedCommandsModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isSendingCommand: false,
            currentSendedCommand: '',
            counter: 0,
            confirmationModalInformation: {
                isOpen: false,
            },
        };
    }

    openConfirmationModal = (command: LP_MATRIXDISPLAYV2_DEVICE_CMD) => {
        this.setState({
            confirmationModalInformation: {
                isOpen: true,
                command,
            },
        });
    };

    closeConfirmationModal = (hasExecutedCommand: boolean) => {
        this.setState({
            confirmationModalInformation: {
                isOpen: false,
            },
        });

        if (hasExecutedCommand) {
            this.startCooldown();
        }
    };

    sendCommand = (command: LP_MATRIXDISPLAYV2_DEVICE_CMD) => {
        this.setState({
            isSendingCommand: true,
            currentSendedCommand: command,
        });

        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/deviceCmd',
                {
                    id: this.props.lpDisplay.getId(),
                    cmd: command,
                },
                { headers: authHeader() }
            )
            .then(() => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: `ipCanDevices.lpDisplays.advancedCommands.${command}.success`,
                    })
                );

                switch (command) {
                    case LP_MATRIXDISPLAYV2_DEVICE_CMD.FORCE_MATRIX_OFF:
                    case LP_MATRIXDISPLAYV2_DEVICE_CMD.FORCE_MATRIX_ON:
                        break;
                    //---
                    default:
                        this.startCooldown();
                        break;
                }
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage(
                        {
                            id: `ipCanDevices.lpDisplays.advancedCommands.${command}.success`,
                        },
                        {
                            err,
                        }
                    )
                );
            })
            .finally(() => {
                this.setState({
                    isSendingCommand: false,
                    currentSendedCommand: '',
                });
            });
    };

    startCooldown = () => {
        this.setState({
            counter: 10,
        });

        const cooldownInterval = setInterval(() => {
            const currentCooldown = this.state.counter;

            if (currentCooldown <= 0) {
                clearInterval(cooldownInterval);
            } else {
                this.setState({
                    counter: currentCooldown - 1,
                });
            }
        }, 1000);
    };

    render = () => (
        <>
            <SendCommandConfirmationModal
                command={this.state.confirmationModalInformation.command}
                isOpen={this.state.confirmationModalInformation.isOpen}
                onClose={this.closeConfirmationModal}
                displayId={this.props.lpDisplay.getId()}
            />

            <Modal show={this.props.show} onHide={() => this.props.onHide()} size="md">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands.title" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.state.counter !== 0 && (
                        <Message
                            className="margin-bottom-5"
                            description={`${this.props.intl.formatMessage({
                                id: 'ipCanDevices.lpDisplays.advancedCommands.cooldownDescription',
                            })} ${this.state.counter}s`}
                        />
                    )}
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <Button
                                data-cy="lpDisplay-command-forceOff"
                                block
                                color="blue"
                                onClick={() => this.sendCommand(LP_MATRIXDISPLAYV2_DEVICE_CMD.FORCE_MATRIX_OFF)}
                                loading={
                                    this.state.currentSendedCommand === LP_MATRIXDISPLAYV2_DEVICE_CMD.FORCE_MATRIX_OFF
                                }
                                disabled={
                                    (this.state.isSendingCommand &&
                                        this.state.currentSendedCommand !==
                                            LP_MATRIXDISPLAYV2_DEVICE_CMD.FORCE_MATRIX_OFF) ||
                                    this.state.counter > 0
                                }>
                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-5" />{' '}
                                <FontAwesomeIcon icon={faStop} className="margin-right-5" />{' '}
                                <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands.command.forceMatrixOff" />
                            </Button>
                            <Button
                                data-cy="lpDisplay-command-disableForce"
                                block
                                color="blue"
                                onClick={() => this.sendCommand(LP_MATRIXDISPLAYV2_DEVICE_CMD.FORCE_MATRIX_ON)}
                                loading={
                                    this.state.currentSendedCommand === LP_MATRIXDISPLAYV2_DEVICE_CMD.FORCE_MATRIX_ON
                                }
                                disabled={
                                    (this.state.isSendingCommand &&
                                        this.state.currentSendedCommand !==
                                            LP_MATRIXDISPLAYV2_DEVICE_CMD.FORCE_MATRIX_ON) ||
                                    this.state.counter > 0
                                }>
                                <FontAwesomeIcon icon={faLightbulb} className="margin-right-5" />{' '}
                                <FontAwesomeIcon icon={faPlay} className="margin-right-5" />{' '}
                                <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands.command.forceMatrixOn" />
                            </Button>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <Button
                                data-cy="lpDisplay-command-sendAllDbConfig"
                                block
                                color="orange"
                                onClick={() =>
                                    this.openConfirmationModal(LP_MATRIXDISPLAYV2_DEVICE_CMD.SEND_ALL_DB_CONFIG)
                                }
                                loading={
                                    this.state.currentSendedCommand === LP_MATRIXDISPLAYV2_DEVICE_CMD.SEND_ALL_DB_CONFIG
                                }
                                disabled={
                                    (this.state.isSendingCommand &&
                                        this.state.currentSendedCommand !==
                                            LP_MATRIXDISPLAYV2_DEVICE_CMD.SEND_ALL_DB_CONFIG) ||
                                    this.state.counter > 0
                                }>
                                <FontAwesomeIcon icon={faCogs} className="margin-right-5" />{' '}
                                <FontAwesomeIcon icon={faDatabase} className="margin-right-5" />{' '}
                                <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands.command.sendAllDbConfig" />
                            </Button>
                            <Button
                                data-cy="lpDisplay-command-sendAllTextConfig"
                                block
                                color="orange"
                                onClick={() =>
                                    this.openConfirmationModal(LP_MATRIXDISPLAYV2_DEVICE_CMD.SEND_ALL_TEXT_CONFIG)
                                }
                                loading={
                                    this.state.currentSendedCommand ===
                                    LP_MATRIXDISPLAYV2_DEVICE_CMD.SEND_ALL_TEXT_CONFIG
                                }
                                disabled={
                                    (this.state.isSendingCommand &&
                                        this.state.currentSendedCommand !==
                                            LP_MATRIXDISPLAYV2_DEVICE_CMD.SEND_ALL_TEXT_CONFIG) ||
                                    this.state.counter > 0
                                }>
                                <FontAwesomeIcon icon={faCogs} className="margin-right-5" />{' '}
                                <FontAwesomeIcon icon={faFileWord} className="margin-right-5" />{' '}
                                <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands.command.sendAllTextConfig" />
                            </Button>
                            <Button
                                data-cy="lpDisplay-command-sendAllDevicesConfig"
                                block
                                color="orange"
                                onClick={() =>
                                    this.openConfirmationModal(LP_MATRIXDISPLAYV2_DEVICE_CMD.SEND_ALL_DEVICES_CONFIG)
                                }
                                loading={
                                    this.state.currentSendedCommand ===
                                    LP_MATRIXDISPLAYV2_DEVICE_CMD.SEND_ALL_DEVICES_CONFIG
                                }
                                disabled={
                                    (this.state.isSendingCommand &&
                                        this.state.currentSendedCommand !==
                                            LP_MATRIXDISPLAYV2_DEVICE_CMD.SEND_ALL_DEVICES_CONFIG) ||
                                    this.state.counter > 0
                                }>
                                <FontAwesomeIcon icon={faCogs} className="margin-right-5" />{' '}
                                <FontAwesomeIcon icon={faSync} className="margin-right-5" />{' '}
                                <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands.command.sendAllDevicesConfig" />
                            </Button>
                            <Button
                                data-cy="lpDisplay-command-resetMemory"
                                block
                                color="red"
                                onClick={() => this.openConfirmationModal(LP_MATRIXDISPLAYV2_DEVICE_CMD.RESET_MEMORY)}
                                loading={this.state.currentSendedCommand === LP_MATRIXDISPLAYV2_DEVICE_CMD.RESET_MEMORY}
                                disabled={
                                    (this.state.isSendingCommand &&
                                        this.state.currentSendedCommand !==
                                            LP_MATRIXDISPLAYV2_DEVICE_CMD.RESET_MEMORY) ||
                                    this.state.counter > 0
                                }>
                                <FontAwesomeIcon icon={faEraser} className="margin-right-5" />{' '}
                                <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands.command.resetMemory" />
                            </Button>
                            <Button
                                data-cy="lpDisplay-command-reboot"
                                block
                                color="red"
                                onClick={() => this.openConfirmationModal(LP_MATRIXDISPLAYV2_DEVICE_CMD.REBOOT)}
                                loading={this.state.currentSendedCommand === LP_MATRIXDISPLAYV2_DEVICE_CMD.REBOOT}
                                disabled={
                                    (this.state.isSendingCommand &&
                                        this.state.currentSendedCommand !== LP_MATRIXDISPLAYV2_DEVICE_CMD.REBOOT) ||
                                    this.state.counter > 0
                                }>
                                <FontAwesomeIcon icon={faPowerOff} className="margin-right-5" />{' '}
                                <FormattedMessage id="ipCanDevices.lpDisplays.advancedCommands.command.reboot" />
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default injectIntl(LPDisplayAdvancedCommandsModal);
