import React from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-advanced';
import { FlexboxGrid, Panel, Progress } from 'rsuite';
const { Line } = Progress;

type Props = {
    listIpCan: Array<Record<string, any>>;
};

type State = {
    loading: boolean;
};
export default class IpCanDeviceOnline extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    render() {
        const state: { total: number; tabIpCanDevice: Array<Record<string, any>> } = {
            total: 0,
            tabIpCanDevice: [],
        };

        const colorTab = ['#ffc107', '#fd7e14', '#17a2b8', '#20c997', '#007bff', '#6610f2', '#6c757d'];

        //---
        for (let l in this.props.listIpCan) {
            let ipcan = this.props.listIpCan[l];

            let devicesOnline = 0;

            if (ipcan.online) {
                devicesOnline =
                    ipcan.nbDisplays.online + ipcan.nbSensors.online > 0
                        ? ipcan.nbDisplays.online + ipcan.nbSensors.online
                        : 0;
            }

            state.tabIpCanDevice.push({
                name: ipcan.label,
                devicesOnDB: ipcan.nbDisplays.onDB + ipcan.nbSensors.onDB,
                devicesOnModule:
                    ipcan.nbDisplays.onModule + ipcan.nbSensors.onModule > 0
                        ? ipcan.nbDisplays.onModule + ipcan.nbSensors.onModule
                        : 0,
                devicesOnline,
            });
            //---
            state.total +=
                ipcan.nbDisplays.onModule + ipcan.nbSensors.onModule > 0
                    ? ipcan.nbDisplays.onModule + ipcan.nbSensors.onModule
                    : 0;
        }
        //---
        for (const i in state.tabIpCanDevice) {
            const resultTemp = state.tabIpCanDevice[i];
            if (resultTemp.devicesOnModule > 0)
                resultTemp.percent = ((resultTemp.devicesOnline / resultTemp.devicesOnModule) * 100).toFixed(1);
            else resultTemp.percent = 100;
        }

        //---

        return (
            <Loader show={this.state.loading}>
                <Panel
                    data-cy="controller-peripheral"
                    className={'dashboard-card-header hoverable'}
                    header={<FormattedMessage id="controllers.cardDeviceOnline.title" />}
                    shaded>
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item colspan={24} xs={24} className=" margin-top-15">
                            <ul>
                                {state.tabIpCanDevice.map((element, index) => {
                                    return (
                                        <li key={index} className="list-ipCan-devices">
                                            <FlexboxGrid justify="center">
                                                <FlexboxGrid.Item colspan={4} className="ipcan-legend-title ">
                                                    {element.name}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item
                                                    data-cy="ipCan-cardDeviceOnline-value"
                                                    colspan={4}
                                                    className="ipcan-legend-value"
                                                    style={{ color: colorTab[index] }}>
                                                    {element.devicesOnline} / {element.devicesOnModule}
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={16}>
                                                    <Line
                                                        percent={parseInt(element.percent)}
                                                        status={
                                                            parseInt(element.percent) === 100 ? 'success' : 'active'
                                                        }
                                                    />
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </li>
                                    );
                                })}
                            </ul>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Panel>
            </Loader>
        );
    }
}
