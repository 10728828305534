import handleError from '../../handlers/error.handler';
import { ipCanCreateHandler, ipCanHandler, ipCanUpdateHandler } from '../../handlers/ipcan';
import { authHeader } from '../helpers';
import { axiosService } from './axios.services';

export const ipCanService = {
    getAll,
    get,
    create,
    update,
    updateBusConfiguration,
    remove,
};

function getAll() {
    return axiosService
        .getAxios()
        .get('/ipcanmodules', { headers: authHeader() })
        .then(response => response.data.map(ipCan => ipCanHandler(ipCan)))
        .then(ipCan => {
            return ipCan;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

/**
 *
 * @param {Number} id
 */
function get(id) {
    return axiosService
        .getAxios()
        .get(`/ipcanmodules/${id}`, { headers: authHeader() })
        .then(response => {
            return ipCanHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

/**
 *
 * @param {String} label
 * @param {String} macStr
 * @param {String} ip
 */
function create(label, macStr, ip) {
    const parameters = { headers: authHeader() };

    const data = { label, macStr, ip };

    return axiosService
        .getAxios()
        .post('/ipcanmodules', data, parameters)
        .then(response => {
            return ipCanCreateHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

/**
 *
 * @param {Number} id
 * @param {String} label
 * @param {String} macStr
 * @param {String} ip
 */
function update(id, label, macStr, ip) {
    const parameters = { headers: authHeader() };

    const data = { id, label, macStr, ip };

    return axiosService
        .getAxios()
        .put('/ipcanmodules', data, parameters)
        .then(response => {
            return ipCanUpdateHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

/**
 *
 * @param {Number} id
 * @param {Object[]} configuration
 */
function updateBusConfiguration(id, configuration) {
    const parameters = { headers: authHeader() };
    const data = { id: id, config: configuration };

    return axiosService
        .getAxios()
        .put('/ipcanmodules/updateBusConfiguration', data, parameters)
        .then(response => {
            return ipCanUpdateHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

/**
 *
 * @param {Number} id
 */
function remove(id) {
    const data = {
        headers: authHeader(),
        data: { id: id },
    };
    return axiosService
        .getAxios()
        .delete('/ipcanmodules', data)
        .then(response => {
            return ipCanHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}
