import defineOneSensorId from './Desc/defineOneSensorId';
import defineSensorId from './Desc/defineSensorId';
import foundVoltageBus from './Desc/foundVoltageBus';
import lpCmdBus from './Desc/lpCmdBus';
import missingVoltageBus from './Desc/missingVoltageBus';
import offline from './Desc/offline';
import online from './Desc/online';
import reboot from './Desc/reboot';
import resetBusCounterError from './Desc/resetBusCounterError';
import resetMemory from './Desc/resetMemory';
import setGPIO from './Desc/setGPIO';
import startEnumeration from './Desc/startEnumeration';
import stopEnumeration from './Desc/stopEnumeration';
import tcmCmdBus from './Desc/tcmCmdBus';
import testBus from './Desc/testBus';
import testLed from './Desc/testLed';
import updateBusConfig from './Desc/updateBusConfig';
import updateConfig from './Desc/updateConfig';

// wait for online, total and db as data
// wait for controler online or not

export default function IpCanDescCell(props) {
    switch (props) {
        case 'online':
            return online();
        case 'offline':
            return offline();
        case 'start enumeration':
            return startEnumeration();
        case 'stop enumeration':
            return stopEnumeration();
        case 'set gpio':
            return setGPIO();
        case 'tcm cmd bus':
            return tcmCmdBus();
        case 'test led':
            return testLed();
        case 'test bus':
            return testBus();
        case 'reboot':
            return reboot();
        case 'define sensor id':
            return defineSensorId();
        case 'found voltage bus':
            return foundVoltageBus();
        case 'missing voltage bus':
            return missingVoltageBus();
        case 'update bus config':
            return updateBusConfig();
        case 'reset memory':
            return resetMemory();
        case 'lp cmd bus':
            return lpCmdBus();
        case 'update config':
            return updateConfig();
        case 'reset bus counter error':
            return resetBusCounterError();
        case 'define one sensor id':
            return defineOneSensorId();
        default:
            return props;
    }
}
