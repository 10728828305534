import { faDotCircle, faEye, faEyeSlash, faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import Control from 'react-leaflet-control';
import { Button, ButtonGroup, SelectPicker } from 'rsuite';
import { PlaceTextInformationType } from '.';

type Props = {
    currentMode: PlaceTextInformationType;
    onChangeMode: (mode: PlaceTextInformationType) => void;
    shouldDisplayPlaceState: boolean;
    onChangeShouldDisplayPlaceState: () => void;
    isLoading: boolean;
    shouldDisplayPlacesBoxes: boolean;
    toggleShouldDisplayPlacesBoxes: () => void;
    shouldDisplayPlacesPoints: boolean;
    toggleShouldDisplayPlacesPoints: () => void;
    editMode: boolean;
};

export const BottomRightControl = (props: Props) => {
    const intl = useIntl();

    return (
        <Control position="bottomright">
            <ButtonGroup className="margin-right-5">
                <Button
                    color="violet"
                    onClick={props.toggleShouldDisplayPlacesBoxes}
                    appearance={props.shouldDisplayPlacesBoxes ? 'primary' : 'ghost'}
                    disabled={props.editMode}>
                    <FontAwesomeIcon icon={faVectorSquare} />
                </Button>
                <Button
                    color="green"
                    onClick={props.toggleShouldDisplayPlacesPoints}
                    appearance={props.shouldDisplayPlacesPoints ? 'primary' : 'ghost'}
                    disabled={!props.shouldDisplayPlacesBoxes}>
                    <FontAwesomeIcon icon={faDotCircle} />
                </Button>
            </ButtonGroup>

            <Button color="blue" onClick={props.onChangeShouldDisplayPlaceState}>
                <FontAwesomeIcon icon={props.shouldDisplayPlaceState ? faEye : faEyeSlash} />
            </Button>

            <SelectPicker
                data={[
                    {
                        label: intl.formatMessage({ id: 'camera.place.textInformation.name' }),
                        value: PlaceTextInformationType.NAME,
                    },
                    {
                        label: intl.formatMessage({ id: 'camera.place.textInformation.status' }),
                        value: PlaceTextInformationType.STATUS,
                    },
                    {
                        label: intl.formatMessage({ id: 'camera.place.textInformation.force' }),
                        value: PlaceTextInformationType.FORCE,
                    },
                    {
                        label: intl.formatMessage({ id: 'camera.place.textInformation.none' }),
                        value: PlaceTextInformationType.NONE,
                    },
                ]}
                style={{ width: 200 }}
                value={props.currentMode}
                onChange={value => props.onChangeMode(value as PlaceTextInformationType)}
                placement="topEnd"
                searchable={false}
                cleanable={false}
                className="margin-right-5 margin-left-5"
            />
        </Control>
    );
};
