import { FlowElement } from 'react-flow-renderer';
import { DisplayPreviewInformation } from '../../../../../../../handlers/lpDisplays/LPDisplay';
import {
    LPDisplayForceTypeCountersInterface,
    LPDisplayForceTypeVirtualDisplaysInterface,
} from '../../../../../../../handlers/lpDisplays/LPDisplayForceType';
import { VirtualDisplayStep } from '../../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import {
    virtualDisplayXPosition,
    virtualDisplayYPosition,
} from '../../../../../../IpCanElementsPage/LPMatrixDisplayV2Config/handlers/Nodes/FlowNodeExporter';
import { LPMatrixDisplayFlowElement } from '../LPMatrixDisplayChartElement';

export default class LPMatrixDisplayChartStepNode extends LPMatrixDisplayFlowElement {
    private _id: string;
    private _index: number;
    private _virtualDisplayIndex: number;
    private _label?: string;
    private _backgroundColor: string;
    private _step: VirtualDisplayStep;
    private _display: DisplayPreviewInformation;
    private _forceMode?: LPDisplayForceTypeCountersInterface;
    private _isForce: boolean;
    private _virtualDisplayForceMode?: LPDisplayForceTypeVirtualDisplaysInterface;
    private _isFirst: boolean;
    private _isLast: boolean;

    constructor(data: LPMatrixDisplayChartStepNodeInterface) {
        super(data.id, data.label);
        this._index = data.index;
        this._id = data.id;
        this._label = data.label;
        this._backgroundColor = data.backgroundColor;
        this._step = data.step;
        this._display = data.display;
        this._virtualDisplayIndex = data.virtualDisplayIndex;
        this._isForce = data.isForce;
        this._isFirst = data.isFirst;
        this._isLast = data.isLast;
        this._forceMode = data.forceMode;
        this._virtualDisplayForceMode = data.virtualDisplayForceMode;
    }

    public render(): FlowElement<LPMatrixDisplayChartStepNodeType> {
        const yPosition = virtualDisplayYPosition();
        const xPosition = virtualDisplayXPosition();

        return {
            id: `${this._id}-force`,
            type: 'forceVirtualDisplayStepNode',
            data: {
                label: this._label || '',
                display: this._display,
                backgroundColor: this._backgroundColor,
                step: this._step,
                isForce: this._isForce,
                forceMode: this._forceMode,
                virtualDisplayForceMode: this._virtualDisplayForceMode,
                isFirst: this._isFirst,
                isLast: this._isLast,
            },
            position: {
                x:
                    xPosition.initialPositionX +
                    (xPosition.baseTopologyWidth +
                        xPosition.columnWidth * this._display.nbMatrixX * 2 +
                        xPosition.gapX) *
                        (this._index + 2),
                y:
                    yPosition.initialPositionY +
                    (yPosition.baseTopologyHeight + yPosition.lineHeight * this._display.nbMatrixY + yPosition.gapY) *
                        this._virtualDisplayIndex,
            },
        };
    }
}

export type LPMatrixDisplayChartStepNodeType = {
    label: string;
    display: DisplayPreviewInformation;
    step: VirtualDisplayStep;
    backgroundColor: string;
    isForce: boolean;
    forceMode?: LPDisplayForceTypeCountersInterface;
    virtualDisplayForceMode?: LPDisplayForceTypeVirtualDisplaysInterface;
    isFirst: boolean;
    isLast: boolean;
};

export interface LPMatrixDisplayChartStepNodeInterface {
    index: number;
    virtualDisplayIndex: number;
    id: string;
    display: DisplayPreviewInformation;
    step: VirtualDisplayStep;
    backgroundColor: string;
    isForce: boolean;
    isFirst: boolean;
    isLast: boolean;
    forceMode?: LPDisplayForceTypeCountersInterface;
    label?: string;
    virtualDisplayForceMode;
}
