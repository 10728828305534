import handleError from '../../handlers/error.handler';
import { createParkingHandler } from '../../handlers/parking/createParkingHandler';
import { parkingHandler } from '../../handlers/parking/parking.handler';
import { updateParkingHandler } from '../../handlers/parking/updateParkingHandler';
import { authHeader } from '../helpers';
import { axiosService } from './axios.services';

export const parkingService = {
    getAll,
    getById,
    create,
    updateInformations,
    updateParkingImage,
    remove: remove,
};

function getAll() {
    return axiosService
        .getAxios()
        .get('/parkings', { headers: authHeader() })
        .then(response => response.data.map(parking => parkingHandler(parking)))
        .then(parkings => {
            return parkings;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function getById(id) {
    return axiosService
        .getAxios()
        .get(`/parkings/${id}`, { headers: authHeader() })
        .then(response => {
            return parkingHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function create(name, description) {
    const parameters = { headers: authHeader() };
    const data = {
        name,
        description,
    };
    return axiosService
        .getAxios()
        .post('/parkings', data, parameters)
        .then(response => {
            let parking = response.data;
            return createParkingHandler(parking);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateInformations(id, name, description) {
    const parameters = { headers: authHeader() };
    const data = { id, name, description };

    return axiosService
        .getAxios()
        .put('/parkings', data, parameters)
        .then(response => {
            let parking = response.data;
            return updateParkingHandler(parking);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateParkingImage(id, image) {
    const parameters = { headers: authHeader() };
    let data = new FormData();
    data.append('file', image);

    return axiosService
        .getAxios()
        .post(`/parkings/picture/${id}`, data, parameters)
        .then(response => {
            let parking = response.data;
            return updateParkingHandler(parking);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function remove(id) {
    const data = {
        headers: authHeader(),
        data: { id: id },
    };
    return axiosService
        .getAxios()
        .delete('/parkings', data)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}
