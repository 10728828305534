import { VirtualDisplayStep } from '../../../../../handlers/lpDisplays/VirtualDisplayStep';
import FlowEdge from './FlowEdge';

export class FlowEdgeWithLabel extends FlowEdge {
    private _step: VirtualDisplayStep;

    constructor(
        source: string,
        sourceHandle: string,
        target: string,
        targetHandle: string,
        step: VirtualDisplayStep,
        color: string
    ) {
        super(source, sourceHandle, target, targetHandle, color);

        this._step = step;
    }

    public toRender = () => {
        return {
            id: `${this._source}-${this._sourceHandle}-${this._target}-${this._targetHandle}`,
            source: this._source,
            sourceHandle: this._sourceHandle,
            target: this._target,
            targetHandle: this._targetHandle,
            type: 'edgeVirtualDisplayStep',
            data: {
                step: this._step,
            },
            style: {
                stroke: this._color,
                strokeWidth: 3,
            },
        };
    };
}
