import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Col, FlexboxGrid, Form, FormControl, List, Modal } from 'rsuite';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    display: LPDisplay;
    show: boolean;
    onHide: Function;
    reloadDisplay?: () => void;
} & WrappedComponentProps;

type State = {
    formValue: FormValue;
    isUpdating: boolean;
    hasError: boolean;
    error: any;
};

type FormValue = {
    userText1: string;
    userText2: string;
    userText3: string;
    userText4: string;
    userText5: string;
    userText6: string;
    userText7: string;
    userText8: string;
};

class UpdateLPDisplayUserText extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            formValue: {
                userText1: props.display.getTextConfig().getUserText()[0],
                userText2: props.display.getTextConfig().getUserText()[1],
                userText3: props.display.getTextConfig().getUserText()[2],
                userText4: props.display.getTextConfig().getUserText()[3],
                userText5: props.display.getTextConfig().getUserText()[4],
                userText6: props.display.getTextConfig().getUserText()[5],
                userText7: props.display.getTextConfig().getUserText()[6],
                userText8: props.display.getTextConfig().getUserText()[7],
            },
            isUpdating: false,
            hasError: false,
            error: '',
        };
    }

    hideModal = () => {
        if (!this.state.isUpdating) {
            this.setState({
                formValue: {
                    userText1: this.props.display.getTextConfig().getUserText()[0],
                    userText2: this.props.display.getTextConfig().getUserText()[1],
                    userText3: this.props.display.getTextConfig().getUserText()[2],
                    userText4: this.props.display.getTextConfig().getUserText()[3],
                    userText5: this.props.display.getTextConfig().getUserText()[4],
                    userText6: this.props.display.getTextConfig().getUserText()[5],
                    userText7: this.props.display.getTextConfig().getUserText()[6],
                    userText8: this.props.display.getTextConfig().getUserText()[7],
                },
            });

            this.props.onHide();
        }
    };

    handleFormChange = formValue => {
        this.setState({
            formValue: {
                userText1: formValue.userText1,
                userText2: formValue.userText2,
                userText3: formValue.userText3,
                userText4: formValue.userText4,
                userText5: formValue.userText5,
                userText6: formValue.userText6,
                userText7: formValue.userText7,
                userText8: formValue.userText8,
            },
        });
    };

    handleEditUserText = () => {
        this.setState({
            isUpdating: true,
            hasError: false,
            error: '',
        });

        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixDisplays-v2/updateUserTexts',
                {
                    id: this.props.display.getId(),
                    userTexts: {
                        texts: [
                            this.state.formValue.userText1,
                            this.state.formValue.userText2,
                            this.state.formValue.userText3,
                            this.state.formValue.userText4,
                            this.state.formValue.userText5,
                            this.state.formValue.userText6,
                            this.state.formValue.userText7,
                            this.state.formValue.userText8,
                        ],
                    },
                },
                { headers: authHeader() }
            )
            .then(updateUserTextsResponse => {
                Alert.success(
                    this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplays.userText.update.success' }),
                    5000
                );

                this.setState({
                    isUpdating: false,
                });

                this.props.display
                    .getTextConfig()
                    .updateUserText(updateUserTextsResponse.data.textConfig.userTexts.texts);

                if (this.props.reloadDisplay) {
                    this.props.reloadDisplay();
                }

                this.props.onHide();
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                    isUpdating: false,
                });
            });
    };

    generateLine = (index: number) => (
        <List.Item className={index % 2 !== 0 ? 'striped panel-list' : 'panel-list'}>
            <FlexboxGrid justify="space-between" align="middle">
                <FlexboxGrid.Item>
                    <FormattedMessage id={`ipCanDevices.lpDisplays.userText.userText${index}`} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={12}>
                    <FormControl data-cy={`lpDisplay-userText-${index}`} name={`userText${index}`} maxLength={50} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </List.Item>
    );

    render = () => (
        <Modal show={this.props.show} onHide={() => this.hideModal()} size="md" backdrop="static">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="ipCanDevices.lpDisplays.userText.update" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form fluid formValue={this.state.formValue} onChange={this.handleFormChange}>
                    <List>
                        {this.generateLine(1)}
                        {this.generateLine(2)}
                        {this.generateLine(3)}
                        {this.generateLine(4)}
                        {this.generateLine(5)}
                        {this.generateLine(6)}
                        {this.generateLine(7)}
                        {this.generateLine(8)}
                    </List>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        data-cy="lpDisplay-userText-edit-cancel"
                        onClick={() => this.hideModal()}
                        color="red"
                        loading={this.state.isUpdating}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        data-cy="lpDisplay-userText-edit-valid"
                        onClick={this.handleEditUserText}
                        appearance="primary"
                        loading={this.state.isUpdating}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
}

export default injectIntl(UpdateLPDisplayUserText);
