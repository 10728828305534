import { faSquareFull, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DataTable, { TableColumn, TableStyles } from 'react-data-table-component';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Tag } from 'rsuite';
import { LPVirtualDisplayLanguages } from '../../../../handlers/lpDisplays/Enums/LPVirtualDisplayEnums';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplay } from '../../../../handlers/lpDisplays/VirtualDisplay';
import VirtualDisplayDrawer from './VirtualDisplayDrawer';

type Props = {
    data: LPDisplay;
    reloadDevices: Function;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    isDrawerOpen: boolean;
    virtualDisplaySelected?: VirtualDisplay;
    reloadKey: boolean;
};

class VirtualDisplaysComponent extends React.Component<Props, State> {
    customStyles: TableStyles;

    columns: TableColumn<VirtualDisplay>[] = [
        {
            name: this.props.intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.virtualDisplay.address',
            }),
            cell: row => <div data-cy="lpDisplay-virtualDisplay-address">{row.getPosId() + 1}</div>,
            center: true,
        },
        {
            name: this.props.intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.virtualDisplay.font',
            }),
            cell: row => <div data-cy="lpDisplay-virtualDisplay-font">{`MLE_BIG_${row.getFont()}`}</div>,
            center: true,
        },
        {
            name: this.props.intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.virtualDisplay.backgroundColor',
            }),
            cell: row => (
                <FontAwesomeIcon
                    data-cy="lpDisplay-virtualDisplay-backgroundColor"
                    icon={faSquareFull}
                    size={'2x'}
                    color={row.getBackgroundColor()}
                />
            ),
            center: true,
        },
        {
            name: this.props.intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.virtualDisplay.languages',
            }),
            cell: row =>
                row.getDisplayLanguage().length === 0 ? (
                    <FontAwesomeIcon
                        data-cy="lpDisplay-virtualDisplay-noLanguages"
                        icon={faTimes}
                        size={'2x'}
                        color="red"
                    />
                ) : (
                    row.getDisplayLanguage().map(displayLanguage => (
                        <Tag data-cy="lpDisplay-virtualDisplay-languages" color="blue">
                            {LPVirtualDisplayLanguages(displayLanguage)}
                        </Tag>
                    ))
                ),
            center: true,
        },
        {
            name: this.props.intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.virtualDisplay.nbSteps',
            }),
            cell: row => (
                <Tag data-cy="lpDisplay-virtualDisplay-nbSteps" color="blue">{`${row.getSteps().length} / 8`}</Tag>
            ),
            center: true,
        },
        {
            name: this.props.intl.formatMessage({
                id: 'ipCanDevices.lpDisplays.virtualDisplay.nbMatrix',
            }),
            cell: row => (
                <Tag data-cy="lpDisplay-virtualDisplay-nbMatrix" color="blue">
                    {row.getNbMatrix()}
                </Tag>
            ),
            center: true,
        },
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            isDrawerOpen: false,
            virtualDisplaySelected: undefined,
            reloadKey: false,
        };

        this.customStyles = {
            headRow: {
                style: {
                    backgroundColor: '#B5DDC2',
                    minHeight: '28px',
                },
            },
            headCells: {
                style: {
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#000',
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgba(181, 221, 194, 0.8)',
                    color: '#000',
                },
                stripedStyle: {
                    color: '#000',
                    backgroundColor: 'rgba(181, 221, 194, 0.4)',
                },
            },
        };
    }

    openDrawer = (row: VirtualDisplay) => {
        this.setState({
            isDrawerOpen: true,
            virtualDisplaySelected: row,
        });
    };

    closeDrawer = () => {
        this.setState({
            isDrawerOpen: false,
            virtualDisplaySelected: undefined,
        });
    };

    render = () => {
        const virtualDisplays = [...this.props.data.getVirtualDisplays()];

        return (
            <div
                className="padding-top-10 padding-right-10 padding-bottom-10 padding-left-10"
                style={{ backgroundColor: '#8e8e93' }}>
                <VirtualDisplayDrawer
                    show={this.state.isDrawerOpen}
                    onHide={this.closeDrawer}
                    virtualDisplay={this.props.data
                        .getVirtualDisplays()
                        .find(virtualDisplay => virtualDisplay.getId() === this.state.virtualDisplaySelected?.getId())}
                    display={this.props.data}
                    updateValue={this.props.reloadDevices}
                    isLoading={this.props.isLoading}
                />
                <DataTable
                    columns={this.columns}
                    customStyles={this.customStyles}
                    data={virtualDisplays}
                    striped
                    highlightOnHover
                    onRowClicked={this.openDrawer}
                />
            </div>
        );
    };
}

export default injectIntl(VirtualDisplaysComponent);
