import PropTypes from 'prop-types';
import React from 'react';
import Arrows from '../Elements/Arrow';

export default function arrowCell(arrow) {
    return <Arrows data-tag="allowRowEvents" value={arrow} />;
}

arrowCell.propTypes = {
    arrow: PropTypes.number,
};
