import { faCheck, faEdit, faPlug, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    List,
    Loader as RsuiteLoader,
    Panel,
    SelectPicker,
    Tag,
} from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
// import { ipCanActions } from '../../../redux/actions';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import PanelHeader from '../../Custom/PanelHeader';
import { getIpCanModuleType, IpCanModuleTypeEnum, ipCanModuleTypeFormValues, ipCanModuleTypeValue } from './IpCanType';

type Props = {
    ipcanModule: IpCan;
    reloadIpCanModules: () => void;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    formValue: Record<string, any>;
    editMode: boolean;
    loading: boolean;
    editTypeMode: boolean;
    typeFormValue: {
        type: IpCanModuleTypeEnum | null;
    };
};
class Informations extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            formValue: { ...this.props.ipcanModule.getInformations() },
            typeFormValue: { type: ipCanModuleTypeValue(props.ipcanModule) },
            editMode: false,
            loading: false,
            editTypeMode: false,
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.unsetEditMode = this.unsetEditMode.bind(this);
        this.cancelEditMode = this.cancelEditMode.bind(this);
        this.setFormValue = this.setFormValue.bind(this);
        this.validEdition = this.validEdition.bind(this);
    }

    setEditMode() {
        this.setState({ editMode: true });
    }

    unsetEditMode() {
        this.setState({ editMode: false });
    }

    cancelEditMode() {
        this.setState({ editMode: false, formValue: this.props.ipcanModule.getInformations() });
        this.unsetEditMode();
    }

    validEdition() {
        this.setState({
            loading: true,
        });
        const { label, desc, ip, macStr } = this.state.formValue;

        this.props.ipcanModule
            .updateInformations(label, desc, ip, macStr)
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'ipcan.informations.updateInformations.success' }));
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage({ id: 'ipcan.informations.updateInformations.error' }, { error: err })
                );
            })
            .finally(() => {
                this.setState({
                    editMode: false,
                    loading: false,
                });

                this.props.reloadIpCanModules && this.props.reloadIpCanModules();
            });
    }

    validTypeEdition = () => {
        this.setState({
            loading: true,
        });

        const { type } = this.state.typeFormValue;

        this.props.ipcanModule
            .updateType(type)
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'ipcan.informations.updateType.success' }));
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage({ id: 'ipcan.informations.updateType.error' }, { error: err })
                );
            })
            .finally(() => {
                this.setState({
                    editTypeMode: false,
                    loading: false,
                });

                this.props.reloadIpCanModules && this.props.reloadIpCanModules();
            });
    };

    cancelTypeEdition = () => {
        this.setState({
            typeFormValue: { type: ipCanModuleTypeValue(this.props.ipcanModule) },
        });

        this.unsetTypeEditMode();
    };

    setTypeEditMode = () => {
        this.setState({ editTypeMode: true });
    };

    unsetTypeEditMode = () => {
        this.setState({ editTypeMode: false });
    };

    setFormValue(formValue) {
        const newFormValue = { ...this.state.formValue, ...formValue };

        this.setState({ formValue: newFormValue });
    }

    setTypeFormValue = formValue => {
        const newFormValue = { ...this.state.typeFormValue, ...formValue };

        this.setState({ typeFormValue: newFormValue });
    };

    render() {
        const informations = this.props.ipcanModule.getInformations();

        if (this.state.editTypeMode) {
            return (
                <Panel
                    shaded
                    bodyFill
                    bordered
                    className="panel-small"
                    data-cy="ipcan-controller-information"
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'controller.information.title' })}
                            buttons={[]}
                        />
                    }>
                    <Form
                        formValue={this.state.typeFormValue}
                        onChange={formValue => this.setTypeFormValue(formValue)}
                        fluid>
                        <List hover>
                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }} componentClass={Col} xs={8}>
                                        <FormattedMessage id="controller.information.type" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={14}>
                                        <FormControl
                                            data-cy="IPCan-edit-type"
                                            name="type"
                                            size="sm"
                                            accepter={SelectPicker}
                                            data={ipCanModuleTypeFormValues(this.props.intl)}
                                            value={this.state.formValue.type}
                                            cleanable={false}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        </List>
                        <FlexboxGrid align="middle" justify="end">
                            <FlexboxGrid.Item>
                                <ButtonGroup
                                    style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}>
                                    <Button
                                        color="red"
                                        data-cy="IPCan-edit-type-cancel"
                                        disabled={this.state.loading}
                                        onClick={this.cancelTypeEdition}>
                                        <FontAwesomeIcon icon={faTimesCircle} />
                                    </Button>
                                    <Button
                                        color="green"
                                        data-cy="IPCan-edit-type-valid"
                                        onClick={this.validTypeEdition}
                                        loading={this.state.loading}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </ButtonGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </Panel>
            );
        } else {
            return (
                <Loader show={this.props.isLoading}>
                    <Panel
                        shaded
                        bodyFill
                        bordered
                        className="panel-small"
                        data-cy="ipcan-controller-information"
                        header={
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'controller.information.title' })}
                                buttons={[
                                    <SecuredFragment
                                        key="informations.edit"
                                        authorizedRoles={[rolesConstants.controller.UPDATE]}>
                                        <Button
                                            onClick={this.setEditMode}
                                            appearance="primary"
                                            size="sm"
                                            data-cy="IPCan-edit-information">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                    </SecuredFragment>,
                                ]}
                            />
                        }>
                        {this.state.editMode ? (
                            <Form
                                formValue={this.state.formValue}
                                onChange={formValue => this.setFormValue(formValue)}
                                fluid>
                                <List hover>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item
                                                style={{ fontWeight: 'bold' }}
                                                componentClass={Col}
                                                xs={8}>
                                                <FormattedMessage id="controller.information.label" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={16}>
                                                <FormControl
                                                    data-cy="IPCan-edit-label"
                                                    name="label"
                                                    size="sm"
                                                    value={this.state.formValue.label}
                                                />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item
                                                style={{ fontWeight: 'bold' }}
                                                componentClass={Col}
                                                xs={8}>
                                                <FormattedMessage id="controller.information.desc" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={16}>
                                                <FormControl
                                                    data-cy="IPCan-edit-desc"
                                                    name="desc"
                                                    size="sm"
                                                    value={this.state.formValue.desc}
                                                />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item
                                                style={{ fontWeight: 'bold' }}
                                                componentClass={Col}
                                                xs={8}>
                                                <FormattedMessage id="controller.information.ipAddress" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={16}>
                                                <FormControl
                                                    data-cy="IPCan-edit-ip"
                                                    name="ip"
                                                    size="sm"
                                                    value={this.state.formValue.ip}
                                                />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item
                                                style={{ fontWeight: 'bold' }}
                                                componentClass={Col}
                                                xs={8}>
                                                <FormattedMessage id="controller.information.macAddress" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={16}>
                                                <FormControl
                                                    data-cy="IPCan-edit-mac"
                                                    name="macStr"
                                                    size="sm"
                                                    value={this.state.formValue.macStr}
                                                />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                </List>
                                <FlexboxGrid align="middle" justify="end">
                                    <FlexboxGrid.Item>
                                        <ButtonGroup
                                            style={{
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                marginTop: 10,
                                                marginBottom: 10,
                                            }}>
                                            <Button
                                                color="red"
                                                data-cy="IPCan-edit-information-cancel"
                                                disabled={this.state.loading}
                                                onClick={this.cancelEditMode}>
                                                <FontAwesomeIcon icon={faTimesCircle} />
                                            </Button>
                                            <Button
                                                color="green"
                                                data-cy="IPCan-edit-information-valid"
                                                onClick={this.validEdition}
                                                loading={this.state.loading}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                        </ButtonGroup>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </Form>
                        ) : (
                            <Fragment>
                                <List hover>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                                <div data-cy="IPCan-information-label-title">
                                                    <FormattedMessage id="controller.information.label" />
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="IPCan-information-label">
                                                <Fragment>
                                                    <span className="margin-right-10">{informations.label}</span>
                                                    {informations.online === 0 && (
                                                        <FontAwesomeIcon icon={faPlug} style={{ color: 'red' }} />
                                                    )}
                                                    {informations.online === 1 && (
                                                        <FontAwesomeIcon icon={faPlug} style={{ color: 'orange' }} />
                                                    )}
                                                    {informations.online === 2 && (
                                                        <FontAwesomeIcon icon={faPlug} style={{ color: 'green' }} />
                                                    )}
                                                </Fragment>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                                <div data-cy="IPCan-information-desc-title">
                                                    <FormattedMessage id="controller.information.desc" />
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="IPCan-information-desc">
                                                <Fragment>
                                                    <span className="margin-right-10">{informations.desc}</span>
                                                </Fragment>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                                <div data-cy="IPCan-information-type-title">
                                                    <FormattedMessage id="controller.information.type" />
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="IPCan-information-type">
                                                <Tag color="blue">
                                                    {getIpCanModuleType(this.props.ipcanModule, this.props.intl)}
                                                </Tag>
                                                <Button
                                                    size="xs"
                                                    appearance="link"
                                                    onClick={this.setTypeEditMode}
                                                    data-cy="IPCan-information-type-edit">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                                <div data-cy="IPCan-information-syncStateAndRestarts-title">
                                                    <FormattedMessage id="controller.information.syncStateAndRestarts" />
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="IPCan-information-syncStateAndRestarts">
                                                <SyncStateAndRestarts
                                                    online={informations.online >= 1}
                                                    syncState={informations.syncState}
                                                    restarts={informations.nbReboot}
                                                    msgFifoLength={informations.fifoLength}
                                                />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                                <div data-cy="IPCan-information-ip-mac-title">
                                                    <FormattedMessage id="controller.information.ipAddressAndMacAddress" />
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="IPCan-information-ip-mac">
                                                <p>
                                                    {informations.ip} / {informations.macStr}
                                                </p>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                                <div data-cy="IPCan-information-VersionAndSerial-title">
                                                    <FormattedMessage id="controller.information.versionAndSerialNumber" />
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="IPCan-information-VersionAndSerial">
                                                <p>
                                                    {informations.version} - {informations.serialNumberStr}
                                                </p>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                                <div data-cy="IPCan-information-creation-title">
                                                    <FormattedMessage id="controller.information.creationInformations" />
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="IPCan-information-creation">
                                                <p>{informations.creationInformations}</p>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between">
                                            <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                                                <div data-cy="IPCan-information-update-title">
                                                    <FormattedMessage id="controller.information.editionInformations" />
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item data-cy="IPCan-information-update">
                                                <p>{informations.updateInformations}</p>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                </List>
                            </Fragment>
                        )}
                    </Panel>
                </Loader>
            );
        }
    }
}

type SyncStateAndRestartsProps = {
    online: boolean;
    syncState: number;
    restarts: number;
    msgFifoLength: number;
};

class SyncStateAndRestarts extends React.Component<SyncStateAndRestartsProps> {
    generateValue(value) {
        if (!this.props.online) {
            return <FontAwesomeIcon icon={faTimesCircle} />;
        } else if (typeof value === 'undefined' || value === -1) {
            return <RsuiteLoader />;
        } else {
            return value;
        }
    }

    render() {
        return (
            <span>
                {this.generateValue(this.props.syncState)} ({this.generateValue(this.props.msgFifoLength)}) /{' '}
                {this.generateValue(this.props.restarts)}
            </span>
        );
    }
}

export default injectIntl(Informations);
