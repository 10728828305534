import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function sendStreamConf(option) {
    return (
        <div data-cy="historic-ipcamera-option-sendStreamConfg">
            <span>
                ID: {option.id} -
                <FontAwesomeIcon
                    icon={option.status ? faPlay : faStop}
                    color={option.status ? 'green' : 'red'}
                    className="margin-left-5"
                />{' '}
                - Time: {option.time}s
            </span>
        </div>
    );
}
