import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, ButtonGroup, Col, FlexboxGrid, List, Loader, Popover, Whisper } from 'rsuite';
import Task from '../../../../handlers/Event/Task';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import { CreateAndEditTaskModal, StepTypeEnum } from '../../Modal/CreateTaskModal';
import DeleteTaskModal from '../../Modal/DeleteTaskModal';
import { TaskTypeEnum } from '../../Modal/Tasks/CreateTaskType';
import { ForceIPCanGPIO } from './EventTaskInformation/ForceIPCanGPIO';
import { LPDisplayForce } from './EventTaskInformation/LPDisplayForce';
import { LPDisplayForceBlack } from './EventTaskInformation/LPDisplayForceBlack';
import { LPDisplayUnforce } from './EventTaskInformation/LPDisplayUnforce';
import { LPSensorForceColor } from './EventTaskInformation/LPSensorForceColor';
import { LPSensorUnforceColor } from './EventTaskInformation/LPSensorUnforceColor';
import { TCMDisplayForce } from './EventTaskInformation/TCMDisplayForce';
import { TCMDisplayUnforce } from './EventTaskInformation/TCMDisplayUnforce';
import { TCMSensorForceColor } from './EventTaskInformation/TCMSensorForceColor';
import { TCMSensorUnforceColor } from './EventTaskInformation/TCMSensorUnforceColor';

type Props = {
    intl: Record<string, any>;
    taskId: number;
    reloadEvent: Function;
} & WrappedComponentProps;

type State = {
    loading: boolean;
    task: undefined | Task;
    isDeleteTaskModalOpen: boolean;
    isEditTaskModalOpen: boolean;
};
class EventTaskInformations extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            task: undefined,
            loading: true,
            isDeleteTaskModalOpen: false,
            isEditTaskModalOpen: false,
        };

        this.handleOpenDeleteTaskModal = this.handleOpenDeleteTaskModal.bind(this);
        this.handleCloseDeleteTaskModal = this.handleCloseDeleteTaskModal.bind(this);
        this.handleOpenEditTaskModal = this.handleOpenEditTaskModal.bind(this);
        this.handleCloseEditTaskModal = this.handleCloseEditTaskModal.bind(this);
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get(`/event-actions/tasks/${this.props.taskId}`, { headers: authHeader() })
            .then(taskResponse => {
                this.setState({
                    task: new Task(taskResponse.data),
                    loading: false,
                });
            });
    }

    handleOpenDeleteTaskModal() {
        this.setState({
            isDeleteTaskModalOpen: true,
        });
    }

    handleCloseDeleteTaskModal() {
        this.setState({
            isDeleteTaskModalOpen: false,
        });
    }

    handleOpenEditTaskModal() {
        this.setState({
            isEditTaskModalOpen: true,
        });
    }

    handleCloseEditTaskModal() {
        this.setState({
            isEditTaskModalOpen: false,
        });

        this.props.reloadEvent();
    }

    generateTask = () => {
        if (this.state.loading || !this.state.task) {
            return <Loader size="sm" content="Loading..." />;
        }

        switch (this.state.task.getForceType()) {
            case TaskTypeEnum.TCM_SENSOR_FORCE_COLOR:
                return <TCMSensorForceColor task={this.state.task} />;

            case TaskTypeEnum.TCM_SENSOR_DISABLE_FORCE:
                return <TCMSensorUnforceColor task={this.state.task} />;

            case TaskTypeEnum.TCM_DISPLAY_FORCE:
                return <TCMDisplayForce task={this.state.task} />;

            case TaskTypeEnum.TCM_DISPLAY_DISABLE_FORCE:
                return <TCMDisplayUnforce task={this.state.task} />;

            case TaskTypeEnum.LP_SENSOR_FORCE_COLOR:
                return <LPSensorForceColor task={this.state.task} />;

            case TaskTypeEnum.LP_SENSOR_DISABLE_FORCE:
                return <LPSensorUnforceColor task={this.state.task} />;

            case TaskTypeEnum.LP_MATRIX_DISPLAY_V2_ENABLE_FORCE:
                return <LPDisplayForce task={this.state.task} />;

            case TaskTypeEnum.LP_MATRIX_DISPLAY_V2_BLACK_FORCE:
                return <LPDisplayForceBlack task={this.state.task} />;

            case TaskTypeEnum.LP_MATRIX_DISPLAY_V2_DISABLE_FORCE:
                return <LPDisplayUnforce task={this.state.task} />;

            case TaskTypeEnum.FORCE_IPCAN_GPIO:
                return <ForceIPCanGPIO task={this.state.task} />;

            default:
                return null;
        }
    };

    render() {
        if (this.state.loading) {
            return (
                <List.Item className="panel-list">
                    <Loader />
                </List.Item>
            );
        } else {
            return (
                <Fragment>
                    {this.state.task && (
                        <DeleteTaskModal
                            show={this.state.isDeleteTaskModalOpen}
                            onHide={this.handleCloseDeleteTaskModal}
                            reloadEvent={this.props.reloadEvent}
                            task={this.state.task}
                        />
                    )}

                    {this.state.task && (
                        <CreateAndEditTaskModal
                            show={this.state.isEditTaskModalOpen}
                            onClose={this.handleCloseEditTaskModal}
                            task={this.state.task}
                            type={StepTypeEnum.EDIT}
                        />
                    )}

                    <Whisper trigger="hover" placement="left" speaker={<TaskPopover task={this.state.task} />}>
                        <List.Item className="panel-list" data-cy="event_task_informations">
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item
                                    data-cy="event-task-name"
                                    componentClass={Col}
                                    xs={20}
                                    style={{ fontWeight: 'bold' }}>
                                    {this.state.task?.getName()} -{' '}
                                    <span className="text-muted">{this.state.task?.getDesc()}</span>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={4} style={{ fontWeight: 'bold' }}>
                                    <ButtonGroup>
                                        <SecuredFragment authorizedRoles={[rolesConstants.eventTasks.UPDATE]}>
                                            <Button
                                                data-cy="task-edit"
                                                color="blue"
                                                size="sm"
                                                onClick={this.handleOpenEditTaskModal}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </SecuredFragment>
                                        <SecuredFragment authorizedRoles={[rolesConstants.eventTasks.DELETE]}>
                                            <Button
                                                data-cy="task-delete"
                                                color="red"
                                                size="sm"
                                                onClick={this.handleOpenDeleteTaskModal}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </Button>
                                        </SecuredFragment>
                                    </ButtonGroup>
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item
                                    data-cy="event-task-type"
                                    componentClass={Col}
                                    xs={24}
                                    className="margin-bottom-10">
                                    {this.state.loading ? <Loader /> : this.generateTask()}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                    </Whisper>
                </Fragment>
            );
        }
    }
}

type PopoverProps = {
    task?: Task;
};

function TaskPopover(props: PopoverProps) {
    return (
        <Popover title={props.task?.getName()} {...props}>
            <SecuredFragment authorizedRoles={[rolesConstants.eventTasks.VIEW]}>
                <p>
                    <span className="bold">
                        <FormattedMessage id="task.creation" />
                    </span>
                    : {moment(props.task?.getCreatedAt()).format('DD/MM/YYYY HH:mm:ss')} - {props.task?.getCreatedBy()}
                </p>
                <p>
                    <span className="bold">
                        <FormattedMessage id="task.update" />
                    </span>
                    : {moment(props.task?.getUpdatedAt()).format('DD/MM/YYYY HH:mm:ss')} - {props.task?.getUpdatedBy()}
                </p>
            </SecuredFragment>
        </Popover>
    );
}

export default injectIntl(EventTaskInformations);
