import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Loader, Panel, Progress } from 'rsuite';
import { IpCan } from '../../handlers/ipcan/IpCan';
import { WritePictoProgress } from '../../handlers/pixelArt/pixelArtWS';
import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';

type Props = {
    writePictoProgress: WritePictoProgress;
};

type IpCanModuleState = {
    ipcan?: IpCan;
    isLoading: boolean;
};

export const WritePictoNotification = (props: Props) => {
    const [ipcanModule, setIpCanModule] = React.useState<IpCanModuleState>({ isLoading: true });
    const [writeProgress, setWriteProgress] = React.useState<number>(0);

    const intl = useIntl();

    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/ipcanmodules/${props.writePictoProgress.ipcanmoduleId}`, {
                headers: authHeader(),
            })
            .then(ipcanModuleResponse => {
                setIpCanModule({ ipcan: new IpCan(ipcanModuleResponse.data), isLoading: false });
            });
    }, []);

    useEffect(() => {
        if (props.writePictoProgress.writeProgress.totalSize && props.writePictoProgress.writeProgress.size) {
            const total =
                (props.writePictoProgress.writeProgress.size / props.writePictoProgress.writeProgress.totalSize) * 100;

            setWriteProgress(parseFloat(total.toFixed(2)));
        }
    }, [props.writePictoProgress.writeProgress.size]);

    const generateTitle = () => {
        if (ipcanModule.isLoading) {
            return (
                <span>
                    <Loader /> - {props.writePictoProgress.bus + 1} - {props.writePictoProgress.deviceId}`
                </span>
            );
        } else if (ipcanModule.ipcan) {
            return `${ipcanModule.ipcan.getLabel()} (${ipcanModule.ipcan.macStr}) - ${
                props.writePictoProgress.bus + 1
            } - ${props.writePictoProgress.deviceId}`;
        }
    };

    let status: 'active' | 'success' | 'fail' = 'active';

    if (writeProgress === 100) status = 'success';
    if (props.writePictoProgress.writeProgress.err) status = 'fail';

    return (
        <Panel
            header={intl.formatMessage({ id: 'writePictoProgress.writePicto.title' })}
            bordered
            shaded
            className="panel-small">
            {/* <FlexboxGrid align="middle" justify="space-between">
                <FlexboxGrid.Item colspan={20}> */}
            <p className="bold">{generateTitle()}</p>
            {/* </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <Button appearance="ghost" size="sm">
                        <PictoAwesomeIcon icon={faTimes} />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid> */}

            {props.writePictoProgress.writeProgress.err && (
                <p style={{ color: 'red' }}>{props.writePictoProgress.writeProgress.err}</p>
            )}
            <Progress.Line percent={writeProgress} status={status} />
        </Panel>
    );
};
