import { LPMatrixDisplayV2VirtualDisplayForceType } from '../../Components/Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/LPMatrixDisplayFactory';
import { rgbToHex } from '../../utils/colorUtils';
import { ColorInterface } from './VirtualDisplay';
import { VirtualDisplayStepTrigger, VirtualDisplayStepTriggerInterface } from './VirtualDisplayStepTrigger';

export enum VirtualDisplayStepState {
    DISABLED = 0,
    ENABLED = 1,
    WAITING = 2,
}

export class VirtualDisplayStep {
    private _state: number;
    private _color: ColorInterface;
    private _mode: number;
    private _textTransformation: number;
    private _transition: number;
    private _stepOrder: number;
    private _time: number;
    private _pictoSize: number;
    private _picto: number;
    private _arrowSize: number;
    private _arrowOnMap: number;
    private _arrow: number;
    private _triggerMin: VirtualDisplayStepTrigger;
    private _triggerMax: VirtualDisplayStepTrigger;
    private _isForcedStep: boolean;
    private _forceMode?: LPMatrixDisplayV2VirtualDisplayForceType;

    constructor(step_: VirtualDisplayStepInterface) {
        const {
            state,
            color,
            mode,
            textTransformation,
            transition,
            time,
            pictoSize,
            picto,
            arrowSize,
            arrow,
            arrowOnMap,
            stepOrder,
            triggerMin,
            triggerMax,
            isForcedStep,
            forceMode,
        } = step_;

        this._state = state;
        this._color = color;
        this._mode = mode;
        this._textTransformation = textTransformation;
        this._transition = transition;
        this._time = time;
        this._pictoSize = pictoSize;
        this._picto = picto;
        this._arrowSize = arrowSize;
        this._arrow = arrow;
        this._arrowOnMap = arrowOnMap;
        this._stepOrder = stepOrder;
        this._triggerMin = new VirtualDisplayStepTrigger(triggerMin);
        this._triggerMax = new VirtualDisplayStepTrigger(triggerMax);
        this._isForcedStep = isForcedStep ? isForcedStep : false;
        this._forceMode = forceMode;
    }

    public getIsForcedStep(): boolean {
        return this._isForcedStep;
    }

    public getState(): VirtualDisplayStepState {
        return this._state;
    }

    public getColor(): ColorInterface {
        return this._color;
    }

    public getColorHex(): string {
        const hex = rgbToHex(this._color.red, this._color.green, this._color.blue);

        return hex.toUpperCase();
    }

    public getMode(): number {
        return this._mode;
    }

    public getTextTransformation(): number {
        return this._textTransformation;
    }

    public getTextTransformationString(): string {
        switch (this._textTransformation) {
            case 0:
                return 'fix';
            case 1:
                return 'autoDefil';
            case 2:
                return 'autoSizeAndDefilMin';
            case 3:
                return 'noText';
            case 4:
                return 'multiLines';
            case 5:
                return 'autoSizeAndDefilMax';
            default:
                return 'none';
        }
    }

    public getTransition(): number {
        return this._transition;
    }

    public getTime(): number {
        return this._time;
    }

    public getPictoSize(): number {
        let pictoSize = this._pictoSize;

        if (pictoSize >= 4000) pictoSize -= 4000;
        if (pictoSize >= 3000) pictoSize -= 3000;
        if (pictoSize >= 2000) pictoSize -= 2000;
        if (pictoSize >= 1000) pictoSize -= 1000;

        return pictoSize;
    }

    public getPicto(): number {
        return this._picto;
    }

    public getPictoImage(): string {
        switch (this._picto) {
            case 1:
                return 'family';
            case 2:
                return 'pmr';
            case 3:
                return 'electrical';
            case 4:
                return 'park';
            case 5:
                return 'taxi';
            case 6:
                return 'taxi_alt';
            case 7:
                return 'star';
            case 8:
                return 'star_alt';
            case 9:
                return 'two_lines_card';
            case 10:
                return 'two_lines_card_alt';
            case 11:
                return 'vip';
            case 12:
                return 'covered_park';
            case 13:
                return 'carpooling';
            case 14:
                return 'three_lines_card';
            case 15:
                return 'dir';
            case 16:
                return 'three_m';
            case 17:
                return 'home';
            case 18:
                return 'times'; // full
            case 19:
                return 'ban'; // closed
            case 20:
                return 'circle'; // full alt
            case 21:
                return 'wrong_way'; // no entry
            case 22:
                return 'wrong_way_inverted'; // no entry alt
        }

        return '';
    }

    public getPictoPosition(): string {
        let pictoSize = this._pictoSize;

        if (pictoSize >= 4000) pictoSize -= 4000;
        if (pictoSize >= 3000) pictoSize -= 3000;

        if (pictoSize >= 2000) return 'right';
        if (pictoSize >= 1000) return 'left';
        return 'center';
    }

    public getPictoPositionNumber(): number {
        let pictoSize = this._pictoSize;

        if (pictoSize >= 4000) pictoSize -= 4000;
        if (pictoSize >= 3000) pictoSize -= 3000;

        if (pictoSize >= 2000) return 2000;
        if (pictoSize >= 1000) return 1000;
        return 0;
    }

    public getTotalArrowSize(): number {
        return this._arrowSize;
    }

    public getArrowSize(): number {
        let arrowSize = this._arrowSize;
        if (arrowSize > 30000) arrowSize -= 30000;
        if (arrowSize > 20000) arrowSize -= 20000;
        if (arrowSize > 10000) arrowSize -= 10000;
        if (arrowSize > 2000) arrowSize -= 2000;
        if (arrowSize > 1000) arrowSize -= 1000;

        return arrowSize;
    }

    public getArrow(): number {
        return this._arrow;
    }

    public getArrowOnMap(): number {
        return this._arrowOnMap || this._arrow;
    }

    public getArrowRepeat(): boolean {
        return this._arrowSize >= 30000;
    }

    public getStepOrder(): number {
        return this._stepOrder;
    }

    public getTriggerMin(): VirtualDisplayStepTrigger {
        return this._triggerMin;
    }

    public getTriggerMax(): VirtualDisplayStepTrigger {
        return this._triggerMax;
    }

    public getArrowRotation(): number {
        return (this._arrow - 1) * 45;
    }

    public getArrowRotationOnMap(): number {
        if (this._arrowOnMap) return (this._arrowOnMap - 1) * 45;
        else return (this._arrow - 1) * 45;
    }

    public getShouldDisplayArrow(): boolean {
        return this._arrow !== 0;
    }

    public getShouldDisplayPicto(): boolean {
        return this._picto !== 0;
    }

    public getArrowPosition(): string {
        let arrowSize = this._arrowSize;

        if (arrowSize >= 30000) arrowSize -= 30000;
        if (arrowSize >= 20000) arrowSize -= 20000;
        if (arrowSize >= 10000) arrowSize -= 10000;

        if (arrowSize >= 2000) return 'right';
        if (arrowSize >= 1000) return 'left';
        return 'center';
    }

    getArrowPositionNumber(): number {
        const arrowPosition = this.getArrowPosition();

        if (arrowPosition === 'right') return 2000;
        if (arrowPosition === 'left') return 1000;
        return 0;
    }

    public getArrowAnimation(arrowOrientation: number = 0, isMirror: boolean = false): string | undefined {
        let arrowSize = this._arrowSize;

        if (arrowSize >= 30000) arrowSize -= 30000;

        if (arrowSize >= 20000) return 'arrow-blink';

        let totalArrowOrientation = this.getArrowRotation() + arrowOrientation;

        if (totalArrowOrientation > 315) {
            totalArrowOrientation -= 360;
        }

        if (isMirror) {
            totalArrowOrientation = 360 - totalArrowOrientation;
        }

        if (arrowSize >= 10000) {
            switch (totalArrowOrientation) {
                case 0:
                    return 'arrow-slide-in-top';
                case 45:
                    return 'arrow-slide-in-top-right';
                case 90:
                    return 'arrow-slide-in-right';
                case 135:
                    return 'arrow-slide-in-bottom-right';
                case 180:
                    return 'arrow-slide-in-bottom';
                case 225:
                    return 'arrow-slide-in-bottom-left';
                case 270:
                    return 'arrow-slide-in-left';
                case 315:
                    return 'arrow-slide-in-top-left';
            }
        }

        return undefined;
    }

    public getArrowAnimationOnMap(arrowOrientation: number = 0, isMirror: boolean = false): string | undefined {
        let arrowSize = this._arrowSize;

        if (arrowSize >= 30000) arrowSize -= 30000;

        if (arrowSize >= 20000) return 'arrow-blink';

        let totalArrowOrientation = this.getArrowRotationOnMap() + arrowOrientation;

        if (totalArrowOrientation > 315) {
            totalArrowOrientation -= 360;
        }

        if (isMirror) {
            totalArrowOrientation = 360 - totalArrowOrientation;
        }

        if (arrowSize >= 10000) {
            switch (totalArrowOrientation) {
                case 0:
                    return 'arrow-slide-in-top';
                case 45:
                    return 'arrow-slide-in-top-right';
                case 90:
                    return 'arrow-slide-in-right';
                case 135:
                    return 'arrow-slide-in-bottom-right';
                case 180:
                    return 'arrow-slide-in-bottom';
                case 225:
                    return 'arrow-slide-in-bottom-left';
                case 270:
                    return 'arrow-slide-in-left';
                case 315:
                    return 'arrow-slide-in-top-left';
            }
        }

        return undefined;
    }

    public getArrowAnimationNumber(): number {
        let arrowSize = this._arrowSize;

        if (arrowSize >= 30000) arrowSize = arrowSize - 30000;
        if (arrowSize >= 20000) return 20000;
        if (arrowSize >= 10000) return 10000;
        return 0;
    }

    getTextNumber = () => {
        return this._mode - 200;
    };

    getUserTextNumber = () => {
        return this._mode - 300;
    };

    getCounterNumber = () => {
        return this._mode - 100;
    };

    public updateVirtualDisplayStep(step_: VirtualDisplayStepInterface) {
        const {
            state,
            color,
            mode,
            textTransformation,
            transition,
            time,
            pictoSize,
            picto,
            arrowSize,
            arrow,
            arrowOnMap,
            stepOrder,
            triggerMin,
            triggerMax,
        } = step_;

        this._state = state;
        this._color = color;
        this._mode = mode;
        this._textTransformation = textTransformation;
        this._transition = transition;
        this._time = time;
        this._pictoSize = pictoSize;
        this._picto = picto;
        this._arrowSize = arrowSize;
        this._arrow = arrow;
        this._arrowOnMap = arrowOnMap;
        this._stepOrder = stepOrder;
        this._triggerMin.updateStepTrigger(triggerMin);
        this._triggerMax.updateStepTrigger(triggerMax);
    }

    public getVirtuaDisplayStepInformation(): VirtualDisplayStepInformationInterface {
        return {
            state: this.getState(),
            mode: this.getMode(),
            triggerMin: {
                action: this.getTriggerMin().getAction(),
                value: this.getTriggerMin().getValue(),
                actionNumber: this.getTriggerMin().getActionInformation().number,
                actionName: this.getTriggerMin().getActionInformation().name,
            },
            triggerMax: {
                action: this.getTriggerMax().getAction(),
                value: this.getTriggerMax().getValue(),
                actionNumber: this.getTriggerMax().getActionInformation().number,
                actionName: this.getTriggerMax().getActionInformation().name,
            },
        };
    }

    public getVirtualDisplayStep(): VirtualDisplayStepFullInterface {
        return {
            state: this.getState(),
            color: this.getColorHex(),
            mode: this.getMode(),
            textTransformation: this.getTextTransformation(),
            transition: this.getTransition(),
            time: this.getTime(),
            pictoSize: this.getPictoSize(),
            picto: this.getPicto(),
            pictoPosition: this.getPictoPositionNumber(),
            arrowSize: this.getArrowSize(),
            arrow: this.getArrow(),
            arrowOnMap: this.getArrowOnMap(),
            arrowRepeat: this.getArrowRepeat(),
            arrowPosition: this.getArrowPositionNumber(),
            arrowAnimation: this.getArrowAnimationNumber(),
            stepOrder: this.getStepOrder(),
            text: this.getTextNumber(),
            userText: this.getUserTextNumber(),
            triggerMin: this.getTriggerMin().getStepTrigger(),
            triggerMax: this.getTriggerMax().getStepTrigger(),
            isForcedStep: this.getIsForcedStep(),
            forceMode: this._forceMode,
        };
    }
}

export interface VirtualDisplayStepInterface {
    state: number;
    color: ColorInterface;
    mode: number;
    textTransformation: number;
    transition: number;
    time: number;
    pictoSize: number;
    picto: number;
    arrowSize: number;
    arrow: number;
    arrowOnMap: number;
    stepOrder: number;
    triggerMin: VirtualDisplayStepTriggerInterface;
    triggerMax: VirtualDisplayStepTriggerInterface;
    isForcedStep?: boolean;
    forceMode?: LPMatrixDisplayV2VirtualDisplayForceType;
}

export interface VirtualDisplayStepFullInterface {
    state: number;
    color: string;
    mode: number;
    textTransformation: number;
    transition: number;
    time: number;
    pictoSize: number;
    picto: number;
    pictoPosition: number;
    arrowSize: number;
    arrow: number;
    arrowOnMap: number;
    arrowPosition: number;
    arrowRepeat: boolean;
    arrowAnimation: number;
    stepOrder: number;
    text: number;
    userText: number;
    triggerMin: VirtualDisplayStepTriggerInterface;
    triggerMax: VirtualDisplayStepTriggerInterface;
    isForcedStep?: boolean;
    forceMode?: LPMatrixDisplayV2VirtualDisplayForceType;
}

export interface VirtualDisplayStepInformationInterface {
    state: number;
    mode: number;
    triggerMin: TriggerVirtualDisplayStepInformationInterface;
    triggerMax: TriggerVirtualDisplayStepInformationInterface;
}

interface TriggerVirtualDisplayStepInformationInterface {
    action: number;
    actionNumber: number;
    actionName: string;
    value: number;
}
