import { faCalendarAlt, faEye, faEyeSlash, faListAlt, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Control from 'react-leaflet-control';
import { Button, ButtonGroup, Tooltip, Whisper } from 'rsuite';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import ActivityLog from '../Components/ActivityLog';
import { EventListModal } from '../Modal/EventListModal';

type Props = {
    levelId: number;
    sensorStateDisplayed: boolean;
    toggleSensorState: Function;
    reloadCounters: Function;
};

type State = {
    showDrawer: boolean;
    showEventListModal: boolean;
};
export default class ActivityMap extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            showDrawer: false,
            showEventListModal: false,
        };

        this.openDrawer = this.openDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.handleCloseEventListModal = this.handleCloseEventListModal.bind(this);
        this.handleOpenEventListModal = this.handleOpenEventListModal.bind(this);
    }

    handleCloseEventListModal() {
        this.setState({ showEventListModal: false });
    }

    handleOpenEventListModal() {
        this.setState({ showEventListModal: true });
    }

    openDrawer() {
        this.setState({
            showDrawer: true,
        });
    }

    closeDrawer() {
        this.setState({
            showDrawer: false,
        });
    }

    render() {
        return (
            <>
                <EventListModal show={this.state.showEventListModal} onHide={this.handleCloseEventListModal} />

                <Control position="bottomright">
                    <ButtonGroup>
                        <SecuredFragment authorizedRoles={[rolesConstants.map.EDIT]}>
                            <Whisper
                                trigger="hover"
                                placement={'autoHorizontal'}
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="map.actions.openActivityLogs" />
                                    </Tooltip>
                                }>
                                <Button
                                    data-cy="map-openActivityLogs"
                                    className="button-map"
                                    appearance="ghost"
                                    size="md"
                                    onClick={this.openDrawer}>
                                    <FontAwesomeIcon icon={faListAlt} />
                                </Button>
                            </Whisper>
                        </SecuredFragment>

                        <SecuredFragment authorizedRoles={[rolesConstants.eventAction.VIEW_LIST]}>
                            <Whisper
                                trigger="hover"
                                placement={'autoHorizontal'}
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="map.actions.eventsList" />
                                    </Tooltip>
                                }>
                                <Button
                                    data-cy="map-eventsList"
                                    className="button-map"
                                    appearance="ghost"
                                    size="md"
                                    onClick={this.handleOpenEventListModal}>
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </Button>
                            </Whisper>
                        </SecuredFragment>

                        <SecuredFragment authorizedRoles={[rolesConstants.map.EDIT]}>
                            <Whisper
                                trigger="hover"
                                placement={'autoHorizontal'}
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="map.actions.hideSensorState" />
                                    </Tooltip>
                                }>
                                <Button
                                    data-cy="map-hideSensorState"
                                    className="button-map"
                                    appearance="ghost"
                                    size="md"
                                    onClick={() => this.props.toggleSensorState()}>
                                    {this.props.sensorStateDisplayed ? (
                                        <FontAwesomeIcon icon={faEye} />
                                    ) : (
                                        <FontAwesomeIcon icon={faEyeSlash} />
                                    )}
                                </Button>
                            </Whisper>

                            <Whisper
                                trigger="hover"
                                placement={'autoHorizontal'}
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="map.actions.reload" />
                                    </Tooltip>
                                }>
                                <Button
                                    data-cy="map-reload"
                                    className="button-map"
                                    appearance="ghost"
                                    size="md"
                                    onClick={() => this.props.reloadCounters()}>
                                    <FontAwesomeIcon icon={faRedo} />
                                </Button>
                            </Whisper>
                        </SecuredFragment>
                    </ButtonGroup>

                    {this.state.showDrawer && (
                        <ActivityLog
                            show={this.state.showDrawer}
                            close={this.closeDrawer}
                            levelId={this.props.levelId}
                        />
                    )}
                </Control>
            </>
        );
    }
}
