import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import L from 'leaflet';
import React, { Fragment } from 'react';
import ReactDOMServer from 'react-dom/server';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Marker } from 'react-leaflet';
import { FlexboxGrid, Loader } from 'rsuite';
import { DrawerInformationInterface } from '../..';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { rolesConstants } from '../../../../static/roles';
import electricLogo from '../../../../style/assets/display_logo/electric.png';
import familyLogo from '../../../../style/assets/display_logo/family.png';
import pmrLogo from '../../../../style/assets/display_logo/PMR.png';
import SecuredFragment from '../../../Auth/SecuredFragment';
import TCMDisplayPopup from './TCMDisplayPopup';

const arrow = [0, 270, 90, 0, 180, 315, 45, 225, 135];

const type = {
    1: {
        name: 'generic',
        image: undefined,
    },
    2: {
        name: 'pmr',
        image: pmrLogo,
    },
    4: {
        name: 'electrical',
        image: electricLogo,
    },
    8: {
        name: 'family',
        image: familyLogo,
    },
};

type Props = {
    display: Record<string, any>;
    key?: string;
    controllers: IpCan[];
    reloadDisplays: Function;
    editMode: boolean;
    placeTypes: Array<Record<string, any>>;
    type?: Record<string, any>;
    hover?: boolean;
    arrowRotation?: number;
    types?: Array<Record<string, any>>;
    arrow?: Record<string, any>;
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
    service: any;
} & WrappedComponentProps;

type State = {
    clickOpen: boolean;
    types;
    currentType: number;
};

// type States = {
//     forceModalOpen: boolean;
//     editModalOpen: boolean;
//     countersModalOpen: boolean;
//     editConfigTypeModalOpen: boolean;
//     configModalOpen: boolean;
// };

class TCMDisplayIcon extends React.PureComponent<Props, State> {
    displayTimer: ReturnType<typeof setTimeout> | undefined;

    constructor(props: Props) {
        super(props);

        let types: Array<Record<string, any>> = [];
        let v = this.props.display.tcmDisplay.type;

        if (v > 0) {
            for (let i = 1; i <= 8; i <<= 1) {
                if ((v & i) !== 0) {
                    types.push(type[i]);
                }
            }
        }

        this.state = {
            clickOpen: false,
            types,
            currentType: 0,
        };

        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.createDisplayIcon = this.createDisplayIcon.bind(this);
        // this.calculateRotation = this.calculateRotation.bind(this);
    }

    componentDidMount() {
        if (this.state.types.length >= 2 && this.props.display.tcmDisplay.online && !this.props.editMode) {
            this.displayTimer = setInterval(() => {
                if (!this.props.editMode) {
                    if (this.state.currentType === this.state.types.length - 1) {
                        this.setState({
                            currentType: 0,
                        });
                    } else {
                        this.setState({
                            currentType: this.state.currentType + 1,
                        });
                    }
                }
            }, 3000);
        }
    }

    componentWillUnmount() {
        if (
            this.state.types.length >= 2 &&
            this.props.display.tcmDisplay.online &&
            !this.props.editMode &&
            this.displayTimer
        ) {
            clearInterval(this.displayTimer);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.editMode !== prevProps.editMode) {
            if (this.props.editMode === true && this.displayTimer) {
                clearInterval(this.displayTimer);
            } else {
                this.displayTimer = setInterval(() => {
                    if (!this.props.editMode) {
                        if (this.state.currentType === this.state.types.length - 1) {
                            this.setState({
                                currentType: 0,
                            });
                        } else {
                            this.setState({
                                currentType: this.state.currentType + 1,
                            });
                        }
                    }
                }, 3000);
            }
        }

        if (prevProps.display.tcmDisplay.type !== this.props.display.tcmDisplay.type) {
            if (this.displayTimer) {
                clearInterval(this.displayTimer);
            }

            let v = this.props.display.tcmDisplay.type;
            let types: Array<Record<string, any>> = [];

            if (v > 0) {
                for (let i = 1; i <= 8; i <<= 1) {
                    if ((v & i) !== 0) {
                        types.push(type[i]);
                    }
                }
            }

            this.setState({
                types,
            });

            if (types.length >= 2 && this.props.display.tcmDisplay.online && !this.props.editMode) {
                this.displayTimer = setInterval(() => {
                    if (!this.props.editMode) {
                        if (this.state.currentType === types.length - 1) {
                            this.setState({
                                currentType: 0,
                            });
                        } else {
                            this.setState({
                                currentType: this.state.currentType + 1,
                            });
                        }
                    }
                }, 3000);
            }
        }
    }

    onMouseOver(e) {
        if (!this.state.clickOpen) {
            e.target.openPopup();
        }
    }

    onMouseOut(e) {
        if (!this.state.clickOpen) {
            e.target.closePopup();
        }
    }

    openPopup(e) {
        if (!this.state.clickOpen) {
            e.target.openPopup();
        } else {
            e.target.closePopup();
        }

        this.setState({
            clickOpen: !this.state.clickOpen,
        });
    }

    // calculateRotation() {
    //     let mapArrowRotation = this.props.display.mapArrowRotation || 0;
    //     let rotation = arrow[this.props.display.tcmDisplay.arrow] + mapArrowRotation;

    //     if (rotation > 360) {
    //         rotation -= 360;
    //     }

    //     return rotation;
    // }

    createDisplayIcon() {
        const rotation = arrow[this.props.display.mapArrowRotation] || arrow[0];

        const arrowStyle = {
            transform: `rotate(${rotation}deg)`,
        };

        const display = this.props.display;

        const displayTypeImage =
            this.state.types.length > 0 &&
            this.state.types[this.state.currentType] &&
            this.state.types[this.state.currentType].image;

        const displayTypeValue =
            this.state.types.length > 0 && this.state.types[this.state.currentType] && display.tcmDisplay.displayValue;

        const freeValue = display.tcmDisplay.displayValue[this.state.types[this.state.currentType]?.name]?.free;

        let classes = '';

        if (display.tcmDisplay.eventForceType === 'tcm-display_force') {
            classes += 'event-force';
        } else if (display.tcmDisplay.isForce) {
            classes += 'forced';
        }

        return (
            <div className={`display ${classes}`}>
                <FlexboxGrid
                    align="bottom"
                    justify={
                        display.tcmDisplay.online && !this.props.editMode && display.tcmDisplay.forceType.forceType < 3
                            ? 'space-between'
                            : 'center'
                    }>
                    {display.tcmDisplay.eventForceType === 'tcm-display_force' && !this.props.editMode ? (
                        <Fragment>
                            <FlexboxGrid.Item>
                                {display.tcmDisplay.eventForceText === 0 && (
                                    <p>
                                        {this.props.intl.formatMessage({
                                            id: 'display.force.full',
                                        })}
                                    </p>
                                )}

                                {display.tcmDisplay.eventForceText === 65535 && (
                                    <p>
                                        {this.props.intl.formatMessage({
                                            id: 'display.force.close',
                                        })}
                                    </p>
                                )}

                                {display.tcmDisplay.eventForceText === 10000 && (
                                    <p>
                                        {this.props.intl.formatMessage({
                                            id: 'display.force.free',
                                        })}
                                    </p>
                                )}
                            </FlexboxGrid.Item>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {display.tcmDisplay.online && !this.props.editMode ? (
                                <Fragment>
                                    {display.tcmDisplay.isForce && display.tcmDisplay.forceType.forceType >= 3 ? (
                                        <FlexboxGrid.Item>
                                            {display.tcmDisplay.forceType.forceType === 3 && (
                                                <p>
                                                    {this.props.intl.formatMessage({
                                                        id: 'display.force.full',
                                                    })}
                                                </p>
                                            )}

                                            {display.tcmDisplay.forceType.forceType === 4 && (
                                                <p>
                                                    {this.props.intl.formatMessage({
                                                        id: 'display.force.close',
                                                    })}
                                                </p>
                                            )}

                                            {display.tcmDisplay.forceType.forceType === 5 && (
                                                <p>
                                                    {this.props.intl.formatMessage({
                                                        id: 'display.force.free',
                                                    })}
                                                </p>
                                            )}
                                        </FlexboxGrid.Item>
                                    ) : (
                                        <Fragment>
                                            {display.mapArrowRotation !== 0 && (rotation >= 180 || rotation === 0) && (
                                                <FlexboxGrid.Item className="margin-right-5">
                                                    <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                                                </FlexboxGrid.Item>
                                            )}

                                            <FlexboxGrid.Item>
                                                {displayTypeImage && (
                                                    <Fragment>
                                                        <img
                                                            style={{ backgroundColor: '#ffb600', width: 16 }}
                                                            src={this.state.types[this.state.currentType].image}
                                                            alt=""
                                                            className="margin-right-5"
                                                        />
                                                    </Fragment>
                                                )}
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item>
                                                {displayTypeValue ? (
                                                    <Fragment>
                                                        <p>{freeValue >= 0 ? freeValue : <Loader />}</p>
                                                    </Fragment>
                                                ) : (
                                                    <Loader />
                                                )}
                                            </FlexboxGrid.Item>

                                            {display.mapArrowRotation !== 0 && rotation < 180 && rotation !== 0 && (
                                                <FlexboxGrid.Item>
                                                    <FontAwesomeIcon icon={faArrowUp} style={arrowStyle} />
                                                </FlexboxGrid.Item>
                                            )}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : (
                                <FlexboxGrid.Item>
                                    <p className="text-center">ID: {display.tcmDisplay.deviceId}</p>
                                </FlexboxGrid.Item>
                            )}
                        </Fragment>
                    )}
                </FlexboxGrid>
            </div>
        );
    }

    render() {
        if (this.props.display.isShow) {
            return (
                <Marker
                    onclick={this.openPopup}
                    onmouseover={this.onMouseOver}
                    onmouseout={this.onMouseOut}
                    position={{
                        lat: this.props.display.geoJSON[0],
                        lng: this.props.display.geoJSON[1],
                    }}
                    name={`tcmDisplay-${this.props.display.id}`}
                    icon={L.divIcon({
                        className: 'tcm-display',
                        html: ReactDOMServer.renderToStaticMarkup(
                            // <DisplayIcon
                            //     onMouseOver={this.onMouseOver}
                            //     onMouseOut={this.onMouseOut}
                            //     onclick={this.openPopup}
                            //     display={this.props.display}
                            //     type={this.state.types[this.state.currentType]}
                            //     imageType={undefined}
                            //     editMode={this.props.editMode}
                            // />
                            this.createDisplayIcon()
                        ),
                    })}>
                    <TCMDisplayPopup
                        controllers={this.props.controllers}
                        display={this.props.display}
                        hover={!this.state.clickOpen}
                        reloadDisplays={this.props.reloadDisplays}
                        editMode={this.props.editMode}
                        types={this.state.types}
                        currentType={this.state.currentType}
                        arrowRotation={arrow[this.props.display.tcmDisplay.arrow]}
                        placeTypes={this.props.placeTypes}
                        drawerInformation={this.props.drawerInformation}
                        openDrawer={this.props.openDrawer}
                        closeDrawer={this.props.closeDrawer}
                        service={this.props.service}
                    />
                </Marker>
            );
        } else {
            return (
                <SecuredFragment authorizedRoles={[rolesConstants.TCMDisplay.VIEW_LIST]}>
                    <Marker
                        onclick={this.openPopup}
                        onmouseover={this.onMouseOver}
                        onmouseout={this.onMouseOut}
                        position={{
                            lat: this.props.display.geoJSON[0],
                            lng: this.props.display.geoJSON[1],
                        }}
                        name={`tcmDisplay-${this.props.display.id}`}
                        icon={L.divIcon({
                            className: 'tcm-display',
                            html: ReactDOMServer.renderToStaticMarkup(this.createDisplayIcon()),
                        })}>
                        <TCMDisplayPopup
                            controllers={this.props.controllers}
                            display={this.props.display}
                            hover={!this.state.clickOpen}
                            reloadDisplays={this.props.reloadDisplays}
                            editMode={this.props.editMode}
                            types={this.state.types}
                            currentType={this.state.currentType}
                            arrowRotation={arrow[this.props.display.tcmDisplay.arrow]}
                            placeTypes={this.props.placeTypes}
                            drawerInformation={this.props.drawerInformation}
                            openDrawer={this.props.openDrawer}
                            closeDrawer={this.props.closeDrawer}
                            service={this.props.service}
                        />
                    </Marker>
                </SecuredFragment>
            );
        }
    }
}

export default injectIntl(TCMDisplayIcon);
