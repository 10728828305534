import History from './History';

export default class ipCameraHistoryHandler extends History {
    _idIpCamera: number;
    _ipCameraLabel: string;

    constructor(historyObject) {
        super(historyObject);
        const { idIpCamera, ipCameraName } = historyObject;

        this._idIpCamera = idIpCamera;
        this._ipCameraLabel = ipCameraName;
    }

    public get idIpCamera() {
        return this._idIpCamera;
    }

    public get ipCameraLabel() {
        return this._ipCameraLabel;
    }

    getOption(): any {
        return this.option;
    }

    getHistory(): History_I {
        return {
            id: this.getId(),
            user: this.getUser(),
            createdAt: this.getCreatedAt(),
            createdBy: this.getCreatedBy(),
            desc: this.getDesc(),
            option: this.getOption(),
            idIpCamera: this.idIpCamera,
            ipCameraLabel: this.ipCameraLabel,
            disabled: this.shouldBeDisabled(),
        };
    }

    private shouldBeDisabled(): boolean {
        return true;
    }
}

interface History_I {
    id: number;
    user: string;
    createdAt: string;
    createdBy: string;
    desc: string;
    option: Record<string, any>;
    idIpCamera: number;
    ipCameraLabel: string;
    disabled: boolean;
}
