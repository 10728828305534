import { faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, FlexboxGrid, List, Loader, Modal, Panel } from 'rsuite';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { MapVehicleCounter } from '../../../../handlers/map/MapVehicleCounter';
import { VehicleCounterExtension } from '../../../../handlers/vehicleCounter/VehicleCounterExtension';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';
import CreateExtensionModal from './Extension/CreateExtensionModal';
import { GpioExtensionList } from './Extension/ExtensionElement/GpioExtensionList';
import { LPSensor1List } from './Extension/ExtensionElement/LPSensor1List';
import { LPSensor2List } from './Extension/ExtensionElement/LPSensor2List';
import { TCMSensor1List } from './Extension/ExtensionElement/TCMSensor1List';
import { TCMSensor2List } from './Extension/ExtensionElement/TCMSensor2List';

type Props = {
    show: boolean;
    onHide: Function;
    passCount: MapVehicleCounter;
    ipcans: IpCan[];
    passCountExtensionsIN: VehicleCounterExtension[];
    passCountExtensionsOUT: VehicleCounterExtension[];
    fetchExtensions: Function;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    showAddINExtension: boolean;
    showAddOUTExtension: boolean;
};

class PassCountExtensionsModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showAddINExtension: false,
            showAddOUTExtension: false,
        };
    }

    hideModal = () => {
        this.props.onHide();
    };

    hideCreateExtensionModal = shouldReload => {
        this.setState({
            showAddINExtension: false,
            showAddOUTExtension: false,
        });

        if (shouldReload) {
            this.props.fetchExtensions();
        }
    };

    openAddINExtensionModal = () => {
        this.setState({
            showAddINExtension: true,
        });
    };

    openAddOUTExtensionModal = () => {
        this.setState({
            showAddOUTExtension: true,
        });
    };

    render() {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={this.hideModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="map.passCount.extensions.list" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.isLoading ? (
                        <FlexboxGrid align="middle" justify="center">
                            <FlexboxGrid.Item>
                                <Loader size="md" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    ) : (
                        <Fragment>
                            <Panel
                                className="panel-small"
                                shaded
                                bordered
                                header={
                                    <PanelHeader
                                        title={this.props.intl.formatMessage({ id: 'passCount.extensions.title.in' })}
                                        tagValue={this.props.passCountExtensionsIN.length}
                                        buttons={[
                                            <SecuredFragment
                                                authorizedRoles={[rolesConstants.mapVehicleCounters.CREATE]}
                                                key="passCount-create-in">
                                                <Button
                                                    color="red"
                                                    size="sm"
                                                    onClick={() => this.openAddINExtensionModal()}
                                                    data-cy="map-passCount-addIN">
                                                    <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                                    <FormattedMessage id="extension.add.in" />
                                                </Button>
                                            </SecuredFragment>,
                                        ]}
                                    />
                                }
                                bodyFill>
                                <List hover>
                                    {this.props.passCountExtensionsIN.map(passCountExtension => {
                                        switch (passCountExtension.getType()) {
                                            case 'GPIO':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <GpioExtensionList
                                                            ipcans={this.props.ipcans}
                                                            extension={passCountExtension}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            case 'LP_SENSOR_1':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <LPSensor1List
                                                            extension={passCountExtension}
                                                            ipcans={this.props.ipcans}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            case 'TCM_SENSOR_1':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <TCMSensor1List
                                                            ipcans={this.props.ipcans}
                                                            extension={passCountExtension}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            case 'LP_SENSOR_2':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <LPSensor2List
                                                            extension={passCountExtension}
                                                            ipcans={this.props.ipcans}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            case 'TCM_SENSOR_2':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <TCMSensor2List
                                                            extension={passCountExtension}
                                                            ipcans={this.props.ipcans}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                </List>
                            </Panel>

                            <Panel
                                className="panel-small"
                                shaded
                                bordered
                                header={
                                    <PanelHeader
                                        title={this.props.intl.formatMessage({ id: 'passCount.extensions.title.out' })}
                                        tagValue={this.props.passCountExtensionsOUT.length}
                                        buttons={[
                                            <SecuredFragment
                                                authorizedRoles={[rolesConstants.mapVehicleCounters.CREATE]}
                                                key="passCount-create-out">
                                                <Button
                                                    data-cy="map-passCount-addOUT"
                                                    color="red"
                                                    size="sm"
                                                    onClick={() => this.openAddOUTExtensionModal()}>
                                                    <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                                    <FormattedMessage id="extension.add.out" />
                                                </Button>
                                            </SecuredFragment>,
                                        ]}
                                    />
                                }
                                bodyFill>
                                <List hover>
                                    {this.props.passCountExtensionsOUT.map(passCountExtension => {
                                        switch (passCountExtension.getType()) {
                                            case 'GPIO':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <GpioExtensionList
                                                            ipcans={this.props.ipcans}
                                                            extension={passCountExtension}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            case 'LP_SENSOR_1':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <LPSensor1List
                                                            extension={passCountExtension}
                                                            ipcans={this.props.ipcans}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            case 'TCM_SENSOR_1':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <TCMSensor1List
                                                            extension={passCountExtension}
                                                            ipcans={this.props.ipcans}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            case 'LP_SENSOR_2':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <LPSensor2List
                                                            extension={passCountExtension}
                                                            ipcans={this.props.ipcans}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            case 'TCM_SENSOR_2':
                                                return (
                                                    <List.Item key={passCountExtension.getId()} className="panel-list">
                                                        <TCMSensor2List
                                                            extension={passCountExtension}
                                                            ipcans={this.props.ipcans}
                                                            fetchExtensions={this.props.fetchExtensions}
                                                        />
                                                    </List.Item>
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                </List>
                            </Panel>

                            <div>
                                <CreateExtensionModal
                                    vehicleCounter={this.props.passCount.getVehicleCounter()}
                                    show={this.state.showAddINExtension}
                                    onHide={this.hideCreateExtensionModal}
                                    type={'IN'}
                                />

                                <CreateExtensionModal
                                    vehicleCounter={this.props.passCount.getVehicleCounter()}
                                    show={this.state.showAddOUTExtension}
                                    onHide={this.hideCreateExtensionModal}
                                    type={'OUT'}
                                />
                            </div>
                        </Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <Button data-cy="map-passCount-validExtensions" color="green" onClick={this.hideModal}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(PassCountExtensionsModal);
