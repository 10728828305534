import { IntlShape } from 'react-intl';
import { PlaceCameraState } from '../../../handlers/Camera/PlaceCamera';

type SelectNumberValue = {
    label: string;
    value: number;
};

export const generatePlaceCameraForceTypes = (intl: IntlShape): SelectNumberValue[] => [
    {
        label: intl.formatMessage({ id: 'camera.place.forceType.free' }),
        value: PlaceCameraState.FREE,
    },
    {
        label: intl.formatMessage({ id: 'camera.place.forceType.occupied' }),
        value: PlaceCameraState.OCCUPIED,
    },
    {
        label: intl.formatMessage({ id: 'camera.place.forceType.overstay' }),
        value: PlaceCameraState.OVERSTAY,
    },
    {
        label: intl.formatMessage({ id: 'camera.place.forceType.unknown' }),
        value: PlaceCameraState.UNKNOWN,
    },
];
