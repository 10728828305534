import { IntlShape } from 'react-intl';

export const TCMDisplayArrowFormValue = (intl: IntlShape) => {
    return [
        {
            label: intl.formatMessage({
                id: 'display.arrows.noArrow',
            }),
            value: 0,
            rotation: 0,
        },
        {
            label: intl.formatMessage({
                id: 'display.arrows.left',
            }),
            value: 1,
            rotation: 270,
        },
        {
            label: intl.formatMessage({
                id: 'display.arrows.right',
            }),
            value: 2,
            rotation: 90,
        },
        {
            label: intl.formatMessage({
                id: 'display.arrows.up',
            }),
            value: 3,
            rotation: 0,
        },
        {
            label: intl.formatMessage({
                id: 'display.arrows.down',
            }),
            value: 4,
            rotation: 180,
        },
        {
            label: intl.formatMessage({
                id: 'display.arrows.upLeft',
            }),
            value: 5,
            rotation: 315,
        },
        {
            label: intl.formatMessage({
                id: 'display.arrows.upRight',
            }),
            value: 6,
            rotation: 45,
        },
        {
            label: intl.formatMessage({
                id: 'display.arrows.downLeft',
            }),
            value: 7,
            rotation: 225,
        },
        {
            label: intl.formatMessage({
                id: 'display.arrows.downRight',
            }),
            value: 8,
            rotation: 135,
        },
    ];
};

export const LPDisplayArrowFormValue = () => {
    return [
        {
            label: '0°',
            value: 0,
        },
        {
            label: '45°',
            value: 1,
        },
        {
            label: '90°',
            value: 2,
        },
        {
            label: '135°',
            value: 3,
        },
        {
            label: '180°',
            value: 4,
        },
        {
            label: '225°',
            value: 5,
        },
        {
            label: '270°',
            value: 6,
        },
        {
            label: '315°',
            value: 7,
        },
    ];
};

export const DisplayTypeSelector = () => [
    {
        label: 'TCM',
        value: 'tcm',
    },
    {
        label: 'lpDisplayMatrixV2',
        value: 'lpDisplayMatrixV2',
    },
];

export const DisplayBusSelector = () => [
    {
        label: '1',
        value: 0,
    },
    {
        label: '2',
        value: 1,
    },
    {
        label: '3',
        value: 2,
    },
];
