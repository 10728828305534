import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup } from 'rsuite';
import { AdvancedConfigurationPart } from './AdvancedConfigurationPart';
import GeneralConfiguration from './General';

type Props = {};

type State = {
    advanced: boolean;
    configuration: boolean;
};

export default class Configuration extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            advanced: false,
            configuration: true,
        };

        this.onConfigurationClick = this.onConfigurationClick.bind(this);
        this.onAdvancedClick = this.onAdvancedClick.bind(this);
    }

    onConfigurationClick() {
        this.setState({
            advanced: false,
            configuration: true,
        });
    }

    onAdvancedClick() {
        this.setState({
            advanced: true,
            configuration: false,
        });
    }

    render() {
        return (
            // <Panel bordered shaded>
            <Fragment>
                <ButtonGroup className="margin-top-10 margin-bottom-10" justified>
                    <Button
                        data-cy="configuration-button-general"
                        onClick={this.onConfigurationClick}
                        appearance={this.state.configuration ? 'primary' : 'ghost'}>
                        <FormattedMessage id="configuration.general" />
                    </Button>
                    <Button
                        data-cy="configuration-button-advanced"
                        onClick={this.onAdvancedClick}
                        appearance={this.state.advanced ? 'primary' : 'ghost'}>
                        <FormattedMessage id="configuration.advanced" />
                    </Button>
                </ButtonGroup>

                {this.state.configuration && <GeneralConfiguration />}

                {this.state.advanced && <AdvancedConfigurationPart />}
            </Fragment>
            // </Panel>
        );
    }
}
