import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { Button, ButtonGroup, Col, FlexboxGrid, Loader, Tag } from 'rsuite';
import { IpCan } from '../../../../../../handlers/ipcan/IpCan';
import TCMSensor from '../../../../../../handlers/ipCanDevices/TCMSensor';
import { VehicleCounterExtension } from '../../../../../../handlers/vehicleCounter/VehicleCounterExtension';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';
import DeleteExtensionModal from '../DeleteExtensionModal';
import EditExtensionModal from '../EditExtensionModal';

type Props = {
    ipcans: IpCan[];
    extension: VehicleCounterExtension;
    fetchExtensions: Function;
};

type State = {
    isFetchinSensor: boolean;
    sensor?: TCMSensor;
    hasError: boolean;
    showDeleteModal: boolean;
    isDeleted: boolean;
    showEditModal: boolean;
};

export class TCMSensor1List extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isFetchinSensor: true,
            hasError: false,
            showDeleteModal: false,
            isDeleted: false,
            showEditModal: false,
        };
    }

    componentDidMount() {
        this.fetchSensor();
    }

    componentDidUpdate = (prevProps: Props) => {
        if (JSON.stringify(prevProps.extension) !== JSON.stringify(this.props.extension)) {
            this.fetchSensor();
        }
    };

    fetchSensor = () => {
        axiosService
            .getAxios()
            .get(`/devices/tcm-sensor/${this.props.extension.getTCMSensor1()?.id}`, { headers: authHeader() })
            .then(tcmSensorResponse => {
                this.setState({
                    sensor: new TCMSensor(tcmSensorResponse.data, tcmSensorResponse.data.ipcanmodule.id, null, null),
                });
            })
            .catch(() => {
                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isFetchinSensor: false,
                });
            });
    };

    hideDeleteModal = (isDeleted: boolean = false) => {
        this.setState({
            showDeleteModal: false,
            isDeleted,
        });

        if (isDeleted) {
            this.props.fetchExtensions();
        }
    };

    showDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
        });
    };

    hideEditModal = (isEdited: boolean = false) => {
        this.setState({
            showEditModal: false,
        });

        if (isEdited) {
            this.props.fetchExtensions();
        }
    };

    showEditModal = () => {
        this.setState({
            showEditModal: true,
        });
    };

    render() {
        if (this.state.isFetchinSensor) {
            return (
                <FlexboxGrid align="middle" justify="center">
                    <FlexboxGrid.Item>
                        <Loader />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            );
        }
        const ipcan = this.props.ipcans.find(ipcan => ipcan.id === this.state.sensor?.ipCanId);

        return (
            <Fragment>
                <DeleteExtensionModal
                    show={this.state.showDeleteModal}
                    onHide={this.hideDeleteModal}
                    extension={this.props.extension}
                />

                {this.state.showEditModal && (
                    <EditExtensionModal
                        show={this.state.showEditModal}
                        onHide={this.hideEditModal}
                        extension={this.props.extension}
                    />
                )}

                <FlexboxGrid>
                    <FlexboxGrid.Item
                        componentClass={Col}
                        xs={20}
                        style={{ textOverflow: 'clip', overflow: 'auto', whiteSpace: 'normal' }}>
                        <strong>{this.props.extension.getName()}</strong>
                        <Tag data-cy="map-passCount-showType" color="blue" className="margin-left-5">
                            {this.props.extension.getType()}
                        </Tag>
                        <span className="margin-left-5">
                            <strong>{this.props.extension.getType()}</strong>
                            <Tag data-cy="map-passCount-showName" className="margin-left-5" color="violet">
                                {`${ipcan?.getLabel()} - ${this.state.sensor!.bus + 1} - ${
                                    this.state.sensor!.deviceId
                                }`}
                            </Tag>
                        </span>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={4}>
                        <ButtonGroup size="sm">
                            <Button
                                data-cy="map-passCount-editTCMSensor1"
                                onClick={this.showEditModal}
                                appearance="primary"
                                loading={false}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button
                                data-cy="map-passCount-deleteTCMSensor1"
                                onClick={this.showDeleteModal}
                                color="red"
                                loading={false}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Fragment>
        );
    }
}
