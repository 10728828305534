import { parkingConstants } from '../constants';
import { parkingService } from '../services';
import { notificationActions } from './notification.actions';

export const parkingActions = {
    getAll,
    // getOne,
    create,
    remove,
    updateInformations,
    updateParkingImage,
    openViewDrawer,
    closeViewDrawer,
    openCreateModal,
    closeCreateModal,
    openDeleteModal,
    closeDeleteModal,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        parkingService.getAll().then(
            parkings => dispatch(success(parkings)),
            error => {
                dispatch(failure(error));
                dispatch(notificationActions.createNotification('error', 'parking.error.fetchAllparkingsError'));
            }
        );
    };

    function request() {
        return { type: parkingConstants.GET_ALL_REQUEST };
    }
    function success(parkings) {
        return { type: parkingConstants.GET_ALL_SUCCESS, parkings };
    }
    function failure(error) {
        return { type: parkingConstants.GET_ALL_FAILURE, error };
    }
}

// function getOne(id) {
//     return dispatch => {
//         dispatch(request());

//         parkingService.getById(id).then(
//             parking => dispatch(success(parking)),
//             error => {
//                 dispatch(failure(error));
//                 dispatch(
//                     notificationActions.createNotification(
//                         'error',
//                         'parking.error.fetchparkingError'
//                     )
//                 );
//             }
//         );
//     };

//     function request() {
//         return { type: parkingConstants.GETONE_REQUEST };
//     }
//     function success(parking) {
//         return { type: parkingConstants.GETONE_SUCCESS, parking };
//     }
//     function failure(error) {
//         return { type: parkingConstants.GETONE_FAILURE, error };
//     }
// }

function remove(id) {
    return dispatch => {
        dispatch(request(id));

        parkingService.remove(id).then(
            data => {
                dispatch(success(data));
                dispatch(notificationActions.createNotification('success', 'parking.success.removeparkingSuccess'));
            },
            error => {
                dispatch(failure(id, error));
                dispatch(notificationActions.createNotification('error', 'parking.error.removeparkingError'));
            }
        );
    };

    function request(id) {
        return { type: parkingConstants.DELETE_REQUEST, id };
    }
    function success(parking) {
        return { type: parkingConstants.DELETE_SUCCESS, parking };
    }
    function failure(id, error) {
        return { type: parkingConstants.DELETE_FAILURE, id, error };
    }
}

function create(name, description) {
    const crypto = window.crypto;
    var array = new Uint32Array(1);

    return dispatch => {
        let id = crypto.getRandomValues(array);
        let newparking = {
            id: id,
            name,
            description,
        };
        dispatch(request(newparking));

        parkingService.create(name, description).then(
            parking => {
                dispatch(success(parking, id));
                dispatch(notificationActions.createNotification('success', 'parking.success.createparkingSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'parking.error.createparkingError'));
            }
        );
    };

    function request(parking) {
        return { type: parkingConstants.CREATE_REQUEST, parking };
    }
    function success(parking, id) {
        return { type: parkingConstants.CREATE_SUCCESS, parking, id: id };
    }
    function failure(error, id) {
        return { type: parkingConstants.CREATE_FAILURE, error, id: id };
    }
}

function updateInformations(id, name, description) {
    return dispatch => {
        dispatch(request(id));

        parkingService.updateInformations(id, name, description).then(
            parking => {
                dispatch(success(parking));
                dispatch(notificationActions.createNotification('success', 'parking.success.updateparkingSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'parking.error.updateparkingError'));
            }
        );
    };

    function request(id) {
        return { type: parkingConstants.UPDATE_REQUEST, id };
    }
    function success(parking) {
        return { type: parkingConstants.UPDATE_SUCCESS, parking };
    }
    function failure(error, id) {
        return { type: parkingConstants.UPDATE_FAILURE, error, id };
    }
}

function updateParkingImage(id, image) {
    return dispatch => {
        dispatch(request(id));

        parkingService.updateParkingImage(id, image).then(
            parking => {
                dispatch(success(parking));
                dispatch(notificationActions.createNotification('success', 'parking.success.updateparkingSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'parking.error.updateparkingError'));
            }
        );
    };

    function request(id) {
        return { type: parkingConstants.UPDATE_REQUEST, id };
    }
    function success(parking) {
        return { type: parkingConstants.UPDATE_SUCCESS, parking };
    }
    function failure(error, id) {
        return { type: parkingConstants.UPDATE_FAILURE, error, id };
    }
}

function openViewDrawer(id) {
    return dispatch => {
        dispatch(request());

        parkingService.getById(id).then(
            parking => {
                dispatch(success(parking));
            },
            error => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: parkingConstants.OPEN_VIEW_DRAWER_REQUEST,
        };
    }

    function success(parking) {
        return {
            type: parkingConstants.OPEN_VIEW_DRAWER_SUCCESS,
            parking: parking,
        };
    }

    function failure(error) {
        return {
            type: parkingConstants.OPEN_VIEW_DRAWER_FAILURE,
            error,
        };
    }
}

function closeViewDrawer() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: parkingConstants.CLOSE_VIEW_DRAWER,
        };
    }
}

function openCreateModal() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: parkingConstants.OPEN_CREATE_MODAL,
        };
    }
}

function closeCreateModal() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: parkingConstants.CLOSE_CREATE_MODAL,
        };
    }
}

function openDeleteModal(id) {
    return dispatch => {
        dispatch(request());

        parkingService.getById(id).then(
            parking => {
                dispatch(success(parking));
            },
            error => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: parkingConstants.OPEN_DELETE_MODAL_REQUEST,
            id,
        };
    }

    function success(parking) {
        return {
            type: parkingConstants.OPEN_DELETE_MODAL_SUCCESS,
            parking,
        };
    }

    function failure(error) {
        return {
            type: parkingConstants.OPEN_DELETE_MODAL_FAILURE,
            error,
        };
    }
}

function closeDeleteModal() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: parkingConstants.CLOSE_DELETE_MODAL,
        };
    }
}
