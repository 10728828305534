import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';

export default class Gpio {
    input: { state: boolean; nbEvent: number; lastEvent: Date }[];
    output: { state: boolean; nbEvent: number; lastEvent: Date }[];
    constructor() {
        this.input = [];
        this.output = [];
    }

    setOutputState(index: number, value: { state: boolean; nbEvent: number; lastEvent: Date }, ipcanId: number) {
        this.output[index] = value;

        // TODO: Send POST request to API
        return axiosService.getAxios().put(
            '/ipcanmodules/cmdSetGPIO',
            {
                id: ipcanId,
                output: index,
                value: value,
            },
            { headers: authHeader() }
        );
    }

    setInputState(state) {
        this.input = state;
    }

    setAllGpio(gpio) {
        this.input = gpio.in;
        this.output = gpio.out;
    }

    getGpioState() {
        return {
            in: this.input,
            out: this.output,
        };
    }
}
