import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';

export default function SetGPIO(options) {
    const { output, value } = options;

    return (
        <Fragment>
            <FormattedMessage id="controller.history.setGPIO.options.output" />
            <Tag className="margin-left-5" color="violet">
                {output}
            </Tag>
            <span className="margin-left-5">-</span>
            <FontAwesomeIcon
                data-cy="historic-setGPIO"
                className="margin-left-5"
                icon={faCircle}
                color={value ? 'green' : 'red'}
                size="lg"
            />
        </Fragment>
    );
}
