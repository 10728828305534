import { IconProp, RotateProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Redirect } from 'react-router-dom';
import { FlexboxGrid, Panel } from 'rsuite';
import ProgressLine from 'rsuite/lib/Progress/ProgressLine';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import DisplayErrorTable from './DisplayErrorTable';
import IPCanErrorTable from './IPCanErrorTable';
import SensorErrorTable from './SensorErrorTable';

type Props = {
    element: string;
    icon: IconProp;
    iconRotation?: RotateProp;
};

type State = {
    element: Record<string, any>;
    percent: number;
    redirect: boolean;
    showErrors: boolean;
    loading: boolean;
};

const timeInterval = 15000;

export default class ElementCard extends React.Component<Props, State> {
    timer: ReturnType<typeof setTimeout> | undefined;
    constructor(props) {
        super(props);

        this.state = {
            element: {
                warnings: [],
            },
            percent: 0,
            redirect: false,
            showErrors: false,
            loading: true,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleShowError = this.handleShowError.bind(this);
        this.hideErrors = this.hideErrors.bind(this);
    }

    componentDidMount() {
        this.fetchInformation().then(() => {
            this.timer = setInterval(() => this.fetchInformation(), timeInterval);
        });
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    fetchInformation = () => {
        let url;

        if (this.props.element === 'ipcan') {
            url = '/ipcanmodules/states';
        } else {
            url = `/devices/states/${this.props.element}`;
        }

        return axiosService
            .getAxios()
            .get(url, { headers: authHeader() })
            .then(response => {
                this.setState({
                    loading: false,
                    element: response.data,
                    percent:
                        response.data.elements > 0
                            ? (parseInt(response.data.onlines) / parseInt(response.data.elements)) * 100
                            : 100,
                });
            })
            .catch(err => {
                console.error(err);
            });
    };

    handleClick() {
        this.setState({
            redirect: true,
        });
    }

    handleShowError(e) {
        e.stopPropagation();

        if (this.state.element.warnings.length > 0) {
            this.setState({
                showErrors: true,
            });
        }
    }

    hideErrors() {
        this.setState({
            showErrors: false,
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/ipCan" />;
        } else {
            return (
                <Loader show={this.state.loading}>
                    <Panel
                        onClick={this.handleClick}
                        className="hoverable status-card"
                        bodyFill
                        data-cy={`dashboard-${this.props.element}-card`}>
                        <h6>
                            <FormattedMessage id={`dashboard.${this.props.element}.title`} />
                        </h6>
                        <h4>{this.state.element.onlines}</h4>

                        <ProgressLine
                            percent={this.state.percent}
                            showInfo={false}
                            strokeWidth={4}
                            strokeColor="#06d6a0"
                            trailColor="#fb8b24"
                        />
                        <FlexboxGrid justify="space-between" onClick={this.handleShowError} className="zindex10">
                            <FlexboxGrid.Item>
                                <p className="text-muted padding-top-10 padding-bottom-10 padding-left-15">
                                    <FormattedMessage id={`dashboard.${this.props.element}.total`} />
                                    <span className="margin-left-5">{this.state.element.elements}</span>
                                </p>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                {this.state.element.warnings.length > 0 ? (
                                    <p className="padding-top-10 padding-bottom-10 padding-right-15">
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" color="#fb8b24" />
                                    </p>
                                ) : (
                                    <p className="padding-top-10 padding-bottom-10 padding-right-15">
                                        <FontAwesomeIcon icon={faCheckCircle} size="lg" color="#06d6a0" />
                                    </p>
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FontAwesomeIcon
                            // className={`${this.props.iconRotation ? 'rotated' : 'bg-icon'}`}
                            className="bg-icon"
                            icon={this.props.icon}
                            rotation={this.props.iconRotation}
                        />
                    </Panel>

                    {this.props.element === 'ipcan' && (
                        <IPCanErrorTable
                            warnings={this.state.element.warnings}
                            show={this.state.showErrors}
                            hideErrors={this.hideErrors}
                        />
                    )}

                    {this.props.element === 'displays' && (
                        <DisplayErrorTable
                            warnings={this.state.element.warnings}
                            show={this.state.showErrors}
                            hideErrors={this.hideErrors}
                        />
                    )}

                    {this.props.element === 'sensors' && (
                        <SensorErrorTable
                            warnings={this.state.element.warnings}
                            show={this.state.showErrors}
                            hideErrors={this.hideErrors}
                        />
                    )}
                    {/* </Panel> */}
                </Loader>
            );
        }
    }
}
