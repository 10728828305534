import PropTypes from 'prop-types';
import React from 'react';
import Types from '../Elements/Types';

export default function typeCell(type) {
    return (
        <div>
            <Types data-tag="allowRowEvents" value={type} />
        </div>
    );
}

typeCell.propTypes = {
    type: PropTypes.number,
};
