import { saveAs } from 'file-saver';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { Alert, Button, ControlLabel, DatePicker, FlexboxGrid, Form, FormControl, FormGroup, Modal } from 'rsuite';
import { MapVehicleCounter } from '../../../../handlers/map/MapVehicleCounter';
import { VehicleCounterStat } from '../../../../handlers/vehicleCounter/VehicleCounterStat';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

interface Props {
    passCount: MapVehicleCounter;
    show: boolean;
    onHide: () => void;
}

export const PassCountStatsModal = (props: Props) => {
    const intl = useIntl();

    const [stats, setStats] = useState<VehicleCounterStat[]>([]);
    const [startDate, setStartDate] = useState<Date>(moment().subtract(1, 'day').second(0).toDate());
    const [endDate, setEndDate] = useState<Date>(moment().second(0).toDate());

    const options = useMemo(() => {
        return {
            maintainAspectRatio: false,
            responsive: true,
            scaleBeginAtZero: true,
            scales: {
                yAxes: [
                    {
                        id: 'total',
                        type: 'linear',
                        position: 'right',
                        beginAtZero: true,
                    },
                    {
                        id: 'inOut',
                        type: 'linear',
                        position: 'left',
                        beginAtZero: true,
                    },
                ],
                xAxes: [
                    {
                        type: 'time',
                        unit: 'minutes',
                        distribution: 'series',
                        offset: true,
                        major: {
                            enabled: true,
                        },
                        bounds: 'ticks',
                        ticks: {
                            source: 'labels',
                            maxTicksLimit: 50,
                            autoSkip: true,
                        },
                        time: {
                            unitStepSize: 10,
                            displayFormats: {
                                millisecond: 'HH[h]mm',
                                second: 'HH[h]mm',
                                minute: 'HH[h]mm',
                                hour: 'HH[h]mm',
                                day: 'DD/MM/YY HH[h]mm',
                                week: 'DD/MM/YYYY HH[h]mm',
                                month: 'DD/MM/YYYY HH[h]mm',
                                quarter: 'DD/MM/YYYY HH[h]mm',
                                year: 'DD/MM/YYYY HH[h]mm',
                            },
                        },
                        gridLines: {
                            offset: true,
                            gridLines: {
                                offsetGridLines: true,
                            },
                            offsetGridLines: true,
                        },
                    },
                ],
            },
            legend: {
                display: true,
            },
            tooltips: {
                callbacks: {
                    title: (tooltipItem, data) => {
                        return moment(data.labels[tooltipItem[0].index]).format('DD/MM/YYYY HH:mm');
                    },
                },
                mode: 'index',
            },
        };
    }, [stats]);

    const data: {
        labels: Date[];
        datasets: any[];
    } = useMemo(() => {
        const labels: Date[] = stats.map(stat => moment(stat.createdAt).toDate());
        const datasets: any[] = [
            {
                label: intl.formatMessage({ id: 'vehicleCounter.statistics.in' }),
                data: stats.map(stat => stat.result.valueIn),
                type: 'bar',
                yAxisID: 'inOut',
                backgroundColor: 'rgba(54, 162, 235, 1)',
            },
            {
                label: intl.formatMessage({ id: 'vehicleCounter.statistics.out' }),
                data: stats.map(stat => stat.result.valueOut),
                type: 'bar',
                yAxisID: 'inOut',
                backgroundColor: 'rgba(255, 99, 132, 1)',
            },
            {
                label: intl.formatMessage({ id: 'vehicleCounter.statistics.inTotal' }),
                data: stats.map(stat => stat.result.valueInTot),
                type: 'line',
                backgroundColor: 'rgba(255, 206, 86, 1)',
                borderColor: 'rgba(255, 206, 86, 1)',
                yAxisID: 'total',
                fill: false,
            },
            {
                label: intl.formatMessage({ id: 'vehicleCounter.statistics.outTotal' }),
                data: stats.map(stat => stat.result.valueOutTot),
                type: 'line',
                yAxisID: 'total',
                backgroundColor: 'rgba(75, 192, 192, 1)',
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
            },
        ];

        return {
            labels,
            datasets,
        };
    }, [stats]);

    useEffect(() => {
        if (props.show) {
            const beforeDate = moment().second(0);
            const afterDate = moment().subtract(1, 'day').second(0);

            setStartDate(afterDate.toDate());
            setEndDate(beforeDate.toDate());

            fetchStats(beforeDate.toISOString(), afterDate.toISOString());
        }
    }, [props.passCount, props.show]);

    const fetchStats = (beforeDate: string, afterDate: string) => {
        axiosService
            .getAxios()
            .get<VehicleCounterStat[]>(
                `/statistics/vehicleCounter/${props.passCount.getId()}/Date/${afterDate};${beforeDate}`,
                {
                    headers: authHeader(),
                }
            )
            .then(statsResponse => {
                setStats(statsResponse.data);
            })
            .catch(err => {
                console.error(err);
                Alert.error(intl.formatMessage({ id: 'vehicleCounter.statistics.fetchError' }));
            });
    };

    const handleFormValid = () => {
        fetchStats(moment(endDate).toISOString(), moment(startDate).toISOString());
    };

    const handleExportStats = () => {
        let fileName = `${props.passCount.getLabel()}_${moment().format('DD-MM-YYYY_HH:mm:ss')}`;

        let csv = 'Date;In;Out;In Total;Out Total;Free;Occupied\r\n';

        stats.forEach(stat => {
            csv += `${moment(stat.createdAt).format('DD/MM/YYYY HH:mm')};${stat.result.valueIn};${
                stat.result.valueOut
            };${stat.result.valueInTot};${stat.result.valueOutTot};${stat.result.valueFree};${
                stat.result.valueOccupied
            }\r\n`;
        });

        const blob = new Blob([csv], { type: 'text/csv' });
        saveAs(blob, fileName);
    };

    const handleStartDateChange = (value: any) => {
        setStartDate(value);
    };

    const handleEndDateChange = (value: any) => {
        setEndDate(value);
    };

    return (
        <Modal backdrop="static" show={props.show} onHide={props.onHide} full>
            <Modal.Header>
                <Modal.Title>
                    {intl.formatMessage({ id: 'vehicleCounter.statistics.title' })} - {props.passCount.getLabel()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '80vh', overflow: 'none' }}>
                <Form fluid>
                    <FlexboxGrid align="bottom" style={{ gap: 12, flexWrap: 'nowrap', overflow: 'hidden' }}>
                        <FlexboxGrid.Item colspan={8}>
                            <FormGroup>
                                <ControlLabel data-cy="vehicleCounter-statistics-startDate">
                                    {intl.formatMessage({ id: 'vehicleCounter.statistics.startDate' })}
                                </ControlLabel>
                                <FormControl
                                    data-cy="passCounter-stats-startDate"
                                    accepter={DatePicker}
                                    cleanable={false}
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    format="DD/MM/YYYY HH:mm"
                                    ranges={[]}
                                    name="startDate"
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <FormGroup>
                                <ControlLabel data-cy="vehicleCounter-statistics-endDate">
                                    {intl.formatMessage({ id: 'vehicleCounter.statistics.endDate' })}
                                </ControlLabel>
                                <FormControl
                                    data-cy="passCounter-stats-endDate"
                                    accepter={DatePicker}
                                    cleanable={false}
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    format="DD/MM/YYYY HH:mm"
                                    ranges={[]}
                                    name="endDate"
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>
                            <Button
                                block
                                appearance="primary"
                                color="blue"
                                data-cy="passCounter-stats-fetch"
                                onClick={handleFormValid}>
                                {intl.formatMessage({ id: 'vehicleCounter.statistics.fetch' })}
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>
                            <Button
                                block
                                appearance="primary"
                                color="violet"
                                data-cy="passCounter-stats-export"
                                onClick={handleExportStats}>
                                {intl.formatMessage({ id: 'vehicleCounter.statistics.export' })}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>

                <div
                    style={{
                        width: 'calc(100% - 32px)',
                        height: 'calc(100% - 32px)',
                        marginLeft: 16,
                        marginRight: 16,
                    }}>
                    <Bar data={data} options={options} />
                </div>
            </Modal.Body>
        </Modal>
    );
};
