import { notificationConstants } from '../constants';

const initialState = {
    message: undefined,
    notificationType: undefined,
};

export function notification(state = initialState, action) {
    switch (action.type) {
        case notificationConstants.CREATE_NOTIFICATION:
            return {
                ...state,
                message: action.message,
                notificationType: action.notificationType,
                duration: action.duration,
            };
        case notificationConstants.REMOVE_NOTIFICATION:
            return { state: initialState };
        default:
            return state;
    }
}
