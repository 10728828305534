import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    ipcanId: number;
    onHide: Function;
    show: boolean;
} & WrappedComponentProps;

type State = {
    loading: boolean;
};
class ResetIpCanModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.reset = this.reset.bind(this);

        this.state = {
            loading: false,
        };
    }

    reset() {
        this.setState({
            loading: true,
        });

        axiosService
            .getAxios()
            .put('/ipcanmodules/cmdResetMemory', { id: this.props.ipcanId }, { headers: authHeader() })
            .then(() => {
                this.setState({
                    loading: false,
                });

                Alert.success(this.props.intl.formatMessage({ id: 'ipcan.actions.reset.success' }));

                this.props.onHide();
            })
            .catch(err => {
                console.error(err);

                Alert.error(this.props.intl.formatMessage({ id: 'ipcan.actions.reset.success' }));

                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCan.resetIpCan.title" />
                    </Modal.Title>
                    <Modal.Body>
                        <FormattedMessage id="ipCan.resetIpCan.message" />
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button data-cy="controller-cancel" onClick={() => this.props.onHide()} color="red">
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                data-cy="controller-valid"
                                onClick={this.reset}
                                appearance="primary"
                                loading={this.state.loading}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal.Header>
            </Modal>
        );
    }
}

export default injectIntl(ResetIpCanModal);
