export interface LPSensorFiltersValue_I {
    median: FiltersValue_I;
    filter: FiltersValue_I;
}

interface FiltersValue_I {
    freeToOccupied: number;
    occupiedToFree: number;
}

export interface LPSensorFilterConfiguration_I {
    filter: number; // 32 bits to break into 4*8 bits
    maskFree: number;
    maskOccupied: number;
    nbInterSalveMin: number;
    nbInterSalveMax: number;
    nbSalveMax: number;
    nbSalveMin: number;
    percent: number;
}

export class LPSensorFilterConfiguration {
    private _nbSalveMin: number;
    private _nbSalveMax: number;
    private _nbInterSalveMin: number;
    private _nbInterSalveMax: number;
    private _filter: number;
    private _maskFree: number;
    private _maskOccupied: number;
    private _percent: number;

    constructor({
        nbSalveMin,
        nbSalveMax,
        nbInterSalveMin,
        nbInterSalveMax,
        filter,
        maskFree,
        maskOccupied,
        percent,
    }: LPSensorFilterConfiguration_I) {
        this._nbSalveMin = nbSalveMin;
        this._nbSalveMax = nbSalveMax;
        this._nbInterSalveMin = nbInterSalveMin;
        this._nbInterSalveMax = nbInterSalveMax;
        this._filter = filter;
        this._maskFree = maskFree;
        this._maskOccupied = maskOccupied;
        this._percent = percent;
    }

    public get nbSalveMin(): number {
        return this._nbSalveMin;
    }

    public setNbSalveMin(_nbSalveMin: number): boolean {
        if (_nbSalveMin !== this.nbSalveMin) {
            this._nbSalveMin = _nbSalveMin;
            return true;
        }
        return false;
    }

    public get nbSalveMax(): number {
        return this._nbSalveMax;
    }

    public setNbSalveMax(_nbSalveMax: number): boolean {
        if (_nbSalveMax !== this.nbSalveMax) {
            if (this.nbSalveMin > _nbSalveMax) {
                _nbSalveMax = this.nbSalveMin;
            }
            this._nbSalveMax = _nbSalveMax;
            return true;
        }
        return false;
    }

    public get nbInterSalveMin(): number {
        return this._nbInterSalveMin;
    }

    public setNbInterSalveMin(_nbInterSalveMin: number): boolean {
        if (_nbInterSalveMin !== this.nbInterSalveMin) {
            this._nbInterSalveMin = _nbInterSalveMin;
            return true;
        }
        return false;
    }

    public get nbInterSalveMax(): number {
        return this._nbInterSalveMax;
    }

    public setNbInterSalveMax(_nbInterSalveMax: number): boolean {
        if (_nbInterSalveMax !== this._nbInterSalveMax) {
            if (this.nbInterSalveMin > _nbInterSalveMax) {
                _nbInterSalveMax = this.nbInterSalveMin;
            }
            this._nbInterSalveMax = _nbInterSalveMax;
            return true;
        }
        return false;
    }

    public get filter(): LPSensorFiltersValue_I {
        return {
            median: {
                freeToOccupied: (this._filter >> 8) & 0xff,
                occupiedToFree: (this._filter >> 24) & 0xff,
            },
            filter: {
                freeToOccupied: this._filter & 0xff,
                occupiedToFree: (this._filter >> 16) & 0xff,
            },
        };
    }

    public setFilter(_filter: number): boolean {
        this._filter = _filter;
        return false;
    }

    public get maskFree(): number {
        return this._maskFree;
    }

    public setMaskFree(_maskFree: number): boolean {
        if (this._maskFree !== _maskFree) {
            this._maskFree = _maskFree;
            return true;
        }
        return false;
    }

    public get maskOccupied(): number {
        return this._maskOccupied;
    }

    public setMaskOccupied(_maskOccupied: number): boolean {
        if (this._maskOccupied !== _maskOccupied) {
            this._maskOccupied = _maskOccupied;
            return true;
        }
        return false;
    }

    public get percent(): number {
        return this._percent;
    }

    public setPercent(_percent: number): boolean {
        if (this._percent !== _percent) {
            this._percent = _percent;
            return true;
        }
        return false;
    }

    public setFilterValues({
        nbSalveMin,
        nbSalveMax,
        nbInterSalveMin,
        nbInterSalveMax,
        filter,
        maskFree,
        maskOccupied,
        percent,
    }: LPSensorFilterConfiguration_I) {
        let result = false;

        if (this.setNbSalveMin(nbSalveMin)) result = true;
        if (this.setNbSalveMax(nbSalveMax)) result = true;
        if (this.setNbInterSalveMin(nbInterSalveMin)) result = true;
        if (this.setNbInterSalveMax(nbInterSalveMax)) result = true;
        if (this.setFilter(filter)) result = true;
        if (this.setMaskFree(maskFree)) result = true;
        if (this.setMaskOccupied(maskOccupied)) result = true;
        if (this.setPercent(percent)) result = true;

        return result;
    }
}
