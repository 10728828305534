import { IPCanHybrid } from './IpCanHybrid';
import IpCanLP from './IpCanLP';
import IpCanLPMatrixDisplayV2 from './IpCanLPMatrixDisplayV2';
import IPCanTcm from './IpCanTCM';

export function createIpCan(data) {
    if (data.version.includes('TCM')) {
        return new IPCanTcm(data);
    } else {
        const CHAR = '.';
        let count = 0;

        for (let i = 0; i < data.version.length; i++) {
            if (data.version.charAt(i) === CHAR) count++;
        }

        if (count === 1) return new IPCanHybrid(data);

        if (data.version.charAt(1) === '2') return new IpCanLPMatrixDisplayV2(data);

        return new IpCanLP(data);
    }
}

export function generateUsConfigHeight(height) {
    switch (height) {
        case 240:
            return 175;
        case 180:
            return 200;
        case 130:
            return 225;
        case 110:
            return 250;
        case 90:
            return 275;
        case 70:
            return 300;
        case 60:
            return 325;
        case 50:
            return 350;
        case 30:
            return 275;
        case 10:
            return 400;
        default:
            return 0;
    }
}

export function generateUsConfigSens(sens) {
    switch (sens) {
        case 255:
            return 'MIN';
        case 240:
            return 'LOW';
        case 150:
            return 'MEDIUM';
        case 70:
            return 'HIGH';
        case 0:
            return 'MAX';
        default:
            return null;
    }
}
