import {
    ArrowHeadType,
    EdgeProps,
    getBezierPath,
    getMarkerEnd,
    NodeComponentProps,
    Position,
} from 'react-flow-renderer';
import { LPMatrixDisplayChartStepToStepEdgeType } from '../LPMatrixDisplayChartStepToStepEdge';

type Props = NodeComponentProps<LPMatrixDisplayChartStepToStepEdgeType> & EdgeProps;

export const LPMatrixDisplayChartStepToStepEdgeComponent = (props: Props) => {
    const edgePath = getBezierPath({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        sourcePosition: props.sourcePosition,
        targetX: props.targetX,
        targetY: props.targetY,
        targetPosition: props.targetPosition,
    });

    const foreignObjectSize = 40;

    const markerEnd = getMarkerEnd(ArrowHeadType.Arrow, props.markerEndId);

    return (
        <>
            <path
                id={props.id}
                style={{ stroke: props.data.color, strokeWidth: 3 }}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />

            <foreignObject
                width={foreignObjectSize}
                height={25}
                x={props.sourceX + (props.sourcePosition === Position.Left ? -50 : 10)}
                y={props.sourceY - 15}
                className="text-center"
                requiredExtensions="http://www.w3.org/1999/xhtml">
                <div style={{ backgroundColor: 'white', fontWeight: 'bolder', borderRadius: 5, fontSize: 16 }}>
                    {`${props.data.time} s`}
                </div>
            </foreignObject>
        </>
    );
};
