import { LatLngExpression } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { ServiceOccupancySensorWSInterface } from '../../../handlers/Camera/PlaceCamera';
import { webSocketService } from '../../../redux/services';

export interface OccupancyBox {
    id: number;
    state: number;
    overstayTime: number;
    idPlace: number;
    history: number[];
    slot: LatLngExpression[];
    prior: LatLngExpression[];
    continuous_state: number;
    statePrediction?: number;
}

export function useOccupancySensorPlace(mac: number, isStreaming: boolean) {
    const [occupancySensorActive, setOccupancySensorActive] = React.useState(false);
    const [statePredictionActive, setStatePredictionActive] = React.useState(false);
    const [occupancyBoxes, setOccupancyBoxes] = useState<OccupancyBox[]>([]);

    useEffect(() => {
        webSocketService.joinRoom(`place-camera_occupancySensor_${mac}`);
    }, []);

    const toggleServiceOccupancySensor = () => {
        if (isStreaming && !occupancySensorActive) {
            webSocketService.onEvent('place-camera:occupancySensor', refOccupancySensor.current);
            setOccupancySensorActive(true);
        }

        if (occupancySensorActive) {
            webSocketService.offEvent('place-camera:occupancySensor', refOccupancySensor.current);
            setOccupancySensorActive(false);
        }
    };

    const handleOccupancySensor = (data: ServiceOccupancySensorWSInterface) => {
        if (data.target === mac) {
            const boxes: OccupancyBox[] = [];

            data.infos.forEach(info => {
                boxes.push({
                    id: info.id,
                    state: info.state,
                    overstayTime: info.overstayTime,
                    idPlace: info.idPlace,
                    history: info.history,
                    slot: info.slot.map(point => {
                        return { lat: point[1] * -1, lng: point[0] };
                    }),
                    continuous_state: info.continuous_state,
                    statePrediction: info.statePrediction,
                    prior: info.prior.map(point => {
                        return { lat: point[1] * -1, lng: point[0] };
                    }),
                });
            });

            setOccupancyBoxes(boxes);
        }
    };

    // HACK TO KEEP REFERENCE OF WEBSOCKET HANDLER
    const refOccupancySensor = React.useRef(handleOccupancySensor);

    const toggleStatePredictionActive = () => {
        setStatePredictionActive(!statePredictionActive);
    };

    return {
        occupancySensorActive,
        statePredictionActive,
        occupancyBoxes,
        toggleServiceOccupancySensor,
        toggleStatePredictionActive,
    };
}
