import { sceneSensorPlaceTypeConstants } from '../constants';

type State = {
    isLoading: boolean;
    scenesSensorPlaceTypeList: Array<Record<string, any>>;
    error: undefined | string;
    fetchedSensorPlaceType: Record<string, any>;
    sceneSensorPlaceTypeDrawer: undefined | Record<string, any>;
};

const initialState = {
    isLoading: false,
    scenesSensorPlaceTypeList: [],
    error: undefined,
    fetchedSensorPlaceType: {},
    sceneSensorPlaceTypeDrawer: undefined,
};

export function scenesSensorPlaceType(state: State = initialState, action) {
    switch (action.type) {
        case sceneSensorPlaceTypeConstants.GET_ALL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case sceneSensorPlaceTypeConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                error: undefined,
                scenesSensorPlaceTypeList: action.scenesSensorPlaceType.map(sceneSensorPlaceType => {
                    return sceneSensorPlaceType.map(scene => {
                        return { ...scene, isLoading: false };
                    });
                }),
                isLoading: false,
            };
        case sceneSensorPlaceTypeConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case sceneSensorPlaceTypeConstants.CREATE_REQUEST:
            state.scenesSensorPlaceTypeList[action.state - 1].push({ ...action.sceneSensorPlaceType, isLoading: true });
            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList,
                isLoading: true,
            };
        case sceneSensorPlaceTypeConstants.CREATE_SUCCESS:
            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList.map(sceneState => {
                    return sceneState.map(scene => {
                        if (scene.id === action.id) {
                            return { ...action.sceneSensorPlaceType, isLoading: false };
                        } else {
                            return scene;
                        }
                    });
                }),
                isLoading: false,
            };
        case sceneSensorPlaceTypeConstants.CREATE_FAILURE:
            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList.filter(
                    sceneSensorPlaceType => sceneSensorPlaceType.id !== action.id
                ),
                isLoading: false,
                error: action.error,
            };
        case sceneSensorPlaceTypeConstants.UPDATE_REQUEST:
            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList.map(sceneState => {
                    return sceneState.map(scene => {
                        if (scene.id === action.id) {
                            return { ...scene, isLoading: true };
                        } else {
                            return scene;
                        }
                    });
                }),
            };
        case sceneSensorPlaceTypeConstants.UPDATE_SUCCESS: {
            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList.map(sceneState => {
                    return sceneState.map(scene => {
                        if (scene.id === action.sceneSensorPlaceType.id) {
                            return { ...scene, ...action.sceneSensorPlaceType, isLoading: false };
                        } else {
                            return scene;
                        }
                    });
                }),
                sceneSensorPlaceTypeDrawer: { ...state.sceneSensorPlaceTypeDrawer, ...action.sceneSensorPlaceType },
                // TODO
            };
        }
        case sceneSensorPlaceTypeConstants.UPDATE_FAILURE:
            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList.map(sceneState => {
                    return sceneState.map(scene => {
                        if (scene.id === action.sceneSensorPlaceType.id) {
                            return { ...scene, isLoading: false };
                        } else {
                            return scene;
                        }
                    });
                }),
                error: action.error,
            };
        case sceneSensorPlaceTypeConstants.UPDATE_ORDER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case sceneSensorPlaceTypeConstants.UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList.map(scenes => {
                    if (scenes.length >= 1) {
                        if (scenes[0].state === action.scenesSensorPlaceType[0].state) {
                            return action.scenesSensorPlaceType;
                        } else {
                            return scenes;
                        }
                    } else {
                        return action.scenesSensorPlaceType;
                    }
                }),
                isLoading: false,
                // TODO
            };
        case sceneSensorPlaceTypeConstants.UPDATE_ORDER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case sceneSensorPlaceTypeConstants.DELETE_REQUEST:
            // add 'deleting:true' property to sceneSensorPlaceType being deleted
            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList.map(sceneState => {
                    return sceneState.map(scene => {
                        if (scene.id === action.id) {
                            return { ...scene, ...action.sceneSensorPlaceType, isLoading: true };
                        } else {
                            return scene;
                        }
                    });
                }),
            };
        case sceneSensorPlaceTypeConstants.DELETE_SUCCESS:
            // remove deleted sceneSensorPlaceType from state

            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList.map(sceneState => {
                    return sceneState.filter(sceneSensorPlaceType => sceneSensorPlaceType.id !== action.id);
                }),
            };
        case sceneSensorPlaceTypeConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to sceneSensorPlaceType
            return {
                ...state,
                scenesSensorPlaceTypeList: state.scenesSensorPlaceTypeList.map(sceneState => {
                    return sceneState.map(scene => {
                        if (scene.id === action.id) {
                            return { ...scene, ...action.sceneSensorPlaceType, isLoading: false };
                        } else {
                            return scene;
                        }
                    });
                }),
            };
        // case sceneSensorPlaceTypeConstants.GETONE_REQUEST:
        //     return {
        //         ...state,
        //         fetchedSensorPlaceType: {},
        //     };
        // case sceneSensorPlaceTypeConstants.GETONE_SUCCESS:
        //     return {
        //         ...state,
        //         fetchedSensorPlaceType: action.sceneSensorPlaceType,
        //     };
        // case sceneSensorPlaceTypeConstants.GETONE_FAILURE:
        //     return {
        //         ...state,
        //         error: action.error,
        //     };
        default:
            return state;
    }
}
