import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Whisper } from 'rsuite';

export default function getPreviewFinish() {
    return (
        <div data-cy="historic-icon-ipcamera-getPreviewFinish">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.ipcamera.getPreviewFinish" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faCamera} color="green" size="lg" />
            </Whisper>
        </div>
    );
}
