import React, { Fragment } from 'react';
import { Handle, NodeComponentProps, Position } from 'react-flow-renderer';
import { WrappedComponentProps } from 'react-intl';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { TopologyInterface } from '../../../../../handlers/lpDisplays/LPDisplayTopology';
import { ColoredPointsInterface } from '../../handlers/Nodes/TopologyFlowNode';
import PreviewTopologyPart from './PreviewTopologyPart';

type TopologyData = {
    label: string;
    display: LPDisplay;
    topology: TopologyInterface;
    topologiesTriggersColors: ColoredPointsInterface[];
    targetVirtualDisplayColor: ColoredPointsInterface;
    targetTriggerColor: ColoredPointsInterface;
};

type Props = NodeComponentProps<TopologyData> & WrappedComponentProps;

const TopologyNode = (props: Props) => {
    // const display: LPDisplay = props.data.display;
    // const topology: TopologyInterface = props.data.topology;
    // console.log(props.data);

    return (
        <Fragment>
            {/* <TopologyDrawer
                show={props.selected || false}
                display={display}
                label={props.data.label}
                topology={topology}
                topologyIndex={props.data.topologyIndex}
                deleteTopologyTriggerEdge={props.data.deleteTopologyTriggerEdge}
                generateElements={props.data.generateElements}
            /> */}
            <div className={`matrix-display-node ${props.selected && 'selected'}`}>
                <Handle
                    type="source"
                    position={Position.Bottom}
                    id={'source-topology-0'}
                    style={{
                        left: '20%',
                        background: props.data.topologiesTriggersColors[0]?.isConnected
                            ? props.data.topologiesTriggersColors[0].color
                            : '#555',
                    }}
                />
                <Handle
                    type="source"
                    position={Position.Bottom}
                    id={'source-topology-1'}
                    style={{
                        left: '40%',
                        background: props.data.topologiesTriggersColors[1]?.isConnected
                            ? props.data.topologiesTriggersColors[1].color
                            : '#555',
                    }}
                />
                <Handle
                    type="source"
                    position={Position.Bottom}
                    id={'source-topology-2'}
                    style={{
                        left: '60%',
                        background: props.data.topologiesTriggersColors[2]?.isConnected
                            ? props.data.topologiesTriggersColors[2].color
                            : '#555',
                    }}
                />
                <Handle
                    type="source"
                    position={Position.Bottom}
                    id={'source-topology-3'}
                    style={{
                        left: '80%',
                        background: props.data.topologiesTriggersColors[3]?.isConnected
                            ? props.data.topologiesTriggersColors[3].color
                            : '#555',
                    }}
                />

                <Handle
                    type="target"
                    position={Position.Right}
                    id={'target-virtualDisplay'}
                    style={{
                        background: props.data.targetVirtualDisplayColor.isConnected
                            ? props.data.targetVirtualDisplayColor.color
                            : '#555',
                    }}
                />
                <Handle
                    type="target"
                    position={Position.Top}
                    id={'target-topology'}
                    style={{
                        background: props.data.targetTriggerColor.isConnected
                            ? props.data.targetTriggerColor.color
                            : '#555',
                    }}
                />

                <h5 className="text-center margin-bottom-10">{props.data.label}</h5>

                <PreviewTopologyPart display={props.data.display} topology={props.data.topology} />
            </div>
        </Fragment>
    );
};

export default TopologyNode;
