import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Table } from 'rsuite';

const { Column, HeaderCell, Cell } = Table;

type Props = {
    hideErrors: Function;
    show: boolean;
    warnings: Array<Record<string, any>>;
};

type State = {};

export default class IPCanErrorTable extends React.Component<Props, State> {
    render() {
        return (
            <Modal
                backdrop="static"
                onHide={() => this.props.hideErrors()}
                show={this.props.show}
                size="lg"
                overflow={false}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="IPCanError.modal.title" />
                    </Modal.Title>
                    <Modal.Body>
                        <Table bordered autoHeight minHeight={160} virtualized size="sm" data={this.props.warnings}>
                            <Column align="center" flexGrow={1}>
                                <HeaderCell>Online</HeaderCell>
                                <Cell>
                                    {rowData => {
                                        if (rowData.ipcanmodule.online === 1) {
                                            return <FontAwesomeIcon icon={faPlug} style={{ color: 'green' }} />;
                                        } else if (rowData.ipcanmodule.online === 2) {
                                            return <FontAwesomeIcon icon={faPlug} style={{ color: 'orange' }} />;
                                        } else {
                                            return <FontAwesomeIcon icon={faPlug} style={{ color: 'red' }} />;
                                        }
                                    }}
                                </Cell>
                            </Column>
                            <Column align="center" flexGrow={1}>
                                <HeaderCell>Label</HeaderCell>
                                <Cell>
                                    {rowData => {
                                        return `${rowData.ipcanmodule.label}`;
                                    }}
                                </Cell>
                            </Column>
                            <Column align="center" flexGrow={1}>
                                <HeaderCell>MAC</HeaderCell>
                                <Cell>
                                    {rowData => {
                                        return `${rowData.ipcanmodule.macStr}`;
                                    }}
                                </Cell>
                            </Column>
                            <Column align="center" flexGrow={1}>
                                <HeaderCell>IP</HeaderCell>
                                <Cell>
                                    {rowData => {
                                        return `${rowData.ipcanmodule.ip}`;
                                    }}
                                </Cell>
                            </Column>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.hideErrors()} appearance="primary">
                            Valider
                        </Button>
                    </Modal.Footer>
                </Modal.Header>
            </Modal>
        );
    }
}
