type Props = {
    color: string;
};

export const DirIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} y="96" width="64" height="352" />
            <rect fill={color} y="96" width="160" height="62" />
            <rect fill={color} x="320" y="96" width="160" height="62" />
            <rect fill={color} x="320" y="256" width="160" height="64" />
            <rect fill={color} y="386" width="160" height="62" />
            <rect fill={color} x="128" y="128" width="64" height="288" />
            <rect fill={color} x="224" y="96" width="64" height="352" />
            <rect fill={color} x="320" y="96" width="64" height="352" />
            <rect fill={color} x="448" y="128" width="64" height="160" />
            <rect fill={color} x="320" y="288" width="128" height="64" />
            <rect fill={color} x="416" y="320" width="32" height="96" />
            <rect fill={color} x="448" y="352" width="32" height="96" />
            <rect fill={color} x="448" y="384" width="64" height="64" />
            <rect fill={color} x="416" y="352" width="64" height="64" />
        </g>
    </svg>
);
