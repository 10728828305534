import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Modal } from 'rsuite';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    handleSavePicto: () => void;
};

export const SavePictoModal = (props: Props) => {
    const handleClickSave = () => {
        props.handleSavePicto();
        props.onHide();
    };

    return (
        <Modal show={props.isOpen} onHide={() => props.onHide()}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="editPictoRGB.savePictoRGB.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="editPictoRGB.savePictoRGB.message" />
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={() => props.onHide()} color="red">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button onClick={() => handleClickSave()} type="submit" appearance="primary">
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
