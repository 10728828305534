import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function UpdateIpCanConfig(options) {
    return (
        <div>
            <div>
                <b>
                    <FormattedMessage id="history.updateIpCanConfig.label" />
                </b>{' '}
                : {options.label}
            </div>
            <div>
                <b>
                    <FormattedMessage id="history.updateIpCanConfig.ip" />
                </b>{' '}
                : {options.ip}
            </div>
            <div>
                <b>
                    <FormattedMessage id="history.updateIpCanConfig.macStr" />
                </b>{' '}
                : {options.macStr}
            </div>
        </div>
    );
}
