import React from 'react';

export default function saveHome(option) {
    return (
        <div data-cy="historic-ipcamera-option-saveHome">
            <span>
                ID: {option.id} - Preset: {option.presetNumber}
            </span>
        </div>
    );
}
