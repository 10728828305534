import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';
import { IPCameraAPIResponse } from '../../../../../../handlers/Camera/ApiCamera';
import Camera from '../../../../../../handlers/Camera/Camera';
import { CameraSensorMapInformation_I } from '../../../../../../handlers/map/MapCameraSensor';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';
import PanelHeader from '../../../../../Custom/PanelHeader';
import { MapPlaceCameraEditInformation } from './MapPlaceCameraEditInformation';
import { MapPlaceCameraViewInformation } from './MapPlaceCameraViewInformation';

type Props = {
    mapCameraSensor: CameraSensorMapInformation_I;
    placeTypes: any[];
    service: any;
};

export const MapPlaceCameraInformation = (props: Props) => {
    const [isEditMode, setIsEditMode] = React.useState(false);

    const intl = useIntl();

    const [cameras, setCameras] = React.useState<Camera[]>([]);

    useEffect(() => {
        axiosService
            .getAxios()
            .get<IPCameraAPIResponse[]>('/ip-cameras', {
                headers: authHeader(),
            })
            .then(response => {
                setCameras(response.data.map(res => new Camera(res)));
            });
    }, []);

    const currentPlaceType = props.placeTypes.find(
        pt => pt.id === props.mapCameraSensor.placeCamera.getSensorPlaceType()?.getId()
    );

    return (
        <Panel
            className="panel-small"
            shaded
            bordered
            bodyFill
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'map.cameraSensor.information' })}
                    buttons={[
                        <Button
                            key="map-cameraSensor-edit"
                            color="blue"
                            size="sm"
                            disabled={isEditMode}
                            onClick={() => setIsEditMode(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {isEditMode ? (
                <MapPlaceCameraEditInformation
                    mapCameraSensor={props.mapCameraSensor}
                    setViewMode={() => setIsEditMode(false)}
                    currentPlaceType={currentPlaceType}
                    cameras={cameras}
                    placeTypes={props.placeTypes}
                    service={props.service}
                />
            ) : (
                <MapPlaceCameraViewInformation
                    mapCameraSensor={props.mapCameraSensor}
                    camera={cameras.find(c => c.getId() === props.mapCameraSensor.idCamera)}
                    currentPlaceType={currentPlaceType}
                />
            )}
        </Panel>
    );
};
