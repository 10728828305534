type Props = {
    color: string;
};

export const CarPoolingIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} x="64" y="32" width="384" height="32" />
            <rect fill={color} x="64" y="32" width="32" height="256" />
            <rect fill={color} transform="scale(1,-1)" x="416" y="-288" width="32" height="256" />
            <rect fill={color} x="32" y="256" width="448" height="32" />
            <rect fill={color} x="64" y="224" width="384" height="32" />
            <rect fill={color} x="416" y="160" width="64" height="64" />
            <rect fill={color} x="32" y="160" width="64" height="64" />
            <g>
                <rect fill={color} x="160" y="96" width="32" height="96" />
                <rect fill={color} x="288" y="96" width="32" height="96" />
                <rect fill={color} x="352" y="96" width="32" height="96" />
            </g>
            <rect fill={color} x="128" y="128" width="96" height="32" />
            <rect fill={color} x="32" y="256" width="32" height="160" />
            <rect fill={color} x="448" y="256" width="32" height="160" />
            <rect fill={color} x="64" y="416" width="384" height="32" />
            <rect fill={color} x="384" y="416" width="64" height="96" />
            <rect fill={color} x="64" y="416" width="64" height="96" />
            <rect fill={color} x="96" y="320" width="64" height="64" />
            <rect fill={color} x="352" y="320" width="64" height="64" />
            <rect fill={color} x="192" y="352" width="128" height="32" />
        </g>
    </svg>
);
