type Props = {
    color: string;
};

export const CoveredParkIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} x="224" width="64" height="64" />
            <rect fill={color} x="192" y="32" width="128" height="32" />
            <g>
                <rect fill={color} x="192" y="32" width="32" height="64" />
                <rect fill={color} x="320" y="64" width="32" height="64" />
                <rect fill={color} x="160" y="64" width="32" height="64" />
                <rect fill={color} x="288" y="32" width="32" height="64" />
            </g>
            <g>
                <rect fill={color} x="160" y="64" width="64" height="32" />
                <rect fill={color} x="128" y="96" width="64" height="32" />
                <rect fill={color} x="288" y="64" width="64" height="32" />
                <rect fill={color} x="320" y="96" width="64" height="32" />
            </g>
            <rect fill={color} x="128" y="160" width="224" height="64" />
            <rect fill={color} x="128" y="320" width="224" height="64" />
            <rect fill={color} x="128" y="160" width="64" height="352" />
            <rect fill={color} x="320" y="193.1" width="64" height="160" />
        </g>
    </svg>
);
