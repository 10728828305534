import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import { RestFull } from '../../../../../../../handlers/profile/RestFull';
import { authHeader } from '../../../../../../../redux/helpers';
import { axiosService } from '../../../../../../../redux/services';

type Props = {
    open: boolean;
    onHide: (shouldReload) => void;
    restFull: RestFull;
};

export const DeleteRestFullModal = (props: Props) => {
    const intl = useIntl();

    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleValid = () => {
        setIsDeleting(true);

        axiosService
            .getAxios()
            .delete('/rest-full', {
                data: { id: props.restFull.getId() },
                headers: authHeader(),
            })
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'user.restFull.delete.success' }), 5000);
                props.onHide(true);
            })
            .catch(err => {
                console.error(err);
                Alert.error(intl.formatMessage({ id: 'user.restFull.delete.error' }), 5000);
            })
            .finally(() => {
                setIsDeleting(false);
            });
    };

    const handleCancel = () => {
        if (!isDeleting) props.onHide(false);
    };

    return (
        <Modal backdrop="static" show={props.open} onHide={handleCancel}>
            <Modal.Header>
                <Modal.Title>{intl.formatMessage({ id: 'user.restFull.delete.title' })}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="margin-bottom-10">
                    {props.restFull.getLabel()} - {props.restFull.getPath()}
                </p>
                <FormattedMessage id="user.restFull.delete.message" />
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={handleCancel}
                        data-cy="user-restFull-delete-cancel"
                        color="red"
                        disabled={isDeleting}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={handleValid}
                        data-cy="user-restFull-delete-valid"
                        appearance="primary"
                        loading={isDeleting}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
