import { faCheck, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Alert, Button, Col, FlexboxGrid, Input, List, Rate } from 'rsuite';
import Camera from '../../../../handlers/Camera/Camera';
import PlaceCamera from '../../../../handlers/Camera/PlaceCamera';
import { DeviceBase } from '../../../../handlers/ipCanDevices/DeviceBase';
import { MaintenanceState } from '../../../../handlers/MaintenanceInformation';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { MaintenanceStateInformationEditURL } from './deviceStateInformationGenerator';

type Props = {
    editMaintenanceStateUrl: MaintenanceStateInformationEditURL;
    device: DeviceBase | Camera | PlaceCamera;
    setIsEditMode: (isEditMode: boolean) => void;
};

export const EditDeviceStateInformation = (props: Props) => {
    const intl = useIntl();

    const [maintenanceState, setMaintenanceState] = React.useState<MaintenanceState>(
        props.device.getMaintenanceState()
    );
    const [notepad, setNotePad] = React.useState<string>(props.device.getNotepad());
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

    const editMaintenaceState = () => {
        setIsUpdating(true);

        axiosService
            .getAxios()
            .put(
                props.editMaintenanceStateUrl,
                {
                    id: props.device.getId(),
                    maintenanceState: maintenanceState,
                    notepad: notepad,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateMaintenanceStateResponse => {
                props.device.updateMaintenanceInformation(updateMaintenanceStateResponse.data);

                Alert.success(
                    intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState.edit.success' })
                );

                setIsUpdating(false);

                props.setIsEditMode(false);
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    intl.formatMessage({
                        id: 'ipCanDevices.maintenanceInformation.maintenanceState.edit.error',
                    })
                );

                setIsUpdating(false);
            });
    };

    const cancelEdit = () => {
        setMaintenanceState(props.device.getMaintenanceState());
        setNotePad(props.device.getNotepad());

        props.setIsEditMode(false);
    };

    return (
        <List hover>
            {/* MAINTENANCE STATE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                        {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState' })}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        <Rate
                            renderCharacter={renderCharacter}
                            readOnly={false}
                            cleanable={false}
                            defaultValue={maintenanceState}
                            onChange={value => setMaintenanceState(value)}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* INFORMATION NOTEPAD */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={24} className="bold">
                        {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.notepad' })}
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item
                        componentClass={Col}
                        xs={24}
                        className="margin-top-10"
                        data-cy="ipCanDevices-maintenanceInformation-notepad">
                        <Input
                            onChange={value => setNotePad(value)}
                            componentClass="textarea"
                            maxLength={400}
                            rows={3}
                            defaultValue={notepad}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* BUTTONS */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <Button block color="red" disabled={isUpdating} onClick={() => cancelEdit()}>
                            <FontAwesomeIcon icon={faTimes} className="margin-right-5" />
                            {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.cancel' })}
                        </Button>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <Button
                            block
                            color="green"
                            loading={isUpdating}
                            onClick={() => editMaintenaceState()}
                            data-cy="ipCanDevices-maintenanceInformation-valid">
                            <FontAwesomeIcon icon={faCheck} className="margin-right-5" />
                            {intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.valid' })}
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
};

const renderCharacter = (value: number, index: number) => {
    if (value < index + 1) return <FontAwesomeIcon icon={faExclamationCircle} />;
    if (value === MaintenanceState.EVERYTHING_OK) return <FontAwesomeIcon icon={faExclamationCircle} color="green" />;
    if (value === MaintenanceState.LOW) return <FontAwesomeIcon icon={faExclamationCircle} color="#FFDA00" />;
    if (value === MaintenanceState.MEDIUM) return <FontAwesomeIcon icon={faExclamationCircle} color="orange" />;
    if (value === MaintenanceState.HIGH) return <FontAwesomeIcon icon={faExclamationCircle} color="#FF5349" />;
    if (value === MaintenanceState.VERY_HIGH) return <FontAwesomeIcon icon={faExclamationCircle} color="red" />;
};
