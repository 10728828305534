import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Modal } from 'rsuite';
import { sensorPlaceTypeActions } from '../../../../redux/actions';

const mapDispatchToProps = dispatch => ({
    closeDeleteModal: () => dispatch(sensorPlaceTypeActions.closeDeleteModal()),
    delete: id => dispatch(sensorPlaceTypeActions.remove(id)),
});

type Props = {
    delete: Function;
    sensorPlaceTypeDrawer: Record<string, any>;
    closeDeleteModal: Function;
    delete_modal_openned: boolean;
    onDeleted: Function;
} & WrappedComponentProps;

type State = {
    isRemoving: boolean;
};

class DeleteSensorPlaceTypeModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isRemoving: false,
        };

        this.handleDelete = this.handleDelete.bind(this);
    }

    handleDelete() {
        this.setState({
            isRemoving: true,
        });
        this.props.delete(this.props.sensorPlaceTypeDrawer.id);
        this.props.closeDeleteModal();
    }

    render() {
        if (this.props.sensorPlaceTypeDrawer) {
            return (
                <Modal
                    size="xs"
                    show={this.props.delete_modal_openned}
                    onHide={() => this.props.closeDeleteModal()}
                    data-cy="sensorPlaceType-delete-modal">
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage id="sensorPlaceType.delete.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body data-cy="sensorPlaceType-delete-modal">
                        <FormattedMessage
                            id="sensorPlaceType.delete.message"
                            values={{ sensorPlaceType: this.props.sensorPlaceTypeDrawer.label }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button
                                onClick={() => this.props.closeDeleteModal()}
                                data-cy="delete-sensorPlaceType-cancel"
                                color="red"
                                loading={this.state.isRemoving}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                onClick={this.handleDelete}
                                data-cy="delete-sensorPlaceType-valid"
                                appearance="primary"
                                loading={this.state.isRemoving}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    let { delete_modal_openned, sensorPlaceTypeDrawer } = state.sensorPlaceTypes;

    return { delete_modal_openned, sensorPlaceTypeDrawer };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeleteSensorPlaceTypeModal));
