import { combineReducers } from 'redux';
import { app } from './app.reducer';
import { auth } from './auth.reducer';
import { ipcan } from './ipcan.reducer';
import { ipcanDevices } from './ipcanDevices.reducer';
import { notification } from './notification.reducer';
import { parkings } from './parking.reducer';
import { placeTypes } from './placeType.reducer';
import { scenesSensorPlaceType } from './scenesSensorPlaceType.reducer';
import { sensorPlaceTypes } from './sensorPlaceType.reducer';
import { users } from './user.reducer';
import { usersModals } from './usersModal.reducer';

const rootReducer = combineReducers({
    app,
    auth,
    users,
    usersModals,
    ipcan,
    notification,
    ipcanDevices,
    parkings,
    placeTypes,
    sensorPlaceTypes,
    scenesSensorPlaceType,
});

export default rootReducer;
