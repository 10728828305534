import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Message, Modal } from 'rsuite';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    lpDisplaysIds: number[];
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isDeleting: boolean;
    hasError: boolean;
    error?: any;
};

class DeleteLPDisplayModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isDeleting: false,
            hasError: false,
            error: undefined,
        };
    }

    hideModal = () => {
        if (!this.state.isDeleting) {
            this.props.onHide();
        }
    };

    deleteLPDisplay = () => {
        this.setState({
            isDeleting: true,
            hasError: false,
            error: undefined,
        });

        axiosService
            .getAxios()
            .delete('/devices/lp-matrixdisplays-v2', {
                headers: authHeader(),
                data: {
                    tabId: this.props.lpDisplaysIds,
                },
            })
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplays.delete.success' }));

                this.props.onHide(true);
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                });
            });
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.hideModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCanDevices.lpDisplays.delete.title" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.state.hasError && (
                        <Message
                            type="error"
                            description={this.props.intl.formatMessage({
                                id: 'ipCanDevices.lpDisplays.virtualDisplay.delete.error',
                            })}
                        />
                    )}

                    <FormattedMessage id="ipCanDevices.lpDisplays.delete.message" />
                </Modal.Body>

                <Modal.Footer>
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button data-cy="lpDisplays-delete-cancel" onClick={() => this.hideModal()} color="red" disabled={this.state.isDeleting}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    data-cy="lpDisplays-delete-valid"
                                    onClick={this.deleteLPDisplay}
                                    appearance="primary"
                                    loading={this.state.isDeleting}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(DeleteLPDisplayModal);
