import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, FlexboxGrid, List, Loader, Panel } from 'rsuite';
import { IPCameraAPIResponse } from '../../../../../handlers/Camera/ApiCamera';
import Camera from '../../../../../handlers/Camera/Camera';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';

type Props = {
    camera: Camera;
};

export const CameraPictureCard = (props: Props) => {
    const intl = useIntl();

    const [isTakingPicture, setIsTakingPicture] = React.useState(false);

    const [currentImage, setCurrentImage] = React.useState<string | null>(null);

    const takePicture = () => {
        setIsTakingPicture(true);

        axiosService
            .getAxios()
            .put(
                '/ip-cameras/preview',
                {
                    id: props.camera.getId(),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'ipCamera.picture.success' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'ipCamera.picture.error' }));
            });
    };

    useEffect(() => {
        axiosService
            .getAxios()
            .get<IPCameraAPIResponse>(`/ip-cameras/${props.camera.getId()}`, {
                headers: authHeader(),
            })
            .then(ipcamera => {
                if (ipcamera.data.image) {
                    props.camera.setImage(ipcamera.data.image.data);

                    setCurrentImage(Buffer.from(ipcamera.data.image, 'binary').toString('base64'));
                }
            });
    }, [props.camera.getId()]);

    useEffect(() => {
        webSocketService.onEvent('ip-camera:updatePreview', handleWebsocketUpdatePreview);

        return function cleanup() {
            webSocketService.offEvent('ip-camera:updatePreview', handleWebsocketUpdatePreview);
        };
    }, []);

    const handleWebsocketUpdatePreview = (data: IPCameraAPIResponse) => {
        if (data.id === props.camera.getId()) {
            props.camera.updateCameraWS(data);

            setCurrentImage(Buffer.from(data.image, 'binary').toString('base64'));

            setIsTakingPicture(false);
        }
    };

    return (
        <Panel
            className="panel-small"
            shaded
            bordered
            bodyFill
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'camera.latestImage' })}
                    buttons={[
                        <Button
                            key="camera-takePicture"
                            data-cy="camera-takePicture"
                            color="blue"
                            size="sm"
                            disabled={isTakingPicture || !props.camera.getOnline()}
                            onClick={takePicture}>
                            <FontAwesomeIcon icon={faCamera} />
                        </Button>,
                    ]}
                />
            }>
            {currentImage ? (
                <>
                    <List>
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item className="bold">
                                    <FormattedMessage id="camera.image.date" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>{props.camera.getImageDate()}</FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>{' '}
                    </List>

                    <img
                        alt={'camera-image'}
                        src={`data:image/${props.camera.getImageType()}; base64, ${currentImage}`}
                        width={550}
                        height={'100%'}
                    />
                </>
            ) : (
                <div style={{ minHeight: 80 }} className="padding-top-20 text-center">
                    <Loader size="md" content={intl.formatMessage({ id: 'camera.latestImage.loading' })} />
                </div>
            )}
        </Panel>
    );
};
