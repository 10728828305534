import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect } from 'react';
import ReactFlow, {
    Background,
    BackgroundVariant,
    Controls,
    Edge,
    Node,
    NodeTypesType,
    OnLoadParams,
} from 'react-flow-renderer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert, Button, Col, DatePicker, FlexboxGrid, Loader, Modal, SelectPicker } from 'rsuite';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import {
    LPDisplayForceTypeCountersInterface,
    LPDisplayForceTypeVirtualDisplaysInterface,
} from '../../../../../handlers/lpDisplays/LPDisplayForceType';
import { VirtualDisplayPreviewInformation } from '../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import { LPMatrixDisplayChartStepToStepEdgeComponent } from './FlowChart/Edges/Components/LPMatrixDisplayChartStepToStepEdgeComponent';
import { LPMatrixDisplayChartTopologyTriggerEdgeComponent } from './FlowChart/Edges/Components/LPMatrixDisplayChartTopologyTriggerEdgeComponent';
import { LPMatrixDisplayChartTopologyVirtualDisplayEdgeComponent } from './FlowChart/Edges/Components/LPMatrixDisplayChartTopologyVirtualDisplayEdgeComponent';
import { LPMatrixDisplayChartVirtualDisplayStepEdgeComponent } from './FlowChart/Edges/Components/LPMatrixDisplayChartVirtualDisplayStepEdgeComponent';
import { LPMatrixDisplayForceRole } from './FlowChart/LPMatrixDisplayChartElement';
import LPMatrixDisplayFactory, { ForceDataInterface } from './FlowChart/LPMatrixDisplayFactory';
import { ForceCounterModal } from './FlowChart/Modal/ForceCounterModal';
import { ForceVirtualDisplayModal } from './FlowChart/Modal/ForceVirtualDisplayModal';
import { LPMatrixDisplayChartStepNodeComponent } from './FlowChart/Nodes/Components/LPMatrixDisplayChartStepNodeComponent';
import { LPMatrixDisplayChartTopologyNodeComponent } from './FlowChart/Nodes/Components/LPMatrixDisplayChartTopologyNodeComponent';
import { LPMatrixDisplayChartVirtualDisplayNodeComponent } from './FlowChart/Nodes/Components/LPMatrixDisplayChartVirtualDisplayNodeComponent';
import { LPMatrixDisplayForceForm, LPMatrixDisplayForceType } from './Form/LPMatrixDisplayForm';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    display: LPDisplay;
};

export type ForceCounterModalInformation = {
    isOpen: boolean;
    step?: VirtualDisplayStep;
    forceMode?: LPDisplayForceTypeCountersInterface;
};

export type ForceVirtualDisplayModalInformation = {
    isOpen: boolean;
    virtualDisplay?: VirtualDisplayPreviewInformation;
    forceMode?: LPDisplayForceTypeVirtualDisplaysInterface;
};

const ForceLPMatrixDisplayModal = (props: Props) => {
    // Initialize translations
    const intl = useIntl();

    const [forceMode, setForceMode] = React.useState<LPMatrixDisplayForceType>(() => {
        if (props.display.isForce) {
            if (props.display.getForceType().getIsBlack()) return LPMatrixDisplayForceType.FORCE_BLACK;

            return LPMatrixDisplayForceType.FORCE;
        }

        return LPMatrixDisplayForceType.NONE;
    });

    const [endForceDate, setEndForceDate] = React.useState<Date>(() => {
        if (props.display.isForce) return props.display.endForceTime;

        return moment().add(1, 'year').toDate();
    });

    const [factory, setFactory] = React.useState<LPMatrixDisplayFactory | null>(null);

    const [reactFlowInstance, setReactFlowInstance] = React.useState<OnLoadParams | null>(null);

    const [forceCounterModalOpen, setForceCounterModalOpen] = React.useState<ForceCounterModalInformation>({
        isOpen: false,
    });

    const [
        forceVirtualDisplayModalOpen,
        setForceVirtualDisplayModalOpen,
    ] = React.useState<ForceVirtualDisplayModalInformation>({
        isOpen: false,
    });

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const nodeTypes: NodeTypesType = {
        forceTopologyNode: LPMatrixDisplayChartTopologyNodeComponent,
        forceVirtualDisplayNode: LPMatrixDisplayChartVirtualDisplayNodeComponent,
        forceVirtualDisplayStepNode: LPMatrixDisplayChartStepNodeComponent,
    };

    const edgeTypes: NodeTypesType = {
        forceVirtualDisplayTopologyVirtualDisplayNode: LPMatrixDisplayChartTopologyVirtualDisplayEdgeComponent,
        forceVirtualDisplayVirtualDisplayStepNode: LPMatrixDisplayChartVirtualDisplayStepEdgeComponent,
        forceVirtualDisplayStepToStepNode: LPMatrixDisplayChartStepToStepEdgeComponent,
        forceVirtualDisplayTopologyTriggerNode: LPMatrixDisplayChartTopologyTriggerEdgeComponent,
    };

    useEffect(() => {
        const forceData: ForceDataInterface = {
            counters: props.display.getForceType().getCounters(),
            topologyNum: props.display.getForceType().getTopology(),
            virtualDisplays: props.display.getForceType().getVirtualDisplays(),
        };

        setFactory(new LPMatrixDisplayFactory(props.display, forceData));

        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, [props.display]);

    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView();
        }
    }, [reactFlowInstance, props.isOpen]);

    const onLoad = (rfi: OnLoadParams) => {
        if (!reactFlowInstance) {
            setReactFlowInstance(rfi);
        }
    };

    const closeModal = () => {
        setForceMode(() => {
            if (props.display.isForce) {
                if (props.display.getForceType().getIsBlack()) return LPMatrixDisplayForceType.FORCE_BLACK;

                return LPMatrixDisplayForceType.FORCE;
            }

            return LPMatrixDisplayForceType.NONE;
        });

        setEndForceDate(() => {
            if (props.display.isForce) return props.display.endForceTime;

            return moment().add(1, 'year').toDate();
        });

        props.onHide();
    };

    const validForce = () => {
        if (factory) {
            const data = {
                id: props.display.id,
                isForce: forceMode !== LPMatrixDisplayForceType.NONE,
                startForceTime: new Date(),
                endForceTime: endForceDate,
                topologyNum: factory.getForceData().topologyNum,
                counters: factory.getForceData().counters,
                virtualDisplays: factory.getForceData().virtualDisplays,
                isBlack: forceMode === LPMatrixDisplayForceType.FORCE_BLACK,
            };

            if (forceMode === LPMatrixDisplayForceType.FORCE_BLACK || forceMode === LPMatrixDisplayForceType.NONE) {
                data.topologyNum = -1;
                data.counters = [];
                data.virtualDisplays = [];
            }

            axiosService
                .getAxios()
                .put('/devices/lp-matrixdisplays-v2/updateForceType', data, {
                    headers: authHeader(),
                })
                .then(() => {
                    Alert.success(intl.formatMessage({ id: 'lpMatrixDisplay.force.success' }));

                    props.onHide();
                });
        }
    };

    const onElementClick = (element: Node<any> | Edge<any>) => {
        if (element.type === 'forceTopologyNode') {
            if (element.data.isForce) {
                factory?.unsetTopologyForced();
            } else {
                factory?.setTopologyForced(element.data.index);
            }

            setForceCounterModalOpen({ isOpen: false });
        }

        if (element.type === 'forceVirtualDisplayStepNode') {
            if (element.data.step.getMode() >= 101 && element.data.step.getMode() <= 132) {
                setForceCounterModalOpen({ isOpen: true, step: element.data.step, forceMode: element.data.forceMode });
            }
        }

        if (element.type === 'forceVirtualDisplayNode') {
            setForceVirtualDisplayModalOpen({
                isOpen: true,
                virtualDisplay: element.data.virtualDisplay,
                forceMode: element.data.virtualDisplayForceData,
            });
        }
    };

    const authenticatedUser = useSelector((state: any) => state.auth.user);

    const role = (): LPMatrixDisplayForceRole => {
        const userIsAdmin = authenticatedUser?.roles.some(role => role.id === 1);

        const userHasAdvancedRole = authenticatedUser?.roles.some(
            role => role.section === 'LPMatrixDisplayV2' && role.name === 'ForceAdvanced'
        );

        const userHasNormalRole = authenticatedUser?.roles.some(
            role => role.section === 'LPMatrixDisplayV2' && role.name === 'ForceNormal'
        );

        const userHasBasicRole = authenticatedUser?.roles.some(
            role => role.section === 'LPMatrixDisplayV2' && role.name === 'ForceBasic'
        );

        if (userIsAdmin || userHasAdvancedRole) return LPMatrixDisplayForceRole.ROLE_ADVANCED;

        if (userHasNormalRole) return LPMatrixDisplayForceRole.ROLE_NORMAL;

        if (userHasBasicRole) return LPMatrixDisplayForceRole.ROLE_BASIC;

        return LPMatrixDisplayForceRole.NONE;
    };

    return (
        <Modal full show={props.isOpen} onHide={() => closeModal()} backdrop="static" overflow={false}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="map.lpMatrixDisplayV2.force.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {factory && forceCounterModalOpen.isOpen && (
                    <ForceCounterModal
                        modalInformation={forceCounterModalOpen}
                        onHide={() => setForceCounterModalOpen({ isOpen: false })}
                        factory={factory}
                    />
                )}

                {factory && forceVirtualDisplayModalOpen.isOpen && (
                    <ForceVirtualDisplayModal
                        modalInformation={forceVirtualDisplayModalOpen}
                        onHide={() => setForceVirtualDisplayModalOpen({ isOpen: false })}
                        factory={factory}
                        display={props.display}
                    />
                )}

                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item className="margin-right-5">
                        <SelectPicker
                            data={LPMatrixDisplayForceForm(intl)}
                            style={{ width: 300 }}
                            cleanable={false}
                            searchable={false}
                            value={forceMode}
                            onChange={(value: LPMatrixDisplayForceType) => setForceMode(value)}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="margin-left-5">
                        <DatePicker
                            format="DD/MM/YYYY HH:mm:ss"
                            ranges={[]}
                            style={{ width: 300 }}
                            value={endForceDate}
                            cleanable={false}
                            onChange={value => setEndForceDate(value)}
                            disabled={forceMode === LPMatrixDisplayForceType.NONE}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <>
                    {isLoading ? (
                        <Loader center backdrop style={{ marginTop: '50px', marginBottom: '30px' }} />
                    ) : (
                        <>
                            {forceMode === LPMatrixDisplayForceType.FORCE && (
                                <ReactFlow
                                    style={{ height: '70vh' }}
                                    defaultZoom={1}
                                    snapToGrid={true}
                                    snapGrid={[20, 20]}
                                    minZoom={-2}
                                    className="margin-top-10"
                                    elements={factory ? factory.generateElement(role()) : []}
                                    nodeTypes={nodeTypes}
                                    edgeTypes={edgeTypes}
                                    onLoad={onLoad}
                                    onlyRenderVisibleElements={true}
                                    onElementClick={(_, element) => onElementClick(element)}>
                                    <Controls />
                                    <Background variant={BackgroundVariant.Lines} gap={1} />
                                </ReactFlow>
                            )}
                        </>
                    )}
                </>
            </Modal.Body>
            <Modal.Footer>
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <Button block appearance="ghost" color="red" onClick={() => closeModal()}>
                            <FontAwesomeIcon icon={faTimesCircle} className="margin-right-5" />
                            <FormattedMessage id="map.lpMatrixDisplayV2.force.cancelAndClose" />
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <Button block color="green" onClick={() => validForce()}>
                            <FontAwesomeIcon icon={faSave} className="margin-right-5" />
                            <FormattedMessage id="map.lpMatrixDisplayV2.force.save" />
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    );
};

export default ForceLPMatrixDisplayModal;
