import { userHandler } from '../../handlers/user.handler';
import { authHeader, setAuthHeader } from '../helpers';
import { axiosService } from './axios.services';

export const authService = {
    login,
    logout,
};

/**
 * Send login request to API
 * @param {string} email
 * @param {string} password
 */
function login(email, password) {
    const parameters = {
        email: email,
        password: btoa(password),
    };

    return axiosService
        .getAxios()
        .post('/auth/login', parameters)
        .then(response => {
            const user = userHandler(response.data.user);

            return axiosService
                .getAxios()
                .get(`/users/${user.id}`, { headers: { Authorization: `Bearer ${response.data.token}` } })
                .then(user => {
                    setAuthHeader(response.data.token, JSON.stringify(userHandler(user.data)));

                    return userHandler(user.data);
                });
        })
        .catch(err => {
            // TODO: handle error
            console.error(err);
            return Promise.reject(err);
        });
}

function logout() {
    const config = {
        headers: authHeader(),
    };

    return axiosService
        .getAxios()
        .post('/auth/logout', {}, config)
        .then(response => {
            setAuthHeader(null, null);

            return response.data;
        })
        .catch(err => {
            // TODO: handle error
            setAuthHeader(null, null);

            return err;
        });
}
