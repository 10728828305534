import React, { Fragment, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, FlexboxGrid } from 'rsuite';
import { levelHandler } from '../../../../handlers/levelHandler';
import { authHeader } from '../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../redux/services';
import { CounterStatus } from '../../../CountersPage/ParkingPanel';

type Props = {
    levelId: number;
    levelsLength: number;
    counter?: CounterStatus;
};

type State = {
    level: Record<string, any>;
    free: Record<string, any>;
    occupied: Record<string, any>;
    loading: boolean;
    data: Record<string, any>;
};

export const ParkingLevel = (props: Props) => {
    const intl = useIntl();

    const [level, setLevel] = React.useState<Record<string, any>>({});
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [values, setValues] = React.useState<Record<string, any>>({
        free: {
            total: 0,
            percent: 0,
        },
        occupied: {
            total: 0,
            percent: 0,
        },
    });

    const donutSizes = React.useMemo(() => {
        let donutSize: string | number = '100%';
        let donutColSize = 12;

        if (props.levelsLength === 1) {
            donutSize = window.innerHeight * 0.2;
        } else if (props.levelsLength === 2) {
            donutSize = window.innerHeight * 0.2;
            donutColSize = 14;
        }

        return { donutSize, donutColSize };
    }, [props.levelsLength, window.innerHeight]);

    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/levels/${props.levelId}`, { headers: authHeader() })
            .then(response => {
                setLevel(levelHandler(response.data));

                setIsLoading(false);
            })
            .catch(err => {
                console.error(err);
            });
    }, [props.levelId]);

    useEffect(() => {
        const handleWebSocket = (data: any) => {
            if (data.name === level.name) {
                setValues({
                    free: {
                        total: data.counterValue.all.free,
                        percent:
                            (data.counterValue.all.free /
                                (data.counterValue.all.free + data.counterValue.all.occupied)) *
                            100,
                    },
                    occupied: {
                        total: data.counterValue.all.occupied,
                        percent:
                            (data.counterValue.all.occupied /
                                (data.counterValue.all.free + data.counterValue.all.occupied)) *
                            100,
                    },
                });
            }
        };

        webSocketService.joinRoom('counter');

        webSocketService.onEvent('counter:update', handleWebSocket);

        return () => {
            webSocketService.offEvent('counter:update', handleWebSocket);
        };
    }, [level]);

    const data = React.useMemo(() => {
        return {
            title: level.name,
            datasets: [
                {
                    data: [values.occupied.total, values.free.total],
                    backgroundColor: ['#f44336', '#008000'],
                },
            ],
            labels: [
                intl.formatMessage({ id: 'parking.occupationByLevel.tooltip.occupied' }),
                intl.formatMessage({ id: 'parking.occupationByLevel.tooltip.free' }),
            ],
        };
    }, [intl, values]);

    return (
        <Fragment>
            <p className="parking-level-name margin-top-15 margin-bottom-10 padding-bottom-5">
                {level.name} <span className="parking-level-description"> - {level.description}</span>
            </p>
            <FlexboxGrid align="middle" justify="space-between" style={{ height: donutSizes.donutSize }}>
                <FlexboxGrid.Item
                    componentClass={Col}
                    colspan={12}
                    xs={donutSizes.donutColSize}
                    style={{ height: donutSizes.donutSize }}>
                    <Doughnut
                        data={data}
                        height={100}
                        // width={donutSize}
                        options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            aspectRatio: 1,
                            legend: {
                                display: false,
                            },
                        }}
                    />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} colspan={24} xs={24 - donutSizes.donutColSize}>
                    <div className="parking-legend-title occupied">
                        <FormattedMessage id="parking.occupationByLevel.legend.occupied" />
                    </div>
                    <div className="parking-legend-value">
                        {values.occupied.total}
                        <FormattedMessage id="parking.occupationByLevel.legend.occupied.value" />
                        <span className="margin-left-5 small occupied">
                            ({isNaN(values.occupied.percent) ? 0 : values.occupied.percent.toFixed(1)}%)
                        </span>
                    </div>

                    <div className="parking-legend-title margin-top-15 free">
                        <FormattedMessage id="parking.occupationByLevel.legend.free" />
                    </div>
                    <div className="parking-legend-value">
                        {values.free.total}
                        <FormattedMessage id="parking.occupationByLevel.legend.free.value" />
                        <span className="margin-left-5 small free">
                            ({isNaN(values.free.percent) ? 0 : values.free.percent.toFixed(1)}%)
                        </span>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Fragment>
    );
};
