import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Col, FlexboxGrid, Loader, Message, Panel, PanelGroup, Tag } from 'rsuite';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';
import { AutomaticUpdateChangelog } from '../Automatic/AutomaticUpdateChangelog';

type Props = {
    nextStep: Function;
    changelog_api: string;
    changelog_engine: string;
    changelog_dashboard: string;
    changelog_camera_sensor?: string;
    changelog_occupancy_sensor?: string;
    changelog_service_ml?: string;
} & WrappedComponentProps;

type State = {
    error: boolean;
    tab: string;
    loading: boolean;
    applicationTag: string;
    current_api_version: undefined;
    current_api_loading: boolean;
    current_engine_version: undefined;
    current_engine_loading: boolean;
    current_camera_sensor_version: undefined;
    current_camera_sensor_loading: boolean;
    current_occupancy_sensor_version: undefined;
    current_occupancy_sensor_loading: boolean;
    current_service_ml_version: undefined;
    current_service_ml_loading: boolean;
};

class Validate extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            tab: 'api',
            loading: false,
            error: false,
            applicationTag: '',
            current_api_version: undefined,
            current_api_loading: true,
            current_engine_version: undefined,
            current_engine_loading: true,
            current_camera_sensor_version: undefined,
            current_camera_sensor_loading: true,
            current_occupancy_sensor_version: undefined,
            current_occupancy_sensor_loading: true,
            current_service_ml_version: undefined,
            current_service_ml_loading: true,
        };

        this.onSelect = this.onSelect.bind(this);
        this.applyUpdate = this.applyUpdate.bind(this);
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get('/configuration/2', { headers: authHeader() })
            .then(configurationResponse => {
                this.setState({ applicationTag: configurationResponse.data.value }, () => {
                    this.fetchApiVersion();
                    this.fetchEngineVersion();
                    this.fetchCameraSensorVersion();
                    this.fetchOccupancySensorVersion();
                    this.fetchServiceMLVersion();
                });
            });
    }

    onSelect(eventKey) {
        this.setState({
            tab: eventKey,
        });
    }

    applyUpdate() {
        this.setState({
            loading: true,
        });
        axiosService
            .getAxios()
            .put('/configuration/system/startUpdate', {}, { headers: authHeader() })
            .then(() => {
                this.setState({
                    loading: false,
                });

                this.props.nextStep();
            })
            .catch(() => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
    }

    fetchEngineVersion = () => {
        axiosService
            .getAxios()
            .get('/configuration/status/engine', { headers: authHeader() })
            .then(engineVersionResponse => {
                this.setState({ current_engine_version: engineVersionResponse.data.version });
            })
            .finally(() => {
                this.setState({ current_engine_loading: false });
            });
    };

    fetchApiVersion = () => {
        axiosService
            .getAxios()
            .get('/configuration/status/api', { headers: authHeader() })
            .then(apiVersionResponse => {
                this.setState({ current_api_version: apiVersionResponse.data.version });
            })
            .finally(() => {
                this.setState({ current_api_loading: false });
            });
    };

    fetchCameraSensorVersion = () => {
        axiosService
            .getAxios()
            .get('/cameras/status/camerasSensor', { headers: authHeader() })
            .then(apiVersionResponse => {
                this.setState({ current_camera_sensor_version: apiVersionResponse.data.app_version });
            })
            .finally(() => {
                this.setState({ current_camera_sensor_loading: false });
            });
    };

    fetchOccupancySensorVersion = () => {
        axiosService
            .getAxios()
            .get('/cameras/status/occupancySensor', { headers: authHeader() })
            .then(apiVersionResponse => {
                this.setState({ current_occupancy_sensor_version: apiVersionResponse.data.app_version });
            })
            .finally(() => {
                this.setState({ current_occupancy_sensor_loading: false });
            });
    };

    fetchServiceMLVersion = () => {
        axiosService
            .getAxios()
            .get('/cameras/status/serviceMl', { headers: authHeader() })
            .then(apiVersionResponse => {
                this.setState({ current_service_ml_version: apiVersionResponse.data.app_version });
            })
            .finally(() => {
                this.setState({ current_service_ml_loading: false });
            });
    };

    render() {
        return (
            <Fragment>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item componentClass={Col} className="margin-top-15">
                        <FormattedMessage id="update.manual.description" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                {this.state.error && (
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-top-15">
                            <Message title={<FormattedMessage id="update.manual.error" />} type="error" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
                <FlexboxGrid
                    justify="center"
                    style={{
                        position: 'sticky',
                        top: 20,
                        marginBottom: 20,
                        zIndex: 1100,
                        textAlign: 'center',
                    }}>
                    <FlexboxGrid.Item componentClass={Col} className="margin-top-15">
                        <Button color="orange" loading={this.state.loading} onClick={this.applyUpdate}>
                            <FontAwesomeIcon icon={faDownload} className="margin-right-10" />
                            <FormattedMessage id="update.manual.goToReboot" />
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <hr />
                <FlexboxGrid>
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <PanelGroup bordered accordion>
                            <Panel
                                className="panel-update"
                                header={
                                    <>
                                        <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                            {this.props.intl.formatMessage({
                                                id: 'update.automatic.changelog.api',
                                            })}
                                        </div>

                                        <Tag color="orange">
                                            {this.state.current_api_loading && <Loader size="xs" />}
                                            {this.state.current_api_version &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.current_version',
                                                })} - ${this.state.current_api_version}`}
                                        </Tag>
                                        <Tag color="green">
                                            {this.props.changelog_api &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.manual.injectedVersion',
                                                })} - ${this.props.changelog_api
                                                    ?.split('\n')[0]
                                                    .replaceAll('# Version ', '')}`}
                                        </Tag>
                                    </>
                                }>
                                <AutomaticUpdateChangelog
                                    title={this.props.intl.formatMessage({ id: 'update.automatic.changelog.api' })}
                                    changeLog={this.props.changelog_api}
                                    hasError={false}
                                    isLoading={false}
                                />
                            </Panel>

                            <Panel
                                className="panel-update"
                                header={
                                    <>
                                        <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                            {this.props.intl.formatMessage({
                                                id: 'update.automatic.changelog.engine',
                                            })}
                                        </div>

                                        <Tag color="orange">
                                            {this.state.current_engine_loading && <Loader size="xs" />}
                                            {this.state.current_engine_version &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.current_version',
                                                })} - ${this.state.current_engine_version}`}
                                        </Tag>
                                        <Tag color="green">
                                            {this.props.changelog_engine &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.manual.injectedVersion',
                                                })} - ${this.props.changelog_engine
                                                    ?.split('\n')[0]
                                                    .replaceAll('# Version ', '')}`}
                                        </Tag>
                                    </>
                                }>
                                <AutomaticUpdateChangelog
                                    title={this.props.intl.formatMessage({ id: 'update.automatic.changelog.engine' })}
                                    changeLog={this.props.changelog_engine}
                                    hasError={false}
                                    isLoading={false}
                                />
                            </Panel>

                            <Panel
                                className="panel-update"
                                header={
                                    <>
                                        <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                            {this.props.intl.formatMessage({
                                                id: 'update.automatic.changelog.dashboard',
                                            })}
                                        </div>

                                        <Tag color="orange">
                                            {this.state.current_engine_loading && <Loader size="xs" />}
                                            {this.state.current_engine_version &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.current_version',
                                                })} - ${process.env.REACT_APP_VERSION}`}
                                        </Tag>
                                        <Tag color="green">
                                            {this.props.changelog_dashboard &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.manual.injectedVersion',
                                                })} - ${this.props.changelog_dashboard
                                                    ?.split('\n')[0]
                                                    .replaceAll('# Version ', '')}`}
                                        </Tag>
                                    </>
                                }>
                                <AutomaticUpdateChangelog
                                    title={this.props.intl.formatMessage({
                                        id: 'update.automatic.changelog.dashboard',
                                    })}
                                    changeLog={this.props.changelog_dashboard}
                                    hasError={false}
                                    isLoading={false}
                                />
                            </Panel>

                            {this.props.changelog_camera_sensor && (
                                <Panel
                                    className="panel-update"
                                    header={
                                        <>
                                            <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                                {this.props.intl.formatMessage({
                                                    id: 'update.automatic.changelog.cameraSensor',
                                                })}
                                            </div>

                                            <Tag color="orange">
                                                {this.state.current_camera_sensor_loading && <Loader size="xs" />}
                                                {this.state.current_camera_sensor_version &&
                                                    `${this.props.intl.formatMessage({
                                                        id: 'update.automatic.current_version',
                                                    })} - ${this.state.current_camera_sensor_version}`}
                                            </Tag>
                                            <Tag color="green">
                                                {this.props.changelog_camera_sensor &&
                                                    `${this.props.intl.formatMessage({
                                                        id: 'update.manual.injectedVersion',
                                                    })} - ${this.props.changelog_camera_sensor
                                                        ?.split('\n')[0]
                                                        .replaceAll('# Version ', '')}`}
                                            </Tag>
                                        </>
                                    }>
                                    <AutomaticUpdateChangelog
                                        title={this.props.intl.formatMessage({
                                            id: 'update.automatic.changelog.dashboard',
                                        })}
                                        changeLog={this.props.changelog_camera_sensor}
                                        hasError={false}
                                        isLoading={false}
                                    />
                                </Panel>
                            )}

                            {this.props.changelog_occupancy_sensor && (
                                <Panel
                                    className="panel-update"
                                    header={
                                        <>
                                            <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                                {this.props.intl.formatMessage({
                                                    id: 'update.automatic.changelog.occupancySensor',
                                                })}
                                            </div>

                                            <Tag color="orange">
                                                {this.state.current_occupancy_sensor_loading && <Loader size="xs" />}
                                                {this.state.current_occupancy_sensor_version &&
                                                    `${this.props.intl.formatMessage({
                                                        id: 'update.automatic.current_version',
                                                    })} - ${this.state.current_occupancy_sensor_version}`}
                                            </Tag>
                                            <Tag color="green">
                                                {this.props.changelog_occupancy_sensor &&
                                                    `${this.props.intl.formatMessage({
                                                        id: 'update.manual.injectedVersion',
                                                    })} - ${this.props.changelog_occupancy_sensor
                                                        ?.split('\n')[0]
                                                        .replaceAll('# Version ', '')}`}
                                            </Tag>
                                        </>
                                    }>
                                    <AutomaticUpdateChangelog
                                        title={this.props.intl.formatMessage({
                                            id: 'update.automatic.changelog.dashboard',
                                        })}
                                        changeLog={this.props.changelog_camera_sensor}
                                        hasError={false}
                                        isLoading={false}
                                    />
                                </Panel>
                            )}

                            {this.props.changelog_service_ml && (
                                <Panel
                                    className="panel-update"
                                    header={
                                        <div>
                                            <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                                {this.props.intl.formatMessage({
                                                    id: 'update.automatic.changelog.serviceML',
                                                })}
                                            </div>

                                            <Tag color="orange">
                                                {this.state.current_service_ml_loading && <Loader size="xs" />}
                                                {this.state.current_service_ml_version &&
                                                    `${this.props.intl.formatMessage({
                                                        id: 'update.automatic.current_version',
                                                    })} - ${this.state.current_service_ml_version}`}
                                            </Tag>
                                            <Tag color="green">
                                                {this.props.changelog_service_ml &&
                                                    `${this.props.intl.formatMessage({
                                                        id: 'update.manual.injectedVersion',
                                                    })} - ${this.props.changelog_service_ml
                                                        ?.split('\n')[0]
                                                        .replaceAll('# Version ', '')}`}
                                            </Tag>
                                        </div>
                                    }>
                                    <AutomaticUpdateChangelog
                                        title={this.props.intl.formatMessage({
                                            id: 'update.automatic.changelog.dashboard',
                                        })}
                                        changeLog={this.props.changelog_service_ml}
                                        hasError={false}
                                        isLoading={false}
                                    />
                                </Panel>
                            )}
                        </PanelGroup>
                    </FlexboxGrid.Item>
                    {/* <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <h4 style={{ color: '#673ab7' }}>
                            <FormattedMessage id="update.manual.changelog.api" />
                        </h4>
                        <ReactMarkdown className="changelog" source={this.props.changelog_api} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <h4 style={{ color: '#673ab7' }}>
                            <FormattedMessage id="update.manual.changelog.engine" />
                        </h4>
                        <ReactMarkdown className="changelog" source={this.props.changelog_engine} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <h4 style={{ color: '#673ab7' }}>
                            <FormattedMessage id="update.manual.changelog.dashboard" />
                        </h4>
                        <ReactMarkdown className="changelog" source={this.props.changelog_dashboard} />
                    </FlexboxGrid.Item> */}
                </FlexboxGrid>
            </Fragment>
        );
    }
}

export default injectIntl(Validate);
