import { faChartArea } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Col, FlexboxGrid, Panel } from 'rsuite';
import { Parking } from '../../handlers/parking/Parking';
import { SensorPlaceType } from '../../handlers/sensorPlaceType/SensorPlaceType';
import noImage from '../../style/assets/eye-slash-solid.svg';
import PanelHeader from '../Custom/PanelHeader';
import { ParkingCounter } from './CountersPage';
import { ParkingStatDoughnut } from './Doughnut/ParkingStatDoughnut';
import { PlaceTypeDoughnut } from './Doughnut/PlaceTypeDoughnut';
import OccupationGraphModal from './Modal/OccupationGraphModal';
import OccupationGraph from './OccupationGraph';
import { PlaceTypeCounterValue } from './ParkingPanel';

type Props = {
    parking: Parking;
    sensorPlaceTypes: SensorPlaceType[];
    parkingCounter?: ParkingCounter;
};

export const ParkingWithOneLevel = (props: Props) => {
    const history = useHistory();
    const intl = useIntl();

    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);
    const [image, setImage] = useState<string | null>(null);

    useEffect(() => {
        const imageData = props.parking.getImage()
            ? Buffer.from(props.parking.getImage(), 'base64').toString('base64')
            : null;

        setImage(imageData);
    }, [props.parking]);

    const placeTypeCounterValue: PlaceTypeCounterValue[] = [];

    if (props.sensorPlaceTypes.length > 0 && props.parkingCounter) {
        Object.keys(props.parkingCounter.counterValue).forEach(placeTypeId => {
            if (placeTypeId !== 'all') {
                const currentSensorPlaceType = props.sensorPlaceTypes.find(
                    sensorPlaceType => sensorPlaceType.getId() === parseInt(placeTypeId)
                );

                if (currentSensorPlaceType) {
                    const currentPlaceType = currentSensorPlaceType.getPlaceType();

                    if (currentPlaceType) {
                        placeTypeCounterValue.push({
                            id: currentSensorPlaceType.getId(),
                            label: currentSensorPlaceType.getLabel(),
                            color: currentSensorPlaceType._scenes[0].color,
                            imageType: currentPlaceType.imageType,
                            image: currentSensorPlaceType.getPlaceType()?.generateImage(),
                            free: props.parkingCounter?.counterValue[placeTypeId].free || 0,
                            occupied: props.parkingCounter?.counterValue[placeTypeId].occupied || 0,
                        });
                    }
                }
            }
        });
    }

    const handleOpenOccupationGraph = (event: SyntheticEvent) => {
        event.stopPropagation();

        setHistoryModalOpen(true);
    };

    const parkingPlaceTypeHeight = props.parking.getLevels().length === 2 ? 130 : 100;

    let placeTypeSize = 6;
    let totalSize = 8;

    if (placeTypeCounterValue.length === 3) {
        placeTypeSize = 4;
        totalSize = 6;
    }

    if (placeTypeCounterValue.length === 4) {
        placeTypeSize = 3;
        totalSize = 6;
    }

    return (
        <Fragment>
            <FlexboxGrid align="middle" justify="center">
                <FlexboxGrid.Item componentClass={Col} xs={22}>
                    <Panel
                        className="hoverable bordered-panel"
                        onClick={() => history.push(`/map/${props.parking.getLevels()[0].getId()}`)}>
                        <FlexboxGrid align="middle" justify="space-between" style={{ height: 350 }}>
                            <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                                {image ? (
                                    <img
                                        src={`data:image/${props.parking.getImageType()};base64, ${image}`}
                                        style={{
                                            height: 'auto',
                                            width: 250,
                                            backgroundColor: 'white',
                                            lineHeight: 1,
                                        }}
                                        alt="Logo"
                                    />
                                ) : (
                                    <img
                                        src={noImage}
                                        style={{
                                            height: 'auto',
                                            width: 250,
                                            backgroundColor: 'white',
                                            lineHeight: 1,
                                        }}
                                        alt="Logo"
                                    />
                                )}
                            </FlexboxGrid.Item>

                            {/* TOTAL */}
                            <FlexboxGrid.Item className="text-center" componentClass={Col} xs={totalSize}>
                                <ParkingStatDoughnut
                                    title={props.parking.getName()}
                                    counterValue={props.parkingCounter!.counterValue.all}
                                    height={350}
                                />
                            </FlexboxGrid.Item>

                            {placeTypeCounterValue.map(placeTypeCounterValue => {
                                return (
                                    <Fragment key={placeTypeCounterValue.id}>
                                        <FlexboxGrid.Item className="margin-side-10">
                                            <PlaceTypeDoughnut
                                                placeTypeCounterValue={placeTypeCounterValue}
                                                height={parkingPlaceTypeHeight}
                                            />
                                        </FlexboxGrid.Item>
                                    </Fragment>
                                );
                            })}
                        </FlexboxGrid>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <FlexboxGrid align="middle" justify="center">
                <FlexboxGrid.Item componentClass={Col} xs={22}>
                    <Panel
                        className="panel-small"
                        shaded
                        bordered
                        header={
                            <PanelHeader
                                title={intl.formatMessage({
                                    id: 'occupationGraph.title',
                                })}
                                buttons={[
                                    <Button
                                        color="blue"
                                        onClick={handleOpenOccupationGraph}
                                        disabled={typeof props.parkingCounter === 'undefined'}>
                                        <FontAwesomeIcon icon={faChartArea} />
                                    </Button>,
                                ]}
                            />
                        }>
                        {props.parkingCounter && (
                            <OccupationGraph
                                height={350}
                                counterId={props.parkingCounter.id}
                                level={props.parking.getLevels()[0]}
                                displayLegend={true}
                                parking={props.parking}
                                responsive
                            />
                        )}
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            {props.parkingCounter && (
                <OccupationGraphModal
                    show={historyModalOpen}
                    onHide={() => setHistoryModalOpen(false)}
                    counterId={props.parkingCounter.id}
                    level={props.parking.getLevels()}
                    parking={props.parking}
                />
            )}
        </Fragment>
    );
};
