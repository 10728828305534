import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Button, Col, FlexboxGrid, Loader, Message, Panel } from 'rsuite';
import { LPSensor } from '../../handlers/ipCanDevices/LPSensor';
import TCMDisplay from '../../handlers/ipCanDevices/TCMDisplay';
import TCMSensor from '../../handlers/ipCanDevices/TCMSensor';
import { LPDisplay } from '../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import DisplaysForcedState from './Cards/DisplaysForcedState';
import DisplaysOnlineState from './Cards/DisplaysOnlineState';
import SensorsDetectionState from './Cards/SensorsDetectionState';
import SensorsForcedState from './Cards/SensorsForcedState';
import SensorsOnlineState from './Cards/SensorsOnlineState';
import DisplaysTable from './DisplaysTable';
import LPDisplaysTable from './LPDisplaysTable';
import LPSensorsTable from './LPSensorsTable';
import SensorsTable from './SensorsTable';

const UI_TIMEOUT = 250;

let uiTimeout;

type Props = {
    computedMatch: Record<string, any>;
};

type State = {
    ipCanId: number;
    busNumber: string;
    tcmDisplays: Array<TCMDisplay>;
    tcmSensors: Array<TCMSensor>;
    lpSensors: Array<LPSensor>;
    lpDisplays: Array<LPDisplay>;
    redirect: boolean;
    isLoading: boolean;
    hasError: boolean;
    reloadCount: number;
    ipcanLabel: string | undefined;
};

export default class IpCanElementsPage extends React.Component<Props, State> {
    putData: Function;
    updateElements: Function;
    constructor(props) {
        super(props);

        this.state = {
            ipCanId: parseInt(props.computedMatch.params.ipCanId),
            busNumber: props.computedMatch.params.busNumber,
            tcmDisplays: [],
            tcmSensors: [],
            lpSensors: [],
            lpDisplays: [],
            redirect: false,
            isLoading: true,
            hasError: false,
            reloadCount: 0,
            ipcanLabel: undefined,
        };

        this.putData = (url, data) => {
            return axiosService
                .getAxios()
                .put(url, data, { headers: authHeader() })
                .then(response => response.data);
        };

        this.updateElements = () => {
            if (!uiTimeout) {
                uiTimeout = setTimeout(() => {
                    this.setState(
                        {
                            reloadCount: this.state.reloadCount + 1,
                        },
                        () => {
                            uiTimeout = null;
                        }
                    );
                }, UI_TIMEOUT);
            }
        };
    }

    componentDidMount = () => {
        webSocketService.joinRoom('tcm-display');
        webSocketService.joinRoom('tcm-sensor');
        webSocketService.joinRoom('lp-sensor');
        webSocketService.joinRoom('lp-matrixDisplayV2');

        axiosService
            .getAxios()
            .get(`/ipcanmodules/devices/${this.state.ipCanId}/bus/${this.state.busNumber}`, { headers: authHeader() })
            .then(ipcanModuleDevicesResponse => {
                const ipcanModuleDevices = ipcanModuleDevicesResponse.data;

                this.setState({
                    tcmDisplays: ipcanModuleDevices.tcmDisplays
                        .filter(tcmDisplay => tcmDisplay.bus === parseInt(this.props.computedMatch.params.busNumber))
                        .map(tcmDisplay => {
                            return new TCMDisplay(tcmDisplay, this.state.ipCanId, this.putData, this.updateElements);
                        }),
                    tcmSensors: ipcanModuleDevices.tcmSensors
                        .filter(tcmSensor => tcmSensor.bus === parseInt(this.props.computedMatch.params.busNumber))
                        .map(tcmSensor => {
                            return new TCMSensor(tcmSensor, this.state.ipCanId, this.putData, this.updateElements);
                        }),
                    lpSensors: ipcanModuleDevices.lpSensors
                        .filter(lpSensor => lpSensor.bus === parseInt(this.props.computedMatch.params.busNumber))
                        .map(lpSensor => {
                            return new LPSensor(lpSensor, this.state.ipCanId, this.putData, this.updateElements);
                        }),
                    lpDisplays: ipcanModuleDevices.lpMatrixDisplayV2
                        .filter(lpDisplay => lpDisplay.bus === parseInt(this.props.computedMatch.params.busNumber))
                        .map(lpDisplay => {
                            return new LPDisplay(lpDisplay, this.state.ipCanId, this.updateElements);
                        }),
                    ipcanLabel: ipcanModuleDevices.label,
                });

                webSocketService.onEvent('tcm-display:updateStatus', this.updateTcmDisplay);
                webSocketService.onEvent('tcm-sensor:updateStatus', this.updateTcmSensor);
                webSocketService.onEvent('lp-sensor:updateStatus', this.updateLPSensor);
                webSocketService.onEvent('lp-matrixDisplayV2:updateStatus', this.updateLPDisplay);

                axiosService
                    .getAxios()
                    .get('/devices/tcm-display/status', { headers: authHeader() })
                    .catch(err => console.error(err));
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    componentWillUnmount = () => {
        webSocketService.offEvent('tcm-display:updateStatus', this.updateTcmDisplay);
        webSocketService.offEvent('tcm-sensor:updateStatus', this.updateTcmSensor);
        webSocketService.offEvent('lp-sensor:updateStatus', this.updateLPSensor);
        webSocketService.offEvent('lp-matrixDisplayV2:updateStatus', this.updateLPDisplay);
    };

    updateTcmDisplay = data => {
        let displays = this.state.tcmDisplays;

        const displayIndex = displays.findIndex(display => display.id === data.id);

        if (displayIndex !== -1) {
            displays[displayIndex].webSocketUpdate(data);
        }
    };

    updateTcmSensor = data => {
        let sensors = this.state.tcmSensors;

        const sensorIndex = sensors.findIndex(sensor => sensor.id === data.id);

        if (sensorIndex !== -1) {
            sensors[sensorIndex].webSocketUpdate(data);
        }
    };

    updateLPSensor = data => {
        let sensors = this.state.lpSensors;

        const sensorIndex = sensors.findIndex(sensor => sensor.id === data.id);

        if (sensorIndex !== -1) {
            sensors[sensorIndex].setWebsocketInformation(data);
        }
    };

    updateLPDisplay = data => {
        let displays = this.state.lpDisplays;

        const displayIndex = displays.findIndex(display => display.getId() === data.id);

        if (displayIndex !== -1) {
            displays[displayIndex].setWebsocketInformation(data);
        }
    };

    reloadDevices = () => {
        this.setState({
            isLoading: true,
        });

        axiosService
            .getAxios()
            .get(`/ipcanmodules/devices/${this.state.ipCanId}/bus/${this.state.busNumber}`, { headers: authHeader() })
            .then(ipcanModuleDevicesResponse => {
                const ipcanModuleDevices = ipcanModuleDevicesResponse.data;

                this.setState({
                    tcmDisplays: ipcanModuleDevices.tcmDisplays
                        .filter(tcmDisplay => tcmDisplay.bus === parseInt(this.props.computedMatch.params.busNumber))
                        .map(tcmDisplay => {
                            return new TCMDisplay(tcmDisplay, this.state.ipCanId, this.putData, this.updateElements);
                        }),
                    tcmSensors: ipcanModuleDevices.tcmSensors
                        .filter(tcmSensor => tcmSensor.bus === parseInt(this.props.computedMatch.params.busNumber))
                        .map(tcmSensor => {
                            return new TCMSensor(tcmSensor, this.state.ipCanId, this.putData, this.updateElements);
                        }),
                    lpSensors: ipcanModuleDevices.lpSensors
                        .filter(lpSensor => lpSensor.bus === parseInt(this.props.computedMatch.params.busNumber))
                        .map(lpSensor => {
                            return new LPSensor(lpSensor, this.state.ipCanId, this.putData, this.updateElements);
                        }),
                    lpDisplays: ipcanModuleDevices.lpMatrixDisplayV2
                        .filter(lpDisplay => lpDisplay.bus === parseInt(this.props.computedMatch.params.busNumber))
                        .map(lpDisplay => {
                            return new LPDisplay(lpDisplay, this.state.ipCanId, this.updateElements);
                        }),
                    ipcanLabel: ipcanModuleDevices.label,
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    render = () => {
        if (this.state.redirect) return <Redirect push to="/ipCan" />;

        if (this.state.hasError) {
            return (
                <Message
                    type="error"
                    description="Une erreur s'est produite durant la récupération des éléments du bus"
                />
            );
        }

        let totalNbElements =
            this.state.lpDisplays.length +
            this.state.lpSensors.length +
            this.state.tcmDisplays.length +
            this.state.tcmSensors.length;

        return (
            <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} xs={24}>
                    <Panel bordered>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item>
                                <h4 data-cy="labelHeader">
                                    {this.state.ipcanLabel ? this.state.ipcanLabel : <Loader />} -{' '}
                                    <FormattedMessage id="ipcanElement.bus" /> {parseInt(this.state.busNumber) + 1}
                                </h4>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <Button
                                    color="blue"
                                    onClick={() => this.setState({ redirect: true })}
                                    data-cy="returnButton">
                                    <FontAwesomeIcon icon={faAngleLeft} className="margin-right-10" />
                                    <FormattedMessage id="ipcanDevices.goBack" />
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                </FlexboxGrid.Item>

                {/* Notify user that no element exists in this bus */}
                {totalNbElements === 0 && !this.state.isLoading && (
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <Message
                            className="margin-top-10 margin-bottom-10"
                            type="warning"
                            description={<FormattedMessage id="ipcanDevices.noDevicesToDisplayWarning" />}
                        />
                    </FlexboxGrid.Item>
                )}

                {this.state.tcmDisplays.length > 0 && (
                    <Fragment>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <DisplaysOnlineState isLoading={this.state.isLoading} displays={this.state.tcmDisplays} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <DisplaysForcedState isLoading={this.state.isLoading} displays={this.state.tcmDisplays} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} xl={24} xs={24}>
                            <DisplaysTable
                                isLoading={this.state.isLoading}
                                displays={this.state.tcmDisplays}
                                reloadDevices={this.reloadDevices}
                                ipCanId={this.state.ipCanId}
                            />
                        </FlexboxGrid.Item>
                    </Fragment>
                )}

                {/* !!! TCM SENSORS !!! */}

                {this.state.tcmSensors.length > 0 && (
                    <Fragment>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <SensorsOnlineState isLoading={this.state.isLoading} sensors={this.state.tcmSensors} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <SensorsForcedState isLoading={this.state.isLoading} sensors={this.state.tcmSensors} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <SensorsDetectionState isLoading={this.state.isLoading} sensors={this.state.tcmSensors} />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} colspan={24} xl={24} xs={24}>
                            <SensorsTable
                                reloadDevices={this.reloadDevices}
                                isLoading={this.state.isLoading}
                                sensors={this.state.tcmSensors}
                                ipCanId={this.state.ipCanId}
                            />
                        </FlexboxGrid.Item>
                    </Fragment>
                )}

                {/* !!! LP SENSORS */}

                {this.state.lpSensors.length > 0 && (
                    <Fragment>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <SensorsOnlineState isLoading={this.state.isLoading} sensors={this.state.lpSensors} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <SensorsForcedState isLoading={this.state.isLoading} sensors={this.state.lpSensors} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <SensorsDetectionState isLoading={this.state.isLoading} sensors={this.state.lpSensors} />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xl={24} xs={24}>
                            <LPSensorsTable
                                reloadDevices={this.reloadDevices}
                                isLoading={this.state.isLoading}
                                sensors={this.state.lpSensors}
                                ipCanId={this.state.ipCanId}
                                ipCanLabel={this.state.ipcanLabel}
                            />
                        </FlexboxGrid.Item>
                    </Fragment>
                )}

                {/* !!! LP DISPLAYS */}

                {this.state.lpDisplays.length > 0 && (
                    <Fragment>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <DisplaysOnlineState isLoading={this.state.isLoading} displays={this.state.lpDisplays} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <DisplaysForcedState isLoading={this.state.isLoading} displays={this.state.lpDisplays} />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item componentClass={Col} xl={24} xs={24}>
                            <LPDisplaysTable
                                reloadDevices={this.reloadDevices}
                                isLoading={this.state.isLoading}
                                displays={this.state.lpDisplays}
                                ipCanId={this.state.ipCanId}
                                ipCanLabel={this.state.ipcanLabel}
                            />
                        </FlexboxGrid.Item>
                    </Fragment>
                )}
            </FlexboxGrid>
        );
    };
}
