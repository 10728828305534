import React from 'react';
import ForceSensor from './Option/ForceSensor';
import NoOptions from './Option/noOptions';
import readRealConfigOptions from './Option/readRealConfigOptions';
import updateCalibration from './Option/updateCalibration';
import UpdateColor from './Option/UpdateColor';
import updateMaintenanceState from './Option/updateMaintenanceState';
import updateTime from './Option/updateTime';

// wait for online, total and db as data
// wait for controler online or not

export default function TCMSensorOptionCell(desc, option) {
    switch (desc) {
        case 'update color':
            return <UpdateColor option={option} />;
        case 'update time':
            return updateTime(option);
        case 'update calibration':
            return updateCalibration(option);
        case 'read real sensor config':
            return readRealConfigOptions(option);
        case 'force sensor':
            return <ForceSensor option={option} />;
        case 'update maintenance state':
            return updateMaintenanceState(option);
        default:
            return NoOptions();
    }
}
