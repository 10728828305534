export const originValue = (origin: number): string => {
    switch (origin) {
        case 0:
            return 'topLeft';
        case 1:
            return 'topRight';
        case 2:
            return 'bottomLeft';
        case 3:
            return 'bottomRight';
        default:
            return '';
    }
};

export const timezoneValue = (timezone: number): string => {
    if (timezone < 0) {
        return `GMT ${timezone}`;
    } else {
        return `GMT +${timezone}`;
    }
};
