export class Role {
    private id: number;
    private section: string;
    private name: string;
    private createdAt: Date;
    private createdBy: string;
    private updatedAt: Date;
    private updatedBy: string;
    private enabled: boolean;

    constructor(role: RoleInterface) {
        this.id = role.id;
        this.section = role.section;
        this.name = role.name;
        this.createdAt = role.createdAt;
        this.createdBy = role.createdBy;
        this.updatedAt = role.updatedAt;
        this.updatedBy = role.updatedBy;
        this.enabled = role.enabled;
    }

    public getId(): number {
        return this.id;
    }

    public getSection(): string {
        return this.section;
    }

    public getName(): string {
        return this.name;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public getCreatedBy(): string {
        return this.createdBy;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public getUpdatedBy(): string {
        return this.updatedBy;
    }

    public isEnabled(): boolean {
        return this.enabled;
    }
}

export interface RoleInterface {
    id: number;
    section: string;
    name: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    enabled: boolean;
}
