import moment from 'moment';
import { colorsValues } from './const/colors';

export function tcmSensorsHandler(tcmSensor) {
    return {
        ...tcmSensor,
        bus: tcmSensor.bus,
        calibration: {
            state: tcmSensor.calibration.state,
            value: tcmSensor.calibration.value,
        },
        color: {
            forced: colorsValues[tcmSensor.color.forced],
            free: colorsValues[tcmSensor.color.free],
            occupied: colorsValues[tcmSensor.color.occupied],
            overstay: colorsValues[tcmSensor.color.overstay],
            overstayFree: colorsValues[tcmSensor.color.overstayFree],
        },
        config: {
            baudrate: tcmSensor.config.baudrate,
            id: tcmSensor.config.id,
            numPlace: tcmSensor.config.numPlace,
            numPulsation: tcmSensor.config.numPulsation,
            parity: tcmSensor.config.parity,
            typePlace: tcmSensor.config.typePlace,
        },
        createdAt: moment.utc(tcmSensor.createdAt).local().format('DD/MM/YYYY HH:mm:ss'),
        createdBy: tcmSensor.createdBy,
        creationInformations: `${tcmSensor.createdBy} - ${moment
            .utc(tcmSensor.createdAt)
            .local()
            .format('DD/MM/YYYY HH:mm:ss')}`,
        detectionState: tcmSensor.detectionState,
        detectionStateTime: moment.utc(tcmSensor.detectionStateTime).local().format('DD/MM/YYYY HH:mm:ss'),
        deviceId: tcmSensor.deviceId,
        id: tcmSensor.id,
        online: tcmSensor.online,
        time: {
            maxFree: tcmSensor.time.maxFree,
            maxOccupied: tcmSensor.time.maxOccupied,
            provOccupied: tcmSensor.time.provOccupied,
        },
        updatedAt: moment.utc(tcmSensor.updatedAt).local().format('DD/MM/YYYY HH:mm:ss'),
        updatedBy: tcmSensor.updatedBy,
        updateInformations: `${tcmSensor.updatedBy} - ${moment
            .utc(tcmSensor.updatedAt)
            .local()
            .format('DD/MM/YYYY HH:mm:ss')}`,
        isForce: tcmSensor.isForce,
        forceType: tcmSensor.forceType,
        endForceTime: moment(tcmSensor.endForceTime).local().format('DD/MM/YYYY HH:mm'),
        versionSoft: tcmSensor.versionSoft,
        lastOnlineStateDate: moment(tcmSensor.lastOnlineStateDate).isValid()
            ? moment(tcmSensor.lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss')
            : null,
    };
}
