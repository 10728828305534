import { faAngleLeft, faCamera, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { match, useHistory, useRouteMatch } from 'react-router';
import { Alert, Button, FlexboxGrid, Loader, Message, Panel } from 'rsuite';
import { IPCameraAPIResponse } from '../../handlers/Camera/ApiCamera';
import Camera from '../../handlers/Camera/Camera';
import PlaceCamera, { WSPlaceIPCamera } from '../../handlers/Camera/PlaceCamera';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { ImagePlacePicker } from './ImagePlacePicker';
import { PlaceCameraLeftSideBar } from './LeftSideBar';
import { HyperParamCameraModal } from './Modal/HyperParamCameraModal/HyperParamCameraModal';

const PLACE_CAMERA_TIMEOUT_VALUE = 1000;

let placeTimeout;

export const CameraPlacesPage = () => {
    const routeParams: match<RouteParams> | null = useRouteMatch('/cameras/:cameraId');

    const history = useHistory();

    const intl = useIntl();

    if (!routeParams) {
        return (
            <Message
                full
                type="error"
                description={intl.formatMessage({
                    id: 'camera.noCameraError',
                })}
            />
        );
    }

    const [camera, setCamera] = React.useState<Camera | null>(null);
    const [selectedPlace, setSelectedPlace] = React.useState<number | null>(null);
    const [places, setPlaces] = React.useState<PlaceCamera[]>([]);
    const [editHyperParamModalOpen, setEditHyperParamModalOpen] = React.useState<boolean>(false);
    const [isTakingPicture, setIsTakingPicture] = React.useState(false);

    const camerasRef = useRef(camera);

    useEffect(() => {
        reloadCamera();
    }, []);

    useEffect(() => {
        webSocketService.joinRoom('place-camera');
        webSocketService.joinRoom('ip-camera');
    }, []);

    useEffect(() => {
        const handlePlaceWs = (data: WSPlaceIPCamera) => {
            if (camera) {
                const currentPlaces = camera?.getPlacesCamera();
                const foundPlaceCamera = currentPlaces.find(place => place.getId() === data.id);

                if (foundPlaceCamera) {
                    foundPlaceCamera.updatePlaceWS(data);

                    if (!placeTimeout) {
                        placeTimeout = setTimeout(() => {
                            setPlaces([...currentPlaces]);
                            camerasRef.current = camera;

                            placeTimeout = clearTimeout(placeTimeout);
                        }, PLACE_CAMERA_TIMEOUT_VALUE);
                    }
                }
            }
        };

        webSocketService.onEvent('place-camera:updateDetection', handlePlaceWs);

        return () => {
            webSocketService.offEvent('place-camera:updateDetection', handlePlaceWs);
        };
    }, [camera]);

    const reloadPlaces = () => {
        if (camera) {
            setPlaces([...camera.getPlacesCamera()]);
        }
    };

    const reloadCamera = () => {
        setCamera(null);
        const cameraId = parseInt(routeParams?.params.cameraId);

        if (cameraId) {
            axiosService
                .getAxios()
                .get<IPCameraAPIResponse>(`/ip-cameras/${cameraId}`, {
                    headers: authHeader(),
                })
                .then(response => {
                    const newCamera = new Camera(response.data);

                    setCamera(newCamera);

                    setPlaces(newCamera.getPlacesCamera());

                    camerasRef.current = newCamera;
                })
                .catch(error => {
                    console.error(error);

                    Alert.error(intl.formatMessage({ id: 'camera.fetchCameraError' }));
                });
        }
    };

    const handleTakePicture = () => {
        setIsTakingPicture(true);

        axiosService
            .getAxios()
            .put(
                '/ip-cameras/preview',
                {
                    id: camera?.getId(),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'ipCamera.picture.success' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'ipCamera.picture.error' }));
            });
    };

    useEffect(() => {
        webSocketService.onEvent('ip-camera:updatePreview', handleWebsocketUpdatePreview);

        return function cleanup() {
            webSocketService.offEvent('ip-camera:updatePreview', handleWebsocketUpdatePreview);
        };
    }, []);

    const handleWebsocketUpdatePreview = (data: IPCameraAPIResponse) => {
        if (data.id === camerasRef.current?.getId()) {
            setCamera(null);

            const currentCamera = camerasRef.current;

            currentCamera.updateCameraWS(data);

            setCamera(currentCamera);

            setIsTakingPicture(false);
        }
    };

    return (
        <>
            {camera && (
                <HyperParamCameraModal
                    show={editHyperParamModalOpen}
                    onClose={() => setEditHyperParamModalOpen(false)}
                    camera={camera}
                />
            )}
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <Panel bordered>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item>
                                <h4 data-cy="labelHeader">
                                    {camera ? `${camera.getName()} (${camera.getIp()})` : <Loader />}
                                </h4>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <Button
                                    color="blue"
                                    onClick={handleTakePicture}
                                    disabled={!camera}
                                    className="margin-right-10"
                                    loading={isTakingPicture}>
                                    <FontAwesomeIcon icon={faCamera} />
                                </Button>
                                <Button
                                    color="violet"
                                    onClick={() => setEditHyperParamModalOpen(true)}
                                    disabled={!camera}
                                    className="margin-right-10">
                                    <FontAwesomeIcon icon={faWrench} className="margin-right-10" />
                                    <FormattedMessage id="camera.editHyperParam" />
                                </Button>
                                <Button
                                    color="blue"
                                    onClick={() => history.push('/cameras')}
                                    data-cy="camera-places-return-to-cameras">
                                    <FontAwesomeIcon icon={faAngleLeft} className="margin-right-10" />
                                    <FormattedMessage id="camera.cameraPlaces.goBack" />
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                </FlexboxGrid.Item>

                {camera && (
                    <>
                        <FlexboxGrid.Item colspan={8}>
                            <PlaceCameraLeftSideBar
                                places={places}
                                setSelectedPlace={setSelectedPlace}
                                selectedPlace={selectedPlace}
                                reloadPlaces={reloadPlaces}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={16}>
                            <ImagePlacePicker
                                places={places}
                                camera={camera}
                                selectedPlaceId={selectedPlace}
                                reloadPlaces={reloadPlaces}
                                reloadCamera={reloadCamera}
                            />
                        </FlexboxGrid.Item>
                    </>
                )}
            </FlexboxGrid>
        </>
    );
};

type RouteParams = {
    cameraId: string;
};
