import { assign, Machine } from 'xstate';
import PlaceCamera from '../../../handlers/Camera/PlaceCamera';
import { LPSensor } from '../../../handlers/ipCanDevices/LPSensor';
import TCMSensor from '../../../handlers/ipCanDevices/TCMSensor';
import { levelHandler } from '../../../handlers/levelHandler';
import { mapDisplaysHandler } from '../../../handlers/map/displays';
import { MapCameraSensor } from '../../../handlers/map/MapCameraSensor';
import { MapLPMatrixDisplayV2 } from '../../../handlers/map/MapLPMatrixDisplayV2';
import { MapLPSensor } from '../../../handlers/map/MapLPSensor';
import { MapTCMSensor } from '../../../handlers/map/MapTCMSensor';
import { MapVehicleCounter } from '../../../handlers/map/MapVehicleCounter';
import { sensorPlaceTypeHandler } from '../../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import noImage from '../../../style/assets/eye-slash-solid.svg';
// import SensorPlaceType from '../../PlaceType/Drawer/SensorPlaceType';

interface MapActionsMachineStateSchema {
    states: {
        loadPlaceType: {};
        loadPlaceTypeFailure: {};
        loadController: {};
        loadControllerFailure: {};
        loadLevel: {};
        loadLevelFailure: {};
        loadSensors: {};
        loadSensorsFailure: {};
        loadDisplays: {};
        loadDisplaysFailure: {};
        loadZones: {};
        loadZonesFailure: {};
        loadVehicleCounters: {};
        loadVehicleCountersFailure: {};
        loadProgressBars: {};
        loadProgressBarsFailure: {};
        loadProgressStatus: {};
        loadProgressStatusFailure: {};
        addListenerToCounters: {};
        checkAllLevels: {};
        checkAllLevelsFailure: {};
        getEngineStatus: {};
        getEngineStatusFailure: {};
        getTcmDisplayStatus: {};
        viewMode: {};
        globalView: {};
        editPlaces: {};
        editZones: {};
        editDisplays: {};
        editPassCount: {};
        editSign: {};
        calibration: {};
    };
}

type MapActionsMachineEvent =
    | { type: 'RELOAD_PLACE_TYPE' }
    | { type: 'RELOAD_CONTROLLERS' }
    | { type: 'RELOAD_LEVELS' }
    | { type: 'RELOAD_SENSORS' }
    | { type: 'RELOAD_DISPLAYS' }
    | { type: 'RELOAD_ZONES' }
    | { type: 'RELOAD_VEHICLE_COUNTER' }
    | { type: 'RELOAD_PROGRESS_BARS' }
    | { type: 'RELOAD_PROGRESS_STATUS' }
    | { type: 'RELOAD_CHECK_ALL_LEVELS' }
    | { type: 'SET_EDIT_MODE' }
    | { type: 'SENSOR_UPDATE' }
    | { type: 'LPSENSOR_UPDATE' }
    | { type: 'CAMERA_SENSOR_UPDATE' }
    | { type: 'LPMATRIXDISPLAY_V2_UPDATE' }
    | { type: 'DISPLAY_UPDATE' }
    | { type: 'UPDATE_TCM_DISPLAY' }
    | { type: 'UPDATE_LP_MATRIX_DISPLAY' }
    | { type: 'COUNTER_UPDATE' }
    | { type: 'VEHICLE_COUNTER_UPDATE' }
    | { type: 'TOGGLE_SENSOR_STATE' }
    | { type: 'RELOAD' }
    | { type: 'RELOAD_COUNTERS' }
    | { type: 'LPSENSOR_EDIT_CONFIGURATION' }
    | { type: 'TCMSENSOR_EDIT_CONFIGURATION' }
    | { type: 'PLACECAMERA_EDIT_CONFIGURATION' }
    | { type: 'TCMSENSOR_EDIT_CALIBRATION' }
    | { type: 'EDIT_MODE' }
    | { type: 'EDIT_PLACES' }
    | { type: 'EDIT_ZONES' }
    | { type: 'EDIT_DISPLAYS' }
    | { type: 'EDIT_PASS_COUNT' }
    | { type: 'EDIT_SIGN' }
    | { type: 'CALIBRATION' }
    | { type: 'SET_VIEW_MODE' }
    | { type: 'GLOBAL_VIEW' }
    | { type: 'UPDATE_LOGIC_COUNTING_SYNC' };

interface MapActionsMachineContext {
    previousMode: string;
    isEdit: boolean;
    sensorPlaceTypes: Array<Record<string, any>>;
    sensorPlaceTypeFailure: boolean;
    controllers: Array<Record<string, any>>;
    controllerFailure: boolean;
    error: boolean;
    levelId: number | undefined;
    level: Record<string, any> | undefined;
    levelFailure: boolean;
    tcmSensors: MapTCMSensor[];
    lpSensors: MapLPSensor[];
    placeCameraSensors: MapCameraSensor[];
    sensorFailure: boolean;
    displays: Array<Record<string, any>>;
    lpMatrixDisplayV2: Array<Record<string, any>>;
    displayFailure: boolean;
    zones: Array<Record<string, any>>;
    zoneFailure: boolean;
    vehicleCounters: Array<Record<string, any>>;
    vehicleCounterFailure: boolean;
    loading: boolean;
    sensorLayer: boolean;
    displayLayer: boolean;
    zoneLayer: boolean;
    passCountLayer: boolean;
    levels: Array<Record<string, any>>;
    needUpdate: boolean;
    checkAllLevelsFailure: boolean;
    logicCountingSync: boolean;
    getEngineStatusFailure: boolean;
    sensorStateDisplayed: boolean;
    progressLoading: boolean;
    progress: Array<Array<Record<string, any>>>;
    progressBarsFailure: boolean;
    progressStatus: Array<Record<string, any>>;
    progressStatusFailure: boolean;
}

let sensorPlaceTypes: any[] = [];

const fetchSensorPlaceType = () => {
    return axiosService
        .getAxios()
        .get('/sensor-place-type', { headers: authHeader() })
        .then(response =>
            response.data.map(r => {
                const spt = sensorPlaceTypeHandler(r);
                sensorPlaceTypes.push(spt);
                return spt;
            })
        );
};

const fetchControllers = () => {
    return axiosService
        .getAxios()
        .get('/ipcanmodules', { headers: authHeader() })
        .then(response => response.data);
};

const fetchLevel = levelId => {
    return axiosService
        .getAxios()
        .get(`/levels/${levelId}`, { headers: authHeader() })
        .then(response => levelHandler(response.data));
};

const fetchSensors = levelId => {
    return axiosService
        .getAxios()
        .get(`/map/level/${levelId}/sensor`, { headers: authHeader() })
        .then(response => {
            const tcmSensors = response.data.mapTcmSensors.map(sensor => {
                const sensorPlaceTypeId = sensor.tcmSensor.sensorPlaceType.id;
                const findSensorPlaceType = sensorPlaceTypes.find(spt => spt.id === sensorPlaceTypeId);

                return new MapTCMSensor(sensor, findSensorPlaceType);
            });
            const lpSensors = response.data.mapLpSensors.map(sensor => {
                const sensorPlaceTypeId = sensor.lpSensor.sensorPlaceType.id;
                const findSensorPlaceType = sensorPlaceTypes.find(spt => spt.id === sensorPlaceTypeId);

                return new MapLPSensor(sensor, findSensorPlaceType);
            });
            const placesCamera = response.data.mapPlaceCamera.map(place => {
                const sensorPlaceTypeId = place.placeCamera.sensorPlaceType.id;
                const findSensorPlaceType = sensorPlaceTypes.find(spt => spt.id === sensorPlaceTypeId);

                return new MapCameraSensor(place, findSensorPlaceType);
            });

            return { tcmSensors, lpSensors, placesCamera };
        });
};

const fetchDisplays = levelId => {
    return axiosService
        .getAxios()
        .get(`/map/level/${levelId}/display`, { headers: authHeader() })
        .then(response => {
            const tcmDisplays = response.data.mapTcmDisplays.map(display => mapDisplaysHandler(display));

            return {
                tcmDisplays: tcmDisplays.map(display => {
                    return {
                        ...display,
                        type: 'tcm',
                    };
                }),
                lpMatrixDisplaysV2: response.data.mapLpMatrixDisplaysV2.map(
                    display => new MapLPMatrixDisplayV2(display)
                ),
            };
        });
};

const fetchZones = levelId => {
    return axiosService
        .getAxios()
        .get(`/map/level/${levelId}/zones`, { headers: authHeader() })
        .then(response => {
            return response.data;
        });
};

const fetchVehicleCounters = levelId => {
    return axiosService
        .getAxios()
        .get(`/map/level/${levelId}/vehicle_counters`, { headers: authHeader() })
        .then(vehicleCounterResponse =>
            vehicleCounterResponse.data.map(vehicleCounter => {
                let currentSensorPlaceType = sensorPlaceTypes.find(spt => spt.id === vehicleCounter.id);

                vehicleCounter.sensorPlaceType = currentSensorPlaceType;

                return new MapVehicleCounter(vehicleCounter);
            })
        );
};

const fetchProgressBars = () => {
    return axiosService
        .getAxios()
        .get('/counters/progress', { headers: authHeader() })
        .then(counterProgressResponse =>
            counterProgressResponse.data.map(counter => {
                return {
                    ...counter,
                    counterValue: {
                        all: {
                            occupied: 0,
                            free: 0,
                            total: 0,
                        },
                    },
                };
            })
        );
};

const fetchProgressStatus = () => {
    return axiosService
        .getAxios()
        .get('/counters/status', { headers: authHeader() })
        .then(counterStatusResponse => {
            return counterStatusResponse.data;
        });
};

const listenToCounters = () => {
    return axiosService.getAxios().get('/devices/tcm-display/status', { headers: authHeader() });
};

const fetchLPMatrixDisplayV2Status = () => {
    return axiosService.getAxios().get('/devices/lp-matrixdisplays-v2/status', { headers: authHeader() });
};

const fetchAllLevels = () => {
    return axiosService
        .getAxios()
        .get('/parkings', { headers: authHeader() })
        .then(parkingResponse => {
            return axiosService
                .getAxios()
                .get('/levels', { headers: authHeader() })
                .then(response =>
                    response.data.map(level => {
                        const currentParking = parkingResponse.data.find(parking =>
                            parking.levels.find(l => l.id === level.id)
                        );

                        return levelHandler({ ...level, parking: currentParking });
                    })
                );
        });
};

const fetchTCMDisplayStatus = () => {
    return axiosService.getAxios().get('/devices/tcm-display/status', { headers: authHeader() });
};

const fetchEngineStatus = () => {
    return axiosService
        .getAxios()
        .get('/engine/status', { headers: authHeader() })
        .then(response => response.data);
};

export const MapActionsMachine = Machine<
    MapActionsMachineContext,
    MapActionsMachineStateSchema,
    MapActionsMachineEvent
>(
    {
        id: 'mapActions',
        initial: 'loadPlaceType',
        context: {
            previousMode: 'globalView',
            isEdit: false,
            sensorPlaceTypes: [],
            sensorPlaceTypeFailure: false,
            controllers: [],
            controllerFailure: false,
            error: false,
            levelId: undefined,
            level: undefined,
            levelFailure: false,
            tcmSensors: [],
            lpSensors: [],
            placeCameraSensors: [],
            sensorFailure: false,
            displays: [],
            lpMatrixDisplayV2: [],
            displayFailure: false,
            zones: [],
            zoneFailure: false,
            vehicleCounters: [],
            vehicleCounterFailure: false,
            loading: true,
            sensorLayer: false,
            displayLayer: false,
            zoneLayer: false,
            passCountLayer: false,
            levels: [],
            needUpdate: false,
            checkAllLevelsFailure: false,
            logicCountingSync: true,
            getEngineStatusFailure: false,
            sensorStateDisplayed: true,
            progressLoading: true,
            progress: [],
            progressBarsFailure: false,
            progressStatus: [],
            progressStatusFailure: false,
        },
        states: {
            // PLACE TYPES
            loadPlaceType: {
                entry: 'setLoading',
                invoke: {
                    src: () => () => {
                        return fetchSensorPlaceType();
                    },
                    onDone: {
                        target: 'loadController',
                        actions: 'setSensorPlaceTypes',
                    },
                    onError: {
                        target: 'loadPlaceTypeFailure',
                        actions: 'setSensorPlaceTypeFailure',
                    },
                },
            },
            loadPlaceTypeFailure: {
                on: {
                    RELOAD_PLACE_TYPE: {
                        target: 'loadPlaceType',
                        actions: 'unsetSensorPlaceTypeFailure',
                    },
                },
            },
            // CONTROLLERS
            loadController: {
                entry: 'setLoading',
                invoke: {
                    src: () => () => {
                        return fetchControllers();
                    },
                    onDone: {
                        target: 'loadLevel',
                        actions: 'setControllers',
                    },
                    onError: {
                        target: 'loadControllerFailure',
                        actions: 'setControllerFailure',
                    },
                },
            },
            loadControllerFailure: {
                on: {
                    RELOAD_CONTROLLERS: {
                        target: 'loadController',
                        actions: 'unsetControllerFailure',
                    },
                },
            },
            // LEVEL
            loadLevel: {
                entry: 'setLoading',
                invoke: {
                    src: context => () => {
                        return new Promise(function (resolve) {
                            fetchLevel(context.levelId).then((level: Record<string, any>) => {
                                if (context.level) {
                                    resolve({
                                        ...context.level,
                                        calibrationCm: level.calibrationCm,
                                        calibrationPlace: level.calibrationPlace,
                                    });
                                } else {
                                    let image = new Image();
                                    if (level.image) {
                                        image.src = `data:image/${level.imageType};base64, ${level.image}`;
                                    } else {
                                        image.src = noImage;
                                    }
                                    image.onload = () => {
                                        level.levelImage = image;
                                        level.imageHeight = image.height;
                                        level.imageWidth = image.width;

                                        resolve(level);
                                    };
                                }
                            });
                        });
                    },
                    onDone: {
                        target: 'loadSensors',
                        actions: 'setLevels',
                    },
                    onError: {
                        target: 'loadLevelFailure',
                        actions: 'setLevelFailure',
                    },
                },
            },
            loadLevelFailure: {
                on: {
                    RELOAD_LEVELS: {
                        target: 'loadLevel',
                        actions: 'unsetLevelFailure',
                    },
                },
            },
            // SENSORS
            loadSensors: {
                invoke: {
                    src: context => () => {
                        return fetchSensors(context.levelId);
                    },
                    onDone: {
                        target: 'loadDisplays',
                        actions: 'setSensors',
                    },
                    onError: {
                        target: 'loadSensorsFailure',
                        actions: 'setSensorFailure',
                    },
                },
            },
            loadSensorsFailure: {
                on: {
                    RELOAD_SENSORS: {
                        target: 'loadSensors',
                        actions: 'unsetSensorFailure',
                    },
                },
            },
            // DISPLAYS
            loadDisplays: {
                invoke: {
                    src: context => () => {
                        return fetchDisplays(context.levelId);
                    },
                    onDone: {
                        target: 'loadZones',
                        actions: 'setDisplays',
                    },
                    onError: {
                        target: 'loadDisplaysFailure',
                        actions: 'setDisplayFailure',
                    },
                },
            },
            loadDisplaysFailure: {
                on: {
                    RELOAD_DISPLAYS: {
                        target: 'loadDisplays',
                        actions: 'unsetDisplayFailure',
                    },
                },
            },
            // ZONES
            loadZones: {
                invoke: {
                    src: context => () => {
                        return fetchZones(context.levelId);
                    },
                    onDone: [
                        {
                            target: 'loadVehicleCounters',
                            actions: 'setZones',
                        },
                    ],
                    onError: {
                        target: 'loadZonesFailure',
                        actions: 'setZoneFailure',
                    },
                },
            },
            loadZonesFailure: {
                on: {
                    RELOAD_ZONES: {
                        target: 'loadZones',
                        actions: 'unsetZoneFailure',
                    },
                },
            },
            loadVehicleCounters: {
                invoke: {
                    src: context => () => {
                        return fetchVehicleCounters(context.levelId);
                    },
                    onDone: [
                        {
                            target: 'checkAllLevels',
                            actions: 'setVehicleCounters',
                        },
                    ],
                    onError: {
                        target: 'loadVehicleCountersFailure',
                        actions: 'setVehicleCountersFailure',
                    },
                },
            },
            loadVehicleCountersFailure: {
                on: {
                    RELOAD_VEHICLE_COUNTER: {
                        target: 'loadVehicleCounters',
                        actions: 'unsetVehicleCountersFailure',
                    },
                },
            },
            checkAllLevels: {
                invoke: {
                    src: () => () => {
                        return fetchAllLevels();
                    },
                    onDone: [
                        {
                            target: 'loadProgressBars',
                            actions: 'setAllLevelsStatus',
                        },
                    ],
                    onError: {
                        target: 'checkAllLevelsFailure',
                        actions: 'setAllLevelsStatusFailure',
                    },
                },
            },
            checkAllLevelsFailure: {
                on: {
                    RELOAD_CHECK_ALL_LEVELS: {
                        target: 'checkAllLevels',
                        actions: 'unsetAllLevelsStatusFailure',
                    },
                },
            },
            loadProgressBars: {
                invoke: {
                    src: () => () => {
                        return fetchProgressBars();
                    },
                    onDone: [
                        {
                            target: 'loadProgressStatus',
                            actions: 'setProgressBars',
                        },
                    ],
                    onError: {
                        target: 'loadProgressBarsFailure',
                        actions: 'setProgressBarFailure',
                    },
                },
            },
            loadProgressBarsFailure: {
                on: {
                    RELOAD_PROGRESS_BARS: {
                        target: 'loadProgressBars',
                        actions: 'unsetProgressBarFailure',
                    },
                },
            },
            loadProgressStatus: {
                invoke: {
                    src: () => () => {
                        return fetchProgressStatus();
                    },
                    onDone: [
                        {
                            target: 'addListenerToCounters',
                            actions: 'setProgressStatus',
                        },
                    ],
                    onError: {
                        target: 'loadProgressStatusFailure',
                        actions: 'setProgressStatusFailure',
                    },
                },
            },
            loadProgressStatusFailure: {
                on: {
                    RELOAD_PROGRESS_STATUS: {
                        target: 'loadProgressStatus',
                        actions: 'unsetProgressStatusFailure',
                    },
                },
            },
            addListenerToCounters: {
                invoke: {
                    src: context => () => {
                        if (context.displays.length > 0 && context.lpMatrixDisplayV2.length > 0) {
                            return fetchTCMDisplayStatus().then(() => fetchLPMatrixDisplayV2Status());
                        } else if (context.displays.length > 0) {
                            return fetchTCMDisplayStatus();
                        } else if (context.lpMatrixDisplayV2.length > 0) {
                            return fetchLPMatrixDisplayV2Status();
                        } else {
                            return Promise.resolve();
                        }
                    },
                    onDone: [
                        {
                            target: 'getEngineStatus',
                        },
                    ],
                    onError: [
                        {
                            target: 'getEngineStatus',
                        },
                    ],
                },
            },
            getEngineStatus: {
                invoke: {
                    src: () => () => {
                        return fetchEngineStatus();
                    },
                    onDone: {
                        actions: ['setEngineStatus'],
                        target: 'getTcmDisplayStatus',
                    },
                },
            },
            getEngineStatusFailure: {
                on: {
                    RELOAD: {
                        target: 'getEngineStatus',
                        actions: 'setEngineStatusFailure',
                    },
                },
            },
            getTcmDisplayStatus: {
                invoke: {
                    src: context => () => {
                        if (context.displays.length > 0 && context.lpMatrixDisplayV2.length > 0) {
                            return fetchTCMDisplayStatus().then(() => fetchLPMatrixDisplayV2Status());
                        } else if (context.displays.length > 0) {
                            return fetchTCMDisplayStatus();
                        } else if (context.lpMatrixDisplayV2.length > 0) {
                            return fetchLPMatrixDisplayV2Status();
                        } else {
                            return Promise.resolve();
                        }
                    },
                    onDone: [
                        {
                            target: 'viewMode',
                            cond: context => !context.isEdit,
                        },
                        {
                            target: 'globalView',
                            cond: context => context.previousMode === 'globalView' && context.isEdit,
                        },
                        {
                            target: 'editPlaces',
                            cond: context => context.previousMode === 'sensorView' && context.isEdit,
                        },
                        {
                            target: 'editZones',
                            cond: context => context.previousMode === 'zoneView' && context.isEdit,
                        },
                        {
                            target: 'editDisplays',
                            cond: context => context.previousMode === 'displayView' && context.isEdit,
                        },
                        {
                            target: 'editPassCount',
                            cond: context => context.previousMode === 'passCountView' && context.isEdit,
                        },
                        {
                            target: 'calibration',
                            cond: context => context.previousMode === 'calibrationView' && context.isEdit,
                        },
                        {
                            target: 'globalView',
                        },
                    ],
                },
            },
            viewMode: {
                entry: 'setViewMode',
                on: {
                    SET_EDIT_MODE: 'globalView',
                    SENSOR_UPDATE: {
                        actions: 'setSensorsUpdate',
                    },
                    LPSENSOR_UPDATE: {
                        actions: 'setLPSensorsUpdate',
                    },
                    CAMERA_SENSOR_UPDATE: {
                        actions: 'setCameraSensorsUpdate',
                    },
                    DISPLAY_UPDATE: {
                        actions: 'setDisplaysUpdate',
                    },
                    UPDATE_TCM_DISPLAY: {
                        actions: 'updateTCMDisplay',
                    },
                    LPMATRIXDISPLAY_V2_UPDATE: {
                        actions: 'setLPMatrixDisplayV2Update',
                    },
                    COUNTER_UPDATE: {
                        actions: 'updateCounter',
                    },
                    TOGGLE_SENSOR_STATE: {
                        actions: assign({
                            sensorStateDisplayed: context => !context.sensorStateDisplayed,
                        }),
                    },
                    RELOAD: [
                        {
                            target: 'loadSensors',
                            cond: (_, event: Record<string, any>) => event.name === 'sensors',
                        },
                        {
                            target: 'loadDisplays',
                            cond: (_, event: Record<string, any>) => event.name === 'displays',
                        },
                        {
                            target: 'loadZones',
                            cond: (_, event: Record<string, any>) => event.name === 'zones',
                        },
                        {
                            target: 'loadVehicleCounters',
                            cond: (_, event: Record<string, any>) => event.name === 'vehicleCounters',
                        },
                        {
                            target: 'globalView',
                        },
                    ],
                    RELOAD_COUNTERS: {
                        target: 'loadSensors',
                    },
                    VEHICLE_COUNTER_UPDATE: {
                        actions: 'setPassCounterUpdate',
                    },
                    UPDATE_LOGIC_COUNTING_SYNC: {
                        actions: 'setLogicCountingSync',
                    },
                },
            },
            // REST
            globalView: {
                entry: 'setGlobalView',
                on: {
                    EDIT_PLACES: 'editPlaces',
                    EDIT_ZONES: 'editZones',
                    EDIT_DISPLAYS: 'editDisplays',
                    EDIT_PASS_COUNT: 'editPassCount',
                    EDIT_SIGN: 'editSign',
                    CALIBRATION: 'calibration',
                    SET_VIEW_MODE: {
                        target: 'loadSensors',
                        actions: 'setViewMode',
                    },
                    SENSOR_UPDATE: {
                        actions: 'setSensorsUpdate',
                    },
                    LPSENSOR_UPDATE: {
                        actions: 'setLPSensorsUpdate',
                    },
                    CAMERA_SENSOR_UPDATE: {
                        actions: 'setCameraSensorsUpdate',
                    },
                    LPSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateLPSensor',
                    },
                    LPMATRIXDISPLAY_V2_UPDATE: {
                        actions: 'setLPMatrixDisplayV2Update',
                    },
                    TCMSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateTCMSensor',
                    },
                    PLACECAMERA_EDIT_CONFIGURATION: {
                        actions: 'updatePlaceCamera',
                    },
                    TCMSENSOR_EDIT_CALIBRATION: {
                        actions: 'updateTCMSensorCalibration',
                    },
                    DISPLAY_UPDATE: {
                        actions: 'setDisplaysUpdate',
                    },
                    UPDATE_TCM_DISPLAY: {
                        actions: 'updateTCMDisplay',
                    },
                    UPDATE_LP_MATRIX_DISPLAY: {
                        actions: 'updateLPMatrixDisplay',
                    },
                    COUNTER_UPDATE: {
                        actions: 'updateCounter',
                    },
                    VEHICLE_COUNTER_UPDATE: {
                        actions: 'setPassCounterUpdate',
                    },
                    RELOAD: [
                        {
                            target: 'loadSensors',
                            cond: (_, event: Record<string, any>) => event.name === 'sensors',
                        },
                        {
                            target: 'loadDisplays',
                            cond: (_, event: Record<string, any>) => event.name === 'displays',
                        },
                        {
                            target: 'loadZones',
                            cond: (_, event: Record<string, any>) => event.name === 'zones',
                        },
                        {
                            target: 'loadVehicleCounters',
                            cond: (_, event: Record<string, any>) => event.name === 'vehicleCounters',
                        },
                        {
                            target: 'globalView',
                        },
                    ],
                    TOGGLE_SENSOR_STATE: {
                        actions: 'setSensorStateDisplayed',
                    },
                    UPDATE_LOGIC_COUNTING_SYNC: {
                        actions: 'setLogicCountingSync',
                    },
                },
            },
            editPlaces: {
                entry: 'setSensorView',
                on: {
                    EDIT_ZONES: 'editZones',
                    EDIT_DISPLAYS: 'editDisplays',
                    EDIT_PASS_COUNT: 'editPassCount',
                    EDIT_SIGN: 'editSign',
                    CALIBRATION: 'calibration',
                    GLOBAL_VIEW: 'globalView',
                    SET_VIEW_MODE: {
                        target: 'loadSensors',
                        actions: 'setViewMode',
                    },
                    RELOAD: 'loadSensors',
                    SENSOR_UPDATE: {
                        actions: 'setSensorsUpdate',
                    },
                    LPSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateLPSensor',
                    },
                    TCMSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateTCMSensor',
                    },
                    PLACECAMERA_EDIT_CONFIGURATION: {
                        actions: 'updatePlaceCamera',
                    },
                    TCMSENSOR_EDIT_CALIBRATION: {
                        actions: 'updateTCMSensorCalibration',
                    },
                    LPSENSOR_UPDATE: {
                        actions: 'setLPSensorsUpdate',
                    },
                    CAMERA_SENSOR_UPDATE: {
                        actions: 'setCameraSensorsUpdate',
                    },
                    DISPLAY_UPDATE: {
                        actions: 'setDisplaysUpdate',
                    },
                    UPDATE_TCM_DISPLAY: {
                        actions: 'updateTCMDisplay',
                    },
                    UPDATE_LP_MATRIX_DISPLAY: {
                        actions: 'updateLPMatrixDisplay',
                    },
                    LPMATRIXDISPLAY_V2_UPDATE: {
                        actions: 'setLPMatrixDisplayV2Update',
                    },
                    TOGGLE_SENSOR_STATE: {
                        actions: 'setSensorStateDisplayed',
                    },
                    UPDATE_LOGIC_COUNTING_SYNC: {
                        actions: 'setLogicCountingSync',
                    },
                    COUNTER_UPDATE: {
                        actions: 'updateCounter',
                    },
                    VEHICLE_COUNTER_UPDATE: {
                        actions: 'setPassCounterUpdate',
                    },
                },
            },
            editZones: {
                entry: 'setZoneView',
                on: {
                    GLOBAL_VIEW: 'globalView',
                    EDIT_PLACES: { target: 'editPlaces', actions: 'setEditPlaces' },
                    EDIT_DISPLAYS: { target: 'editDisplays', actions: 'setEditDisplays' },
                    EDIT_PASS_COUNT: { target: 'editPassCount', actions: 'setEditPassCount' },
                    EDIT_SIGN: { target: 'editSign', actions: 'setEditSign' },
                    CALIBRATION: 'calibration',
                    SET_VIEW_MODE: {
                        target: 'loadSensors',
                        actions: 'setViewMode',
                    },
                    SENSOR_UPDATE: {
                        actions: 'setSensorsUpdate',
                    },
                    LPSENSOR_UPDATE: {
                        actions: 'setLPSensorsUpdate',
                    },
                    CAMERA_SENSOR_UPDATE: {
                        actions: 'setCameraSensorsUpdate',
                    },
                    LPSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateLPSensor',
                    },
                    TCMSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateTCMSensor',
                    },
                    PLACECAMERA_EDIT_CONFIGURATION: {
                        actions: 'updatePlaceCamera',
                    },
                    TCMSENSOR_EDIT_CALIBRATION: {
                        actions: 'updateTCMSensorCalibration',
                    },
                    DISPLAY_UPDATE: {
                        actions: 'setDisplaysUpdate',
                    },
                    UPDATE_TCM_DISPLAY: {
                        actions: 'updateTCMDisplay',
                    },
                    UPDATE_LP_MATRIX_DISPLAY: {
                        actions: 'updateLPMatrixDisplay',
                    },
                    LPMATRIXDISPLAY_V2_UPDATE: {
                        actions: 'setLPMatrixDisplayV2Update',
                    },
                    RELOAD: { target: 'loadZones', actions: 'setLoading' },
                    TOGGLE_SENSOR_STATE: {
                        actions: 'setSensorStateDisplayed',
                    },
                    UPDATE_LOGIC_COUNTING_SYNC: {
                        actions: 'setLogicCountingSync',
                    },
                    COUNTER_UPDATE: {
                        actions: 'updateCounter',
                    },
                    VEHICLE_COUNTER_UPDATE: {
                        actions: 'setPassCounterUpdate',
                    },
                },
            },
            editDisplays: {
                entry: 'setDisplayView',
                on: {
                    GLOBAL_VIEW: 'globalView',
                    EDIT_PLACES: { target: 'editPlaces', actions: 'setEditPlaces' },
                    EDIT_ZONES: { target: 'editZones', actions: 'setEditZones' },
                    EDIT_PASS_COUNT: { target: 'editPassCount', actions: 'setEditPassCount' },
                    EDIT_SIGN: { target: 'editSign', actions: 'setEditSign' },
                    CALIBRATION: 'calibration',
                    SET_VIEW_MODE: {
                        target: 'loadSensors',
                        actions: 'setViewMode',
                    },
                    SENSOR_UPDATE: {
                        actions: 'setSensorsUpdate',
                    },
                    LPSENSOR_UPDATE: {
                        actions: 'setLPSensorsUpdate',
                    },
                    CAMERA_SENSOR_UPDATE: {
                        actions: 'setCameraSensorsUpdate',
                    },
                    LPSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateLPSensor',
                    },
                    TCMSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateTCMSensor',
                    },
                    PLACECAMERA_EDIT_CONFIGURATION: {
                        actions: 'updatePlaceCamera',
                    },
                    TCMSENSOR_EDIT_CALIBRATION: {
                        actions: 'updateTCMSensorCalibration',
                    },
                    DISPLAY_UPDATE: {
                        actions: 'setDisplaysUpdate',
                    },
                    UPDATE_TCM_DISPLAY: {
                        actions: 'updateTCMDisplay',
                    },
                    UPDATE_LP_MATRIX_DISPLAY: {
                        actions: 'updateLPMatrixDisplay',
                    },
                    LPMATRIXDISPLAY_V2_UPDATE: {
                        actions: 'setLPMatrixDisplayV2Update',
                    },
                    RELOAD: 'loadDisplays',
                    TOGGLE_SENSOR_STATE: {
                        actions: 'setSensorStateDisplayed',
                    },
                    UPDATE_LOGIC_COUNTING_SYNC: {
                        actions: 'setLogicCountingSync',
                    },
                    COUNTER_UPDATE: {
                        actions: 'updateCounter',
                    },
                    VEHICLE_COUNTER_UPDATE: {
                        actions: 'setPassCounterUpdate',
                    },
                },
            },
            editPassCount: {
                entry: 'setPassCountView',
                on: {
                    GLOBAL_VIEW: 'globalView',
                    EDIT_PLACES: { target: 'editPlaces', actions: 'setEditPlaces' },
                    EDIT_DISPLAYS: { target: 'editDisplays', actions: 'setEditDisplays' },
                    EDIT_ZONES: { target: 'editZones', actions: 'setEditZones' },
                    EDIT_SIGN: { target: 'editSign', actions: 'setEditSign' },
                    CALIBRATION: 'calibration',
                    SET_VIEW_MODE: {
                        target: 'loadSensors',
                        actions: 'setViewMode',
                    },
                    SENSOR_UPDATE: {
                        actions: 'setSensorsUpdate',
                    },
                    LPSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateLPSensor',
                    },
                    TCMSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateTCMSensor',
                    },
                    PLACECAMERA_EDIT_CONFIGURATION: {
                        actions: 'updatePlaceCamera',
                    },
                    TCMSENSOR_EDIT_CALIBRATION: {
                        actions: 'updateTCMSensorCalibration',
                    },
                    LPSENSOR_UPDATE: {
                        actions: 'setLPSensorsUpdate',
                    },
                    CAMERA_SENSOR_UPDATE: {
                        actions: 'setCameraSensorsUpdate',
                    },
                    DISPLAY_UPDATE: {
                        actions: 'setDisplaysUpdate',
                    },
                    UPDATE_TCM_DISPLAY: {
                        actions: 'updateTCMDisplay',
                    },
                    UPDATE_LP_MATRIX_DISPLAY: {
                        actions: 'updateLPMatrixDisplay',
                    },
                    LPMATRIXDISPLAY_V2_UPDATE: {
                        actions: 'setLPMatrixDisplayV2Update',
                    },
                    RELOAD: 'loadZones',
                    TOGGLE_SENSOR_STATE: {
                        actions: 'setSensorStateDisplayed',
                    },
                    UPDATE_LOGIC_COUNTING_SYNC: {
                        actions: 'setLogicCountingSync',
                    },
                    COUNTER_UPDATE: {
                        actions: 'updateCounter',
                    },
                    VEHICLE_COUNTER_UPDATE: {
                        actions: 'setPassCounterUpdate',
                    },
                },
            },
            editSign: {
                on: {
                    GLOBAL_VIEW: 'globalView',
                    EDIT_PLACES: { target: 'editPlaces', actions: 'setEditPlaces' },
                    EDIT_DISPLAYS: { target: 'editDisplays', actions: 'setEditDisplays' },
                    EDIT_ZONES: { target: 'editZones', actions: 'setEditZones' },
                    EDIT_PASS_COUNT: { target: 'editPassCount', actions: 'setEditPassCount' },
                    CALIBRATION: 'calibration',
                    SET_VIEW_MODE: {
                        target: 'loadSensors',
                        actions: 'setViewMode',
                    },
                    SENSOR_UPDATE: {
                        actions: 'setSensorsUpdate',
                    },
                    LPSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateLPSensor',
                    },
                    TCMSENSOR_EDIT_CONFIGURATION: {
                        actions: 'updateTCMSensor',
                    },
                    PLACECAMERA_EDIT_CONFIGURATION: {
                        actions: 'updatePlaceCamera',
                    },
                    TCMSENSOR_EDIT_CALIBRATION: {
                        actions: 'updateTCMSensorCalibration',
                    },
                    LPSENSOR_UPDATE: {
                        actions: 'setLPSensorsUpdate',
                    },
                    CAMERA_SENSOR_UPDATE: {
                        actions: 'setCameraSensorsUpdate',
                    },
                    DISPLAY_UPDATE: {
                        actions: 'setDisplaysUpdate',
                    },
                    UPDATE_TCM_DISPLAY: {
                        actions: 'updateTCMDisplay',
                    },
                    UPDATE_LP_MATRIX_DISPLAY: {
                        actions: 'updateLPMatrixDisplay',
                    },
                    LPMATRIXDISPLAY_V2_UPDATE: {
                        actions: 'setLPMatrixDisplayV2Update',
                    },
                    RELOAD: 'loadZones',
                    TOGGLE_SENSOR_STATE: {
                        actions: 'setSensorStateDisplayed',
                    },
                    UPDATE_LOGIC_COUNTING_SYNC: {
                        actions: 'setLogicCountingSync',
                    },
                    COUNTER_UPDATE: {
                        actions: 'updateCounter',
                    },
                    VEHICLE_COUNTER_UPDATE: {
                        actions: 'setPassCounterUpdate',
                    },
                },
            },
            calibration: {
                entry: 'setCalibrationView',
                on: {
                    GLOBAL_VIEW: 'globalView',
                    EDIT_PLACES: { target: 'editPlaces', actions: 'setEditPlaces' },
                    EDIT_DISPLAYS: { target: 'editDisplays', actions: 'setEditDisplays' },
                    EDIT_ZONES: { target: 'editZones', actions: 'setEditZones' },
                    EDIT_PASS_COUNT: { target: 'editPassCount', actions: 'setEditPassCount' },
                    EDIT_SIGN: { target: 'editSign', actions: 'setEditSign' },
                    SET_VIEW_MODE: {
                        target: 'loadSensors',
                        actions: 'setViewMode',
                    },
                    SENSOR_UPDATE: {
                        actions: 'setSensorsUpdate',
                    },
                    LPSENSOR_UPDATE: {
                        actions: 'setLPSensorsUpdate',
                    },
                    CAMERA_SENSOR_UPDATE: {
                        actions: 'setCameraSensorsUpdate',
                    },
                    DISPLAY_UPDATE: {
                        actions: 'setDisplaysUpdate',
                    },
                    UPDATE_TCM_DISPLAY: {
                        actions: 'updateTCMDisplay',
                    },
                    LPMATRIXDISPLAY_V2_UPDATE: {
                        actions: 'setLPMatrixDisplayV2Update',
                    },
                    RELOAD: 'loadLevel',
                    TOGGLE_SENSOR_STATE: {
                        actions: 'setSensorStateDisplayed',
                    },
                    UPDATE_LOGIC_COUNTING_SYNC: {
                        actions: 'setLogicCountingSync',
                    },
                    COUNTER_UPDATE: {
                        actions: 'updateCounter',
                    },
                },
            },
        },
    },
    {
        actions: {
            //PLACE TYPES
            setSensorPlaceTypes: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorPlaceTypes: (_, event: Record<string, any>) => event.data,
            }),
            setSensorPlaceTypeFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorPlaceTypeFailure: true,
            }),
            unsetSensorPlaceTypeFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorPlaceTypeFailure: false,
            }),
            // CONTROLLERS
            setControllers: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                controllers: (_, event: Record<string, any>) => event.data,
            }),
            setControllerFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                controllerFailure: true,
            }),
            unsetControllerFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                controllerFailure: false,
            }),
            // LEVELS
            setLevels: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                level: (_, event: Record<string, any>) => event.data,
                loading: false,
            }),
            setLevelFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                levelFailure: true,
            }),
            unsetLevelFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                levelFailure: false,
            }),
            // SENSORS
            setSensors: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                tcmSensors: (_, event: Record<string, any>) => event.data.tcmSensors,
                lpSensors: (_, event: Record<string, any>) => event.data.lpSensors,
                placeCameraSensors: (_, event: Record<string, any>) => event.data.placesCamera,
                loading: false,
            }),
            setSensorFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorFailure: true,
            }),
            unsetSensorFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorFailure: false,
            }),
            // DISPLAYS
            setDisplays: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                displays: (_, event: Record<string, any>) => event.data.tcmDisplays,
                lpMatrixDisplayV2: (_, event: Record<string, any>) => event.data.lpMatrixDisplaysV2,
                loading: false,
            }),
            setDisplayFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                displayFailure: true,
            }),
            unsetDisplayFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                displayFailure: false,
            }),
            // ZONES
            setZones: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                zones: (_, event: Record<string, any>) => event.data,
                loading: false,
            }),
            setZoneFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                zoneFailure: true,
            }),
            unsetZoneFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                zoneFailure: false,
            }),
            // VEHICLE COUNTERS
            setVehicleCounters: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                vehicleCounters: (_, event: Record<string, any>) => event.data,
                loading: false,
            }),
            setVehicleCountersFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                vehicleCounterFailure: true,
            }),
            unsetVehicleCountersFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                vehicleCounterFailure: false,
            }),
            // PROGRESS BARS
            setProgressBars: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                progress: (_, event: Record<string, any>) => {
                    const progressBars = event.data;
                    let parkingsCounters: any = [];
                    let levelsCounters: any = [];
                    let zonesCounters: any = [];

                    for (let progress in progressBars) {
                        // INIT PROGRESS BARS VALUES
                        progressBars[progress].counterValue = {
                            all: {
                                free: 0,
                                occupied: 0,
                                overstayOccupied: 0,
                                overstayFree: 0,
                                forced: 0,
                                total: 0,
                            },
                        };

                        if (progressBars[progress].type === 'Park') parkingsCounters.push(progressBars[progress]);
                        if (progressBars[progress].type === 'Level') levelsCounters.push(progressBars[progress]);
                        if (progressBars[progress].type === 'Zone' || progressBars[progress].type === 'cpt vehicle') {
                            zonesCounters.push(progressBars[progress]);
                        }
                    }

                    zonesCounters.sort((a, b) => {
                        return a.label.localeCompare(b.label, 'fr', {
                            numeric: true,
                            ignorePunctuation: true,
                            sensitivity: 'variant',
                            caseFirst: 'upper',
                        });
                    });

                    return [parkingsCounters, levelsCounters, zonesCounters];
                },
                loading: false,
            }),
            setProgressBarFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                progressBarsFailure: true,
            }),
            unsetProgressBarFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                progressBarsFailure: false,
            }),
            // PROGRESS STATUS
            setProgressStatus: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                progress: (context: MapActionsMachineContext, event: Record<string, any>) => {
                    // GET STATUS DATA
                    const status = event.data;
                    // RECREATE AN ARRAY TO EASILY LOOP OVER
                    const counters = context.progress.flat();
                    // INIT TYPES
                    let parkingsCounters: any[] = [];
                    let levelsCounters: any[] = [];
                    let zonesCounters: any[] = [];

                    for (let c in counters) {
                        // SET VALUES TO COUNTERS
                        for (let s in status) {
                            if (counters[c].id === status[s].id) {
                                counters[c].counterValue = status[s].counterValue;
                            }
                        }

                        // RESET TYPES WITH VALUES
                        if (counters[c].type === 'Park') parkingsCounters.push(counters[c]);
                        if (counters[c].type === 'Level') {
                            const foundLevel = context.levels.find(level => level.name === counters[c].label);
                            levelsCounters.push({
                                ...counters[c],
                                order: foundLevel?.order,
                                parking: foundLevel?.parking,
                            });
                        }
                        if (counters[c].type === 'Zone' || counters[c].type === 'cpt vehicle') {
                            zonesCounters.push(counters[c]);
                        }
                    }

                    zonesCounters.sort((a, b) => {
                        return a.label.localeCompare(b.label, 'fr', {
                            numeric: true,
                            ignorePunctuation: true,
                            sensitivity: 'variant',
                            caseFirst: 'upper',
                        });
                    });

                    return [parkingsCounters, levelsCounters, zonesCounters];
                },
                progressLoading: false,
            }),
            setProgressStatusFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                progressStatusFailure: true,
            }),
            unsetProgressStatusFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                progressStatusFailure: false,
            }),
            // CHECK ALL LEVELS
            setAllLevelsStatus: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                levels: (_, event: Record<string, any>) => event.data,
                needUpdate: (_, event: Record<string, any>) => event.data.some(level => level.isUpToDate === false),
                loading: false,
            }),
            setAllLevelsStatusFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                checkAllLevelsFailure: true,
            }),
            unsetAllLevelsStatusFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                checkAllLevelsFailure: false,
            }),
            // SENSOR UPDATE
            setSensorsUpdate: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                tcmSensors: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.tcmSensors.map(tcmSensor => {
                        if (tcmSensor.sensor.id === event.sensor.id) {
                            let sensor: TCMSensor = { ...tcmSensor.sensor, ...event.sensor };
                            tcmSensor.setSensor(sensor);
                        }
                        return tcmSensor;
                    }),
            }),
            setLPSensorsUpdate: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                lpSensors: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.lpSensors.map(lpSensor => {
                        if (lpSensor.sensor.id === event.sensor.id) {
                            let updatedSensor = event;
                            lpSensor.setSensor(updatedSensor);
                        }
                        return lpSensor;
                    }),
            }),
            updateLPSensor: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                lpSensors: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.lpSensors.map(lpSensor => {
                        if (lpSensor.id === event.sensor.id) {
                            let sensor: LPSensor = { ...lpSensor.sensor, ...event.sensor };
                            const findSensorPlaceType = sensorPlaceTypes.find(
                                spt => spt.id === event.sensor.lpSensor.sensorPlaceType.id
                            );
                            lpSensor.updateSensor(sensor, findSensorPlaceType);
                        }
                        return lpSensor;
                    }),
            }),
            // TCM SENSOR CONFIG UPDATE
            updateTCMSensor: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                tcmSensors: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.tcmSensors.map(tcmSensor => {
                        if (tcmSensor.id === event.sensor.id) {
                            let sensor: TCMSensor = { ...tcmSensor.sensor, ...event.sensor };
                            const findSensorPlaceType = sensorPlaceTypes.find(
                                spt => spt.id === event.sensor.tcmSensor.sensorPlaceType.id
                            );
                            tcmSensor.updateSensor(sensor, findSensorPlaceType);
                        }
                        return tcmSensor;
                    }),
            }),
            // TCM SENSOR CALIBRATION CONFIG
            updateTCMSensorCalibration: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                tcmSensors: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.tcmSensors.map(tcmSensor => {
                        if (tcmSensor.sensor.id === event.sensor.id) {
                            let sensor: TCMSensor = { ...tcmSensor.sensor, ...event.sensor };
                            tcmSensor.updateSensorCalibration(sensor);
                        }
                        return tcmSensor;
                    }),
            }),
            // PLACE CAMERA
            updatePlaceCamera: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                placeCameraSensors: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.placeCameraSensors.map(placeCameraSensor => {
                        if (placeCameraSensor.id === event.sensor.id) {
                            let sensor: PlaceCamera = { ...placeCameraSensor, ...event.sensor };
                            const findSensorPlaceType = sensorPlaceTypes.find(
                                spt => spt.id === event.sensor.placeCamera.sensorPlaceType.id
                            );
                            placeCameraSensor.updateSensor(sensor, findSensorPlaceType);
                        }
                        return placeCameraSensor;
                    }),
            }),
            // PLACE CAMERA UPDATE
            setCameraSensorsUpdate: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                placeCameraSensors: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.placeCameraSensors.map(placeCameraSensor => {
                        if (placeCameraSensor.getPlaceCamera().getId() === event.sensor.id) {
                            // placeCameraSensor.setPlaceCamera(event.sensor);
                            // let sensor: PlaceCamera = { ...placeCameraSensor.getPlaceCamera(), ...event.sensor };
                            placeCameraSensor.updateSensorWS(event.sensor);
                        }
                        return placeCameraSensor;
                    }),
            }),
            // DISPLAY UPDATE
            setDisplaysUpdate: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                displays: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.displays.map(display => {
                        if (display.tcmDisplay.id === event.display.id) {
                            let tcmDisplay = { ...display.tcmDisplay, ...event.display };
                            return { ...display, tcmDisplay: tcmDisplay };
                        } else {
                            return display;
                        }
                    }),
            }),
            // UPDATE TCM DISPLAY
            updateTCMDisplay: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                displays: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.displays.map(display => {
                        if (display.id === event.display.id) {
                            let tcmDisplay = { ...display.tcmDisplay, ...event.display.tcmDisplay };
                            return { ...display, ...event.display, tcmDisplay: tcmDisplay };
                        } else {
                            return display;
                        }
                    }),
            }),
            updateLPMatrixDisplay: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                lpMatrixDisplayV2: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.lpMatrixDisplayV2.map(display => {
                        if (display.id === event.display.id) {
                            display.updateDisplay(event.display);
                            return display;
                        } else {
                            return display;
                        }
                    }),
            }),
            setLPMatrixDisplayV2Update: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                lpMatrixDisplayV2: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.lpMatrixDisplayV2.map(display => {
                        if (display.getLpMatrixDisplayV2().getId() === event.display.id) {
                            display.websocketUpdateDisplay(event.display);
                        }
                        return display;
                    }),
            }),
            // UPDATE PASS COUNTER
            setPassCounterUpdate: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                vehicleCounters: (context: MapActionsMachineContext, event: Record<string, any>) =>
                    context.vehicleCounters.map(counter => {
                        if (counter.getVehicleCounter().getId() === event.vehicleCounter.id) {
                            counter.updateVehicleCounter(event.vehicleCounter);
                        }

                        return counter;
                    }),
            }),
            // SET SENSOR STATE DISPLAYED
            setSensorStateDisplayed: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorStateDisplayed: (context: MapActionsMachineContext) => !context.sensorStateDisplayed,
            }),
            // UPDATE LOGIC COUNTING SYNC
            setLogicCountingSync: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                logicCountingSync: (_, event: Record<string, any>) => event.value,
            }),
            // LOADING
            setLoading: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                loading: true,
            }),
            // VIEW MODE
            setViewMode: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorLayer: true,
                displayLayer: true,
                zoneLayer: false,
                passCountLayer: true,
                previousMode: 'SET_VIEW_MODE',
                isEdit: false,
            }),
            // EDIT MODE
            setEditMode: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorLayer: true,
                displayLayer: true,
                zoneLayer: true,
                passCountLayer: true,
                previousMode: 'EDIT',
                isEdit: true,
            }),
            // SET EDIT PLACES
            setEditPlaces: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                previousMode: 'editPlaces',
            }),
            // SET EDIT DISPLAYS
            setEditDisplays: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                previousMode: 'editDisplays',
            }),
            // SET EDIT ZONES
            setEditZones: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                previousMode: 'editZones',
            }),
            // SET EDIT PASS COUNT
            setEditPassCount: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                previousMode: 'editPassCount',
            }),
            // SET EDIT PASS COUNT
            setEditSign: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                previousMode: 'editSign',
            }),
            // GLOBAL VIEW
            setGlobalView: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorLayer: true,
                displayLayer: true,
                zoneLayer: true,
                passCountLayer: true,
                previousMode: 'globalView',
                isEdit: true,
            }),
            setSensorView: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorLayer: true,
                displayLayer: false,
                zoneLayer: false,
                passCountLayer: false,
                previousMode: 'sensorView',
                isEdit: true,
            }),
            setDisplayView: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorLayer: true,
                displayLayer: true,
                zoneLayer: false,
                passCountLayer: false,
                previousMode: 'displayView',
                isEdit: true,
            }),
            setZoneView: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorLayer: true,
                displayLayer: false,
                zoneLayer: true,
                passCountLayer: false,
                previousMode: 'zoneView',
                isEdit: true,
            }),
            setPassCountView: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorLayer: true,
                displayLayer: false,
                zoneLayer: false,
                passCountLayer: true,
                previousMode: 'passCountView',
                isEdit: true,
            }),
            setCalibrationView: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                sensorLayer: false,
                displayLayer: false,
                zoneLayer: false,
                passCountLayer: false,
                previousMode: 'calibrationView',
                isEdit: true,
            }),
            setEngineStatusFailure: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                getEngineStatusFailure: false,
            }),
            setEngineStatus: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                logicCountingSync: (_, event: Record<string, any>) => event.data.logicCountingSync,
                loading: false,
            }),
            updateCounter: assign<MapActionsMachineContext, MapActionsMachineEvent>({
                progress: (context, event: Record<string, any>) => {
                    // GET STATUS DATA
                    const status = event.data;
                    // RECREATE AN ARRAY TO EASILY LOOP OVER
                    const counters = context.progress.flat();
                    // INIT TYPES
                    let parkingsCounters: any[] = [];
                    let levelsCounters: any[] = [];
                    let zonesCounters: any[] = [];

                    for (let c in counters) {
                        // SET NEW VALUE TO COUNTER
                        if (counters[c].id === status.id) {
                            counters[c].counterValue = status.counterValue;
                        }

                        // RESET TYPES WITH VALUES
                        if (counters[c].type === 'Park') parkingsCounters.push(counters[c]);
                        if (counters[c].type === 'Level') {
                            const foundLevel = context.levels.find(level => level.name === counters[c].label);
                            levelsCounters.push({
                                ...counters[c],
                                order: foundLevel?.order,
                                parking: foundLevel?.parking,
                            });
                        }
                        if (counters[c].type === 'Zone' || counters[c].type === 'cpt vehicle')
                            zonesCounters.push(counters[c]);
                    }

                    zonesCounters.sort((a, b) => {
                        return a.label.localeCompare(b.label, 'fr', {
                            numeric: true,
                            ignorePunctuation: true,
                            sensitivity: 'variant',
                            caseFirst: 'upper',
                        });
                    });

                    return [parkingsCounters, levelsCounters, zonesCounters];
                },
            }),
        },
    }
);
