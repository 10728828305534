import { faArrowUp, faCheck, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React from 'react';
import { CompactPicker } from 'react-color';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Input,
    Modal,
    Panel,
    SelectPicker,
    Toggle
} from 'rsuite';
import { LPDisplay } from '../../../../../../../handlers/lpDisplays/LPDisplay';
import {
    VirtualDisplayInterface,
    VirtualDisplayPreviewInformation,
} from '../../../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { authHeader } from '../../../../../../../redux/helpers';
import { axiosService } from '../../../../../../../redux/services';
import { hexToRgb } from '../../../../../../../utils/colorUtils';
import PanelHeader from '../../../../../../Custom/PanelHeader';
import { EditUserTextModal } from '../../../../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Modal/EditUserTextModal';
import { LPMatrixDisplayWidget } from '../../../../Widgets/LPMatrixDisplayWidget';
import {
    LPVirtualDisplayArrow,
    LPVirtualDisplayArrowAnimation,
    LPVirtualDisplayArrowOnMap,
    LPVirtualDisplayMode,
    LPVirtualDisplayPicto,
    LPVirtualDisplayPosition,
    LPVirtualDisplayRGBPicto,
    LPVirtualDisplaySize,
    LPVirtualDisplayStateFormValues,
    LPVirtualDisplayStepColor,
    LPVirtualDisplaySystemText,
    LPVirtualDisplayTextTransformation,
    LPVirtualDisplayTriggerSteps,
    LPVirtualDisplayTriggerType,
    LPVirtualDisplayTriggerVirtualDisplay,
    LPVirtualDisplayUserText,
} from '../../Form/LPVirtualDisplayStepFormValues';

type Props = {
    display: LPDisplay;
    virtualDisplay: VirtualDisplayPreviewInformation;
    show: boolean;
    onHide: Function;
    step: VirtualDisplayStep;
    updateInformation: Function;
} & WrappedComponentProps;

type State = {
    formValue: FormValue;
    isCreating: boolean;
    hasError: boolean;
    previewStep: VirtualDisplayStep;
    editUserTextModalInformation: {
        isOpen: boolean;
        userTextIndex: number;
    };
};

type FormValue = {
    state: number;
    color: string;
    mode: number;
    textTransformation: number;
    transition: number;
    time: number;
    pictoSize: number;
    picto: number;
    pictoPosition: number;
    arrowSize: number;
    arrowOnMap: number;
    arrow: number;
    arrowPosition: number;
    arrowAnimation: number;
    arrowRepeat: boolean;
    text: number;
    userText: number;
    triggerMinType: number;
    triggerMinValue: number;
    triggerMinAction: number;
    triggerMaxType: number;
    triggerMaxValue: number;
    triggerMaxAction: number;
};

class EditLPVirtualDisplayStepModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isCreating: false,
            hasError: false,
            editUserTextModalInformation: {
                isOpen: false,
                userTextIndex: -1,
            },
            formValue: {
                state: this.props.step.getState(),
                color: this.props.step.getColorHex(),
                mode: this.props.step.getMode(),
                textTransformation: this.props.step.getTextTransformation(),
                transition: this.props.step.getTransition(),
                time: this.props.step.getTime(),
                pictoSize: this.props.step.getPictoSize(),
                picto: this.props.step.getPicto(),
                pictoPosition: this.props.step.getPictoPositionNumber(),
                arrowSize: this.props.step.getArrowSize(),
                arrow: this.props.step.getArrow(),
                arrowOnMap: this.props.step.getArrowOnMap(),
                arrowPosition: this.props.step.getArrowPositionNumber(),
                arrowAnimation: this.props.step.getArrowAnimationNumber(),
                arrowRepeat: this.props.step.getArrowRepeat(),
                text: this.props.step.getTextNumber(),
                userText: this.props.step.getUserTextNumber(),
                triggerMinType: this.props.step.getTriggerMin().getTriggerType(),
                triggerMinAction: this.props.step.getTriggerMin().getActionValue(),
                triggerMinValue: this.props.step.getTriggerMin().getValue(),
                triggerMaxType: this.props.step.getTriggerMax().getTriggerType(),
                triggerMaxAction: this.props.step.getTriggerMax().getActionValue(),
                triggerMaxValue: this.props.step.getTriggerMax().getValue(),
            },
            previewStep: new VirtualDisplayStep({
                state: this.props.step.getState(),
                color: this.props.step.getColor(),
                mode: this.props.step.getMode(),
                textTransformation: this.props.step.getTextTransformation(),
                transition: this.props.step.getTransition(),
                time: this.props.step.getTime(),
                pictoSize: this.props.step.getPictoSize(),
                picto: this.props.step.getPicto(),
                arrowSize: this.props.step.getTotalArrowSize(),
                arrow: this.props.step.getArrow(),
                arrowOnMap: this.props.step.getArrowOnMap(),
                stepOrder: this.props.step.getStepOrder(),
                triggerMin: {
                    value: this.props.step.getTriggerMin().getValue(),
                    action: this.props.step.getTriggerMin().getActionValue(),
                },
                triggerMax: {
                    value: this.props.step.getTriggerMax().getValue(),
                    action: this.props.step.getTriggerMax().getActionValue(),
                },
            }),
        };
    }

    componentDidUpdate = (prevProps: Props) => {
        if (
            this.props.step.getStepOrder() !== prevProps.step.getStepOrder() ||
            this.props.virtualDisplay.id !== prevProps.virtualDisplay.id
        ) {
            this.setState({
                isCreating: false,
                hasError: false,
                formValue: {
                    state: this.props.step.getState(),
                    color: this.props.step.getColorHex(),
                    mode: this.props.step.getMode(),
                    textTransformation: this.props.step.getTextTransformation(),
                    transition: this.props.step.getTransition(),
                    time: this.props.step.getTime(),
                    pictoSize: this.props.step.getPictoSize(),
                    picto: this.props.step.getPicto(),
                    pictoPosition: this.props.step.getPictoPositionNumber(),
                    arrowSize: this.props.step.getArrowSize(),
                    arrow: this.props.step.getArrow(),
                    arrowOnMap: this.props.step.getArrowOnMap(),
                    arrowPosition: this.props.step.getArrowPositionNumber(),
                    arrowAnimation: this.props.step.getArrowAnimationNumber(),
                    arrowRepeat: this.props.step.getArrowRepeat(),
                    text: this.props.step.getTextNumber(),
                    userText: this.props.step.getUserTextNumber(),
                    triggerMinType: this.props.step.getTriggerMin().getTriggerType(),
                    triggerMinAction: this.props.step.getTriggerMin().getActionValue(),
                    triggerMinValue: this.props.step.getTriggerMin().getValue(),
                    triggerMaxType: this.props.step.getTriggerMax().getTriggerType(),
                    triggerMaxAction: this.props.step.getTriggerMax().getActionValue(),
                    triggerMaxValue: this.props.step.getTriggerMax().getValue(),
                },
                previewStep: new VirtualDisplayStep({
                    state: this.props.step.getState(),
                    color: this.props.step.getColor(),
                    mode: this.props.step.getMode(),
                    textTransformation: this.props.step.getTextTransformation(),
                    transition: this.props.step.getTransition(),
                    time: this.props.step.getTime(),
                    pictoSize: this.props.step.getPictoSize(),
                    picto: this.props.step.getPicto(),
                    arrowSize: this.props.step.getTotalArrowSize(),
                    arrow: this.props.step.getArrow(),
                    arrowOnMap: this.props.step.getArrowOnMap(),
                    stepOrder: this.props.step.getStepOrder(),
                    triggerMin: {
                        value: this.props.step.getTriggerMin().getValue(),
                        action: this.props.step.getTriggerMin().getActionValue(),
                    },
                    triggerMax: {
                        value: this.props.step.getTriggerMax().getValue(),
                        action: this.props.step.getTriggerMax().getActionValue(),
                    },
                }),
            });
        }
    };

    handleFormChange = formValue => {
        if (formValue.mode !== 1 && formValue.arrowPosition === 0) formValue.arrowPosition = 1000;
        if (formValue.mode !== 2 && formValue.mode !== 20 && formValue.pictoPosition === 0)
            formValue.pictoPosition = 1000;

        if ((formValue.mode === 2 || formValue.mode === 20) && formValue.picto === 0) {
            formValue.picto = 1;
        }

        if (formValue.mode === 1 && formValue.arrow === 0) {
            formValue.arrow = 1;
        }

        if (formValue.arrow !== 0 && formValue.arrowOnMap === 0) {
            formValue.arrowOnMap = formValue.arrow;
        }

        this.state.previewStep.updateVirtualDisplayStep({
            state: parseInt(formValue.state),
            color: hexToRgb(formValue.color),
            mode: parseInt(formValue.mode),
            textTransformation: parseInt(formValue.textTransformation),
            transition: parseInt(formValue.transition),
            time: parseInt(formValue.time),
            pictoSize: parseInt(formValue.pictoSize) + parseInt(formValue.pictoPosition),
            picto: parseInt(formValue.picto),
            arrowSize:
                parseInt(formValue.arrowSize) +
                parseInt(formValue.arrowPosition) +
                parseInt(formValue.arrowAnimation) +
                (formValue.arrowRepeat ? 30000 : 0),
            arrow: parseInt(formValue.arrow),
            arrowOnMap: parseInt(formValue.arrowOnMap),
            stepOrder: 0,
            triggerMin: {
                value: parseInt(formValue.triggerMinValue),
                action: parseInt(formValue.triggerMinAction),
            },
            triggerMax: {
                value: parseInt(formValue.triggerMaxValue),
                action: parseInt(formValue.triggerMaxAction),
            },
        });

        this.setState({
            formValue: {
                state: parseInt(formValue.state),
                color: formValue.color,
                mode: parseInt(formValue.mode),
                textTransformation: parseInt(formValue.textTransformation),
                transition: parseInt(formValue.transition),
                time: parseInt(formValue.time),
                pictoSize: parseInt(formValue.pictoSize),
                picto: parseInt(formValue.picto),
                pictoPosition: parseInt(formValue.pictoPosition),
                arrowSize: parseInt(formValue.arrowSize),
                arrow: parseInt(formValue.arrow),
                arrowOnMap: parseInt(formValue.arrowOnMap),
                arrowPosition: parseInt(formValue.arrowPosition),
                arrowAnimation: parseInt(formValue.arrowAnimation),
                arrowRepeat: formValue.arrowRepeat,
                text: parseInt(formValue.text),
                userText: parseInt(formValue.userText),
                triggerMinType: parseInt(formValue.triggerMinType),
                triggerMinAction: parseInt(formValue.triggerMinAction),
                triggerMinValue: parseInt(formValue.triggerMinValue),
                triggerMaxAction: parseInt(formValue.triggerMaxAction),
                triggerMaxValue: parseInt(formValue.triggerMaxValue),
                triggerMaxType: parseInt(formValue.triggerMaxType),
            },
        });
    };

    handleColorChange = color => {
        this.state.previewStep.updateVirtualDisplayStep({
            state: this.state.formValue.state,
            color: { red: color.rgb.r, green: color.rgb.g, blue: color.rgb.b },
            mode: this.state.formValue.mode,
            textTransformation: this.state.formValue.textTransformation,
            transition: this.state.formValue.transition,
            time: this.state.formValue.time,
            pictoSize: this.state.formValue.pictoSize + this.state.formValue.pictoPosition,
            picto: this.state.formValue.picto,
            arrowSize:
                this.state.formValue.arrowSize +
                this.state.formValue.arrowPosition +
                this.state.formValue.arrowAnimation +
                (this.state.formValue.arrowRepeat ? 30000 : 0),
            arrow: this.state.formValue.arrow,
            arrowOnMap: this.state.formValue.arrowOnMap,
            stepOrder: 0,
            triggerMin: {
                value: this.state.formValue.triggerMinValue,
                action: 0,
            },
            triggerMax: {
                value: this.state.formValue.triggerMaxValue,
                action: 0,
            },
        });

        this.setState({
            formValue: {
                ...this.state.formValue,
                color: color.hex.toUpperCase(),
            },
        });
    };

    hideModal = () => {
        // let triggerMaxType = this.props.step.getTriggerMax().getAction() === ;
        this.setState({
            isCreating: false,
            hasError: false,
            formValue: {
                state: this.props.step.getState(),
                color: this.props.step.getColorHex(),
                mode: this.props.step.getMode(),
                textTransformation: this.props.step.getTextTransformation(),
                transition: this.props.step.getTransition(),
                time: this.props.step.getTime(),
                pictoSize: this.props.step.getPictoSize(),
                picto: this.props.step.getPicto(),
                pictoPosition: this.props.step.getPictoPositionNumber(),
                arrowSize: this.props.step.getArrowSize(),
                arrow: this.props.step.getArrow(),
                arrowOnMap: this.props.step.getArrowOnMap(),
                arrowPosition: this.props.step.getArrowPositionNumber(),
                arrowAnimation: this.props.step.getArrowAnimationNumber(),
                arrowRepeat: this.props.step.getArrowRepeat(),
                text: this.props.step.getTextNumber(),
                userText: this.props.step.getUserTextNumber(),
                triggerMinType: this.props.step.getTriggerMin().getTriggerType(),
                triggerMinAction: this.props.step.getTriggerMin().getActionValue(),
                triggerMinValue: this.props.step.getTriggerMin().getValue(),
                triggerMaxType: this.props.step.getTriggerMax().getTriggerType(),
                triggerMaxAction: this.props.step.getTriggerMax().getActionValue(),
                triggerMaxValue: this.props.step.getTriggerMax().getValue(),
            },
            previewStep: new VirtualDisplayStep({
                state: this.props.step.getState(),
                color: this.props.step.getColor(),
                mode: this.props.step.getMode(),
                textTransformation: this.props.step.getTextTransformation(),
                transition: this.props.step.getTransition(),
                time: this.props.step.getTime(),
                pictoSize: this.props.step.getPictoSize(),
                picto: this.props.step.getPicto(),
                arrowSize: this.props.step.getTotalArrowSize(),
                arrow: this.props.step.getArrow(),
                arrowOnMap: this.props.step.getArrowOnMap(),
                stepOrder: this.props.step.getStepOrder(),
                triggerMin: {
                    value: this.props.step.getTriggerMin().getValue(),
                    action: this.props.step.getTriggerMin().getActionValue(),
                },
                triggerMax: {
                    value: this.props.step.getTriggerMax().getValue(),
                    action: this.props.step.getTriggerMax().getActionValue(),
                },
            }),
        });

        this.props.onHide();
    };

    updateStep = () => {
        this.setState({
            isCreating: true,
            hasError: false,
        });

        const {
            state,
            color,
            mode,
            textTransformation,
            transition,
            time,
            pictoSize,
            picto,
            pictoPosition,
            arrowSize,
            arrow,
            arrowOnMap,
            arrowPosition,
            arrowAnimation,
            arrowRepeat,
            triggerMinValue,
            triggerMinType,
            triggerMinAction,
            triggerMaxValue,
            triggerMaxType,
            triggerMaxAction,
        } = this.state.formValue;

        const data = {
            state,
            color: hexToRgb(color),
            mode,
            textTransformation,
            transition,
            orderStep: this.props.step.getStepOrder(),
            time,
            pictoSize: pictoSize + pictoPosition,
            picto,
            arrowSize: arrowSize + arrowPosition + arrowAnimation + (arrowRepeat ? 30000 : 0),
            arrowOnMap: arrow === 0 ? 0 : arrowOnMap,
            arrow,
            triggerMin: {
                value: triggerMinValue,
                action: triggerMinType < 0 ? 0 : triggerMinType + triggerMinAction,
            },
            triggerMax: {
                value: triggerMaxValue,
                action: triggerMaxType < 0 ? 0 : triggerMaxType + triggerMaxAction,
            },
            id_virtualDisplay: this.props.virtualDisplay.id,
        };


        axiosService
            .getAxios()
            .put('/devices/lp-matrixdisplays-v2/virtualDisplay/step/updateConfig', data, {
                headers: authHeader(),
            })
            .then((editVirtualDisplayStepResponse: AxiosResponse<VirtualDisplayInterface>) => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.edit.success',
                    }),
                    5000
                );

                this.props.updateInformation(editVirtualDisplayStepResponse.data);

                // ActionObserver.emit(ActionObserverEvent.UPDATE_VIRTUAL_DISPLAY_STEPS, {
                //     virtualDisplayId: this.props.virtualDisplay.id,
                //     color: editVirtualDisplayStepResponse.data.backgroundColor,
                //     label: this.props.label,
                // });

                // this.props.virtualDisplay.updateVirtualDisplaySteps(editVirtualDisplayStepResponse.data.steps);

                this.props.onHide(true);
            })
            .catch(err => {
                console.error(err);
                Alert.error(
                    this.props.intl.formatMessage(
                        {
                            id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.edit.error',
                        },
                        {
                            err,
                        }
                    ),
                    5000
                );
            })
            .finally(() => {
                this.setState({
                    isCreating: false,
                });
            });
    };

    renderArrowSection = () => (
        <Panel
            className="panel-small"
            shaded
            bordered
            header={
                <PanelHeader
                    title={this.props.intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.edit.arrowPart',
                    })}
                    buttons={[]}
                />
            }>
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.arrow" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-edit-step-arrow"
                            name="arrow"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayArrow(this.props.intl)}
                            renderMenuItem={(label, item: any) => {
                                if (item.value === 0) return label;

                                return (
                                    <FontAwesomeIcon
                                        data-cy={`lpDisplay-edit-step-arrow-${item.value}`}
                                        icon={faArrowUp}
                                        transform={{ rotate: item.orientation }}
                                    />
                                );
                            }}
                            renderValue={(value, item: any) => {
                                if (value === 0) return item.label;
                                return <FontAwesomeIcon icon={faArrowUp} transform={{ rotate: item.orientation }} />;
                            }}
                            placement="top"
                            searchable={false}
                            cleanable={false}
                            disabledItemValues={this.state.formValue.mode === 1 ? [0] : []}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.arrowOnMap" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-edit-step-arrowOnMap"
                            disabled={this.state.formValue.arrow === 0}
                            name="arrowOnMap"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayArrowOnMap(this.props.intl)}
                            renderMenuItem={(label, item: any) => {
                                if (item.value === 0) return label;

                                return (
                                    <FontAwesomeIcon
                                        data-cy={`lpDisplay-edit-step-arrowOnMap-${item.value}`}
                                        icon={faArrowUp}
                                        transform={{ rotate: item.orientation }}
                                    />
                                );
                            }}
                            renderValue={(value, item: any) => {
                                if (value === 0) return item.label;
                                return <FontAwesomeIcon icon={faArrowUp} transform={{ rotate: item.orientation }} />;
                            }}
                            placement="top"
                            searchable={false}
                            cleanable={false}
                            disabledItemValues={[0]}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === ARROW SIZE === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.arrowSize" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-edit-step-arrowSize"
                            name="arrowSize"
                            accepter={SelectPicker}
                            data={LPVirtualDisplaySize(this.props.intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={this.state.formValue.arrow === 0}
                            placement="top"
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-edit-step-arrowSize-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === ARROW POSITION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.arrowPosition" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-edit-step-arrowPosition"
                            name="arrowPosition"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayPosition(this.props.intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={this.state.formValue.arrow === 0}
                            placement="top"
                            disabledItemValues={this.state.formValue.mode !== 1 ? [0] : []}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-edit-step-arrowPosition-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === ARROW ANIMATION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.arrowAnimation" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-edit-step-arrowAnimation"
                            name="arrowAnimation"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayArrowAnimation(this.props.intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={this.state.formValue.arrow === 0}
                            placement="top"
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-edit-step-arrowAnimation-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === ARROW REPEAT === */}
            {this.state.formValue.mode === 1 && (
                <FormGroup>
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={10}>
                            <ControlLabel>
                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.arrowRepeat" />
                            </ControlLabel>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>
                            <FormControl
                                data-cy="lpDisplay-add-step-arrowRepeat"
                                name="arrowRepeat"
                                accepter={Toggle}
                                defaultChecked={this.props.step.getArrowRepeat()}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FormGroup>
            )}
        </Panel>
    );

    renderRGBPictoSection = () => (
        <Panel
            className="panel-small"
            shaded
            bordered
            header={
                <PanelHeader
                    title={this.props.intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.pictoPart',
                    })}
                    buttons={[]}
                />
            }>
            {/* === PICTO STYLE === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.picto" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            name="picto"
                            data-cy="lpDisplay-add-step-rgb-picto"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayRGBPicto()}
                            placement="top"
                            searchable={false}
                            cleanable={false}
                            disabledItemValues={this.state.formValue.mode === 2 ? [0] : []}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === PICTO POSITION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.create.pictoPosition" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-add-step-pictoPosition"
                            name="pictoPosition"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayPosition(this.props.intl)}
                            searchable={false}
                            cleanable={false}
                            disabled={this.state.formValue.picto === 0}
                            placement="top"
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-add-step-pictoPosition-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>
        </Panel>
    );

    renderPictoSection = () => (
        <Panel
            className="panel-small"
            shaded
            bordered
            header={
                <PanelHeader
                    title={this.props.intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.pictoPart',
                    })}
                    buttons={[]}
                />
            }>
            {/* === PICTO STYLE === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.picto" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-edit-step-picto"
                            name="picto"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayPicto(this.props.intl)}
                            placement="top"
                            renderMenuItem={label => (
                                <div data-cy="lpDisplay-edit-step-picto-number">
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item style={{ height: 20, width: 20 }}>{label}</FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </div>
                            )}
                            renderValue={(_, item: any) => (
                                <div>
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item style={{ height: 20, width: 20 }}>
                                            {item.label}
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </div>
                            )}
                            searchable={false}
                            cleanable={false}
                            disabledItemValues={this.state.formValue.mode === 2 ? [0] : []}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === PICTO SIZE === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.pictoSize" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-edit-step-pictoSize"
                            name="pictoSize"
                            accepter={SelectPicker}
                            data={LPVirtualDisplaySize(this.props.intl)}
                            placement="top"
                            searchable={false}
                            cleanable={false}
                            disabled={this.state.formValue.picto === 0}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-edit-step-pictoSize-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>

            {/* === PICTO POSITION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.pictoPosition" />
                        </ControlLabel>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-add-step-pictoPosition"
                            name="pictoPosition"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayPosition(this.props.intl)}
                            placement="top"
                            searchable={false}
                            cleanable={false}
                            disabled={this.state.formValue.picto === 0}
                            disabledItemValues={this.state.formValue.mode !== 2 ? [0] : []}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-add-step-pictoPosition-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>
        </Panel>
    );

    renderTextSection = () => (
        <Panel
            className="panel-small"
            shaded
            bordered
            header={
                <PanelHeader
                    title={this.props.intl.formatMessage({
                        id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.textPart',
                    })}
                    buttons={[]}
                />
            }>
            {/* === TEXT TRANSFORMATION === */}
            <FormGroup>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={10}>
                        <ControlLabel>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.textTransformation" />
                        </ControlLabel>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                        <FormControl
                            data-cy="lpDisplay-virtualDisplay-textTransformation"
                            name="textTransformation"
                            accepter={SelectPicker}
                            data={LPVirtualDisplayTextTransformation(this.props.intl)}
                            searchable={false}
                            cleanable={false}
                            placement="top"
                            renderMenuItem={(label, item) => {
                                return (
                                    <div data-cy={`lpDisplay-virtualDisplay-step-textTransformation-${item.value}`}>
                                        {label}
                                    </div>
                                );
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FormGroup>
        </Panel>
    );

    renderTriggerMin = () => {
        return (
            <Panel
                className="panel-small"
                shaded
                bordered
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({
                            id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.triggerMin',
                        })}
                        buttons={[]}
                    />
                }>
                {/* === TRIGGER MIN TYPE === */}
                <FormGroup>
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={10}>
                            <ControlLabel>
                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMinType" />
                            </ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="lpDisplay-edit-step-triggerMinType"
                                name="triggerMinType"
                                accepter={SelectPicker}
                                data={LPVirtualDisplayTriggerType(this.props.intl)}
                                searchable={false}
                                cleanable={false}
                                placement="top"
                                renderMenuItem={(label, item) => {
                                    return (
                                        <div data-cy={`lpDisplay-virtualDisplay-step-triggerMinType-${item.value}`}>
                                            {label}
                                        </div>
                                    );
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FormGroup>

                {this.state.formValue.triggerMinType === 0 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMinAction" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-step-triggerMinAction"
                                    name="triggerMinAction"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplayTriggerSteps(this.props.intl, this.props.virtualDisplay)}
                                    searchable={false}
                                    cleanable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div
                                                data-cy={`lpDisplay-virtualDisplay-step-triggerMinAction-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}

                {this.state.formValue.triggerMinType === 100 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMinAction" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-step-triggerMinAction"
                                    name="triggerMinAction"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplayTriggerVirtualDisplay(
                                        this.props.intl,
                                        this.props.display.getPreviewInformation()
                                    )}
                                    searchable={false}
                                    cleanable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div
                                                data-cy={`lpDisplay-virtualDisplay-step-triggerMinAction-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}

                {this.state.formValue.triggerMinType === 1000 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMinAction" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-step-triggerMinAction"
                                    name="triggerMinAction"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplaySystemText(this.props.intl)}
                                    searchable={false}
                                    cleanable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div
                                                data-cy={`lpDisplay-virtualDisplay-step-triggerMinAction-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}

                {this.state.formValue.triggerMinType === 2000 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMinAction" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-step-triggerMinAction"
                                    name="triggerMinAction"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplayUserText(this.props.display.getTextConfig().getUserText())}
                                    searchable={false}
                                    cleanable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div
                                                data-cy={`lpDisplay-virtualDisplay-step-triggerMinAction-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}

                {this.state.formValue.triggerMinType !== -1 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMinValue" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl name="triggerMinValue" accepter={Input} type="number" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}
            </Panel>
        );
    };

    renderTriggerMax = () => {
        return (
            <Panel
                className="panel-small"
                shaded
                bordered
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({
                            id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.triggerMax',
                        })}
                        buttons={[]}
                    />
                }>
                {/* === TRIGGER MAX TYPE === */}
                <FormGroup>
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={10}>
                            <ControlLabel>
                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMaxType" />
                            </ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="lpDisplay-edit-step-triggerMaxType"
                                name="triggerMaxType"
                                accepter={SelectPicker}
                                data={LPVirtualDisplayTriggerType(this.props.intl)}
                                searchable={false}
                                cleanable={false}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <div data-cy={`lpDisplay-virtualDisplay-step-triggerMaxType-${item.value}`}>
                                            {label}
                                        </div>
                                    );
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FormGroup>

                {this.state.formValue.triggerMaxType === 0 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMaxAction" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    name="triggerMaxAction"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplayTriggerSteps(this.props.intl, this.props.virtualDisplay)}
                                    searchable={false}
                                    cleanable={false}
                                    placement="auto"
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}

                {this.state.formValue.triggerMaxType === 100 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMaxAction" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-step-triggerMaxAction"
                                    name="triggerMaxAction"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplayTriggerVirtualDisplay(
                                        this.props.intl,
                                        this.props.display.getPreviewInformation()
                                    )}
                                    searchable={false}
                                    cleanable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div
                                                data-cy={`lpDisplay-virtualDisplay-step-triggerMaxAction-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}

                {this.state.formValue.triggerMaxType === 1000 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMaxAction" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-step-triggerMaxAction"
                                    name="triggerMaxAction"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplaySystemText(this.props.intl)}
                                    searchable={false}
                                    cleanable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div
                                                data-cy={`lpDisplay-virtualDisplay-step-triggerMaxAction-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}

                {this.state.formValue.triggerMaxType === 2000 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMaxAction" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-step-triggerMaxAction"
                                    name="triggerMaxAction"
                                    accepter={SelectPicker}
                                    data={LPVirtualDisplayUserText(this.props.display.getTextConfig().getUserText())}
                                    searchable={false}
                                    cleanable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        return (
                                            <div
                                                data-cy={`lpDisplay-virtualDisplay-step-triggerMaxAction-${item.value}`}>
                                                {label}
                                            </div>
                                        );
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}

                {this.state.formValue.triggerMaxType !== -1 && (
                    <FormGroup>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                <ControlLabel>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.triggerMaxValue" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl name="triggerMaxValue" accepter={Input} type="number" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FormGroup>
                )}
            </Panel>
        );
    };

    render = () => {
        const mode = this.state.formValue.mode;
        const shouldShowArrowPart =
            mode === 1 || (mode >= 101 && mode <= 132) || (mode >= 201 && mode <= 232) || (mode >= 301 && mode <= 308);
        const shouldShowPictoPart =
            mode === 2 || (mode >= 101 && mode <= 132) || (mode >= 201 && mode <= 232) || (mode >= 301 && mode <= 308);
        const shouldShowRGBPictoPart = mode === 20;
        const shouldShowTextPart = (mode >= 101 && mode <= 308) || (mode >= 5 && mode <= 7);
        const shouldShowCounterPart = mode >= 101 && mode <= 132;

        return (
            <>
                <EditUserTextModal
                    display={this.props.display}
                    editUserInformation={this.state.editUserTextModalInformation}
                    onHide={() => this.setState({ editUserTextModalInformation: { isOpen: false, userTextIndex: -1 } })}
                />
                <Modal show={this.props.show} onHide={this.hideModal} backdrop="static" size="md">
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit" />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form fluid onChange={this.handleFormChange} formValue={this.state.formValue}>
                            <FlexboxGrid justify="center" style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                                <FlexboxGrid.Item className="margin-bottom-10">
                                    <LPMatrixDisplayWidget
                                        display={this.props.display}
                                        backgroundColor={this.props.virtualDisplay.backgroundColor}
                                        step={this.state.previewStep}
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <Panel
                                className="panel-small"
                                shaded
                                bordered
                                header={
                                    <PanelHeader
                                        title={this.props.intl.formatMessage({
                                            id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.configuration',
                                        })}
                                        buttons={[]}
                                    />
                                }>
                                {/* === STATE === */}
                                <FormGroup>
                                    <FlexboxGrid align="middle" justify="space-between">
                                        <FlexboxGrid.Item componentClass={Col} xs={10}>
                                            <ControlLabel>
                                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.state" />
                                            </ControlLabel>
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <FormControl
                                                data-cy="virtualDisplay-step-state"
                                                name="state"
                                                accepter={SelectPicker}
                                                data={LPVirtualDisplayStateFormValues(this.props.intl)}
                                                searchable={false}
                                                cleanable={false}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`virtualDisplay-step-state-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </FormGroup>

                                {/* === COLOR === */}
                                {mode !== 20 && (
                                    <FormGroup>
                                        <FlexboxGrid align="middle" justify="space-between">
                                            <FlexboxGrid.Item componentClass={Col} xs={10}>
                                                <ControlLabel>
                                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.color" />
                                                </ControlLabel>
                                            </FlexboxGrid.Item>

                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={12}
                                                className="padding-top-5 padding-bottom-5">
                                                <CompactPicker
                                                    colors={LPVirtualDisplayStepColor()}
                                                    color={this.state.formValue.color}
                                                    onChangeComplete={this.handleColorChange}
                                                />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </FormGroup>
                                )}
                                {/* === MODE === */}
                                <FormGroup>
                                    <FlexboxGrid align="middle" justify="space-between">
                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <ControlLabel>
                                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.mode" />
                                            </ControlLabel>
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item
                                            componentClass={Col}
                                            xs={
                                                this.state.formValue.mode >= 301 && this.state.formValue.mode <= 308
                                                    ? 10
                                                    : 12
                                            }>
                                            <FormControl
                                                data-cy="lpDisplay-edit-step-mode"
                                                name="mode"
                                                accepter={SelectPicker}
                                                data={LPVirtualDisplayMode(
                                                    this.props.intl,
                                                    this.props.display.getPreviewInformation()
                                                )}
                                                searchable={false}
                                                cleanable={false}
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div data-cy={`lpDisplay-edit-step-mode-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </FlexboxGrid.Item>
                                        {this.state.formValue.mode >= 301 && this.state.formValue.mode <= 308 && (
                                            <FlexboxGrid.Item componentClass={Col} xs={2}>
                                                <Button
                                                    appearance="link"
                                                    onClick={() =>
                                                        this.setState({
                                                            editUserTextModalInformation: {
                                                                isOpen: true,
                                                                userTextIndex: this.state.formValue.mode - 301,
                                                            },
                                                        })
                                                    }>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                            </FlexboxGrid.Item>
                                        )}
                                    </FlexboxGrid>
                                </FormGroup>

                                {/* === TIME === */}
                                <FormGroup>
                                    <FlexboxGrid align="middle" justify="space-between">
                                        <FlexboxGrid.Item componentClass={Col} xs={10}>
                                            <ControlLabel>
                                                <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.steps.edit.time" />
                                            </ControlLabel>
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <FormControl
                                                data-cy="lpDisplay-edit-step-time"
                                                name="time"
                                                min={0}
                                                type="number"
                                            />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </FormGroup>
                            </Panel>

                            {shouldShowArrowPart && this.renderArrowSection()}
                            {shouldShowPictoPart && this.renderPictoSection()}

                            {shouldShowRGBPictoPart && this.renderRGBPictoSection()}

                            {shouldShowTextPart && this.renderTextSection()}

                            {shouldShowCounterPart && this.renderTriggerMin()}
                            {shouldShowCounterPart && this.renderTriggerMax()}
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <FlexboxGrid align="middle" justify="end">
                            <FlexboxGrid.Item>
                                <ButtonGroup>
                                    <Button
                                        data-cy="lpDisplay-edit-step-cancel"
                                        onClick={() => this.hideModal()}
                                        color="red"
                                        disabled={this.state.isCreating}>
                                        <FontAwesomeIcon icon={faTimesCircle} />
                                    </Button>
                                    <Button
                                        data-cy="lpDisplay-add-edit-valid"
                                        onClick={this.updateStep}
                                        appearance="primary"
                                        loading={this.state.isCreating}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </ButtonGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };
}

export default injectIntl(EditLPVirtualDisplayStepModal);
