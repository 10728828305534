import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

export default function calibrationCell(rowData) {
    if (!rowData.state)
        return <FontAwesomeIcon data-tag="allowRowEvents" size="lg" icon={faTimes} style={{ color: 'red' }} />;

    return (
        <span data-cy="sensor-calibration" data-tag="allowRowEvents">
            <FontAwesomeIcon
                data-tag="allowRowEvents"
                className="margin-right-10"
                size="lg"
                icon={faCheck}
                style={{ color: 'green' }}
            />{' '}
            {(rowData.value * 3.264).toFixed(0)} cm
        </span>
    );
}

calibrationCell.propTypes = {
    rowData: PropTypes.object,
    dataKey: PropTypes.string,
};
