import { assign, Machine } from 'xstate';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

interface CalibrationMachineStateSchema {
    states: {
        entrypoint: {};
        inputValue: {};
        sendValue: {};
        error: {};
        updated: {};
    };
}

type CalibrationMachineEvent =
    | { type: 'VALID_CALIBRATION'; calibration: number }
    | { type: 'CANCEL_CALIBRATION' }
    | { type: 'EDIT_CALIBRATION' }
    | { type: 'UPDATE_CALIBRATION' }
    | { type: 'CANCEL_CALIBRATION' }
    | { type: 'CALIBRATION_ERROR' }
    | { type: 'CALIBRATION_SUCCESS' };

interface CalibrationMachineContext {
    calibration: number;
    loading: boolean;
    error: boolean;
    calibrationModalOpen: boolean;
    cm: number;
    levelId: number;
    calibrationError: boolean;
}

export const CalibrationMachine = Machine<
    CalibrationMachineContext,
    CalibrationMachineStateSchema,
    CalibrationMachineEvent
>(
    {
        key: 'calibration',
        id: 'calibration',
        initial: 'entrypoint',
        context: {
            calibration: 0,
            loading: false,
            error: false,
            calibrationModalOpen: false,
            cm: 0,
            levelId: 0,
            calibrationError: true,
        },
        states: {
            entrypoint: {
                on: {
                    VALID_CALIBRATION: {
                        target: 'inputValue',
                        actions: 'openCalibrationModal',
                    },
                    CANCEL_CALIBRATION: {
                        target: 'entrypoint',
                        actions: 'closeCalibrationModal',
                    },
                },
            },
            inputValue: {
                on: {
                    EDIT_CALIBRATION: {
                        target: 'inputValue',
                        actions: 'editCalibration',
                    },
                    UPDATE_CALIBRATION: {
                        target: 'sendValue',
                        actions: 'setLoading',
                    },
                    CANCEL_CALIBRATION: {
                        target: 'entrypoint',
                        actions: 'cancelCalibration',
                    },
                },
            },
            sendValue: {
                invoke: {
                    src: context => cb => {
                        const { levelId, calibration, cm } = context;

                        return axiosService
                            .getAxios()
                            .put(
                                '/levels/updateCalibration',
                                {
                                    id: levelId,
                                    cm,
                                    place: calibration,
                                },
                                { headers: authHeader() }
                            )
                            .then(() => {
                                cb('CALIBRATION_SUCCESS');
                            })
                            .catch(() => {
                                cb('CALIBRATION_ERROR');
                            });
                    },
                },
                on: {
                    CALIBRATION_ERROR: {
                        target: 'error',
                        actions: 'setCalibrationError',
                    },
                    CALIBRATION_SUCCESS: {
                        target: 'updated',
                        actions: 'setCalibrationSuccess',
                    },
                },
            },
            error: {
                on: {
                    CALIBRATION_ERROR: {
                        target: 'inputValue',
                        actions: 'setError',
                    },
                },
            },
            updated: {
                on: {
                    CALIBRATION_SUCCESS: {
                        target: 'entrypoint',
                        actions: 'setSuccess',
                    },
                },
            },
        },
    },
    {
        actions: {
            openCalibrationModal: assign<CalibrationMachineContext, CalibrationMachineEvent>({
                calibrationModalOpen: true,
                calibration: (_, event: Record<string, any>): number => event.calibration,
                cm: 0,
            }),
            closeCalibrationModal: assign<CalibrationMachineContext, CalibrationMachineEvent>({
                calibrationModalOpen: false,
                cm: 0,
            }),
            editCalibration: assign<CalibrationMachineContext, CalibrationMachineEvent>({
                cm: (_, event: Record<string, any>) => parseInt(event.cm) || 0,
                calibrationError: (_, event: Record<string, any>) => parseInt(event.cm || 0) <= 0,
                error: false,
            }),
            cancelCalibration: assign<CalibrationMachineContext, CalibrationMachineEvent>({
                calibrationModalOpen: false,
                cm: 0,
                error: false,
                calibration: 0,
            }),
            setLoading: assign<CalibrationMachineContext, CalibrationMachineEvent>({
                loading: true,
            }),
            setCalibrationError: assign<CalibrationMachineContext, CalibrationMachineEvent>({
                loading: false,
                calibrationModalOpen: false,
            }),
            setCalibrationSuccess: assign<CalibrationMachineContext, CalibrationMachineEvent>({
                loading: false,
                calibrationModalOpen: false,
                calibration: 0,
                cm: 0,
            }),
            setError: assign<CalibrationMachineContext, CalibrationMachineEvent>({
                error: true,
            }),
            setSuccess: assign<CalibrationMachineContext, CalibrationMachineEvent>({
                calibrationModalOpen: false,
                calibration: 0,
            }),
        },
    }
);
