import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { FlexboxGrid } from 'rsuite';
import { DisplayPreviewInformation } from '../../../../../../../../handlers/lpDisplays/LPDisplay';
import { LPDisplayForceTypeCountersInterface } from '../../../../../../../../handlers/lpDisplays/LPDisplayForceType';
import { VirtualDisplayStep } from '../../../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { RenderLPDisplayIcon } from '../../../../../../../IpCanElementsPage/LPDisplays/Icons/RenderLPDisplayIcon';
import { LPMatrixDisplayV2CounterForceType } from '../../LPMatrixDisplayFactory';

type Props = {
    step: VirtualDisplayStep;
    backgroundColor: string;
    display: DisplayPreviewInformation;
    isForce: boolean;
    forceMode?: LPDisplayForceTypeCountersInterface;
};

export const LPMatrixDisplayForceWidget = ({ step, backgroundColor, display, isForce, forceMode }: Props) => {
    const intl = useIntl();

    const stepMode = step.getMode();

    const shouldShowArrow =
        (stepMode === 1 ||
            (stepMode >= 201 && stepMode <= 232) ||
            (stepMode >= 301 && stepMode <= 316) ||
            (stepMode >= 101 && stepMode <= 132)) &&
        step.getShouldDisplayArrow();

    const shouldShowPicto =
        (stepMode === 2 ||
            (stepMode >= 201 && stepMode <= 232) ||
            (stepMode >= 301 && stepMode <= 316) ||
            (stepMode >= 101 && stepMode <= 132)) &&
        step.getShouldDisplayPicto();

    let text = '';

    if (stepMode === 5) text = moment().format('HH:mm:ss');
    if (stepMode === 6) text = moment().format('DD/MM/YYYY');
    if (stepMode === 7) text = moment().format('DD/MM/YYYY HH:mm:ss');

    if (stepMode === 20) text = `Picto RGB ${step.getPicto()}`;

    if (stepMode >= 201 && stepMode <= 232) {
        let systemTextNumber = stepMode - 201;

        text = display.systemText[systemTextNumber][0];
    }

    if (stepMode >= 301 && stepMode <= 316) {
        let userTextNumber = stepMode - 301;

        text = display.userText[userTextNumber];
    }

    if (stepMode >= 101 && stepMode <= 132)
        text = intl.formatMessage(
            { id: 'ipCanDevices.lpDisplay.virtualDisplay.step.counter.value' },
            { counterNumber: step.getCounterNumber() }
        );

    const shouldDisplayLeftPart =
        (shouldShowArrow && step.getArrowPosition() !== 'right') ||
        (shouldShowPicto && step.getPictoPosition() !== 'right');

    let justifyContent: any = 'space-around';

    if (stepMode === 1) {
        if (step.getArrowPosition() === 'right') {
            justifyContent = 'end';
        }

        // if (step.getArrowPosition() === 'center' || step.getPictoPosition() === 'center') {
        //     justifyContent = 'space-around';
        // }

        if (step.getArrowPosition() === 'left') {
            justifyContent = 'start';
        }
    }

    if (stepMode === 2 || stepMode === 20) {
        if (step.getPictoPosition() === 'right') {
            justifyContent = 'end';
        }

        if (step.getPictoPosition() === 'left') {
            justifyContent = 'start';
        }
    }

    if (stepMode === 5 || stepMode === 6 || stepMode === 7) {
        justifyContent = 'space-around';
    }

    if (stepMode >= 101 && stepMode <= 132) {
        if (shouldShowArrow && shouldShowPicto) {
            justifyContent = 'space-around';
        }
    }

    return (
        <div className="matrix-display-demo" style={{ backgroundColor }}>
            <FlexboxGrid align="middle" justify={justifyContent}>
                {shouldDisplayLeftPart && (
                    <FlexboxGrid>
                        {shouldShowArrow && step.getArrowPosition() !== 'right' && (
                            <FlexboxGrid.Item style={{ overflow: 'hidden' }}>
                                <FontAwesomeIcon
                                    data-cy="lpDisplay-virtualDisplay-step-arrow"
                                    icon={faArrowUp}
                                    color={step.getColorHex()}
                                    transform={{ rotate: step.getArrowRotation() }}
                                    size="2x"
                                    className={step.getArrowAnimation()}
                                />
                            </FlexboxGrid.Item>
                        )}

                        {shouldShowPicto && step.getPictoPosition() !== 'right' && (
                            <FlexboxGrid.Item data-cy="lpDisplay-virtualDisplay-step-picto">
                                <RenderLPDisplayIcon color={step.getColorHex()} icon={step.getPictoImage()} />
                            </FlexboxGrid.Item>
                        )}
                    </FlexboxGrid>
                )}

                {text && (
                    <FlexboxGrid.Item
                        data-cy="lpDisplay-virtualDisplay-displayMatrix"
                        className="matrix-display-demo-text"
                        style={{ color: step.getColorHex() }}>
                        {text}
                        {isForce && forceMode && (
                            <span className="margin-left-5">
                                {forceMode.mode === LPMatrixDisplayV2CounterForceType.FORCE_VALUE &&
                                    forceMode.value === 0 &&
                                    `(${display.systemText[0][0]})`}

                                {forceMode.mode === LPMatrixDisplayV2CounterForceType.FORCE_VALUE &&
                                    forceMode.value !== 0 && <>({forceMode.value})</>}

                                {forceMode.mode === LPMatrixDisplayV2CounterForceType.FORCE_RELATIVE && (
                                    <>{forceMode.value >= 0 ? `(+${forceMode.value})` : `(${forceMode.value})`}</>
                                )}
                            </span>
                        )}
                    </FlexboxGrid.Item>
                )}

                {stepMode === 1 && step.getArrowRepeat() && (
                    <FlexboxGrid.Item style={{ overflow: 'hidden' }}>
                        <FontAwesomeIcon
                            icon={faArrowUp}
                            color={step.getColorHex()}
                            transform={{ rotate: step.getArrowRotation() }}
                            size="2x"
                            className={step.getArrowAnimation()}
                        />
                    </FlexboxGrid.Item>
                )}

                {shouldShowPicto && step.getPictoPosition() === 'right' && (
                    <FlexboxGrid.Item>
                        <RenderLPDisplayIcon color={step.getColorHex()} icon={step.getPictoImage()} />
                    </FlexboxGrid.Item>
                )}

                {shouldShowArrow && step.getArrowPosition() === 'right' && (
                    <FlexboxGrid.Item style={{ overflow: 'hidden' }}>
                        <FontAwesomeIcon
                            icon={faArrowUp}
                            color={step.getColorHex()}
                            transform={{ rotate: step.getArrowRotation() }}
                            size="2x"
                            className={step.getArrowAnimation()}
                        />
                    </FlexboxGrid.Item>
                )}
                {/* 
                {((shouldShowPicto && step.getPictoPosition() === 'right') ||
                    (shouldShowArrow && step.getArrowPosition() === 'right')) && (
                    <FlexboxGrid.Item style={{ overflow: 'hidden' }}>
                        {shouldShowPicto && step.getPictoPosition() === 'right' && (
                            <RenderLPDisplayIcon isMap color={step.getColorHex()} icon={step.getPictoImage()} />
                        )}

                        {shouldShowArrow && step.getArrowPosition() === 'right' && (
                            <FontAwesomeIcon
                                data-cy="lpDisplay-picto"
                                icon={faArrowUp}
                                color={step.getColorHex()}
                                transform={{ rotate: step.getArrowRotationOnMap() }}
                                size="lg"
                                className={step.getArrowAnimation()}
                            />
                        )}
                    </FlexboxGrid.Item>
                )} */}
            </FlexboxGrid>
        </div>
    );
};
