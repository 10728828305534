import { faCheck, faEye, faEyeSlash, faTag, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    InputGroup,
    Message,
    Modal,
    Toggle,
} from 'rsuite';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';

type Props = {
    zone: Record<string, any>;
    onHide: Function;
    show: boolean;
};

type State = {
    formValue: Record<string, any>;
    loading: boolean;
    error: boolean;
};
export default class EditZoneModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            formValue: {
                label: this.props.zone.label,
                isView: this.props.zone.isView,
            },
            loading: false,
            error: false,
        };

        this.updateZone = this.updateZone.bind(this);
    }

    updateZone() {
        this.setState({
            loading: true,
        });

        const { label, isView } = this.state.formValue;
        const data = {
            id: this.props.zone.id,
            label,
            isView,
        };

        axiosService
            .getAxios()
            .put('/map-zones/updateZone', data, { headers: authHeader() })
            .then(() => {
                this.setState({
                    loading: false,
                });

                this.props.onHide();
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    error: true,
                    loading: false,
                });
            });
    }

    render() {
        return (
            <SecuredFragment authorizedRoles={[rolesConstants.mapZones.UPDATE]}>
                <Modal backdrop="static" show={this.props.show} onHide={() => this.props.onHide()}>
                    <Modal.Header>
                        <Modal.Title>Edition de la calibration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error && (
                            <Message
                                className="margin-bottom-15"
                                type="error"
                                description="Une erreur s'est produite pendant la mise à jour de la zone"
                            />
                        )}
                        <Form
                            fluid
                            formValue={this.state.formValue}
                            onChange={formValue => this.setState({ formValue: formValue })}>
                            <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <ControlLabel>
                                        <FormattedMessage id="map.zone.label" />
                                    </ControlLabel>
                                    <InputGroup style={{ marginTop: 0 }}>
                                        <InputGroup.Addon>
                                            <FontAwesomeIcon icon={faTag} />
                                        </InputGroup.Addon>
                                        <FormControl data-cy="map-zone-label" name="label" />
                                    </InputGroup>
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <ControlLabel>
                                        <FormattedMessage id="map.zone.isShow" />
                                    </ControlLabel>
                                    <FormControl
                                        checked={this.state.formValue.isView}
                                        data-cy="map-Zone-isShow"
                                        size="lg"
                                        style={{ left: '35%' }}
                                        accepter={Toggle}
                                        checkedChildren={<FontAwesomeIcon icon={faEye} />}
                                        unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} />}
                                        name="isView"
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button data-cy="map-zone-cancel" onClick={() => this.props.onHide()} color="red">
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                data-cy="map-zone-valid"
                                onClick={this.updateZone}
                                loading={this.state.loading}
                                color="green">
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            </SecuredFragment>
        );
    }
}
