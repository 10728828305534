export const predefinedRoles = [
    { name: 'level1', roles: [13, 23, 53, 62, 63, 84, 93, 96, 104, 138, 139, 140, 142] },
    { name: 'level2', roles: [23, 61, 73, 146, 148, 150] },
    { name: 'level3', roles: [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31] },
    { name: 'administrator', roles: [1] },
];

export const rolesConstants = {
    administration: {
        ADMIN: generateRight('Administration', 'Admin'),
    },

    configuration: {
        VIEW_LIST: generateRight('Configuration', 'ViewList'),
        VIEW: generateRight('Configuration', 'View'),
        UPDATE_APP: generateRight('Configuration', 'UpdateApp'),
        CREATE: generateRight('Configuration', 'Create'),
        UPDATE: generateRight('Configuration', 'Update'),
        DELETE: generateRight('Configuration', 'Delete'),
        GET_DATABASE_INFORMATION: generateRight('Configuration', 'InfosDB'),
        RESET_DATABASE: generateRight('Configuration', 'ResetDB'),
        EXPORT_DATABASE: generateRight('Configuration', 'ExportDB'),
        IMPORT_DATABASE: generateRight('Configuration', 'ImportDB'),
        GET_API: generateRight('Configuration', 'GetAPI'),
        GET_ENGINE: generateRight('Configuration', 'GetEngine'),
        GET_REACT: generateRight('Configuration', 'GetReact'),
        GET_NETWORK: generateRight('Configuration', 'GetNetwork'),
        UPDATE_NETWORK: generateRight('Configuration', 'UpdateNetwork'),
        REBOOT_AUTOMATE: generateRight('Configuration', 'RebootAutomate'),
        UPDATE_SYSTEM: generateRight('Configuration', 'UpdateSystem'),
    },

    user: {
        CREATE: generateRight('User', 'Create'),
        DELETE: generateRight('User', 'Delete'),
        GET_STATE: generateRight('User', 'States'),
        VIEW_LIST: generateRight('User', 'ViewList'),
        VIEW: generateRight('User', 'View'),
        UPDATE_INFORMATIONS: generateRight('User', 'UpdateInformations'),
        UPDATE_PASSWORD: generateRight('User', 'UpdatePassword'),
        UPDATE_PROFILES: generateRight('User', 'UpdateProfiles'),
    },

    role: {
        CREATE: generateRight('Role', 'Create'),
        VIEW: generateRight('Role', 'View'),
        DELETE: generateRight('Role', 'Delete'),
        UPDATE: generateRight('Role', 'Update'),
        VIEW_LIST: generateRight('Role', 'ViewList'),
    },

    controller: {
        STATES: generateRight('Controller', 'States'),
        VIEW_LIST: generateRight('Controller', 'ViewList'),
        VIEW: generateRight('Controller', 'View'),
        CREATE: generateRight('Controller', 'Create'),
        DELETE: generateRight('Controller', 'Delete'),
        UPDATE: generateRight('Controller', 'Update'),
        UPDATE_BUS_CONFIGURATION: generateRight('Controller', 'UpdateBusConfiguration'),
        TEST_LED: generateRight('Controller', 'TestLed'),
        REBOOT: generateRight('Controller', 'Reboot'),
        ENUM_BUS: generateRight('Controller', 'EnumBus'),
        COMMAND_TCM: generateRight('Controller', 'CommandTCM'),
        COMMAND_LP: generateRight('Controller', 'CommandLP'),
        SET_MANY_ID: generateRight('Controller', 'SetManyID'),
        TEST_BUS: generateRight('Controller', 'TestBus'),
        SET_GPIO: generateRight('Controller', 'SetGPIO'),
        RESET_MEMORY: generateRight('Controller', 'ResetMemory'),
        UPDATE_TYPE: generateRight('Controller', 'UpdateType'),
        RELOAD_DEVICE_CONFIG: generateRight('Controller', 'ReloadDeviceConfig'),
        REPLACE_MODULE: generateRight('Controller', 'ReplaceModule'),
        RESET_BUS_COUNTER_ERROR: generateRight('Controller', 'ResetBusCounterError'),
        DELETE_BUS: generateRight('Controller', 'DeleteBus'),
        SET_ONE_ID: generateRight('Controller', 'SetOneID'),
    },

    devices: {
        NB_SENSORS_PLACE_TYPE: generateRight('Devices', 'NbSensorsOnSensorsPlaceType'),
        SENSORS_STATE: generateRight('Devices', 'SensorsState'),
        DISPLAYS_STATE: generateRight('Devices', 'DisplaysState'),
        DELETE_DEVICES: generateRight('Devices', 'DeleteDevices'),
    },

    TCMSensor: {
        STATES: generateRight('TCMSensor', 'States'),
        NB_SENSOR_PLACE_TYPE: generateRight('TCMSensor', 'NbSensorOnSensorPlaceType'),
        VIEW_LIST: generateRight('TCMSensor', 'ViewList'),
        VIEW: generateRight('TCMSensor', 'View'),
        CREATE: generateRight('TCMSensor', 'Create'),
        DELETE: generateRight('TCMSensor', 'Delete'),
        UPDATE_COLOR_CONFIG: generateRight('TCMSensor', 'UpdateColorConfig'),
        UPDATE_TIME_CONFIG: generateRight('TCMSensor', 'UpdateTimeConfig'),
        UPDATE_CALIBRATION_CONFIG: generateRight('TCMSensor', 'UpdateCalibrationConfig'),
        UPDATE_SENSOR_PLACE_TYPE: generateRight('TCMSensor', 'UpdateSensorPlaceType'),
        UPDATE_MAINTENANCE_STATE: generateRight('TCMSensor', 'UpdateMaintenanceState'),
        FORCE_SENSOR: generateRight('TCMSensor', 'ForceSensor'),
        COPY_CONFIG: generateRight('TCMSensor', 'CopyConfig'),
    },

    LPSensor: {
        STATES: generateRight('LPSensor', 'States'),
        NB_SENSOR_PLACE_TYPE: generateRight('LPSensor', 'NbSensorOnSensorPlaceType'),
        VIEW_LIST: generateRight('LPSensor', 'ViewList'),
        VIEW: generateRight('LPSensor', 'View'),
        CREATE: generateRight('LPSensor', 'Create'),
        DELETE: generateRight('LPSensor', 'Delete'),
        UPDATE_FILTER_CONFIG: generateRight('LPSensor', 'UpdateFilterConfig'),
        UPDATE_US_CONFIG: generateRight('LPSensor', 'UpdateUsConfig'),
        UPDATE_CONFIG: generateRight('LPSensor', 'UpdateConfig'),
        UPDATE_SENSOR_PLACE_TYPE: generateRight('LPSensor', 'UpdateSensorPlaceType'),
        UPDATE_MAINTENANCE_STATE: generateRight('LPSensor', 'UpdateMaintenanceState'),
        FORCE_SENSOR: generateRight('LPSensor', 'ForceSensor'),
        AUTO_CALIB_MODE: generateRight('LPSensor', 'AutoCalibMode'),
        COPY_CONFIG: generateRight('LPSensor', 'CopyConfig'),
    },

    TCMDisplay: {
        STATES: generateRight('TCMDisplay', 'States'),
        VIEW_LIST: generateRight('TCMDisplay', 'ViewList'),
        VIEW: generateRight('TCMDisplay', 'View'),
        CREATE: generateRight('TCMDisplay', 'Create'),
        DELETE: generateRight('TCMDisplay', 'Delete'),
        UPDATE_CONFIG: generateRight('TCMDisplay', 'UpdateConfig'),
        UPDATE_ARROW_CONFIG: generateRight('TCMDisplay', 'UpdateArrowConfig'),
        UPDATE_TYPE_CONFIG: generateRight('TCMDisplay', 'UpdateTypeConfig'),
        UPDATE_LANG_CONFIG: generateRight('TCMDisplay', 'UpdateLangConfig'),
        UPDATE_TEXT_CONFIG: generateRight('TCMDisplay', 'UpdateTextConfig'),
        UPDATE_CONFIG_TYPE: generateRight('TCMDisplay', 'UpdateConfigType'),
        UPDATE_MAINTENANCE_STATE: generateRight('TCMDisplay', 'UpdateMaintenanceState'),
        FORCE_DISPLAY: generateRight('TCMDisplay', 'ForceDisplay'),
        ADD_COUNTER: generateRight('TCMDisplay', 'AddCounter'),
        ADD_SUBSTRACTION_COUNTER: generateRight('TCMDisplay', 'AddSubstractionCounter'),
        REMOVE_COUNTER: generateRight('TCMDisplay', 'RemoveCounter'),
        REMOVE_SUBSTRACTION_COUNTER: generateRight('TCMDisplay', 'RemoveSubstractionCounter'),
    },

    parking: {
        VIEW: generateRight('Parking', 'View'),
        VIEW_LIST: generateRight('Parking', 'ViewList'),
        CREATE: generateRight('Parking', 'Create'),
        DELETE: generateRight('Parking', 'Delete'),
        UPDATE: generateRight('Parking', 'Update'),
        UPLOAD_PICTURE: generateRight('Parking', 'UploadPicture'),
    },

    counters: {
        STATES: generateRight('Counters', 'States'),
        VIEW_PROGRESS: generateRight('Counters', 'ViewProgress'),
        VIEW_DASHBOARD: generateRight('Counters', 'ViewDashboard'),
        VIEW_LIST: generateRight('Counters', 'ViewList'),
        VIEW: generateRight('Counters', 'View'),
        CREATE: generateRight('Counters', 'Create'),
        DELETE: generateRight('Counters', 'Delete'),
        UPDATE: generateRight('Counters', 'Update'),
        UPDATE_TCMSENSOR: generateRight('Counters', 'UpdateTCMSensor'),
        UPDATE_TCMDISPLAY: generateRight('Counters', 'UpdateTCMDisplay'),
        RELOAD: generateRight('Counters', 'Reload'),
    },

    engine: {
        SENSORS_HISTORIC: generateRight('Engine', 'SensorsHistoric'),
        STATUS: generateRight('Engine', 'Status'),
        SEND_STATE: generateRight('Engine', 'SendState'),
        RELOAD_CONFIG: generateRight('Engine', 'ReloadConfig'),
    },

    level: {
        VIEW: generateRight('Level', 'View'),
        VIEW_LIST: generateRight('Level', 'ViewList'),
        CREATE: generateRight('Level', 'Create'),
        DELETE: generateRight('Level', 'Delete'),
        UPDATE: generateRight('Level', 'Update'),
        UPDATE_LEVELS_ORDER: generateRight('Level', 'UpdateLevelsOrder'),
        UPDATE_PICTURE: generateRight('Level', 'UpdatePicture'),
        UPDATE_ZOOM: generateRight('Level', 'UpdateZoom'),
        UPDATE_CALIBRATION: generateRight('Level', 'UpdateCalibration'),
    },

    placeType: {
        VIEW: generateRight('PlaceType', 'View'),
        VIEW_LIST: generateRight('PlaceType', 'ViewList'),
        CREATE: generateRight('PlaceType', 'Create'),
        DELETE: generateRight('PlaceType', 'Delete'),
        UPDATE: generateRight('PlaceType', 'Update'),
        UPLOAD_PICTURE: generateRight('PlaceType', 'UploadPicture'),
    },

    sensorPlaceType: {
        VIEW: generateRight('SensorPlaceType', 'View'),
        VIEW_LIST: generateRight('SensorPlaceType', 'ViewList'),
        CREATE: generateRight('SensorPlaceType', 'Create'),
        DELETE: generateRight('SensorPlaceType', 'Delete'),
        UPDATE: generateRight('SensorPlaceType', 'Update'),
    },

    sceneSensorPlaceType: {
        VIEW: generateRight('SceneSensorPlaceType', 'View'),
        VIEW_LIST: generateRight('SceneSensorPlaceType', 'ViewList'),
        CREATE: generateRight('SceneSensorPlaceType', 'Create'),
        DELETE: generateRight('SceneSensorPlaceType', 'Delete'),
        UPDATE: generateRight('SceneSensorPlaceType', 'Update'),
    },

    mapTCMSensors: {
        VIEW: generateRight('MapTCMSensors', 'View'),
        VIEW_LIST: generateRight('MapTCMSensors', 'ViewList'),
        CREATE: generateRight('MapTCMSensors', 'Create'),
        DELETE: generateRight('MapTCMSensors', 'Delete'),
        UPDATE: generateRight('MapTCMSensors', 'Update'),
        UPDATE_SENSOR: generateRight('MapTCMSensors', 'UpdateSensor'),
    },

    mapTCMDisplays: {
        VIEW: generateRight('MapTCMDisplays', 'View'),
        VIEW_LIST: generateRight('MapTCMDisplays', 'ViewList'),
        CREATE: generateRight('MapTCMDisplays', 'Create'),
        DELETE: generateRight('MapTCMDisplays', 'Delete'),
        UPDATE: generateRight('MapTCMDisplays', 'Update'),
        UPDATE_DISPLAY: generateRight('MapTCMDisplays', 'UpdateDisplay'),
    },

    mapZones: {
        VIEW: generateRight('MapZones', 'View'),
        VIEW_LIST: generateRight('MapZones', 'ViewList'),
        CREATE: generateRight('MapZones', 'Create'),
        DELETE: generateRight('MapZones', 'Delete'),
        UPDATE: generateRight('MapZones', 'Update'),
        UPDATE_ALL_COUNTER_ZONE: generateRight('MapZones', 'UpdateAllCounterZone'),
    },

    mapVehicleCounters: {
        VIEW: generateRight('MapVehicleCounters', 'View'),
        VIEW_LIST: generateRight('MapVehicleCounters', 'ViewList'),
        CREATE: generateRight('MapVehicleCounters', 'Create'),
        DELETE: generateRight('MapVehicleCounters', 'Delete'),
        UPDATE: generateRight('MapVehicleCounters', 'Update'),
        UPDATE_SENSOR: generateRight('MapVehicleCounters', 'UpdateSensor'),
    },

    counterStats: {
        VIEW_LIST: generateRight('CounterStats', 'ViewList'),
        VIEW: generateRight('CounterStats', 'View'),
    },

    map: {
        VIEW_SENSORS_LIST: generateRight('Map', 'ViewSensorsList'),
        VIEW_DISPLAYS_LIST: generateRight('Map', 'ViewDisplaysList'),
        VIEW_ZONES_LIST: generateRight('Map', 'ViewZonesList'),
        VIEW_VEHICLES_COUNTERS_LIST: generateRight('Map', 'ViewVehiclesCountersList'),
        EDIT: generateRight('Map', 'Edit'),
        VIEW: generateRight('Map', 'View'),
    },

    eventTasks: {
        VIEW: generateRight('EventTasks', 'View'),
        VIEW_LIST: generateRight('EventTasks', 'ViewList'),
        CREATE: generateRight('EventTasks', 'Create'),
        DELETE: generateRight('EventTasks', 'Delete'),
        UPDATE: generateRight('EventTasks', 'Update'),
    },

    eventAction: {
        VIEW: generateRight('EventAction', 'View'),
        VIEW_LIST: generateRight('EventAction', 'ViewList'),
        VIEW_MAP_LIST: generateRight('EventAction', 'ViewMapList'),
        CREATE: generateRight('EventAction', 'Create'),
        DELETE: generateRight('EventAction', 'Delete'),
        UPDATE: generateRight('EventAction', 'Update'),
        PLAY: generateRight('EventAction', 'Play'),
        RESET_TRIGGER: generateRight('EventAction', 'ResetTrigger'),
    },

    lpMatrixDisplayV2: {
        STATES: generateRight('LPMatrixDisplayV2', 'States'),
        VIEW_LIST: generateRight('LPMatrixDisplayV2', 'ViewList'),
        VIEW: generateRight('LPMatrixDisplayV2', 'View'),
        CREATE: generateRight('LPMatrixDisplayV2', 'Create'),
        DELETE: generateRight('LPMatrixDisplayV2', 'Delete'),
        UPDATE_CONFIG: generateRight('LPMatrixDisplayV2', 'UpdateConfig'),
        UPDATE_TOPOLOGYS_CONFIG: generateRight('LPMatrixDisplayV2', 'UpdateTopologysConfig'),
        UPDATE_COUNTERS_CONFIG: generateRight('LPMatrixDisplayV2', 'UpdateCountersConfig'),
        UPDATE_MAINTENACE_STATE: generateRight('LPMatrixDisplayV2', 'UpdateMaintenanceState'),
        UPDATE_FORCE_TYPE: generateRight('LPMatrixDisplayV2', 'UpdateForceType'),
        UPDATE_DICTIONNARY_ENTRY: generateRight('LPMatrixDisplayV2', 'UpdateDictionnaryEntry'),
        UPDATE_USER_TEXTS: generateRight('LPMatrixDisplayV2', 'UpdateUserTexts'),
        CREATE_VIRTUAL_DISPLAY: generateRight('LPMatrixDisplayV2', 'CreateVirtualDisplay'),
        DELETE_VIRTUAL_DISPLAY: generateRight('LPMatrixDisplayV2', 'DeleteVirtualDisplay'),
        UPDATE_VIRTUAL_DISPLAY_CONFIG: generateRight('LPMatrixDisplayV2', 'UpdateVirtualDisplayConfig'),
        UPDATE_VIRTUAL_DISPLAY_ORDER: generateRight('LPMatrixDisplayV2', 'UpdateVirtualDisplayOrder'),
        VIEW_VIRTUAL_DISPLAY: generateRight('LPMatrixDisplayV2', 'ViewVirtualDisplay'),
        CREATE_VIRTUAL_DISPLAY_STEP: generateRight('LPMatrixDisplayV2', 'CreateVirtualDisplayStep'),
        DELETE_VIRTUAL_DISPLAY_STEP: generateRight('LPMatrixDisplayV2', 'DeleteVirtualDisplayStep'),
        UPDATE_VIRTUAL_DISPLAY_STEP_ORDER: generateRight('LPMatrixDisplayV2', 'UpdateVirtualDisplayStepOrder'),
        UPDATE_VIRTUAL_DISPLAY_STEP_CONFIG: generateRight('LPMatrixDisplayV2', 'UpdateVirtualDisplayStepConfig'),
        CMD_DEVICE: generateRight('LPMatrixDisplayV2', 'CmdDevice'),
        COPY_CONFIG: generateRight('LPMatrixDisplayV2', 'CopyConfig'),
        READ_FONT: generateRight('LPMatrixDisplayV2', 'ReadFont'),
        WRITE_FONT: generateRight('LPMatrixDisplayV2', 'WriteFont'),
        READ_PICTO: generateRight('LPMatrixDisplayV2', 'ReadPicto'),
        WRITE_PICTO: generateRight('LPMatrixDisplayV2', 'WritePicto'),
        VIEW_COUNTERS: generateRight('LPMatrixDisplayV2', 'ViewCounters'),
    },
    profile: {
        VIEW_LIST: generateRight('Profile', 'ViewList'),
        VIEW: generateRight('Profile', 'View'),
        CREATE: generateRight('Profile', 'Create'),
        DELETE: generateRight('Profile', 'Delete'),
        UPDATE: generateRight('Profile', 'Update'),
        UPDATE_ROLES: generateRight('Profile', 'UpdateRoles'),
        ADD_USER: generateRight('Profile', 'AddUser'),
        REMOVE_USER: generateRight('Profile', 'RemoveUser'),
    },
    ipCameras: {
        VIEW: generateRight('IpCameras', 'View'),
        VIEW_LIST: generateRight('IpCameras', 'ViewList'),
        CREATE: generateRight('IpCameras', 'Create'),
        DELETE: generateRight('IpCameras', 'Delete'),
        UPDATE: generateRight('IpCameras', 'Update'),
        UPDATE_MAINTENANCE_STATE: generateRight('IpCameras', 'UpdateMaintenanceState'),
        PREVIEW: generateRight('IpCameras', 'Preview'),
        MOVE_PTZ: generateRight('IpCameras', 'MovePtz'),
        STREAM: generateRight('IpCameras', 'Stream'),
        SAVE_PRESET: generateRight('IpCameras', 'SavePreset'),
        GO_TO_PRESET: generateRight('IpCameras', 'GoToPreset'),
        ALL_GO_TO_PRESET: generateRight('IpCameras', 'AllGoToPreset'),
    },
    placesCamera: {
        VIEW_LIST: generateRight('PlacesCamera', 'ViewList'),
        VIEW: generateRight('PlacesCamera', 'View'),
        CREATE: generateRight('PlacesCamera', 'Create'),
        DELETE: generateRight('PlacesCamera', 'Delete'),
        UPDATE: generateRight('PlacesCamera', 'Update'),
    },
    historics: {
        VIEW_LIST: generateRight('Historics', 'ViewList'),
        VIEW_IP_CAN_MODULE: generateRight('Historics', 'ViewIpCanModule'),
        VIEW_TCM_SENSOR: generateRight('Historics', 'ViewTcmSensor'),
        VIEW_TCM_DISPLAY: generateRight('Historics', 'ViewTcmDisplay'),
        VIEW_LP_SENSOR: generateRight('Historics', 'ViewLpSensor'),
        VIEW_LP_MATRIX_DISPLAY_V2: generateRight('Historics', 'ViewLpMatrixDisplayV2'),
        VIEW_IP_CAMERA: generateRight('Historics', 'ViewIpCamera'),
        VIEW_EVENT: generateRight('Historics', 'ViewEvent'),
    },
    restFull: {
        COUNTER_UPDATE: generateRight('RestFull', 'counterUpdate'),
        TCM_SENSOR_UPDATE: generateRight('RestFull', 'tcmSensorUpdate'),
        TCM_DISPLAY_UPDATE: generateRight('RestFull', 'tcmDisplayUpdate'),
        LP_SENSOR_UPDATE: generateRight('RestFull', 'lpSensorUpdate'),
        LP_DISPLAY_V2_UPDATE: generateRight('RestFull', 'lpDisplayV2Update'),
        PLACE_CAMERA_UPDATE: generateRight('RestFull', 'placeCameraUpdate'),
        IP_CAMERA_UPDATE: generateRight('RestFull', 'ipCameraUpdate'),
        COUNTER_STATISTIC_UPDATE: generateRight('RestFull', 'counterStatisticUpdate'),
        VEHICLE_COUNTER_UPDATE: generateRight('RestFull', 'vehicleCounterUpdate'),
        VIEW_LIST: generateRight('RestFull', 'iewList'),
        CREATE: generateRight('RestFull', 'Create'),
        DELETE: generateRight('RestFull', 'Delete'),
        UPDATE: generateRight('RestFull', 'Update'),
        VIEW: generateRight('RestFull', 'View'),
        CREATE_FOR_USER: generateRight('RestFull', 'CreateForUser'),
    },
    vehicleCounter: {
        VIEW_LIST: generateRight('VehicleCounter', 'ViewList'),
        VIEW: generateRight('VehicleCounter', 'View'),
        CREATE: generateRight('VehicleCounter', 'Create'),
        DELETE: generateRight('VehicleCounter', 'Delete'),
        UPDATE: generateRight('VehicleCounter', 'Update'),
        CREATE_EXTENSION: generateRight('VehicleCounter', 'CreateExtension'),
        DELETE_EXTENSION: generateRight('VehicleCounter', 'DeleteExtension'),
        UPDATE_EXTENSION: generateRight('VehicleCounter', 'UpdateExtension'),
        FORCE_VEHICLE_COUNTER: generateRight('VehicleCounter', 'ForceVehicleCounter'),
    },
};

function generateRight(section, name) {
    return {
        section: section,
        name: name,
    };
}
