import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Whisper } from 'rsuite';

export default function updateTime() {
    return (
        <div data-cy="historic-icon-updateTime">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.updateTime" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faClock} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}
