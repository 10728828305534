import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Message, Modal, Toggle } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    closeCreateConfigurationModal: Function;
    show: boolean;
};
type State = {
    error: boolean;
    loading: boolean;
    formValue: FormValue;
};

type FormValue = {
    section: string;
    key: string;
    value: string;
    isDeletable: boolean;
};
export default class AddConfigurationModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            formValue: {
                section: '',
                key: '',
                value: '',
                isDeletable: false,
            },
            error: false,
            loading: false,
        };

        this.createConfiguration = this.createConfiguration.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    // handleCheckboxChange = value => {
    //     this.setState({
    //         isDeletable: value,
    //     });
    // };

    handleChange(formValue) {
        this.setState({
            formValue: formValue,
        });
    }

    createConfiguration() {
        this.setState({
            loading: true,
        });

        const parameters = { headers: authHeader() };

        const { section, key, value, isDeletable } = this.state.formValue;

        const data = {
            section,
            key,
            value,
            isDeletable,
        };

        return axiosService
            .getAxios()
            .post('/configuration', data, parameters)
            .then(() => {
                this.setState({
                    loading: false,
                    formValue: {
                        section: '',
                        key: '',
                        value: '',
                        isDeletable: false,
                    },
                });

                this.props.closeCreateConfigurationModal(true);
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    loading: false,
                });
            });
    }

    handleCloseModal = () => {
        this.setState({
            formValue: {
                section: '',
                key: '',
                value: '',
                isDeletable: false,
            },
        });

        this.props.closeCreateConfigurationModal();
    };

    render() {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={() => this.handleCloseModal()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="configuration.advanced.addConfiguration.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="configuration-advanced-addModal">
                    {this.state.error && <Message type="error" description="Une erreur s'est produite..." />}
                    <Form fluid formValue={this.state.formValue} onChange={this.handleChange}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="configuration.advanced.addConfiguration.section" />
                            </ControlLabel>
                            <FormControl data-cy="configuration-advanced-addModal-section" name="section" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="configuration.advanced.addConfiguration.key" />
                            </ControlLabel>
                            <FormControl data-cy="configuration-advanced-addModal-key" name="key" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="configuration.advanced.addConfiguration.value" />
                            </ControlLabel>
                            <FormControl data-cy="configuration-advanced-addModal-value" name="value" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="configuration.advanced.addConfiguration.isDeletable" />
                            </ControlLabel>
                            <FormControl
                                accepter={Toggle}
                                data-cy="configuration-advanced-addModal-isDeletable"
                                name="isDeletable"
                            />
                        </FormGroup>
                        {/* <Checkbox
                            data-cy="configuration-advanced-addModal-isDeletable"
                            name="isDeletable"
                            value="isDeletable"
                            defaultChecked={this.state.formValue.isDeletable}>
                            <FormattedMessage id="configuration.advanced.addConfiguration.isDeletable" />
                        </Checkbox> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.handleCloseModal()}
                            data-cy="configuration-advanced-addModal-cancel"
                            color="red"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.createConfiguration}
                            data-cy="configuration-advanced-addModal-valid"
                            appearance="primary"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}
