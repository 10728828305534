import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Form, FormControl, List } from 'rsuite';
import { IPCameraAPIResponse } from '../../../../../handlers/Camera/ApiCamera';
import Camera from '../../../../../handlers/Camera/Camera';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    camera: Camera;
    setViewMode: () => void;
};

type FormValue = {
    ip: string;
    macStr: string;
    name: string;
    login: string;
    password: string;
};

export const InformationEditMode = (props: Props) => {
    const intl = useIntl();
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

    const [formValue, setFormValue] = React.useState<FormValue>({
        ip: props.camera.getIp(),
        macStr: props.camera.getMacStr(),
        name: props.camera.getName(),
        login: props.camera.getLogin(),
        password: props.camera.getPassword(),
    });

    const handleCancel = () => {
        setFormValue({
            ip: props.camera.getIp(),
            macStr: props.camera.getMacStr(),
            name: props.camera.getName(),
            login: props.camera.getLogin(),
            password: props.camera.getPassword(),
        });

        props.setViewMode();
    };

    const handleValid = () => {
        setIsUpdating(true);

        axiosService
            .getAxios()
            .put<IPCameraAPIResponse>(
                '/ip-cameras',
                {
                    id: props.camera.getId(),
                    ip: formValue.ip,
                    macStr: formValue.macStr,
                    name: formValue.name,
                    login: formValue.login,
                    password: formValue.password,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(ipcamerasResponse => {
                props.camera.updateCamera(ipcamerasResponse.data);

                Alert.success(intl.formatMessage({ id: 'ipcameras.update.success' }));

                setIsUpdating(false);

                props.setViewMode();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'ipcameras.update.error' }));

                setIsUpdating(false);
            });
    };

    return (
        <Form formValue={formValue} fluid onChange={formValue => setFormValue(formValue as FormValue)}>
            <List>
                {/* NAME */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="camera.information.name" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="name" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* IP */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="camera.information.ip" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="ip" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* MAC STR */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="camera.information.macStr" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="macStr" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* LOGIN */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="camera.information.login" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="login" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* PASSWORD */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="camera.information.password" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="password" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button color="red" onClick={handleCancel} disabled={isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button color="green" onClick={handleValid} loading={isUpdating}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );
};
