import { Col, FlexboxGrid } from 'rsuite';
import Counter from '../../../../handlers/Counter/Counter';
import { SensorPlaceType } from '../../../../handlers/sensorPlaceType/SensorPlaceType';

export default function CountersEditRow(
    counterConfig: { counters: number[]; sensorPlaceType: [number[]] },
    counters: Counter[],
    sensorPlaceTypes: SensorPlaceType[]
) {
    return (
        <div>
            <FlexboxGrid>
                <FlexboxGrid.Item componentClass={Col} xs={12}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum nemo iusto, maiores temporibus tenetur
                    sapiente ipsam? Assumenda velit similique distinctio cum ad, officia praesentium sint corporis sequi
                    in ab cupiditate!
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={12}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur, labore. Nam inventore quis
                    laboriosam amet beatae. Eos laboriosam facere mollitia, numquam rerum iure pariatur consectetur
                    similique nesciunt. Earum, nihil vero!
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
}
