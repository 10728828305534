import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Modal } from 'rsuite';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    database: string;
    show: boolean;
    close: Function;
};

type State = {
    loading: boolean;
};
export default class ExportDatabaseModal extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        this.setState({
            loading: true,
        });
        axiosService
            .getAxios()
            .get('/configuration/database/export/' + this.props.database, {
                headers: authHeader(),
                responseType: 'blob',
            })
            .then(response => {
                const splittedCD = response.headers['content-disposition'].split('=');
                const filename = splittedCD[1];
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.setState({
                    loading: false,
                });

                this.props.close();
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <Modal backdrop={'static'} show={this.props.show} onHide={() => this.props.close()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="configuration.database.exportDatabaseModal.title" /> -{' '}
                        {this.props.database}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy={`export-database-${this.props.database}`}>
                    <FormattedMessage id="configuration.database.exportDatabaseModal.confirmationMessage" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.props.close()}
                            data-cy="export-database-cancel"
                            color="red"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.handleSubmit}
                            data-cy="export-database-valid"
                            appearance="primary"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}
