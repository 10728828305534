import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { SyntheticEvent } from 'react';
import { Layer, Rect, Stage } from 'react-konva';
import { Button, FlexboxGrid, Tag } from 'rsuite';
import { PictoRGBValueInterface } from '..';

type Props = {
    picto: PictoRGBValueInterface;
    isSelected: boolean;
    isCopied: boolean;
    handleClickDelete: (index: number) => void;
    index: number;
};

const PREVIEW_SIZE = 80;

export const PictoPreview = (props: Props) => {
    const squareWidth = props.picto.value.length > 0 ? PREVIEW_SIZE / props.picto.value[0].length : 0;
    const squareHeight = PREVIEW_SIZE / props.picto.value.length;

    let squareSize = squareHeight;

    if (props.picto.value.length > 0 && props.picto.value.length < props.picto.value[0].length) {
        squareSize = squareWidth;
    }

    const handleClickDelete = (event: SyntheticEvent) => {
        event.stopPropagation();

        props.handleClickDelete(props.index);
    };

    const shouldDisplayLayer =
        squareWidth !== Infinity && squareWidth !== 0 && squareHeight !== Infinity && squareHeight !== 0;

    return (
        <FlexboxGrid
            justify="space-around"
            align="middle"
            className="padding-right-10 padding-left-10"
            style={{ backgroundColor: props.isSelected ? 'lightgreen' : '#FFF' }}>
            <FlexboxGrid.Item colspan={5}>
                <Tag color={props.isCopied ? 'orange' : 'blue'} className="bold">
                    {props.picto.name}
                </Tag>
                <br />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={10} className="padding-top-5 padding-bottom-5">
                {props.picto.value.length > 0 && squareSize && squareWidth && (
                    <Stage width={squareSize * props.picto.value[0].length} height={PREVIEW_SIZE}>
                        {shouldDisplayLayer && (
                            <Layer>
                                {props.picto.value.map((line, lineIndex) => {
                                    return line.map((pixel, pixelIndex) => (
                                        <Rect
                                            x={pixelIndex * squareSize}
                                            y={lineIndex * squareSize}
                                            width={squareSize}
                                            height={squareSize}
                                            fill={`rgb(${pixel.r}, ${pixel.g}, ${pixel.b})`}
                                        />
                                    ));
                                })}
                            </Layer>
                        )}
                    </Stage>
                )}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
                <Button color="red" size="sm" onClick={handleClickDelete}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};
