import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert } from 'rsuite';
import Counter from '../handlers/Counter/Counter';
import { authHeader } from '../redux/helpers';
import { axiosService } from '../redux/services';

export function useFetchCounter(counterId: number) {
    const intl = useIntl();

    const [counter, setCounter] = useState<Counter | null>(null);

    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/counters/${counterId}`, {
                headers: authHeader(),
            })
            .then(response => {
                setCounter(response.data);
            })
            .catch(error => {
                Alert.error(intl.formatMessage({ id: 'error.fetchCounterError' }));
                console.error(error);
            });
    }, [counterId]);

    return counter;
}
