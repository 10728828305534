import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';
import { LPSensorForceType } from '../../../handlers/ipCanDevices/Sensor/LPSensorForceType';

export function lpSensorForceCell(isForce: boolean, configForce: LPSensorForceType, endForceTime: string) {
    if (!isForce) return <FontAwesomeIcon data-cy="lpSensor-force" icon={faTimes} size="2x" color="red" />;

    if (configForce.forceType === 1) {
        return (
            <div className="text-center" data-tag="allowRowEvents">
                <Tag color="green" data-tag="allowRowEvents">
                    <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.sensorForce.free" />
                </Tag>
                <br />
                <small data-tag="allowRowEvents">{endForceTime}</small>
            </div>
        );
    }

    if (configForce.forceType === 2) {
        return (
            <div className="text-center" data-tag="allowRowEvents">
                <Tag color="orange" data-tag="allowRowEvents">
                    <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.sensorForce.occupied" />
                </Tag>
                <br />
                <small data-tag="allowRowEvents">{endForceTime}</small>
            </div>
        );
    }

    if (configForce.forceType === 3) {
        return (
            <div className="text-center" data-tag="allowRowEvents">
                <Tag color="red" data-tag="allowRowEvents">
                    <FormattedMessage
                        data-tag="allowRowEvents"
                        id="ipCanDevices.LPSensors.sensorForce.overstay_occupied"
                    />
                </Tag>
                <br />
                <small data-tag="allowRowEvents">{endForceTime}</small>
            </div>
        );
    }

    if (configForce.forceType === 4) {
        return (
            <div className="text-center" data-tag="allowRowEvents">
                <Tag color="violet" data-tag="allowRowEvents">
                    <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.sensorForce.reserved" />
                </Tag>
                <br />
                <small data-tag="allowRowEvents">{endForceTime}</small>
            </div>
        );
    }

    if (configForce.forceType === 10) {
        return (
            <div data-cy="lpSensor-force-color" className="text-center" data-tag="allowRowEvents">
                <Tag
                    style={{
                        backgroundColor: `rgb(${configForce.color.red}, ${configForce.color.green}, ${configForce.color.blue})`,
                        color: '#FFF',
                    }}
                    data-tag="allowRowEvents">
                    <FormattedMessage data-tag="allowRowEvents" id="ipCanDevices.LPSensors.sensorForce.color" />
                </Tag>
                <br />
                <small data-tag="allowRowEvents">{endForceTime}</small>
            </div>
        );
    }
}
