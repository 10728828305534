import {
    faArrowLeft,
    faArrowsAltH,
    faArrowsAltV,
    faCopy,
    faEraser,
    faExclamationCircle,
    faExclamationTriangle,
    faEyeDropper,
    faFileImport,
    faFont,
    faImage,
    faPaste,
    faSave,
    faShareAlt,
    faUndoAlt,
    faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { SketchPicker } from 'react-color';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Button, ButtonGroup, Divider, FlexboxGrid, Input, InputGroup, Message, Panel, SelectPicker } from 'rsuite';
import { PictoRGBValueInterface, RGBColorType } from '..';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { PictoPixel, PixelArtPictoRGBImport } from '../../../../handlers/pixelArt/pixelArtPictoRGBWS';
import PanelHeader from '../../../Custom/PanelHeader';
import { downloadFile, selectPictoSizeData } from '../../../PixelArtFontEditor/RightSideBar/utils';
import { ImportImageModal } from './ImportImageModal';
import { ImportPictoModal } from './ImportPictoModal';
import { ResetPictoModal } from './ResetPictoModal';
import { SavePictoModal } from './SavePictoModal';
import { SendPictoToManyDisplayModal } from './SendPictoToManyDisplayModal';

type Props = {
    pictos: PictoRGBValueInterface[];
    pictosFromApi: any;
    selectedPictoIndex: number;
    isLoading: boolean;
    handlePictoWidthChange: (value: number) => void;
    handlePictoHeightChange: (value: number) => void;
    handlePictoUpload: (receivedPicto: PixelArtPictoRGBImport) => void;
    handleImageUpload: (picto: PictoPixel[][]) => void;
    readPictoFromAPI: () => void;
    handleSizeChange: (value: number) => void;
    handleSavePicto: () => void;
    handleSaveToManyPicto: (displays: number[]) => void;
    handleClickCopy: () => void;
    handleClickPaste: () => void;
    handleFullBlack: () => void;
    isPasteButtonEnabled: boolean;
    currentSelectedColor: RGBColorType;
    setCurrentSelectedColor: (color: RGBColorType) => void;
    setIsEyeDropperMode: (isEyeDropperMode: boolean) => void;
    isEyeDropperMode: boolean;
    isTooLarge: boolean;
    display?: LPDisplay;
    ipcan?: IpCan;
};

export const RightSideBar = (props: Props) => {
    const intl = useIntl();

    const [importModalIsOpen, setImportModalIsOpen] = React.useState<boolean>(false);

    const [sendToManyModalIsOpen, setSendToManyModalIsOpen] = React.useState<boolean>(false);

    const [isResetModalOpen, setIsResetModalOpen] = React.useState<boolean>(false);

    const [saveModalIsOpen, setSaveModalIsOpen] = React.useState<boolean>(false);

    const [importImageIsOpen, setImportImageIsOpen] = React.useState<boolean>(false);

    const currentPicto: PictoRGBValueInterface | undefined = props.pictos[props.selectedPictoIndex];

    const history = useHistory();

    const handleClickSave = () => {
        downloadFile(
            JSON.stringify({
                picto: props.pictosFromApi,
                pictoType: 10,
            }),
            `export-picto-rgb-${moment().format('DD_MM_YYYY-HH_mm_ss')}.json`
        );
    };

    const handleClickImport = () => {
        setImportModalIsOpen(true);
    };

    const handleWidthChange = value => {
        props.handlePictoWidthChange(value);
    };

    const handleHeightChange = value => {
        props.handlePictoHeightChange(value);
    };

    const handleClickReset = () => {
        setIsResetModalOpen(true);
    };

    const handleSizeChange = (value: number) => {
        props.handleSizeChange(value);
    };

    const handleColorChange = value => {
        props.setCurrentSelectedColor({ r: value.rgb.r, g: value.rgb.g, b: value.rgb.b });
    };

    const handleClickImportImage = () => {
        setImportImageIsOpen(true);
    };

    return (
        <>
            <ImportPictoModal
                isOpen={importModalIsOpen}
                onHide={() => setImportModalIsOpen(false)}
                handlePictoUpload={props.handlePictoUpload}
            />

            <ResetPictoModal
                isOpen={isResetModalOpen}
                onHide={() => setIsResetModalOpen(false)}
                handleClickReset={() => props.readPictoFromAPI()}
            />

            <SendPictoToManyDisplayModal
                isOpen={sendToManyModalIsOpen}
                onHide={() => setSendToManyModalIsOpen(false)}
                handleSaveToManyPicto={props.handleSaveToManyPicto}
            />

            <SavePictoModal
                isOpen={saveModalIsOpen}
                onHide={() => setSaveModalIsOpen(false)}
                handleSavePicto={() => props.handleSavePicto()}
            />

            <ImportImageModal
                isOpen={importImageIsOpen}
                onHide={() => setImportImageIsOpen(false)}
                handleUploadValid={props.handleImageUpload}
            />

            <Panel
                className="panel-small"
                bordered
                shaded
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'editPicto.editorMenu' })}
                        buttons={[
                            <Button
                                color="blue"
                                size="sm"
                                onClick={() => {
                                    if (props.ipcan && props.display) {
                                        return history.push(`/ipCan/${props.ipcan.getId()}/bus/${props.display.bus}`);
                                    } else {
                                        return history.push('/ipCan');
                                    }
                                }}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Button>,
                        ]}
                    />
                }>
                {(props.ipcan || props.display) && (
                    <>
                        <h5 className="text-center">
                            {props.ipcan?.getLabel()} - {props.display?.getBus() && props.display?.getBus()} -{' '}
                            {props.display?.getDeviceId()}
                        </h5>

                        <Divider className="margin-top-10" />
                    </>
                )}
                <ButtonGroup justified>
                    <Button onClick={handleClickReset} color="red" disabled={props.isLoading || !props.display}>
                        <FontAwesomeIcon icon={faUndoAlt} />
                    </Button>
                    <Button color="orange" disabled={props.isLoading} onClick={handleClickImport}>
                        <FontAwesomeIcon icon={faFileImport} />
                    </Button>
                    <Button
                        color="green"
                        disabled={props.isLoading || props.pictos.length === 0}
                        onClick={handleClickSave}>
                        <FontAwesomeIcon icon={faSave} />
                    </Button>
                    <Button
                        color="cyan"
                        disabled={props.isLoading || props.pictos.length === 0}
                        onClick={handleClickImportImage}>
                        <FontAwesomeIcon icon={faImage} />
                    </Button>
                </ButtonGroup>
                <Divider />
                <ButtonGroup justified>
                    <Button onClick={props.handleClickCopy} color="blue" disabled={props.isLoading}>
                        <FontAwesomeIcon icon={faCopy} />
                    </Button>
                    <Button
                        color="orange"
                        disabled={props.isLoading || !props.isPasteButtonEnabled}
                        onClick={props.handleClickPaste}>
                        <FontAwesomeIcon icon={faPaste} />
                    </Button>
                    <Button color="red" disabled={props.isLoading} onClick={props.handleFullBlack}>
                        <FontAwesomeIcon icon={faEraser} />
                    </Button>
                    <Button
                        color="blue"
                        appearance={props.isEyeDropperMode ? 'primary' : 'ghost'}
                        disabled={props.isLoading}
                        onClick={() => props.setIsEyeDropperMode(!props.isEyeDropperMode)}>
                        <FontAwesomeIcon icon={faEyeDropper} />
                    </Button>
                </ButtonGroup>

                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item>
                        <SketchPicker
                            className="margin-top-15"
                            disableAlpha={true}
                            color={props.currentSelectedColor}
                            onChange={handleColorChange}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <Divider />

                <FlexboxGrid className="margin-bottom-15">
                    <FlexboxGrid.Item colspan={24}>
                        <p className="bold">
                            <FormattedMessage id="editPicto.pictoType" />
                        </p>
                        <SelectPicker
                            disabled={props.isLoading}
                            value={10}
                            data={selectPictoSizeData(intl)}
                            onChange={handleSizeChange}
                            searchable={false}
                            cleanable={false}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <div>
                    <InputGroup>
                        <InputGroup.Addon>
                            <FontAwesomeIcon icon={faArrowsAltH} />
                        </InputGroup.Addon>
                        <Input
                            type="number"
                            key={`${new Date()}-${props.selectedPictoIndex}`}
                            disabled={props.isLoading}
                            defaultValue={currentPicto?.value[0]?.length.toString() || '0'}
                            step={1}
                            max={100}
                            onChange={value => handleWidthChange(parseInt(value))}
                        />
                    </InputGroup>
                </div>

                <div className="margin-top-15">
                    <InputGroup>
                        <InputGroup.Addon>
                            <FontAwesomeIcon icon={faArrowsAltV} />
                        </InputGroup.Addon>

                        <Input
                            type="number"
                            key={`${new Date()}-${props.selectedPictoIndex}`}
                            disabled={props.isLoading}
                            defaultValue={currentPicto?.value.length.toString() || '0'}
                            step={1}
                            max={100}
                            onChange={value => handleHeightChange(parseInt(value))}
                        />
                    </InputGroup>
                </div>

                <Divider />
                {!props.display && (
                    <Message
                        className="margin-bottom-10"
                        type="warning"
                        description={
                            <>
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    color="orange"
                                    className="margin-right-5"
                                />{' '}
                                <FormattedMessage id="editPicto.noDisplay" />
                            </>
                        }></Message>
                )}
                {props.isTooLarge && (
                    <Message
                        className="margin-bottom-10"
                        type="error"
                        description={
                            <>
                                <FontAwesomeIcon icon={faExclamationCircle} color="red" className="margin-right-5" />{' '}
                                <FormattedMessage id="editPicto.pictosTooLarge" />
                            </>
                        }></Message>
                )}
                <ButtonGroup justified>
                    <Button
                        onClick={() => setSaveModalIsOpen(true)}
                        disabled={props.isLoading || !props.display || props.isTooLarge}
                        color="blue">
                        <FontAwesomeIcon icon={faUpload} />
                    </Button>
                    <Button
                        onClick={() => setSendToManyModalIsOpen(true)}
                        disabled={props.isLoading || props.isTooLarge}
                        color="violet">
                        <FontAwesomeIcon icon={faShareAlt} />
                    </Button>
                </ButtonGroup>

                <Divider />
                <Button
                    onClick={() =>
                        history.push(
                            `/ipCan/${props.ipcan?.getId()}/bus/${props.display?.getBus()}/matrix-display/${props.display?.getId()}/font/16`
                        )
                    }
                    block
                    appearance="ghost">
                    <FontAwesomeIcon icon={faFont} className="margin-right-5" />
                    <FormattedMessage id="editFont.switchToFont" />
                </Button>
            </Panel>
        </>
    );
};
