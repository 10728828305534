import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    database: string;
    show: boolean;
    close: Function;
};

type State = {
    loading: boolean;
    value: string | Blob;
};

export default class ImportDatabaseModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            value: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            this.setState({
                value: event.target.files[0],
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            loading: true,
        });

        const parameters = {
            'content-type': 'multipart/form-data',
            headers: authHeader(),
        };

        const data = new FormData();
        data.append('file', this.state.value);

        axiosService
            .getAxios()
            .post('/configuration/database/import/' + this.props.database, data, parameters)
            .then(() => {
                Alert.success('Base de données importée !', 5000);

                this.setState({
                    loading: false,
                });

                this.props.close(true);
            })
            .catch(err => {
                Alert.error('Erreur d importation de la base de données.', 5000);

                this.setState({ loading: false });

                console.error(err);

                this.props.close();
            });
    }

    render() {
        return (
            <Modal backdrop={'static'} show={this.props.show} onHide={() => this.props.close()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="configuration.database.importDatabaseModal.title" /> -{' '}
                        {this.props.database}
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={this.handleSubmit}>
                    <Modal.Body data-cy={`import-database-${this.props.database}`}>
                        <FormattedMessage id="configuration.database.importDatabaseModal.confirmationMessage" />

                        <input
                            style={{ marginTop: 10 }}
                            data-cy="import-files"
                            type="file"
                            accept=".tgz"
                            name="file"
                            onChange={event => this.handleChange(event)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button
                                onClick={() => this.props.close()}
                                data-cy="import-database-cancel"
                                color="red"
                                loading={this.state.loading}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                type="submit"
                                data-cy="import-database-valid"
                                appearance="primary"
                                loading={this.state.loading}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}
