import {
    faArrowLeft,
    faCheck,
    faCircle,
    faDesktop,
    faExclamationTriangle,
    faPlay,
    faPlus,
    faStop,
    faTimes,
    faTrash,
    faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layer, Rect, Stage } from 'react-konva';
import {
    Button,
    Col,
    ControlLabel,
    Divider,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    InputNumber,
    List,
    Loader,
    Message,
    Modal,
    Steps,
    Tag,
} from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { generateUsConfigHeight, generateUsConfigSens } from '../../../handlers/ipcan/IpCanHelper';
import { IPCanHybrid } from '../../../handlers/ipcan/IpCanHybrid';
import IpCanLP from '../../../handlers/ipcan/IpCanLP';
import IpCanLPMatrixDisplayV2 from '../../../handlers/ipcan/IpCanLPMatrixDisplayV2';
import IPCanTcm from '../../../handlers/ipcan/IpCanTCM';
import { webSocketService } from '../../../redux/services';
import Arrows from '../../IpCanElementsPage/Elements/Arrow';
import Languages from '../../IpCanElementsPage/Elements/Language';
import Text from '../../IpCanElementsPage/Elements/Text';
import Types from '../../IpCanElementsPage/Elements/Types';

type State = {
    formValue: FormValue;
    step: number;
};

type Props = {
    bus: number;
    ipCan: IpCan;
    onHide: Function;
    show: boolean;
};

type FormValue = {
    startId: string;
    endId: string;
    jumps: {
        id: string;
        nbIdJump: string;
    }[];
};

export default class EnumBusModal extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            formValue: {
                startId: '1',
                endId: props.bus === 2 ? '12' : '128',
                jumps: [],
            },
            step: 0,
        };

        this.switcher = this.switcher.bind(this);
        this.passToEnumeration = this.passToEnumeration.bind(this);
        this.passToForm = this.passToForm.bind(this);
        this.updateForm = this.updateForm.bind(this);
    }

    passToEnumeration() {
        this.setState({
            step: 1,
        });
    }

    passToForm() {
        this.setState({
            step: 0,
        });
    }

    updateForm(formValue) {
        let jumps = [...this.state.formValue.jumps];

        if (jumps.length > 0 && jumps[0].id <= formValue.startId) {
            jumps[0] = {
                nbIdJump: !isNaN(parseInt(formValue.startId)) ? (parseInt(formValue.startId) + 4).toString() : '0',
                id: !isNaN(parseInt(formValue.startId)) ? (parseInt(formValue.startId) + 2).toString() : '0',
            };

            if (jumps.length > 1) {
                for (let i = 1; i < jumps.length; i++) {
                    if (jumps[i].id < jumps[i - 1].nbIdJump + 2) {
                        jumps[i].id = jumps[i - 1].nbIdJump + 2;

                        if (jumps[i].nbIdJump < jumps[i].id + 2) {
                            jumps[i].nbIdJump = jumps[i].id + 2;
                        }
                    }
                }
            }
        }

        this.setState({
            formValue: {
                startId: formValue.startId,
                endId: formValue.endId,
                jumps,
            },
        });
    }

    addJump = () => {
        const jumps = [...this.state.formValue.jumps];

        if (jumps.length === 0) {
            jumps.push({
                id: this.state.formValue.startId + 1,
                nbIdJump: this.state.formValue.startId + 3,
            });
        } else if (jumps.length < 10) {
            jumps.push({
                id: jumps[jumps.length - 1].nbIdJump + 2,
                nbIdJump: jumps[jumps.length - 1].nbIdJump + 4,
            });
        }

        this.setState({
            formValue: {
                ...this.state.formValue,
                jumps,
            },
        });
    };

    inputIDChange = (index, value) => {
        let jumps = [...this.state.formValue.jumps];

        jumps[index].id = value;

        // if (jumps[index].nbIdJump < jumps[index].id + 2) {
        //     jumps[index].nbIdJump = jumps[index].id + 2;
        // }

        // if (index === 0 && value <= this.state.formValue.startId + 1) {
        //     jumps[index].id = this.state.formValue.startId + 1;
        // }

        // if (jumps.length > 1) {
        //     for (let i = 1; i < jumps.length; i++) {
        //         if (jumps[i].id < jumps[i - 1].nbIdJump + 2) {
        //             jumps[i].id = jumps[i - 1].nbIdJump + 2;
        //         }
        //         if (jumps[i].nbIdJump < jumps[i].id + 2) {
        //             jumps[i].nbIdJump = jumps[i].id + 2;
        //         }
        //     }
        // }

        this.setState({
            formValue: {
                ...this.state.formValue,
                jumps,
            },
        });
    };

    inputJumpChange = (index, value) => {
        const jumps = [...this.state.formValue.jumps];

        jumps[index] = {
            ...jumps[index],
            nbIdJump: value,
        };

        // if (index === jumps.length - 1 && value <= jumps[index].id + 2) {
        //     jumps[index].nbIdJump = jumps[index].id + 2;
        // }

        // if (jumps.length > 1) {
        //     for (let i = 0; i < jumps.length - 1; i++) {
        //         if (jumps[i + 1].id < jumps[i].nbIdJump + 2) {
        //             jumps[i + 1].id = jumps[i].nbIdJump + 2;
        //         }
        //     }
        // }

        this.setState({
            formValue: {
                ...this.state.formValue,
                jumps,
            },
        });
    };

    removeJump = index => {
        const jumps = [...this.state.formValue.jumps];

        jumps.splice(index, 1);

        this.setState({
            formValue: {
                ...this.state.formValue,
                jumps,
            },
        });
    };

    switcher() {
        switch (this.state.step) {
            case 0:
                return (
                    <EnumForm
                        passToEnumeration={this.passToEnumeration}
                        formValue={this.state.formValue}
                        updateForm={this.updateForm}
                        bus={this.props.bus}
                        ipCan={this.props.ipCan}
                        addJump={this.addJump}
                        inputIDChange={this.inputIDChange}
                        inputJumpChange={this.inputJumpChange}
                        removeJump={this.removeJump}
                    />
                );
            case 1:
                return (
                    <EnumerationTable
                        bus={this.props.bus}
                        ipCan={this.props.ipCan}
                        formValue={this.state.formValue}
                        passToForm={this.passToForm}
                        onHide={this.props.onHide}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <Modal
                backdrop="static"
                show={this.props.show}
                onHide={() => this.props.onHide()}
                full={this.state.step !== 0}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCan.enumeration.title" />
                        <span className="margin-left-5">
                            {this.props.ipCan.getLabel()} - {this.props.bus + 1}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Steps current={this.state.step} className="margin-bottom-20">
                        <Steps.Item
                            title={<FormattedMessage id="ipCan.enumeration.configuration" />}
                            passToEnumeration
                        />
                        <Steps.Item title={<FormattedMessage id="ipCan.enumeration.enumeration" />} />
                    </Steps>

                    {this.switcher()}
                </Modal.Body>
            </Modal>
        );
    }
}

type EnumFormProps = {
    ipCan: Record<string, any>;
    formValue: FormValue;
    updateForm: Function;
    passToEnumeration: Function;
    bus: number;
    addJump: Function;
    removeJump: Function;
    inputIDChange: Function;
    inputJumpChange: Function;
};

type EnumFormState = {
    shouldDisabledStartEnumButton: boolean;
};

class EnumForm extends Component<EnumFormProps, EnumFormState> {
    constructor(props) {
        super(props);

        this.state = {
            shouldDisabledStartEnumButton: false,
        };
    }

    componentDidUpdate = prevProps => {
        if (
            prevProps.formValue.startId !== this.props.formValue.startId ||
            prevProps.formValue.endId !== this.props.formValue.endId
        ) {
            this.setState({
                shouldDisabledStartEnumButton:
                    parseInt(this.props.formValue.startId) >= parseInt(this.props.formValue.endId),
            });
        }
    };

    render() {
        const jumps = this.props.formValue.jumps;
        if (
            this.props.ipCan instanceof IPCanTcm ||
            (this.props.ipCan instanceof IpCanLPMatrixDisplayV2 && this.props.bus === 2)
        ) {
            return (
                <Form fluid formValue={this.props.formValue} onChange={formValue => this.props.updateForm(formValue)}>
                    {this.props.ipCan.getOnline() !== 2 && (
                        <Message
                            type="warning"
                            description={<FormattedMessage id="ipCan.enumeration.ipCanNotOnlineError" />}
                        />
                    )}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="ipCan.enumeration.startId" />
                        </ControlLabel>
                        <FormControl style={{ width: '100%' }} name="startId" type="number" max="127" min="1" />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="ipCan.enumeration.endId" />
                        </ControlLabel>
                        <FormControl
                            style={{ width: '100%' }}
                            name="endId"
                            type="number"
                            max="128"
                            min="1"
                            data-cy="ipcan-enumeration-endId"
                        />
                    </FormGroup>

                    <FlexboxGrid justify="end">
                        <FlexboxGrid.Item>
                            <Button
                                data-cy="ipCan-enum-start"
                                color="green"
                                className="text-right"
                                disabled={
                                    this.props.ipCan.getOnline() !== 2 || this.state.shouldDisabledStartEnumButton
                                }
                                onClick={() => this.props.passToEnumeration()}>
                                <FormattedMessage id="ipCan.enumeration.startEnumeration" />{' '}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            );
        }

        if (
            (this.props.ipCan instanceof IPCanHybrid ||
                this.props.ipCan instanceof IpCanLP ||
                this.props.ipCan instanceof IpCanLPMatrixDisplayV2) &&
            this.props.bus < 2
        ) {
            let isButtonDisabled = this.props.ipCan.getOnline() !== 2;

            this.props.formValue.jumps.forEach(jump => {
                if (!jump.id) isButtonDisabled = true;
                if (!jump.nbIdJump) isButtonDisabled = true;
            });

            return (
                <Form fluid formValue={this.props.formValue} onChange={formValue => this.props.updateForm(formValue)}>
                    {this.props.ipCan.getOnline() !== 2 && (
                        <Message
                            type="warning"
                            description={<FormattedMessage id="ipCan.enumeration.ipCanNotOnlineError" />}
                        />
                    )}
                    <FlexboxGrid align="bottom" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={this.props.formValue.jumps.length === 0 ? 22 : 24}>
                            <FormGroup>
                                <ControlLabel>
                                    <FormattedMessage id="ipCan.enumeration.startId" />
                                </ControlLabel>
                                <FormControl style={{ width: '100%' }} name="startId" type="number" max="127" min="1" />
                            </FormGroup>
                        </FlexboxGrid.Item>
                        {this.props.formValue.jumps.length === 0 && (
                            <FlexboxGrid.Item componentClass={Col} xs={2}>
                                <Button color="blue" onClick={() => this.props.addJump()}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </FlexboxGrid.Item>
                        )}
                    </FlexboxGrid>

                    <FlexboxGrid align="bottom" justify="space-between">
                        {this.props.formValue.jumps.length > 0 && (
                            <FlexboxGrid.Item componentClass={Col} xs={24}>
                                <Divider>
                                    <FormattedMessage id="ipCan.enumeration.jumps" />
                                </Divider>
                            </FlexboxGrid.Item>
                        )}
                    </FlexboxGrid>

                    {this.props.formValue.jumps.map((jump, index) => {
                        return (
                            <FlexboxGrid align="bottom" justify="space-between" key={`jump-${index}`}>
                                <FlexboxGrid.Item componentClass={Col} xs={11} className="margin-bottom-10">
                                    <FormGroup>
                                        <ControlLabel>
                                            <FormattedMessage id="ipCan.enumeration.jumpId" />
                                        </ControlLabel>
                                        <FormControl
                                            style={{ width: '100%' }}
                                            accepter={InputNumber}
                                            onChange={value => this.props.inputIDChange(index, value)}
                                            value={jump.id}
                                            min={
                                                index === 0
                                                    ? parseInt(this.props.formValue.startId) + 1
                                                    : parseInt(this.props.formValue.jumps[index - 1].nbIdJump) + 2
                                            }
                                        />
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={11} className="margin-bottom-10">
                                    <FormGroup>
                                        <ControlLabel>
                                            <FormattedMessage id="ipCan.enumeration.nbIdJump" />
                                        </ControlLabel>
                                        <FormControl
                                            style={{ width: '100%' }}
                                            accepter={InputNumber}
                                            onChange={value => this.props.inputJumpChange(index, value)}
                                            value={jump.nbIdJump}
                                            min={
                                                index === 0
                                                    ? parseInt(this.props.formValue.startId) + 3
                                                    : parseInt(this.props.formValue.jumps[index].id) + 2
                                            }
                                        />
                                    </FormGroup>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={2} className="margin-bottom-10">
                                    <Button
                                        style={{ marginBottom: 3 }}
                                        size="sm"
                                        block
                                        color="red"
                                        onClick={() => this.props.removeJump(index)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </FlexboxGrid.Item>
                                {jumps.length === index + 1 && jumps.length < 10 && (
                                    <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-top-10">
                                        <Button block color="blue" onClick={() => this.props.addJump()}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </FlexboxGrid.Item>
                                )}
                            </FlexboxGrid>
                        );
                    })}

                    <FlexboxGrid justify="end" className="margin-top-15">
                        <FlexboxGrid.Item>
                            <Button
                                data-cy="ipCan-enum-start"
                                color="green"
                                className="text-right"
                                disabled={isButtonDisabled}
                                onClick={() => this.props.passToEnumeration()}>
                                <FormattedMessage id="ipCan.enumeration.startEnumeration" />{' '}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            );
        }

        if (this.props.ipCan instanceof IPCanHybrid && this.props.bus === 2) {
            return (
                <Form fluid formValue={this.props.formValue} onChange={formValue => this.props.updateForm(formValue)}>
                    <FlexboxGrid justify="end">
                        <FlexboxGrid.Item>
                            <Button
                                color="green"
                                className="text-right"
                                onClick={() => this.props.passToEnumeration()}
                                disabled={this.props.ipCan.getOnline() !== 2}>
                                <FormattedMessage id="ipCan.enumeration.startEnumeration" />{' '}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            );
        }

        return null;
    }
}

type EnumerationTableProps = {
    ipCan: IpCan;
    bus: number;
    formValue: FormValue;
    passToForm: Function;
    onHide: Function;
};

type EnumerationTableState = {
    elements: Array<Record<string, any>>;
    elementsCount: number;
    displayCount: number;
    sensorCount: number;
    running: boolean;
    loading: boolean;
    currentId: number;
    beforeElementType: string | undefined;
    error: boolean;
    count: number;
};

class EnumerationTable extends Component<EnumerationTableProps, EnumerationTableState> {
    enumUpdate: Function;
    constructor(props) {
        super(props);

        this.state = {
            elements: [],
            elementsCount: 0,
            displayCount: 0,
            sensorCount: 0,
            running: false,
            loading: true,
            currentId: -1,
            beforeElementType: undefined,
            error: false,
            count: 1,
        };

        this.addElement = this.addElement.bind(this);
        this.launchEnumBus = this.launchEnumBus.bind(this);

        this.enumUpdate = () => {
            this.setState({ count: this.state.count + 1 });
        };
    }

    launchEnumBus() {
        if (!this.props.ipCan.isEnumRunning) {
            this.setState({
                error: false,
                elements: [],
                elementsCount: 0,
                displayCount: 0,
                sensorCount: 0,
                loading: true,
            });
        }

        if (this.props.ipCan instanceof IPCanTcm) {
            this.props.ipCan.TCM_enumBusCommand(
                this.props.bus,
                !this.props.ipCan.isEnumRunning,
                this.props.formValue.startId,
                this.props.formValue.endId,
                this.enumUpdate
            );
        }

        if (
            (this.props.ipCan instanceof IPCanHybrid ||
                this.props.ipCan instanceof IpCanLP ||
                this.props.ipCan instanceof IpCanLPMatrixDisplayV2) &&
            this.props.bus < 2
        ) {
            this.props.ipCan.LPSensor_enumBusCommand(
                this.props.bus,
                !this.props.ipCan.isEnumRunning,
                this.props.formValue.jumps || [],
                this.props.formValue.startId,
                this.enumUpdate
            );
        }

        if (this.props.ipCan instanceof IPCanHybrid && this.props.bus === 2) {
            this.props.ipCan.TCM_enumBusDisplayCommand(
                this.props.bus,
                !this.props.ipCan.isEnumRunning,
                this.enumUpdate
            );
        }

        if (this.props.ipCan instanceof IpCanLPMatrixDisplayV2 && this.props.bus === 2) {
            this.props.ipCan.enumLPDisplayMatrixV2Command(
                this.props.bus,
                !this.props.ipCan.isEnumRunning,
                this.props.formValue.startId,
                this.props.formValue.endId,
                this.enumUpdate
            );
        }
    }

    componentDidMount() {
        webSocketService.onEvent(`ipcanmodule:enumBus/${this.props.bus}`, this.addElement);
        webSocketService.onEvent(`ipcanmodule:enumBusLpSensor/${this.props.bus}`, this.addElement);
        webSocketService.onEvent(`ipcanmodule:enumBusTcmDisplay/${this.props.bus}`, this.addElement);
        webSocketService.onEvent(`ipcanmodule:enumBusLpMatrixDisplayV2/${this.props.bus}`, this.addElement);

        this.launchEnumBus();
    }

    componentWillUnmount() {
        webSocketService.offEvent(`ipcanmodule:enumBus/${this.props.bus}`, this.addElement);
        webSocketService.offEvent(`ipcanmodule:enumBusLpSensor/${this.props.bus}`, this.addElement);
        webSocketService.offEvent(`ipcanmodule:enumBusTcmDisplay/${this.props.bus}`, this.addElement);
        webSocketService.offEvent(`ipcanmodule:enumBusLpMatrixDisplayV2/${this.props.bus}`, this.addElement);
    }

    addElement(data) {
        if (data.mac === this.props.ipCan.mac) {
            if (data.device && !data.last && Object.entries(data.device).length > 0) {
                this.setState({
                    loading: false,
                });
                // First element pushed in table
                if (this.state.elements.length === 0) {
                    let array = [{ type: `separator-${data.device.type}` }];
                    array.push(data.device);

                    this.setState({
                        elements: array,
                        // currentId: data.id,
                        beforeElementType: data.device.type,
                        elementsCount: this.state.elementsCount + 1,
                        displayCount:
                            data.device.type === 'tcm-display' ? this.state.displayCount + 1 : this.state.displayCount,
                        sensorCount:
                            data.device.type === 'tcm-sensor' ? this.state.sensorCount + 1 : this.state.sensorCount,
                    });
                } else if (this.state.beforeElementType !== data.device.type) {
                    let newElementsArray = this.state.elements;
                    newElementsArray.unshift(data.device);
                    newElementsArray.unshift({ type: `separator-${data.device.type}` });

                    this.setState({
                        elements: newElementsArray,
                        // currentId: data.id,
                        beforeElementType: data.device.type,
                        elementsCount: this.state.elementsCount + 1,
                        displayCount:
                            data.device.type === 'tcm-display' || data.device.type === 'lp-displayV2'
                                ? this.state.displayCount + 1
                                : this.state.displayCount,
                        sensorCount:
                            data.device.type === 'tcm-sensor' ? this.state.sensorCount + 1 : this.state.sensorCount,
                    });
                } else {
                    let newElementsArray = this.state.elements;
                    newElementsArray.splice(1, 0, data.device);

                    this.setState({
                        elements: newElementsArray,
                        // currentId: data.id,
                        elementsCount: this.state.elementsCount + 1,
                        displayCount:
                            data.device.type === 'tcm-display' || data.device.type === 'lp-displayV2'
                                ? this.state.displayCount + 1
                                : this.state.displayCount,
                        sensorCount:
                            data.device.type === 'tcm-sensor' ? this.state.sensorCount + 1 : this.state.sensorCount,
                    });
                }
            } else if (data.last) {
                this.props.ipCan.stopEnumeration(this.enumUpdate);
            }

            this.setState({
                currentId: data.id,
                loading: false,
            });

            this.props.ipCan.setDisableTimeout(true);
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.ipCan.hasEnumError && (
                    <Message type="error" description="Une erreur est survenue, veuillez réessayer" />
                )}
                <FlexboxGrid justify="space-between" align="middle" className="margin-top-15 margin-bottom-15">
                    <FlexboxGrid.Item>
                        <FlexboxGrid align="middle" className="margin-top-15 margin-bottom-15">
                            <FlexboxGrid.Item>
                                <Tag color="blue">
                                    ID :{' '}
                                    {this.state.currentId > 0 ? this.state.currentId : this.props.formValue.startId}{' '}
                                    {this.props.ipCan instanceof IPCanTcm && <span>/{this.props.formValue.endId}</span>}
                                </Tag>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                {this.props.ipCan.isEnumRunning && <Loader size="sm" className="margin-left-5" />}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Tag color="green">
                            <span className="margin-right-5">{this.state.elementsCount}</span>
                            <FormattedMessage id="ipCan.enumeration.elementsCount" />
                        </Tag>
                        {this.props.ipCan instanceof IPCanTcm && (
                            <Fragment>
                                <Tag color="blue">
                                    <span className="margin-right-5">{this.state.sensorCount}</span>
                                    <FormattedMessage id="ipCan.enumeration.sensorsCount" />
                                </Tag>
                                <Tag color="blue">
                                    <span className="margin-right-5">{this.state.displayCount}</span>
                                    <FormattedMessage id="ipCan.enumeration.displaysCount" />
                                </Tag>
                            </Fragment>
                        )}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {this.props.ipCan.isEnumRunning ? (
                            <Button size="xs" onClick={this.launchEnumBus} color="red" loading={this.state.loading}>
                                <FontAwesomeIcon icon={faStop} className="margin-right-10" />
                                <FormattedMessage id="ipCan.enumeration.stopEnumeration" />
                            </Button>
                        ) : (
                            <Fragment>
                                <Button
                                    className="margin-right-5"
                                    size="xs"
                                    onClick={() => this.props.passToForm()}
                                    color="orange">
                                    <FontAwesomeIcon icon={faArrowLeft} className="margin-right-10" />
                                    <FormattedMessage id="ipCan.enumeration.reconfigure" />
                                </Button>

                                <Button
                                    size="xs"
                                    onClick={this.launchEnumBus}
                                    color="green"
                                    loading={this.props.ipCan.isEnumLoading}
                                    disabled={this.props.ipCan.getOnline() !== 2}>
                                    <FontAwesomeIcon icon={faPlay} className="margin-right-10" />
                                    <FormattedMessage id="ipCan.enumeration.startEnumeration" />
                                </Button>

                                {this.props.ipCan.getOnline() !== 2 && (
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                        color="orange"
                                        size="lg"
                                        className="margin-left-5"
                                    />
                                )}
                            </Fragment>
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <List className="flexbox-scrollable" style={{ maxHeight: '65vh' }}>
                    {this.state.elements.map((element, index) => {
                        switch (element.type) {
                            case 'tcm-sensor': {
                                return (
                                    <TCMSensor
                                        key={`${element.type}-${index}`}
                                        sensor={element}
                                        striped={index % 2 === 0}
                                    />
                                );
                            }

                            case 'tcm-display': {
                                return (
                                    <TCMDisplay
                                        key={`${element.type}-${index}`}
                                        display={element}
                                        striped={index % 2 === 0}
                                    />
                                );
                            }

                            case 'lp-sensor': {
                                const key = `${element.type}-${index}`;
                                const striped = index % 2 === 0;

                                return LPSensor(key, element, striped);
                            }
                            case 'lp-displayV2': {
                                const key = `${element.type}-${index}`;
                                const striped = index % 2 === 0;

                                return LPMatrixDisplayV2(key, element, striped);
                            }
                            case 'separator-lp-sensor':
                                return LPSensorSeparator(`${element.type}-${index}`);
                            case 'separator-tcm-display':
                                return <TCMDisplaySeparator key={`${element.type}-${index}`} />;
                            case 'separator-tcm-sensor':
                                return <TCMSensorSeparator key={`${element.type}-${index}`} />;
                            case 'separator-lp-displayV2':
                                return <LPDisplayMatrixV2Separator key={`${element.type}-${index}`} />;
                            default:
                                return null;
                        }
                    })}
                </List>
            </Fragment>
        );
    }
}

class TCMDisplaySeparator extends Component {
    render() {
        return (
            <List.Item style={{ backgroundColor: 'black', color: 'white' }}>
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        <FormattedMessage id="ipCan.enumeration.display.bus" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        <FormattedMessage id="ipCan.enumeration.display.id" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.display.versionSoft" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.display.type" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={3}>
                        <FormattedMessage id="ipCan.enumeration.display.arrow" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={3}>
                        <FormattedMessage id="ipCan.enumeration.display.language" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={3}>
                        <FormattedMessage id="ipCan.enumeration.display.text" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={3}>
                        <FormattedMessage id="ipCan.enumeration.display.displayType" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        );
    }
}

class LPDisplayMatrixV2Separator extends Component {
    render() {
        return (
            <List.Item style={{ backgroundColor: 'black', color: 'white' }}>
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        <FormattedMessage id="ipCan.enumeration.display.bus" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        <FormattedMessage id="ipCan.enumeration.display.id" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.display.manufacturer" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.display.modelName" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.display.softwareVersion" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.display.size" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.display.preview" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        );
    }
}

class TCMSensorSeparator extends Component {
    render() {
        return (
            <List.Item style={{ backgroundColor: 'black', color: 'white' }}>
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        <FormattedMessage id="ipCan.enumeration.sensor.bus" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        <FormattedMessage id="ipCan.enumeration.sensor.id" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.sensor.versionSoft" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.sensor.type" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.sensor.detectionState" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.sensor.calibration" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FormattedMessage id="ipCan.enumeration.sensor.calibrationHeight" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        );
    }
}

function LPSensorSeparator(key) {
    return (
        <List.Item key={key} style={{ backgroundColor: 'black', color: 'white' }}>
            <FlexboxGrid align="middle">
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                    <FormattedMessage id="ipCan.enumeration.sensor.bus" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                    <FormattedMessage id="ipCan.enumeration.sensor.id" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <FormattedMessage id="ipCan.enumeration.sensor.versionSoft" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <FormattedMessage id="ipCan.enumeration.sensor.voltage" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <FormattedMessage id="ipCan.enumeration.sensor.type" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <FormattedMessage id="ipCan.enumeration.sensor.us1" />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <FormattedMessage id="ipCan.enumeration.sensor.us2" />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </List.Item>
    );
}

type TCMDisplayProps = {
    striped: boolean;
    display: Record<string, any>;
};

type TCMDisplayState = {};

class TCMDisplay extends Component<TCMDisplayProps, TCMDisplayState> {
    render() {
        return (
            <List.Item className={this.props.striped ? 'striped' : ''}>
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        {parseInt(this.props.display.bus) + 1}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        {this.props.display.deviceId}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        {this.props.display.info.softwareVersion}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FontAwesomeIcon icon={faDesktop} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={3}>
                        <Arrows value={this.props.display.config.arrow} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={3}>
                        <Languages value={this.props.display.config.lang} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={3}>
                        <Text value={this.props.display.config.text} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={3}>
                        <Types value={this.props.display.config.type} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        );
    }
}

type TCMSensorProps = {
    striped: boolean;
    sensor: Record<string, any>;
};

type TCMSensorState = {};
class TCMSensor extends Component<TCMSensorProps, TCMSensorState> {
    render() {
        return (
            <List.Item className={this.props.striped ? 'striped' : ''}>
                <FlexboxGrid align="middle">
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        {parseInt(this.props.sensor.bus) + 1}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                        {this.props.sensor.deviceId}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        {this.props.sensor.versionSoft}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        <FontAwesomeIcon icon={faWifi} rotate={180} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        {this.props.sensor.detectionState === 1 && (
                            <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }} />
                        )}
                        {this.props.sensor.detectionState === 2 && (
                            <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} />
                        )}
                        {this.props.sensor.detectionState === 3 && (
                            <FontAwesomeIcon icon={faCircle} style={{ color: 'orange' }} />
                        )}
                        {this.props.sensor.detectionState === 4 && (
                            <FontAwesomeIcon icon={faCircle} style={{ color: 'purple' }} />
                        )}
                        {(this.props.sensor.detectionState === 0 || this.props.sensor.detectionState > 4) && (
                            <FontAwesomeIcon icon={faCircle} style={{ color: 'black' }} />
                        )}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        {this.props.sensor.calibration.state ? (
                            <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
                        )}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                        {(this.props.sensor.calibration.value * 3.264).toFixed(0)} cm
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        );
    }
}

function LPSensor(key, sensor, striped) {
    return (
        <List.Item className={striped ? 'striped' : ''} key={key}>
            <FlexboxGrid align="middle">
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                    {parseInt(sensor.bus) + 1}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                    {sensor.deviceId}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <Tag color="blue">{sensor.infos.softwareVersion}</Tag>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <Tag color="violet">{sensor.infos.voltage / 10} V</Tag>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <FontAwesomeIcon icon={faWifi} rotate={180} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <Tag color="green">{generateUsConfigHeight(sensor.us1.height)} cm</Tag>{' '}
                    <Tag color="blue">{generateUsConfigSens(sensor.us1.sens)}</Tag>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <Tag color="green">{generateUsConfigHeight(sensor.us2.height)} cm</Tag>{' '}
                    <Tag color="blue">{generateUsConfigSens(sensor.us2.sens)}</Tag>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </List.Item>
    );
}

function LPMatrixDisplayV2(key, display, striped) {
    const CANVAS_WIDTH = 260;
    const BORDER_SIZE = 2;
    const matrixXArray = new Array(display.configMatrix.nbPixelX / 32).fill(0);
    const matrixYArray = new Array(display.configMatrix.nbPixelY / 16).fill(0);

    const rectWidth = CANVAS_WIDTH / matrixXArray.length - 3;
    const rectHeight = rectWidth / 2 - 3;

    const CANVAS_HEIGHT = BORDER_SIZE + rectHeight * matrixYArray.length + BORDER_SIZE * matrixYArray.length;

    return (
        <List.Item className={striped ? 'striped' : ''} key={key}>
            <FlexboxGrid align="middle">
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                    {parseInt(display.bus) + 1}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={2}>
                    {display.deviceId}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    {display.ident.manufacturer}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    {display.ident.modelName}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    {display.ident.softwareVersion}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    {display.configMatrix.nbPixelY}px * {display.configMatrix.nbPixelX}px
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className="text-center" componentClass={Col} xs={4}>
                    <Stage width={CANVAS_WIDTH} height={CANVAS_HEIGHT}>
                        <Layer>
                            {matrixYArray.map((_, indexY) => {
                                return matrixXArray.map((_, indexX) => {
                                    return (
                                        <Rect
                                            x={BORDER_SIZE + indexX * rectWidth + indexX * BORDER_SIZE}
                                            y={BORDER_SIZE + indexY * rectHeight + indexY * BORDER_SIZE}
                                            width={rectWidth}
                                            height={rectHeight}
                                            fill="#000000"
                                        />
                                    );
                                });
                            })}
                        </Layer>
                    </Stage>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </List.Item>
    );
}
