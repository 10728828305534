import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, SyntheticEvent } from 'react';
import { Handle, NodeComponentProps, Position } from 'react-flow-renderer';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, FlexboxGrid } from 'rsuite';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import {
    VirtualDisplayPreviewInformation,
    VirtualDisplayStateEnum,
} from '../../../../../handlers/lpDisplays/VirtualDisplay';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import { ColoredPointsInterface } from '../../handlers/Nodes/TopologyFlowNode';
import { ActionObserver, ActionObserverEvent } from '../../handlers/Observers/ActionObserver';
import VirtualDisplayPreview from './VirtualDisplayPreview';

type State = {
    isLoading: boolean;
};

interface VirtualDisplayNodeInterface {
    label: string;
    display: LPDisplay;
    virtualDisplay: VirtualDisplayPreviewInformation;
    color: string;
    virtualDisplayIndex: number;
    sourceTopologyColor: ColoredPointsInterface;
    isFirst: boolean;
    isLast: boolean;
}

type Props = NodeComponentProps<VirtualDisplayNodeInterface> & WrappedComponentProps;

/**
 * DATA AVAILABLE
 * display: LPDisplay;
 * virtualDisplay: VirtualDisplay;
 * color: string;
 * virtualDisplayIndex: number;
 * isFirst: boolean;
 * isLast: boolean;
 * removeVirtualDisplay: Function;
 * generateElements: Function;
 * updateVirtualDisplayOrder: Function;
 * getHandleColor: Function;
 */

class VirtualDisplayNode extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    handleClickTopArrow = (e: SyntheticEvent) => {
        e.stopPropagation();

        if (!this.props.data.isFirst) {
            this.setState({
                isLoading: true,
            });

            // let order = this.display.getVirtualDisplays().map(virtualDisplay => virtualDisplay.getId());

            let order = this.props.data.display
                .getPreviewInformation()
                .virtualDisplays.map(virtualDisplay => virtualDisplay.getId());

            order.splice(this.props.data.virtualDisplayIndex, 1);

            order.splice(this.props.data.virtualDisplayIndex - 1, 0, this.props.data.virtualDisplay.id);

            this.updateOrder(order, 'up');
        }
    };

    handleClickDownArrow = (e: SyntheticEvent) => {
        e.stopPropagation();

        this.setState({
            isLoading: true,
        });

        if (!this.props.data.isLast) {
            this.setState({
                isLoading: true,
            });

            let order = this.props.data.display
                .getPreviewInformation()
                .virtualDisplays.map(virtualDisplay => virtualDisplay.getId());

            order.splice(this.props.data.virtualDisplayIndex, 1);

            order.splice(this.props.data.virtualDisplayIndex + 1, 0, this.props.data.virtualDisplay.id);

            this.updateOrder(order, 'down');
        }
    };

    updateOrder = (order: number[], type: 'up' | 'down') => {
        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/virtualDisplay/orders',
                {
                    tabId: order,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updatedOrderResponse => {
                // this.props.data.updateVirtualDisplayOrder(updatedOrderResponse);

                ActionObserver.emit(ActionObserverEvent.UPDATE_VIRTUAL_DISPLAY_ORDER, {
                    virtualDisplays: updatedOrderResponse.data,
                    label: this.props.data.label,
                    color: this.props.data.color,
                    sourceTopologyColor: this.props.data.sourceTopologyColor,
                    virtualDisplayIndex: this.props.data.virtualDisplayIndex,
                    virtualDisplay: this.props.data.virtualDisplay,
                });

                this.setState({
                    isLoading: false,
                });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'virtualDisplays.updateOrder.success',
                    })
                );
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    isLoading: false,
                });
                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'virtualDisplays.updateOrder.error',
                    })
                );
            });
    };

    render = () => {
        return (
            <Fragment>
                {/* <VirtualDisplayDrawer
                    show={this.props.selected ? this.props.selected : false}
                    display={this.props.data.display}
                    virtualDisplay={this.props.data.virtualDisplay}
                    color={this.props.data.color}
                    removeVirtualDisplay={this.props.data.removeVirtualDisplay}
                    generateElements={this.props.data.generateElements}
                /> */}
                <div
                    className={`matrix-display-node ${this.props.selected && 'selected'} ${
                        this.props.data.virtualDisplay.state === VirtualDisplayStateEnum.FORCED_ENABLED &&
                        'virtual-display-force-enabled'
                    } ${this.props.data.virtualDisplay.state === VirtualDisplayStateEnum.WAIT && 'virtual-display-wait'}
                    }`}>
                    <Handle
                        type="source"
                        position={Position.Left}
                        id="virtualDisplay-topology"
                        style={{
                            background: this.props.data.sourceTopologyColor.isConnected
                                ? this.props.data.sourceTopologyColor.color
                                : '#555',
                        }}
                    />
                    <Handle
                        type="source"
                        position={Position.Right}
                        id="virtualDisplay-step"
                        style={{
                            background: '#555',
                        }}
                    />

                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item>
                            <Button
                                size="xs"
                                className="margin-bottom-5"
                                appearance="primary"
                                disabled={this.props.data.isFirst}
                                loading={this.state.isLoading}
                                onClick={this.handleClickTopArrow}>
                                <FontAwesomeIcon icon={faArrowUp} />
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <h5 className="text-center margin-bottom-10">{this.props.data.label}</h5>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <Button
                                size="xs"
                                className="margin-bottom-5"
                                appearance="primary"
                                disabled={this.props.data.isLast}
                                loading={this.state.isLoading}
                                onClick={this.handleClickDownArrow}>
                                <FontAwesomeIcon icon={faArrowDown} />
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid align="middle" justify="center">
                        <FlexboxGrid.Item>
                            <VirtualDisplayPreview
                                color={this.props.data.color}
                                virtualDisplay={this.props.data.virtualDisplay}
                                display={this.props.data.display.getPreviewInformation()}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </div>
            </Fragment>
        );
    };
}

export default injectIntl(VirtualDisplayNode);
