import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

export default class TCMSensorTime {
    protected _maxFree: number;
    protected _maxOccupied: number;
    protected _provOccupied: number;

    constructor(time_) {
        const { maxFree, maxOccupied, provOccupied } = time_;

        this._maxFree = maxFree;
        this._maxOccupied = maxOccupied;
        this._provOccupied = provOccupied;
    }

    public get maxFree() {
        return this._maxFree;
    }

    public get maxOccupied() {
        return this._maxOccupied;
    }

    public get provOccupied() {
        return this._provOccupied;
    }

    public set maxFree(maxFree_: number) {
        this._maxFree = maxFree_;
    }

    public set maxOccupied(maxOccupied_: number) {
        this._maxOccupied = maxOccupied_;
    }

    public set provOccupied(provOccupied_: number) {
        this._provOccupied = provOccupied_;
    }

    public get fetchTime() {
        return {
            maxFree: this._maxFree,
            maxOccupied: this._maxOccupied,
            provOccupied: this._provOccupied
        }
    }

    public setTime(sensorId_: number, maxFree_: number, maxOccupied_: number, provOccupied_: number) {
        this._maxFree = maxFree_;
        this._maxOccupied = maxOccupied_;
        this._provOccupied = provOccupied_;

        return axiosService.getAxios().put('/devices/tcm-sensor/updateTimeConfig', {
            id: sensorId_,
            maxFree: maxFree_,
            maxOccupied: maxOccupied_,
            provOccupied: provOccupied_
        }, {
            headers: authHeader()
        })
    }
}
