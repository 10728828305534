import { appConstants } from '../constants';

const initialState = {
    language: navigator.language.split(/[-_]/)[0],
};

export function app(state = initialState, action) {
    switch (action.type) {
        case appConstants.CHANGE_LANGUAGE_REQUEST:
            return {
                ...state,
                language: action.language,
            };

        default:
            return { ...state };
    }
}
