import { DataProps } from '../../Drawer';

class DrawerObserverClass {
    private _listeners: Array<any>;

    constructor() {
        this._listeners = [];
    }

    subscribe(fn: CallableFunction): void {
        this._listeners.push(fn);
    }

    unsubscribe(fn: CallableFunction): void {
        this._listeners = this._listeners.filter(listener => listener !== fn);
    }

    emit(event: DrawerObserverEvent, type?: DrawerObserverType, data?: DataProps): void {
        this._listeners.forEach(listener => listener.call(null, event, type, data));
    }
}

export enum DrawerObserverEvent {
    DRAWER_OPEN = 'drawer-open',
    DRAWER_CLOSE = 'drawer-close',
}

export enum DrawerObserverType {
    VIRTUAL_DISPLAY = 'virtual-display',
    TOPOLOGY = 'topology',
}

const DrawerObserver = new DrawerObserverClass();
export { DrawerObserver };
