import { ReactElement } from 'react';
import { Layer, Rect, Stage } from 'react-konva';
import { DisplayPreviewInformation } from '../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../handlers/lpDisplays/VirtualDisplay';

type Props = {
    virtualDisplay: VirtualDisplayPreviewInformation;
    color: string;
    display: DisplayPreviewInformation;
};

interface CoordInterface {
    color: string;
}

export default function VirtualDisplayPreview({ virtualDisplay, display, color }: Props): ReactElement {
    const MATRIX_WIDTH = 32;
    const MATRIX_HEIGHT = 32;
    const SEPARATOR_WIDTH = 2;
    const SEMI_SEPARATOR_WIDTH = SEPARATOR_WIDTH / 2;

    const stageWidth =
        (display.nbMatrixX + 1) * SEPARATOR_WIDTH +
        display.nbMatrixX * SEMI_SEPARATOR_WIDTH +
        display.nbMatrixX * 2 * MATRIX_WIDTH;

    const stageHeight = display.nbMatrixY * MATRIX_HEIGHT + (display.nbMatrixY + 1) * SEPARATOR_WIDTH;

    let squareArray: CoordInterface[][] = [];

    for (let i = 0; i < display.nbMatrixY; i++) {
        let displays: CoordInterface[] = [];
        for (let j = 0; j < display.nbMatrixX * 2; j++) {
            displays.push({
                color: 'grey',
            });
        }
        squareArray[i] = displays;
    }

    const indexStartX = virtualDisplay.formattedStartX;
    const indexStartY = virtualDisplay.formattedStartY;
    const sizeX = virtualDisplay.formattedSizeX;
    const sizeY = virtualDisplay.formattedSizeY;

    for (let i = indexStartY; i < indexStartY + sizeY; i++) {
        for (let j = indexStartX; j < indexStartX + sizeX; j++) {
            if (i <= display.nbMatrixY && j <= display.nbMatrixX * 2 && squareArray[i] && squareArray[i][j]) {
                squareArray[i][j].color = color;
            }
        }
    }

    return (
        <Stage width={stageWidth} height={stageHeight}>
            <Layer>
                <Rect width={stageWidth} height={stageHeight} x={0} y={0} fill="#000" />
            </Layer>
            <Layer>
                {squareArray.map((displays, indexY) => {
                    let previousX = SEPARATOR_WIDTH;
                    return displays.map((disp, indexX) => {
                        let x;
                        if (indexX > 0) {
                            x = previousX + MATRIX_WIDTH;

                            if (indexX % 2 !== 0) {
                                x += SEMI_SEPARATOR_WIDTH;
                            } else {
                                x += SEPARATOR_WIDTH;
                            }

                            previousX = x;
                        } else {
                            x = previousX;
                        }

                        let y = indexY * MATRIX_HEIGHT + (indexY + 1) * SEPARATOR_WIDTH;

                        return (
                            <Rect
                                key={`${indexX}_${indexY}`}
                                width={MATRIX_WIDTH}
                                height={MATRIX_HEIGHT}
                                fill={disp.color}
                                x={x}
                                y={y}
                            />
                        );
                    });
                })}
            </Layer>
        </Stage>
    );
}
