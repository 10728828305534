import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid, List } from 'rsuite';
import Camera from '../../../../../handlers/Camera/Camera';
import { ErrorFlag } from '../../../DataTable/ErrorFlag';

type Props = {
    camera: Camera;
};

export const InformationViewMode = (props: Props) => {
    return (
        <List>
            {/* NAME */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.name" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.camera.getName()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* ONLINE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.online" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon
                            icon={faPlug}
                            color={props.camera.getOnline() ? 'green' : 'red'}
                            size="lg"
                            className="margin-right-5"
                        />
                        ({props.camera.getLastOnlineStateDate()})
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* ERROR FLAG */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.errorFlag" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <ErrorFlag errorFlag={props.camera.getErrorFlag()} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* IP / MAC */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.ipAndMac" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{`${props.camera.getIp()} / ${props.camera.getMacStr()}`}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* HARDWARE & FIRMWARE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.hardwareAndFirmware" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{`${props.camera.getHardware()} / ${props.camera.getFirmware()}`}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* URL */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.url" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.camera.getUrl()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* PORT */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.port" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.camera.getPort()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* USERNAME */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.login" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.camera.getLogin()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* PASSWORD */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.password" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.camera.getPassword()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* CREATION */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.creation" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.camera.getCreation()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* UPDATE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="camera.information.update" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.camera.getUpdate()}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
};
