import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'rsuite';
import { AutoResetTrigger, EventConditionType, EventCounterCondition } from '../../../handlers/EventAction/EventAction';
import { StepOne } from './CreateEventSteps/StepOne';
import { StepThreeCounter } from './CreateEventSteps/StepThreeCounter';
import { StepThreeGpio } from './CreateEventSteps/StepThreeGPIO';
import { StepTwoCalendar } from './CreateEventSteps/StepTwoCalendar';
import { StepTwoCounter } from './CreateEventSteps/StepTwoCounter';
import { StepTwoGPIO } from './CreateEventSteps/StepTwoGPIO';

type Props = {
    show: boolean;
    onHide: () => void;
};

export type EventActionFormDataType = {
    name: string;
    desc: string;
    isInfinite: boolean;
    startDate: Date;
    endDate: Date;
    daily: string[];
    startTime: Date;
    counterConditions: EventCounterCondition[];
    eventConditionType: EventConditionType;
    autoResetTrigger: AutoResetTrigger;
    autoResetTriggerTime: Date;
    moduleIpCan: number;
    gpioNumber: number;
    gpioState: boolean;
};

export const CreateEventModal = (props: Props) => {
    const intl = useIntl();

    const [currentStep, setCurrentStep] = useState(0);

    const [formData, setFormData] = useState<EventActionFormDataType>({
        name: '',
        desc: '',
        isInfinite: true,
        startDate: moment().toDate(),
        endDate: moment().add(1, 'year').toDate(),
        daily: [],
        startTime: moment().startOf('day').toDate(),
        counterConditions: [],
        eventConditionType: EventConditionType.CALENDAR,
        autoResetTrigger: AutoResetTrigger.NONE,
        autoResetTriggerTime: moment().startOf('day').toDate(),
        moduleIpCan: 0,
        gpioNumber: 1,
        gpioState: true,
    });

    const handleClose = () => {
        setCurrentStep(0);

        setFormData({
            name: '',
            desc: '',
            isInfinite: true,
            startDate: moment().toDate(),
            endDate: moment().add(1, 'year').toDate(),
            daily: [],
            startTime: moment().startOf('day').toDate(),
            counterConditions: [],
            eventConditionType: EventConditionType.CALENDAR,
            autoResetTrigger: AutoResetTrigger.NONE,
            autoResetTriggerTime: moment().startOf('day').toDate(),
            moduleIpCan: 0,
            gpioNumber: 1,
            gpioState: true,
        });

        props.onHide();
    };

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleGoBack = () => {
        setCurrentStep(currentStep - 1);
    };

    return (
        <Modal backdrop="static" show={props.show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{intl.formatMessage({ id: 'event.create.title' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body data-cy="event-addEvent-modal">
                {currentStep === 0 && (
                    <StepOne
                        formData={formData}
                        setFormData={setFormData}
                        handleCancel={handleClose}
                        nextStep={handleNextStep}
                    />
                )}

                {currentStep === 1 && formData.eventConditionType === EventConditionType.CALENDAR && (
                    <StepTwoCalendar
                        formData={formData}
                        setFormData={setFormData}
                        handleCancel={handleClose}
                        handleGoBack={handleGoBack}
                    />
                )}

                {currentStep === 1 && formData.eventConditionType === EventConditionType.COUNTERS && (
                    <StepTwoCounter
                        formData={formData}
                        setFormData={setFormData}
                        handleCancel={handleClose}
                        handleGoBack={handleGoBack}
                        handleNextStep={handleNextStep}
                    />
                )}

                {currentStep === 1 && formData.eventConditionType === EventConditionType.GPIO && (
                    <StepTwoGPIO
                        formData={formData}
                        setFormData={setFormData}
                        handleCancel={handleClose}
                        handleGoBack={handleGoBack}
                        handleNextStep={handleNextStep}
                    />
                )}

                {currentStep === 2 && formData.eventConditionType === EventConditionType.COUNTERS && (
                    <StepThreeCounter
                        formData={formData}
                        setFormData={setFormData}
                        handleCancel={handleClose}
                        handleGoBack={handleGoBack}
                    />
                )}

                {currentStep === 2 && formData.eventConditionType === EventConditionType.GPIO && (
                    <StepThreeGpio
                        formData={formData}
                        setFormData={setFormData}
                        handleCancel={handleClose}
                        handleGoBack={handleGoBack}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};
