import { faCheck, faCircle, faPlug, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlexboxGrid, List } from 'rsuite';
import Camera from '../../../../../../handlers/Camera/Camera';
import { PlaceCameraState } from '../../../../../../handlers/Camera/PlaceCamera';
import { CameraSensorMapInformation_I } from '../../../../../../handlers/map/MapCameraSensor';

type Props = {
    mapCameraSensor: CameraSensorMapInformation_I;
    camera?: Camera;
    currentPlaceType: any;
};

export const MapPlaceCameraViewInformation = (props: Props) => {
    const intl = useIntl();

    return (
        <List>
            {/* LABEL */}
            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.label" />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>{props.mapCameraSensor.label}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            {/* PLACE TYPE */}
            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.placeType" />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        <img
                            alt={`${props.currentPlaceType.label}-logo`}
                            src={`data:image/${props.currentPlaceType.placeType.imageType};base64, ${props.currentPlaceType.placeType.image}`}
                            height="20"
                        />
                        <span className="margin-left-5">{props.currentPlaceType.label}</span>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            {/* ONLINE */}
            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.online" />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        <FontAwesomeIcon icon={faPlug} color={props.camera?.getOnline() ? 'green' : 'red'} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            {/* DETECTION STATE */}
            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.detectionState" />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        {props.mapCameraSensor.placeCamera.getState() === PlaceCameraState.FREE && (
                            <FontAwesomeIcon icon={faCircle} color="green" />
                        )}
                        {props.mapCameraSensor.placeCamera.getState() === PlaceCameraState.OCCUPIED && (
                            <FontAwesomeIcon icon={faCircle} color="red" />
                        )}
                        {props.mapCameraSensor.placeCamera.getState() === PlaceCameraState.OVERSTAY && (
                            <FontAwesomeIcon icon={faCircle} color="orange" />
                        )}
                        {props.mapCameraSensor.placeCamera.getState() === PlaceCameraState.UNKNOWN && (
                            <FontAwesomeIcon icon={faCircle} color="violet" />
                        )}{' '}
                        ({(props.mapCameraSensor.placeCamera.getStatePrediction() * 100).toFixed(0)}%) -{' '}
                        {props.mapCameraSensor.placeCamera.getDetectionStateTime()}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            {/* FORCE STATE */}
            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.isForce" />
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        <FontAwesomeIcon
                            size="lg"
                            icon={props.mapCameraSensor.placeCamera.getIsForce() ? faCheck : faTimes}
                            color={props.mapCameraSensor.placeCamera.getIsForce() ? 'green' : 'red'}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            {props.mapCameraSensor.placeCamera.getIsForce() && (
                <>
                    {/* FORCE TYPE */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item className="bold">
                                <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.forceType" />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item>
                                {props.mapCameraSensor.placeCamera.getForceType() === PlaceCameraState.FREE &&
                                    intl.formatMessage({ id: 'camera.cameraPlace.forceType.free' })}
                                {props.mapCameraSensor.placeCamera.getForceType() === PlaceCameraState.OCCUPIED &&
                                    intl.formatMessage({ id: 'camera.cameraPlace.forceType.occupied' })}
                                {props.mapCameraSensor.placeCamera.getForceType() === PlaceCameraState.OVERSTAY &&
                                    intl.formatMessage({ id: 'camera.cameraPlace.forceType.overstay' })}
                                {props.mapCameraSensor.placeCamera.getForceType() === PlaceCameraState.UNKNOWN &&
                                    intl.formatMessage({ id: 'camera.cameraPlace.forceType.unknown' })}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* END FORCE TIME */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item className="bold">
                                <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.endForceTime" />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item>{props.mapCameraSensor.placeCamera.getEndForceTime()}</FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                </>
            )}
        </List>
    );
};
