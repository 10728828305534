import moment from 'moment';

export function ipCanHandler(ipCan) {
    let busConfiguration: Array<Record<string, any>> = [];
    let config = ipCan.config;

    if (ipCan.version.includes('TCM') && config.length > 2) {
        config = config.slice(0, 2);
    }

    if (ipCan.nbDevicesOnBus) {
        for (let i = 0; i < ipCan.nbDevicesOnBus.length; i++) {
            busConfiguration.push(ipCan.nbDevicesOnBus[i]);
            busConfiguration[i].bus = 'Bus ' + (i + 1);
            busConfiguration[i].busNumber = i;

            busConfiguration[i].errors = -1;

            // busConfiguration[i].errors = ipCan.errors[i] || -1;
        }
    }

    return {
        autoSetScene: ipCan.autoSetScene,
        busConfiguration: busConfiguration,
        config: config,
        createdAt: moment(ipCan.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: ipCan.createdBy,
        creationInformations: `${ipCan.createdBy} - ${moment(ipCan.createdAt).format('DD/MM/YYYY HH:mm:ss')}`,
        gpioState: ipCan.gpioState,
        id: ipCan.id,
        ip: ipCan.ip,
        label: ipCan.label,
        mac: ipCan.mac,
        macStr: ipCan.macStr,
        nbDevicesOnBus: ipCan.nbDevicesOnBus ? ipCan.nbDevicesOnBus : 0,
        nbDisplays: ipCan.nbDisplays ? ipCan.nbDisplays : { onDB: 0, onModule: -1, online: -1 },
        nbReboot: ipCan.nbReboot ? ipCan.nbReboot : -1,
        msgFifoLength: ipCan.msgFifoLength ? ipCan.msgFifoLength : -1,
        nbSensors: ipCan.nbSensors ? ipCan.nbSensors : { onDB: 0, onModule: -1, online: -1 },
        online: ipCan.online,
        onDBLoading: false,
        serialNumber: ipCan.serialNumber,
        serialNumberStr: ipCan.serialNumberStr,
        startAt: moment(ipCan.startAt).format('DD/MM/YYYY HH:mm:ss'),
        updatedAt: moment(ipCan.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        updateInformations: `${ipCan.updatedBy} - ${moment(ipCan.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`,
        version: ipCan.version,
        lastOnlineStateDate: moment(ipCan.lastOnlineStateDate).isValid()
            ? moment(ipCan.lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss')
            : null,
    };
}
