import { faCircle, faEdit, faHourglassHalf, faPlug, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Button, ButtonGroup, Col, FlexboxGrid, List } from 'rsuite';
import { RestFull } from '../../../../../../handlers/profile/RestFull';
import { DeleteRestFullModal } from './Modal/DeleteRestFullModal';
import { EditRestFullModal } from './Modal/EditRestFullModal';

type Props = {
    restFullItem: RestFull;
    reloadUsers: () => void;
};

export const RestFullListItem = (props: Props) => {
    const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

    const handleCloseDeleteModal = (shouldReload: boolean) => {
        setDeleteModalOpen(false);
        if (shouldReload) {
            props.reloadUsers();
        }
    };

    const handleCloseEditModal = (shouldReload: boolean) => {
        setEditModalOpen(false);
        if (shouldReload) {
            props.reloadUsers();
        }
    };

    return (
        <>
            <EditRestFullModal open={editModalOpen} onHide={handleCloseEditModal} restFull={props.restFullItem} />
            <DeleteRestFullModal open={deleteModalOpen} onHide={handleCloseDeleteModal} restFull={props.restFullItem} />

            <List.Item className="panel-list">
                <FlexboxGrid>
                    <FlexboxGrid.Item componentClass={Col} xs={20}>
                        <span>
                            <FontAwesomeIcon
                                icon={faCircle}
                                color={props.restFullItem.getIsEnable() ? 'green' : 'red'}
                            />{' '}
                            <FontAwesomeIcon icon={faPlug} color={props.restFullItem.getIsOnline() ? 'green' : 'red'} />{' '}
                            {props.restFullItem.getLabel()} - {props.restFullItem.getPath()}
                        </span>
                        <p className="text-muted">
                            <FontAwesomeIcon icon={faHourglassHalf} />{' '}
                            {moment(props.restFullItem.getExpirationDate()).format('DD/MM/YYYY HH:mm')}
                        </p>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={4}>
                        <ButtonGroup>
                            <Button color="blue" onClick={() => setEditModalOpen(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button color="red" onClick={() => setDeleteModalOpen(true)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </>
    );
};
