import { faPlay, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, ButtonGroup, FlexboxGrid, Loader, Panel, PanelGroup, Tag } from 'rsuite';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';
import { AutomaticUpdateChangelog } from './AutomaticUpdateChangelog';

// STEP 2
type State = {
    changelog_api: string | undefined;
    changelog_api_error: boolean;
    changelog_api_loading: boolean;
    current_api_version: string | undefined;
    current_api_loading: boolean;
    changelog_engine: string | undefined;
    changelog_engine_error: boolean;
    changelog_engine_loading: boolean;
    current_engine_version: string | undefined;
    current_engine_loading: boolean;
    changelog_dashboard: string | undefined;
    changelog_dashboard_error: boolean;
    changelog_dashboard_loading: boolean;
    changelog_camera_sensor: string | undefined;
    changelog_camera_sensor_error: boolean;
    changelog_camera_sensor_loading: boolean;
    current_camera_sensor_version: string | undefined;
    current_camera_sensor_loading: boolean;
    changelog_occupancy_sensor: string | undefined;
    changelog_occupancy_sensor_error: boolean;
    changelog_occupancy_sensor_loading: boolean;
    current_occupancy_sensor_version: string | undefined;
    current_occupancy_sensor_loading: boolean;
    changelog_service_ml: string | undefined;
    changelog_service_ml_error: boolean;
    changelog_service_ml_loading: boolean;
    current_service_ml_version: string | undefined;
    current_service_ml_loading: boolean;
    loading: boolean;
    error: boolean;
    applicationTag?: string;
};

type Props = {
    nextStep: Function;
    closeModal: Function;
} & WrappedComponentProps;

class Changelog extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            changelog_api: undefined,
            changelog_api_error: false,
            changelog_api_loading: true,
            current_api_version: undefined,
            current_api_loading: true,
            changelog_engine: undefined,
            changelog_engine_error: false,
            changelog_engine_loading: true,
            current_engine_version: undefined,
            current_engine_loading: true,
            changelog_dashboard: undefined,
            changelog_dashboard_error: false,
            changelog_dashboard_loading: true,
            changelog_camera_sensor: undefined,
            changelog_camera_sensor_error: false,
            changelog_camera_sensor_loading: true,
            current_camera_sensor_version: undefined,
            current_camera_sensor_loading: true,
            changelog_occupancy_sensor: undefined,
            changelog_occupancy_sensor_error: false,
            changelog_occupancy_sensor_loading: true,
            current_occupancy_sensor_version: undefined,
            current_occupancy_sensor_loading: true,
            changelog_service_ml: undefined,
            changelog_service_ml_error: false,
            changelog_service_ml_loading: true,
            current_service_ml_version: undefined,
            current_service_ml_loading: true,
            loading: true,
            error: false,
        };

        this.startUpdate = this.startUpdate.bind(this);
    }

    startUpdate() {
        axiosService
            .getAxios()
            .post('/configuration/updateApp', { appName: 'All' }, { headers: authHeader() })
            .then(() => {
                this.props.nextStep();
            })
            .catch(e => {
                console.error(e);
                this.setState({
                    error: true,
                });
            });
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get('/configuration/2', { headers: authHeader() })
            .then(configurationResponse => {
                this.setState({ applicationTag: configurationResponse.data.value }, () => {
                    this.fetchApiChangelog();
                    this.fetchApiVersion();
                    this.fetchEngineChangelog();
                    this.fetchEngineVersion();
                    this.fetchDashboardChangelog();
                    this.fetchCameraSensorChangelog();
                    this.fetchCameraSensorVersion();
                    this.fetchOccupancySensorChangelog();
                    this.fetchOccupancySensorVersion();
                    this.fetchServiceMLChangelog();
                    this.fetchServiceMLVersion();
                });
            });
    }

    fetchEngineChangelog = () => {
        axiosService
            .getAxios()
            .get(
                `https://gitlab.ie-concept.fr/api/v4/projects/149/repository/files/CHANGELOG.md/raw?ref=${this.state.applicationTag}&private_token=${process.env.REACT_APP_GITLAB_API_TOKEN}`,
                { responseType: 'text' }
            )
            .then(engineChangelogResponse => {
                this.setState({ changelog_engine: engineChangelogResponse.data });
            })
            .catch(() => this.setState({ changelog_engine_error: true, changelog_engine: '' }))
            .finally(() => {
                this.setState({ changelog_engine_loading: false });
            });
    };

    fetchEngineVersion = () => {
        axiosService
            .getAxios()
            .get('/configuration/status/engine', { headers: authHeader() })
            .then(engineVersionResponse => {
                this.setState({ current_engine_version: engineVersionResponse.data.version });
            })
            .finally(() => {
                this.setState({ current_engine_loading: false });
            });
    };

    fetchApiChangelog = () => {
        axiosService
            .getAxios()
            .get(
                `https://gitlab.ie-concept.fr/api/v4/projects/139/repository/files/CHANGELOG.md/raw?ref=${this.state.applicationTag}&private_token=${process.env.REACT_APP_GITLAB_API_TOKEN}`,
                { responseType: 'text' }
            )
            .then(changelogApiResponse => this.setState({ changelog_api: changelogApiResponse.data }))
            .catch(() => this.setState({ changelog_api_error: true, changelog_api: '' }))
            .finally(() => {
                this.setState({ changelog_api_loading: false });
            });
    };

    fetchApiVersion = () => {
        axiosService
            .getAxios()
            .get('/configuration/status/api', { headers: authHeader() })
            .then(apiVersionResponse => {
                this.setState({ current_api_version: apiVersionResponse.data.version });
            })
            .finally(() => {
                this.setState({ current_api_loading: false });
            });
    };

    fetchDashboardChangelog = () => {
        axiosService
            .getAxios()
            .get(
                `https://gitlab.ie-concept.fr/api/v4/projects/136/repository/files/CHANGELOG.md/raw?ref=${this.state.applicationTag}&private_token=${process.env.REACT_APP_GITLAB_API_TOKEN}`,
                { responseType: 'text' }
            )
            .then(dashboardChangelogResponse => this.setState({ changelog_dashboard: dashboardChangelogResponse.data }))
            .catch(() => this.setState({ changelog_dashboard_error: true, changelog_dashboard: '' }))
            .finally(() => {
                this.setState({ changelog_dashboard_loading: false });
            });
    };

    fetchCameraSensorChangelog = () => {
        axiosService
            .getAxios()
            .get(
                `https://gitlab.ie-concept.fr/api/v4/projects/197/repository/files/CHANGELOG.md/raw?ref=${this.state.applicationTag}&private_token=${process.env.REACT_APP_GITLAB_API_TOKEN}`,
                { responseType: 'text' }
            )
            .then(changelogApiResponse => this.setState({ changelog_camera_sensor: changelogApiResponse.data }))
            .catch(() => this.setState({ changelog_camera_sensor_error: true, changelog_camera_sensor: '' }))
            .finally(() => {
                this.setState({ changelog_camera_sensor_loading: false });
            });
    };

    fetchCameraSensorVersion = () => {
        axiosService
            .getAxios()
            .get('/cameras/status/camerasSensor', { headers: authHeader() })
            .then(apiVersionResponse => {
                this.setState({ current_camera_sensor_version: apiVersionResponse.data.app_version });
            })
            .finally(() => {
                this.setState({ current_camera_sensor_loading: false });
            });
    };

    fetchOccupancySensorChangelog = () => {
        axiosService
            .getAxios()
            .get(
                `https://gitlab.ie-concept.fr/api/v4/projects/200/repository/files/CHANGELOG.md/raw?ref=${this.state.applicationTag}&private_token=${process.env.REACT_APP_GITLAB_API_TOKEN}`,
                { responseType: 'text' }
            )
            .then(changelogApiResponse => this.setState({ changelog_occupancy_sensor: changelogApiResponse.data }))
            .catch(() => this.setState({ changelog_occupancy_sensor_error: true, changelog_occupancy_sensor: '' }))
            .finally(() => {
                this.setState({ changelog_occupancy_sensor_loading: false });
            });
    };

    fetchOccupancySensorVersion = () => {
        axiosService
            .getAxios()
            .get('/cameras/status/occupancySensor', { headers: authHeader() })
            .then(apiVersionResponse => {
                this.setState({ current_occupancy_sensor_version: apiVersionResponse.data.app_version });
            })
            .finally(() => {
                this.setState({ current_occupancy_sensor_loading: false });
            });
    };

    fetchServiceMLChangelog = () => {
        axiosService
            .getAxios()
            .get(
                `https://gitlab.ie-concept.fr/api/v4/projects/199/repository/files/CHANGELOG.md/raw?ref=${this.state.applicationTag}&private_token=${process.env.REACT_APP_GITLAB_API_TOKEN}`,
                { responseType: 'text' }
            )
            .then(changelogApiResponse => this.setState({ changelog_service_ml: changelogApiResponse.data }))
            .catch(() => this.setState({ changelog_service_ml_error: true, changelog_service_ml: '' }))
            .finally(() => {
                this.setState({ changelog_service_ml_loading: false });
            });
    };

    fetchServiceMLVersion = () => {
        axiosService
            .getAxios()
            .get('/cameras/status/serviceMl', { headers: authHeader() })
            .then(apiVersionResponse => {
                this.setState({ current_service_ml_version: apiVersionResponse.data.app_version });
            })
            .finally(() => {
                this.setState({ current_service_ml_loading: false });
            });
    };

    render() {
        return (
            <Fragment>
                <FlexboxGrid
                    justify="center"
                    style={{
                        position: 'sticky',
                        top: 20,
                        marginTop: 20,
                        marginBottom: 20,
                        zIndex: 1100,
                        textAlign: 'center',
                    }}>
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button color="red" onClick={() => this.props.closeModal()}>
                                <FontAwesomeIcon icon={faTimesCircle} className="margin-right-10" />
                                <FormattedMessage id="update.automatic.cancel_update" />
                            </Button>
                            <Button color="green" onClick={this.startUpdate}>
                                <FontAwesomeIcon icon={faPlay} className="margin-right-10" />
                                <FormattedMessage id="update.automatic.start_update" />
                            </Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <PanelGroup bordered accordion>
                            <Panel
                                className="panel-update"
                                header={
                                    <>
                                        <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                            {this.props.intl.formatMessage({
                                                id: 'update.automatic.changelog.api',
                                            })}
                                        </div>

                                        <Tag color="orange">
                                            {this.state.current_api_loading && <Loader size="xs" />}
                                            {this.state.current_api_version &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.current_version',
                                                })} - ${this.state.current_api_version}`}
                                        </Tag>
                                        <Tag color="green">
                                            {this.state.changelog_api_loading && <Loader size="xs" />}
                                            {this.state.changelog_api_error && <FontAwesomeIcon icon={faTimes} />}
                                            {this.state.changelog_api &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.latestVersion',
                                                })} - ${this.state.changelog_api
                                                    ?.split('\n')[0]
                                                    .replaceAll('# Version ', '')}`}
                                        </Tag>
                                    </>
                                }>
                                <AutomaticUpdateChangelog
                                    title={this.props.intl.formatMessage({ id: 'update.automatic.changelog.api' })}
                                    changeLog={this.state.changelog_api}
                                    hasError={this.state.changelog_api_error}
                                    isLoading={this.state.changelog_api_loading}
                                />
                            </Panel>

                            <Panel
                                className="panel-update"
                                header={
                                    <>
                                        <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                            {this.props.intl.formatMessage({
                                                id: 'update.automatic.changelog.engine',
                                            })}
                                        </div>

                                        <Tag color="orange">
                                            {this.state.current_engine_loading && <Loader size="xs" />}
                                            {this.state.current_engine_version &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.current_version',
                                                })} - ${this.state.current_engine_version}`}
                                        </Tag>
                                        <Tag color="green">
                                            {this.state.changelog_engine_loading && <Loader size="xs" />}
                                            {this.state.changelog_engine_error && <FontAwesomeIcon icon={faTimes} />}
                                            {this.state.changelog_engine &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.latestVersion',
                                                })} - ${this.state.changelog_engine
                                                    ?.split('\n')[0]
                                                    .replaceAll('# Version ', '')}`}
                                        </Tag>
                                    </>
                                }>
                                <AutomaticUpdateChangelog
                                    title={this.props.intl.formatMessage({ id: 'update.automatic.changelog.engine' })}
                                    changeLog={this.state.changelog_engine}
                                    hasError={this.state.changelog_engine_error}
                                    isLoading={this.state.changelog_engine_error}
                                />
                            </Panel>

                            <Panel
                                className="panel-update"
                                header={
                                    <>
                                        <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                            {this.props.intl.formatMessage({
                                                id: 'update.automatic.changelog.dashboard',
                                            })}
                                        </div>

                                        <Tag color="orange">
                                            {this.state.current_engine_loading && <Loader size="xs" />}
                                            {this.state.current_engine_version &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.current_version',
                                                })} - ${process.env.REACT_APP_VERSION}`}
                                        </Tag>
                                        <Tag color="green">
                                            {this.state.changelog_dashboard_loading && <Loader size="xs" />}
                                            {this.state.changelog_dashboard_error && <FontAwesomeIcon icon={faTimes} />}
                                            {this.state.changelog_dashboard &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.latestVersion',
                                                })} - ${this.state.changelog_dashboard
                                                    ?.split('\n')[0]
                                                    .replaceAll('# Version ', '')}`}
                                        </Tag>
                                    </>
                                }>
                                <AutomaticUpdateChangelog
                                    title={this.props.intl.formatMessage({
                                        id: 'update.automatic.changelog.dashboard',
                                    })}
                                    changeLog={this.state.changelog_dashboard}
                                    hasError={this.state.changelog_dashboard_error}
                                    isLoading={this.state.changelog_dashboard_loading}
                                />
                            </Panel>

                            <Panel
                                className="panel-update"
                                header={
                                    <>
                                        <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                            {this.props.intl.formatMessage({
                                                id: 'update.automatic.changelog.cameraSensor',
                                            })}
                                        </div>

                                        <Tag color="orange">
                                            {this.state.current_camera_sensor_loading && <Loader size="xs" />}
                                            {this.state.current_camera_sensor_version &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.current_version',
                                                })} - ${this.state.current_camera_sensor_version}`}
                                        </Tag>
                                        <Tag color="green">
                                            {this.state.changelog_camera_sensor_loading && <Loader size="xs" />}
                                            {this.state.changelog_camera_sensor_error && (
                                                <FontAwesomeIcon icon={faTimes} />
                                            )}
                                            {this.state.changelog_camera_sensor &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.latestVersion',
                                                })} - ${this.state.changelog_camera_sensor
                                                    ?.split('\n')[0]
                                                    .replaceAll('# Version ', '')}`}
                                        </Tag>
                                    </>
                                }>
                                <AutomaticUpdateChangelog
                                    title={this.props.intl.formatMessage({
                                        id: 'update.automatic.changelog.dashboard',
                                    })}
                                    changeLog={this.state.changelog_camera_sensor}
                                    hasError={this.state.changelog_camera_sensor_error}
                                    isLoading={this.state.changelog_camera_sensor_loading}
                                />
                            </Panel>

                            <Panel
                                className="panel-update"
                                header={
                                    <>
                                        <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                            {this.props.intl.formatMessage({
                                                id: 'update.automatic.changelog.occupancySensor',
                                            })}
                                        </div>

                                        <Tag color="orange">
                                            {this.state.current_occupancy_sensor_loading && <Loader size="xs" />}
                                            {this.state.current_occupancy_sensor_version &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.current_version',
                                                })} - ${this.state.current_occupancy_sensor_version}`}
                                        </Tag>
                                        <Tag color="green">
                                            {this.state.changelog_occupancy_sensor_loading && <Loader size="xs" />}
                                            {this.state.changelog_occupancy_sensor_error && (
                                                <FontAwesomeIcon icon={faTimes} />
                                            )}
                                            {this.state.changelog_occupancy_sensor &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.latestVersion',
                                                })} - ${this.state.changelog_occupancy_sensor
                                                    ?.split('\n')[0]
                                                    .replaceAll('# Version ', '')}`}
                                        </Tag>
                                    </>
                                }>
                                <AutomaticUpdateChangelog
                                    title={this.props.intl.formatMessage({
                                        id: 'update.automatic.changelog.dashboard',
                                    })}
                                    changeLog={this.state.changelog_occupancy_sensor}
                                    hasError={this.state.changelog_occupancy_sensor_error}
                                    isLoading={this.state.changelog_occupancy_sensor_loading}
                                />
                            </Panel>

                            <Panel
                                className="panel-update"
                                header={
                                    <div>
                                        <div style={{ color: '#FFF', fontWeight: 700 }} className="margin-bottom-5">
                                            {this.props.intl.formatMessage({
                                                id: 'update.automatic.changelog.serviceML',
                                            })}
                                        </div>

                                        <Tag color="orange">
                                            {this.state.current_service_ml_loading && <Loader size="xs" />}
                                            {this.state.current_service_ml_version &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.current_version',
                                                })} - ${this.state.current_service_ml_version}`}
                                        </Tag>
                                        <Tag color="green">
                                            {this.state.changelog_service_ml_loading && <Loader size="xs" />}
                                            {this.state.changelog_service_ml_error && (
                                                <FontAwesomeIcon icon={faTimes} />
                                            )}
                                            {this.state.changelog_service_ml &&
                                                `${this.props.intl.formatMessage({
                                                    id: 'update.automatic.latestVersion',
                                                })} - ${this.state.changelog_service_ml
                                                    ?.split('\n')[0]
                                                    .replaceAll('# Version ', '')}`}
                                        </Tag>
                                    </div>
                                }>
                                <AutomaticUpdateChangelog
                                    title={this.props.intl.formatMessage({
                                        id: 'update.automatic.changelog.dashboard',
                                    })}
                                    changeLog={this.state.changelog_service_ml}
                                    hasError={this.state.changelog_service_ml_error}
                                    isLoading={this.state.changelog_service_ml_loading}
                                />
                            </Panel>
                        </PanelGroup>
                    </FlexboxGrid.Item>
                    {/* <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <AutomaticUpdateChangelog
                            title={this.props.intl.formatMessage({ id: 'update.automatic.changelog.api' })}
                            changeLog={this.state.changelog_api}
                            hasError={this.state.changelog_api_error}
                            isLoading={this.state.loading}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <AutomaticUpdateChangelog
                            title={this.props.intl.formatMessage({ id: 'update.automatic.changelog.engine' })}
                            changeLog={this.state.changelog_engine}
                            hasError={this.state.changelog_engine_error}
                            isLoading={this.state.loading}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                        <AutomaticUpdateChangelog
                            title={this.props.intl.formatMessage({ id: 'update.automatic.changelog.dashboard' })}
                            changeLog={this.state.changelog_dashboard}
                            hasError={this.state.changelog_dashboard_error}
                            isLoading={this.state.loading}
                        />
                    </FlexboxGrid.Item> */}
                </FlexboxGrid>
            </Fragment>
        );
    }
}

export default injectIntl(Changelog);
