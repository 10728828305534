import moment from 'moment';
import { MaintenanceInformation } from '../MaintenanceInformation';

export abstract class DeviceBase extends MaintenanceInformation {
    protected _id: number;
    protected _bus: number;
    protected _deviceId: number;
    protected _online: boolean;
    protected _createdAt: Date;
    protected _updatedAt: Date;
    protected _createdBy: string;
    protected _updatedBy: string;
    protected _versionSoft: string;
    protected _startForceTime: Date;
    protected _endForceTime: Date;
    protected _isForce: boolean;
    protected _ipCanId: number;
    protected _lastOnlineStateDate: Date;

    constructor(
        id_,
        bus_,
        deviceId_,
        online_,
        createdAt_,
        updatedAt_,
        createdBy_,
        updatedBy_,
        versionSoft_,
        startForceTime_,
        endForceTime_,
        isForce_,
        lastOnlineStateDate_,
        maintenanceState_,
        notepad_,
        ipCanId_
    ) {
        super(maintenanceState_, notepad_);

        this._id = id_;
        this._bus = bus_;
        this._deviceId = deviceId_;
        this._online = this._online = Boolean(Number(online_));
        this._createdAt = createdAt_;
        this._updatedAt = updatedAt_;
        this._createdBy = createdBy_;
        this._updatedBy = updatedBy_;
        this._versionSoft = versionSoft_;
        this._startForceTime = startForceTime_;
        this._endForceTime = endForceTime_;
        this._isForce = isForce_;
        this._lastOnlineStateDate = lastOnlineStateDate_;
        this._ipCanId = ipCanId_;
    }

    public get id(): number {
        return this._id;
    }

    public getId(): number {
        return this._id;
    }

    public get bus(): number {
        return this._bus;
    }

    public getBus(): number {
        return this._bus;
    }

    public get deviceId(): number {
        return this._deviceId;
    }

    public getDeviceId(): number {
        return this._deviceId;
    }

    public get online(): boolean {
        return this._online;
    }

    public getOnline(): boolean {
        return this._online;
    }

    public get createdAt(): string {
        return moment(this._createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public get updatedAt(): string {
        return moment(this._updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public get createdBy(): string {
        return this._createdBy;
    }

    public get updatedBy(): string {
        return this._updatedBy;
    }

    public get versionSoft(): string {
        return this._versionSoft;
    }

    public get endForceTime(): Date {
        return this._endForceTime;
    }

    public get startForceTime(): Date {
        return this._startForceTime;
    }

    public get endForceTimeFormatted(): string {
        return moment(this._endForceTime).format('DD/MM/YYYY HH:mm:ss');
    }

    public get isForce(): boolean {
        return this._isForce;
    }

    public get lastOnlineStateDateFormatted(): string {
        return moment(this._lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss');
    }

    public getLastOnlineStateDate(): string {
        return moment(this._lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss');
    }

    public get ipCanId(): number {
        return this._ipCanId;
    }

    public get creation(): string {
        return `${this.createdBy} - ${this.createdAt}`;
    }

    public get update(): string {
        return `${this.updatedBy} - ${this.updatedAt}`;
    }

    //  ____  _____ _____ _____ _____ ____  ____
    // / ___|| ____|_   _|_   _| ____|  _ \/ ___|
    // \___ \|  _|   | |   | | |  _| | |_) \___ \
    //  ___) | |___  | |   | | | |___|  _ < ___) |
    // |____/|_____| |_|   |_| |_____|_| \_\____/

    public setIsForce(isForce: boolean): boolean {
        if (this._isForce !== isForce) {
            this._isForce = isForce;
            return true;
        }
        return false;
    }

    public setLastOnlineStateDate(lastOnlineStateDate_: Date): boolean {
        if (this._lastOnlineStateDate !== lastOnlineStateDate_) {
            this._lastOnlineStateDate = lastOnlineStateDate_;
            return true;
        }
        return false;
    }

    public setEndForceTime(endForceTime_: Date): boolean {
        if (this._endForceTime !== endForceTime_) {
            this._endForceTime = endForceTime_;
            return true;
        }
        return false;
    }

    public setStartForceTime(startForceTime_: Date): boolean {
        if (this._startForceTime !== startForceTime_) {
            this._startForceTime = startForceTime_;
            return true;
        }
        return false;
    }

    public setOnline(online_: boolean | number): boolean {
        if (this._online != online_) {
            if (typeof online_ === 'number') {
                this._online = Boolean(Number(online_));
            } else {
                this._online = online_;
            }
        }
        return false;
    }
}
