interface LPSensorDebugValueUs_I {
    distance1: number;
    distance2: number;
    power1: number;
    power2: number;
}

export class LPSensorDebugValues {
    private _us1: LPSensorDebugValueUs_I;
    private _us2: LPSensorDebugValueUs_I;
    private _valueFiltered;

    constructor() {
        this._us1 = {
            distance1: 0,
            distance2: 0,
            power1: 0,
            power2: 0
        };

        this._us2 = {
            distance1: 0,
            distance2: 0,
            power1: 0,
            power2: 0
        };

        this._valueFiltered = 0;
    }

    public get_us1(): LPSensorDebugValueUs_I {
        return this._us1;
    }

    public set_us1(_us1: LPSensorDebugValueUs_I): void {
        this._us1 = _us1;
    }

    public get_us2(): LPSensorDebugValueUs_I {
        return this._us2;
    }

    public set_us2(_us2: LPSensorDebugValueUs_I): void {
        this._us2 = _us2;
    }

    public get_valueFiltered(): undefined {
        return this._valueFiltered;
    }

    public set_valueFiltered(_valueFiltered: undefined): void {
        this._valueFiltered = _valueFiltered;
    }
}