export const componentToHex = (c: number) => {
    let hex = c.toString(16);

    return hex.length === 1 ? `0${hex}` : hex;
};

export const rgbToHex = (red: number, green: number, blue: number) => {
    return `#${componentToHex(red)}${componentToHex(green)}${componentToHex(blue)}`;
};

export const hexToRgb = (hex: string) => {
    let value = hex.replace('#', '');

    const aRgbHex = value.match(/.{1,2}/g);

    if (aRgbHex) {
        return {
            red: parseInt(aRgbHex[0], 16),
            green: parseInt(aRgbHex[1], 16),
            blue: parseInt(aRgbHex[2], 16),
        };
    } else {
        return {
            red: 0,
            green: 0,
            blue: 0,
        };
    }
};
