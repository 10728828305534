import moment from 'moment';
import PlaceType from '../PlaceType';

export class SensorPlaceType {
    private _createdAt: Date;
    private _createdBy: string;
    private _updatedAt: Date;
    private _updatedBy: string;
    private _id: number;
    private _label: string;
    private _description: string;
    private _placeType?: PlaceType;
    public _scenes: any;

    constructor(sensorPlaceType_) {
        this._createdAt = sensorPlaceType_.createdAt;
        this._createdBy = sensorPlaceType_.createdBy;
        this._updatedAt = sensorPlaceType_.updatedAt;
        this._updatedBy = sensorPlaceType_.updatedBy;
        this._id = sensorPlaceType_.id;
        this._label = sensorPlaceType_.label;
        this._description = sensorPlaceType_.description;
        this._placeType = sensorPlaceType_.placeType ? new PlaceType(sensorPlaceType_.placeType) : undefined; // TODO: Create place type class
        this._scenes = sensorPlaceType_.scenes;
    }

    public getCreatedAt() {
        return moment(this._createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreatedBy() {
        return this._createdBy;
    }

    public getUpdatedAt() {
        return moment(this._updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getUpdatedBy() {
        return this._updatedBy;
    }

    public getId() {
        return this._id;
    }

    public getLabel() {
        return this._label;
    }

    public getDescription() {
        return this._description;
    }

    public getPlaceType() {
        return this._placeType;
    }

    // public getScenes() {
    //     return this._scenes;
    // }

    // public getInformations() {
    //     return {
    //         createdAt: this._createdAt,
    //         createdBy: this._createdBy,
    //         updatedAt: this._updatedAt,
    //         updatedBy: this._updatedBy,
    //         id: this._id,
    //         label: this._label,
    //         description: this._description,
    //         placeType: this._placeType,
    //         scenes: this._scenes,
    //     };
    // }
}
