import { faCalendarAlt, faCheck, faDotCircle, faFileExport, faRobot, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, Col, FlexboxGrid, Panel, Tag, TagGroup } from 'rsuite';
import { EventConditionType } from '../../handlers/EventAction/EventAction';
import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';
import { useFetchCounter } from '../../utils/useFetchCounter';
import { useFetchIpCan } from '../../utils/useFetchIpCan';
import PanelHeader from '../Custom/PanelHeader';
import HistoryTable from '../ReactDataTableComponent/HistoryTable';
import HistorySortPart from './SortPart';

type Props = {};

type FormValues = {
    user: string | null;
    before: Date | null;
    after: Date | null;
    nbElements: number;
};

export const EventHistoricTable = (props: Props) => {
    const intl = useIntl();

    const [historic, setHistoric] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [users, setUsers] = useState<{ label: string; value: string }[]>([]);
    const [formValues, setFormValues] = useState<FormValues>({
        user: null,
        before: null,
        after: null,
        nbElements: 100,
    });

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/historics/events', {
                headers: authHeader(),
            })
            .then(response => {
                setHistoric(response.data);
                const fetchedUsers: { label: string; value: string }[] = [];

                response.data.forEach(ev => {
                    if (!fetchedUsers.find(u => u.value === ev.user)) {
                        fetchedUsers.push({ label: ev.user, value: ev.user });
                    }
                });
                setUsers(fetchedUsers);
            })
            .catch(err => {
                Alert.error(intl.formatMessage({ id: 'events.historic.error' }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const exportHistoric = () => {
        let allText = '';
        let nbLines = historic.length;
        let sortLines = formValues.nbElements;
        let sortUser = formValues.user;
        let sortBefore = formValues.before;
        let sortAfter = formValues.after;
        let fileName = 'export-historic';

        if (nbLines > 0) {
            if ('-' + sortUser != null) {
                allText = allText + 'user:' + sortUser;
                fileName = fileName + '-user:' + sortUser;
            }

            if (sortAfter + '-' + sortBefore != 'null-null') {
                allText = allText + ';start: ' + sortBefore + ';end: ' + sortAfter;
                fileName = fileName + '-start:' + sortBefore + '-end:' + sortAfter;
            } else {
                allText = allText + ';;';
            }

            fileName = fileName + '-lines:' + sortLines + '-date:' + moment().format('YYYY-MM-DD_HH:mm:ss') + '.csv';
            allText =
                allText +
                ';lines: ' +
                sortLines +
                '\nhistory.ipCanModule;nbLines: ' +
                nbLines +
                '\nuser;createdAt;ipCanModuleName;description;option\n';

            for (let i = 0; i < nbLines; i++) {
                allText =
                    allText +
                    historic[i].user +
                    ';' +
                    historic[i].createdAt +
                    ';' +
                    historic[i].ipcanmoduleName +
                    ';' +
                    historic[i].desc +
                    ';' +
                    JSON.stringify(historic[i].option) +
                    '\n';
            }

            const csvData = new Blob([allText], { type: 'text/csv;charset=utf-8;' });
            saveAs(csvData, fileName);

            Alert.success(intl.formatMessage({ id: 'historic.exportCSV.success' }));
        }
    };

    const columns: TableColumn<RowRecord>[] = useMemo(() => {
        return [
            {
                name: intl.formatMessage({ id: 'events.historic.table.user' }),
                center: true,
                cell: row => {
                    if (row.user === 'MQTT') return <FontAwesomeIcon icon={faRobot} />;

                    return row.user;
                },
            },
            {
                name: intl.formatMessage({ id: 'events.historic.table.eventName' }),
                center: true,
                selector: 'name',
            },
            {
                name: intl.formatMessage({ id: 'events.historic.table.description' }),
                center: true,
                selector: 'desc',
            },
        ];
    }, []);

    const setUser = (user: string | null) => {
        setFormValues({ ...formValues, user });
    };

    const setBefore = (before: Date | null) => {
        setFormValues({ ...formValues, before });
    };

    const setAfter = (after: Date | null) => {
        setFormValues({ ...formValues, after });
    };

    const setNbElements = (nbElements: number) => {
        setFormValues({ ...formValues, nbElements });
    };

    const loadHistory = () => {
        setIsLoading(true);
        axiosService
            .getAxios()
            .post(
                '/historics/events',
                {
                    user: formValues.user,
                    before: formValues.before,
                    after: formValues.after,
                    nbElements: formValues.nbElements,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(response => {
                setHistoric(response.data);
            })
            .catch(err => {
                Alert.error(intl.formatMessage({ id: 'events.historic.error' }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Panel
            className="panel-big dashboard-card-header"
            shaded
            bordered
            header={
                <PanelHeader
                    icon={faCalendarAlt}
                    title={intl.formatMessage({ id: 'history.event' })}
                    buttons={[
                        <FlexboxGrid.Item componentClass={Col} xs={2.5}>
                            <Button color="orange" block onClick={exportHistoric} data-cy="history-export">
                                <FontAwesomeIcon icon={faFileExport} />
                                Exporter
                            </Button>
                        </FlexboxGrid.Item>,
                    ]}
                />
            }
            bodyFill>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <HistorySortPart
                    users={users}
                    setUser={setUser}
                    setBefore={setBefore}
                    setAfter={setAfter}
                    valid={loadHistory}
                    setNbElements={setNbElements}
                />
            </div>

            <HistoryTable
                columns={columns}
                data={historic}
                progressPending={isLoading}
                expandableRows
                expandOnRowClicked
                expandableRowDisabled={row => Object.keys(row.option).length === 0}
                expandableRowsComponent={EventHistoricOption}
            />
        </Panel>
    );
};

const formattedDate = minutesToAdd => {
    return moment().startOf('day').add(minutesToAdd, 'minutes').format(' : HH:mm');
};

const EventHistoricOption = (props: any) => {
    let option;

    if (Array.isArray(props.data.option)) {
        option = props.data.option[0];
    } else {
        option = props.data.option;
    }

    const { eventCondition } = option;

    return (
        <div style={{ padding: 16 }}>
            <span>
                {option.name} - {option.desc}
            </span>
            <br />
            <span>
                <FormattedMessage id="event.isInfinite" /> -{' '}
                <FontAwesomeIcon
                    icon={option.isInfinite ? faCheck : faTimes}
                    color={option.isInfinite ? 'green' : 'red'}
                />
            </span>
            <br />
            <span>
                <FormattedMessage id="event.isEnable" /> -{' '}
                <FontAwesomeIcon icon={option.isEnable ? faCheck : faTimes} color={option.isEnable ? 'green' : 'red'} />
            </span>
            <br />
            <span>
                <FormattedMessage id="event.isTriggered" /> -{' '}
                <FontAwesomeIcon
                    icon={option.isTriggered ? faCheck : faTimes}
                    color={option.isTriggered ? 'green' : 'red'}
                />
            </span>
            <br />
            <span>
                <FormattedMessage id="event.startTime" /> -{' '}
                {moment().startOf('day').add(option.startTime, 'minutes').format('HH:mm')}
            </span>
            <br />
            {eventCondition && (
                <>
                    <span>
                        <FormattedMessage id="event.autoresetTrigger" /> -{' '}
                        <FormattedMessage id={'event.autoresetTrigger.' + eventCondition?.autoResetTrigger} />
                        {eventCondition?.autoResetTriggerTime
                            ? formattedDate(eventCondition?.autoResetTriggerTime)
                            : ''}
                    </span>
                    <br />
                    {eventCondition?.eventConditionType === EventConditionType.COUNTERS && (
                        <TagGroup>
                            {eventCondition?.countersId.map((counter: any) => (
                                <EventHistoricCounter counter={counter} />
                            ))}
                        </TagGroup>
                    )}
                    {eventCondition?.eventConditionType === EventConditionType.GPIO && (
                        <EventHistoricGpio gpioCondition={eventCondition?.gpioCondition} />
                    )}
                </>
            )}
            {!option.isInfinite && (
                <>
                    <span>
                        <FormattedMessage id="event.startDate" /> - {moment(option.startDate).format('DD/MM/YYYY')}
                    </span>
                    <br />
                    <span>
                        <FormattedMessage id="event.endDate" /> - {moment(option.endDate).format('DD/MM/YYYY')}
                    </span>
                    <br />
                </>
            )}
            {option.daily && (
                <TagGroup data-cy="event-list-daily">
                    {Object.keys(option.daily).map(day => {
                        if (option.daily[day]) {
                            return (
                                <Tag color="violet" key={`${option.id}-${day}`}>
                                    <FormattedMessage id={`event.daily.day.${day}`} />
                                </Tag>
                            );
                        }
                    })}
                </TagGroup>
            )}
        </div>
    );
};

const EventHistoricCounter = (props: {
    counter: { counterId: number; triggerType: string; triggerValue: string | number };
}) => {
    const counter = useFetchCounter(props.counter.counterId);

    return (
        <Tag color="blue">
            {counter?.label} {props.counter.triggerType} {props.counter.triggerValue}
        </Tag>
    );
};

const EventHistoricGpio = (props: {
    gpioCondition: {
        moduleIpCanId: number;
        gpioNumber: number;
        gpioState: boolean;
    };
}) => {
    const ipcan = useFetchIpCan(props.gpioCondition.moduleIpCanId);

    return (
        <Tag>
            <FontAwesomeIcon
                icon={faDotCircle}
                color={props.gpioCondition.gpioState ? 'green' : 'red'}
                style={{ marginRight: 8 }}
            />{' '}
            {ipcan?.label} ({ipcan?.macStr}) - {props.gpioCondition.gpioNumber}
        </Tag>
    );
};
