import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';
import PlaceCamera from '../../../../handlers/Camera/PlaceCamera';
import PanelHeader from '../../../Custom/PanelHeader';
import { EditHyperParamPlaceCameraModal } from '../../Modal/EditHyperParamPlaceCameraModal';
import { HyperParamPlaceCameraView } from './HyperParamPlaceCameraView';

type Props = {
    place: PlaceCamera;
    reloadPlaces: () => void;
};

export const HyperParamPlaceCamera = (props: Props) => {
    const intl = useIntl();

    const [editHyperParamModal, setEditHyperParamModal] = useState<boolean>(false);

    const handleCloseEditHyperParamPlaceCameraModal = () => {
        setEditHyperParamModal(false);
        props.reloadPlaces();
    };

    return (
        <>
            <EditHyperParamPlaceCameraModal
                show={editHyperParamModal}
                onClose={handleCloseEditHyperParamPlaceCameraModal}
                placesCameraSelected={[props.place]}
            />

            <Panel
                className="panel-small"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'camera.place.hyperParameter' })}
                        buttons={[
                            <Button
                                key="hyperParam-edit"
                                color="blue"
                                size="sm"
                                onClick={() => setEditHyperParamModal(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>,
                        ]}
                    />
                }>
                <HyperParamPlaceCameraView place={props.place} />
            </Panel>
        </>
    );
};
