import { IP_CAMERA_HISTORY_EVENTS } from './IpCameraDescCell';
import movePtz from './Option/IpCamera/movePtz';
import saveHome from './Option/IpCamera/saveHome';
import sendStreamConf from './Option/IpCamera/sendStreamConf';
import NoOptions from './Option/noOptions';
import updateMaintenanceState from './Option/updateMaintenanceState';

export default function IpCameraOptionCell(desc: IP_CAMERA_HISTORY_EVENTS, option) {
    switch (desc) {
        case IP_CAMERA_HISTORY_EVENTS.SEND_STREAM_CONF:
            return sendStreamConf(option);
        case IP_CAMERA_HISTORY_EVENTS.MOVE_PTZ:
            return movePtz(option);
        case IP_CAMERA_HISTORY_EVENTS.SAVE_HOME:
            return saveHome(option);
        case IP_CAMERA_HISTORY_EVENTS.UPDATE_MAINTENANCE_STATE:
            return updateMaintenanceState(option);
        default:
            return NoOptions();
    }
}
