import { faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function nbElements(isOnline, elements, onDrawer) {
    const { onDB, onModule, online } = elements;

    if (onDrawer == true)
        return (
            <div data-cy="controllers-nbElements-drawer" data-tag="allowRowEvents">
                {displayValue(isOnline, online)} / {displayValue(isOnline, onModule)} / {onDB}
            </div>
        );
    else
        return (
            <div data-cy="controllers-nbElements-ipCanMainPage" data-tag="allowRowEvents">
                {displayValue(isOnline, online)} / {displayValue(isOnline, onModule)} / {onDB}
            </div>
        );
}

const displayValue = (isOnline, value) => {
    if (isOnline === 0) return <FontAwesomeIcon data-tag="allowRowEvents" icon={faTimesCircle} />;
    return value === -1 ? <FontAwesomeIcon data-tag="allowRowEvents" icon={faCircleNotch} spin /> : value;
};
