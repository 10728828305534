import { FlowElement } from 'react-flow-renderer';
import { LPVirtualDisplayColors } from '../../../../handlers/ipCanDevices/const/colors';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayStep, VirtualDisplayStepState } from '../../../../handlers/lpDisplays/VirtualDisplayStep';
import FlowEdge from './Edges/FlowEdge';
import FlowEdgeTrigger from './Edges/FlowEdgeTrigger';
import { FlowEdgeVirtualDisplayStepTrigger } from './Edges/FlowEdgeVirtualDisplayStepTrigger';
import { FlowEdgeWithDeleteButton } from './Edges/FlowEdgeWithDeleteButton';
import { FlowEdgeWithLabel } from './Edges/FlowEdgeWithLabel';
import TopologyFlowNode, { ColoredPointsInterface } from './Nodes/TopologyFlowNode';
import VirtualDisplayFlowNode from './Nodes/VirtualDisplayFlowNode';
import VirtualDisplayStepFlowNode from './Nodes/VirtualDisplayStepFlowNode';
export type ColoredPoints = {
    color: string;
    sourcePoint: string;
    sourceHandlePoint: string;
    targetPoint: string;
    targetHandlePoint: string;
};

const TOPOLOGY_TRIGGER_COLORS = [
    '#f427b7',
    '#bb1fef',
    '#f751f7',
    '#5de0f4',
    '#6497c1',
    '#061266',
    '#aaa6fc',
    '#28ff28',
    '#eae87c',
    '#68d4ff',
    '#7caaf4',
    '#113f9b',
    '#b7650c',
    '#64e56f',
    '#27aa78',
    '#1aaf62',
    '#7678ed',
    '#0d1c72',
    '#cbef88',
    '#80db53',
    '#1cd108',
    '#99f7b7',
    '#fafc9f',
    '#39ef4e',
];
export default class FlowChartFactory {
    private _display: LPDisplay;

    constructor(display: LPDisplay) {
        this._display = display;
    }

    public getElements(): FlowElement[] {
        let elements: FlowElement[] = [];

        elements = elements.concat(this.getTopologies());
        elements = elements.concat(this.getVirtualDisplays());
        elements = elements.concat(this.getSteps());

        // Link between topologies and virtual displays
        elements = elements.concat(this.getTopologyVirtualDisplayLink());
        // Link between topologies
        elements = elements.concat(this.getTopologyTriggerLink());
        // Link between steps
        elements = elements.concat(this.getStepsEdgeLink());

        return elements;
    }

    /**
     * Fetch the topologies from the display
     * @returns FlowElements[] React Flow elements of topologies
     */
    private getTopologies(): FlowElement[] {
        const topologies = this._display
            .getTopologyConfig()
            .getTopologys()
            .map((topology, index) => {
                let sourceTriggerColor: ColoredPointsInterface[] = [];
                let targetVirtualDisplayColor: ColoredPointsInterface = {
                    isConnected: topology.virtualDisplays.length > 0,
                    color: LPVirtualDisplayColors[index],
                };

                topology.triggers.forEach((trigger, triggerIndex) => {
                    sourceTriggerColor.push({
                        color: TOPOLOGY_TRIGGER_COLORS[index * 4 + triggerIndex],
                        isConnected: trigger.trigger !== 0,
                    });
                });

                let nbTarget = 0;
                let lastTarget = 0;

                this._display
                    .getTopologyConfig()
                    .getTopologys()
                    .forEach((t, t_index) => {
                        t.triggers.forEach((trigger, triggerIndex) => {
                            if (trigger.trigger !== 0 && trigger.next === index) {
                                nbTarget++;
                                lastTarget = t_index * 4 + triggerIndex;
                            }
                        });
                    });

                const targetTriggerColor: ColoredPointsInterface = {
                    isConnected: nbTarget > 0,
                    color: nbTarget < 2 ? TOPOLOGY_TRIGGER_COLORS[lastTarget] : '#006A00',
                };

                return new TopologyFlowNode(
                    `topology-${index}`,
                    `topology-${index + 1}`,
                    index,
                    this._display,
                    topology,
                    sourceTriggerColor,
                    targetVirtualDisplayColor,
                    targetTriggerColor
                );
            });

        return topologies.map(topology => topology.toRender());
    }

    /**
     * Fetch the virtual displays from the display
     * @returns FlowElements[] React Flow elements of virtual display
     */
    private getVirtualDisplays(): FlowElement[] {
        const virtualDisplays = this._display.getVirtualDisplays().map((virtualDisplay, index) => {
            let sourceTopologyColor: ColoredPointsInterface = {
                isConnected: false,
                color: LPVirtualDisplayColors[index],
            };

            this._display
                .getTopologyConfig()
                .getTopologys()
                .forEach((topology, topologyIndex) => {
                    if (topology.virtualDisplays.includes(virtualDisplay.getPosId() + 1)) {
                        sourceTopologyColor = {
                            isConnected: true,
                            color: LPVirtualDisplayColors[topologyIndex],
                        };
                    }
                });

            return new VirtualDisplayFlowNode(
                `virtualDisplay-${index}`,
                `virtualDisplay-${index + 1}`,
                index,
                this._display,
                virtualDisplay.getPreviewInformation(),
                sourceTopologyColor
            );
        });

        return virtualDisplays.map(virtualDisplay => virtualDisplay.toRender());
    }

    private getSteps(): FlowElement[] {
        let elements: FlowElement[] = [];

        this._display.getVirtualDisplays().forEach((virtualDisplay, vdIndex) => {
            const steps = virtualDisplay
                .getSteps()
                .filter(step => !step.getIsForcedStep())
                .map(step => step);
            const nbOfEnabledSteps = steps.filter(step => step.getState() === VirtualDisplayStepState.ENABLED).length;

            steps.sort((a: VirtualDisplayStep, b: VirtualDisplayStep): number => {
                if (a.getState() === 1 && b.getState() === 1) {
                    if (a.getStepOrder() < b.getStepOrder()) return -1;
                    if (a.getStepOrder() > b.getStepOrder()) return 1;

                    return 0;
                }

                if (a.getState() === 1) return -1;
                if (b.getState() === 1) return 1;

                if (a.getState() > b.getState()) return -1;
                if (a.getState() < b.getState()) return 1;
                return 0;
            });

            steps.forEach((step, stepIndex) => {
                const isFirst = stepIndex === 0;
                const isLast = stepIndex === nbOfEnabledSteps - 1;

                const virtualDisplayStepFlowNode = new VirtualDisplayStepFlowNode(
                    `virtualDisplay-${vdIndex}-step-${stepIndex}`,
                    this._display,
                    virtualDisplay.getPreviewInformation(),
                    vdIndex,
                    step,
                    stepIndex,
                    isFirst,
                    isLast
                );

                elements.push(virtualDisplayStepFlowNode.toRender());
            });
        });

        return elements;
    }

    /**
     * Create the edge between topologies and virtual displays
     * @returns FlowElements[] React Flow elements of topologies and virtual displays edge
     */
    private getTopologyVirtualDisplayLink(): FlowElement[] {
        const elements: FlowElement[] = [];

        const topologies = this._display.getTopologyConfig().getTopologys();
        const virtualDisplays = this._display.getVirtualDisplays();

        topologies.forEach((topology, topologyIndex) => {
            virtualDisplays.forEach((virtualDisplay, virtualDisplayIndex) => {
                if (topology.virtualDisplays.includes(virtualDisplay.getPosId() + 1)) {
                    const flowEdgeWithDeleteButton = new FlowEdgeWithDeleteButton(
                        `virtualDisplay-${virtualDisplayIndex}`,
                        'virtualDisplay-topology',
                        `topology-${topologyIndex}`,
                        'target-virtualDisplay',
                        topologyIndex,
                        virtualDisplayIndex,
                        virtualDisplay.getPosId() + 1
                    );

                    elements.push(flowEdgeWithDeleteButton.toRender());
                }
            });
        });

        return elements;
    }

    /**
     * Create the edge between topologies
     */
    private getTopologyTriggerLink(): FlowElement[] {
        const elements: FlowElement[] = [];

        const topologies = this._display.getTopologyConfig().getTopologys();

        topologies.forEach((topology, topologyIndex) => {
            topology.triggers.forEach((trigger, triggerIndex) => {
                if (trigger.trigger !== 0) {
                    const flowEdgeTopology = new FlowEdgeTrigger(
                        `topology-${topologyIndex}`,
                        `source-topology-${triggerIndex}`,
                        `topology-${trigger.next}`,
                        'target-topology',
                        trigger,
                        topologyIndex,
                        trigger.next,
                        triggerIndex,
                        TOPOLOGY_TRIGGER_COLORS[topologyIndex * 4 + triggerIndex]
                    );

                    elements.push(flowEdgeTopology.toRender());
                }
            });
        });

        return elements;
    }

    /**
     * Create the edge between steps
     */
    private getStepsEdgeLink(): FlowElement[] {
        const elements: FlowElement[] = [];

        const virtualDisplays = this._display.getVirtualDisplays();

        virtualDisplays.forEach((virtualDisplay, index) => {
            const steps = virtualDisplay.getSteps().map(step => step);

            steps.sort((a: VirtualDisplayStep, b: VirtualDisplayStep): number => {
                if (a.getState() === 1 && b.getState() === 1) {
                    if (a.getStepOrder() < b.getStepOrder()) return -1;
                    if (a.getStepOrder() > b.getStepOrder()) return 1;

                    return 0;
                }

                if (a.getState() === 1) return -1;
                if (b.getState() === 1) return 1;

                if (a.getState() > b.getState()) return -1;
                if (a.getState() < b.getState()) return 1;
                return 0;
            });

            const enabledSteps = steps.filter(step => step.getState() === VirtualDisplayStepState.ENABLED);

            enabledSteps.forEach((step, stepIndex) => {
                if (stepIndex === 0) {
                    const element = new FlowEdge(
                        `virtualDisplay-${index}`,
                        'virtualDisplay-step',
                        `virtualDisplay-${index}-step-${stepIndex}`,
                        'target-virtual-display',
                        LPVirtualDisplayColors[index]
                    );
                    elements.push(element.toRender());
                }

                if (stepIndex === enabledSteps.length - 1 && enabledSteps.length > 1 && step.getTime() > 0) {
                    const element = new FlowEdgeWithLabel(
                        `virtualDisplay-${index}-step-${stepIndex}`,
                        'source-virtual-display-step-loop',
                        `virtualDisplay-${index}-step-0`,
                        'target-virtual-display-step-loop',
                        step,
                        '#000'
                    );

                    elements.push(element.toRender());
                }

                if (stepIndex !== 0 && enabledSteps[stepIndex - 1].getTime() > 0) {
                    const element = new FlowEdgeWithLabel(
                        `virtualDisplay-${index}-step-${stepIndex - 1}`,
                        'source-virtual-display-step',
                        `virtualDisplay-${index}-step-${stepIndex}`,
                        'target-virtual-display-step',
                        enabledSteps[stepIndex - 1],
                        '#000'
                    );

                    elements.push(element.toRender());
                }
            });

            steps.forEach((step, stepIndex) => {
                if (steps.length > 1 && step.getMode() >= 101 && step.getMode() <= 132) {
                    const triggerMin = step.getTriggerMin().getActionInformation();
                    const triggerMax = step.getTriggerMax().getActionInformation();

                    if (triggerMin.name === 'step') {
                        const element = new FlowEdgeVirtualDisplayStepTrigger(
                            `virtualDisplay-${index}-step-${stepIndex}`,
                            'source-virtual-display-step-trigger-min',
                            `virtualDisplay-${index}-step-${triggerMin.number - 1}`,
                            'target-virtual-display-step-trigger',
                            step.getTriggerMin(),
                            'min',
                            '#00FF00'
                        );

                        elements.push(element.toRender());
                    }

                    if (step.getTriggerMax().getActionInformation().name === 'step') {
                        const element = new FlowEdgeVirtualDisplayStepTrigger(
                            `virtualDisplay-${index}-step-${stepIndex}`,
                            'source-virtual-display-step-trigger-max',
                            `virtualDisplay-${index}-step-${triggerMax.number - 1}`,
                            'target-virtual-display-step-trigger',
                            step.getTriggerMax(),
                            'max',
                            '#FF0000'
                        );

                        elements.push(element.toRender());
                    }
                }
            });
        });

        return elements;
    }
}
