import { faDesktop, faFileExport, faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, Message, Col, Panel, FlexboxGrid, Alert } from 'rsuite';
import { saveAs } from 'file-saver';
import LPMatrixDisplayV2Historic from '../../handlers/history/LPMatrixDisplayV2Historic.handler';
import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';
import PanelHeader from '../Custom/PanelHeader';
import HistoryTable from '../ReactDataTableComponent/HistoryTable';
import HistorySortPart from './SortPart';
import LPMatrixDisplayV2Expandable from './Table/Expandable/LPMatrixDisplayV2Expandable';
import LPMatrixDisplayV2DescCell from './Table/LPMatrixDisplayV2DescCell';
import LPMatrixDisplayV2OptionCell from './Table/LPMatrixDisplayV2OptionCell';

type Props = WrappedComponentProps;

type State = {
    histories: Array<Record<string, any>>;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    users: Array<Record<string, any>>;
    formValues: FormValues;
};

type FormValues = {
    user: string | null;
    before: Date | null;
    after: Date | null;
    nbElements: number;
};

class LPMatrixDisplayV2History extends React.Component<Props, State> {
    columns: TableColumn<RowRecord>[];

    constructor(props: Props) {
        super(props);

        this.state = {
            histories: [],
            isLoading: true,
            hasError: false,
            error: '',
            users: [],
            formValues: {
                user: null,
                before: null,
                after: null,
                nbElements: 100,
            },
        };

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'history.LPMatrixDisplayV2.user',
                }),
                selector: 'user',
                sortable: true,
                cell: row => {
                    if (row.user === 'MQTT') {
                        return <FontAwesomeIcon icon={faRobot} size="lg" />;
                    } else {
                        return row.user;
                    }
                },
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'history.LPMatrixDisplayV2.createdAt',
                }),
                selector: 'createdAt',
                sortable: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'history.LPMatrixDisplayV2.LPMatrixDisplayV2Name',
                }),
                selector: 'LPMatrixDisplayV2Name',
                sortable: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'history.LPMatrixDisplayV2.desc',
                }),
                selector: 'desc',
                sortable: true,
                cell: row => LPMatrixDisplayV2DescCell(row.desc),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.LPMatrixDisplayV2.option',
                }),
                selector: 'option',
                cell: row => LPMatrixDisplayV2OptionCell(row.desc, row.option),
            },
        ];
    }

    componentDidMount() {
        this.loadHistory();
    }

    loadHistory = () => {
        this.setState({
            isLoading: true,
            error: '',
            hasError: false,
        });

        axiosService
            .getAxios()
            .post(
                '/historics/lp-matrixDisplayV2',
                {
                    nbResult: this.state.formValues.nbElements || 100,
                    user: this.state.formValues.user,
                    after: this.state.formValues.after ? moment(this.state.formValues.after).toISOString() : null,
                    before: this.state.formValues.before ? moment(this.state.formValues.before).toISOString() : null,
                },
                { headers: authHeader() }
            )
            .then(lpSensorHistoryResponse => {
                let users: Array<string> = [];

                const histories = lpSensorHistoryResponse.data.map(history => {
                    const lpSensorHistory = new LPMatrixDisplayV2Historic(history);

                    users.indexOf(lpSensorHistory.getUser()) === -1 && users.push(lpSensorHistory.getUser());

                    return lpSensorHistory.getHistory();
                });
                this.setState({
                    histories,
                    users: users.map(user => {
                        return { label: user, value: user };
                    }),
                });
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    setUser = user => {
        this.setState({
            formValues: { ...this.state.formValues, user },
        });
    };

    setBefore = before => {
        this.setState({
            formValues: { ...this.state.formValues, before },
        });
    };

    setAfter = after => {
        this.setState({
            formValues: { ...this.state.formValues, after },
        });
    };

    setNbElements = nbElements => {
        this.setState({
            formValues: { ...this.state.formValues, nbElements },
        });
    };

    exportLPMatrixDisplayV2History = () => {
        let allText = '';
        let nbLines = this.state.histories.length;
        let sortLines = this.state.formValues.nbElements;
        let sortUser = this.state.formValues.user;
        let sortBegining = moment(this.state.formValues.before).toISOString();
        let sortStop = moment(this.state.formValues.after).toISOString();
        let fileName = 'export-LPMatrixDisplayV2History';

        if (nbLines > 0) {
            if ('-' + sortUser != '-null') {
                allText = allText + 'user:' + sortUser;
                fileName = fileName + '-user:' + sortUser;
            }
            if (sortStop + '-' + sortBegining != 'null-null') {
                allText = allText + ';start: ' + sortBegining + ';end: ' + sortStop;
                fileName = fileName + '-start:' + sortBegining + '-end:' + sortStop;
            } else {
                allText = allText + ';;';
            }

            fileName = fileName + '-lines:' + sortLines + '-date:' + moment().format('YYYY-MM-DD_HH:mm:ss') + '.csv';
            allText =
                allText +
                ';lines: ' +
                sortLines +
                '\nhistory.LPMatrixDisplayV2;nbLines: ' +
                nbLines +
                '\nuser;createdAt;LPMatrixDisplayV2Name;description;option\n';

            for (let i = 0; i < nbLines; i++) {
                allText =
                    allText +
                    this.state.histories[i].user +
                    ';' +
                    this.state.histories[i].createdAt +
                    ';' +
                    this.state.histories[i].LPMatrixDisplayV2Name +
                    ';' +
                    this.state.histories[i].desc +
                    ';' +
                    JSON.stringify(this.state.histories[i].option) +
                    '\n';
            }

            const csvData = new Blob([allText], { type: 'text/csv;charset=utf-8;' });
            saveAs(csvData, fileName);

            Alert.success(this.props.intl.formatMessage({ id: 'LPMatrixDisplayV2History.exportCSV.success' }));
        } else {
            Alert.error(this.props.intl.formatMessage({ id: 'LPMatrixDisplayV2History.exportCSV.error' }));
        }
    };

    render() {
        if (this.state.hasError) {
            return (
                <Message type="error">
                    <FormattedMessage
                        id="history.LPMatrixDisplayV2.fetchHistoryError"
                        values={{ error: this.state.error }}
                    />
                </Message>
            );
        } else {
            return (
                <Panel
                    className="panel-big dashboard-card-header"
                    shaded
                    bordered
                    header={
                        <PanelHeader
                            icon={faDesktop}
                            title={this.props.intl.formatMessage({ id: 'history.LPMatrixDisplayV2' })}
                            buttons={[
                                <FlexboxGrid.Item componentClass={Col} xs={2.5}>
                                    <Button
                                        color="orange"
                                        block
                                        onClick={this.exportLPMatrixDisplayV2History}
                                        data-cy="history-export">
                                        <FontAwesomeIcon icon={faFileExport} />
                                        Exporter
                                    </Button>
                                </FlexboxGrid.Item>,
                            ]}
                        />
                    }
                    bodyFill>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <HistorySortPart
                            users={this.state.users}
                            setUser={this.setUser}
                            setBefore={this.setBefore}
                            setAfter={this.setAfter}
                            valid={this.loadHistory}
                            setNbElements={this.setNbElements}
                        />
                    </div>
                    <HistoryTable
                        columns={this.columns}
                        data={this.state.histories}
                        progressPending={this.state.isLoading}
                        expandableRows
                        expandOnRowClicked
                        expandableRowDisabled={row => row.disabled}
                        expandableRowsComponent={LPMatrixDisplayV2Expandable}
                    />
                </Panel>
            );
        }
    }
}

export default injectIntl(LPMatrixDisplayV2History);
