import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function UpdateUserTextConfig(option) {
    return (
        <div>
            <div data-cy="lpMatrixDisplayV2-historic-userTexts">
                <FormattedMessage id="lpMatrixDisplayV2.history.userTexts" />
                <span className="margin-left-5 margin-right-5">:</span>
                {option.texts.map(o => {
                    return <div>{o}</div>;
                })}
            </div>
        </div>
    );
}
