import { faCheck, faCircle, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Button, FlexboxGrid, List, Nav, Panel } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import PanelHeader from '../../Custom/PanelHeader';
import EditNetworkModal from '../Actions/Modal/Network';

type Props =  WrappedComponentProps;

type State = {
    network: NetworkState;
    vpn: VPNState;
    supervision: SupervisionState;
    isLoading: boolean;
    tab: string;
    editNetworkOpen: boolean;
};

type NetworkState = {
    connected: boolean;
    ipAddress: string | undefined;
    mask: string | undefined;
    gateway: string | undefined;
    isDHCP: boolean | undefined;
};

type VPNState = {
    connected: boolean;
    ipAddress: string | undefined;
};

type SupervisionState = {
    connected: boolean;
    keyName: string | undefined;
};
class NetworkCard extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            network: {
                connected: false,
                ipAddress: undefined,
                mask: undefined,
                gateway: undefined,
                isDHCP: undefined,
            },
            vpn: {
                connected: false,
                ipAddress: undefined,
            },
            supervision: {
                connected: false,
                keyName: undefined,
            },
            isLoading: false,
            tab: 'network',
            editNetworkOpen: false,
        };

        this.openEditNetworkModal = this.openEditNetworkModal.bind(this);
        this.closeEditNetworkModal = this.closeEditNetworkModal.bind(this);
        this.updateNetwork = this.updateNetwork.bind(this);
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get('/configuration/status/network', { headers: authHeader() })
            .then(response => {
                this.setState({
                    network: {
                        connected: response.data.connected,
                        ipAddress: response.data.ipAddress,
                        mask: response.data.mask,
                        gateway: response.data.gateway,
                        isDHCP: response.data.isDHCP,
                    },
                    isLoading: false,
                });
            })
            .catch(error => console.error(error));
    }

    openEditNetworkModal() {
        this.setState({
            editNetworkOpen: true,
        });
    }

    closeEditNetworkModal() {
        this.setState({
            editNetworkOpen: false,
        });
    }

    updateNetwork(formValue) {
        this.setState({
            network: { ...this.state.network, ...formValue },
        });
    }

    render() {
        return (
            <Fragment>
                <Panel
                    bodyFill
                    shaded
                    className="panel-small"
                    header={
                        <SecuredFragment authorizedRoles={[rolesConstants.configuration.UPDATE_NETWORK]}>
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'configuration.network' })}
                                buttons={[
                                    <Button
                                        key="network-edit"
                                        color="blue"
                                        onClick={this.openEditNetworkModal}
                                        size="xs">
                                        <FontAwesomeIcon icon={faEdit} className="magin-right-10" />
                                        <FormattedMessage id="configuration.network.edit" />
                                    </Button>,
                                ]}
                            />
                        </SecuredFragment>
                    }>
                    <Loader show={this.state.isLoading}>
                        <Nav appearance="subtle" justified onSelect={e => this.setState({ tab: e })}>
                            <Nav.Item eventKey="network" active={this.state.tab === 'network'}>
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    className="margin-right-5"
                                    style={{
                                        color: this.state.network.connected ? 'green' : 'red',
                                    }}
                                />
                                Connexion
                            </Nav.Item>
                            <Nav.Item eventKey="vpn" active={this.state.tab === 'vpn'}>
                                <FontAwesomeIcon
                                    icon={this.state.vpn.connected ? faCheck : faTimesCircle}
                                    className="margin-right-5"
                                    style={{
                                        color: this.state.vpn.connected ? 'green' : 'red',
                                    }}
                                />
                                VPN
                            </Nav.Item>
                            <Nav.Item eventKey="supervision" active={this.state.tab === 'supervision'}>
                                <FontAwesomeIcon
                                    icon={this.state.supervision.connected ? faCheck : faTimesCircle}
                                    className="margin-right-5"
                                    style={{
                                        color: this.state.supervision.connected ? 'green' : 'red',
                                    }}
                                />
                                Supervision
                            </Nav.Item>
                        </Nav>

                        <ConnectTab connection={this.state.network} />

                        {/* {this.state.tab === 'network' && <ConnectTab connection={this.state.network} />} */}
                        {/* {this.state.tab === 'vpn' && <VPNTab connection={this.state.vpn} />} */}
                        {/* {this.state.tab === 'supervision' && <SupervisionTab connection={this.state.supervision} />} */}
                    </Loader>
                </Panel>
                <EditNetworkModal
                    show={this.state.editNetworkOpen}
                    closeModal={this.closeEditNetworkModal}
                    network={this.state.network}
                    updateNetwork={this.updateNetwork}
                />
            </Fragment>
        );
    }
}

function ConnectTab(props) {
    return (
        <List hover>
            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id={'configuration.network.online'} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon
                            icon={faCircle}
                            style={{
                                color: props.connection.connected ? 'green' : 'red',
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id={'configuration.network.isDHCP'} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon
                            icon={props.connection.isDHCP ? faCheck : faTimesCircle}
                            style={{
                                color: props.connection.isDHCP ? 'green' : 'red',
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id={'configuration.network.ipAddress'} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {props.connection.ipAddress ? (
                            props.connection.ipAddress
                        ) : (
                            <FontAwesomeIcon icon={faTimesCircle} />
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id={'configuration.network.mask'} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {props.connection.mask ? props.connection.mask : <FontAwesomeIcon icon={faTimesCircle} />}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>

            <List.Item className="panel-list">
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
                        <FormattedMessage id={'configuration.network.gateway'} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {props.connection.gateway ? props.connection.gateway : <FontAwesomeIcon icon={faTimesCircle} />}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
}

// function VPNTab(props) {
//     return (
//         <List hover>
//             <List.Item className="panel-list">
//                 <FlexboxGrid justify="space-between">
//                     <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
//                         <FormattedMessage id={'configuration.network.vpn.online'} />
//                     </FlexboxGrid.Item>
//                     <FlexboxGrid.Item>
//                         <FontAwesomeIcon
//                             icon={faCircle}
//                             style={{
//                                 color: props.connection.connected ? 'green' : 'red',
//                             }}
//                         />
//                     </FlexboxGrid.Item>
//                 </FlexboxGrid>
//             </List.Item>

//             <List.Item className="panel-list">
//                 <FlexboxGrid justify="space-between">
//                     <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
//                         <FormattedMessage id={'configuration.network.vpn.ipAddress'} />
//                     </FlexboxGrid.Item>
//                     <FlexboxGrid.Item>
//                         {props.connection.ipAddress ? (
//                             props.connection.ipAddress
//                         ) : (
//                             <FontAwesomeIcon icon={faTimesCircle} />
//                         )}
//                     </FlexboxGrid.Item>
//                 </FlexboxGrid>
//             </List.Item>
//         </List>
//     );
// }

// function SupervisionTab(props) {
//     return (
//         <List hover>
//             <List.Item className="panel-list">
//                 <FlexboxGrid justify="space-between">
//                     <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
//                         <FormattedMessage id={'configuration.network.supervision.active'} />
//                     </FlexboxGrid.Item>
//                     <FlexboxGrid.Item>
//                         <FontAwesomeIcon
//                             icon={faCircle}
//                             style={{
//                                 color: props.connection.connected ? 'green' : 'red',
//                             }}
//                         />
//                     </FlexboxGrid.Item>
//                 </FlexboxGrid>
//             </List.Item>

//             <List.Item className="panel-list">
//                 <FlexboxGrid justify="space-between">
//                     <FlexboxGrid.Item style={{ fontWeight: 'bold' }}>
//                         <FormattedMessage id={'configuration.network.supervision.keyName'} />
//                     </FlexboxGrid.Item>
//                     <FlexboxGrid.Item>
//                         {props.connection.keyName ? props.connection.keyName : <FontAwesomeIcon icon={faTimesCircle} />}
//                     </FlexboxGrid.Item>
//                 </FlexboxGrid>
//             </List.Item>
//         </List>
//     );
// }

export default injectIntl(NetworkCard);
