import handleError from '../../handlers/error.handler';
import { ipCanHandler } from '../../handlers/ipcan';
import { tcmDisplaysHandler, tcmSensorsHandler } from '../../handlers/ipCanDevices';
import { authHeader } from '../helpers';
import { axiosService } from './axios.services';

export const ipCanDevicesService = {
    getBusDevices,
    updateDisplayConfiguration,
    deleteDisplay,
    updateSensorColors,
    updateSensorTimes,
    updateSensorCalibration,
    deleteSensor,
};

/**
 *
 * @param {Number} ipCanId
 * @param {Number} busNumber
 */
function getBusDevices(ipCanId, busNumber) {
    return axiosService
        .getAxios()
        .get(`/ipcanmodules/devices/${ipCanId}/bus/${busNumber}`, { headers: authHeader() })
        .then(response => {
            let tcmDisplays = response.data.tcmDisplays.map(tcmDisplay => tcmDisplaysHandler(tcmDisplay));
            let tcmSensors = response.data.tcmSensors.map(tcmSensor => tcmSensorsHandler(tcmSensor));

            delete response.data.tcmDisplays;
            delete response.data.tcmSensors;
            let ipCan = ipCanHandler(response.data);

            return { tcmDisplays, tcmSensors, ipCan };
        })
        .then(ipCan => {
            return ipCan;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateDisplayConfiguration(id, arrow, lang, text, type) {
    const data = { id, arrow, lang, text, type };
    const parameters = { headers: authHeader() };

    return axiosService
        .getAxios()
        .put('/devices/tcm-display/updateConfig', data, parameters)
        .then(response => {
            return tcmDisplaysHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

/**
 *
 * @param {Number[]} ids
 */
function deleteDisplay(ids) {
    const data = {
        headers: authHeader(),
        data: { tabId: ids },
    };
    return axiosService
        .getAxios()
        .delete('/devices/tcm-display', data)
        .then(response => {
            let tcmDisplays = response.data.map(tcmDisplay => tcmDisplaysHandler(tcmDisplay));

            return tcmDisplays;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateSensorColors(id, forced, free, occupied, overstay, overstayFree) {
    const data = { id, forced, free, occupied, overstay, overstayFree };
    const parameters = { headers: authHeader() };

    return axiosService
        .getAxios()
        .put('/devices/tcm-sensor/updateColorConfig', data, parameters)
        .then(response => {
            return tcmSensorsHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateSensorTimes(id, maxFree, maxOccupied, provOccupied) {
    const data = {
        id,
        maxFree: parseInt(maxFree),
        maxOccupied: parseInt(maxOccupied),
        provOccupied: parseInt(provOccupied),
    };
    const parameters = { headers: authHeader() };

    return axiosService
        .getAxios()
        .put('/devices/tcm-sensor/updateTimeConfig', data, parameters)
        .then(response => {
            return tcmSensorsHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateSensorCalibration(id, state, value) {
    const data = { id, state, value: parseInt(value) };
    const parameters = { headers: authHeader() };

    return axiosService
        .getAxios()
        .put('/devices/tcm-sensor/updateCalibrationConfig', data, parameters)
        .then(response => {
            return tcmSensorsHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

/**
 *
 * @param {Number[]} ids
 */
function deleteSensor(ids) {
    const data = {
        headers: authHeader(),
        data: { tabId: ids },
    };
    return axiosService
        .getAxios()
        .delete('/devices/tcm-sensor', data)
        .then(response => {
            let tcmSensors = response.data.map(tcmSensor => tcmSensorsHandler(tcmSensor));

            return tcmSensors;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}
