import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Col,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    Message,
    Modal,
} from 'rsuite';
import { MapLPSensor } from '../../../../handlers/map/MapLPSensor';
import { MapTCMSensor } from '../../../../handlers/map/MapTCMSensor';

type Props = {
    sensors: Array<Record<string, any>>;
    show: boolean;
    onHide: Function;
    selectedSensors: { sensor: MapLPSensor | MapTCMSensor; type: string }[];
    formValue: Record<string, any>;
    loading: boolean;
    handleChange: Function;
    handleSubmit: Function;
    context: any;
} & WrappedComponentProps;
class EditPlacesIdModal extends React.Component<Props> {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(formValue) {
        this.props.handleChange({
            offsetId: parseInt(formValue.offsetId),
            offsetLabel: parseInt(formValue.offsetLabel),
        });
    }

    handleSubmit() {
        this.props.handleSubmit();
    }

    render() {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={() => this.props.onHide()} size="md">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="map.places.editPlacesId.title" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.props.context.updatePlacesIdError && (
                        <Message
                            type="error"
                            description={this.props.intl.formatMessage({ id: 'map.places.editPlacesId.error' })}
                        />
                    )}
                    <p className="margin-bottom-10">
                        <span className="margin-right-5">{this.props.selectedSensors.length}</span>
                        <FormattedMessage id="map.places.editPlacesId.nbSelected" />
                    </p>

                    <FlexboxGrid>
                        {this.props.selectedSensors.map((selectedSensor, index) => (
                            <FlexboxGrid.Item
                                key={selectedSensor.sensor.id}
                                componentClass={Col}
                                className="text-center"
                                xs={8}
                                style={{ backgroundColor: index % 2 === 0 ? '#EEEEEE' : '#FFFFFF' }}>
                                {selectedSensor.sensor.label}
                                <span className="italic small-text">
                                    ({selectedSensor.sensor.sensor.ipCanId}-{selectedSensor.sensor.sensor.bus + 1}-
                                    {selectedSensor.sensor.sensor.deviceId})
                                </span>
                            </FlexboxGrid.Item>
                        ))}
                    </FlexboxGrid>

                    <Form className="margin-top-15" fluid formValue={this.props.formValue} onChange={this.handleChange}>
                        <FlexboxGrid align="middle" justify="end">
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormGroup>
                                    <ControlLabel>
                                        <FormattedMessage id="map.places.editPlacesId.startId" />
                                    </ControlLabel>
                                    <FormControl name="offsetId" type="number" />
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormGroup>
                                    <ControlLabel>
                                        <FormattedMessage id="map.places.editPlacesId.incrementName" />
                                    </ControlLabel>
                                    <FormControl name="offsetLabel" type="number" />
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="margin-top-15">
                                <FormGroup>
                                    <ButtonToolbar>
                                        <ButtonGroup>
                                            <Button
                                                data-cy="map-places-editId-cancel"
                                                color="red"
                                                disabled={this.props.context.updatingPlacesId}
                                                onClick={() => this.props.onHide()}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                            <Button
                                                data-cy="map-places-editId-valid"
                                                appearance="primary"
                                                loading={this.props.context.updatingPlacesId}
                                                onClick={this.handleSubmit}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                        </ButtonGroup>
                                    </ButtonToolbar>
                                </FormGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default injectIntl(EditPlacesIdModal);
