import { faBookReader, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid, Tooltip, Whisper } from 'rsuite';

export default function readSceneConfig() {
    return (
        <div data-cy="history-readSceneConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.readSceneConfig" />
                    </Tooltip>
                }>
                <FlexboxGrid>
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon icon={faBookReader} style={{ color: '#2184da' }} size="lg" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <FontAwesomeIcon icon={faTools} style={{ color: '#2184da' }} size="lg" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Whisper>
        </div>
    );
}
