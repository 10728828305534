import History from './History';

export default class lpMatrixDisplayV2Historic extends History {
    _idLPMatrixDisplayV2: number;
    _LPMatrixDisplayV2Name: string;

    constructor(historyObject) {
        super(historyObject);
        const { idLpMatrixDisplayV2, lpMatrixDisplayV2Name } = historyObject;

        this._idLPMatrixDisplayV2 = idLpMatrixDisplayV2;
        this._LPMatrixDisplayV2Name = lpMatrixDisplayV2Name;
    }

    public get idLPMatrixDisplayV2() {
        return this._idLPMatrixDisplayV2;
    }

    public get LPMatrixDisplayV2Name() {
        return this._LPMatrixDisplayV2Name;
    }

    getOption(): any {
        return this.option;
    }

    getHistory(): History_I {
        return {
            id: this.getId(),
            user: this.getUser(),
            createdAt: this.getCreatedAt(),
            createdBy: this.getCreatedBy(),
            desc: this.getDesc(),
            option: this.getOption(),
            idLPMatrixDisplayV2: this.idLPMatrixDisplayV2,
            LPMatrixDisplayV2Name: this.LPMatrixDisplayV2Name,
            disabled: this.shouldBeDisabled(),
        };
    }

    private shouldBeDisabled(): boolean {
        let result = true;
        if (this.getDesc() === 'update key text config') result = false;

        return result;
    }
}

interface History_I {
    id: number;
    user: string;
    createdAt: string;
    createdBy: string;
    desc: string;
    option: Record<string, any>;
    idLPMatrixDisplayV2: number;
    LPMatrixDisplayV2Name: string;
    disabled: boolean;
}
