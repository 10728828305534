import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    ControlLabel,
    DatePicker,
    Form,
    FormControl,
    FormGroup,
    Modal,
    Toggle,
} from 'rsuite';
import { RestFull } from '../../../../../../../handlers/profile/RestFull';
import { authHeader } from '../../../../../../../redux/helpers';
import { axiosService } from '../../../../../../../redux/services';

type Props = {
    open: boolean;
    onHide: (shouldReload) => void;
    restFull: RestFull;
};

type FormValue = {
    expirationDate: Date;
    label: string;
    path: string;
    isEnable: boolean;
};

export const EditRestFullModal = (props: Props) => {
    const intl = useIntl();

    const [formValue, setFormValue] = React.useState<FormValue>({
        expirationDate: props.restFull.getExpirationDate(),
        label: props.restFull.getLabel(),
        path: props.restFull.getPath(),
        isEnable: props.restFull.getIsEnable(),
    });

    const [isEditing, setIsEditing] = React.useState<boolean>(false);

    useEffect(() => {
        if (props.open) {
            setFormValue({
                expirationDate: props.restFull.getExpirationDate(),
                label: props.restFull.getLabel(),
                path: props.restFull.getPath(),
                isEnable: props.restFull.getIsEnable(),
            });
        }
    }, [props.open]);

    const handleFormChange = (value: FormValue) => {
        setFormValue(value);
    };

    const handleValid = () => {
        setIsEditing(true);

        axiosService
            .getAxios()
            .put(
                '/rest-full',
                {
                    id: props.restFull.getId(),
                    expirationDate: formValue.expirationDate,
                    label: formValue.label,
                    path: formValue.path,
                    isEnable: formValue.isEnable,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'user.restFull.create.success' }), 5000);
                props.onHide(true);
            })
            .catch(err => {
                console.error(err);
                Alert.error(intl.formatMessage({ id: 'user.restFull.create.error' }), 5000);
            })
            .finally(() => {
                setIsEditing(false);
            });
    };

    const handleCancel = () => {
        if (!isEditing) props.onHide(false);
    };

    return (
        <Modal backdrop="static" show={props.open} onHide={handleCancel}>
            <Modal.Header>
                <Modal.Title>{intl.formatMessage({ id: 'user.restFull.create.title' })}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form formValue={formValue} onChange={formValue => handleFormChange(formValue as FormValue)} fluid>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.restFull.create.label" />
                        </ControlLabel>
                        <FormControl name="label" data-cy="user-restFull-create-label" />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.restFull.create.path" />
                        </ControlLabel>
                        <FormControl name="path" data-cy="user-restFull-create-path" />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.restFull.create.expirationDate" />
                        </ControlLabel>
                        <FormControl
                            accepter={DatePicker}
                            format="DD/MM/YYYY HH:mm"
                            name="expirationDate"
                            onChangeCalendarDate={value => setFormValue({ ...formValue, expirationDate: value })}
                            data-cy="user-restFull-create-expirationDate"
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.restFull.create.isEnable" />
                        </ControlLabel>
                        <FormControl
                            accepter={Toggle}
                            name="isEnable"
                            defaultChecked={formValue.isEnable}
                            data-cy="user-restFull-create-isEnable"
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={handleCancel}
                        data-cy="user-restFull-create-cancel"
                        color="red"
                        disabled={isEditing}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={handleValid}
                        data-cy="user-restFull-create-valid"
                        appearance="primary"
                        loading={isEditing}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
