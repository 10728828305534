import { FlowElement } from 'react-flow-renderer';
import { LPMatrixDisplayFlowElement } from '../LPMatrixDisplayChartElement';

export default class LPMatrixDisplayChartTopologyVirtualDisplayEdge extends LPMatrixDisplayFlowElement {
    private _index: number;
    private _color: string;
    private _source: string;
    private _sourceHandle?: string;
    private _target: string;
    private _targetHandle?: string;

    constructor(data: LPMatrixDisplayChartTopologyVirtualDisplayEdgeInterface) {
        super(data.id, data.label);
        this._index = data.index;
        this._color = data.color;
        this._source = data.source;
        this._sourceHandle = data.sourceHandle;
        this._target = data.target;
        this._targetHandle = data.targetHandle;
    }

    public render(): FlowElement<LPMatrixDisplayChartTopologyVirtualDisplayEdgeType> {
        return {
            id: `${this.id}-force`,
            type: 'forceVirtualDisplayTopologyVirtualDisplayNode',
            data: {
                color: this._color,
            },
            source: this._source,
            sourceHandle: this._sourceHandle,
            target: this._target,
            targetHandle: this._targetHandle,
        };
    }
}

export interface LPMatrixDisplayChartTopologyVirtualDisplayEdgeInterface {
    id: string;
    index: number;
    color: string;
    label: string;
    source: string;
    sourceHandle?: string;
    target: string;
    targetHandle?: string;
}

export type LPMatrixDisplayChartTopologyVirtualDisplayEdgeType = {
    color: string;
};
