type Props = {
    color: string;
};

export const VipIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <g>
                <rect fill={color} y="64" width="32" height="192" />
                <rect fill={color} x="160" y="64" width="32" height="192" />
                <rect fill={color} x="32" y="160" width="32" height="192" />
                <rect fill={color} x="128" y="160" width="32" height="192" />
            </g>
            <rect fill={color} x="64" y="288" width="64" height="160" />
            <rect fill={color} x="128" y="160" width="64" height="96" />
            <rect fill={color} y="160" width="64" height="96" />
            <rect fill={color} x="32" y="288" width="128" height="64" />
            <rect fill={color} x="224" y="64" width="64" height="384" />
            <rect fill={color} x="320" y="64" width="64" height="384" />
            <rect fill={color} x="320" y="64" width="160" height="64" />
            <rect fill={color} x="320" y="256" width="160" height="64" />
            <rect fill={color} x="448" y="96" width="64" height="192" />
        </g>
    </svg>
);
