import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal } from 'rsuite';
import { Profile } from '../../../../../handlers/profile/Profile';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    profile?: Profile;
    open: boolean;
    onClose: (shouldReload: boolean) => void;
};

type FormValues = {
    name: string;
    description: string;
};

export const CopyProfileModal = (props: Props) => {
    const intl = useIntl();

    const [formValues, setFormValues] = React.useState<FormValues>({
        name: '',
        description: '',
    });

    const [isCopying, setIsCopying] = React.useState<boolean>(false);

    const handleFormChange = (value: FormValues) => {
        setFormValues(value);
    };

    const handleCloseModal = () => {
        props.onClose(false);
    };

    const handleValid = () => {
        setIsCopying(true);

        axiosService
            .getAxios()
            .post(
                '/profile/duplicate',
                {
                    id: props.profile?.getId(),
                    name: formValues.name,
                    description: formValues.description,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'profiles.copy.success' }));

                props.onClose(true);
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'profiles.copy.success' }));
            })
            .finally(() => {
                setIsCopying(false);
            });
    };

    if (!props.profile) return null;

    return (
        <Modal show={props.open} onHide={handleCloseModal} backdrop="static" size="xs">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="profiles.copy.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="profiles.copy.description" /> {props.profile.getName()}
                <hr />
                <Form formValue={formValues} onChange={value => handleFormChange(value as FormValues)} fluid>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="profiles.copy.name" />
                        </ControlLabel>
                        <FormControl type="text" name="name" required />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="profiles.copy.description" />
                        </ControlLabel>
                        <FormControl type="text" name="description" required />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={handleCloseModal} color="red" disabled={isCopying}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button onClick={handleValid} color="green" loading={isCopying}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
