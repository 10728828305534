import {
    faCalendarAlt,
    faCarSide,
    faHistory,
    faHome,
    faParking,
    faServer,
    faTachometerAlt,
    faUsers,
    faVideo,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { Nav, Sidebar, Sidenav } from 'rsuite';
import { rolesConstants } from '../../static/roles';
import logoLeaderPark from '../../style/assets/Logo-Leader-Park.png';
import SecuredFragment from '../Auth/SecuredFragment';
import NavbarToggleMobile from './navbarToggleMobile';

type Props = {
    expanded: boolean;
    handleToggle: Function;
    shouldDisplayCountersPart: boolean;
} & RouteComponentProps;

type State = {};

class SidenavbarMobile extends React.Component<Props, State> {
    render() {
        return (
            <Sidebar
                className="hide-on-desktop"
                style={{
                    flexDirection: 'column',
                    flexFlow: 'column nowrap',
                    position: 'fixed',
                    height: window.innerHeight - 168,
                    marginTop: '56px',
                    zIndex: 100,
                }}
                width={this.props.expanded ? '100%' : '0%'}
                collapsible>
                <Sidenav.Header>
                    <div
                        style={{
                            padding: 18,
                            fontSize: 16,
                            height: 56,
                            background: '#fff',
                            color: ' #fff',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            display: this.props.expanded ? 'block' : 'none',
                        }}>
                        <img src={logoLeaderPark} alt="Logo leaderpark" style={{ height: 30 }} />
                    </div>
                </Sidenav.Header>
                <Sidenav
                    style={{ height: '100%' }}
                    className="mobile"
                    expanded={this.props.expanded}
                    defaultOpenKeys={['3']}>
                    <Sidenav.Body>
                        <Nav>
                            <Nav.Item
                                active={this.props.location.pathname === '/'}
                                onSelect={() => this.props.handleToggle()}
                                componentClass={NavLink}
                                to="/"
                                icon={<FontAwesomeIcon icon={faHome} />}
                                data-cy="sidenav-dashboard">
                                <FormattedMessage id="sidenav.dashboard" />
                            </Nav.Item>
                            {this.props.shouldDisplayCountersPart && (
                                <SecuredFragment authorizedRoles={[rolesConstants.counters.VIEW_LIST]}>
                                    <Nav.Item
                                        active={this.props.location.pathname.startsWith('/counters')}
                                        componentClass={NavLink}
                                        to="/counters"
                                        icon={<FontAwesomeIcon icon={faTachometerAlt} />}
                                        data-cy="sidenav-counters">
                                        <FormattedMessage id="sidenav.counters" />
                                    </Nav.Item>
                                </SecuredFragment>
                            )}
                            <SecuredFragment authorizedRoles={[rolesConstants.parking.VIEW_LIST]}>
                                <Nav.Item
                                    active={this.props.location.pathname.startsWith('/parkings')}
                                    onSelect={() => this.props.handleToggle()}
                                    componentClass={NavLink}
                                    to="/parkings"
                                    icon={<FontAwesomeIcon icon={faParking} />}
                                    data-cy="sidenav-parkings">
                                    <FormattedMessage id="sidenav.parking" />
                                </Nav.Item>
                            </SecuredFragment>
                            <SecuredFragment authorizedRoles={[rolesConstants.controller.VIEW_LIST]}>
                                <Nav.Item
                                    active={this.props.location.pathname.startsWith('/ipCan')}
                                    onSelect={() => this.props.handleToggle()}
                                    componentClass={NavLink}
                                    to="/ipCan"
                                    icon={<FontAwesomeIcon icon={faServer} />}
                                    data-cy="sidenav-ipcan">
                                    <FormattedMessage id="sidenav.controllers" />
                                </Nav.Item>
                            </SecuredFragment>
                            <SecuredFragment authorizedRoles={[rolesConstants.ipCameras.VIEW_LIST]}>
                                <Nav.Item
                                    active={this.props.location.pathname.startsWith('/cameras')}
                                    componentClass={NavLink}
                                    to="/cameras"
                                    icon={<FontAwesomeIcon icon={faVideo} />}
                                    data-cy="sidenav-cameras">
                                    <FormattedMessage id="sidenav.cameras" />
                                </Nav.Item>
                            </SecuredFragment>
                            <SecuredFragment authorizedRoles={[rolesConstants.eventAction.VIEW_LIST]}>
                                <Nav.Item
                                    active={this.props.location.pathname.startsWith('/events')}
                                    componentClass={NavLink}
                                    to="/events"
                                    icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                                    data-cy="sidenav-events">
                                    <FormattedMessage id="sidenav.events" />
                                </Nav.Item>
                            </SecuredFragment>
                            <SecuredFragment authorizedRoles={[rolesConstants.placeType.VIEW_LIST]}>
                                <Nav.Item
                                    active={this.props.location.pathname.startsWith('/places')}
                                    componentClass={NavLink}
                                    to="/places"
                                    icon={<FontAwesomeIcon icon={faCarSide} />}
                                    data-cy="sidenav-placeTypes">
                                    <FormattedMessage id="sidenav.placetype" />
                                </Nav.Item>
                            </SecuredFragment>
                            <SecuredFragment authorizedRoles={[rolesConstants.historics.VIEW_LIST]}>
                                <Nav.Item
                                    active={this.props.location.pathname.startsWith('/history')}
                                    componentClass={NavLink}
                                    to="/history"
                                    icon={<FontAwesomeIcon icon={faHistory} />}
                                    data-cy="sidenav-history">
                                    <FormattedMessage id="sidenav.history" />
                                </Nav.Item>
                            </SecuredFragment>
                            <SecuredFragment authorizedRoles={[rolesConstants.user.VIEW_LIST]}>
                                <Nav.Item
                                    active={this.props.location.pathname.startsWith('/users')}
                                    onSelect={() => this.props.handleToggle()}
                                    componentClass={NavLink}
                                    to="/users"
                                    icon={<FontAwesomeIcon icon={faUsers} />}
                                    data-cy="sidenav-users">
                                    <FormattedMessage id="sidenav.users" />
                                </Nav.Item>
                            </SecuredFragment>
                            <SecuredFragment authorizedRoles={[rolesConstants.configuration.VIEW_LIST]}>
                                <Nav.Item
                                    active={this.props.location.pathname.startsWith('/configuration')}
                                    onSelect={() => this.props.handleToggle()}
                                    componentClass={NavLink}
                                    to="/configuration"
                                    icon={<FontAwesomeIcon icon={faWrench} />}
                                    data-cy="sidenav-configuration">
                                    <FormattedMessage id="sidenav.configuration" />
                                </Nav.Item>
                            </SecuredFragment>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
                <NavbarToggleMobile expanded={this.props.expanded} />
            </Sidebar>
        );
    }
}

export default withRouter(SidenavbarMobile);
