import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MaintenanceState } from '../../../../../../handlers/MaintenanceInformation';

type Props = {
    maintenanceState: MaintenanceState;
};

export const MapTCMDeviceStateInformationLogo = (props: Props) => {
    return (
        // <Whisper
        //     trigger={'hover'}
        //     placement="right"
        //     speaker={
        //         <Tooltip>
        //             {device.getNotepad() === '' ? (
        //                 <FontAwesomeIcon icon={faCommentSlash} />
        //             ) : (
        //                 <p>{device.getNotepad()}</p>
        //             )}
        //         </Tooltip>
        //     }
        //     data-tag="allowRowEvents">
        <span>
            {props.maintenanceState === MaintenanceState.EVERYTHING_OK && (
                <FontAwesomeIcon icon={faExclamationCircle} color="green" />
            )}
            {props.maintenanceState === MaintenanceState.LOW && (
                <FontAwesomeIcon icon={faExclamationCircle} color="#FFDA00" />
            )}
            {props.maintenanceState === MaintenanceState.MEDIUM && (
                <FontAwesomeIcon icon={faExclamationCircle} color="orange" />
            )}
            {props.maintenanceState === MaintenanceState.HIGH && (
                <FontAwesomeIcon icon={faExclamationCircle} color="#FF5349" />
            )}
            {props.maintenanceState === MaintenanceState.VERY_HIGH && (
                <FontAwesomeIcon icon={faExclamationCircle} color="red" />
            )}
            {/* <span className="margin-left-5">({device.getMaintenanceState()})</span> */}
        </span>
        // </Whisper>
    );
};
