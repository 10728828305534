import { faCheck, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord } from 'react-data-table-component';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Col, FlexboxGrid, List } from 'rsuite';
import { tabColor } from '../../../../handlers/ipCanDevices/const/colors';

type SensorStates = {
    label: string;
    steps: {
        led1: boolean;
        led2: boolean;
        led3: boolean;
        led4: boolean;
        led5: boolean;
        led6: boolean;
        color: string;
        time: number;
    }[];
};

type Props = RowRecord & WrappedComponentProps;

type State = {
    sensorData: SensorStates[];
};

class LPSensorSendScenes extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sensorData: [],
        };
    }

    componentDidMount = () => {
        let sensorData: SensorStates[] = [
            {
                label: this.props.intl.formatMessage({
                    id: 'sensorPlaceTypeScene.free',
                }),
                steps: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'sensorPlaceTypeScene.occupied',
                }),
                steps: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'sensorPlaceTypeScene.overstay',
                }),
                steps: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'sensorPlaceTypeScene.reserved',
                }),
                steps: [],
            },
        ];

        for (let i = 1; i < this.props.data.option.length; i++) {
            let currentScene = this.props.data.option[i];

            for (let j = 0; j < currentScene.step.length; j++) {
                let sceneConfigObject: any = {
                    led1: false,
                    led2: false,
                    led3: false,
                    led4: false,
                    led5: false,
                    led6: false,
                    color: '#FFFFFF',
                    time: 0,
                };

                const currentStep = currentScene.step[j];
                let index = 1;
                for (let l = 1; l < 64; l <<= 1) {
                    sceneConfigObject[`led${index}`] = (currentStep.ledMask & l) !== 0;
                    index++;
                }
                sceneConfigObject.color = tabColor[currentStep.colorIdx];
                sceneConfigObject.time = currentStep.time;
                sensorData[currentScene.sceneIdx - 1].steps.push(sceneConfigObject);
            }
        }

        this.setState({
            sensorData,
        });
    };

    render = () => (
        <FlexboxGrid justify="center">
            <FlexboxGrid.Item componentClass={Col} xs={12}>
                <List bordered hover>
                    {this.state.sensorData.map((scene: SensorStates) => {
                        return (
                            <Fragment>
                                <List.Item className="separator-filter">
                                    <FlexboxGrid justify="center" align="middle">
                                        <FlexboxGrid.Item>{scene.label}</FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>

                                {generateListLine(scene)}
                            </Fragment>
                        );
                    })}
                </List>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
}

function generateListLine(scene: SensorStates) {
    let listLine: any[] = [];

    for (let s in scene.steps) {
        let ledActive: any[] = [];

        const step = scene.steps[s];

        for (let i = 1; i <= 6; i++) {
            ledActive.push(
                <FlexboxGrid.Item data-cy="history-lpSensor-state">
                    <FontAwesomeIcon
                        icon={step[`led${i}`] ? faCheck : faTimes}
                        color={step[`led${i}`] ? 'green' : 'red'}
                        size="lg"
                    />
                </FlexboxGrid.Item>
            );
        }

        ledActive.push(
            <Fragment>
                <FlexboxGrid.Item>
                    <FontAwesomeIcon icon={faSquare} color={JSON.parse(JSON.stringify(step.color))} size="lg" />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item>{step.time} s</FlexboxGrid.Item>
            </Fragment>
        );

        listLine.push(ledActive);
    }

    return (
        <Fragment>
            {listLine.map(led => (
                <List.Item>
                    <FlexboxGrid justify="space-between" align="middle">
                        {led}
                    </FlexboxGrid>
                </List.Item>
            ))}
        </Fragment>
    );
}

export default injectIntl(LPSensorSendScenes);
