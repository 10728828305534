export class Scene {
    private steps: Array<StepsInterface>;

    constructor(steps_) {
        this.steps = steps_;
    }

    getStep(blinkState: number): StepsInterface {
        if (this.steps.length > 1) {
            const totalTime = this.steps.reduce((acc, cur) => acc + cur.time, 0);

            const currentTime = blinkState % totalTime;

            let currentStepTime = 0;
            for (let i = 0; i < this.steps.length; i++) {
                currentStepTime += this.steps[i].time;

                if (currentTime < currentStepTime) {
                    return this.steps[i];
                }
            }
        }

        return this.steps[0];
    }

    getLength(): number {
        return this.steps.length;
    }
}

interface StepsInterface {
    color: string;
    createdAt: Date;
    createdBy: string;
    id: number;
    led1: boolean;
    led2: boolean;
    led3: boolean;
    led4: boolean;
    led5: boolean;
    led6: boolean;
    order: number;
    state: number;
    time: number;
    updatedAt: Date;
    updatedBy: string;
}
