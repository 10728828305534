export default class TCMBusConfig {
    name: string;
    speed: number;
    pollingTime: number;
    isPolling: boolean;
    isActiv: boolean;
    isLoadConf: boolean;
    crcIsActiv: boolean;
    constructor(configuration, index: number) {
        this.name = `Bus ${index + 1}`;
        this.speed = configuration.speed;
        this.pollingTime = configuration.pollingTime;
        this.isPolling = configuration.isPolling;
        this.isActiv = configuration.isActiv;
        this.isLoadConf = configuration.isLoadConf;
        this.crcIsActiv = configuration.crcIsActiv;
    }

    getBusConfig() {
        return {
            name: this.name,
            speed: this.speed,
            pollingTime: this.pollingTime,
            isPolling: this.isPolling,
            isActiv: this.isActiv,
            isLoadConf: this.isLoadConf,
            crcIsActiv: this.crcIsActiv,
        };
    }

    updateBusConfig(bus) {
        this.name = bus.name;
        this.speed = bus.speed;
        this.pollingTime = bus.pollingTime;
        this.isPolling = bus.isPolling;
        this.isActiv = bus.isActiv;
        this.isLoadConf = bus.isLoadConf;
        this.crcIsActiv = bus.crcIsActiv;
    }
}
