import { FormattedMessage } from 'react-intl';
import { Divider, Tag, TagGroup } from 'rsuite';
import Counter from '../../../../../../handlers/Counter/Counter';

type Props = {
    counters: Counter[];
};

export const CountersPart = (props: Props) => {
    return (
        <>
            <Divider style={{ color: 'purple' }}>
                <FormattedMessage id="event.task.counters" />
            </Divider>
            <TagGroup>
                {props.counters.map(counter => {
                    return (
                        <Tag data-cy="task-level" color="blue" key={counter.id}>
                            {counter.label}
                        </Tag>
                    );
                })}
            </TagGroup>
        </>
    );
};
