import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Button, Drawer } from 'rsuite';
import { User } from '../../../../../handlers/profile/User';
import { DeleteUserModal } from '../Modal/DeleteUserModal';
import { UserRestFull } from './RestFull/UserRestFull';
import { UserInformations } from './UserInformations';
import { UserParkings } from './UserParkings';
import UserPassword from './UserPassword';
import { UserRoles } from './UserRoles';

type Props = {
    open: boolean;
    onHide: () => void;
    user?: User;
    reloadUsers: () => void;
    reloadProfiles: () => void;
};

export const UserDrawer = (props: Props) => {
    if (!props.user) return null;

    const [deleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

    const handleCloseDeleteUserModal = (shouldReload: boolean) => {
        setIsDeleteModalOpen(false);
        if (shouldReload) {
            props.reloadUsers();
            props.reloadProfiles();
        }
    };

    return (
        <>
            <DeleteUserModal onHide={handleCloseDeleteUserModal} open={deleteModalOpen} user={props.user} />
            <Drawer backdrop={true} show={props.open} onHide={props.onHide}>
                <Drawer.Header data-cy="userdrawer">
                    <Drawer.Title className="with-avatar" data-cy="userdrawer-title">
                        <Avatar>
                            {props.user.getFirstName().charAt(0).toUpperCase()}
                            {props.user.getLastName().charAt(0).toUpperCase()}
                        </Avatar>
                        {props.user.getFirstName()} {props.user.getLastName()}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className="drawer-body-with-avatar">
                    <UserInformations user={props.user} reloadUsers={props.reloadUsers} />
                    <UserPassword user={props.user} />
                    <UserParkings user={props.user} reloadUsers={props.reloadUsers} />
                    <UserRoles
                        user={props.user}
                        reloadUsers={props.reloadUsers}
                        reloadProfiles={props.reloadProfiles}
                    />
                    <UserRestFull user={props.user} reloadUsers={props.reloadUsers} />
                </Drawer.Body>
                <Drawer.Footer>
                    <Button
                        onClick={() => setIsDeleteModalOpen(true)}
                        data-cy="delete-user-button"
                        color="red"
                        disabled={!props.user}>
                        <FormattedMessage id="user.deleteButton" />
                    </Button>
                </Drawer.Footer>
            </Drawer>
        </>
    );
};
