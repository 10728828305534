import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';

export default function detectionStateCell(detectionState, detectionStateTime) {
    const intl = useIntl();

    let color: string;
    let text: string;

    switch (detectionState) {
        case 1:
            color = 'green';
            text = intl.formatMessage({ id: 'sensor.detectionState.free' });
            break;
        case 2:
            color = 'red';
            text = intl.formatMessage({ id: 'sensor.detectionState.occupied' });
            break;
        case 3:
            color = 'orange';
            text = intl.formatMessage({ id: 'sensor.detectionState.overstayOccupied' });
            break;
        case 4:
            color = 'purple';
            text = intl.formatMessage({ id: 'sensor.detectionState.overstayFree' });
            break;
        default:
            color = 'black';
            text = intl.formatMessage({ id: 'sensor.detectionState.none' });
    }

    return (
        <div data-cy="sensor-detectionState" data-tag="allowRowEvents" className="text-center">
            <FontAwesomeIcon icon={faCircle} style={{ color: color, marginRight: 6 }} />
            <small>{text}</small>
            <br />
            <small>({detectionStateTime})</small>
        </div>
    );
}
