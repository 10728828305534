import { faArrowLeft, faCheck, faPlus, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    ControlLabel,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    InputNumber,
    InputPicker,
    Schema,
    SelectPicker,
    Table,
} from 'rsuite';
import {
    AutoResetTrigger,
    EventConditionType,
    EventCounterConditionTriggerType,
} from '../../../../handlers/EventAction/EventAction';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { EventActionFormDataType } from '../CreateEventModal';

type Props = {
    formData: EventActionFormDataType;
    setFormData: (data: EventActionFormDataType) => void;
    handleCancel: () => void;
    handleGoBack: () => void;
};

const { DateType } = Schema.Types;

export const StepThreeCounter = (props: Props) => {
    const intl = useIntl();

    const formRef = useRef<any>(null);

    const [isCreating, setIsCreating] = useState(false);
    const [counters, setCounters] = useState<{ label: string; value: number }[]>([]);

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/counters', {
                headers: authHeader(),
            })
            .then(countersResponse => {
                setCounters(
                    countersResponse.data.map(counter => {
                        return {
                            label: counter.label,
                            value: counter.id,
                        };
                    })
                );
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'error.fetchCountersError' }));
            });
    }, []);

    const model = useMemo(() => {
        return Schema.Model({
            autoResetTriggerTime: DateType().addRule((value, data) => {
                if (data.autoResetTrigger === AutoResetTrigger.TIME && !value) {
                    return false;
                }

                return true;
            }),
        });
    }, [intl]);

    const triggerCondition = useMemo(() => {
        return [
            {
                label: EventCounterConditionTriggerType.LOWER,
                value: EventCounterConditionTriggerType.LOWER,
            },
            {
                label: EventCounterConditionTriggerType.EQUALITY,
                value: EventCounterConditionTriggerType.EQUALITY,
            },
            {
                label: EventCounterConditionTriggerType.SUPERIOR,
                value: EventCounterConditionTriggerType.SUPERIOR,
            },
        ];
    }, []);

    const autoResetTriggerData = useMemo(() => {
        return [
            {
                label: intl.formatMessage({ id: 'autoResetTrigger.none' }),
                value: AutoResetTrigger.NONE,
            },
            {
                label: intl.formatMessage({ id: 'autoResetTrigger.auto' }),
                value: AutoResetTrigger.AUTO,
            },
            {
                label: intl.formatMessage({ id: 'autoResetTrigger.time' }),
                value: AutoResetTrigger.TIME,
            },
        ];
    }, [intl]);

    const handleAddCounter = () => {
        props.setFormData({
            ...props.formData,
            counterConditions: [
                ...props.formData.counterConditions,
                {
                    counterId: 0,
                    triggerType: EventCounterConditionTriggerType.SUPERIOR,
                    triggerValue: 0,
                },
            ],
        });
    };

    const handleRemoveCounter = (index: number) => {
        const newCounterConditions = [...props.formData.counterConditions];
        newCounterConditions.splice(index, 1);

        props.setFormData({
            ...props.formData,
            counterConditions: newCounterConditions,
        });
    };

    const handleCounterChange = (value: number, index: number) => {
        const newCounterConditions = [...props.formData.counterConditions];
        newCounterConditions[index].counterId = value;

        props.setFormData({
            ...props.formData,
            counterConditions: newCounterConditions,
        });
    };

    const handleConditionChange = (value: EventCounterConditionTriggerType, index: number) => {
        const newCounterConditions = [...props.formData.counterConditions];
        newCounterConditions[index].triggerType = value;

        props.setFormData({
            ...props.formData,
            counterConditions: newCounterConditions,
        });
    };

    const handleValueChange = (value: string, index: number) => {
        const intValue = parseInt(value);

        const newCounterConditions = [...props.formData.counterConditions];
        newCounterConditions[index].triggerValue = isNaN(intValue) ? 0 : intValue;
        props.setFormData({
            ...props.formData,
            counterConditions: newCounterConditions,
        });
    };

    const handleSubmit = () => {
        setIsCreating(true);

        let hasError = props.formData.counterConditions.length === 0;

        props.formData.counterConditions.forEach(counterCondition => {
            if (
                counterCondition.counterId === 0 ||
                counterCondition.triggerValue < 0 ||
                !counterCondition.triggerType ||
                !counterCondition.counterId
            ) {
                hasError = true;
            }
        });

        if (hasError) {
            Alert.error(intl.formatMessage({ id: 'error.invalidCounterConditions' }));
            setIsCreating(false);
            return;
        }

        const dailyValues = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        };

        props.formData.daily.forEach((day: string) => {
            dailyValues[day] = true;
        });

        const formattedStartTime = moment(props.formData.startTime);
        const formattedAutoResetTriggerTime = moment(props.formData.autoResetTriggerTime);

        axiosService
            .getAxios()
            .post(
                '/event-actions/event',
                {
                    name: props.formData.name,
                    desc: props.formData.desc,
                    isInfinite: props.formData.isInfinite,
                    daily: dailyValues,
                    startDate: props.formData.startDate,
                    endDate: props.formData.endDate,
                    eventCondition: {
                        eventConditionType: EventConditionType.COUNTERS,
                        startTime: formattedStartTime.get('hours') * 60 + formattedStartTime.get('minutes'),
                        countersId: props.formData.counterConditions,
                        autoResetTrigger: props.formData.autoResetTrigger,
                        autoResetTriggerTime:
                            formattedAutoResetTriggerTime.get('hours') * 60 +
                            formattedAutoResetTriggerTime.get('minutes'),
                    },
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                setIsCreating(false);
                props.handleCancel();
            })
            .catch(err => {
                setIsCreating(false);

                Alert.error(intl.formatMessage({ id: 'event.createTask.error' }));

                console.error(err);
            });

        return;
    };

    const handleFormChange = (value: any) => {
        props.setFormData({ ...props.formData, ...value });
    };

    return (
        <>
            <Form
                fluid
                formValue={props.formData}
                onChange={handleFormChange}
                ref={formRef}
                model={model}
                style={{ marginBottom: 16 }}>
                <FlexboxGrid align="bottom">
                    <FlexboxGrid.Item colspan={12}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="event.createEvent.autoResetTrigger" />
                            </ControlLabel>
                            <FormControl
                                data-cy="event-add-autoResetTrigger"
                                name="autoResetTrigger"
                                accepter={SelectPicker}
                                data={autoResetTriggerData}
                                searchable={false}
                                cleanable={false}
                            />
                        </FormGroup>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={12}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="event.createEvent.autoResetTriggerTime" />
                            </ControlLabel>
                            <FormControl
                                data-cy="event-add-autoResetTriggerTime"
                                name="autoResetTriggerTime"
                                accepter={DatePicker}
                                format="HH:mm"
                                ranges={[]}
                                disabled={props.formData.autoResetTrigger !== AutoResetTrigger.TIME}
                            />
                        </FormGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>

            <Table
                bordered
                height={300}
                data={props.formData.counterConditions}
                renderEmpty={() => (
                    <div className="text-center" style={{ marginTop: 32 }}>
                        <p style={{ marginBottom: 8 }}>{intl.formatMessage({ id: 'event.counter.noCounter' })}</p>
                        <Button onClick={handleAddCounter} appearance="primary" color="blue">
                            <FontAwesomeIcon icon={faPlus} /> {intl.formatMessage({ id: 'event.counter.add' })}
                        </Button>
                    </div>
                )}>
                <Table.Column flexGrow={2}>
                    <Table.HeaderCell>{intl.formatMessage({ id: 'event.counter.name' })}</Table.HeaderCell>
                    <EditCounterCell counters={counters} dataKey="counterId" onChange={handleCounterChange} rowData />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>{intl.formatMessage({ id: 'event.counter.condition' })}</Table.HeaderCell>
                    <EditCounterConditionCell
                        dataKey="triggerType"
                        onChange={handleConditionChange}
                        rowData
                        triggers={triggerCondition}
                    />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>{intl.formatMessage({ id: 'event.counter.value' })}</Table.HeaderCell>
                    <EditValueCell dataKey="triggerValue" onChange={handleValueChange} rowData />
                </Table.Column>
                <Table.Column width={50}>
                    <Table.HeaderCell>{intl.formatMessage({ id: 'event.counter.delete' })}</Table.HeaderCell>
                    <DeleteButtonCell dataKey="delete" onClick={handleRemoveCounter} rowData />
                </Table.Column>
            </Table>

            <div className="text-center" style={{ marginTop: 8 }}>
                <Button onClick={handleAddCounter} appearance="ghost" data-cy="event-add-counter">
                    <FontAwesomeIcon icon={faPlus} /> {intl.formatMessage({ id: 'event.counter.add' })}
                </Button>
            </div>

            <FlexboxGrid style={{ marginTop: 12 }} justify="space-between">
                <FlexboxGrid.Item>
                    <Button
                        onClick={props.handleCancel}
                        appearance="ghost"
                        color="red"
                        data-cy="event-createModal-cancel">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <ButtonGroup>
                        <Button
                            onClick={props.handleGoBack}
                            data-cy="event-createModal-back"
                            appearance="ghost"
                            loading={isCreating}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            data-cy="event-createModal-finish"
                            appearance="primary"
                            loading={isCreating}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};

export const EditValueCell = ({ rowData, dataKey, onChange, ...props }) => {
    return (
        <Table.Cell {...props} className="form-table">
            <InputNumber
                data-cy={`counter-value-${props.rowIndex}`}
                value={rowData[dataKey]}
                min={0}
                onChange={value => onChange(value, props.rowIndex)}
            />
        </Table.Cell>
    );
};

export const EditCounterCell = ({ rowData, dataKey, onChange, counters, ...props }) => {
    return (
        <Table.Cell {...props} className="form-table">
            <InputPicker
                cleanable={false}
                data={counters}
                value={rowData[dataKey]}
                onChange={value => onChange(value, props.rowIndex)}
                data-cy={`counter-counterId-${props.rowIndex}`}
            />
        </Table.Cell>
    );
};

export const EditCounterConditionCell = ({ rowData, dataKey, onChange, triggers, ...props }) => {
    return (
        <Table.Cell {...props} className="form-table">
            <InputPicker
                cleanable={false}
                data={triggers}
                value={rowData[dataKey]}
                onChange={value => onChange(value, props.rowIndex)}
                data-cy={`counter-condition-${props.rowIndex}`}
            />
        </Table.Cell>
    );
};

export const DeleteButtonCell = ({ rowData, dataKey, onClick, ...props }) => {
    return (
        <Table.Cell {...props} className="form-table">
            <Button
                onClick={() => onClick(props.rowIndex)}
                appearance="primary"
                color="red"
                data-cy={`counter-delete-${props.rowIndex}`}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        </Table.Cell>
    );
};
