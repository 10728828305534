import Gpio from './Gpio';

export default class PhysicalInformation {
    cpu_temp: number | undefined;
    card_temp: number | undefined;
    bus: Array<Record<string, any>>;
    voltage: number | undefined;
    power: number | undefined;
    gpio: Gpio;
    constructor() {
        this.cpu_temp = undefined;
        this.card_temp = undefined;
        this.bus = [];
        this.voltage = undefined;
        this.power = undefined;
        this.gpio = new Gpio();
    }

    getCpuTemp() {
        if (this.cpu_temp) {
            return `${this.cpu_temp.toFixed(2)}°C`;
        }

        return this.cpu_temp;
    }

    getCardTemp() {
        if (this.card_temp) {
            return `${this.card_temp.toFixed(2)}°C`;
        }

        return this.card_temp;
    }

    getVoltage() {
        if (this.voltage) {
            return `${this.voltage.toFixed(2)} V`;
        }

        return this.voltage;
    }

    getPower() {
        if (this.power) {
            return `${this.power.toFixed(2)} W`;
        }

        return this.power;
    }

    getBus() {
        let bus = this.bus.map(bus => {
            return {
                busName: bus.busName,
                volt: `${bus.volt.toFixed(2)}V`,
                amp: `${bus.amp.toFixed(2)}A`,
                p: `${bus.p.toFixed(2)}W`,
            };
        });

        return bus;
    }

    getPhysicalInformation() {
        return {
            cpuTemp: this.getCpuTemp(),
            cardTemp: this.getCardTemp(),
            voltage: this.getVoltage(),
            bus: this.getBus(),
            power: this.getPower(),
            gpio: this.gpio.getGpioState(),
        };
    }

    setPhysicalInformation(cpuTemp, cardTemp, voltage, bus, gpio) {
        this.setCpuTemp(cpuTemp);
        this.setCardTemp(cardTemp);
        this.setVoltage(voltage);
        this.setBus(bus);
        this.setPower();
        this.gpio.setAllGpio(gpio);
    }

    setCpuTemp(temp) {
        this.cpu_temp = parseFloat(temp);
    }

    setCardTemp(temp) {
        this.card_temp = parseFloat(temp);
    }

    setVoltage(voltage) {
        this.voltage = parseFloat(voltage);
    }

    setBus(bus) {
        this.bus = bus;
    }

    setPower() {
        const power = this.bus.map(b => b.p).reduce((a, b) => a + b);

        this.power = parseFloat(power);
    }
}
