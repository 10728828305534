import {
    faArrowAltCircleUp,
    faCheck,
    faEdit,
    faTimes,
    faTimesCircle,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    List,
    Message,
    Panel,
    SelectPicker,
    Tag,
    TagPicker,
} from 'rsuite';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';
import jsonToArray from '../../../../IpCanElementsPage/Drawer/Display/jsonToArrayHandler';
import Arrows from '../../../../IpCanElementsPage/Elements/Arrow';
import Languages from '../../../../IpCanElementsPage/Elements/Language';
import Text from '../../../../IpCanElementsPage/Elements/Text';
import Types from '../../../../IpCanElementsPage/Elements/Types';

type Props = {
    display: any;
    service: any;
} & WrappedComponentProps;

type State = {
    isEditMode: boolean;
    isUpdating: boolean;
    hasError: boolean;
    formValue: FormValue;
};

type FormValue = {
    type: number[];
    arrow: number;
    lang: number[];
    text: number;
};

type ArrowRotationFormValue = {
    label: string;
    value: number;
    rotation: number;
    icon: IconDefinition;
};

const languages = { 1: 'PL', 2: 'ES', 8: 'FR', 16: 'EN', 32: 'PT', 64: 'NL', 128: 'DE' };

const text = [
    {
        label: 'Full',
        value: 0,
    },
    {
        label: 'X',
        value: 1,
    },
    {
        label: 'NoPsign',
        value: 2,
    },
    {
        label: 'No',
        value: 3,
    },
];

const types = { 1: 'Generic', 2: 'PMR', 4: 'Electric', 8: 'Family' };

class DisplayConfigPart extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            isUpdating: false,
            hasError: false,
            formValue: this.generateFormValue(),
        };
    }

    generateFormValue = (): FormValue => {
        const { arrow, lang, text, type } = this.props.display.tcmDisplay;

        let langs: number[] = [];
        for (let i = 1; i <= 128; i <<= 1) {
            if ((lang & i) !== 0) {
                langs.push(i);
            }
        }

        let typesArray: number[] = [];
        for (let i = 1; i <= 8; i <<= 1) {
            if ((type & i) !== 0) {
                typesArray.push(i);
            }
        }

        return {
            arrow,
            lang: langs,
            text,
            type: typesArray,
        };
    };

    generateArrowRotationForm = (): ArrowRotationFormValue[] => {
        return [
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.noArrow',
                }),
                icon: faTimesCircle,
                rotation: 0,
                value: 0,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.left',
                }),
                icon: faArrowAltCircleUp,
                rotation: 270,
                value: 1,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.right',
                }),
                icon: faArrowAltCircleUp,
                rotation: 90,
                value: 2,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.up',
                }),
                icon: faArrowAltCircleUp,
                rotation: 0,
                value: 3,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.down',
                }),
                icon: faArrowAltCircleUp,
                rotation: 180,
                value: 4,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.upLeft',
                }),
                icon: faArrowAltCircleUp,
                rotation: 315,
                value: 5,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.upRight',
                }),
                icon: faArrowAltCircleUp,
                rotation: 45,
                value: 6,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.downLeft',
                }),
                icon: faArrowAltCircleUp,
                rotation: 225,
                value: 7,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'display.arrows.downRight',
                }),
                icon: faArrowAltCircleUp,
                rotation: 135,
                value: 8,
            },
        ];
    };

    setEditMode = () => {
        this.setState({
            isEditMode: true,
        });
    };

    handleChange = formValue => {
        this.setState({ formValue: formValue });
    };

    cancelEditMode = () => {
        this.setState({
            isEditMode: false,
            formValue: this.generateFormValue(),
        });
    };

    validEdition = () => {
        this.setState({
            isUpdating: true,
            hasError: false,
        });

        const { arrow, lang, text, type } = this.state.formValue;

        axiosService
            .getAxios()
            .put(
                '/devices/tcm-display/updateConfig',
                {
                    id: this.props.display.tcmDisplay.id,
                    arrow,
                    text,
                    lang: lang.length > 0 ? lang.reduce((acc, value) => acc + value) : 0,
                    type: type.length > 0 ? type.reduce((acc, value) => acc + value) : 0,
                },
                { headers: authHeader() }
            )
            .then(updateConfigResponse => {
                this.setState({
                    isEditMode: false,
                    isUpdating: false,
                });

                this.props.service.send('DISPLAY_UPDATE', { display: updateConfigResponse.data });

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'map.tcmDisplay.edit.configuration.success',
                    })
                );
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                    isUpdating: false,
                });
            });
    };

    generateViewPart = () => (
        <List>
            {/* ARROW */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                        <FormattedMessage id="map.tcmDisplay.edit.configuration.arrow" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item data-cy="tcmDisplay-view-configuration-arrow">
                        <Arrows value={this.props.display.tcmDisplay.arrow} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* LANGS */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                        <FormattedMessage id="map.tcmDisplay.edit.configuration.languages" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item data-cy="tcmDisplay-view-configuration-languages">
                        <Languages value={this.props.display.tcmDisplay.lang} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* TEXT */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                        <FormattedMessage id="map.tcmDisplay.edit.configuration.text" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item data-cy="tcmDisplay-view-configuration-text">
                        <Text value={this.props.display.tcmDisplay.text} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* TYPES */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                        <FormattedMessage id="map.tcmDisplay.edit.configuration.types" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item data-cy="tcmDisplay-view-configuration-types">
                        <Types value={this.props.display.tcmDisplay.type} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );

    generateEditPart = () => (
        <Form formValue={this.state.formValue} onChange={this.handleChange} fluid>
            <List hover>
                {/* ARROW */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item componentClass={Col} xs={12} style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="map.tcmDisplay.edit.configuration.arrow" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12} style={{ fontWeight: 'bold' }}>
                            <FormGroup>
                                <FormControl
                                    data-cy="ipCanDevices-display-arrow"
                                    name="arrow"
                                    accepter={SelectPicker}
                                    data={this.generateArrowRotationForm()}
                                    cleanable={false}
                                    searchable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        const style = {
                                            transform: `rotate(${item.rotation}deg)`,
                                        };

                                        return (
                                            <div data-cy={`configuration-display-value-${item.value}`}>
                                                <FontAwesomeIcon
                                                    className="margin-right-15"
                                                    icon={item.icon}
                                                    style={style}
                                                />
                                                {label}
                                            </div>
                                        );
                                    }}
                                    renderValue={(_, item) => {
                                        const style = {
                                            transform: `rotate(${item.rotation}deg)`,
                                        };

                                        return (
                                            <div data-cy={`configuration-display-value-${item.value}`}>
                                                <FontAwesomeIcon
                                                    style={style}
                                                    className="margin-right-15"
                                                    icon={item.icon}
                                                />
                                                {item.label}
                                            </div>
                                        );
                                    }}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* LANGUAGES */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item componentClass={Col} xs={12} style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="map.tcmDisplay.edit.configuration.languages" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormGroup>
                                <FormControl
                                    data-cy="ipCanDevices-display-lang"
                                    name="lang"
                                    data={jsonToArray(languages)}
                                    accepter={TagPicker}
                                    cleanable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        return <div data-cy={`configuration-lang-value-${item.value}`}>{label}</div>;
                                    }}
                                    renderValue={(_, item) => {
                                        return item.map(i => {
                                            return (
                                                <Tag
                                                    data-cy={`configuration-lang-${i.label}`}
                                                    color="blue"
                                                    key={`type-${i.label}`}>
                                                    {i.label}
                                                </Tag>
                                            );
                                        });
                                    }}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* TEXT */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item componentClass={Col} xs={12} style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="map.tcmDisplay.edit.configuration.text" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormGroup>
                                <FormControl
                                    data-cy="ipCanDevices-display-text"
                                    name="text"
                                    accepter={SelectPicker}
                                    data={text}
                                    cleanable={false}
                                    searchable={false}
                                    placement="auto"
                                    renderMenuItem={(label, item) => {
                                        return <div data-cy={`configuration-text-value-${item.value}`}>{label}</div>;
                                    }}
                                />
                            </FormGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* TYPES */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between" align="middle">
                        <FlexboxGrid.Item componentClass={Col} xs={12} style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id="map.tcmDisplay.edit.configuration.types" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                            <FormControl
                                data-cy="ipCanDevices-display-type"
                                name="type"
                                data={jsonToArray(types)}
                                accepter={TagPicker}
                                cleanable={false}
                                placement="auto"
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`configuration-type-value-${item.value}`}>{label}</div>;
                                }}
                                renderValue={(_, item) => {
                                    return item.map(i => {
                                        return (
                                            <Tag
                                                data-cy={`configuration-type-${i.label}`}
                                                color="blue"
                                                key={`type-${i.label}`}>
                                                {i.label}
                                            </Tag>
                                        );
                                    });
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    data-cy="tcmDisplay-view-configuration-cancel"
                                    color="red"
                                    onClick={this.cancelEditMode}
                                    disabled={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    data-cy="tcmDisplay-view-configuration-valid"
                                    color="green"
                                    onClick={this.validEdition}
                                    loading={this.state.isUpdating}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );

    render = () => (
        <Panel
            className="panel-small"
            bordered
            bodyFill
            shaded
            header={
                <PanelHeader
                    title={this.props.intl.formatMessage({
                        id: 'map.tcmDisplay.edit.configuration',
                    })}
                    buttons={[
                        <Button
                            data-cy="tcmDisplay-edit-configuration"
                            color="blue"
                            size="sm"
                            onClick={this.setEditMode}
                            disabled={this.state.isEditMode}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {this.state.hasError && (
                <Message
                    type="error"
                    description={this.props.intl.formatMessage({ id: 'map.tcmDisplay.edit.information.error' })}
                />
            )}
            {this.state.isEditMode ? this.generateEditPart() : this.generateViewPart()}
        </Panel>
    );
}

export default injectIntl(DisplayConfigPart);
