import React, { Component, Fragment } from 'react';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PlaceTypeTable from './PlaceTypeTable';
import SensorPlaceTypeTable from './SensorPlaceTypeTable';

export default class PlaceType extends Component {
    render() {
        return (
            <Fragment>
                <SecuredFragment authorizedRoles={[rolesConstants.placeType.VIEW_LIST]}>
                    <PlaceTypeTable />
                </SecuredFragment>
                <SecuredFragment authorizedRoles={[rolesConstants.sensorPlaceType.VIEW_LIST]}>
                    <SensorPlaceTypeTable />
                </SecuredFragment>
            </Fragment>
        );
    }
}
