import { faRobot, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FlexboxGrid, Message, Modal } from 'rsuite';
import IpCanHistory from '../../../handlers/history/IPCanHistoric.handler';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { createIpCan } from '../../../handlers/ipcan/IpCanHelper';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import HistorySortPart from '../../History/SortPart';
import UpdateBusConfigExpand from '../../History/Table/Expandable/UpdateBusConfigExpand';
import IpCanDescCell from '../../History/Table/IpCanDescCell';
import IpCanOptionsCell from '../../History/Table/ipCanOptionsCell';
import HistoryTable from '../../ReactDataTableComponent/HistoryTable';

type Props = {
    show: boolean;
    onHide: Function;
    controller: Record<string, any>;
    intl: Record<string, any>;
} & WrappedComponentProps;

type State = {
    histories: Array<Record<string, any>>;
    isLoading: boolean;
    hasError: boolean;
    users: Array<Record<string, any>>;
    formValues: Record<string, any>;
    ipcans: Array<IpCan>;
};

class ControllerHistoryModal extends React.Component<Props, State> {
    columns: TableColumn<Record<string, any>>[];

    constructor(props) {
        super(props);

        this.state = {
            histories: [],
            isLoading: true,
            hasError: false,
            users: [],
            formValues: {
                user: null,
                before: null,
                after: null,
            },
            ipcans: [],
        };

        this.setUser = this.setUser.bind(this);
        this.setBefore = this.setBefore.bind(this);
        this.setAfter = this.setAfter.bind(this);
        this.loadHistory = this.loadHistory.bind(this);
        this.setNbElements = this.setNbElements.bind(this);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.user',
                }),
                selector: 'user',
                sortable: true,
                cell: row => {
                    if (row.user === 'MQTT') {
                        return <FontAwesomeIcon icon={faRobot} size="lg" />;
                    } else {
                        return row.user;
                    }
                },
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.createdAt',
                }),
                selector: 'createdAt',
                sortable: true,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.desc',
                }),
                selector: 'desc',
                sortable: true,
                cell: row => IpCanDescCell(row.desc),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.history.option',
                }),
                selector: 'option',
                cell: row => IpCanOptionsCell(row.desc, row.option),
            },
        ];
    }

    setUser(user) {
        this.setState({
            formValues: { ...this.state.formValues, user },
        });
    }

    setBefore(before) {
        this.setState({
            formValues: { ...this.state.formValues, before: moment(before).add(1, 'hour') },
        });
    }

    setAfter(after) {
        this.setState({
            formValues: { ...this.state.formValues, after: moment(after).add(1, 'hour') },
        });
    }

    setNbElements(nbElements) {
        this.setState({
            formValues: { ...this.state.formValues, nbElements },
        });
    }

    loadHistory() {
        this.setState({
            isLoading: true,
        });

        axiosService
            .getAxios()
            .post(
                '/historics/ipcanmodules',
                {
                    nbResult: 100,
                    id: this.props.controller.id,
                    user: this.state.formValues.user,
                    after: this.state.formValues.after ? moment(this.state.formValues.after).toISOString() : null,
                    before: this.state.formValues.before ? moment(this.state.formValues.before).toISOString() : null,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(ipCanModuleHistoryResponse => {
                let users: Array<string> = [];

                const histories = ipCanModuleHistoryResponse.data.map(history => {
                    const ipcanHistory: IpCanHistory = new IpCanHistory(history);

                    users.indexOf(ipcanHistory.getUser()) === -1 && users.push(ipcanHistory.getUser());

                    let formattedHistory: Record<string, any> = ipcanHistory.getHistory();

                    if (
                        formattedHistory.desc !== 'update bus config' ||
                        (formattedHistory.desc === 'update bus config' && formattedHistory.option.error)
                    ) {
                        formattedHistory.disabled = true;
                    } else {
                        formattedHistory.disabled = false;
                    }

                    return formattedHistory;
                });

                axiosService
                    .getAxios()
                    .get('/ipcanmodules', { headers: authHeader() })
                    .then(ipCanResponse => {
                        this.setState({
                            ipcans: ipCanResponse.data.map(ipcan => createIpCan(ipcan)),
                            histories,
                            users: users.map(user => {
                                return { label: user, value: user };
                            }),
                        });
                    });

                this.setState({
                    histories,
                    users: users.map(user => {
                        if (user === 'MQTT') {
                            return {
                                label: user,
                                value: user,
                            };
                        } else {
                            return { label: user, value: user };
                        }
                    }),
                });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    hasError: true,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    componentDidMount() {
        this.loadHistory();
    }

    render() {
        return (
            <Modal
                full
                backdrop="static"
                show={this.props.show}
                onHide={() => this.props.onHide()}
                style={{ height: '95%' }}
                className="no-modal-padding">
                <Modal.Header style={{ backgroundColor: '#212121' }}>
                    <HistorySortPart
                        users={this.state.users}
                        setUser={this.setUser}
                        setBefore={this.setBefore}
                        setAfter={this.setAfter}
                        valid={this.loadHistory}
                        setNbElements={this.setNbElements}
                    />
                </Modal.Header>
                <Modal.Body>
                    {this.state.hasError ? (
                        <Message type="error">
                            <FormattedMessage id="history.ipCanModules.fetchHistoryError" />
                        </Message>
                    ) : (
                        <Fragment>
                            <FlexboxGrid align="middle" justify="center">
                                <FlexboxGrid.Item>
                                    <FontAwesomeIcon icon={faServer} size="2x" className="margin-right-5" />
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item>
                                    <h4
                                        data-cy="history-controller-label-name"
                                        className="text-center">{`${this.props.controller.label} (${this.props.controller.macStr})`}</h4>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <HistoryTable
                                columns={this.columns}
                                data={this.state.histories}
                                progressPending={this.state.isLoading}
                                expandableRows
                                expandOnRowClicked
                                expandableRowDisabled={row => row.disabled}
                                expandableRowsComponent={UpdateBusConfigExpand}
                                expandableRowsComponentProps={{ ipcans: this.state.ipcans }}
                            />
                        </Fragment>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}
export default injectIntl(ControllerHistoryModal);
