import { authConstants } from '../constants';
import { setAuthHeader } from '../helpers';
import { authService } from '../services';
import { notificationActions } from './notification.actions';

export const authActions = {
    login,
    logout,
};

function login(email, password) {
    return dispatch => {
        dispatch(request());

        authService.login(email, password).then(
            user => {
                dispatch(success(user));
                dispatch(notificationActions.createNotification('success', 'login.success'));
            },
            error => {
                dispatch(notificationActions.createNotification('error', 'login.failure'));
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: authConstants.LOGIN_REQUEST,
        };
    }

    function success(user) {
        return {
            type: authConstants.LOGIN_SUCCESS,
            user: user,
        };
    }

    function failure(error) {
        return {
            type: authConstants.LOGIN_FAILURE,
            error: error,
        };
    }
}

function logout(isForced) {
    return dispatch => {
        dispatch(request());

        if (isForced) {
            dispatch(notificationActions.createNotification('error', 'unauthorized'));
        }

        authService.logout().then(
            () => {
                dispatch(notificationActions.createNotification('success', 'logout.success'));
                dispatch(success());
            },
            error => {
                dispatch(failure(error));
                dispatch(notificationActions.createNotification('error', 'logout.error'));
            }
        );

        setAuthHeader(null, null);
    };

    function request() {
        return {
            type: authConstants.LOGOUT_REQUEST,
        };
    }

    function success() {
        return {
            type: authConstants.LOGOUT_SUCCESS,
        };
    }

    function failure(error) {
        return {
            type: authConstants.LOGOUT_ERROR,
            error: error,
        };
    }
}
