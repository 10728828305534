type Props = {
    color: string;
};

export const BanIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} x="192" width="128" height="64" stroke-width=".41312" />
            <rect fill={color} x="192" y="448" width="128" height="64" stroke-width=".41312" />
            <rect fill={color} y="192" width="64" height="128" stroke-width=".58748" />
            <rect fill={color} x="448" y="192" width="64" height="128" stroke-width=".58748" />
            <g stroke-width=".62864">
                <rect fill={color} x="16" y="160" width="64" height="64" />
                <rect fill={color} x="16" y="288" width="64" height="64" />
                <rect fill={color} x="32" y="320" width="64" height="64" />
                <rect fill={color} x="64" y="368" width="64" height="64" />
            </g>
            <rect fill={color} x="48" y="336" width="64" height="80" stroke-width=".70284" />
            <rect fill={color} x="32" y="128" width="64" height="64" stroke-width=".62864" />
            <rect fill={color} x="48" y="96" width="64" height="80" stroke-width=".70284" />
            <rect fill={color} x="64" y="80" width="64" height="64" stroke-width=".62864" />
            <rect fill={color} x="80" y="64" width="64" height="64" stroke-width=".62864" />
            <rect fill={color} x="160" y="16" width="48" height="64" stroke-width="1.8613" />
            <rect fill={color} x="160" y="432" width="48" height="64" stroke-width="1.8613" />
            <g stroke-width="2.1492">
                <rect fill={color} x="128" y="32" width="64" height="64" />
                <rect fill={color} x="128" y="416" width="64" height="64" />
                <rect fill={color} x="96" y="400" width="64" height="64" />
                <rect fill={color} x="80" y="384" width="64" height="64" />
                <rect fill={color} x="96" y="48" width="64" height="64" />
            </g>
            <g stroke-width=".62864">
                <rect fill={color} transform="scale(-1)" x="-496" y="-352" width="64" height="64" />
                <rect fill={color} transform="scale(-1)" x="-496" y="-224" width="64" height="64" />
                <rect fill={color} transform="scale(-1)" x="-480" y="-192" width="64" height="64" />
                <rect fill={color} transform="scale(-1)" x="-448" y="-144" width="64" height="64" />
            </g>
            <rect fill={color} transform="scale(-1)" x="-464" y="-176" width="64" height="80" stroke-width=".70284" />
            <rect fill={color} transform="scale(-1)" x="-480" y="-384" width="64" height="64" stroke-width=".62864" />
            <rect fill={color} transform="scale(-1)" x="-464" y="-416" width="64" height="80" stroke-width=".70284" />
            <rect fill={color} transform="scale(-1)" x="-448" y="-432" width="64" height="64" stroke-width=".62864" />
            <rect fill={color} transform="scale(-1)" x="-432" y="-448" width="64" height="64" stroke-width=".62864" />
            <rect fill={color} transform="scale(-1)" x="-352" y="-496" width="48" height="64" stroke-width="1.8613" />
            <rect fill={color} transform="scale(-1)" x="-352" y="-80" width="48" height="64" stroke-width="1.8613" />
            <g stroke-width="2.1492">
                <rect fill={color} transform="scale(-1)" x="-384" y="-480" width="64" height="64" />
                <rect fill={color} transform="scale(-1)" x="-384" y="-96" width="64" height="64" />
                <rect fill={color} transform="scale(-1)" x="-416" y="-112" width="64" height="64" />
                <rect fill={color} transform="scale(-1)" x="-432" y="-128" width="64" height="64" />
                <rect fill={color} transform="scale(-1)" x="-416" y="-464" width="64" height="64" />
            </g>
            <g stroke-width=".95108">
                <rect fill={color} x="112" y="112" width="48" height="48" />
                <rect fill={color} x="128" y="128" width="48" height="48" />
                <rect fill={color} x="144" y="144" width="48" height="48" />
                <rect fill={color} x="160" y="160" width="48" height="48" />
                <rect fill={color} x="176" y="176" width="48" height="48" />
                <rect fill={color} x="192" y="192" width="48" height="48" />
                <rect fill={color} x="208" y="208" width="48" height="48" />
                <rect fill={color} x="224" y="224" width="48" height="48" />
                <rect fill={color} x="240" y="240" width="48" height="48" />
                <rect fill={color} x="256" y="256" width="48" height="48" />
                <rect fill={color} x="272" y="272" width="48" height="48" />
                <rect fill={color} x="288" y="288" width="48" height="48" />
                <rect fill={color} x="304" y="304" width="48" height="48" />
                <rect fill={color} x="320" y="320" width="48" height="48" />
                <rect fill={color} x="336" y="336" width="48" height="48" />
                <rect fill={color} x="352" y="352" width="48" height="48" />
            </g>
        </g>
    </svg>
);
