import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Col, DatePicker, FlexboxGrid, Modal, Progress, SelectPicker, Toggle } from 'rsuite';
import TCMSensor from '../../../../../handlers/ipCanDevices/TCMSensor';
import { ipCanDevicesActions } from '../../../../../redux/actions';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../../redux/services';

const mapDispatchToProps = dispatch => ({
    updateSensors: sensors => dispatch(ipCanDevicesActions.updateSensorForce(sensors)),
});

type Props = {
    show: boolean;
    onHide: Function;
    sensorsId: number[];
    updateSensors?: Function;
    sensors: TCMSensor[];
} & WrappedComponentProps;

type State = {
    forceValues: Array<Record<string, any>>;
    isForce: boolean;
    forceState: any;
    endForceTime: Moment | undefined;
    loading: boolean;
    forced: Array<Record<string, any>>;
};
class ForceSensorModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            forceValues: [],
            isForce: false,
            forceState: 0,
            endForceTime: moment().add(1, 'year').set({ second: 0, millisecond: 0 }),
            loading: false,
            forced: [],
        };

        this.forceSensors = this.forceSensors.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
    }

    closeModal() {
        this.setState({
            isForce: false,
            forceState: 0,
            endForceTime: moment().add(1, 'year').set({ second: 0, millisecond: 0 }),
            loading: false,
            forced: [],
        });

        this.props.onHide();
    }

    updateStatus(e) {
        let forcedArray = this.state.forced;

        if (forcedArray.indexOf(e.id) === -1 && this.props.sensorsId.indexOf(e.id) !== -1) {
            forcedArray.push(e.id);

            this.setState({
                forced: forcedArray,
            });
        }
    }

    componentDidMount() {
        webSocketService.onEvent('tcm-sensor:updateStatus', this.updateStatus);

        this.setState({
            forceValues: [
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.free',
                    }),
                    value: 1,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.occupied',
                    }),
                    value: 2,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.overstayFree',
                    }),
                    value: 3,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.overstayOccupied',
                    }),
                    value: 4,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.green',
                    }),
                    value: 11,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.red',
                    }),
                    value: 12,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.black',
                    }),
                    value: 13,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.greenBlink',
                    }),
                    value: 14,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.redBlink',
                    }),
                    value: 15,
                },
                {
                    label: this.props.intl.formatMessage({
                        id: 'sensor.force.redGreenBlink',
                    }),
                    value: 17,
                },
            ],
        });
    }

    componentDidUpdate = (prevProps: Props) => {
        if (this.props.show && prevProps.show !== this.props.show) {
            if (this.props.sensorsId.length === 1) {
                const currentSensor = this.props.sensors.find(sensor => sensor.id === this.props.sensorsId[0]);

                if (currentSensor) {
                    this.setState({
                        isForce: currentSensor.isForce,
                        forceState: currentSensor.isForce ? currentSensor.forceType : 0,
                        endForceTime: currentSensor.isForce
                            ? moment(currentSensor.endForceTime)
                            : moment().add(1, 'year').set({ second: 0, millisecond: 0 }),
                    });
                }
            }
        }
    };

    forceSensors() {
        this.setState({
            loading: true,
            forced: [],
        });

        axiosService
            .getAxios()
            .put(
                '/devices/tcm-sensor/forceSensor',
                {
                    tabId: this.props.sensorsId,
                    isForce: this.state.isForce,
                    forceState: this.state.isForce ? this.state.forceState : 0,
                    endForceTime: this.state.isForce
                        ? this.state.endForceTime
                        : moment().set({ seconds: 0, millisecond: 0 }),
                    startForceTime: new Date(),
                },
                { headers: authHeader() }
            )
            .then(response => {
                if (this.props.updateSensors) {
                    this.props.updateSensors(response.data);
                }

                this.closeModal();
            });
    }

    render() {
        return (
            <Modal backdrop={true} show={this.props.show} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="sensor.force.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="ipcan-elements-sensor-force-modal">
                    {this.state.loading ? (
                        <Fragment>
                            <Progress.Line
                                percent={(this.state.forced.length / this.props.sensorsId.length) * 100}
                                showInfo={false}
                            />

                            <p className="text-center">
                                {this.state.forced.length} / {this.props.sensorsId.length}
                            </p>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <FlexboxGrid data-cy="ipcan-elements-isForce" justify="center" className="margin-bottom-15">
                                <Toggle
                                    onChange={checked => this.setState({ isForce: checked })}
                                    size="lg"
                                    checked={this.state.isForce}
                                    checkedChildren={this.props.intl.formatMessage({
                                        id: 'sensor.force.forced',
                                    })}
                                    unCheckedChildren={this.props.intl.formatMessage({
                                        id: 'sensor.force.unforced',
                                    })}
                                />
                            </FlexboxGrid>

                            {this.state.isForce && (
                                <FlexboxGrid>
                                    <FlexboxGrid.Item data-cy="ipcan-elements-forceType" componentClass={Col} xs={12}>
                                        <SelectPicker
                                            value={this.state.forceState}
                                            block
                                            data={this.state.forceValues}
                                            onChange={value => this.setState({ forceState: value })}
                                            renderMenuItem={(label, item) => {
                                                return (
                                                    <div data-cy={`ipcan-forceType-value-${item.value}`}>{label}</div>
                                                );
                                            }}
                                        />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <DatePicker
                                            block
                                            format="DD/MM/YYYY HH:mm"
                                            ranges={[
                                                {
                                                    label: this.props.intl.formatMessage({ id: 'calendar.now' }),
                                                    value: new Date(),
                                                },
                                            ]}
                                            defaultValue={this.state.endForceTime?.toDate()}
                                            onChange={date => this.setState({ endForceTime: moment(date) })}
                                        />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            )}
                        </Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <FlexboxGrid justify="end" align="middle">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button
                                    onClick={this.closeModal}
                                    color="red"
                                    data-cy="ipcan-elements-sensor-force-cancel">
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    onClick={this.forceSensors}
                                    loading={this.state.loading}
                                    color="green"
                                    data-cy="ipcan-elements-sensor-force-valid">
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(null, mapDispatchToProps)(injectIntl(ForceSensorModal));
