import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, FlexboxGrid } from 'rsuite';

type Props = {
    nextStep: Function;
};

export const Validation = ({ nextStep }: Props) => (
    <FlexboxGrid justify="center">
        <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-top-15">
            <FormattedMessage id="update.automatic.description" />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item className="margin-top-15">
            <Button color="green" className="margin-top-10" onClick={() => nextStep()}>
                <FontAwesomeIcon className="margin-right-10" icon={faChevronRight} />
                <FormattedMessage id="update.automatic.continueUpdate" />
            </Button>
        </FlexboxGrid.Item>
    </FlexboxGrid>
)