import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Loader, Panel } from 'rsuite';
import { Profile } from '../../../../../../handlers/profile/Profile';
import { RoleInterface } from '../../../../../../handlers/profile/Role';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';
import PanelHeader from '../../../../../Custom/PanelHeader';
import EditProfileRolesPart from './EditProfileRolesPart';
import { ViewProfileRolesPart } from './ViewProfileRolesPart';

type Props = {
    profile: Profile;
    updateProfiles: () => void;
    reloadProfiles: () => void;
    reloadUsers: () => void;
};

export type RoleData = {
    label: string;
    value: number;
    children: {
        label: string;
        value: number;
    }[];
};

export const RoleCard = (props: Props) => {
    const intl = useIntl();

    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [roles, setRoles] = useState<RoleData[]>([]);

    const authenticatedUser = useSelector((state: any) => state.auth.user);

    useEffect(() => {
        setIsLoading(true);

        const currentUserRolesId = authenticatedUser.roles.map(role => role.id);

        axiosService
            .getAxios()
            .get<RoleInterface[]>('/roles', {
                headers: authHeader(),
            })
            .then(roleResponse => {
                const generatedRoles: RoleData[] = [];

                const result = roleResponse.data.reduce((previous, current) => {
                    previous[current.section] = previous[current.section] || [];
                    previous[current.section].push(current);
                    return previous;
                }, Object.create(null));

                Object.keys(result).forEach((section, index) => {
                    if (currentUserRolesId.includes(result[section][0].id) || currentUserRolesId.includes(1)) {
                        generatedRoles.push({
                            label: section,
                            value: (index + 1) * -1,
                            children: [],
                        });

                        result[section].forEach(role => {
                            if (currentUserRolesId.includes(role.id) || currentUserRolesId.includes(1)) {
                                generatedRoles[generatedRoles.length - 1].children.push({
                                    label: role.name,
                                    value: role.id,
                                });
                            }
                        });
                    }
                });

                setRoles(generatedRoles);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <Panel
                shaded
                bordered
                className="panel-small"
                data-cy="profile-role-card"
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'profile.roles' })}
                        buttons={[
                            <Button
                                color="blue"
                                size="sm"
                                data-cy="profile-role-edit"
                                disabled={editMode || props.profile.getName() === 'Admin'}
                                onClick={() => setEditMode(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>,
                        ]}
                    />
                }>
                {/* <List>
                    {props.profile.getRoles().map(role => (
                        <List.Item className="panel-list">
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item>
                                    {role.getSection()} - {role.getName()}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                    ))}
                </List> */}
                {isLoading ? (
                    <Loader center />
                ) : (
                    <>
                        {editMode ? (
                            <div data-cy="roles-choosable">
                                <EditProfileRolesPart
                                    profile={props.profile}
                                    roles={roles}
                                    setViewMode={() => setEditMode(false)}
                                    reloadProfiles={props.reloadProfiles}
                                    reloadUsers={props.reloadUsers}
                                />
                            </div>
                        ) : (
                            <ViewProfileRolesPart profile={props.profile} />
                        )}
                    </>
                )}
            </Panel>
        </>
    );
};
