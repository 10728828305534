import moment from 'moment';

export function webSocketTcmDisplaysHandler(tcmDisplay) {
    return {
        arrow: parseInt(tcmDisplay.arrow),
        bus: parseInt(tcmDisplay.bus),
        configType: tcmDisplay.configType,
        deviceId: parseInt(tcmDisplay.deviceId),
        displayValue: tcmDisplay.displayValue,
        endForceTime: moment.utc(tcmDisplay.endForceTime).local().format('DD/MM/YYYY HH:mm:ss'),
        forceType: tcmDisplay.forceType,
        id: parseInt(tcmDisplay.id),
        intensity: parseInt(tcmDisplay.intensity),
        ipCanId: tcmDisplay.ipcanmoduleId,
        isForce: tcmDisplay.isForce,
        lang: parseInt(tcmDisplay.lang),
        manufacturer: tcmDisplay.manufacturer,
        modelName: tcmDisplay.modelName,
        online: tcmDisplay.online,
        text: parseInt(tcmDisplay.text),
        type: parseInt(tcmDisplay.type),
        version: tcmDisplay.version,
        lastOnlineStateDate: moment(tcmDisplay.lastOnlineStateDate).isValid()
            ? moment(tcmDisplay.lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss')
            : null,
    };
}
