import React from 'react';
import { ArrowHeadType, EdgeProps, getBezierPath, getMarkerEnd } from 'react-flow-renderer';
import { useIntl, WrappedComponentProps } from 'react-intl';
import { TriggerInterface } from '../../../../handlers/lpDisplays/LPDisplayTopology';

type TriggerProps = {
    trigger: TriggerInterface;
};

type Props = EdgeProps<TriggerProps> & WrappedComponentProps;

const EdgeTrigger = (props: Props) => {
    const intl = useIntl();

    // const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    //     sourceX: props.sourceX,
    //     sourceY: props.sourceY,
    //     targetX: props.targetX,
    //     targetY: props.targetY,
    // });

    const foreignObjectSize = 100;

    const edgePath = getBezierPath({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        sourcePosition: props.sourcePosition,
        targetX: props.targetX,
        targetY: props.targetY,
        targetPosition: props.targetPosition,
    });

    const markerEnd = getMarkerEnd(ArrowHeadType.Arrow, props.markerEndId);

    const generateMode = () => {
        if (!props.data) return '';

        switch (props.data.trigger.trigger) {
            case 1: {
                return `${intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.topology.sourceTime',
                })} (${props.data.trigger.value}s)`;
            }
            case 2: {
                return `${intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.topology.sourceCounterMin',
                })} ${props.data.trigger.source} < ${props.data.trigger.value}`;
            }
            case 3: {
                return `${intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.topology.sourceCounterMax',
                })} ${props.data.trigger.source} > ${props.data.trigger.value}`;
            }
            case 4: {
                return `${intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.topology.sourceGpio',
                })} ${props.data.trigger.value + 1}`;
            }
            case 5: {
                return `${intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.topology.sourceInvertGPIO',
                })} ${props.data.trigger.value + 1}`;
            }
            default:
                return null;
        }
    };

    return (
        <>
            <path
                id={props.id}
                style={props.style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize / 5}
                x={props.sourceX - foreignObjectSize / 2}
                y={props.sourceY + 2}
                requiredExtensions="http://www.w3.org/1999/xhtml">
                <div
                    style={{
                        backgroundColor: 'white',
                        color: '#000',
                        textAlign: 'center',
                        borderRadius: '5px',
                    }}>
                    {generateMode()}
                </div>
            </foreignObject>
        </>
    );
};

export default EdgeTrigger;
