import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ChromePicker } from 'react-color';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    ControlLabel,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    Message,
    Modal,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { LPSensor } from '../../../handlers/ipCanDevices/LPSensor';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    sensors: LPSensor[];
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isLoading: boolean;
    hasError: boolean;
    error: any | null;
    formValue: FormValue;
    color: {
        r: number;
        g: number;
        b: number;
        a: number;
    };
};

type FormValue = {
    isForce: boolean;
    forceType: number;
    // ledMask: number; FF
    // exitLed: boolean; !isForce
    endForceTime: Date;
};

class ForceLPSensorModal extends React.PureComponent<Props, State> {
    forceValues: Array<any>;
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            hasError: false,
            error: null,
            formValue: {
                isForce: false,
                forceType: 1,
                // ledMask: 0,
                // exitLed: false,
                endForceTime: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            },
            color: {
                r: 0,
                g: 0,
                b: 0,
                a: 1,
            },
        };

        this.forceValues = [
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceFree',
                }),
                value: 1,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceOccupied',
                }),
                value: 2,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceOverstayOccupied',
                }),
                value: 3,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceReserved',
                }),
                value: 4,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'lpSensors.force.forceType.forceColor',
                }),
                value: 10,
            },
        ];
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.show !== this.props.show) {
            if (this.props.show && this.props.sensors.length === 1) {
                const currentSensor = this.props.sensors[0];

                this.setState({
                    formValue: {
                        isForce: currentSensor.isForce,
                        endForceTime: currentSensor.isForce
                            ? new Date(currentSensor.endForceTime)
                            : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                        forceType: currentSensor.isForce ? currentSensor.forceType.forceType : 1,
                    },
                    color: {
                        r: currentSensor.isForce ? currentSensor.forceType.color.red : 0,
                        g: currentSensor.isForce ? currentSensor.forceType.color.green : 0,
                        b: currentSensor.isForce ? currentSensor.forceType.color.blue : 0,
                        a: 1,
                    },
                });
            } else {
                this.setState({
                    formValue: {
                        isForce: false,
                        endForceTime: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                        forceType: 1,
                    },
                    color: {
                        r: 0,
                        g: 0,
                        b: 0,
                        a: 1,
                    },
                });
            }
        }
    }

    sendForce = () => {
        this.setState({
            isLoading: true,
            hasError: false,
            error: null,
        });

        axiosService
            .getAxios()
            .put(
                '/devices/lp-sensor/forceSensor',
                {
                    tabId: this.props.sensors.map(sensor => sensor.id),
                    isForce: this.state.formValue.isForce,
                    forceType: {
                        forceType: this.state.formValue.isForce ? this.state.formValue.forceType : 0,
                        color: {
                            red:
                                this.state.formValue.isForce && this.state.formValue.forceType === 10
                                    ? this.state.color.r
                                    : 0,
                            green:
                                this.state.formValue.isForce && this.state.formValue.forceType === 10
                                    ? this.state.color.g
                                    : 0,
                            blue:
                                this.state.formValue.isForce && this.state.formValue.forceType === 10
                                    ? this.state.color.b
                                    : 0,
                            ledMask: 0xff,
                            exitLed: !this.state.formValue.isForce,
                        },
                    },
                    endForceTime: this.state.formValue.isForce ? this.state.formValue.endForceTime : new Date(),
                    startForceTime: new Date(),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'lpSensors.force.success',
                    })
                );

                this.setState({
                    isLoading: false,
                });

                this.closeModal();
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    closeModal = () => {
        if (!this.state.isLoading) {
            this.props.onHide();
        }
    };

    handleChange = formValue => {
        this.setState({
            formValue,
        });
    };

    handleColorChange = color => {
        this.setState({
            color: {
                r: color.rgb.r,
                g: color.rgb.g,
                b: color.rgb.b,
                a: 1,
            },
        });
    };

    render = () => {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="lpSensors.force.title" />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.state.hasError && (
                        <Message
                            type="error"
                            description={this.props.intl.formatMessage(
                                {
                                    id: 'lpSensors.force.error',
                                },
                                {
                                    error: this.state.error,
                                }
                            )}
                        />
                    )}
                    <Form formValue={this.state.formValue} onChange={this.handleChange}>
                        <FlexboxGrid justify="center" align="middle">
                            <FlexboxGrid.Item>
                                <FormControl
                                    data-cy="ipCanDevices-lpSensor-force"
                                    name="isForce"
                                    accepter={Toggle}
                                    size="lg"
                                    checkedChildren={this.props.intl.formatMessage({
                                        id: 'lpSensors.force.forced',
                                    })}
                                    unCheckedChildren={this.props.intl.formatMessage({
                                        id: 'lpSensors.force.unforced',
                                    })}
                                    checked={this.state.formValue.isForce}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        {this.state.formValue.isForce && (
                            <FlexboxGrid className="margin-top-15">
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <ControlLabel>
                                        <FormattedMessage id="lpSensors.force.type" />
                                        <FormControl
                                            data-cy="ipCanDevices-lpSensor-forceType"
                                            name="forceType"
                                            accepter={SelectPicker}
                                            data={this.forceValues}
                                            searchable={false}
                                            cleanable={false}
                                            renderMenuItem={(label, item) => {
                                                return (
                                                    <div data-cy={`editLpSensor-force-value-${item.value}`}>
                                                        {label}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </ControlLabel>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <ControlLabel>
                                        <FormattedMessage id="lpSensors.force.endForceTime" />
                                        <FormControl
                                            data-cy="ipCanDevices-lpSensor-endForceTime"
                                            name="endForceTime"
                                            accepter={DatePicker}
                                            data={this.forceValues}
                                            format="DD/MM/YYYY HH:mm:ss"
                                            cleanable={false}
                                        />
                                    </ControlLabel>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        )}

                        {this.state.formValue.forceType === 10 && (
                            <FlexboxGrid justify="center" align="middle" className="margin-top-10">
                                <FlexboxGrid.Item>
                                    <ControlLabel>
                                        <FormattedMessage id="lpSensors.force.color" />
                                        <ChromePicker
                                            disableAlpha={true}
                                            onChange={this.handleColorChange}
                                            color={this.state.color}
                                        />
                                    </ControlLabel>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        )}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            data-cy="ipCanDevices-lpSensor-force-cancel"
                            onClick={() => this.closeModal()}
                            color="red"
                            disabled={this.state.isLoading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            data-cy="ipCanDevices-lpSensor-force-valid"
                            onClick={() => this.sendForce()}
                            appearance="primary"
                            loading={this.state.isLoading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default injectIntl(ForceLPSensorModal);
