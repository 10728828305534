export default class LPDisplayBaseConfig {
    private _brightness: number;
    private _gmt: number;
    private _nbPixelX: number;
    private _nbPixelY: number;
    private _origin: number;
    private _typeMatrix: number;

    constructor(baseConfig_: LPDisplayBaseConfigInterface) {
        this._brightness = baseConfig_.brightness;
        this._gmt = baseConfig_.gmt;
        this._nbPixelX = baseConfig_.nbPixelX;
        this._nbPixelY = baseConfig_.nbPixelY;
        this._origin = baseConfig_.origin;
        this._typeMatrix = baseConfig_.typeMatrix;
    }

    public getBrightness(): number {
        return this._brightness;
    }

    public getGmt(): number {
        return this._gmt;
    }

    public getNbPixelX(): number {
        return this._nbPixelX;
    }

    public getNbPixelY(): number {
        return this._nbPixelY;
    }

    public getOrigin(): number {
        return this._origin;
    }

    public getTypeMatrix(): number {
        return this._typeMatrix;
    }

    public getNbMatrixX(): number {
        return this._nbPixelX / 32;
    }

    public getNbMatrixY(): number {
        return this._nbPixelY / 16;
    }

    private setBrightness(brightness_: number): boolean {
        if (this._brightness !== brightness_) {
            this._brightness = brightness_;
            return true;
        }

        return false;
    }

    private setNbPixelX(nbPixelX_: number): boolean {
        if (this._nbPixelX !== nbPixelX_) {
            this._nbPixelX = nbPixelX_;
            return true;
        }

        return false;
    }

    private setNbPixelY(nbPixelY_: number): boolean {
        if (this._nbPixelY !== nbPixelY_) {
            this._nbPixelY = nbPixelY_;
            return true;
        }

        return false;
    }

    private setGMT(gmt_: number): boolean {
        if (this._gmt !== gmt_) {
            this._gmt = gmt_;
            return true;
        }

        return false;
    }

    private setOrigin(origin_: number): boolean {
        if (this._origin !== origin_) {
            this._origin = origin_;
            return true;
        }

        return false;
    }

    private setTypeMatrix(typeMatrix_: number): boolean {
        if (this._typeMatrix !== typeMatrix_) {
            this._typeMatrix = typeMatrix_;
            return true;
        }

        return false;
    }

    public updateBaseConfig(baseConfig_: LPDisplayBaseConfigInterface) {
        this._brightness = baseConfig_.brightness;
        this._nbPixelX = baseConfig_.nbPixelX;
        this._nbPixelY = baseConfig_.nbPixelY;
        this._gmt = baseConfig_.gmt;
        this._origin = baseConfig_.origin;
        this._typeMatrix = baseConfig_.typeMatrix;
    }

    public webSocketUpdateBaseConfig(baseConfig_: LPDisplayBaseConfigInterface): boolean {
        let nbElementsUpdated = 0;

        if (this.setBrightness(baseConfig_.brightness)) nbElementsUpdated++;
        if (this.setNbPixelX(baseConfig_.nbPixelX)) nbElementsUpdated++;
        if (this.setNbPixelY(baseConfig_.nbPixelY)) nbElementsUpdated++;
        if (this.setGMT(baseConfig_.gmt)) nbElementsUpdated++;
        if (this.setOrigin(baseConfig_.origin)) nbElementsUpdated++;
        if (this.setTypeMatrix(baseConfig_.typeMatrix)) nbElementsUpdated++;

        return nbElementsUpdated > 0;
    }
}

export interface LPDisplayBaseConfigInterface {
    brightness: number;
    gmt: number;
    nbPixelX: number;
    nbPixelY: number;
    origin: number;
    typeMatrix: number;
}
