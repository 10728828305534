import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Drawer, FlexboxGrid, Popover, Whisper } from 'rsuite';
import { ipCanHandler } from '../../../../handlers/ipcan';
import { authHeader } from '../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../redux/services';
import ActivityTable from './Components/ActivityTable';

type Props = {
    show: boolean;
    close: Function;
    levelId: number;
};

type State = {
    activities: Array<Record<string, any>>;
    controllers: Array<Record<string, any>>;
    error: boolean;
    loading: boolean;
};
export default class ActivityLog extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            activities: [],
            controllers: [],
            error: false,
            loading: false,
        };

        this.sensorEvent = this.sensorEvent.bind(this);
    }

    componentDidMount() {
        webSocketService.joinRoom('engine');

        this.setState({
            loading: true,
        });

        axiosService
            .getAxios()
            .get('/ipcanmodules', { headers: authHeader() })
            .then(ipcanResponse => {
                this.setState({
                    controllers: ipcanResponse.data.map(ipcan => ipCanHandler(ipcan)),
                });

                axiosService
                    .getAxios()
                    .get(`/engine/sensorsHistoric/level/${this.props.levelId}`, { headers: authHeader() })
                    .then(historyResponse => {
                        let data = historyResponse.data.reverse();
                        let activities = data.map((d, index) => {
                            return { ...d, date: moment(d.date).format('DD/MM/YYYY HH:mm:ss'), key: `${index}` };
                        });
                        this.setState({
                            activities,
                            loading: false,
                        });
                    });
            })
            .catch(err => {
                console.error(err);

                this.setState({
                    loading: false,
                    error: true,
                });
            });

        webSocketService.onEvent('engine:sensorEvent', this.sensorEvent);
    }

    componentWillUnmount() {
        webSocketService.offEvent('engine:sensorEvent', this.sensorEvent);
    }

    sensorEvent(data) {
        let activities = this.state.activities;
        const newActivity = {
            ...data,
            date: moment(data.date).format('DD/MM/YYYY HH:mm:ss'),
            key: activities.length,
        };

        activities.unshift(newActivity);

        this.setState({
            activities,
        });
    }

    render() {
        return (
            <Drawer
                className="drawer-body-without-avatar"
                size="md"
                backdrop={false}
                show={this.props.show}
                onHide={() => this.props.close()}>
                <Drawer.Header>
                    <Drawer.Title>
                        <FormattedMessage id="map.activity.list" />
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body style={{ height: '95vh' }}>
                    <FlexboxGrid align="middle" className="margin-bottom-5">
                        {this.state.controllers.map(controller => {
                            return (
                                <Whisper
                                    key={controller.id}
                                    trigger="hover"
                                    placement={'auto'}
                                    speaker={
                                        <Speaker
                                            label={controller.label}
                                            macAddress={controller.macStr}
                                            lastOnlineStateDate={controller.lastOnlineStateDate}
                                        />
                                    }>
                                    <FlexboxGrid.Item
                                        style={{
                                            padding: 5,
                                            textAlign: 'center',
                                            backgroundColor: controller.online ? '#008000' : '#f44336',
                                            color: 'white',
                                        }}
                                        componentClass={Col}
                                        xs={4}>
                                        {controller.label}
                                    </FlexboxGrid.Item>
                                </Whisper>
                            );
                        })}
                    </FlexboxGrid>

                    <ActivityTable levelId={this.props.levelId} />
                </Drawer.Body>
            </Drawer>
        );
    }
}

const Speaker = ({ ...props }) => {
    return (
        <Popover title={props.label} {...props}>
            <FormattedMessage id="activityLog.controller.macAddress" /> : {props.macAddress}
            <br />
            <FormattedMessage id="activityLog.controller.lastOnlineStateDate" /> : {props.lastOnlineStateDate}
        </Popover>
    );
};
