import { faCheck, faPlug, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, CheckPicker, ControlLabel, Form, FormControl, FormGroup, Modal } from 'rsuite';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    handleSaveToManyPicto: (displays: number[]) => void;
};

type Data = {
    label: string;
    value: string;
    online: boolean;
};

type FormValue = {
    displays: number[];
};

export const SendPictoToManyDisplayModal = (props: Props) => {
    const [displays, setDisplays] = React.useState<Data[]>([]);

    const [formValue, setFormValue] = React.useState<FormValue>({
        displays: [],
    });

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/ipcanmodules', {
                headers: authHeader(),
            })
            .then(ipcanModuleResponse => {
                const ipcans = ipcanModuleResponse.data.map(ipcan => new IpCan(ipcan));
                axiosService
                    .getAxios()
                    .get('/devices/lp-matrixdisplays-v2', {
                        headers: authHeader(),
                    })
                    .then(lpMatrixDisplayResponse => {
                        const displaysData: Data[] = [];

                        lpMatrixDisplayResponse.data.forEach(display => {
                            const ipcan = ipcans.find(ipcan => ipcan.id === display.ipcanmodule.id);

                            if (ipcan) {
                                displaysData.push({
                                    label: `${ipcan.label} (${ipcan.macStr}) - ${display.bus + 1} - ${
                                        display.deviceId
                                    }`,
                                    value: display.id,
                                    online: display.online,
                                });
                            }
                        });

                        setDisplays(displaysData);
                    });
            });
    }, []);

    const handleClickSave = () => {
        props.handleSaveToManyPicto(formValue.displays);
        props.onHide();
    };

    return (
        <Modal show={props.isOpen} onHide={() => props.onHide()}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="editPictoRGB.sendPictoRGBToMany.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="editPictoRGB.sendPictoRGBToMany.message" />

                <Form
                    fluid
                    className="margin-top-10"
                    onChange={value => setFormValue(value as FormValue)}
                    formValue={formValue}>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="editPictoRGB.sendPictoRGBToMany.displays" />
                        </ControlLabel>
                        <FormControl
                            data-cy="sendPictoRGBToMany-selector"
                            accepter={CheckPicker}
                            data={displays}
                            searchable={false}
                            name="displays"
                            cleanable={false}
                            disabledItemValues={displays
                                .filter(display => !display.online)
                                .map(display => display.value)}
                            renderMenuItem={(label, item) => {
                                return (
                                    <div>
                                        <FontAwesomeIcon
                                            icon={faPlug}
                                            color={item.online ? 'green' : 'red'}
                                            className="margin-right-5"
                                        />
                                        {label}
                                    </div>
                                );
                            }}
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={() => props.onHide()} color="red">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={() => handleClickSave()}
                        type="submit"
                        appearance="primary"
                        disabled={formValue.displays.length === 0}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
