import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, FlexboxGrid, Message } from 'rsuite';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';

type Props = {
    closeModal: Function;
};

type State = {
    error: boolean;
    loading: boolean;
};

export class Reboot extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
        };

        this.restartAutomaton = this.restartAutomaton.bind(this);
    }

    restartAutomaton() {
        this.setState({
            loading: true,
            error: false,
        });

        axiosService
            .getAxios()
            .put('/configuration/rebootAutomate', {}, { headers: authHeader() })
            .then(() => {
                this.setState({
                    loading: false,
                });
                this.props.closeModal(true);
            })
            .catch(() => {
                this.setState({
                    error: true,
                    loading: false,
                });
            });
    }

    render() {
        return (
            <Fragment>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item componentClass={Col} className="margin-top-15">
                        <FormattedMessage id="update.manual.description" />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                {this.state.error && (
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} xs={24} className="margin-top-15">
                            <Message type="error" title={<FormattedMessage id="update.manual.error" />} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item componentClass={Col} className="margin-top-15">
                        <Button onClick={this.restartAutomaton} color="red" loading={this.state.loading}>
                            <FontAwesomeIcon icon={faPowerOff} />
                            <FormattedMessage id="update.manual.restartNow" />
                        </Button>
                        <Button onClick={() => this.props.closeModal(false)} appearance="link">
                            <FormattedMessage id="update.manual.restartLater" />
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Fragment>
        );
    }
}
