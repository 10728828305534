import moment from 'moment';
import { Profile, ProfileInterface } from './Profile';
import { RestFull, RestFullInterface } from './RestFull';
import { Role } from './Role';

export class User {
    private id: number;
    private firstName: string;
    private lastName: string;
    private enabled: boolean;
    private email: string;
    private profiles: Profile[];
    private roles: Role[];
    private createdAt: Date;
    private createdBy: string;
    private updatedAt: Date;
    private updatedBy: string;
    private loggedIn: boolean;
    private lastLogin?: Date;
    private restFulls: RestFull[];
    private parkingList: number[];

    constructor(user: UserInterface) {
        this.id = user.id;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.enabled = user.enabled;
        this.email = user.email;
        this.profiles = user.profiles?.map(profile => new Profile(profile)) || [];
        this.createdAt = user.createdAt;
        this.createdBy = user.createdBy;
        this.updatedAt = user.updatedAt;
        this.updatedBy = user.updatedBy;
        this.loggedIn = user.loggedIn;
        this.lastLogin = user.lastLogin;
        this.restFulls = user.restFulls?.map(restFull => new RestFull(restFull)) || [];
        this.parkingList = user.parkingList || [];

        const roles: any[] = [];

        user.profiles?.forEach(profile => {
            profile.roles?.forEach(role => {
                roles.push(new Role(role));
            });
        });

        this.roles = roles;
    }

    public getId(): number {
        return this.id;
    }

    public getFirstName(): string {
        return this.firstName;
    }

    public getLastName(): string {
        return this.lastName;
    }

    public getEnabled(): boolean {
        return this.enabled;
    }

    public getEmail(): string {
        return this.email;
    }

    public getProfiles(): Profile[] {
        return this.profiles;
    }

    public getRoles(): Role[] {
        return this.roles;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public getCreatedBy(): string {
        return this.createdBy;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public getUpdatedBy(): string {
        return this.updatedBy;
    }

    public getLoggedIn(): boolean {
        return this.loggedIn;
    }

    public getLastLogin(): string {
        return this.lastLogin ? moment(this.lastLogin).format('DD/MM/YYYY HH:mm:ss') : '-';
    }

    public getRestFulls(): RestFull[] {
        return this.restFulls;
    }

    public getParkingList(): number[] {
        return this.parkingList;
    }

    public updateUser(user: UserInterface): void {
        this.id = user.id;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.enabled = user.enabled;
        this.email = user.email;
        this.createdAt = user.createdAt;
        this.createdBy = user.createdBy;
        this.updatedAt = user.updatedAt;
        this.updatedBy = user.updatedBy;
        this.loggedIn = user.loggedIn;

        if (user.lastLogin) {
            this.lastLogin = user.lastLogin;
        }

        if (user.profiles) {
            this.profiles = user.profiles.map(profile => new Profile(profile));

            const roles: any[] = [];

            user.profiles?.forEach(profile => {
                profile.roles?.forEach(role => {
                    roles.push(new Role(role));
                });
            });

            this.roles = roles;
        }
    }

    public updateRestFulls(restFulls: RestFullInterface[]): void {
        this.restFulls = restFulls.map(restFull => new RestFull(restFull));
    }
}

export interface UserInterface {
    id: number;
    firstName: string;
    lastName: string;
    enabled: boolean;
    email: string;
    profiles?: ProfileInterface[];
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    loggedIn: boolean;
    lastLogin?: Date;
    restFulls: RestFullInterface[];
    parkingList?: number[];
}
