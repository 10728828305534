import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Tag, TagGroup } from 'rsuite';

const type = {
    1: {
        text: 'Generic',
        color: 'green',
    },
    2: {
        text: 'PMR',
        color: 'blue',
    },
    4: {
        text: 'Electrical',
        color: 'cyan',
    },
    8: {
        text: 'Family',
        color: 'violet',
    },
};

type Props = {
    value: number;
    noStyle?: boolean;
    history?: boolean;
    noTag?: boolean;
};

type State = {
    types: Array<Record<string, any>>;
};

export default class Types extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            types: [],
        };

        this.updateValue = this.updateValue.bind(this);
    }

    componentDidMount() {
        this.updateValue();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.updateValue();
        }
    }

    updateValue() {
        let types: Array<Record<string, any>> = [];
        let v = this.props.value;

        for (let i = 1; i <= 8; i <<= 1) {
            if ((v & i) !== 0) {
                types.push(type[i]);
            }
        }

        this.setState({
            types,
        });
    }

    render() {
        if (this.state.types.length === 0) {
            return (
                <FontAwesomeIcon
                    data-cy="display-no-types"
                    data-tag="allowRowEvents"
                    style={{ lineHeight: this.props.noStyle ? 1 : 1.4 }}
                    size={this.props.history ? '1x' : '2x'}
                    icon={faTimesCircle}
                    color="red"
                />
            );
        } else if (this.props.noTag) {
            return this.state.types.map(type => {
                return (
                    <span data-tag="allowRowEvents" className="margin-right-5" key={type.text}>
                        {type.text}
                    </span>
                );
            });
        } else {
            return (
                <TagGroup data-tag="allowRowEvents">
                    {this.state.types.map(type => {
                        return (
                            <Tag data-cy="display-types" data-tag="allowRowEvents" key={type.text} color={type.color}>
                                <span data-tag="allowRowEvents">{type.text}</span>
                            </Tag>
                        );
                    })}
                </TagGroup>
            );
        }
    }
}
