import { faCheck, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Col, ControlLabel, FlexboxGrid, Form, FormControl, Panel } from 'rsuite';
import { placeTypeActions } from '../../../../redux/actions';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';

const mapDispatchToProps = dispatch => ({
    update: (id, label) => dispatch(placeTypeActions.update(id, label)),
    updateImage: (id, image) => dispatch(placeTypeActions.updateImage(id, image)),
});

type Props = {
    placeType: Record<string, any>;
    update: Function;
    updateImage: Function;
    isLoading: boolean;
} & WrappedComponentProps;

type State = {
    editMode: boolean;
    loading: boolean;
    formValue: Record<string, any>;
};
class PlaceTypeInformations extends React.Component<Props, State> {
    fileInput: any;
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            loading: false,
            formValue: {
                label: this.props.placeType.label,
                createdAt: this.props.placeType.createdAt,
                createdBy: this.props.placeType.createdBy,
                updatedAt: this.props.placeType.updatedAt,
                updatedBy: this.props.placeType.updatedBy,
            },
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.unsetEditMode = this.unsetEditMode.bind(this);
        this.cancelEditMode = this.cancelEditMode.bind(this);
        this.setFormValue = this.setFormValue.bind(this);
        this.updatePlaceType = this.updatePlaceType.bind(this);
        this.updateImage = this.updateImage.bind(this);
        this.fileInput = React.createRef();
    }

    setEditMode() {
        this.setState({
            editMode: true,
        });
    }

    cancelEditMode() {
        this.setState({
            editMode: false,
            formValue: {
                label: this.props.placeType.label,
                createdAt: this.props.placeType.createdAt,
                createdBy: this.props.placeType.createdBy,
                updatedAt: this.props.placeType.updatedAt,
                updatedBy: this.props.placeType.updatedBy,
            },
        });
    }

    unsetEditMode() {
        this.setState({
            editMode: false,
        });
    }

    updatePlaceType() {
        this.unsetEditMode();
        this.props.update(this.props.placeType.id, this.state.formValue.label);
    }

    updateImage() {
        this.unsetEditMode();
        this.props.updateImage(this.props.placeType.id, this.fileInput.current.files[0]);
    }

    setFormValue(formValue) {
        this.setState({
            formValue,
        });
    }

    render() {
        return (
            <Loader show={this.props.isLoading}>
                <Panel
                    shaded
                    bordered
                    className="panel-small"
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'placeType.drawer.information' })}
                            buttons={[
                                <SecuredFragment
                                    key="placeType.edit"
                                    authorizedRoles={[rolesConstants.placeType.UPDATE]}>
                                    <Button
                                        data-cy="placeType-editButton"
                                        onClick={this.setEditMode}
                                        appearance="primary"
                                        size="sm">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }>
                    <Form
                        formValue={this.state.formValue}
                        onChange={formValue => this.setFormValue(formValue)}
                        fluid
                        style={{ marginTop: 10 }}>
                        <FlexboxGrid align="middle" justify="end">
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="placeType.edit.label" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="edit-label"
                                    size="sm"
                                    name="label"
                                    plaintext={!this.state.editMode}
                                />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="placeType.edit.createdAt" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl size="sm" name="createdAt" plaintext={true} />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="placeType.edit.createdBy" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl size="sm" name="createdBy" plaintext={true} />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="placeType.edit.updatedAt" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl size="sm" name="updatedAt" plaintext={true} />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <ControlLabel className="form-control-label">
                                    <FormattedMessage id="placeType.edit.updatedBy" />
                                </ControlLabel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl size="sm" name="updatedBy" plaintext={true} />
                            </FlexboxGrid.Item>
                            {this.state.editMode && (
                                <FlexboxGrid.Item>
                                    <ButtonGroup>
                                        <Button
                                            data-cy="placeType-edit-cancel"
                                            onClick={this.cancelEditMode}
                                            disabled={this.state.loading}
                                            color="red">
                                            <FontAwesomeIcon icon={faTimesCircle} />
                                        </Button>
                                        <Button
                                            data-cy="placeType-edit-valid"
                                            color="green"
                                            onClick={this.updatePlaceType}
                                            loading={this.state.loading}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </Button>
                                    </ButtonGroup>
                                </FlexboxGrid.Item>
                            )}
                        </FlexboxGrid>
                        {this.state.editMode && (
                            <FlexboxGrid align="middle" justify="end">
                                <FlexboxGrid.Item componentClass={Col} xs={24}>
                                    <hr />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <ControlLabel className="form-control-label">
                                        <FormattedMessage id="placeType.edit.image" />
                                    </ControlLabel>
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item componentClass={Col} xs={12}>
                                    <input type="file" accept="image/*" ref={this.fileInput} />
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item>
                                    <Button
                                        loading={this.state.loading}
                                        style={{ marginTop: 8 }}
                                        data-cy="placeType-edit-image-valid"
                                        color="green"
                                        onClick={this.updateImage}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        )}
                    </Form>
                </Panel>
            </Loader>
        );
    }
}

function mapStateToProps(state) {
    let { isLoading } = state.placeTypes;

    return { isLoading };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PlaceTypeInformations));
