export default class LPDisplayInfoConfig {
    private _isEnable: boolean;
    private _softwareVersion: string;
    private _manufacturer: string;
    private _modelName: string;

    constructor(infoConfig_: LPDisplayInfoConfigInterface) {
        this._isEnable = infoConfig_.isEnable;
        this._softwareVersion = infoConfig_.softwareVersion;
        this._manufacturer = infoConfig_.manufacturer;
        this._modelName = infoConfig_.modelName;
    }

    public getIsEnable(): boolean {
        return this._isEnable;
    }

    public getSoftwareVersion(): string {
        return this._softwareVersion;
    }

    public getManufacturer(): string {
        return this._manufacturer;
    }

    public getModelName(): string {
        return this._modelName;
    }
}

export interface LPDisplayInfoConfigInterface {
    isEnable: boolean;
    softwareVersion: string;
    manufacturer: string;
    modelName: string;
}
