import moment from 'moment';
import { SensorPlaceType } from '../sensorPlaceType/SensorPlaceType';
import { VehicleCounterValue } from './VehicleCounterValue';

export class VehicleCounter {
    private _countingOnLevel: boolean;
    private _createdAt: string;
    private _createdBy: string;
    private _id: number;
    private _isForce: boolean;
    private _isResettable: boolean;
    private _name: string;
    private _nbTotPlace: number;
    private _resetTime: number;
    private _updatedAt: string;
    private _updatedBy: string;
    private _value: VehicleCounterValue;
    private _valueIn: VehicleCounterValue;
    private _valueOut: VehicleCounterValue;
    private _sensorPlaceType: SensorPlaceType;
    private _counter: VehicleCounter_CounterInterface;

    constructor(vehicleCounter_) {
        const {
            countingOnLevel,
            createdAt,
            createdBy,
            id,
            isForce,
            isResettable,
            name,
            nbTotPlace,
            resetTime,
            updatedAt,
            updatedBy,
            value,
            valueIn,
            valueOut,
            sensorPlaceType,
            counter,
        } = vehicleCounter_;

        this._countingOnLevel = countingOnLevel;
        this._createdAt = createdAt;
        this._createdBy = createdBy;
        this._id = id;
        this._isForce = isForce;
        this._isResettable = isResettable;
        this._name = name;
        this._nbTotPlace = nbTotPlace;
        this._resetTime = resetTime;
        this._updatedAt = updatedAt;
        this._updatedBy = updatedBy;
        this._value = new VehicleCounterValue(value);
        this._valueIn = new VehicleCounterValue(valueIn);
        this._valueOut = new VehicleCounterValue(valueOut);
        this._sensorPlaceType = new SensorPlaceType(sensorPlaceType);
        this._counter = counter;
    }

    public getCountingOnLevel() {
        return this._countingOnLevel;
    }

    public getCreatedAt() {
        return moment(this._createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreatedBy() {
        return this._createdBy;
    }

    public getId() {
        return this._id;
    }

    public getIsForce() {
        return this._isForce;
    }

    public getIsResettable() {
        return this._isResettable;
    }

    public getName() {
        return this._name;
    }

    public getNbTotPlace() {
        return this._nbTotPlace;
    }

    public getResetTime() {
        return this._resetTime;
    }

    public getUpdatedAt() {
        return moment(this._resetTime).format('DD/MM/YYYY HH:mm:ss');
    }

    public getUpdatedBy() {
        return this._updatedBy;
    }

    public getValue() {
        return this._value;
    }

    public getValueIn() {
        return this._valueIn;
    }

    public getValueOut() {
        return this._valueOut;
    }

    public getSensorPlaceType() {
        return this._sensorPlaceType;
    }

    public getCounter() {
        return this._counter;
    }

    private setUpdatedAt(updatedAt_) {
        if (updatedAt_ !== this._updatedAt) {
            this._updatedAt = updatedAt_;
        }
    }

    private setUpdatedBy(updatedBy_) {
        if (updatedBy_ !== this._updatedBy) {
            this._updatedBy = updatedBy_;
        }
    }

    private setName(name_) {
        if (name_ !== this._name) {
            this._name = name_;
        }
    }

    private setNbTotPlace(nbTotPlace_) {
        if (nbTotPlace_ !== this._nbTotPlace) {
            this._nbTotPlace = nbTotPlace_;
        }
    }

    private setResetTime(resetTime_) {
        if (resetTime_ !== this._resetTime) {
            this._resetTime = resetTime_;
        }
    }

    private setIsResettable(isResettable_) {
        if (isResettable_ !== this._isResettable) {
            this._isResettable = isResettable_;
        }
    }

    private setCountingOnLevel(countingOnLevel_) {
        if (countingOnLevel_ !== this._countingOnLevel) {
            this._countingOnLevel = countingOnLevel_;
        }
    }

    private setSensorPlaceType(sensorPlaceType_) {
        if (sensorPlaceType_.id !== this._sensorPlaceType.getId()) {
            this._sensorPlaceType = new SensorPlaceType(sensorPlaceType_);
        }
    }

    private setCounter(counter_) {
        this._counter = counter_;
    }

    public updateVehicleCounter(values: VehicleCounterInterface) {
        const {
            updatedAt,
            updatedBy,
            name,
            nbTotPlace,
            resetTime,
            isResettable,
            countingOnLevel,
            sensorPlaceType,
        } = values;

        this.setUpdatedAt(updatedAt);
        this.setUpdatedBy(updatedBy);
        this.setName(name);
        this.setNbTotPlace(nbTotPlace);
        this.setResetTime(resetTime);
        this.setIsResettable(isResettable);
        this.setCountingOnLevel(countingOnLevel);
        this.setSensorPlaceType(sensorPlaceType);
    }

    public updateVehicleCounterWebSocket(values: VehicleCounterWebSocketInterface) {
        const { name, nbTotPlace, resetTime, isResettable, countingOnLevel, value, valueIn, valueOut } = values;

        this.setName(name);
        this.setNbTotPlace(nbTotPlace);
        this.setResetTime(resetTime);
        this.setIsResettable(isResettable);
        this.setCountingOnLevel(countingOnLevel);
        this._value.setValue(value);
        this._valueIn.setValue(valueIn);
        this._valueOut.setValue(valueOut);
    }
}

export interface VehicleCounterInterface {
    id: number;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    name: string;
    nbTotPlace: number;
    valueIn: number;
    valueOut: number;
    value: number;
    resetTime: number;
    isForce: boolean;
    isResettable: boolean;
    countingOnLevel: boolean;
    sensorPlaceType: SensorPlaceType;
}

export interface VehicleCounterWebSocketInterface {
    countingOnLevel: boolean;
    id: number;
    isForce: boolean;
    isResettable: boolean;
    name: string;
    nbTotPlace: number;
    resetTime: number;
    valueIn: number;
    valueOut: number;
    value: number;
}

export interface VehicleCounter_CounterInterface {
    isShowOnProgressZone3: number;
}
