import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Alert, Button, List, Panel, Tag, Tooltip, Whisper } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { IPCanHybrid } from '../../../handlers/ipcan/IpCanHybrid';
import IpCanLP from '../../../handlers/ipcan/IpCanLP';
import IpCanLPMatrixDisplayV2 from '../../../handlers/ipcan/IpCanLPMatrixDisplayV2';
import IPCanTcm from '../../../handlers/ipcan/IpCanTCM';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import ElementTable from '../../ReactDataTableComponent/ElementTable';
import DestroyBus from '../Modal/DestroyBus';
import EnumBusModal from '../Modal/EnumBusModal';
import LPMatrixDisplayV2ActionsModal from '../Modal/LPMatrixDisplayV2ActionsModal';
import LPSensorsActionsModal from '../Modal/LPSensorsActionsModal';
import TCMBusActionsModal from '../Modal/TCMBusActionsModal';
import busInformationsButton from '../Table/busInformationsButton';
import LPMatrixDisplayV2_busInformation from '../Table/LPMatrixDisplayV2_busInformation';
import nbElements from '../Table/nbElements';
import { Background } from 'react-flow-renderer';

type Props = {
    ipcanModule: IpCan;
    createNotification?: Function;
    getDbValue?: Function;
} & WrappedComponentProps;

type State = {
    redirect: boolean;
    redirectTo: string;
    enumerationModalOpen: boolean;
    enumerationBusNumber: number | undefined;
    showMoreActionsModal: boolean;
    moreActionsBus: number;
    moreActionsIpCan: Record<string, any>;
    showDeleteDevicesModal: boolean;
    deleteDevicesBusNumber: number;
    deleteDevicesIpcanId: number;
};

class Bus extends React.Component<Props, State> {
    columns: Array<TableColumn>;
    constructor(props: Props) {
        super(props);

        this.state = {
            redirect: false,
            redirectTo: '',
            enumerationModalOpen: false,
            enumerationBusNumber: undefined,
            showMoreActionsModal: false,
            moreActionsBus: 0,
            moreActionsIpCan: {},
            showDeleteDevicesModal: false,
            deleteDevicesBusNumber: 0,
            deleteDevicesIpcanId: -1,
        };

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.busInformations.name',
                }),
                sortable: true,
                cell: row => <div data-cy="controllers-bus-name">{row.name}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.busInformations.devicesOnBus',
                }),
                sortable: true,
                cell: row => nbElements(props.ipcanModule.getOnline(), row.devicesOnBus, true),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.busInformations.errors',
                }),
                sortable: true,
                cell: row => {
                    if (row.errors > 0) {
                        return (
                            <Whisper
                                trigger='hover'
                                speaker={
                                    <Tooltip>
                                        <Fragment>
                                            {Object.entries(row.errorType).map(([key, value]) => {
                                                return (<div>{key}: {value}</div>)
                                            })}
                                        </Fragment>
                                    </Tooltip>
                                }>
                                <Tag color="orange">{row.errors}</Tag>
                            </Whisper>
                        );
                    }
                    return <div data-cy="controllers-nbErrors">{row.errors}</div>;
                },
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'controller.busInformations.actions',
                }),
                cell: row => {
                    if (this.props.ipcanModule instanceof IpCanLP && row.bus === 2) {
                        return (
                            <SecuredFragment
                                key="informations.deleteBus"
                                authorizedRoles={[rolesConstants.controller.DELETE_BUS]}>
                                <Whisper
                                    trigger="hover"
                                    placement="top"
                                    speaker={
                                        <Tooltip>
                                            <FormattedMessage id="controller.busInformations.actions.destroyBus" />
                                        </Tooltip>
                                    }>
                                    <Button
                                        data-cy="ipcan-element-bus-destroy"
                                        color="red"
                                        size="xs"
                                        onClick={() => this.buttonItemClicked(4, row.bus, this.props.ipcanModule)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </Whisper>
                            </SecuredFragment>
                        );
                    } else if (this.props.ipcanModule instanceof IpCanLPMatrixDisplayV2 && row.bus === 2) {
                        return LPMatrixDisplayV2_busInformation(
                            this.buttonItemClicked,
                            row.bus,
                            row.ipcanId,
                            this.props.ipcanModule.getOnline()
                        );
                    } else {
                        return busInformationsButton(
                            this.buttonItemClicked,
                            row.bus,
                            row.ipcanId,
                            this.props.ipcanModule.getOnline()
                        );
                    }
                },
            },
        ];

        this.buttonItemClicked = this.buttonItemClicked.bind(this);
        this.hideEnumerationModal = this.hideEnumerationModal.bind(this);
        this.hideMoreActionsModal = this.hideMoreActionsModal.bind(this);
        this.hideDeleteDevicesModal = this.hideDeleteDevicesModal.bind(this);
    }

    hideMoreActionsModal() {
        this.setState({
            showMoreActionsModal: false,
        });
    }

    hideDeleteDevicesModal() {
        this.setState({
            showDeleteDevicesModal: false,
        });
    }

    buttonItemClicked(eventkey, bus, ipCan) {
        switch (eventkey) {
            case 1:
                this.setState({
                    enumerationModalOpen: true,
                    enumerationBusNumber: bus,
                });
                break;
            case 2:
                axiosService
                    .getAxios()
                    .put(
                        '/ipcanmodules/resetBusCounterError',
                        {
                            id: ipCan,
                            bus,
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(response => {
                        this.props.ipcanModule.resetBusCounterError(bus);

                        Alert.success(
                            this.props.intl.formatMessage({
                                id: 'controller.busInformations.resetBusCounterError.success',
                            })
                        );
                    })
                    .catch(err => {
                        console.error(err);

                        Alert.error(
                            this.props.intl.formatMessage({
                                id: 'controller.busInformations.resetBusCounterError.error',
                            })
                        );
                    });

                break;
            case 3:
                this.setState({
                    redirectTo: `/ipCan/${ipCan}/bus/${bus}`,
                    redirect: true,
                });
                break;
            case 4:
                this.setState({
                    showDeleteDevicesModal: true,
                    deleteDevicesBusNumber: bus,
                    deleteDevicesIpcanId: this.props.ipcanModule.getId(),
                });
                break;
            case 5:
                this.setState({
                    showMoreActionsModal: true,
                    moreActionsBus: bus,
                    moreActionsIpCan: this.props.ipcanModule,
                });
                break;
            default:
                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'controller.busInformations.command.commandNotFound',
                    })
                );
                break;
        }
    }

    hideEnumerationModal() {
        this.setState({
            enumerationModalOpen: false,
            enumerationBusNumber: undefined,
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.redirectTo} />;
        } else {
            const shouldShowMatrixDisplaysV2MoreActions =
                this.props.ipcanModule instanceof IpCanLPMatrixDisplayV2 && this.state.moreActionsBus === 2;
            return (
                <Fragment>
                    {typeof this.state.enumerationBusNumber !== 'undefined' && (
                        <EnumBusModal
                            show={this.state.enumerationModalOpen}
                            onHide={this.hideEnumerationModal}
                            bus={this.state.enumerationBusNumber}
                            ipCan={this.props.ipcanModule}
                        />
                    )}

                    {typeof this.state.moreActionsBus !== 'undefined' && (
                        <Fragment>
                            {(this.props.ipcanModule instanceof IPCanHybrid ||
                                this.props.ipcanModule instanceof IpCanLP ||
                                (this.props.ipcanModule instanceof IpCanLPMatrixDisplayV2 &&
                                    this.state.moreActionsBus !== 2)) && (
                                <LPSensorsActionsModal
                                    show={this.state.showMoreActionsModal}
                                    onHide={this.hideMoreActionsModal}
                                    bus={this.state.moreActionsBus}
                                    ipCan={this.props.ipcanModule}
                                />
                            )}

                            {this.props.ipcanModule instanceof IPCanTcm && (
                                <TCMBusActionsModal
                                    show={this.state.showMoreActionsModal}
                                    onHide={this.hideMoreActionsModal}
                                    bus={this.state.moreActionsBus}
                                    ipCan={this.props.ipcanModule}
                                />
                            )}

                            {shouldShowMatrixDisplaysV2MoreActions && (
                                <LPMatrixDisplayV2ActionsModal
                                    show={this.state.showMoreActionsModal}
                                    onHide={this.hideMoreActionsModal}
                                    bus={this.state.moreActionsBus}
                                    ipCan={this.props.ipcanModule}
                                />
                            )}
                        </Fragment>
                    )}

                    {typeof this.state.deleteDevicesBusNumber !== 'undefined' && (
                        <DestroyBus
                            show={this.state.showDeleteDevicesModal}
                            onHide={this.hideDeleteDevicesModal}
                            ipCan={this.props.ipcanModule}
                            bus={this.state.deleteDevicesBusNumber}
                            createNotification={() => this.props.createNotification && this.props.createNotification()}
                            getDbValue={() => this.props.getDbValue && this.props.getDbValue()}
                        />
                    )}

                    <Panel
                        shaded
                        bodyFill
                        bordered
                        className="panel-small"
                        data-cy="ipcan-bus-informations"
                        header={<FormattedMessage id="controller.busInformations.title" />}>
                        <ElementTable small columns={this.columns} data={this.props.ipcanModule.getBusInformations()} />
                    </Panel>
                </Fragment>
            );
        }
    }
}

export default injectIntl(Bus);
