import { Blob } from 'blob';
import { LatLngExpression } from 'leaflet';
import moment from 'moment';
import { MaintenanceInformation } from '../MaintenanceInformation';
import { IPCameraAPIResponse } from './ApiCamera';
import { HyperParametersCameraConfig } from './HyperParametersCameraConfig';
import PlaceCamera from './PlaceCamera';

export default class Camera extends MaintenanceInformation {
    private _id: number;
    private _ip: string;
    private _port: number;
    private _macStr: string;
    private _mac: number;
    private _url: string;
    private _errorFlag: CameraErrorFlag;
    private _online: boolean;
    private _image?: Blob;
    private _imageType: string;
    private _imageDate: Date;
    private _name: string;
    private _deviceId: number;
    private _firmware: string;
    private _hardware: string;
    private _login: string;
    private _password: string;
    private _lastOnlineStateDate: Date;
    private _createdAt: Date;
    private _createdBy: string;
    private _updatedAt: Date;
    private _updatedBy: string;
    private _placesCamera: PlaceCamera[] = [];
    private _config: HyperParametersCameraConfig;

    constructor(camera: IPCameraAPIResponse) {
        super(camera.maintenanceState, camera.notepad);

        this._id = camera.id;
        this._ip = camera.ip;
        this._port = camera.port;
        this._macStr = camera.macStr;
        this._mac = camera.mac;
        this._url = camera.url;
        this._errorFlag = camera.errorFlag;
        this._online = camera.online;
        this._image = camera.image;
        this._imageType = camera.imageType;
        this._imageDate = camera.imageDate;
        this._name = camera.name;
        this._deviceId = camera.deviceId;
        this._firmware = camera.firmware;
        this._hardware = camera.hardware;
        this._login = camera.login;
        this._password = camera.password;
        this._lastOnlineStateDate = camera.lastOnlineStateDate;
        this._createdAt = camera.createdAt;
        this._createdBy = camera.createdBy;
        this._updatedAt = camera.updatedAt;
        this._updatedBy = camera.updatedBy;
        this._placesCamera = camera.placesCamera ? camera.placesCamera.map(place => new PlaceCamera(place)) : [];
        this._config = new HyperParametersCameraConfig(camera.config);
    }

    public getId(): number {
        return this._id;
    }

    public getIp(): string {
        return this._ip;
    }

    public getMacStr(): string {
        return this._macStr;
    }

    public getMac(): number {
        return this._mac;
    }

    public getPort(): number {
        return this._port;
    }

    public getUrl(): string {
        return this._url;
    }

    public getErrorFlag(): CameraErrorFlag {
        return this._errorFlag;
    }

    public getOnline(): boolean {
        return this._online;
    }

    public getImage(): Blob | undefined {
        return this._image;
    }

    public getImageType(): string {
        return this._imageType;
    }

    public getImageDate(): string {
        return moment(this._imageDate).format('DD/MM/YYYY HH:mm:ss');
    }

    public getName(): string {
        return this._name;
    }

    public getDeviceId(): number {
        return this._deviceId;
    }

    public getFirmware(): string {
        return this._firmware;
    }

    public getHardware(): string {
        return this._hardware;
    }

    public getLogin(): string {
        return this._login;
    }

    public getPassword(): string {
        return this._password;
    }

    public getLastOnlineStateDate(): string {
        return moment(this._lastOnlineStateDate).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreatedAt(): Date {
        return this._createdAt;
    }

    public getCreatedBy(): string {
        return this._createdBy;
    }

    public getUpdatedAt(): Date {
        return this._updatedAt;
    }

    public getUpdatedBy(): string {
        return this._updatedBy;
    }

    public getCreation(): string {
        return `${this.getCreatedBy()} - ${moment(this.getCreatedAt()).format('DD/MM/YYYY HH:mm:ss')}`;
    }

    public getUpdate(): string {
        return `${this.getUpdatedBy()} - ${moment(this.getUpdatedAt()).format('DD/MM/YYYY HH:mm:ss')}`;
    }

    public getPlacesCamera(): PlaceCamera[] {
        return this._placesCamera;
    }

    public getConfig(): HyperParametersCameraConfig {
        return this._config;
    }

    public updateCamera(camera: IPCameraAPIResponse): void {
        this._name = camera.name;
        this._ip = camera.ip;
        this._port = camera.port;
        this._macStr = camera.macStr;
        this._url = camera.url;
        this._login = camera.login;
        this._password = camera.password;
    }

    public setImage(image: Blob): void {
        this._image = image;
    }

    public addPlaceCamera(placeCamera: PlaceCamera): void {
        this._placesCamera = [...this._placesCamera, placeCamera];
    }

    public deletePlacesCamera(placesId: number[]): void {
        this._placesCamera = this._placesCamera.filter(place => !placesId.includes(place.getId()));
    }

    public editPlacesCamera(places: { id: number; geoJSON: LatLngExpression }[]): void {
        for (const place of places) {
            const placeCamera = this._placesCamera.find(placeCamera => placeCamera.getId() === place.id);

            if (placeCamera) {
                placeCamera.setPosGeoJSON(place.geoJSON);
            }
        }
    }

    public editPlacesBoxCamera(places: { id: number; geoJSON: LatLngExpression[] }[]): void {
        for (const place of places) {
            const placeCamera = this._placesCamera.find(placeCamera => placeCamera.getId() === place.id);

            if (placeCamera) {
                placeCamera.setGeoJSON(place.geoJSON);
            }
        }
    }

    public updateCameraWS(data: IPCameraAPIResponse): void {
        this._errorFlag = data.errorFlag;
        this._ip = data.ip;
        this._lastOnlineStateDate = data.lastOnlineStateDate;
        this._login = data.login;
        this._mac = data.mac;
        this._macStr = data.macStr;
        this._name = data.name;
        this._online = data.online;
        this._password = data.password;
        this._port = data.port;
    }

    public updateHyperParameters(data: IPCameraAPIResponse): void {
        this._config = new HyperParametersCameraConfig(data.config);
    }
}

export enum CameraErrorFlag {
    OK = 0,
    BAD_CONFIG = 1,
    BAD_LOGIN = 2,
}
