import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Layer, Rect, Stage } from 'react-konva';
import { Alert, Button, FlexboxGrid, Modal } from 'rsuite';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { findLastIndex } from '../../../../utils/findLastIndex';
import { ActionObserver, ActionObserverEvent } from '../handlers/Observers/ActionObserver';

type Props = {
    display: LPDisplay;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isCreating: boolean;
    coordArray: CoordInterface[][];
};

interface CoordInterface {
    selected: boolean;
}

const PIXEL_X = 16;
const PIXEL_Y = 16;

class CreateVirtualDisplayModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isCreating: false,
            coordArray: this.generateCoordArray(),
        };
    }

    generateCoordArray = () => {
        let squareArray: CoordInterface[][] = [];

        for (let i = 0; i < this.props.display.getBaseConfig().getNbMatrixY(); i++) {
            let displays: CoordInterface[] = [];
            for (let j = 0; j < this.props.display.getBaseConfig().getNbMatrixX() * 2; j++) {
                displays.push({
                    selected: false,
                });
            }
            squareArray[i] = displays;
        }

        return squareArray;
    };

    isRectangle = (): boolean => {
        const coordArrayFlat = this.state.coordArray.flat();

        const nbOfElementsActive = coordArrayFlat.filter(display => display.selected).length;
        /**
         * Case no active semi-display
         */
        if (nbOfElementsActive === 0) return false;

        /**
         * Case only one semi-display is selected
         **/
        if (nbOfElementsActive === 1) return true;

        /**
         * Case all semi-diplays are selected
         */
        if (nbOfElementsActive === coordArrayFlat.length) return true;

        let linesWithActiveSemiDisplay: number[] = [];

        /**
         * Check if elements in lines are packed
         */
        for (let i = 0; i < this.state.coordArray.length; i++) {
            // Loop over lines
            let currentLine = this.state.coordArray[i];
            const firstLineEntry = currentLine.findIndex(column => column.selected);

            if (firstLineEntry !== -1) {
                linesWithActiveSemiDisplay.push(i);
                const lastLineEntry = findLastIndex(currentLine, column => column.selected);

                for (let k = firstLineEntry + 1; k < lastLineEntry; k++) {
                    // Loop over found index to check if every case is active
                    if (!currentLine[k].selected) return false;
                }
            }
        }

        /**
         * Case only one line
         */
        if (linesWithActiveSemiDisplay.length === 1) return true;

        let arrayToCompare: number[] = [];

        /**
         * Generate the comparision base case with the very first line
         */
        for (let i = 0; i < this.state.coordArray[linesWithActiveSemiDisplay[0]].length; i++) {
            const semiVirtualDisplay = this.state.coordArray[linesWithActiveSemiDisplay[0]][i];
            if (semiVirtualDisplay.selected) {
                arrayToCompare.push(i);
            }
        }

        /**
         * Check any other lines to correspond with base
         */
        for (let i = 1; i < linesWithActiveSemiDisplay.length; i++) {
            let currentArrayCompared: number[] = [];
            let currentLineNumber = linesWithActiveSemiDisplay[i];

            if (currentLineNumber - linesWithActiveSemiDisplay[i - 1] !== 1) {
                return false;
            }

            for (let j = 0; j < this.state.coordArray[currentLineNumber].length; j++) {
                if (this.state.coordArray[currentLineNumber][j].selected) {
                    currentArrayCompared.push(j);
                }
            }

            /**
             * Lines in array are not all the same
             */
            if (JSON.stringify(arrayToCompare) !== JSON.stringify(currentArrayCompared)) {
                return false;
            }
        }

        /**
         * Lines in array are all the same as base
         */
        return true;
    };

    handleClick = (event: KonvaEventObject<MouseEvent>) => {
        const indexY = parseInt(event.target.attrs.id);
        const indexX = parseInt(event.target.attrs.name);

        const squareArray = [...this.state.coordArray];

        squareArray[indexY][indexX].selected = !squareArray[indexY][indexX].selected;

        this.setState({
            coordArray: squareArray,
        });
    };

    createNewVirtualDisplay = () => {
        if (this.isRectangle()) {
            this.setState({
                isCreating: true,
            });
            let firstIsKnown = false;
            let coordFirstSquare = { x: 0, y: 0 };
            let sizeX = 0;
            let sizeY = 0;

            for (let i = 0; i < this.state.coordArray.length; i++) {
                if (!firstIsKnown) {
                    for (let j = 0; j < this.state.coordArray[i].length; j++) {
                        if (this.state.coordArray[i][j].selected && !firstIsKnown) {
                            coordFirstSquare = { x: j * PIXEL_X, y: i * PIXEL_Y };
                            firstIsKnown = true;
                        }
                    }
                }

                if (this.state.coordArray[i].findIndex(line => line.selected) !== -1) {
                    sizeY++;
                }
            }

            for (let display in this.state.coordArray[coordFirstSquare.y / PIXEL_Y]) {
                if (this.state.coordArray[coordFirstSquare.y / PIXEL_Y][display].selected) {
                    sizeX++;
                }
            }

            axiosService
                .getAxios()
                .post(
                    '/devices/lp-matrixdisplays-v2/virtualDisplay',
                    {
                        backgroundColor: {
                            red: 0,
                            green: 0,
                            blue: 0,
                        },
                        startX: coordFirstSquare.x,
                        startY: coordFirstSquare.y,
                        sizeX: sizeX * PIXEL_X,
                        sizeY: sizeY * PIXEL_Y,
                        font: 1,
                        language: 1,
                        arrowSpeed: 1,
                        textSpeed: 1,
                        state: 1,
                        id_matrixDisplay: this.props.display.getId(),
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(createVirtualDisplayResponse => {
                    this.props.display.addVirtualDisplay(createVirtualDisplayResponse.data);

                    ActionObserver.emit(ActionObserverEvent.CREATE_VIRTUAL_DISPLAY);

                    this.setState({
                        isCreating: false,
                        coordArray: this.generateCoordArray(),
                    });

                    Alert.success(
                        this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplays.virtualDisplays.create.success' })
                    );

                    this.props.onHide(true);
                })
                .catch(err => {
                    this.setState({
                        isCreating: false,
                    });

                    Alert.error(
                        this.props.intl.formatMessage(
                            { id: 'ipCanDevices.lpDisplays.virtualDisplays.create.error' },
                            { err }
                        )
                    );
                });
        } else {
            Alert.error(
                this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplays.virtualDisplays.notARectangleError' })
            );
        }
    };

    render = () => {
        const MATRIX_WIDTH = 100;
        const MATRIX_HEIGHT = 100;
        const SEPARATOR_WIDTH = 6;
        const SEMI_SEPARATOR_WIDTH = SEPARATOR_WIDTH / 3;

        const stageWidth =
            (this.props.display.getBaseConfig().getNbMatrixX() + 1) * SEPARATOR_WIDTH +
            this.props.display.getBaseConfig().getNbMatrixX() * SEMI_SEPARATOR_WIDTH +
            this.props.display.getBaseConfig().getNbMatrixX() * 2 * MATRIX_WIDTH;

        const stageHeight =
            this.props.display.getBaseConfig().getNbMatrixY() * MATRIX_HEIGHT +
            (this.props.display.getBaseConfig().getNbMatrixY() + 1) * SEPARATOR_WIDTH;

        const isSelectionValid = this.isRectangle();

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()} backdrop="static" full>
                <Modal.Header>
                    <FormattedMessage id="ipcan.lpDisplay.configuration.virtualDisplay.create.title" />
                </Modal.Header>
                <Modal.Body>
                    <FlexboxGrid align="middle" justify="center">
                        <FlexboxGrid.Item>
                            <div data-cy="virtualDisplay-create-selection">
                                <Stage width={stageWidth} height={stageHeight}>
                                    <Layer>
                                        <Rect width={stageWidth} height={stageHeight} x={0} y={0} fill="#000" />
                                    </Layer>
                                    <Layer>
                                        {this.state.coordArray.map((displays, indexY) => {
                                            let previousX = SEPARATOR_WIDTH;
                                            return displays.map((disp, indexX) => {
                                                let x;
                                                if (indexX > 0) {
                                                    x = previousX + MATRIX_WIDTH;

                                                    if (indexX % 2 !== 0) {
                                                        x += SEMI_SEPARATOR_WIDTH;
                                                    } else {
                                                        x += SEPARATOR_WIDTH;
                                                    }

                                                    previousX = x;
                                                } else {
                                                    x = previousX;
                                                }

                                                let y = indexY * MATRIX_HEIGHT + (indexY + 1) * SEPARATOR_WIDTH;

                                                return (
                                                    <Rect
                                                        key={`${indexX}_${indexY}`}
                                                        width={MATRIX_WIDTH}
                                                        height={MATRIX_HEIGHT}
                                                        fill={disp.selected ? 'green' : 'grey'}
                                                        x={x}
                                                        y={y}
                                                        id={indexY.toString()}
                                                        name={indexX.toString()}
                                                        onClick={this.handleClick}
                                                    />
                                                );
                                            });
                                        })}
                                    </Layer>
                                </Stage>

                                <Button
                                    block
                                    data-cy="lpDisplay-updateLayout-create"
                                    appearance="primary"
                                    color={'green'}
                                    disabled={!isSelectionValid}
                                    onClick={this.createNewVirtualDisplay}
                                    loading={this.state.isCreating}>
                                    <FormattedMessage id="ipCanDevices.lpDisplays.virtualDisplays.create" />
                                </Button>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>
            </Modal>
        );
    };
}

export default injectIntl(CreateVirtualDisplayModal);
