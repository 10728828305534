import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Col, FlexboxGrid, List, Panel } from 'rsuite';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';

type Props = {
    loading?: boolean;
    config: Record<string, any>;
    isCheckingDatabase: boolean;
    databaseData: Record<string, any> | undefined;
} & WrappedComponentProps;
class ConfigurationCard extends React.Component<Props> {
    render() {
        let numPlaceClass;
        let typePlaceClass;
        let numPulsationClass;
        let baudrateClass;
        let parityClass;

        if (this.props.isCheckingDatabase) {
            numPlaceClass =
                this.props.databaseData?.config.numPlace === this.props.config.numPlace ? 'list-green' : 'list-red';
            typePlaceClass =
                this.props.databaseData?.config.typePlace === this.props.config.typePlace ? 'list-green' : 'list-red';
            numPulsationClass =
                this.props.databaseData?.config.numPulsation === this.props.config.numPulsation
                    ? 'list-green'
                    : 'list-red';
            baudrateClass =
                this.props.databaseData?.config.baudrate === this.props.config.baudrate ? 'list-green' : 'list-red';
            parityClass =
                this.props.databaseData?.config.parity === this.props.config.parity ? 'list-green' : 'list-red';
        }

        return (
            <Fragment>
                <Loader show={this.props.loading || false}>
                    <Panel
                        className={'panel-small'}
                        shaded
                        bordered
                        data-cy="ipcan-elements-sensor-drawer-configuration"
                        header={
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'ipCanDevices.sensor.config' })}
                                buttons={[]}
                            />
                        }
                        bodyFill>
                        <List hover>
                            <List.Item className={`panel-list ${numPlaceClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-numPlace" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.config.numPlace" />
                                    </FlexboxGrid.Item>

                                    {/* DATABASE CHECK */}
                                    {this.props.isCheckingDatabase && (
                                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                                            {this.props.databaseData?.config.numPlace}
                                        </FlexboxGrid.Item>
                                    )}

                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-numPlace-value">{this.props.config.numPlace}</FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.UPDATE_SENSOR_PLACE_TYPE]}>
                                <List.Item className={`panel-list ${typePlaceClass}`}>
                                    <FlexboxGrid justify="space-between">
                                        <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-typePlace" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                            <FormattedMessage id="ipCanDevices.sensor.config.typePlace" />
                                        </FlexboxGrid.Item>

                                        {/* DATABASE CHECK */}
                                        {this.props.isCheckingDatabase && (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                {this.props.databaseData?.config.typePlace}
                                            </FlexboxGrid.Item>
                                        )}

                                        <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-typePlace-value">{this.props.config.typePlace}</FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            </SecuredFragment>

                            <List.Item className={`panel-list ${numPulsationClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-pulsation" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.config.pulsation" />
                                    </FlexboxGrid.Item>

                                    {/* DATABASE CHECK */}
                                    {this.props.isCheckingDatabase && (
                                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                                            {this.props.databaseData?.config.numPulsation}
                                        </FlexboxGrid.Item>
                                    )}

                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-pulsation-value">{this.props.config.numPulsation}</FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${baudrateClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-baudrate" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.config.baudrate" />
                                    </FlexboxGrid.Item>

                                    {/* DATABASE CHECK */}
                                    {this.props.isCheckingDatabase && (
                                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                                            {this.props.databaseData?.config.baudrate}
                                        </FlexboxGrid.Item>
                                    )}

                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-baudrate-value">{this.props.config.baudrate}</FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className={`panel-list ${parityClass}`}>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-parity" style={{ fontWeight: 'bold' }} componentClass={Col} xs={6}>
                                        <FormattedMessage id="ipCanDevices.sensor.config.parity" />
                                    </FlexboxGrid.Item>

                                    {/* DATABASE CHECK */}
                                    {this.props.isCheckingDatabase && (
                                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                                            {this.props.databaseData?.config.parity === 0 && (
                                                <FormattedMessage id="ipCanDevices.sensor.config.parity.even" />
                                            )}
                                            {this.props.databaseData?.config.parity === 1 && (
                                                <FormattedMessage id="ipCanDevices.sensor.config.parity.disabled" />
                                            )}
                                            {this.props.databaseData?.config.parity === 2 && (
                                                <FormattedMessage id="ipCanDevices.sensor.config.parity.odd" />
                                            )}{' '}
                                        </FlexboxGrid.Item>
                                    )}

                                    <FlexboxGrid.Item data-cy="ipcan-sensor-drawer-configuration-parity-value">
                                        {this.props.config.parity === 0 && (
                                            <FormattedMessage id="ipCanDevices.sensor.config.parity.even" />
                                        )}
                                        {this.props.config.parity === 1 && (
                                            <FormattedMessage id="ipCanDevices.sensor.config.parity.disabled" />
                                        )}
                                        {this.props.config.parity === 2 && (
                                            <FormattedMessage id="ipCanDevices.sensor.config.parity.odd" />
                                        )}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        </List>
                    </Panel>
                </Loader>
            </Fragment>
        );
    }
}

export default injectIntl(ConfigurationCard);