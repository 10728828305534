import { faArrowLeft, faCheck, faPlay, faStop, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    ControlLabel,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    List,
    Loader,
    Message,
    Modal,
    Steps,
    Tag,
} from 'rsuite';
import IPCanTcm from '../../../handlers/ipcan/IpCanTCM';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';

type Props = {
    show: boolean;
    bus: number;
    ipCan: IPCanTcm | undefined;
    onHide: Function;
};

type State = {
    formValue: Record<string, any>;
    step: number;
};

export default class SetManyIdModal extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            formValue: {
                startId: 1,
                stopId: 5,
            },
            step: 0,
        };

        this.switcher = this.switcher.bind(this);
        this.passToEnumeration = this.passToEnumeration.bind(this);
        this.passToForm = this.passToForm.bind(this);
        this.updateForm = this.updateForm.bind(this);
        this.handleClickClose = this.handleClickClose.bind(this);
    }

    passToEnumeration() {
        this.setState({
            step: 1,
        });
    }

    passToForm() {
        this.setState({
            step: 0,
        });
    }

    updateForm(formValue) {
        this.setState({
            formValue,
        });
    }

    switcher() {
        switch (this.state.step) {
            case 0:
                return (
                    <EnumForm
                        passToEnumeration={this.passToEnumeration}
                        formValue={this.state.formValue}
                        updateForm={this.updateForm}
                    />
                );
            case 1:
                return (
                    <ChangeIdTable
                        bus={this.props.bus}
                        ipCan={this.props.ipCan}
                        formValue={this.state.formValue}
                        passToForm={this.passToForm}
                        onHide={() => this.props.onHide()}
                    />
                );
            default:
                return null;
        }
    }

    handleClickClose = () => {
        this.setState({
            formValue: {
                startId: 1,
                stopId: 5,
            },
            step: 0,
        });
        this.props.onHide();
    };

    render() {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={this.handleClickClose}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCan.setManyId.title" /> | {this.props.ipCan?.getLabel()} -{' '}
                        {this.props.bus + 1}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Steps current={this.state.step} className="margin-bottom-20">
                        <Steps.Item title={<FormattedMessage id="ipCan.setManyId.configuration" />} passToEnumeration />
                        <Steps.Item title={<FormattedMessage id="ipCan.setManyId.setManyId" />} />
                    </Steps>

                    {this.switcher()}
                </Modal.Body>
            </Modal>
        );
    }
}

type EnumFormProps = {
    formValue: Record<string, any>;
    updateForm: Function;
    passToEnumeration: Function;
};

class EnumForm extends Component<EnumFormProps> {
    render() {
        return (
            <Form fluid formValue={this.props.formValue} onChange={formValue => this.props.updateForm(formValue)}>
                <FormGroup>
                    <ControlLabel>
                        <FormattedMessage id="ipCan.setManyId.startId" />
                    </ControlLabel>
                    <FormControl style={{ width: '100%' }} name="startId" type="number" max="127" min="1" />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>
                        <FormattedMessage id="ipCan.setManyId.stopId" />
                    </ControlLabel>
                    <FormControl style={{ width: '100%' }} name="stopId" type="number" max="127" min="1" />
                </FormGroup>
                <FlexboxGrid justify="end">
                    <FlexboxGrid.Item>
                        <Button
                            data-cy="ipCan-setManyId-valid"
                            color="green"
                            className="text-right"
                            onClick={() => this.props.passToEnumeration()}>
                            <FormattedMessage id="ipCan.setManyId.changeIds" />
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Form>
        );
    }
}

type ChangeIdTableProps = {
    ipCan: Record<string, any> | undefined;
    bus: number;
    formValue: Record<string, any>;
    passToForm: Function;
    onHide: Function;
};

type ChangeIdTableState = {
    elements: Array<Record<string, any>>;
    running: boolean;
    loading: boolean;
    currentId: number;
    error: boolean;
};

var timeoutFunction;
class ChangeIdTable extends Component<ChangeIdTableProps, ChangeIdTableState> {
    constructor(props) {
        super(props);

        this.state = {
            elements: [],
            running: false,
            loading: true,
            currentId: -1,
            error: false,
        };

        this.addElement = this.addElement.bind(this);
        this.launch = this.launch.bind(this);
    }

    launch() {
        if (typeof timeoutFunction !== 'undefined') {
            clearTimeout(timeoutFunction);
        }

        if (!this.state.running) {
            this.setState({
                error: false,
                elements: [],
                loading: true,
            });
        }

        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/cmdTCMSetManyId',
                {
                    id: this.props.ipCan?.getId(),
                    bus: this.props.bus,
                    startStop: !this.state.running,
                    startId: parseInt(this.props.formValue.startId),
                    stopId: parseInt(this.props.formValue.stopId),
                },
                { headers: authHeader() }
            )
            .then(() => {
                this.setState({
                    running: !this.state.running,
                    loading: true,
                });
            });

        timeoutFunction = setTimeout(() => {
            if (this.state.running && this.state.currentId === -1) {
                this.setState({ error: true });
                axiosService
                    .getAxios()
                    .put(
                        '/ipcanmodules/cmdTCMSetManyId',
                        {
                            id: this.props.ipCan?.getId(),
                            bus: this.props.bus,
                            startStop: !this.state.running,
                            startId: parseInt(this.props.formValue.startId),
                            stopId: parseInt(this.props.formValue.stopId),
                        },
                        { headers: authHeader() }
                    )
                    .then(() => {
                        this.setState({
                            running: false,
                            loading: false,
                        });
                    });
            }
        }, 10000);
    }

    componentDidMount() {
        webSocketService.onEvent(`ipcanmodule:setIdBus/${this.props.bus}`, this.addElement);

        this.launch();
    }

    componentWillUnmount() {
        webSocketService.offEvent(`ipcanmodule:setIdBus/${this.props.bus}`, this.addElement);
    }

    addElement(data) {
        let elements = this.state.elements;

        if (data.mac === this.props.ipCan?.mac) {
            let running = true;
            let loading = false;

            if (elements.length === 0) {
                elements.push({
                    id: data.id,
                    nbTry: data.nbTry,
                    find: data.find,
                    stopped: false,
                });
            } else {
                if (
                    data.finish &&
                    (elements.length === this.props.formValue.stopId - this.props.formValue.startId ||
                        elements.filter(element => !element.find).length === 0)
                ) {
                    running = false;
                } else if (data.finish) {
                    running = false;

                    elements[0] = {
                        id: data.id,
                        nbTry: data.nbTry,
                        find: data.find,
                        stopped: true,
                    };
                } else if (data.id === elements[0].id) {
                    elements[0] = {
                        id: data.id,
                        nbTry: data.nbTry,
                        find: data.find,
                        stopped: false,
                    };
                } else {
                    elements.unshift({
                        id: data.id,
                        nbTry: data.nbTry,
                        find: data.find,
                        stopped: false,
                    });
                }
            }

            this.setState({
                elements,
                currentId: data.id,
                running,
                loading,
            });
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.error && <Message type="error" description="Une erreur est survenue, veuillez réessayer" />}
                <FlexboxGrid justify="space-between" align="middle" className="margin-top-15 margin-bottom-15">
                    <FlexboxGrid.Item>
                        <Tag color="blue">
                            ID : {this.state.currentId > 0 ? this.state.currentId : this.props.formValue.startId} /{' '}
                            {this.props.formValue.stopId}
                        </Tag>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {this.state.running ? (
                            <Button size="xs" onClick={this.launch} color="red" loading={this.state.loading}>
                                <FontAwesomeIcon icon={faStop} className="margin-right-10" />
                                <FormattedMessage id="ipCan.setManyId.stop" />{' '}
                            </Button>
                        ) : (
                            <Fragment>
                                <Button
                                    className="margin-right-5"
                                    size="xs"
                                    onClick={() => this.props.passToForm()}
                                    color="orange">
                                    <FontAwesomeIcon icon={faArrowLeft} className="margin-right-10" />
                                    <FormattedMessage id="ipCan.setManyId.reconfigure" />{' '}
                                </Button>

                                <Button size="xs" onClick={this.launch} color="green" loading={this.state.loading}>
                                    <FontAwesomeIcon icon={faPlay} className="margin-right-10" />
                                    <FormattedMessage id="ipCan.setManyId.start" />{' '}
                                </Button>
                            </Fragment>
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <List className="flexbox-scrollable" style={{ maxHeight: '65vh' }}>
                    <List.Item>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item>
                                <FormattedMessage id="ipCan.setManyId.id" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <FormattedMessage id="ipCan.setManyId.status" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <FormattedMessage id="ipCan.setManyId.nbTry" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    {this.state.elements.map((element, index) => {
                        return <Element key={element.id} element={element} striped={index % 2 === 0} />;
                    })}
                </List>
            </Fragment>
        );
    }
}

type ElementProps = {
    element: Record<string, any>;
    striped: boolean;
};

class Element extends Component<ElementProps> {
    render() {
        return (
            <List.Item>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>{this.props.element.id}</FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {this.props.element.stopped ? (
                            <FontAwesomeIcon size="lg" icon={faTimes} style={{ color: '#f44336' }} />
                        ) : this.props.element.find ? (
                            <FontAwesomeIcon size="lg" icon={faCheck} style={{ color: '#008000' }} />
                        ) : (
                            <Loader size="sm" />
                        )}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{this.props.element.nbTry}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        );
    }
}
