import { faCheck, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Panel, Table, Tooltip, Whisper } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import IPCanTcm from '../../../handlers/ipcan/IpCanTCM';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import PanelHeader from '../../Custom/PanelHeader';
import EditableNumberCell from './Cells/EditableNumberCell';
import EditableSwitchCell from './Cells/EditableSwitchCell';

type Props = {
    ipcanModule: IpCan;
} & WrappedComponentProps;

type State = {
    editMode: boolean;
    config: Array<Record<string, any>>;
};
class Configuration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editMode: false,
            config: props.ipcanModule.getBusConfig(),
        };

        this.setEditMode = this.setEditMode.bind(this);
        this.unsetEditMode = this.unsetEditMode.bind(this);
        this.cancelEditMode = this.cancelEditMode.bind(this);
        this.validEdition = this.validEdition.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.changeSwitch = this.changeSwitch.bind(this);
    }

    // componentDidUpdate = (prevProps: Props) => {
    //     if (this.props.ipcanModule.getBusConfig() !== prevProps.ipcanModule.getBusConfig() && !this.state.editMode) {
    //         this.setState({
    //             config: this.props.ipcanModule.getBusConfig(),
    //         });
    //     }
    // };

    setEditMode() {
        this.setState({
            editMode: true,
        });
    }

    unsetEditMode() {
        this.setState({
            editMode: false,
        });
    }

    cancelEditMode() {
        // TODO
        this.setState({
            config: this.props.ipcanModule.getBusConfig(),
        });
        this.unsetEditMode();
    }

    validEdition() {
        this.unsetEditMode();
        this.props.ipcanModule
            .updateBusConfigs(this.state.config)
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'ipcan.busconfig.update.success' }));
            })
            .catch(err => {
                Alert.error(this.props.intl.formatMessage({ id: 'ipcan.busconfig.update.error' }, { error: err }));
            });
    }

    changeValue(name, dataName, value) {
        let config = this.state.config;
        for (let c in config) {
            if (config[c].name === name) {
                config[c][dataName] = parseInt(value);
            }
        }

        this.setState({
            config: config,
        });
    }

    changeSwitch(name, dataName, value) {
        let config = this.state.config;
        for (let c in config) {
            if (config[c].name === name) {
                config[c][dataName] = value;
            }
        }

        this.setState({
            config: config,
        });
    }

    changeSpeed = (name, dataName, value) => {
        let config = this.state.config;
        for (let c in config) {
            if (config[c].name === name) {
                config[c][dataName] = value;
            }
        }

        this.setState({
            config: config,
        });
    }

    render() {
        return (
            <Panel
                shaded
                bodyFill
                bordered
                className="panel-small"
                data-cy="ipcan-configuration"
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({ id: 'controller.configuration.title' })}
                        buttons={[
                            <SecuredFragment
                                key="informations.edit"
                                authorizedRoles={[rolesConstants.controller.UPDATE_BUS_CONFIGURATION]}>
                                <Button
                                    onClick={this.setEditMode}
                                    key="configuration.edit"
                                    appearance="primary"
                                    size="sm"
                                    data-cy="IPCan-edit-configuration">
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            </SecuredFragment>,
                        ]}
                    />
                }>
                <Table autoHeight data={this.state.config}>
                    <Table.Column align="center" fixed flexGrow={1}>
                        <Table.HeaderCell>
                            <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="controller.configuration.bus" />
                                    </Tooltip>
                                }>
                                <div>
                                    <FormattedMessage id="controller.configuration.bus" />
                                </div>
                            </Whisper>
                        </Table.HeaderCell>
                        <Table.Cell dataKey="name" />
                    </Table.Column>

                    <Table.Column align="center" flexGrow={1}>
                        <Table.HeaderCell>
                            <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="controller.configuration.busSpeed" />
                                    </Tooltip>
                                }>
                                <div>
                                    <FormattedMessage id="controller.configuration.busSpeed" />
                                </div>
                            </Whisper>
                        </Table.HeaderCell>
                        <EditableNumberCell
                            editMode={this.state.editMode}
                            onChange={this.changeSpeed}
                            dataKey="speed"
                            name="speed"
                        />
                    </Table.Column>

                    <Table.Column align="center" flexGrow={1}>
                        <Table.HeaderCell>
                            <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="controller.configuration.polling" />
                                    </Tooltip>
                                }>
                                <div>
                                    <FormattedMessage id="controller.configuration.polling" />
                                </div>
                            </Whisper>
                        </Table.HeaderCell>
                        <EditableSwitchCell
                            editMode={this.state.editMode}
                            onChange={this.changeSwitch}
                            dataKey="isPolling"
                            name="isPolling"
                        />
                    </Table.Column>

                    {this.props.ipcanModule instanceof IPCanTcm ? (
                        <Table.Column align="center" flexGrow={1}>
                            <Table.HeaderCell>
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <FormattedMessage id="controller.configuration.crcIsActiv" />
                                        </Tooltip>
                                    }>
                                    <div>
                                        <FormattedMessage id="controller.configuration.crcIsActiv" />
                                    </div>
                                </Whisper>
                            </Table.HeaderCell>
                            <EditableSwitchCell
                                editMode={this.state.editMode}
                                onChange={this.changeSwitch}
                                dataKey="crcIsActiv"
                                name="crcIsActiv"
                            />
                        </Table.Column>
                    ) : (
                        <Table.Column align="center" flexGrow={1}>
                            <Table.HeaderCell>
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <FormattedMessage id="controller.configuration.pollingTime" />
                                        </Tooltip>
                                    }>
                                    <div>
                                        <FormattedMessage id="controller.configuration.pollingTime" />
                                    </div>
                                </Whisper>
                            </Table.HeaderCell>
                            <EditableNumberCell
                                editMode={this.state.editMode}
                                onChange={this.changeValue}
                                dataKey="pollingTime"
                                name="pollingTime"
                            />
                        </Table.Column>
                    )}

                    <Table.Column align="center" flexGrow={1}>
                        <Table.HeaderCell>
                            <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="controller.configuration.automaticConfiguration" />
                                    </Tooltip>
                                }>
                                <div>
                                    <FormattedMessage id="controller.configuration.automaticConfiguration" />
                                </div>
                            </Whisper>
                        </Table.HeaderCell>
                        <EditableSwitchCell
                            editMode={this.state.editMode}
                            onChange={this.changeSwitch}
                            dataKey="isLoadConf"
                            name="isLoadConf"
                        />
                    </Table.Column>

                    <Table.Column align="center" flexGrow={1}>
                        <Table.HeaderCell>
                            <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="controller.configuration.busEnabled" />
                                    </Tooltip>
                                }>
                                <div>
                                    <FormattedMessage id="controller.configuration.busEnabled" />
                                </div>
                            </Whisper>
                        </Table.HeaderCell>
                        <EditableSwitchCell
                            editMode={false}
                            onChange={this.changeValue}
                            dataKey="isActiv"
                            name="isActiv"
                        />
                    </Table.Column>
                </Table>

                {this.state.editMode && (
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, marginBottom: 10 }}>
                                <Button
                                    color="red"
                                    data-cy="IPCan-edit-configuration-cancel"
                                    onClick={this.cancelEditMode}>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                                <Button
                                    color="green"
                                    data-cy="IPCan-edit-configuration-valid"
                                    onClick={this.validEdition}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                )}
            </Panel>
        );
    }
}

export default injectIntl(Configuration);
