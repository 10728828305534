import { authService } from '../redux/services';

export default function handleError(err) {
    console.error(err);
    const error = err.response;

    if (error.status === 401) {
        authService.logout();
    }

    return Promise.reject(error.statusText);
}
