import moment from 'moment';

export function parkingHandler(parking) {
    return {
        id: parking.id,
        name: parking.name,
        description: parking.description,
        updateInformations: parking.updatedAt
            ? `${parking.updatedBy} - ${moment(parking.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`
            : '-',
        createInformations: `${parking.createdBy} - ${moment(parking.createdAt).format('DD/MM/YYYY HH:mm:ss')}`,
        createdAt: moment(parking.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        updatedAt: moment(parking.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: parking.createdBy,
        updatedBy: parking.updatedBy,
        image: generateImage(parking.image),
        imageType: checkType(parking.imageType),
        levels: parking.levels.map(level => {
            return handleLevel(level);
        }),
        levelsLength: parking.levels.length,
    };
}

function generateImage(image) {
    if (image) {
        let buffer = Buffer.from(image.data, 'base64');
        return buffer.toString('base64');
    } else {
        return null;
    }
}

function checkType(type) {
    if (type === 'svg') {
        return 'svg+xml';
    } else {
        return type;
    }
}

function handleLevel(level) {
    return {
        createdAt: moment(level.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: level.createdBy,
        updatedBy: level.updatedBy,
        updatedAt: moment(level.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        defaultZoom: level.defaultZoom,
        description: level.description,
        id: level.id,
        image: generateImage(level.image),
        imageType: checkType(level.imageType),
        name: level.name,
        order: level.order,
    };
}
