import { faBrain, faBullseye, faObjectGroup, faPercent, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Control from 'react-leaflet-control';
import { Button, ButtonGroup } from 'rsuite';

type Props = {
    toggleServiceML: () => void;
    mlBoxesActive: boolean;
    percentageActive: boolean;
    setPercentageActive: () => void;
    isStreaming: boolean;
    occupancySensorActive: boolean;
    statePredictionActive: boolean;
    toggleServiceOccupancySensor: () => void;
    toggleStatePredictionActive: () => void;
    handleAssociationMode: () => void;
    handleLeaveAssociationMode: () => void;
    isAssociationMode: boolean;
};

export const TopLeftControl = (props: Props) => {
    return (
        <Control position="topleft">
            <ButtonGroup>
                <Button
                    appearance={props.mlBoxesActive ? 'default' : 'ghost'}
                    color="green"
                    onClick={props.toggleServiceML}>
                    <FontAwesomeIcon icon={faBrain} />
                </Button>

                <Button
                    appearance={props.percentageActive ? 'default' : 'ghost'}
                    color="blue"
                    disabled={!props.mlBoxesActive}
                    onClick={props.setPercentageActive}>
                    <FontAwesomeIcon icon={faPercent} />
                </Button>
            </ButtonGroup>

            <br />

            <ButtonGroup className="margin-top-10">
                <Button
                    appearance={props.occupancySensorActive ? 'default' : 'ghost'}
                    color="green"
                    onClick={props.toggleServiceOccupancySensor}
                    disabled={!props.isStreaming}>
                    <FontAwesomeIcon icon={faBullseye} />
                </Button>

                <Button
                    appearance={props.statePredictionActive ? 'default' : 'ghost'}
                    color="blue"
                    disabled={!props.occupancySensorActive}
                    onClick={props.toggleStatePredictionActive}>
                    <FontAwesomeIcon icon={faPercent} />
                </Button>
            </ButtonGroup>

            <br />

            <ButtonGroup className="margin-top-10">
                <Button color="blue" onClick={props.handleAssociationMode} disabled={props.isAssociationMode}>
                    <FontAwesomeIcon icon={faObjectGroup} />
                </Button>
                <Button color="red" onClick={props.handleLeaveAssociationMode} disabled={!props.isAssociationMode}>
                    <FontAwesomeIcon icon={faUndo} />
                </Button>
            </ButtonGroup>
        </Control>
    );
};
