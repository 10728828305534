export interface LPSensorInformations_I {
    modelName: string;
    softwareVersion: string;
    uniqueId: Array<number>;
    int_state: number;
    voltage: number;
    isEnable: boolean;
    intState: number;
}

export class LPSensorInformations {
    private _isEnable: boolean;
    private _modelName: string;
    private _softwareVersion: string;
    private _uniqueId: Array<number>;
    private _intState: number;
    private _voltage: number;

    constructor({ isEnable, modelName, softwareVersion, uniqueId, intState, voltage }: LPSensorInformations) {
        this._isEnable = isEnable;
        this._modelName = modelName;
        this._softwareVersion = softwareVersion;
        this._uniqueId = uniqueId;
        this._intState = intState;
        this._voltage = voltage;
    }

    public get isEnable() {
        return this._isEnable;
    }

    public setIsEnable(isEnable_: boolean): boolean {
        if (this._isEnable !== isEnable_) {
            this._isEnable = isEnable_;
            return true;
        }
        return false;
    }

    public get modelName() {
        return this._modelName;
    }

    public setModelName(modelName_: string): boolean {
        if (this._modelName !== modelName_) {
            this._modelName = modelName_;
            return true;
        }
        return false;
    }

    public get softwareVersion() {
        return this._softwareVersion;
    }

    public setSoftwareVersion(softwareVersion_): boolean {
        if (this._softwareVersion !== softwareVersion_) {
            this._softwareVersion = softwareVersion_;
            return true;
        }
        return false;
    }

    public get uniqueId() {
        return this._uniqueId;
    }

    public setUniqueId(uniqueId_: Array<number>): boolean {
        if (this._uniqueId !== uniqueId_) {
            this._uniqueId = uniqueId_;
            return true;
        }
        return false;
    }

    public get intState() {
        return this._intState;
    }

    public setIntState(intState_: number): boolean {
        if (this._intState !== intState_) {
            this._intState = intState_;
            return true;
        }
        return false;
    }

    public get voltage() {
        return this._voltage;
    }

    public setVoltage(voltage_: number): boolean {
        if (this._voltage !== voltage_) {
            this._voltage = voltage_;
            return true;
        }
        return false;
    }

    public get fetchInformations() {
        return {
            isEnable: this.isEnable,
            modelName: this.modelName,
            softwareVersion: this.softwareVersion,
            uniqueId: this.uniqueId,
            intState: this.intState,
            voltage: this.voltage,
        };
    }

    public setInformations(infoConfig: LPSensorInformations_I): boolean {
        let result = false;

        if (this.setIsEnable(infoConfig.isEnable)) result = true;
        if (this.setModelName(infoConfig.modelName)) result = true;
        if (this.setSoftwareVersion(infoConfig.softwareVersion)) result = true;
        if (this.setUniqueId(infoConfig.uniqueId)) result = true;
        if (this.setIntState(infoConfig.intState)) result = true;
        if (this.setVoltage(infoConfig.voltage)) result = true;

        return result;
    }
}
