import { Alert } from 'rsuite';
import { assign, Machine } from 'xstate';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

interface PassCountMachineStateSchema {
    states: {
        entrypoint: {};
        createPassCountForm: {};
        createPassCount: {};
        editPassCount: {};
        deletePassCounts: {};
        configurePassCount: {};
        reload: {};
    };
}

type PassCountMachineEvent =
    | { type: 'CREATE_PASS_COUNT' }
    | { type: 'EDIT_CREATE_PASS_COUNT' }
    | { type: 'VALID_CREATE_PASS_COUNT' }
    | { type: 'CANCEL_CREATE_PASS_COUNT' }
    | { type: 'EDIT_PASS_COUNT' }
    | { type: 'CONFIGURE_PASS_COUNT' }
    | { type: 'VALID_CONFIGURE_PASS_COUNT' }
    | { type: 'CANCEL_CONFIGURE_PASS_COUNT' }
    | { type: 'DELETE_PASS_COUNT' }
    | { type: 'PASS_COUNTER_RELOADED' };

interface PassCountMachineContext {
    createPassCountFormValue: CreatePassCountFormValue;
    createPassCountModalOpen: boolean;
    createPassCountError: boolean;
    levelId: number | undefined;
    loading: boolean;
    intl: Record<string, any>;
    geojson: Array<number>;
    editMode: boolean;
}

type CreatePassCountFormValue = {
    name: string;
    nbTotPlace: number;
    resetTime: number;
    isResettable: boolean;
    isShow: boolean;
};

export const PassCountMachine = Machine<PassCountMachineContext, PassCountMachineStateSchema, PassCountMachineEvent>(
    {
        id: 'passCount',
        initial: 'entrypoint',
        context: {
            createPassCountFormValue: {
                name: '',
                nbTotPlace: 100,
                isResettable: false,
                resetTime: 0,
                isShow: true,
            },
            createPassCountModalOpen: false,
            createPassCountError: false,
            levelId: undefined,
            loading: false,
            editMode: false,
            geojson: [],
            intl: {},
        },
        states: {
            entrypoint: {
                on: {
                    CREATE_PASS_COUNT: {
                        target: 'createPassCountForm',
                        actions: 'setCreatePassCount',
                    },
                    EDIT_PASS_COUNT: {
                        target: 'editPassCount',
                    },
                    DELETE_PASS_COUNT: {
                        target: 'deletePassCounts',
                    },
                    CONFIGURE_PASS_COUNT: {
                        target: 'configurePassCount',
                    },
                },
            },
            createPassCountForm: {
                on: {
                    EDIT_CREATE_PASS_COUNT: {
                        target: 'createPassCountForm',
                        actions: 'editCreatePassCount',
                    },
                    VALID_CREATE_PASS_COUNT: {
                        target: 'createPassCount',
                        actions: 'validCreatePassCount',
                    },
                    CANCEL_CREATE_PASS_COUNT: {
                        target: 'entrypoint',
                        actions: 'createPlaceForm',
                    },
                },
            },
            createPassCount: {
                invoke: {
                    id: 'create-pass-count',
                    src: (context: PassCountMachineContext, event: Record<string, any>) => () => {
                        const { geojson, createPassCountFormValue, levelId } = context;
                        const { name, nbTotPlace, resetTime, isResettable, isShow } = createPassCountFormValue;

                        const data = {
                            name,
                            nbTotPlace,
                            resetTime,
                            isResettable,
                            isShow,
                            geojson,
                            levelId,
                        };

                        return axiosService.getAxios().post('/map-verhicle-counters', data, { headers: authHeader() });
                    },
                    onDone: {
                        target: 'reload',
                        actions: [
                            'createPassCountFormValid',
                            context =>
                                Alert.success(context.intl.formatMessage({ id: 'map.passCounter.create.success' })),
                        ],
                    },
                    onError: {
                        target: 'createPassCountForm',
                        actions: 'createPassCountFormError',
                    },
                },
            },
            editPassCount: {},
            deletePassCounts: {},
            configurePassCount: {},
            reload: {
                on: {
                    PASS_COUNTER_RELOADED: 'entrypoint',
                },
            },
        },
    },
    {
        actions: {
            createPassCountForm: assign<PassCountMachineContext, PassCountMachineEvent>({}),
            createPassCountFormValid: assign<PassCountMachineContext, PassCountMachineEvent>({}),
            createPassCountFormError: assign<PassCountMachineContext, PassCountMachineEvent>({}),
        },
    }
);
