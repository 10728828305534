import moment from 'moment';

export default class PlaceType {
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    id: number;
    image: Record<string, any>;
    imageType: string;
    label: string;
    sensorPlaceTypes: Array<Record<string, any>>;
    scene: Record<string, any>;
    constructor(placeType_) {
        const {
            createdAt,
            createdBy,
            updatedAt,
            updatedBy,
            id,
            image,
            imageType,
            label,
            sensorPlaceTypes,
            scene,
        } = placeType_;

        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.updatedAt = updatedAt;
        this.updatedBy = updatedBy;
        this.id = id;
        this.image = image;
        this.imageType = imageType;
        this.label = label;
        this.sensorPlaceTypes = sensorPlaceTypes;
        this.scene = scene;
    }

    getCreatedAt() {
        return moment(this.createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    getUpdatedAt() {
        return moment(this.updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    generateImage() {
        if (this.image) return Buffer.from(this.image.data, 'base64').toString('base64');

        return null;
    }

    getImageType() {
        if (this.imageType === 'svg') return 'svg+xml';
        return this.imageType;
    }

    getPlaceType() {
        return {
            createdAt: this.getCreatedAt(),
            createdBy: this.createdBy,
            updatedAt: this.getUpdatedAt(),
            updatedBy: this.updatedBy,
            id: this.id,
            image: this.generateImage(),
            imageType: this.getImageType(),
            label: this.label,
            sensorPlaceTypes: this.sensorPlaceTypes,
            scene: this.scene,
        };
    }
}
