import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, FlexboxGrid, Message } from 'rsuite';
import { authHeader } from '../../../../../../redux/helpers';
import { axiosService } from '../../../../../../redux/services';

type RestarterProps = {
    closeModal: Function;
};

type RestarterState = {
    loading: boolean;
    error: boolean;
};
// STEP 4
export class Restarter extends React.Component<RestarterProps, RestarterState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
        };

        this.restartAutomaton = this.restartAutomaton.bind(this);
    }

    restartAutomaton() {
        this.setState({
            loading: true,
            error: false,
        });

        axiosService
            .getAxios()
            .put('/configuration/rebootAutomate', {}, { headers: authHeader() })
            .then(() => {
                this.setState({
                    loading: false,
                });
                this.props.closeModal(true);
            })
            .catch(() => {
                this.setState({
                    error: true,
                    loading: false,
                });
            });
    }

    render() {
        return (
            <Fragment>
                <Message
                    showIcon
                    type="success"
                    title={<FormattedMessage id="update.automatic.updateComplete" />}
                    description={<FormattedMessage id="update.automatic.applyUpdate" />}
                />

                <FlexboxGrid justify="center" className="margin-top-10">
                    <FlexboxGrid.Item>
                        <Button color="red" onClick={this.restartAutomaton}>
                            <FontAwesomeIcon icon={faPowerOff} className="margin-right-10" />
                            <FormattedMessage id="update.automatic.applyAndRestart" />
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Fragment>
        );
    }
}
