import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal } from 'rsuite';

type Props = {
    createParking: Function;
    close: Function;
    show: boolean;
};

type State = {
    loading: boolean;
    formValue: Record<string, any>;
};

export default class CreateLevelModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            formValue: {
                name: '',
                description: '',
            },
        };

        this.createParking = this.createParking.bind(this);
        this.setFormValue = this.setFormValue.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    createParking() {
        this.setState({
            loading: true,
        });

        this.props.createParking(this.state.formValue.name, this.state.formValue.description);

        this.setState(
            {
                loading: false,
                formValue: {
                    name: '',
                    description: '',
                },
            },
            () => {
                this.props.close();
            }
        );
    }

    setFormValue(formValue) {
        this.setState({ formValue: formValue });
    }

    hideModal() {
        this.setState({
            loading: false,
            formValue: {
                name: '',
                description: '',
            },
        });
        this.props.close();
    }

    render() {
        return (
            <Modal backdrop={'static'} show={this.props.show} onHide={() => this.props.close()}>
                <Modal.Header>
                    <Modal.Title>Ajouter un niveau</Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="parking-addLevel-modal">
                    <Form formValue={this.state.formValue} onChange={formValue => this.setFormValue(formValue)} fluid>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="level.create.name" />
                            </ControlLabel>
                            <FormControl data-cy="level-addLevel-name" name="name" value={this.state.formValue.name} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="level.create.description" />
                            </ControlLabel>
                            <FormControl
                                data-cy="level-addLevel-description"
                                name="description"
                                value={this.state.formValue.description}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={this.hideModal}
                            data-cy="level-addLevel-cancel"
                            color="red"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.createParking}
                            data-cy="level-addLevel-valid"
                            appearance="primary"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}
