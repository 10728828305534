import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import ReactFlow, {
    Background,
    BackgroundVariant,
    Controls,
    Edge,
    Node,
    NodeTypesType,
    OnLoadParams,
} from 'react-flow-renderer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert, Button, FlexboxGrid, Loader } from 'rsuite';
import { StepTaskData, StepTypeEnum } from '.';
import Task from '../../../../handlers/Event/Task';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { LPMatrixDisplayChartStepToStepEdgeComponent } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Edges/Components/LPMatrixDisplayChartStepToStepEdgeComponent';
import { LPMatrixDisplayChartTopologyTriggerEdgeComponent } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Edges/Components/LPMatrixDisplayChartTopologyTriggerEdgeComponent';
import { LPMatrixDisplayChartTopologyVirtualDisplayEdgeComponent } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Edges/Components/LPMatrixDisplayChartTopologyVirtualDisplayEdgeComponent';
import { LPMatrixDisplayChartVirtualDisplayStepEdgeComponent } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Edges/Components/LPMatrixDisplayChartVirtualDisplayStepEdgeComponent';
import { LPMatrixDisplayForceRole } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/LPMatrixDisplayChartElement';
import LPMatrixDisplayFactory, {
    ForceDataInterface,
} from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/LPMatrixDisplayFactory';
import { ForceCounterModal } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Modal/ForceCounterModal';
import { ForceVirtualDisplayModal } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Modal/ForceVirtualDisplayModal';
import { LPMatrixDisplayChartStepNodeComponent } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Nodes/Components/LPMatrixDisplayChartStepNodeComponent';
import { LPMatrixDisplayChartTopologyNodeComponent } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Nodes/Components/LPMatrixDisplayChartTopologyNodeComponent';
import { LPMatrixDisplayChartVirtualDisplayNodeComponent } from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/Nodes/Components/LPMatrixDisplayChartVirtualDisplayNodeComponent';
import {
    ForceCounterModalInformation,
    ForceVirtualDisplayModalInformation,
} from '../../../Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/ForceLPMatrixDisplayModal';

type Props = {
    existingTask?: Task;
    currentTask: StepTaskData;
    onClickBack: () => void;
    closeModal: () => void;
    type: StepTypeEnum;
};

export const LPMatrixDisplayV2Step = (props: Props) => {
    const intl = useIntl();

    const [isLoading, setIsLoading] = React.useState(true);
    const [display, setDisplay] = React.useState<LPDisplay>();
    const [isCreating, setIsCreating] = React.useState(false);

    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/devices/lp-matrixdisplays-v2/${props.currentTask.lpDisplays[0]}`, {
                headers: authHeader(),
            })
            .then(matrixDisplayResponse => {
                setDisplay(new LPDisplay(matrixDisplayResponse.data, 0, null));
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const [factory, setFactory] = React.useState<LPMatrixDisplayFactory | null>(null);

    const [reactFlowInstance, setReactFlowInstance] = React.useState<OnLoadParams | null>(null);

    const [forceCounterModalOpen, setForceCounterModalOpen] = React.useState<ForceCounterModalInformation>({
        isOpen: false,
    });

    const [
        forceVirtualDisplayModalOpen,
        setForceVirtualDisplayModalOpen,
    ] = React.useState<ForceVirtualDisplayModalInformation>({
        isOpen: false,
    });

    const nodeTypes: NodeTypesType = {
        forceTopologyNode: LPMatrixDisplayChartTopologyNodeComponent,
        forceVirtualDisplayNode: LPMatrixDisplayChartVirtualDisplayNodeComponent,
        forceVirtualDisplayStepNode: LPMatrixDisplayChartStepNodeComponent,
    };

    const edgeTypes: NodeTypesType = {
        forceVirtualDisplayTopologyVirtualDisplayNode: LPMatrixDisplayChartTopologyVirtualDisplayEdgeComponent,
        forceVirtualDisplayVirtualDisplayStepNode: LPMatrixDisplayChartVirtualDisplayStepEdgeComponent,
        forceVirtualDisplayStepToStepNode: LPMatrixDisplayChartStepToStepEdgeComponent,
        forceVirtualDisplayTopologyTriggerNode: LPMatrixDisplayChartTopologyTriggerEdgeComponent,
    };

    useEffect(() => {
        if (display) {
            const forceData: ForceDataInterface = {
                counters: props.currentTask.forceOption.counters || [],
                topologyNum:
                    typeof props.currentTask.forceOption.topologyNum === 'number'
                        ? props.currentTask.forceOption.topologyNum
                        : -1,
                virtualDisplays: props.currentTask.forceOption.virtualDisplays || [],
            };

            setFactory(new LPMatrixDisplayFactory(display, forceData));

            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }, [display]);

    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView();
        }
    }, [reactFlowInstance]);

    const onLoad = (rfi: OnLoadParams) => {
        if (!reactFlowInstance) {
            setReactFlowInstance(rfi);
        }
    };

    const onElementClick = (element: Node<any> | Edge<any>) => {
        if (element.type === 'forceTopologyNode') {
            if (element.data.isForce) {
                factory?.unsetTopologyForced();
            } else {
                factory?.setTopologyForced(element.data.index);
            }
            setForceCounterModalOpen({
                isOpen: false,
            });
        }

        if (element.type === 'forceVirtualDisplayStepNode') {
            if (element.data.step.getMode() >= 101 && element.data.step.getMode() <= 132) {
                setForceCounterModalOpen({ isOpen: true, step: element.data.step, forceMode: element.data.forceMode });
            }
        }

        if (element.type === 'forceVirtualDisplayNode') {
            setForceVirtualDisplayModalOpen({
                isOpen: true,
                virtualDisplay: element.data.virtualDisplay,
                forceMode: element.data.virtualDisplayForceData,
            });
        }
    };

    const authenticatedUser = useSelector((state: any) => state.auth.user);

    const role = (): LPMatrixDisplayForceRole => {
        const userIsAdmin = authenticatedUser?.roles.some(role => role.id === 1);

        const userHasAdvancedRole = authenticatedUser?.roles.some(
            role => role.section === 'LpMatrixDisplayV2' && role.name === 'ForceAdvanced'
        );

        const userHasNormalRole = authenticatedUser?.roles.some(
            role => role.section === 'LpMatrixDisplayV2' && role.name === 'ForceNormal'
        );

        const userHasBasicRole = authenticatedUser?.roles.some(
            role => role.section === 'LpMatrixDisplayV2' && role.name === 'ForceBasic'
        );

        if (userIsAdmin || userHasAdvancedRole) return LPMatrixDisplayForceRole.ROLE_ADVANCED;

        if (userHasNormalRole) return LPMatrixDisplayForceRole.ROLE_NORMAL;

        if (userHasBasicRole) return LPMatrixDisplayForceRole.ROLE_BASIC;

        return LPMatrixDisplayForceRole.NONE;
    };

    const handleValid = () => {
        if (factory && display) {
            setIsCreating(true);

            if (props.type === StepTypeEnum.CREATE) {
                axiosService
                    .getAxios()
                    .post(
                        '/event-actions/tasks',
                        {
                            idEvent: props.currentTask.eventId,
                            name: props.currentTask.name,
                            desc: props.currentTask.description,
                            forceType: props.currentTask.type,
                            forceOption: {
                                topologyNum: factory.getForceData().topologyNum,
                                counters: factory.getForceData().counters,
                                virtualDisplays: factory.getForceData().virtualDisplays,
                            },
                            tabTcmDisplayId: [],
                            tabLpMatrixDisplayV2Id: [display.id],
                            tabCounterId: [],
                            tabIpcanId: [],
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(() => {
                        Alert.success(intl.formatMessage({ id: 'event.createTask.success' }));

                        setIsCreating(false);

                        props.closeModal();
                    })
                    .catch(err => {
                        console.error(err);

                        Alert.error(intl.formatMessage({ id: 'event.createTask.error' }));

                        setIsCreating(false);
                    });
            } else {
                axiosService
                    .getAxios()
                    .put(
                        '/event-actions/tasks',
                        {
                            id: props.existingTask?.getId(),
                            name: props.currentTask.name,
                            desc: props.currentTask.description,
                            forceType: props.currentTask.type,
                            forceOption: {
                                topologyNum: factory.getForceData().topologyNum,
                                counters: factory.getForceData().counters,
                                virtualDisplays: factory.getForceData().virtualDisplays,
                            },
                            tabTcmDisplayId: [],
                            tabLpMatrixDisplayV2Id: [display.id],
                            tabCounterId: [],
                            tabIpcanId: [],
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(() => {
                        Alert.success(intl.formatMessage({ id: 'event.createTask.success' }));

                        setIsCreating(false);

                        props.closeModal();
                    })
                    .catch(err => {
                        console.error(err);

                        Alert.error(intl.formatMessage({ id: 'event.createTask.error' }));

                        setIsCreating(false);
                    });
            }
        }
    };

    let isValid = false;

    if (factory && display) {
        isValid =
            factory.getForceData().topologyNum !== -1 ||
            factory.getForceData().counters.length > 0 ||
            factory.getForceData().virtualDisplays.length > 0;
    }

    return (
        <div style={{ minHeight: '50vh' }}>
            {factory && forceCounterModalOpen.isOpen && (
                <ForceCounterModal
                    modalInformation={forceCounterModalOpen}
                    onHide={() => setForceCounterModalOpen({ isOpen: false })}
                    factory={factory}
                />
            )}

            {factory && forceVirtualDisplayModalOpen.isOpen && display && (
                <ForceVirtualDisplayModal
                    modalInformation={forceVirtualDisplayModalOpen}
                    onHide={() => setForceVirtualDisplayModalOpen({ isOpen: false })}
                    factory={factory}
                    display={display}
                />
            )}

            {isLoading ? (
                <>
                    <Loader size="lg" center backdrop style={{ marginTop: '50px', marginBottom: '30px' }} />
                </>
            ) : (
                <>
                    <ReactFlow
                        style={{ height: '65vh' }}
                        defaultZoom={1}
                        snapToGrid={true}
                        snapGrid={[20, 20]}
                        minZoom={-2}
                        className="margin-top-10"
                        elements={factory ? factory.generateElement(role()) : []}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        onLoad={onLoad}
                        onlyRenderVisibleElements={true}
                        onElementClick={(_, element) => onElementClick(element)}>
                        <Controls />
                        <Background variant={BackgroundVariant.Lines} gap={1} />
                    </ReactFlow>

                    <FlexboxGrid align="middle" justify="end" className="margin-top-15">
                        <FlexboxGrid.Item>
                            <Button
                                onClick={() => props.onClickBack()}
                                color="blue"
                                className="margin-right-5"
                                disabled={isCreating}>
                                <FontAwesomeIcon icon={faArrowLeft} className="margin-right-5" />
                                <FormattedMessage id="event.createTask.back" />
                            </Button>

                            <Button
                                data-cy="event-createTask-valid"
                                color="green"
                                onClick={() => handleValid()}
                                loading={isCreating}
                                disabled={!isValid}>
                                <FontAwesomeIcon icon={faCheck} className="margin-right-5" />
                                <FormattedMessage id="event.createTask.valid" />
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </>
            )}
        </div>
    );
};
