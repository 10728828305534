import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';
import Camera from '../../../../handlers/Camera/Camera';
import PlaceCamera from '../../../../handlers/Camera/PlaceCamera';
import { DeviceBase } from '../../../../handlers/ipCanDevices/DeviceBase';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';
import { EditDeviceStateInformation } from './EditDeviceStateInformation';
import { ViewDeviceStateInformation } from './ViewDeviceStateInformation';
import { MaintenanceStateInformationEditURL } from './deviceStateInformationGenerator';

type Props = {
    editMaintenanceStateUrl: MaintenanceStateInformationEditURL;
    device: DeviceBase | Camera | PlaceCamera;
    isMinimized?: boolean;
};

export const DeviceStateInformation = (props: Props) => {
    const intl = useIntl();
    const [isEditMode, setIsEditMode] = React.useState(false);

    return (
        <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_MAINTENACE_STATE]}>
            <Panel
                className="panel-small"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.title' })}
                        buttons={[
                            <Button
                                key="maintenanceInformation-edit"
                                data-cy="maintenanceInformation-edit"
                                color="blue"
                                size="sm"
                                disabled={isEditMode}
                                onClick={() => setIsEditMode(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>,
                        ]}
                    />
                }>
                {isEditMode ? (
                    <EditDeviceStateInformation
                        editMaintenanceStateUrl={props.editMaintenanceStateUrl}
                        device={props.device}
                        setIsEditMode={setIsEditMode}
                    />
                ) : (
                    <ViewDeviceStateInformation device={props.device} isMinimized={props.isMinimized} />
                )}
            </Panel>
        </SecuredFragment>
    );
};
