type Props = {
    color: string;
};

export const WrongWayInvertedIcon = ({ color }: Props) => (
    <svg id="SVGRoot" width="100%" height="100%" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect fill={color} x="192" width="128" height="64" stroke-width=".41312" />
            <rect fill={color} x="192" y="448" width="128" height="64" stroke-width=".41312" />
            <rect fill={color} y="192" width="64" height="128" stroke-width=".58748" />
            <rect fill={color} x="448" y="192" width="64" height="128" stroke-width=".58748" />
            <rect fill={color} x="16" y="160" width="480" height="64" stroke-width="1.7216" />
            <rect fill={color} x="16" y="288" width="480" height="64" stroke-width="1.7216" />
            <rect fill={color} x="32" y="320" width="448" height="64" stroke-width="1.6632" />
            <rect fill={color} x="64" y="368" width="384" height="64" stroke-width="1.5398" />
            <rect fill={color} x="48" y="336" width="416" height="80" stroke-width="1.7919" />
            <rect fill={color} x="32" y="128" width="448" height="96" stroke-width="2.037" />
            <rect fill={color} x="48" y="96" width="416" height="128" stroke-width="2.2666" />
            <rect fill={color} x="64" y="80" width="384" height="144" stroke-width="2.3098" />
            <rect fill={color} x="80" y="64" width="352" height="160" stroke-width="2.3311" />
            <rect fill={color} x="160" y="16" width="208" height="192" stroke-width="6.7109" />
            <rect fill={color} x="160" y="432" width="192" height="64" stroke-width="3.7225" />
            <rect fill={color} x="128" y="32" width="256" height="192" stroke-width="7.4451" />
            <rect fill={color} x="128" y="416" width="256" height="64" stroke-width="4.2984" />
            <rect fill={color} x="96" y="400" width="320" height="64" stroke-width="4.8058" />
            <rect fill={color} x="80" y="384" width="352" height="64" stroke-width="5.0403" />
            <rect fill={color} x="96" y="48" width="320" height="176" stroke-width="7.9695" />
        </g>
    </svg>
);
