import { faCircle, faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function ActivityTypeCell(props) {
    if (props.state === 'detectionChange') {
        switch (props.value) {
            case 1:
                return <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }} />;
            case 2:
                return <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} />;
            case 3:
                return <FontAwesomeIcon icon={faCircle} style={{ color: 'orange' }} />;
            case 4:
                return <FontAwesomeIcon icon={faCircle} style={{ color: 'purple' }} />;
            default:
                return <FontAwesomeIcon icon={faCircle} style={{ color: 'black' }} />;
        }
    } else if (props.state === 'onlineChange') {
        return <FontAwesomeIcon icon={faPlug} style={{ color: props.value ? 'green' : 'red' }} />;
    }
}
