import moment from 'moment';

export function sceneSensorPlaceTypeHandler(sceneSensorPlaceType) {
    return {
        color: sceneSensorPlaceType.color,
        createdAt: moment(sceneSensorPlaceType.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: sceneSensorPlaceType.createdBy,
        id: sceneSensorPlaceType.id,
        led1: sceneSensorPlaceType.led1,
        led2: sceneSensorPlaceType.led2,
        led3: sceneSensorPlaceType.led3,
        led4: sceneSensorPlaceType.led4,
        led5: sceneSensorPlaceType.led5,
        led6: sceneSensorPlaceType.led6,
        order: sceneSensorPlaceType.order,
        sensorPlaceType: sensorPlaceTypeHandler({ ...sceneSensorPlaceType.sensorPlaceType, scenes: [] }),
        state: sceneSensorPlaceType.state,
        time: sceneSensorPlaceType.time,
        updatedBy: sceneSensorPlaceType.updatedBy,
        updatedAt: moment(sceneSensorPlaceType.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
    };
}

function sensorPlaceTypeHandler(sensorPlaceType) {
    return {
        createdAt: moment(sensorPlaceType.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: sensorPlaceType.createdBy,
        updatedBy: sensorPlaceType.updatedBy,
        updatedAt: moment(sensorPlaceType.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        id: sensorPlaceType.id,
        label: sensorPlaceType.label,
        description: sensorPlaceType.description,
    };
}
