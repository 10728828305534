interface secondsToHours_I {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    stringified: string;
}

export function secondsToHours(time: number): secondsToHours_I {
    let hoursStr = '';
    let minutesStr = '';
    let secondsStr = '';

    let days = Math.floor(time / 24 / 60 / 60);
    let hours = Math.floor(time / 60 / 60 - days * 24);
    let totalSeconds = time % 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    if (hours < 10) {
        hoursStr = `0${hours}`;
    } else {
        hoursStr = hours.toString();
    }

    if (minutes < 10) {
        minutesStr = `0${minutes}`;
    } else {
        minutesStr = minutes.toString();
    }

    if (seconds < 10) {
        secondsStr = `0${seconds}`;
    } else {
        secondsStr = seconds.toString();
    }

    return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        stringified:
            days >= 1
                ? `${days}j - ${hoursStr}:${minutesStr}:${secondsStr}`
                : `${hoursStr}:${minutesStr}:${secondsStr}`,
    };
}
