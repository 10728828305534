import moment from 'moment';
import { useEffect, useState } from 'react';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { VersionLine } from '../VersionTable';

export function useServiceMLVersionHook() {
    const [serviceMLVersion, setServiceMLVersion] = useState<VersionLine>({
        appName: 'Service ML',
        version: '-',
        dateTag: '-',
        isConnected: false,
        startDate: '-',
        versionTag: '-',
        isActiv: false,
    });

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/cameras/status/serviceMl', {
                headers: authHeader(),
            })
            .then(response => {
                setServiceMLVersion({
                    appName: response.data.app_name,
                    version: response.data.app_version,
                    isConnected: true,
                    versionTag: response.data.app_commit_tag,
                    dateTag: moment.unix(response.data.app_build_date).format('DD/MM/YYYY HH:mm:ss'),
                    startDate: moment.unix(response.data.boot_time).format('DD/MM/YYYY HH:mm:ss'),
                    isActiv: response.data.app_status,
                });
            });
    }, []);

    return serviceMLVersion;
}
