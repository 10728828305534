import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Col, Divider, FlexboxGrid, List, Tag, TagGroup } from 'rsuite';
import { LPDisplay } from '../../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { LPMatrixDisplayWidget } from '../../../Widgets/LPMatrixDisplayWidget';
import LPVirtualDisplayStepEditAndDelete from './Parts/LPVirtualDisplayStepEditAndDelete';

type Props = {
    step: VirtualDisplayStep;
    backgroundColor: string;
    virtualDisplay: VirtualDisplayPreviewInformation;
    reloadDisplay: Function;
    display: LPDisplay;
    updateInformation: Function;
};

export const LPVirtualDisplayStepCounter = ({
    step,
    backgroundColor,
    virtualDisplay,
    reloadDisplay,
    display,
    updateInformation,
}: Props) => {
    const triggerMinTextNumber = step.getTriggerMin().getActionInformation().number;
    const triggerMaxTextNumber = step.getTriggerMax().getActionInformation().number;
    const systemText = display.getTextConfig().getSystemTextEntries();
    const userText = display.getTextConfig().getUserText();

    const shouldDisplayTriggerPart = step.getTriggerMin().getAction() !== 0 || step.getTriggerMax().getAction() !== 0;

    return (
        <Fragment>
            <FlexboxGrid align="middle" justify="space-between">
                <FlexboxGrid.Item data-cy="lpDisplay-virtualDisplay-step-order" componentClass={Col} xs={2}>
                    <Avatar circle size="sm">
                        {step.getStepOrder() + 1}
                    </Avatar>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} xs={6}>
                    <Tag color="blue" data-cy="lpDisplay-virtualDisplay-step-counter">
                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.counter" />
                    </Tag>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} xs={13}>
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} xs={24}>
                            <LPMatrixDisplayWidget backgroundColor={backgroundColor} step={step} display={display} />
                            <TagGroup>
                                {step.getState() === 0 && (
                                    <Tag color="red">
                                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.mode.disabled" />
                                    </Tag>
                                )}
                                {step.getState() === 1 && (
                                    <Tag color="green">
                                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.mode.enabled" />
                                    </Tag>
                                )}
                                {step.getState() === 2 && (
                                    <Tag color="orange">
                                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.step.mode.waiting" />
                                    </Tag>
                                )}

                                <Tag color="cyan" data-cy="lpDisplay-virtualDisplay-step-seconds">
                                    <span className="margin-right-5">{step.getTime()}</span>
                                    <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.seconds" />
                                </Tag>
                                <Tag color="violet" data-cy="lpDisplay-virtualDisplay-step-textTransformation">
                                    <FormattedMessage
                                        id={`ipCanDevices.lpDisplay.virtualDisplay.textTransformation.${step.getTextTransformationString()}`}
                                    />
                                </Tag>

                                {step.getTransition() === 1 && (
                                    <Tag color="orange" data-cy="lpDisplay-virtualDisplay-step-transitionDown">
                                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.transition.DownToDown" />
                                    </Tag>
                                )}
                                {step.getTransition() === 2 && (
                                    <Tag color="orange" data-cy="lpDisplay-virtualDisplay-step-transitionUp">
                                        <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.transition.UpToDown" />
                                    </Tag>
                                )}
                            </TagGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item componentClass={Col} xs={3}>
                    <LPVirtualDisplayStepEditAndDelete
                        step={step}
                        virtualDisplay={virtualDisplay}
                        reloadDisplay={reloadDisplay}
                        display={display}
                        updateInformation={updateInformation}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>

            {shouldDisplayTriggerPart && (
                <FlexboxGrid align="middle" justify="center">
                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                        <Divider data-cy="lpDisplay-virtualDisplay-step-triggers">
                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.triggers" />
                        </Divider>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={20} className="padding-top-5 padding-bottom-5">
                        <List bordered>
                            {step.getTriggerMin().getAction() !== 0 && (
                                <List.Item className="panel-list">
                                    <FlexboxGrid align="middle" justify="space-between">
                                        <FlexboxGrid.Item>
                                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.triggerMin" />
                                        </FlexboxGrid.Item>

                                        <TagGroup>
                                            <Tag
                                                color="green"
                                                data-cy="lpDisplay-virtualDisplay-step-triggerMin-action">
                                                <FormattedMessage
                                                    id={`ipCanDevices.lpDisplay.virtualDisplay.triggerMin.action.${
                                                        step.getTriggerMin().getActionInformation().name
                                                    }`}
                                                />{' '}
                                                {step.getTriggerMin().getActionInformation().number}
                                            </Tag>

                                            {step.getTriggerMin().getActionInformation().name === 'userText' && (
                                                <Tag color="green">{userText[triggerMinTextNumber]}</Tag>
                                            )}

                                            {step.getTriggerMin().getActionInformation().name === 'text' && (
                                                <Tag color="green">
                                                    {systemText[triggerMinTextNumber].lang0} /{' '}
                                                    {systemText[triggerMinTextNumber].lang1} /{' '}
                                                    {systemText[triggerMinTextNumber].lang2} /{' '}
                                                    {systemText[triggerMinTextNumber].lang3}
                                                </Tag>
                                            )}
                                            <Tag data-cy="step-triggerMin-action-number" color="blue">
                                                {step.getTriggerMin().getValue()}
                                            </Tag>
                                        </TagGroup>
                                    </FlexboxGrid>
                                </List.Item>
                            )}

                            {step.getTriggerMax().getAction() !== 0 && (
                                <List.Item className="panel-list">
                                    <FlexboxGrid align="middle" justify="space-between">
                                        <FlexboxGrid.Item>
                                            <FormattedMessage id="ipCanDevices.lpDisplay.virtualDisplay.triggerMax" />
                                        </FlexboxGrid.Item>
                                        <TagGroup>
                                            <Tag
                                                color="green"
                                                data-cy="lpDisplay-virtualDisplay-step-triggerMax-action">
                                                <FormattedMessage
                                                    id={`ipCanDevices.lpDisplay.virtualDisplay.triggerMax.action.${
                                                        step.getTriggerMax().getActionInformation().name
                                                    }`}
                                                />{' '}
                                                {step.getTriggerMax().getActionInformation().number}
                                            </Tag>

                                            {step.getTriggerMax().getActionInformation().name === 'userText' && (
                                                <Tag color="green">{userText[triggerMaxTextNumber]}</Tag>
                                            )}

                                            {step.getTriggerMax().getActionInformation().name === 'text' && (
                                                <Tag color="green">
                                                    {systemText[triggerMaxTextNumber].lang0} /{' '}
                                                    {systemText[triggerMaxTextNumber].lang1} /{' '}
                                                    {systemText[triggerMaxTextNumber].lang2} /{' '}
                                                    {systemText[triggerMaxTextNumber].lang3}
                                                </Tag>
                                            )}

                                            <Tag color="blue" data-cy="lpDisplay-virtualDisplay-step-triggerMax-value">
                                                {step.getTriggerMax().getValue()}
                                            </Tag>
                                        </TagGroup>
                                    </FlexboxGrid>
                                </List.Item>
                            )}
                        </List>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            )}
        </Fragment>
    );
};
