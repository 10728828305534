import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LPSensorUsHeightValues } from '../../../handlers/ipCanDevices/Sensor/LPSensorUsConfig';

export default function usHeightCell(us1Height, us2Height) {
    return (
        <div className="text-center" data-tag="allowRowEvents">
            <span className="bold" data-tag="allowRowEvents">
                <FormattedMessage id="ipCanDevices.LPSensors.usHeight.c1" data-tag="allowRowEvents" />
                <span data-cy="lpSensor-c1" className="text-normal" data-tag="allowRowEvents">
                    {LPSensorUsHeightValues(us1Height)}
                </span>
            </span>
            <br />
            <span className="bold" data-tag="allowRowEvents">
                <FormattedMessage id="ipCanDevices.LPSensors.usHeight.c2" data-tag="allowRowEvents" />
                <span data-cy="lpSensor-c2" className="text-normal" data-tag="allowRowEvents">
                    {LPSensorUsHeightValues(us2Height)}
                </span>
            </span>
        </div>
    );
}
