import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Modal } from 'rsuite';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    handleClickReset: () => void;
};

export const ResetPictoModal = (props: Props) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleValid = () => {
        setIsLoading(true);

        props.handleClickReset();
        props.onHide();

        setIsLoading(false);
    };

    return (
        <Modal show={props.isOpen} onHide={() => props.onHide()}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="editPictoRGB.resetPictoRGB.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="editPictoRGB.resetPictoRGB.message" />
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button onClick={() => props.onHide()} color="red" disabled={isLoading}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button onClick={() => handleValid()} type="submit" appearance="primary" loading={isLoading}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
