import { VirtualDisplay } from '../../../../handlers/lpDisplays/VirtualDisplay';
import { MapLPMatrixDisplayV2 } from '../../../../handlers/map/MapLPMatrixDisplayV2';
import { LPMatrixDisplayPreview } from './LPMatrixDisplayPreview';

const BASE_MATRIX_DISPLAY_HEIGHT = 18;
const BASE_MATRIX_DISPLAY_WIDTH = 50;

type Props = {
    mapDisplay: MapLPMatrixDisplayV2;
    currentTopology: number;
};

export const LPMatrixDisplayPreviewGenerator = (props: Props) => {
    const display = props.mapDisplay.getLpMatrixDisplayV2();

    const height = BASE_MATRIX_DISPLAY_HEIGHT * display.getBaseConfig().getNbMatrixY() + 12;
    const width = BASE_MATRIX_DISPLAY_WIDTH * display.getBaseConfig().getNbMatrixX() + 23;

    // Get only virtual displays from 1st topology
    let virtualDisplays: VirtualDisplay[] = [];

    const currentTopology = display.getTopologyConfig().getTopologys()[props.currentTopology];

    currentTopology.virtualDisplays.forEach(virtualDisplay => {
        const foundVirtualDisplay = display.getVirtualDisplays().find(vd => vd.getPosId() + 1 === virtualDisplay);

        if (foundVirtualDisplay) {
            virtualDisplays.push(foundVirtualDisplay);
        }
    });

    // ---
    const totalNbMatrixX = display.getBaseConfig().getNbMatrixX() * 2 + 1;
    const totalNbMatrixY = display.getBaseConfig().getNbMatrixY() + 1;

    let classes = '';

    if (display.getEventForceType().getIsForce()) {
        classes = 'display-event-forced';
    } else if (display.isForce) {
        classes = 'display-forced';
    }

    return (
        <div
            className={`map-matrix-display-demo ${classes}`}
            style={{
                width,
                height,
                gridRowGap: 1,
                gridColumnGap: 1,
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fill, minmax(${totalNbMatrixX}, ${
                    BASE_MATRIX_DISPLAY_WIDTH / 2
                }px, 1fr)`,
                gridTemplateRows: `repeat(auto-fill, minmax(${totalNbMatrixY}, ${BASE_MATRIX_DISPLAY_HEIGHT}px, 1fr)`,
                justifyItems: 'stretch',
                alignItems: 'stretch',
            }}>
            <>
                <div
                    style={{
                        gridColumn: `1 / ${totalNbMatrixX}`,
                        gridRow: `1 / ${totalNbMatrixY}`,
                        backgroundColor: 'grey',
                    }}
                />
                {virtualDisplays.map((virtualDisplay, index) => {
                    let startX = virtualDisplay.getFormattedStartX() + 1;
                    let startY = virtualDisplay.getFormattedStartY() + 1;
                    let endX = startX + virtualDisplay.getFormattedSizeX();
                    let endY = startY + virtualDisplay.getFormattedSizeY();

                    if (props.mapDisplay.getIsMirror()) {
                        startX = virtualDisplays[virtualDisplays.length - index - 1].getFormattedStartX() + 1;
                        endX = startX + virtualDisplays[virtualDisplays.length - index - 1].getFormattedSizeX();
                    }

                    if (endX <= totalNbMatrixX || endY <= totalNbMatrixY) {
                        return (
                            <div
                                key={`virtualDisplay-${props.mapDisplay.id}-${virtualDisplay.getId()}`}
                                style={{
                                    gridColumn: `${startX} / ${endX > totalNbMatrixX ? totalNbMatrixX : endX}`,
                                    gridRow: `${startY} / ${endY > totalNbMatrixY ? totalNbMatrixY : endY}`,
                                    color: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: virtualDisplay.getBackgroundColor(),
                                }}>
                                {virtualDisplay.getSteps().map((step, stepIndex) => {
                                    if (virtualDisplay.getCurrentStep() === stepIndex) {
                                        return (
                                            <LPMatrixDisplayPreview
                                                key={`virtualDisplay-${
                                                    props.mapDisplay.id
                                                }-${virtualDisplay.getId()}-step-${stepIndex}`}
                                                display={display.getPreviewInformation()}
                                                step={step}
                                                disconnectedText={`ID: ${display.getDeviceId()}.${
                                                    virtualDisplay.getPosId() + 1
                                                }`}
                                                arrowOrientation={props.mapDisplay.getMapArrowRotation() * 45}
                                                virtualDisplay={virtualDisplay.getPosId()}
                                                isMirror={props.mapDisplay.getIsMirror()}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        );
                    }
                })}
            </>
        </div>
    );
};
