import moment from 'moment';

export default class History {
    id: number;
    user: string;
    createdAt: string;
    createdBy: string;
    desc: string;
    option: any;
    disabled: boolean;
    constructor(historyObject) {
        const { id, user, createdAt, createdBy, desc, option, disabled } = historyObject;
        this.id = id;
        this.user = user;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.desc = desc;
        this.option = option;
        this.disabled = disabled;
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    getUser() {
        return this.user;
    }

    setUser(user) {
        this.user = user;
    }

    getCreatedAt() {
        return moment(this.createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    setCreatedAt(createdAt) {
        this.createdAt = createdAt;
    }

    getCreatedBy() {
        return this.createdBy;
    }

    setCreatedBy(createdBy) {
        this.createdBy = createdBy;
    }

    getDesc(): string {
        //let desc = this.desc.toLowerCase().replace(/\s/g, '_');

        return this.desc.toLocaleLowerCase();
    }

    setDesc(desc) {
        this.desc = desc;
    }

    // getOption() {
    //     return this.option;
    // }

    setOption(option) {
        this.option = option;
    }

    getDisabled() {
        return this.disabled;
    }

    setDisabled(disabled) {
        this.disabled = disabled;
    }

    // getHistory() {
    //     return {
    //         id: this.getId(),
    //         user: this.getUser(),
    //         createdAt: this.getCreatedAt(),
    //         createdBy: this.getCreatedBy(),
    //         desc: this.getDesc(),
    //         option: this.getOption(),
    //     };
    // }
}
