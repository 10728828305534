import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    show: boolean;
    onHide: Function;
    createNotification: Function;
    ipcanId: number | undefined;
    bus: number;
} & WrappedComponentProps;

type State = {
    loading: boolean;
};
class StopForceTCMModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.stopForce = this.stopForce.bind(this);
    }

    stopForce() {
        this.setState({
            loading: true,
        });

        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/cmdTCM',
                {
                    id: this.props.ipcanId,
                    bus: this.props.bus,
                    cmd: 'disableforcedAllSensor',
                },
                { headers: authHeader() }
            )
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'ipcan.actions.disableforcedAllSensor.success' }));

                this.setState({
                    loading: false,
                });

                this.props.onHide();
            })
            .catch(err => {
                console.error(err);

                Alert.error(this.props.intl.formatMessage({ id: 'ipcan.actions.disableforcedAllSensor.error' }, { error: err }));

                this.setState({
                    loading: false,
                });

                this.props.onHide();
            });
    }

    render() {
        return (
            <Modal backdrop={'static'} show={this.props.show} keyboard={false} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="ipCan.bus.actions.stopForceBeforeExit.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="ipCan.bus.actions.stopForceBeforeExit.message" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.props.onHide()}
                            color="red"
                            loading={this.state.loading}
                            data-cy="ipCan-bus-stopBeforeExit-cancel">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.stopForce}
                            appearance="primary"
                            loading={this.state.loading}
                            data-cy="ipCan-bus-stopBeforeExit-valid">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(StopForceTCMModal);