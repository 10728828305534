import { faArrowsAlt, faCar, faCircle, faDesktop, faExchangeAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Tooltip, Whisper } from 'rsuite';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';

type Props = {
    levelId: number;
    handleButtonClick: Function;
    activeButton: Record<string, any>;
    needCalibration: boolean;
};
export default class EditActions extends React.Component<Props> {
    render() {
        const enableButtons =
            this.props.activeButton.matches('globalView') ||
            this.props.activeButton.matches('editPlaces') ||
            this.props.activeButton.matches('editDisplays') ||
            this.props.activeButton.matches('editZones') ||
            this.props.activeButton.matches('editPassCount') ||
            this.props.activeButton.matches('calibration');
        return (
            <ButtonGroup vertical>
                {/* GLOBAL VIEW */}
                <Whisper
                    trigger="hover"
                    placement={'autoHorizontal'}
                    speaker={
                        <Tooltip>
                            <FormattedMessage id="map.actions.globalView" />
                        </Tooltip>
                    }>
                    <Button
                        className="button-map"
                        onClick={() => this.props.handleButtonClick('GLOBAL_VIEW')}
                        appearance={this.props.activeButton.matches('globalView') ? 'primary' : 'ghost'}
                        disabled={!enableButtons}
                        data-cy="global-View"
                        size="md">
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </Whisper>

                {/* EDIT PLACES */}
                <SecuredFragment authorizedRoles={[rolesConstants.mapTCMSensors.VIEW]}>
                    <Whisper
                        trigger="hover"
                        placement={'autoHorizontal'}
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="map.actions.editPlaces" />
                            </Tooltip>
                        }>
                        <Button
                            disabled={this.props.needCalibration || !enableButtons}
                            className="button-map"
                            onClick={() => this.props.handleButtonClick('EDIT_PLACES')}
                            appearance={this.props.activeButton.matches('editPlaces') ? 'primary' : 'ghost'}
                            data-cy="edit-Places"
                            size="md">
                            <FontAwesomeIcon icon={faCar} />
                        </Button>
                    </Whisper>
                </SecuredFragment>
                {/* EDIT DISPLAYS */}
                <SecuredFragment authorizedRoles={[rolesConstants.mapTCMDisplays.VIEW]}>
                    <Whisper
                        trigger="hover"
                        placement={'autoHorizontal'}
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="map.actions.editDisplays" />
                            </Tooltip>
                        }>
                        <Button
                            disabled={this.props.needCalibration || !enableButtons}
                            appearance={this.props.activeButton.matches('editDisplays') ? 'primary' : 'ghost'}
                            className="button-map"
                            onClick={() => this.props.handleButtonClick('EDIT_DISPLAYS')}
                            data-cy="edit-Displays"
                            size="md">
                            <FontAwesomeIcon icon={faDesktop} />
                        </Button>
                    </Whisper>
                </SecuredFragment>
                {/* EDIT ZONE */}
                <SecuredFragment authorizedRoles={[rolesConstants.mapZones.VIEW]}>
                    <Whisper
                        trigger="hover"
                        placement={'autoHorizontal'}
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="map.actions.editZones" />
                            </Tooltip>
                        }>
                        <Button
                            disabled={this.props.needCalibration || !enableButtons}
                            appearance={this.props.activeButton.matches('editZones') ? 'primary' : 'ghost'}
                            className="button-map"
                            onClick={() => this.props.handleButtonClick('EDIT_ZONES')}
                            data-cy="edit-Zones"
                            size="md">
                            <FontAwesomeIcon icon={faCircle} />
                        </Button>
                    </Whisper>
                </SecuredFragment>
                {/* EDIT PASS COUNT */}
                <SecuredFragment authorizedRoles={[rolesConstants.mapVehicleCounters.VIEW]}>
                    <Whisper
                        trigger="hover"
                        placement={'autoHorizontal'}
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="map.actions.editPassCount" />
                            </Tooltip>
                        }>
                        <Button
                            disabled={this.props.needCalibration || !enableButtons}
                            appearance={this.props.activeButton.matches('editPassCount') ? 'primary' : 'ghost'}
                            data-cy="edit-passCounters"
                            className="button-map"
                            onClick={() => this.props.handleButtonClick('EDIT_PASS_COUNT')}
                            size="md">
                            <FontAwesomeIcon icon={faExchangeAlt} />
                        </Button>
                    </Whisper>
                </SecuredFragment>
                {/* EDIT SIGN */}
                {/* <Whisper
                    trigger="hover"
                    placement={'autoHorizontal'}
                    speaker={
                        <Tooltip>
                            <FormattedMessage id="map.actions.editSign" />
                        </Tooltip>
                    }>
                    <Button
                        appearance={this.props.activeButton.matches('editSign') ? 'primary' : 'ghost'}
                        className="button-map"
                        onClick={() => this.props.handleButtonClick('EDIT_SIGN')}
                        size="md">
                        <FontAwesomeIcon icon={faMapSigns} />
                    </Button>
                </Whisper> */}
                {/* CALIBRATION */}
                <SecuredFragment authorizedRoles={[rolesConstants.map.EDIT]}>
                    <Whisper
                        trigger="hover"
                        placement={'autoHorizontal'}
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="map.actions.calibration" />
                            </Tooltip>
                        }>
                        <Button
                            appearance={this.props.activeButton.matches('calibration') ? 'primary' : 'ghost'}
                            className={`button-map ${this.props.needCalibration && 'redBlink'}`}
                            onClick={() => this.props.handleButtonClick('CALIBRATION')}
                            data-cy="Calibration"
                            disabled={!enableButtons}
                            size="md">
                            <FontAwesomeIcon icon={faArrowsAlt} />
                        </Button>
                    </Whisper>
                </SecuredFragment>
            </ButtonGroup>
        );
    }
}
