import { IntlShape } from 'react-intl';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { IPCanHybrid } from '../../../handlers/ipcan/IpCanHybrid';
import IpCanLP from '../../../handlers/ipcan/IpCanLP';
import IpCanLPMatrixDisplayV2 from '../../../handlers/ipcan/IpCanLPMatrixDisplayV2';
import IPCanTcm from '../../../handlers/ipcan/IpCanTCM';

export enum IpCanModuleTypeEnum {
    ALL_TCM = 'all tcm type',
    LP_AND_TCM = 'lp and tcm',
    LP_MDV1 = 'lp matrixDisplayV1',
    LP_MDV2 = 'lp matrixDisplayV2',
}

export const ipCanModuleTypeFormValues = (intl: IntlShape): { label: string; value: string }[] => {
    return [
        {
            label: intl.formatMessage({ id: 'controller.information.type.all_tcm' }),
            value: IpCanModuleTypeEnum.ALL_TCM,
        },
        {
            label: intl.formatMessage({ id: 'controller.information.type.lpAndTcm' }),
            value: IpCanModuleTypeEnum.LP_AND_TCM,
        },
        {
            label: intl.formatMessage({ id: 'controller.information.type.lpMatrixDisplayV1' }),
            value: IpCanModuleTypeEnum.LP_MDV1,
        },
        {
            label: intl.formatMessage({ id: 'controller.information.type.lpMatrixDisplayV2' }),
            value: IpCanModuleTypeEnum.LP_MDV2,
        },
    ];
};

export const getIpCanModuleType = (ipcan: IpCan, intl: IntlShape) => {
    if (ipcan instanceof IPCanTcm) return intl.formatMessage({ id: 'controller.information.type.all_tcm' });
    if (ipcan instanceof IPCanHybrid) return intl.formatMessage({ id: 'controller.information.type.lpAndTcm' });
    if (ipcan instanceof IpCanLP) return intl.formatMessage({ id: 'controller.information.type.lpMatrixDisplayV1' });
    if (ipcan instanceof IpCanLPMatrixDisplayV2)
        return intl.formatMessage({ id: 'controller.information.type.lpMatrixDisplayV2' });
};

export const ipCanModuleTypeValue = (ipcan: IpCan): IpCanModuleTypeEnum | null => {
    if (ipcan instanceof IPCanTcm) return IpCanModuleTypeEnum.ALL_TCM;
    if (ipcan instanceof IPCanHybrid) return IpCanModuleTypeEnum.LP_AND_TCM;
    if (ipcan instanceof IpCanLP) return IpCanModuleTypeEnum.LP_MDV1;
    if (ipcan instanceof IpCanLPMatrixDisplayV2) return IpCanModuleTypeEnum.LP_MDV2;
    return null;
};
