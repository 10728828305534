import { Fragment, SyntheticEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, FlexboxGrid, Message, Panel } from 'rsuite';
import { Parking } from '../../handlers/parking/Parking';
import { SensorPlaceType } from '../../handlers/sensorPlaceType/SensorPlaceType';
import noImage from '../../style/assets/eye-slash-solid.svg';
import { CountersValues } from './CountersPage';
import { ParkingStatDoughnut } from './Doughnut/ParkingStatDoughnut';
import { PlaceTypeDoughnut } from './Doughnut/PlaceTypeDoughnut';
import { LevelPanel } from './LevelPanel';
import OccupationGraphModal from './Modal/OccupationGraphModal';
import OccupationGraph from './OccupationGraph';
import { ParkingWithOneLevel } from './ParkingWithOneLevel';

type Props = {
    parking: Parking;
    sensorPlaceTypes: SensorPlaceType[];
    counters: CountersValues;
};

export interface CounterStatus {
    id: number;
    name: string;
    counterValue: any;
}

export interface PlaceTypeCounterValue {
    id: number;
    label: string;
    image?: any;
    imageType: string;
    color: string;
    free: number;
    occupied: number;
}

export interface ParkingCounterValue {
    id: number;
    free: number;
    occupied: number;
}

export const ParkingPanel = (props: Props) => {
    const intl = useIntl();

    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

    const handleOpenOccupationGraph = (event: SyntheticEvent) => {
        event.stopPropagation();

        setHistoryModalOpen(true);
    };

    if (props.parking.getLevels().length === 1) {
        return (
            <ParkingWithOneLevel
                parking={props.parking}
                sensorPlaceTypes={props.sensorPlaceTypes}
                parkingCounter={props.counters.parkings.find(p => p.parkingId === props.parking.getId())}
            />
        );
    }

    // return null;
    const parkingCounterValue = props.counters.parkings.find(p => p.parkingId === props.parking.getId());
    const parkingTotalHeight = props.parking.getLevels().length === 2 ? 180 : 130;
    const parkingPlaceTypeHeight = props.parking.getLevels().length === 2 ? 130 : 100;

    const placeTypeCounterValue: PlaceTypeCounterValue[] = [];

    if (props.sensorPlaceTypes.length > 0 && parkingCounterValue) {
        Object.keys(parkingCounterValue.counterValue).forEach(placeTypeId => {
            if (placeTypeId !== 'all') {
                const currentSensorPlaceType = props.sensorPlaceTypes.find(
                    sensorPlaceType => sensorPlaceType.getId() === parseInt(placeTypeId)
                );

                if (currentSensorPlaceType) {
                    const currentPlaceType = currentSensorPlaceType.getPlaceType();

                    if (currentPlaceType) {
                        placeTypeCounterValue.push({
                            id: currentSensorPlaceType.getId(),
                            label: currentSensorPlaceType.getLabel(),
                            color: currentSensorPlaceType._scenes[0].color,
                            imageType: currentPlaceType.imageType,
                            image: currentSensorPlaceType.getPlaceType()?.generateImage(),
                            free: parkingCounterValue.counterValue[placeTypeId].free || 0,
                            occupied: parkingCounterValue.counterValue[placeTypeId].occupied || 0,
                        });
                    }
                }
            }
        });
    }

    return (
        <div className="text-center">
            <Panel
                style={{ width: 'fit-content', margin: 'auto' }}
                bordered
                collapsible
                className="rs-panel-collapsible-no-carret panel-no-padding text-center panel-parking"
                defaultExpanded
                bodyFill
                header={
                    <FlexboxGrid align="middle" className="flexbox-scrollable">
                        <FlexboxGrid.Item
                            componentClass={Col}
                            xs={2}
                            className="text-center margin-side-10"
                            style={{ width: 80 }}>
                            {props.parking.getImage() && (
                                <img
                                    src={
                                        props.parking.getBufferImage()
                                            ? `data:image/${props.parking.getImageType()};base64, ${props.parking.getBufferImage()}`
                                            : noImage
                                    }
                                    style={{
                                        height: 'auto',
                                        width: 80,
                                        backgroundColor: 'white',
                                        lineHeight: 1,
                                    }}
                                    alt="Logo"
                                />
                            )}
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item onClick={handleOpenOccupationGraph} className="text-center margin-side-10">
                            {parkingCounterValue && (
                                <OccupationGraph
                                    parking={props.parking}
                                    height={180}
                                    counterId={parkingCounterValue.id}
                                    level={props.parking.getLevels()[0]}
                                    displayLegend={false}
                                />
                            )}
                        </FlexboxGrid.Item>
                        {/* TOTAL */}
                        <FlexboxGrid.Item className="text-center margin-side-10">
                            <ParkingStatDoughnut
                                title={props.parking.getName()}
                                counterValue={parkingCounterValue!.counterValue.all}
                                height={parkingTotalHeight}
                            />
                        </FlexboxGrid.Item>

                        {placeTypeCounterValue.map(placeTypeCounterValue => (
                            <Fragment key={placeTypeCounterValue.id}>
                                <FlexboxGrid.Item className="margin-side-10">
                                    <PlaceTypeDoughnut
                                        placeTypeCounterValue={placeTypeCounterValue}
                                        height={parkingPlaceTypeHeight}
                                    />
                                </FlexboxGrid.Item>
                            </Fragment>
                        ))}
                    </FlexboxGrid>
                }>
                {props.parking.getLevels().length === 0 && (
                    <Message type="warning" description={intl.formatMessage({ id: 'counter.levels.noLevelsExists' })} />
                )}

                {props.parking
                    .getLevels()
                    .sort((a, b) => a.getOrder() - b.getOrder())
                    .map(level => {
                        const levelCounterValue = props.counters.levels.find(l => l.levelId === level.getId());
                        return (
                            <LevelPanel
                                level={level}
                                levelCounter={levelCounterValue}
                                sensorPlaceTypes={props.sensorPlaceTypes}
                                levelLength={props.parking.getLevels().length}
                            />
                        );
                    })}
            </Panel>

            {parkingCounterValue && (
                <OccupationGraphModal
                    show={historyModalOpen}
                    onHide={() => setHistoryModalOpen(false)}
                    counterId={parkingCounterValue.id}
                    level={props.parking.getLevels()}
                    parking={props.parking}
                />
            )}
        </div>
    );
};
