import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal } from 'rsuite';
import { placeTypeActions } from '../../../../redux/actions';

const mapDispatchToProps = dispatch => ({
    createPlaceType: label => dispatch(placeTypeActions.create(label)),
    closeCreateModal: () => dispatch(placeTypeActions.closeCreateModal()),
});

type Props = {
    closeCreateModal: Function;
    createPlaceType: Function;
    create_modal_openned: boolean;
} & WrappedComponentProps;

type State = {
    formValues: Record<string, any>;
    loading: boolean;
};

class CreatePlaceTypeModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            formValues: {
                label: '',
            },
            loading: false,
        };

        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createPlaceType = this.createPlaceType.bind(this);
    }

    closeModal() {
        this.setState({
            formValues: {
                label: '',
            },
        });

        this.props.closeCreateModal();
    }

    handleChange(formValues) {
        this.setState({
            formValues: formValues,
        });
    }

    createPlaceType() {
        this.props.createPlaceType(this.state.formValues.label);
        this.closeModal();
    }

    render() {
        return (
            <Modal backdrop="static" show={this.props.create_modal_openned} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="placeType.create.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="placeType-create-modal">
                    <Form fluid formValue={this.state.formValues} onChange={this.handleChange}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="placeType.create.label" />
                            </ControlLabel>
                            <FormControl name="label" data-cy="placeType-create-modal-label" />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={this.closeModal}
                            data-cy="placeType-create-cancel"
                            color="red"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.createPlaceType}
                            data-cy="placeType-create-valid"
                            appearance="primary"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    let { create_modal_openned } = state.placeTypes;

    return { create_modal_openned };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreatePlaceTypeModal));
