import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';

export default function tcmSensorForcedCell(isForce, forceType, endForceTime) {
    if (!isForce)
        return (
            <FontAwesomeIcon data-cy="ipCanSensor-forced-nothing" size="2x" icon={faTimes} style={{ color: 'red' }} />
        );

    return (
        <span className="text-center">
            {generateForceType(forceType)}
            <br />
            {endForceTime}
        </span>
    );
}

function generateForceType(forceType) {
    switch (forceType) {
        case 'tcm-sensor_disable_force':
            return <FormattedMessage id="sensor.force.noForce" />;
        case 1:
            return (
                <Tag data-cy="ipCanSensor-forced-free" color="green">
                    <FormattedMessage id="sensor.force.free" />
                </Tag>
            );
        case 2:
            return (
                <Tag data-cy="ipCanSensor-forced-occupied" color="red">
                    <FormattedMessage id="sensor.force.occupied" />
                </Tag>
            );
        case 3:
            return (
                <Tag data-cy="ipCanSensor-forced-overstayFree" color="green">
                    <FormattedMessage id="sensor.force.overstayFree" />
                </Tag>
            );
        case 4:
            return (
                <Tag data-cy="ipCanSensor-forced-overstayOccupied" color="red">
                    <FormattedMessage id="sensor.force.overstayOccupied" />
                </Tag>
            );
        case 11:
            return (
                <Tag data-cy="ipCanSensor-forced-green" color="green">
                    <FormattedMessage id="sensor.force.green" />
                </Tag>
            );
        case 12:
            return (
                <Tag data-cy="ipCanSensor-forced-red" color="red">
                    <FormattedMessage id="sensor.force.red" />
                </Tag>
            );
        case 13:
            return (
                <Tag data-cy="ipCanSensor-forced-black" style={{ backgroundColor: '#212121', color: 'white' }}>
                    <FormattedMessage id="sensor.force.black" />
                </Tag>
            );
        case 14:
            return (
                <Tag data-cy="ipCanSensor-forced-greenBlink" color="green">
                    <FormattedMessage id="sensor.force.greenBlink" />
                </Tag>
            );
        case 15:
            return (
                <Tag data-cy="ipCanSensor-forced-redBlink" color="red">
                    <FormattedMessage id="sensor.force.redBlink" />
                </Tag>
            );
        case 17:
            return (
                <Tag data-cy="ipCanSensor-forced-redGreenBlink" color="red">
                    <FormattedMessage id="sensor.force.redGreenBlink" />
                </Tag>
            );
        default:
            return null;
    }
}

tcmSensorForcedCell.propTypes = {
    rowData: PropTypes.object,
    dataKey: PropTypes.string,
    intl: PropTypes.object,
};
