import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function updateTime(option) {
    const { maxFree, maxOccupied, provOccupied } = option;

    return (
        <div>
            <div data-cy="historic-updateTime-maxFree">
                <FormattedMessage id="tcmSensor.history.maxFree" />
                <span className="margin-left-5">-</span>
                <span className="margin-left-5">{maxFree}</span>
            </div>
            <div data-cy="historic-updateTime-maxOccupied">
                <FormattedMessage id="tcmSensor.history.maxOccupied" />
                <span className="margin-left-5">-</span>
                <span className="margin-left-5">{maxOccupied}</span>
            </div>
            <div data-cy="historic-updateTime-provOccupied">
                <FormattedMessage id="tcmSensor.history.provOccupied" />
                <span className="margin-left-5">-</span>
                <span className="margin-left-5">{provOccupied}</span>
            </div>
        </div>
    );
}
