import { faCheck, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord } from 'react-data-table-component';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Col, FlexboxGrid, List, Panel } from 'rsuite';

type SensorStates = {
    label: string;
    steps: {
        led1: boolean;
        led2: boolean;
        led3: boolean;
        led4: boolean;
        led5: boolean;
        led6: boolean;
        color: string;
        time: number;
    }[];
};

type Props = RowRecord & WrappedComponentProps;

type State = {
    databaseData: SensorStates[];
};

class LPSensorUpdatePlaceType extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            databaseData: [],
        };
    }

    componentDidMount = () => {
        let databaseData: SensorStates[] = [
            {
                label: this.props.intl.formatMessage({
                    id: 'sensorPlaceTypeScene.free',
                }),
                steps: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'sensorPlaceTypeScene.occupied',
                }),
                steps: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'sensorPlaceTypeScene.overstay',
                }),
                steps: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'sensorPlaceTypeScene.reserved',
                }),
                steps: [],
            },
        ];

        for (let i = 0; i < this.props.data.option.scenes.length; i++) {
            let currentScene = this.props.data.option.scenes[i];

            let sceneConfigObject: any = {
                led1: currentScene.led1,
                led2: currentScene.led2,
                led3: currentScene.led3,
                led4: currentScene.led4,
                led5: currentScene.led5,
                led6: currentScene.led6,
                color: currentScene.color,
                time: currentScene.time,
            };

            databaseData[currentScene.state - 1].steps.push(sceneConfigObject);
        }

        this.setState({
            databaseData,
        });
    };

    render = () => (
        <FlexboxGrid justify="center" className="margin-top-10">
            <FlexboxGrid.Item componentClass={Col} xs={12}>
                <Panel className="panel-small" bordered bodyFill header={this.props.data.option.label}>
                    <List bordered hover>
                        {this.state.databaseData.map((scene: SensorStates) => {
                            return (
                                <Fragment>
                                    <List.Item className="separator-filter">
                                        <FlexboxGrid justify="center" align="middle">
                                            <FlexboxGrid.Item data-cy="history-lpSensor-label">
                                                {scene.label}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>

                                    {generateListLine(scene)}
                                </Fragment>
                            );
                        })}
                    </List>
                </Panel>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
}

function generateListLine(scene: SensorStates) {
    let listLine: any[] = [];

    for (let s in scene.steps) {
        let ledActive: any[] = [];

        const step = scene.steps[s];

        for (let i = 1; i <= 6; i++) {
            ledActive.push(
                <FlexboxGrid.Item data-cy="history-lpSensor-state">
                    <FontAwesomeIcon
                        icon={step[`led${i}`] ? faCheck : faTimes}
                        color={step[`led${i}`] ? 'green' : 'red'}
                        size="lg"
                    />
                </FlexboxGrid.Item>
            );
        }

        ledActive.push(
            <Fragment>
                <FlexboxGrid.Item data-cy="history-lpSensor-square">
                    <FontAwesomeIcon icon={faSquare} color={JSON.parse(JSON.stringify(step.color))} size="lg" />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item data-cy="history-lpSensor-time">{step.time} s</FlexboxGrid.Item>
            </Fragment>
        );

        listLine.push(ledActive);
    }

    return (
        <Fragment>
            {listLine.map(led => (
                <List.Item>
                    <FlexboxGrid data-cy="history-lpSensor-led" justify="space-between" align="middle">
                        {led}
                    </FlexboxGrid>
                </List.Item>
            ))}
        </Fragment>
    );
}

export default injectIntl(LPSensorUpdatePlaceType);
