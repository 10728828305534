import { IntlShape } from 'react-intl';
import { MaintenanceState } from '../../../../handlers/MaintenanceInformation';

export const deviceStateInformationFormValue = (intl: IntlShape) => [
    {
        label: intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState.everything_ok' }),
        value: MaintenanceState.EVERYTHING_OK,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState.low' }),
        value: MaintenanceState.LOW,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState.medium' }),
        value: MaintenanceState.MEDIUM,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState.high' }),
        value: MaintenanceState.HIGH,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.maintenanceInformation.maintenanceState.very_high' }),
        value: MaintenanceState.VERY_HIGH,
    },
];

export enum MaintenanceStateInformationEditURL {
    LP_MATRIX_DISPLAY_V2 = '/devices/lp-matrixdisplays-v2/updateMaintenanceState',
    TCM_DISPLAY = '/devices/tcm-display/updateMaintenanceState',
    LP_SENSOR = '/devices/lp-sensor/updateMaintenanceState',
    TCM_SENSOR = '/devices/tcm-sensor/updateMaintenanceState',
    IP_CAMERA = '/ip-cameras/updateMaintenanceState',
    IP_CAMERA_PLACE = '/places-camera/updateMaintenanceState',
}
