import moment from 'moment';
import { authHeader } from '../../redux/helpers';
import { axiosService } from '../../redux/services';
import { DeviceBase } from './DeviceBase';
import { LPSensorAutoCalibrationValue } from './Sensor/LPSensorAutoCalibValue';
import { LPSensorDebugValues } from './Sensor/LPSensorDebugValues';
import { LPSensorFilterConfiguration, LPSensorFilterConfiguration_I } from './Sensor/LPSensorFilterConfiguration';
import { LPSensorForceColorConf, LPSensorForceColorConf_I } from './Sensor/LPSensorForceColorConf';
import { LPSensorForceType } from './Sensor/LPSensorForceType';
import { LPSensorInformations } from './Sensor/LPSensorInformations';
import { LPSensorUsConfig } from './Sensor/LPSensorUsConfig';
import { LPSensorWebsocketUpdate_I } from './Sensor/LPSensorWebsocketUpdate';

export interface LPSensor_I {
    bus: number;
    createdAt: Date;
    createdBy: string;
    detectionState: number;
    detectionStateTime: Date;
    deviceId: number;
    startForceTime: Date;
    endForceTime: Date;
    eventForceColor: LPSensorForceColorConf_I;
    eventForceType: string;
    filterConfig: LPSensorFilterConfiguration_I;
    forceColorConf: LPSensorForceColorConf;
    forceType: LPSensorForceType;
    id: number;
    infoConfig: LPSensorInformations;
    isForce: false;
    keepAlive: number;
    lastOnlineStateDate: Date;
    online: boolean;
    overstay: number;
    updatedAt: Date;
    updatedBy: string;
    us1: LPSensorUsConfig;
    us2: LPSensorUsConfig;
    percent: number;
    sensorPlaceType: { id: number } | undefined;
    maintenanceState: number;
    notepad: string;
}

export class LPSensor extends DeviceBase {
    private _informations: LPSensorInformations;
    private _autoCalibrationValue: LPSensorAutoCalibrationValue;
    private _debugMode: boolean;

    private _overstay: number;
    private _keepAlive: number;

    private _us1Config: LPSensorUsConfig;
    private _us2Config: LPSensorUsConfig;

    private _forceType: LPSensorForceType;

    private _filterConfiguration: LPSensorFilterConfiguration;
    private _debugValues: LPSensorDebugValues;

    private _forceColorConf: LPSensorForceColorConf;
    private _detectionState: number;
    private _detectionStateTime: Date;

    private _eventForceType: string;

    private _eventForceColor: LPSensorForceColorConf_I;

    private _putData: Function;
    private _updateElements: Function;

    private _sensorPlaceType?: {
        id: number;
    };

    constructor(lpSensor_: LPSensor_I, ipCanId_, putData_, updateElements_) {
        super(
            lpSensor_.id,
            lpSensor_.bus,
            lpSensor_.deviceId,
            lpSensor_.online,
            lpSensor_.createdAt,
            lpSensor_.updatedAt,
            lpSensor_.createdBy,
            lpSensor_.updatedBy,
            lpSensor_.infoConfig.softwareVersion,
            lpSensor_.startForceTime,
            lpSensor_.endForceTime,
            lpSensor_.isForce,
            lpSensor_.lastOnlineStateDate,
            lpSensor_.maintenanceState,
            lpSensor_.notepad,
            ipCanId_
        );

        this._informations = new LPSensorInformations(lpSensor_.infoConfig);
        this._filterConfiguration = new LPSensorFilterConfiguration(lpSensor_.filterConfig);
        this._overstay = lpSensor_.overstay;
        this._keepAlive = lpSensor_.keepAlive;
        this._detectionState = lpSensor_.detectionState;
        this._detectionStateTime = lpSensor_.detectionStateTime;

        this._us1Config = new LPSensorUsConfig(lpSensor_.us1);
        this._us2Config = new LPSensorUsConfig(lpSensor_.us2);

        this._forceType = new LPSensorForceType(lpSensor_.forceType);

        this._autoCalibrationValue = new LPSensorAutoCalibrationValue(0, 0);
        this._debugMode = false;
        this._debugValues = new LPSensorDebugValues();

        this._forceColorConf = new LPSensorForceColorConf(lpSensor_.forceColorConf);

        this._eventForceType = lpSensor_.eventForceType;
        this._eventForceColor = lpSensor_.eventForceColor;

        this._putData = putData_;
        this._updateElements = updateElements_;
        this._sensorPlaceType = lpSensor_.sensorPlaceType;
    }

    public get informations(): LPSensorInformations {
        return this._informations;
    }

    public setInformations(_informations: LPSensorInformations) {
        this._informations = _informations;
    }

    public get autoCalibrationValue(): LPSensorAutoCalibrationValue {
        return this._autoCalibrationValue;
    }

    public setAutoCalibrationValue(_autoCalibrationValue: LPSensorAutoCalibrationValue) {
        this._autoCalibrationValue = _autoCalibrationValue;
    }

    public is_debugMode(): boolean {
        return this._debugMode;
    }

    public setDebugMode(_debugMode: boolean) {
        this._debugMode = _debugMode;
    }

    public get overstay(): number {
        return this._overstay;
    }

    public setOverstay(_overstay: number) {
        this._overstay = _overstay;
    }

    public get keepAlive(): number {
        return this._keepAlive;
    }

    public setKeepAlive(_keepAlive: number) {
        this._keepAlive = _keepAlive;
    }

    public get us1Config(): LPSensorUsConfig {
        return this._us1Config;
    }

    public setUs1Config(_us1Config: LPSensorUsConfig) {
        this._us1Config = _us1Config;
    }

    public get us2Config(): LPSensorUsConfig {
        return this._us2Config;
    }

    public setUs2Config(_us2Config: LPSensorUsConfig) {
        this._us2Config = _us2Config;
    }

    public get filterConfiguration(): LPSensorFilterConfiguration {
        return this._filterConfiguration;
    }

    public setFilterConfiguration(_filterConfiguration: LPSensorFilterConfiguration) {
        this._filterConfiguration = _filterConfiguration;
    }

    public get debugValues(): LPSensorDebugValues {
        return this._debugValues;
    }

    public setDebugValues(_debugValues: LPSensorDebugValues) {
        this._debugValues = _debugValues;
    }

    public get forceColorConf(): LPSensorForceColorConf {
        return this._forceColorConf;
    }

    public get detectionState(): number {
        return this._detectionState;
    }

    public get forceType() {
        return this._forceType;
    }

    public get detectionStateTimeFormatted(): string {
        // return formatDate(this._detectionStateTime);
        return moment(this._detectionStateTime).format('DD/MM/YYYY HH:mm:ss');
    }

    public get sensorPlaceType() {
        return this._sensorPlaceType;
    }

    public get deviceId(): number {
        return this._deviceId;
    }

    public setSensorPlaceType(sensorPlaceType_): boolean {
        if (this._sensorPlaceType?.id !== sensorPlaceType_?.id) {
            this._sensorPlaceType = sensorPlaceType_;
            return true;
        }

        return false;
    }

    public setDetectionState(detectionState_: number): boolean {
        if (this._detectionState !== detectionState_) {
            this._detectionState = detectionState_;
            return true;
        }
        return false;
    }

    public setDetectionStateTime(detectionStateTime_: Date): boolean {
        if (this._detectionStateTime !== detectionStateTime_) {
            this._detectionStateTime = detectionStateTime_;
            return true;
        }

        return false;
    }

    public setEventForceColor(eventForceColor_: LPSensorForceColorConf_I): boolean {
        if (this._eventForceColor !== eventForceColor_) {
            this._eventForceColor = eventForceColor_;
            return true;
        }
        return false;
    }

    public get eventForceColor(): LPSensorForceColorConf_I {
        return this._eventForceColor;
    }

    public setEventForceType(eventForceType_: string): boolean {
        if (this._eventForceType !== eventForceType_) {
            this._eventForceType = eventForceType_;
            return true;
        }
        return false;
    }

    public get eventForceType(): string {
        return this._eventForceType;
    }

    public setWebsocketInformation(data: LPSensorWebsocketUpdate_I) {
        let nbElementsUpdated = 0;

        if (this.setDetectionState(data.detectionState)) nbElementsUpdated++;
        if (this.setDetectionStateTime(data.detectionStateTime)) nbElementsUpdated++;
        if (this.setEndForceTime(data.endForceTime)) nbElementsUpdated++;
        if (this.setIsForce(data.isForce)) nbElementsUpdated++;
        if (this.setOnline(data.online)) nbElementsUpdated++;
        if (this.setLastOnlineStateDate(data.lastOnlineStateDate)) nbElementsUpdated++;
        if (this.forceType.setForceTypeInformations(data.forceType)) nbElementsUpdated++;
        if (this.informations.setInformations(data.infoConfig)) nbElementsUpdated++;
        if (this.filterConfiguration.setFilterValues(data.filterConfig)) nbElementsUpdated++;
        if (this.setEventForceColor(data.eventForceColor)) nbElementsUpdated++;
        if (this.setEventForceType(data.eventForceType)) nbElementsUpdated++;
        // if (this.setSensorPlaceType(data.sensorPlaceType)) nbElementsUpdated++;

        if (this._updateElements && nbElementsUpdated > 0) {
            this._updateElements();
        }
    }

    public updateSensor(data) {
        let nbElementsUpdated = 0;

        if (this.setDetectionState(data.detectionState)) nbElementsUpdated++;
        if (this.setDetectionStateTime(data.detectionStateTime)) nbElementsUpdated++;
        if (this.setEndForceTime(data.endForceTime)) nbElementsUpdated++;
        if (this.setIsForce(data.isForce)) nbElementsUpdated++;
        if (this.setOnline(data.online)) nbElementsUpdated++;
        if (this.setLastOnlineStateDate(data.lastOnlineStateDate)) nbElementsUpdated++;
        if (this.informations.setInformations(data.infoConfig)) nbElementsUpdated++;
        if (this.setSensorPlaceType(data.sensorPlaceType)) nbElementsUpdated++;
        if (this.setEventForceColor(data.eventForceColor)) nbElementsUpdated++;
        if (this.setEventForceType(data.eventForceType)) nbElementsUpdated++;

        if (this._updateElements && nbElementsUpdated > 0) {
            this._updateElements();
        }
    }

    public updateUsConfig(us1Height, us2Height) {
        return axiosService
            .getAxios()
            .put(
                '/devices/lp-sensor/updateUsConfig',
                {
                    id: this.id,
                    us1: {
                        height: us1Height,
                        sens: this.us1Config.sens,
                    },
                    us2: {
                        height: us2Height,
                        sens: this.us2Config.sens,
                    },
                    overstay: this.overstay,
                    keepAlive: this.keepAlive,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.us1Config.setSensorUsConfig(us1Height, this.us1Config.sens);
                this.us2Config.setSensorUsConfig(us2Height, this.us2Config.sens);

                this._updateElements();
            });
    }

    public updateCalibration(us1Height, us2Height, us1Sens, us2Sens, overstay) {
        let filterValue = 0;

        filterValue += this.filterConfiguration.filter.filter.freeToOccupied & 0xff;
        filterValue += (this.filterConfiguration.filter.median.freeToOccupied << 8) & 0xff00;
        filterValue += (this.filterConfiguration.filter.filter.occupiedToFree << 16) & 0xff0000;
        filterValue += (this.filterConfiguration.filter.median.occupiedToFree << 24) & 0xff000000;

        return axiosService
            .getAxios()
            .put(
                '/devices/lp-sensor/updateConfig',
                {
                    id: this.id,
                    nbSalveMin: this.filterConfiguration.nbSalveMin,
                    nbSalveMax: this.filterConfiguration.nbSalveMax,
                    nbInterSalveMin: this.filterConfiguration.nbInterSalveMin,
                    nbInterSalveMax: this.filterConfiguration.nbInterSalveMax,
                    filter: filterValue,
                    maskFree: this.filterConfiguration.maskFree,
                    maskOccupied: this.filterConfiguration.maskOccupied,
                    percent: this.filterConfiguration.percent,
                    us1: {
                        height: us1Height,
                        sens: us1Sens,
                    },
                    us2: {
                        height: us2Height,
                        sens: us2Sens,
                    },
                    overstay,
                    keepAlive: this._keepAlive,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.us1Config.setSensorUsConfig(us1Height, us1Sens);
                this.us2Config.setSensorUsConfig(us2Height, us2Sens);
                this.setOverstay(overstay);

                if (this._updateElements) {
                    this._updateElements();
                }
            });
    }

    public updateConfig(
        nbSalveMin,
        nbSalveMax,
        nbInterSalveMin,
        nbInterSalveMax,
        maskFree,
        maskOccupied,
        filter,
        us1Height,
        us2Height,
        us1Sens,
        us2Sens,
        overstay
    ) {
        const formattedNbInterSalveMin = parseInt(nbInterSalveMin, 10);
        const formattedNbInterSalveMax = parseInt(nbInterSalveMax, 10);
        const formattedNbSalveMin = parseInt(nbSalveMin, 10);
        const formattedNbSalveMax = parseInt(nbSalveMax, 10);
        return axiosService
            .getAxios()
            .put(
                '/devices/lp-sensor/updateConfig',
                {
                    id: this.id,
                    nbSalveMin: formattedNbSalveMin,
                    nbSalveMax: formattedNbSalveMax < formattedNbSalveMin ? formattedNbSalveMin : formattedNbSalveMax,
                    nbInterSalveMin: formattedNbInterSalveMin,
                    nbInterSalveMax:
                        formattedNbInterSalveMax < formattedNbInterSalveMin
                            ? formattedNbInterSalveMin
                            : formattedNbInterSalveMax,
                    filter,
                    maskFree,
                    maskOccupied,
                    percent: this.filterConfiguration.percent,
                    us1: {
                        height: us1Height,
                        sens: us1Sens,
                    },
                    us2: {
                        height: us2Height,
                        sens: us2Sens,
                    },
                    overstay,
                    keepAlive: this._keepAlive,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(response => {
                this.filterConfiguration.setFilterValues(response.data.filterConfig);
                this.us1Config.setSensorUsConfig(us1Height, us1Sens);
                this.us2Config.setSensorUsConfig(us2Height, us2Sens);
                this.setOverstay(overstay);

                if (this._updateElements) {
                    this._updateElements();
                }
            });
    }

    public autoCalibration(us1: number = 0, us2: number = 0) {
        return axiosService
            .getAxios()
            .put(
                '/devices/lp-sensor/autoCalibration',
                {
                    id: this.id,
                    us1,
                    us2,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(autoCalibrationResponse => {
                const response = autoCalibrationResponse.data;

                const us1Config: LPSensorUsConfig = new LPSensorUsConfig(response.us1);
                const us2Config: LPSensorUsConfig = new LPSensorUsConfig(response.us2);

                this.setUs1Config(us1Config);
                this.setUs2Config(us2Config);

                this._updateElements();
            });
    }

    public compare(sensor_: LPSensor): boolean {
        if (this._online !== sensor_.online) return false;

        return true;
    }
}
