import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, ControlLabel, FlexboxGrid, Form, FormControl, FormGroup, Modal } from 'rsuite';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    open: boolean;
    onClose: () => void;
    reloadUsers: () => void;
};

type FormValue = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
};

export const CreateUserModal = (props: Props) => {
    const intl = useIntl();

    const [isAdding, setIsAdding] = React.useState<boolean>(false);
    const [formValue, setFormValue] = React.useState<FormValue>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });

    const handleValid = () => {
        setIsAdding(true);

        axiosService
            .getAxios()
            .post('/users', formValue, {
                headers: authHeader(),
            })
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'user.add.success' }));

                handleClose(true);
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'user.add.error' }));
            })
            .finally(() => {
                setIsAdding(false);
            });
    };

    const handleClose = (shouldReload: boolean) => {
        if (!isAdding) {
            if (shouldReload) {
                props.reloadUsers();
            }

            setFormValue({
                firstName: '',
                lastName: '',
                email: '',
                password: '',
            });

            props.onClose();
        }
    };

    const handleFormChange = (value: FormValue) => {
        setFormValue(value);
    };

    const checkValidDisabled = () => {
        return !formValue.firstName || !formValue.lastName || !formValue.email || !formValue.password;
    };

    return (
        <Modal size="xs" show={props.open} onHide={() => handleClose(false)}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="user.create.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body data-cy="user-adduser-modal">
                <Form fluid formValue={formValue} onChange={value => handleFormChange(value as FormValue)}>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.create.firstName" />
                        </ControlLabel>
                        <FormControl name="firstName" data-cy="adduser-firstName" disabled={isAdding} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.create.lastName" />
                        </ControlLabel>
                        <FormControl name="lastName" data-cy="adduser-lastName" disabled={isAdding} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.create.email" />
                        </ControlLabel>
                        <FormControl name="email" type="email" data-cy="adduser-email" disabled={isAdding} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="user.create.password" />
                        </ControlLabel>
                        <FormControl name="password" type="password" data-cy="adduser-password" disabled={isAdding} />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <FlexboxGrid align="middle" justify="end">
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button
                                onClick={() => handleClose(false)}
                                color="red"
                                data-cy="user-adduser-modal-cancel"
                                disabled={isAdding}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                onClick={handleValid}
                                color="green"
                                loading={isAdding}
                                data-cy="user-adduser-modal-create"
                                disabled={checkValidDisabled()}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    );
};
