import React from 'react';
import { Header, Nav, Navbar } from 'rsuite';
import NavbarToggle from './navbarToggle';

type Props = {
    expanded: boolean;
    handleToggle: Function;
};

type State = {};
export default class Topnav extends React.Component<Props, State> {
    render() {
        return (
            <Header style={{ marginLeft: this.props.expanded ? 260 : 56 }}>
                <Navbar>
                    <Navbar.Body>
                        <Nav className="hide-on-desktop">
                            <NavbarToggle expanded={this.props.expanded} onChange={this.props.handleToggle} />
                        </Nav>
                    </Navbar.Body>
                </Navbar>
            </Header>
        );
    }
}
