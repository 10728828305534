import handleError from '../../handlers/error.handler';
import { userHandler } from '../../handlers/user.handler';
import { authHeader } from '../helpers';
import { axiosService } from './axios.services';

export const userService = {
    getAll,
    getById,
    create,
    updateInformations,
    updateUserPassword,
    updateUserRights,
    remove: remove,
};

function getAll() {
    return axiosService
        .getAxios()
        .get('/users', { headers: authHeader() })
        .then(response => response.data.map(user => userHandler(user)))
        .then(users => {
            return users;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function getById(id) {
    return axiosService
        .getAxios()
        .get(`/users/${id}`, { headers: authHeader() })
        .then(response => {
            return userHandler(response.data);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function create(firstName, lastName, email, password) {
    const parameters = { headers: authHeader() };
    const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
    };
    return axiosService
        .getAxios()
        .post('/users', data, parameters)
        .then(response => {
            let user = response.data;
            return userHandler(user);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateInformations(id, firstName, lastName, email, enabled) {
    const parameters = { headers: authHeader() };
    const data = { id, firstName, lastName, email, enabled };

    return axiosService
        .getAxios()
        .put('/users/updateInformations', data, parameters)
        .then(response => {
            let user = response.data;
            return userHandler(user);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateUserPassword(id, password, repeatedPassword) {
    const parameters = { headers: authHeader() };
    const data = { id, password, repeatedPassword };

    return axiosService
        .getAxios()
        .put('/users/updatePassword', data, parameters)
        .then(response => {
            let user = response.data;
            return userHandler(user);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function updateUserRights(id, roles) {
    const parameters = { headers: authHeader() };
    const data = { id, roles };

    return axiosService
        .getAxios()
        .put('/users/updateRoles', data, parameters)
        .then(response => {
            let user = response.data;
            return userHandler(user);
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}

function remove(id) {
    const data = {
        headers: authHeader(),
        data: { id: id },
    };
    return axiosService
        .getAxios()
        .delete('/users', data)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            handleError(err);
            return Promise.reject(err);
        });
}
