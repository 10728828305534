import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Message, Modal } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    closeCreateModal: Function;
    createModalOpen: boolean;
} & WrappedComponentProps;

type State = {
    isSubmitted: boolean;
    formValue: Record<string, any>;
    validEnabled: boolean;
    error: boolean;
};
class CreateIpCanModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isSubmitted: false,
            formValue: {
                label: '',
                mac: '',
                ip: '',
            },
            validEnabled: false,
            error: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            formValue: {
                label: '',
                mac: '',
                ip: '',
            },
            isSubmitted: false,
        });
        this.props.closeCreateModal();
    }

    handleChange(formValue) {
        const newFormValue = { ...this.state.formValue, ...formValue };

        this.setState({
            formValue: newFormValue,
            validEnabled: testNotEmpty(formValue.label) && testNotEmpty(formValue.mac) && testNotEmpty(formValue.ip),
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            isSubmitted: true,
            error: false,
        });

        const { mac, label, ip } = this.state.formValue;

        if (testNotEmpty(label) && testNotEmpty(mac) && testNotEmpty(ip)) {
            axiosService
                .getAxios()
                .post(
                    '/ipcanmodules',
                    {
                        label,
                        macStr: mac,
                        ip,
                    },
                    { headers: authHeader() }
                )
                .then(() => {
                    this.closeModal();
                });
        } else {
            this.setState({
                isSubmitted: false,
                error: true,
            });
        }
    }

    render() {
        return (
            <Modal size="xs" show={this.props.createModalOpen} onHide={this.closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="controller.add.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="IPCan-addIpCan-modal">
                    {this.state.error && (
                        <Message type="error" description={<FormattedMessage id="controller.add.errorMessage" />} />
                    )}
                    <Form fluid onChange={this.handleChange}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="controller.add.label" />
                            </ControlLabel>
                            <FormControl
                                data-cy="addIpCan-label"
                                name="label"
                                value={this.state.formValue.label}
                                disabled={this.state.isSubmitted}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="controller.add.macAddress" />
                            </ControlLabel>
                            <FormControl
                                name="mac"
                                data-cy="addIpCan-mac"
                                value={this.state.formValue.mac}
                                disabled={this.state.isSubmitted}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="controller.add.ipAddress" />
                            </ControlLabel>
                            <FormControl
                                name="ip"
                                data-cy="addIpCan-ip"
                                value={this.state.formValue.ip}
                                disabled={this.state.isSubmitted}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={this.closeModal} color="red" data-cy="IPCan-addIpCan-modal-cancel">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.handleSubmit}
                            appearance="primary"
                            loading={this.state.isSubmitted}
                            data-cy="IPCan-addIpCan-modal-create"
                            disabled={!this.state.validEnabled}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

function testNotEmpty(str) {
    if (str) {
        return str.trim().length >= 1;
    } else {
        return false;
    }
}

export default injectIntl(CreateIpCanModal);
