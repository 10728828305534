import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Modal } from 'rsuite';
import Counter from '../../../../handlers/Counter/Counter';
import { LPDisplay } from '../../../../handlers/lpDisplays/LPDisplay';
import { SensorPlaceType } from '../../../../handlers/sensorPlaceType/SensorPlaceType';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import CountersEditRow from '../Table/CountersEditRow';
import CountersRow from '../Table/CountersRow';
import CounterListItem from './CounterPart/CounterListItem';

type Props = {
    display: LPDisplay;
    show: boolean;
    onHide: Function;
    reloadDisplay?: () => void;
} & WrappedComponentProps;

type State = {
    counters: Counter[];
    sensorsPlaceTypes: SensorPlaceType[];
    isFetchingCounters: boolean;
    hasFetchCountersError: boolean;
    isUpdatingCounters: boolean;
    hasUpdateCountersError: boolean;
    formValue: FormValue;
    isEditMode: boolean;
};

type FormValue = {
    counter: FormValueCounterInterface[];
};

export interface FormValueCounterInterface {
    label: string;
    addCounters: {
        counters: number[];
        sensorPlaceType: number[][];
    };
    deleteCounters: {
        counters: number[];
        sensorPlaceType: number[][];
    };
}

class EditLPDisplayCountersModal extends React.Component<Props, State> {
    columns: TableColumn<RowRecord>[];

    constructor(props: Props) {
        super(props);

        this.state = {
            counters: [],
            sensorsPlaceTypes: [],
            isFetchingCounters: true,
            hasFetchCountersError: false,
            isUpdatingCounters: false,
            hasUpdateCountersError: false,
            formValue: {
                counter: props.display.getCountersConfig().map((c, index) => {
                    return {
                        label: `${this.props.intl.formatMessage({
                            id: 'ipCanDevices.lpDisplays.counterConfiguration.counter',
                        })} ${index + 1}`,
                        addCounters: {
                            counters: c.getAddCounter().map(addCounter => addCounter.counterId),
                            sensorPlaceType: c.getAddCounter().map(addCounter => addCounter.placeTypeId),
                        },
                        deleteCounters: {
                            counters: c.getSubstractCounter().map(subCounter => subCounter.counterId),
                            sensorPlaceType: c.getSubstractCounter().map(subCounter => subCounter.placeTypeId),
                        },
                    };
                }),
            },
            isEditMode: false,
        };

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.counterConfiguration.label',
                }),
                center: true,
                cell: row => row.label,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.counterConfiguration.addCounter',
                }),
                center: true,
                cell: row =>
                    this.state.isEditMode
                        ? CountersEditRow(row.addCounters, this.state.counters, this.state.sensorsPlaceTypes)
                        : CountersRow(row.addCounters, this.state.counters, this.state.sensorsPlaceTypes),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.lpDisplays.counterConfiguration.deleteCounters',
                }),
                center: true,
                cell: row =>
                    this.state.isEditMode
                        ? CountersEditRow(row.addCounters, this.state.counters, this.state.sensorsPlaceTypes)
                        : CountersRow(row.deleteCounters, this.state.counters, this.state.sensorsPlaceTypes),
            },
        ];
    }

    handleCounterChange = (displayIndex: number, index: number, counter: number, type: 'add' | 'sub') => {
        const currentCounterState = [...this.state.formValue.counter];

        if (type === 'add') {
            currentCounterState[displayIndex].addCounters.counters[index] = counter;
        } else {
            currentCounterState[displayIndex].deleteCounters.counters[index] = counter;
        }

        this.setState({
            formValue: {
                counter: currentCounterState,
            },
        });
    };

    handleSensorPlaceTypeChange = (
        displayIndex: number,
        index: number,
        sensorPlaceType: number[],
        type: 'add' | 'sub'
    ) => {
        const currentCounterState = [...this.state.formValue.counter];

        if (type === 'add') {
            currentCounterState[displayIndex].addCounters.sensorPlaceType[index] = sensorPlaceType;
        } else {
            currentCounterState[displayIndex].deleteCounters.sensorPlaceType[index] = sensorPlaceType;
        }

        this.setState({
            formValue: {
                counter: currentCounterState,
            },
        });
    };

    addCounter = (displayIndex: number, type: 'add' | 'sub') => {
        const currentCounterState = [...this.state.formValue.counter];

        if (type === 'add') {
            currentCounterState[displayIndex].addCounters.counters.push(0);
            currentCounterState[displayIndex].addCounters.sensorPlaceType.push(
                this.state?.sensorsPlaceTypes.map(spt => spt.getId())
            );
        } else {
            currentCounterState[displayIndex].deleteCounters.counters.push(0);
            currentCounterState[displayIndex].deleteCounters.sensorPlaceType.push(
                this.state?.sensorsPlaceTypes.map(spt => spt.getId())
            );
        }

        this.setState({
            formValue: {
                counter: currentCounterState,
            },
        });
    };

    deleteCounter = (displayIndex: number, counterIndex: number, type: 'add' | 'sub') => {
        const currentCounterState = [...this.state.formValue.counter];

        if (type === 'add') {
            currentCounterState[displayIndex].addCounters.counters.splice(counterIndex, 1);
            currentCounterState[displayIndex].addCounters.sensorPlaceType.splice(counterIndex, 1);
        } else {
            currentCounterState[displayIndex].deleteCounters.counters.splice(counterIndex, 1);
            currentCounterState[displayIndex].deleteCounters.sensorPlaceType.splice(counterIndex, 1);
        }

        this.setState({
            formValue: {
                counter: currentCounterState,
            },
        });
    };

    componentDidMount = () => {
        const fetchCounters = axiosService.getAxios().get('/counters', { headers: authHeader() });

        const fetchSensorsPlaceType = axiosService.getAxios().get('/sensor-place-type', { headers: authHeader() });

        Promise.all([fetchCounters, fetchSensorsPlaceType]).then(values => {
            const countersResponse = values[0].data;
            const sensorPlaceTypeResponse = values[1].data;

            this.setState({
                counters: countersResponse.map(counter => new Counter(counter)),
                sensorsPlaceTypes: sensorPlaceTypeResponse.map(sensorPlaceType => new SensorPlaceType(sensorPlaceType)),
                isFetchingCounters: false,
            });
        });
    };

    closeModal = (shouldReload: boolean = false) => {
        if (this.props.reloadDisplay) {
            this.props.reloadDisplay();
        }

        this.setState({
            isEditMode: false,
            formValue: {
                counter: this.props.display.getCountersConfig().map((c, index) => {
                    return {
                        label: `${this.props.intl.formatMessage({
                            id: 'ipCanDevices.lpDisplays.counterConfiguration.counter',
                        })} ${index + 1}`,
                        addCounters: {
                            counters: c.getAddCounter().map(addCounter => addCounter.counterId),
                            sensorPlaceType: c.getAddCounter().map(addCounter => addCounter.placeTypeId),
                        },
                        deleteCounters: {
                            counters: c.getSubstractCounter().map(subCounter => subCounter.counterId),
                            sensorPlaceType: c.getSubstractCounter().map(subCounter => subCounter.placeTypeId),
                        },
                    };
                }),
            },
        });
        this.props.onHide(shouldReload);
    };

    updateCounterConfig = () => {
        this.setState({
            // isUpdatingCounters: true,
            hasUpdateCountersError: false,
        });

        const data = this.state.formValue.counter.map(counter => {
            return {
                addCounter: counter.addCounters.counters.map((addCounter, index) => {
                    if (addCounter) {
                        return {
                            counterId: addCounter,
                            placeTypeId: counter.addCounters.sensorPlaceType[index],
                        };
                    }
                }),
                substractCounter: counter.deleteCounters.counters.map((deleteCounter, index) => {
                    if (deleteCounter) {
                        return {
                            counterId: deleteCounter,
                            placeTypeId: counter.deleteCounters.sensorPlaceType[index],
                        };
                    }
                }),
            };
        });

        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/updateCountersConfig',
                {
                    counters: data,
                    id: this.props.display.getId(),
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateCounterConfigResponse => {
                this.setState({
                    isUpdatingCounters: false,
                    isEditMode: false,
                });

                Alert.success(
                    this.props.intl.formatMessage({ id: 'ipCanDevices.lpDisplays.counterConfiguration.update.success' })
                );

                this.props.display.updateCountersConfig(updateCounterConfigResponse.data);

                this.closeModal(true);
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage(
                        { id: 'ipCanDevices.lpDisplays.counterConfiguration.update.error' },
                        { error: err }
                    )
                );
            });
    };

    render = () => (
        <Modal
            size="lg"
            full={this.state.isEditMode}
            backdrop="static"
            show={this.props.show}
            onHide={() => this.closeModal(false)}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="ipCanDevices.lpDisplays.counterConfiguration.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {this.state.formValue.counter.map((counter, index) =>
                    CounterListItem(
                        counter,
                        index,
                        this.state.isEditMode,
                        this.state.counters,
                        this.state.sensorsPlaceTypes,
                        this.handleCounterChange,
                        this.handleSensorPlaceTypeChange,
                        this.addCounter,
                        this.deleteCounter
                    )
                )}
            </Modal.Body>

            <Modal.Footer>
                {this.state.isEditMode ? (
                    <ButtonGroup className="margin-top-5 margin-right-10">
                        <Button
                            onClick={() => this.closeModal(false)}
                            data-cy="lpDisplay-counterConfiguration-edit-cancel"
                            color="red"
                            loading={this.state.isUpdatingCounters}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.updateCounterConfig}
                            data-cy="lpDisplay-counterConfiguration-edit-valid"
                            appearance="primary"
                            loading={this.state.isUpdatingCounters}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                ) : (
                    <Button
                        color="blue"
                        data-cy="lpDisplay-counterConfiguration-edit"
                        onClick={() => this.setState({ isEditMode: !this.state.isEditMode })}
                        className="margin-top-5 margin-right-10">
                        Edit
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default injectIntl(EditLPDisplayCountersModal);
