import moment from 'moment';

export function createParkingHandler(parking) {
    return {
        id: parking.id,
        name: parking.name,
        description: parking.description,
        updateInformations: parking.updatedAt
            ? `${parking.updatedBy} - ${moment(parking.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`
            : '-',
        createInformations: `${parking.createdBy} - ${moment(parking.createdAt).format('DD/MM/YYYY HH:mm:ss')}`,
        createdAt: moment(parking.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        updatedAt: moment(parking.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: parking.createdBy,
        updatedBy: parking.updatedBy,
        image: generateImage(parking.image),
        imageType: checkType(parking.imageType),
        levels: [],
        levelsLength: 0,
    };
}

function generateImage(image) {
    if (image) {
        let buffer = Buffer.from(image.data, 'base64');
        return buffer.toString('base64');
    } else {
        return '';
    }
}

function checkType(type) {
    if (type === 'svg') {
        return 'svg+xml';
    } else {
        return type;
    }
}
