import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'react-leaflet';
import { Button, Col, FlexboxGrid, List, Tag } from 'rsuite';
import { DrawerInformationInterface } from '../..';
import Counter from '../../../../handlers/Counter/Counter';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { MapLPMatrixDisplayV2 } from '../../../../handlers/map/MapLPMatrixDisplayV2';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import { DeviceStateInformationLogo } from '../../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformationLogo';
import ForceLPMatrixDisplayModal from '../../Components/LPMatrixDisplay/ForceLPMatrixDisplay/ForceLPMatrixDisplayModal';
import { LPDisplayDrawer } from './Drawer';

type CounterType = {
    number: number;
    addCounter: CounterElement[];
    substractCounter: CounterElement[];
};

type CounterElement = {
    counter?: Counter;
    placeType: any;
};

type Props = {
    controllers: IpCan[];
    display: MapLPMatrixDisplayV2;
    hover: boolean;
    reloadDisplays: Function;
    editMode: boolean;
    service: any;
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
    counters: Counter[];
    placeTypes: any[];
};

export const LPMatrixDisplayV2IconPopup = (props: Props) => {
    const currentController = props.controllers.find(
        controller => controller.id === props.display.getLpMatrixDisplayV2().getIpCanId()
    );

    const shouldShowDrawer =
        props.editMode &&
        props.drawerInformation.isOpen &&
        props.drawerInformation.type === 'lp-display' &&
        props.drawerInformation.id === props.display.getLpMatrixDisplayV2().getId();

    const [counters, setCounters] = useState<CounterType[]>([]);
    const [showForceModal, setShowForceModal] = useState<boolean>(false);

    useEffect(() => {
        const counterBuilder: CounterType[] = [];

        props.display
            .getLpMatrixDisplayV2()
            .getCountersConfig()
            .forEach((counterConfig, index) => {
                if (counterConfig.getAddCounter().length > 0 || counterConfig.getSubstractCounter().length > 0) {
                    counterBuilder.push({
                        number: index + 1,
                        addCounter: counterConfig.getAddCounter().map(addCounter => {
                            const foundCounter = props.counters.find(counter => counter.id === addCounter.counterId);

                            return {
                                counter: foundCounter,
                                placeType: addCounter.placeTypeId.map(placeType =>
                                    props.placeTypes.find(pt => pt.id === placeType)
                                ),
                            };
                        }),

                        substractCounter: counterConfig.getSubstractCounter().map(substractCounter => {
                            const foundCounter = props.counters.find(
                                counter => counter.id === substractCounter.counterId
                            );

                            return {
                                counter: foundCounter,
                                placeType: substractCounter.placeTypeId.map(placeType =>
                                    props.placeTypes.find(pt => pt.id === placeType)
                                ),
                            };
                        }),
                    });
                }
            });

        setCounters(counterBuilder);
    }, [props.display.getLpMatrixDisplayV2().getCountersConfig(), props.counters, props.placeTypes]);

    const hideForceModal = () => {
        setShowForceModal(false);

        props.service.send('RELOAD', { name: 'displays' });
    };

    return (
        <Fragment>
            {shouldShowDrawer && (
                <LPDisplayDrawer
                    show={props.drawerInformation.isOpen}
                    onHide={() => props.closeDrawer()}
                    display={props.display}
                    controllers={props.controllers}
                    service={props.service}
                />
            )}

            <ForceLPMatrixDisplayModal
                isOpen={showForceModal}
                onHide={() => hideForceModal()}
                display={props.display.getLpMatrixDisplayV2()}
            />

            <Popup autoPan={false} autoClose={false}>
                <h5>
                    <SecuredFragment authorizedRoles={[]}>
                        <FontAwesomeIcon
                            className="margin-right-5"
                            color={props.display.getLpMatrixDisplayV2().online ? 'green' : 'red'}
                            icon={faPlug}
                        />
                    </SecuredFragment>
                    <span className="margin-right-5">{props.display.label}</span>
                    <SecuredFragment authorizedRoles={[]}>
                        <DeviceStateInformationLogo
                            maintenanceState={props.display.getLpMatrixDisplayV2().getMaintenanceState()}
                        />
                    </SecuredFragment>
                </h5>

                <p>
                    {currentController?.label} -- {props.display.getLpMatrixDisplayV2().getBus() + 1} --{' '}
                    {props.display.getLpMatrixDisplayV2().getDeviceId()}
                </p>

                <SecuredFragment authorizedRoles={[]}>
                    <p>
                        <FormattedMessage id="map.lpMatrixDisplayV2.lastOnlineStateDate" /> :{' '}
                        {props.display.getLpMatrixDisplayV2().getLastOnlineStateDate()}
                    </p>
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.VIEW_COUNTERS]}>
                    <List className="margin-bottom-10">
                        {counters.map((counter, counter_index) => (
                            <List.Item key={`counter_${counter_index}`}>
                                <FlexboxGrid align="middle">
                                    <FlexboxGrid.Item componentClass={Col} xs={8} className="bold">
                                        <FormattedMessage
                                            id="map.lpMatrixDisplayV2.counter.number"
                                            values={{ number: counter.number }}
                                        />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={16}>
                                        {counter.addCounter.map((addCounter, addCounterIndex) => (
                                            <div key={`add_counter_${addCounterIndex}`}>
                                                <Tag color="green">{addCounter.counter?.label}</Tag>{' '}
                                                {addCounter.placeType.map(pt => (
                                                    <img
                                                        key={`add_counter_${addCounterIndex}_${pt.placeType.label}`}
                                                        alt={`${pt.placeType.label}-logo`}
                                                        src={`data:image/${pt.placeType.imageType};base64, ${pt.placeType.image}`}
                                                        height="24"
                                                    />
                                                ))}
                                            </div>
                                        ))}

                                        {counter.substractCounter.map((substractCounter, substractCounterIndex) => (
                                            <div key={`substract_counter_${substractCounterIndex}`}>
                                                <Tag color="green">{substractCounter.counter?.label}</Tag>{' '}
                                                {substractCounter.placeType.map(pt => (
                                                    <img
                                                        key={`substract_counter_${substractCounterIndex}_${pt.placeType.label}`}
                                                        alt={`${pt.placeType.label}-logo`}
                                                        src={`data:image/${pt.placeType.imageType};base64, ${pt.placeType.image}`}
                                                        height="24"
                                                    />
                                                ))}
                                            </div>
                                        ))}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                        ))}
                    </List>
                </SecuredFragment>

                {!props.hover && (
                    <Fragment>
                        <SecuredFragment authorizedRoles={[rolesConstants.lpMatrixDisplayV2.UPDATE_FORCE_TYPE]}>
                            <Button color="blue" block onClick={() => setShowForceModal(true)}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.forceDisplay" />
                            </Button>
                        </SecuredFragment>
                        {props.editMode && (
                            <Button
                                color="orange"
                                block
                                onClick={() =>
                                    props.openDrawer('lp-display', props.display.getLpMatrixDisplayV2().getId())
                                }>
                                <FormattedMessage id="ipCanDevices.lpDisplay.updateConfiguration" />
                            </Button>
                        )}
                    </Fragment>
                )}
            </Popup>
        </Fragment>
    );
};

export default LPMatrixDisplayV2IconPopup;
