import { userConstants } from '../constants';

const initialState = {
    isLoading: false,
    usersList: [],
    error: undefined,
    deleting: false,
};

type State = {
    isLoading: boolean;
    usersList: Array<Record<string, any>>;
    error: string | undefined;
    deleting: boolean;
};

export function users(state: State = initialState, action) {
    switch (action.type) {
        case userConstants.GET_ALL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.GET_ALL_SUCCESS:
            return {
                error: undefined,
                usersList: action.users.map(user => {
                    return { ...user, isLoading: false };
                }),
                isLoading: false,
            };
        case userConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case userConstants.CREATE_REQUEST:
            return {
                ...state,
                usersList: state.usersList.concat({
                    ...action.user,
                    isLoading: true,
                    roles: [],
                }),
            };
        case userConstants.CREATE_SUCCESS:
            return {
                ...state,
                usersList: state.usersList.map(user => {
                    if (user.id === action.id) {
                        return { ...action.user, isLoading: false, roles: [] };
                    } else {
                        return user;
                    }
                }),
            };
        case userConstants.CREATE_FAILURE:
            return {
                ...state,
                usersList: state.usersList.filter(user => user.id !== action.id),
            };
        case userConstants.UPDATE_REQUEST:
            return {
                ...state,
                usersList: state.usersList.map(user => (user.id === action.id ? { ...user, isLoading: true } : user)),
            };
        case userConstants.UPDATE_SUCCESS:
            return {
                ...state,
                usersList: state.usersList.map(user => {
                    if (user.id === action.user.id) {
                        return { ...action.user, isLoading: false };
                    } else {
                        return user;
                    }
                }),
                // TODO
            };
        case userConstants.UPDATE_FAILURE:
            return {
                ...state,
                usersList: state.usersList.map(user => {
                    if (user.id === action.id) {
                        return { ...user, isLoading: false };
                    } else {
                        return user;
                    }
                }),
            };
        case userConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                usersList: state.usersList.map(user => (user.id === action.id ? { ...user, isLoading: true } : user)),
                deleting: true,
            };
        case userConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                usersList: state.usersList.filter(user => user.id !== action.user.id),
                deleting: false,
            };
        case userConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                usersList: state.usersList.map(user => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                }),
                deleting: false,
            };
        // case userConstants.GETONE_REQUEST:
        //     return {
        //         ...state,
        //         fetchedUser: {},
        //     };
        // case userConstants.GETONE_SUCCESS:
        //     return {
        //         ...state,
        //         fetchedUser: action.user,
        //     };
        // case userConstants.GETONE_FAILURE:
        //     return {
        //         ...state,
        //         error: action.error,
        //     };
        // case userConstants.OPEN_CREATE_MODAL_SUCCESS:
        //     return {
        //         ...state,
        //         create_modal_openned: true,
        //         delete_modal_openned: false,
        //         update_modal_openned: false,
        //         view_modal_openned: false,
        //         modal_user: undefined,
        //     };
        // case userConstants.CLOSE_CREATE_MODAL:
        //     return {
        //         ...state,
        //         create_modal_openned: false,
        //         delete_modal_openned: false,
        //         update_modal_openned: false,
        //         view_modal_openned: false,
        //         modal_user: undefined,
        //     };
        // case userConstants.IO_USER_LOGGED_IN:
        //     return {
        //         ...state,
        //         usersList: state.usersList.map(user => {
        //             if (user.id === action.user.id) {
        //                 return { ...action.user, isLoading: false };
        //             } else {
        //                 return user;
        //             }
        //         }),
        //     };
        // case userConstants.IO_USER_LOGGED_OUT:
        //     return {
        //         ...state,
        //         usersList: state.usersList.map(user => {
        //             if (user.id === action.user.id) {
        //                 return { ...action.user, isLoading: false };
        //             } else {
        //                 return user;
        //             }
        //         }),
        //     };
        default:
            return state;
    }
}
