import { FlowElement } from 'react-flow-renderer';
import { LPDisplay } from '../../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplay } from '../../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep, VirtualDisplayStepState } from '../../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { ForceDataInterface } from './LPMatrixDisplayFactory';

export abstract class LPMatrixDisplayChartElement {
    constructor(readonly display: LPDisplay, readonly forceData: ForceDataInterface) {}

    public abstract getFlowChartElement(flowElement: FlowChartElementInterface): LPMatrixDisplayFlowElement | undefined;

    public generateElement(role: LPMatrixDisplayForceRole): FlowElement[] {
        let elements: FlowElement[] = [];

        const virtualDisplays = this.display.getVirtualDisplays();

        this.display
            .getTopologyConfig()
            .getTopologys()
            .forEach((topology, index) => {
                const topologyIsValid = virtualDisplays.some(vd =>
                    topology.virtualDisplays.includes(vd.getPosId() + 1)
                );

                if (topologyIsValid) {
                    const element = this.getFlowChartElement({
                        role,
                        type: LPMatrixDisplayChartElementType.NODE_TOPOLOGY,
                        index,
                        id: `topology-${index}`,
                        label: `Topologie ${index + 1}`,
                    });

                    if (element) {
                        elements.push(element.render());
                    }

                    const virtualDisplaysIndex = virtualDisplays.map(vd => vd.getPosId() + 1);

                    let topologyVirtualDisplays = topology.virtualDisplays.filter(tvd => {
                        return virtualDisplaysIndex.includes(tvd);
                    });

                    topologyVirtualDisplays.forEach((vd, vdIndex) => {
                        let edgeElement = this.getFlowChartElement({
                            role,
                            type: LPMatrixDisplayChartElementType.EDGE_VIRTUAL_DISPLAY_TO_TOPOLOGY,
                            index: vdIndex,
                            id: `virtualDisplay-${vd}-topology-${index}`,
                            topologyIndex: index,
                            virtualDisplayIndex: vd - 1,
                        });

                        if (edgeElement) {
                            elements.push(edgeElement.render());
                        }
                    });

                    topology.triggers.forEach((trigger, triggerIndex) => {
                        let edgeElement = this.getFlowChartElement({
                            role,
                            type: LPMatrixDisplayChartElementType.EDGE_TRIGGER_TO_TOPOLOGY,
                            index: triggerIndex,
                            id: `trigger-${triggerIndex}-topology-${index}`,
                            topologyIndex: index,
                        });

                        if (edgeElement) {
                            elements.push(edgeElement.render());
                        }
                    });
                }
            });

        virtualDisplays.forEach((virtualDisplay, virtualDisplayIndex) => {
            const lpDisplayElements = this.getFlowChartElement({
                role,
                type: LPMatrixDisplayChartElementType.NODE_VIRTUAl_DISPLAY,
                index: virtualDisplayIndex,
                id: `virtual-display-${virtualDisplayIndex}`,
                label: `Virtual Display ${virtualDisplayIndex + 1}`,
                virtualDisplay,
            });

            if (lpDisplayElements) {
                elements.push(lpDisplayElements.render());
            }

            const steps = virtualDisplay.getSteps().filter(step => step.getState() === VirtualDisplayStepState.ENABLED);

            const foundForcedVirtualDisplay = this.forceData.virtualDisplays.find(
                vd => vd.numVirtualDisplay === virtualDisplay.getPosId()
            );

            if (foundForcedVirtualDisplay) {
                const newStep = new VirtualDisplayStep({ ...foundForcedVirtualDisplay.step, isForcedStep: true });
                virtualDisplay.addForcedVirtualDisplayStep(newStep, foundForcedVirtualDisplay.mode);
            } else {
                virtualDisplay.removeForcedVirtualDisplayStep();
            }

            steps.forEach((_, stepIndex) => {
                const lpDisplayStepElements = this.getFlowChartElement({
                    role,
                    type: LPMatrixDisplayChartElementType.NODE_STEP,
                    index: stepIndex,
                    id: `step-${virtualDisplayIndex}-${stepIndex}`,
                    label: `Step ${stepIndex + 1}`,
                    virtualDisplay,
                    virtualDisplayIndex,
                    steps,
                });

                if (lpDisplayStepElements) {
                    elements.push(lpDisplayStepElements.render());
                }

                if (stepIndex === 0) {
                    const edgeElement = this.getFlowChartElement({
                        role,
                        type: LPMatrixDisplayChartElementType.EDGE_VIRTUAL_DISPLAY_TO_STEP,
                        index: stepIndex,
                        id: `virtualDisplay-${virtualDisplayIndex}-step-${stepIndex}`,
                        stepIndex,
                        virtualDisplayIndex,
                    });

                    if (edgeElement) {
                        elements.push(edgeElement.render());
                    }
                } else if (stepIndex === steps.length - 1) {
                    const edgeElement = this.getFlowChartElement({
                        role,
                        type: LPMatrixDisplayChartElementType.EDGE_STEP_TO_FIRST_STEP,
                        stepIndex,
                        index: stepIndex,
                        id: `step-${virtualDisplayIndex}-${stepIndex}-back-to-step-0`,
                        steps,
                        virtualDisplayIndex,
                    });

                    if (edgeElement) {
                        elements.push(edgeElement.render());
                    }

                    // const edgeBetweenElement = this.getFlowChartElement({
                    //     role,
                    //     type: LPMatrixDisplayChartElementType.EDGE_STEP_TO_STEP,
                    //     stepIndex,
                    //     index: stepIndex,
                    //     id: `step-${virtualDisplayIndex}-${stepIndex - 1}-to-step-${stepIndex}`,
                    //     steps,
                    //     virtualDisplayIndex,
                    // });

                    // if (edgeBetweenElement) {
                    //     elements.push(edgeBetweenElement.render());
                    // }
                }

                if (stepIndex > 0) {
                    const edgeElement = this.getFlowChartElement({
                        role,
                        type: LPMatrixDisplayChartElementType.EDGE_STEP_TO_STEP,
                        stepIndex,
                        index: stepIndex,
                        id: `step-${virtualDisplayIndex}-${stepIndex - 1}-to-step-${stepIndex}`,
                        steps,
                        virtualDisplayIndex,
                    });

                    if (edgeElement) {
                        elements.push(edgeElement.render());
                    }
                }
            });
        });

        return elements;
    }
}

export abstract class LPMatrixDisplayFlowElement {
    constructor(readonly id: string, readonly label?: string) {}

    public abstract render(): FlowElement;
}

export enum LPMatrixDisplayChartElementType {
    NODE_TOPOLOGY = 'topology',
    NODE_VIRTUAl_DISPLAY = 'virtual_display',
    NODE_STEP = 'step',
    EDGE_VIRTUAL_DISPLAY_TO_TOPOLOGY = 'virtual_display_to_topology',
    EDGE_VIRTUAL_DISPLAY_TO_STEP = 'virtual_display_to_step',
    EDGE_STEP_TO_STEP = 'step_to_step',
    EDGE_STEP_TO_FIRST_STEP = 'step_to_first_step',
    EDGE_TRIGGER_TO_TOPOLOGY = 'trigger_to_topology',
}

export enum LPMatrixDisplayForceRole {
    ROLE_ADVANCED = 'advanced',
    ROLE_NORMAL = 'normal',
    ROLE_BASIC = 'basic',
    NONE = 'none',
}

export interface FlowChartElementInterface {
    role: LPMatrixDisplayForceRole;
    type: LPMatrixDisplayChartElementType;
    index: number;
    id: string;
    label?: string;
    virtualDisplay?: VirtualDisplay;
    virtualDisplayIndex?: number;
    steps?: VirtualDisplayStep[];
    topologyIndex?: number;
    stepIndex?: number;
}
