import { ReactElement } from 'react';
import { IntlShape } from 'react-intl';
import { LPDisplay } from '../../../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplay } from '../../../../../../../handlers/lpDisplays/VirtualDisplay';
import { BanIcon } from '../../../../../LPDisplays/Icons/BanIcon';
import { CarPoolingIcon } from '../../../../../LPDisplays/Icons/CarPoolingIcon';
import { CircleIcon } from '../../../../../LPDisplays/Icons/CircleIcon';
import { CoveredParkIcon } from '../../../../../LPDisplays/Icons/CoveredParkIcon';
import { DirIcon } from '../../../../../LPDisplays/Icons/DirIcon';
import { ElectricalIcon } from '../../../../../LPDisplays/Icons/ElectricalIcon';
import { FamilyIcon } from '../../../../../LPDisplays/Icons/FamilyIcon';
import { HomeIcon } from '../../../../../LPDisplays/Icons/HomeIcon';
import { ParkingIcon } from '../../../../../LPDisplays/Icons/ParkingIcon';
import { PMRIcon } from '../../../../../LPDisplays/Icons/PMRIcon';
import { StarAltIcon } from '../../../../../LPDisplays/Icons/StarAltIcon';
import { StarIcon } from '../../../../../LPDisplays/Icons/StarIcon';
import { TaxiAltIcon } from '../../../../../LPDisplays/Icons/TaxiAltIcon';
import { TaxiIcon } from '../../../../../LPDisplays/Icons/TaxiIcon';
import { ThreeLinesCardIcon } from '../../../../../LPDisplays/Icons/ThreeLinesCardIcon';
import { ThreeMIcon } from '../../../../../LPDisplays/Icons/ThreeMIcon';
import { TimesIcon } from '../../../../../LPDisplays/Icons/TimesIcon';
import { TwoLinesCardAltIcon } from '../../../../../LPDisplays/Icons/TwoLinesCardAltIcon';
import { TwoLinesCardIcon } from '../../../../../LPDisplays/Icons/TwoLinesCardIcon';
import { VipIcon } from '../../../../../LPDisplays/Icons/VipIcon';
import { WrongWayIcon } from '../../../../../LPDisplays/Icons/WrongWayIcon';
import { WrongWayInvertedIcon } from '../../../../../LPDisplays/Icons/WrongWayInvertedIcon';

type FormValuesInputString = {
    label: string;
    value: string;
};

type FormValuesInputNumber = {
    label: string;
    value: number;
};

type FormValuesInputArrow = {
    label: string;
    value: number;
    orientation: number;
};

type FormValuesInputPicto = {
    label: ReactElement<any, any> | string;
    value: number;
};

export const LPVirtualDisplayArrow = (intl: IntlShape): FormValuesInputArrow[] => [
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.arrow.none' }),
        value: 0,
        orientation: 0,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.arrow.top' }),
        value: 1,
        orientation: 0,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.arrow.topRight' }),
        value: 2,
        orientation: 45,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.arrow.right' }),
        value: 3,
        orientation: 90,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.arrow.bottomRight' }),
        value: 4,
        orientation: 135,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.arrow.bottom' }),
        value: 5,
        orientation: 180,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.arrow.bottomLeft' }),
        value: 6,
        orientation: 225,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.arrow.left' }),
        value: 7,
        orientation: 270,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.arrow.topLeft' }),
        value: 8,
        orientation: 315,
    },
];

export const LPVirtualDisplayPicto = (intl: IntlShape): FormValuesInputPicto[] => [
    {
        value: 0,
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.picto.none' }),
    },
    {
        value: 1,
        label: <FamilyIcon color="#000000" />,
    },
    {
        value: 2,
        label: <PMRIcon color="#000000" />,
    },
    {
        value: 3,
        label: <ElectricalIcon color="#000000" />,
    },
    {
        value: 4,
        label: <ParkingIcon color="#000000" />,
    },
    {
        value: 5,
        label: <TaxiIcon color="#000000" />,
    },
    {
        value: 6,
        label: <TaxiAltIcon color="#000000" />,
    },
    {
        value: 7,
        label: <StarIcon color="#000000" />,
    },
    {
        value: 8,
        label: <StarAltIcon color="#000000" />,
    },
    {
        value: 9,
        label: <TwoLinesCardIcon color="#000000" />,
    },
    {
        value: 10,
        label: <TwoLinesCardAltIcon color="#000000" />,
    },
    {
        value: 11,
        label: <VipIcon color="#000000" />,
    },
    {
        value: 12,
        label: <CoveredParkIcon color="#000000" />,
    },
    {
        value: 13,
        label: <CarPoolingIcon color="#000000" />,
    },
    {
        value: 14,
        label: <ThreeLinesCardIcon color="#000000" />,
    },
    {
        value: 15,
        label: <DirIcon color="#000000" />,
    },
    {
        value: 16,
        label: <ThreeMIcon color="#000000" />,
    },
    {
        value: 17,
        label: <HomeIcon color="#000000" />,
    },
    {
        value: 18,
        label: <TimesIcon color="#000000" />,
    },
    {
        value: 19,
        label: <BanIcon color="#000000" />,
    },
    {
        value: 20,
        label: <CircleIcon color="#000000" />,
    },
    {
        value: 21,
        label: <WrongWayIcon color="#000000" />,
    },
    {
        value: 22,
        label: <WrongWayInvertedIcon color="#000000" />,
    },
];

export const LPVirtualDisplayPosition = (intl: IntlShape): FormValuesInputNumber[] => [
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.position.left' }),
        value: 1000,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.position.right' }),
        value: 2000,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.position.center' }),
        value: 0,
    },
];

export const LPVirtualDisplaySize = (intl: IntlShape): FormValuesInputNumber[] => [
    {
        label: '16px',
        value: 16,
    },
    {
        label: '32px',
        value: 32,
    },
    {
        label: '48px',
        value: 48,
    },
    {
        label: '64px',
        value: 64,
    },
    {
        label: '80px',
        value: 80,
    },
];

export const LPVirtualDisplayTextTransformation = (intl: IntlShape): FormValuesInputNumber[] => [
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.textTransformation.fixed' }),
        value: 0,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.textTransformation.autoAdjust' }),
        value: 1,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.textTransformation.autoDefil' }),
        value: 2,
    },
];

export const LPVirtualDisplayTransition = (intl: IntlShape): FormValuesInputNumber[] => [
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.transition.none' }),
        value: 0,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.transition.downToDown' }),
        value: 1,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.transition.upToDown' }),
        value: 2,
    },
];

export const LPVirtualDisplayTriggerAction = (intl: IntlShape): FormValuesInputNumber[] => {
    let triggers: FormValuesInputNumber[] = [];

    for (let i = 1; i <= 8; i++) {
        triggers.push({
            label: intl.formatMessage({ id: `ipCanDevices.lpDisplay.virtualDisplay.trigger.step_${i}` }),
            value: i,
        });
    }

    for (let i = 1; i <= 16; i++) {
        triggers.push({
            label: intl.formatMessage({ id: `ipCanDevices.lpDisplay.virtualDisplay.trigger.virtualDisplay_${i}` }),
            value: i + 100,
        });
    }

    for (let i = 1; i <= 16; i++) {
        triggers.push({
            label: intl.formatMessage({ id: `ipCanDevices.lpDisplay.virtualDisplay.trigger.text_${i}` }),
            value: i + 1000,
        });
    }

    return triggers;
};

export const LPVirtualDisplayMode = (intl: IntlShape, display: LPDisplay): FormValuesInputNumber[] => {
    let modes: FormValuesInputNumber[] = [
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.mode.arrow' }),
            value: 1,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.mode.picto' }),
            value: 2,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.transition.hour' }),
            value: 5,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.transition.date' }),
            value: 6,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.transition.hourAndDate' }),
            value: 7,
        },
        {
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.transition.pictoRGB' }),
            value: 20,
        },
    ];

    for (let i = 1; i <= 32; i++) {
        modes.push({
            label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.mode.counter' }, { index: i }),
            value: 100 + i,
        });
    }

    // for (let i = 1; i <= 16; i++) {
    //     modes.push({
    //         label: intl.formatMessage({ id: `ipCanDevices.lpDisplay.virtualDisplay.mode.text_${i}` }),
    //         value: 200 + i,
    //     });
    // }

    display
        .getTextConfig()
        .getEntries()
        .forEach((entry, index) => {
            modes.push({
                label: entry.join(' / '),
                value: 201 + index,
            });
        });

    display
        .getTextConfig()
        .getUserText()
        .forEach((userText, index) =>
            modes.push({
                label: userText,
                value: 301 + index,
            })
        );
    // for (let i = 1; i <= 8; i++) {
    //     modes.push({
    //         label: intl.formatMessage({ id: `ipCanDevices.lpDisplay.virtualDisplay.mode.userText_${i}` }),
    //         value: 300 + i,
    //     });
    // }

    return modes;
};

export const LPVirtualDisplayStateFormValues = (intl: IntlShape): FormValuesInputNumber[] => [
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.state.disable' }),
        value: 0,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.state.enable' }),
        value: 1,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.state.wait' }),
        value: 2,
    },
];

export const LPVirtualDisplayArrowAnimation = (intl: IntlShape): FormValuesInputNumber[] => [
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.animation.none' }),
        value: 0,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.animation.slide' }),
        value: 10000,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.animation.blink' }),
        value: 20000,
    },
];

export const LPVirtualDisplaySystemText = (intl: IntlShape): FormValuesInputNumber[] => [
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.full' }),
        value: 1,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.free' }),
        value: 2,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.closed' }),
        value: 3,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.open' }),
        value: 4,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.available' }),
        value: 5,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.welcome' }),
        value: 6,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.entry' }),
        value: 7,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.exit' }),
        value: 8,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.public' }),
        value: 9,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.private' }),
        value: 10,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.goUp' }),
        value: 11,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.goDown' }),
        value: 12,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.goForward' }),
        value: 13,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.subscribers' }),
        value: 14,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.employees' }),
        value: 15,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.reserved' }),
        value: 16,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.reservation' }),
        value: 17,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.vip' }),
        value: 18,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.ice' }),
        value: 19,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.karting' }),
        value: 20,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.fullExceptSubscription' }),
        value: 21,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.offices' }),
        value: 22,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.shops' }),
        value: 23,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.interparking' }),
        value: 24,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.text.stopMotor' }),
        value: 25,
    },
];

export const LPVirtualDisplayUserText = (userText: string[]): FormValuesInputNumber[] => {
    return userText.map((userText, index) => {
        return {
            label: userText,
            value: index + 1,
        };
    });
};

export const LPVirtualDisplayTriggerType = (intl: IntlShape): FormValuesInputNumber[] => [
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.triggerType.none' }),
        value: -1,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.triggerType.step' }),
        value: 0,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.triggerType.virtualDisplay' }),
        value: 100,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.triggerType.text' }),
        value: 1000,
    },
    {
        label: intl.formatMessage({ id: 'ipCanDevices.lpDisplay.virtualDisplay.triggerType.userText' }),
        value: 2000,
    },
];

export const LPVirtualDisplayTriggerSteps = (
    intl: IntlShape,
    virtualDisplay: VirtualDisplay
): FormValuesInputNumber[] => {
    let stepsForm: { label: string; value: number }[] = [];

    for (let i = 0; i < virtualDisplay.getSteps().length; i++) {
        stepsForm.push({
            label: `${intl.formatMessage({
                id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.trigger.step',
            })} ${i + 1}`,
            value: i + 1,
        });
    }

    return stepsForm;
};

export const LPVirtualDisplayTriggerVirtualDisplay = (intl: IntlShape, display: LPDisplay): FormValuesInputNumber[] => {
    let virtualDisplayForm: { label: string; value: number }[] = [];

    for (let i = 0; i < display.getVirtualDisplays().length; i++) {
        virtualDisplayForm.push({
            label: intl.formatMessage(
                {
                    id: 'ipCanDevices.lpDisplay.virtualDisplay.steps.create.trigger.virtualDisplay',
                },
                {
                    stepNumber: i + 1,
                }
            ),
            value: i + 1,
        });
    }

    return virtualDisplayForm;
};

export const LPVirtualDisplayStepColor = (): string[] => {
    let colorsArray: string[] = [];

    for (let blue = 0; blue < 16; blue += 4) {
        const finalBlueHex = generate4BitsColorHex(blue);
        for (let green = 0; green < 16; green += 4) {
            const finalGreenHex = generate4BitsColorHex(green);
            for (let red = 0; red < 16; red += 4) {
                const finalRedHex = generate4BitsColorHex(red);

                const finalColor = `#${finalRedHex}${finalGreenHex}${finalBlueHex}`;

                colorsArray.push(finalColor);
            }
        }
    }

    return colorsArray;
};

const generate4BitsColorHex = (color): string => {
    let finalColorDecimal = color << 4;

    const colorHex = finalColorDecimal.toString(16).toUpperCase();

    return colorHex.length < 2 ? colorHex + '0' : colorHex;
};
