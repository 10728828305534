import { faArrowDown, faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, ButtonGroup, Checkbox, Col, FlexboxGrid, Message, Modal } from 'rsuite';
import { LPSensor } from '../../../handlers/ipCanDevices/LPSensor';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    sensorToCopy: LPSensor | null;
    sensorsToCopyOn: Array<LPSensor>;
    show: boolean;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    isLoading: boolean;
    hasError: boolean;
    error: any | null;
    formValue: FormValue;
};

type FormValue = {
    filterConfig: boolean;
    OverstayConfig: boolean;
    us1HeightConfig: boolean;
    us1SensConfig: boolean;
    us2HeightConfig: boolean;
    us2SensConfig: boolean;
};

class CopySensorModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            hasError: false,
            error: null,
            formValue: {
                filterConfig: true,
                OverstayConfig: true,
                us1HeightConfig: true,
                us1SensConfig: true,
                us2HeightConfig: true,
                us2SensConfig: true,
            },
        };

        this.launchCopy = this.launchCopy.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    launchCopy = () => {
        this.setState({
            isLoading: true,
            hasError: false,
            error: null,
        });

        axiosService
            .getAxios()
            .put(
                '/devices/lp-sensor/copyConfig',
                {
                    id: this.props.sensorToCopy!.id,
                    tabId: this.props.sensorsToCopyOn.map(sensor => sensor.id),
                    filterConfig: this.state.formValue.filterConfig,
                    keepAliveConfig: false,
                    OverstayConfig: this.state.formValue.OverstayConfig,
                    us1HeightConfig: this.state.formValue.us1HeightConfig,
                    us1SensConfig: this.state.formValue.us1SensConfig,
                    us2HeightConfig: this.state.formValue.us2HeightConfig,
                    us2SensConfig: this.state.formValue.us2SensConfig,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                this.setState({
                    isLoading: false,
                });
            })
            .then(() => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'lpSensors.copy.success',
                    })
                );

                this.closeModal(true);
            })
            .catch(err => {
                this.setState({
                    hasError: err,
                    error: err,
                });
            });
    };

    closeModal = (success: boolean = false) => {
        if (!this.state.isLoading) {
            this.props.onHide(success);
        }
    };

    handleChange = (value, checked) => {
        this.setState({
            formValue: {
                ...this.state.formValue,
                [value]: checked,
            },
        });
    };

    render = () => {
        if (this.props.sensorToCopy) {
            const {
                OverstayConfig,
                filterConfig,
                us1HeightConfig,
                us1SensConfig,
                us2HeightConfig,
                us2SensConfig,
            } = this.state.formValue;

            const shouldAllowSensorCopy =
                OverstayConfig || filterConfig || us1HeightConfig || us1SensConfig || us2HeightConfig || us2SensConfig;

            return (
                <Modal backdrop="static" show={this.props.show} onHide={() => this.closeModal()}>
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage
                                id="lpSensors.copy.title"
                                values={{ bus: this.props.sensorToCopy.bus, address: this.props.sensorToCopy.deviceId }}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.hasError && (
                            <Message
                                type="error"
                                description={this.props.intl.formatMessage(
                                    {
                                        id: 'lpSensors.copy.error',
                                    },
                                    {
                                        error: this.state.error,
                                    }
                                )}
                            />
                        )}
                        <p>
                            <FormattedMessage id="lpSensors.copy.body" />
                        </p>
                        <FlexboxGrid align="middle" justify="center">
                            <FlexboxGrid.Item componentClass={Col} xs={3}>
                                <div className="sensor-to-copy">
                                    {this.props.sensorToCopy.bus + 1} - {this.props.sensorToCopy.deviceId}
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid align="middle" justify="center" className="margin-top-10 margin-bottom-10">
                            <FlexboxGrid.Item>
                                <FontAwesomeIcon icon={faArrowDown} size="2x" style={{ textAlign: 'center' }} />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid align="middle" justify="center">
                            {this.props.sensorsToCopyOn.map((item: LPSensor) => (
                                <FlexboxGrid.Item componentClass={Col} xs={3} className="margin-bottom-5">
                                    <div className="sensor-to-be-copied">
                                        {item.bus + 1} - {item.deviceId}
                                    </div>
                                </FlexboxGrid.Item>
                            ))}
                        </FlexboxGrid>

                        <FlexboxGrid
                            style={{ backgroundColor: '#CFCFCF' }}
                            align="middle"
                            justify="center"
                            className="margin-top-10">
                            <FlexboxGrid.Item>
                                <Checkbox
                                    value="filterConfig"
                                    checked={this.state.formValue.filterConfig}
                                    onChange={this.handleChange}>
                                    <FormattedMessage id="lpSensors.copy.filterConfig" />
                                </Checkbox>
                                <Checkbox
                                    value="OverstayConfig"
                                    checked={this.state.formValue.OverstayConfig}
                                    onChange={this.handleChange}>
                                    <FormattedMessage id="lpSensors.copy.OverstayConfig" />
                                </Checkbox>
                                <Checkbox
                                    value="us1HeightConfig"
                                    checked={this.state.formValue.us1HeightConfig}
                                    onChange={this.handleChange}>
                                    <FormattedMessage id="lpSensors.copy.us1HeightConfig" />
                                </Checkbox>
                                <Checkbox
                                    value="us1SensConfig"
                                    checked={this.state.formValue.us1SensConfig}
                                    onChange={this.handleChange}>
                                    <FormattedMessage id="lpSensors.copy.us1SensConfig" />
                                </Checkbox>
                                <Checkbox
                                    value="us2HeightConfig"
                                    checked={this.state.formValue.us2HeightConfig}
                                    onChange={this.handleChange}>
                                    <FormattedMessage id="lpSensors.copy.us2HeightConfig" />
                                </Checkbox>
                                <Checkbox
                                    value="us2SensConfig"
                                    checked={this.state.formValue.us2SensConfig}
                                    onChange={this.handleChange}>
                                    <FormattedMessage id="lpSensors.copy.us2SensConfig" />
                                </Checkbox>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button
                                data-cy="ipCanDevices-lpSensor-copy-cancel"
                                onClick={() => this.closeModal()}
                                color="red"
                                disabled={this.state.isLoading}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                data-cy="ipCanDevices-lpSensor-copy-valid"
                                onClick={() => this.launchCopy()}
                                appearance="primary"
                                loading={this.state.isLoading}
                                disabled={!shouldAllowSensorCopy}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            );
        }

        return null;
    };
}

export default injectIntl(CopySensorModal);
