import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Whisper } from 'rsuite';

export default function readRealSensorConfig() {
    return (
        <div data-cy="history-readRealSensorConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.readRealSensorConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faBookOpen} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}
