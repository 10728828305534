import React from 'react';
import DataTable, { TableProps, TableStyles } from 'react-data-table-component';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Loader } from 'rsuite';

type Props = {
    small?: boolean;
} & WrappedComponentProps &
    TableProps<any>;

type State = {
    histories: Array<Record<string, any>>;
    isLoading: boolean;
    hasError: boolean;
    users: Array<Record<string, any>>;
    customStyle: Record<string, any>;
    formValues: FormValu;
};

type FormValu = {
    user: Record<string, any> | null;
    before: Date | null;
    after: Date | null;
    nbElements: number;
};

class ElementTable extends React.Component<Props, State> {
    customStyles: TableStyles | undefined;

    constructor(props) {
        super(props);

        this.customStyles = {
            ...props.customStyles,
            headRow: {
                style: {
                    backgroundColor: '#757575',
                    minHeight: props.small ? '28px' : '56px',
                },
            },
            headCells: {
                style: {
                    fontSize: '15px',
                    fontWeight: 500,
                    color: '#FFF',
                },
                activeSortStyle: {
                    color: '#BDBDBD',
                    '&:focus': {
                        outline: 'none',
                    },
                    '&:hover:not(:focus)': {
                        color: '#BDBDBD',
                    },
                },
                inactiveSortStyle: {
                    '&:focus': {
                        outline: 'none',
                        color: '#BDBDBD',
                    },
                    '&:hover': {
                        color: '#BDBDBD',
                    },
                },
            },
        };
    }

    render() {
        return (
            <DataTable
                {...this.props}
                columns={this.props.columns}
                data={this.props.data}
                customStyles={this.customStyles}
                progressComponent={
                    <div style={{ padding: 10 }}>
                        <Loader size="lg" content={this.props.intl.formatMessage({ id: 'loading' })} vertical />
                    </div>
                }
                persistTableHead
                highlightOnHover
                striped
                fixedHeader
                fixedHeaderScrollHeight={this.props.fixedHeaderScrollHeight || '75vh'}
            />
        );
    }
}

export default injectIntl(ElementTable);
