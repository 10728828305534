import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button, ButtonGroup, Modal } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    closeDeleteModal: Function;
    fetchedIpcan: Record<string, any>;
    deleteModalOpen: boolean;
} & WrappedComponentProps;

type State = {
    isSubmitted: boolean;
};
class DeleteIpCanModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isSubmitted: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(deleted: boolean = false) {
        this.setState({
            isSubmitted: false,
        });

        this.props.closeDeleteModal(deleted);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            isSubmitted: true,
        });

        this.deleteIpCan();
    }

    deleteIpCan() {
        const data = {
            headers: authHeader(),
            data: { id: this.props.fetchedIpcan.id },
        };

        axiosService
            .getAxios()
            .delete('ipcanmodules', data)
            .then(() => {
                this.closeModal(true);
            });
    }

    render() {
        return (
            <Modal size="xs" show={this.props.deleteModalOpen} onHide={() => this.closeModal()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="controller.delete.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy="IPCan-delete-modal">
                    <FormattedMessage id="controller.delete.message" />

                    <p className="bold">{this.props.fetchedIpcan.label}</p>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button onClick={() => this.closeModal()} color="red" data-cy="ipCan-delete-cancel">
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.handleSubmit}
                            loading={this.state.isSubmitted}
                            color="green"
                            data-cy="ipCan-delete-valid">
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(DeleteIpCanModal);
