import React from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'rsuite';
import { LPDisplay } from '../../../handlers/lpDisplays/LPDisplay';
import { FlowChart } from './FlowChart';

type Props = {
    display: LPDisplay;
    show: boolean;
    onHide: (boolean) => void;
    reloadDisplay?: () => void;
};

const LPMatrixDisplayV2Config = (props: Props) => {
    const closeModal = () => {
        props.onHide(true);

        if (props.reloadDisplay) {
            props.reloadDisplay();
        }
    };

    return (
        <Modal show={props.show} onHide={() => closeModal()} full overflow={false}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="ipCanElements.lpDisplay.editTopology" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReactFlowProvider>
                    <FlowChart display={props.display} />
                </ReactFlowProvider>
            </Modal.Body>
        </Modal>
    );
};

export default LPMatrixDisplayV2Config;
