import React from 'react';
import { RowRecord } from 'react-data-table-component';
import LPSensorReadConfig from './LPSensorReadConfig';
import LPSensorReadSceneConfig from './LPSensorReadSceneConfig';
import LPSensorSendScenes from './LPSensorSendScenes';
import LPSensorUpdatePlaceType from './LPSensorUpdatePlaceType';
import LPSensorUpdateUsAndFilterConfig from './LPSensorUpdateUsAndFilterConfig';

type Props = RowRecord;

export default class LPSensorExpandable extends React.Component<Props> {
    render = () => {
        if (this.props.data.desc === 'update us and filter config')
            return <LPSensorUpdateUsAndFilterConfig data={this.props.data} />;
        if (this.props.data.desc === 'read config') return <LPSensorReadConfig data={this.props.data} />;
        if (this.props.data.desc === 'read scene config') return <LPSensorReadSceneConfig data={this.props.data} />;
        if (this.props.data.desc === 'send scenes') return <LPSensorSendScenes data={this.props.data} />;
        if (this.props.data.desc === 'update place type') return <LPSensorUpdatePlaceType data={this.props.data} />;

        return null;
    };
}
