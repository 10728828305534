import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';
import Camera from '../../../../../handlers/Camera/Camera';
import PanelHeader from '../../../../Custom/PanelHeader';
import { InformationEditMode } from './InformationEditMode';
import { InformationViewMode } from './InformationViewMode';

type Props = {
    camera: Camera;
};

export const CameraInformationCard = (props: Props) => {
    const [isEditMode, setIsEditMode] = React.useState(false);

    const intl = useIntl();

    return (
        <Panel
            className="panel-small"
            shaded
            bordered
            bodyFill
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'camera.information' })}
                    buttons={[
                        <Button
                            key="maintenanceInformation-edit"
                            color="blue"
                            size="sm"
                            disabled={isEditMode}
                            onClick={() => setIsEditMode(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {isEditMode ? (
                <InformationEditMode camera={props.camera} setViewMode={() => setIsEditMode(false)} />
            ) : (
                <InformationViewMode camera={props.camera} />
            )}
        </Panel>
    );
};
