import {
    faIdCard,
    faToggleOn,
    faSyncAlt,
    faPowerOff,
    faToggleOff,
    faDatabase,
    faEnvelopeOpenText,
    faCogs,
    faCog,
    faBookOpen,
    faBusinessTime,
    faRedoAlt,
    faMap,
    faFileImport,
    faAddressCard,
    faLaptopCode,
    faScrewdriver,
    faUserEdit,
    faArrowsAlt,
    faEdit,
    faHeading,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Whisper } from 'rsuite';

function lpMatrixDisplayV2_readUserTextsValue() {
    return (
        <div data-cy="historic-icon-readUserTextsValue">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.readUserTextsValue" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faIdCard} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}
function lpMatrixDisplayV2_readDictionnaryValue() {
    return (
        <div data-cy="historic-icon-readDictionnaryValue">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.readDictionnaryValue" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faBookOpen} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendAllBasicConfig() {
    return (
        <div data-cy="historic-icon-sendAllBasicConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendAllBasicConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faCogs} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendBasicConfig() {
    return (
        <div data-cy="historic-icon-sendBasicConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendBasicConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faCog} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendCounterConfig() {
    return (
        <div data-cy="historic-icon-sendCounterConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendCounterConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faBusinessTime} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendDBConfig() {
    return (
        <div data-cy="historic-icon-sendDBConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendDBConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faDatabase} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendKeyTextConfig() {
    return (
        <div data-cy="historic-icon-sendKeyTextConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendKeyTextConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faEnvelopeOpenText} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendMatrixOff() {
    return (
        <div data-cy="historic-icon-sendMatrixOff">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendMatrixOff" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faToggleOff} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendMatrixOn() {
    return (
        <div data-cy="historic-icon-sendMatrixOn">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="sendMatrixOn.sendScene" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faToggleOn} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendReboot() {
    return (
        <div data-cy="historic-icon-sendReboot">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendReboot" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faPowerOff} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendReloadConfig() {
    return (
        <div data-cy="historic-icon-sendReloadConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendReloadConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faSyncAlt} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendResetMemory() {
    return (
        <div data-cy="historic-icon-sendResetMemory">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendResetMemory" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faRedoAlt} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendTopologyConfig() {
    return (
        <div data-cy="historic-icon-sendTopologyConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendTopologyConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faMap} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendTXTConfig() {
    return (
        <div data-cy="historic-icon-sendTXTConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendTXTConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faFileImport} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendUserTextConfig() {
    return (
        <div data-cy="historic-icon-sendUserTextConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendUserTextConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faAddressCard} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_sendVirtualDisplayConfig() {
    return (
        <div data-cy="historic-icon-sendVirtualDisplayConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.sendVirtualDisplayConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faLaptopCode} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_updateBasicConfig() {
    return (
        <div data-cy="historic-icon-updateBasicConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.updateBasicConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faScrewdriver} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_updateUserTextConfig() {
    return (
        <div data-cy="historic-icon-updateUserTextConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.updateUserTextConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faUserEdit} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_updateTopologyConfig() {
    return (
        <div data-cy="historic-icon-updateTopologyConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.updateTopologyConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faArrowsAlt} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_updateVirtualDisplayConfig() {
    return (
        <div data-cy="historic-icon-updateVirtualDisplayConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.updateVirtualDisplayConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faEdit} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

function lpMatrixDisplayV2_updateKeyTextConfig() {
    return (
        <div data-cy="historic-icon-updateKeyTextConfig">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.updateKeyTextConfig" />
                    </Tooltip>
                }>
                <FontAwesomeIcon icon={faHeading} size="lg" style={{ color: '#2184da' }} />
            </Whisper>
        </div>
    );
}

export {
    lpMatrixDisplayV2_readUserTextsValue,
    lpMatrixDisplayV2_readDictionnaryValue,
    lpMatrixDisplayV2_sendAllBasicConfig,
    lpMatrixDisplayV2_sendBasicConfig,
    lpMatrixDisplayV2_sendCounterConfig,
    lpMatrixDisplayV2_sendDBConfig,
    lpMatrixDisplayV2_sendKeyTextConfig,
    lpMatrixDisplayV2_sendMatrixOff,
    lpMatrixDisplayV2_sendMatrixOn,
    lpMatrixDisplayV2_sendReboot,
    lpMatrixDisplayV2_sendReloadConfig,
    lpMatrixDisplayV2_sendResetMemory,
    lpMatrixDisplayV2_sendTopologyConfig,
    lpMatrixDisplayV2_sendTXTConfig,
    lpMatrixDisplayV2_sendUserTextConfig,
    lpMatrixDisplayV2_sendVirtualDisplayConfig,
    lpMatrixDisplayV2_updateBasicConfig,
    lpMatrixDisplayV2_updateTopologyConfig,
    lpMatrixDisplayV2_updateUserTextConfig,
    lpMatrixDisplayV2_updateVirtualDisplayConfig,
    lpMatrixDisplayV2_updateKeyTextConfig,
};
