import {
    faArrowAltCircleUp,
    faCheck,
    faEdit,
    faEye,
    faEyeSlash,
    faPlug,
    faTimes,
    faTimesCircle,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    Input,
    List,
    Panel,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { IpCan } from '../../../../../handlers/ipcan/IpCan';
import { MapLPMatrixDisplayV2 } from '../../../../../handlers/map/MapLPMatrixDisplayV2';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';
import PanelHeader from '../../../../Custom/PanelHeader';

type Props = {
    display: MapLPMatrixDisplayV2;
    currentIpCan?: IpCan;
    controllers: IpCan[];
    service: any;
};

type FormValue = {
    label: string;
    isShow: boolean;
    mapArrowRotation: number;
    ipcan: number;
    bus: number;
    deviceId: number;
    isMirror: boolean;
};

type MapArrowRotationFormValue = {
    label: string;
    value: number;
    rotation: number;
    icon: IconDefinition;
};

type FormSelector = {
    label: string;
    value: number;
};

export const LPDisplayMapInformationCard = (props: Props) => {
    const intl = useIntl();

    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const [formValue, setFormValue] = useState<FormValue>({
        label: props.display.label,
        mapArrowRotation: props.display.getMapArrowRotation(),
        isShow: props.display.getIsShow(),
        ipcan: props.display.getLpMatrixDisplayV2().ipCanId,
        deviceId: props.display.getLpMatrixDisplayV2().deviceId,
        bus: props.display.getLpMatrixDisplayV2().bus,
        isMirror: props.display.getIsMirror(),
    });

    const generateMapArrowRotation = (): {
        icon: IconDefinition;
        rotation: number;
    } => {
        switch (props.display.getMapArrowRotation()) {
            case 1: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 270,
                };
            }
            case 2: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 90,
                };
            }
            case 3: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 0,
                };
            }
            case 4: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 180,
                };
            }
            case 5: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 315,
                };
            }
            case 6: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 45,
                };
            }
            case 7: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 225,
                };
            }
            case 8: {
                return {
                    icon: faArrowAltCircleUp,
                    rotation: 135,
                };
            }
            default: {
                return {
                    icon: faTimesCircle,
                    rotation: 0,
                };
            }
        }
    };

    const generateControllersForm = (): FormSelector[] => {
        let controllers: Array<FormSelector> = [];

        props.controllers.forEach(controller =>
            controllers.push({
                label: `${controller.label} (${controller.macStr})`,
                value: controller.id,
            })
        );

        return controllers;
    };

    const generateBusForm = (): FormSelector[] => {
        const selectedIpCan = props.controllers.find(controller => controller.id === formValue.ipcan);

        return selectedIpCan?.nbDevicesOnBus.map((_, index) => {
            return {
                label: index + 1,
                value: index,
            };
        });
    };

    const arrowStyle = {
        transform: `rotate(${generateMapArrowRotation}deg)`,
    };

    const onFormChange = formValue => {
        setFormValue({
            label: formValue.label,
            deviceId: parseInt(formValue.deviceId),
            bus: parseInt(formValue.bus),
            mapArrowRotation: formValue.mapArrowRotation,
            ipcan: formValue.ipcan,
            isShow: formValue.isShow,
            isMirror: formValue.isMirror,
        });
    };

    const generateMapArrowRotationForm = (): MapArrowRotationFormValue[] => {
        return [
            {
                label: intl.formatMessage({
                    id: 'display.arrows.noArrow',
                }),
                icon: faTimesCircle,
                rotation: 0,
                value: 0,
            },
            {
                label: intl.formatMessage({
                    id: 'display.arrows.left',
                }),
                icon: faArrowAltCircleUp,
                rotation: 270,
                value: 1,
            },
            {
                label: intl.formatMessage({
                    id: 'display.arrows.right',
                }),
                icon: faArrowAltCircleUp,
                rotation: 90,
                value: 2,
            },
            {
                label: intl.formatMessage({
                    id: 'display.arrows.up',
                }),
                icon: faArrowAltCircleUp,
                rotation: 0,
                value: 3,
            },
            {
                label: intl.formatMessage({
                    id: 'display.arrows.down',
                }),
                icon: faArrowAltCircleUp,
                rotation: 180,
                value: 4,
            },
            {
                label: intl.formatMessage({
                    id: 'display.arrows.upLeft',
                }),
                icon: faArrowAltCircleUp,
                rotation: 315,
                value: 5,
            },
            {
                label: intl.formatMessage({
                    id: 'display.arrows.upRight',
                }),
                icon: faArrowAltCircleUp,
                rotation: 45,
                value: 6,
            },
            {
                label: intl.formatMessage({
                    id: 'display.arrows.downLeft',
                }),
                icon: faArrowAltCircleUp,
                rotation: 225,
                value: 7,
            },
            {
                label: intl.formatMessage({
                    id: 'display.arrows.downRight',
                }),
                icon: faArrowAltCircleUp,
                rotation: 135,
                value: 8,
            },
        ];
    };

    const handleCancel = () => {
        setIsUpdateMode(false);

        setFormValue({
            label: props.display.label,
            mapArrowRotation: props.display.getMapArrowRotation(),
            isShow: props.display.getIsShow(),
            ipcan: props.display.getLpMatrixDisplayV2().ipCanId,
            deviceId: props.display.getLpMatrixDisplayV2().deviceId,
            bus: props.display.getLpMatrixDisplayV2().bus,
            isMirror: props.display.getIsMirror(),
        });
    };

    const handleValid = () => {
        setIsUpdating(true);

        axiosService
            .getAxios()
            .put(
                '/map-lp-matrix-displays-v2/updateDisplay',
                {
                    id: props.display.id,
                    lpMatrixDisplayV2: {
                        id_ipcanmodule: formValue.ipcan,
                        bus: formValue.bus,
                        deviceId: formValue.deviceId,
                        baseConfig: {
                            nbPixelX: props.display.getLpMatrixDisplayV2().getBaseConfig().getNbPixelX(),
                            nbPixelY: props.display.getLpMatrixDisplayV2().getBaseConfig().getNbPixelY(),
                            typeMatrix: props.display.getLpMatrixDisplayV2().getBaseConfig().getTypeMatrix(),
                            brightness: props.display.getLpMatrixDisplayV2().getBaseConfig().getBrightness(),
                            origin: props.display.getLpMatrixDisplayV2().getBaseConfig().getOrigin(),
                            gmt: props.display.getLpMatrixDisplayV2().getBaseConfig().getGmt(),
                        },
                    },
                    mapArrowRotation: formValue.mapArrowRotation,
                    isShow: formValue.isShow,
                    isMirror: formValue.isMirror,
                    label: formValue.label,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(lpMatrixDisplayUpdated => {
                setIsUpdating(false);
                setIsUpdateMode(false);

                props.service.send('UPDATE_LP_MATRIX_DISPLAY', {
                    display: lpMatrixDisplayUpdated.data,
                });

                Alert.success(
                    intl.formatMessage({
                        id: 'map.lpDisplay.edit.information.success',
                    })
                );
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    intl.formatMessage({
                        id: 'map.lpDisplay.edit.information.error',
                    })
                );

                setIsUpdating(false);
            });
    };

    const generateViewCard = () => {
        return (
            <List>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.label" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>{props.display.label}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* IS LOGGED IN */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.isConnected" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>
                            <FontAwesomeIcon
                                icon={faPlug}
                                color={props.display.getLpMatrixDisplayV2().getOnline() ? 'green' : 'red'}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* IS VISIBLE */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.isShow" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>
                            <FontAwesomeIcon
                                icon={props.display.getIsShow() ? faCheck : faTimes}
                                color={props.display.getIsShow() ? 'green' : 'red'}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* IS VISIBLE */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.isMirror" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>
                            <FontAwesomeIcon
                                icon={props.display.getIsMirror() ? faCheck : faTimes}
                                color={props.display.getIsMirror() ? 'green' : 'red'}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* IP CAN */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.mac" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>
                            {`${props.currentIpCan?.label} (${props.currentIpCan?.macStr})`}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* BUS */}
                <List.Item className="panel-list">
                    <FlexboxGrid justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.bus" />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item>{props.display.getLpMatrixDisplayV2().getBus() + 1}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        );
    };

    const generateEditPart = () => {
        return (
            <Form fluid formValue={formValue} onChange={formValue => onFormChange(formValue)}>
                <List>
                    {/* LABEL */}
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between" align="middle">
                            <FlexboxGrid.Item className="bold" componentClass={Col} xs={12}>
                                <FormattedMessage id="ipCanDevices.lpDisplay.mapInformation.label" />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl name="label" accepter={Input} />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* IS SHOW */}
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.lpDisplay.edit.information.isShow" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <FormControl
                                    data-cy="lpDisplay-edit-isShow"
                                    name="isShow"
                                    value={formValue.isShow}
                                    accepter={Toggle}
                                    defaultChecked={formValue.isShow}
                                    checkedChildren={<FontAwesomeIcon icon={faEye} />}
                                    unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} />}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* IS MIRROR */}
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.lpDisplay.edit.information.isMirror" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <FormControl
                                    data-cy="lpDisplay-edit-isMirror"
                                    name="isMirror"
                                    value={formValue.isMirror}
                                    accepter={Toggle}
                                    defaultChecked={formValue.isMirror}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* IPCAN */}
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.lpDisplay.edit.information.ipcanModule" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-ipCan"
                                    name="ipcan"
                                    cleanable={false}
                                    accepter={SelectPicker}
                                    data={generateControllersForm()}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* BUS */}
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.lpDisplay.edit.information.bus" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-bus"
                                    name="bus"
                                    accepter={SelectPicker}
                                    data={generateBusForm()}
                                    cleanable={false}
                                    searchable={false}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* DEVICE ID */}
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={12} className="bold">
                                <FormattedMessage id="map.tcpDisplay.edit.information.deviceId" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                <FormControl
                                    data-cy="lpDisplay-edit-deviceId"
                                    name="deviceId"
                                    accepter={Input}
                                    type="number"
                                    min={1}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* BUTTONS */}
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="end">
                            <FlexboxGrid.Item>
                                <ButtonGroup>
                                    <Button
                                        data-cy="lpDisplay-edit-cancel"
                                        color="red"
                                        onClick={() => handleCancel()}
                                        disabled={isUpdating}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                    <Button
                                        data-cy="lpDisplay-edit-valid"
                                        color="green"
                                        onClick={() => handleValid()}
                                        loading={isUpdating}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </ButtonGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                </List>
            </Form>
        );
    };

    return (
        <Panel
            className="panel-small"
            shaded
            bordered
            data-cy="ipcan-elements-lpDisplay-systemText"
            bodyFill
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'ipCanDevices.lpDisplay.mapInformation' })}
                    buttons={[
                        <Button
                            data-cy="lpDisplay-systemText-edit-button"
                            appearance="primary"
                            size="sm"
                            color="blue"
                            onClick={() => setIsUpdateMode(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {isUpdateMode ? generateEditPart() : generateViewCard()}
        </Panel>
    );
};

export default LPDisplayMapInformationCard;
