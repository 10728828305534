import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { match, useHistory, useRouteMatch } from 'react-router';
import { Alert, FlexboxGrid, Loader, Message } from 'rsuite';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { LPDisplay } from '../../../handlers/lpDisplays/LPDisplay';
import {
    getColumn,
    LP_MATRIXDISPLAYV2_PICTO_TYPE,
    PixelArtPictoImport,
    PixelArtPictoWS,
} from '../../../handlers/pixelArt/pixelArtWS';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';
import { clone } from '../../../utils/clone';
import { PictoEditor } from './Editor';
import { LeftSideBar } from './LeftSideBar';
import { PictoLoader } from './Loader';
import { RightSideBar } from './RightSideBar';

const picto_TIMEOUT = 10000;

export const PictoMonochrome = () => {
    let pictoTimeout: ReturnType<typeof setTimeout>;
    let changeWidthTimeout: ReturnType<typeof setTimeout>;

    const routeParams: match<RouteParams> | null = useRouteMatch(
        '/ipCan/:ipCanId/bus/:busNumber/matrix-display/:displayId/picto/:type'
    );
    const intl = useIntl();

    const history = useHistory();

    if (!routeParams)
        return <Message full type="error" description={intl.formatMessage({ id: 'pixelArt.pictoEditor.error' })} />;

    // PARSE ROUTE PARAMS TO NUMBER
    const busNumber = parseInt(routeParams.params.busNumber, 10);
    const displayId = parseInt(routeParams.params.displayId, 10);
    const type = parseInt(routeParams.params.type, 10);
    const ipCanId = parseInt(routeParams.params.ipCanId, 10);

    // LP MATRIX DISPLAY V2
    const [lpMatrixDisplay, setLPMatrixDisplay] = React.useState<LPDisplay | undefined>(undefined);
    // picto ARRAY
    const [picto, setPicto] = React.useState<string[][]>([]);
    // MatrixDisplay
    const [ipCanModule, setIpCanModule] = React.useState<IpCan | undefined>(undefined);
    // SELECTED picto INDEX
    const [selectedPictoIndex, setSelectedPictoIndex] = React.useState<number>(0);
    // LOADING STATE
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    // ERROR STATE
    const [hasError, setHasError] = React.useState<boolean>(false);
    // PICTO TO COPY
    const [pictoToCopy, setPictoToCopy] = React.useState<number>(-1);

    // Websocket connection
    useEffect(() => {
        webSocketService.joinRoom('lp-matrixDisplayV2');

        return function cleanup() {
            webSocketService.offEvent('lp-matrixDisplayV2:readPicto', handleWebsocket);
        };
    }, []);

    // REQUEST MATRIX DISPLAY DATA
    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/devices/lp-matrixdisplays-v2/${displayId}`, {
                headers: authHeader(),
            })
            .then(matrixDisplayResponse => {
                setLPMatrixDisplay(new LPDisplay(matrixDisplayResponse.data, ipCanId, null));

                axiosService
                    .getAxios()
                    .get(`/ipcanmodules/${ipCanId}`, { headers: authHeader() })
                    .then(ipCanResponse => {
                        setIpCanModule(new IpCan(ipCanResponse.data));
                    });
            })
            .catch(err => {
                console.error(err);
            });
    }, [displayId]);

    // REQUEST IP CAN DATA
    useEffect(() => {
        axiosService
            .getAxios()
            .get(`/ipcanmodules/${ipCanId}`, { headers: authHeader() })
            .then(ipCanResponse => {
                setIpCanModule(new IpCan(ipCanResponse.data));
            })
            .catch(err => {
                console.error(err);
            });
    }, [displayId]);

    // REQUEST WEBSOCKET DATA
    useEffect(() => {
        readPictoFromAPI();
    }, [type]);

    // Websocket handling
    const handleWebsocket = (data: PixelArtPictoWS) => {
        if (data.id === displayId) {
            if (pictoTimeout) {
                clearTimeout(pictoTimeout);
            }

            setPicto(data.pictos.picto);

            if (data.pictos.pictoWasComplete) {
                setIsLoading(false);

                webSocketService.offEvent('lp-matrixDisplayV2:readPicto', handleWebsocket);
            } else {
                pictoTimeout = setTimeout(() => {
                    setIsLoading(false);
                    setHasError(true);
                }, picto_TIMEOUT);
            }
        }
    };

    const readPictoFromAPI = () => {
        setPicto([]);
        setIsLoading(true);
        setHasError(false);

        webSocketService.onEvent('lp-matrixDisplayV2:readPicto', handleWebsocket);

        setTimeout(() => {
            axiosService.getAxios().put(
                '/devices/lp-matrixdisplays-v2/readPicto',
                {
                    id: displayId,
                    pictoType: type,
                },
                {
                    headers: authHeader(),
                }
            );

            pictoTimeout = setTimeout(() => {
                setIsLoading(false);
                setHasError(true);
            }, picto_TIMEOUT);
        }, 1000);
    };

    const generatePictos = () => {
        const finalValue: PictoValueInterface[] = [];

        let typeValue = 16;

        if (type === LP_MATRIXDISPLAYV2_PICTO_TYPE.PICTO_SIZE_32) {
            typeValue = 32;
        }

        picto.map((p, pIndex) => {
            const columns: boolean[][] = [];
            p.map(value => {
                columns.push(getColumn(typeValue, BigInt(value)));
            });
            finalValue.push({
                name: (pIndex + 1).toString(),
                value: columns,
            });
        });

        return finalValue;
    };

    const handlePictoWidthChange = width => {
        if (changeWidthTimeout) {
            clearTimeout(changeWidthTimeout);
        }

        changeWidthTimeout = setTimeout(() => {
            let finalWidth = width;

            if (finalWidth > 100) finalWidth = 100;
            if (finalWidth < 0) finalWidth = 0;

            const pictos = [...picto];
            const currentPicto = pictos[selectedPictoIndex];

            const diff = currentPicto.length - finalWidth;

            if (diff > 0) {
                currentPicto.splice(0, diff);
            }

            if (diff < 0) {
                currentPicto.push(...Array(Math.abs(diff)).fill('0'));
            }

            pictos[selectedPictoIndex] = currentPicto;

            setPicto(pictos);
        }, 500);
    };

    const handleClick = (column: number, lineValue: number) => {
        const pictos = [...picto];
        const currentPicto = pictos[selectedPictoIndex];

        // Get the correct column to edit
        let currentPictoColumnValue = BigInt(currentPicto[column]);

        // Get bit value of pixel to edit
        const lineBitValue = Math.pow(2, lineValue);

        let newValue = currentPictoColumnValue;

        if ((currentPictoColumnValue & BigInt(lineBitValue)) === BigInt(0)) {
            newValue = currentPictoColumnValue | BigInt(lineBitValue);
        } else {
            newValue = currentPictoColumnValue & BigInt(~lineBitValue);
        }

        pictos[selectedPictoIndex][column] = newValue.toString();

        setPicto(pictos);
    };

    const handleSavePicto = () => {
        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/writePicto',
                {
                    tabId: [displayId],
                    pictoValue: {
                        picto: picto,
                        pictoType: type,
                    },
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'pixelArt.pictoEditor.startSave' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'pixelArt.pictoEditor.saveError' }));
            });
    };

    const handleSaveToManyPicto = (displays: number[]) => {
        axiosService
            .getAxios()
            .put(
                '/devices/lp-matrixdisplays-v2/writePicto', // TODO: CHANGE API URL
                {
                    id: displayId,
                    pictoValue: {
                        picto: picto,
                        pictoType: type,
                    },
                    tabId: displays,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'pixelArt.pictoEditor.startSave' }));
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'pixelArt.pictoEditor.saveError' }));
            });
    };

    const handlePictoUpload = (receivedPicto: PixelArtPictoImport) => {
        webSocketService.offEvent('lp-matrixDisplayV2:readPicto', handleWebsocket);

        if (receivedPicto.pictoType === type && receivedPicto.picto) {
            setPicto(receivedPicto.picto);
            Alert.success(intl.formatMessage({ id: 'pixelArt.pictoEditor.uploadSuccess' }), 5000);
        } else {
            Alert.error(intl.formatMessage({ id: 'pixelArt.pictoEditor.uploadError' }), 5000);
        }
    };

    const handleSizeChange = (size: number) => {
        setSelectedPictoIndex(0);
        history.push(`/ipCan/${ipCanId}/bus/${busNumber}/matrix-display/${displayId}/picto/${size}`);
    };

    const handleClickCopy = () => {
        setPictoToCopy(selectedPictoIndex);
    };

    const handleClickPaste = () => {
        const pictos = [...picto];

        pictos[selectedPictoIndex] = clone(picto[pictoToCopy]);

        setPicto(pictos);
    };

    const handleClickReset = () => {
        const pictos = [...picto];

        pictos[selectedPictoIndex].fill('0');

        setPicto(pictos);
    };

    return (
        <>
            {/* <Modal backdrop="static" show={picto.length === 0 && !hasError} size="sm">
                <Modal.Body style={{ height: '10vh' }}>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item>
                            <Loader size="lg" content={intl.formatMessage({ id: 'pixelArt.pictoEditor.loading' })} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>
            </Modal> */}

            {hasError && (
                <Message
                    className="margin-bottom-10"
                    type="error"
                    description={intl.formatMessage({ id: 'pixelArt.pictoEditor.error' })}
                    closable
                />
            )}

            {isLoading && <Message description={<Loader center content={<PictoLoader pictoNb={picto.length} />} />} />}

            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item colspan={4} className="padding-left-0 padding-right-0">
                    {picto.length > 0 && (
                        <LeftSideBar
                            setClickedPictoIndex={setSelectedPictoIndex}
                            pictos={generatePictos()}
                            selectedPictoIndex={selectedPictoIndex}
                            pictoType={type}
                            pictoToCopy={pictoToCopy}
                        />
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    {picto.length > 0 && (
                        <PictoEditor
                            pictos={generatePictos()}
                            selectedPictoIndex={selectedPictoIndex}
                            pictoSize={type}
                            handleClick={handleClick}
                        />
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={4} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    <RightSideBar
                        pictoType={type}
                        pictos={generatePictos()}
                        selectedPictoIndex={selectedPictoIndex}
                        isLoading={isLoading}
                        pictosFromApi={picto}
                        handlePictoWidthChange={handlePictoWidthChange}
                        handlePictoUpload={handlePictoUpload}
                        readPictoFromAPI={readPictoFromAPI}
                        handleSizeChange={handleSizeChange}
                        handleSavePicto={handleSavePicto}
                        handleSaveToManyPicto={handleSaveToManyPicto}
                        handleClickCopy={handleClickCopy}
                        handleClickPaste={handleClickPaste}
                        handleClickReset={handleClickReset}
                        isPasteButtonEnabled={pictoToCopy >= 0}
                        display={lpMatrixDisplay}
                        ipcan={ipCanModule}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};

type RouteParams = {
    ipCanId: string;
    busNumber: string;
    displayId: string;
    type: string;
};

export interface PictoValueInterface {
    name: string;
    value: boolean[][];
}
