import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Form, FormControl, InputNumber, List, SelectPicker } from 'rsuite';
import { PlaceIPCamera } from '../../../../handlers/Camera/ApiCamera';
import PlaceCamera from '../../../../handlers/Camera/PlaceCamera';
import { sensorPlaceTypeHandler } from '../../../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    place: PlaceCamera;
    setViewMode: () => void;
    reloadPlaces: () => void;
};

type FormValue = {
    name: string;
    placeType: number;
    overstayTime: string;
};

export const CameraPlaceInformationEditMode = (props: Props) => {
    const intl = useIntl();
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [sensorPlaceTypes, setSensorPlaceTypes] = React.useState<any[]>([]);

    const [formValue, setFormValue] = React.useState<FormValue>({
        name: props.place.getLabel(),
        placeType: props.place.getSensorPlaceType()?.getId() || 0,
        overstayTime: props.place.getOverstayTime().toString(),
    });

    useEffect(() => {
        axiosService
            .getAxios()
            .get('/sensor-place-type', { headers: authHeader() })
            .then(response => {
                setSensorPlaceTypes(response.data.map(sensorPlaceType => sensorPlaceTypeHandler(sensorPlaceType)));
            });
    }, []);

    const handleFormChange = (value: any) => {
        setFormValue({
            ...value,
        });
    };

    const handleCancel = () => {
        setFormValue({
            name: props.place.getLabel(),
            placeType: props.place.getSensorPlaceType()?.getId() || 0,
            overstayTime: props.place.getOverstayTime().toString(),
        });

        props.setViewMode();
    };

    const handleValid = () => {
        setIsUpdating(true);

        axiosService
            .getAxios()
            .put<PlaceIPCamera>(
                '/places-camera/updatePlaceCamera',
                {
                    id: props.place.getId(),
                    label: formValue.name,
                    overstayTime: parseInt(formValue.overstayTime),
                    id_sensorPlaceType: formValue.placeType,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(response => {
                props.place.updatePlace(response.data);

                handleCancel();

                Alert.success(intl.formatMessage({ id: 'camera.place.information.edit.success' }));

                props.reloadPlaces();

                setIsUpdating(false);
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'camera.place.information.edit.error' }));

                setIsUpdating(false);
            });
    };

    const shouldEnableValidButton = () => {
        return formValue.overstayTime.length > 0 && formValue.placeType;
    };

    return (
        <Form formValue={formValue} fluid onChange={handleFormChange}>
            <List>
                {/* NAME */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="camera.information.name" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="name" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* PLACE TYPE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="camera.information.placeType" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl
                                name="placeType"
                                accepter={SelectPicker}
                                data={sensorPlaceTypes.map(spt => {
                                    return {
                                        label: spt.placeType.label,
                                        value: spt.id,
                                    };
                                })}
                                cleanable={false}
                                searchable={false}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* OVERSTAY TIME */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="camera.information.overstayTime" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="overstayTime" accepter={InputNumber} min={0} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button color="red" onClick={handleCancel} disabled={isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    color="green"
                                    onClick={handleValid}
                                    loading={isUpdating}
                                    disabled={!shouldEnableValidButton}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );
};
