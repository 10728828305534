import { sensorPlaceTypeConstants } from '../constants';
import { sensorPlaceTypeService } from '../services';
import { notificationActions } from './notification.actions';

export const sensorPlaceTypeActions = {
    getAll,
    // getOne,
    create,
    remove,
    update,
    // updateImage,
    openViewDrawer,
    closeViewDrawer,
    openCreateModal,
    closeCreateModal,
    openDeleteModal,
    closeDeleteModal,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        sensorPlaceTypeService.getAll().then(
            sensorPlaceTypes => dispatch(success(sensorPlaceTypes)),
            error => {
                dispatch(failure(error));
                dispatch(
                    notificationActions.createNotification(
                        'error',
                        'sensorPlaceType.error.fetchAllsensorPlaceTypesError'
                    )
                );
            }
        );
    };

    function request() {
        return { type: sensorPlaceTypeConstants.GET_ALL_REQUEST };
    }
    function success(sensorPlaceTypes) {
        return { type: sensorPlaceTypeConstants.GET_ALL_SUCCESS, sensorPlaceTypes };
    }
    function failure(error) {
        return { type: sensorPlaceTypeConstants.GET_ALL_FAILURE, error };
    }
}

// function getOne(id) {
//     return dispatch => {
//         dispatch(request());

//         sensorPlaceTypeService.getById(id).then(
//             sensorPlaceType => dispatch(success(sensorPlaceType)),
//             error => {
//                 dispatch(failure(error));
//                 dispatch(
//                     notificationActions.createNotification(
//                         'error',
//                         'sensorPlaceType.error.fetchsensorPlaceTypeError'
//                     )
//                 );
//             }
//         );
//     };

//     function request() {
//         return { type: sensorPlaceTypeConstants.GETONE_REQUEST };
//     }
//     function success(sensorPlaceType) {
//         return { type: sensorPlaceTypeConstants.GETONE_SUCCESS, sensorPlaceType };
//     }
//     function failure(error) {
//         return { type: sensorPlaceTypeConstants.GETONE_FAILURE, error };
//     }
// }

function remove(id) {
    return dispatch => {
        dispatch(request(id));

        sensorPlaceTypeService.remove(id).then(
            data => {
                dispatch(success(data));
                dispatch(
                    notificationActions.createNotification(
                        'success',
                        'sensorPlaceType.success.removesensorPlaceTypeSuccess'
                    )
                );
            },
            error => {
                dispatch(failure(id, error));
                dispatch(
                    notificationActions.createNotification('error', 'sensorPlaceType.error.removesensorPlaceTypeError')
                );
            }
        );
    };

    function request(id) {
        return { type: sensorPlaceTypeConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: sensorPlaceTypeConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: sensorPlaceTypeConstants.DELETE_FAILURE, id, error };
    }
}

function create(label, description, placeTypeId) {
    const crypto = window.crypto;
    var array = new Uint32Array(1);

    return dispatch => {
        let id = crypto.getRandomValues(array);
        let newsensorPlaceType = {
            id: id,
            label,
            description,
            placeTypeId,
        };
        dispatch(request(newsensorPlaceType));

        sensorPlaceTypeService.create(label, description, placeTypeId).then(
            sensorPlaceType => {
                dispatch(success(sensorPlaceType, id));
                dispatch(
                    notificationActions.createNotification(
                        'success',
                        'sensorPlaceType.success.createsensorPlaceTypeSuccess'
                    )
                );
            },
            error => {
                dispatch(failure(error, id));
                dispatch(
                    notificationActions.createNotification('error', 'sensorPlaceType.error.createsensorPlaceTypeError')
                );
            }
        );
    };

    function request(sensorPlaceType) {
        return { type: sensorPlaceTypeConstants.CREATE_REQUEST, sensorPlaceType };
    }
    function success(sensorPlaceType, id) {
        return { type: sensorPlaceTypeConstants.CREATE_SUCCESS, sensorPlaceType, id: id };
    }
    function failure(error, id) {
        return { type: sensorPlaceTypeConstants.CREATE_FAILURE, error, id: id };
    }
}

function update(id, label, description, placeTypeId) {
    return dispatch => {
        dispatch(request(id));

        sensorPlaceTypeService.update(id, label, description, placeTypeId).then(
            sensorPlaceType => {
                dispatch(success(sensorPlaceType));
                dispatch(
                    notificationActions.createNotification(
                        'success',
                        'sensorPlaceType.success.updatesensorPlaceTypeSuccess'
                    )
                );
            },
            error => {
                dispatch(failure(error, id));
                dispatch(
                    notificationActions.createNotification('error', 'sensorPlaceType.error.updatesensorPlaceTypeError')
                );
            }
        );
    };

    function request(id) {
        return { type: sensorPlaceTypeConstants.UPDATE_REQUEST, id };
    }
    function success(sensorPlaceType) {
        return { type: sensorPlaceTypeConstants.UPDATE_SUCCESS, sensorPlaceType };
    }
    function failure(error, id) {
        return { type: sensorPlaceTypeConstants.UPDATE_FAILURE, error, id };
    }
}

// function updateImage(id, image) {
//     return dispatch => {
//         dispatch(request(id));

//         sensorPlaceTypeService.updateImage(id, image).then(
//             sensorPlaceType => {
//                 dispatch(success(sensorPlaceType));
//                 dispatch(
//                     notificationActions.createNotification(
//                         'success',
//                         'sensorPlaceType.success.updatesensorPlaceTypeSuccess'
//                     )
//                 );
//             },
//             error => {
//                 dispatch(failure(error, id));
//                 dispatch(
//                     notificationActions.createNotification(
//                         'error',
//                         'sensorPlaceType.error.updatesensorPlaceTypeError'
//                     )
//                 );
//             }
//         );
//     };

//     function request(id) {
//         return { type: sensorPlaceTypeConstants.UPDATE_REQUEST, id };
//     }
//     function success(sensorPlaceType) {
//         return { type: sensorPlaceTypeConstants.UPDATE_SUCCESS, sensorPlaceType };
//     }
//     function failure(error, id) {
//         return { type: sensorPlaceTypeConstants.UPDATE_FAILURE, error, id };
//     }
// }

function openViewDrawer(id) {
    return dispatch => {
        dispatch(request());

        sensorPlaceTypeService.getById(id).then(
            sensorPlaceType => {
                dispatch(success(sensorPlaceType));
            },
            error => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: sensorPlaceTypeConstants.OPEN_VIEW_DRAWER_REQUEST,
        };
    }

    function success(sensorPlaceType) {
        return {
            type: sensorPlaceTypeConstants.OPEN_VIEW_DRAWER_SUCCESS,
            sensorPlaceType: sensorPlaceType,
        };
    }

    function failure(error) {
        return {
            type: sensorPlaceTypeConstants.OPEN_VIEW_DRAWER_FAILURE,
            error,
        };
    }
}

function closeViewDrawer() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: sensorPlaceTypeConstants.CLOSE_VIEW_DRAWER,
        };
    }
}

function openCreateModal() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: sensorPlaceTypeConstants.OPEN_CREATE_MODAL,
        };
    }
}

function closeCreateModal() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: sensorPlaceTypeConstants.CLOSE_CREATE_MODAL,
        };
    }
}

function openDeleteModal(id) {
    return dispatch => {
        dispatch(request());

        sensorPlaceTypeService.getById(id).then(
            sensorPlaceType => {
                dispatch(success(sensorPlaceType));
            },
            error => {
                dispatch(failure(error));
            }
        );
    };

    function request() {
        return {
            type: sensorPlaceTypeConstants.OPEN_DELETE_MODAL_REQUEST,
            id,
        };
    }

    function success(sensorPlaceType) {
        return {
            type: sensorPlaceTypeConstants.OPEN_DELETE_MODAL_SUCCESS,
            sensorPlaceType,
        };
    }

    function failure(error) {
        return {
            type: sensorPlaceTypeConstants.OPEN_DELETE_MODAL_FAILURE,
            error,
        };
    }
}

function closeDeleteModal() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: sensorPlaceTypeConstants.CLOSE_DELETE_MODAL,
        };
    }
}
