import { faHome, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Whisper } from 'rsuite';

export default function saveHome() {
    return (
        <div data-cy="historic-icon-ipcamera-saveHome">
            <Whisper
                placement="right"
                trigger="hover"
                speaker={
                    <Tooltip>
                        <FormattedMessage id="history.ipcamera.saveHome" />
                    </Tooltip>
                }>
                <div>
                    <FontAwesomeIcon icon={faSave} size="lg" className="margin-right-5" />
                    <FontAwesomeIcon icon={faHome} size="lg" />
                </div>
            </Whisper>
        </div>
    );
}
