import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Drawer } from 'rsuite';
import { sceneSensorPlaceTypeActions, sensorPlaceTypeActions } from '../../../../redux/actions';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import DeleteSensorPlaceTypeModal from '../../Modal/SensorPlaceType/DeleteSensorPlaceTypeModal';
import SensorPlaceTypeInformations from './SensorPlaceTypeInformations';
import SensorPlaceTypeScene from './SensorPlaceTypeScene';

const mapDispatchToProps = dispatch => ({
    openDeleteModal: id => dispatch(sensorPlaceTypeActions.openDeleteModal(id)),
    closeViewDrawer: () => dispatch(sensorPlaceTypeActions.closeViewDrawer()),
    openViewDrawer: id => dispatch(sensorPlaceTypeActions.openViewDrawer(id)),
    getScenes: sensorPlaceTypeId => dispatch(sceneSensorPlaceTypeActions.getScenes(sensorPlaceTypeId)),
});

type Props = {
    sensorPlaceTypeDrawer: Record<string, any>;
    getScenes: Function;
    view_drawer_openned: boolean;
    closeViewDrawer: Function;
    scenesSensorPlaceTypeList: Function;
    openDeleteModal: Function;
} & WrappedComponentProps;

type State = {};

class SensorPlaceTypeDrawer extends React.Component<Props, State> {
    fetchSensorPlaceType: any;
    constructor(props) {
        super(props);

        this.state = {
            sensorPlaceTypeDrawer: undefined,
            scenes: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.sensorPlaceTypeDrawer !== prevProps.sensorPlaceTypeDrawer && this.props.sensorPlaceTypeDrawer) {
            this.props.getScenes(this.props.sensorPlaceTypeDrawer.id);
        }
    }

    render() {
        if (this.props.sensorPlaceTypeDrawer) {
            return (
                <Fragment>
                    <Drawer
                        backdrop={true}
                        show={this.props.view_drawer_openned}
                        size="lg"
                        onHide={() => this.props.closeViewDrawer()}>
                        <Drawer.Header data-cy="sensorPlaceTypeDrawer">
                            <Drawer.Title data-cy="sensorPlaceTypeDrawer-title">
                                {this.props.sensorPlaceTypeDrawer.label}
                            </Drawer.Title>
                        </Drawer.Header>
                        <Drawer.Body className="drawer-body-without-avatar">
                            <Fragment>
                                <SensorPlaceTypeInformations sensorPlaceType={this.props.sensorPlaceTypeDrawer} />

                                {this.props.scenesSensorPlaceTypeList.length > 0 && (
                                    <Fragment>
                                        <SensorPlaceTypeScene
                                            sensorId={this.props.sensorPlaceTypeDrawer.id}
                                            title={this.props.intl.formatMessage({ id: 'sensorPlaceTypeScene.free' })}
                                            state="free"
                                            scenes={this.props.scenesSensorPlaceTypeList[0]}
                                        />
                                        <SensorPlaceTypeScene
                                            sensorId={this.props.sensorPlaceTypeDrawer.id}
                                            state="occuped"
                                            title={this.props.intl.formatMessage({
                                                id: 'sensorPlaceTypeScene.occuped',
                                            })}
                                            scenes={this.props.scenesSensorPlaceTypeList[1]}
                                        />
                                        <SensorPlaceTypeScene
                                            sensorId={this.props.sensorPlaceTypeDrawer.id}
                                            state="overstay"
                                            title={this.props.intl.formatMessage({
                                                id: 'sensorPlaceTypeScene.overstay',
                                            })}
                                            scenes={this.props.scenesSensorPlaceTypeList[2]}
                                        />
                                        <SensorPlaceTypeScene
                                            sensorId={this.props.sensorPlaceTypeDrawer.id}
                                            state="reserved"
                                            title={this.props.intl.formatMessage({
                                                id: 'sensorPlaceTypeScene.reserved',
                                            })}
                                            scenes={this.props.scenesSensorPlaceTypeList[3]}
                                        />
                                    </Fragment>
                                )}
                            </Fragment>
                        </Drawer.Body>
                        <Drawer.Footer>
                            <Button
                                onClick={() => {
                                    this.props.openDeleteModal(this.props.sensorPlaceTypeDrawer.id);
                                }}
                                data-cy="delete-placeType-button"
                                color="red">
                                <FormattedMessage id="placeType.deleteButton" />
                            </Button>
                        </Drawer.Footer>
                    </Drawer>

                    <SecuredFragment authorizedRoles={[rolesConstants.sensorPlaceType.DELETE]}>
                        <DeleteSensorPlaceTypeModal onDeleted={this.fetchSensorPlaceType} />
                    </SecuredFragment>
                </Fragment>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    let { view_drawer_openned, sensorPlaceTypeDrawer } = state.sensorPlaceTypes;
    let { scenesSensorPlaceTypeList } = state.scenesSensorPlaceType;

    return { view_drawer_openned, sensorPlaceTypeDrawer, scenesSensorPlaceTypeList };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SensorPlaceTypeDrawer));
