import { LPMatrixDisplayV2VirtualDisplayForceType } from '../../Components/Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/LPMatrixDisplayFactory';

export class LPDisplayForceType {
    private _isBlack: boolean;
    private _counters: LPDisplayForceTypeCountersInterface[];
    private _virtualDisplays: LPDisplayForceTypeVirtualDisplaysInterface[];
    private _topology: number;

    constructor(data: LPDisplayForceTypeData) {
        this._isBlack = data.isBlack;
        this._counters = data.counters;
        this._virtualDisplays = data.virtualDisplays;
        this._topology = data.topologyNum;
    }

    public getIsBlack(): boolean {
        return this._isBlack;
    }

    public getCounters(): LPDisplayForceTypeCountersInterface[] {
        return this._counters;
    }

    public getVirtualDisplays(): LPDisplayForceTypeVirtualDisplaysInterface[] {
        return this._virtualDisplays;
    }

    public getTopology(): number {
        return this._topology;
    }
}

export type LPDisplayForceTypeData = {
    isBlack: boolean;
    counters: LPDisplayForceTypeCountersInterface[];
    virtualDisplays: LPDisplayForceTypeVirtualDisplaysInterface[];
    topologyNum: number;
};

export interface LPDisplayForceTypeCountersInterface {
    numCounter: number;
    mode: number;
    value: number;
}

export interface LPDisplayForceTypeVirtualDisplaysInterface {
    numVirtualDisplay: number;
    mode: LPMatrixDisplayV2VirtualDisplayForceType;
    step: LPDisplayForceTypeVirtualDisplaysStepInterface;
}

export interface LPDisplayForceTypeVirtualDisplaysStepInterface {
    color: {
        red: number;
        blue: number;
        green: number;
    };
    mode: number;
    time: number;
    pictoSize: number;
    picto: number;
    arrowSize: number;
    arrow: number;
    arrowOnMap: number;
    stepOrder: number;
    state: number;
    transition: number;
    textTransformation: number;
    triggerMax: {
        value: number;
        action: number;
    };
    triggerMin: {
        value: number;
        action: number;
    };
}
