import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { connect } from 'react-redux';
import { Button, List, Panel } from 'rsuite';
import { levelHandler } from '../../../handlers/levelHandler';
import { notificationActions, parkingActions } from '../../../redux/actions';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import PanelHeader from '../../Custom/PanelHeader';
import Level from './Level';
import CreateLevelModal from './Modal/CreateLevelModal';

const mapDispatchToProps = dispatch => ({
    getAllParkings: () => dispatch(parkingActions.getAll()),
});

type Props = {
    levels: Array<Record<string, any>>;
    parking: number;
    auth: Record<string, any>;
    getAllParkings: Function;
} & WrappedComponentProps;

type State = {
    levels: Array<Record<string, any>>;
    showAddLevelModal: boolean;
    loading: boolean;
    sortable: boolean;
};

type Payload = {
    collection: number | string;
    node: Record<string, any>;
    newIndex: number;
    oldIndex: number;
};

class ParkingLevels extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            levels: this.props.levels,
            showAddLevelModal: false,
            loading: false,
            sortable: this.props.auth.user.roles.find(data => {
                return (data.name === 'UpdateOrder' && data.section === 'Level') || data.name === 'Admin';
            }),
        };

        this.addLevel = this.addLevel.bind(this);
        this.openAddLevelModal = this.openAddLevelModal.bind(this);
        this.closeAddLevelModal = this.closeAddLevelModal.bind(this);
        this.deleteLevel = this.deleteLevel.bind(this);
        this.handleSortEnd = this.handleSortEnd.bind(this);
        this.updateLevel = this.updateLevel.bind(this);
    }

    addLevel(name, description) {
        const parameters = { headers: authHeader() };

        const data = {
            parkingId: this.props.parking,
            name: name,
            description: description,
        };

        axiosService
            .getAxios()
            .post('/levels', data, parameters)
            .then(response => {
                let currentLevels = this.state.levels;

                currentLevels.push(levelHandler(response.data));

                this.setState({
                    levels: currentLevels,
                });

                notificationActions.createNotification('success', 'level.create.success', 100);

                this.props.getAllParkings();
            })
            .catch(err => {
                console.error(err);

                notificationActions.createNotification('error', 'level.create.error', 100);
            });
    }

    handleSortEnd(payload: Payload | undefined): void {
        if (payload) {
            this.setState({
                loading: true,
            });

            const moveData = this.state.levels.splice(payload.oldIndex, 1);
            const newData = [...this.state.levels];
            newData.splice(payload.newIndex, 0, moveData[0]);

            const parameters = { headers: authHeader() };
            const data = {
                tabId: newData.map(level => {
                    if (level) {
                        return level.id;
                    } else {
                        return null;
                    }
                }),
            };

            axiosService
                .getAxios()
                .put('/levels/levelsOrder', data, parameters)
                .then(response => {
                    this.setState({
                        levels: response.data,
                        loading: false,
                    });
                });
        }
    }

    deleteLevel(id) {
        let levels = this.state.levels;

        this.setState({
            levels: levels.filter(level => level.id !== id),
        });

        this.props.getAllParkings();
    }

    openAddLevelModal() {
        this.setState({
            showAddLevelModal: true,
        });
    }

    closeAddLevelModal() {
        this.setState({
            showAddLevelModal: false,
        });
    }

    updateLevel(level) {
        this.setState({
            levels: this.state.levels.map(stateLevel => {
                if (stateLevel.id === level.id) {
                    return { ...stateLevel, ...level };
                } else {
                    return stateLevel;
                }
            }),
        });
    }

    render() {
        return (
            <Panel
                shaded
                bordered
                bodyFill
                className="panel-small"
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({ id: 'parking.levels' })}
                        buttons={[
                            <Button
                                key="parking-levels"
                                color="blue"
                                onClick={this.openAddLevelModal}
                                size="sm"
                                data-cy="parking-add-level">
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </Button>,
                        ]}
                    />
                }>
                <SecuredFragment authorizedRoles={[rolesConstants.level.CREATE]}>
                    <CreateLevelModal
                        createParking={this.addLevel}
                        close={this.closeAddLevelModal}
                        show={this.state.showAddLevelModal}
                    />
                </SecuredFragment>
                <SecuredFragment authorizedRoles={[rolesConstants.level.UPDATE_LEVELS_ORDER]}>
                    <Loader show={this.state.loading}>
                        <List
                            hover
                            sortable={this.state.sortable}
                            onSort={this.handleSortEnd}
                            data-cy="parking-level-list">
                            {this.state.levels.map((level, index) => {
                                return (
                                    <List.Item className="panel-list" index={index} key={`${level.id}-${level.order}`}>
                                        <Level
                                            level={level}
                                            deleteLevel={this.deleteLevel}
                                            updateLevel={this.updateLevel}
                                        />
                                    </List.Item>
                                );
                            })}
                        </List>
                    </Loader>
                </SecuredFragment>
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    const { auth } = state;

    return { auth };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ParkingLevels));
