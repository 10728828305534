import { faPlay, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, List, Panel } from 'rsuite';
import { tcmDisplaysHandler } from '../../../../handlers/ipCanDevices';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';
import { CreateAndEditTaskModal, StepTypeEnum } from '../../Modal/CreateTaskModal';
import EventTaskInformations from './EventTaskInformations';

type Props = {
    reloadEvent: Function;
    eventId: number;
    tasks: Record<string, any>[];
} & WrappedComponentProps;

type State = {
    isCreateTaskModalOpen: boolean;
    launchingTasks: boolean;
    countersLoading: boolean;
    counters: Record<string, any>;
    displaysLoading: boolean;
    tcmDisplays: Record<string, any>;
};

class EventTasks extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            // Create task modal
            isCreateTaskModalOpen: false,
            launchingTasks: false,
            countersLoading: false,
            counters: [],
            displaysLoading: false,
            tcmDisplays: [],
        };

        this.openCreateTaskModal = this.openCreateTaskModal.bind(this);
        this.closeCreateTaskModal = this.closeCreateTaskModal.bind(this);
        this.launchEvent = this.launchEvent.bind(this);
        this.fetchCounters = this.fetchCounters.bind(this);
        this.fetchTCMDisplays = this.fetchTCMDisplays.bind(this);
    }

    componentDidMount() {
        this.fetchCounters();
        this.fetchTCMDisplays();
    }

    openCreateTaskModal() {
        this.setState({
            isCreateTaskModalOpen: true,
        });
    }

    closeCreateTaskModal() {
        this.setState({
            isCreateTaskModalOpen: false,
        });

        this.props.reloadEvent();
    }

    launchEvent() {
        this.setState({
            launchingTasks: true,
        });
        axiosService
            .getAxios()
            .put('/event-actions/event/play', { id: this.props.eventId }, { headers: authHeader() })
            .then(() => {
                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'tasks.launch.eventLauched',
                    }),
                    5000
                );
            })
            .catch(err => {
                console.error(err);

                Alert.error(
                    this.props.intl.formatMessage({
                        id: 'tasks.launch.eventNotLauched',
                    }),
                    5000
                );
            })
            .finally(() => {
                this.setState({
                    launchingTasks: false,
                });
            });
    }

    fetchCounters() {
        this.setState({
            countersLoading: true,
        });

        axiosService
            .getAxios()
            .get('/counters', { headers: authHeader() })
            .then(countersResponse => {
                this.setState({
                    counters: countersResponse.data,
                    countersLoading: false,
                });
            });
    }

    fetchTCMDisplays() {
        this.setState({
            displaysLoading: true,
        });

        axiosService
            .getAxios()
            .get('/map-tcm-displays', { headers: authHeader() })
            .then(mapTCMDisplaysResponse => {
                this.setState({
                    tcmDisplays: mapTCMDisplaysResponse.data.map(TCMDisplay => tcmDisplaysHandler(TCMDisplay)),
                    displaysLoading: false,
                });
            });
    }

    render() {
        return (
            <Fragment>
                <CreateAndEditTaskModal
                    show={this.state.isCreateTaskModalOpen}
                    onClose={this.closeCreateTaskModal}
                    eventId={this.props.eventId}
                    type={StepTypeEnum.CREATE}
                />

                <Panel
                    shaded
                    bordered
                    bodyFill
                    className="panel-small"
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'event.tasks' })}
                            buttons={[
                                <SecuredFragment authorizedRoles={[rolesConstants.eventAction.PLAY]}>
                                    <Button
                                        key="event.task.run"
                                        data-cy="event-task-launchEvent"
                                        onClick={this.launchEvent}
                                        color="green"
                                        loading={this.state.launchingTasks}
                                        size="sm">
                                        <FontAwesomeIcon icon={faPlay} />
                                    </Button>
                                </SecuredFragment>,
                                <SecuredFragment
                                    key="event.task.create"
                                    authorizedRoles={[rolesConstants.eventTasks.CREATE]}>
                                    <Button
                                        data-cy="event-task-createButton"
                                        onClick={this.openCreateTaskModal}
                                        appearance="primary"
                                        size="sm">
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }>
                    <List hover>
                        {this.props.tasks.map(task => {
                            return (
                                <SecuredFragment
                                    authorizedRoles={[rolesConstants.eventTasks.VIEW]}
                                    key={`event-task-${task.id}`}>
                                    <EventTaskInformations taskId={task.id} reloadEvent={this.props.reloadEvent} />
                                </SecuredFragment>
                            );
                        })}
                    </List>
                </Panel>
            </Fragment>
        );
    }
}

export default injectIntl(EventTasks);
