import { faArrowRight, faCalendarAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { Button, Col, FlexboxGrid, Grid, Row } from 'rsuite';
import { EventConditionType } from '../../../../handlers/EventAction/EventAction';
import { EventActionFormDataType } from '../CreateEventModal';

type Props = {
    formData: EventActionFormDataType;
    setFormData: (data: EventActionFormDataType) => void;
    handleCancel: () => void;
    nextStep: () => void;
};

export const StepOne = (props: Props) => {
    const intl = useIntl();

    const setCalendarTypeSelected = () => {
        props.setFormData({
            ...props.formData,
            eventConditionType: EventConditionType.CALENDAR,
        });
    };

    const setCountersTypeSelected = () => {
        props.setFormData({
            ...props.formData,
            eventConditionType: EventConditionType.COUNTERS,
        });
    };

    const setGpioTypeSelected = () => {
        props.setFormData({
            ...props.formData,
            eventConditionType: EventConditionType.GPIO,
        });
    };

    const handleValidStep = () => {
        props.nextStep();
    };

    return (
        <>
            <Grid fluid>
                <Row>
                    <Col xs={8}>
                        <div
                            className={
                                props.formData.eventConditionType === EventConditionType.CALENDAR
                                    ? 'event-select-type selected'
                                    : 'event-select-type'
                            }
                            onClick={setCalendarTypeSelected}>
                            <div className="icon">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>
                            {intl.formatMessage({ id: 'event.type.calendar' })}
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div
                            className={
                                props.formData.eventConditionType === EventConditionType.COUNTERS
                                    ? 'event-select-type selected'
                                    : 'event-select-type'
                            }
                            onClick={setCountersTypeSelected}>
                            <div className="icon">
                                <span>123</span>
                            </div>
                            {intl.formatMessage({ id: 'event.type.counters' })}
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div
                            className={
                                props.formData.eventConditionType === EventConditionType.GPIO
                                    ? 'event-select-type selected'
                                    : 'event-select-type'
                            }
                            onClick={setGpioTypeSelected}>
                            <div className="icon">
                                <span>I/o</span>
                            </div>
                            {intl.formatMessage({ id: 'event.type.gpio' })}
                        </div>
                    </Col>
                </Row>
            </Grid>

            <FlexboxGrid style={{ marginTop: 12 }} justify="space-between">
                <FlexboxGrid.Item>
                    <Button
                        onClick={props.handleCancel}
                        appearance="ghost"
                        color="red"
                        data-cy="event-createModal-cancel">
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <Button onClick={handleValidStep} data-cy="event-createModal-next" appearance="primary">
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
};
