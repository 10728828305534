export class Configuration {
    private _id: number;
    private _section: string;
    private _key: string;
    private _value: string;
    private _createdAt: Date;
    private _isDeletable: boolean;
    private _isEditable: boolean;
    private _updatedAt: Date;
    private _createdBy: string;
    private _updatedBy: string;

    constructor(data: ConfigurationInterface) {
        this._id = data.id;
        this._section = data.section;
        this._key = data.key;
        this._value = data.value;
        this._createdAt = data.createdAt;
        this._updatedAt = data.createdAt;
        this._isDeletable = data.isDeletable;
        this._isEditable = data.isEditable;
        this._updatedBy = data.updatedBy;
        this._createdBy = data.createdBy;
    }

    public getId(): number {
        return this._id;
    }

    public getSection(): string {
        return this._section;
    }

    public getKey(): string {
        return this._key;
    }

    public getValue(): string {
        return this._value;
    }

    public getCreatedAt(): Date {
        return this._createdAt;
    }

    public getUpdatedAt(): Date {
        return this._updatedAt;
    }

    public getCreatedBy(): string {
        return this._createdBy;
    }

    public getUpdatedBy(): string {
        return this._updatedBy;
    }

    public getIsDeletable(): boolean {
        return this._isDeletable;
    }

    public getIsEditable(): boolean {
        return this._isEditable;
    }
}

export interface ConfigurationInterface {
    id: number;
    section: string;
    key: string;
    value: string;
    createdAt: Date;
    isDeletable: boolean;
    isEditable: boolean;
    updatedAt: Date;
    createdBy: string;
    updatedBy: string;
}
