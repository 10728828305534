import { userConstants } from '../constants';
import { userService } from '../services';
import { notificationActions } from './notification.actions';

export const userActions = {
    getAll,
    // getOne,
    create,
    remove,
    updateInformations,
    updateUserPassword,
    updateUserRights,
    // ioUserLoggedIn,
    // ioUserLoggedOut,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll().then(
            users => dispatch(success(users)),
            error => {
                dispatch(failure(error));
                dispatch(notificationActions.createNotification('error', 'user.error.fetchAllUsersError'));
            }
        );
    };

    function request() {
        return { type: userConstants.GET_ALL_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GET_ALL_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GET_ALL_FAILURE, error };
    }
}

// function getOne(id) {
//     return dispatch => {
//         dispatch(request());

//         userService.getById(id).then(
//             user => dispatch(success(user)),
//             error => {
//                 dispatch(failure(error));
//                 dispatch(
//                     notificationActions.createNotification(
//                         'error',
//                         'user.error.fetchUserError'
//                     )
//                 );
//             }
//         );
//     };

//     function request() {
//         return { type: userConstants.GETONE_REQUEST };
//     }
//     function success(user) {
//         return { type: userConstants.GETONE_SUCCESS, user };
//     }
//     function failure(error) {
//         return { type: userConstants.GETONE_FAILURE, error };
//     }
// }

function remove(id) {
    return dispatch => {
        dispatch(request(id));

        userService.remove(id).then(
            data => {
                dispatch(success(data));
                dispatch(notificationActions.createNotification('success', 'user.success.removeUserSuccess'));
            },
            error => {
                dispatch(failure(id, error));
                dispatch(notificationActions.createNotification('error', 'user.error.removeUserError'));
            }
        );
    };

    function request(id) {
        return { type: userConstants.DELETE_REQUEST, id };
    }
    function success(user) {
        return { type: userConstants.DELETE_SUCCESS, user };
    }
    function failure(id, error) {
        return { type: userConstants.DELETE_FAILURE, id, error };
    }
}

function create(firstName, lastName, email, password) {
    const crypto = window.crypto;
    var array = new Uint32Array(10);

    return dispatch => {
        let id = crypto.getRandomValues(array);
        let newUser = {
            id: id,
            name: `${firstName} ${lastName}`,
            email: email,
        };
        dispatch(request(newUser));

        userService.create(firstName, lastName, email, password).then(
            user => {
                dispatch(success(user, id));
                dispatch(notificationActions.createNotification('success', 'user.success.createUserSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'user.error.createUserError'));
            }
        );
    };

    function request(user) {
        return { type: userConstants.CREATE_REQUEST, user };
    }
    function success(user, id) {
        return { type: userConstants.CREATE_SUCCESS, user, id: id };
    }
    function failure(error, id) {
        return { type: userConstants.CREATE_FAILURE, error, id: id };
    }
}

function updateInformations(id, firstName, lastName, email, enabled) {
    return dispatch => {
        dispatch(request(id));

        userService.updateInformations(id, firstName, lastName, email, enabled).then(
            user => {
                dispatch(success(user));
                dispatch(notificationActions.createNotification('success', 'user.success.updateUserSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'user.error.updateUserError'));
            }
        );
    };

    function request(id) {
        return { type: userConstants.UPDATE_REQUEST, id };
    }
    function success(user) {
        return { type: userConstants.UPDATE_SUCCESS, user };
    }
    function failure(error, id) {
        return { type: userConstants.UPDATE_FAILURE, error, id };
    }
}

function updateUserPassword(id, password, repeatedPassword) {
    return dispatch => {
        dispatch(request(id));

        userService.updateUserPassword(id, password, repeatedPassword).then(
            user => {
                dispatch(success(user));
                dispatch(notificationActions.createNotification('success', 'user.success.updateUserSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'user.error.updateUserError'));
            }
        );
    };

    function request(id) {
        return { type: userConstants.UPDATE_REQUEST, id };
    }
    function success(user) {
        return { type: userConstants.UPDATE_SUCCESS, user };
    }
    function failure(error, id) {
        return { type: userConstants.UPDATE_FAILURE, error, id };
    }
}

function updateUserRights(id, rights) {
    return dispatch => {
        dispatch(request(id));

        userService.updateUserRights(id, rights).then(
            user => {
                dispatch(success(user));
                dispatch(notificationActions.createNotification('success', 'user.success.updateUserSuccess'));
            },
            error => {
                dispatch(failure(error, id));
                dispatch(notificationActions.createNotification('error', 'user.error.updateUserError'));
            }
        );
    };

    function request(id) {
        return { type: userConstants.UPDATE_REQUEST, id };
    }
    function success(user) {
        return { type: userConstants.UPDATE_SUCCESS, user };
    }
    function failure(error, id) {
        return { type: userConstants.UPDATE_FAILURE, error, id };
    }
}

// function ioUserLoggedIn(user) {
//     return dispatch => {
//         dispatch(success(user));
//     };

//     function success(user) {
//         return {
//             type: userConstants.IO_USER_LOGGED_IN,
//             user: userHandler(user),
//         };
//     }
// }

// function ioUserLoggedOut(user) {
//     return dispatch => {
//         dispatch(success(user));
//     };

//     function success(user) {
//         return {
//             type: userConstants.IO_USER_LOGGED_IN,
//             user: userHandler(user),
//         };
//     }
// }
