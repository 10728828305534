import { faHourglassStart, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { Handle, NodeComponentProps, Position } from 'react-flow-renderer';
import { FlexboxGrid, List, Tag, TagGroup } from 'rsuite';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { VirtualDisplayPreviewInformation } from '../../../../../handlers/lpDisplays/VirtualDisplay';
import { VirtualDisplayStep } from '../../../../../handlers/lpDisplays/VirtualDisplayStep';
import { LPMatrixDisplayWidget } from '../../../LPDisplays/Widgets/LPMatrixDisplayWidget';

type DataProps = {
    step: VirtualDisplayStep;
    stepIndex: number;
    display: LPDisplay;
    virtualDisplay: VirtualDisplayPreviewInformation;
    isFirst: boolean;
    isLast: boolean;
};

type Props = NodeComponentProps<DataProps>;

// label: string;
// step: VirtualDisplayStep;
// display: LPDisplay;
// virtualDisplay: VirtualDisplay;
// getHandleColor: Function;

const VirtualDisplayStepNode = (props: Props) => {
    const step: VirtualDisplayStep = props.data.step;

    const triggerMinTextNumber = step.getTriggerMin().getActionInformation().number;
    const triggerMaxTextNumber = step.getTriggerMax().getActionInformation().number;
    const systemText = props.data.display.getTextConfig().getSystemTextEntries();
    const userText = props.data.display.getTextConfig().getUserText();

    let backgroundColor = props.data.virtualDisplay.backgroundColor;

    if (backgroundColor === '#000000') {
        backgroundColor = 'rgba(0, 0, 0, 0.5)';
    }

    return (
        <div
            className={`matrix-display-node ${step.getState() === 2 && 'virtual-display-step-wait'} ${
                step.getState() === 0 && 'virtual-display-step-disabled'
            }`}>
            <Fragment>
                {step.getState() === 1 && (
                    <Fragment>
                        {props.data.isFirst ? (
                            <Fragment>
                                <Handle
                                    type="target"
                                    id="target-virtual-display"
                                    position={Position.Left}
                                    isConnectable={false}
                                    style={{
                                        background: '#555',
                                        top: '35%',
                                    }}
                                />
                                <Handle
                                    type="target"
                                    id="target-virtual-display-step-loop"
                                    position={Position.Right}
                                    isConnectable={false}
                                    style={{ background: '#555', top: '80%' }}
                                />

                                <Handle
                                    type="target"
                                    id="target-virtual-display-step-trigger"
                                    position={Position.Left}
                                    isConnectable={false}
                                    style={{ background: '#555', top: '70%' }}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Handle
                                    type="target"
                                    id="target-virtual-display-step"
                                    position={Position.Left}
                                    isConnectable={false}
                                    style={{ background: '#555', top: '20%' }}
                                />

                                <Handle
                                    type="target"
                                    id="target-virtual-display-step-trigger"
                                    position={Position.Left}
                                    isConnectable={false}
                                    style={{ background: '#555', top: '50%' }}
                                />
                            </Fragment>
                        )}

                        {props.data.isLast && !props.data.isFirst ? (
                            <Handle
                                type="source"
                                id="source-virtual-display-step-loop"
                                position={Position.Left}
                                isConnectable={false}
                                style={{ background: '#555', top: '80%' }}
                            />
                        ) : (
                            <Handle
                                type="source"
                                id="source-virtual-display-step"
                                position={Position.Right}
                                isConnectable={false}
                                style={{ background: '#555', top: '20%' }}
                            />
                        )}

                        {step.getMode() >= 101 && step.getMode() <= 132 && (
                            <Fragment>
                                <Handle
                                    type="source"
                                    id="source-virtual-display-step-trigger-min"
                                    position={Position.Right}
                                    isConnectable={false}
                                    style={{ background: '#006400', top: '40%' }}
                                />
                                <Handle
                                    type="source"
                                    id="source-virtual-display-step-trigger-max"
                                    position={Position.Right}
                                    isConnectable={false}
                                    style={{ background: '#8B0000', top: '60%' }}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {step.getState() === 2 && (
                    <Fragment>
                        <Handle
                            type="target"
                            id="target-virtual-display-step-trigger"
                            position={Position.Left}
                            isConnectable={false}
                            style={{ background: 'orange', top: '50%' }}
                        />
                        <Handle
                            type="target"
                            id="target-virtual-display-step-loop"
                            position={Position.Right}
                            isConnectable={false}
                            style={{ background: '#555', top: '50%' }}
                        />

                        {step.getMode() >= 101 && step.getMode() <= 132 && (
                            <Fragment>
                                <Handle
                                    type="source"
                                    id="source-virtual-display-step-trigger-min"
                                    position={Position.Right}
                                    isConnectable={false}
                                    style={{ background: '#006400', top: '20%' }}
                                />
                                <Handle
                                    type="source"
                                    id="source-virtual-display-step-trigger-max"
                                    position={Position.Right}
                                    isConnectable={false}
                                    style={{ background: '#8B0000', top: '80%' }}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Fragment>
            <FlexboxGrid align="middle" justify="space-between" className="margin-bottom-5">
                <FlexboxGrid.Item></FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <h5 className="text-center margin-bottom-10"></h5>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    {step.getState() === 0 && <FontAwesomeIcon icon={faTimesCircle} color="red" />}
                    {step.getState() === 2 && <FontAwesomeIcon icon={faHourglassStart} color="orange" />}
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <LPMatrixDisplayWidget backgroundColor={backgroundColor} display={props.data.display} step={step} />

            <List bordered size="sm" className="margin-top-5">
                {step.getTriggerMin().getAction() >= 1000 && (
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item>
                                <Tag color="blue">{`< ${step.getTriggerMin().getValue()}`}</Tag>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <TagGroup>
                                    {step.getTriggerMin().getActionInformation().name === 'userText' && (
                                        <Tag color="green">{userText[triggerMinTextNumber]}</Tag>
                                    )}

                                    {step.getTriggerMin().getActionInformation().name === 'text' && (
                                        <Tag color="green">
                                            {systemText[triggerMinTextNumber].lang0} /{' '}
                                            {systemText[triggerMinTextNumber].lang1} /{' '}
                                            {systemText[triggerMinTextNumber].lang2} /{' '}
                                            {systemText[triggerMinTextNumber].lang3}
                                        </Tag>
                                    )}
                                </TagGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}

                {step.getTriggerMax().getAction() >= 1000 && (
                    <List.Item className="panel-list">
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item>
                                <Tag color="blue">{`> ${step.getTriggerMax().getValue()}`}</Tag>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <TagGroup>
                                    {step.getTriggerMax().getActionInformation().name === 'userText' && (
                                        <Tag color="green">{userText[triggerMaxTextNumber]}</Tag>
                                    )}

                                    {step.getTriggerMax().getActionInformation().name === 'text' && (
                                        <Tag color="green">
                                            {systemText[triggerMaxTextNumber].lang0} /{' '}
                                            {systemText[triggerMaxTextNumber].lang1} /{' '}
                                            {systemText[triggerMaxTextNumber].lang2} /{' '}
                                            {systemText[triggerMaxTextNumber].lang3}
                                        </Tag>
                                    )}
                                </TagGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                )}
            </List>
        </div>
    );
};

export default VirtualDisplayStepNode;
