import moment from 'moment';

export function eventHandler(event) {
    return {
        ...event,
        creation: `${moment(event.createdAt).format('DD/MM/YYYY HH:mm:ss')} - ${event.createdBy}`,
        update: `${moment(event.updatedAt).format('DD/MM/YYYY HH:mm:ss')} - ${event.updatedBy}`,
        createdAt: moment(event.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        createdBy: event.createdBy,
        updatedAt: moment(event.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        updatedBy: event.updatedBy,
        name: event.name,
        description: event.description,
        startTime: moment().startOf('day').add(event.startTime, 'minutes').format('HH:mm'),
        startDate: moment(event.startDate).format('DD/MM/YYYY'),
        endDate: moment(event.endDate).format('DD/MM/YYYY'),
    };
}
