import { faCheck, faEdit, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    List,
    Panel,
    SelectPicker,
    Tag,
    TagGroup,
    TagPicker,
    Toggle,
} from 'rsuite';
import Counter from '../../../../handlers/Counter/Counter';
import {
    AutoResetTrigger,
    EventConditionType,
    EventCounterCondition,
} from '../../../../handlers/EventAction/EventAction';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import PanelHeader from '../../../Custom/PanelHeader';
import { EditEventCounterModal } from '../../Modal/EditEvent/EditEventCounterModal';

type Props = {
    intl: Record<string, any>;
    event: Record<string, any> | undefined;
    reloadEvents: Function;
    counters: Counter[];
    ipCanModules: IpCan[];
} & WrappedComponentProps;

type State = {
    isEditMode: boolean;
    isLoading: boolean;
    formValue: FormValue;
    tags: Record<string, any>[];
    autoResetTriggerData: Record<string, any>[];
    isCreating: boolean;
    isCounterEditModalOpen: boolean;
};

interface FormValue {
    name: string;
    desc: string;
    isInfinite: boolean;
    daily: number[];
    startTime: Record<string, any>;
    startDate: string;
    endDate: string;
    isEnable: boolean;
    autoResetTrigger: AutoResetTrigger;
    autoResetTriggerTime: moment.Moment;
    moduleIpCanId: number;
    gpioNumber: number;
    gpioState: boolean;
    counters: EventCounterCondition[];
}

class EventInformations extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        const tags = [
            {
                label: this.props.intl.formatMessage({
                    id: 'event.daily.day.monday',
                }),
                value: 1,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'event.daily.day.tuesday',
                }),
                value: 2,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'event.daily.day.wednesday',
                }),
                value: 3,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'event.daily.day.thursday',
                }),
                value: 4,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'event.daily.day.friday',
                }),
                value: 5,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'event.daily.day.saturday',
                }),
                value: 6,
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'event.daily.day.sunday',
                }),
                value: 7,
            },
        ];

        const autoResetTriggerData = [
            {
                label: this.props.intl.formatMessage({ id: 'autoResetTrigger.none' }),
                value: AutoResetTrigger.NONE,
            },
            {
                label: this.props.intl.formatMessage({ id: 'autoResetTrigger.auto' }),
                value: AutoResetTrigger.AUTO,
            },
            {
                label: this.props.intl.formatMessage({ id: 'autoResetTrigger.time' }),
                value: AutoResetTrigger.TIME,
            },
        ];

        this.state = {
            isEditMode: false,
            isLoading: false,
            formValue: {
                name: this.props.event?.name,
                desc: this.props.event?.desc,
                isInfinite: this.props.event?.isInfinite,
                daily: Object.keys(this.props.event?.daily).map(
                    (day, index) => this.props.event?.daily[day] && index + 1
                ),
                startTime: moment().startOf('day').add(this.props.event?.eventCondition.startTime, 'minutes'),
                startDate: moment(this.props.event?.startDate, 'DD/MM/YYYY HH:mm').toISOString(),
                endDate: moment(this.props.event?.endDate, 'DD/MM/YYYY HH:mm').toISOString(),
                isEnable: this.props.event?.isEnable,
                autoResetTrigger: this.props.event?.eventCondition.autoResetTrigger,
                autoResetTriggerTime: moment()
                    .startOf('day')
                    .add(this.props.event?.eventCondition.autoResetTriggerTime, 'minute'),
                moduleIpCanId: this.props.event?.eventCondition.gpioCondition?.moduleIpCanId,
                gpioNumber: this.props.event?.eventCondition.gpioCondition?.gpioNumber,
                gpioState: this.props.event?.eventCondition.gpioCondition?.gpioState,
                counters: this.props.event?.eventCondition.countersId,
            },
            tags,
            autoResetTriggerData,
            isCreating: false,
            isCounterEditModalOpen: false,
        };

        this.handleFormChange = this.handleFormChange.bind(this);
        this.setEditMode = this.setEditMode.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
        this.openEditEventCounterModal = this.openEditEventCounterModal.bind(this);
        this.closeEditCounterModal = this.closeEditCounterModal.bind(this);
        this.handleCountersChange = this.handleCountersChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event !== this.props.event) {
            this.setState({
                formValue: {
                    name: this.props.event?.name,
                    desc: this.props.event?.desc,
                    isInfinite: this.props.event?.isInfinite,
                    daily: Object.keys(this.props.event?.daily).map(
                        (day, index) => this.props.event?.daily[day] && index + 1
                    ),
                    startTime: moment().startOf('day').add(this.props.event?.startTime, 'minutes'),
                    startDate: moment(this.props.event?.startDate, 'DD/MM/YYYY HH:mm').toISOString(),
                    endDate: moment(this.props.event?.endDate, 'DD/MM/YYYY HH:mm').toISOString(),
                    isEnable: this.props.event?.isEnable,
                    autoResetTrigger: this.props.event?.eventCondition.autoResetTrigger,
                    autoResetTriggerTime: moment()
                        .startOf('day')
                        .add(this.props.event?.eventCondition.autoResetTriggerTime, 'minute'),
                    moduleIpCanId: this.props.event?.eventCondition.gpioCondition?.moduleIpCanId,
                    gpioNumber: this.props.event?.eventCondition.gpioCondition?.gpioNumber,
                    gpioState: this.props.event?.eventCondition.gpioCondition?.gpioState,
                    counters: this.props.event?.eventCondition.countersId,
                },
            });
        }
    }

    /**
     * Set edit mode to true
     */
    setEditMode() {
        this.setState({
            isEditMode: true,
        });
    }

    cancelEdit() {
        this.setState({
            isEditMode: false,
            formValue: {
                name: this.props.event?.name,
                desc: this.props.event?.desc,
                isInfinite: this.props.event?.isInfinite,
                daily: Object.keys(this.props.event?.daily).map(
                    (day, index) => this.props.event?.daily[day] && index + 1
                ),
                startTime: moment().startOf('day').add(this.props.event?.startTime, 'minutes'),
                startDate: moment(this.props.event?.startDate, 'DD/MM/YYYY HH:mm').toISOString(),
                endDate: moment(this.props.event?.endDate, 'DD/MM/YYYY HH:mm').toISOString(),
                isEnable: this.props.event?.isEnable,
                autoResetTrigger: this.props.event?.eventCondition.autoResetTrigger,
                autoResetTriggerTime: moment()
                    .startOf('day')
                    .add(this.props.event?.eventCondition.autoResetTriggerTime, 'minute'),
                moduleIpCanId: this.props.event?.eventCondition.gpioCondition?.moduleIpCanId,
                gpioNumber: this.props.event?.eventCondition.gpioCondition?.gpioNumber,
                gpioState: this.props.event?.eventCondition.gpioCondition?.gpioState,
                counters: this.props.event?.eventCondition.countersId,
            },
        });
    }

    updateEvent() {
        this.setState({
            isLoading: true,
        });

        const formattedStartTime = moment(this.state.formValue.startTime);
        const formattedAutoResetTriggerTime = moment(this.state.formValue.autoResetTriggerTime);

        const event = {
            id: this.props.event?.id,
            name: this.state.formValue.name,
            desc: this.state.formValue.desc,
            isInfinite: this.state.formValue.isInfinite,
            daily: {
                monday: this.state.formValue.daily.includes(1),
                tuesday: this.state.formValue.daily.includes(2),
                wednesday: this.state.formValue.daily.includes(3),
                thursday: this.state.formValue.daily.includes(4),
                friday: this.state.formValue.daily.includes(5),
                saturday: this.state.formValue.daily.includes(6),
                sunday: this.state.formValue.daily.includes(7),
            },
            startDate: this.state.formValue.startDate,
            endDate: this.state.formValue.endDate,
            isEnable: this.state.formValue.isEnable,
            eventCondition: {},
        };

        if (this.props.event?.eventCondition.eventConditionType === EventConditionType.GPIO) {
            const ipCanMac = this.props.ipCanModules.find(
                ipCanModule => ipCanModule.id === this.state.formValue.moduleIpCanId
            )?.mac;

            event.eventCondition = {
                eventConditionType: EventConditionType.GPIO,
                startTime: formattedStartTime.get('hours') * 60 + formattedStartTime.get('minutes'),
                gpioCondition: {
                    moduleIpCanId: this.state.formValue.moduleIpCanId,
                    gpioNumber: this.state.formValue.gpioNumber,
                    gpioState: this.state.formValue.gpioState,
                    moduleIpCanMac: ipCanMac,
                },
                autoResetTrigger: this.state.formValue.autoResetTrigger,
                autoResetTriggerTime:
                    formattedAutoResetTriggerTime.get('hours') * 60 + formattedAutoResetTriggerTime.get('minutes'),
            };
        } else if (this.props.event?.eventCondition.eventConditionType === EventConditionType.CALENDAR) {
            event.eventCondition = {
                eventConditionType: EventConditionType.CALENDAR,
                startTime: formattedStartTime.get('hours') * 60 + formattedStartTime.get('minutes'),
                autoResetTrigger: 'auto',
                autoResetTriggerTime: 0,
            };
        } else if (this.props.event?.eventCondition.eventConditionType === EventConditionType.COUNTERS) {
            event.eventCondition = {
                eventConditionType: EventConditionType.COUNTERS,
                startTime: formattedStartTime.get('hours') * 60 + formattedStartTime.get('minutes'),
                autoResetTrigger: this.state.formValue.autoResetTrigger,
                autoResetTriggerTime:
                    formattedAutoResetTriggerTime.get('hours') * 60 + formattedAutoResetTriggerTime.get('minutes'),
                countersId: this.state.formValue.counters,
            };
        }

        axiosService
            .getAxios()
            .put('/event-actions/event', event, { headers: authHeader() })
            .then(() => {
                this.setState({
                    isEditMode: false,
                    isLoading: false,
                });

                this.props.reloadEvents();
            })
            .catch(err => {
                console.error(err);

                Alert.error(this.props.intl.formatMessage({ id: 'event.update.error' }));

                this.setState({
                    isLoading: false,
                });
            });
    }

    /**
     * Update form value in state according to user entries
     * @param {Object} formValue
     */
    handleFormChange(formValue) {
        this.setState({
            formValue: {
                ...formValue,
                startTime: moment(formValue.startTime).set('seconds', 0).set('milliseconds', 0).toISOString(),
                startDate: moment(formValue.startDate).set('seconds', 0).set('milliseconds', 0).toISOString(),
                endDate: moment(formValue.endDate).set('seconds', 0).set('milliseconds', 0).toISOString(),
            },
        });
    }

    openEditEventCounterModal() {
        this.setState({
            isCounterEditModalOpen: true,
        });
    }

    closeEditCounterModal() {
        this.setState({
            isCounterEditModalOpen: false,
        });
    }

    handleCountersChange(counters) {
        this.setState({
            formValue: {
                ...this.state.formValue,
                counters,
            },
        });
    }

    render() {
        const currentIpCanModule = this.props.ipCanModules.find(
            module => module.id === this.state.formValue.moduleIpCanId
        );

        return (
            <>
                <EditEventCounterModal
                    counters={this.props.counters}
                    currentCounters={this.state.formValue.counters}
                    isEditMode={this.state.isEditMode}
                    onHide={this.closeEditCounterModal}
                    show={this.state.isCounterEditModalOpen}
                    onCountersChange={this.handleCountersChange}
                />
                <Panel
                    shaded
                    bordered
                    bodyFill
                    className="panel-small"
                    header={
                        <PanelHeader
                            title={this.props.intl.formatMessage({ id: 'event.informations' })}
                            buttons={[
                                <SecuredFragment
                                    key="event.informations.edit"
                                    authorizedRoles={[rolesConstants.eventAction.UPDATE]}>
                                    <Button
                                        data-cy="event-informations-editButton"
                                        onClick={this.setEditMode}
                                        appearance="primary"
                                        size="sm">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }>
                    <Form fluid formValue={this.state.formValue} onChange={this.handleFormChange}>
                        <List hover>
                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item componentClass={Col} xs={10} style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="event.type" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        <p data-cy="event-show-type" className="text-right">
                                            <FormattedMessage
                                                id={`event.type.${this.props.event?.eventCondition.eventConditionType}`}
                                            />
                                        </p>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item componentClass={Col} xs={10} style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="event.enabled" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} style={{ textAlign: 'right' }}>
                                        {this.state.isEditMode ? (
                                            <FormControl
                                                data-cy="event-edit-isEnable"
                                                name="isEnable"
                                                accepter={Toggle}
                                                defaultChecked={this.state.formValue?.isEnable}
                                            />
                                        ) : (
                                            <p data-cy="event-show-isEnable" className="text-right">
                                                {this.props.event?.isEnable ? (
                                                    <FontAwesomeIcon
                                                        size="lg"
                                                        icon={faCheck}
                                                        style={{ color: 'green' }}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        size="lg"
                                                        icon={faTimes}
                                                        style={{ color: 'red' }}
                                                    />
                                                )}
                                            </p>
                                        )}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item componentClass={Col} xs={10} style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="event.active" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} style={{ textAlign: 'right' }}>
                                        <p data-cy="event-show-isTriggered" className="text-right">
                                            {this.props.event?.isTriggered ? (
                                                <FontAwesomeIcon size="lg" icon={faCheck} style={{ color: 'green' }} />
                                            ) : (
                                                <FontAwesomeIcon size="lg" icon={faTimes} style={{ color: 'red' }} />
                                            )}
                                        </p>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item componentClass={Col} xs={10} style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="event.name" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        {this.state.isEditMode ? (
                                            <FormControl data-cy="event-edit-name" name="name" />
                                        ) : (
                                            <p data-cy="event-show-name" className="text-right">
                                                {this.props.event?.name}
                                            </p>
                                        )}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item componentClass={Col} xs={10} style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="event.desc" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        {this.state.isEditMode ? (
                                            <FormControl data-cy="event-edit-desc" name="desc" />
                                        ) : (
                                            <p data-cy="event-show-desc" className="text-right">
                                                {this.props.event?.desc}
                                            </p>
                                        )}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item componentClass={Col} xs={10} style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="event.startTime" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12}>
                                        {this.state.isEditMode ? (
                                            <FormControl
                                                data-cy="event-edit-date"
                                                name="startTime"
                                                accepter={DatePicker}
                                                format="HH:mm"
                                                ranges={[]}
                                            />
                                        ) : (
                                            <p data-cy="event-show-date" className="text-right">
                                                {moment()
                                                    .startOf('day')
                                                    .add(this.props.event?.eventCondition.startTime, 'minutes')
                                                    .format('HH:mm')}
                                            </p>
                                        )}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item componentClass={Col} xs={10} style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="event.daily" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item
                                        componentClass={Col}
                                        xs={12}
                                        style={{ whiteSpace: 'normal', textAlign: 'center' }}>
                                        {this.state.isEditMode ? (
                                            <FormControl
                                                data-cy="event-edit-daily"
                                                name="daily"
                                                accepter={TagPicker}
                                                data={this.state.tags}
                                                renderMenuItem={(label, item) => {
                                                    return <div data-cy={`editEvent-value-${item.value}`}>{label}</div>;
                                                }}
                                            />
                                        ) : (
                                            <TagGroup>
                                                {Object.keys(this.props.event?.daily).map(day => {
                                                    if (this.props.event?.daily[day]) {
                                                        return (
                                                            <Tag
                                                                data-cy="event-show-daily"
                                                                color="violet"
                                                                key={`${this.props.event?.id}-${day}`}>
                                                                <FormattedMessage id={`event.daily.day.${day}`} />
                                                            </Tag>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </TagGroup>
                                        )}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            <List.Item className="panel-list">
                                <FlexboxGrid justify="space-between" align="middle">
                                    <FlexboxGrid.Item componentClass={Col} xs={10} style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="event.isInfinite" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} style={{ textAlign: 'right' }}>
                                        {this.state.isEditMode ? (
                                            <FormControl
                                                data-cy="event-edit-isInfinite"
                                                name="isInfinite"
                                                accepter={Toggle}
                                                defaultChecked={this.state.formValue.isInfinite}
                                            />
                                        ) : (
                                            <p data-cy="event-show-isInfinite" className="text-right">
                                                {this.props.event?.isInfinite ? (
                                                    <FontAwesomeIcon
                                                        size="lg"
                                                        icon={faCheck}
                                                        style={{ color: 'green' }}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        size="lg"
                                                        icon={faTimes}
                                                        style={{ color: 'red' }}
                                                    />
                                                )}
                                            </p>
                                        )}
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>

                            {!this.state.formValue.isInfinite && (
                                <Fragment>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={10}
                                                style={{ fontWeight: 'bold' }}>
                                                <FormattedMessage id="event.startDate" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                {this.state.isEditMode ? (
                                                    <FormControl
                                                        data-cy="event-edit-startDate"
                                                        name="startDate"
                                                        accepter={DatePicker}
                                                        format="DD/MM/YYYY"
                                                    />
                                                ) : (
                                                    <p data-cy="event-show-startDate" className="text-right">
                                                        {this.props.event?.startDate}
                                                    </p>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>

                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={10}
                                                style={{ fontWeight: 'bold' }}>
                                                <FormattedMessage id="event.endDate" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                {this.state.isEditMode ? (
                                                    <FormControl
                                                        data-cy="event-edit-endDate"
                                                        name="endDate"
                                                        accepter={DatePicker}
                                                        format="DD/MM/YYYY"
                                                    />
                                                ) : (
                                                    <p data-cy="event-show-endDate" className="text-right">
                                                        {this.props.event?.endDate}
                                                    </p>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                </Fragment>
                            )}

                            {this.props.event?.eventCondition.eventConditionType !== EventConditionType.CALENDAR && (
                                <>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={10}
                                                style={{ fontWeight: 'bold' }}>
                                                <FormattedMessage id="event.autoResetTrigger" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                {this.state.isEditMode ? (
                                                    <FormControl
                                                        data-cy="event-edit-autoResetTrigger"
                                                        name="autoResetTrigger"
                                                        accepter={SelectPicker}
                                                        data={this.state.autoResetTriggerData}
                                                        defaultChecked={this.state.formValue.autoResetTrigger}
                                                    />
                                                ) : (
                                                    <p data-cy="event-show-autoResetTrigger" className="text-right">
                                                        {this.props.intl.formatMessage({
                                                            id: `event.autoResetTrigger.${this.props.event?.eventCondition.autoResetTrigger}`,
                                                        })}
                                                    </p>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={10}
                                                style={{ fontWeight: 'bold' }}>
                                                <FormattedMessage id="event.autoResetTriggerTime" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                {this.state.isEditMode ? (
                                                    <FormControl
                                                        data-cy="event-edit-autoResetTriggerTime"
                                                        name="autoResetTriggerTime"
                                                        accepter={DatePicker}
                                                        format="HH:mm"
                                                        preventOverflow
                                                    />
                                                ) : (
                                                    <p data-cy="event-show-autoResetTriggerTime" className="text-right">
                                                        {moment()
                                                            .startOf('day')
                                                            .add(
                                                                this.props.event?.eventCondition.autoResetTriggerTime,
                                                                'minutes'
                                                            )
                                                            .format('HH:mm')}
                                                    </p>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                </>
                            )}

                            {this.props.event?.eventCondition.eventConditionType === EventConditionType.COUNTERS && (
                                <List.Item className="panel-list">
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item componentClass={Col} xs={10} style={{ fontWeight: 'bold' }}>
                                            <FormattedMessage id="event.counters" />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item componentClass={Col} xs={12}>
                                            <Button
                                                appearance="primary"
                                                onClick={this.openEditEventCounterModal}
                                                block
                                                data-cy="event-button-edit-counters">
                                                <FormattedMessage id={'event.counters'} />
                                            </Button>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            )}

                            {this.props.event?.eventCondition.eventConditionType === EventConditionType.GPIO && (
                                <>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={10}
                                                style={{ fontWeight: 'bold' }}>
                                                <FormattedMessage id="event.moduleIpCanId" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                {this.state.isEditMode ? (
                                                    <FormControl
                                                        data-cy="event-edit-moduleIpCanId"
                                                        name="moduleIpCanId"
                                                        accepter={SelectPicker}
                                                        cleanable={false}
                                                        data={this.props.ipCanModules.map(ipCanModule => {
                                                            return {
                                                                label: `${ipCanModule.label} (${ipCanModule.macStr})`,
                                                                value: ipCanModule.id,
                                                            };
                                                        })}
                                                    />
                                                ) : (
                                                    <p data-cy="event-show-moduleIpCanId" className="text-right">
                                                        {currentIpCanModule?.label} ({currentIpCanModule?.macStr})
                                                    </p>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={10}
                                                style={{ fontWeight: 'bold' }}>
                                                <FormattedMessage id="event.gpioName" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                {this.state.isEditMode ? (
                                                    <FormControl
                                                        data-cy="event-edit-gpioNumber"
                                                        name="gpioNumber"
                                                        accepter={SelectPicker}
                                                        data={[
                                                            { label: currentIpCanModule?.getGpioConfig().gpioIn[0].name, value: 1 },
                                                            { label: currentIpCanModule?.getGpioConfig().gpioIn[1].name, value: 2 },
                                                            { label: currentIpCanModule?.getGpioConfig().gpioIn[2].name, value: 3 },
                                                            { label: currentIpCanModule?.getGpioConfig().gpioIn[3].name, value: 4 },
                                                        ]}
                                                        searchable={false}
                                                        cleanable={false}
                                                    />
                                                ) : (
                                                    <p data-cy="event-show-gpioNumber" className="text-right">
                                                        {currentIpCanModule?.getGpioConfig().gpioIn[this.props.event?.eventCondition.gpioCondition.gpioNumber - 1].name}
                                                    </p>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item className="panel-list">
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={10}
                                                style={{ fontWeight: 'bold' }}>
                                                <FormattedMessage id="event.gpioState" />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item
                                                componentClass={Col}
                                                xs={12}
                                                style={{ textAlign: 'right' }}>
                                                {this.state.isEditMode ? (
                                                    <FormControl
                                                        data-cy="event-edit-gpioState"
                                                        name="gpioState"
                                                        accepter={Toggle}
                                                        defaultChecked={this.state.formValue.gpioState}
                                                    />
                                                ) : (
                                                    <p data-cy="event-show-gpioState" className="text-right">
                                                        {this.props.event?.eventCondition.gpioCondition.gpioState ? (
                                                            <FontAwesomeIcon icon={faCheck} size="lg" color="green" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faTimes} size="lg" color="red" />
                                                        )}
                                                    </p>
                                                )}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                </>
                            )}
                        </List>
                    </Form>

                    {this.state.isEditMode && (
                        <FlexboxGrid align="middle" justify="end">
                            <FlexboxGrid.Item>
                                <ButtonGroup
                                    style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10, marginBottom: 10 }}>
                                    <Button
                                        onClick={this.cancelEdit}
                                        data-cy="event-editEvent-cancel"
                                        color="red"
                                        loading={this.state.isCreating}>
                                        <FontAwesomeIcon icon={faTimesCircle} />
                                    </Button>
                                    <Button
                                        onClick={this.updateEvent}
                                        data-cy="event-editEvent-valid"
                                        appearance="primary"
                                        loading={this.state.isCreating}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                </ButtonGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    )}
                </Panel>
            </>
        );
    }
}

export default injectIntl(EventInformations);
