import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Modal, Uploader } from 'rsuite';
import { FileType } from 'rsuite/lib/Uploader';
import { PictoPixel } from '../../../../handlers/pixelArt/pixelArtPictoRGBWS';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    handleUploadValid: (picto: PictoPixel[][]) => void;
};

export const ImportImageModal = (props: Props) => {
    const [importedImage, setImportedImage] = React.useState<FileType[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [imageData, setImageData] = React.useState<ImageData>();

    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    const onChangeHandler = (file: FileType[]) => {
        setImportedImage(file);
    };

    const handleCloseModal = () => {
        setImportedImage([]);

        props.onHide();
    };

    const handleClickValid = () => {
        if (imageData) {
            setIsLoading(true);

            const currentData = imageData.data;

            const finalArray: any[] = [];
            let lineArray: any[] = [];

            const LINE_LENGTH = imageData.width * 4;

            let currentLine = 0;

            for (let i = 0; i < currentData.length; i += 4) {
                const newLine = Math.trunc(i / LINE_LENGTH);

                if (newLine !== currentLine) {
                    finalArray.push(lineArray);
                    lineArray = [];
                    currentLine = newLine;
                }

                const currentColorValue = {
                    r: currentData[i] & 0xf0,
                    g: currentData[i + 1] & 0xf0,
                    b: currentData[i + 2] & 0xf0,
                };

                lineArray.push(currentColorValue);
            }

            if (lineArray.length > 0) {
                finalArray.push(lineArray);
            }

            props.handleUploadValid(finalArray);

            setIsLoading(false);

            handleCloseModal();
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');

        if (canvas && context && importedImage.length > 0 && importedImage[0].blobFile) {
            const image = new Image();

            image.onload = function () {
                URL.revokeObjectURL(image.src);
                context.drawImage(image, 0, 0);

                const canvasImageData = context.getImageData(0, 0, image.naturalWidth, image.naturalHeight);

                setImageData(canvasImageData);
            };

            image.src = URL.createObjectURL(importedImage[0].blobFile);
        }
    }, [importedImage]);

    return (
        <Modal show={props.isOpen} onHide={() => handleCloseModal()}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="editPictoRGB.importPictoRGB.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Uploader
                    data-cy="importPictoRGB-uploader"
                    multiple={false}
                    autoUpload={false}
                    onChange={onChangeHandler}
                    disabled={importedImage.length >= 1}
                    accept="image/png, image/jpeg"
                    listType="picture-text"
                />

                {importedImage.length === 1 && (
                    <canvas
                        ref={canvasRef}
                        // width={canvasInfo.width}
                        // height={canvasInfo.height}
                        style={{ display: 'none' }}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={() => handleCloseModal()}
                        data-cy="import-PictoRGB-cancel"
                        color="red"
                        disabled={isLoading}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={() => handleClickValid()}
                        type="submit"
                        data-cy="import-PictoRGB-valid"
                        appearance="primary"
                        loading={isLoading}
                        disabled={importedImage.length !== 1}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
