import { sensorPlaceTypeConstants } from '../constants';

type State = {
    isLoading: boolean;
    sensorPlaceTypesList: Array<Record<string, any>>;
    error: string | undefined;
    fetchedSensorPlaceType: undefined | Record<string, any>;
    create_modal_openned: boolean;
    delete_modal_openned: boolean;
    update_modal_openned: boolean;
    view_drawer_openned: boolean;
    sensorPlaceTypeDrawer: undefined | Record<string, any>;
};

const initialState = {
    isLoading: false,
    sensorPlaceTypesList: [],
    error: undefined,
    fetchedSensorPlaceType: undefined,
    create_modal_openned: false,
    delete_modal_openned: false,
    update_modal_openned: false,
    view_drawer_openned: false,
    sensorPlaceTypeDrawer: undefined,
};

export function sensorPlaceTypes(state: State = initialState, action) {
    switch (action.type) {
        case sensorPlaceTypeConstants.GET_ALL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case sensorPlaceTypeConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                error: undefined,
                sensorPlaceTypesList: action.sensorPlaceTypes.map(sensorPlaceType => {
                    return { ...sensorPlaceType, isLoading: false };
                }),
                isLoading: false,
            };
        case sensorPlaceTypeConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case sensorPlaceTypeConstants.CREATE_REQUEST:
            return {
                ...state,
                sensorPlaceTypesList: state.sensorPlaceTypesList.concat({
                    ...action.sensorPlaceType,
                    scenes: [],
                }),
                isLoading: true,
            };
        case sensorPlaceTypeConstants.CREATE_SUCCESS:
            return {
                ...state,
                sensorPlaceTypesList: state.sensorPlaceTypesList.map(sensorPlaceType => {
                    if (sensorPlaceType.id === action.id) {
                        return { ...action.sensorPlaceType };
                    } else {
                        return sensorPlaceType;
                    }
                }),
                create_modal_openned: false,
                isLoading: false,
            };
        case sensorPlaceTypeConstants.CREATE_FAILURE:
            return {
                ...state,
                sensorPlaceTypesList: state.sensorPlaceTypesList.filter(
                    sensorPlaceType => sensorPlaceType.id !== action.id
                ),
                create_modal_openned: false,
                isLoading: false,
            };
        case sensorPlaceTypeConstants.UPDATE_REQUEST:
            return {
                ...state,
                sensorPlaceTypesList: state.sensorPlaceTypesList.map(sensorPlaceType =>
                    sensorPlaceType.id === action.id ? { ...sensorPlaceType, isLoading: true } : sensorPlaceType
                ),
            };
        case sensorPlaceTypeConstants.UPDATE_SUCCESS:
            return {
                ...state,
                sensorPlaceTypesList: state.sensorPlaceTypesList.map(sensorPlaceType => {
                    if (sensorPlaceType.id === action.sensorPlaceType.id) {
                        return { ...sensorPlaceType, ...action.sensorPlaceType };
                    } else {
                        return sensorPlaceType;
                    }
                }),
                sensorPlaceTypeDrawer: { ...state.sensorPlaceTypeDrawer, ...action.sensorPlaceType },
                // TODO
            };
        case sensorPlaceTypeConstants.UPDATE_FAILURE:
            return {
                ...state,
                sensorPlaceTypesList: state.sensorPlaceTypesList.map(sensorPlaceType => {
                    if (sensorPlaceType.id === action.id) {
                        return { ...sensorPlaceType, isLoading: false };
                    } else {
                        return sensorPlaceType;
                    }
                }),
            };
        case sensorPlaceTypeConstants.DELETE_REQUEST:
            // add 'deleting:true' property to sensorPlaceType being deleted
            return {
                ...state,
                sensorPlaceTypesList: state.sensorPlaceTypesList.map(sensorPlaceType =>
                    sensorPlaceType.id === action.id ? { ...sensorPlaceType, isLoading: true } : sensorPlaceType
                ),
            };
        case sensorPlaceTypeConstants.DELETE_SUCCESS:
            // remove deleted sensorPlaceType from state
            return {
                ...state,
                sensorPlaceTypesList: state.sensorPlaceTypesList.filter(
                    sensorPlaceType => sensorPlaceType.id !== action.id
                ),
                view_drawer_openned: false,
                sensorPlaceTypeDrawer: undefined,
            };
        case sensorPlaceTypeConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to sensorPlaceType
            return {
                ...state,
                sensorPlaceTypesList: state.sensorPlaceTypesList.map(sensorPlaceType => {
                    if (sensorPlaceType.id === action.id) {
                        // make copy of sensorPlaceType without 'deleting:true' property
                        const { ...sensorPlaceTypeCopy } = sensorPlaceType;
                        // return copy of sensorPlaceType with 'deleteError:[error]' property
                        return { ...sensorPlaceTypeCopy, deleteError: action.error };
                    }

                    return sensorPlaceType;
                }),
            };
        // case sensorPlaceTypeConstants.GETONE_REQUEST:
        //     return {
        //         ...state,
        //         fetchedSensorPlaceType: {},
        //     };
        // case sensorPlaceTypeConstants.GETONE_SUCCESS:
        //     return {
        //         ...state,
        //         fetchedSensorPlaceType: action.sensorPlaceType,
        //     };
        // case sensorPlaceTypeConstants.GETONE_FAILURE:
        //     return {
        //         ...state,
        //         error: action.error,
        //     };
        case sensorPlaceTypeConstants.OPEN_CREATE_MODAL:
            return {
                ...state,
                create_modal_openned: true,
                delete_modal_openned: false,
                update_modal_openned: false,
                view_drawer_openned: false,
                sensorPlaceTypeDrawer: undefined,
            };
        case sensorPlaceTypeConstants.CLOSE_CREATE_MODAL:
            return {
                ...state,
                create_modal_openned: false,
                delete_modal_openned: false,
                update_modal_openned: false,
                view_drawer_openned: false,
                sensorPlaceTypeDrawer: undefined,
            };
        case sensorPlaceTypeConstants.OPEN_VIEW_DRAWER_SUCCESS:
            return {
                ...state,
                view_drawer_openned: true,
                sensorPlaceTypeDrawer: action.sensorPlaceType,
            };
        case sensorPlaceTypeConstants.CLOSE_VIEW_DRAWER:
            return {
                ...state,
                view_drawer_openned: false,
                sensorPlaceTypeDrawer: undefined,
            };
        case sensorPlaceTypeConstants.OPEN_DELETE_MODAL_REQUEST:
            return {
                ...state,
                view_drawer_openned: false,
                sensorPlaceTypeDrawer: undefined,
            };

        case sensorPlaceTypeConstants.OPEN_DELETE_MODAL_SUCCESS:
            return {
                ...state,
                delete_modal_openned: true,
                sensorPlaceTypeDrawer: action.sensorPlaceType,
            };
        case sensorPlaceTypeConstants.OPEN_DELETE_MODAL_FAILURE:
            return {
                ...state,
                delete_modal_openned: false,
                sensorPlaceTypeDrawer: undefined,
            };
        case sensorPlaceTypeConstants.CLOSE_DELETE_MODAL:
            return {
                ...state,
                delete_modal_openned: false,
                sensorPlaceTypeDrawer: undefined,
            };
        default:
            return state;
    }
}
