import moment from 'moment';
import { LPMatrixDisplayV2VirtualDisplayForceType } from '../../Components/Map/Components/LPMatrixDisplay/ForceLPMatrixDisplay/FlowChart/LPMatrixDisplayFactory';
import { rgbToHex } from '../../utils/colorUtils';
import { VirtualDisplayStep, VirtualDisplayStepInterface, VirtualDisplayStepState } from './VirtualDisplayStep';

const SIZE_MATRIX_DISPLAY = 32;
const SIZE_SEMI_MATRIX_DISPLAY = SIZE_MATRIX_DISPLAY / 2;
const HEIGHT_MATRIX_DISPLAY = 16;

export enum VirtualDisplayStateEnum {
    DISABLED = 0,
    ENABLED_WAIT_MODBUS = 1,
    WAIT = 2,
    FORCED_ENABLED = 3,
}

export class VirtualDisplay {
    private _id: number;
    private _createdAt: string;
    private _createdBy: string;
    private _updatedAt: string;
    private _updatedBy: string;
    private _posId: number;
    private _startX: number;
    private _startY: number;
    private _sizeX: number;
    private _sizeY: number;
    private _font: number;
    private _language: number;
    private _arrowSpeed: number;
    private _textSpeed: number;
    private _steps: VirtualDisplayStep[];
    private _backgroundColor: ColorInterface;
    private _state: number;
    private _currentStep: number;
    private _timeOfRound: number;
    private _isForce: boolean;
    private _forceMode?: LPMatrixDisplayV2VirtualDisplayForceType;

    constructor(virtualDisplay: VirtualDisplayInterface) {
        const {
            id,
            createdAt,
            createdBy,
            updatedAt,
            updatedBy,
            posId,
            startX,
            startY,
            sizeX,
            sizeY,
            font,
            language,
            arrowSpeed,
            textSpeed,
            steps,
            backgroundColor,
            state,
            isForce,
            forceMode,
        } = virtualDisplay;

        this._id = id;
        this._createdAt = createdAt;
        this._createdBy = createdBy;
        this._updatedAt = updatedAt;
        this._updatedBy = updatedBy;
        this._posId = posId;
        this._startX = startX;
        this._startY = startY;
        this._sizeX = sizeX;
        this._sizeY = sizeY;
        this._font = font;
        this._state = state;
        this._language = language;
        this._arrowSpeed = arrowSpeed;
        this._textSpeed = textSpeed;
        this._backgroundColor = backgroundColor;

        this._steps = steps.map(step => new VirtualDisplayStep(step));

        let generatedSteps: VirtualDisplayStep[] = [];

        steps.forEach(step => {
            if (step.mode !== 0) generatedSteps.push(new VirtualDisplayStep(step));
        });

        this._steps = generatedSteps;

        this._currentStep = 0;

        let timeOfRound = 0;

        for (let i = 0; i < this._steps.length; i++) {
            const currentStep = steps[i];

            if (currentStep.state === VirtualDisplayStepState.ENABLED) {
                timeOfRound += currentStep.time;
            }
        }

        this._timeOfRound = timeOfRound;

        this._forceMode = forceMode;
        this._isForce = isForce ? isForce : false;
    }

    public getId() {
        return this._id;
    }

    public getCreatedAt(): string {
        return moment(this._createdAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getCreatedBy(): string {
        return this._createdBy;
    }

    public getUpdatedAt(): string {
        return moment(this._updatedAt).format('DD/MM/YYYY HH:mm:ss');
    }

    public getUpdatedBy(): string {
        return this._updatedBy;
    }

    public getPosId(): number {
        return this._posId;
    }

    public getStartX(): number {
        return this._startX;
    }

    public getStartY(): number {
        return this._startY;
    }

    public getSizeX(): number {
        return this._sizeX;
    }

    public getSizeY(): number {
        return this._sizeY;
    }

    public getFormattedStartX(): number {
        return this._startX / SIZE_SEMI_MATRIX_DISPLAY;
    }

    public getFormattedStartY(): number {
        return this._startY / SIZE_SEMI_MATRIX_DISPLAY;
    }

    public getFormattedSizeX(): number {
        return this._sizeX / SIZE_SEMI_MATRIX_DISPLAY;
    }

    public getFormattedSizeY(): number {
        return this._sizeY / SIZE_SEMI_MATRIX_DISPLAY;
    }

    public getFont(): number {
        return this._font;
    }

    public getForceMode(): LPMatrixDisplayV2VirtualDisplayForceType | undefined {
        return this._forceMode;
    }

    public getLanguage(): number {
        // TODO: Maybe transform to correct language

        return this._language;
    }

    public getArrowSpeed(): number {
        return this._arrowSpeed;
    }

    public getTextSpeed(): number {
        return this._textSpeed;
    }

    public getSteps(): VirtualDisplayStep[] {
        return this._steps;
    }

    public getBackgroundColor(): string {
        return rgbToHex(this._backgroundColor.red, this._backgroundColor.green, this._backgroundColor.blue);
    }

    public getState(): VirtualDisplayStateEnum {
        return this._state;
    }

    getBackgroundColorRGB(): ColorInterface {
        return {
            red: this._backgroundColor.red,
            green: this._backgroundColor.green,
            blue: this._backgroundColor.blue,
        };
    }

    public getNbMatrix(): number {
        const nbMatrix_X = this._sizeX / SIZE_MATRIX_DISPLAY;
        const nbMatrix_Y = this._sizeY / HEIGHT_MATRIX_DISPLAY;

        return nbMatrix_X * nbMatrix_Y;
    }

    public getDisplayLanguage(): number[] {
        const languages = ['FR', 'LANG 1', 'LANG 2', 'LANG 3'];

        let value: number[] = [];

        for (let i = 0; i < languages.length; i++) {
            if ((this._language & Math.pow(2, i)) !== 0) {
                value.push(Math.pow(2, i));
            }
        }

        return value;
    }

    private setUpdatedAt(updatedAt_: string) {
        this._updatedAt = updatedAt_;
    }

    private setUpdatedBy(updatedBy_: string) {
        this._updatedBy = updatedBy_;
    }

    private setPosId(posId_: number) {
        this._posId = posId_;
    }

    private setStartX(startX_: number) {
        this._startX = startX_;
    }

    private setStartY(startY_: number) {
        this._startY = startY_;
    }

    private setSizeX(sizeX_: number) {
        this._sizeX = sizeX_;
    }

    private setSizeY(sizeY_: number) {
        this._sizeY = sizeY_;
    }

    private setFont(font_: number) {
        this._font = font_;
    }

    private setLanguage(language_: number) {
        this._language = language_;
    }

    private setArrowSpeed(arrowSpeed_: number) {
        this._arrowSpeed = arrowSpeed_;
    }

    private setTextSpeed(textSpeed_: number) {
        this._textSpeed = textSpeed_;
    }

    private setBackgroundColor(backgroundColor_: ColorInterface) {
        this._backgroundColor = backgroundColor_;
    }

    private setState(state: number): void {
        this._state = state;
    }

    public updateVirtualDisplay(virtualDisplay: VirtualDisplayInterface) {
        this.setUpdatedAt(virtualDisplay.updatedAt);
        this.setUpdatedBy(virtualDisplay.updatedBy);
        this.setPosId(virtualDisplay.posId);
        this.setStartX(virtualDisplay.startX);
        this.setStartY(virtualDisplay.startY);
        this.setSizeX(virtualDisplay.sizeX);
        this.setSizeY(virtualDisplay.sizeY);
        this.setFont(virtualDisplay.font);
        this.setLanguage(virtualDisplay.language);
        this.setArrowSpeed(virtualDisplay.arrowSpeed);
        this.setTextSpeed(virtualDisplay.textSpeed);
        this.setBackgroundColor(virtualDisplay.backgroundColor);
        this.setState(virtualDisplay.state);
    }

    public updateVirtualDisplaySteps(steps_: VirtualDisplayStepInterface[]) {
        let steps: VirtualDisplayStep[] = [];

        steps_.forEach(step => {
            if (step.mode !== 0) {
                steps.push(new VirtualDisplayStep(step));
            }
        });

        this._steps = steps;
    }

    public getPreviewInformation(): VirtualDisplayPreviewInformation {
        return {
            id: this.getId(),
            startX: this.getStartX(),
            startY: this.getStartY(),
            sizeX: this.getSizeX(),
            sizeY: this.getSizeY(),
            formattedSizeX: this.getFormattedSizeX(),
            formattedSizeY: this.getFormattedSizeY(),
            formattedStartX: this.getFormattedStartX(),
            formattedStartY: this.getFormattedStartY(),
            createdAt: this.getCreatedAt(),
            updatedAt: this.getUpdatedAt(),
            createdBy: this.getCreatedBy(),
            updatedBy: this.getUpdatedBy(),
            posId: this.getPosId(),
            font: this.getFont(),
            language: this.getDisplayLanguage(),
            lang: this.getLanguage(),
            arrowSpeed: this.getArrowSpeed(),
            textSpeed: this.getTextSpeed(),
            backgroundColorRGB: this.getBackgroundColorRGB(),
            backgroundColor: this.getBackgroundColor(),
            state: this.getState(),
            steps: this.getSteps(),
        };
    }

    public getCurrentStep(): number {
        return this._currentStep;
    }

    public updateDisplay(countersValue?: number[]): boolean {
        const oldStep = this._currentStep;

        let stepIndex = 0;

        // CASE FORCED OVERRIDE
        if (this._isForce && this._forceMode === LPMatrixDisplayV2VirtualDisplayForceType.OVERRIDE) {
            const virtualDisplayForcedIndex = this._steps.findIndex(step => step.getIsForcedStep());

            this._currentStep = virtualDisplayForcedIndex;

            return false;
        }

        const enabledSteps = this.getSteps().filter(step => step.getState() === VirtualDisplayStepState.ENABLED);

        // if (oldStep > enabledSteps.length - 1) return false;

        // Case 1 step enabled
        if (enabledSteps.length < 2 || this._timeOfRound === 0) {
            this._currentStep = 0;

            return false;
        } else {
            let timeOffset = moment().unix() % this._timeOfRound;

            let tmp = this._timeOfRound;

            for (stepIndex = enabledSteps.length - 1; stepIndex !== 0; stepIndex--) {
                tmp -= enabledSteps[stepIndex].getTime();

                if (timeOffset >= tmp) {
                    break;
                }
            }

            const nextStep = this.getSteps()[enabledSteps[stepIndex].getStepOrder()];

            if (nextStep && nextStep.getTriggerMin().getTriggerType() >= 0) {
                if (
                    countersValue &&
                    nextStep.getTriggerMin().getTriggerType() === 0 &&
                    countersValue[nextStep.getCounterNumber() - 1] < nextStep.getTriggerMin().getValue()
                ) {
                    this._currentStep = nextStep.getTriggerMin().getAction() - 1;

                    return true;
                }
            }

            if (enabledSteps[stepIndex].getStepOrder() !== this._currentStep)
                this._currentStep = enabledSteps[stepIndex].getStepOrder();

            return oldStep !== this._currentStep;
        }
    }

    public addForcedVirtualDisplayStep(
        virtualDisplayStep: VirtualDisplayStep,
        mode: LPMatrixDisplayV2VirtualDisplayForceType
    ) {
        const existingVirtualDisplayStep = this._steps.findIndex(
            step => step.getStepOrder() === virtualDisplayStep.getStepOrder()
        );

        if (existingVirtualDisplayStep !== -1) this._steps.splice(existingVirtualDisplayStep, 1);

        this._steps.sort((a, b) => a.getStepOrder() - b.getStepOrder());

        if (mode === LPMatrixDisplayV2VirtualDisplayForceType.ADD_FORCE_STEP) {
            this._steps.push(virtualDisplayStep);
        } else {
            this._steps.unshift(virtualDisplayStep);
        }
    }

    public removeForcedVirtualDisplayStep() {
        this._steps = this._steps.filter(step => !step.getIsForcedStep());
    }
}

export interface VirtualDisplayInterface {
    id: number;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    posId: number;
    startX: number;
    startY: number;
    sizeX: number;
    sizeY: number;
    font: number;
    language: number;
    arrowSpeed: number;
    textSpeed: number;
    steps: VirtualDisplayStepInterface[];
    backgroundColor: ColorInterface;
    state: number;
    isForce?: boolean;
    forceMode?: LPMatrixDisplayV2VirtualDisplayForceType;
}

export interface ColorInterface {
    red: number;
    green: number;
    blue: number;
}

export interface VirtualDisplayPreviewInformation {
    id: number;
    startX: number;
    startY: number;
    sizeX: number;
    sizeY: number;
    formattedStartX: number;
    formattedStartY: number;
    formattedSizeX: number;
    formattedSizeY: number;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    posId: number;
    font: number;
    language: number[];
    lang: number;
    arrowSpeed: number;
    textSpeed: number;
    backgroundColor: string;
    backgroundColorRGB: ColorInterface;
    state: number;
    steps: VirtualDisplayStep[];
}
