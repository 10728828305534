import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Toggle } from 'rsuite';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

export const StatusToggle = (appName: string, isActiv: boolean) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isActivated, setIsActivated] = React.useState(isActiv);

    useEffect(() => {
        setIsActivated(isActiv);
    }, [isActiv]);

    const handleChange = value => {
        if (['Engine'].includes(appName)) {
            setIsLoading(true);

            let state = 'start';
            if (!value) state = 'stop';
            let valueToSend = 'start';
            if (!value) valueToSend = 'stop';

            axiosService
                .getAxios()
                .put(
                    '/engine/state',
                    {
                        state: valueToSend,
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    setIsLoading(false);

                    isActivated ? setIsActivated(false) : setIsActivated(true);

                    Alert.success(intl.formatMessage({ id: `configuration.startStop.${appName}.success` }));
                })
                .catch(err => {
                    console.error(err);

                    Alert.error(intl.formatMessage({ id: `configuration.startStop.${appName}.error` }));

                    setIsLoading(false);
                });
        }

        if (appName === 'Camera Sensor') {
            setIsLoading(true);

            axiosService
                .getAxios()
                .put(
                    '/cameras/state/camerasSensor',
                    {
                        state: value,
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    setIsActivated(value);
                    Alert.success(intl.formatMessage({ id: `configuration.startStop.${appName}.success` }));
                })
                .catch(err => {
                    console.error(err);
                    Alert.error(intl.formatMessage({ id: `configuration.startStop.${appName}.error` }));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        if (appName === 'Occupancy Sensor') {
            setIsLoading(true);

            axiosService
                .getAxios()
                .put(
                    '/cameras/state/occupancySensor',
                    {
                        state: value,
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    setIsActivated(value);
                    Alert.success(intl.formatMessage({ id: `configuration.startStop.${appName}.success` }));
                })
                .catch(err => {
                    console.error(err);
                    Alert.error(intl.formatMessage({ id: `configuration.startStop.${appName}.error` }));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        if (appName === 'Service ML') {
            setIsLoading(true);

            axiosService
                .getAxios()
                .put(
                    '/cameras/state/serviceMl',
                    {
                        state: value,
                    },
                    {
                        headers: authHeader(),
                    }
                )
                .then(() => {
                    setIsActivated(value);
                    Alert.success(intl.formatMessage({ id: `configuration.startStop.${appName}.success` }));
                })
                .catch(err => {
                    console.error(err);
                    Alert.error(intl.formatMessage({ id: `configuration.startStop.${appName}.error` }));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <Toggle
            onChange={handleChange}
            disabled={appName === 'React' || appName === 'API' || isLoading}
            data-cy={`configuration_restart_${appName}`}
            defaultChecked={isActivated}
            checked={isActivated}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faTimes} />}
        />
    );
};
