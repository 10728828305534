export type EventAction = {
    id: number;
    name: string;
    desc: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    startDate?: string;
    endDate?: string;
    eventCondition: EventCondition;
    isInfinite: boolean;
    isEnable: boolean;
    isTriggered: boolean;
    daily: {
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
    };
    tasks: Task[];
};

export type EventCondition = {
    startTime?: number; // in minutes
    countersId?: EventCounterCondition[];
    gpioCondition?: GpioCondition;
    eventConditionType: EventConditionType;
    autoResetTrigger: AutoResetTrigger;
    autoResetTriggerTime: number; // in minutes
};

export type EventCounterCondition = {
    counterId: number;
    triggerType: EventCounterConditionTriggerType;
    triggerValue: number;
};

export type GpioCondition = {
    moduleIpCanId: number;
    gpioNumber: number;
    gpioState: boolean;
};

export type Task = {
    id: number;
};

export enum EventConditionType {
    CALENDAR = 'calendar',
    COUNTERS = 'counters',
    GPIO = 'gpio',
}

export enum AutoResetTrigger {
    NONE = 'none',
    AUTO = 'auto',
    TIME = 'time',
}

export enum EventCounterConditionTriggerType {
    LOWER = '<',
    SUPERIOR = '>',
    EQUALITY = '==',
}

export interface EventWSAction {
    id: number;
    name: string;
    desc: string;
    startDate?: string;
    endDate?: string;
    eventCondition: EventCondition;
    isEnable: boolean;
    isTriggered: boolean;
    daily: {
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
    };
}
