import { FormattedMessage } from 'react-intl';
import Task from '../../../../../handlers/Event/Task';
import { CountersPart } from './Parts/CountersPart';

type Props = {
    task: Task;
};

export const TCMSensorUnforceColor = (props: Props) => {
    return (
        <>
            <FormattedMessage id="tasks.disableForceTCMSensorColor" />

            <CountersPart counters={props.task.getCounters()} />
        </>
    );
};
