import moment from 'moment';
import { Role, RoleInterface } from './Role';

export class Profile {
    private id: number;
    private name: string;
    private description: string;
    private createdAt: Date;
    private createdBy: string;
    private updatedAt: Date;
    private updatedBy: string;
    private roles: Role[];
    private users: any[];

    constructor(profile: ProfileInterface) {
        this.id = profile.id;
        this.name = profile.name;
        this.description = profile.description;
        this.createdAt = profile.createdAt;
        this.createdBy = profile.createdBy;
        this.updatedAt = profile.updatedAt;
        this.updatedBy = profile.updatedBy;
        this.roles = profile.roles ? profile.roles.map(role => new Role(role)) : [];
        this.users = profile.users ? profile.users : [];
    }

    public getId(): number {
        return this.id;
    }

    public getName(): string {
        return this.name;
    }

    public getDescription(): string {
        return this.description;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public getCreatedBy(): string {
        return this.createdBy;
    }

    public getUpdatedAt(): Date {
        return this.updatedAt;
    }

    public getUpdatedBy(): string {
        return this.updatedBy;
    }

    public getRoles(): Role[] {
        return this.roles;
    }

    public getCreation(): string {
        return `${this.createdBy} - ${moment(this.createdAt).format('DD/MM/YYYY HH:mm:ss')}`;
    }

    public getUpdate(): string {
        return `${this.updatedBy} - ${moment(this.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`;
    }

    public getUsers(): any[] {
        return this.users;
    }

    public updateProfile(profile: ProfileInterface): void {
        this.name = profile.name;
        this.description = profile.description;
        this.updatedAt = profile.updatedAt;
        this.updatedBy = profile.updatedBy;
        if (profile.roles) {
            this.roles = profile.roles.map(role => new Role(role));
        }

        if (profile.users) {
            this.users = profile.users;
        }
    }
}

export interface ProfileInterface {
    id: number;
    name: string;
    description: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    roles?: RoleInterface[];
    users?: any[];
}
