import { faCarSide, faPlusCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Panel } from 'rsuite';
import { sensorPlaceTypeActions } from '../../redux/actions';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import SensorPlaceTypeDrawer from './Drawer/SensorPlaceType';
import CreateSensorPlaceTypeModal from './Modal/SensorPlaceType/CreateSensorPlaceTypeModal';
import ColorCell from './Table/ColorCell';
import PlaceTypeCell from './Table/PlaceTypeCell';

const mapDispatchToProps = dispatch => ({
    getAllSensorPlaceTypes: () => dispatch(sensorPlaceTypeActions.getAll()),
    openCreateSensorPlaceTypeModal: () => dispatch(sensorPlaceTypeActions.openCreateModal()),
    openSensorPlaceTypeDrawer: id => dispatch(sensorPlaceTypeActions.openViewDrawer(id)),
});

type Props = {
    getAllSensorPlaceTypes: Function;
    openSensorPlaceTypeDrawer: Function;
    sensorPlaceTypes: Record<string, any>;
    openCreateSensorPlaceTypeModal: Function;
} & WrappedComponentProps;

class SensorPlaceTypeTable extends React.Component<Props> {
    columns: TableColumn<RowRecord>[];

    constructor(props) {
        super(props);

        this.openSensorPlaceTypeDrawer = this.openSensorPlaceTypeDrawer.bind(this);
        this.closeSensorPlaceTypeDrawer = this.closeSensorPlaceTypeDrawer.bind(this);

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'sensorPlaceType.label',
                }),
                grow: 1,
                center: true,
                cell: row => row.label,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'sensorPlaceType.description',
                }),
                grow: 1,
                center: true,
                cell: row => row.description,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'sensorPlaceType.placeType',
                }),
                grow: 1,
                center: true,
                cell: row => PlaceTypeCell(row.placeType),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'sensorPlaceType.free',
                }),
                grow: 1,
                center: true,
                cell: row => ColorCell(row.scenes, 1),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'sensorPlaceType.occuped',
                }),
                grow: 1,
                center: true,
                cell: row => ColorCell(row.scenes, 2),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'sensorPlaceType.overstay',
                }),
                grow: 1,
                center: true,
                cell: row => ColorCell(row.scenes, 3),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'sensorPlaceType.reserved',
                }),
                grow: 1,
                center: true,
                cell: row => ColorCell(row.scenes, 4),
            },
        ];
    }

    componentDidMount() {
        this.props.getAllSensorPlaceTypes();
    }

    openSensorPlaceTypeDrawer(id) {
        this.props.openSensorPlaceTypeDrawer(id);
    }

    closeSensorPlaceTypeDrawer() {
        this.setState({
            sensorDrawerId: undefined,
            showDrawer: false,
        });
    }

    render() {
        return (
            <Fragment>
                <SecuredFragment authorizedRoles={[rolesConstants.sensorPlaceType.CREATE]}>
                    <CreateSensorPlaceTypeModal />
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.sensorPlaceType.VIEW]}>
                    <SensorPlaceTypeDrawer />
                </SecuredFragment>

                <Panel
                    className="panel-big dashboard-card-header"
                    shaded
                    bordered
                    bodyFill
                    header={
                        <PanelHeader
                            icon={faCarSide}
                            title={this.props.intl.formatMessage({ id: 'sensorPlaceType.title' })}
                            tagValue={this.props.sensorPlaceTypes.sensorPlaceTypesList.length}
                            buttons={[
                                <SecuredFragment
                                    key="sensorPlaceType-reload"
                                    authorizedRoles={[rolesConstants.sensorPlaceType.VIEW_LIST]}>
                                    <Button
                                        appearance="primary"
                                        size="sm"
                                        onClick={() => this.props.getAllSensorPlaceTypes()}
                                        data-cy="sensorPlaceType-reloadPlaceType-button">
                                        <FontAwesomeIcon icon={faSyncAlt} className="margin-right-10" />
                                        <FormattedMessage id="sensorPlaceType.reloadList" />
                                    </Button>
                                </SecuredFragment>,
                                <SecuredFragment key="parking-create" authorizedRoles={[rolesConstants.parking.CREATE]}>
                                    <Button
                                        color="red"
                                        size="sm"
                                        onClick={() => this.props.openCreateSensorPlaceTypeModal()}
                                        data-cy="sensorPlaceType-addSensorPlaceType-button">
                                        <FontAwesomeIcon icon={faPlusCircle} className="margin-right-10" />
                                        <FormattedMessage id="sensorPlaceType.addSensorPlaceType" />
                                    </Button>
                                </SecuredFragment>,
                            ]}
                        />
                    }>
                    <ElementTable
                        columns={this.columns}
                        data={this.props.sensorPlaceTypes.sensorPlaceTypesList}
                        progressPending={this.props.sensorPlaceTypes.isLoading}
                        onRowClicked={row => this.openSensorPlaceTypeDrawer(row.id)}
                        small={false}
                        data-cy="placeType-table"
                    />
                </Panel>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { sensorPlaceTypes } = state;

    return { sensorPlaceTypes };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SensorPlaceTypeTable));
