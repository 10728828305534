import { FlowElement } from 'react-flow-renderer';
import { LPMatrixDisplayFlowElement } from '../LPMatrixDisplayChartElement';

export default class LPMatrixDisplayChartStepToStepEdge extends LPMatrixDisplayFlowElement {
    private _index: number;
    private _time: number;
    private _source: string;
    private _sourceHandle?: string;
    private _target: string;
    private _targetHandle?: string;

    constructor(data: LPMatrixDisplayChartStepToStepEdgeInterface) {
        super(data.id, data.label);
        this._index = data.index;
        this._time = data.time;
        this._source = data.source;
        this._sourceHandle = data.sourceHandle;
        this._target = data.target;
        this._targetHandle = data.targetHandle;
    }

    public render(): FlowElement<LPMatrixDisplayChartStepToStepEdgeType> {
        return {
            id: `${this.id}`,
            type: 'forceVirtualDisplayStepToStepNode',
            data: {
                time: this._time,
            },
            source: this._source,
            sourceHandle: this._sourceHandle,
            target: this._target,
            targetHandle: this._targetHandle,
        };
    }
}

export interface LPMatrixDisplayChartStepToStepEdgeInterface {
    id: string;
    index: number;
    time: number;
    label: string;
    source: string;
    sourceHandle?: string;
    target: string;
    targetHandle?: string;
}

export type LPMatrixDisplayChartStepToStepEdgeType = {
    time: number;
};
