import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Modal } from 'rsuite';
import { authActions } from '../../../../redux/actions';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    logout: Function;
    database: string;
    close: Function;
    show: boolean;
};

type State = {
    loading: boolean;
};

const mapDispatchToProps = (dispatch: Function) => ({
    logout: () => dispatch(authActions.logout(true)),
});

class ResetDatabaseModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        this.setState({
            loading: true,
        });
        axiosService
            .getAxios()
            .post('/configuration/database/reset/' + this.props.database, null, { headers: authHeader() })
            .then(() => {
                this.setState({
                    loading: false,
                });
                this.props.close();

                if (this.props.database === 'configuration') {
                    this.props.logout();
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <Modal backdrop={'static'} show={this.props.show} onHide={() => this.props.close()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="configuration.database.resetDatabaseModal.title" /> -{' '}
                        {this.props.database}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-cy={`reset-database-${this.props.database}`}>
                    <FormattedMessage id="configuration.database.resetDatabaseModal.confirmationMessage" />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.props.close()}
                            data-cy="reset-database-cancel"
                            color="red"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.handleSubmit}
                            data-cy="reset-database-valid"
                            appearance="primary"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(null, mapDispatchToProps)(ResetDatabaseModal);
