import { faMinusCircle, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, CheckPicker, Col, FlexboxGrid, Form, FormControl, FormGroup, Panel, SelectPicker, Tag } from 'rsuite';
import Counter from '../../../../../handlers/Counter/Counter';
import { SensorPlaceType } from '../../../../../handlers/sensorPlaceType/SensorPlaceType';
import PanelHeader from '../../../../Custom/PanelHeader';
import { FormValueCounterInterface } from '../EditLPDisplayCountersModal';

export default function CounterListItem(
    counter: FormValueCounterInterface,
    displayIndex: number,
    isEditMode: boolean,
    counters: Counter[],
    sensorsPlaceTypes: SensorPlaceType[],
    handleCounterChange: Function,
    handleSensorPlaceTypeChange: Function,
    addCounter: Function,
    deleteCounter: Function
) {
    let panelButtons: ReactElement[] = [];

    if (isEditMode) {
        panelButtons = [
            <Button
                data-cy="lpDisplay-counterConfiguration-add"
                color="green"
                size="sm"
                onClick={() => addCounter(displayIndex, 'add')}>
                <FontAwesomeIcon icon={faPlusCircle} />
            </Button>,
            <Button
                data-cy="lpDisplay-counterConfiguration-substract"
                color="red"
                size="sm"
                onClick={() => addCounter(displayIndex, 'sub')}>
                <FontAwesomeIcon icon={faMinusCircle} />
            </Button>,
        ];
    }

    return (
        <Panel
            className="panel-small"
            shaded
            bordered
            bodyFill
            data-cy="ipCanDevices-lpDisplays-counterConfiguration-counter"
            header={<PanelHeader title={counter.label} buttons={panelButtons} />}>
            <FlexboxGrid style={{ alignItems: 'stretch' }}>
                <FlexboxGrid.Item componentClass={Col} xs={12} className="text-center background-green">
                    {isEditMode ? (
                        <div>
                            {counter.addCounters.counters.length >= 1 && (
                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={11} className="bold padding-top-10">
                                        <FormattedMessage id="ipCanDevices.lpDisplays.counterConfiguration.selectCounter" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={11} className="bold padding-top-10">
                                        <FormattedMessage id="ipCanDevices.lpDisplays.counterConfiguration.sensorPlaceTypes" />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            )}

                            {counter.addCounters.counters.map((currentCounter, index) =>
                                generateCounterEdit(
                                    displayIndex,
                                    index,
                                    currentCounter,
                                    counter.addCounters.sensorPlaceType[index],
                                    counters,
                                    sensorsPlaceTypes,
                                    true,
                                    handleCounterChange,
                                    handleSensorPlaceTypeChange,
                                    deleteCounter
                                )
                            )}
                        </div>
                    ) : (
                        <div>
                            {counter.addCounters.counters.map((currentCounter, index) =>
                                generateCounterView(
                                    currentCounter,
                                    counter.addCounters.sensorPlaceType[index],
                                    counters,
                                    sensorsPlaceTypes
                                )
                            )}
                        </div>
                    )}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={12} className="text-center background-red">
                    {isEditMode ? (
                        <div>
                            {counter.deleteCounters.counters.length >= 1 && (
                                <FlexboxGrid>
                                    <FlexboxGrid.Item componentClass={Col} xs={11} className="bold padding-top-10">
                                        <FormattedMessage id="ipCanDevices.lpDisplays.counterConfiguration.selectCounter" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={11} className="bold padding-top-10">
                                        <FormattedMessage id="ipCanDevices.lpDisplays.counterConfiguration.sensorPlaceTypes" />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            )}

                            {counter.deleteCounters.counters.map((currentCounter, index) =>
                                generateCounterEdit(
                                    displayIndex,
                                    index,
                                    currentCounter,
                                    counter.deleteCounters.sensorPlaceType[index],
                                    counters,
                                    sensorsPlaceTypes,
                                    false,
                                    handleCounterChange,
                                    handleSensorPlaceTypeChange,
                                    deleteCounter
                                )
                            )}
                        </div>
                    ) : (
                        <div>
                            {counter.deleteCounters.counters.map((currentCounter, index) =>
                                generateCounterView(
                                    currentCounter,
                                    counter.deleteCounters.sensorPlaceType[index],
                                    counters,
                                    sensorsPlaceTypes
                                )
                            )}
                        </div>
                    )}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Panel>
    );
}

function generateCounterView(
    currentCounter: number,
    associatedSensorPlaceTypes: number[],
    counters: Counter[],
    sensorPlaceTypes: SensorPlaceType[]
) {
    const foundCounter = counters.find(c => c.id === currentCounter);

    let sensorPlaceTypeArray: SensorPlaceType[] = [];

    if (foundCounter) {
        associatedSensorPlaceTypes.forEach(currentSensorPlaceType => {
            const foundSpt = sensorPlaceTypes.find(s => s.getId() === currentSensorPlaceType);

            if (foundSpt) sensorPlaceTypeArray.push(foundSpt);
        });

        return (
            <div className="padding-bottom-10 padding-top-10">
                <Tag data-cy="lpDisplay-counterConfiguration-infoCounter" color="blue">
                    {foundCounter.label}
                </Tag>{' '}
                -{' '}
                {sensorPlaceTypeArray.map(placeType => (
                    <img
                        data-cy={`lpDisplay-counterConfiguration-${placeType?.getId()}`}
                        src={`data:image/${placeType
                            ?.getPlaceType()
                            ?.getImageType()};base64, ${placeType?.getPlaceType()?.generateImage()}`}
                        height={26}
                        key={`counter-image-${placeType?.getId()}`}
                        className="margin-right-5"
                    />
                ))}
            </div>
        );
    } else {
        return null;
    }
}

function generateCounterEdit(
    displayIndex: number,
    counterIndex: number,
    currentCounter: number,
    associatedSensorPlaceTypes: number[],
    counters: Counter[],
    sensorPlaceTypes: SensorPlaceType[],
    isAddCounter: boolean,
    handleCounterChange: Function,
    handleSensorPlaceTypeChange: Function,
    deleteCounter: Function
) {
    return (
        <Form fluid>
            <FlexboxGrid className="padding-bottom-10 padding-top-10" justify="start" align="bottom">
                <FlexboxGrid.Item componentClass={Col} xs={11}>
                    <FormGroup>
                        <FormControl
                            accepter={SelectPicker}
                            data-cy="lpDisplay-counter"
                            size="sm"
                            data={counters.map(counter => {
                                return {
                                    label: counter.label,
                                    value: counter.id,
                                };
                            })}
                            onChange={value =>
                                handleCounterChange(displayIndex, counterIndex, value, isAddCounter ? 'add' : 'sub')
                            }
                            value={currentCounter}
                            cleanable={false}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-counter-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={11}>
                    <FormGroup>
                        <FormControl
                            accepter={CheckPicker}
                            data-cy="lpDisplay-placeType"
                            size="sm"
                            data={sensorPlaceTypes.map(sensorPlaceType => {
                                return {
                                    label: sensorPlaceType.getLabel(),
                                    value: sensorPlaceType.getId(),
                                };
                            })}
                            onChange={value =>
                                handleSensorPlaceTypeChange(
                                    displayIndex,
                                    counterIndex,
                                    value,
                                    isAddCounter ? 'add' : 'sub'
                                )
                            }
                            value={associatedSensorPlaceTypes}
                            cleanable={false}
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`lpDisplay-placeType-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FormGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item componentClass={Col} xs={2}>
                    <Button
                        color="red"
                        data-cy="lpDisplay-counterConfiguration-remove"
                        size="sm"
                        onClick={() => deleteCounter(displayIndex, counterIndex, isAddCounter ? 'add' : 'sub')}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Form>
    );
}
