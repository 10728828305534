import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'react-leaflet';
import { Button, Tag } from 'rsuite';
import { DrawerInformationInterface } from '../..';
import {
    LPSensorUsHeightValues,
    LPSensorUsSensValues,
} from '../../../../handlers/ipCanDevices/Sensor/LPSensorUsConfig';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { LPSensorMapInformation_I } from '../../../../handlers/map/MapLPSensor';
import { SensorPlaceType } from '../../../../handlers/sensorPlaceType/SensorPlaceType';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import { DeviceStateInformationLogo } from '../../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformationLogo';
import ForceLPSensorModal from '../../../IpCanElementsPage/LPSensors/ForceLPSensorModal';
import LPSensorHistoryModal from '../../../IpCanElementsPage/LPSensors/LPSensorHistoryModal';
import LPSensorPlaceDrawer from './Drawer/LPSensorPlaceDrawer';

type Props = {
    sensor: LPSensorMapInformation_I;
    editMode: boolean;
    service: any;
    overPopup: boolean;
    controller?: IpCan;
    controllers: IpCan[];
    placeTypes: SensorPlaceType[];
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
    closePopup: Function;
};

type State = {
    editModalOpen: boolean;
    forceSensorModalOpen: boolean;
    editFilterModalOpen: boolean;
    time: string;
    historicModalOpen: boolean;
};

export default class LPSensorPopup extends React.Component<Props, State> {
    timer: ReturnType<typeof setTimeout> | undefined;
    popupRef: React.LegacyRef<any>;

    constructor(props: Props) {
        super(props);

        this.state = {
            editModalOpen: false,
            forceSensorModalOpen: false,
            editFilterModalOpen: false,
            time: '0 secondes',
            historicModalOpen: false,
        };

        this.popupRef = React.createRef();
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.sensor.detectionStateTime !== this.props.sensor.detectionStateTime) {
            this.generateTime();
        }
    }

    openForceModal = () => {
        this.setState({
            forceSensorModalOpen: true,
        });
    };

    closeForceModal = () => {
        this.setState({
            forceSensorModalOpen: false,
        });
    };

    openEditFilterModal = () => {
        this.setState({
            editFilterModalOpen: true,
        });
    };

    closeEditFilterModal = () => {
        this.setState({
            editFilterModalOpen: false,
        });
    };

    generateTime = () => {
        let currentTime = moment(new Date());
        let timeToCompare = moment(this.props.sensor.detectionStateTime, 'DD-MM-YYYY HH:mm:ss');
        //---
        const calculated = (currentTime.valueOf() - timeToCompare.valueOf()) / 1000;
        const duration = moment.duration(calculated, 'seconds');

        let finalString = '';
        let days = duration.days();
        let hours = duration.hours();
        let minutes = duration.minutes();
        let seconds = duration.seconds();

        if (days > 0) {
            finalString += days < 10 ? '0' + days : days;
            finalString += ' jour(s), ';
        }

        if (hours > 0) {
            finalString += hours < 10 ? '0' + hours : hours;
            finalString += ' heure(s), ';
        }

        if (minutes > 0) {
            finalString += minutes < 10 ? '0' + minutes : minutes;
            finalString += ' minute(s) et ';
        }

        finalString += seconds < 10 ? '0' + seconds : seconds;
        finalString += ' seconde(s)';

        return finalString;
    };

    startTimer = () => {
        this.setState({
            time: this.generateTime(),
        });

        this.timer = setInterval(() => {
            this.setState({
                time: this.generateTime(),
            });
        }, 1000);

    };

    stopTimer = () => {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.props.closePopup();
    };

    openEditPlaceModal = () => {
        this.setState({
            editModalOpen: true,
        });
    };

    closeEditPlaceModal = () => {
        this.setState({
            editModalOpen: false,
        });
    };

    openHistoricModal = () => {
        this.setState({
            historicModalOpen: true,
        });
    };

    closeHistoricModal = () => {
        this.setState({
            historicModalOpen: false,
        });
    };

    generateForceType = () => {
        if (this.props.sensor.forceType.forceType === 1) {
            return (
                <Tag color="green">
                    <FormattedMessage id="ipCanDevices.LPSensors.sensorForce.free" />
                </Tag>
            );
        }
        if (this.props.sensor.forceType.forceType === 2) {
            return (
                <Tag color="red">
                    <FormattedMessage id="ipCanDevices.LPSensors.sensorForce.occupied" />
                </Tag>
            );
        }
        if (this.props.sensor.forceType.forceType === 3) {
            return (
                <Tag color="orange">
                    <FormattedMessage id="ipCanDevices.LPSensors.sensorForce.overstay_occupied" />
                </Tag>
            );
        }

        if (this.props.sensor.forceType.forceType === 4) {
            return (
                <Tag color="violet">
                    <FormattedMessage id="ipCanDevices.LPSensors.sensorForce.reserved" />
                </Tag>
            );
        }

        if (this.props.sensor.forceType.forceType === 10) {
            return (
                <Tag
                    style={{
                        backgroundColor: `rgb(${this.props.sensor.forceType.color.red}, ${this.props.sensor.forceType.color.green}, ${this.props.sensor.forceType.color.blue})`,
                        color: '#FFF',
                    }}>
                    <FormattedMessage id="ipCanDevices.LPSensors.sensorForce.color" />
                </Tag>
            );
        }

        return null;
    };

    generateDetectionStateStatus = () => {
        switch (this.props.sensor.detectionState - 1) {
            case 0:
                return (
                    <Tag color="green">
                        <FormattedMessage id="ipCanDevices.LPSensors.sensorDetectionState.free" />
                    </Tag>
                );
            case 1:
                return (
                    <Tag color="red">
                        <FormattedMessage id="ipCanDevices.LPSensors.sensorDetectionState.occupied" />
                    </Tag>
                );
            case 2:
                return (
                    <Tag color="orange">
                        <FormattedMessage id="ipCanDevices.LPSensors.sensorDetectionState.overstay_occupied" />
                    </Tag>
                );
            case 3:
                return (
                    <Tag color="violet">
                        <FormattedMessage id="ipCanDevices.LPSensors.sensorDetectionState.reserved" />
                    </Tag>
                );
            default:
                return null;
        }
    };

    render() {
        const shouldShowDrawer =
            this.props.editMode &&
            this.props.drawerInformation.isOpen &&
            this.props.drawerInformation.type === 'lp-sensor' &&
            this.props.drawerInformation.id === this.props.sensor.id;

        return (
            <Fragment>
                {shouldShowDrawer && (
                    <LPSensorPlaceDrawer
                        show={this.props.drawerInformation.isOpen}
                        onHide={() => this.props.closeDrawer()}
                        sensor={this.props.sensor}
                        controllers={this.props.controllers}
                        placeTypes={this.props.placeTypes}
                        service={this.props.service}
                    />
                )}
                <Popup
                    key={`LP-Sensor-${this.props.sensor.id}-${this.props.overPopup}`}
                    autoPan={!this.props.overPopup}
                    onOpen={this.startTimer}
                    onClose={this.stopTimer}
                    ref={this.popupRef}>
                    <h5>
                        <SecuredFragment authorizedRoles={[]}>
                            <FontAwesomeIcon icon={faPlug} color={this.props.sensor.online ? 'green' : 'red'} />{' '}
                        </SecuredFragment>
                        <span className="margin-right-5">{this.props.sensor.label}</span>
                        <SecuredFragment authorizedRoles={[]}>
                            <DeviceStateInformationLogo
                                maintenanceState={this.props.sensor.lpSensor.getMaintenanceState()}
                            />
                        </SecuredFragment>
                    </h5>
                    <p>
                        {this.props.controller?.label} -- {this.props.sensor.bus + 1} -- {this.props.sensor.deviceId}
                    </p>
                    <p>
                        <FormattedMessage id="map.lpSensor.popup.placeStatus" />
                        {this.generateDetectionStateStatus()}
                    </p>
                    <SecuredFragment authorizedRoles={[]}>
                        <p>
                            <FormattedMessage id="map.lpSensor.lastOnlineStateDate" /> :{' '}
                            {this.props.sensor.lastOnlineStateDate}
                        </p>
                    </SecuredFragment>
                    <hr />

                    {this.props.sensor.isForce && (
                        <p>
                            <FormattedMessage id="map.lpSensor.force" /> : {this.generateForceType()}
                            <br />
                            <FormattedMessage id="map.displays.popup.startForceDate" />
                            <span className="margin-left-5">
                                {moment(this.props.sensor.lpSensor.startForceTime).format('DD/MM/YYYY HH:mm:ss')}
                            </span>
                            <br />
                            <FormattedMessage id="map.displays.popup.endForceDate" />
                            <span className="margin-left-5">
                                {moment(this.props.sensor.lpSensor.endForceTime).format('DD/MM/YYYY HH:mm:ss')}
                            </span>
                        </p>
                    )}

                    {this.props.sensor.detectionState === 2 && (
                        <Fragment>
                            <p>
                                <FormattedMessage id="map.lpSensor.detectionDate" /> :{' '}
                                {this.props.sensor.detectionStateTime}
                            </p>
                            <p>
                                <FormattedMessage id="map.lpSensor.occupiedSince" /> {this.state.time}
                            </p>
                        </Fragment>
                    )}

                    <SecuredFragment authorizedRoles={[]}>
                        <p>
                            <b>
                                <FormattedMessage id="map.lpSensor.filter.c1" />
                            </b>{' '}
                            : {LPSensorUsHeightValues(this.props.sensor.us1Config.height)}
                            <br />
                            <b>
                                <FormattedMessage id="map.lpSensor.filter.c2" />
                            </b>{' '}
                            : {LPSensorUsHeightValues(this.props.sensor.us2Config.height)}
                            <br />
                            <b>
                                <FormattedMessage id="map.lpSensor.filter.sDetection" />
                            </b>{' '}
                            : {LPSensorUsSensValues(this.props.sensor.us1Config.sens)}
                            <br />
                            <b>
                                <FormattedMessage id="map.lpSensor.filter.sRelease" />
                            </b>{' '}
                            : {LPSensorUsSensValues(this.props.sensor.us2Config.sens)}
                        </p>
                    </SecuredFragment>

                    {!this.props.overPopup && (
                        <Fragment>
                            <SecuredFragment authorizedRoles={[]}>
                                <Button size="sm" block color="green" onClick={this.openHistoricModal}>
                                    <FormattedMessage id="map.lpSensor.openHistoricSensor" />
                                </Button>

                                <LPSensorHistoryModal
                                    show={this.state.historicModalOpen}
                                    onHide={this.closeHistoricModal}
                                    sensor={this.props.sensor.lpSensor}
                                />
                            </SecuredFragment>

                            <SecuredFragment authorizedRoles={[rolesConstants.LPSensor.FORCE_SENSOR]}>
                                <Button size="sm" block color="blue" onClick={this.openForceModal}>
                                    <FormattedMessage id="map.lpSensor.forceSensor" />
                                </Button>

                                <ForceLPSensorModal
                                    show={this.state.forceSensorModalOpen}
                                    onHide={this.closeForceModal}
                                    sensors={[this.props.sensor.lpSensor]}
                                />
                            </SecuredFragment>

                            {this.props.editMode && (
                                <SecuredFragment authorizedRoles={[]}>
                                    <Button
                                        block
                                        size="sm"
                                        color="orange"
                                        onClick={() => this.props.openDrawer('lp-sensor', this.props.sensor.id)}>
                                        <FormattedMessage id="map.lpSensor.edit" />
                                    </Button>
                                </SecuredFragment>
                            )}
                        </Fragment>
                    )}
                </Popup>
            </Fragment>
        );
    }
}
