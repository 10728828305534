import { faCircle, faExclamationTriangle, faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Col, FlexboxGrid, List, Loader, Panel, Tooltip, Whisper } from 'rsuite';
import TCMSensor from '../../../../handlers/ipCanDevices/TCMSensor';
import { sensorPlaceTypeHandler } from '../../../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import noImage from '../../../../style/assets/eye-slash-solid.svg';
import PanelHeader from '../../../Custom/PanelHeader';

type Props = {
    loading?: boolean;
    sensor: TCMSensor;
    isCheckingDatabase: boolean;
    databaseData: Record<string, any> | undefined;
} & WrappedComponentProps;

type State = {
    sensorPlaceType: any | null;
    isLoading: boolean;
    hasError: boolean;
    error: string;
};

class InfosCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sensorPlaceType: null,
            isLoading: true,
            hasError: false,
            error: '',
        };
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get(`/devices/tcm-sensor/${this.props.sensor.id}`, { headers: authHeader() })
            .then(response => {
                const sensorPlaceTypeId = response.data.sensorPlaceType.id;

                axiosService
                    .getAxios()
                    .get(`/sensor-place-type/${sensorPlaceTypeId}`, { headers: authHeader() })
                    .then(placeTypeResponse => {
                        const sensorPlaceType = sensorPlaceTypeHandler(placeTypeResponse.data);
                        this.setState({
                            sensorPlaceType,
                            isLoading: false,
                        });
                    })
                    .catch(err => {
                        this.setState({
                            hasError: true,
                            error: err,
                            isLoading: false,
                        });
                    });
            });
    }

    render() {
        let softwareVersionClass;
        let detectionStateClass;

        if (this.props.isCheckingDatabase) {
            softwareVersionClass =
                this.props.sensor.versionSoft === this.props.databaseData?.versionSoft ? 'list-green' : 'list-red';
            detectionStateClass =
                this.props.sensor.detectionState === this.props.databaseData?.detectionState
                    ? 'list-green'
                    : 'list-red';
        }

        return (
            <Panel
                className={'panel-small'}
                shaded
                data-cy="ipcan-elements-sensor-drawer-infos"
                bordered
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({ id: 'ipCanDevices.sensor.informations' })}
                        buttons={[]}
                    />
                }
                bodyFill>
                <List hover>
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item data-cy="IPCan-information-label-status" style={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="ipCanDevices.sensor.informations.online" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="IPCan-information-label-status-value">
                                <FontAwesomeIcon
                                    icon={faPlug}
                                    style={{
                                        color: this.props.sensor.online ? 'green' : 'red',
                                    }}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item data-cy="IPCan-information-label-deviceId" style={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="ipCanDevices.sensor.informations.deviceId" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="IPCan-information-label-deviceId-value">
                                {this.props.sensor.deviceId}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    <List.Item className={`panel-list ${softwareVersionClass}`}>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item
                                data-cy="ipCanDevices-sensor-informations-softwareVersion"
                                style={{ fontWeight: 'bold' }}
                                componentClass={Col}
                                xs={6}>
                                <FormattedMessage id="ipCanDevices.sensor.informations.softwareVersion" />
                            </FlexboxGrid.Item>

                            {/* DATABASE CHECK */}
                            {this.props.isCheckingDatabase && (
                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    {this.props.databaseData?.versionSoft}
                                </FlexboxGrid.Item>
                            )}

                            <FlexboxGrid.Item data-cy="ipCanDevices-sensor-informations-softwareVersion-value">
                                {this.props.sensor.versionSoft}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    {/* SENSOR PLACE TYPE */}
                    <List.Item className={'panel-list'}>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item
                                data-cy="ipCanDevices-sensor-informations-sensorPlaceType"
                                style={{ fontWeight: 'bold' }}
                                componentClass={Col}
                                xs={6}>
                                <FormattedMessage id="ipCanDevices.sensor.informations.sensorPlaceType" />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item data-cy="ipCanDevices-sensor-informations-sensorPlaceType-value">
                                {this.state.isLoading && <Loader />}

                                {this.state.hasError && (
                                    <Whisper
                                        placement="auto"
                                        trigger="hover"
                                        speaker={
                                            <Tooltip>
                                                <FormattedMessage
                                                    id="ipCanDevices.LPSensor.informations.fetchPlaceTypeError"
                                                    values={{ error: this.state.error }}
                                                />
                                            </Tooltip>
                                        }>
                                        <FontAwesomeIcon icon={faExclamationTriangle} color="orange" />
                                    </Whisper>
                                )}

                                {!this.state.isLoading && !this.state.hasError && this.state.sensorPlaceType && (
                                    <div>
                                        <span className="margin-right-5">{this.state.sensorPlaceType.label}</span>
                                        {this.state.sensorPlaceType.placeType.image ? (
                                            <img
                                                data-cy="placeTypeLogo"
                                                alt={`${this.state.sensorPlaceType.label}-logo`}
                                                src={`data:image/${this.state.sensorPlaceType.placeType.imageType};base64, ${this.state.sensorPlaceType.placeType.image}`}
                                                width="30"
                                            />
                                        ) : (
                                            <img
                                                src={noImage}
                                                style={{
                                                    width: 30,
                                                    backgroundColor: 'white',
                                                    lineHeight: 1,
                                                }}
                                                alt="empty"
                                            />
                                        )}
                                    </div>
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    <List.Item className={`panel-list ${detectionStateClass}`}>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item
                                data-cy="ipCanDevices-sensor-informations-detectionState"
                                style={{ fontWeight: 'bold' }}
                                componentClass={Col}
                                xs={6}>
                                <FormattedMessage id="ipCanDevices.sensor.informations.detectionState" />
                            </FlexboxGrid.Item>

                            {/* DATABASE CHECK */}
                            {this.props.isCheckingDatabase && (
                                <FlexboxGrid.Item componentClass={Col} xs={8}>
                                    {this.props.databaseData?.detectionState === 1 && (
                                        <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }} />
                                    )}
                                    {this.props.databaseData?.detectionState === 2 && (
                                        <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} />
                                    )}
                                    {this.props.databaseData?.detectionState === 3 && (
                                        <FontAwesomeIcon icon={faCircle} style={{ color: 'orange' }} />
                                    )}
                                    {this.props.databaseData?.detectionState === 4 && (
                                        <FontAwesomeIcon icon={faCircle} style={{ color: 'purple' }} />
                                    )}
                                    {this.props.databaseData?.detectionState === 0 ||
                                        (this.props.databaseData?.detectionState >= 5 && (
                                            <FontAwesomeIcon icon={faCircle} style={{ color: 'black' }} />
                                        ))}
                                </FlexboxGrid.Item>
                            )}

                            <FlexboxGrid.Item data-cy="ipCanDevices-sensor-informations-detectionState-value">
                                {this.props.sensor.detectionState === 1 && (
                                    <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }} />
                                )}
                                {this.props.sensor.detectionState === 2 && (
                                    <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} />
                                )}
                                {this.props.sensor.detectionState === 3 && (
                                    <FontAwesomeIcon icon={faCircle} style={{ color: 'orange' }} />
                                )}
                                {this.props.sensor.detectionState === 4 && (
                                    <FontAwesomeIcon icon={faCircle} style={{ color: 'purple' }} />
                                )}
                                {this.props.sensor.detectionState === 0 ||
                                    (this.props.sensor.detectionState >= 5 && (
                                        <FontAwesomeIcon icon={faCircle} style={{ color: 'black' }} />
                                    ))}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>

                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item
                                style={{ fontWeight: 'bold' }}
                                data-cy="ipCanDevices-sensor-informations-detection-state-time">
                                <FormattedMessage id="ipCanDevices.sensor.informations.detectionStateTime" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>{this.props.sensor.detectionStateTimeFormatted}</FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item
                                data-cy="ipCanDevices-sensor-informations-creation"
                                style={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="ipCanDevices.sensor.informations.creation" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="ipCanDevices-sensor-informations-creation-value">
                                {this.props.sensor.creation}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    <List.Item className="panel-list">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item
                                data-cy="ipCanDevices-sensor-informations-update"
                                style={{ fontWeight: 'bold' }}>
                                <FormattedMessage id="ipCanDevices.sensor.informations.update" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item data-cy="ipCanDevices-sensor-informations-update-value">
                                {this.props.sensor.updateInformations}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                </List>
            </Panel>
        );
    }
}

export default injectIntl(InfosCard);
