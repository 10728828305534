import { faCheck, faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { RowRecord } from 'react-data-table-component';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid } from 'rsuite';
import { secondsToHours } from '../../../../handlers/helper';
import {
    LPSensorUsHeightValues,
    LPSensorUsSensValues
} from '../../../../handlers/ipCanDevices/Sensor/LPSensorUsConfig';

type Props = RowRecord;

export default class LPSensorReadConfig extends React.Component<Props> {
    render = () => (
        <Fragment>
            <FlexboxGrid style={{ margin: 15 }} justify="space-around">
                {/* Config */}
                <FlexboxGrid.Item>
                    <h5>
                        <FormattedMessage id="lpSensor.history.filterConfig" />
                    </h5>

                    <div data-cy="history-lpSensor-nbSalveMin">
                        <FormattedMessage id="lpSensor.history.nbSalveMin" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filterConfig.nbSalveMin}</span>
                    </div>
                    <div data-cy="history-lpSensor-nbSalveMax">
                        <FormattedMessage id="lpSensor.history.nbSalveMax" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filterConfig.nbSalveMax}</span>
                    </div>
                    <div data-cy="history-lpSensor-nbInterSalveMin">
                        <FormattedMessage id="lpSensor.history.nbInterSalveMin" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filterConfig.nbInterSalveMin}</span>
                    </div>
                    <div data-cy="history-lpSensor-nbInterSalveMax">
                        <FormattedMessage id="lpSensor.history.nbInterSalveMax" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filterConfig.nbInterSalveMax}</span>
                    </div>
                    <div data-cy="history-lpSensor-maskFreeToOccupied">
                        <FormattedMessage id="lpSensor.history.maskFreeToOccupied" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filterConfig.maskFree}</span>
                    </div>
                    <div data-cy="history-lpSensor-maskOccupiedToFree">
                        <FormattedMessage id="lpSensor.history.maskOccupiedToFree" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.filterConfig.maskOccupied}</span>
                    </div>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item>
                    <h5>
                        <FormattedMessage id="lpSensor.history.sensorConfig" />
                    </h5>
                    <div data-cy="history-lpSensor-c1">
                        <FormattedMessage id="lpSensor.history.c1" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            {LPSensorUsHeightValues(this.props.data.option.us1.height)}
                        </span>
                    </div>
                    <div data-cy="history-lpSensor-c2">
                        <FormattedMessage id="lpSensor.history.c2" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            {LPSensorUsHeightValues(this.props.data.option.us2.height)}
                        </span>
                    </div>
                    <div data-cy="history-lpSensor-sDetection">
                        <FormattedMessage id="lpSensor.history.sDetection" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{LPSensorUsSensValues(this.props.data.option.us1.sens)}</span>
                    </div>
                    <div data-cy="history-lpSensor-sRelease">
                        <FormattedMessage id="lpSensor.history.sRelease" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{LPSensorUsSensValues(this.props.data.option.us2.sens)}</span>
                    </div>
                    <div data-cy="history-lpSensor-overstay">
                        <FormattedMessage id="lpSensor.history.overstay" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            {secondsToHours(this.props.data.option.overstay).stringified}
                        </span>
                    </div>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item>
                    <h5>
                        <FormattedMessage id="lpSensor.history.infoConfig" />
                    </h5>
                    <div data-cy="history-lpSensor-isEnable">
                        <FormattedMessage id="lpSensor.history.isEnable" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            <FontAwesomeIcon
                                icon={this.props.data.option.infoConfig.isEnable ? faCheck : faTimes}
                                color={this.props.data.option.infoConfig.isEnable ? 'green' : 'red'}
                            />
                        </span>
                    </div>
                    <div data-cy="history-lpSensor-modelName">
                        <FormattedMessage id="lpSensor.history.modelName" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.infoConfig.modelName}</span>
                    </div>
                    <div data-cy="history-lpSensor-softwareVersion">
                        <FormattedMessage id="lpSensor.history.softwareVersion" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.infoConfig.softwareVersion}</span>
                    </div>
                    <div data-cy="history-lpSensor-uniqueId">
                        <FormattedMessage id="lpSensor.history.uniqueId" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.infoConfig.uniqueId.join('.')}</span>
                    </div>
                    <div data-cy="history-lpSensor-intState">
                        <FormattedMessage id="lpSensor.history.intState" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">
                            <FontAwesomeIcon
                                data-tag="allowthis.props.dataEvents"
                                icon={faCircle}
                                color={this.props.data.option.infoConfig.int_state === 1 ? 'green' : 'red'}
                            />
                        </span>
                    </div>
                    <div data-cy="history-lpSensor-voltage">
                        <FormattedMessage id="lpSensor.history.voltage" />
                        <span className="margin-left-5">-</span>
                        <span className="margin-left-5">{this.props.data.option.infoConfig.voltage}</span>
                    </div>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Fragment>
    );
}
