import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    InputNumber,
    Modal,
    SelectPicker,
} from 'rsuite';
import { MapVehicleCounter } from '../../../../handlers/map/MapVehicleCounter';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    show: boolean;
    passCount: MapVehicleCounter;
    onHide: Function;
} & WrappedComponentProps;

type State = {
    formValue: {
        type: string;
        value: number;
        valueIn: number;
        valueOut: number;
    };
    isForcing: boolean;
    hasError: boolean;
    error: any;
};

class ForcePassCountModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            formValue: {
                type: 'forceReset',
                value: 0,
                valueIn: 0,
                valueOut: 0,
            },
            isForcing: false,
            hasError: false,
            error: null,
        };
    }

    handleFormChange = values => {
        this.setState({
            formValue: {
                type: values.type,
                value: values.value ? parseInt(values.value) : 0,
                valueIn: values.valueIn ? parseInt(values.valueIn) : 0,
                valueOut: values.valueOut ? parseInt(values.valueOut) : 0,
            },
        });
    };

    forcePassCount = () => {
        this.setState({
            isForcing: true,
            hasError: false,
            error: null,
        });

        const { type, value, valueIn, valueOut } = this.state.formValue;

        const data = {
            id: this.props.passCount.getVehicleCounter().getId(),
            forceType: type,
            value: type === 'forceValue' ? value : 0,
            valueIn: type === 'forceInOut' ? valueIn : 0,
            valueOut: type === 'forceInOut' ? valueOut : 0,
        };

        axiosService
            .getAxios()
            .put('/devices/vehicle-counters/forceVehicleCounter', data, { headers: authHeader() })
            .then(() => {
                Alert.success(this.props.intl.formatMessage({ id: 'map.passCount.forcePassCount.success' }));
            })
            .catch(err => {
                this.setState({ hasError: true, error: err });
            })
            .finally(() => {
                this.setState({
                    isForcing: false,
                });
                this.closeForceModal();
            });
    };

    closeForceModal = () => {
        this.setState({
            formValue: {
                type: 'forceReset',
                value: 0,
                valueIn: 0,
                valueOut: 0,
            },
            hasError: false,
            error: null,
        });

        this.props.onHide();
    };

    render() {
        const typeData: {
            label: string;
            value: string;
        }[] = [
            {
                label: this.props.intl.formatMessage({ id: 'map.passCount.forcePassCount.type.forceReset' }),
                value: 'forceReset',
            },
            {
                label: this.props.intl.formatMessage({ id: 'map.passCount.forcePassCount.type.forceZero' }),
                value: 'forceZero',
            },
            {
                label: this.props.intl.formatMessage({ id: 'map.passCount.forcePassCount.type.forceValue' }),
                value: 'forceValue',
            },
            {
                label: this.props.intl.formatMessage({ id: 'map.passCount.forcePassCount.type.forceInOut' }),
                value: 'forceInOut',
            },
        ];

        return (
            <Modal show={this.props.show} onHide={() => this.props.onHide()}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="map.passCount.forcePassCount.title" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid onChange={this.handleFormChange} formValue={this.state.formValue}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.passCount.forcePassCount.type" />
                            </ControlLabel>
                            <FormControl
                                data-cy="map-passCount-force-type"
                                name="type"
                                accepter={SelectPicker}
                                data={typeData}
                                searchable={false}
                                cleanable={false}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`passCount-force-value-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FormGroup>

                        {this.state.formValue.type === 'forceValue' && (
                            <FormGroup>
                                <ControlLabel>
                                    <FormattedMessage id="map.passCount.forcePassCount.value" />
                                </ControlLabel>
                                <FormControl
                                    data-cy="map-passCount-force-type-value"
                                    name="value"
                                    accepter={InputNumber}
                                    min={0}
                                />
                            </FormGroup>
                        )}

                        {this.state.formValue.type === 'forceInOut' && (
                            <Fragment>
                                <FormGroup>
                                    <ControlLabel>
                                        <FormattedMessage id="map.passCount.forcePassCount.valueIn" />
                                    </ControlLabel>
                                    <FormControl
                                        data-cy="map-passCount-force-type-valueIn"
                                        name="valueIn"
                                        accepter={InputNumber}
                                        min={0}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <ControlLabel>
                                        <FormattedMessage id="map.passCount.forcePassCount.valueOut" />
                                    </ControlLabel>
                                    <FormControl
                                        data-cy="map-passCount-force-type-valueOut"
                                        name="valueOut"
                                        accepter={InputNumber}
                                        min={0}
                                    />
                                </FormGroup>
                            </Fragment>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            data-cy="map-passCount-force-type-cancel"
                            onClick={() => this.closeForceModal()}
                            color="red"
                            disabled={this.state.isForcing}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            data-cy="map-passCount-force-type-valid"
                            onClick={this.forcePassCount}
                            appearance="primary"
                            loading={this.state.isForcing}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(ForcePassCountModal);
