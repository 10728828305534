import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Modal } from 'rsuite';
import { parkingActions } from '../../../redux/actions';

const mapDispatchToProps = dispatch => ({
    deleteIpCan: id => {
        dispatch(parkingActions.remove(id));
    },
    closeDeleteModal: () => dispatch(parkingActions.closeDeleteModal()),
});

type Props = {
    closeDeleteModal: Function;
    deleteIpCan: Function;
    parkingDrawer: Record<string, any>;
    delete_modal_openned: boolean;
} & WrappedComponentProps;

type State = {
    isSubmitted: boolean;
};

class DeleteParkingModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isSubmitted: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            isSubmitted: false,
        });

        this.props.closeDeleteModal();
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            isSubmitted: true,
        });

        this.props.deleteIpCan(this.props.parkingDrawer.id);
        this.closeModal();
    }

    render() {
        if (this.props.parkingDrawer) {
            return (
                <Modal size="xs" show={this.props.delete_modal_openned} onHide={this.closeModal}>
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage id="parking.delete.title" /> - {this.props.parkingDrawer.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body data-cy="parking-delete-modal">
                        <FormattedMessage id="parking.delete.message" />
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button
                                onClick={this.closeModal}
                                data-cy="parking-delete-cancel"
                                color="red"
                                loading={this.state.isSubmitted}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                onClick={this.handleSubmit}
                                data-cy="parking-delete-valid"
                                appearance="primary"
                                loading={this.state.isSubmitted}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    let { delete_modal_openned, parkingDrawer } = state.parkings;

    return { delete_modal_openned, parkingDrawer };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeleteParkingModal));
