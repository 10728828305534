import { IntlShape } from 'react-intl';

export enum LPMatrixDisplayForceType {
    NONE = 0,
    FORCE_BLACK = 1,
    FORCE = 2,
}

type FormValue = {
    label: string;
    value: number;
};

export const LPMatrixDisplayForceForm = (intl: IntlShape): FormValue[] => [
    {
        label: intl.formatMessage({ id: 'lpmatrixdisplay.force.none' }),
        value: LPMatrixDisplayForceType.NONE,
    },
    {
        label: intl.formatMessage({ id: 'lpmatrixdisplay.force.force_black' }),
        value: LPMatrixDisplayForceType.FORCE_BLACK,
    },
    {
        label: intl.formatMessage({ id: 'lpmatrixdisplay.force.force' }),
        value: LPMatrixDisplayForceType.FORCE,
    },
];
