import React from 'react';
import { InputNumber, Table, TableCellProps } from 'rsuite';

type Props = {
    editMode: boolean;
    dataKey: string;
} & TableCellProps;

type State = {
    value: string | number | undefined;
};
export default class EditableNumberCell extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            value: props.rowData[props.dataKey],
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({
            value: e,
        });
        this.props.onChange(this.props.rowData?.['name'], this.props.dataKey, e);
    }

    render() {
        let { editMode, ...props } = this.props;
        return (
            <Table.Cell {...props}>
                {editMode ? (
                    <InputNumber
                        size="sm"
                        className="no-padding-top"
                        value={this.state.value}
                        onChange={this.onChange}
                        name={props.rowData?.['name'] + '_' + props.dataKey}
                    />
                ) : (
                    <p>{this.state.value} ms</p>
                )}
            </Table.Cell>
        );
    }
}
