import { DeviceBase } from '../ipCanDevices/DeviceBase';
import LPDisplayBaseConfig, { LPDisplayBaseConfigInterface } from './LPDisplayBaseConfig';
import LPDisplayCounters, { LPDisplayCountersInterface } from './LPDisplayCounters';
import { LPDisplayEventForceType, LPDisplayEventForceTypeData } from './LPDisplayEventForceType';
import {
    LPDisplayForceType,
    LPDisplayForceTypeData,
    LPDisplayForceTypeVirtualDisplaysInterface,
} from './LPDisplayForceType';
import LPDisplayInfoConfig, { LPDisplayInfoConfigInterface } from './LPDisplayInfoConfigInterface';
import { LPDisplayTextConfig, LPDisplayTextConfigInterface } from './LPDisplayTextConfig';
import { LPDisplayTopology, LPDisplayTopologyInterface } from './LPDisplayTopology';
import { VirtualDisplay, VirtualDisplayInterface } from './VirtualDisplay';
import { VirtualDisplayStep } from './VirtualDisplayStep';

export enum Origin {
    'TOP_RIGHT' = 0,
    'BOTTOM_RIGHT' = 1,
    'BOTTOM_LEFT' = 2,
    'TOP_LEFT' = 3,
}

export class LPDisplay extends DeviceBase {
    private _baseConfig: LPDisplayBaseConfig;
    private _countersConfig: LPDisplayCounters[];
    private _infoConfig: LPDisplayInfoConfig;
    private _virtualDisplays: VirtualDisplay[];
    private _textConfig: LPDisplayTextConfig;
    private _topologyConfig: LPDisplayTopology;
    private _updateElements: Function;
    private _countersValue?: number[];
    private _forceType: LPDisplayForceType;
    private _eventForceType: LPDisplayEventForceType;

    constructor(data_: LPDisplayData, ipCanId_: number, updateElements_) {
        const {
            baseConfig,
            bus,
            countersConfig,
            createdAt,
            createdBy,
            deviceId,
            endForceTime,
            id,
            infoConfig,
            isForce,
            lastOnlineStateDate,
            online,
            updatedAt,
            updatedBy,
            virtualDisplays,
            textConfig,
            topologysConfig,
            startForceTime,
            maintenanceState,
            notepad,
            forceType,
            eventForceType,
        } = data_;

        super(
            id,
            bus,
            deviceId,
            online,
            createdAt,
            updatedAt,
            createdBy,
            updatedBy,
            infoConfig.softwareVersion,
            startForceTime,
            endForceTime,
            isForce,
            lastOnlineStateDate,
            maintenanceState,
            notepad,
            ipCanId_
        );

        this._baseConfig = new LPDisplayBaseConfig(baseConfig);
        this._countersConfig = countersConfig.counters.map(counterConfig => new LPDisplayCounters(counterConfig));
        this._infoConfig = new LPDisplayInfoConfig(infoConfig);

        if (virtualDisplays) {
            this._virtualDisplays = virtualDisplays.map((virtualDisplay, index) => {
                let forcedVirtualDisplay: LPDisplayForceTypeVirtualDisplaysInterface | undefined;

                if (isForce && forceType.virtualDisplays.length > 0) {
                    forcedVirtualDisplay = forceType.virtualDisplays.find(vd => vd.numVirtualDisplay === index);

                    if (forcedVirtualDisplay) virtualDisplay.steps.push({ ...forcedVirtualDisplay.step });
                }

                return new VirtualDisplay({
                    ...virtualDisplay,
                    isForce: typeof forcedVirtualDisplay !== 'undefined',
                    forceMode: forcedVirtualDisplay?.mode,
                });
            });
        } else {
            this._virtualDisplays = [];
        }

        this._textConfig = new LPDisplayTextConfig(textConfig);
        this._topologyConfig = new LPDisplayTopology(topologysConfig);
        this._updateElements = updateElements_;
        this._forceType = new LPDisplayForceType(forceType);
        this._eventForceType = new LPDisplayEventForceType(eventForceType);

        if (isForce && forceType.virtualDisplays.length > 0) {
            forceType.virtualDisplays.forEach(vd => {
                if (this._virtualDisplays[vd.numVirtualDisplay]) {
                    const currentStep = new VirtualDisplayStep({ ...vd.step, isForcedStep: true, forceMode: vd.mode });
                    this._virtualDisplays[vd.numVirtualDisplay].addForcedVirtualDisplayStep(currentStep, vd.mode);
                }
            });
        }
    }

    public getBaseConfig(): LPDisplayBaseConfig {
        return this._baseConfig;
    }

    public getCountersConfig(): LPDisplayCounters[] {
        return this._countersConfig;
    }

    public getInfoConfig(): LPDisplayInfoConfig {
        return this._infoConfig;
    }

    public getVirtualDisplays() {
        return this._virtualDisplays;
    }

    public getIpCanId(): number {
        return this._ipCanId;
    }

    public setUpdatedAt(updatedAt_: Date): boolean {
        if (this._updatedAt !== updatedAt_) {
            this._updatedAt = updatedAt_;
            return true;
        }

        return false;
    }

    public setUpdatedBy(updatedBy_: string): boolean {
        if (this._updatedBy !== updatedBy_) {
            this._updatedBy = updatedBy_;
            return true;
        }

        return false;
    }

    public setEventForceType(forceType_: LPDisplayEventForceTypeData): void {
        this._eventForceType = new LPDisplayEventForceType(forceType_);
    }

    public setWebsocketInformation(data_: LPDisplayWebsocketData) {
        let nbElementsUpdated = 0;
        // TODO: Implement with real data
        if (this.setOnline(data_.online)) nbElementsUpdated++;
        if (this.setEndForceTime(data_.endForceTime)) nbElementsUpdated++;
        if (this.setLastOnlineStateDate(data_.lastOnlineStateDate)) nbElementsUpdated++;
        if (this.setIsForce(data_.isForce)) nbElementsUpdated++;
        if (this.setUpdatedAt(data_.updatedAt)) nbElementsUpdated++;
        if (this.setUpdatedBy(data_.updatedBy)) nbElementsUpdated++;

        this.setEventForceType(data_.eventForceType);

        if (this._baseConfig.webSocketUpdateBaseConfig(data_.baseConfig)) nbElementsUpdated++;

        this._countersConfig.forEach((counterConfig, index) => {
            if (counterConfig.websocketUpdateCounterConfig(data_.countersConfig.counters[index])) nbElementsUpdated++;
        });

        if (this._topologyConfig.websocketUpdateLPDisplayTopology(data_.topologysConfig)) nbElementsUpdated++;
        if (this._textConfig.websocketUpdateLPDisplayTextConfig(data_.textConfig)) nbElementsUpdated++;

        this._countersValue = data_.countersValue;

        if (nbElementsUpdated > 0 && this._updateElements) this._updateElements();
    }

    public addVirtualDisplay(data_: VirtualDisplayInterface) {
        this._virtualDisplays.push(new VirtualDisplay(data_));
    }

    public updateVirtualDisplay(data_: VirtualDisplayInterface) {
        const editedVirtualDisplay = this._virtualDisplays.find(virtualDisplay => virtualDisplay.getId() === data_.id);

        editedVirtualDisplay?.updateVirtualDisplay(data_);
    }

    public deleteVirtualDisplays(data_: VirtualDisplayInterface[]) {
        let virtualDisplays = this._virtualDisplays;

        for (let vd in data_) {
            const currentVirtualDisplayId = data_[vd].id;

            virtualDisplays = virtualDisplays.filter(vd => vd.getId() !== currentVirtualDisplayId);
        }

        this._virtualDisplays = virtualDisplays;
    }

    public updateConfig(data_: LPDisplayData) {
        if (data_) {
            this._updatedAt = data_.updatedAt;
            this._updatedBy = data_.updatedBy;
            this._baseConfig.updateBaseConfig(data_.baseConfig);
        }
    }

    public updateCountersConfig(data_: LPDisplayData) {
        if (data_) {
            this._countersConfig = data_.countersConfig.counters.map(
                counterConfig => new LPDisplayCounters(counterConfig)
            );
        }
    }

    public updateVirtualDisplayOrder(data_: VirtualDisplayInterface[]) {
        this._virtualDisplays = data_.map(virtualDisplay => new VirtualDisplay(virtualDisplay));
    }

    public getTextConfig() {
        return this._textConfig;
    }

    public getTopologyConfig() {
        return this._topologyConfig;
    }

    public getIsForce() {
        return this._isForce;
    }

    public getPreviewInformation(): DisplayPreviewInformation {
        return {
            online: this.getOnline(),
            id: this.getId(),
            bus: this.getBus(),
            deviceId: this.getDeviceId(),
            nbMatrixX: this.getBaseConfig().getNbMatrixX(),
            nbMatrixY: this.getBaseConfig().getNbMatrixY(),
            userText: this.getTextConfig().getUserText(),
            systemText: this.getTextConfig().getEntries(),
            virtualDisplays: this.getVirtualDisplays(),
            countersValue: this.getCountersValue(),
        };
    }

    public getCountersValue(): number[] | undefined {
        return this._countersValue;
    }

    public updateTopologysConfig(data_: LPDisplayData) {
        this._updatedAt = data_.updatedAt;
        this._updatedBy = data_.updatedBy;
        this._topologyConfig.updateTopologies(data_.topologysConfig);
    }

    public updateVirtualDisplaysDisplay(): boolean {
        let shouldReload = false;
        this._virtualDisplays.forEach(virtualDisplay => {
            let result = virtualDisplay.updateDisplay(this._countersValue);
            if (!shouldReload) shouldReload = result;
        });

        return shouldReload;
    }

    public getForceType(): LPDisplayForceType {
        return this._forceType;
    }

    public getEventForceType(): LPDisplayEventForceType {
        return this._eventForceType;
    }
}

interface LPDisplayWebsocketData {
    id: number;
    bus: number;
    deviceId: number;
    online: boolean;
    baseConfig: LPDisplayBaseConfigInterface;
    infoConfig: LPDisplayInfoConfigInterface;
    countersConfig: { counters: LPDisplayCountersInterface[] };
    countersValue: number[];
    topologysConfig: LPDisplayTopologyInterface;
    textConfig: LPDisplayTextConfigInterface;
    forceType: { virtualDisplays: number[] };
    isForce: boolean;
    endForceTime: Date;
    lastOnlineStateDate: Date;
    updatedAt: Date;
    updatedBy: string;
    eventForceType: LPDisplayEventForceTypeData;
}

export interface LPDisplayData {
    baseConfig: LPDisplayBaseConfigInterface;
    bus: number;
    countersConfig: { counters: LPDisplayCountersInterface[] };
    createdAt: Date;
    createdBy: string;
    deviceId: number;
    endForceTime: string;
    startForceTime: Date;
    ipcanmodule?: {
        id: number;
    };
    id: number;
    infoConfig: LPDisplayInfoConfigInterface;
    isForce: boolean;
    lastOnlineStateDate: string;
    online: number;
    updatedAt: Date;
    updatedBy: string;
    virtualDisplays: VirtualDisplayInterface[];
    textConfig: LPDisplayTextConfigInterface;
    topologysConfig: LPDisplayTopologyInterface;
    maintenanceState: number;
    notepad: string;
    forceType: LPDisplayForceTypeData;
    eventForceType: LPDisplayEventForceTypeData;
}

export interface DisplayPreviewInformation {
    online: boolean;
    id: number;
    bus: number;
    deviceId: number;
    nbMatrixX: number;
    nbMatrixY: number;
    userText: string[];
    systemText: string[][];
    virtualDisplays: VirtualDisplay[];
    countersValue?: number[];
}
