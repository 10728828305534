type Props = {
    color: string;
};

export const ThreeMIcon = ({ color }: Props) => (
    <svg width="100%" height="100%" version="1.1" viewBox="0 0 135.47 135.47" xmlns="http://www.w3.org/2000/svg">
        <g>
            <g>
                <rect fill={color} y="16.933" width="42.333" height="16.933" />
                <rect fill={color} y="59.267" width="42.333" height="16.933" />
                <rect fill={color} x="8.8818e-16" y="101.6" width="42.333" height="16.933" />
                <rect fill={color} x="33.867" y="25.4" width="16.933" height="42.333" />
            </g>
            <rect fill={color} x="33.867" y="76.32" width="16.933" height="33.867" />
            <rect fill={color} x="33.867" y="59.267" width="8.4667" height="50.92" />
            <rect fill={color} x="127" y="16.933" width="8.4667" height="101.6" />
            <rect fill={color} x="67.733" y="16.933" width="8.4667" height="101.6" />
            <rect fill={color} x="118.53" y="25.4" width="16.933" height="93.133" />
            <rect fill={color} x="67.733" y="25.4" width="16.933" height="93.133" />
            <g>
                <rect fill={color} x="110.07" y="33.867" width="16.933" height="25.4" />
                <rect fill={color} x="76.2" y="33.867" width="16.933" height="25.4" />
                <rect fill={color} x="93.133" y="42.333" width="16.933" height="25.4" />
                <rect fill={color} x="84.667" y="42.333" width="33.867" height="16.933" />
            </g>
        </g>
    </svg>
);
