import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';
import Task from '../../../../../handlers/Event/Task';

type Props = {
    task: Task;
};

export const ForceIPCanGPIO = (props: Props) => {
    return (
        <>
            <p style={{ marginBottom: 8 }}>
                <FormattedMessage id="tasks.forceIpCanGPIO" />
            </p>

            <Tag>
                <FontAwesomeIcon
                    icon={faDotCircle}
                    color={props.task.getForceOption().getGpioEnabled() ? 'green' : 'red'}
                    style={{ marginRight: 4 }}
                />
                {props.task.getIpCans()[0].label} ({props.task.getIpCans()[0].getMacStr()}) -{' '}
                <>
                    {props.task.getIpCans()[0].getGpioConfig().gpioOut[(props.task.getForceOption().getGpioNumber() || 1) - 1].name}
                </>
            </Tag>
        </>
    );
};
