import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ControlLabel, FlexboxGrid, Form, FormControl, FormGroup, SelectPicker } from 'rsuite';
import { StepTaskData, StepTypeEnum } from '..';
import Task from '../../../../../handlers/Event/Task';
import { MapLPMatrixDisplayV2 } from '../../../../../handlers/map/MapLPMatrixDisplayV2';
import { authHeader } from '../../../../../redux/helpers';
import { axiosService } from '../../../../../redux/services';

type Props = {
    onClickBack: () => void;
    currentTask: StepTaskData;
    closeModal: () => void;
    type: StepTypeEnum;
    existingTask?: Task;
    onClickNext: (lpDisplay: number) => void;
};

type FormValue = {
    lpDisplay?: number;
};

export const LPMatrixDisplayV2ForceStepType = (props: Props) => {
    const intl = useIntl();
    // STATE
    const [lpMatrixDisplaysV2, setLPMatrixDisplaysV2] = React.useState<MapLPMatrixDisplayV2[]>([]);
    const [formValue, setFormValue] = React.useState<FormValue>(() => {
        if (props.existingTask) {
            return {
                lpDisplay: props.existingTask.getLpMatrixDisplaysV2()[0].id,
            };
        }
        return {};
    });

    // EFFECTS
    useEffect(() => {
        axiosService
            .getAxios()
            .get('map-lp-matrix-displays-v2', {
                headers: authHeader(),
            })
            .then(lpMatrixDisplaysV2Response => {
                setLPMatrixDisplaysV2(
                    lpMatrixDisplaysV2Response.data.map(
                        lpMatrixDisplayV2 => new MapLPMatrixDisplayV2(lpMatrixDisplayV2)
                    )
                );
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const handleClickNext = () => {
        if (formValue.lpDisplay) {
            props.onClickNext(formValue.lpDisplay);
        }
    };

    const handleChange = (formValue: FormValue) => {
        setFormValue(formValue);
    };

    return (
        <div>
            <Form fluid formValue={formValue} onChange={formValue => handleChange(formValue as FormValue)}>
                <FormGroup>
                    <ControlLabel data-cy="event-createTask-lpDisplay-title">
                        {intl.formatMessage({ id: 'event.createTask.lpDisplay' })}
                    </ControlLabel>
                    <FormControl
                        data-cy="event-createTask-lpDisplay"
                        accepter={SelectPicker}
                        searchable={false}
                        cleanable={false}
                        data={lpMatrixDisplaysV2.map(lpMatrixDisplayV2 => {
                            return {
                                label: lpMatrixDisplayV2.label,
                                value: lpMatrixDisplayV2.getLpMatrixDisplayV2().getId(),
                            };
                        })}
                        name="lpDisplay"
                    />
                </FormGroup>
            </Form>

            <FlexboxGrid align="middle" justify="end" className="margin-top-15">
                <FlexboxGrid.Item>
                    <Button
                        onClick={() => props.onClickBack()}
                        color="blue"
                        className="margin-right-5"
                        data-cy="event-createTask-back">
                        <FontAwesomeIcon icon={faArrowLeft} className="margin-right-5" />
                        <FormattedMessage id="event.createTask.back" />
                    </Button>

                    <Button
                        color="green"
                        onClick={handleClickNext}
                        disabled={typeof formValue.lpDisplay !== 'number'}
                        data-cy="event-createTask-next">
                        <FontAwesomeIcon icon={faArrowRight} className="margin-right-5" />
                        <FormattedMessage id="event.createTask.next" />
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
};
