import { FlowElement } from 'react-flow-renderer';
import { DisplayPreviewInformation } from '../../../../../../../handlers/lpDisplays/LPDisplay';
import { LPDisplayForceTypeVirtualDisplaysInterface } from '../../../../../../../handlers/lpDisplays/LPDisplayForceType';
import { VirtualDisplayPreviewInformation } from '../../../../../../../handlers/lpDisplays/VirtualDisplay';
import {
    virtualDisplayXPosition,
    virtualDisplayYPosition,
} from '../../../../../../IpCanElementsPage/LPMatrixDisplayV2Config/handlers/Nodes/FlowNodeExporter';
import { LPMatrixDisplayFlowElement } from '../LPMatrixDisplayChartElement';

export default class LPMatrixDisplayChartVirtualDisplayNode extends LPMatrixDisplayFlowElement {
    private _id: string;
    private _index: number;
    private _label?: string;
    private _virtualDisplay: VirtualDisplayPreviewInformation;
    private _color: string;
    private _display: DisplayPreviewInformation;
    private _isForce: boolean;
    private _virtualDisplayForceData?: LPDisplayForceTypeVirtualDisplaysInterface;

    constructor(
        index: number,
        id: string,
        display: DisplayPreviewInformation,
        color: string,
        virtualDisplay: VirtualDisplayPreviewInformation,
        isForce: boolean,
        virtualDisplayForceData?: LPDisplayForceTypeVirtualDisplaysInterface,
        label?: string
    ) {
        super(id, label);
        this._index = index;
        this._id = id;
        this._label = label;
        this._virtualDisplay = virtualDisplay;
        this._color = color;
        this._display = display;
        this._isForce = isForce;
        this._virtualDisplayForceData = virtualDisplayForceData;
    }

    public render(): FlowElement<LPMatrixDisplayChartVirtualDisplayNodeType> {
        const yPosition = virtualDisplayYPosition();
        const xPosition = virtualDisplayXPosition();

        return {
            id: `${this._id}-force`,
            type: 'forceVirtualDisplayNode',
            data: {
                label: this._label || '',
                virtualDisplay: this._virtualDisplay,
                color: this._color,
                display: this._display,
                isForce: this._isForce,
                virtualDisplayForceData: this._virtualDisplayForceData,
            },
            position: {
                x:
                    xPosition.initialPositionX +
                    (xPosition.baseTopologyWidth +
                        xPosition.columnWidth * this._display.nbMatrixX * 2 +
                        xPosition.gapX),
                y:
                    yPosition.initialPositionY +
                    (yPosition.baseTopologyHeight + yPosition.lineHeight * this._display.nbMatrixY + yPosition.gapY) *
                        this._index,
            },
        };
    }
}

export type LPMatrixDisplayChartVirtualDisplayNodeType = {
    label: string;
    display: DisplayPreviewInformation;
    virtualDisplay: VirtualDisplayPreviewInformation;
    color: string;
    isForce: boolean;
    virtualDisplayForceData?: LPDisplayForceTypeVirtualDisplaysInterface;
};
