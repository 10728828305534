import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Layer, Rect, Stage } from 'react-konva';
import { PictoValueInterface } from '..';

type Props = {
    pictos: PictoValueInterface[];
    selectedPictoIndex: number;
    pictoSize: number;
    handleClick: (column: number, bitValue: number) => void;
};

const PREVIEW_SIZE = 850;

export const PictoEditor = (props: Props) => {
    const picto = props.pictos[props.selectedPictoIndex];

    let squareSize: number = PREVIEW_SIZE / props.pictoSize;

    const handleClick = (event: KonvaEventObject<MouseEvent>) => {
        const column = parseInt(event.target.attrs.id);
        const line = parseInt(event.target.attrs.name);

        props.handleClick(column, line);
    };

    if (picto.value.length > props.pictoSize) {
        squareSize = PREVIEW_SIZE / picto.value.length;
    }

    return (
        <>
            <h3 className="text-center margin-bottom-15" style={{ color: 'black' }}>
                <FormattedMessage id="editPicto.picto" /> - {picto.name}{' '}
            </h3>
            <div data-cy="picto-canvas">
                <Stage width={squareSize * picto.value.length} height={PREVIEW_SIZE}>
                    {squareSize !== Infinity && squareSize !== 0 && (
                        <Layer>
                            {picto.value.map((column, columnIndex) => {
                                return column.map((pixel, pixelIndex) => (
                                    <Rect
                                        x={columnIndex * squareSize}
                                        y={pixelIndex * squareSize}
                                        strokeWidth={1}
                                        stroke="grey"
                                        width={squareSize}
                                        height={squareSize}
                                        fill={pixel ? '#000' : '#FFF'}
                                        id={columnIndex.toString()}
                                        name={pixelIndex.toString()}
                                        onClick={handleClick}
                                    />
                                ));
                            })}
                        </Layer>
                    )}
                </Stage>
            </div>
        </>
    );
};
