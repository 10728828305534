import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ForceType from '../Elements/ForceType';

export default function displayForcedCell(isForce, endForceTime, forceType) {
    if (!isForce) return <FontAwesomeIcon data-cy="ipCanDisplays-noForce" icon={faTimes} size="2x" color="red" />;

    return (
        <div className="text-center">
            <span data-cy="ipCanDisplays-forced-value">
                <ForceType value={forceType} />
                <br />
                {endForceTime}
            </span>
        </div>
    );
}
