import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Modal, Tag, TagGroup } from 'rsuite';
import Camera from '../../../handlers/Camera/Camera';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';

type Props = {
    show: boolean;
    onHide: (deleted: boolean) => void;
    cameras: Camera[];
};

export const DeleteCameraModal = (props: Props) => {
    const intl = useIntl();

    const [isDeleting, setIsDeleting] = useState(false);

    const handleCancel = () => {
        if (!isDeleting) {
            props.onHide(false);
        }
    };

    const handleValid = () => {
        setIsDeleting(true);

        axiosService
            .getAxios()
            .delete('/ip-cameras', {
                data: { tabId: props.cameras.map(camera => camera.getId()) },
                headers: authHeader(),
            })
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'camera.delete.success' }));

                setIsDeleting(false);

                props.onHide(true);
            })
            .catch(err => {
                setIsDeleting(false);

                console.error(err);

                Alert.error(intl.formatMessage({ id: 'camera.delete.error' }));
            });
    };

    return (
        <Modal size="xs" show={props.show} onHide={handleCancel} data-cy="user-deleteuser-modal">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="camera.delete.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body data-cy="camera-delete-modal">
                <FormattedMessage id="camera.delete.message" />

                <TagGroup>
                    {props.cameras.map(camera => (
                        <Tag>
                            {camera.getName()} ({camera.getId()})
                        </Tag>
                    ))}
                </TagGroup>
            </Modal.Body>
            <Modal.Footer>
                <FlexboxGrid align="middle" justify="end">
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button
                                data-cy="delete-camera-cancel"
                                onClick={handleCancel}
                                disabled={isDeleting}
                                color="red">
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                data-cy="delete-camera-valid"
                                onClick={handleValid}
                                color="green"
                                loading={isDeleting}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Footer>
        </Modal>
    );
};
