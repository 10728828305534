import { faCheck, faPalette, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CompactPicker } from 'react-color';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Button, ButtonGroup, FlexboxGrid, Form, FormControl, InputNumber, List, Modal, Toggle } from 'rsuite';
import { sceneSensorPlaceTypeActions } from '../../../../redux/actions';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';

const styleCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
};

const headerCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '16px',
};

const colors = [
    '#000000',
    '#000055',
    '#0000AA',
    '#0000FF',
    '#005500',
    '#005555',
    '#0055AA',
    '#0055FF',
    '#00AA00',
    '#00AA55',
    '#00AAAA',
    '#00AAFF',
    '#550000',
    '#550055',
    '#5500AA',
    '#5500FF',
    '#555500',
    '#555555',
    '#5555AA',
    '#5555FF',
    '#55AA00',
    '#55AA55',
    '#55AAAA',
    '#55AAFF',
    '#AA0000',
    '#AA0055',
    '#AA00AA',
    '#AA00FF',
    '#AA5500',
    '#AA5555',
    '#AA55AA',
    '#AA55FF',
    '#AAAA00',
    '#AAAA55',
    '#AAAAAA',
    '#AAAAFF',
    '#FF0000',
    '#FF0055',
    '#FF00AA',
    '#FF00FF',
    '#FF5500',
    '#FF5555',
    '#FF55AA',
    '#FF55FF',
    '#FFAA00',
    '#FFAA55',
    '#FFAAAA',
    '#FFAAFF',
    '#00FF00',
    '#00FF55',
    '#00FFAA',
    '#00FFFF',

    '#55FF00',
    '#55FF55',
    '#55FFAA',
    '#55FFFF',
    '#AAFF00',
    '#AAFF55',
    '#AAFFAA',
    '#AAFFFF',
    '#FFFF00',
    '#FFFF55',
    '#FFFFAA',
    '#FFFFFF',
    '#000000',
    '#040404',
    '#080808',
    '#0C0C0C',
    '#101010',
    '#141414',
    '#181818',
    '#1C1C1C',
    '#202020',
    '#242424',
    '#282828',
    '#2C2C2C',
    '#303030',
    '#343434',
    '#383838',
    '#3C3C3C',
    '#404040',
    '#444444',
    '#484848',
    '#4C4C4C',
    '#505050',
    '#555555',
    '#595959',
    '#5D5D5D',
    '#616161',
    '#656565',
    '#696969',
    '#6D6D6D',
    '#717171',
    '#757575',
    '#797979',
    '#7D7D7D',
    '#818181',
    '#858585',
    '#898989',
    '#8D8D8D',
    '#919191',
    '#959595',
    '#999999',
    '#9D9D9D',
    '#A1A1A1',
    '#A5A5A5',
    '#AAAAAA',
    '#AEAEAE',
    '#B2B2B2',
    '#B6B6B6',
    '#BABABA',
    '#BEBEBE',
    '#C2C2C2',
    '#C6C6C6',
    '#CACACA',
    '#CECECE',
    '#D2D2D2',
    '#D6D6D6',
    '#DADADA',
    '#DEDEDE',
    '#E2E2E2',
    '#E6E6E6',
    '#EAEAEA',
    '#EEEEEE',
    '#F2F2F2',
    '#F6F6F6',
    '#FAFAFA',
    '#FFFFFF',
];

const mapDispatchToProps = dispatch => ({
    createScene: (led1, led2, led3, led4, led5, led6, color, time, state, sensorPlaceTypeId) =>
        dispatch(
            sceneSensorPlaceTypeActions.create(
                led1,
                led2,
                led3,
                led4,
                led5,
                led6,
                color,
                time,
                state,
                sensorPlaceTypeId
            )
        ),
});

type Props = {
    closeModal: Function;
    createScene: Function;
    state: number;
    sensorId: number;
    show: boolean;
} & WrappedComponentProps;

type State = {
    formValue: Record<string, any>;
    showColorPicker: boolean;
    loading: boolean;
};

class CreateSceneModal extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            formValue: {
                led1: true,
                led2: true,
                led3: true,
                led4: true,
                led5: true,
                led6: true,
                color: '#FFFFFF',
                time: 0,
            },
            showColorPicker: false,
            loading: false,
        };

        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createScene = this.createScene.bind(this);
        this.handleShowColorPicker = this.handleShowColorPicker.bind(this);
        this.handleChangeColor = this.handleChangeColor.bind(this);
    }

    closeModal() {
        this.setState({
            formValue: {
                led1: true,
                led2: true,
                led3: true,
                led4: true,
                led5: true,
                led6: true,
                color: '#FFFFFF',
                time: 0,
            },
        });

        this.props.closeModal();
    }

    handleChange(formValues) {
        this.setState({
            formValue: formValues,
        });
    }

    handleChangeColor(color) {
        this.setState({
            formValue: { ...this.state.formValue, color: color.hex },
        });
    }

    createScene() {
        this.setState({
            loading: true,
        });

        this.props.closeModal();

        this.props.createScene(
            this.state.formValue.led1,
            this.state.formValue.led2,
            this.state.formValue.led3,
            this.state.formValue.led4,
            this.state.formValue.led5,
            this.state.formValue.led6,
            this.state.formValue.color,
            parseInt(this.state.formValue.time),
            this.props.state,
            this.props.sensorId
        );

        this.setState({
            loading: false,
        })
    }

    handleShowColorPicker() {
        this.setState({
            showColorPicker: !this.state.showColorPicker,
        });
    }

    render() {
        return (
            <Modal size="lg" backdrop="static" show={this.props.show} onHide={this.closeModal}>
                <SecuredFragment authorizedRoles={[rolesConstants.sceneSensorPlaceType.CREATE]}>
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage id="sensorPlaceTypeScene.create.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body data-cy="sensorPlaceTypeScene-create-modal">
                        <List>
                            <List.Item disabled={true}>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                        <FormattedMessage id="sensorPlaceTypeScene.create.led1" />
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                        <FormattedMessage id="sensorPlaceTypeScene.create.led2" />
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                        <FormattedMessage id="sensorPlaceTypeScene.create.led3" />
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                        <FormattedMessage id="sensorPlaceTypeScene.create.led4" />
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                        <FormattedMessage id="sensorPlaceTypeScene.create.led5" />
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item style={headerCenter} colspan={2}>
                                        <FormattedMessage id="sensorPlaceTypeScene.create.led6" />
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item style={headerCenter} colspan={5}>
                                        <FormattedMessage id="sensorPlaceTypeScene.create.time" />
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item style={headerCenter} colspan={7}>
                                        <FormattedMessage id="sensorPlaceTypeScene.create.color" />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                            <List.Item>
                                <Form formValue={this.state.formValue} onChange={this.handleChange}>
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <FormControl
                                                data-cy="sensorPlaceTypeScene-create-led1"
                                                name="led1"
                                                accepter={Toggle}
                                                defaultChecked={this.state.formValue.led1}
                                            />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <FormControl
                                                data-cy="sensorPlaceTypeScene-create-led2"
                                                name="led2"
                                                accepter={Toggle}
                                                defaultChecked={this.state.formValue.led2}
                                            />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <FormControl
                                                data-cy="sensorPlaceTypeScene-create-led3"
                                                name="led3"
                                                accepter={Toggle}
                                                defaultChecked={this.state.formValue.led3}
                                            />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <FormControl
                                                data-cy="sensorPlaceTypeScene-create-led4"
                                                name="led4"
                                                accepter={Toggle}
                                                defaultChecked={this.state.formValue.led4}
                                            />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <FormControl
                                                data-cy="sensorPlaceTypeScene-create-led5"
                                                name="led5"
                                                accepter={Toggle}
                                                defaultChecked={this.state.formValue.led5}
                                            />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={2}>
                                            <FormControl
                                                data-cy="sensorPlaceTypeScene-create-led6"
                                                name="led6"
                                                accepter={Toggle}
                                                defaultChecked={this.state.formValue.led6}
                                            />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={4}>
                                            <FormControl
                                                data-cy="sensorPlaceTypeScene-create-time"
                                                fluid
                                                name="time"
                                                accepter={InputNumber}
                                                style={{ width: 120 }}
                                            />
                                        </FlexboxGrid.Item>

                                        <FlexboxGrid.Item style={styleCenter} colspan={8}>
                                            <FlexboxGrid justify="center">
                                                <FlexboxGrid.Item colspan={24} style={{ textAlign: 'center' }}>
                                                    <Button
                                                        data-cy="sensorPlaceTypeScene-create-color"
                                                        block
                                                        onClick={this.handleShowColorPicker}
                                                        style={{
                                                            backgroundColor: this.state.formValue.color,
                                                            border: '1px solid black',
                                                        }}>
                                                        <FontAwesomeIcon icon={faPalette} />
                                                    </Button>
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item>
                                                    {this.state.showColorPicker && (
                                                        <CompactPicker
                                                            color={this.state.formValue.color}
                                                            onChangeComplete={this.handleChangeColor}
                                                            colors={colors}
                                                        />
                                                    )}
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </Form>
                            </List.Item>
                        </List>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button
                                onClick={this.closeModal}
                                data-cy="sensorPlaceTypeScene-create-cancel"
                                color="red"
                                loading={this.state.loading}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </Button>
                            <Button
                                onClick={this.createScene}
                                data-cy="sensorPlaceTypeScene-create-valid"
                                appearance="primary"
                                loading={this.state.loading}>
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </SecuredFragment>
            </Modal>
        );
    }
}

export default injectIntl(connect(null, mapDispatchToProps)(CreateSceneModal));
