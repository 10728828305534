import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Dropdown } from 'rsuite';
import { appActions } from '../../../redux/actions';
import ch from '../../../style/assets/flags/ch.svg';
import de from '../../../style/assets/flags/de.svg';
import es from '../../../style/assets/flags/es.svg';
import fr from '../../../style/assets/flags/fr.svg';
import it from '../../../style/assets/flags/it.svg';
import us from '../../../style/assets/flags/us.svg';

type Props = {
    language: string;
    changeLanguage: Function;
} & WrappedComponentProps;

type State = { language: string | undefined; flagImg: string | undefined; activeKey: string | undefined };

const mapDispatchToProps = dispatch => ({
    changeLanguage: language => dispatch(appActions.changeLanguage(language)),
});

const iconStyles: Record<string, any> = {
    width: 36,
    height: 36,
    marginLeft: 10,
    lineHeight: '56px',
    textAlign: 'center',
    marginTop: 8,
};

class DropdownLanguage extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            flagImg: undefined,
            language: undefined,
            activeKey: undefined,
        };

        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
        switch (this.props.language) {
            case 'fr':
                this.setState({
                    flagImg: fr,
                    language: 'language.french',
                    activeKey: 'fr',
                });
                break;
            case 'es':
                this.setState({
                    flagImg: es,
                    language: 'language.spanish',
                    activeKey: 'es',
                });
                break;
            case 'it':
                this.setState({
                    flagImg: it,
                    language: 'language.italian',
                    activeKey: 'it',
                });
                break;
            case 'de':
                this.setState({
                    flagImg: de,
                    language: 'language.german',
                    activeKey: 'de',
                });
                break;
            case 'ch':
                this.setState({
                    flagImg: ch,
                    language: 'language.switzerland',
                    activeKey: 'ch',
                });
                break;
            case 'us':
                this.setState({
                    flagImg: us,
                    language: 'language.english',
                    activeKey: 'us',
                });
                break;
            default:
                this.setState({
                    flagImg: us,
                    language: 'language.english',
                    activeKey: 'us',
                });
        }
    }

    onSelect(eventKey) {
        this.props.changeLanguage(eventKey);

        switch (eventKey) {
            case 'fr':
                this.setState({
                    flagImg: fr,
                    language: 'language.french',
                    activeKey: 'fr',
                });
                break;
            case 'es':
                this.setState({
                    flagImg: es,
                    language: 'language.spanish',
                    activeKey: 'es',
                });
                break;
            case 'it':
                this.setState({
                    flagImg: it,
                    language: 'language.italian',
                    activeKey: 'it',
                });
                break;
            case 'de':
                this.setState({
                    flagImg: de,
                    language: 'language.german',
                    activeKey: 'de',
                });
                break;
            case 'ch':
                this.setState({
                    flagImg: ch,
                    language: 'language.switzerland',
                    activeKey: 'ch',
                });
                break;
            case 'us':
                this.setState({
                    flagImg: us,
                    language: 'language.english',
                    activeKey: 'us',
                });
                break;
            default:
                this.setState({
                    flagImg: us,
                    language: 'language.english',
                    activeKey: 'us',
                });
        }
    }

    render() {
        if (this.state.language) {
            return (
                <Dropdown
                    placement="rightEnd"
                    // title={language}
                    icon={<img src={this.state.flagImg} className="flag" alt={`flag-${this.state.activeKey}`} />}
                    renderTitle={() => {
                        return (
                            <img
                                src={this.state.flagImg}
                                className="flag"
                                data-cy="flag"
                                alt={`flag-${this.state.activeKey}`}
                                style={iconStyles}
                            />
                        );
                    }}
                    activeKey={this.state.activeKey}
                    onSelect={this.onSelect}>
                    <Dropdown.Item eventKey="fr">
                        <img src={fr} className="flag" alt="flag-fr" />
                        <FormattedMessage id="language.french" />
                    </Dropdown.Item>

                    <Dropdown.Item eventKey="es">
                        <Fragment>
                            <img src={es} className="flag" alt="flag-es" />
                            <FormattedMessage id="language.spanish" />
                        </Fragment>
                    </Dropdown.Item>

                    <Dropdown.Item eventKey="it">
                        <Fragment>
                            <img src={it} className="flag" alt="flag-it" />
                            <FormattedMessage id="language.italian" />
                        </Fragment>
                    </Dropdown.Item>

                    <Dropdown.Item eventKey="de">
                        <Fragment>
                            <img src={de} className="flag" alt="flag-de" />
                            <FormattedMessage id="language.german" />
                        </Fragment>
                    </Dropdown.Item>

                    <Dropdown.Item eventKey="ch">
                        <Fragment>
                            <img src={ch} className="flag" alt="flag-ch" />
                            <FormattedMessage id="language.switzerland" />
                        </Fragment>
                    </Dropdown.Item>

                    <Dropdown.Item eventKey="us">
                        <Fragment>
                            <img src={us} className="flag" alt="flag-us" data-cy="usFlag" />
                            <FormattedMessage id="language.english" />
                        </Fragment>
                    </Dropdown.Item>
                </Dropdown>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
    const { language } = state.app;

    return { language };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DropdownLanguage));
