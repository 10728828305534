import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';

type Props = {
    isAuthenticated: boolean;
} & RouteProps;

export class ProtectedRoute extends Route<Props> {
    constructor(props: Props){
        super(props);
    }
    render() {
        return (
            <Route
                render={(props: RouteComponentProps) => {
                    if (!this.props.isAuthenticated) {
                        return <Redirect to="/login" />;
                    }

                    if (this.props.component) {
                        return React.createElement(this.props.component, this.props);
                    }

                    if (this.props.render) {
                        return this.props.render(props);
                    }
                }}
            />
        );
    }
}
