export const userConstants = {
    GET_ALL_REQUEST: 'USERS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'USERS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'USERS_GET_ALL_FAILURE',

    GET_ONE_REQUEST: 'USERS_GET_ONE_REQUEST',
    GET_ONE_SUCCESS: 'USERS_GET_ONE_SUCCESS',
    GET_ONE_ERROR: 'USERS_GET_ONE_ERROR',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    CREATE_REQUEST: 'USERS_CREATE_REQUEST',
    CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'USERS_CREATE_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',
};
