import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Drawer, Message, Placeholder } from 'rsuite';
import PlaceholderParagraph from 'rsuite/lib/Placeholder/PlaceholderParagraph';
import Counter from '../../../handlers/Counter/Counter';
import { eventHandler } from '../../../handlers/event.handler';
import { IpCan } from '../../../handlers/ipcan/IpCan';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import DeleteEventModal from '../Modal/DeleteEventModal';
import EventInformations from './Components/EventInformations';
import EventTasks from './Components/EventTasks';

type Props = {
    event: Record<string, any>;
    show: boolean;
    onHide: Function;
    reloadEvents: Function;
    counters: Counter[];
    ipCanModules: IpCan[];
};

type State = {
    deleteModalOpen: boolean;
    event: Record<string, any> | undefined;
    loading: boolean;
    error: boolean;
    errorMessage: string | undefined;
};
export default class EventDrawer extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            // Delete modal
            deleteModalOpen: false,
            // Fetch event
            event: undefined,
            loading: true,
            error: false,
            errorMessage: undefined,
        };

        this.openDeleteEventModal = this.openDeleteEventModal.bind(this);
        this.closeDeleteEventModal = this.closeDeleteEventModal.bind(this);
        this.fetchEvent = this.fetchEvent.bind(this);
    }

    componentDidMount() {
        this.fetchEvent();
    }

    openDeleteEventModal() {
        this.setState({
            deleteModalOpen: true,
        });
    }

    closeDeleteEventModal() {
        this.setState({
            deleteModalOpen: false,
        });
    }

    fetchEvent() {
        this.setState({
            error: false,
            loading: true,
        });

        axiosService
            .getAxios()
            .get(`/event-actions/event/${this.props.event.id}`, { headers: authHeader() })
            .then(fetchEventResponse => {
                this.setState({
                    event: eventHandler(fetchEventResponse.data),
                });
            })
            .catch(err => {
                console.error(err);
                this.setState({
                    error: true,
                    errorMessage: JSON.stringify(err),
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event !== this.props.event) {
            this.fetchEvent();
        }
    }

    render() {
        return (
            <Fragment>
                <DeleteEventModal
                    onHide={this.closeDeleteEventModal}
                    show={this.state.deleteModalOpen}
                    event={this.props.event}
                    reloadEvents={this.props.reloadEvents}
                />

                <Drawer backdrop={true} show={this.props.show} onHide={() => this.props.onHide()}>
                    <Drawer.Header data-cy="event-drawer">
                        <Drawer.Title data-cy="event-drawer-title">
                            {this.state.loading ? <PlaceholderParagraph active rows={1} /> : this.props.event.name}
                        </Drawer.Title>
                    </Drawer.Header>

                    <Drawer.Body className="drawer-body-without-avatar">
                        {this.state.loading ? (
                            <Fragment>
                                <Placeholder.Graph height={400} rows={12} active className="margin-bottom-10" />
                                <Placeholder.Grid rows={8} columns={2} active />
                            </Fragment>
                        ) : this.state.error ? (
                            <Message type="error" description={this.state.errorMessage} />
                        ) : (
                            <Fragment>
                                <EventInformations
                                    event={this.state.event}
                                    reloadEvents={this.props.reloadEvents}
                                    counters={this.props.counters}
                                    ipCanModules={this.props.ipCanModules}
                                />
                                <SecuredFragment authorizedRoles={[rolesConstants.eventTasks.VIEW_LIST]}>
                                    <EventTasks
                                        tasks={this.state.event?.tasks}
                                        eventId={this.state.event?.id}
                                        reloadEvent={this.fetchEvent}
                                    />
                                </SecuredFragment>
                            </Fragment>
                        )}
                    </Drawer.Body>

                    <Drawer.Footer>
                        <SecuredFragment authorizedRoles={[rolesConstants.eventAction.DELETE]}>
                            <Button onClick={this.openDeleteEventModal} data-cy="delete-event-button" color="red">
                                <FormattedMessage id="event.drawer.deleteButton" />
                            </Button>
                        </SecuredFragment>
                    </Drawer.Footer>
                </Drawer>
            </Fragment>
        );
    }
}
