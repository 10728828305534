import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Alert } from 'rsuite';
import { Profile, ProfileInterface } from '../../handlers/profile/Profile';
import { User, UserInterface } from '../../handlers/profile/User';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import { ProfilesTable } from './Components/Profiles';
import { UsersTable } from './Components/Users';

type Props = {
    auth: Record<string, any>;
};

const UsersPage = (props: Props) => {
    const intl = useIntl();

    const [isLoadingUsers, setIsLoadingUsers] = React.useState<boolean>(false);
    const [isLoadingProfiles, setIsLoadingProfiles] = React.useState<boolean>(false);
    const [users, setUsers] = React.useState<User[]>([]);
    const [profiles, setProfiles] = React.useState<Profile[]>([]);

    const currentUserIsAdmin =
        props.auth?.user.roles.find(role => {
            return role.id === 1;
        }) || false;

    useEffect(() => {
        loadUsers();
        loadProfiles();
    }, []);

    useEffect(() => {
        const handleKeepAlive = data => {
            users
                .map(user => {
                    return user.getRestFulls().filter(restFull => restFull.getId() === data.id);
                })
                .forEach(rf => {
                    rf.forEach(rest => {
                        if (rest.getId() === data.id) rest.wsUpdate(data);
                    });
                });

            loadUsers();
        };

        webSocketService.joinRoom('restfull');

        webSocketService.onEvent('restfull:keepAlive', handleKeepAlive);

        return () => {
            webSocketService.offEvent('restfull:keepAlive', handleKeepAlive);
        };
    }, []);

    const loadUsers = () => {
        setIsLoadingUsers(true);

        axiosService
            .getAxios()
            .get<UserInterface[]>('/users', {
                headers: authHeader(),
            })
            .then(fetchAllUsersResponse => {
                let currentUsers = fetchAllUsersResponse.data.map(user => new User(user));

                if (!currentUserIsAdmin) {
                    currentUsers = currentUsers.filter(currentUser => {
                        if (currentUser.getProfiles().findIndex(profile => profile.getId() === 1) !== -1) {
                            return null;
                        }

                        return currentUser;
                    });
                }

                setUsers(currentUsers);
            })
            .catch(err => {
                console.error(err);

                axiosService
                    .getAxios()
                    .get<UserInterface>(`/users/${props.auth?.user.id}`, {
                        headers: authHeader(),
                    })
                    .then(userResponse => {
                        setUsers([new User(userResponse.data)]);
                    })
                    .catch(err => {
                        console.error(err);

                        Alert.error(intl.formatMessage({ id: 'users.error.fetch' }));
                    });
            })
            .finally(() => {
                setIsLoadingUsers(false);
            });
    };

    const loadProfiles = () => {
        setIsLoadingProfiles(true);

        axiosService
            .getAxios()
            .get<ProfileInterface[]>('profile', {
                headers: authHeader(),
            })
            .then(profileResponse => {
                let currentProfiles = profileResponse.data.map(profile => new Profile(profile));

                if (!currentUserIsAdmin) {
                    currentProfiles = currentProfiles.filter(profile => profile.getName() !== 'Admin');
                }

                setProfiles(currentProfiles);
            })
            .catch(error => {
                console.error(error);

                Alert.error(intl.formatMessage({ id: 'profiles.error.fetch' }));
            })
            .finally(() => {
                setIsLoadingProfiles(false);
            });
    };

    return (
        <>
            <UsersTable loading={isLoadingUsers} users={users} reloadUsers={loadUsers} reloadProfiles={loadProfiles} />
            <SecuredFragment authorizedRoles={[rolesConstants.profile.VIEW_LIST]}>
                <ProfilesTable
                    loading={isLoadingProfiles}
                    profiles={profiles}
                    reloadUsers={loadUsers}
                    reloadProfiles={loadProfiles}
                />
            </SecuredFragment>
        </>
    );
};

function mapStateToProps(state) {
    const { auth } = state;

    return { auth };
}

export default connect(mapStateToProps)(UsersPage);
