export default class NbDisplays {
    onDB: number;
    onModule: number;
    online: number;
    constructor(onDB, onModule, online) {
        this.onDB = onDB;
        this.onModule = onModule;
        this.online = online;
    }

    setWebsocketInformations(values) {
        const { total, online } = values;

        this.onModule = total;
        this.online = online;
    }

    getNbDisplays() {
        return {
            onDB: this.onDB,
            onModule: this.onModule,
            online: this.online,
        };
    }

    updateValues(onModule, online) {
        this.onModule = onModule;
        this.online = online;
    }
}
