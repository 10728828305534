import { faBan, faLock, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ConditionalStyles, TableColumn } from 'react-data-table-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, FlexboxGrid, Panel } from 'rsuite';
import PlaceCamera from '../../../handlers/Camera/PlaceCamera';
import { rolesConstants } from '../../../static/roles';
import SecuredFragment from '../../Auth/SecuredFragment';
import PanelHeader from '../../Custom/PanelHeader';
import { maintenanceStateInformation } from '../../IpCanElementsPage/Table/maintenanceStateInformation';
import ElementTable from '../../ReactDataTableComponent/ElementTable';
import { CameraPlaceDrawer } from '../Drawer';
import { EditHyperParamPlaceCameraModal } from '../Modal/EditHyperParamPlaceCameraModal';
import { ForceCameraPlaceModal } from '../Modal/ForceCameraPlaceModal';
import { DetectionStatePlace } from './Table/DetectionStatePlace';

type Props = {
    places: PlaceCamera[];
    selectedPlace: number | null;
    setSelectedPlace: (placeId: number | null) => void;
    reloadPlaces: () => void;
};

type PlaceInfoDrawer = {
    isOpen: boolean;
    place?: PlaceCamera;
};

export const PlaceCameraLeftSideBar = (props: Props) => {
    const intl = useIntl();

    const [selectedRows, setSelectedRows] = React.useState<PlaceCamera[]>([]);

    const [placeInfoDrawer, setPlaceInfoDrawer] = React.useState<PlaceInfoDrawer>({ isOpen: false });

    const [forceModalIsOpen, setForceModalIsOpen] = React.useState<boolean>(false);

    const [hyperParamModalOpen, setHyperParamModalOpen] = React.useState<boolean>(false);

    const [clearSelectedRows, setClearSelectedRows] = React.useState<boolean>(false);

    const handleOpenPlaceInfoDrawer = (place: PlaceCamera) => {
        setPlaceInfoDrawer({ isOpen: true, place: place });
    };

    const handleClosePlaceInfoDrawer = () => {
        setPlaceInfoDrawer({ isOpen: false });
    };

    const columns: TableColumn<PlaceCamera>[] = [
        {
            name: intl.formatMessage({ id: 'cameras.placeCamera.label' }),
            selector: row => row.getLabel(),
            cell: row => row.getLabel(),
            sortable: true,
            center: true,
            grow: 1,
        },
        {
            name: intl.formatMessage({ id: 'cameras.placeCamera.maitenanceState' }),
            selector: row => row.getMaintenanceState(),
            cell: row => (
                <SecuredFragment authorizedRoles={[rolesConstants.ipCameras.UPDATE_MAINTENANCE_STATE]}>
                    {maintenanceStateInformation(row.getMaintenanceState(), row.getNotepad())}
                </SecuredFragment>
            ),
            sortable: true,
            center: true,
            grow: 2,
        },
        {
            name: intl.formatMessage({ id: 'cameras.placeCamera.detection' }),
            selector: row => row.getState(),
            cell: row => (
                <DetectionStatePlace
                    detectionStateDate={row.getDetectionStateTime()}
                    state={row.getState()}
                    statePrediction={(row.getStatePrediction() * 100).toFixed(0)}
                />
            ),
            sortable: true,
            center: true,
            grow: 2,
        },
    ];

    const conditionalRowStyle: ConditionalStyles<PlaceCamera>[] = [
        {
            when: row => row.getIsForce(),
            style: {
                backgroundColor: 'rgba(244, 67, 54, 0.3)',
                '&:hover': {
                    color: 'rgba(244, 67, 54, 1)',
                },
            },
        },
        {
            when: row => row.getId() === props.selectedPlace,
            style: {
                backgroundColor: 'rgba(33, 132, 218, .3)',
            },
        },
    ];

    const handleSelectedRowsChange = ({ selectedRows }: { selectedRows: PlaceCamera[] }) => {
        setSelectedRows(selectedRows);
    };

    const handleCloseEditHyperParamPlaceCameraModal = () => {
        setHyperParamModalOpen(false);

        // setSelectedRows([]);

        // setClearSelectedRows(!clearSelectedRows);

        props.reloadPlaces();
    };

    const handleCloseForceCameraPlaceModal = () => {
        setForceModalIsOpen(false);

        // setSelectedRows([]);

        // setClearSelectedRows(!clearSelectedRows);

        props.reloadPlaces();
    };

    return (
        <>
            <CameraPlaceDrawer
                show={placeInfoDrawer.isOpen}
                onHide={handleClosePlaceInfoDrawer}
                place={placeInfoDrawer.place}
                reloadPlaces={props.reloadPlaces}
            />

            <ForceCameraPlaceModal
                places={selectedRows}
                onClose={handleCloseForceCameraPlaceModal}
                show={forceModalIsOpen}
            />

            <EditHyperParamPlaceCameraModal
                onClose={handleCloseEditHyperParamPlaceCameraModal}
                show={hyperParamModalOpen}
                placesCameraSelected={selectedRows}
            />

            <Panel
                className="panel-small"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader title={intl.formatMessage({ id: 'camera.places' })} tagValue={props.places.length} />
                }>
                <FlexboxGrid className="margin-top-5 margin-bottom-5">
                    <FlexboxGrid.Item colspan={8}>
                        <Button
                            color="orange"
                            onClick={() => setForceModalIsOpen(true)}
                            disabled={selectedRows.length === 0}
                            block>
                            <FontAwesomeIcon icon={faLock} className="margin-right-5" />
                            <FormattedMessage id="camera.cameraPlaces.forcePlaces" />
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                        <Button
                            color="violet"
                            onClick={() => setHyperParamModalOpen(true)}
                            disabled={selectedRows.length === 0}
                            block>
                            <FontAwesomeIcon icon={faWrench} className="margin-right-5" />
                            <FormattedMessage id="camera.cameraPlaces.updateHyperParameter" />
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                        <Button
                            color="green"
                            onClick={() => props.setSelectedPlace(null)}
                            disabled={props.selectedPlace === null}
                            block>
                            <FontAwesomeIcon icon={faBan} className="margin-right-5" />
                            <FormattedMessage id="camera.cameraPlaces.resetSelector" />
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <div className="table-camera-place">
                    <ElementTable
                        small={true}
                        columns={columns}
                        data={props.places}
                        onRowDoubleClicked={row => handleOpenPlaceInfoDrawer(row)}
                        onRowClicked={row => props.setSelectedPlace(row.getId())}
                        highlightOnHover
                        pointerOnHover
                        striped
                        responsive
                        conditionalRowStyles={conditionalRowStyle}
                        selectableRows
                        onSelectedRowsChange={handleSelectedRowsChange}
                        clearSelectedRows={clearSelectedRows}
                    />
                </div>
            </Panel>
        </>
    );
};
