export const placeTypeConstants = {
    GET_ALL_REQUEST: 'PLACE_TYPES_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PLACE_TYPES_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PLACE_TYPES_GET_ALL_FAILURE',

    GET_ONE_REQUEST: 'PLACE_TYPES_GET_ONE_REQUEST',
    GET_ONE_SUCCESS: 'PLACE_TYPES_GET_ONE_SUCCESS',
    GET_ONE_ERROR: 'PLACE_TYPES_GET_ONE_ERROR',

    DELETE_REQUEST: 'PLACE_TYPES_DELETE_REQUEST',
    DELETE_SUCCESS: 'PLACE_TYPES_DELETE_SUCCESS',
    DELETE_FAILURE: 'PLACE_TYPES_DELETE_FAILURE',

    CREATE_REQUEST: 'PLACE_TYPES_CREATE_REQUEST',
    CREATE_SUCCESS: 'PLACE_TYPES_CREATE_SUCCESS',
    CREATE_FAILURE: 'PLACE_TYPES_CREATE_FAILURE',

    UPDATE_REQUEST: 'PLACE_TYPES_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PLACE_TYPES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PLACE_TYPES_UPDATE_FAILURE',

    OPEN_VIEW_DRAWER_REQUEST: 'PLACE_TYPES_OPEN_VIEW_DRAWER_REQUEST',
    OPEN_VIEW_DRAWER_SUCCESS: 'PLACE_TYPES_OPEN_VIEW_DRAWER_SUCCESS',
    OPEN_VIEW_DRAWER_FAILURE: 'PLACE_TYPES_OPEN_VIEW_DRAWER_FAILURE',

    CLOSE_VIEW_DRAWER: 'PLACE_TYPES_CLOSE_VIEW_DRAWER_REQUEST',

    OPEN_CREATE_MODAL: 'PLACE_TYPES_OPEN_CREATE_MODAL',
    CLOSE_CREATE_MODAL: 'PLACE_TYPES_CLOSE_CREATE_MODAL',

    OPEN_DELETE_MODAL_REQUEST: 'PLACE_TYPES_OPEN_DELETE_MODAL_REQUEST',
    OPEN_DELETE_MODAL_SUCCESS: 'PLACE_TYPES_OPEN_DELETE_MODAL_SUCCESS',
    OPEN_DELETE_MODAL_FAILURE: 'PLACE_TYPES_OPEN_DELETE_MODAL_FAILURE',

    CLOSE_DELETE_MODAL: 'PLACE_TYPES_CLOSE_DELETE_MODAL',
};
