import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'rsuite';

export default function lpDisplayForcedCell(isForce, endForceTime) {
    if (!isForce) return <FontAwesomeIcon data-cy="ipCanDisplays-noForce" icon={faTimes} size="2x" color="red" />;

    return (
        <div className="text-center">
            <span data-cy="ipCanDisplays-forced-value">
                <Tag color="orange">
                    <FormattedMessage id="ipCanDisplays.forced" />
                </Tag>
                <br />
                {endForceTime}
            </span>
        </div>
    );
}
