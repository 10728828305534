import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Steps } from 'rsuite';
import Task from '../../../../handlers/Event/Task';
import { TaskForceOptionInterface } from '../../../../handlers/Event/TaskForceOption';
import { TaskTypeEnum } from '../Tasks/CreateTaskType';
import { FirstStep } from './FirstStep';
import { LPMatrixDisplayV2Step } from './LPMatrixDisplayV2Step';
import { SecondStep } from './SecondStep';

type Props = {
    show: boolean;
    onClose: () => void;
    eventId?: number;
    task?: Task;
    type: StepTypeEnum;
};

export type StepTaskData = {
    eventId?: number;
    name: string;
    description: string;
    type: TaskTypeEnum;
    tcmDisplays: number[];
    counters: number[];
    lpDisplays: number[];
    color: string;
    forceOption: TaskForceOptionInterface;
};

export const CreateAndEditTaskModal = (props: Props) => {
    const [stepNumber, setStepNumber] = React.useState<number>(0);

    const closeModal = () => {
        props.onClose();
    };

    const [taskData, setTaskData] = React.useState<StepTaskData>(() => {
        if (props.task) {
            return {
                name: props.task.getName(),
                description: props.task.getDesc(),
                type: props.task.getForceType(),
                tcmDisplays: props.task.getTcmDisplay().map(tcmDisplay => tcmDisplay.getId()),
                counters: props.task.getCounters().map(counter => counter.id),
                lpDisplays: props.task.getLpMatrixDisplaysV2().map(display => display.getId()),
                color: props.task.getForceOption().getHexColor() || '',
                forceOption: props.task.getForceOption().getForceTypeData(),
            };
        } else {
            return {
                eventId: props.eventId,
                forceOption: {},
                name: '',
                description: '',
                type: TaskTypeEnum.LP_SENSOR_FORCE_COLOR,
                tcmDisplays: [],
                counters: [],
                lpDisplays: [],
                color: '',
            };
        }
    });

    const handleClickNext = (name: string, description: string, type: TaskTypeEnum) => {
        setStepNumber(stepNumber + 1);

        setTaskData({
            ...taskData,
            name,
            description,
            type,
        });
    };

    const handleClickNextLPMatrixDisplayV2 = (lpDisplay: number) => {
        setStepNumber(stepNumber + 1);

        setTaskData({
            ...taskData,
            lpDisplays: [lpDisplay],
        });
    };

    const generateSteps = () => {
        if (taskData.type === TaskTypeEnum.LP_MATRIX_DISPLAY_V2_ENABLE_FORCE) {
            return (
                <Steps current={stepNumber}>
                    <Steps.Item />
                    <Steps.Item />
                    <Steps.Item />
                </Steps>
            );
        } else {
            return (
                <Steps current={stepNumber}>
                    <Steps.Item />
                    <Steps.Item />
                </Steps>
            );
        }
    };

    return (
        <Modal backdrop="static" show={props.show} onHide={() => closeModal()} size="sm" full={stepNumber === 2}>
            <Modal.Header>
                <Modal.Title>
                    {props.type === StepTypeEnum.CREATE && <FormattedMessage id="event.createTask.title" />}
                    {props.type === StepTypeEnum.EDIT && <FormattedMessage id="event.editTask.title" />}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {generateSteps()}

                {stepNumber === 0 && <FirstStep currentTask={taskData} onClickNext={handleClickNext} />}
                {stepNumber === 1 && (
                    <SecondStep
                        onClickBack={() => setStepNumber(stepNumber - 1)}
                        existingTask={props.task}
                        currentTask={taskData}
                        closeModal={closeModal}
                        type={props.type}
                        onClickNext={handleClickNextLPMatrixDisplayV2}
                    />
                )}
                {stepNumber === 2 && (
                    <LPMatrixDisplayV2Step
                        existingTask={props.task}
                        currentTask={taskData}
                        onClickBack={() => setStepNumber(stepNumber - 1)}
                        closeModal={closeModal}
                        type={props.type}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
};

export enum StepTypeEnum {
    CREATE = 0,
    EDIT = 1,
}
