import React from 'react';

export default function movePtz(option) {
    return (
        <div data-cy="historic-ipcamera-option-movePtz">
            <span>ID: {option.id}</span>
            <br />
            <span>Pan: {option.pan}</span>
            <br />
            <span>Tilt: {option.tilt}</span>
            <br />
            <span>Zoom: {option.zoom}</span>
            <br />
            <span>Timeout: {option.timeout}</span>
        </div>
    );
}
