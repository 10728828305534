import { faCheck, faEye, faEyeSlash, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Checkbox,
    CheckboxGroup,
    ControlLabel,
    DatePicker,
    FlexboxGrid,
    Form,
    FormControl,
    FormGroup,
    InputNumber,
    Loader,
    Message,
    Modal,
    SelectPicker,
    Toggle,
} from 'rsuite';
import { MapVehicleCounter } from '../../../../handlers/map/MapVehicleCounter';
import { sensorPlaceTypeHandler } from '../../../../handlers/sensorPlaceType/sensorPlaceType.handler';
import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';

type Props = {
    show: boolean;
    onHide: Function;
    passCount: MapVehicleCounter;
} & WrappedComponentProps;

type State = {
    loading: boolean;
    formValue: FormValue;
    hasError: boolean;
    sensorPlaceTypes: { label: string; value: number; image: Buffer; imageType: string }[];
};

type FormValue = {
    name: string;
    nbTotPlace: number;
    resetTime: any;
    isResettable: boolean;
    countingOnLevel: boolean;
    isShow: boolean;
    displayValue: string[];
    sensorPlaceType: number | null;
    isShowOnZone: number;
};

class EditPassCountModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            formValue: {
                name: props.passCount.getLabel(),
                nbTotPlace: props.passCount.getVehicleCounter().getNbTotPlace(),
                countingOnLevel: props.passCount.getVehicleCounter().getCountingOnLevel(),
                resetTime: moment()
                    .startOf('day')
                    .add(props.passCount.getVehicleCounter().getResetTime(), 'seconds')
                    .format('LLLL'),
                isResettable: props.passCount.getVehicleCounter().getIsResettable(),
                isShow: props.passCount.getIsShow(),
                displayValue: props.passCount.getDisplayValue(),
                sensorPlaceType: props.passCount.getVehicleCounter().getSensorPlaceType().getId(),
                isShowOnZone: props.passCount.getVehicleCounter().getCounter().isShowOnProgressZone3,
            },
            hasError: false,
            sensorPlaceTypes: [],
        };
    }

    componentDidMount = () => {
        axiosService
            .getAxios()
            .get('/sensor-place-type', { headers: authHeader() })
            .then(sensorPlaceResponse => {
                this.setState({
                    sensorPlaceTypes: sensorPlaceResponse.data.map(placeType => {
                        const pt = sensorPlaceTypeHandler(placeType);
                        return {
                            label: pt.label,
                            value: pt.id,
                            image: pt.placeType.image,
                            imageType: pt.placeType.imageType,
                        };
                    }),
                });
            });
    };

    handleChange = formValues => {
        this.setState({
            formValue: {
                name: formValues.name,
                nbTotPlace: parseInt(formValues.nbTotPlace),
                countingOnLevel: formValues.countingOnLevel,
                resetTime: formValues.resetTime,
                isResettable: formValues.isResettable,
                isShow: formValues.isShow,
                displayValue: formValues.displayValue,
                sensorPlaceType: formValues.sensorPlaceType,
                isShowOnZone: formValues.isShowOnZone,
            },
        });
    };

    editPassCount = () => {
        this.setState({
            loading: true,
        });

        const {
            name,
            nbTotPlace,
            resetTime,
            isResettable,
            isShow,
            displayValue,
            countingOnLevel,
            sensorPlaceType,
            isShowOnZone,
        } = this.state.formValue;

        axiosService
            .getAxios()
            .put(
                '/map-vehicle-counters/updateVehicleCounter',
                {
                    name,
                    nbTotPlace,
                    isResettable,
                    resetTime: isResettable
                        ? moment(resetTime).set('seconds', 0).diff(moment().startOf('day'), 'seconds')
                        : 0,
                    isShow,
                    displayValue,
                    countingOnLevel,
                    id: this.props.passCount.getId(),
                    id_sensorPlaceType: sensorPlaceType,
                    displayOnZone: isShowOnZone ? 99 : 0,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(updateVehicleCounterResponse => {
                this.props.passCount.updateMapVehicleCounter(updateVehicleCounterResponse.data);

                this.setState({
                    formValue: {
                        name: this.props.passCount.getLabel(),
                        nbTotPlace: this.props.passCount.getVehicleCounter().getNbTotPlace(),
                        countingOnLevel: this.props.passCount.getVehicleCounter().getCountingOnLevel(),
                        resetTime: this.props.passCount.getVehicleCounter().getResetTime(),
                        isResettable: this.props.passCount.getVehicleCounter().getIsResettable(),
                        isShow: this.props.passCount.getIsShow(),
                        displayValue: this.props.passCount.getDisplayValue(),
                        sensorPlaceType: this.props.passCount.getVehicleCounter().getSensorPlaceType().getId(),
                        isShowOnZone: this.props.passCount.getVehicleCounter().getCounter().isShowOnProgressZone3,
                    },
                });

                Alert.success(this.props.intl.formatMessage({ id: 'map.passCount.editPassCount.success' }));

                this.cancelCreation(true);
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage(
                        { id: 'map.passCount.editPassCount.error' },
                        {
                            error: err,
                        }
                    )
                );
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    cancelCreation = (shouldReload: boolean = false) => {
        this.setState({
            formValue: {
                name: this.props.passCount.getLabel(),
                nbTotPlace: this.props.passCount.getVehicleCounter().getNbTotPlace(),
                countingOnLevel: this.props.passCount.getVehicleCounter().getCountingOnLevel(),
                resetTime: this.props.passCount.getVehicleCounter().getResetTime(),
                isResettable: this.props.passCount.getVehicleCounter().getIsResettable(),
                isShow: this.props.passCount.getIsShow(),
                displayValue: this.props.passCount.getDisplayValue(),
                sensorPlaceType: this.props.passCount.getVehicleCounter().getSensorPlaceType().getId(),
                isShowOnZone: this.props.passCount.getVehicleCounter().getCounter().isShowOnProgressZone3,
            },
        });

        this.props.onHide(shouldReload);
    };

    render() {
        return (
            <Modal show={this.props.show} backdrop="static" onHide={() => this.cancelCreation(false)} size="xs">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="map.passCount.editPassCount" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.hasError && (
                        <Message
                            className="margin-bottom-15"
                            type="error"
                            description={this.props.intl.formatMessage({
                                id: 'map.passCount.editPassCountError',
                            })}
                        />
                    )}

                    <Form fluid onChange={values => this.handleChange(values)} formValue={this.state.formValue}>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.passCount.form.name" />
                            </ControlLabel>
                            <FormControl
                                data-cy="map-passCount-edit-name"
                                className="form-margin"
                                name="name"
                                disabled={this.state.loading}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.passCount.form.nbToPlace" />
                            </ControlLabel>
                            <FormControl
                                className="form-margin"
                                data-cy="map-passCount-edit-nbToPlace"
                                name="nbTotPlace"
                                accepter={InputNumber}
                                min={0}
                                style={{ width: '100%' }}
                                disabled={this.state.loading}
                            />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.passCount.form.sensorPlaceType" />
                            </ControlLabel>
                            <FormControl
                                className="form-margin"
                                data-cy="map-passCount-edit-sensorPlaceType"
                                name="sensorPlaceType"
                                disabled={this.state.loading}
                                accepter={SelectPicker}
                                data={this.state.sensorPlaceTypes}
                                renderMenuItem={(_, item) => {
                                    if (item) {
                                        return (
                                            <FlexboxGrid align="middle">
                                                <FlexboxGrid.Item data-cy="map-passCount-edit-type">
                                                    <img
                                                        alt={item.label}
                                                        style={{ height: 20 }}
                                                        src={`data:image/${item.imageType};base64, ${item.image}`}
                                                    />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item>{item.label}</FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        );
                                    } else {
                                        return <Loader />;
                                    }
                                }}
                                renderValue={(_, item) => {
                                    if (item) {
                                        return (
                                            <FlexboxGrid align="middle">
                                                <FlexboxGrid.Item>
                                                    <img
                                                        alt={item.label}
                                                        style={{ height: 20 }}
                                                        src={`data:image/${item.imageType};base64, ${item.image}`}
                                                    />
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item>{item.label}</FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        );
                                    } else {
                                        return <Loader />;
                                    }
                                }}
                                cleanable={false}
                            />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.passCount.form.isResettable" />
                            </ControlLabel>
                            <FormControl
                                className="form-margin"
                                name="isResettable"
                                data-cy="map-passCount-edit-isResettable"
                                defaultChecked={this.state.formValue.isResettable}
                                accepter={Toggle}
                                checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                                unCheckedChildren={<FontAwesomeIcon icon={faTimes} />}
                                disabled={this.state.loading}
                            />
                        </FormGroup>
                        {this.state.formValue.isResettable && (
                            <FormGroup>
                                <ControlLabel>
                                    <FormattedMessage id="map.passCount.form.resetTime" />
                                </ControlLabel>
                                <FormControl
                                    className="form-margin"
                                    data-cy="map-passCount-edit-resetTime"
                                    name="resetTime"
                                    accepter={DatePicker}
                                    ranges={[]}
                                    format="HH:mm"
                                    disabled={this.state.loading}
                                />
                            </FormGroup>
                        )}
                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.passCount.form.countingOnLevel" />
                            </ControlLabel>
                            <FormControl
                                className="form-margin"
                                data-cy="map-passCount-edit-countingOnLevel"
                                name="countingOnLevel"
                                accepter={Toggle}
                                defaultChecked={this.state.formValue.countingOnLevel}
                                checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                                unCheckedChildren={<FontAwesomeIcon icon={faTimes} />}
                                disabled={this.state.loading}
                            />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.passCount.form.isShow" />
                            </ControlLabel>
                            <FormControl
                                className="form-margin"
                                data-cy="map-passCount-edit-isShow"
                                name="isShow"
                                accepter={Toggle}
                                defaultChecked={this.state.formValue.isShow}
                                checkedChildren={<FontAwesomeIcon icon={faEye} />}
                                unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} />}
                                disabled={this.state.loading}
                            />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.passCount.form.isShowOnZone" />
                            </ControlLabel>
                            <FormControl
                                className="form-margin"
                                name="isShowOnZone"
                                data-cy="map-passCount-create-isShowOnZone"
                                accepter={Toggle}
                                defaultChecked={this.state.formValue.isShowOnZone > 0}
                                checkedChildren={<FontAwesomeIcon icon={faEye} />}
                                unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} />}
                                disabled={this.state.loading}
                            />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>
                                <FormattedMessage id="map.passCount.form.displayValue" />
                            </ControlLabel>
                            <FormControl
                                className="form-margin"
                                data-cy="map-passCount-edit-displayValue"
                                name="displayValue"
                                accepter={CheckboxGroup}
                                inline
                                disabled={this.state.loading}>
                                <Checkbox value={'IN'}>IN</Checkbox>
                                <Checkbox value={'OUT'}>OUT</Checkbox>
                                <Checkbox value={'OCCUPED'}>FREE</Checkbox>
                            </FormControl>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            onClick={() => this.cancelCreation(false)}
                            data-cy="map-passCount-edit-cancel"
                            color="red"
                            disabled={this.state.loading}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                        <Button
                            onClick={this.editPassCount}
                            data-cy="map-passCount-edit-valid"
                            appearance="primary"
                            loading={this.state.loading}>
                            <FontAwesomeIcon icon={faCheck} />
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default injectIntl(EditPassCountModal);
